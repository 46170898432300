(function(ns) {
    /**
     * @namespace
     * @alias Model.Action.Definition
     * @constructor
     *
     * @param {String} name
     * @param {String=} label
     * @param {String=} contentType
     * @param {String[]=} context
     * @param {Object=} fields
     * @param {String=} category
     * @param {String=} description
     * @param {Model.Message.Template[]=} templates
     */
    var Definition = function (name, label, contentType, context, fields, category, description, templates) {
        name        = name || '';
        label       = label || name;
        contentType = contentType || false;
        category    = category || '';
        context     = context || [];
        fields      = fields || [];
        templates   = templates || [];

        var combinables = new Model.Action.Definition.Collection();

        Object.defineProperties(this, {
            /**
             * @property {String}
             * @name Model.Action.Definition#name
             */
            name: {
                enumerable: true,
                configurable: false,
                get: function () {
                    return name;
                }
            },
            /**
             * @property {String}
             * @name Model.Action.Definition#label
             */
            label: {
                enumerable: true,
                configurable: false,
                get: function () {
                    return label;
                }
            },
            /**
             * @property {String}
             * @name Model.Action.Definition#contentType
             */
            contentType: {
                enumerable: true,
                configurable: false,
                get: function () {
                    return contentType;
                }
            },
            /**
             * @property {String[]}
             * @name Model.Action.Definition#context
             */
            context: {
                enumerable: true,
                configurable: false,
                get: function () {
                    return context;
                }
            },
            /**
             * @property {Model.Action.Definition.Collection}
             * @name Model.Action.Definition#combinables
             */
            combinables: {
                enumerable: true,
                configurable: false,
                get: function () {
                    return combinables;
                }
            },
            fields: {
                enumerable: true,
                configurable: false,
                get: function () {
                    return fields;
                }
                /**
                 * @property {ActionFieldDefinition[]}
                 * @name Model.Action.Definition#fields
                 */
            },
            category: {
                enumerable: true,
                get: function () {
                    return category;
                }
                /**
                 * @property
                 * @name Model.Action.Definition#category
                 * @type {String}
                 */
            },
            description: {
                enumerable: true,
                get: function () {
                    return description;
                }
                /**
                 * @property
                 * @name Model.Action.Definition#description
                 * @type {String}
                 */
            },
            templates: {
                enumerable: true,
                get: function () {
                    return templates;
                }
                /**
                 * @property
                 * @name Model.Action.Definition#templates
                 * @type {Model.Message.Template[]}
                 */
            }
        });

        /**
         * @typedef {Object} ActionFieldDefinition
         * @property {String} name
         * @property {String} type
         * @property {String} label
         * @property {boolean} [required]
         * @property {boolean} [is_array]
         */

        this.__getCloneArguments = function () {
            return [
                this.name,
                this.label,
                this.contentType,
                this.context,
                this.combinables,
                this.fields,
                this.category,
                this.description,
                this.templates
            ];
        };

        this.__dontCloneProperties = function () {
            return ['$$hashKey'];
        };
    };

    /**
     * @function
     * @name Model.Action.Definition#hasContext
     * @param {String} token
     * @returns boolean
     */
    Definition.prototype.hasContext = function hasContext(token) {
        return this.context.filter(function (context) {
            return context === token;
        }).length > 0;
    };

    /**
     * @function
     * @name Model.Action.Definition#validateCombinables
     * @throws Model.Exception.InvalidActionDefinitionCombination
     */
    Definition.prototype.validateCombinables = function validateCombinables() {
        var missingActionNames = [];
        for (var i = 0; i < this.combinables.length; i++) {
            if (this.combinables.definitions[i].combinables.getIndex(this) === -1) {
                missingActionNames.push(this.combinables.definitions[i].name)
            }
        }
        if (missingActionNames.length) {
            throw Object.construct(Model.Exception.InvalidActionDefinitionCombination, [this].concat(missingActionNames));
        }
    };

    /**
     * @return {boolean}
     */
    Definition.prototype.hasTemplate = function hasTemplate() {
        return Boolean(this.templates.length);
    };

    /**
     * @function
     * @static
     * @name Model.Action.Definition#create
     * @returns Model.Action.Definition
     */
    Definition.create = function create(data) {
        var name, label, contentType, context, fields, category, description;

        if (data) {
            if (data.hasOwnProperty('name')) {
                name = data.name;
            }
            if (data.hasOwnProperty('label')) {
                label = data.label;
            }
            if (data.hasOwnProperty('contentType')) {
                contentType = data.contentType;
            }
            if (data.hasOwnProperty('context')) {
                context = data.context;
            }
            if (data.hasOwnProperty('fields')) {
                fields = data.fields;
            }

            if (data.hasOwnProperty('category')) {
                category = data.category;
            }

            if (data.hasOwnProperty('description')) {
                description = data.description;
            }
        }
        return new Definition(name, label, contentType, context, fields, category, description);
    };

    Definition.CONTENT_TYPE_JSON    = 'json';
    Definition.CONTENT_TYPE_MESSAGE = 'message';

    /**
     * Contains the collection of all definitions loaded
     * @static
     * @property
     * @name Model.Action.Definition#all
     * @type {Model.Action.Definition[]}
     */
    
    ns.Definition = Definition;
})(Object.namespace('Model.Action'));
