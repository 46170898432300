(function(ns) {
    var KEY_UUID                        = 'uuid',
        KEY_SENDER                      = 'sender',
        KEY_TIME_UPDATED                = 'timeUpdated',
        STATUS_ACTIVE                   = 'active',
        STATUS_INACTIVE                 = 'inactive',
        INTEGRATION_STATUS_CONNECTED    = 'connected',
        INTEGRATION_STATUS_PENDING      = 'pending'
    ;

    /**
     * @namespace
     * @alias Model.Sender
     * @extends Model.RESTAccessByUUID
     * @implements Model.Behavior.Meta
     * @constructor
     *
     * @param {String} _type
     * @param {String|Integer} uuid
     */
    var Sender = function (_type, uuid) {
        var self        = this,
            type        = _type,
            isConnected = false,
            timeUpdated
        ;

        Model.Behavior.Meta.call(this);

        /**
         * @property
         * @name Model.Sender#status
         * @type {String}
         */
        this.status = STATUS_ACTIVE;

        /**
         * @property
         * @name Model.Sender#integrationStatus
         * @type {?String}
         */
        this.integrationStatus = null;

        this.alias = uuid || '';

        Object.defineProperties(
            this,
            {
                type: {
                    enumerable: true,
                    get: function() {
                        for (var i in Sender.TYPES) {
                            if (Sender.TYPES[i] === type) {
                                return i.toLowerCase();
                            }
                        }
                        return '';
                    }
                    /**
                     * @property
                     * @type {String}
                     * @name Model.Sender#type
                     */
                },
                isConnected: {
                    enumerable: true,
                    configurable: true,
                    get: function () {
                        return isConnected;
                    },
                    set: function (val) {
                        isConnected = !!val;
                    }
                    /**
                     * @property
                     * @type {Boolean}
                     * @name Model.Sender#isConnected
                     */
                },
                isActive: {
                    enumerable: false,
                    configurable: true,
                    get: function () {
                        return self.status === STATUS_ACTIVE;
                    },
                    set: function (val) {
                        self.status = val ? STATUS_ACTIVE : STATUS_INACTIVE;
                    }
                    /**
                     * @property
                     * @type {Boolean}
                     * @name Model.Sender#isActive
                     */
                },
                isAvailable: {
                    enumerable: false,
                    configurable: true,
                    get: function () {
                        return self.isActive && self.isConnected;
                    },
                    set: function () {
                        // noop
                    }
                    /**
                     * @property
                     * @type {Boolean}
                     * @name Model.Sender#isAvailable
                     */
                },
                timeUpdated: {
                    enumerable: true,
                    configurable: true,
                    get: function () {
                        return timeUpdated;
                    },
                    set: function (val) {
                        if (!moment.isMoment(val)) {
                            val = moment(val);
                        }
                        timeUpdated = val;
                    }
                    /**
                     * @property
                     * @type {moment|null}
                     * @name Model.Sender#timeUpdated
                     */
                },
                meta: {
                    enumerable: true,
                    get: function () {
                        return this.getMetasJson();
                    }
                    /**
                     * @property
                     * @name Model.Sender#meta
                     * @type {Object}
                     */
                }
            }
        );

        $.each(Sender.TYPES, function(index, element) {
            var propName = 'is' + index.substr(0, 1) + index.toLowerCase().substr(1);
            if (self.hasOwnProperty(propName)) {
                return false;
            }
            Object.defineProperty(
                self,
                propName,
                {
                    get: function () {
                        return type === element;
                    }
                }
            );
        });

        var protecteds = Sender._pProto.constructor.call(this, uuid);

        /**
         * @function
         * @name Model.Sender#setAsExisting
         */
        this.setAsExisting = function() {
            protecteds.setAsNew(false);
        };
    };

    Object.extendProto(Sender, Model.RESTAccessByUUID);

    /**
     * @function
     * @name Model.Sender#updateByData
     * @param {object} data
     */
    Sender.prototype.updateByData = function updateByData(data) {
        Object.updateByData(this, data);

        if (data['meta']) {
            this.updateMetaByData(data['meta']);
            delete data['meta'];
        }

        Sender._pProto.updateByData.call(this);
    };

    /**
     * Gets the formdata-representation of the sender-object
     *
     * @function
     * @name Model.Sender#getFormData
     * @param {FormData=} formData
     * @returns FormData
     */
    Sender.prototype.getFormData = function getFormData(formData) {
        var fData = Sender._pProto.getFormData.call(this, formData),
            senderData = {};

        for (var key in this) {
            if (this.hasOwnProperty(key) && !(this[key] instanceof Function)) {
                if (key === KEY_TIME_UPDATED || key === KEY_UUID) {
                    continue;
                }
                senderData[key] = this[key];
            }
        }

        fData.append(KEY_SENDER, JSON.stringify(senderData));

        return fData;
    };

    /**
     * Creates a sender by data
     * @function
     * @static
     * @name Model.Sender.createByData
     * @param {string} type
     * @param {object} data
     * @returns Model.Sender
     */
    Sender.createByData = function createByData(type, data) {
        var typeClass,
            uuid,
            sender;

        if (!(typeClass = Sender.TYPES[type])) {
            throw 'Type not found: ' + type;
        }

        if (data && data.hasOwnProperty('uuid')) {
            uuid = data.uuid;
        }

        sender = Object.construct(typeClass, [uuid]);
        sender.updateByData(data);

        return sender;
    };

    Object.defineProperties(
        Sender,
        {
            INTEGRATION_STATUS_CONNECTED : {
                value : INTEGRATION_STATUS_CONNECTED
                /**
                 * @property
                 * @constant
                 * @name Model.Sender#INTEGRATION_STATUS_CONNECTED
                 * @type {String}
                 */
            },
            INTEGRATION_STATUS_PENDING : {
                value : INTEGRATION_STATUS_PENDING
                /**
                 * @property
                 * @constant
                 * @name Model.Sender#INTEGRATION_STATUS_PENDING
                 * @type {String}
                 */
            },
            STATUS_ACTIVE : {
                value : STATUS_ACTIVE
                /**
                 * @property
                 * @constant
                 * @name Model.Sender#STATUS_ACTIVE
                 * @type {String}
                 */
            }
        }
    );

    Sender.TYPES = {};

    ns.Sender = Sender;
})(Object.namespace('Model'));
