(function(ns){
    /**
     * @namespace
     * @alias Controller.Directive.sStickyHeader
     *
     * @param $element
     * @param $scope
     */
    var sStickyHeader = function ($element, $scope) {
        this.$deRegister    = [];
        this.$element       = $element;
        this.$scope         = $scope;

        /**
         * @property
         * @name Controller.Directive.sStickyHeader#header
         * @type {{element: String, from: String, extract: (Function)}|String}
         */
    };

    /**
     * @function
     * @name Controller.Directive.sStickyHeader#$onInit
     */
    sStickyHeader.prototype.$onInit = function $onInit() {
        var self = this;

        if (!this.header.element) {
            this.$deRegister = this.$deRegister.concat(this.$element.$on('scroll', this.handleScroll.bind(this)));
            return;
        }

        var $unWatch = this.$scope.$watch(function () {
                return $(self.header.element);
            },
            /**
             * @param {jQuery} val
             */
            function (val) {
                if (val.length) {
                    $unWatch();
                    self.$deRegister.concat(val.$on('scroll', self.handleScroll.bind(self)));
                }
            });
    };

    /**
     * @function
     * @name Controller.Directive.sStickyHeader#$onDestroy
     */
    sStickyHeader.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    sStickyHeader.prototype.handleScroll = function handleScroll() {
        if (!this.$header) {
            this.$header = $('<div class="sticky-header"></div>');
            this.$header.insertAfter(this.$element);
            this.$header.$on('click', this.handleHeaderClick.bind(this));
            this.$header.parent().css('position', 'relative');
        } else if (Math.ceil(this.$element[0].getBoundingClientRect().height) === this.$element[0].scrollHeight){
            this.$header.remove();
            this.$header = null;
        }

        if (!this.$header) {
            return;
        }

        if (this.header.extract) {
            return this.handleScrollExtract();
        }

        if (this.$usedHeader
            && this.$usedHeader.length
            && Math.round(parseFloat(this.$usedHeader.offset().top)) === Math.round(parseFloat(this.$header.offset().top))) {
            return;
        }

        this.$usedHeader = $(this.header).lastNotVisible(this.$element);
        if (!this.$usedHeader.length) {
            this.$header.remove();
            this.$header = null;
            return;
        }
        this.$header.html(this.$usedHeader.html());
    };

    sStickyHeader.prototype.handleScrollExtract = function handleScrollExtract() {
        var header = this.header.extract($(this.header.from).firstInView(this.$element));
        this.$header.html(header);
    };

    sStickyHeader.prototype.handleHeaderClick = function handleHeaderClick() {
        if (this.$usedHeader && this.$usedHeader.length) {
            var diff = this.$usedHeader.offset().top - this.$header.offset().top;
            this.$usedHeader.scrollParent().scrollTop(this.$usedHeader.scrollParent().scrollTop() + diff);
        }
    };
    ns.sStickyHeader = sStickyHeader;
})(Object.namespace('Controller.Directive'));