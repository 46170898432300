(function(ns) {
    /**
     * @namespace
     * @alias Model.User.RoleRelation
     * @constructor
     */
    var RoleRelation = function() {
        var role = null,
            user = null
        ;

        /**
         * @property
         * @name Model.User.RoleRelation#domainId
         */

        /**
         * @property
         * @name Model.User.RoleRelation#role
         * @type Model.User.Role
         */

        /**
         * @property
         * @name Model.User.RoleRelation#user
         * @type Model.User
         */

        Object.defineProperties(
            this,
            {
                domainId: {
                    writable    : true,
                    enumerable  : true
                },
                role: {
                    configurable: true,
                    get: function () {
                        return role;
                    },
                    set: function (val) {
                        if (val !== null) {
                            Object.instanceOf(val, Model.User.Role);
                        }
                        role = val;
                    }
                },
                user: {
                    configurable: true,
                    get: function () {
                        return user;
                    },
                    set: function (val) {
                        if (val !== null) {
                            Object.instanceOf(val, Model.User);
                        }
                        user = val;
                    }
                },
                status: {
                    writable    : true,
                    enumerable  : true
                }
                /**
                 * @property
                 * @name Model.User.RoleRelation#status
                 * @type ?string
                 */
            }
        );
    };

    /**
     * @function
     * @name Model.User.RoleRelation#toSimpleObject
     * @returns object
     */
    RoleRelation.prototype.toSimpleObject = function toSimpleObject() {
        return {
            domainId: this.domainId,
            roleUuid: (this.role ? this.role.uuid : null),
            userUuid: (this.user ? this.user.uuid : null)
        };
    };

    /**
     * @function
     * @static
     * @name Model.User.RoleRelation#create
     * @param {Model.User} user
     * @param {Model.User.Role} role
     * @param domainId
     * @param {?string} status
     * @returns Model.User.RoleRelation
     */
    RoleRelation.create = function create(user, role, domainId, status) {
        var roleRelation    = new RoleRelation();

        roleRelation.role       = role;
        roleRelation.user       = user;
        roleRelation.domainId   = domainId;
        roleRelation.status     = status;

        return roleRelation;
    };

    RoleRelation.STATUS_ACTIVE              = 'active';
    RoleRelation.STATUS_INACTIVE            = 'inactive';
    RoleRelation.STATUS_INVITATION_PENDING  = 'invitationPending';

    ns.RoleRelation = RoleRelation;
})(Object.namespace('Model.User'));