(function (ns) {
    /**
     * @namespace
     * @alias sInbox.Route.IncomingMessageBase
     * @extends sRoute
     * @constructor
     */
    var IncomingMessageBase = function IncomingMessageMatched() {
        this.controller     = sInbox.Controller.IncomingMessage;
        this.controllerAs   = '$unmatchedCtrl';
        this.templateUrl    = 'sinbox:inbox';
        this.reloadOnSearch = false;
        this.accessControl  = {'messageIncoming.all': Const.Method.GET};

        IncomingMessageBase._pProto.constructor.call(this);
    };

    Object.extendProto(IncomingMessageBase, sRoute);

    ns.IncomingMessageBase = IncomingMessageBase;
})(Object.namespace('sInbox.Route'));
