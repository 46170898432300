(function(ns) {
    /**
     * @namespace
     * @alias sContentFeed.Controller.Overview
     * @constructor
     *
     * @param $scope
     * @param $mdDialog
     * @param sConfirm
     * @param sDomainService
     * @param $location
     * @param Notification
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param $exceptionHandler
     */
    var Overview = function (
        $scope,
        $mdDialog,
        sConfirm,
        sDomainService,
        $location,
        Notification,
        sAPIAccess,
        $exceptionHandler
    ) {
        this.$scope             = $scope;
        this.$mdDialog          = $mdDialog;
        this.$location          = $location;
        this.notification       = Notification;
        this.sConfirm           = sConfirm;
        this.sAPIAccess         = sAPIAccess;
        this.$exceptionHandler  = $exceptionHandler;
        this.selectedRows       = [];

        this.list = new Model.List(
            sAPIAccess.endpoint('contentFeed.byDomain.all').get(sDomainService.currentDomainId)
        );

        this.list.mapFn = function(element) {
            return Model.ContentFeed.Entry.createByData(element);
        };

        this.list.pager.recordsPerPage = 100;

        var column = this.list.columns.createAndAdd('channelName');
        column.label = 'Segment';

        column = this.list.columns.createAndAdd('title');
        column.label = 'Title';

        column = this.list.columns.createAndAdd('timePublished');
        column.label = 'Published';

        this.list.actions = this.list.actions || {};
        if(sAPIAccess.isAllowed('contentFeed.setProcessed', Const.Method.PUT)) {
            this.list.actions.remove = new Model.Menu.Action('remove', this.openRemoveConfirmation.bind(this));
        }
        if(sAPIAccess.check({'contentFeed.byDomain.toConversation': [Const.Method.GET], 'message.RESTAccess': [Const.Method.POST]})) {
            this.list.actions.create = new Model.Menu.Action('create', this.openTemplateSelect.bind(this));
        }

        this.list.isMultipleAllowed = false;
        if (Object.keys(this.list.actions).length) {
            this.list.isMultipleAllowed = true;
            column = this.list.columns.createAndAdd('options');
            column.label = 'Options';
        }
    };

    /**
     * @function
     * @name sContentFeed.Controller.Overview#openTemplateSelect
     * @param {Model.ContentFeed.Entry[]} contentFeedEntries
     */
    Overview.prototype.openTemplateSelect = function openTemplateSelect(contentFeedEntries) {
        var self = this;

        this.$mdDialog.show({
            controller          : sContentFeed.Controller.TemplateSelect,
            controllerAs        : '$ctrl',
            templateUrl         : 'scontentfeed:template-select',
            parent              : angular.element(Const.PanelAnchor),
            clickOutsideToClose : true,
            locals: {
                selectedFeeds: contentFeedEntries
            }
        }).then(function (template) {
            var uuids = contentFeedEntries.map(function (entry) {
                return entry.uuid;
            });

            self.$location
                .path("/creator/new")
                .search({
                    source         : 'content-feed',
                    template       : template,
                    uuids          : uuids,
                    asConversation : true
                });
        }).catch(function (err) {
            if (err) {
                self.$exceptionHandler(err);
            }
        });
    };

    /**
     * @function
     * @name sContentFeed.Controller.Overview#openRemoveConfirmation
     * @param {Model.ContentFeed.Entry[]} contentFeedEntries
     * @return {$.Deferred}
     */
    Overview.prototype.openRemoveConfirmation = function openRemoveConfirmation(contentFeedEntries) {
        var self = this;

        return this.sConfirm.open({
            confirm: 'Remove',
            decline: 'Cancel',
            title: 'Remove selected feed ' + ((contentFeedEntries.length > 1) ? 'entries' : 'entry'),
            content: 'Do you really want to remove the selected feed ' + ((contentFeedEntries.length > 1) ? 'entries' : 'entry') + '?<br/> This action can not be undone.',
            ariaLabel: ''
        })
        .then(function() {
            return self.removeContentFeedEntries(contentFeedEntries);
        });
    };

    /**
     * @function
     * @name sContentFeed.Controller.Overview#removeContentFeedEntries
     * @param {Model.ContentFeed.Entry[]} contentFeedEntries
     * @return {$.Deferred}
     */
    Overview.prototype.removeContentFeedEntries = function removeContentFeedEntries(contentFeedEntries) {
        var self = this,
            promiseCollection = [],
            i;

        if (!contentFeedEntries instanceof Array) {
            return $.Deferred().reject();
        }

        this.$scope.$evalAsync(function() {
            self.isListReady = false;
        });

        for (i = 0; i < contentFeedEntries.length; i++) {
            promiseCollection.push(contentFeedEntries[i].setProcessed());
        }

        return $.when.apply($, promiseCollection).then(
            function() {
                self.notification.success("Removal successful.");
                self.$scope.$broadcast(sList.Component.Controller.sList.EVENT_CLEAR_SELECTION);
            },
            function() {
                self.notification.error("Error while removing.");
            }
        ).always(function() {
            self.$scope.$evalAsync(function() {
                self.isListReady = true;
            });
            self.list.load(false, true);
        });
    };

    ns.Overview = Overview;
})(Object.namespace('sContentFeed.Controller'));