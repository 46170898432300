(function(angular) {
    var module = angular.module('sInboxModule', ['sListModule']);

    module.config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/message/inbox', new sInbox.Route.Index())
            .when('/message/unmatched', new sInbox.Route.IncomingMessageUnmatched())
            .when('/message/matched', new sInbox.Route.IncomingMessageMatched())
        ;
    }]);

    module.config(['sTitleProvider', function (sTitleProvider) {
        sTitleProvider
            .when(
                '/message/matched',
                'Inbox',
                'Validate Matches'
            )
            .when(
                '/message/unmatched',
                'Inbox',
                'Review Unmatched'
            )
            .when(
                '/message/inbox',
                'Inbox',
                'Messages'
            )
        ;
    }]);

})(angular);

