(function(ns) {
    /**
     * @namespace
     * @alias Model.Menu.LocationAction
     * @extends Model.Menu.Action
     */
    var LocationAction = function(label, url, options) {
        var locationIsActiveCache = {},
            self = this
            ;

        Object.defineProperties(
            this,
            {
                url: {
                    get: function() {
                        return (url instanceof Function) ? url.call(self) : url;
                    }
                }
            }
        );

        LocationAction._pProto.constructor.call(
            this,
            label,
            this.changeLocation.bind(this),
            options
        );

        this.isActive = function isActive() {
            var key = window.location.toString();
            locationIsActiveCache[key] = locationIsActiveCache[key] || null;
            if (locationIsActiveCache[key] === null) {
                locationIsActiveCache[key] = Object.getPrototypeOf(this).isActive.call(self);
            }
            return locationIsActiveCache[key];
        }

    };

    Object.extendProto(LocationAction, ns.Action);

    LocationAction.prototype.changeLocation = function changeLocation() {
        window.location = this.url;
    };

    LocationAction.prototype.isActive = function isActive() {
        var regExp = new RegExp(
            this.url
                .replace('?', '\\?')
                .replace(new RegExp('(:[^:?/]*)'), '([^:?/]*)')
            + '([\/]?)([\?\&].*?)?$')
        ;
        return window.location.toString().search(regExp) !== -1;
    };

    LocationAction.TYPE_IDENT = 'LOCATION_ACTION';

    ns.Entry.TYPES.LOCATION_ACTION = LocationAction;
    ns.LocationAction = LocationAction;

})(Object.namespace('Model.Menu'));
