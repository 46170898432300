(function(ns) {
    /**
     * @namespace
     * @alias Model.Action.Definition.Collection
     * @constructor
     */
    var Collection = function () {
        var self        = this,
            definitions = [],
            itemsCache  = [],
            refreshItemsCache = function refreshItemsCache() {
                itemsCache.splice.apply(itemsCache, [0, itemsCache.length].concat(definitions));
            }
        ;

        Object.defineProperties(this, {
            /**
             * @property {Model.Action.Definition[]}
             * @name Model.Action.Definition.Collection#definitions
             */
            definitions: {
                enumerable: true,
                get: function () {
                    return itemsCache;
                }
            },
            /**
             * @property {int}
             * @name Model.Action.Definition.Collection#length
             */
            length : {
                get: function() {
                    return itemsCache.length;
                }
            }
        });

        /**
         * @function
         * @name Model.Action.Definition.Collection#add
         * @param {Model.Action.Definition} definition
         * @returns Model.Action.Definition.Collection
         */
        this.add = function add(definition) {
            var pos;
            if ((pos = self.getIndex(definition)) !== -1) {
                definitions.splice(pos, 1, definition);
            } else {
                definitions.push(definition);
            }

            refreshItemsCache();
            return this;
        };
    };

    /**
     * @function
     * @name Model.Action.Definition.Collection#findByName
     * @param {string} name
     * @returns {?Model.Action.Definition}
     */
    Collection.prototype.findByName = function findByName(name) {
        return this.definitions.find(function (definition) {
            return definition.name === name;
        }) || null;
    };

    /**
     * @function
     * @name Model.Action.Definition.Collection#getIndex
     * @param {Model.Action.Definition} definition
     * @returns int
     */
    Collection.prototype.getIndex = function getIndex(definition) {
        return this.definitions.indexOf(definition);
    };

    /**
     * @function
     * @name Model.Action.Definition.Collection#validateCombinables
     */
    Collection.prototype.validateCombinables = function validateCombinables() {
        for (var i = 0; i < this.definitions.length; i++) {
            this.definitions[i].validateCombinables();
        }
    };

    /**
     * @function
     * @static
     * @name Model.Action.Definition.Collection#create
     * @param {Array} data
     * @returns Model.Action.Definition.Collection
     */
    Collection.create = function create(data) {
        var lazyPopulation          = [],
            templateCreationQueue   = [],
            definitions             = new Collection(),
            i;

        if (data instanceof Array) {
            // first set basic data
            data.map(function(definitionData) {
                var templates = definitionData.templates || [];
                delete (definitionData.templates);
                var definition = Model.Action.Definition.create(definitionData);

                templateCreationQueue.push(function() {
                    templates.reduce(function(carry, templateData) {
                        var template = Model.Message.Template.createByData(templateData);
                        carry.templates.push(template);

                        return carry;
                    }, definition);
                });
                definitions.add(definition);

                if (!definitionData
                    || !definitionData.hasOwnProperty('combinables')
                    || !(definitionData.combinables instanceof Array)) {
                    return;
                }

                // wait until all definitions are created and link combinables
                lazyPopulation.push((function (definition, combinables) {
                    return function (collection) {
                        var combinable = null;

                        for (var i = 0; i < combinables.length; i++) {
                            if (combinable = collection.findByName(combinables[i])) {
                                definition.combinables.add(combinable);
                            }
                        }
                    };
                })(definition, definitionData.combinables));
            });

            // now all definitions are created and we can link combinables
            for (i = 0; i < lazyPopulation.length; i++) {
                lazyPopulation[i].call(this, definitions);
            }

            Model.Action.Definition.all = definitions;
            templateCreationQueue.map(function(executable){
                executable.call(this);
            });
        }

        return definitions;
    };

    ns.Collection = Collection;
})(Object.namespace('Model.Action.Definition'));
