(function(ns) {
    var UNICODE_SELECTION_START_PLACEHOLDER = Const.Unicode.SELECTION_START_PLACEHOLDER,
        UNICODE_SELECTION_END_PLACEHOLDER   = Const.Unicode.SELECTION_END_PLACEHOLDER,
        UNICODE_SPACE_CAP                   = Const.Unicode.SPACE_CAP,
        UNICODE_NON_BREAKING_SPACE          = Const.Unicode.NON_BREAKING_SPACE,
        UNICODE_SPACE                       = Const.Unicode.SPACE,
        SELECTION_MARKERS_REGEXP            = new RegExp(UNICODE_SELECTION_START_PLACEHOLDER + '|' + UNICODE_SELECTION_END_PLACEHOLDER, 'g')
    ;

    /**
     * @static
     * @private
     * @param $element
     * @param {Range=} range
     */
    var cleanNodes  = function cleanNodes($element, range) {
        var nodes,
            node,
            index
            ;

        nodes = $element.nodeByContent(UNICODE_SELECTION_START_PLACEHOLDER);
        if (nodes && nodes.length) {
            node = Array.prototype.pop.call(nodes);
            index = node.nodeValue.search(UNICODE_SELECTION_START_PLACEHOLDER);
            ContentEditableController.removeFromNodeValue(node, index);
            if (range) {
                ContentEditableController.addNodeToRange(range, 'start', node, index);
                range.adjusted = true;
            }
        }

        nodes = $element.nodeByContent(UNICODE_SELECTION_END_PLACEHOLDER)
        if (nodes && nodes.length) {
            node = Array.prototype.pop.call(nodes);
            index = node.nodeValue.search(UNICODE_SELECTION_END_PLACEHOLDER);
            ContentEditableController.removeFromNodeValue(node, index);
            if (range) {
                ContentEditableController.addNodeToRange(range, 'end', node, index);
            }
        }

        nodes = $element.nodeByContent(UNICODE_SPACE_CAP);
        if (nodes && nodes.length) {
            node = Array.prototype.pop.call(nodes);

            index = node.nodeValue.search(UNICODE_SPACE_CAP);
            if (index < node.nodeValue.length - 1) {
                ContentEditableController.removeFromNodeValue(node, index);
            }
        }
    };

    /**
     * @namespace
     * @alias Controller.Directive.ContentEditableController
     * @constructor
     *
     * @param $scope
     * @param $element
     * @param $attrs
     * @param $sce
     * @param $sanitize
     */
    var ContentEditableController = function($scope, $element, $attrs, $sce, $sanitize) {
        this.$scope         = $scope;
        this.$element       = $element;
        this.$attrs         = $attrs;
        this.$sce           = $sce;
        this.$sanitize      = $sanitize;
        this.$deRegister    = [];
    };

    /**
     * @function
     * @name Controller.Directive.ContentEditableController#$postLink
     */
    ContentEditableController.prototype.$postLink = function $postLink() {
        var self = this;

        this.$scope.$emit('on-before-init', this.$element);

        this.allowMultiline = !(typeof this.$attrs.allowMultiline !== 'undefined' && (this.$attrs.allowMultiline === 'false' || this.$attrs.allowMultiline === false));

        this.ngModelCtrl.$render = this.render.bind(this);

        this.$deRegister = this.$deRegister.concat(this.$element.$on("keydown", this.handleKeyDown.bind(this)));
        this.$deRegister = this.$deRegister.concat(this.$element.$on("keyup change", this.handleChange.bind(this)));
        this.$deRegister = this.$deRegister.concat(this.$element.$on("blur", this.handleFocusLost.bind(this)));
        this.$deRegister = this.$deRegister.concat(this.$element.$on("paste", this.handlePaste.bind(this)));

        this.ngModelCtrl.$parsers.unshift(function removeSpaces(valueFromInput) {
            return valueFromInput.replace(/\&nbsp\;/g, UNICODE_NON_BREAKING_SPACE).replace(/\&#x20;/g, UNICODE_SPACE);
        });

        this.ngModelCtrl.$parsers.unshift(function mergeAdjecentMarkers(valueFromInput) {
           return valueFromInput.replace(new RegExp(UNICODE_SELECTION_START_PLACEHOLDER+UNICODE_SELECTION_END_PLACEHOLDER, 'g'), UNICODE_SELECTION_START_PLACEHOLDER);
        });

        this.ngModelCtrl.$parsers.push(function parseLineBreaks(valueFromInput) {
            return valueFromInput.toString().replace(/\<br.*?\>/g, "\n").replace(/\n$/, '');
        });

        this.ngModelCtrl.$parsers.push(function unEscapeHtml(valueFromInput) {
            var $pseudoElement = angular.element('<div></div>');

            $pseudoElement.html(valueFromInput);
            valueFromInput = $pseudoElement.text();

            delete($pseudoElement);
            return valueFromInput;
        });

        if (!this.allowMultiline) {
            this.ngModelCtrl.$parsers.unshift(function removeLineBreaks(valueFromInput) {
                return valueFromInput.replace(/\n/g, ' ').replace(/\<br.*?\>(?!$)/g, ' ');
            });
        }

        this.ngModelCtrl.$formatters.push(function addSpaces(valueFromModel) {
            return valueFromModel.replace(new RegExp(UNICODE_NON_BREAKING_SPACE, 'g'), '\&nbsp\;').replace(new RegExp(UNICODE_SPACE, 'g'), '\&#x20\;');
        });

        this.ngModelCtrl.$formatters.push(function addLineBreaks(valueFromModel, partial) {
            var viewValue = valueFromModel.replace(/\n/g, '<br>');
            if (viewValue.replace(SELECTION_MARKERS_REGEXP, '').length && !partial) {
                viewValue += '<br>';
            }

            return viewValue;
        });

        this.ngModelCtrl.$formatters.push(function escapeHtml(valueFromModel) {
            var $pseudoElement = angular.element('<div></div>');

            $pseudoElement.text(valueFromModel);
            valueFromModel = $pseudoElement.html();

            delete($pseudoElement);
            return valueFromModel;
        });

        var $$runValidatorsAncestor = this.ngModelCtrl.$$runValidators;

        this.ngModelCtrl.$$runValidators = function(modelValue, viewValue, doneCallback) {
            var usedValues = [modelValue, viewValue].map(function(usedValue) {
                if (usedValue && usedValue.replace instanceof Function) {
                    return usedValue.replace(SELECTION_MARKERS_REGEXP, '');
                }
            });
            $$runValidatorsAncestor.apply(self.ngModelCtrl, usedValues.concat([doneCallback]));
        };

        // restore the $$runValidators to initial
        this.$scope.$on('$destroy', function() {
            self.ngModelCtrl.$$runValidators = $$runValidatorsAncestor;
        });
    };

    /**
     * @function
     * @name Controller.Directive.ContentEditableController#$onDestroy
     */
    ContentEditableController.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while(($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name Controller.Directive.ContentEditableController#read
     * @param {Boolean=} force
     */
    ContentEditableController.prototype.read = function read(force) {
        var $html       ,
            formatters  = this.ngModelCtrl.$formatters,
            idx         = formatters.length,
            sel         = window.getSelection(),
            viewValue   ,
            range       ,
            prevModel   = this.ngModelCtrl.$modelValue
        ;

        if (sel.rangeCount) {
            range = sel.getRangeAt(0);
            var rangeClone = range.cloneRange()
            ;

            // remove left over selection markers
            cleanNodes(this.$element);

            if (this.$element.find(range.startContainer).length || this.$element[0] === rangeClone.startContainer) {
                this.prevPHSNode = document.createTextNode(UNICODE_SELECTION_START_PLACEHOLDER);

                if (!range.collapsed) {
                    this.prevPHENode = document.createTextNode(UNICODE_SELECTION_END_PLACEHOLDER);
                    rangeClone.collapse(false);
                    rangeClone.insertNode(this.prevPHENode);
                }
                range.insertNode(this.prevPHSNode);
            }
        }

        $html = this.$element.html();

        if (!this.allowMultiline) {
            $html = $html.replace(/<br>(?!$)/g, ' ').replace(/\n/g, ' ');
        }

        // the view value has changed because the caret markers, so it needs to be committed in order to be in sync with angular
        this.ngModelCtrl.$setViewValue($html);

        if (prevModel === this.ngModelCtrl.$modelValue && !force) {
            cleanNodes(this.$element, range);
            return;
        }

        viewValue = this.ngModelCtrl.$modelValue || '';
        // TODO: IT-4526: this needs to go from 0 > formatters.length
        while (idx--) {
            viewValue = formatters[idx](viewValue);
        }

        if (!viewValue) {
            cleanNodes(this.$element, range);
            return;
        }

        if (viewValue !== $html) {
            // if the view value changes, the model value should be updated with it
            this.ngModelCtrl.$setViewValue(viewValue);
        }

        this.$element.html(viewValue);

        range = document.createRange();
        cleanNodes(this.$element, range);

        if (range.adjusted) {
            sel.removeAllRanges();
            sel.addRange(range);
        }
    };

    ContentEditableController.prototype.render = function render() {
        this.$element.html(this.ngModelCtrl.$viewValue || "");
    };

    /**
     * @param ev
     * @returns {boolean}
     */
    ContentEditableController.prototype.preventKeyEnter = function preventKeyEnter(ev) {
        return (!this.allowMultiline && ev.which === Const.EnterKey);
    };

    /**
     * @param ev
     */
    ContentEditableController.prototype.handleChange = function handleChange(ev) {
        var self = this
            ;

        if (Const.ArrowKeys.indexOf(ev.which) !== -1 || ev.which === 27) {
            return;
        }

        if (self.preventKeyEnter(ev)) {
            ev.preventDefault();
            return;
        }

        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(function () {
            self.read();
            digestIfNeeded(self.$scope);
            self.timeout = null;
        }, 10);
    };

    /**
     * @param ev
     */
    ContentEditableController.prototype.handleKeyDown = function handleKeyDown(ev) {
        if (ev.which === Const.TabKey) {
            ev.preventDefault();
        }

        // if its editInline prevent from line breaks
        if (this.preventKeyEnter(ev)) {
            ev.preventDefault();
            return;
        }

        if (ev.which === Const.EnterKey && !ev.shiftKey) {
            ev.preventDefault();
            ContentEditableController.insertNode($('<br>')[0]);
        }

        if (ev.which === Const.SpaceKey) {
            if (!ev.metaKey) {
                ev.preventDefault();
            }

            if(!ev.shiftKey) {
                ContentEditableController.insertNode(document.createTextNode(UNICODE_NON_BREAKING_SPACE));
            } else {
                ContentEditableController.insertNode(document.createTextNode(UNICODE_SPACE + UNICODE_SPACE_CAP));
            }
        }

        // IT-2462: backspace doesn't remove the element in some cases
        if (ev.which === Const.BACKSPACE) {
            var sel = window.getSelection(),
                anchor,
                prev
                ;
            if (!(anchor = sel.anchorNode)
                || anchor.nodeType !== Node.TEXT_NODE
                || anchor.data !== ''
                || !(prev = anchor.previousElementSibling)) {
                return;
            }

            var range = document.createRange();
            range.selectNode(prev);

            sel.removeAllRanges();
            sel.addRange(range);
        }
    };

    /**
     * Ensure that all paste is happening as plain text
     * @param event
     */
    ContentEditableController.prototype.handlePaste = function handlePaste(event) {
        event.preventDefault();

        var text
            ;

        if (event.originalEvent.clipboardData && event.originalEvent.clipboardData.getData) {
            text = this.$sanitize(event.originalEvent.clipboardData.getData("text/plain"));
            document.execCommand("insertHTML", false, text);
        } else if (window.clipboardData && window.clipboardData.getData) {
            text = this.$sanitize(window.clipboardData.getData("Text"));
            ContentEditableController.insertTextAtCursor(text);
        }
    };

    ContentEditableController.prototype.handleFocusLost = function handleFocusLost() {
        if (!this.ngModelCtrl.$$lastCommittedViewValue) {
            return;
        }

        var value = this.ngModelCtrl.$$lastCommittedViewValue.replace(SELECTION_MARKERS_REGEXP, '');
        this.ngModelCtrl.$setViewValue(value);
        this.ngModelCtrl.$validate();
    };

    /**
     * @param {Range} range
     * @param {String} type
     * @param {Node} node
     * @param {Number} pos
     */
    ContentEditableController.addNodeToRange = function addNodeToRange(range, type, node, pos) {
        if (pos <= node.nodeValue.length) {
            range['set' + type.ucFirst()].call(range, node, pos);
        } else if (node.nextSibling) {
            range['set' + type.ucFirst()].call(range, node.nextSibling, 1);
        } else {
            range['set' + type.ucFirst() + 'After'].call(range, node);
        }
    };

    /**
     * @param {Node} node
     * @param {Number} pos
     * @param {Number=} length
     */
    ContentEditableController.removeFromNodeValue = function(node, pos, length) {
        length = length || 1;
        var cleanupRange = document.createRange();
        try {
            cleanupRange.setStart(node, pos);
            cleanupRange.setEnd(node, pos + length);
            cleanupRange.deleteContents();
        } catch (e) {
        }
    };

    /**
     * @param {Node} node
     */
    ContentEditableController.insertNode = function insertNode(node) {
        var sel = window.getSelection(),
            range = sel.getRangeAt(0)
        ;

        range.insertNode(node);
        sel.removeAllRanges();

        range = document.createRange();

        range.selectNode(node);
        range.setEndAfter(node);
        range.setStartAfter(node);
        sel.addRange(range);
    };

    /**
     * @param {String} text
     */
    ContentEditableController.insertTextAtCursor = function insertTextAtCursor(text) {
        if (window.getSelection) {
            var sel = window.getSelection(),
                range
                ;

            if (sel.getRangeAt && sel.rangeCount) {
                range = sel.getRangeAt(0);
                range.deleteContents();
                range.insertNode(document.createTextNode(text));
            }
        } else if (document.selection && document.selection.createRange) {
            document.selection.createRange().text = text;
        }
    };

    Object.defineProperties(
        ContentEditableController,
        {
            UNICODE_SELECTION_START_PLACEHOLDER: {
                value: UNICODE_SELECTION_START_PLACEHOLDER
                /**
                 * @property
                 * @constant
                 * @name Controller.Directive.ContentEditableController#UNICODE_SELECTION_START_PLACEHOLDER
                 * @type {String}
                 */
            },
            UNICODE_SELECTION_END_PLACEHOLDER: {
                value: UNICODE_SELECTION_END_PLACEHOLDER
                /**
                 * @property
                 * @constant
                 * @name Controller.Directive.ContentEditableController#UNICODE_SELECTION_END_PLACEHOLDER
                 * @type {String}
                 */
            },
            UNICODE_SPACE_CAP: {
                value: UNICODE_SPACE_CAP
                /**
                 * @property
                 * @constant
                 * @name Controller.Directive.ContentEditableController#UNICODE_SPACE_CAP
                 * @type {String}
                 */
            },
            UNICODE_NBSP: {
                value: UNICODE_NON_BREAKING_SPACE
                /**
                 * @property
                 * @constant
                 * @name Controller.Directive.ContentEditableController#UNICODE_NBSP
                 * @type {String}
                 */
            },
            UNICODE_SPACE: {
                value: UNICODE_SPACE
                /**
                 * @property
                 * @constant
                 * @name Controller.Directive.ContentEditableController#UNICODE_SPACE
                 * @type {String}
                 */
            },
            SELECTION_MARKERS_REGEXP: {
                value: SELECTION_MARKERS_REGEXP
                /**
                 * @property
                 * @constant
                 * @name Controller.Directive.ContentEditableController#SELECTION_MARKERS_REGEXP
                 * @type {String}
                 */
            }
        });

    ns.ContentEditableController = ContentEditableController;
})(Object.namespace('Controller.Directive'));
