/**
 * Created by Gabor on 11-Jan-17.
 */

var ns = Object.namespace('Model'),
    Rule = ns.Rule = function(_type) {
        var type,
            alias,
            label
        ;

    /**
     * @name Model.AI.Rule#values
     * @type {Array}
     */
    this.values = [];

    Object.defineProperties(
        this,
        {
            type: {
                enumerable  : true,
                get         : function() {
                    return type;
                },
                set         : function(val) {
                    alias = Model.Rule.TYPES[val].alias;
                    label = Model.Rule.TYPES[val].label;
                    type = val;
                }
            },
            alias: {
                get: function () {
                    return alias;
                }
            },
            label: {
                get: function () {
                    return label;
                }
            }
        }
    );

        this.type = _type || Object.getFirstPropertyName(Model.Rule.TYPES);
};

Model.Rule.TYPES = {
    equals: {
        alias   : 'is',
        label   : 'equals',
        operator: '='
    },
    contains: {
        alias   : 'contains',
        label   : 'contains',
        operator: '*'
    },
    startsWith  : {
        alias    : 'startsWith',
        label    : 'starts with',
        operator : '^'
    },
    is_not       : {
        alias    : 'is_not',
        label    : 'Is not',
        operator : '!='
    },
    includes_not : {
        alias    : 'includes_not',
        label    : 'Does not contain',
        operator : '!*'
    }
};