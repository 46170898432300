(function(angular) {

    var module = angular.module('sSegmentModule');


    module
        /**
         * @ngdoc component
         * @name sSegmentConditionCollection
         * @restrict E
         *
         * @param {Model.Segment.Condition.Collection}    model
         */
        .component(
        'sSegmentConditionCollection', {
            templateUrl : 'ssegment/components:s-segment-condition-collection',
            controller  : sSegment.Component.Controller.sSegmentConditionCollection,
            bindings: {
                model             : '='
            }
        })
        /**
         * @ngdoc component
         * @name sSegmentPicker
         * @restrict E
         *
         * @param {Model.Segment=}    model
         * @param {Object}            labels
         * @param {Boolean}           isRequired
         */
        .component('sSegmentPicker', {
            templateUrl : 'ssegment/components:s-segment-picker',
            controller  : sSegment.Component.Controller.sSegmentPicker,
            bindings: {
                model      : '=',
                labels     : '<',
                isRequired : '<'
            }
        })
    ;
})(angular);
