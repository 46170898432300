(function(ns) {
    /**
     * @namespace
     * @alias Model.AI.Reaction
     * @param _id
     * @constructor
     */
    var Reaction = function (_id) {

            var isNew = true,
                /**
                 * @property
                 * @type Number
                 * @name Model.AI.Reaction#id
                 */
                id = _id,
                self = this,
                type
            ;

            /**
             * @property
             * @type String
             * @name Model.AI.Reaction#ident
             */

            /**
             * @property {String}
             * @name Model.AI.Reaction#domainId
             */
            this.domainId = null;

            /**
             * @property
             * @type String
             * @name Model.AI.Reaction#type
             */

            this.save = function save() {
                return Object.getPrototypeOf(this).save.call(this).then(function (response) {
                    isNew = false;
                    id = response.id;
                });
            };

            this.retrieve = function retrieve() {
                return Object.getPrototypeOf(this).retrieve.call(self).then(function () {
                    isNew = false;
                });
            };

            // TODO: remove ASAP, bad toxic
            this.setOld = function () {
                isNew = false;
            };

            /**
             * @property
             * @type Boolean
             * @name Model.AI.Reaction#isNew
             */
            Object.defineProperties(
                this,
                {
                    'isNew': {
                        get: function () {
                            return isNew;
                        }
                    },
                    'id': {
                        get: function () {
                            return id;
                        }
                    },
                    'type': {
                        get: function () {
                            return type;
                        },
                        set: function (val) {
                            if (val !== type) {
                                type = val;
                                this.value = '';
                            }
                        },
                        enumerable: true
                    }
                }
            );

            this.type = this.type || Reaction.TYPE_CONVERSATION;
        };

    Reaction.prototype.save = function save() {
        var url = Reaction.restEndPoint,
            method = Const.Method.POST,
            formdata = new FormData()
        ;

        if (!this.isNew) {
            url += this.id;
            method = Const.Method.PUT;
        }

        formdata.append('reaction', JSON.stringify(this));

        return $.ajax({
            url: url,
            data: formdata,
            method: method,
            context: self,
            processData: false,
            contentType: false
        });
    };

    Reaction.prototype.delete = function _delete() {
        if (this.isNew) {
            return $.Deferred().resolve();
        }

        var url = Reaction.restEndPoint + this.id,
            method = Const.Method.DELETE
        ;


        return $.ajax({
            url: url,
            method: method,
            data: {}
        });
    };

    Reaction.prototype.retrieve = function retrieve() {
        if (!this.id) {
            return null;
        }

    };

    /**
     * @param {String} url
     * @param {Array=} filters
     */
    Reaction.retrieve = function retrieve(url, filters) {
        return $.ajax({
            url: url,
            method: 'GET',
            data: filters
        }).then(function (data) {
            var reactions = [];
            for (var i = 0; i < data.length; i++) {
                var reaction = new Reaction(data[i].id);

                reactions.push(reaction);
                // TODO: remove this temporary fix, there should be an AJAX transport that makes retrieve believe that it is coming through ajax
                reaction.setOld();
                reaction.domainId = data[i].domainId;
                reaction.type = data[i].reactionType;
                reaction.ident = data[i].ident;
                reaction.value = data[i].reactionValue;
            }

            return reactions;
        });
    };

    Reaction.TYPE_CONVERSATION = 'message_uuid';
    Reaction.TYPE_TEXT = 'text';

    ns.Reaction = Reaction;
})(Object.namespace('Model.AI'));