(function (ns) {
    /**
     * @namespace
     * @alias Model.Delay
     *
     * @constructor
     */
    var Delay = function () {
        var type,
            value
            ;

        Object.defineProperties(
            this,
            {
                type: {
                    enumerable: true,
                    get: function() {
                        return type;
                    },
                    set: function(val) {
                        if (val === type) {
                            return;
                        }

                        if (val
                            && !Delay.getOptions().filter(function(element) {
                                return element.value === val;
                            }).length
                        ) {
                            return;
                        }

                        type = val;
                    }
                    /**
                     * @name Model.Delay#type
                     * @type {String}
                     */
                },
                value: {
                    enumerable: true,
                    get: function() {
                        return value;
                    },
                    set: function(val) {
                        if (val === value) {
                            return;
                        }

                        value = val;
                    }
                    /**
                     * @name Model.Delay#value
                     * @type {int}
                     */
                }
            }
        );
    };

    /**
     * @static
     * @name Model.Delay#getOptions
     * @returns {{value: string, label: string}[]}
     */
    Delay.getOptions = function getOptions() {
        return [
            {
                value: 'minutes',
                label: 'minute(s)'
            },
            {
                value: 'hours',
                label: 'hour(s)'
            },
            {
                value: 'days',
                label: 'day(s)'
            },
            {
                value: 'weeks',
                label: 'week(s)'
            }
        ];
    };

    /**
     * @param {Object} data
     */
    Delay.prototype.updateByData = function updateByData(data) {
        Object.updateByData(this, data);
    };

    /**
     * Creates a delay by data
     * @static
     * @function
     * @name Model.Delay.createByData
     * @param {object} data
     * @returns Model.Delay
     */
    Delay.createByData = function createByData(data) {
        var delay = new Delay();

        delay.updateByData(data);
        return delay;
    };

    ns.Delay = Delay;
})(Object.namespace('Model'));