(function (ns) {
    /**
     * @namespace
     * @alias sDomain.Service.sDomainLogo
     *
     * @param $rootScope
     * @param {Service.sDomain} sDomainService
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {Service.sFile} sFile
     */
    var sDomainLogo = function ($rootScope, sDomainService, sAPIAccess, sFile) {
        this.$rootScope     = $rootScope;
        this.sDomainService = sDomainService;
        this.sAPIAccess     = sAPIAccess;
        this.sFile          = sFile;
        this.loading        = true;
        this.hasLogo        = false;
        this.logoSquareURL  = null;
        this.logoURL        = null;
        this.currentDomain  = null;
        this.init($rootScope);

        /**
         * @type {String}
         * @property
         * @name sDomain.Service.sDomainLogo#logoSquareURL
         */

        /**
         * @type {String}
         * @property
         * @name sDomain.Service.sDomainLogo#logoURL
         */
    };

    /**
     * @function
     * @private
     * @name sDomain.Service.sDomainLogo#init
     * @param $rootScope
     */
    sDomainLogo.prototype.init = function init($rootScope) {
        var self       = this,
            deregister = $rootScope.$on(sAPIAccess.Service.sAPIAccess.EVENT_ENDPOINTS_LOADED, function () {
                self.load();
                deregister();
            });
    };

    /**
     * @function
     * @name sDomain.Service.sDomainLogo#load
     */
    sDomainLogo.prototype.load = function load() {
        this.loading = true;
        var self     = this;

        this.sDomainService.getCurrentDomain().then(function (currentDomain) {
            self.currentDomain = currentDomain;
            self.setDomainMetaEndpoint();

            Model.DomainMeta.getOrCreate(Model.DomainMeta.DOMAIN_LOGO_SQUARE_URL).then(function (meta) {
                self.loading = false;

                // This domain has no logo set
                if (meta.isNew || !meta.value) {
                    return;
                }

                self.logoSquareURL = meta.value;
                self.hasLogo       = true;
            });
        });
    };

    /**
     * Returns promise returning the URL of original logo file or null
     *
     * @function
     * @name sDomain.Service.sDomainLogo#getLogoURL
     * @return {$.Deferred}
     */
    sDomainLogo.prototype.getLogoURL = function getLogoURL() {
        if (!this.hasLogo) {
            return $.Deferred().resolve(null);
        }

        if (this.logoURL) {
            return $.Deferred().resolve(this.logoURL);
        }

        var self = this;
        this.setDomainMetaEndpoint();

        return Model.DomainMeta.getOrCreate(Model.DomainMeta.DOMAIN_LOGO_URL).then(function (meta) {
            self.logoURL = meta.value;
            return self.logoURL;
        });
    };

    /**
     * @function
     * @name sDomain.Service.sDomainLogo#setDomainMetaEndpoint
     */
    sDomainLogo.prototype.setDomainMetaEndpoint = function setDomainMetaEndpoint() {
        // set the endpoint for domain meta service
        Model.DomainMeta.endPoint = this.sAPIAccess.endpoint('domain.domainMeta.RESTAccess').post(this.currentDomain.id);
    };

    /**
     * @function
     * @name sDomain.Service.sDomainLogo#removeDomainLogo
     * @returns {$.Deferred}
     */
    sDomainLogo.prototype.removeDomainLogo = function removeDomainLogo() {
        var self = this;
        this.setDomainMetaEndpoint();

        return Model.DomainMeta.getOrCreate(Model.DomainMeta.DOMAIN_LOGO_SQUARE_URL).then(function (meta) {
            meta.value = '';

            return meta.save().then(function () {
                self.logoSquareURL = null;
                self.hasLogo       = false;
                self.$rootScope.$evalAsync();
            });
        });
    };

    /**
     * @function
     * @name sDomain.Service.sDomainLogo#updateDomainLogo
     * @param {Model.sImage} image
     * @returns {$.Deferred}
     */
    sDomainLogo.prototype.updateDomainLogo = function updateDomainLogo(image) {
        var self                = this,
            originalImageUpload = this.sFile.uploadFile(image),
            resizedImageUpload  = this.sFile.uploadFile(image.reSizedImage || image);
        ;

        return $.when(originalImageUpload, resizedImageUpload).then(
            function (originalImageResult, resizedImageResult) {
                var originalImageUrl = originalImageResult[0].url,
                    resizedImageUrl  = resizedImageResult[0].url
                ;

                self.setDomainMetaEndpoint();

                var resizedLogoMetaUpload = Model.DomainMeta.getOrCreate(Model.DomainMeta.DOMAIN_LOGO_SQUARE_URL).then(function (meta) {
                    meta.value = resizedImageUrl;
                    return meta.save();
                });

                var originalLogoMetaUpload = Model.DomainMeta.getOrCreate(Model.DomainMeta.DOMAIN_LOGO_URL).then(function (meta) {
                    meta.value = originalImageUrl;
                    return meta.save();
                });

                return $.when(originalLogoMetaUpload, resizedLogoMetaUpload).then(function () {
                    self.logoURL       = originalImageUrl;
                    self.logoSquareURL = resizedImageUrl;
                    self.hasLogo       = true;
                    self.$rootScope.$evalAsync();
                });
            }
        );
    };

    ns.sDomainLogo = sDomainLogo;
})(Object.namespace('sDomain.Service'));
