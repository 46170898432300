(function (ns) {
    /**
     * @namespace
     * @alias sInbox.Route.IncomingMessageMatched
     * @extends sRoute
     * @constructor
     */
    var IncomingMessageMatched = function IncomingMessageMatched() {
        IncomingMessageMatched._pProto.constructor.call(this);

        this.accessControl = {'messageIncoming.matched.all': Const.Method.GET};
    };

    Object.extendProto(IncomingMessageMatched, sInbox.Route.IncomingMessageBase);

    /**
     * @function
     * @name sInbox.Route.IncomingMessageMatched#resolveListUrl
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @returns PromiseLike
     */
    IncomingMessageMatched.prototype.resolveListUrl = function resolveListUrl(sAPIAccess) {
        return sAPIAccess.endpoint('messageIncoming.matched.all').get();
    };

    /**
     * @function
     * @name sInbox.Route.IncomingMessageMatched#resolveType
     * @returns String
     */
    IncomingMessageMatched.prototype.resolveType = function resolveType() {
        return sInbox.Controller.IncomingMessage.TYPE_MATCHED;
    };

    ns.IncomingMessageMatched = IncomingMessageMatched;
})(Object.namespace('sInbox.Route'));
