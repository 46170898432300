(function(ns) {
    /**
     * @namespace
     * @alias Model.RepairCase.MediaQRToHScroll
     *
     * @constructor
     * @extends Model.RepairCase
     */
    var MediaQRToHScroll = function () {
        var options = {
            confirm     : 'Ok do it',
            decline     : 'I’ll fix this myself',
            title       : 'Some messages are like WIFI cables … they link nowhere',
            content     : 'Unfortunately, Media with Buttons don’t work well with Quick Replies when they are directly after each other. What I can do is to transform the Media with Buttons into a Carousel Message with one bubble.',
            ariaLabel   : ''
        };

        MediaQRToHScroll._pProto.constructor.call(
            this,
            this.checkMediaFollowedByQR,
            this.convertMediaFollowedByQR,
            options
        );

        this.partsToConvert = [];
    };

    Object.extendProto(MediaQRToHScroll, Model.RepairCase);

    /**
     * @param {Model.Message.Collection} collection
     */
    MediaQRToHScroll.prototype.checkMediaFollowedByQR = function checkMediaFollowedByQR(collection) {
        this.partsToConvert = [];
        for (var i = 0; i < collection.messages.length; i++) {
            /**
             * @type {?Model.Message.Part}
             */
            var prevPart = null;
            for (var j in collection.messages[i].messageParts) {
                // if the previous part is media with buttons and this is a QR, we have a problem
                if (prevPart && prevPart.type === 'Media' && prevPart.getAllActions().length
                    && collection.messages[i].messageParts[j].type === 'QuickReplies') {
                    this.partsToConvert.push(prevPart);
                }
                prevPart = collection.messages[i].messageParts[j];
            }
        }

        if (this.partsToConvert.length){
            throw new Model.Exception.InvalidMessages(this.partsToConvert.map(
                /**
                 * @param {Model.Message.Part} part
                 */
                function(part) {
                    return part.parent;
            }));
        }
    };
    
    MediaQRToHScroll.prototype.convertMediaFollowedByQR = function convertMediaFollowedByQR() {
        this.partsToConvert.map(
            /**
             * @param {Model.Message.Part} part
             */
            function(part) {
                /** @type {Model.Message.Part} */
                var clonedPart = part.clone();

                clonedPart.type = 'HScroll';
                clonedPart.clearContents();

                part.messageContents.map(
                    /**
                     * @param {Model.Message.Content} content
                     */
                    function(content) {
                        /** @type {Model.Message.Content} */
                        var newContent = clonedPart.addEmptyContent().content;
                        newContent.updateByData(JSON.parse(JSON.stringify(content)));
                        if (content.media && !content.media.url) {
                            newContent.media = content.media.clone();
                        }
                });
                part.parent.addPart(clonedPart);
        });
    };

    ns.MediaQRToHScroll = MediaQRToHScroll;
})(Object.namespace('Model.RepairCase'));