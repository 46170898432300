(function (ns) {
    /**
     * @alias sTermsOfService.Service.sTermsOfService
     * @namespace
     * 
     * @param $mdDialog
     * @param $mdPanel
     * @param $exceptionHandler
     * @param sBugsnag
     * @param {Service.sProgressIndicator} sProgressIndicator
     * @param {Service.sConfirm} sConfirm
     * @constructor
     */
    var sTermsOfServiceService = function (
        $mdDialog,
        $mdPanel,
        $exceptionHandler,
        sBugsnag,
        sProgressIndicator,
        sConfirm
    ) {
        this.$mdDialog = $mdDialog;
        this.$mdPanel = $mdPanel;
        this.$exceptionHandler = $exceptionHandler;
        this.sBugsnag = sBugsnag;
        this.sProgressIndicator = sProgressIndicator;
        this.sConfirm = sConfirm;

        this.latestTOS = {
            "releaseDate": "2022-01-24T00:00:00Z",
            "version": "https://app.spectrm.io/misc/spectrm_terms_of_service_v20220124.pdf"
        }
        this.acceptedTOS = true;

        /**
        * Initial TOS
        * @function
        * @name sTermsOfService.Service.sTermsOfService#init
        * @returns {$.Deferred}
        */
        this.init = function init() {
            var self = this
            // Delay the init to prevent modal from closing
            setTimeout(function () {
                self.checkUserAcceptedTOS()
            }, 3750)
        };
    };

    /**
     * A quick solution to define API base URL for different environment
     * 
     * @function
     * @name sTermsOfService.Service.sTermsOfService#getAPIBaseURL
     */
    sTermsOfServiceService.prototype.getAPIBaseURL = function getAPIBaseURL() {
        // API Base URLs
        var apiUrl;
        const IS_STAGING = window.location.hostname.indexOf('staging') >= 0;
        const IS_PRODUCTION = (window.location.hostname.indexOf('.spectrm.de') >= 0 || window.location.hostname.indexOf('.spectrm.io') >= 0) && window.location.hostname.indexOf('dev.spectrm.de') === -1;
        const ENVIRONMENT = IS_STAGING ? 'staging' : IS_PRODUCTION ? 'production' : 'development';
        switch (ENVIRONMENT) {
            case 'staging':
                apiUrl = 'https://api.staging-spectrm.de';
                break;
            case 'production':
                apiUrl = 'https://api.spectrm.de';
                break;
            default:
                apiUrl = 'https://api.development-spectrm.de';
        }
        return apiUrl;
    }

    /**
     * @function
     * @name sTermsOfService.Service.sTermsOfService#checkUserAcceptedTOS
     */
    sTermsOfServiceService.prototype.checkUserAcceptedTOS = function checkUserAcceptedTOS() {
        var self = this;
        this.getUserAcceptedTOS().then(function (data) {
            self.acceptedTOS = data.lastAccepted || false;
            if (!self.acceptedTOS) {
                self.showTOSDialog();
            }
        })
    }

    /**
     * @function
     * @name sTermsOfService.Service.sTermsOfService#showTOSDialog
     * @return {PromiseLike<Boolean>}
     */
    sTermsOfServiceService.prototype.showTOSDialog = function showTOSDialog() {
        var self = this,
            deferred = $.Deferred()
            ;

        this.$mdDialog.show({
            controller: sTermsOfService.Controller.TermsOfServiceDialog,
            controllerAs: '$ctrl',
            bindToController: true,
            templateUrl: 'stermsofservice:terms-of-service-dialog',
            parent: angular.element(Const.PanelAnchor),
            clickOutsideToClose: false,
            escapeToClose: false
        })
            .then(deferred.resolve)
            .catch(function (err) {
                deferred.reject(err);
            });

        return deferred.promise();
    };

    /**
     * @function
     * @name sTermsOfService.Service.sTermsOfService#getLatestTOS
     */
    sTermsOfServiceService.prototype.getLatestTOS = function getLatestTOS() {
        return $.ajax({
            url: this.getAPIBaseURL() + '/v2/tos',
        }).then(function (data) {
            return data;
        })
    };

    /**
     * @function
     * @name sTermsOfService.Service.sTermsOfService#getUserAcceptedTOS
     */
    sTermsOfServiceService.prototype.getUserAcceptedTOS = function getUserAcceptedTOS() {
        return $.ajax({
            url: this.getAPIBaseURL() + '/v2/user/tos',
        }).then(function (data) {
            return data;
        })
    };

    /**
     * @function
     * @name sTermsOfService.Service.sTermsOfService#saveUserAcceptedTOS
     */
    sTermsOfServiceService.prototype.saveUserAcceptedTOS = function saveUserAcceptedTOS() {
        return $.ajax({
            url: this.getAPIBaseURL() + '/v2/user/tos',
            method: Const.Method.POST
        }).then(function (data) {
            return data;
        })
    };

    ns.sTermsOfService = sTermsOfServiceService;
})(Object.namespace('sTermsOfService.Service'));
