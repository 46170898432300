(function (ns) {
    /**
     * @namespace
     * @alias sSource.Controller.Component.sConditionCollection
     *
     * @constructor
     * @extends Controller.Component.sConditionCollection
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     */
    var sConditionCollection = function (sAPIAccess) {
        sConditionCollection._pProto.constructor.call(this);
        var definitions;

        this.itemName = 'Items';

        this.sAPIAccess = sAPIAccess;

        /**
         * @property
         * @name sSource.Controller.Component.sConditionCollection#source
         * @type {Model.Source}
         */

        /**
         * @param {Object.<String, SimpleChange>} $changes
         */
        this.$onChanges = function $onChanges($changes) {
            if ($changes.source) {
                definitions = null;

                if ($changes.source.currentValue instanceof Model.Source) {
                    var source = $changes.source.currentValue;
                    this.itemName = source.type.name;
                }
            }
        };

        /**
         * Override to proto function for private cache access
         * @return {PromiseLike<Model.Condition.Definition[]>}
         */
        this.getDefinitions = function getDefinitions() {
            if (!definitions) {
                definitions = sConditionCollection.prototype.getDefinitions.call(this);
            }

            return definitions;
        };

        /**
         * @property
         * @name sSource.Controller.Component.sConditionCollection#source
         * @type {Model.Source}
         */
    };

    Object.extendProto(sConditionCollection, Controller.Component.sConditionCollection);

    /**
     * @return {PromiseLike.<Model.Condition.Definition[]>}
     */
    sConditionCollection.prototype.getDefinitions = function () {
        return $.get(this.sAPIAccess.endpoint('source.condition').get(this.source.uuid)).then(function(results) {
            if (!(results instanceof Array)) {
                return [];
            }

            return results.map(function(element) {
                return new Model.Condition.Definition(
                    element.category,
                    element.label,
                    element.name,
                    element.operators,
                    element.values,
                    element.internal
               );
            });
        });
    };

    /**
     * @param {*} item
     * @return {Boolean}
     */
    sConditionCollection.prototype.isItemDisabled = function (item) {
        return false;
    };

    ns.sConditionCollection = sConditionCollection;
})(Object.namespace('sSource.Controller.Component'));
