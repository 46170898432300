(function (ns) {
    var MARKER_DEFAULT_NAME       = 'Marker',
        MARKER_GOAL_NAME          = 'Goal',
        EVENT_MARKER_MODEL_CHANGE = 'EVENT_MARKER_MODEL_CHANGE'
    ;

    /**
     * @namespace
     * @alias Model.Marker
     *
     * @constructor
     * @param {String=} uuid
     *
     * @extends Model.RESTAccessByUUID
     */
    var Marker = function (uuid) {
        Object.defineProperties(
            this,
            {
                name: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @name Model.Marker#name
                     * @type {String}
                     */
                }
            });

        Marker._pProto.constructor.call(this, uuid);

        // Overwrite parent save function to log the saving event
        this.save = function() {
            return Object.getPrototypeOf(this).save.apply(this, arguments).then(function(data) {
                $(document).trigger("eventMarkerSaved", data);
                return data;
            }.bind(this));
        };
    };

    Object.extendProto(Marker, Model.RESTAccessByUUID);

    /**
     * @param {Object} data
     */
    Marker.prototype.updateByData = function updateByData(data) {
        Object.updateByData(this, data);
        Marker._pProto.updateByData.call(this);
    };

    /**
     * @param formData
     * @param name
     * @return {*}
     */
    Marker.prototype.getFormData = function getFormData(formData, name) {
        var fData = Marker._pProto.getFormData.call(this, formData);

        if (name && name instanceof Object) {
            name['dataName'] = 'marker';
            name = JSON.stringify(name).bin2hex();
        }

        fData.append(name ? name : 'marker', JSON.stringify(this));

        return fData;
    };

    Marker.prototype.valueOf = function valueOf() {
        return this.uuid;
    };

    /**
     * @function
     * @name Model.Marker.createMarkerWithName
     * @param {String} name
     * @returns {Model.Marker}
     */
    Marker.createMarkerWithName = function createMarkerWithName(name) {
        var marker = new Marker();
        marker.name = name || MARKER_DEFAULT_NAME;
        return marker;
    };

    Object.defineProperties(
        Marker,
        {
            MARKER_DEFAULT_NAME : {
                value : MARKER_DEFAULT_NAME
                /**
                 * @property
                 * @constant
                 * @name Model.Marker#MARKER_DEFAULT_NAME
                 * @type {String}
                 */
            },
            MARKER_GOAL_NAME : {
                value : MARKER_GOAL_NAME
                /**
                 * @property
                 * @constant
                 * @name Model.Marker#MARKER_GOAL_NAME
                 * @type {String}
                 */
            },
            EVENT_MARKER_MODEL_CHANGE : {
                value : EVENT_MARKER_MODEL_CHANGE
                /**
                 * @property
                 * @constant
                 * @name Model.Marker#EVENT_MARKER_MODEL_CHANGE
                 * @type {String}
                 */
            }
        }
    );

    ns.Marker = Marker;
})(Object.namespace('Model'));
