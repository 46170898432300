(function(tinycolor) {
    tinycolor.defaults = {};

    tinycolor.toTinyRGB = function toTinyRGB(tc) {
        var c = tc.toRgb();
        return 'rgb(' + Math.round(c.r) + ', ' +
            Math.round(c.g) + ', ' + Math.round(c.b) + ')';
    };

    /**
     * @param {SVGElement} s
     * @param {String} c
     */
    tinycolor.fillSVGElementWithColor = function fill(s, c) {
        var tc = tinycolor(c);
        s.style({
            'fill': tinycolor.toTinyRGB(tc),
            'fill-opacity': tc.getAlpha()
        });
    };

    /**
     *
     * @param {String} front
     * @param {String} back
     * @returns {String}
     */
    tinycolor.combineColor = function combineColor(front, back) {
        var fc = tinycolor(front).toRgb();
        if(fc.a === 1) return tinycolor(front).toRgbString();

        var bc = tinycolor(back || tinycolor.defaults.background).toRgb(),
            bcFlat = bc.a === 1 ? bc : {
                r: 255 * (1 - bc.a) + bc.r * bc.a,
                g: 255 * (1 - bc.a) + bc.g * bc.a,
                b: 255 * (1 - bc.a) + bc.b * bc.a
            },
            fcFlat = {
                r: bcFlat.r * (1 - fc.a) + fc.r * fc.a,
                g: bcFlat.g * (1 - fc.a) + fc.g * fc.a,
                b: bcFlat.b * (1 - fc.a) + fc.b * fc.a
            };
        return tinycolor(fcFlat).toRgbString();
    };

    /**
     * @param {String} cStr
     * @param {Number} op
     * @returns {string}
     */
    tinycolor.addOpacityToColor = function addOpacityToColor(cStr, op) {
        var c = tinycolor(cStr).toRgb();
        return 'rgba(' + Math.round(c.r) + ', ' +
            Math.round(c.g) + ', ' + Math.round(c.b) + ', ' + op + ')';
    };

    /**
     * @param {String} cStr
     * @returns {number}
     */
    tinycolor.getOpacity = function getOpacity(cStr) {
        return cStr ? tinycolor(cStr).getAlpha() : 0;
    };

    /**
     * @param {String} color
     * @returns {String}
     */
    tinycolor.getMostReadableColor = function getMostReadableColor(color) {
        return tinycolor.mostReadable(color, Object.getOwnPropertyNames(tinycolor.names)).toHexString();
    };

    /**
     * @param {String} cStr
     * @param lightAmount
     * @param darkAmount
     * @returns {string}
     */
    tinycolor.getContrast = function getContrast(cStr, lightAmount, darkAmount) {
        var tc = tinycolor(cStr);

        if(tc.getAlpha() !== 1) tc = tinycolor(self.combineColor(cStr, tinycolor.defaults.background));

        var newColor = tc.isDark() ?
            (lightAmount ? tc.lighten(lightAmount) : tinycolor.defaults.background) :
            (darkAmount ? tc.darken(darkAmount) : tinycolor.defaults.defaultLine);

        return newColor.toString();
    };

})(window.tinycolor);