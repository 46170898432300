(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sDateRangePicker
     * @constructor
     *
     * @param $scope
     * @param $mdPanel
     * @param {Service.sDomain} sDomainService
     */
    var sDateRangePicker = function ($scope, $mdPanel, sDomainService) {
        var domainTimezone = sDomainService.currentDomain.domainData['timezone'] || moment.tz.guess();

        this.$mdPanel       = $mdPanel;
        this.$scope         = $scope;
        this.mdPanelRef     = null;
        this.timezoneMoment = moment.tz(domainTimezone);
    };

    /**
     * @function
     * @name Controller.Component.sDateRangePicker#$onInit
     */
    sDateRangePicker.prototype.$onInit = function $onInit() {
        this.rangeTemplates = this.rangeTemplates || this.getDefaultRangeTemplates(this.timezoneMoment.tz());
        this.keepTime       = this.keepTime !== false;

        if (this.model) {
            this.model.from && this.model.from.tz(this.timezoneMoment.tz());
            this.model.to && this.model.to.tz(this.timezoneMoment.tz());
        }
    };

    /**
     * @function
     * @name Controller.Component.sDateRangePicker#$onDestroy
     */
    sDateRangePicker.prototype.$onDestroy = function $onDestroy() {
        // if it is not closed + destroyed it will still be there after changed route
        if (this.mdPanelRef) {
            this.mdPanelRef.close();
            this.mdPanelRef.destroy();
        }
    };

    /**
     * @function
     * @name Controller.Component.sDateRangePicker#showDialog
     * @param {jQuery.Event} $event
     */
    sDateRangePicker.prototype.showDialog = function showDialog($event) {
        var elementClicked = $event.currentTarget,
            position = this.$mdPanel
                .newPanelPosition()
                .relativeTo(elementClicked)
                .addPanelPosition(
                    this.$mdPanel.xPosition.ALIGN_START,
                    this.$mdPanel.yPosition.BELOW
                )
            ;

        var panelAnimation = this.$mdPanel.newPanelAnimation()
            .openFrom(elementClicked)
            .duration(200)
            .closeTo(elementClicked)
            .withAnimation(this.$mdPanel.animation.SCALE);

        var config = {
            // TODO: whenever https://github.com/angular/material/pull/9379#pullrequestreview-65210421 is fixed uncomment this line
            //id                : 's-date-range-picker-dialog',
            attachTo            : angular.element(Const.PanelAnchor),
            controller          : function() {
            },
            bindToController    : true,
            locals              : {
                rangeTemplates  : this.rangeTemplates,
                keepTime        : this.keepTime,
                model           : this.model,
                setRange        : this.setRange.bind(this),
                closeDialog     : this.closeDialog.bind(this)
            },
            animation           : panelAnimation,
            controllerAs        : '$ctrl',
            templateUrl         : '_component:s-date-range-picker-dialog',
            hasBackdrop         : false,
            panelClass          : 's-date-range-picker-dialog-panel',
            position            : position,
            trapFocus           : true,
            scope               : this.$scope.$new(),
            zIndex              : 150,
            clickOutsideToClose : true,
            onCloseSuccess      : function(mdPanelRef) {
                // enforce destroy of inner scope, so all the components get freed properly
                if (!mdPanelRef) {
                    return;
                }
                mdPanelRef.destroy();
            },
            escapeToClose       : true,
            focusOnOpen         : true
        };

        this.mdPanelRef = this.$mdPanel.create(config);

        this.mdPanelRef.open();
    };

    /**
     * @function
     * @name Controller.Component.sDateRangePicker#setRange
     * @param {Model.DateRange} dateRange
     */
    sDateRangePicker.prototype.setRange = function setRange(dateRange) {
        var momentFrom,
            momentTo,
            timezone = this.timezoneMoment.tz()
        ;

        if (this.keepTime) {
            momentFrom = moment(this.model.from)
                .tz(timezone)
                .year(dateRange.from.year())
                .month(dateRange.from.month())
                .date(dateRange.from.date());
            momentTo = moment(this.model.to)
                .tz(timezone)
                .year(dateRange.to.year())
                .month(dateRange.to.month())
                .date(dateRange.to.date());
        } else {
            momentFrom = moment(dateRange.from).tz(timezone).startOf('day');
            momentTo = moment(dateRange.to).tz(timezone).endOf('day');
        }

        this.model = new Model.DateRange(momentFrom, momentTo);
        digestIfNeeded(this.$scope.$parent);
        this.closeDialog();
    };

    /**
     * @function
     * @name Controller.Component.sDateRangePicker#closeDialog
     */
    sDateRangePicker.prototype.closeDialog = function closeDialog() {
        this.mdPanelRef && this.mdPanelRef.close();
    };

    /**
     * @function
     * @name Controller.Component.sDateRangePicker#getDefaultRangeTemplates
     * @param {String} timezone
     */
    sDateRangePicker.prototype.getDefaultRangeTemplates = function getDefaultRangeTemplates(timezone) {
        return [
            {
                label: 'Last 7 days',
                dateRange: new Model.DateRange(
                    moment().tz(timezone).subtract(7, 'days'),
                    moment().tz(timezone).subtract(1, 'days')
                )
            },
            {
                label: 'Last month',
                dateRange: new Model.DateRange(
                    moment().tz(timezone).subtract(1, 'months').startOf('month'),
                    moment().tz(timezone).subtract(1, 'months').endOf('month')
                )
            },
            {
                label: 'Since beginning of this year',
                dateRange: new Model.DateRange(
                    moment().tz(timezone).startOf('year'),
                    moment().tz(timezone)
                )
            }
        ]
    };

    ns.sDateRangePicker = sDateRangePicker;
})(Object.namespace('Controller.Component'));
