(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sSenderCheckList
     */
    var SenderCheckList  = function () {};

    /**
     * @function
     * @name Controller.Component.sSenderCheckList#$onInit
     */
    SenderCheckList.prototype.$onInit = function $onInit() {
        this.senders = this.senders || [];
    };
    /**
     * @function
     * @name Controller.Component.sSenderCheckList#$onChanges
     * @param {object} $changes
     */
    SenderCheckList.prototype.$onChanges = function $onChanges($changes) {
        if ($changes.senders) {
            Object.instanceOf($changes.senders.currentValue, Array)
        }
    };

    /**
     * @function
     * @name Controller.Component.sSenderCheckList#isChecked
     * @param {Model.Sender} sender
     * @returns boolean
     */
    SenderCheckList.prototype.isChecked = function isChecked(sender) {
        return this.model.getIndex(sender) !== -1;
    };

    /**
     * @function
     * @name Controller.Component.sSenderCheckList#toggle
     * @param {Model.Sender} sender
     */
    SenderCheckList.prototype.toggle = function toggle(sender) {
        if(this.model.getIndex(sender) !== -1) {
            this.model.removeItem(sender);
        } else {
            this.model.addItem(sender);
        }
    };

    ns.sSenderCheckList = SenderCheckList;
})(Object.namespace('Controller.Component'));