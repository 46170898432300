(function (angular) {
    var module = angular.module('sSourceModule');
    module
        /**
         * @ngdoc directive
         * @name hintPlaceholder
         *
         * @restrict A
         * @param {sSource.Service.sSource} sSourceService
         * @requires autocompleter
         * @requires ngModel
         */
        .directive('hintPlaceholder', function () {
                return {
                    restrict: 'A',
                    bindToController: {
                        allowedTypes : '=hintPlaceholder'
                    },
                    controller: sSource.Directive.Controller.HintPlaceholder,
                    controllerAs: 'hintController',
                    require: {
                        autocompleterCtrl: 'autocompleter',
                        ngModelCtrl: 'ngModel'
                    }
                }
            })
})(angular);