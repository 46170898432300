(function(ns) {
    var Logout = ns.Logout = function () {
        sSecurity.Route.Logout._pProto.constructor.call(this);
    };

    Object.extendProto(sSecurity.Route.Logout, sRoute);

    /**
     * @function
     * @name sSecurity.Route.Logout#resolveLogout
     * @param {sSecurity.Service.sAuth} sAuth
     * @param {object} $window
     * @param {object} apiEndpoints
     * @returns {$.Deferred}
     */
    Logout.prototype.resolveLogout = function resolveLogout(sAuth, $window, apiEndpoints) {
        logout();
    };

})(Object.namespace('sSecurity.Route'));