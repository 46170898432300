(function(ns) {
    var MEDIA_AR_LANDSCAPE = 1.91,
        MEDIA_AR_SQUARE    = 1
    ;

    /**
     * @namespace
     * @alias Controller.Component.sMediaController
     *
     * @param $element
     * @param $scope
     */
    var sMediaController = function($element, $scope) {
        var model,
            tmp = this.model,
            isCropAble = false
        ;

        this.$deRegister = [];

        this.$element = $element;
        this.$scope = $scope;

        this.aspectRatios = [[
            {value: MEDIA_AR_LANDSCAPE, label: 'Landscape'},
            {value: MEDIA_AR_SQUARE, label: 'Square'},
        ]];

        Object.defineProperties(
            this,
            {
                model: {
                    configurable: false,
                    enumerable  : true,
                    get         : function() {
                        return model;
                    },
                    set         : function(val) {
                        if (val && !(val instanceof Model.sFile) || model === val) {
                            return;
                        }
                        model = val;
                    }
                },
                type: {
                    enumerable: true,
                    get: function () {
                        return self.model.file.type.split('/').shift();
                    }
                    /**
                     * @property
                     * @name Controller.Component.sMediaController#type
                     * @type {String}
                     */
                },
                isCropAble: {
                    enumerable: true,
                    get: function () {
                        return isCropAble;
                    }
                    /**
                     * @property
                     * @name Controller.Component.sMediaController#isCropAble
                     * @type {Boolean}
                     */
                }
            }
        );

        this.aspectRatio = MEDIA_AR_LANDSCAPE;

        this.$onInit = function $onInit() {
            this.$deRegister.push(this.$scope.$watch(
                function () {
                    return this.model;
                }.bind(this),
                function () {
                    if (!this.model || !(this.model instanceof Model.sImage) || this.model.isAnimated()) {
                        isCropAble = false;
                        this.doCrop = false;
                        return;
                    }

                    isCropAble = true;
                    if (this.model.reSizedImage) {
                        this.doCrop = true;
                        var cropOptions = this.model.reSizedImage.getMeta('crop'),
                            arCalc      = cropOptions.height ? cropOptions.width / cropOptions.height : MEDIA_AR_LANDSCAPE
                        ;

                        this.aspectRatio = this.aspectRatios.reduce(function (carry, element) {
                            carry = carry.concat(element);
                            return carry;
                        }, []).sort(function (a, b) {
                            return Math.abs(a.value - arCalc) - Math.abs(b.value - arCalc);
                        }).shift().value;
                    }
                }.bind(this)
            ))
        }

        /**
         * @property
         * @name Controller.Component.sMediaController#doCrop
         * @type {Boolean}
         */

        this.model = tmp;
    };

    sMediaController.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };


    /**
     * @name Controller.Component.sMediaController#handleUpload
     * @param {Model.sFile[]} files
     * @return {$.Deferred}
     */
    sMediaController.prototype.handleUpload = function handleUpload(files) {
        var self        = this,
            $deferred   = $.Deferred()
        ;

        if (!(files instanceof Array)) {
            $deferred.reject([]);
            return $deferred;
        }
        this.model = files.slice(0,1).pop();
        $deferred.resolve();

        digestIfNeeded(self.$scope, 1);

        return $deferred;
    };

    sMediaController.prototype.handleCropChange = function handleCropChange() {
        if (this.doCrop || !this.model || !this.model.reSizedImage) {
            return;
        }

        this.model.resetReSizedImage();
    }

    ns.sMediaController = sMediaController;

})(Object.namespace('Controller.Component'));
