(function (ns) {
    /**
     * @namespace
     * @alias sSource.Route.ContentOverview
     * @extends sRoute
     * @constructor
     */
    var ContentOverview = function ContentOverview() {
        this.controller     = sSource.Controller.ContentOverview;
        this.controllerAs   = '$ctrl';
        this.templateUrl    = 'ssource:content-overview';
        this.reloadOnSearch = false;
        this.accessControl  = {'source.RESTAccess': Const.Method.GET};

        ContentOverview._pProto.constructor.call(this);
    };

    Object.extendProto(ContentOverview, sRoute);

    /**
     * @function
     * @name sSource.Route.ContentOverview#resolveSource
     * @param $route
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @returns {$.Deferred}
     */
    ContentOverview.prototype.resolveSource = function resolveSource($route, sAPIAccess) {
        return Model.RESTAccessByUUID.load.call(Model.Source,
            sAPIAccess.endpoint('source.RESTAccess').get(),
            $route.current.params.uuid
        );
    };

    ns.ContentOverview = ContentOverview;
})(Object.namespace('sSource.Route'));
