/**
 * Created by florian on 28.12.16.
 */

var Model = Model || {};

/**
 * @type {Model.Configuration}
 */
Model.Configuration = function(configData) {
    var general = new Model.Configuration.General();

    if (configData.hasOwnProperty('general')) {
        general = new Model.Configuration.General(configData['general']);
    }

    Object.defineProperties(this, {
        /**
         * @property {Model.Configuration.General}
         * @name Model.Configuration#general
         */
        general: {
            enumerable: true,
            configurable: false,
            get: function () {
                return general;
            }
        }
    });
};

/**
 * @type {Model.Configuration.General}
 */
Model.Configuration.General = function(configGeneralData) {
    var self = this,
        general = {
            'isSameDomain': true,
        };

    Object.defineProperties(this, {
        /**
         * @property {Object}
         * @name Model.Configuration.General#isSameDomain
         */
        isSameDomain: {
            enumerable: true,
            configurable: false,
            get: function () {
                return general['isSameDomain'] ? true : false;
            }
        }
    });

    $.each(configGeneralData, function(key, value) {
        if (self.hasOwnProperty(key)) {
            general[key] = value;
        }
    });
};