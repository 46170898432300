(function(ns, $) {
    /**
     * @namespace
     * @alias Model.Member
     * @constructor
     *
     * @param _id
     */
    var Member = function (_id) {
        var id = _id,
            timeJoined,
            timeLastActive
        ;

        Object.defineProperties(
            this,
            {
                id: {
                    get: function () {
                        return id;
                    }
                    /**
                     * @property
                     * @name Model.Member#id
                     * @type {String}
                     */
                },
                firstName: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @name Model.Member#firstName
                     * @type {String}
                     */
                },
                lastName: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @name Model.Member#lastName
                     * @type {String}
                     */
                },
                fullName: {
                    get: function() {
                        var fn = '';
                        fn = [this.firstName, this.lastName].join(' ');

                        if (!fn.trim()) {
                            return 'unknown';
                        }

                        return fn;
                    }
                    /**
                     * @property
                     * @name Model.Member#fullName
                     * @type {String}
                     */
                },
                gender: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @name Model.Member#gender
                     * @type {String}
                     */
                },
                timezoneOffset: {
                    enumerable: true,
                    writable: true
                },
                avatar: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @name Model.Member#id
                     * @type {String}
                     */
                },
                status: {
                    enumerable: true,
                    writable: true
                },
                messenger: {
                    enumerable: true,
                    writable: true
                },
                locale: {
                    enumerable: true,
                    writable: true
                },
                source: {
                    enumerable: true,
                    writable: true
                },
                attributes: {
                    enumerable: true,
                    writable: true
                },
                timeJoined: {
                    enumerable: true,
                    get: function () {
                        return timeJoined * 1000;
                    },
                    set: function (val) {
                        if (timeJoined !== val) {
                            timeJoined = val;
                        }
                    }
                },
                timeLastActive: {
                    enumerable: true,
                    get: function () {
                        return timeLastActive * 1000;
                    },
                    set: function (val) {
                        if (timeLastActive !== val) {
                            timeLastActive = val;
                        }
                    }
                }
            }
        );


        this.updateFromJson = function (data) {
            var props = Object.getOwnPropertyNames(this);

            for (var i = 0; i < props.length; i++) {
                if (data[props[i]]) {
                    this[props[i]] = data[props[i]];
                }
            }

            return this;
        }
    };

    Member.load = function load(endPoint, id) {
        return $.ajax({
            url: endPoint + id,
            dataType: 'member'
        });
    };

    Member.fromJson = function (data) {
        var json = typeof(data) === 'string' ? JSON.parse(data) : data,
            member = new Member(json.id);
        return member.updateFromJson(json);
    };

    /**
     * Delete a member
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @returns {$.Deferred|Promise}
     */
    Member.prototype.delete = function (sAPIAccess) {
        if (!this.id) {
            return $.when(null);
        }
        var deleteEndpoint = sAPIAccess.endpoint('member.RESTAccess').delete(this.id);
        return $.ajax({
            url: deleteEndpoint,
            method: Const.Method.DELETE
        });
    };

    $.ajaxSetup({
        'converters': {
            'text member': Member.fromJson
        }
    });

    ns.Member = Member;
})(Object.namespace('Model'), $);
