(function (ns) {
    /**
     * @typedef {Object} tableData
     * @property {Array} header
     * @property {Array} values
     */

    /**
     * @namespace
     * @alias Controller.Component.STable
     *
     * @constructor
     * @param $scope
     * @param {Service.sProgressIndicator} sProgressIndicator
     * @param $element
     * @param $compile
     */
    var sTable = function ($scope, sProgressIndicator, $element, $compile) {
        this.$scope             = $scope;
        this.$deRegister        = [];
        this.$element           = $element;
        this.sProgressIndicator = sProgressIndicator;
        this.$element           = $element;
        this.$compile           = $compile;
        this.$renderDeRegisters = [];

        var resolvedData,
            size
        ;
        
        Object.defineProperties(
            this,
            {
                resolvedData: {
                    enumerable: true,
                    configurable: true,
                    get: function () {
                        return resolvedData;
                    },
                    set: function (val) {
                        if (val === resolvedData) {
                            return;
                        }
                        resolvedData = val;
                        var newSize =  (resolvedData.values.length + 1) + 'x' + resolvedData.header.length;

                        if (newSize !== size) {
                            this.render();
                            size = newSize;
                        }
                    }
                    /**
                     * @property
                     * @name Controller.Component.STable#resolvedData
                     * @type {tableData}
                     */
                }
            });

        /**
         * @property
         * @name Controller.Component.STable#fixedRows
         * @type {Number}
         */

        /**
         * @property
         * @name Controller.Component.STable#fixedColumns
         * @type {Number}
         */
    };
    
    sTable.prototype.$onInit = function $onInit() {
        var getPreviousSiblings = function getPreviousSiblings(el, filter) {
            var siblings = [];
            while (el = el.previousSibling) { if (!filter || filter(el)) siblings.push(el); }
            return siblings;
        };

        var self = this;
        this.$element
            .css('position', 'relative')
            .css('overflow', 'visible')
        ;

        this.$deRegister = this.$deRegister.concat($(window).$on('resize', function() {
            requestAnimationFrame(function() {
                digestIfNeeded(self.$scope)
            });
        }));

        // TODO: IT-3525
        // this.$deRegister = this.$deRegister.concat(this.$element.$on('copy', function(e) {
        //     e = e.originalEvent;
        //
        //     var selection   = window.getSelection(),
        //         range       = selection.getRangeAt(0),
        //         startCol    = getPreviousSiblings(range.startContainer.parentElement).length,
        //         startRow    = getPreviousSiblings(range.startContainer.parentElement.parentElement).length,
        //         endCol      = getPreviousSiblings(range.endContainer.parentElement).length,
        //         endRow      = getPreviousSiblings(range.endContainer.parentElement.parentElement).length,
        //         startType   = range.startContainer.parentElement.parentElement.parentElement.nodeName,
        //         endType     = range.endContainer.parentElement.parentElement.parentElement.nodeName
        //     ;
        //
        //     var dataSource  = self.$element.find('.scroll'),
        //         startNode   = dataSource[0]
        //             .querySelector(startType)
        //             .childNodes[startRow]
        //             .childNodes[startCol],
        //         endNode     = dataSource[0]
        //             .querySelector(endType)
        //             .childNodes[endRow]
        //             .childNodes[endCol],
        //         newRange = new Range()
        //     ;
        //
        //
        //     newRange.setStartBefore(startNode);
        //     newRange.setEndAfter(endNode);
        //
        //     var table   = $('<table></table>').append(newRange.cloneContents()),
        //         html    = table[0].outerHTML
        //     ;
        //
        //     e.clipboardData.setData('text/html', html);
        //     e.preventDefault();
        // }));
    };

    sTable.prototype.$onChanges = function $onChanges(changes) {
        var val,
            self = this
        ;

        if (changes.data) {
            if ((val = changes.data.currentValue)) {
                if (val.then) {
                    val.then(function (data) {
                        self.resolvedData = data;
                    });
                    self.sProgressIndicator.attachAndHandlePromise(self.$element, val);
                    return;
                }

                this.resolvedData = val;
            }
        }
    };

    sTable.prototype.render = function render() {
        var $deRegFn;
        while (($deRegFn = this.$renderDeRegisters.pop())) {
            $deRegFn();
        }

        this.$element.children().remove();
        
        var containers      = [],
            html            ,
            fixedColumns    = this.fixedColumns || 1,
            fixedRows       = this.fixedRows || 1,
            scrollContainer = angular.element('<div class="scroll"></div>'),
            self            = this
        ;

        containers.push(scrollContainer);

        scrollContainer
            .css('width', 'inherit')
            .css('height', 'inherit')
            .css('max-width', 'inherit')
            .css('max-height', 'inherit')
            .css('overflow', 'auto')
        ;

        html = '<table ng-cloak><thead><tr class="' + (fixedRows ? 'fixed' : '') + '">';
        html = this.resolvedData.header.reduce(function(carryHtml, element, col) {
            carryHtml += '<th class="' + (col < fixedColumns ? 'fixed' : '') + '" style="{{ $ctrl.getCellColor(' + 0 + ',' + col + ') }}">{{ $ctrl.resolvedData.header[' + col + '] }}</th>';
            return carryHtml;
        }, html);
        html += '</tr></thead>';

        html += '<tbody>';
        html = this.resolvedData.values.reduce(function(carryTBodyHtml, fields, row) {
            carryTBodyHtml += ('<tr class="' + (fixedRows > (row + 1) ? 'fixed' : '') + '">' + fields.reduce(function(carryRowHtml, field, col) {
                carryRowHtml += '<td class="' + (col < fixedColumns ? 'fixed' : '');
                if (field && field.cssClass) {
                    carryRowHtml += ' {{ $ctrl.resolvedData.values[' + row + '][' + col + '].cssClass }}';
                }

                carryRowHtml += '" style="{{ $ctrl.getCellColor(' + (row + 1) + ',' + col + ') }}" ';
                if (field && field.colSpan) {
                    carryRowHtml += ' colspan="{{ $ctrl.resolvedData.values[' + row + '][' + col + '].colSpan }}"';
                }

                if (field && field.rowSpan) {
                    carryRowHtml += ' rowspan="{{ $ctrl.resolvedData.values[' + row + '][' + col + '].rowSpan }}"';
                }
                carryRowHtml += '>';

                if (field && field.tooltip) {
                    carryRowHtml += '<md-tooltip md-direction="bottom">' + field.tooltip + '</md-tooltip>'
                }
                carryRowHtml += '<span ng-bind-html="$ctrl.resolvedData.values[' + row + '][' + col + ']">';
                carryRowHtml += '</td>';
                return carryRowHtml;
            }, '') + '</tr>');
            return carryTBodyHtml;
        }, html);
        html += '</tbody></table>';

        var containerHFixed     = angular.element('<div class="h-fixed"></div>'),
            containerVFixed     = angular.element('<div class="v-fixed"></div>'),
            containerVHFixed    = angular.element('<div class="v-h-fixed"></div>')
        ;

        containers.push(containerHFixed, containerVFixed, containerVHFixed);

        containers.map(function(container, index) {
            container.append($(html));
            var linkFn  = self.$compile(container);
            container = linkFn(self.$scope);
            if (index === 0) {
                scrollContainer = container;
            } else {
                container.css('overflow', 'hidden');
                container.css('position', 'absolute');
            }
            self.$element.prepend(container);
        });

        this.$renderDeRegisters = this.$renderDeRegisters.concat(scrollContainer.$on('scroll', function() {
            self.handleScrolling(scrollContainer, containerHFixed, containerVFixed);
        }));

        this.$renderDeRegisters.push(this.$scope.$watch(function() {
                return JSON.stringify({width: scrollContainer[0].clientWidth, height: scrollContainer[0].clientHeight});
            },
            /**
             * @param {String} val
             */
            function(val) {
                var metrics = JSON.parse(val);
                containers.map(function(container, index) {
                    if (index === 0) {
                        return;
                    }
                    for (var i in metrics) {
                        container.css(i, metrics[i]);
                    }
                })
            }
        ));
    };

    sTable.prototype.handleScrolling = function handleScrolling(scrollContainer, containerHFixed, containerVFixed) {
        // Sync header tables with the actually scrollable table
        containerHFixed.scrollTop(scrollContainer.scrollTop());
        containerVFixed.scrollLeft(scrollContainer.scrollLeft());
    };

    sTable.prototype.$onDestroy = function $onDestroy() {
        this.$deRegister = this.$deRegister.concat(this.$renderDeRegisters);
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    /**
     * @name Controller.Component.sTable#getCellColor
     * @param {Number} row
     * @param {Number} col
     * @returns {string}
     */
    sTable.prototype.getCellColor = function getCellColor(row, col) {
        var fragments = [];
        if (this.resolvedData.bgColors && this.resolvedData.bgColors[row] && this.resolvedData.bgColors[row][col]) {
            fragments.push('background-color: ' + this.resolvedData.bgColors[row][col]);
        }

        if (this.resolvedData.colors && this.resolvedData.colors[row] && this.resolvedData.colors[row][col]) {
            fragments.push('color: ' + this.resolvedData.colors[row][col]);
        }

        return fragments.join(';');
    };

    ns.sTable = sTable;
})(Object.namespace('Controller.Component'));
