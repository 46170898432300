(function(ns) {
    /**
     * @namespace
     * @alias Model.Filter
     * @constructor
     *
     * @param {string} name
     * @param {*} [value]
     * @param {boolean} [hidden]
     */
    var Filter = function(name, value, hidden) {
        var asJson,
            asArray
        ;

        if (!name) {
            throw 'Missing arguments!';
        }

        /**
         * @name Model.Filter#name
         * @property
         */
        this.name   = name;

        /**
         * @name Model.Filter#value
         * @property
         */
        this.value  = value;

        /**
         * @name Model.Filter#isHidden
         * @property
         */
        this.isHidden = hidden || false;

        Object.defineProperties(
            this,
            {
                asJson: {
                    get: function() {
                        return asJson;
                    },
                    set: function(val) {
                        if (!val) {
                            asJson = null;
                            return;
                        }

                        Object.instanceOf(val, Function);

                        asJson = val;
                        asArray = false;
                    }
                    /**
                     * To interpret a value as(from) JSON a reviver needs to be specified
                     * @name Model.Filter#asJson
                     * @property
                     * @type {?Function}
                     */
                },
                asArray: {
                    get : function() {
                        return asArray;
                    },
                    set: function(val) {
                        asArray = Boolean(val);
                        if (asArray) {
                            asJson = null;
                        }
                    }
                    /**
                     * @name Model.Filter#asArray
                     * @property
                     * @type {Boolean}
                     */
                }
            });

        this.__getCloneArguments = function() {
            return [this.name, this.value, this.isHidden];
        };

        this.__extendClone = function __extendClone(original) {
            if (original.asJson) {
                this.asJson = original.asJson;
            }
        }
    };

    ns.Filter = Filter;
})(Object.namespace('Model'));


