(function (ns) {
    /**
     * This is a helper-class for the route-mapping-object
     * @see https://code.angularjs.org/1.7.8/docs/api/ngRoute/provider/$routeProvider#when
     *
     * @namespace
     * @alias sRoute
     * @constructor
     */
    var sRoute = function sRoute() {
        var self = this;

        Object.defineProperty(
            this,
            'resolve', {
                configurable: false,
                enumerable: true,
                get: function () {
                    var methods = {},
                        def = new $.Deferred(),
                        promise = def.promise()
                    ;

                    for (var i in self) {
                        // search for all resolve-prefixed functions and map it to a resolve-object
                        if (i.search(/^resolve(.+)$/) !== -1 && typeof(self[i]) === 'function') {
                            var match = RegExp.$1.toString();
                            if (match === 'RedirectTo') {
                                continue;
                            }

                            // property-name equals the function name without the resolve-prefix and
                            // property-value is the resolve function but wrapped in a higher level function
                            // so we can check the access upfront before resolving the resolves
                            methods[match.charAt(0).toLowerCase() + match.slice(1)] = (function (resolveFn) {
                                return function ($injector) {
                                    return promise.then(function () {
                                        return $injector.invoke(resolveFn, null, null);
                                    });
                                }
                            })(self[i])
                        }
                    }

                    // add the access-checking resolve-function which needs to be resolved before all
                    // other resolve-functions get resolved
                    methods['$access'] = function (sAPIAccess) {
                        if (!self.accessControl
                            || (typeof self.accessControl === 'function' && self.accessControl(sAPIAccess))
                            || sAPIAccess.check(self.accessControl)
                        ) {
                            return def.resolve();
                        }

                        return def.reject(new sRouteRejection('We are sorry, but it seems you don\'t have the necessary permissions to access this page. If that should not be the case, please contact your domain administrator.', {}));
                    };

                    return methods;
                }
            }
        );
    };

    ns.sRoute = sRoute;
})(Object.namespace(''));
