(function (ns) {
    var TemplateTexts = {};

    Object.defineProperties(
        TemplateTexts,
        {
            notification : {
                get : function () {
                    return {
                        'activatedAITemplateCount' : 'Sorry, you reached your limit of activated AI-Templates. <a product-module-info-dialog>Upgrade your plan</a> to unlock more.',
                        'activatedEntryPointCount' : 'Sorry, you reached your limit of activated Entry-Points. <a product-module-info-dialog>Upgrade your plan</a> to unlock more.',
                        'defaultLimitReached'      : 'Forbidden due to resource restrictions. <a product-module-info-dialog>Upgrade your plan</a> to unlock more.'
                    };
                }
                /**
                 * @property
                 * @constant
                 * @name Const#notification
                 * @type {Object}
                 */
            }
        }
    );

    ns.TemplateTexts = TemplateTexts;
})(Object.namespace(''));
