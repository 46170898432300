(function(ns) {
    var KEY_NAV_LEFT = 'nav-left',
        TEMPLATE_URL = 'snavigation:subnavigation'
        ;

    /**
     * @namespace
     * @alias sNavigation.Controller.Component.sNavigation
     *
     * @param sNavigationMenu
     * @param sAppCookies
     * @param $mdSidenav
     * @param $rootScope
     * @param $scope
     * @param $route
     * @param $location
     */
    var sNavigation = function(sNavigationMenu, sAppCookies, $mdSidenav, $rootScope, $scope, $route, $location) {
        this.menu           = sNavigationMenu;
        this.sAppCookies    = sAppCookies;
        this.$mdSidenav     = $mdSidenav;
        this.$rootScope     = $rootScope;
        this.$scope         = $scope;
        this.$route         = $route;
        this.$location      = $location;
    };

    sNavigation.prototype.$onInit = function $onInit() {
        this.collapsed = this.sAppCookies.get(sNavigation.COOKIE_KEY);
        this.$rootScope.navigationCollapsed = this.collapsed;

        this.$rootScope.$on('$locationChangeStart', this.closeSideNav.bind(this));
    };

    sNavigation.prototype.toggleMenu = function toggleMenu() {
        this.collapsed = !this.collapsed;
        this.$rootScope.navigationCollapsed = this.collapsed;
        this.closeSideNav();

        this.sAppCookies.put(sNavigation.COOKIE_KEY, this.collapsed);
    };

    /**
     * @param {Model.Menu.Entry} item
     * @param {Boolean=} clickSubMenu
     */
    sNavigation.prototype.clickMainItem = function clickMainItem(item, clickSubMenu) {
        if (item instanceof Model.Menu.LocationAction) {
            if (this.$mdSidenav(KEY_NAV_LEFT).isOpen()) {
                this.$mdSidenav(KEY_NAV_LEFT).close();
            }

            if (!item.options.forceReload) {
                return item.action();
            } else {
                var oldPath = this.$location.path();
                this.$location.url(this.$location.path(item.url).path());

                if (oldPath === this.$location.path()) {
                    this.$route.reload();
                }

                return;
            }
        }

        if (item instanceof Model.Menu.Menu) {
            if (clickSubMenu) {
                return item.getElementsOfType(Model.Menu.Action).slice(0,1).pop().action();
            }

            this.subMenu = new Model.Menu.Menu();
            this.subMenu.merge(item);
        }

        if (!this.collapsed) {
            return;
        }

        this.lastItem = item;
        this.$mdSidenav(KEY_NAV_LEFT).$rScope = this.$scope;
        this.$mdSidenav(KEY_NAV_LEFT).templateUrl = TEMPLATE_URL;

        if (!this.$mdSidenav(KEY_NAV_LEFT).isOpen()) {
            this.$mdSidenav(KEY_NAV_LEFT).open();
        }
    };

    sNavigation.prototype.closeSideNav = function closeSideNav() {
        if (this.$mdSidenav(KEY_NAV_LEFT).isOpen()) {
            this.$mdSidenav(KEY_NAV_LEFT).close();
        }
    };

    sNavigation.COOKIE_KEY  = 'sNavigationCollapsed';
    Const.SideNavLeft       = KEY_NAV_LEFT;

    ns.sNavigation = sNavigation;
})(Object.namespace('sNavigation.Controller.Component'));
