(function (ns) {
    var REFRESH_SORT_CHOICES_EVENT = 'RefreshSortChoicesEvent';

    /**
     * @namespace
     * @alias sSource.Controller.Component.sSourceSortColumnPicker
     *
     * @constructor
     * @param {sSource.Service.sSourceService} sSource
     * @param $scope
     */
    var sSourceSortColumnPicker = function (sSource, $scope) {
        this.disabled = false;
        this.sSource  = sSource;
        this.$scope   = $scope;

        Object.defineProperties(
            this,
            {
                REFRESH_SORT_CHOICES_EVENT: {
                    value: REFRESH_SORT_CHOICES_EVENT
                    /**
                     * @property
                     * @constant
                     * @name #REFRESH_SORT_CHOICES_EVENT
                     * @type {String}
                     */
                }
            });
    };
    
    /**
     * @param {Object.<String, SimpleChange>} $changes
     */
    sSourceSortColumnPicker.prototype.$onChanges = function $onChanges($changes) {
        if ($changes.source) {
            this.model = null;
            if (!$changes.source.currentValue) {
                this.disabled = true;
            } else if (this.disabled) {
                this.disabled = false;
                this.$scope.$broadcast(REFRESH_SORT_CHOICES_EVENT);
            }
        }
    };

    /**
     * @return {$.Deferred<String[]>}
     */
    sSourceSortColumnPicker.prototype.getChoices = function getChoices() {
        var self = this;
        return this.sSource.getAvailableSourceFieldsAsAttributes().then(function (data) {
            if (!(data instanceof Array)) {
                return [];
            }
            return data
                .filter(function(attribute) {
                    return attribute.sourceNames.indexOf(self.source.label) !== -1;
                })
                .map(function(attribute) {
                    return attribute.name;
                });
        });
    };

    ns.sSourceSortColumnPicker = sSourceSortColumnPicker;
})(Object.namespace('sSource.Controller.Component'));
