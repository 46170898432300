(function (ns) {
    /**
     * @namespace
     * @alias sSettings.Route.PlanManagement
     * @extends sSettings.Route.Base
     * @constructor
     */
    var PlanManagement = function PlanManagement() {
        this.controller     = sFeatureManagement.Controller.Plan;
        this.templateUrl    = 'sfeaturemanagement:plan-admin';
        this.controllerAs   = '$ctrl';
        this.accessControl  = {'plan.all': Const.Method.GET};

        PlanManagement._pProto.constructor.call(this);
    };

    Object.extendProto(PlanManagement, ns.Base);

    /**
     * @name sSettings.Route.PlanManagement#resolveFeatures
     * @return {$.Deferred}
     */
    PlanManagement.prototype.resolveFeatures = function resolveFeatures() {
        return Model.Feature.query();
    };

    /**
     * @name sSettings.Route.PlanManagement#resolvePlans
     * @return {$.Deferred}
     */
    PlanManagement.prototype.resolvePlans = function resolvePlans() {
        return Model.Plan.query();
    };

    ns.PlanManagement = PlanManagement;
})(Object.namespace('sSettings.Route'));