(function (ns) {
    /**
     * @namespace
     * @alias sSegment.Component.Controller.sSegmentConditionCollection
     *
     * @param {sSegment.Service.sConditionDefinition} sSegmentConditionDefinition
     * @extends Controller.Component.sConditionCollection
     * @constructor
     */
    var sSegmentConditionCollection = function (sSegmentConditionDefinition) {
        this.itemName                       = 'Users';
        this.sortDefinitions                = sSegmentConditionCollection.prototype.sortDefinitions.bind(this);
        this.sSegmentConditionDefinition    = sSegmentConditionDefinition;
    };

    Object.extendProto(sSegmentConditionCollection, Controller.Component.sConditionCollection);

    /**
     * @return {Model.Condition.Definition[]}
     */
    sSegmentConditionCollection.prototype.getDefinitions = function getDefinitions() {
        return this.sSegmentConditionDefinition.init().then(function (definitions) {
            return definitions.filter(function (definition) {
                return !definition.internal;
            });
        });
    };

    /**
     * @return {string[]}
     */
    sSegmentConditionCollection.prototype.getCategoriesOrder = function getCategoriesOrder() {
        return ['user data', 'segments', 'activity'];
    };

    /**
     * @function
     * @name sSegment.Component.Controller.sSegmentConditionCollection#$onInit
     */
    sSegmentConditionCollection.prototype.$onInit = function $onInit() {
        if (!this.model) {
            this.model = new Model.Segment.Condition.Collection();
        }
    };


    /**
     * @param {Model.Condition.Definition} item
     */
    sSegmentConditionCollection.prototype.isItemDisabled = function isItemDisabled(item) {
        if (item.name === 'attribute_label') {
            item.disabledHint = Controller.Component.sMemberAttributeSelect.HINT_USER_LABEL_DEPRECATED;
            return true;
        }
        return false;
    };

    ns.sSegmentConditionCollection = sSegmentConditionCollection;
})(Object.namespace('sSegment.Component.Controller'));
