(function (ns) {
    /**
     * @namespace
     * @alias Controller.sMessageAdmin.FacebookSendController
     * @extends Controller.sMessageAdmin.SendController
     * @param $scope
     * @param $mdDialog
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {Service.sMessageCollection} sMessageCollection
     * @param {Service.sNotificationLabel} sNotificationLabel
     *
     * @constructor
     */
    var FacebookSendController = function ($scope, $mdDialog, sAPIAccess, sMessageCollection, sNotificationLabel) {
        // the return value contains protected props and methods
        var protecteds = FacebookSendController._pProto.constructor.call(this, $scope, $mdDialog);

        this.sendAsSponsoredDisabled           = true;
        this.sendAsOneTimeNotificationDisabled = true;

        this.sendAsSponsoredData     = {
            campaignEnd: moment()
        };

        this.sAPIAccess         = sAPIAccess;
        this.sMessageCollection = sMessageCollection;
        this.sNotificationLabel = sNotificationLabel;

        this.sendAsSponsoredDisabledReasons           = [];
        this.sendAsOneTimeNotificationDisabledReasons = [];

        this.sponsoredConfig = {
            facebookPageSenderId: {
                component: 'sFacebookPageSelect',
                    options: {
                    attrs: {
                        'ng-required'   : true
                    },
                    label: 'Facebook page'
                }
            },
            adAccountSenderId: {
                component: 'sFacebookAdAccountSelect',
                    options: {
                    attrs: {
                        'ng-required'       : true,
                            'on-handle-change'  : '$ctrl.helperControls.handleAdAccountChange(item)'
                    },
                    label: 'Facebook Ad Account'
                }
            },
            budget: {
                component: 'div',
                    options: {
                    model: 'ng-model',
                        attrs: {
                        'contenteditable'   : true,
                            's-form-wrapper'    : '{errorTemplate: \'sbase:number_errors\', class: \'inline-input \', hint: \'Please refer to <a href="https://developers.facebook.com/docs/messenger-platform/discovery/sponsored-messages/country-bids#minimum_country_bid" target="_blank" title="Minimum Country Bid Amount For Sponsored Messages">Minimum Country Bid Amount List <i class="fa fa-external-link"></i></a> to set the correct minimum amount.\'}',
                            'ng-pattern'        : new RegExp('^[1-9]([0-9])*(?:<br>)?$'),
                            'class'             : 'input',
                            'data-suffix'       : '{{ $ctrl.helperControls.currency }}',
                            'placeholder'       : '{{$ctrl.helperControls.currency ? \'... \' + $ctrl.helperControls.currency : \'Select an Ad Account first\'}}',
                            'ng-model-options'  : '{allowInvalid: true}',
                            'ng-required'       : '$ctrl.helperControls.currency',
                            'ng-class'          : '{\'disabled\' : !$ctrl.helperControls.currency}',
                            'allow-multiline'   : false
                    },
                    label: 'Total budget'
                }
            },
            campaignEnd: {
                component: 'sDatetime',
                    options: {
                    attrs: {
                        'class'         : 'inline',
                            'ng-required'   : true,
                            'min'           : '$ctrl.helperControls.minScheduleDate.clone().add(25, \'hours\').valueOf()'
                    },
                    label: 'Time end'
                }
            }
        };

        this.oneTimeConfig = {
            oneTimeNotificationLabel: {
                component: 'sNotificationLabelSelect',
                options: {
                    attrs: {
                        'in-use-only'   : true
                    },
                    label: 'Token to use'
                }
            }
        };

        var parentBuildSendData = protecteds.buildSendData;
        protecteds.buildSendData = function () {
            var data = parentBuildSendData();

            if (this.sendAsSponsored) {
                data.sendAsSponsoredData = this.sendAsSponsoredData;
            }

            return data;
        }.bind(this);

        /**
         * @property
         * @name Controller.sMessageAdmin.FacebookSendController#sendAsSponsored
         * @type {Boolean}
         */

        /**
         * @property
         * @name Controller.sMessageAdmin.FacebookSendController#sendAsOneTimeNotification
         * @type {Boolean}
         */

        /**
         * @property
         * @name Controller.sMessageAdmin.FacebookSendController#oneTimeNotificationLabel
         * @type {?String}
         */
    };

    Object.extendProto(FacebookSendController, Controller.sMessageAdmin.SendController);

    FacebookSendController.prototype.$onInit = function $onInit() {
        var self = this;
        FacebookSendController._pProto.$onInit.apply(this, arguments);

        var hasSendAsSponsoredPermission           = this.sAPIAccess.check({'messageAnchor.sendAsSponsored': ['PUT']}),
            hasSendAsOneTimeNotificationPermission = this.sAPIAccess.check({'messageAnchor.sendAsOneTimeNotification' : ['PUT']})
        ;

        if (!hasSendAsSponsoredPermission && !hasSendAsOneTimeNotificationPermission) {
            return;
        }

        var collection = this.anchor.message.collections.slice(0,1).shift();

        if (!collection) {
            return;
        }

        this.$deRegister.push(this.$scope.$watch(function () {
                return this.sendAsOneTimeNotification;
            }.bind(this),
            function () {
                this.createVirtualSegment();
            }.bind(this))
        );

        this.$deRegister.push(this.$scope.$watch(function () {
                return this.oneTimeNotificationLabel;
            }.bind(this),
            function () {
                this.createVirtualSegment();
            }.bind(this))
        );

        // first check if the message qualifies as notification message
        this.sMessageCollection.validateAsSponsored(collection).then(function(isValidAsNotification) {
            self.sendAsSponsoredDisabledReasons = [];
            self.sendAsOneTimeNotificationDisabledReasons = [];
            if (!isValidAsNotification) {
                self.sendAsSponsoredDisabledReasons.push('The first message meets the criteria (has only one message part).');
                self.sendAsOneTimeNotificationDisabledReasons.push('The first message meets the criteria (has only one message part).');
                digestIfNeeded(self.$scope);
                return;
            }

            // based on the permissions collect what checks need to be performed
            var performChecks = [];

            if (hasSendAsSponsoredPermission) {
                performChecks.push(
                    $.when(
                        self.sMessageCollection.sFacebookAdAccount.loadAll(['isAvailable'])
                            .then(function (adAccounts) {
                                if (!adAccounts.length) {
                                    self.sendAsSponsoredDisabledReasons.push('Your Facebook Ad Account is available and activated.');
                                }
                                return adAccounts;
                            }),
                        self.sMessageCollection.sFacebookPage.loadAll(['isAvailable', 'isBusinessIntegrated', 'isPublished'])
                            .then(function(pages) {
                                if (!pages.length) {
                                    self.sendAsSponsoredDisabledReasons.push('Your Facebook Page is published and connected to Spectrm\'s Business Manager.');
                                }
                                return pages;
                            })
                    ).then(function (adAccounts, pages) {
                        self.sendAsSponsoredDisabled = !adAccounts.length || !pages.length;
                    })
                );
            }

            if (hasSendAsOneTimeNotificationPermission) {
                performChecks.push(self.sNotificationLabel.getAll(true, true).then(function (tokens) {
                    if (!tokens.length) {
                        self.sendAsOneTimeNotificationDisabledReasons.push('At least one token is available.');
                    } else {
                        self.sendAsOneTimeNotificationDisabled = false;
                    }

                    return tokens;
                }));
            }

            return $.when.apply($, performChecks).always(function() {
                digestIfNeeded(self.$scope);
            });
        });

        this.updateCampaignEnd();


        // update the min time every 5 mins
        var interval = setInterval(function() {
            self.initMinDate();
            digestIfNeeded(self.$scope);
        }, 300000);

        this.$deRegister.push(function () {
            clearInterval(interval)
        });
    };

    /**
     * @param adAccount
     */
    FacebookSendController.prototype.handleAdAccountChange = function handleAdAccountChange(adAccount) {
        this.currency = adAccount ? adAccount.adAccountCurrency : null;
        // reset budget when adAccount is unselected
        if (!this.currency) {
            this.sendAsSponsoredData.totalBudget = null;
        }
    };

    FacebookSendController.prototype.handleScheduledChange = function handleScheduledChange() {
        this.initMinDate(true);
        this.updateCampaignEnd();
    };

    FacebookSendController.prototype.updateCampaignEnd = function updateCampaignEnd() {
        var newEnd = this.scheduledDate.clone().add(25, 'hours');
        if (newEnd > this.sendAsSponsoredData.campaignEnd) {
            this.sendAsSponsoredData.campaignEnd = newEnd;
        }
    };

    FacebookSendController.prototype.handleSASChange = function handleSASChange() {
        if (!this.sendAsSponsored || !this.sendAsOneTimeNotification) {
            return;
        }
        this.sendAsOneTimeNotification = false;
    };

    FacebookSendController.prototype.handleOTNChange = function handleOTNChange() {
        if (!this.sendAsSponsored || !this.sendAsOneTimeNotification) {
            return;
        }
        this.sendAsSponsored = false;
    };

    FacebookSendController.prototype.createVirtualSegment = function createVirtualSegment() {
        FacebookSendController._pProto.createVirtualSegment.apply(this, arguments);

        if (!this.sendAsOneTimeNotification) {
            return;
        }

        var condition = new Model.Segment.Condition();
        condition.key = 'reachable_by_one_time_notification';
        condition.operator = 'is';
        condition.value.splice(0, condition.value.length, this.oneTimeNotificationLabel);

        this.virtualSegment.conditions.addCondition(condition);
    };

    ns.FacebookSendController = FacebookSendController;
})(Object.namespace('Controller.sMessageAdmin'));
