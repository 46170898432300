(function (ns) {

    /**
     * @namespace
     * @memberOf Model.Sender
     * @constructor
     */
    var WAMessageTemplate = function WAMessageTemplate(id, name) {
        Object.defineProperties(
            this,
            {
                id     : {
                    enumerable : true,
                    get        : function () {
                        return id;
                    }
                    /**
                     * @property
                     * @type Number
                     * @name Model.Sender.WAMessageTemplate#id
                     */
                },
                name   : {
                    enumerable : true,
                    get        : function () {
                        return name;
                    }
                    /**
                     * @property
                     * @type String
                     * @name Model.Sender.WAMessageTemplate#name
                     */
                }
            }
        );
    };

    /**
     * @function
     * @name Model.Sender.WAMessageTemplate.createByData
     * @param {Object} data
     *
     * @returns {Model.Sender.WAMessageTemplate}
     */
    WAMessageTemplate.createByData = function createByData(data) {
        return new WAMessageTemplate(data.id, data.name);
    };

    ns.WAMessageTemplate = WAMessageTemplate;
})(Object.namespace('Model.Sender'));