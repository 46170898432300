(function(ns) {
    var KEY_PARTIAL_ENTITY        = 'partialEntity'
    ;

    new Model.AI.EntityCollection();

    /**
     * @namespace
     * @alias Model.AI.MatchTextCollection
     * @constructor
     * @extends Model.Collection
     */
    var MatchTextCollection =  function () {
        Model.AI.MatchTextCollection._pProto.constructor.call(this, Model.AI.MatchText, 'matchText');
        Model.Behavior.QualityScore.call(this, scoreCalculationConfig);

        var parentUpdateByData  = this.updateByData.bind(this),
            entitiesCache       = {key: null}
            ;

        this.updateByData = function(data) {
            var i,
                matchText
                ;

            if (data instanceof Array) {
                for (i = 0; i < this.matchTexts.length; i++) {
                    this.min = 0;
                    this.removeMatchText(this.matchTexts[i]);
                }
                for (i = 0; i < data.length; i++) {
                    if (!data[i].values.length) {
                        continue;
                    }
                    matchText = new Model.AI.MatchText();
                    matchText.type = Model.Rule.TYPES[data[i].type].alias || Model.Rule.TYPES[data[i].type].label;
                    matchText.pattern = data[i].values.join('|');
                    this.addMatchText(matchText);
                }
            } else {
                parentUpdateByData(data);
            }

            // IT-2632: templates messed up needs clean up
            this.matchTexts.concat([]).map(function(matchText) {
                if (!matchText.pattern || !matchText.pattern.trim().length) {
                    this.removeMatchText(matchText);
                    return null;
                }
                if (matchText.type === 'equals') {
                    matchText.type = 'is';
                }

                // if the match text contains pipes and spaces but no parenthesis, add parenthesis to the whole group
                if (matchText.pattern.search(' ') !== -1 && matchText.pattern.search('\\|') !== -1 && matchText.pattern.search('\\(') === -1) {
                    matchText.pattern = '(' + matchText.pattern + ')';
                }

            }.bind(this));
        };

        Object.defineProperties(
            this,
            {
                isNegative : {
                    enumerable : false,
                    writable   : true
                    /**
                     * @property
                     * @type {Boolean}
                     * @name Model.AI.MatchTextCollection#isNegative
                     */
                }
            }
        );

        this.__dontCloneProperties = function () {
            return ['$$hashKey'];
        };

        /**
         * @property matchTexts
         */
        /**
         * @method removeMatchText
         */
        /**
         * @method addMatchText
         */
    };

    Object.extendProto(MatchTextCollection, Model.Collection);

    /**
     * @function
     * @name Model.AI.MatchTextCollection#toString
     * @return {String}
     */
    MatchTextCollection.prototype.toString = function toString() {
        return JSON.stringify(
            this,
            function (key, value) {
                if (key === '$$hashKey') {
                    return undefined;
                }

                if (key === 'matchTexts' && value instanceof Array) {
                    return value.map(function (elem) {
                        return elem.toString();
                    });
                }
                return value;
            }
        );
    };

    /**
     * @return {String[]}
     */
    MatchTextCollection.prototype.getEntities = function getEntities() {
        // build up a register that binds entities with their presence in all matchTexts

        var entitiesByMatchTexts = this.matchTexts.map(
            /**
             * @param {Model.AI.MatchText} matchText
             */
            function(matchText) {
                return matchText.groups.filter(
                    /**
                     * @param {Model.AI.MatchGroup} element
                     */
                    function (element) {
                        return element.entity;
                    }
                ).map(function (group) {
                    var entityClone = group.entity.clone();
                    if (!entityClone.setMeta) {
                        Model.Behavior.Meta.call(entityClone);
                        Object.defineProperties(
                            entityClone,
                            {
                                meta: {
                                    enumerable: true,
                                    get: function () {
                                        return entityClone.getMetasJson();
                                    }
                                }
                            }
                        );
                    }
                    entityClone.setMeta(KEY_PARTIAL_ENTITY, true);

                    return entityClone;
                }).unique();
        });

        entitiesByMatchTexts = entitiesByMatchTexts.sort(function(a, b) {
            return a.length - b.length;
        });

        var uniqueEntities = entitiesByMatchTexts.reduce(function (carry, entitiesByMathText) {
            carry.push.apply(carry, carry.diff(entitiesByMathText, function (el) {
                return el.uuid;
            }));
            return carry;
        }, []);

        var leastEntities = entitiesByMatchTexts.shift();

        if (!leastEntities) {
            return uniqueEntities;
        }

        leastEntities.filter(function(entity) {
            var inAllOtherGroups = true;
            entitiesByMatchTexts.map(function (otherGroup) {
                var length = otherGroup.filter(function (walkedEntity) {
                    return walkedEntity.uuid === entity.uuid;
                }).length;

                inAllOtherGroups &= length;
            });

            return inAllOtherGroups;
        }).map(function(entity) {
            entity.unsetMeta(KEY_PARTIAL_ENTITY);
        });

        return uniqueEntities;
    };

    /**
     * @function
     * @name Model.AI.MatchTextCollection~ruleForMatchTextCount
     * @param {Object} config
     * @return {Number}
     */
    var ruleForMatchTextCount = function ruleForMatchTextCount(config) {
        var min     = config.min,
            max     = config.max,
            count   = this.matchTexts.length
        ;

        if (typeof min === 'undefined' || typeof max === 'undefined') {
            return null;
        }

        return + (min <= count && count <= max);
    };

    /**
     * @function
     * @name Model.AI.MatchTextCollection~ruleForIndividualMatchTexts
     * @return {?Number}
     */
    var ruleForIndividualMatchTexts = function ruleForIndividualMatchTexts() {
        var score = this.matchTexts.reduce(
            function (currentCount, matchText) {
                var tempScore = matchText.qualityScore;

                if (tempScore === null) {
                    return currentCount;
                }

                return currentCount + tempScore;
            },
            0
        );

        if (this.matchTexts.length)  {
            return score / this.matchTexts.length;
        }

        return null;
    };

    var scoreCalculationConfig = {
        hint: 'Your score is :score/:maxScore',
        maxScore: 100,
        rules: [
            {
                weight: 1,
                calculationFn: ruleForMatchTextCount,
                config: {
                    min: 1,
                    max: 10
                },
                hint: '<small>:score/:maxScore</small> - It’s better when you add between :min and :max matches'
            },
            {
                weight: 3,
                calculationFn: ruleForIndividualMatchTexts,
                config: {},
                hint: '<small>:score/:maxScore</small> - The average score of your matches'
            }
        ]
};

    Object.defineProperties(
        MatchTextCollection,
        {
            KEY_PARTIAL_ENTITY: {
                value: KEY_PARTIAL_ENTITY
                /**
                 * @property
                 * @constant
                 * @name Model.AI.MatchTextCollection#KEY_PARTIAL_ENTITY
                 * @type {String}
                 */
            }
        });

    ns.MatchTextCollection = MatchTextCollection;
})(Object.namespace('Model.AI'));
