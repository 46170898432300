(function(ns) {
    var lastTokenize = {
        input: null,
        groups: null
    };

    /**
     * @namespace
     * @alias Model.AI.MatchText
     * @param {Boolean=} isNegative
     * @constructor
     */
    var MatchText = function(isNegative) {
        Model.Behavior.QualityScore.call(this, scoreCalculationConfig);

        var groups      = [],
            groupsCopy  = [],
            pattern
            ;

        Object.defineProperties(
            this,
            {
                type: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @type {String}
                     * @name Model.AI.MatchText#type
                     */
                },
                pattern: {
                    enumerable: true,
                    get: function() {
                        return pattern;
                    },
                    set: function(val) {
                        if (val === pattern) {
                            return;
                        }
                        pattern = val;
                        groups.splice.apply(groups, [0, groups.length].concat(MatchText.tokenize(pattern)));
                    }
                    /**
                     * @property
                     * @type {String}
                     * @name Model.AI.MatchText#pattern
                     */
                },
                groups: {
                    get: function() {
                        groupsCopy.splice.apply(groupsCopy, [0, groupsCopy.length].concat(groups));

                        return groupsCopy;
                    }
                    /**
                     * @readonly
                     * @property
                     * @type {Model.AI.MatchGroup[]}
                     * @name Model.AI.MatchText#groups
                     */
                }
            }
        );

        /**
         * @property
         * @type {Boolean}
         * @name Model.AI.MatchText#markAsNew
         */
        this.markAsNew = false;

        this.type = isNegative ? Model.Rule.TYPES.includes_not.alias : Model.Rule.TYPES.contains.alias;

        this.__dontCloneProperties = function () {
            return ['$$hashKey'];
        };
    };

    /**
     * @function
     * @name Model.AI.MatchText#toString
     * @return {String}
     */
    MatchText.prototype.toString = function toString() {
        return JSON.stringify(
            this,
            Model.RESTAccessByUUID.prototype.mapFn
        );
    };

    /**
     * @param {Object} data
     */
    MatchText.prototype.updateByData = function updateByData(data) {
        if (!(data instanceof Object)) {
            return;
        }

        if (data.type) {
            this.type = data.type;
        }

        if (data.pattern) {
            this.pattern = data.pattern;
        }

        if (data.markAsNew) {
            this.markAsNew = data.markAsNew;
        }
    };

    /**
     * @function
     * @name Model.AI.MatchText#createByData
     * @param {object} data
     * @static
     * @return {Model.AI.MatchText}
     */
    MatchText.createByData = function createByData(data) {
        var matchText = new MatchText();
        matchText.updateByData(data);

        return matchText;
    };

    /**
     * @static
     * @param {String} input
     * @return {Array}
     */
    MatchText.tokenize = function tokenize(input) {
        if (lastTokenize.input === input) {
            return lastTokenize.groups;
        }

        // replace all spaces not inside brackets with nbsp
        input = input.replace(/[\(\[].*?[\)\]]/g, function(match) {
            return match
            // if for some reason the space is already converted to nbsp, then convert it back
                .replace(/(\u00A0)([^\u200E])/g, function() {
                    return '\u0020' + arguments[2];
                })
                .replace(/\u0020/g, '\u00AD');
        })
            .replace(/\u0020\u200E/, '\u00AD\u200E')
            .replace(/\u0020/g, '\u00A0')
            .replace(/\u00AD/g, '\u0020');

        input = input.replace(/([\)\]])([^\u00A0])/g, function() {
            var opening = arguments[1],
                closing = arguments[2];
            // mark following element with zwj
            return opening + '\u00A0' + closing + '\u200D';
        });

        input = input.replace(/([^\u00A0])([\[\(])/g, function() {
            var opening = arguments[1],
                closing = arguments[2];

            // mark following element with zwj
            return opening + '\u00A0' + closing + '\u200D';
        });

        // replace '( ' with ' ('
        input = input
            .replace(/([\(\[])([\u00A0]+)/g, function () {
                return arguments[2] + arguments[1];
            })
            .replace(/([\u00A0]+[\u200E])(\:weight\=[\d]\))/g, function() {
                return arguments[2] + arguments[1];
            })
            .replace(/([\u00A0]+[\u200E])((?:\|.*?)[\)\]])/g, function () {
                return arguments[2] + arguments[1];
            });

        // if a word gets broken up, carry the synonyms to the new one
        input = input
            .replace(/([\(\[])([^\u00A0\)\]]+)\u00A0([^\u00A0\(\[]+)([\)\]])/g, function () {
                var synonyms = arguments[3].split('|');

                synonyms.shift();
                synonyms.unshift(arguments[2]);

                return arguments[1]
                    + synonyms.join('|')
                    + arguments[4]
                    + '\u00A0'
                    + arguments[1]
                    + arguments[3]
                    + arguments[4];
            });

        var fragments       = input.split('\u00A0'),
            accumulatedGlue = ''
        ;

        lastTokenize.groups = fragments.reduce(function (groups, element, index) {
            if (!element && index) {
                // multiple nbsp after each other > add them to glue
                accumulatedGlue += '\u00A0';

                if (index === fragments.length - 1) {
                    groupObj = Model.AI.MatchGroup.fromString(element);
                    groupObj.glue = accumulatedGlue;
                    accumulatedGlue = '';
                    groups.push(groupObj);
                }
                // and skip part, unless last
                return groups;
            }

            if (index && element.search('\u200D') === -1) {
                accumulatedGlue += '\u00A0';
            } else {
                element = element.replace(/\u200D/g, '');
                // if the u200D was the only content ignore this fragment
                if (!element) {
                    return groups;
                }
            }

            var cleaned = element,
                groupObj
            ;

            if (cleaned.match(/\((.*?)\)/)) {
                groupObj = Model.AI.MatchGroup.fromString(RegExp.$1);
            } else if (cleaned.match(/\[(.*?)\]/)) {
                groupObj = Model.AI.MatchGroup.fromString(RegExp.$1);
                groupObj.optional = true;
            } else {
                groupObj = Model.AI.MatchGroup.fromString(cleaned);
            }

            //groupObj.elements = groupObj.elements.unique();
            groupObj.matchAll = groupObj.elements.filter(function(element) {
                return MatchText.isStringMatchingAll(element.value);
            }).length !== 0;

            groupObj.glue = accumulatedGlue;
            accumulatedGlue = '';
            groups.push(groupObj);
            return groups;
        }, []);

        lastTokenize.input = MatchText.buildPattern(lastTokenize.groups);

        return lastTokenize.groups;
    };

    /**
     * @static
     * @param {String}   pattern
     * @param {String=}  type
     * @param {Boolean=} markAsNew
     * @returns {Model.AI.MatchText}
     */
    MatchText.createFromString = function createFromString(pattern, type, markAsNew) {
        return MatchText.createByData({
            pattern  : pattern,
            type     : type,
            markAsNew: markAsNew
        });
    };

    /**
     * @static
     * @param {Model.AI.MatchGroup[]} groups
     * @return {*}
     */
    MatchText.buildPattern = function buildPattern(groups) {
        return groups.reduce(
            /**
             * @param {String} reduced
             * @param {Model.AI.MatchGroup} element
             */
            function(reduced, element) {
            reduced += (element.glue + element.toString(true));
            return reduced;
        }, '');
    };

    /**
     * @param {String} input
     * @return {String}
     */
    MatchText.removeSelectionPlaceholders = function removeSelectionPlaceholders(input) {
        return input
            .replace(Const.Unicode.SELECTION_START_PLACEHOLDER, '')
            .replace(Const.Unicode.SELECTION_END_PLACEHOLDER, '')
            ;
    };

    /**
     * @param {String} input
     * @return {Boolean}
     */
    MatchText.isStringMatchingAll = function isStringMatchingAll(input) {
        // In order not to match everything, the input string has to contain at least one letter,
        // number or special character (@, #, _, -). Underscore is matched by "\w".
        var matchingAllRegex = XRegExp("^[^\\pL@\\-_#\w0-9]*$");

        input = MatchText.removeSelectionPlaceholders(input);
        input = MatchText.replaceEmojis(input);
        input = MatchText.removeAccents(input);

        return matchingAllRegex.test(input);
    };

    /**
     * @param {String} input
     * @return {String}
     */
    MatchText.removeAccents = function removeAccents(input) {
        var search =
                'ÀÁÂÃÄÅĄĀāàáâãäåąßÒÓÔÕÕÖØŐòóôőõöøĎďŽžÈÉÊËĘèéêëęðÇçČčĆćÐÌÍÎÏĪìíîïīÙÚÛÜŰŮůùűúûüĽĹŁľĺłÑŇŃňñńŔŘŕřŠŚŞšśşŤťŸÝÿýŽŻŹžżźđĢĞģğ',
            replace =
                'AAAAAAAAaaaaaaaaSOOOOOOOOoooooooDdZzEEEEEeeeeeeCcCcCcDIIIIIiiiiiUUUUUuUuuuuuLLLlllNNNnnnRRrrSSSsssTtYYyDZZdzzzdGGgg';

        return input.split('')
            .map(function (letter) {
                var index = search.indexOf(letter);
                return (index !== -1 ? replace[index] : letter);
            })
            .join('');
    };

    /**
     * @function
     * @name Model.AI.MatchText#equals
     * @param {Model.AI.MatchText} matchText
     * @return {Boolean}
     */
    MatchText.prototype.equals = function equals(matchText) {
        // The two Match Texts are equal if their type and pattern (case insensitive) are equal
        // The Match Texts can also include different types of white space - we replace them so
        // they do not affect the comparison
        return this.pattern.toLowerCase().replace(/\s/g, ' ')
            === matchText.pattern.toLowerCase().replace(/\s/g, ' ')
            && this.type === matchText.type;
    };

    /**
     * @param {String} input
     * @return {String}
     */
    MatchText.replaceEmojis = function replaceEmojis(input) {
        var emojis = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|[\ud83c[\ude50\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

        return input.replace(emojis, '_');
    };

    /**
     * @function
     * @name Model.AI.MatchText~ruleForWordLength
     * @param {Object} config
     * @return {Number}
     */
    var ruleForWordLength = function ruleForWordLength(config) {
        var min         = config.min,
            max         = config.max,
            wordCount   = 0;

        if (typeof min === 'undefined' || typeof max === 'undefined') {
            return null;
        }

        this.groups.map(function (group) {
            if (group.getNotEmptyElements().length) {
                wordCount++;
            }
        });

        return (min <= wordCount && wordCount <= max) ? 1 : 0;
    };

    /**
     * @function
     * @name Model.AI.MatchText~ruleForSynonymsInWord
     * @param {Object} config
     * @return {?Number}
     */
    var ruleForSynonymsInWord = function ruleForSynonymsInWord(config) {
        var min                     = config.min,
            max                     = config.max,
            badSynonymGroupsCount   = 0,
            nonEmptyGroups
        ;

        if (!this.groups.length || typeof min === 'undefined' || typeof max === 'undefined') {
            return null;
        }

        nonEmptyGroups = this.groups.filter(function (group) {
            return group.getNotEmptyElements().length > 0;
        }).map(function (group) {
            var elements = group.elements;
            if (elements.length < (min + 1) || elements.length > (max + 1)) {
                badSynonymGroupsCount++;
            }
        });

        if (nonEmptyGroups.length === 0) {
            return null;
        }

        return (badSynonymGroupsCount > 0) ? 0 : 1;
    };

    /**
     * @function
     * @name Model.AI.MatchText~ruleForBasicSentence
     * @param {Object} configWithMultiple
     * @return {?Number}
     */
    var ruleForBasicSentence = function ruleForBasicSentence(configWithMultiple) {
        var config                      = configWithMultiple.multiple ||[],
            charactersFoundBySubConfig  = config.map(function () { return 0; });

        if (!this.groups.length) {
            return null;
        }

        this.groups.map(function (group) {
            var charactersFoundBySubConfigCached = charactersFoundBySubConfig.slice();

            group.getNotEmptyElements().map(function (element) {
                var value = element.value,
                    char;

                config.map(function (subConfig, subConfigIndex) {

                    // same character-group should just be counted once per match-text-group
                    if (charactersFoundBySubConfigCached[subConfigIndex] !== charactersFoundBySubConfig[subConfigIndex]) {
                        return;
                    }

                    // iterate through characters
                    for (var k = 0; subConfig.characters.length > k; k++) {
                        char = subConfig.characters[k];

                        if (value.indexOf(char) !== -1) {
                            charactersFoundBySubConfig[subConfigIndex]++;
                            break;
                        }
                    }

                });
            });
        });

        return config.reduce(
            function (currentScore, subConfig, subConfigIndex) {
                if (currentScore <= 0) {
                    return 0;
                }

                if (subConfig.min > charactersFoundBySubConfig[subConfigIndex]
                    || subConfig.max < charactersFoundBySubConfig[subConfigIndex]
                ) {
                    return 0;
                }

                return currentScore;
            },
            1
        );
    };

    var scoreCalculationConfig = {
        hint: 'Your match text fulfills :score/:maxScore criteria',
        maxScore: 3,
        rules: [
            {
                weight: 1,
                calculationFn: ruleForWordLength,
                config: {
                    min: 3,
                    max: 10
                },
                hint: '<small>:score</small> - Ideally, the text should contain between :min and :max words'
            },
            {
                weight: 1,
                calculationFn: ruleForSynonymsInWord,
                config: {
                    min: 0,
                    max: 30
                },
                hint: '<small>:score</small> - Please, try to limit the number of synonyms to :max per word'
            },
            {
                weight: 1,
                calculationFn: ruleForBasicSentence,
                config: {
                    multiple: [
                        {
                            characters: ['.', '?', '!'],
                            min: 0,
                            max: 1
                        },
                        {
                            characters: [',', ';'],
                            min: 0,
                            max: 2
                        }
                    ]
                },
                hint: '<small>:score</small> - Make sure your sentence or phrase has only one intent'
            }
        ]
    };

    ns.MatchText = MatchText;
})(Object.namespace('Model.AI'));
