/**
 * Created by Gabor on 22-Jun-17.
 */

var ns = Object.namespace('Model'),
    Subscription = ns.Subscription = function (_id) {

        var id = _id;
        Object.defineProperties(
            this,
            {
                id: {
                    get: function () {
                        return id;
                    }
                },
                'memberId': {
                    writable: true,
                    enumerable: true
                },
                'segmentId': {
                    writable: true,
                    enumerable: true
                },
                'time': {
                    writable: true,
                    enumerable: true
                }
            }
        );

        /**
         * @param data
         * @returns {ns.Subscription}
         */
        this.updateFromJson = function (data) {
            var props = Object.getOwnPropertyNames(this);

            for (var i = 0; i < props.length; i++) {
                if (data[props[i]]) {
                    this[props[i]] = data[props[i]];
                }
            }

            return this;
        };

        this.save = function () {
            var method = Const.Method.PUT,
                url = Model.Subscription.endPoint,
                self = this,
                data
            ;

            if (!id) {
                method = Const.Method.POST;
            } else {
                url += '/' + id;
            }

            data = new FormData();
            data.append('subscription', JSON.stringify(this));
            return $.ajax({
                url: url,
                data: data,
                method: method,
                processData: false,
                contentType: false
            }).then(function (data) {
                if (!id && data.id) {
                    id = data.id;
                }

                return self;
            });
        };
    };

Subscription.prototype.delete = function () {
    if (!this.id) {
        return $.when(null);
    }
    return $.ajax({
        url: Model.Subscription.endPoint + this.id,
        method: Const.Method.DELETE
    });
};

Model.Subscription.fromJson = function (data) {
    var json = JSON.parse(data),
        subscription = new Model.Subscription(json.id);
    return subscription.updateFromJson(json);
};