(function(angular) {

    var module = angular.module('sMessageAnchorModule', []);

    module
        .controller('sMessageAnchorOverviewController', sMessageAnchor.Controller.Overview)
        .service('sMessageAnchor', sMessageAnchor.Service.MessageAnchor)
    ;

    module.config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/message', new sMessageAnchor.Route.Overview())
            .when('/creator/:uuid', new sMessageAnchor.Route.Creator())
            // following redirects are temporary (can be deleted after about 6 months) to forward saved urls
            .when('/message/create', {
                redirectTo: '/creator/new'
            })
            .when('/message/edit/:uuid', {
                redirectTo: '/creator/:uuid'
            })
            .when('/nlp-checker', {
                redirectTo : function () {
                    return '/message?type=ai-templates&state=active&orderBy=-timeUpdated&selectedFilterGroup=test';
                }
            });
    }]);

    module.config(['sTitleProvider', function (sTitleProvider) {
        sTitleProvider
            .when(
                '/message',
                function ($route) {
                    return ($route.params && $route.params.type === 'ai-templates') ? 'AI-Templates' : 'Conversations';
                },
                'Overview'
            )
            .when(
                '/creator/:uuid',
                function ($route) {
                    return ($route.locals
                        && $route.locals.messageCollection
                        && $route.locals.messageCollection.getRootMessage().messageAnchor.type.alias === Model.Message.TYPE_ALIAS_REACTION)
                        ? 'AI-Template'
                        : 'Conversation';
                },
                function ($route) {
                    if ($route.params.uuid === 'new') {
                        return  'Create new';
                    }
                    return 'Edit';
                }
            );
    }]);

})(angular);
