/**
 * Created by Gabor on 13-Aug-17.
 */

(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sPostbackAlternatives
     * @param $scope
     */
    var sPostbackAlternatives = function($scope) {
        this.$deRegister = [];
        this.actionCollection                   = new Model.Action.Collection();
        this.actionCollection.context           = 'postback-only';
        this.actionCollection.allowAlternatives = true;

        this.$scope = $scope;
    };

    sPostbackAlternatives.prototype.$onDestroy = function() {
        var $destroyFunc;
        while (($destroyFunc = this.$deRegister.pop())) {
            $destroyFunc();
        }
    };

    sPostbackAlternatives.prototype.$onInit = function $onInit() {
        var self = this;

        this.$deRegister.push(this.$scope.$watchCollection(function() {
            return self.model.ctas;
        },
        /**
         * @param {Array} val
         */
        function(val) {
            self.actionCollection.removeAll();
            for (var i = 0; i < val.length; i++) {
                var actionToAdd = val[i].actions.actions.slice(0,1).pop();
                if (self.actionCollection.getIndex(actionToAdd) === -1) {
                    self.actionCollection.addAction(actionToAdd);
                }
            }
        }));

        this.$deRegister.push(this.$scope.$watchCollection(function() {
            return self.actionCollection.actions;
        },
        /**
         * @param {Array} val
         */
        function(val) {
            self.model.removeAll();
            for (var i = 0; i < val.length; i++) {
                if (self.model.getAllActions().map(function(element) {
                    return element.action;
                }).indexOf(val[i]) === -1) {
                    var cta = self.model.createAndAddCTA();
                    cta.label = i.toLettersRadix().toUpperCase();
                    cta.actions.addAction(val[i]);
                }
            }
        }));
    };

    sPostbackAlternatives.prototype.createAndAddAction = function createAndAddAction() {
        var action = new Model.Action();
        action.type = this.actionCollection.getAllowedActions().slice(0,1).pop();
        this.actionCollection.addAction(action);
    };

    ns.sPostbackAlternatives = sPostbackAlternatives;
})(Object.namespace('Controller.Component'));
