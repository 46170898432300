(function (angular) {

    var module = angular.module('sSourceModule');
    module.filter('resolvePlaceholders',
        [
            '$sce',
            'sSource',
            '$timeout',
            /**
             * @param {$sce} $sce
             * @param {sSource.Service.sSource} sSourceService
             * @param $timeout
             * @returns {Function}
             */
            function ($sce, sSourceService, $timeout) {
                /**
                 * @param input
                 * @param {Boolean} dontEscape
                 * @param {Array=} contextSpecificPlaceholders
                 * @param {Array=} allowedTypes
                 * @param {Array=} allowedPlaceholders
                 */
                return function (input, dontEscape, contextSpecificPlaceholders, allowedTypes, allowedPlaceholders) {
                    var output = '',
                        replacements = [],
                        changed = false
                    ;
                    contextSpecificPlaceholders = contextSpecificPlaceholders || [];

                    if (input instanceof Object && input.$$unwrapTrustedValue) {
                        input = input.$$unwrapTrustedValue();
                        // return the same format as input
                        dontEscape = false;
                    }

                    input = input || '';

                    if (!input.length) {
                        return;
                    }

                    var $el = $('<div></div>');
                    $el.html(input);

                    for (var i = 0; i < $el[0].childNodes.length; i++) {
                        replacements = [];
                        if ($el[0].childNodes[i].nodeType !== Node.TEXT_NODE) {
                            output += $el[0].childNodes[i].outerHTML;
                            continue;
                        }

                        /**
                         * workaround for preventing unescaping html entities
                         * (since it is "textContent" it should have no 'real' html in it)
                         */
                        var $textContentElement = $('<div></div>');
                        $textContentElement.text($el[0].childNodes[i].textContent);

                        var textContent = $textContentElement.html();

                        var placeholdersPromise = sSourceService.getPlaceholders(contextSpecificPlaceholders, allowedTypes, allowedPlaceholders);

                        // in case the promise needs time to resolve, return untouched, and ensure the filter is called again
                        if (placeholdersPromise.state() !== 'resolved') {
                            placeholdersPromise.always(function() {
                                // force digest loop
                                return $timeout(function() {}, 0);
                            });

                            return input;
                        }

                        // get the variable out of the closure
                        var lookUps;
                        placeholdersPromise.then(function(placeholders) {
                            lookUps = placeholders;
                        });

                        textContent = lookUps.reduce(function(reduced, element) {
                            var rxp = new RegExp(sSource.Service.sSource.transformer(element), 'gi');
                            reduced = reduced.replace(rxp, function() {
                                changed = true;
                                return '<div contenteditable="false" class="bubble" title="' + element.description + '" data-content="' + element.fullyQualifiedName + '"><span>' + element.token + '</span></div>';

                            });

                            return reduced;
                        }, textContent);

                        output += textContent;
                        delete($textContentElement);
                    }

                    if (!changed) {
                        output = input;
                    }

                    if (dontEscape === true) {
                        return output;
                    }

                    return $sce.trustAsHtml(output);
                }
            }])
})(angular);