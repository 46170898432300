(function (ns) {
    /**
     * @namespace
     * @alias sMember.Controller.AttributeOverview
     * @constructor
     *
     * @param $mdDialog
     * @param Notification
     * @param {Service.sDomain} sDomainService
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     */
    var AttributeOverview = function (
        $mdDialog,
        Notification,
        sDomainService,
        sAPIAccess
    ) {
        this.$mdDialog = $mdDialog;
        this.notification = Notification;
        this.sAPIAccess = sAPIAccess;
        this.sDomainService = sDomainService;
        this.list = this.getList();
        this.loadingAttribute = null;
    };

    /**
     * @function
     * @name sMember.Controller.AttributeOverview#getList
     **
     * @returns {Model.List}
     */
    AttributeOverview.prototype.getList = function getList() {
        var column,
            list = new Model.List(this.sAPIAccess.endpoint('member.attribute').get())
        ;

        // Only show current domains user attributes
        list.filters.createAndAdd('domainId', this.sDomainService.currentDomainId, true);

        list.mapFn = function (row) {
            return Model.Member.Attribute.createByData(row);
        };

        column = list.columns.createAndAdd('key');
        column.isSortable = true;
        column.label = 'Name';

        list.actions = {
            inspect : new Model.Menu.Action('inspect', this.inspectAttribute.bind(this))
        };

        if (this.sAPIAccess.isAllowed('member.attribute', Const.Method.PUT)) {
            list.actions['edit'] = new Model.Menu.Action('edit', this.editAttribute.bind(this));
        }

        if (Object.keys(list.actions).length) {
            column = list.columns.createAndAdd('options');
            column.label = 'Options';
        }

        return list;
    };

    /**
     * @param {Model.Member.Attribute} attribute
     */
    AttributeOverview.prototype.editAttribute = function editAttribute(attribute) {
        var self = this;

        self.$mdDialog.show({
            controller          : sMember.Controller.EditAttribute,
            locals              : {
                attribute : attribute
            },
            controllerAs        : '$ctrl',
            templateUrl         : 'smember:editattribute',
            parent              : angular.element(document.body),
            clickOutsideToClose : false
        }).then(function () {
                self.notification.success('Attribute was successfully saved.');
                self.list.load(false, true);
        }).catch(function () {
        });
    };

    /**
     * @param {Model.Member.Attribute} attribute
     */
    AttributeOverview.prototype.inspectAttribute = function inspectAttribute(attribute) {
        this.loadingAttribute = attribute.key;
        var self = this;

        attribute.loadValues(this.sAPIAccess).then(function () {
            self.$mdDialog.show({
                controller          : function () {
                    this.attribute = attribute;
                    this.cancel = function cancel() {
                        self.$mdDialog.cancel();
                    };
                },
                controllerAs        : '$ctrl',
                templateUrl         : 'smember:inspectattribute',
                parent              : angular.element(document.body),
                clickOutsideToClose : true
            });
        }).fail(function () {
            self.notification.error('There was an error while loading attribute values. Please notify the support.');
        }).always(function () {
            self.loadingAttribute = null;
        });
    };

    ns.AttributeOverview = AttributeOverview;
})(Object.namespace('sMember.Controller'));
