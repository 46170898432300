(function (ns) {
    /**
     * @namespace
     * @alias sAutomation.Route.Overview
     * @extends sRoute
     * @constructor
     */
    var Overview = function Overview() {
        this.controller     = sAutomation.Controller.Overview;
        this.controllerAs   = '$ctrl';
        this.templateUrl    = 'sautomation:overview';
        this.reloadOnSearch = false;
        this.accessControl  = {'automation.all': Const.Method.GET};

        Overview._pProto.constructor.call(this);
    };

    Object.extendProto(Overview, sRoute);

    /**
     * @name sAutomation.Route.Overview#resolveConditionDefinitions
     * @param {sSegment.Service.sConditionDefinition} sSegmentConditionDefinition
     * @returns {*}
     */
    Overview.prototype.resolveConditionDefinitions = function resolveConditionDefinitions(sSegmentConditionDefinition) {
        return sSegmentConditionDefinition.init();
    };

    ns.Overview = Overview;
})(Object.namespace('sAutomation.Route'));