(function(angular) {

    var module = angular.module('sContentFeedModule', []);

    module.config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/content-feed/overview', new sContentFeed.Route.Overview())
        ;
    }]);

    module.config(['sTitleProvider', function(sTitleProvider) {
        sTitleProvider
            .when(
                '/content-feed/overview',
                'Content Feed',
                'Overview'
            )
        ;
    }]);

})(angular);
