(function (ns) {

    /**
     * @namespace
     * @alias Service.sNotificationLabel
     * @constructor
     *
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     */
    var sNotificationLabel = function (sAPIAccess) {
        var notificationLabels         = [],
            notificationLabelsCache    = [],
            loadingPromise             = null,
            self                       = this,

            /**
             * @param {String} name
             * @return {Model.NotificationLabel|null}
             */
            getNotificationLabelByName = function (name) {
                var matches = notificationLabels.filter(function (notificationLabel) {
                    return notificationLabel.name === name;
                });

                return matches.length ? matches.pop() : null;
            },

            /**
             * @param {String} uuid
             * @return {Model.NotificationLabel|null}
             */
            getNotificationLabelByUuid = function (uuid) {
                var matches = notificationLabelsCache.filter(function (notificationLabel) {
                    return notificationLabel.uuid === uuid;
                });

                return matches.length ? matches.pop() : null;
            },

            /**
             * @return {$.Deferred}
             */
            loadAll                    = function () {
                if (loadingPromise && loadingPromise.state() === 'pending') {
                    return loadingPromise;
                }

                loadingPromise = $.ajax(sAPIAccess.endpoint('action.notificationLabels').get())
                    .then(function (response) {
                        if (!Array.isArray(response)) {
                            return;
                        }

                        response.map(function (notificationLabelData) {
                            notificationLabelData.isNew = false;
                            self.addNewNotificationLabel(Model.NotificationLabel.createByData(notificationLabelData));
                        });
                    });

                return loadingPromise;
            };

        /**
         * @function
         * @name Service.sNotificationLabel#getAll
         * @param {Boolean=} onlyWithActiveTokens
         * @param {Boolean=} forceReload
         *
         * @return {$.Deferred}
         */
        this.getAll = function (onlyWithActiveTokens, forceReload) {
            var deferred          = $.Deferred(),
                getFilteredLabels = function () {
                    return notificationLabelsCache.filter(function (notificationLabel) {
                        return (!onlyWithActiveTokens || notificationLabel.hasActiveTokens);
                    });
                }
            ;

            if (forceReload || !loadingPromise) {
                loadAll().then(function () {
                    deferred.resolve(getFilteredLabels());
                });
            } else {
                deferred.resolve(getFilteredLabels());
            }

            return deferred.promise();
        };

        /**
         * @function
         * @name Service.sNotificationLabel#addNewNotificationLabelByName
         *
         * @param {String} name
         */
        this.addNewNotificationLabelByName = function (name) {
            this.addNewNotificationLabel(
                Model.NotificationLabel.createByData({name : name.trim()})
            );
        };

        /**
         * @function
         * @name Service.sNotificationLabel#addNewNotificationLabel
         *
         * @param {Model.NotificationLabel} notificationLabel
         */
        this.addNewNotificationLabel = function (notificationLabel) {
            if (getNotificationLabelByName(notificationLabel.name)) {
                return;
            }

            notificationLabels.push(notificationLabel);

            // Refresh cache
            notificationLabelsCache.splice.apply(
                notificationLabelsCache, [0, notificationLabelsCache.length].concat(notificationLabels)
            );
        };

        /**
         * @function
         * @name Service.sNotificationLabel#getNewLabelsInCollection
         *
         * @param {Model.Message.Collection} collection
         * @return {$.Deferred}
         */
        this.getNewLabelsInCollection = function (collection) {
            var labelsInCollection = [];

            collection.messages.map(function (message) {
                message.getAllActions().map(function (action) {
                    if (action.type === Model.Action.TYPE_ONE_TIME_NOTIFICATION) {
                        labelsInCollection.push(action.value);
                    }
                });
            });

            if (!labelsInCollection.length) {
                return $.Deferred().resolve([]);
            }

            return this.getAll()
                .then(function () {
                    var notificationLabels = [];

                    labelsInCollection.map(function (notificationLabelUuid) {
                        var notificationLabel = getNotificationLabelByUuid(notificationLabelUuid);

                        if (notificationLabel && notificationLabel.isNew) {
                            notificationLabels.push(notificationLabel);
                        }
                    });

                    return notificationLabels;
                });
        };
    };

    ns.sNotificationLabel = sNotificationLabel;
})(Object.namespace('Service'));
