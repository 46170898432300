(function (ns) {
    var EVENT_BUGSNAG_BEFORE_SEND = 'sEventBugsnagBeforeSend';
    /**
     * @namespace
     * @alias sBugsnag.Provider.sBugsnag
     *
     * @constructor
     */
    var sBugsnag = function () {
        var apiKey          ,
            releaseStage    ,
            client          ,
            allowBreadcrumb ,
            opts            = {},
            beforeNotify    = function(report) {
                $.event.trigger(EVENT_BUGSNAG_BEFORE_SEND, report)
            },
            createClient    = function() {
                client = window.bugsnag(
                    $.extend(
                        {},
                        opts,
                        {
                            apiKey: apiKey,
                            releaseStage: releaseStage,
                            beforeSend: beforeNotify
                        }
                    )
                );

                // Override proto function in order to filter out some breadcrumbs
                // Breadcrumbs are maxed, and later filtering would mean that unused breadcrumbs have already consumed  slots
                // from other valuable breadcrumbs, so they should not be added
                client.leaveBreadcrumb = function leaveBreadcrumb(name, metaData, type, timestamp) {
                    if (allowBreadcrumb && !allowBreadcrumb.apply(client, arguments)) {
                        return;
                    }
                    return Object.getPrototypeOf(client).leaveBreadcrumb.apply(client, arguments);
                };

                // extend the proto function to clear breadcrumbs and refresh (reset) the max error limit
                client.notify = function notify() {
                    var retVal = Object.getPrototypeOf(client).notify.apply(client, arguments);

                    client.clearBreadcrumbs();
                    client.refresh();

                    return retVal;
                };

                client.clearBreadcrumbs = function clearBreadcrumbs(limit) {
                    limit = Math.max(limit || client.breadcrumbs.length - 1, client.breadcrumbs.length - 1);
                    client.breadcrumbs.splice(0, limit);

                };
                window.bugsnagClient = client;
            }
        ;
        
        Object.defineProperties(
            this,
            {
                apiKey: {
                    set: function (val) {
                        if (val === apiKey) {
                            return;
                        }
                        apiKey = val;
                        if (client) {
                            createClient();
                        }
                    }
                    /**
                     * @property
                     * @name Provider.sBugsnag#apiKey
                     * @type {string}
                     */
                },
                releaseStage: {
                    set: function (val) {
                        if (val === releaseStage) {
                            return;
                        }
                        releaseStage = val;
                        if (client) {
                            createClient();
                        }
                    }
                    /**
                     * @property
                     * @name Provider.sBugsnag#releaseStage
                     * @type {string}
                     */
                },
                options: {
                    set: function (val) {
                        if (val === options) {
                            return;
                        }
                        opts = val;

                        if (client) {
                            createClient();
                        }
                    }
                    /**
                     * @property
                     * @name sBugsnag.Provider.sBugsnag#options
                     * @type {Object}
                     */
                },
                allowBreadcrumb: {
                    set: function (val) {
                        if (val) {
                            Object.instanceOf(val, Function);
                        }

                        if (val === allowBreadcrumb) {
                            return;
                        }
                        allowBreadcrumb = val;
                    }
                    /**
                     * @property
                     * @name sBugsnag.Provider.sBugsnag#allowBreadcrumb
                     * @type {Function}
                     */
                }

            });

        this.$get = function $get() {
            if (!client) {
                createClient();
            }

            return client;
        };
    };

    Object.defineProperties(
        sBugsnag,
        {
            EVENT_BUGSNAG_BEFORE_SEND: {
                get: function () {
                    return EVENT_BUGSNAG_BEFORE_SEND;
                }
                /**
                 * @property
                 * @name sBugsnag.Provider.sBugsnag#EVENT_BUGSNAG_BEFORE_SEND
                 * @type {String}
                 */
            }

        });

    ns.sBugsnag = sBugsnag;
})(Object.namespace('sBugsnag.Provider'));