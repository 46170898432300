(function(angular) {

    var module = angular.module('sSegmentModule', []);

    module.service('sSegmentConditionDefinition', sSegment.Service.sConditionDefinition);

    module
        .service('sSegment', sSegment.Service.sSegment);

    module.config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/segment', new sSegment.Route.Overview());
    }]);

    module.config(['sTitleProvider', function(sTitleProvider) {
        sTitleProvider
            .when(
                '/segment',
                'Segment',
                'Overview'
            )
        ;
    }]);

})(angular);