(function(ns) {
    /**
     * @namespace
     * @alias sList.Component.Controller.sRecordsPerPageSelector
     */
    var RecordsPerPageSelector = function ($scope) {
        this.$scope         = $scope;
        this.$deRegister    = [];
    };

    /**
     * @function
     * @name sList.Component.Controller.sRecordsPerPageSelector#$onInit
     */
    RecordsPerPageSelector.prototype.$onInit = function $onInit() {
        var self = this;

        this.$deRegister.push(this.$scope.$watch(
            function () {
                return self.model;
            },
            function (newVal) {
                // check if model is in list of choices
                if (!self.choices.length || self.choices.indexOf(newVal) !== -1) {
                    return;
                }

                self.$scope.$evalAsync(function () {
                    self.model = self.choices.slice().shift();
                });
            }
        ));
    };

    /**
     * @function
     * @name sList.Component.Controller.sRecordsPerPageSelector#$onDestroy
     */
    RecordsPerPageSelector.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name sList.Component.Controller.sRecordsPerPageSelector#$onChanges
     * @param {object} $changes
     */
    RecordsPerPageSelector.prototype.$onChanges = function $onChanges($changes) {
        if ($changes.choices) {
            Object.instanceOf($changes.choices.currentValue, Array);
        }
    };

    ns.sRecordsPerPageSelector = RecordsPerPageSelector;
})(Object.namespace('sList.Component.Controller'));
