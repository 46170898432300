/**
 * Created by Gabor on 16-Oct-17.
 */
var module = angular.module('sNavigation');

module
    /**
     * @ngdoc component
     * @name sNavigation
     * @restrict E
     */
    .component('sNavigation', {
        controller: sNavigation.Controller.Component.sNavigation,
        templateUrl: 'snavigation/components:s-navigation'
    })
    /**
     * @ngdoc component
     * @name sSideNavigation
     * @restrict E
     */
    .component('sSideNavigation', {
        controller: sNavigation.Controller.Component.sSideNavigation,
        templateUrl: 'snavigation/components:s-side-navigation'
    })
    /**
     * @ngdoc component
     * @name sTabNavigation
     * @restrict E
     *
     * @param {String} item
     */
    .component('sTabNavigation', {
        controller: sNavigation.Controller.Component.sTabNavigation,
        templateUrl: 'snavigation/components:s-tab-navigation',
        bindings: {
            item: '@'
        }
    })
    /**
     * @ngdoc component
     * @name sNavigationItem
     * @restrict E
     *
     * @param {Model.Menu.Action} item
     * @param {Boolean} collapsed
     */
    .component('sNavigationItem', {
        templateUrl : 'snavigation/components:s-navigation-item',
        bindings    : {
            item      : '<',
            collapsed : '<'
        }
    })
;
