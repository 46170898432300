(function(ns) {
    /**
     * @namespace
     * @alias Model.Menu.Label
     *
     * @param label
     * @constructor
     */
    var Label = function(label) {
            this.label = label;
            Label._pProto.constructor.call(this, Label);
    };

    Object.extendProto(Label, ns.Entry);

    Label.TYPE_IDENT = 'LABEL';
    ns.Entry.TYPES.LABEL = Label;
    ns.Label = Label;
})(Object.namespace('Model.Menu'));