(function (ns) {
    var KEY_CONTENT_FIELD_PREFIX = 'content.';

    /**
     * Immutable model since we just list crawled data from system
     *
     * @namespace
     * @alias Model.Source.Content
     * @extends Model.RESTAccessSubResource
     *
     * @constructor
     * @param {Model.Source} source
     * @param {String} uuid
     * @param {Object} content
     * @param {int} timeCreated
     */
    var Content = function Content(source, uuid, content, timeCreated) {
        var self = this;

        if (!moment.isMoment(timeCreated)) {
            timeCreated = moment(timeCreated);
        }

        Object.defineProperties(
            this,
            {
                source              : {
                    enumerable : true,
                    get        : function () {
                        return this.parent;
                    }
                    /**
                     * @property
                     * @name Model.Source#source
                     * @type {Model.Source}
                     */
                },
                content        : {
                    enumerable : true,
                    get        : function () {
                        return content;
                    }
                    /**
                     * @property
                     * @name Model.Source#content
                     * @type {Object}
                     */
                },
                timeCreated        : {
                    enumerable : true,
                    get        : function () {
                        return timeCreated;
                    }
                    /**
                     * @property
                     * @name Model.Source#timeCreated
                     * @type {Moment}
                     */
                }
            }
        );
        Content._pProto.constructor.call(this, source, uuid);

        source.fields.map(function (fieldName) {
            Object.defineProperty(self, KEY_CONTENT_FIELD_PREFIX + fieldName, {
                enumerable : true,
                get        : function () {
                    return content[fieldName];
                }
            })
        });
    };

    Object.extendProto(Content, Model.RESTAccessSubResource);

    Content.prototype.endPoint = '/content/';

    Object.defineProperties(
        Content,
        {
            KEY_CONTENT_FIELD_PREFIX : {
                value : KEY_CONTENT_FIELD_PREFIX
                /**
                 * @property
                 * @constant
                 * @name Model.Source.Content#KEY_CONTENT_FIELD_PREFIX
                 * @type {String}
                 */
            }
        }
    );

    ns.Content = Content;
})(Object.namespace('Model.Source'));
