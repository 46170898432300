(function(ns) {
    var EVENT_APPEND_MESSAGE_PART           = 'sEventAppendMessagePart',
        EVENT_ADD_MESSAGE_BY_CONTENT        = 'sEventAddMessageByContent',
        EVENT_MESSAGE_LOG_SCROLL_TO_BOTTOM  = 'sEventMessageLogScrollToBottom'
    ;

    /**
     * @param {String} uuid
     */
    var executeSetContentsByMessageUuid = function (uuid) {
        var msg,
            goalMsg = this.items.reduce(function (carry, element) {
                // reduce the elements to the very last outgoing message
                if (element instanceof Model.Message && (!element.isIncoming || element.isRoot)) {
                    carry = element;
                }
                return carry;
            }, null)
        ;

        if (goalMsg && goalMsg.uuid === uuid) {
            return;
        }

        var firstNode,
            startMsg = this.messageCollection.getMessageByUuid(uuid);

        // if there was no goalMsg, then items should be emptied and needs to decide how to fill it
        if (!goalMsg) {
            this.removeElements(0);
            if (!startMsg.isOrphan) {
                // if the message is not an orphan, then root should be the start
                goalMsg = this.messageCollection.getRootMessage();
            } else {
                // if it is oprhan, then just choose that message
                goalMsg = startMsg;
            }
        }

        var nodes        = Model.Message.Node.createGraphNodesReversed(startMsg, goalMsg),
            nodeWalk,
            relations,
            relation,
            nlpTemplates = [],
            i
        ;

        if ((firstNode = nodes.slice(0, 1).pop()) && firstNode.message.uuid !== goalMsg.uuid) {
            // clear current msgLog
            this.removeElements(0);
        } else {
            this.removeElements(this.items.length - 1);
        }

        // get all dead-end-nodes which are not start-nodes
        // and ordered by smallest weight last
        var deadEndNodes = nodes
            .filter(function (node) {
                return node.isDeadEnd && node.weight !== 0;
            })
            .sort(function (nodeA, nodeB) {
                return nodeA.weight - nodeB.weight;
            })
        ;

        if (deadEndNodes.length) {
            // first try to find the root-message
            nodeWalk = deadEndNodes.find(function (node) {
                return node.message.isRoot;
            });
            // if root-message is not found get dead-end with smallest weight
            nodeWalk = nodeWalk ? nodeWalk : deadEndNodes.pop();
        } else {
            // when there is no deadEnd then just start (and end) with the start-node
            nodeWalk = nodes.find(function (node) {
                return node.weight === 0;
            });
        }

        while (nodeWalk) {
            msg = nodeWalk.message;

            this.addElement(msg);

            if (msg.isIncoming && nlpTemplates.length) {
                for (i = 0; i < nlpTemplates.length; i++) {
                    if (msg.uuid !== nlpTemplates[i].uuid) {
                        this.addElement(nlpTemplates[i]);
                    }
                }
            }

            if (!nodeWalk.prev) {
                this.addNLPTemplates(msg);
                break;
            }

            nlpTemplates = this.createNLPTemplates(msg);

            relations = msg.getRelationsFrom();
            for (i = 0; i < relations.length; i++) {
                relation = relations[i];

                if (!relation.to || relation.to.uuid !== nodeWalk.prev.message.uuid) {
                    continue;
                }

                this.addElement(relation);
                break;
            }

            // here prev is actually the 'next' starting from possible root-node
            nodeWalk = nodeWalk.prev;
        }
        digestIfNeeded(this.$scope);
        this.redrawConnections(1000);
    };

    /**
     * @typedef {Object} Model~ActionWithMessages
     * @property {Model.Action} action
     * @property {Model.Message[]} messages
     */

    /**
     * @namespace
     * @alias Controller.Component.sMessageLog
     *
     * This controller is responsible for displaying messages
     * Depending on the mode it can show
     * - message history
     * - conversation
     * @param {Service.sConfirm} sConfirm
     * @param $mdDialog
     * @param $scope
     * @param $element
     * @param {Number} maxFileSize
     * @param Notification
     * @param $exceptionHandler
     * @param $location
     * @param $anchorScroll
     * @param {sSource.Service.sSourceService} sSource
     * @constructor
     */
    var sMessageLog = function(
        sConfirm,
        $mdDialog,
        $scope,
        $element,
        maxFileSize,
        Notification,
        $exceptionHandler,
        $location,
        $anchorScroll,
        sSource
    ) {
        var self            = this,
            fetching        = false,
            isEditableCache = null
            ;

        this.notification      = Notification;
        this.$exceptionHandler = $exceptionHandler;
        this.$location         = $location;
        this.$anchorScroll     = $anchorScroll;
        this.sSource           = sSource;

        /**
         * @property
         * @type Model.Message.Collection
         * @name Controller.Component.sMessageLog#messageCollection
         */

        /**
         * @property
         * @type Boolean
         * @name Controller.Component.sMessageLog#isEditable
         */

        /**
         * @property
         * @type Service.sConfirm
         * @name Controller.Component.sMessageLog#sConfirm
         */
        this.sConfirm = sConfirm;

        /**
         * @property
         * @type $mdDialog
         * @name Controller.Component.sMessageLog#$mdDialog
         */
        this.$mdDialog = $mdDialog;


        /**
         * @property
         * @type Scope
         * @name Controller.Component.sMessageLog#$scope
         */
        this.$scope = $scope;

        /**
         * @property
         * @type jQuery
         * @name Controller.Component.sMessageLog#$element
         */
        this.$element = $element;

        /**
         * Stores all watch unRegisters
         * @type {Array}
         * @name Controller.Component.sMessageLog#$deRegister
         */
        this.$deRegister = [];

        this.placeHolders = [];

        this.maxFileSize = maxFileSize;

        this.numOfConnectionsRedrawing = 0;

        var contents = [],
            contentsCache = [],
            actionsTakenCache = [],
            refreshActionsTakenCache = function refreshActionsTakenCache() {
                var actionByTypeAndValue = {},
                    /**
                     * @type {Model.ActionWithMessages[]}
                     */
                    actionsFromIncomingMessages = contentsCache.filter(function(content) {
                        return content instanceof Model.Message && content.isIncoming;
                    }).reduce(
                        /**
                         * @param {Array} carriedActionsWithMessages
                         * @param {Model.Message} message
                         * @return {Array}
                         */
                        function(carriedActionsWithMessages, message) {
                            var firstPart = message.firstPart();

                            if (!firstPart) {
                                return carriedActionsWithMessages;
                            }

                            firstPart.getMetaFields(Model.Message.Part.KEY_NS_ACTIONS).map(function(metaFieldName) {
                                var value = firstPart.getMeta(metaFieldName, null, Model.Message.Part.KEY_NS_ACTIONS),
                                    lookup = [metaFieldName],
                                    action,
                                    actionWithMessages  // << contains action and all occurrences (msguuid) of it
                                ;

                                if (value) {
                                    lookup.push(value);
                                }

                                if (!(actionWithMessages = actionByTypeAndValue[lookup.join('-')])) {
                                    actionWithMessages = {
                                        action: new Model.Action(),
                                        messages: []
                                    };

                                    actionByTypeAndValue[lookup.join('-')] = actionWithMessages;
                                    carriedActionsWithMessages.push(actionWithMessages);
                                }


                                actionWithMessages.action.type = metaFieldName;
                                actionWithMessages.action.value = value;
                                if (actionWithMessages.messages.indexOf(message.uuid) === -1) {
                                    actionWithMessages.messages.push(message.uuid);
                                }

                                return action;
                            });


                            return carriedActionsWithMessages;
                        }, []);

                return actionsTakenCache.splice.apply(
                    actionsTakenCache,
                    [0, actionsTakenCache.length].concat(actionsFromIncomingMessages)
                );
            },
            refreshContentsCache = function() {
                contentsCache.splice.apply(contentsCache, [0, contentsCache.length].concat(contents));
                self.onMessageLaneChange({items: Model.UUID.mapByUUID(contents).unique()});
                refreshActionsTakenCache();
            }
            ;

        /**
         * Stores the contents of the messageLog
         * @type {Model.UUID[]}
         * @private
         * @name Controller.Component.sMessageLog#contents
         */

        /**
         * Stores when the last redraw occurred
         * Redraw's shouldn't occur more often then reasonable (30 fps >> 33.3ms)
         * @name Controller.Component.sMessageLog#lastRedraw
         * @type {number}
         */
        this.lastRedraw = 0;

        Object.defineProperties(
            this,
            {
                /**
                 * @property
                 * @name Controller.Component.sMessageLog#items
                 */
                items: {
                    get:
                    /**
                     * @returns {Model.UUID[]}
                     */
                    function() {
                        return contentsCache;
                    }
                },
                fetching: {
                    get: function () {
                        return fetching;
                    },
                    set: function (val) {
                        if (!val && fetching) {
                            var $element = $('s-message').firstInView(self.$element);
                            if ($element.length) {
                                self.$stickyPosition = {
                                    $element: $element,
                                    top: $element.offset().top
                                };
                            } else {
                                self.$stickyPosition = 'parent';
                            }
                        }

                        fetching = val;
                    }
                },
                isEditable: {
                    get: function() {
                        if (isEditableCache === null) {
                            isEditableCache = false;

                            if (self.messageControls) {
                                isEditableCache = Controller.Component.View.sMessage.getActionMarkedAsEdit(self.messageControls);
                            }
                        }
                        return isEditableCache;
                    }
                },
                actionsTaken: {
                    enumerable: true,
                    get: function () {
                        return actionsTakenCache;
                    }
                    /**
                     * @property
                     * @name Controller.Component.sMessageLog#actionsTaken
                     * @type {Model.ActionWithMessages[]}
                     */
                },
                showMessageGoals: {
                    get: function () {
                        return this.isEditable && !this.noPostbackMessages;
                    }
                    /**
                     * @property
                     * @name Controller.Component.sMessageLog#showMessageGoals
                     * @type {Boolean}
                     */
                }
            }
        );

        this.resetEditableCache = function resetEditableCache() {
            isEditableCache = null;
        };

        /**
         * Adds a new element to the collection
         * @name Controller.Component.sMessageLog#addElement
         * @param {Model.UUID} element
         * @param {Boolean=} prepend Controls if the element should be appended (false) or prepended (true)
         */
        this.addElement = function addElement(element, prepend) {
            Object.instanceOf(element, Model.UUID);
            if (!prepend) {
                contents.push(element);
            } else {
                contents.unshift(element);
            }
            refreshContentsCache();
        };

        /**
         * Returns the index of all occurrences in the collection
         * @name Controller.Component.sMessageLog#getElementIndices
         * @param {Model.UUID} element
         * @returns {Array}
         */
        this.getElementIndices = function getElementIndices(element) {
            Object.instanceOf(element, Model.UUID);
            return this.getUuidIndices(element.uuid);
        };

        /**
         * Returns the index of all occurrences in the collection
         * @name Controller.Component.sMessageLog#getUuidIndices
         * @param {String} uuid
         * @returns {Array}
         */
        this.getUuidIndices = function getUuidIndices(uuid) {
            var indices = [];
            for (var i = 0; i < contents.length; i++) {
                if (contents[i].uuid === uuid) {
                    indices.push(i);
                }
            }

            return indices;
        };

        /**
         * Removes elements starting from position
         * @name Controller.Component.sMessageLog#removeElements
         * @param {Number} fromPos
         */
        this.removeElements = function removeElements(fromPos) {
            contents.splice(fromPos);
            refreshContentsCache();
        };

        /**
         * Removes the first element from the contents and returns it
         * @name Controller.Component.sMessageLog#shiftContents
         * @returns {Model.UUID}
         */
        this.shiftContents = function shiftContents() {
            var shifted = contents.shift();
            refreshContentsCache();

            return shifted;
        };

        /**
         * @name Controller.Component.sMessageLog#removeElementsByUuid
         * @param uuid
         */
        this.removeElementsByUuid = function removeElementsByUuid(uuid) {
            var tmp = new Model.UUID(uuid),
                indices = this.getElementIndices(tmp)
                ;

            if (!indices.length) {
                return;
            }

            // start kicking out elements from the back so the lower indices remain correct
            for (var i = indices.length - 1; i >= 0; i--) {
                contents.splice(indices[i], 1);
            }

            refreshContentsCache();
        };

        /**
         * Replaces the contents by the provided relation
         * @name Controller.Component.sMessageLog#replaceContents
         * @function
         * @param relation
         * @param index
         * @param visibility
         * @param {Boolean=} noUpdate
         */
        this.replaceContents = function replaceContents(relation, index, visibility, noUpdate) {
            var execute = function executeReplaceContents(relation, index, visibility, noUpdate) {
                if (this.model instanceof Model.Message.FetchAbleCollection) {
                    return;
                }
                var lastValidIndex
                ;

                if (!relation) {
                    return;
                }

                if (visibility) {
                    if (!relation.to) {
                        this.messageCollection.addAndRelateMessage(relation);
                    } else {
                        if (relation.type === Model.Message.Relation.TYPE_NLP) {
                            lastValidIndex = this.getLastOccurrenceIndexBefore(relation, index);
                        } else {
                            lastValidIndex = this.getFirstOccurrenceIndexAfter(relation, index);
                        }

                        if (lastValidIndex !== -1 && relation.uuid === contents[lastValidIndex].uuid) {
                            visibility = false;
                        }
                    }
                }

                if (relation.from) {
                    if (relation.from.isIncoming && relation.type === Model.Message.Relation.TYPE_FOLLOWED_BY) {
                        var relations = relation.from.getRelationsTo();
                        relations = relations.filter(function (relation) {
                            return relation.type === Model.Message.Relation.TYPE_NLP;
                        });

                        if (relations.length) {
                            relation = relations.shift();
                        }

                        lastValidIndex = this.getLastOccurrenceIndexBefore(relation.from, index);
                    } else {
                        // if the relation is of type NLP, then we have to go back until we find the from message
                        if (relation.type === Model.Message.Relation.TYPE_NLP) {
                            lastValidIndex = this.getLastOccurrenceIndexBefore(relation.from, index);
                        } else {
                            // get the last message that is active on a given lane)
                            lastValidIndex = this.getFirstOccurrenceIndexAfter(relation.from, index);
                        }
                    }
                } else {
                    lastValidIndex = 0;
                }

                // remove everything else
                if (lastValidIndex < contents.length - 1) {
                    contents.splice(lastValidIndex + 1);
                }

                // add relation and next message
                if (visibility) {
                    contents.push(relation, relation.to);
                }

                if (!relation.from.isIncoming) {
                    this.addNLPTemplates(relation.from, false, lastValidIndex);
                } else if (relation.from.isRoot && visibility) {
                    this.addNLPTemplates(relation.to, false, lastValidIndex);
                }

                if (visibility) {
                    this.addStraightForwardMessageIfNeeded(relation, lastValidIndex);
                }

                if (!noUpdate) {
                    refreshContentsCache();
                    this.redrawConnections();
                }

                this.redrawConnections(1000);
            }.bind(this); // avoid binding on the invoker, already bound functions maintain bound context

            Model.Versionable.executeChange(execute, this.messageCollection, 'replaceContents', relation, index, visibility, noUpdate);
        };

        /**
         * @name Controller.Component.sMessageLog#replayContents
         */
        this.replayContents = function replayContents() {
            var relations = contents.filter(
                /**
                 *
                 * @param {Model.Message.Relation} element
                 * @returns {Boolean}
                 */
                function(element) {
                    return element instanceof Model.Message.Relation && element.to && (element.to.isIncoming || element.from.isRoot);
                }),
                indices = {}
                ;

            contents.splice(1);

            if (relations.length) {
                for (var i = 0; i < relations.length; i++) {
                    var number = indices[relations[i].uuid] = indices[relations[i].uuid] || 0,
                        tmp = new Model.UUID(relations[i].from.uuid),
                        index = (this.getElementIndices(tmp)[number] || (contents.length - 1))
                    ;

                    indices[relations[i].uuid]++;
                    this.replaceContents(relations[i], index, true, true);
                }
            } else {
                if (contents.length === 0)  {
                    this.addElement(this.messageCollection.messages.slice(0, 1).pop());
                }
            }

            var message = contents[0];
            if (message instanceof Model.Message) {
                if (!message.isRoot && message.isEmpty() && this.messageCollection.length) {
                    this.removeElements(0);
                    this.addElement(this.messageCollection.messages.slice(0, 1).pop());
                }
                this.addNLPTemplates(message);
            }

            refreshContentsCache();
            this.redrawConnections(1000);
        };

        /**
         * @name Controller.Component.sMessageLog#createNLPTemplates
         * @param {Model.Message} msg
         * @param {Number=} index
         * @returns Array
         */
        this.createNLPTemplates = function createNLPTemplates(msg, index) {
            var tmpArr = [],
                // add NLP templates - excluding the active one if there is any
                relations = msg.getRelationsFrom(),
                relation,
                i,
                placeHolder,
                lastMsg
            ;

            /**
             * Index can be greater if there was a relation before,
             * that will be replaced by the new relation
             * in that case we need to revert by 2, since revert by 1 is a message
             */
            if (index && index === contents.length) {
                index = contents.length - 2;
            }

            /**
             * Only add the AI templates if there is a direct index specified or the source message is last in the log
             */
            if (index === undefined && (lastMsg = contents.slice(-1).pop()) && lastMsg !== msg) {
                return tmpArr;
            }

            for (i = (contents.length - 1); i >= Math.max((index || 0), 0); i--) {
                if (contents[i] instanceof Model.Message.Relation) {
                    relation = contents[i];
                }

                if (contents[i].uuid === msg.uuid) {
                    break;
                }
            }

            for (i = 0; i < relations.length; i++) {
                if (relations[i].type !== Model.Message.Relation.TYPE_NLP || (relation && relations[i].uuid === relation.uuid)) {
                    continue;
                }
                tmpArr.push(relations[i].to);
            }

            tmpArr.sort(
                /**
                 *
                 * @param {Model.Message} a
                 * @param {Model.Message} b
                 */
                function(a, b) {
                    return a.firstPart().isFallback - b.firstPart().isFallback;
                });

            if ((placeHolder = this.getOrCreateNLPFallbackPlaceholder(msg))) {
                tmpArr.push(placeHolder);
            }

            return tmpArr;
        };

        /**
         * @name Controller.Component.sMessageLog#addNLPTemplates
         * @param {Model.Message} msg
         * @param {Boolean=} prepend
         * @param {Number=} index
         */
        this.addNLPTemplates = function addNLPTemplates(msg, prepend, index) {
            var tmpArr = this.createNLPTemplates(msg, index),
                i
            ;

            if (prepend) {
                for (i = tmpArr.length - 1; i >= 0; i--) {
                    contents.unshift(tmpArr[i]);
                }
            } else {
                for (i = 0; i < tmpArr.length; i++) {
                    contents.push(tmpArr[i]);
                }
            }
            refreshContentsCache();
        };

        /**
         * @param {Model.Message.Relation} relation
         */
        this.addStraightForwardRelation = function addStraightForwardRelation(relation) {
            if (!relation.to) {
                return;
            }

            contents.push(relation, relation.to);
            this.addNLPTemplates(relation.to, false, contents.length - 1);
        };

        /**
         * @param {Model.Message.Part} part
         * @param {Number?} index
         */
        this.onRemoveMessagePart = function onRemoveMessagePart(part, index) {
            return Model.Versionable.executeChange(sMessageLog.prototype.onRemoveMessagePart.bind(this), this.messageCollection, 'removeMessagePart', part, index);
        };

        /**
         * @param event
         * @param content
         * @param $scrollParent
         * @return {*}
         */
        this.handleAddMessageByContent = function handleAddMessageByContent(event, content, $scrollParent) {
            return Model.Versionable.executeChange(sMessageLog.prototype.handleAddMessageByContent.bind(this), this.messageCollection, 'addMessageByContent',  event, content, $scrollParent);

        };

        /**
         * @param toElement
         * @param _element
         * @param position
         * @return {*}
         */
        this.onDropPart = function onDropPart(toElement, _element, position) {
            return Model.Versionable.executeChange(sMessageLog.prototype.onDropPart.bind(this), this.messageCollection, 'onDropPart', toElement, _element, position);
        };

        /**
         * @param data
         * @return {*}
         */
        this.handleEditMessageDialogSave = function handleEditMessageDialogSave(data) {
            return Model.Versionable.executeChange(sMessageLog.prototype.handleEditMessageDialogSave.bind(this), this.messageCollection, 'handleDialogSave', data);
        };

        this.handleFileDrop = function handleFileDrop(files) {
            return Model.Versionable.executeChange(sMessageLog.prototype.handleFileDrop.bind(this), this.messageCollection, 'handleFileDrop', files);
        };

        /**
         * @param {SimpleChange} changes
         */
        this.$onChanges = function $onChanges(changes) {
            Object.getPrototypeOf(this).$onChanges.call(this, changes);

            if (changes.messageCollection && !changes.messageCollection.isFirstChange()) {
                contents = [];
                this.replayContents();
            }
        };

        /**
         * @property
         * @name Controller.Component.sMessageLog#highlightMessageUuid
         * @type {String}
         */
        this.highlightMessageUuid = null;

        /**
         * @property
         * @name Controller.Component.sMessageLog#highlightMessageGoal
         * @type {Boolean}
         */
        this.highlightMessageGoal = false;

        /**
         * @property
         * @name Controller.Component.sMessageLog#updatingHash
         * @type {Boolean}
         */
        this.updatingHash = false;
    };

    /**
    * @param {SimpleChange} changes
    */
    sMessageLog.prototype.$onChanges = function $onChanges(changes) {
        if (changes.messageControls && changes.messageControls.currentValue) {
            Object.instanceOf(changes.messageControls.currentValue, Model.Menu.Menu);
        }

        this.resetEditableCache();
    };

    /**
     * @name Controller.Component.sMessageLog#$onInit
     */
    sMessageLog.prototype.$onInit = function $onInit() {
        var self      = this,
            $document = $(document);

        this.$deRegister.push(this.$scope.$watch(function () {
            return self.messageCollection ? self.messageCollection.length : null;
        }, function (val) {
            if (!val) {
                return;
            }
            self.syncWithCollection();
        }));

        var $scrollParent = this.$element.scrollParent();

        if ($scrollParent[0] === document) {
            $scrollParent = this.$element;
        } else {
            this.$deRegister = this.$deRegister.concat($scrollParent.$on(
                'scroll', this.handleScroll.bind(this)
            ));
        }

        this.$deRegister = this.$deRegister.concat(this.$element.$on(
            'transitionend webkitTransitionEnd oTransitionEnd',
            'sMessageLog',
            function () {
                self.redrawConnectionsHandler();
            }
        ));

        // prevent the menu from opening if there is only one possible choice
        this.$deRegister = this.$deRegister.concat(
            $(document).$on(
                Controller.Component.sMessageInputBox.EVENT_BEFORE_MESSAGE_TYPE_MENU_OPEN,
                this.handleBeforeMessageTypeMenu.bind(this)
            )
        );

        this.$deRegister = this.$deRegister.concat(
            $document.$on(EVENT_APPEND_MESSAGE_PART,
                /**
                 * @param {Event} ev
                 * @param {Model.Message.Part} part
                 */
                function(ev, part) {
                    if (ev.context instanceof Model.Message && part.isFallback && !ev.context.canHaveNLPFallback(true)) {
                        return;
                    }

                    self.openEditMessageDialog(part, ev.context);
                })
        );

        this.$deRegister = this.$deRegister.concat(
            this.$scope.$on('sEventMessageCtaClick',
                /**
                 * @param {Event}           $event
                 * @param {Model.Message}   message
                 */
                function ($event, message) {
                    // Prevent event if redrawing is in progress
                    if (self.numOfConnectionsRedrawing > 0) {
                        $event.preventDefault();
                    } else {
                        self.numOfConnectionsRedrawing = 2 * message.getRelationsAsArray().length;
                    }

                    // Always reset the numOfConnectionsRedrawing variable to avoid stuck UI
                    setTimeout(function () {
                        self.numOfConnectionsRedrawing = 0;
                    }, 300);
                }
            )
        );

        this.$deRegister = this.$deRegister.concat(
            this.$scope.$on('redraw-connection-finished',
                function () {
                    self.numOfConnectionsRedrawing = Math.max(0, self.numOfConnectionsRedrawing - 1);
                }
            )
        );

        // TODO after IT-5980  bind this event to the model itself instead of the element
        this.$deRegister = this.$deRegister.concat(
            this.$element.$on('blur', '[contenteditable][ng-model="$ctrl.model.messageName"]', function () {
                self.$scope.$emit(Model.Message.EVENT_MESSAGE_NAME_BLUR);
            })
        );

        this.$deRegister = this.$deRegister.concat(
            this.$scope.$on('$locationChangeSuccess', function () {
                self.scrollToHash();
            })
        );

        this.$deRegister = this.$deRegister.concat(
            $document.$on('remove-relation',
                function(event, relation) {
                    self.removeRelation(relation);
                }
            )
        );

        this.$deRegister = this.$deRegister.concat(
            $document.$on('create-relation',
                function(event) {
                    event.stopPropagation();
                    self.$scope.$emit('content-changed');
                }
            )
        );

        this.$deRegister = this.$deRegister.concat(
            this.$element.$on(
                'dragleave',
                self.redrawConnectionsHandler.bind(self))
        );

        this.$deRegister.push(this.$scope.$on('remove-message-part',
            /**
             * @param event
             * @param {Model.Message.Part} messagePart
             */
            function(event, messagePart) {
                self.onRemoveMessagePart(messagePart);
            }
        ));
        this.$deRegister.push(this.$scope.$on('edit-message-part',
            /**
             * @param event
             * @param {Model.Message.Part} part
             * @param {Model.Message?} message
             */
            function(event, part, message) {
                if (!(part instanceof Model.Message.Part)) {
                    return;
                }

                self.openEditMessageDialog(part, message);
            }
        ));

        this.$deRegister.push(
            self.$scope.$watch(function() {
                    return $scrollParent[0].scrollHeight + $scrollParent.outerHeight();
            },
                function () {
                    if (self.$stickyPosition && !self.fetching) {
                        if (self.$stickyPosition === 'parent') {
                            self.scrollToBottom();
                            self.$stickyPosition = null;
                        } else {
                            $scrollParent.scrollTop($scrollParent.scrollTop() + self.$stickyPosition.$element.offset().top - self.$stickyPosition.top);

                            if (self.$stickyPosition.destroy) {
                                clearTimeout(self.$stickyPosition.destroy);
                            }
                            self.$stickyPosition.destroy = setTimeout(function () {
                                self.$stickyPosition = null;
                            }, 500);
                        }
                    }
                self.redrawConnections(50);
            })
        );

        var $initScrollToBottom = self.$scope.$watch(function () {
            return $scrollParent[0].scrollHeight;
        }, function () {
            setTimeout(function () {
                self.scrollToBottom();
                $initScrollToBottom();
            }, 500);
        });

        this.$deRegister.push(
            self.$scope.$watch(function() {
                return self.isEditable;
            },
            function() {
                self.redrawConnections(10);
            })
        );

        this.$deRegister.push(
            self.$scope.$watch(function() {
                return self.$element.innerWidth() * self.$element.innerHeight();
            },
            function() {
                self.redrawConnections(5);
                self.redrawConnections(250);
            })
        );

        this.$deRegister.push(
            this.$scope.$on(EVENT_ADD_MESSAGE_BY_CONTENT, function(event, content) {
                self.handleAddMessageByContent(event, content, $scrollParent);
            })
        );

        this.$deRegister.push(
            this.$scope.$on(EVENT_MESSAGE_LOG_SCROLL_TO_BOTTOM, function() {
                self.scrollToBottom();
            })
        );

        this.$deRegister = this.$deRegister.concat(this.$element.$on('drop', function(evt) {
            evt.preventDefault();
            evt.stopPropagation();

            if (!self.isEditable || self.getOrCreateMessage().isIncoming) {
                return;
            }

            self.$element.removeClass('drop-zone');

            Model.sFile.uploadFiles(
                evt.originalEvent.dataTransfer.files,
                self.maxFileSize,
                evt.originalEvent.dataTransfer.files.length === 1
                    ? Model.Message.Part.acceptedMediaMimeTypes.split(',')
                    : Model.Message.Part.acceptedImageUploadMimeTypes.split(',')
            )
                .then(self.handleFileDrop.bind(self))
                .progress(function(err) {
                    self.notification.error(err.toString());
                });
        }));

        this.$deRegister = this.$deRegister.concat(this.$element.$on('dragover', function(evt) {
            evt.preventDefault();
            evt.stopPropagation();

            if (self.isEditable && !self.getOrCreateMessage().isIncoming && evt.originalEvent.dataTransfer.types.indexOf('Files') !== -1) {
                evt.originalEvent.dataTransfer.dropEffect = 'copy';
                self.$element.addClass('drop-zone');
            }
        }));

        this.$deRegister = this.$deRegister.concat(this.$element.$on('dragleave', function() {
            self.$element.removeClass('drop-zone');
        }));

        this.scrollToBottom = function scrollToBottom() {
            $scrollParent.scrollTop(999999999);
        };

        setTimeout(function () {
            self.scrollToHash();
        }, 1000);
    };

    /**
     * UnRegister events and watchers
     * @name Controller.Component.sMessageLog#$onDestroy
     */
    sMessageLog.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    sMessageLog.prototype.instanceOf = function instanceOf(obj, type) {
        return obj instanceof Object.namespace(type);
    };

    /**
     * @name Controller.Component.sMessageLog#setContentsByMessageUuid
     * @function
     * @param uuid
     */
    sMessageLog.prototype.setContentsByMessageUuid = function setContentsByMessageUuid(uuid) {
        executeSetContentsByMessageUuid.call(this, uuid);
    };

    /**
     * @param event
     * @param content
     * @param $scrollParent
     * @return {Boolean}
     */
    sMessageLog.prototype.handleAddMessageByContent = function handleAddMessageByContent(event, content, $scrollParent) {
        if (!this.isEditable || !content.toString().trim().length) {
            return false;
        }

        var message = this.getOrCreateMessage(),
            part    ,
            reDraw  = function() {
                return setTimeout(function () {
                    var $elements = $('[data-id="' + part.uuid + '"]'),
                        eBottom = $elements[0].getBoundingClientRect().top + $elements[0].getBoundingClientRect().height,
                        pHeight = $scrollParent[0].getBoundingClientRect().height
                    ;

                    if (eBottom > pHeight) {
                        $scrollParent.scrollTop($scrollParent.scrollTop() + eBottom - pHeight);
                    }
                }, 250)
            };

        if (!message.isIncoming) {
            part = message.createAndAddPart(Model.Message.Part.DEFAULT_TYPE_OUTGOING);
            part.content.body = content;
            reDraw();
        } else {
            if (!(part = message.firstPart())) {
                part = Model.Message.Part.create(Model.Message.Part.DEFAULT_TYPE_INCOMING);
            }
            var matchText = part.content.matches.createAndAdd();
            matchText.pattern = content;
            this.openEditMessageDialog(part, message);
        }
    };

    /**
     *
     * @param event
     */
    sMessageLog.prototype.handleBeforeMessageTypeMenu = function handleBeforeMessageTypeMenu(event) {
        if (!this.isEditable) {
            return;
        }

        var msg = this.getOrCreateMessage();

        if (!msg.isRoot) {
            return;
        }

        // set context message for pop up menu
        event.context = msg;

        if (!msg.isIncoming) {
            return;
        }

        var part = msg.firstPart();
        if (!part) {
            part = Model.Message.Part.create(Model.Message.Part.TYPE_AI_REACTION);
        }

        this.openEditMessageDialog(part, msg);
        event.preventDefault();
    };

    /**
     * Gets active relation originating from message
     * @param {Model.Message} message
     * @param index
     * @name Controller.Component.sMessageLog#getActiveRelationFrom
     * @returns {*}
     */
    sMessageLog.prototype.getActiveRelationFrom = function getActiveRelationFrom(message, index) {
        return getActiveRelation.call(this, 'from', message, index);
    };

    /**
     * Gets active relation leading to message
     * @param {Model.Message} message
     * @param index
     * @name Controller.Component.sMessageLog#getActiveRelationTo
     * @returns {*}
     */
    sMessageLog.prototype.getActiveRelationTo = function getActiveRelationTo(message, index) {
        return getActiveRelation.call(this, 'to', message, index);

    };

    /**
     * @name Controller.Component.sMessageLog#getFirstOccurrenceIndexAfter
     * @param element
     * @param index
     * @returns {*}
     */
    sMessageLog.prototype.getFirstOccurrenceIndexAfter = function getFirstOccurrenceIndexAfter(element, index) {
        var found = sMessageLog.getFirstElement(
            sMessageLog.filterLessThen(
                this.getElementIndices(element),
                index
            )
        );

        return found === undefined ? -1 : found;
    };

    /**
     * @name Controller.Component.sMessageLog#getLastOccurrenceIndexBefore
     * @param element
     * @param index
     * @returns {*}
     */
    sMessageLog.prototype.getLastOccurrenceIndexBefore = function getLastOccurrenceIndexBefore(element, index) {
        var found = sMessageLog.getLastElement(
            sMessageLog.filterGreaterThen(
                this.getElementIndices(element),
                index
            )
        );

        return found === undefined ? -1 : found;
    };

    /**
     * @function
     * @name Controller.Component.sMessageLog#onDropPart
     * @param toElement
     * @param _element
     * @param position
     * @return {$.Deferred}
     */
    sMessageLog.prototype.onDropPart = function(toElement, _element, position) {
        var fromElement = this.messageCollection.getMessageByUuid(_element.parentUUID),
            element     = fromElement.parts[_element.uuid]
            ;

        try {
            toElement.insertBeforePosition(element, position);
            this.redrawConnections(600);
        } catch (e) {
            if (e instanceof Model.Exception.MultipleInteractiveParts) {
                return this.sConfirm.open(sMessageLog.splitMessageOptions).then(function() {
                    e.target.splitMessage(e.messagePart);
                });
            } else {
                throw e;
            }
        }

        this.$scope.$emit('content-changed');
        this.redrawConnections(500);

        return $.Deferred().resolve().promise();
    };

    /**
     * @name Controller.Component.sMessageLog#getOrCreateMessage
     * @returns {Model.Message|null}
     */
    sMessageLog.prototype.getOrCreateMessage = function getOrCreateMessage() {
        if (!this.isEditable) {
            return null;
        }

        var length = this.messageCollection.length,
            msg = this.messageCollection.getOrCreateMessage()
            ;

        if (!length) {
            this.addElement(msg);
        }

        var outgoing = this.items.filter(
            /**
             * @param {Model.Message} msg
             * @returns {Boolean}
             */
            function(msg) {
                // Model.Message.PlaceHolder can also be in the active lane
                return msg instanceof Model.Message && msg.needsCounter();
            }
        );

        return sMessageLog.getLastElement(outgoing);
    };

    /**
     * @name Controller.Component.sMessageLog#onRemoveMessagePart
     * @param {Model.Message.Part} part
     * @param {Number=} index
     */
    sMessageLog.prototype.onRemoveMessagePart = function onRemoveMessagePart(part, index) {
        var relation,
            lastIndex,
            msg
            ;

        if (!part || !(msg = part.parent)) {
            return;
        }

        var removeFn = function() {
            if (part.isInteractive) {
                relation = this.getActiveRelationFrom(msg, index);
                if (relation && relation.to) {
                    if ((lastIndex = this.getFirstOccurrenceIndexAfter(relation, index - 1)) > -1) {
                        this.removeElements(lastIndex);
                    }
                }
            }
            part.remove();

            if (!Object.getOwnPropertyNames(msg.parts).length && !msg.isRoot && !msg.isDynamicContentMessage()) {
                if (msg.isIncoming) {
                    for (var i in msg.relations) {
                        this.removeElementsByUuid(i);
                    }
                }

                this.messageCollection.removeMessage(msg);

                this.replayContents();
            } else {
                this.$scope.$emit('content-changed');
            }
            this.redrawConnections();
            this.redrawConnections(800);
        }.bind(this);

        if (!msg.isRoot && !msg.isIncoming && msg.partsLength() === 1 && msg.getRelationsByType(Model.Message.Relation.TYPE_NLP).length) {
            this.sConfirm.open(sMessageLog.removeAttachedNLPOptions).then(removeFn);
        } else {
            removeFn();
        }
    };

    /**
     * @param {Model.Message.Part} messagePart
     * @param {Model.Message=} message The message parent (create new if unset)
     * @param {Number=} index The index in the messageLog (last one if unset)
     */
    sMessageLog.prototype.openEditMessageDialog = function openEditMessageDialog(messagePart, message, index) {
        if (!this.isEditable) {
            return;
        }

        var self        = this,
            isIncoming  = Model.Message.Part.isIncomingType(messagePart.type),
            targetMessage
            ;

        if (!message || Boolean(message.isIncoming) !== isIncoming) {
            if (messagePart.parent) {
                message = messagePart.parent;
            } else {
                if (isIncoming) {
                    if (message) {
                        targetMessage = message;
                    }
                    message = new Model.Message();
                    message.setIncoming();
                } else {
                    message = this.getOrCreateMessage();
                }
            }
        }

        // place as last if not specified
        if (index === undefined) {
            index = this.items.length - 1;
        }

        return this.$mdDialog.show({
            controller              : Controller.sMessageAdmin.EditorController,
            controllerAs            : '$ctrl',
            bindToController        : true,
            templateUrl             : isIncoming ? 'smessageadmin:template-edit' : 'smessageadmin:edit',
            parent                  : angular.element(Const.PanelAnchor),
            clickOutsideToClose     : false,
            locals                  : {
                messagePart         : messagePart,
                messages            : self.messageCollection.messages,
                message             : message,
                messageAnchor       : self.messageCollection.getAnchor(),
                index               : index,
                relateFrom          : targetMessage ? targetMessage : this.getRelateFrom(message),
                noPostbackMessages  : this.noPostbackMessages || (isIncoming && message.isRoot)
            },
            resolve                 : {
                contextSpecificStaticPlaceholders : this.sSource.getCachedPlaceholders.bind(this.sSource, [], true)
            }
        })
        .then(self.handleEditMessageDialogSave.bind(self))
        .catch(function (err) {
            if (err) {
                self.$exceptionHandler(err);
            }
        });
    };

    /**
     * @name Controller.Component.sMessageLog#handleEditMessageDialogSave
     * @param {Object} data
     * @return {$.Deferred}
     */
    sMessageLog.prototype.handleEditMessageDialogSave = function handleEditMessageDialogSave(data) {
        var messagePart = data.part,
            msg         = data.message,
            self        = this
            ;

        Object.instanceOf(messagePart, Model.Message.Part);

        messagePart.resetCache();

        if (msg && msg.isIncoming) {
            // check if the message is already part of the collection
            if (!this.messageCollection.getMessageByUuid(msg.uuid)) {
                this.messageCollection.appendMessage(msg);
            }

            msg.addPart(messagePart);
            return this.handleIncomingMessage(msg, data.relateFrom);
        }

        try {
            if (!(msg)) {
                msg = this.getOrCreateMessage();
            }
            msg.addPart(messagePart);
        } catch (e) {
            if (e instanceof Model.Exception.MultipleInteractiveParts) {
                return this.sConfirm.open(sMessageLog.splitMessageOptions).then(function() {
                    e.target.splitMessage(e.messagePart);
                    self.$scope.$emit('content-changed');
                    self.redrawConnections(500);
                });
            } else {
                throw e;
            }
        }

        // check relations effect on the message log
        for (var i in msg.relations) {
            var indices = this.getElementIndices(msg.relations[i]);
            if (!indices.length) {
                // the relation is never active no need to do anything
                continue;
            }

            var firstOccurrence = indices.shift();

            // the relation has a to side
            // check if the relation needs AND has a follow by message properly set
            if (msg.relations[i].to) {
                // followed by M1 > R1 > MI1 > RI1
                // >> M1 > R1 > MI1' > RI1'
                // R1 suffers change in
                this.addStraightForwardMessageIfNeeded(msg.relations[i], firstOccurrence);
                continue;
            }

            // the relation is active, but doesn't lead anywhere > whatever is after shall be removed
            this.removeElements(firstOccurrence);
        }

        this.$scope.$emit('content-changed');
        this.redrawConnections(500);

        return $.Deferred().resolve().promise();
    };

    /**
     * Handles incoming message save
     * @param {Model.Message} msg
     * @param {Model.Message} relateFrom
     * @retrun {$.Deferred}
     */
    sMessageLog.prototype.handleIncomingMessage = function(msg, relateFrom) {
        var relation;

        // we dont have relateFrom if we create the first message as incoming message (AI-Template)
        if (!msg.isRoot) {
            relation = msg.getOrCreateMessageRelationByOption(relateFrom);
            this.messageCollection.addAndRelateMessage(relation);
        }

        this.replayContents();
        return $.Deferred().resolve().promise();
    };

    /**
     * @param {Model.Message} msg
     */
    sMessageLog.prototype.getRelateFrom = function getRelateFrom(msg) {
        var i,
            relateFrom
            ;

        if (msg.isRoot) {
            return null;
        }

        for (i = 0; i < this.placeHolders.length; i++) {
            if (this.placeHolders[i].uuid === msg.uuid && this.placeHolders[i].type === Model.Message.Relation.TYPE_NLP) {
                relateFrom = this.placeHolders[i].message;
                break;
            }
        }

        if (msg.isIncoming) {
            var relationsTo = msg.getRelationsTo();
            if (relationsTo.length === 1) {
                relateFrom = relationsTo.pop().from;
            }
        }

        // if placeholder is not found it has to be replaced with an element if it is incoming
        if (!relateFrom && msg.isIncoming) {
            relateFrom = this.getOrCreateMessage();
        }

        return relateFrom;
    };

    /**
     * @name Controller.Component.sMessageLog#addNLPFallback
     * @param {Model.Message.PlaceHolder} placeHolder
     */
    sMessageLog.prototype.addNLPFallback = function addNLPFallback(placeHolder) {
        if (!this.isEditable) {
            return;
        }

        var message     = new Model.Message(placeHolder.uuid),
            messagePart = message.createAndAddPart('AITemplate')
            ;

        message.setIncoming();
        messagePart.isFallback = true;
        messagePart.content.intent = 'Fallback';

        this.openEditMessageDialog(messagePart, message);
    };

    /**
     * @name Controller.Component.sMessageLog#getIndicesBefore
     * @param {Model.UUID} element
     * @param {Number} index
     */
    sMessageLog.prototype.getIndicesBefore = function getIndicesBefore(element, index) {
        return sMessageLog.filterGreaterThen(this.getElementIndices(element), ++index);
    };

    /**
     * Just a proxy for event handlers, so the event param will not conflict with the delay param
     * @name Controller.Component.sMessageLog#redrawConnectionsHandler
     */
    sMessageLog.prototype.redrawConnectionsHandler = function redrawConnectionsHandler() {
        if (this.lastRedrawReviveTO) {
            clearTimeout(this.lastRedrawReviveTO);
        }
        var time = Date.now();
        // it is possible that we have denied the last redraw, so give it a chance to execute itself after 30ms
        // the next redraw will clear the timeout so this will only hit if it was the last
        if (time - this.lastRedraw < 33.3) {
            this.lastRedrawReviveTO = setTimeout(this.redrawConnections.bind(this), 30);
            return;
        }
        this.redrawConnections();
        this.lastRedraw = time;
    };

    /**
     * @name Controller.Component.sMessageLog#redrawConnections
     * @param {=} delay Can be a number to, indicate the delay in ms, can anything that evaluates to true to indicate the redraw is immediate
     */
    sMessageLog.prototype.redrawConnections = function redrawConnection(delay) {
        var self = this
            ;

        if (delay === undefined) {
            requestAnimationFrame(function() {
                self.$scope.$broadcast('redraw-connection');
            });
        } else if (!isNaN(parseInt(delay))) {
            setTimeout(function() {
                self.$scope.$broadcast('redraw-connection');
            }, parseInt(delay));
        } else {
            self.$scope.$broadcast('redraw-connection');
        }
    };

    /**
     * Remove relation and also remove relation binding from messages
     * @param {Model.Message.Relation} relation
     */
    sMessageLog.prototype.removeRelation = function removeRelation(relation) {
        if (!this.isEditable) {
            return;
        }

        var self = this;

        Object.instanceOf(relation, Model.Message.Relation);
        var indices = this.getElementIndices(relation);
        if (!indices.length) {
            return;
        }

        setTimeout(function() {
            self.replayContents();
            digestIfNeeded(self.$scope);
        }, 1);


        this.redrawConnections(500);
    };

    /**
     * Handles file dnd
     * @param {Array} files
     */
    sMessageLog.prototype.handleFileDrop = function(files) {
        var message = this.getOrCreateMessage()
        ;

        if (files.length === 1) {
            message.createAndAddPart('Media').content.media = files[0];
        } else if (files.length > 1) {
            var part,
                content;

            files.map(function(sFile, index) {
                if (index % 10 === 0) {
                    part = message.createAndAddPart('HScroll');
                    content = part.content;
                } else {
                    content = part.addEmptyContent().content;
                }
                content.media = new Model.sImage(sFile.file, sFile.uuid);
                content.headline = sFile.file.name.split('.', 1).pop();
            });
        }
        digestIfNeeded(this.$scope);
    };

    /**
     * @function
     * @name Controller.Component.sMessageLog#getFromSelectorForRelation
     * @param {Model.Message.Relation} relation
     * @param {Number} sequence
     * @returns {String}
     */
    sMessageLog.prototype.getFromSelectorForRelation = function getFromSelectorForRelation(relation, sequence) {
        var dataSelector        = '[data-id="',
            dataSelectorSuffix  = '',
            firstPart,
            cta
            ;

        if (!relation || !relation.from || !(firstPart = relation.from.firstPart())) {
            return null;
        }

        // use the relation option key to figure out what we're looking for ;)

        if (relation.from.isIncoming) {
            cta = relation.from.getCTAByActionUuid(relation.options.uuid);
            if (cta) {
                dataSelector += cta.uuid;
            } else if (firstPart) {
                dataSelector += firstPart.uuid;
                dataSelectorSuffix = ' .body';
            }
        } else if (!relation.from.isIncoming) {
            if (relation.type === Model.Message.Relation.TYPE_FOLLOWED_BY && (cta = relation.from.getCTAByUuid(relation.options.CTAuuid))) {
                dataSelector += cta.uuid;
            } else if (relation.type === Model.Message.Relation.TYPE_NLP) {
                var lastPart = relation.from.lastPart();
                if (!lastPart) {
                    return null;
                }

                dataSelector += (lastPart.type === Const.QuickReplies)
                    ? relation.from.getPart(Math.max(lastPart.number - 2, 0)).uuid
                    : lastPart.uuid;

                dataSelectorSuffix = ' .body';
            }
        }

        dataSelector += '"]:eq(' + (this.getIndicesBefore(relation.from, sequence).length - 1) + ')';
        return dataSelector + dataSelectorSuffix;
    };

    /**
     * @function
     * @name Controller.Component.sMessageLog#getToSelectorForRelation
     * @param {Model.Message.Relation} relation
     * @param {Number} sequence
     * @returns {String}
     */
    sMessageLog.prototype.getToSelectorForRelation = function getToSelectorForRelation(relation, sequence) {
        var dataSelector    = '[data-id="',
            newPart         = false,
            firstPart
            ;

        if (!relation || !relation.to) {
            return null;
        }

        firstPart = relation.to.firstPart();

        if (relation.to.isIncoming) {
            dataSelector += firstPart.uuid;
        } else if (!relation.to.isIncoming) {
            if (firstPart && (!firstPart.isInteractive || !this.isEditable)) {
                dataSelector += firstPart.uuid;
            } else {
                newPart = true;
                dataSelector += ('new-part-' + relation.to.uuid);
            }
        }

        dataSelector += '"]:eq(' + (this.getIndicesBefore(relation.to, sequence).length - 1) + ')';

        if (!newPart) {
            dataSelector += ' s-content-group:first-child [ng-switch-when]:eq(0)';
        }

        return dataSelector;
    };

    /**
     * @name Controller.Component.sMessageLog#getOrCreateNLPFallbackPlaceholder
     * @param {Model.Message} msg
     */
    sMessageLog.prototype.getOrCreateNLPFallbackPlaceholder = function getOrCreateNLPFallbackPlaceholder(msg) {
        var canHaveNLPFallback = msg.canHaveNLPFallback(),
            placeHolder
            ;

        if (!canHaveNLPFallback) {
            return;
        }

        for (var i = 0; i < this.placeHolders.length; i++) {
            if (this.placeHolders[i].message === msg && this.placeHolders[i].type === Model.Message.Relation.TYPE_NLP) {
                placeHolder = this.placeHolders[i];
                break;
            }
        }

        if (!placeHolder) {
            placeHolder = new Model.Message.PlaceHolder(msg, Model.Message.Relation.TYPE_NLP);
            this.placeHolders.push(placeHolder);
        }

        return placeHolder;
    };

    /**
     * @name Controller.Component.sMessageLog#syncWithCollection
     */
    sMessageLog.prototype.syncWithCollection = function syncWithCollection() {
        if (!this.messageCollection.length) {
            this.removeElements(0);
            return;
        }

        if (this.isInteractive && this.items.length === 0) {
            this.addElement(this.messageCollection.messages[0]);
            this.addNLPTemplates(this.messageCollection.messages[0]);
        } else if (!this.isInteractive) {
            this.removeElements(0);
            for (var i = 0; i < this.messageCollection.length; i++) {
                this.addElement(this.messageCollection.messages[i]);
            }
        }
    };

    sMessageLog.prototype.handleScroll = function (event) {
        if (!(this.messageCollection instanceof Model.Message.FetchAbleCollection)) {
            return;
        }

        var $element    = $(event.target ? event.target : event.originalEvent.src),
            isTop
            ;

        if (!this.messageCollection.fetching
            && ((isTop = $element.scrollTop() === 0) || ($element[0].scrollHeight - $element.height() - $element.scrollTop()) <= 1)
            && isTop === this.messageCollection.isReverse) {
            this.fetchNext();
            digestIfNeeded(this.$scope);
        }
    };

    sMessageLog.prototype.fetchNext = function fetchNext() {
        var self = this;
        this.messageCollection.fetchNext().then(function() {
            digestIfNeeded(self.$scope);
        });
    };

    /**
     * @param {Model.Message.Relation} relation
     * @param {Number} firstOccurrence
     */
    sMessageLog.prototype.addStraightForwardMessageIfNeeded = function addStraightForwardMessageIfNeeded(relation, firstOccurrence) {
        // Auto add destination for forward messages
        if (!relation.to.isIncoming) {
            return;
        }

        if (!relation.to.getRelationsFrom().length) {
            this.messageCollection.addAndRelateMessage(relation);
        }

        var relationOut,
            relationsFrom,
            self            = this
        ;

        // check if there are multiple relations
        switch ((relationsFrom = relation.to.getRelationsFrom()).length) {
            case 0:
                return;
            case 1:
                relationOut = relationsFrom.pop();
                break;
            default:
                // there are more relations available for use
                // so check if any of them is already part of the current flow
                if (!(relationOut = relationsFrom.filter(function(relation) {
                    return self.getFirstOccurrenceIndexAfter(relation, firstOccurrence) !== -1
                }).pop())) {
                    // no active relation found, use the first one
                    relationOut = relationsFrom.shift();
                }

                break;
        }

        var forwardIndex = this.getFirstOccurrenceIndexAfter(relationOut, firstOccurrence);

        // if the target relation is found with a higher index and with no outgoing messages in between
        // then no action should be taken
        if (forwardIndex !== -1
            && this.items.slice(firstOccurrence, forwardIndex).filter(function(content) {
            return content instanceof Model.Message && !content.isIncoming;
        }).length === 0) {
            return;
        }

        this.addStraightForwardRelation(relationOut);
    };

    /**
     * @function
     * @name Controller.Component.sMessageLog#openInMessageLane
     * @param {String} uuid
     */
    sMessageLog.prototype.openInMessageLane = function openInMessageLane(uuid) {
        // Message is already in the active message lane
        if (this.getUuidIndices(uuid).length) {
            return;
        }

        var message = this.messageCollection.getMessageByUuid(uuid);

        // Message is outgoing - open it in the message lane
        if (!message.isIncoming) {
            this.setContentsByMessageUuid(uuid);
            return;
        }

        var relationsFrom = message.getRelationsFrom();

        // In case of incoming message with following content
        if (relationsFrom.length && relationsFrom[0].to && relationsFrom[0].to.uuid) {
            this.setContentsByMessageUuid(relationsFrom[0].from.uuid);
            this.replaceContents(relationsFrom[0], this.items.length - 1, true);
            return;
        }

        // In case of incoming message without following content
        var relationsTo = message.getRelationsTo();
        if (relationsTo.length && relationsTo[0].from && relationsTo[0].from.uuid) {
            // Show parent message
            this.setContentsByMessageUuid(relationsTo[0].from.uuid);
            // Show relation to message
            this.replaceContents(relationsTo[0], this.items.length - 1, true);
        }
    };

    /**
     * @function
     * @name Controller.Component.sMessageLog#highlightMessage
     * @param {String} messageUuid
     */
    sMessageLog.prototype.highlightMessage = function highlightMessage(messageUuid) {
        var self = this;

        self.highlightMessageUuid = messageUuid;
        digestIfNeeded(self.$scope);

        setTimeout(function () {
            if (self.highlightMessageUuid === messageUuid) {
                self.highlightMessageUuid = null;
                self.highlightMessageGoal = false;
                digestIfNeeded(self.$scope);
            }
        }, 1500);
    };

    /**
     * @function
     * @name Controller.Component.sMessageLog#scrollToHash
     */
    sMessageLog.prototype.scrollToHash = function scrollToHash() {
        if (this.updatingHash) {
            return;
        }

        var self = this,
            hash = this.$location.hash()
        ;

        if (!hash || !hash.match(Model.Message.REGEX_MESSAGE_URL_HASH)) {
            return;
        }

        var match = hash.match(Model.Message.REGEX_MESSAGE_URL_HASH),
            uuid  = match[2];

        if (!this.messageCollection.getMessageByUuid(uuid)) {
            return;
        }

        this.updatingHash = true;
        this.openInMessageLane(uuid);

        // We scroll to the first occurrence of this message in message lane
        var indices = this.getUuidIndices(uuid);

        if (!indices.length) {
            this.updatingHash = false;
            return;
        }

        var newHash = 'message-' + uuid;
        self.$location.hash(newHash);

        setTimeout(function () {
            self.$location.hash('message-' + uuid + '-' + indices[0]);
            self.$anchorScroll();
            self.$location.hash(newHash);
            self.$location.replace();
            self.highlightMessageGoal = (match[1] === 'goal-');
            self.highlightMessage(uuid);
            self.updatingHash = false;
        }, 100);
    };

    /**
     * Extract the first element of the array, without altering it
     * @function
     * @name  Controller.Component.sMessageLog#getFirstElement
     * @param {Array} arr
     * @static
     */
    sMessageLog.getFirstElement = function getFirstElement(arr) {
        return arr.slice(0,1).pop();
    };

    /**
     * Extract the last element of the array, without altering it
     * @function
     * @name  Controller.Component.sMessageLog#getLastElement
     * @param {Array} arr
     * @static
     */
    sMessageLog.getLastElement = function getLastElement(arr) {
        return arr.slice(-1).pop();
    };

    /**
     * Filters out elements smaller then the argument
     * @function Controller.Component.sMessageLog.filterLessThen
     * @name  Controller.Component.sMessageLog#filterLessThen
     * @param {Array} arr
     * @param {Number} value
     * @static
     */
    sMessageLog.filterLessThen = function filterLessThen(arr, value) {
        return arr.filter(function(element) {
            return element >= value;
        });
    };

    /**
     * Filters out elements greater then the argument
     * @name  Controller.Component.sMessageLog#filterGreaterThen
     * @function Controller.Component.sMessageLog.filterGreaterThen
     * @param {Array} arr
     * @param {Number} value
     * @static
     */
    sMessageLog.filterGreaterThen = function filterGreaterThen(arr, value) {
        return arr.filter(function(element) {
            return element <= value;
        });
    };

    /**
     * @name Controller.Component.sMessageLog#splitMessageOptions
     * @type {Object}
     * @static
     */
    sMessageLog.splitMessageOptions = {
        confirm     : 'Yes, go on.',
        decline     : 'Wait, no.',
        title       : 'Some parts of your message will become orphans …',
        content     : 'Messages will be split, when you move an interactive message part in front of others. One part will become a message without connections. Continue?',
        ariaLabel   : ''
    };

    /**
     * @name Controller.Component.sMessageLog#removeAttachedNLPOptions
     * @type {Object}
     * @static
     */
    sMessageLog.removeAttachedNLPOptions = {
        confirm     : 'Yes, go on.',
        decline     : 'Wait, no.',
        title       : 'Attached NLP will be removed …',
        content     : 'This is the last part of the message. Removing it will also remove the message with the associated NLP template. Continue?',
        ariaLabel   : ''
    };

    Object.defineProperties(
        sMessageLog,
        {
            EVENT_APPEND_MESSAGE_PART: {
                value: EVENT_APPEND_MESSAGE_PART
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sMessageLog#EVENT_APPEND_MESSAGE_PART
                 * @type {String}
                 */
            },
            EVENT_ADD_MESSAGE_BY_CONTENT: {
                value: EVENT_ADD_MESSAGE_BY_CONTENT
                /**
                 * @property
                 * @constant
                 * @name #EVENT_ADD_MESSAGE_BY_CONTENT
                 * @type {String}
                 */
            },
            EVENT_MESSAGE_LOG_SCROLL_TO_BOTTOM: {
                value: EVENT_MESSAGE_LOG_SCROLL_TO_BOTTOM
                /**
                 * @property
                 * @constant
                 * @name #EVENT_MESSAGE_LOG_SCROLL_TO_BOTTOM
                 * @type {String}
                 */
            }

        });

    /**
     * Private generic version of getActiveRelation[To/From] to avoid code duplication
     * @param {String} type
     * @param {Model.Message} message
     * @param {Number} index
     * @return {Model.Message.Relation|void}
     */
    function getActiveRelation(type, message, index) {
        var modifier = 0;
        if (!message || (type === 'from' && !this.isInteractive)) {
            return;
        }

        if (type === 'to') {
            modifier = -1;
        }

        Object.instanceOf(message, Model.Message);

        var matches = this.getElementIndices(message);
        if (!matches.length) {
            return;
        }

        matches = sMessageLog.filterLessThen(matches, index);
        // limit the search for current pos and next instance's pos
        for (var i = matches[0] + modifier; i < (matches[1] || this.items.length); i++) {
            if (!(this.items[i] instanceof Model.Message.Relation)
                || !this.items[i][type]) {
                continue;
            }
            if (this.items[i][type].uuid === message.uuid) {
                return this.items[i];
            }
        }
    }

    ns.sMessageLog = sMessageLog;

})(Object.namespace('Controller.Component'));
