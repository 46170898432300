(function (ns) {
    /**
     * @namespace
     * @alias Model.Source.Condition
     *
     * @constructor
     * @extends Model.Condition
     */
    var Condition = function () {
        Condition._pProto.constructor.call(this);
    };

    Object.extendProto(Condition, Model.Condition);

    /**
     * @param {Object} data
     * @return {Model.Source.Condition}
     */
    Condition.createByData = function createByData(data) {
        var condition = new Condition();
        condition.updateByData(data);

        return condition;
    };

    ns.Condition = Condition;
})(Object.namespace('Model.Source'));