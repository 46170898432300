(function(ns){
    /**
     * @namespace
     * @alias Model.Exception.AjaxError
     * @constructor
     * @extends Error
     *
     * @param {String=} message
     */
    var AjaxError = function AjaxError(message) {
        Object.defineProperties(
            this,
            {
                name: {
                    configurable: true,
                    enumerable  : true,
                    value       : 'AjaxError'
                    /**
                     * @property
                     * @name Model.Exception.AjaxError#name
                     * @type {String}
                     */
                },
                message: {
                    configurable: true,
                    enumerable: true,
                    get: function () {
                        return message;
                    }
                    /**
                     * @property
                     * @name Model.Exception.AjaxError#message
                     * @type {String}
                     */
                }
            }
        );

        AjaxError._pProto.constructor.call(this, message);
    };

    Object.extendProto(AjaxError, Error);

    ns.AjaxError = AjaxError;
})(Object.namespace('Model.Exception'));
