(function (ns) {
    var KEY_DEFAULT_NAMESPACE = '_default',
        VALUE_TRANSFORM_FUNCTION = 'value_transform_function';

    /**
     * This class acts more or less as a trait and
     * provides the functionality to define meta fields for an object.
     * Any meta field defined - that doesn't have a conflicting property name yet -
     * will also be defined as a property of the object.
     *
     * @namespace
     * @alias Model.Behavior.Meta
     *
     * @abstract
     * @param {Object=} options
     */
    var Meta = function (options) {
        if (this.constructor === Meta) {
            throw 'Behavior should not be instantiated!';
        }

        var meta = {},
            self = this,
            metaCache = {}
        ;

        var refreshMetaCache = function refreshMetaCache(ns) {
            ns = ns || KEY_DEFAULT_NAMESPACE;

            metaCache[ns] = metaCache[ns] || [];

            metaCache[ns].splice(0, metaCache[ns].length);
            Object.getOwnPropertyNames(meta[ns]).reduce(function(cache, propName) {
                cache.push({key: propName, value: meta[ns][propName]});
                return cache;
            }, metaCache[ns]);
        };

        /**
         * @method
         * @name Model.Behavior.Meta#setMeta
         * @param {String} key
         * @param {*} value
         * @param {String=} ns
         * @returns {*}
         */
        this.setMeta = function setMeta(key, value, ns) {
            ns = ns || KEY_DEFAULT_NAMESPACE;
            // only the default namespace properties will be added as properties of the object
            if (!self.hasOwnProperty(key) && ns === KEY_DEFAULT_NAMESPACE) {
                Object.defineProperty(
                    self,
                    key,
                    {
                        set: function (val) {
                            if(options && typeof options[VALUE_TRANSFORM_FUNCTION] === 'function') {
                                val = options[VALUE_TRANSFORM_FUNCTION](key, val);
                            }

                            meta[ns][key] = val;
                            refreshMetaCache(ns);
                        },
                        get: function () {
                            return meta[ns][key];
                        }
                    }
                );
            }
            meta[ns] = meta[ns] || {};
            meta[ns][key] = value;

            refreshMetaCache(ns);

            return this;
        };

        /**
         * @method
         * @name Model.Behavior.Meta#unsetMeta
         * @param {String} key
         * @param {String=} ns
         * @returns {*}
         */
        this.unsetMeta = function unsetMeta(key, ns) {
            ns = ns || KEY_DEFAULT_NAMESPACE;

            if (!meta[ns]) {
                return;
            }

            if (meta[ns].hasOwnProperty(key)) {
                delete meta[ns][key];
            }

            refreshMetaCache(ns);

            return this;
        };

        /**
         * @method
         * @name Model.Behavior.Meta#getMeta
         * @param {String} key
         *
         * @param {*=} defVal
         * @param {String=} ns
         * @returns {*}
         */
        this.getMeta = function getMeta(key, defVal, ns) {
            ns = ns || KEY_DEFAULT_NAMESPACE;
            if (!meta[ns]) {
                return defVal;
            }

            return meta[ns].hasOwnProperty(key) ? meta[ns][key] : defVal;
        };

        /**
         * @method
         * @name Model.Behavior.Meta#getMetaFields
         * @returns {String[]}
         */
        this.getMetaFields = function getMetaFields(ns) {
            ns = ns || KEY_DEFAULT_NAMESPACE;
            var fieldNames = [];

            if (!meta[ns]) {
                return fieldNames;
            }

            for (var i in meta[ns]) {
                if (meta[ns].hasOwnProperty(i)) {
                    fieldNames.push(i);
                }
            }

            return fieldNames;
        };

        /**
         * @name Model.Behavior.Meta#getMetas
         * @param {String?} ns
         * @return {{key: string, value: *}[]}
         */
        this.getMetas = function getMetas(ns) {
            ns = ns || KEY_DEFAULT_NAMESPACE;
            return metaCache[ns];
        };

        /**
         * @method
         * @name Model.Behavior.Meta#getMetaNamespaces
         * @return {string[]}
         */
        this.getMetaNamespaces = function getMetaNamespaces() {
            return Object.getOwnPropertyNames(meta);
        };

        /**
         * @method
         * @name Model.Behavior.Meta#clearMetaNamespace
         * @param {String=} ns
         * @return {Model.Behavior.Meta}
         */
        this.clearMetaNamespace = function clearMetaNamespace(ns) {
            ns = ns || KEY_DEFAULT_NAMESPACE;
            meta[ns] = {};

            refreshMetaCache(ns);
            return this;
        };

        /**
         * @method
         * @name Model.Behavior.Meta#prepareMetaData
         * @param {*} data
         * @returns {Object}
         */
        this.prepareMetaData = function prepareMetaData(data) {
            // API might return values packed in an array
            if (!(data instanceof Array)) {
                return data;
            }

            return data.reduce(function(carry, element) {
                carry = $.extend(true, {}, carry, element);
                return carry;
            }, {});
        };

        /**
         * @method
         * @name Model.Behavior.Meta#updateMetaByData
         * @param {*} data
         */
        this.updateMetaByData = function updateMetaByData(data) {
            data = this.prepareMetaData(data);

            for (var propName in data) {
                this.setMeta(propName, data[propName]);
            }
        };

        /**
         * @method
         * @param original
         */
        this.__extendClone = function __extendClone(original) {
            var self = this;

            original.getMetaNamespaces().map(function(namespace) {
                original.getMetaFields(namespace).map(function(metaName) {

                    var tmpMeta = original.getMeta(metaName, null, namespace);

                    self.setMeta(
                        metaName,
                        tmpMeta && typeof tmpMeta.clone === "function" ? tmpMeta.clone() : tmpMeta,
                        namespace
                    );
                });
            });
        };

        this.getMetasJson = function getMetasJson() {
            if (!this.getMetas()) {
                return {};
            }

            return this.getMetas().reduce(function (carry, keyValue) {
                carry[keyValue.key] = keyValue.value;
                return carry;
            }, {});
        };

        return meta;
    };

    Object.defineProperties(
        Meta,
        {
            VALUE_TRANSFORM_FUNCTION : {
                value : VALUE_TRANSFORM_FUNCTION
                /**
                 * @static
                 * @property
                 * @name Model.Behavior.Meta#VALUE_TRANSFORM_FUNCTION
                 * @type {String}
                 */
            }
        });

    ns.Meta = Meta;
})(Object.namespace('Model.Behavior'));