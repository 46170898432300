(function (ns) {

    /**
     * @namespace
     * @memberOf Model.Source.Type
     * @constructor
     */
    var Field = function Field(name, type) {
        Object.defineProperties(
            this,
            {
                name : {
                    enumerable : true,
                    get        : function () {
                        return name;
                    }
                    /**
                     * @property
                     * @type String
                     * @name Model.Source.Type#name
                     */
                },
                type : {
                    enumerable : true,
                    get        : function () {
                        return type;
                    }
                    /**
                     * @property
                     * @type String
                     * @name Model.Source.Type#type
                     */
                }
            }
        );
    };

    /**
     * @function
     * @name Model.Source.Type.Field.createByData
     * @param {Object} data
     *
     * @returns {Model.Source.Type.Field}
     */
    Field.createByData = function createByData(data) {
        return new Field(data.name, data.type);
    };

    ns.Field = Field;
})(Object.namespace('Model.Source.Type'));