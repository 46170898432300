(function(ns) {

    /**
     * @namespace
     * @alias Controller.Component.sMemberData
     * @constructor
     *
     * @param {Object} apiEndpoints
     * @param $scope
     */
    var sMemberData = function sMemberData(apiEndpoints, $scope) {
        this.apiEndpoints   = apiEndpoints;
        this.$scope         = $scope;
        this.$deRegister    = [];
        this.loadingPromise = null;
    };

    /**
     * @function
     * @name Controller.Component.sMemberData#$onChanges
     */
    sMemberData.prototype.$onChanges = function $onChanges() {
        this.fetchData();
    };

    /**
     * @function
     * @name Controller.Component.sMemberData#fetchData
     * @return {PromiseLike}
     */
    sMemberData.prototype.fetchData = function fetchData() {
        var self = this;

        if (!this.loadingPromise) {
            this.member = null;
            this.loadingPromise = Model.Member.load(this.apiEndpoints.member.byUserId(), this.memberId).then(
                function (member) {
                    self.member = member;
                }
            ).always(
                function () {
                    self.loadingPromise = null;
                    digestIfNeeded(self.$scope);
                }
            );
        }

        return this.loadingPromise;
    };

    ns.sMemberData = sMemberData;
})(Object.namespace('Controller.Component'));
