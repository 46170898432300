(function(angular) {

    var module = angular.module('sHeadlineModule');

    module
        .component('sHeadline' , {
            controller  : sHeadline.Component.Controller.sHeadline,
            templateUrl : 'sheadline/components:s-headline'
        });

})(angular);
