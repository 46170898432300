(function(ns) {
    /**
     * @namespace
     * @alias Model.Collection
     *
     * @param {Object} object
     * @param {String=} alias
     * @constructor
     */
    var Collection = function(object, alias) {
        if (!object && !(object instanceof Function)) {
            throw 'Invalid argument!';
        }

        var type        = object,
            objectName  = alias || 'item',
            collName    = objectName + 's',
            items       = [],
            itemsCache  = [],
            min         ,
            max         ,
            self        = this,
            refreshItemsCache = function () {
                itemsCache.splice.apply(itemsCache, [0, itemsCache.length].concat(items));
            }
            ;


        Object.defineProperty(
            this,
            collName,
            {
                enumerable: true,
                get: function () {
                    return itemsCache;
                }
            }
        );


        Object.defineProperties(
            this,
            {
                min : {
                    get: function() {
                        return min === undefined ? 0 : min;
                    },
                    set: function(val) {
                        if (isNaN(val = parseInt(val)) || val === min || val < 0 || (max && val > max)) {
                            return;
                        }

                        min = val;

                        if (!min) {
                            return;
                        }

                        for (var i = 0; i < min - this.length; i++) {
                            self.createAndAdd();
                        }
                    }
                    /**
                     * @name Model.Collection#min
                     * @type {Number}
                     */
                },
                max : {
                    get: function() {
                        return max === undefined ? 0 : max;
                    },
                    set: function(val) {
                        if (isNaN(val = parseInt(val)) || val === max || (min && val < min)) {
                            return;
                        }

                        max = val;
                        if (!max) {
                            return;
                        }

                        if (max < self.length) {
                            items.splice(max, self.length - max);
                        }
                    }
                    /**
                     * @name Model.Collection#max
                     * @type {Number}
                     */
                },
                length : {
                    get: function() {
                        return itemsCache.length;
                    }
                    /**
                     * @name Model.Collection#length
                     * @type {Number}
                     */
                }
            }
        );

        this['add' + objectName.ucFirst()] = this.addItem = function(item, prepend) {
            if (!this.canGainItem()) {
                return;
            }

            Object.instanceOf(item, type);
            var pos;
            if ((pos = self.getIndex(item)) !== -1) {
                items.splice(pos, 1, item);
            } else {
                if (prepend) {
                    items.unshift(item);
                } else {
                    items.push(item);
                }
            }

            refreshItemsCache();
            return this;
        };

        this['remove' + objectName.ucFirst()] = this.removeItem = function (item) {
            if (!this.canLoseItem()) {
                return;
            }

            var pos;
            if ((pos = self.getIndex(item)) !== -1) {
                items.splice(pos, 1);
            }

            refreshItemsCache();
            return this;
        };

        this.getIndex = function getIndex(item) {
            return this[collName].indexOf(item);
        };

        this.createAndAdd = function createAndAdd() {
            var item = Object.create(type.prototype);
            item.constructor.apply(item, arguments);
            this['add' + objectName.ucFirst()].call(this, item);

            return item;
        };

        this.updateByData = function(data, immutable) {
            var minHolder   = min,
                self        = this,
                justAdded   = []
            ;

            this.min = 0;
            if (!data[collName] || !(data[collName] instanceof Array)) {
                data[collName] = [];
            }

            data[collName].map(function(element) {
                var item;
                if (immutable) {
                    var dataObj = element,
                        dataValues = []
                    ;

                    if (immutable instanceof Array) {
                        immutable.map(function(key) {
                            dataValues.push(dataObj[key]);
                        });
                    } else {
                        for (var j in dataObj) {
                            dataValues.push(dataObj[j]);
                        }
                    }

                    item = self.createAndAdd.apply(self, dataValues);
                    if (item.updateByData instanceof Function) {
                        item.updateByData(element);
                    }
                } else {
                    item = self.createAndAdd();
                    item.updateByData(element);
                }
                justAdded.push(self.getIndex(item));
            });

            for (var i = items.length - 1; i >= 0; i--) {
                if (justAdded.indexOf(i) === -1) {
                    this['remove' + objectName.ucFirst()].call(this, items[i]);
                }
            }

            refreshItemsCache();
            min = minHolder;
            return this;
        };

        this.reset = function() {
            var tmpMin = this.min;

            this.min = 0;

            items.splice(0);
            refreshItemsCache();

            this.min = tmpMin;
            return this;
        };

        /**
         * @name Model.Collection#__extendClone
         * @param {Model.Collection} original
         */
        this.__extendClone = function(original) {
            // the item might have a minimum set, which would increase the items count, so hard reset
            items = [];
            for (var i = 0; i < original[collName].length; i++) {
                this['add' + objectName.ucFirst()].call(this, original[collName][i].clone());
            }
        };
    };


    Collection.prototype.canGainItem = function() {
        return !this.max || this.length < this.max;
    };

    Collection.prototype.canLoseItem = function() {
        return !this.min || this.length > this.min;
    };

    ns.Collection = Collection;

})(Object.namespace('Model'));
