(function (ns) {
    /**
     * @namespace
     * @alias Controller.sMessageAdmin.ExtractController
     *
     * @constructor
     * @param $scope
     * @param $mdDialog
     * @param {Service.sDomain} sDomainService
     * @param {Service.sMessageCollection} sMessageCollection
     * @param Notification
     */
    var ExtractController = function ($scope, $mdDialog, sDomainService, sMessageCollection, Notification) {
        this.$mdDialog          = $mdDialog;
        this.$scope             = $scope;
        this.sDomainService     = sDomainService;
        this.sMessageCollection = sMessageCollection;
        this.notification       = Notification;

        /**
         * @property
         * @name Controller.sMessageAdmin.ExtractController#hostCollection
         * @type {Model.Message.Collection}
         */

        /**
         * @property
         * @name Controller.sMessageAdmin.ExtractController#path
         * @type {Model.Message[]}
         */
    };

    ExtractController.prototype.$onInit = function $onInit() {
        this.anchor                 = new Model.Message()
            .createAndSetMessageAnchor(this.sDomainService.currentDomainId)
            .messageAnchor;
        this.tagsAutocompleteSource = this.sMessageCollection.getTagsAutocompleteSource();
    };

    ExtractController.prototype.cancel = function cancel() {
        this.$mdDialog.hide();
    };

    /**
     * @return {$.Deferred}
     */
    ExtractController.prototype.handleExtractClick = function handleExtractClick() {
        var rootCandidate         = this.path.slice(0, 1).pop(),
            messageAnchorDeferred = $.Deferred()
        ;

        rootCandidate.exchangeIncomingTriggerMessageWithTriggerConversation(
            messageAnchorDeferred,
            this.path.diff(this.hostCollection.messages)
        );
        this.hostCollection.getRootMessage().exchangeIncomingTriggerMessageWithTriggerConversation(
            $.Deferred().resolve(this.hostCollection.getRootMessage().messageAnchor),
            this.path
        );

        var newCollection = Model.Message.Collection.createFromSubTree(this.path);

        return newCollection.waitForMediaToLoad().then(function () {
            // need to remove orphans left behind the connection extraction
            this.hostCollection.messages.filter(function (message) {
                return message.isOrphan;
            }).map(function (message) {
                this.hostCollection.removeMessage(message);
            }.bind(this));

            // create collection from path
            // add anchor onto collection rootMessage
            try {
                newCollection.getRootMessage().messageAnchor = this.anchor;
                // resolve with the anchor once the new collection has been added
                messageAnchorDeferred.resolve(this.anchor);
            } catch (e) {
                this.$mdDialog.cancel();
            }

            return this.sMessageCollection.validateAndSaveAndActivate(this.anchor, true)
                .then(function () {
                        // now exchange postback_message relations pointing to the rootCandidate in the original convo
                        // exchange messages pointing from the path to the host root message
                        // save and activate old collection
                        return this.sMessageCollection.validateAndSaveAndActivate(this.hostCollection.getRootMessage().messageAnchor, true)
                            .fail(function() {
                                this.$mdDialog.cancel();
                            }.bind(this))
                    }.bind(this),
                    function () {
                        this.$mdDialog.cancel();
                    }.bind(this))
                .then(function() {
                    this.$mdDialog.hide(newCollection);
                }.bind(this))
                ;
        }.bind(this));
    };

    ns.ExtractController = ExtractController;
})(Object.namespace('Controller.sMessageAdmin'));
