(function(ns) {
    /**
     * @namespace
     * @alias sMember.Controller.Overview
     * @constructor
     *
     * @param $scope
     * @param $rootScope
     * @param sConfirm
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     */
    var Overview = function (
        $scope,
        $rootScope,
        sConfirm,
        sAPIAccess
    ) {
        var listEndpoint    = sAPIAccess.endpoint('member.list').get(),
            self            = this
        ;

        this.$scope             = $scope;
        this.sConfirm           = sConfirm;
        this.sAPIAccess         = sAPIAccess;
        this.$deRegister        = [];
        this.segment            = null;
        this.currentNavItem     = 'AudienceItem';

        this.list = this.getList(listEndpoint);

        this.filterFields = {
            search: {
                component: 'sTextInput',
                options: {
                    defaultValue: '',
                    attrs: {
                        'placeholder': 'Name',
                        'class'      : 'textsearch',
                        'is-disabled': '$ctrl.helperControls.readonly'
                    },
                    label: "search"
                }
            },
            segment: {
                component: 'sSegmentPicker',
                options: {
                    attrs: {
                        labels: "{existing:'An existing Segment', new:'A new Segment'}",
                        'is-required': false
                    },
                    label: 'segment',
                    asJson: function(key, value) {
                        if (key) {
                            return value;
                        }
                        return Model.Segment.createByData(value);
                    },
                    defaultValue: null
                }
            }
        };

        this.$deRegister = this.$deRegister.concat($(document).$on(
            'ajaxSend',
            function(event, jqXHR, request) {
                if (request.url.search(listEndpoint) === -1
                ) {
                    return true;
                }

                jqXHR.done(function(data, status, jqXHR) {
                    var segmentUuid;
                    if ((segmentUuid = jqXHR.getResponseHeader('x-segment-uuid'))
                        && (filter = self.list.filters.getByName('segment'))
                    ) {
                        filter.value = new Model.Segment(segmentUuid);
                    }
                });
            }
        ));

        this.$deRegister.push($scope.$on('$destroy', this.$onDestroy.bind(this)));
    };

    /**
     * @function
     * @name sMember.Controller.Overview#$onDestroy
     */
    Overview.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name sMember.Controller.Overview#getList
     * @param {string} listEndpoint
     * @returns {Model.List}
     */
    Overview.prototype.getList = function getList(listEndpoint) {
        var column,
            list = new Model.List(listEndpoint)
        ;

        list.mapFn = function (row) {
            return Model.Member.fromJson(row);
        };

        column = list.columns.createAndAdd('fullName');
        column.label = 'Name';
        column.isSortable = true;

        column = list.columns.createAndAdd('messenger');
        column.label = 'Messenger';
        column.isSortable = true;

        column = list.columns.createAndAdd('locale');
        column.label = 'Locale';
        column.isSortable = true;

        column = list.columns.createAndAdd('source');
        column.label = 'Source';
        column.isSortable = true;

        column = list.columns.createAndAdd('timeLastActive');
        column.label = 'Last Active';
        column.isSortable = true;

        column = list.columns.createAndAdd('timeJoined');
        column.label = 'Signed Up';
        column.isSortable = true;

        column = list.columns.createAndAdd('attributes');
        column.label = 'Attributes';

        column = list.columns.createAndAdd('segment', true);
        column.isHidden = true;

        list.isMultipleAllowed = false;
        list.actions = {};

        if(this.sAPIAccess.isAllowed('member.RESTAccess', Const.Method.DELETE)) {
            list.actions['delete'] = new Model.Menu.Action('delete', this.deleteMember.bind(this));
            list.isMultipleAllowed = true;
            list.actions['deleteMultiple'] = new Model.Menu.Action('deleteMultiple', this.deleteMembers.bind(this));
        }

        if (this.sAPIAccess.isAllowed('member.export', Const.Method.GET)) {
            list.isMultipleAllowed = true;
            list.actions['exportSelection'] = new Model.Menu.Action('exportSelection', this.exportSelection.bind(this));
        }

        if (Object.keys(list.actions).length) {
            column = list.columns.createAndAdd('options');
            column.label = 'Options';
        }

        return list;
    };

    /**
     * @function
     * @name sMember.Controller.Overview#deleteMember
     * @param {Model.Member} member
     * @return {PromiseLike}
     */
    Overview.prototype.deleteMember = function deleteMember(member) {
        var confirmOptions = this.getConfirmOptions([member]);

        var sAPIAccess = this.sAPIAccess,
            list = this.list,
            self = this;

        return this.sConfirm.open(confirmOptions).then(
            function () {
                member.delete(sAPIAccess);
                list.load(false, true);
                digestIfNeeded(self.$scope);
                return true;
            },
            function () {
                return false;
            }
        );
    };

    /**
     * @function
     * @name sMember.Controller.Overview#deleteMembers
     * @param {Model.Member[]} selection
     * @return {PromiseLike}
     */
    Overview.prototype.deleteMembers = function deleteMembers(selection) {
        var self = this,
            confirmOptions = this.getConfirmOptions(selection);

        return this.sConfirm.open(confirmOptions).then(
            function () {
                var actions = [];
                selection.map(function(element){
                    actions.push(function () {
                        element.delete(self.sAPIAccess);
                    });
                });

                return $.aggregateAction(actions, Model.RESTAccessByUUID.endpoint_batch(true)).then(function () {
                    self.list.load(false, true);
                    digestIfNeeded(self.$scope);
                });
            },
            function () {
                return false;
            });
    };

    /**
     * @function
     * @name sMember.Controller.Overview#exportList
     * @param {Array=} memberIds
     * @returns {Promise|PromiseLike}
     */
    Overview.prototype.exportList = function exportList(memberIds) {
        var queryParams = this.list.getQueryParameters(); // Apply the current list parameters for export

        if (memberIds) {
            queryParams['memberIds'] = memberIds;
        }

        return $.ajax({
            url    : this.sAPIAccess.endpoint('member.export').get(),
            method : Const.Method.GET,
            data   : queryParams
        }).then(function (data) {
                var dl = new sDownload(data, 'export.csv', 'text/csv');
                dl.downloadFile();
            },
            function (jqXHR) {
                console.log(jqXHR.responseJSON);
            }
        );
    };

    /**
     * @function
     * @name sMember.Controller.Overview#exportSelection
     * @param {Model.Member[]} selection
     * @returns {Promise|PromiseLike}
     */
    Overview.prototype.exportSelection = function exportSelection(selection) {
        var memberIds = selection.reduce(
            /**
             * @param {Array} collection
             * @param {Model.Member} member
             * @returns {Array}
             */
            function (collection, member) {
                collection.push(member.id);
                return collection;
            },
            []
        );

        return this.exportList(memberIds);
    };

    /**
     * @function
     * @name sMember.Controller.Overview#filterReset
     * @return {void}
     */
    Overview.prototype.filterReset = function filterReset() {
        this.list.filters.clear();
        this.list.load(false, true);
        this.segment = null;
    };

    /**
     * @function
     * @name sMember.Controller.Overview#onFilter
     * @return {void}
     */
    Overview.prototype.onFilter = function onFilter() {
        if (this.segment.saveAsNewSegment) {
            this.segment.save();
        }

        this.list.filters.getOrCreateByName('conditions', []).value = JSON.stringify(
            this.segment.getDispatchConditions(true)
        );
        this.list.load(false, true);
    };

    /**
     * @function
     * @name sMember.Controller.Overview~getConfirmOptions
     * @param {Model.Member[]} selection
     * @return {{confirm: string, decline: string, title: string, content: string}}
     */
    Overview.prototype.getConfirmOptions = function getConfirmOptions(selection) {
        var content = '';

        if (selection.length === 1) {
            content = 'Do you really want to remove the Member <b>' + selection[0].fullName + '</b>?';
        } else if (selection.length > 1) {
            content = 'Do you really want to remove <b>' + selection.length + '</b> Members?';
        }

        return {
            confirm : 'Remove',
            decline : 'Cancel',
            title   : 'Remove Audience Member' + (selection.length > 1 ? 's' : ''),
            content : content
        };
    };

    ns.Overview = Overview;
})(Object.namespace('sMember.Controller'));
