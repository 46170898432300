(function(angular) {

    var module = angular.module('sFacebookModule');

    /**
     * @ngdoc component
     * @name sFacebookPageSelect
     * @restrict E
     *
     * @param {String} model
     * @param {Boolean=} ngRequired
     * @param {Function=} onHandleChange
     */
    module.component(
        'sFacebookPageSelect', {
            templateUrl : 'sfacebook/components:s-facebook-page-select',
            controller  : sFacebook.Controller.Component.sFacebookPageSelect,
            bindings: {
                model           : '=',
                ngRequired      : '<',
                onHandleChange  : '&'
            }
        });

    /**
     * @ngdoc component
     * @name sFacebookAdAccountSelect
     * @restrict E
     *
     * @param {String} model
     * @param {Boolean=} ngRequired
     * @param {Function=} onHandleChange
     */
    module.component(
        'sFacebookAdAccountSelect', {
            templateUrl : 'sfacebook/components:s-facebook-ad-account-select',
            controller  : sFacebook.Controller.Component.sFacebookAdAccountSelect,
            bindings: {
                model           : '=',
                ngRequired      : '<',
                onHandleChange  : '&'
            }
        });
})(angular);
