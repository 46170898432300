(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sCTACollectionController
     * @constructor
     *
     * @param $scope
     */
    var sCTACollectionController = function($scope) {

        this.$scope         = $scope;
        this.$deRegister    = [];

        /**
         * @property {Number}
         * @name Controller.Component.sCTACollectionController#minAllowedCta
         */

        /**
         * @property {Number}
         * @name Controller.Component.sCTACollectionController#maxAllowedCta
         */

        /**
         * @property {Boolean}
         * @name Controller.Component.sCTACollectionController#withoutOffset
         */


        /**
         * @property
         * @name Controller.Component.sCTACollectionController#$accordionHeadCtrl
         */

        /**
         * @property
         * @name Controller.Component.sCTACollectionController#dynamicForm
         * @type Controller.Directive.sDynamicForm
         */

        /**
         * @property
         * @name Controller.Component.sCTACollectionController#model
         * @type Model.CTA.Collection
         */
    };

    /**
     * @function
     * @name Controller.Component.sCTACollectionController#$onInit
     */
    sCTACollectionController.prototype.$onInit = function $onInit() {
        var self = this;

        this.maxAllowedCta  = this.maxAllowedCta || 3;

        this.$deRegister.push(this.$scope.$watch(
            function() {
                return self.model;
            },
            this.setRestrictions.bind(this))
        );

        this.setRestrictions();
    };

    /**
     * @function
     * @name Controller.Component.sCTACollectionController#getLabel
     * @param {string} key
     * @returns {string}
     */
    sCTACollectionController.prototype.getLabel = function getLabel(key) {
        var label;

        try {
            label = this.dynamicForm.rules.links.labels[key];
        } catch (e) {
            label = '';
        }

        return label;
    };

    /**
     * @function
     * @name Controller.Component.sCTACollectionController#setRestrictions
     */
    sCTACollectionController.prototype.setRestrictions = function setRestrictions() {
        if (!this.model) {
            return;
        }

        if (this.minAllowedCta) {
            this.model.minLength = this.minAllowedCta;
            if (this.model.minLength > this.model.ctas.length) {
                this.model.createAndAddCTA();
            }
        }
        if (this.maxAllowedCta) {
            this.model.maxLength = this.maxAllowedCta;
        }
    };

    sCTACollectionController.prototype.$onDestroy = function $onDestroy() {
        var destroyFn;
        while ((destroyFn = this.$deRegister.pop())) {
            destroyFn();
        }
    };

    ns.sCTACollectionController = sCTACollectionController;
})(Object.namespace('Controller.Component'));