(function (ns) {
    var TYPE_FIELDS         = 'fields',
        TYPE_JSON           = 'json',
        TYPES_TO_CONVERT    = [
            TYPE_JSON,
            TYPE_FIELDS
        ],
        KEY_DEFAULT_VALUE = 'default_value'
    ;
    /**
     * @namespace
     * @alias Model.Action.Field.Bag
     * @constructor
     *
     * @param {ActionFieldDefinition[]} fieldsDefinition
     */
    var Bag = function (fieldsDefinition) {
        var self            = this,
            fields          = {},
            formFields      = null,
            cachedFieldsDefinition
        ;

        /**
         * @function
         * @name Model.Action.Field.Bag~cacheFieldsDefinition
         */
        var cacheFieldsDefinition = function cacheFieldsDefinition() {
            cachedFieldsDefinition = {};

            for (var i = 0; i < fieldsDefinition.length; i++) {
                cachedFieldsDefinition[fieldsDefinition[i].name] = fieldsDefinition[i];
            }
        };

        /**
         * @function
         * @name Model.Action.Field.Bag~buildFields
         */
        var buildFields = function buildFields() {
            fields = {};
            $.each(self.cachedFieldsDefinition, function (name, fieldDefinition) {
                var fieldValue;

                if (fieldDefinition.is_array) {
                    fieldValue = new Model.Action.Field.Collection(fieldDefinition);
                } else if (fieldDefinition.type === TYPE_FIELDS) {
                    fieldValue = new Model.Action.Field.Bag(fieldDefinition);
                }

                fields[name] = fieldValue;
            });

            self.clearFields();
        };

        /**
         * @function
         * @name Model.Action.Field.Bag~buildFormFields
         */
        var buildFormFields = function buildFormFields() {
            formFields = {};

            $.each(self.fields, function (name, field) {
                if (!self.cachedFieldsDefinition.hasOwnProperty(name)) {
                    return true;
                }
                var element = self.cachedFieldsDefinition[name],
                    formField;

                if (field instanceof Model.Action.Field.Collection) {
                    formField = {
                        template    : '_form_row_action_fields_row',
                        options     : {
                            label: element.label,
                            attrs: {
                                'template'      : '_form_collection_action_field',
                                'batch-property': "'"+element.name+"'"
                            }
                        },
                        component: 'sDynamicFormCollection'
                    };

                    formFields[name] = formField;
                    return true;
                }

                formField = Bag.getFormField(element);
                formFields[name] = formField;
            });
        };

        Object.defineProperties(this, {
            fields: {
                enumerable: true,
                get: function () {
                    return fields;
                }
                /**
                 * @property
                 * @name Model.Action.Field.Bag#fields
                 * @type {Object}
                 */
            },
            json: {
                enumerable: true,
                get: function () {
                    return JSON.stringify(self);
                }
                /**
                 * @property
                 * @name Model.Action.Field.Bag#json
                 * @type {string}
                 */
            },
            cachedFieldsDefinition: {
                get: function () {
                    if (!cachedFieldsDefinition) {
                        cacheFieldsDefinition();
                    }
                    return cachedFieldsDefinition;
                }
                /**
                 * @property
                 * @name Model.Action.Field.Bag#cachedFieldsDefinition
                 * @type {Object}
                 */
            },
            formFields: {
                get: function () {
                    if (!formFields) {
                        buildFormFields();
                    }
                    return formFields;
                }
                /**
                 * @property
                 * @name Model.Action.Field.Bag#formFields
                 * @type {Object}
                 */
            }
        });

        /**
         * Used by the stringify method
         *
         * @function
         * @name Model.Action.Field.Bag#toJSON
         */
        this.toJSON = function toJSON() {
            var tmpFields = {};

            $.each(this.fields, function (key, value) {
                if (value instanceof Model.Action.Field.Collection) {
                    value = value.toJSON();
                    if (value.length > 0) {
                        tmpFields[key] = value;
                    }
                    return true;
                }

                // pseudo fields should handle their values
                if (key.search(/^\!.*?/) !== -1) {
                    self.cachedFieldsDefinition[key].fields.map(function(field) {
                        tmpFields[field] = value ? value[field] : undefined;
                    });
                    return true;
                }

                // autoconvert certain types from string to let them handle from nested component
                if (self.cachedFieldsDefinition[key] && TYPES_TO_CONVERT.indexOf(self.cachedFieldsDefinition[key].type) !== -1) {
                    try {
                        value = JSON.parse(value);
                    } catch (error) {
                        value = undefined;
                    }
                }

                // remove property if value is empty
                if (typeof value === 'undefined' || value === '') {
                    delete tmpFields[key];
                    return true;
                }

                tmpFields[key] = value;
            });

            return tmpFields;
        };

        /**
         * Updates the current fields or clear set values
         *
         * @function
         * @name Model.Action.Field.Bag#updateFields
         * @param {object} object
         */
        this.updateFields = function updateFields(object) {

            $.each(self.fields, function (name) {
                // pseudo fields should handle their values
                if (name.search(/^\!.*?/) !== -1) {
                    self.cachedFieldsDefinition[name].fields.map(function(field) {
                        self.fields[field] = object[field];
                    });
                    return true;
                }

                if (self.fields[name] instanceof Model.Action.Field.Collection
                    || self.fields[name] instanceof Model.Action.Field.Bag
                ) {
                    self.fields[name].updateFields(object[name]);
                    return true;
                }

                if (!object.hasOwnProperty(name)) {
                    self.fields[name] = undefined;
                    return true;
                }

                // pseudo fields can create fields without definition, should be ignored
                if (!self.cachedFieldsDefinition[name]) {
                    return true;
                }

                // autoconvert certain types to string to let them handle from nested component
                if (TYPES_TO_CONVERT.indexOf(self.cachedFieldsDefinition[name].type) !== -1) {
                    self.fields[name] = JSON.stringify(object[name]);
                    return true;
                }

                self.fields[name] = object[name];
            });
        };

        /**
         * @function
         * @name Model.Action.Field.Bag#clearFields
         */
        this.clearFields = function clearFields() {
            $.each(self.fields, function (name) {

                if (self.fields[name] instanceof Model.Action.Field.Collection
                    || self.fields[name] instanceof Model.Action.Field.Bag
                ) {
                    self.fields[name].clearFields();
                    return true;
                }

                var definition = self.cachedFieldsDefinition[name];

                if (definition && definition.hasOwnProperty(KEY_DEFAULT_VALUE)) {
                    self.fields[name] = self.cachedFieldsDefinition[name][KEY_DEFAULT_VALUE];
                    return true;
                }
                self.fields[name] = undefined;
            });
        };

        buildFields();
    };

    /**
     * @static
     * @function
     * @name Model.Action.Field.Bag.getFormField
     * @param {object} element
     * @returns object
     */
    Bag.getFormField = function getFormField(element) {
        var formField = {
                visibility  : !element.hidden,
                template    : '_form_row_action_fields_row',
                options     : {
                    label: element.label,
                    attrs: {
                        'ng-required': element.required
                    }
                }
            }
        ;

        if (typeof element.type === "object") {
            for (var key in element.type) {
                if (key === 'content_source') {
                    formField.component = 'sContentSourcePicker';
                    $.extend(true, formField.options, {
                        attrs           : {
                            'source-type-id'   : element.type[key],
                            'on-handle-change' : '$ctrl.helperControls.formFields[\''+element.name+'\'].options.handleChange(item, $ctrl.helperControls.formFields[\''+element.name+'\'].options)'
                        },
                        handleChange: function(source, container) {
                            container.source = source;
                        }
                    });
                }
            }

            return formField;
        }

        switch(element.type) {
            case 'enum':
                formField.component     = 'sSelect';
                $.extend(true, formField.options, {
                    attrs           : {
                        'choices'       : '$ctrl.helperControls.formFields[\''+element.name+'\'].options.choices()',
                        'view-field'    : 'label',
                        'sort-by'       : 'label',
                        'value-field'   : 'value',
                        'ng-disabled'   : '$ctrl.helperControls.isDisabled'
                    },
                    choices: function () {
                        return element.options;
                    }
                });
                break;
            case 'conversation':
                formField.component     = 'sConversationSelect';
                break;
            case 'boolean':
                formField.component     = 'mdCheckbox';
                $.extend(true, formField.options, {
                    model: 'ng-model',
                    attrs: {
                        'ng-true-value'     : true,
                        'ng-false-value'    : false
                    }
                });
                break;
            case 'user_attribute':
                formField.component     = 'sMemberAttributeSelect';
                $.extend(true, formField.options, {
                    attrs: {
                        'allow-new'     : true,
                        'is-required'   : true,
                        'is-disabled'   : "$ctrl.helperControls.isDisabled"
                    }
                });
                break;
            case 'user_attribute_select':
                formField.component     = 'sMemberAttributeSelect';
                $.extend(true, formField.options, {
                    attrs: {
                        'is-required'   : true,
                        'key-only'      : true,
                        'use-context'   : true,
                        'is-disabled'   : "$ctrl.helperControls.isDisabled"
                    }
                });
                break;
            case 'wa_message_template':
                formField.component     = 'sWATemplateSelect';
                $.extend(true, formField.options, {
                    attrs: {
                        'is-required'   : true,
                        'key-only'      : true,
                        'use-context'   : true,
                        'is-disabled'   : "$ctrl.helperControls.isDisabled"
                    }
                });
                break;
            case 'json':
                formField.component = 'textarea';
                $.extend(true, formField.options, {
                    model: 'ng-model',
                    attrs: {
                        's-valid-json': true
                    }
                });
                break;
            case 'fields':
                formField.component = 'sActionFields';
                $.extend(true, formField.options, {
                    attrs: {
                        'fields-definition': '$ctrl.helperControls.formFields[\''+element.name+'\'].options.fields()'
                    },
                    fields: function () {
                        return element.fields;
                    }
                });
                break;
            case 'source_sort_field':
                formField.component = 'sSourceSortColumnPicker';
                $.extend(true, formField.options, {
                    attrs: {
                        'ng-required': false,
                        'source'     : '$ctrl.helperControls.formFields[\'sources_id\'].options.source'
                    },
                    source: null
                });
                break;
            default:
                formField.component     = 'div';
                $.extend(true, formField.options, {
                    model: 'ng-model',
                    attrs: {
                        'contenteditable'   : true,
                        's-form-wrapper'    : '{errorTemplate: \'sbase:text_errors\', class: \'s-text-input \'}',
                        'class'             : 'input',
                        'ng-model-options'  : '{allowInvalid: true}',
                        'allow-multiline'   : false,
                        'autocompleter'     : true,
                        'hint-placeholder'  : '',
                        'ng-disabled'       : '$ctrl.helperControls.isDisabled'
                    }
                });
                if (element.type === 'int' || element.type === 'integer') {
                    $.extend(true, formField.options, {
                        attrs: {
                            'ng-pattern'        : new RegExp('^(-)?[1-9]([0-9]+)?(?:<br>)?$'),
                            'placeholder'       : 'Enter a whole number',
                            'hint-placeholder'  : "'int'"
                        }
                    });
                    formField.options.attrs['s-form-wrapper'] = '{errorTemplate: \'sbase:integer_errors\', class: \'s-text-input \'}';
                }
                if (element.type === 'url') {
                    $.extend(true, formField.options, {
                        attrs: {
                            's-validate-url'    : true
                        }
                    });
                    formField.options.attrs['s-form-wrapper'] = '{errorTemplate: \'sbase:text_errors\', class: \'s-text-input \', min: 1, max: 999}';
                }
                break;
        }
        return formField;
    };

    Object.defineProperties(
        Bag,
        {
            TYPE_FIELDS: {
                enumerable: true,
                get: function () {
                    return TYPE_FIELDS;
                }
                /**
                 * @property
                 * @name Model.Action.Field.Bag.TYPE_FIELDS
                 * @type {string}
                 */
            },
            KEY_DEFAULT_VALUE: {
                enumerable: true,
                get: function () {
                    return KEY_DEFAULT_VALUE;
                }
                /**
                 * @property
                 * @name Model.Action.Field.Bag.KEY_DEFAULT_VALUE
                 * @type {string}
                 */
            }
        });

    ns.Bag = Bag;
})(Object.namespace('Model.Action.Field'));
