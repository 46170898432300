(function (ns) {
    /**
     * @namespace
     * @alias sSettings.Route.Base
     * @extends sRoute
     * @constructor
     */
    var Base = function Base() {
        var path = this.templateUrl;

        this.templateUrl = 'ssettings:index';

        this.resolvePath = function resolvePath() {
            return $.when(path);
        };

        Base._pProto.constructor.apply(this, arguments);
    };

    Object.extendProto(Base, sRoute);

    /**
     * @function
     * @name sSettings.Route.Base#resolveSubNavigation
     * @param {Model.Menu.Menu} sNavigationMenu
     * @return {PromiseLike}
     */
    Base.prototype.resolveSubNavigation = function resolveSubNavigation(sNavigationMenu) {
        var menu = sNavigationMenu.findItemByLabel('Settings');
        if (menu) {
            menu = menu.getElementsOfType(Model.Menu.Menu).slice(0, 1).pop();
        }
        return $.when(menu);
    };

    ns.Base = Base;
})(Object.namespace('sSettings.Route'));