(function (angular) {
    var sEditAnywhereDirective = angular.module('sEditAnywhereDirective', ['ngMaterial']);

    /**
     * @ngdoc directive
     * @name sEditAnywhere
     * @description
     * @element md-chips
     *
     * @param {Function} sEditAnywhere Callback for special cases checking whether the input needs focusing
     * @restrict A
     */
    sEditAnywhereDirective.directive('sEditAnywhere', function() {
        return {
            restrict: 'A',
            require: ['mdChips', 'sEditAnywhere'],
            bindToController: {
                focusCondition: '=sEditAnywhere'
            },
            controller: function() {
            },
            link: function($scope, $element, $attrs, $controllers) {
                var $deRegister = [],
                    isDown      = false
                ;

                var $mdChipCtrl         = $controllers[0],
                    $editAnywhereCtrl   = $controllers[1]
                ;

                // register a variable to deny mouseup handler conditionally
                $deRegister = $deRegister.concat($element.$on('mousedown', 'md-chip', function(event) {
                    isDown = $(event.currentTarget).attr('index');
                    event.stopPropagation();
                }));

                // reset if the variable was set and not freed
                $deRegister = $deRegister.concat($element.$on('mousedown', function() {
                    isDown = false;
                }));

                // only select the element if not blocked
                $deRegister = $deRegister.concat($element.$on('mouseup', function() {
                    if (isDown !== false) {
                        isDown = false;
                        return;
                    }

                    // quit if input element is focused
                    if ($mdChipCtrl.$element[0].querySelector('input:focus')
                        || ($mdChipCtrl.selectedChip === -1 && !$editAnywhereCtrl.focusCondition)) {
                        return;
                    }

                    $mdChipCtrl.focusInput();
                }));

                $scope.$on('$destroy', function() {
                    var $dRegFn;
                    while (($dRegFn = $deRegister.pop())) {
                        $dRegFn();
                    }
                });
            }
        }
    })
})(angular);