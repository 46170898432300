(function(ns) {
    /**
     * @namespace
     * @alias Model.Feature
     *
     * @constructor
     * @extends Model.RESTAccessByUUID
     */
    var Feature = function(uuid) {
        var permissions         = [],
            permissionsCache    = [],
            refreshPermissionsCache = function () {
                permissionsCache.splice.apply(
                    permissionsCache,
                    [0, permissionsCache.length].concat(permissions)
                );
            }
            ;

        Object.defineProperties(
            this,
            {
                'name' : {
                    enumerable  : true,
                    writable    : true
                },
                'ident' : {
                    enumerable  : true,
                    writable    : true
                },
                'system' : {
                    enumerable  : true,
                    writable    : true
                },
                'action' : {
                    enumerable  : true,
                    writable    : true
                },
                'permissions' : {
                    get: function() {
                        return permissionsCache;
                    }
                }
            }
        );

        /**
         * {Model.User.Permission} @param permission
         */
        this.addPermission = function addPermission(permission) {
            Object.instanceOf(permission, Model.User.Permission);

            if (permissions.indexOf(permission) === -1) {
                permissions.push(permission);
                permission.feature = this;
            }

            refreshPermissionsCache();
        };

        /**
         * @param permission
         */
        this.removePermission = function removePermission(permission) {
            var key;
            Object.instanceOf(permission, Model.User.Permission);

            if ((key = permissions.indexOf(permission)) !== -1) {
                permissions.splice(key, 1);
                permission.feature = null;
            }

            refreshPermissionsCache();
        };

        this.sortPermissions = function sortPermissions() {
            permissions.sort(function(a,b) {
                var valA = a.associatedPermissions.reduce(function(carry, association) {
                        if (association.type.substr(0,1) === '-') {
                            carry++;
                        } else if (association.type) {
                            carry--;
                        }
                        return carry;
                    }, 0),
                    valB = b.associatedPermissions.reduce(function(carry, association) {
                        if (association.type.substr(0,1) === '-') {
                            carry++;
                        } else if (association.type) {
                            carry--;
                        }
                        return carry;
                    }, 0)
                ;

                return valB - valA;
            });
            refreshPermissionsCache();
        };

        Feature._pProto.constructor.call(this, uuid);

        /**
         * @name Model.Feature#ident
         * @type String
         */

        /**
         * @name Model.Feature#name
         * @type String
         */

        /**
         * @name Model.Feature#permissions
         * @type Model.Permission[]
         */
    };

    Object.extendProto(Feature, Model.RESTAccessByUUID);

    Feature.prototype.updateByData = function(data) {
        Object.updateByData(this, data);

        this.name = this.name || this.ident;

        Feature._pProto.updateByData.call(this, data);
    };

    /**
     * @param {Object} data
     * @returns {Model.Feature}
     */
    Feature.createByData = function(data) {
        var feature = new Feature(data['uuid']);

        feature.updateByData(data);

        return feature;
    };

    /**
     * @return {$.Deferred}
     */
    Feature.query = function query() {
        return new Model.Collection.RESTAccess(Feature).load().then(function(collection) {
            return collection.items;
        })
    };

    ns.Feature = Feature;
})(Object.namespace('Model'));
