(function(angular) {
    var module = angular.module('sSourceModule', ['ngRoute']);

    module
        .provider('sSource', sSource.Provider.sSource)
    ;

    // bind $rootScope events
    module.run(['sSource', '$rootScope',
        /**
         * @param {sSource.Service.sSource} sSource
         * @param $rootScope
         */
        function(sSource, $rootScope) {
            $rootScope.$on('$routeChangeStart',
                /**
                 * @param event
                 * @param {Route} $route
                 */
                function(event, $route) {
                    if (event.defaultPrevented) {
                        return true;
                    }

                    $route.resolve = $route.resolve || {};
                    $route.resolve['sSourceInit'] = sSource.getPlaceholders.bind(sSource);
            });
        }
    ]);

    module.config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/content-source/overview', new sSource.Route.Overview())
            .when('/content-source/:uuid/content', new sSource.Route.ContentOverview())
        ;
    }]);

    module.config(['sTitleProvider', function(sTitleProvider) {
        sTitleProvider
            .when(
                '/content-source/overview',
                'Content Sources',
                'Overview'
            )
            .when(
                '/content-source/:uuid/content',
                'Source',
                'Content'
            )
        ;
    }]);

})(angular);
