(function(ns) {
    var SetPassword = function ($scope, $window, tokenAndTerms, apiEndpoints) {
        this.$scope         = $scope;
        this.$window        = $window;
        this.apiEndpoints   = apiEndpoints;

        this.requestInProgress = false;
        this.hasToAgreeTerms = tokenAndTerms.hasToAgreeTerms;
        this.credentials = {
            token: tokenAndTerms.token,
            password: '',
            passwordRepeat: '',
            termsAgreed: false
        };
    };

    /**
     * @function
     * @name sSecurity.Controller.SetPassword#sendRequest
     */
    SetPassword.prototype.sendRequest = function sendRequest() {
        var self = this,
            data = new FormData();

        data.append('password',         this.credentials.password.bin2hex());
        data.append('token',            this.credentials.token);
        data.append('terms',            this.credentials.termsAgreed);

        this.$scope.$evalAsync(function () {
            self.requestInProgress = true;
        });

        $.ajax({
            url         : this.apiEndpoints.security.resetPassword(),
            method      : Const.Method.POST,
            data        : data,
            processData : false,
            contentType : false
        }).then(
            function () {
                self.$window.location.href = self.apiEndpoints.baseUrl();
            },
            function () {
                self.$scope.$evalAsync(function () {
                    self.credentials.password = '';
                    self.credentials.passwordRepeat = '';
                });
            }
        ).always(function () {
            self.$scope.$evalAsync(function () {
                self.requestInProgress = false;
            });
        });
    };

    ns.SetPassword = SetPassword;
})(Object.namespace('sSecurity.Controller'));