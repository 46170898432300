(function(ns) {
    /**
     * @namespace
     * @alias sAnalytics.Controller.Campaign
     * @constructor
     *
     * @param $location
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param domainSegments
     * @param {Service.sDomain} sDomainService
     *
     * @extends sAnalytics.Controller.Overview
     */
    var Campaign = function (
        $location,
        sAPIAccess,
        domainSegments,
        sDomainService
    ) {
        var listEndpoint    = sAPIAccess.endpoint('analytics.byDomain.getCampaign').get(sDomainService.currentDomainId)
        ;

        this.$location          = $location;
        this.domainSegments     = domainSegments;

        this.list = this.getList(listEndpoint);

        var range = new Model.DateRange();
        range.from = moment().subtract(1, 'months').startOf('day');
        range.to = moment().endOf('day');

        this.filterFields = {
            time: {
                component: 'sDateRangePicker',
                template: '_component:s-dynamic-form-row',
                options: {
                    label: 'Date Range',
                    defaultValue: range,
                    asJson: function(key, value) {
                        if (key) {
                            return value;
                        }
                        var dateRange = new Model.DateRange();
                        dateRange.from = moment(value.from);
                        dateRange.to = moment(value.to);
                        return dateRange;
                    }
                }
            }
        };
    };

    /**
     * @function
     * @name sAnalytics.Controller.Campaign#getList
     * @param {string} listEndpoint
     * @returns {Model.List}
     */
    Campaign.prototype.getList = function getList(listEndpoint) {
        var column,
            list = new Model.List(listEndpoint),
            self = this
        ;

        list.mapFn = function (row) {
            var campaign = Model.Analytics.Campaign.fromJson(row);
            campaign.mapExistingSegment(self.domainSegments);
            return campaign;
        };

        column = list.columns.createAndAdd('date');
        column.label = 'Date';

        column = list.columns.createAndAdd('conversationTitle');
        column.label = 'Conversation';

        column = list.columns.createAndAdd('segment');
        column.label = 'Segment';

        column = list.columns.createAndAdd('status');
        column.label = 'Status';

        column = list.columns.createAndAdd('recipients');
        column.label = 'Recipients';

        list.filters.getOrCreateByName('date');

        return list;
    };

    /**
     * @function
     * @name sAnalytics.Controller.Campaign#changeToCategory
     * @param {string} category
     */
    Campaign.prototype.changeToCategory = function changeToCategory(category) {
        this.$location.href = '#/analytics/' + category;
    };


    ns.Campaign = Campaign;
})(Object.namespace('sAnalytics.Controller'));