(function (ns) {
    /**
     * @namespace
     * @alias sNavigation.Controller.Component.STabNavigation
     *
     * @param {Model.Menu.Menu} sNavigationMenu
     * @constructor
     */
    var sTabNavigation = function (sNavigationMenu) {
        this.sNavigationMenu = sNavigationMenu;
    };

    sTabNavigation.prototype.$onInit = function $onInit() {
        this.menu = this.sNavigationMenu.findItemByLabel(this.item);
    };
    
    ns.sTabNavigation = sTabNavigation;
})(Object.namespace('sNavigation.Controller.Component'));