(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sEventPicker
     *
     * @param $scope
     * @param {sAutomation.Service.sEventDefinition} sEventDefinition
     */
    var sEventPicker = function ($scope, sEventDefinition) {
        var eventsLoaded = null;

        this.$scope = $scope;

        /**
         * @function
         * @name Controller.Component.sEventPicker#getAvailableEvents
         */
        this.getAvailableEvents = function getAvailableEvents() {
            if (!eventsLoaded) {
                eventsLoaded = sEventDefinition.getDefinitions();
            }
            return eventsLoaded;
        };
    };

    /**
     * @function
     * @name Controller.Component.sEventPicker#$onInit
     */
    sEventPicker.prototype.$onInit = function $onInit() {
        var self = this;
        this.getAvailableEvents().then(function (eventDefinitions) {
            for (var i = 0; i < eventDefinitions.length; i++) {
                if (self.model.name === eventDefinitions[i].name) {
                    self.selectedEvent = eventDefinitions[i];
                    return;
                }
            }
        });
    };

    /**
     * @function
     * @name Controller.Component.sEventPicker#handleChangeSelected
     */
    sEventPicker.prototype.handleChangeSelected = function handleChangeSelected(eventDefinition) {
        this.selectedEvent = eventDefinition;
        digestIfNeeded(this.$scope);
    };

    /**
     * @param {Model.Event.Definition} item
     * @returns {Boolean}
     */
    sEventPicker.prototype.isItemDisabled = function isItemDisabled(item) {
        if (item.contentType === 'user_label') {
            item.disabledHint = Controller.Component.sMemberAttributeSelect.HINT_USER_LABEL_DEPRECATED;
            return true;
        }
        return false;
    };

    ns.sEventPicker = sEventPicker;
})(Object.namespace('Controller.Component'));
