(function(ns) {

    /**
     * @namespace
     * @alias Controller.Directive.sAccordionBehaviorController
     * @constructor
     *
     * @param $scope
     */
    var sAccordionBehaviorController = function sAccordionBehaviorController($scope) {
        /**
         * @name Controller.Directive.sAccordionBehaviorController#elements
         * @type Array
         */
        this.elements   = [];

        /**
         * @name Controller.Directive.sAccordionBehaviorController#activeId
         * @type Number
         */
        this.activeId   = 0;

        /**
         * @name Controller.Directive.sAccordionBehaviorController#$scope
         * @type Scope
         */
        this.$scope     = $scope;

        /**
         * @name Controller.Directive.sAccordionBehaviorController#touched
         * @type {boolean}
         */
        this.touched    = false;
    };

    /**
     * @function
     * @name Controller.Directive.sAccordionBehaviorController#addElement
     * @param val
     */
    sAccordionBehaviorController.prototype.addElement = function addElement(val) {
        if ((this.elements.indexOf(val)) !== -1) {
            return;
        }

        this.elements.push(val);

        if (this.touched) {
            this.activeId = this.elements.length - 1;
        } else if (this.defaultGroup) {
            this.activeId = this.elements.indexOf(this.defaultGroup);
        }
    };

    /**
     * @function
     * @name Controller.Directive.sAccordionBehaviorController#changeActive
     * @param element
     * @return {Boolean}
     */
    sAccordionBehaviorController.prototype.changeActive = function(element) {
        this.touched = true;
        var index;

        if ((index = this.elements.indexOf(element)) === -1) {
            return false;
        }

        if (this.activeId === index) {
            this.activeId = -1;
        } else {
            this.activeId = index;
        }
        return true;
    };

    /**
     * @function
     * @name Controller.Directive.sAccordionBehaviorController#remove
     * @param element
     */
    sAccordionBehaviorController.prototype.remove = function remove(element) {
        var index;

        if ((index = this.elements.indexOf(element)) === -1) {
            return;
        }

        this.elements.splice(index, 1);

        if (this.activeId === index) {
            this.activeId = -1;
        }

        this.touched = true;
    };

    /**
     * @function
     * @name Controller.Directive.sAccordionBehaviorController#$onInit
     */
    sAccordionBehaviorController.prototype.$onInit = function $onInit() {
        var self = this
        ;

        this.$unsubscribe = this.$scope.$on('accordion-changed', function(ev, val) {
            self.changeActive(val);
        });
    };

    /**
     * @function
     * @name Controller.Directive.sAccordionBehaviorController#$onDestroy
     */
    sAccordionBehaviorController.prototype.$onDestroy = function $onDestroy() {
        if (this.$unsubscribe) {
            this.$unsubscribe();
        }
    };

    /**
     * @function
     * @name Controller.Directive.sAccordionBehaviorController#getActiveElement
     * @return {Object}
     */
    sAccordionBehaviorController.prototype.getActiveElement = function getActiveElement() {
        if (this.activeId >= 0 && this.activeId < this.elements.length) {
            return this.elements[this.activeId];
        } else {
            return null;
        }
    };

    ns.sAccordionBehaviorController = sAccordionBehaviorController;
})(Object.namespace('Controller.Directive'));
