(function(ns) {
    /**
     * @namespace
     * @alias sHeadline.Provider.sTitle
     * @constructor
     */
    var sTitle = function () {
        var rules = [];

        this.$get = function $get($rootScope) {
            return new sHeadline.Service.sTitle($rootScope, rules);
        };

        this.when = function (path, title, subTitle) {
            rules.push({path: path, title: title, subTitle: subTitle});
            return this;
        }
    };

    ns.sTitle = sTitle;
})(Object.namespace('sHeadline.Provider'));