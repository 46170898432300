(function (ns) {
    var KEY_META_NEW = 'new';
    /**
     * @namespace
     * @alias Service.sMemberAttribute
     *
     * @constructor
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param $rootScope
     */
    var sMemberAttribute = function (sAPIAccess, $rootScope) {
        var attributesInProgress,
            valuesInProgress,
            attributesToAdd,
            cachedAttributes,
            reset = function reset() {
                attributesInProgress    = null;
                valuesInProgress        = {};
                attributesToAdd         = [];
                cachedAttributes        = [];
            }
        ;

        $rootScope.$on(Service.sDomain.EVENT_DOMAIN_CHANGED, function() {
            reset();
        });

        $rootScope.$on('$routeChangeStart', function() {
            reset();
        });

        reset();

        /**
         * This is a bit tricky as the attributes are cached as not grouped to favor later resolving with a whitelist that contains ungrouped
         * attributes as well. That is the reason why the function makes a mapping and doesn't return the cached promise immediately
         * @return {PromiseLike<String[]>}
         */
        this.getAvailableAttributes = function getAvailableAttributes() {
            if (!attributesInProgress) {
                attributesInProgress = $.ajax(sAPIAccess.endpoint('action.memberAttributes').get())
                    .then(function(attributeJSONs) {
                        var attributes = attributeJSONs.map(function(attributeJSON) {
                            attributeJSON.key = attributeJSON.name;
                            delete (attributeJSON.name);
                            return sMemberAttribute.createKeyValueObjectByData(attributeJSON);
                        });
                        cachedAttributes.push.apply(cachedAttributes, attributes);
                    });
            }

            return attributesInProgress.then(function() {
                return $.when(cachedAttributes.reduce(function(carry, attribute) {
                    if (!attribute.key) {
                        return carry;
                    }
                    if (carry.indexOf(attribute.key) === -1) {
                        carry.push(attribute.key);
                    }
                    return carry;
                }, []) , cachedAttributes)
            });
        };

        /**
         * @param {String} key
         * @return {PromiseLike<Model.Behavior.KeyValue>}
         */
        this.getAttributeByKey = function getAttributeByKey(key) {
            return this.getAvailableAttributes().then(function() {
                return cachedAttributes.filter(function(attribute) {
                    return attribute.key === key;
                }).pop();
            });
        };

        /**
         * @param {Model.Behavior.KeyValue|Model.Behavior.Meta} attribute
         */
        this.addAttribute = function addAttribute(attribute) {
            if (!attribute) {
                return;
            }

            if (!(attribute.getMeta instanceof Function)) {
                Model.Behavior.Meta.call(attribute);
            }

            var attributeValuesMatching = cachedAttributes.filter(function (element) {
                return element.value === attribute.value && element.key === attribute.key;
            });

            if (attributeValuesMatching.length !== 0) {
                return;
            }

            attribute.setMeta(KEY_META_NEW, true);
            cachedAttributes.push(attribute);
        };

        /**
         * @param {Model.Behavior.KeyValue[]=} attributesWhiteList
         * @return {PromiseLike}
         */
        this.save = function save(attributesWhiteList) {
            attributesWhiteList = attributesWhiteList || [];
            var toSave = cachedAttributes.filter(
                function (attribute) {
                    var retVal = true;
                    if (attributesWhiteList.length && retVal) {
                        retVal = attributesWhiteList.filter(function(attributeInWhiteList, index) {
                            if (attributeInWhiteList.key === attribute.key && (attributeInWhiteList.value === attribute.value || !attribute.value)) {
                                // use from attributes white list
                                attributesWhiteList.splice(index, 1);
                                return true;
                            }
                            return false;
                        }).length !== 0;
                    }

                    return retVal;
                }
            );

            attributesWhiteList.map(function (attribute) {

                this.addAttribute(attribute);
                toSave.push(attribute);
            }.bind(this));

            toSave = toSave.filter(function (attribute) {
                return (attribute.getMeta instanceof Function) && attribute.getMeta(KEY_META_NEW);
            });


            if (!toSave.length) {
                reset();
                return $.Deferred().resolve().promise();
            }

            return $.ajax({
                url     : sAPIAccess.endpoint('action.memberAttributes').post(),
                method  : Const.Method.POST,
                data    : {
                        'memberAttributes' : JSON.stringify(toSave)
                    }
                }
            ).then(function() {
                reset();
            });
        };

        /**
         *
         * @param {String}   attributeName
         * @param {Boolean=} includeLiveValues
         * @return {PromiseLike<String[]>}
         */
        this.getAvailableAttributeValues = function getAvailableAttributeValues(attributeName, includeLiveValues) {
            return this.getAvailableAttributes().then(function(attributeNames, attributes) {
                if (attributeNames.indexOf(attributeName) === -1) {
                    return [];
                }

                var attribute = attributes.filter(function(el) {
                    return el.key === attributeName;
                }).pop();

                if (!valuesInProgress[attributeName]) {
                    valuesInProgress[attributeName] = $.ajax(sAPIAccess.endpoint('action.memberAttributes.value').get(attributeName), includeLiveValues ? {data: {'includeLiveValues' : 1}} : null).then(function(values) {
                        if (!(values instanceof Array)) {
                            return [];
                        }

                        return values.map(function(value) {
                           cachedAttributes.push(sMemberAttribute.createKeyValueObjectByData({'key': attributeName, 'value': value, valueCount: attribute.valueCount, inUse: attribute.inUse}));
                        });
                    });
                }

                return valuesInProgress[attributeName].then(function() {
                    // attribute can be present without name, so filter those out
                    return cachedAttributes.filter(function(attribute) {
                        return attribute.key === attributeName && attribute.value;
                    }).map(function(attribute) {
                        return attribute.value;
                    });
                });
            });
        };
    };

    /**
     * @param {Model.Message[]} messages
     * @return {Model.Behavior.KeyValue[]}
     */
    sMemberAttribute.prototype.extractAttributesFromConversation = function extractAttributesFromConversation(messages) {
        return messages.reduce(function(carry, message) {
            for (var partUuid in message.messageParts) {
                carry.push.apply(carry, message.messageParts[partUuid].getAllActions());
            }
            return carry;
        }, []).filter(function (actionWithContext) {
            return actionWithContext.action.type === Model.Action.TYPE_USER_ATTRIBUTE;
        }).map(function(actionWithContext) {
            return actionWithContext.action;
        }).map(function(action) {
            return sMemberAttribute.createKeyValueObjectByData(JSON.parse(action.value));
        })
    };

    /**
     * @static
     * @param {Object} data
     * @return {Model.Behavior.KeyValue}
     */
    sMemberAttribute.createKeyValueObjectByData = function createKeyValueObjectByData(data) {
        var obj = {};
        Model.Behavior.KeyValue.call(obj);
        // create fields so they get populated
        obj.valueCount  = 0;
        obj.inUse       = false;
        Object.updateByData(obj, data);
        return obj;
    };

    ns.sMemberAttribute = sMemberAttribute;
})(Object.namespace('Service'));
