(function (ns) {
    /**
     * @param {Object} options
     * @param {FormData} data
     * @param {Number} counter
     */
    var dataTransformFunc   = function dataTransformFunc(options, data, counter) {
        var nameObj = {
            method  : options.method,
            url     : options.url,
            counter : counter
        };
        options.context.getFormData(data, nameObj);
    };

    /**
     * @namespace
     * @alias Model.Collection.RESTAccess
     *
     * @constructor
     * @extends Model.Collection
     * @param {Function} object Represented by it's constructor
     */
    var RESTAccess = function (object) {
        if (!Object.inherits(object, Model.RESTAccessByUUID)) {
            throw "Must be an object that inherits from Model.RESTAccessByUUID";
        }

        RESTAccess._pProto.constructor.call(this, object);

        var deleteStack = [],
            loading,
            saving,
            protecteds = {
                deleteStack                 : deleteStack,
                extraExecutablesBeforeSave  : null
            }
        ;
        
        Object.defineProperties(
            this,
            {
                object: {
                    enumerable: true,
                    get: function () {
                        return object;
                    }
                    /**
                     * @property
                     * @name Model.RESTAccess#object
                     * @type {Function}
                     */
                },
                loading: {
                    enumerable: true,
                    get: function () {
                        return loading && loading.state() === 'pending';
                    }
                    /**
                     * @property
                     * @name Model.Collection.RESTAccess#loading
                     * @type {Boolean}
                     */
                }
            });

        var parentRemoveItem = this.removeItem;

        /**
         * @param {Object} item
         */
        this.removeItem = function removeItem(item) {
            parentRemoveItem.call(this, item);

            if (item.isNew) {
                return;
            }

            var alreadyInStack = Boolean(deleteStack.filter(function(stackEntry) {
                return stackEntry.uuid === item.uuid;
            }).length);

            if (alreadyInStack) {
                return;
            }

            deleteStack.push(item);
        };


        var parentAddItem = this.addItem;

        /**
         * @param {Object} item
         * @param {Boolean=} prepend
         */
        this.addItem = function addItem(item, prepend) {
            parentAddItem.call(this, item, prepend);

            deleteStack.map(function(stackEntry, key) {
                if (stackEntry.uuid !== item.uuid) {
                    return;
                }

                deleteStack.splice(key, 1);
            });
        };

        this.save = function save() {
            if (!this.isModified()) {
                return $.Deferred().resolve([]).promise();
            }

            if (saving) {
                return saving;
            }

            var executables = deleteStack.map(function(item) {
                    return item.delete.bind(item);
                }).concat(
                    this.items.reduce(function(carry, item) {
                        carry.push(item.save.bind(item));
                        return carry;
                    }, [])
                );

            if (protecteds.extraExecutablesBeforeSave instanceof Array) {
                executables.push.apply(executables, protecteds.extraExecutablesBeforeSave);
            } else if (protecteds.extraExecutablesBeforeSave && typeof(protecteds.extraExecutablesBeforeSave) === 'object') {
                if (protecteds.extraExecutablesBeforeSave.pre) {
                    executables.unshift.apply(executables, protecteds.extraExecutablesBeforeSave.pre);
                }
                if (protecteds.extraExecutablesBeforeSave.post) {
                    executables.push.apply(executables, protecteds.extraExecutablesBeforeSave.post)
                }
            }

            saving = $.aggregateAction(
                executables,
                Model.RESTAccessByUUID.endpoint_batch(true),
                dataTransformFunc
            )
            .always(function() {
                // clear deleteStack on success
                saving = undefined;
                deleteStack.splice(0, deleteStack.length);
            })
            .then(
                function (promises, response) {
                    return response;
                },
                function (promises) {
                    return promises;
                }
            );

            return saving;
        };

        /**
         * Override for cached loading
         * @function
         * @name Model.Collection.RESTAccess#load
         * @param {String} url
         * @param {Object=} data
         * @param {Boolean=} force
         * @return {$.Deferred}
         */
        this.load = function load(url, data, force) {
            if (force) {
                this.reset();
            }

            if (!loading || force) {
                loading = Object.getPrototypeOf(this).load.call(this, url, data);
            }

            return loading;
        };

        /**
         * @param {*} item
         * @return {Number} -1 if not found
         */
        this.getIndex = function getIndex(item) {
            return this.items.reduce(function(carry, element, key) {
                if (carry !== -1 || element.uuid !== item.uuid) {
                    return carry;
                }

                carry = key;
                return carry;
            }, -1);
        };

        /**
         * @return {Boolean}
         */
        this.isModified = function() {
            if (deleteStack.length) {
                return true;
            }

            return this.items.reduce(function(carry, item) {
                carry = carry || item.isModified();
                return carry;
            }, false);
        };

        var updateByDataBase = this.updateByData;
        this.updateByData = function updateByData(data) {
            return updateByDataBase.call(this, data, ['uuid']);
        };

        if (this.constructor !== RESTAccess && this instanceof RESTAccess) {
            return protecteds;
        }
    };

    Object.extendProto(RESTAccess, Model.Collection);


    /**
     * @param {String=} url
     * @param {Object=} data
     * @return {$.Deferred}
     */
    RESTAccess.prototype.load = function load(url, data) {
        var self            = this,
            dummy           = new self.object(),
            restEndPoint    = dummy.endPoint
        ;

        return $.ajax({
            url      : url || restEndPoint,
            method   : Const.Method.GET,
            data     : data || {},
            dataType : "json"
        }).then(function(response) {
            if (!(response instanceof Array)) {
                return self;
            }

            // Magic ON
            // What this does is that uses the completed XHR data and uses it
            // to resolve the RESTAccessByUUID.load call emulating that there was an actual XHR request for those.
            // This is done to properly register that an element was found inside the database.
            var responseJson    = response,
                handler         = function(e, xhr, options) {
                    // Don't try to load data from cache for binary
                    if (options.dataType === 'binary') {
                        return;
                    }
                    options.dataType = 'resolveWithXHR';
                    options.ignoreAggregation = true;
                    options.extractorFn = function(options) {
                        return responseJson.reduce(function(carry, element) {
                            if (carry || !element.uuid || options.url.search(new RegExp('/' + element.uuid + '$')) === -1) {
                                return carry;
                            }
                            carry = element;
                            return carry;
                        }, null);
                    }
                },
                $deferredCollection = []
            ;

            $(document).ajaxSend(handler);

            // some cases require the item to be attached to the collection before it is updatedByData
            var addItemHandler = function(ev, item) {
                if (!(item instanceof self.object)) {
                    return;
                }
                self.addItem(item);
            };

            $(document).on(Model.RESTAccessByUUID.EVENT_REST_ACCESS_INSTANCE_RETRIEVED, addItemHandler);

            response.map(function (entryData) {
                var errors = [];
                /** @type Model.RESTAccessByUUID */
                $deferredCollection.push(
                    Model.RESTAccessByUUID.load.call(self.object, restEndPoint, entryData['uuid'], errors)
                );
            });

            $(document).off(Model.RESTAccessByUUID.EVENT_REST_ACCESS_INSTANCE_RETRIEVED, addItemHandler);
            $(document).off('ajaxSend', handler);
            // MAGIC off

            return $.when.apply(window, $deferredCollection).then(function() {
                return $.Deferred().resolve(self, Array.prototype.slice.call(arguments));
            });
        });
    };

    /**
     * @return {$.Deferred<Model.RESTAccessByUUID[]>}
     */
    RESTAccess.prototype.getItems = function getItems() {
        return this.load().then(function() {
            return this.items;
        }.bind(this));
    };

    ns.RESTAccess = RESTAccess;
})(Object.namespace('Model.Collection'));
