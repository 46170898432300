/**
 * Created by Gabor on 21-Feb-17.
 */

/**
 * Returns only the unique elements in an array
 * @param {Function=} mapFn
 * @returns {Array.<*>}
 */
Array.prototype.unique = function unique(mapFn) {
    var arrIn = this;
    if (mapFn) {
        arrIn = this.map(mapFn);
    }


    return this.filter(function(el, index) {
        return index === arrIn.indexOf(arrIn[index]);
    });
};

/**
 * Returns the intersection of arrays
 * @param {Array} arr
 * @param {Function=} mapFn
 * @returns {Array.<*>}
 */
Array.prototype.intersect = function intersect(arr, mapFn) {
    var arrIn = this.unique(mapFn).concat(arr.unique(mapFn));

    if (mapFn) {
        arrIn = arrIn.map(mapFn);
    }

    var duplicates = arrIn.filter(function(el, index) {
        return index !== arrIn.indexOf(arrIn[index]);
    });

    if (!duplicates.length) {
        return [];
    }

    if (!mapFn) {
        return duplicates;
    }

    arrIn = this.map(mapFn);

    return this.filter(function(el, index) {
        return duplicates.indexOf(arrIn[index]) !== -1;
    });
};

/**
 * @param {Array} arr
 * @param {Function=} mapFn
 */
Array.prototype.diff = function diff(arr, mapFn) {
    var arrIn       = this,
        arrInArg    = arr
        ;

    if (mapFn) {
        arrIn = this.map(mapFn);
        arrInArg = arr.map(mapFn);
    }

    var differences = arrInArg.unique().filter(function (el) {
        return arrIn.indexOf(el) === -1;
    });

    if (!mapFn) {
        return differences;
    }

    return arr.filter(function(el, index) {
        return differences.indexOf(arrInArg[index]) !== -1;
    });
};

/**
 * @param {Array} array
 * @see https://stackoverflow.com/a/14853974
 */
Array.prototype.equals = function (array) {
    // if the other array is a falsy value, return
    if (!array)
        return false;

    // compare lengths - can save a lot of time
    if (this.length !== array.length)
        return false;

    for (var i = 0, l = this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!this[i].equals(array[i]))
                return false;
        }
        else if (this[i] !== array[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
};

/**
 * @param {boolean=} reverseOrder false
 * @returns {Array}
 */
Array.prototype.sortLocaleCompare = function sortLocaleCompare(reverseOrder) {
    reverseOrder = reverseOrder || false;

    return this.sort(function (a, b) {
        var returnValue = 0;
        if (!b || !(b.toString instanceof Function)) {
            returnValue = -1;
        } else if (!a || !(a.toString instanceof Function)) {
            returnValue = 1;
        } else {
            returnValue = a.toString().localeCompare(b.toString()) * (reverseOrder ? -1 : 1);
        }

        returnValue *= reverseOrder ? -1 : 1;
        return returnValue;
    });
};

/**
 * @param {string} property
 * @param {boolean=} reverseOrder false
 * @returns {Function}
 */
Array.sortFnByProperty = function sortFnByProperty(property, reverseOrder) {
    reverseOrder = reverseOrder || false;
    return function (a, b) {
        var returnValue = 0;
        if ((a instanceof Object) && (b instanceof Object)) {
            if (typeof(a[property].localeCompare) !== "undefined") {
                returnValue = a[property].localeCompare(b[property]);
            } else if (a[property] < b[property]) {
                returnValue = -1;
            } else if (a[property] > b[property]) {
                returnValue = 1;
            }
        }

        if (reverseOrder) {
            returnValue *= -1;
        }

        return returnValue;
    }
};

Object.defineProperties(
    Array.prototype,
    {
        'unique': {
            enumerable: false
        },
        'intersect': {
            enumerable: false
        },
        'diff': {
            enumerable: false
        },
        'equals': {
            enumerable: false
        },
        'sortLocaleCompare': {
            enumerable: false
        },
        'clear': {
            value: function() {
                this.splice(0, this.length);
                return undefined;
            },
            configurable: true,
            enumerable: false
        }
    }
);

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function(predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return undefined.
            return undefined;
        },
        configurable: true,
        writable: true
    });
}