(function (ns) {
    /**
     * @namespace
     * @alias sMessageAnchor.Route.Creator
     * @extends sRoute
     * @constructor
     */
    var Creator = function Creator() {
        this.controller     = Controller.sMessageAdmin.IndexController;
        this.controllerAs   = '$ctrl';
        this.templateUrl    = 'smessageadmin:index';
        this.reloadOnUrl    = false;
        this.reloadOnSearch = false;
        this.requireDomain  = true;
        this.accessControl  = {'message.RESTAccess': Const.Method.POST};

        Creator._pProto.constructor.call(this);
    };

    Object.extendProto(Creator, sRoute);

    /**
     * @function
     * @name sMessageAnchor.Route.Creator#resolveConditionDefinitions
     * @param {sSegment.Service.sConditionDefinition} sSegmentConditionDefinition
     * @returns {*}
     */
    Creator.prototype.resolveConditionDefinitions = function resolveConditionDefinitions(sSegmentConditionDefinition) {
        return sSegmentConditionDefinition.init();
    };

    /**
     * @function
     * @name sMessageAnchor.Route.Creator#resolveMessageCollection
     * @param {Service.sDomain} sDomainService
     * @param $location
     * @param $route
     * @param {Service.sMessageCollection} sMessageCollection
     * @returns {Promise<Model.Message.Collection>|Model.Message.Collection}
     */
    Creator.prototype.resolveMessageCollection = function resolveMessageCollection(
        sDomainService,
        $location,
        $route,
        sMessageCollection
    ) {
        var params      = $route.current.params,
            messageUuid = params.uuid,
            loadingPromise
        ;

        if (messageUuid === 'new') {

            if (params.source === 'content-feed'
                && params.template
                && params.uuids
                && params.uuids.length
            ) {
                loadingPromise = sMessageCollection.buildByContentFeed(params.template, params.uuids);

                loadingPromise.then(
                    null,
                    function (err) {
                        return $.Deferred().reject(new sRouteRejection(err, {
                            messageUuid : messageUuid,
                            domain      : sDomainService.currentDomain ? sDomainService.currentDomain.name : null
                        }));
                    }
                );

                return loadingPromise;
            }

            return sMessageCollection.createInitialCollection(!params.asConversation);
        }

        loadingPromise = sMessageCollection.loadByMessageUuid(messageUuid);

        loadingPromise.then(
            function (collection) {
                var msgAnchor = collection.getAnchor();

                // check for domainId
                if (msgAnchor === null || msgAnchor.domainId !== sDomainService.currentDomain.id) {
                    // We are in the wrong domain scope, redirect to default page
                    $location.path('/');
                }
            },
            function (err) {
                return $.Deferred().reject(new sRouteRejection(err, {
                    messageUuid : messageUuid,
                    domain      : sDomainService.currentDomain ? sDomainService.currentDomain.name : null
                }));
            }
        );

        return loadingPromise;
    };

    ns.Creator = Creator;
})(Object.namespace('sMessageAnchor.Route'));
