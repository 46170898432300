(function(ns) {
    var OWNER_TIME   = 'time',
        OWNER_MOMENT = 'moment'
    ;

    /**
     * @namespace
     * @alias Controller.Component.sDatetime
     * @constructor
     *
     * @param $scope
     * @param moment
     */
    var sDatetime = function($scope, moment) {
        var timezoneObj = {
            name: 'unknown timezone',
            offsetNamed: 'unknown',
            offset: '+00:00'
        };

        this.$deRegister    = [];
        this.$scope         = $scope;
        this.moment         = moment;
        this.time           = null;

        Object.defineProperties(this, {
            timezoneObj: {
                get: function() {
                    return timezoneObj;
                },
                set: function(value) {

                    if (!angular.isString(value)) {
                        value = "";
                    }

                    var zone = moment.tz.zone(value);

                    // check for valid zone
                    if (zone === null) {
                        value = moment.tz.guess();
                    }

                    timezoneObj.name = value;
                    timezoneObj.offsetNamed = moment.tz(value).format('Z z');
                    timezoneObj.offset = moment.tz(value).format('ZZ');
                }
            },
            value: {
                enumerable: true,
                set: function() {
                    // empty setter, to avoid angular exception
                },
                get: function () {
                    return this.model.valueOf();
                }
                /**
                 * @property
                 * @name Controller.Component.sDatetime#value
                 * @type {Number}
                 */
            }

        });

        this.model          = (this.model || moment());
        this.timezoneObj    = (this.timezone || "");
    };

    /**
     * @name Controller.Component.sDatetime#$onInit
     */
    sDatetime.prototype.$onInit = function $onInit() {
        var self = this,
            // semaphore to prevent ping-pong in digest loop
            hasTheKing
        ;

        this.$deRegister.push(this.$scope.$watch(
            function() {
                return self.model;
            },
            function() {
                if (hasTheKing === OWNER_TIME) {
                    hasTheKing = null;
                    return;
                }
                hasTheKing = OWNER_MOMENT;
                // check for moment-object
                if (!moment.isMoment(self.model)) {
                    throw new Error("Must be a moment-object.");
                }

                // date must be a Date-object because of ng-material
                self.date = self.model.toDate();
                self.time = Model.Time.fromISOTime(self.model.format('HH:mm:ssZ'));
            },
            true
        ));

        this.$deRegister.push(this.$scope.$watch(
            function() {
                return self.time.getISOTime();
            },
            function() {
                if (hasTheKing === OWNER_MOMENT) {
                    hasTheKing = null;
                    return;
                }
                hasTheKing = OWNER_TIME;
                self.model
                    .hours(self.time.hours)
                    .minutes(self.time.minutes)
                    .seconds(self.time.seconds)
                ;
            },
            true
        ));
    };

    /**
     * @name Controller.Component.sDatetime#$onDestroy
     */
    sDatetime.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @param event
     */
    sDatetime.prototype.setMinAsNewValue = function setMinAsNewValue(event) {
        event.preventDefault();
        this.model = moment(this.min).add(1, 'seconds');
    };

    /**
     * @name Controller.Component.sDatetime#onChangeDate
     * @param {Date} date
     */
    sDatetime.prototype.onChangeDate = function onChangeDate(date) {
        var oldValObject = this.model.toObject();
        this.model = moment(date)
            .hours(oldValObject.hours)
            .minutes(oldValObject.minutes)
            .seconds(oldValObject.seconds);
    };

    ns.sDatetime = sDatetime;

})(Object.namespace('Controller.Component'));


