(function(ns) {
    var EVENT_COLLECTION_ENTITIES_CHANGED = 'sEventMatchTextCollectionEntitiesChanged',
        EVENT_UPDATE_ENTITY_MATCH_GROUPS  = 'sEventUpdateMatchGroups'
    ;

    /**
     * @namespace
     * @alias Controller.Component.sMatchTextCollection
     * @param $element
     * @param $scope
     */
    var sMatchTextCollection = function ($element, $scope) {
        var lastEntities    = [];

        this.$deRegister    = [];
        this.$element       = $element;
        this.$scope         = $scope;

        this.refreshEntitiesIfNeeded = function refreshEntitiesIfNeeded() {
            if (this.model.isNegative) {
                return;
            }

            var entities = this.model.getEntities();
            $scope.$broadcast(EVENT_UPDATE_ENTITY_MATCH_GROUPS, entities);

            if (JSON.stringify(lastEntities) === JSON.stringify(entities)) {
                return;
            }

            lastEntities = entities;

            $scope.$emit(EVENT_COLLECTION_ENTITIES_CHANGED, entities);
        };

        Object.defineProperties(
            this,
            {
                hasPartialEntity: {
                    get: function () {
                        return lastEntities.filter(function (entity) {
                            return entity.getMeta(Model.AI.MatchTextCollection.KEY_PARTIAL_ENTITY);
                        }).length !== 0;
                    }
                    /**
                     * @property
                     * @name Controller.Component.sMatchTextCollection#hasPartialEntity
                     * @type {Boolean}
                     */
                }
            });

        /**
         * @property
         * @name Controller.Component.sMatchTextCollection#model
         * @type {Model.AI.MatchTextCollection}
         */
    };

    sMatchTextCollection.prototype.$onInit = function $onInit() {
        var self = this;
        this.$deRegister = this.$deRegister.concat(
            this.$element.$on('blur', '[contenteditable]', this.refreshEntitiesIfNeeded.bind(this))
        );

        this.$deRegister.push(this.$scope.$watch(function() {
                return JSON.stringify(
                    self.model.matchTexts.map(function(matchText) {
                        return Model.AI.MatchText.buildPattern(matchText.groups.filter(function (group) {
                            return group.entity;
                        }));
                    })
                ).replace(Controller.Directive.ContentEditableController.SELECTION_MARKERS_REGEXP, '');
            },
            function() {
                self.refreshEntitiesIfNeeded();
            })
        );

        this.$deRegister.push(this.$scope.$watch(function() {
                return JSON.stringify(
                    self.model.matchTexts.map(function(matchText) {
                        return Model.AI.MatchText.buildPattern(matchText.groups.filter(function (group) {
                            return group.entity;
                        }));
                    })
                ).replace(Controller.Directive.ContentEditableController.SELECTION_MARKERS_REGEXP, '');
            },
            function() {
                self.refreshEntitiesIfNeeded();
            })
        );
    };

    sMatchTextCollection.prototype.$onDestroy = function $onDestroy() {
        /**
         * @type {Function}
         */
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    /**
     * @param {Object} changes
     */
    sMatchTextCollection.prototype.$onChanges = function $onChanges(changes) {
        if (changes.min) {
            this.model.min = changes.min.currentValue;
        }

        if (changes.max) {
            this.model.max = changes.max.currentValue;
        }
    };

    /**
     * @param {Model.AI.Entity} entity
     */
    sMatchTextCollection.prototype.handleEntityNameChanged = function handleEntityNameChanged(entity) {
        this.model.matchTexts.map(function (matchText) {
            matchText.groups.map(function (group) {
                if (!group.entity || group.entity.uuid !== entity.uuid || group.entity === entity) {
                    return;
                }
                group.entity = entity;
                group.elements[0].value = Model.AI.MatchGroup.ENTITY_PREFIX + entity.name;
            });

            matchText.pattern = Model.AI.MatchText.buildPattern(matchText.groups);
        });
        digestIfNeeded(this.$scope);
    };

    Object.defineProperties(
        sMatchTextCollection,
        {
            EVENT_COLLECTION_ENTITIES_CHANGED: {
                value: EVENT_COLLECTION_ENTITIES_CHANGED
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sMatchTextCollection#EVENT_COLLECTION_ENTITIES_CHANGED
                 * @type {String}
                 */
            },
            EVENT_UPDATE_ENTITY_MATCH_GROUPS: {
                value: EVENT_UPDATE_ENTITY_MATCH_GROUPS
                /**
                 * @property
                 * @constant
                 * @name Model.AI.MatchTextCollection#EVENT_UPDATE_ENTITY_MATCH_GROUPS
                 * @type {String}
                 */
            }
        });

    ns.sMatchTextCollection = sMatchTextCollection;
})(Object.namespace('Controller.Component'));
