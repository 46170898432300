(function(ns) {
    var TASK_TYPE_REQUIRED      = 'MANAGE',
        REJECT_REASON_MANUAL    = 'manually_closed'
    ;

    /**
     * @namespace
     * @alias sSender.Service.sInstagramDirectAccount
     * @constructor
     *
     * @param $mdDialog
     * @param $mdPanel
     * @param $exceptionHandler
     * @param sBugsnag
     * @param {Service.sProgressIndicator} sProgressIndicator
     * @param {Service.sConfirm} sConfirm
     * @param {sFacebook.Service.sFacebookClient} sFacebookClient
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     */
    var sInstagramDirectAccount = function sInstagramDirectAccount(
        $mdDialog,
        $mdPanel,
        $exceptionHandler,
        sBugsnag,
        sProgressIndicator,
        sConfirm,
        sFacebookClient,
        sAPIAccess
    ) {
        this.$mdDialog          = $mdDialog;
        this.$mdPanel           = $mdPanel;
        this.$exceptionHandler  = $exceptionHandler;
        this.sBugsnag           = sBugsnag;
        this.sProgressIndicator = sProgressIndicator;
        this.sConfirm           = sConfirm;
        this.sFacebookClient    = sFacebookClient;
        this.sAPIAccess         = sAPIAccess;
    };

    /**
     * @function
     * @name sSender.Service.sInstagramDirectAccount#loadAll
     * @param {[]?} filters
     * @return {$.Deferred<Model.Sender[]>}
     */
    sInstagramDirectAccount.prototype.loadAll = function loadAll(filters) {
        filters = filters || [];
        var collection = new Model.Sender.Collection(Model.Sender.InstagramDirectAccount);
        return collection
            .load(this.sAPIAccess.endpoint('sender.instagramDirect.all').get(), {dontPaginate: true})
            .then(function (collection) {
                // checks for presence of a certain property on the account model
                // and if the property does not evaluate to false
                return collection.items.filter(function(account) {
                    return filters.reduce(function(carry, filter) {
                        if (carry && carry[filter]) {
                            return carry;
                        } else {
                            return null;
                        }
                    }, account);
                });
            });
    };

    /**
     * @function
     * @name sSender.Service.sInstagramDirectAccount#synchronize
     * @param {Model.Sender.InstagramDirectAccount} instagramDirectAccount
     * @return {PromiseLike}
     */
    sInstagramDirectAccount.prototype.synchronize = function synchronize(instagramDirectAccount) {
        return $.ajax({
            url: this.sAPIAccess.endpoint('sender.instagramDirect.synchronize').put(instagramDirectAccount.uuid),
            method: Const.Method.PUT
        });
    };

    /**
     * @function
     * @name sSender.Service.sInstagramDirectAccount#addNewAccount
     * @return {PromiseLike<Model.Sender.InstagramDirectAccount>}
     */
    sInstagramDirectAccount.prototype.addNewAccount = function addNewAccount() {
        var self = this;

        return this.showSetupAccountDialog()
            .then(function (instagramDirectAccount) {

                return self.save(instagramDirectAccount).fail(function (reason) {
                    self.sBugsnag.notify(
                        new Model.Facebook.ConnectionException('Save failed'),
                        {
                            metaData: {reason: reason}
                        }
                    );
                });
            })
            .then(function (instagramDirectAccount) {
                return self.connect(instagramDirectAccount).fail(function (reason) {
                    self.sBugsnag.notify(
                        new Model.Facebook.ConnectionException('Connect failed'),
                        {
                            metaData: {reason: reason}
                        }
                    );
                });
            });
    };

    /**
     * @function
     * @name sSender.Service.sInstagramDirectAccount#save
     * @param {Model.Sender.InstagramDirectAccount} instagramDirectAccount
     * @return {PromiseLike<Model.Sender.InstagramDirectAccount>}
     */
    sInstagramDirectAccount.prototype.save = function save(instagramDirectAccount) {
        return instagramDirectAccount.save().then(function (data) {
            if (!data || !data.uuid) {
                return instagramDirectAccount;
            }

            // recreate model to have proper uuid which is returned from backend
            var fbData = JSON.parse(JSON.stringify(instagramDirectAccount));
            fbData.uuid = data.uuid;
            instagramDirectAccount = Model.Sender.createByData(Model.Sender.InstagramDirectAccount.TYPE, fbData);
            instagramDirectAccount.setAsExisting();

            return instagramDirectAccount;
        });
    };

    /**
     * @function
     * @name sSender.Service.sInstagramDirectAccount#connect
     * @param {Model.Sender.InstagramDirectAccount} instagramDirectAccount
     * @param {Boolean=} reconnect
     * @return {PromiseLike}
     */
    sInstagramDirectAccount.prototype.connect = function connect(instagramDirectAccount, reconnect) {
        var formData = new FormData();

        formData.append('authData', JSON.stringify({
            userAccessToken : instagramDirectAccount.meta.userAccessToken,
            pageAccessToken : instagramDirectAccount.meta.pageAccessToken,
            grantedScopes   : instagramDirectAccount.meta.grantedScopes,
            userId          : instagramDirectAccount.meta.userId,
            reconnect       : !!reconnect
        }));

        return $.ajax({
            url         : this.sAPIAccess.endpoint('sender.instagramDirect.connect').put(instagramDirectAccount.uuid),
            method      : Const.Method.PUT,
            data        : formData,
            processData : false
        });
    };

    /**
     * @function
     * @name sSender.Service.sInstagramDirectAccount#remove
     * @param {Model.Sender.InstagramDirectAccount} instagramDirectAccount
     * @return {PromiseLike}
     */
    sInstagramDirectAccount.prototype.remove = function remove(instagramDirectAccount) {
        var confirmOptions = {
            confirm: 'Remove',
            decline: 'Cancel',
            title: 'Remove Instagram Direct Account',
            content: 'Do you really want to remove the Instagram Direct Account<b>' + instagramDirectAccount.alias + '</b> ?'
        };

        return this.sConfirm.open(confirmOptions).then(
            function () {
                return instagramDirectAccount.delete()
            },
            function () {
                return false;
            });
    };

    /**
     * @function
     * @name sFacebook.Service.sInstagramDirectAccount#showSetupAccountDialog
     * @return {PromiseLike<Model.Sender.InstagramDirectAccount>}
     */
    sInstagramDirectAccount.prototype.showSetupAccountDialog = function showSetupAccountDialog() {
        var self = this,
            deferred = $.Deferred()
        ;

        this.$mdDialog.show({
            controller          : sSender.Controller.InstagramDirectAccountSetupDialog,
            controllerAs        : '$ctrl',
            bindToController    : true,
            templateUrl         : 'ssender:instagram-direct-account-setup-dialog',
            parent              : angular.element(Const.PanelAnchor),
            clickOutsideToClose : false
        })
            .then(deferred.resolve)
            .catch(function (err) {
                if (!err) {
                    deferred.reject(REJECT_REASON_MANUAL);
                    return;
                }
                self.$exceptionHandler(err);
                deferred.reject(err);
            });

        return deferred.promise();
    };

    /**
     * @function
     * @name sSender.Service.sInstagramDirectAccount#authenticateWithFacebook
     * @param {Model.Facebook.Connection} connection
     * @param {Model.Sender.InstagramDirectAccount} instagramDirectAccount
     * @return {PromiseLike}
     */
    sInstagramDirectAccount.prototype.authenticateWithFacebook = function authenticateWithFacebook(connection, instagramDirectAccount) {
        var permissions = instagramDirectAccount.permissionsRequired.concat(instagramDirectAccount.permissionsOptional);

        return connection.login({
            scope           : permissions.join(','),
            return_scopes   : true,
            auth_type       : 'rerequest',
            auth_nonce      : Math.random().toString(36).substring(2, 15)
        }).then(function(response) {

            if (!response || !response.authResponse) {
                return $.Deferred().reject('User cancelled login or did not fully authorize.');
            }

            instagramDirectAccount.setMeta('grantedScopes', response.authResponse.grantedScopes);
            instagramDirectAccount.setMeta('userAccessToken', response.authResponse.accessToken);
            instagramDirectAccount.setMeta('userId', response.authResponse.userID);

            return response;
        });
    };

    /**
     * @function
     * @name sSender.Service.sInstagramDirectAccount#getReadableGraphPages
     * @param {Model.Facebook.Connection} connection
     * @return {PromiseLike<FacebookPage[]>}
     */
    sInstagramDirectAccount.prototype.getReadableGraphPages = function getReadableGraphPages(connection) {
        var fetchAllDeferred,
            domainIGDirectAccountsDeferred
        ;

        fetchAllDeferred = connection.apiFetchAll(
            '/me/accounts',
            Const.Method.GET,
            {fields: 'name,tasks,access_token,instagram_business_account{id,username}'}
        );

        domainIGDirectAccountsDeferred = this.loadAll().then(function (instagramDirectAccountSenders) {
            return instagramDirectAccountSenders
                .map(function (instagramDirectAccount) {
                    return instagramDirectAccount.subjectId ? instagramDirectAccount.subjectId.toString() : null;
                })
                .filter(function (instagramDirectAccount) {
                    return instagramDirectAccount !== null;
                })
        });

        return $.when(fetchAllDeferred, domainIGDirectAccountsDeferred).then(function (facebookPagesResult, domainIGDirectAccountIds) {
            if (!(facebookPagesResult instanceof Array)) {
                return [];
            }

            facebookPagesResult = facebookPagesResult.filter(function (fbPageResult) {
                return !!fbPageResult.instagram_business_account && !!fbPageResult.instagram_business_account.id;
            });

            return facebookPagesResult.map(function (fbPageResult) {
                return {
                    id              : fbPageResult.instagram_business_account.id,
                    name            : fbPageResult.instagram_business_account.username,
                    pageId          : fbPageResult.id,
                    pageName        : fbPageResult.name,
                    pageAccessToken : fbPageResult.access_token,
                    hasRoleToConnect: hasRoleToConnect(fbPageResult),
                    alreadyInList   : domainIGDirectAccountIds.indexOf(fbPageResult.instagram_business_account.id) !== -1
                };
            });
        });
    };

    /**
     * @function
     * @name sSender.Service.sInstagramDirectAccount#connectWithAuthentication
     * @param {Model.Sender.InstagramDirectAccount} instagramDirectAccount
     * @param {Boolean=} reconnect
     * @return {PromiseLike}
     */
    sInstagramDirectAccount.prototype.connectWithAuthentication = function connectWithAuthentication(instagramDirectAccount, reconnect) {
        var self        = this,
            connection  = this.getConnectionInstance(instagramDirectAccount)
        ;

        return this.authenticateWithFacebook(connection, instagramDirectAccount).then(function () {
            return self.connect(instagramDirectAccount, reconnect);
        });
    };

    /**
     * @function
     * @name sSender.Service.sInstagramDirectAccount#enable
     * @param {Model.Sender.InstagramDirectAccount} instagramDirectAccount
     * @return {PromiseLike}
     */
    sInstagramDirectAccount.prototype.enable = function enable(instagramDirectAccount) {
        return $.ajax({
            url     : this.sAPIAccess.endpoint('sender.instagramDirect.enable').put(instagramDirectAccount.uuid),
            method  : Const.Method.PUT
        });
    };

    /**
     * @function
     * @name sSender.Service.sInstagramDirectAccount#disable
     * @param {Model.Sender.InstagramDirectAccount} instagramDirectAccount
     * @return {PromiseLike}
     */
    sInstagramDirectAccount.prototype.disable = function disable(instagramDirectAccount) {
        return $.ajax({
            url     : this.sAPIAccess.endpoint('sender.instagramDirect.disable').put(instagramDirectAccount.uuid),
            method  : Const.Method.PUT
        });
    };

    /**
     * @function
     * @name sSender.Service.sInstagramDirectAccount#getConnectionInstance
     * @param {Model.Sender.InstagramDirectAccount} instagramDirectAccount
     * @return {Model.Facebook.Connection}
     */
    sInstagramDirectAccount.prototype.getConnectionInstance = function getConnectionInstance(instagramDirectAccount) {
        return this.sFacebookClient.createConnection(instagramDirectAccount.appId);
    };

    /**
     * @function
     * @name sSender.Service.sInstagramDirectAccount#getSkeletonAccount
     * @return {PromiseLike<Model.Sender.InstagramDirectAccount>}
     */
    sInstagramDirectAccount.prototype.getSkeletonAccount = function getSkeletonAccount() {
        return $.ajax({
            url: this.sAPIAccess.endpoint('sender.instagramDirect.skeleton').get()
        }).then(function (data) {
            return Model.Sender.createByData(Model.Sender.InstagramDirectAccount.TYPE, data);
        });
    };

    /**
     * @function
     * @name sSender.Service.sInstagramDirectAccount~hasRoleToConnect
     * @param {FacebookPage} facebookPage
     * @return {Boolean}
     */
    var hasRoleToConnect = function hasRoleToConnect(facebookPage) {
        return !!facebookPage.tasks && facebookPage.tasks.indexOf(TASK_TYPE_REQUIRED) !== -1
    };

    Object.defineProperties(
        sInstagramDirectAccount,
        {
            REJECT_REASON_MANUAL : {
                value : REJECT_REASON_MANUAL
                /**
                 * @property
                 * @constant
                 * @name sSender.Service.sInstagramDirectAccount#REJECT_REASON_MANUAL
                 * @type {String}
                 */
            }
        }
    );

    ns.sInstagramDirectAccount = sInstagramDirectAccount;
})(Object.namespace('sSender.Service'));
