(function (ns) {
    /**
     * @namespace
     * @alias Model.List.RESTAccess
     *
     * @constructor
     * @extends Model.List
     * @param {Function} object
     * @param {String} endPoint
     * @param {Number=} defaultRecordsPerPage
     */
    var RESTAccess = function (object, endPoint, defaultRecordsPerPage) {
        if (!Object.inherits(object, Model.RESTAccessByUUID)) {
            throw "Must be an object that inherits from Model.RESTAccessByUUID";
        }
        var collection  = new Model.Collection.RESTAccess(object),
            dummy       = new object();

        endPoint = endPoint || dummy.endPoint;

        RESTAccess._pProto.constructor.call(this, endPoint, defaultRecordsPerPage);

        Object.defineProperties(
            this,
            {
                collection: {
                    enumerable: true,
                    get: function () {
                        return collection;
                    }
                    /**
                     * @property
                     * @name Model.List.RESTAccess#collection
                     * @type {Model.Collection.RESTAccess}
                     */
                }
            });
    };

    Object.extendProto(RESTAccess, Model.List);

    /**
     * @param {Object=} queryParams
     * @return {$.Deferred}
     */
    RESTAccess.prototype.load = function load(queryParams) {
        var headerInfo  = undefined,
            self        = this,
            fn = function(ev, xhr, options) {
                if (options.url.search(new RegExp(self.endPoint + '\\\?*?')) === -1) {
                    return;
                }

                options.dataFilter = function(raw, type) {
                    // already parsed just do nothing
                    if (headerInfo) {
                        return raw;
                    }

                    var wasString = typeof(raw) === 'string';
                    if (wasString && type === 'json') {
                        raw = JSON.parse(raw);
                    }

                    if (!raw) {
                        return [];
                    }

                    var coll = raw.records || [];
                    headerInfo = raw._meta;

                    if (wasString && type === 'json') {
                        coll = JSON.stringify(coll);
                    }

                    return coll;
                }
            }
        ;

        $(document).ajaxSend(fn);
        var promise = this.collection.load(this.endPoint, queryParams, true)
            .then(function(collection) {
                self.parseMeta(headerInfo);

                return collection.items;
            });
        $(document).off("ajaxSend", fn);

        return promise;
    };

    ns.RESTAccess = RESTAccess;
})(Object.namespace('Model.List'));