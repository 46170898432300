(function (ns) {
    /**
     * @namespace
     * @alias sSettings.Route.UserManagement
     * @extends sSettings.Route.Base
     * @constructor
     */
    var UserManagement = function UserManagement() {
        this.controller     = sUserManagement.Controller.List;
        this.controllerAs   = '$ctrl';
        this.templateUrl    = 'susermanagement:list';
        this.reloadOnSearch = false;
        this.accessControl  = {'user.byDomain.list': Const.Method.GET};

        UserManagement._pProto.constructor.apply(this, arguments);
    };

    Object.extendProto(UserManagement, ns.Base);

    /**
     * @name sSettings.Route.UserManagement#resolveListEndpoint
     * @param {Service.sDomain} sDomainService
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @returns {PromiseLike}
     */
    UserManagement.prototype.resolveListEndpoint = function resolveListEndpoint(sDomainService, sAPIAccess) {
        return sDomainService.getCurrentDomain().then(function (currentDomain) {
            return sAPIAccess.endpoint('user.byDomain.list').get(currentDomain.id);
        });
    };

    ns.UserManagement = UserManagement;
})(Object.namespace('sSettings.Route'));
