(function (ns) {
    /**
     * @namespace
     * @alias sDomain.Controller.Admin.PlanEdit
     * @constructor
     */
    var PlanEdit = function (origDomain, $mdDialog, $scope, apiEndpoints) {
        this.$scope             = $scope;
        this.$mdDialog          = $mdDialog;
        this.apiEndpoints       = apiEndpoints;
        this.domain             = origDomain.clone();
        this.origDomain         = origDomain;
        this.savingInProgress   = null;
        this.errorMsgs          = [];
        this.availablePlans     = new Model.Collection.RESTAccess(Model.Plan).load().then(function(collection) {
            return collection.items;
        });
    };

    /**
     * @function
     * @name sDomain.Controller.Admin.PlanEdit#save
     * @returns void
     */
    PlanEdit.prototype.save = function save() {
        var self = this;

        if (this.savingInProgress) {
            return;
        }

        this.errorMsgs = [];
        this.origDomain.plan = this.domain.plan;

        this.savingInProgress = this.origDomain.save().then(
            function () {
                self.$mdDialog.hide();
            },
            function (jqXHR) {
                if (jqXHR.responseJSON && jqXHR.responseJSON.messages) {
                    for (var k in jqXHR.responseJSON.messages) {
                        self.errorMsgs.push(jqXHR.responseJSON.messages[k]);
                    }
                }
                digestIfNeeded(self.$scope);
            }
        ).always(function () {
            self.savingInProgress = null;
        });
    };

    /**
     * @function
     * @name sDomain.Controller.Admin.PlanEdit#cancel
     */
    PlanEdit.prototype.cancel = function cancel() {
        this.$mdDialog.cancel(true);
    };

    /**
     * @function
     * @name sDomain.Controller.Admin.PlanEdit#compareDomains
     * @param {Model.Plan} planA
     * @param {Model.Plan} planB
     * @returns Boolean
     */
    PlanEdit.prototype.comparePlans = function comparePlans(planA, planB) {
        if (planA === planB) {
            return true;
        }
        if (!planA || !planB) {
            return false;
        }
        return planA.uuid === planB.uuid;
    };

    ns.PlanEdit = PlanEdit;
})(Object.namespace('sDomain.Controller.Admin'));
