(function (ns) {
    /**
     * @namespace
     * @alias Model.Automation.Step
     * @constructor
     * @implements {Model.Behavior.Meta}
     */
    var Step = function () {
        Model.Behavior.Meta.call(this);
        var exceptionEvents = new Model.Event.Collection(),
            actions = new Model.Action.Collection(),
            delay           ,
            segment         ,
            schedule
            ;

        Object.defineProperties(
            this,
            {
                name: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @name Model.Automation.Step#name
                     * @type {String}
                     */
                },
                delay: {
                    enumerable: true,
                    get: function() {
                        return delay;
                    },
                    set: function(val) {
                        if (val) {
                            Object.instanceOf(val, Model.Delay);
                        }

                        if (delay === val) {
                            return;
                        }

                        delay = val;
                    }
                    /**
                     * @property
                     * @name Model.Automation.Step#delay
                     * @type {?Model.Delay}
                     */
                },
                conversationUuid: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @name Model.Automation.Step#conversationUuid
                     * @type {String}
                     */
                },
                actions       : {
                    enumerable   : true,
                    configurable : true,
                    get          : function () {
                        return actions;
                    },
                    set          : function (val) {
                        Object.instanceOf(val, Model.Action.Collection);
                        actions = val;
                    }
                },
                exceptionEvents: {
                    enumerable: true,
                    get: function() {
                        return exceptionEvents;
                    }
                    /**
                     * @property
                     * @name Model.Automation.Step#exceptionEvents
                     * @type {Model.Event.Collection}
                     */
                },
                segment: {
                    enumerable: true,
                    get: function() {
                        return segment;
                    },
                    set: function(val) {
                        if (val) {
                            Object.instanceOf(val, Model.Segment);
                        }

                        if (segment === val) {
                            return;
                        }

                        segment = val;
                    }
                    /**
                     * @property
                     * @name Model.Automation.Step#segment
                     * @type {?Model.Segment}
                     */
                },
                schedule: {
                    enumerable: true,
                    get: function() {
                        return schedule;
                    },
                    set: function(val) {
                        if (val) {
                            Object.instanceOf(val, Model.Schedule);
                        }

                        if (schedule === val) {
                            return;
                        }

                        schedule = val;
                    }
                    /**
                     * @property
                     * @name Model.Automation.Step#schedule
                     * @type {?Model.Schedule}
                     */
                }
            }
        );

        this.name = 'Automation Step';


        var pExtendClone = this.__extendClone;
        /**
         * @param {Model.Automation.Step} original
         * @private
         */
        this.__extendClone = function __extendClone(original) {
            this.exceptionEvents.__extendClone(original.exceptionEvents);
            pExtendClone.call(this, original);
        };
    };

    /**
     * @param {Model.Automation.Step} data
     */
    Step.prototype.updateByData = function updateByData(data) {
        var dummy = {
            events: data.exceptionEvents
        };
        this.exceptionEvents.updateByData(dummy);
        if (data.exceptionEvents) {
            delete (data.exceptionEvents);
        }

        if (data.delay) {
            this.delay = Model.Delay.createByData(data.delay);
        }

        if (data.schedule) {
            this.schedule = Model.Schedule.createByData(data.schedule);
        }

        if (data.segment) {
            this.segment = Model.Segment.createByData(data.segment);
        }

        Object.updateByData(this, data);

        // needs to be re-set since update by data can happen multiple times and should not alter the incoming data
        data.exceptionEvents = dummy.events;

        return this;
    };

    ns.Step = Step;
})(Object.namespace('Model.Automation'));
