(function(ns) {
    /**
     * @namespace
     * @alias Service.sAction
     * @constructor
     *
     * @param {Service.sDomain} sDomainService
     * @param apiEndpoints
     * @param sBugsnag
     */
    var sAction = function (sDomainService, apiEndpoints, sBugsnag) {
        var self                = this,
            definitions         = new Model.Action.Definition.Collection(),
            definitionsLoading  = null,
            testDefinitions = function testDefinitions(definitionsArr) {
                try {
                    definitionsArr.validateCombinables();
                } catch (err) {
                    sBugsnag.notify(err);
                }
            }
        ;

        /**
         * @function
         * @name Service.sAction#getDefinitions
         * @returns Promise
         */
        this.getDefinitions = function getDefinitions() {
            if (!definitionsLoading) {
                definitionsLoading = self.loadDefinitions().then(function (data) {
                    definitions = data;
                    testDefinitions(definitions);
                    return definitions;
                });
                return definitionsLoading;
            }

            if (definitionsLoading && definitionsLoading.state() === 'pending') {
                return definitionsLoading;
            }

            return $.Deferred().resolve(definitions).promise();
        };

        /**
         * @function
         * @name Service.sAction#refreshDefinitions
         * @returns Promise
         */
        this.refreshDefinitions = function refreshDefinitions() {
            definitionsLoading = null;
            return self.getDefinitions();
        };

        /**
         * @function
         * @name Service.sAction#loadDefinitions
         * @returns $.Deferred
         */
        this.loadDefinitions = function loadDefinitions() {
            return sDomainService.getCurrentDomain().then(function (domain) {
                var endPoint = apiEndpoints.action.byDomain(domain.id).all();
                return $.ajax({
                    url: endPoint,
                    method: Const.Method.GET
                }).then(function (data) {
                    return Model.Action.Definition.Collection.create(data);
                });
            });
        };
    };
    ns.sAction = sAction;
})(Object.namespace('Service'));