(function(ns) {
    /**
     * @namespace
     * @alias sContextNavigation.Controller.Component.sContextNavigationMenu
     * @param {sContextNavigationMenu.Service.sContextNavigationMenu} sContextNavigationMenu
     */
    var sContextNavigationMenu = function(sContextNavigationMenu) {
        this.sContextNavigationMenu = sContextNavigationMenu;
    };

    ns.sContextNavigationMenu = sContextNavigationMenu;
})(Object.namespace('sContextNavigation.Controller.Component'));