/**
 * Created by Gabor on 24-May-17.
 */


var module = angular.module('sMessageLog', []);

module.config(['$routeProvider', function ($routeProvider) {
    $routeProvider
        .when('/message/member/:hMemberId', new sMessageLog.Route.Member())
        .when('/message/segment/:hChannelId', new sMessageLog.Route.Channel())
    ;
}]);

module.config(['sTitleProvider', function(sTitleProvider) {
    sTitleProvider
        .when(
            '/message/member/:hMemberId',
            'Message Log',
            'Member'
        )
        .when(
            '/message/segment/:hChannelId',
            'Message Log',
            'Segment'
        )
        ;
}]);