(function(ns) {
    /**
     * @namespace
     * @alias sRouteRejection
     * @constructor
     *
     * @param reason
     * @param meta
     */
    var sRouteRejection = function (reason, meta) {
        meta = meta || {};

        Object.defineProperties(this, {
            /**
             * @name sRouteRejection#reason
             */
            reason: {
                get: function () {
                    return reason;
                }
            },
            /**
             * @name sRouteRejection#meta
             */
            meta: {
                get: function () {
                    return meta;
                }
            }
        });
    };

    ns.sRouteRejection = sRouteRejection;
})(Object.namespace(''));