(function (ns) {
    var KEY_IGNORE_ACTION = Const.PostbackMessage;
    /**
     * @namespace
     * @alias Controller.Component.View.sActionsPerformed
     *
     * @constructor
     * @param $scope
     */
    var sActionsPerformed = function ($scope) {
        /**
         * @property
         * @name Controller.Component.View.sActionsPerformed#actions
         * @type {{key: String, value: *}[]}
         */

        this.aggregatedActions  = {};
        this.$scope             = $scope;
        this.$deRegister        = [];
    };

    sActionsPerformed.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    sActionsPerformed.prototype.$onInit = function $onInit() {
        var self = this;
        this.$deRegister.push(this.$scope.$watchCollection(
            function() {
                return self.actions;
            },
            function(val) {
                if (!val) {
                    return;
                }
                self.handleActionsChange();
            }
        ));
    };

    sActionsPerformed.prototype.handleActionsChange = function handleActionsChange() {
        var self = this;
        this.actions.map(function(action, index) {
            if (action.key === KEY_IGNORE_ACTION) {
                self.actions.splice(index, 1);
            }
        });

        if (!this.actions.length) {
            return;
        }

        this.aggregateActions();
    };

    sActionsPerformed.prototype.aggregateActions = function aggregateActions() {
        var actionsByKey = {};
        // clear existing value
        this.actions.map(function(action) {
            actionsByKey[action.key] = actionsByKey[action.key] || [];
            if (action.value instanceof Array) {
                actionsByKey[action.key].push.apply(actionsByKey[action.key], action.value);
                return;
            }

            actionsByKey[action.key].push(action.value);
        });

        this.aggregatedActions = actionsByKey;
    };

    ns.sActionsPerformed = sActionsPerformed;
})(Object.namespace('Controller.Component.View'));