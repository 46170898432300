(function (ns) {
    var permissionQuery = $.Deferred();

    /**
     * @namespace
     * @alias sSettings.Route.RoleManagement
     * @extends sSettings.Route.Base
     * @constructor
     */
    var RoleManagement = function RoleManagement() {
        this.controller     = sSecurity.Controller.RoleAdmin;
        this.templateUrl    = 'ssecurity:admin/role';
        this.controllerAs   = '$ctrl';
        this.accessControl  = {'role.all': Const.Method.GET};

        RoleManagement._pProto.constructor.apply(this, arguments);
    };

    Object.extendProto(RoleManagement, ns.Base);

    /**
     * @function
     * @name sSettings.Route.RoleManagement#resolveUser
     * @param {sSecurity.Service.sAuth} sAuth
     * @returns {$.Deferred}
     */
    RoleManagement.prototype.resolveUser = function resolveUser(sAuth) {
        return sAuth.ready().then(function() {
            return sAuth.user;
        });
    };
    
    /**
     * @method
     * @name sSettings.Route.RoleManagement#resolveRoles
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @returns {$.Deferred}
     */
    RoleManagement.prototype.resolveRoles = function resolveRoles(sAPIAccess) {
        return $.when(permissionQuery, Model.User.Role.query(sAPIAccess.endpoint('role.all').get(), [], true)).then(function(permissions, roles) {
            permissionQuery = $.Deferred();

            roles.map(
                /**
                 * @param {Model.User.Role} role
                 */
                function(role) {
                permissions.map(
                    /**
                     * @param {Model.User.Permission} permission
                     */
                    function(permission) {
                        var perm;
                        if ((perm = role.getPermissionByUuid(permission.uuid))) {
                            role.removePermission(perm);
                            role.addPermission(permission);
                        }
                });
            });
            return roles;
        });
    };

    /**
     * @function
     * @name sSettings.Route.RoleManagement#resolveFeatures
     * @returns {PromiseLike}
     */
    RoleManagement.prototype.resolveFeatures = function resolveFeatures() {
        return Model.User.Permission.query().then(function(permissions){
            permissionQuery.resolve(permissions);
            return permissions.reduce(function(carry, permission) {
                if (carry.indexOf(permission.feature) === -1) {
                    carry.push(permission.feature);
                    permission.feature.sortPermissions();
                }
                return carry;
            }, [])
        });
    };

    ns.RoleManagement = RoleManagement;
})(Object.namespace('sSettings.Route'));