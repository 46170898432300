(function (ns) {
    /**
     * @namespace
     * @alias Controller.Component.sMarkersList
     *
     * @param $element
     * @param $scope
     * @param $location
     * @param {Service.sMarkerRepository} sMarkerRepository
     */
    var sMarkersList = function ($element, $scope, $location, sMarkerRepository) {
        this.$element       = $element;
        this.$scope         = $scope;
        this.$location      = $location;
        this.$deRegister    = [];
        this.markers        = null;
        this.loading        = true;
        this.showAggregated = false;

        var markerRepositoryItems = [],
            aggregationToggled    = false
        ;

        this.handleAggregationChange = function () {
            aggregationToggled = true;
            this.setUpMarkers();
        };

        /**
         * @type Model.Message.Collection
         * @name Controller.Component.sMarkersList#model
         */

        /**
         * @type Boolean
         * @name Controller.Component.sMarkersList#loading
         */

        /**
         * @type Object
         * @name Controller.Component.sMarkersList#markers
         */

        this.loadMarkers = function () {
            var self = this;

            sMarkerRepository.getItems().then(
                function (markers) {
                    markerRepositoryItems = markers;
                    self.setUpMarkers();
                },
                function () {
                    self.markers = null;
                }
            ).always(function () {
                self.loading = false;
                digestIfNeeded(self.$scope);
            });
        };

        /**
         * @function
         * @name Controller.Component.sMarkersList#initMarker
         * @param {String} markerUuid
         * @return {Object}
         */
        this.initMarker = function (markerUuid) {
            var selectedMarker = this.getMarkerByUuid(markerUuid);
            return {
                'name'     : selectedMarker ? selectedMarker.name : Model.Marker.MARKER_GOAL_NAME,
                'messages' : []
            };
        };

        /**
         * @param {String} uuid
         * @return {?Model.Marker}
         */
        this.getMarkerByUuid = function getMarkerByUuid(uuid) {
            return markerRepositoryItems.find(function (item) {
                return item.uuid === uuid;
            });
        };

        /**
         * @property
         * @name Controller.Component.sMarkersList#showAggregated
         * @type {Boolean}
         */

        Object.defineProperties(
            this,
            {
                aggregationToggled: {
                    enumerable: true,
                    get: function () {
                        return aggregationToggled;
                    }
                    /**
                     * @property
                     * @name Controller.Component.sMarkersList#aggregationToggled
                     * @type {Boolean}
                     */
                }

            });
    };

    sMarkersList.prototype.$onInit = function $onInit() {
        this.loadMarkers();

        var self = this;

        this.$deRegister.push(this.$scope.$root.$on(Model.Marker.EVENT_MARKER_MODEL_CHANGE, function () {
            self.loadMarkers();
        }));

        this.$deRegister.push(this.$scope.$root.$on(Model.Message.EVENT_MESSAGE_NAME_BLUR, function () {
            self.loadMarkers();
        }));
    };

    /**
     * @function
     * @name Controller.Component.sMarkersList#$onDestroy
     */
    sMarkersList.prototype.$onDestroy = function () {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name Controller.Component.sMarkersList#$onChanges
     * @param {Object} $changes
     * */
    sMarkersList.prototype.$onChanges = function ($changes) {
        if ($changes.showAnalytics) {
            if ($changes.showAnalytics.currentValue && !$changes.showAnalytics.previousValue && !this.showAggregated) {
                this.showAggregated = true;
            }
            if ($changes.showAnalytics.previousValue && !$changes.showAnalytics.currentValue && this.showAggregated) {
                this.showAggregated = false;
            }
        }

        this.setUpMarkers();
    };

    sMarkersList.prototype.setUpMarkers = function setUpMarkers() {
        var messages    = this.model.items,
            result      = {},
            self        = this,
            markerUuid
        ;

        if (this.showAggregated) {
            var aggregatedStats = this.model.getRootMessage().getMeta(Model.Message.KEY_ANALYTICS_META, [], Model.Message.KEY_AGGREGATED_STATS),
                defaultMarker   = new Model.Marker()
            ;

            defaultMarker.name = 'Removed goal';

            result = aggregatedStats.reduce(
                /**
                 * @param {Object} carry
                 * @param {{
                 *  goalUuid: String,
                 *  reached: Number,
                 *  conversations: {
                 *      uuid: String,
                 *      reached: Number,
                 *      delivered: Number,
                 *      breakdown :
                 *      {
                 *       uuid    : String
                 *       type    : String (message|cta),
                 *       reached : Number
                 *      }[]
                 *   }[]
                 * }} goalEntry
                 */
                function (carry, goalEntry) {
                    var conversation = goalEntry.conversations.slice(0,1).pop();
                    if (conversation.uuid !== self.model.getRootMessage().uuid) {
                        return carry;
                    }

                    var messages = conversation.breakdown.reduce(
                        /**
                         * @param {Array} breakDownCarry
                         * @param {{
                         *       uuid    : String
                         *       type    : String (message|cta),
                         *       reached : Number
                         *      }} element
                         *
                         * @return {*}
                         */
                        function (breakDownCarry, element) {
                            var recipient;
                            if (element.type === 'message') {
                                recipient = self.model.getMessageByUuid(element.uuid);
                            }
                            if (element.type === 'cta') {
                                var hostMessage = self.model.getMessageByCTAUuid(element.uuid),
                                    relation
                                ;

                                if (hostMessage) {
                                    relation = hostMessage.getRelationByOption(hostMessage.getCTAByUuid(element.uuid));
                                    if (relation && relation.to) {
                                        recipient = relation.to;
                                    }
                                }
                            }

                            if (recipient && recipient.getMeta(Model.Message.KEY_MARKER_UUID) !== goalEntry.goalUuid) {
                                // reject recipient if it does not have the association in the current version
                                recipient = null;
                            }

                            if (recipient) {
                                breakDownCarry.push({
                                    targetUuid         : element.uuid,
                                    messageDescription : self.getMessageDescription(recipient),
                                    isIncoming         : recipient.isIncoming,
                                    counter            : self.getMessageCounter(recipient),
                                    analytics          : self.formatAnalytics({
                                        read        : element.reached,
                                        readOfTotal : conversation.delivered ? (element.reached / conversation.delivered * 100) : 0
                                    })
                                });
                            } else {
                                // recipient is not found within the convo > aggregate as "in other version(s)"
                                var firstElement = breakDownCarry.slice(0,1).shift();
                                if (!firstElement || !firstElement.isDefault) {
                                    firstElement = {
                                        isDefault          : true,
                                        targetUuid         : null,
                                        messageDescription : {description: 'Only other version(s)', tooltip: 'In other version(s) this goal was set on message(s) that are currently not present in this version or was removed from the messages'},
                                        analytics          : {
                                            read        : 0,
                                            readOfTotal : 0
                                        }
                                    };

                                    breakDownCarry.unshift(firstElement);
                                }

                                var read = firstElement.analytics.read + element.reached;
                                firstElement.analytics = self.formatAnalytics({
                                    read        : read,
                                    readOfTotal : conversation.delivered ? (read / conversation.delivered * 100) : 0
                                });
                            }

                            return breakDownCarry;
                        },
                        []
                    );

                    var marker = self.getMarkerByUuid(goalEntry.goalUuid);
                    if (!marker) {
                        marker = defaultMarker;
                    }

                    if (!carry[marker.uuid]) {
                        carry[marker.uuid] = {messages: messages, name: (marker.name || Model.Marker.MARKER_DEFAULT_NAME) };
                        return carry;
                    }

                    carry[marker.uuid].messages.map(function (message) {
                       messages.map(function(newMessage) {
                            if (newMessage.uuid === message.uuid) {
                                var read = message.analytics.read + parseInt(newMessage.analytics.read);
                                message.analytics          = self.formatAnalytics({
                                    read        : read,
                                    readOfTotal : conversation.delivered ? (read / conversation.delivered * 100) : 0
                                })
                            }
                        })
                    });

                    return carry;
                },
                {}
            );
        } else {
            for (var i = 0; i < messages.length; i++) {
                if (!messages[i].getMeta(Model.Message.KEY_MARKER_UUID)) {
                    continue;
                }

                markerUuid = messages[i].getMeta(Model.Message.KEY_MARKER_UUID);

                if (!result[markerUuid]) {
                    result[markerUuid] = this.initMarker(markerUuid);
                }

                result[markerUuid].messages.push({
                    targetUuid         : messages[i].uuid,
                    messageDescription : this.getMessageDescription(messages[i]),
                    isIncoming         : messages[i].isIncoming,
                    counter            : this.getMessageCounter(messages[i]),
                    analytics          : this.getMessageAnalytics(messages[i])
                });
            }
        }

        this.sortMessages(result);

        this.markers = (Object.keys(result).length === 0 ? null : result);
        this.setUpMarkersAnalytics();
    };

    sMarkersList.prototype.setUpMarkersAnalytics = function setUpMarkersAnalytics() {
        if (!this.showAnalytics) {
            return;
        }

        for (var makerUuid in this.markers) {
            var analytics = {
                read        : 0,
                readOfTotal : 0
            };

            this.markers[makerUuid].messages.reduce(function (carry, message) {
                analytics.read += message.analytics.read ? message.analytics.read : 0;
                analytics.readOfTotal += message.analytics.readOfTotal ? message.analytics.readOfTotal : 0;
            }, analytics);

            this.markers[makerUuid].analytics = this.formatAnalytics(analytics);
        }
    };

    /**
     * @function
     * @name Controller.Component.sMarkersList#getMessageDescription
     * @param {Model.Message} message
     * @return {Object}
     */
    sMarkersList.prototype.getMessageDescription = function getMessageDescription(message) {
        var description = {description : message.getMessageDescription()};

        if (message.isIncoming) {
            var relationsTo = message.getRelationsTo();

            if (relationsTo.length && relationsTo[0].from.getMessageDescription()) {
                description.prefix = relationsTo[0].from.getMessageDescription();
            }

            if ([Model.Message.Part.TYPE_AI_REACTION, Model.Message.Part.AI_TEMPLATE].indexOf(message.firstPart().type) === -1) {
                description.tooltip = message.firstPart().content.content.body;
            }
        }

        return description;
    };

    /**
     * @function
     * @name Controller.Component.sMarkersList#getMessageCounter
     * @param {Model.Message} message
     * @return {Number}
     */
    sMarkersList.prototype.getMessageCounter = function getMessageCounter(message) {
        if (message.needsCounter()) {
            return parseInt(message.counter);
        }

        var relationsTo = message.getRelationsTo();

        if (relationsTo.length && relationsTo[0].from.needsCounter()) {
            return parseInt(relationsTo[0].from.counter);
        }

        return 0;
    };

    /**
     * @function
     * @name Controller.Component.sMarkersList#sortMessages
     * @param {Object} markers
     */
    sMarkersList.prototype.sortMessages = function sortMessages(markers) {
        var sortFunction = function (a, b) {
            if (!b.counter && a.counter) {
                return -1;
            }

            if (a.counter < b.counter) {
                return -1;
            }
            if (b.counter > a.counter) {
                return 1;
            }
            return 0;
        };

        for (var markerUuid in markers) {
            if (markers.hasOwnProperty(markerUuid)) {
                markers[markerUuid].messages = markers[markerUuid].messages.sort(sortFunction);
            }
        }
    };

    /**
     * @function
     * @name Controller.Component.sMarkersList#getMessageAnalytics
     * @param {Model.Message} message
     * @return {Object}
     */
    sMarkersList.prototype.getMessageAnalytics = function getMessageAnalytics(message) {
        var analytics = message.getMeta(Model.Message.KEY_ANALYTICS_META, {});

        return this.formatAnalytics(analytics);
    };

    /**
     * @function
     * @name Controller.Component.sMarkersList#focusMessage
     * @param {String} messageUuid
     */
    sMarkersList.prototype.focusMessage = function focusMessage(messageUuid) {
        this.$location.hash('message-goal-' + messageUuid);
    };

    /**
     * @function
     * @name Controller.Component.sMarkersList#formatPercent
     * @param {Object} analytics
     * @return {Object}
     */
    sMarkersList.prototype.formatAnalytics = function formatAnalytics(analytics) {
        analytics.read        = analytics.read || 0;
        analytics.readOfTotal = analytics.readOfTotal ? +(analytics.readOfTotal.toFixed(1)) : 0;

        return analytics;
    };

    ns.sMarkersList = sMarkersList;

})(Object.namespace('Controller.Component'));
