(function (ns) {
    /**
     * @namespace
     * @alias sMessageLog.Route.Channel
     * @extends sRoute
     * @constructor
     */
    var Channel = function Channel() {
        this.controller     = Controller.sMessageLog.Channel;
        this.controllerAs   = '$ctrl';
        this.templateUrl    = 'smessagelog:channel';
        this.accessControl  = {'messageLog.bySegment': Const.Method.GET};

        Channel._pProto.constructor.call(this);
    };

    Object.extendProto(Channel, sRoute);

    /**
     * Used by the sRoute's magic resolve method
     * @function
     * @name sMessageLog.Route.Channel#resolveSent
     * @param $route
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {Service.sAction} sActionService
     * @return {PromiseLike}
     */
    Channel.prototype.resolveSent = function ($route, sAPIAccess, sActionService) {
        return sActionService.getDefinitions().then(function() {
            var collection = new Model.Message.FetchAbleCollection(
                sAPIAccess.endpoint('messageLog.bySegment').get($route.current.params.hChannelId),
                true
            );

            return collection.fetchNext().then(function() {
                return collection;
            });
        });
    };

    /**
     * Used by the sRoute's magic resolve method
     * @function
     * @name sMessageLog.Route.Channel#resolveQueued
     * @param $route
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {Service.sAction} sActionService
     * @return {PromiseLike}
     */
    Channel.prototype.resolveQueued = function ($route, sAPIAccess, sActionService) {
        return sActionService.getDefinitions().then(function() {
            var collection = new Model.Message.FetchAbleCollection(
                sAPIAccess.endpoint('messageSchedule.bySegment').get($route.current.params.hChannelId)
            );

            return collection.fetchNext().then(
                function() {
                    return collection;
                },
                function(jqXhr) {
                    if (jqXhr.status === 403) {
                        // save from failing if forbidden
                        return $.Deferred().resolve(new Model.Message.Collection());
                    }

                }
            );
        });
    };

    /**
     * Used by the sRoute's magic resolve method
     * @function
     * @name sMessageLog.Route.Channel#resolveHChannelId
     * @param $route
     * @return {PromiseLike}
     */
    Channel.prototype.resolveHChannelId = function ($route) {
        return $.when($route.current.params.hChannelId);
    };

    ns.Channel = Channel;
})(Object.namespace('sMessageLog.Route'));
