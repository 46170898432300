(function () {
    if (typeof String.prototype.bin2hex === 'undefined') {
        String.prototype.bin2hex = function bin2hex() {
            var i,
                s = this.toString(),
                a = [],
                enc = encodeURIComponent(s);

            for(i = 0; i < enc.length;) {
                if(enc[i] === '%') {
                    a.push(parseInt(enc.substr(i+1, 2), 16));
                    i += 3
                } else {
                    a.push(enc.charCodeAt(i++))
                }
            }


            return a.map(function(el) {
                return el.toString(16);
            }).join('');
        };

        Object.defineProperty(
            String.prototype,
            'bin2hex',
            {
                enumerable: false
            }
        );
    }

    if (typeof String.prototype.ucFirst === 'undefined') {
        String.prototype.ucFirst = function ucFirst() {
            return this.substr(0, 1).toUpperCase() + this.substr(1);
        };

        Object.defineProperty(
            String.prototype,
            'ucFirst',
            {
                enumerable: false
            }
        );
    }

    if (typeof String.prototype.trim === 'undefined') {
        String.prototype.trim = function trim() {
            return this.replace(/^[\s]+|[\s]+$/g, '');
        };

        Object.defineProperty(
            String.prototype,
            'trim',
            {
                enumerable: false
            }
        );
    }

    if (typeof String.prototype.lpad === 'undefined') {
        /**
         * Left pads a number to the specified width with the given characters
         * @param {Number} number
         * @param {String} value
         * @returns {string}
         */
        String.prototype.lpad = function lpad(number, value) {
            value = value || '0';
            var pad = new Array(number + 1).join(value);

            return this.replace(/^.*$/, pad.substring(0, number - this.length) + this);
        };

        Object.defineProperty(
            String.prototype,
            'lpad',
            {
                enumerable: false
            }
        );
    }

    if (typeof String.prototype.toUnicode === 'undefined') {
        /**
         * @see https://stackoverflow.com/questions/21014476/javascript-convert-unicode-string-to-javascript-escape/21014576#21014576
         * @returns {string}
         */
        String.prototype.toUnicode = function toUnicode(){
            var result = "";
            for(var i = 0; i < this.length; i++){
                // Assumption: all characters are < 0xffff
                result += "\\u" + ("000" + this[i].charCodeAt(0).toString(16)).substr(-4);
            }
            return result;
        };

        Object.defineProperty(
            String.prototype,
            'toUnicode',
            {
                enumerable: false
            }
        );
    }
})();
