(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sTags
     * @constructor
     *
     * @param $scope
     */
    var sTags = function ($scope) {
        this.$scope         = $scope;
        this.$deRegister    = [];

        /**
         * @property
         * @name Controller.Component.sTags#autocompleteSource
         * @type {String}
         */
    };

    /**
     * @function
     * @name Controller.Component.sTags#$onInit
     */
    sTags.prototype.$onInit = function $onInit() {
        var self = this;
        this.model = this.model || [];

        this.$deRegister.push(this.$scope.$watchCollection(
            function() {
                return self.model;
            },
            function(newModel) {
                if (!newModel instanceof Array) {
                    self.model = [];
                }
            }
        ));
    };

    /**
     * @function
     * @name Controller.Component.sTags#$onDestroy
     */
    sTags.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name Controller.Component.sTags#transformChip
     * @param {string} chip
     * @returns null|string
     */
    sTags.prototype.transformChip = function transformChip(chip) {
        for (var i = 0; i < this.model.length; i++) {
            if (chip.toLowerCase() === this.model[i].toLowerCase()) return null;
        }
        return chip;
    };

    /**
     * @function
     * @name Controller.Component.sTags#getAutocompleteItems
     * @param {string} searchString
     * @return {$.Deferred|Promise|PromiseLike}
     */
    sTags.prototype.getAutocompleteItems = function getAutocompleteItems(searchString) {
        if (!this.autocompleteSource) {
            return $.Deferred().resolve([]).promise();
        }

        return $.ajax({
            url     : this.autocompleteSource,
            data    : {searchString: searchString},
            dataType: 'json',
            method  : Const.Method.GET
        }).then(function (items) {
            return items.sortLocaleCompare();
        });
    };

    ns.sTags = sTags;
})(Object.namespace('Controller.Component'));
