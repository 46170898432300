(function(ns) {

        /**
         * @type {Model.Endpoint.Collection}
         */
    var sharedEndpointList,
        /**
         *
         * @type {string}
         */
        EVENT_ENDPOINTS_LOADED = 'sEventEndpointsLoaded'
    ;

    /**
     * @namespace
     * @alias sAPIAccess.Service.sAPIAccess
     * @constructor
     *
     * @param {Object.<string, Object.<string, string>>} endpointMapping - The first string is the token we use for mapping, second is the method-string and third is the mapped route-name
     * @param {Object.<string, string>} fixedEndpoints
     * @param $rootScope
     * @param $route
     */
    var sAPIAccess = function (endpointMapping, fixedEndpoints, $rootScope, $route) {
        var loading,
            self = this
            ;
        /**
         * @property
         * @type Model.Endpoint.Collection
         * @name sAPIAccess.Service.sAPIAccess#endpoints
         */

        /**
         * @property
         * @type Boolean
         * @name sAPIAccess.Service.sAPIAccess#isLoading
         */

        Object.defineProperties(
            this,
            {
                'endpoints': {
                    enumerable: true,
                    get: function () {
                        return sharedEndpointList;
                    }
                    /**
                     * @property
                     * @name sAPIAccess.Service.sAPIAccess#endpoints
                     * @type {Model.Endpoint.Collection}
                     */
                },
                'isLoading' : {
                    get: function() {
                        return Boolean(loading);
                    }
                }
            });

        /**
         * @function
         * @name sAPIAccess.Service.sAPIAccess#loadEndpoints
         * @returns {$.Deferred}
         */
        this.loadEndpoints = function loadEndpoints() {
            if (loading) {
                return loading;
            }
            loading = $.ajax({
                url: this.endpoint('api.list').get()
            }).then(function (allowedEndpoints, status, jqXHR) {
                var endpoints = $.extend(fixedEndpoints, allowedEndpoints);
                sharedEndpointList.update(endpoints);
                $rootScope.$broadcast(EVENT_ENDPOINTS_LOADED, parseInt(jqXHR.getResponseHeader('x-access-transient')));
            }).always(function() {
                loading = false;
            });

            return loading;
        };

        /**
         * @function
         * @name sAPIAccess.Service.sAPIAccess#init
         */
        this.init = function init() {
            sharedEndpointList.update(fixedEndpoints);
        };

        if (!(sharedEndpointList instanceof Model.Endpoint.Collection)) {
            sharedEndpointList = new Model.Endpoint.Collection(endpointMapping, sAPIAccess.endpointPrefix);
        }

        $rootScope.$on(Service.sDomain.EVENT_DOMAIN_SET, function() {
            self.loadEndpoints();
        });

        /**
         * Block the route change as long as the endpoints are not loaded
         */
        $rootScope.$on('$routeChangeStart', function(event) {
            if (loading) {
                event.preventDefault();
                loading.then(function() {
                    $route.reload();
                });
            }
        });

        $(document).ajaxSuccess(function (event, xhr) {
            if (loading) {
                return;
            }

            var limitHeader = xhr.getResponseHeader('x-resource-limit');

            if (!limitHeader) {
                return;
            }

            try {
                limitHeader = JSON.parse(limitHeader);
            } catch (e) {
                return;
            }

            sharedEndpointList.updateLimitedEndpoints(
                limitHeader[Model.Endpoint.KEY_LIMIT_QUERY],
                parseInt(limitHeader[Model.Endpoint.KEY_RESOURCE_LIMIT]),
                parseInt(limitHeader[Model.Endpoint.KEY_RESOURCE_USED])
            );

            digestIfNeeded($rootScope);
        });
    };

    /**
     * Shortcut function for getting one endpoint of the collection
     *
     * @function
     * @name sAPIAccess.Service.sAPIAccess#endpoint
     * @param {string} name
     * @returns {?Model.Endpoint}
     */
    sAPIAccess.prototype.endpoint = function endpoint(name) {
        return this.endpoints.endpoint(name);
    };

    /**
     * @function
     * @name sAPIAccess.Service.sAPIAccess#isAllowed
     * @param {string} name
     * @param {string} method
     * @returns {boolean}
     */
    sAPIAccess.prototype.isAllowed = function isAllowed(name, method) {
        var endpoint = this.endpoint(name);
        return endpoint && endpoint.hasPath(method);
    };

    /**
     * @function
     * @name sAPIAccess.Service.sAPIAccess#check
     * @param {*} val
     * @returns {boolean}
     */
    sAPIAccess.prototype.check = function check(val) {
        var methods;

        if (typeof val === 'string' || val instanceof String) {
            return this.isAllowed(val, Const.Method.GET);
        }

        if ($.isEmptyObject(val)) {
            return false;
        }

        for (var name in val) {
            if (!val.hasOwnProperty(name)) {
                return false;
            }
            methods = val[name];
            if (!(val[name] instanceof Array)) {
                methods = [val[name]];
            }
            for (var i = 0; i < methods.length; i++) {
                if (!this.isAllowed(name, methods[i])) {
                    return false;
                }
            }
        }
        return true;
    };

    sAPIAccess.endpointPrefix = '';
    sAPIAccess.imagePath    = '';

    sAPIAccess.EVENT_ENDPOINTS_LOADED = EVENT_ENDPOINTS_LOADED;

    ns.sAPIAccess = sAPIAccess;
})(Object.namespace('sAPIAccess.Service'));
