(function (ns) {

    /**
     * This class acts more or less as a trait
     *
     * @namespace
     * @alias Model.Behavior.KeyValue
     *
     * @constructor
     */
    var KeyValue = function () {
        if (this.constructor === KeyValue) {
            throw 'Behavior should not be instantiated!';
        }

        Object.defineProperties(
            this,
            {
                key: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @name Model.Behavior.KeyValue#key
                     * @type {String}
                     */
                },
                value: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @name Model.Behavior.KeyValue#value
                     * @type {String}
                     */
                }
            });
    };


    ns.KeyValue = KeyValue;
})(Object.namespace('Model.Behavior'));