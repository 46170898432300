(function(ns) {
    /**
     * @namespace
     * @alias Model.Exception.FileTooLarge
     * @param {Blob} file
     * @constructor
     * @extends Error
     */
    var FileTooLarge = function(file) {

        this.name           = 'FileTooLargeError';
        this.message        = 'File size exceeds allowed maximum';
        this.file           = file;
    };

    Object.extendProto(FileTooLarge, Error);

    ns.FileTooLarge = FileTooLarge;

})(Object.namespace('Model.Exception'));