(function (ns) {
    /**
     * @namespace
     * @memberOf Model.Member
     * @constructor
     */
    var Attribute = function Attribute() {
        var key,
            values = []
        ;

        Object.defineProperties(
            this,
            {
                key    : {
                    enumerable : true,
                    get        : function () {
                        return key;
                    },
                    set        : function (val) {
                        key = val;
                    }
                    /**
                     * @property
                     * @type String
                     * @name Model.Member.Attribute#key
                     */
                },
                values : {
                    enumerable : true,
                    get        : function () {
                        return values;
                    },
                    set        : function (val) {
                        values = val;
                    }
                    /**
                     * @property
                     * @type Array
                     * @name Model.Member.Attribute#values
                     */
                }
            }
        );
    };

    /**
     * @function
     * @name Model.Member.Attribute#updateByData
     * @param {Object} data
     */
    Attribute.prototype.updateByData = function updateByData(data) {
        Object.updateByData(this, data);
    };

    /**
     * @function
     * @name Model.Member.Attribute.createByData
     * @param {Object} data
     *
     * @returns {Model.Member.Attribute}
     */
    Attribute.createByData = function createByData(data) {
        var attribute = new Attribute();
        attribute.updateByData(data);

        return attribute;
    };

    /**
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     *
     * @return {Promise}
     */
    Attribute.prototype.loadValues = function loadValues(sAPIAccess) {
        var self = this;

        return $.ajax({
            url         : sAPIAccess.endpoint('member.attribute.values').get(this.key),
            method      : Const.Method.GET,
            context     : self,
            processData : false,
            success     : function (data) {
                self.values = data;
            },
            error       : function () {
                self.values = [];
            }
        });
    };

    ns.Attribute = Attribute;
})(Object.namespace('Model.Member'));
