(function (ns) {
    var KEY_MAX_UNGROUPED       = 9,
        KEY_LEGEND_ONLY         = 'legendonly',
        KEY_GROUPED_TRACE       = 'isGroupedTrace',
        KEY_GROUPED_TRACE_NAME  = 'Others',
        KEY_PLOTLY_TYPE_SCATTER = 'scatter',
        KEY_LINE                = 'line',
        KEY_MARKER              = 'marker',
        KEY_BAR                 = 'bar'
    ;

    tinycolor.defaults.background   = '#fff';
    tinycolor.defaults.defaultLine  = '#444';

    /**
     * @namespace
     * @alias sAnalytics.Component.Controller.sPlot
     *
     * @constructor
     * @param $element
     * @param $scope
     * @param {Service.sProgressIndicator} sProgressIndicator
     * @param {sAnalytics.Service.sTrace} sTrace
     */
    var sPlot = function ($element, $scope, sProgressIndicator, sTrace) {
        this.element            = $element;
        this.$deRegister        = [];
        this.$scope             = $scope;
        this.sProgressIndicator = sProgressIndicator;
        this.sTrace             = sTrace;
        this.inited             = false;
        this.noDataElement      = null;

        /**
         * @property
         * @name sAnalytics.Component.Controller.sPlot#model
         * @type {Model.Analytics.StatisticsBag}
         */

        /**
         * @property
         * @name sAnalytics.Component.Controller.sPlot#traceFactory
         * @type {String}
         */

        /**
         * @property
         * @name sAnalytics.Component.Controller.sPlot#variant
         * @type {String}
         */

        /**
         * `|` separated strings of templates to use
         * @property
         * @name sAnalytics.Component.Controller.sPlot#template
         * @type {String}
         */

        /**
         * @property
         * @name sAnalytics.Component.Controller.sPlot#plotting
         * @type {$.Deferred|Promise}
         */
    };
    
    sPlot.prototype.$onInit = function $onInit() {
        var self        = this,
            timeout     ,
            timedUpdate = function() {
                if (timeout) {
                    clearTimeout(timeout);
                }

                timeout = setTimeout(self.loadWithIndication.bind(self), 50);
            }
        ;

        this.$deRegister.push(
            this.$scope.$watch(
                function() {
                    return JSON.stringify(self.model.filters);
                },
                /**
                 * @param {String} val
                 * @param {String} oldVal
                 */
                function(val, oldVal) {
                    if (oldVal === val) {
                        return;
                    }
                    timedUpdate();
                }
            )
        );

        this.$deRegister.push(
            this.$scope.$watch(
                function() {
                    return self.model.loading;
                },
                function(val) {
                    if (val && !self.indicator) {
                        self.loadWithIndication();
                    }
                }
            )
        );

        this.$deRegister = this.$deRegister.concat(
            // handle stacked scatter + group the rest of the data
            this.element.$on('plotly_beforeplot',
                /**
                 * @param event
                 * @param {Model.Analytics.Trace2d[]} data
                 * @param {Object} layout
                 * @returns {boolean}
                 */
            function (event, data, layout) {
                if (!data) {
                    return true;
                }

                // extract the string after `type:` controls trace types eg type:{traceName: 'bar'}
                if (self.template && self.template.search(/type\:((?:\[.*?\])|(?:\{.*?\}))/) !== -1) {

                    values = JSON.parse(RegExp.$1);

                    data.map(
                        /**
                         * @param {Model.Analytics.Trace2d} trace
                         * @param index
                         */
                        function(trace, index) {
                            if (values instanceof Array && values[index]) {
                                trace.type = values[index];
                            } else if (values.hasOwnProperty(trace.name)) {
                                trace.type = values[trace.name];
                            }
                        });
                }

                if (self.template && self.template.search(/type\:\"(.*?)\"/) !== -1) {
                    data.map(
                        /**
                         * @param {Model.Analytics.Trace2d} trace
                         * @param index
                         */
                        function(trace, index) {
                            trace.type = RegExp.$1;
                        });
                }

                if (self.template && self.template.search(/show\:(\[.*?\])/) === -1) {
                    // don't apply heavy logic if later hidden by show
                    sPlot.groupDataIfNeeded(data);
                }

                sPlot.fixFillForNoData(data);

                if (sPlot.isStackedScatter(layout)) {
                    sPlot.sumUpTraces(
                        data,
                        data.reduce(function(carry, trace, index) {
                            if (trace.type === KEY_PLOTLY_TYPE_SCATTER) {
                                carry.push(index);
                            }
                            return carry;
                        }, [])
                    );
                }

                var values;


                // extract the string after `order:` controls ordering of the traces
                if (self.template && self.template.search(/order\:(\[.*?\])/) !== -1) {
                    values = JSON.parse(RegExp.$1);

                    data.sort(
                        function(traceA, traceB) {
                            var indA = values.indexOf(traceA.name),
                                indB = values.indexOf(traceB.name)
                            ;

                            if (indA !== indB) {
                                if (indA === -1) {
                                    return 1;
                                } else {
                                    return -1;
                                }
                            }

                            return indA - indB;
                        });
                }

                // extract the string after `order:` controls ordering of the traces
                if (self.template && self.template.search(/order\:("desc"|"asc")/i) !== -1) {
                    var flag = JSON.parse(RegExp.$1).toLowerCase();

                    sPlot.sortByValues(data, flag === 'asc');
                }

                // extract the string after `show:` if it contains [something] eg (show:[0,1] > show 1st and 2nd trace only)
                if (self.template && self.template.search(/show\:(\[.*?\])/) !== -1) {
                    values = JSON.parse(RegExp.$1);

                    data.map(
                        function(trace, index) {
                            if (values.indexOf(index) === -1) {
                                trace.setMeta('visible', KEY_LEGEND_ONLY);
                            }
                        });
                }

                self.alignXAxis(data, layout);
                self.alignYAxis(data, layout);
            })
        );

        this.$deRegister = this.$deRegister.concat(
            this.element.$on('plotly_legendclick',
            /**
             *
             * @param event
             * @param {Object} payload
             */
            function (event, payload) {
                var data    = payload.data,
                    layout  = payload.layout
                ;

                data[payload.curveNumber].visible = (data[payload.curveNumber].visible === true || data[payload.curveNumber].visible === undefined)
                    ? KEY_LEGEND_ONLY
                    : true;

                sPlot.groupDataIfNeeded(data);

                if (sPlot.isStackedScatter(layout)) {
                    var visibleIndexes = payload.fullData
                        .filter(function (traceData) {
                            return (traceData.visible === true) === (traceData.index !== payload.curveNumber);
                        })
                        .map(function (traceData) {
                            return traceData.index;
                        })
                    ;

                    sPlot.sumUpTraces(data, visibleIndexes);
                }
            })
        );

        this.$deRegister = this.$deRegister.concat(
            this.element.$on(
                'plotly_hover',
                function(){
                    sPlot.customHoverFn(self.element[0], self.element[0]._hoverdata);
                })
            );

        this.inited = true;

        if (!this.model) {
            return;
        }

        if (this.model.entries.length) {
            this.plot();
        } else if(this.model.loading) {
            this.model.loading.then(function() {self.plot()});
        }
    };

    /**
     * @returns {$.Deferred|Promise}
     */
    sPlot.prototype.plot = function plot() {
        var self = this,
            $deferred = $.Deferred()
        ;

        if (this.plotting) {
            return this.plotting;
        }

        this.plotting = $deferred;

        var config = {
            scrollZoom          : false, // default
            responsive          : true,
            doubleClick         : false,
            showTips            : false,
            showAxisDragHandles : false,
            displayModeBar      : false
        };

        this.plotting.always(function() {
            self.plotting = null;
        });

        var traces = this.sTrace.executeTraceFactory(
            this.traceFactory,
            this.model.entries,
            this.model.period,
            this.variant
        );

        if (this.noDataElement) {
            this.noDataElement.remove();
            this.noDataElement = null;
        }

        /**
         * Queue plotting but only perform before the next paint occurs, to have the element in the DOM working already
         */
        requestAnimationFrame(function() {
            var trClones = traces.clone(),
                extrema = sPlot.getYExtremaByData(trClones)
            ;

            Plotly.newPlot(
                self.element[0],
                trClones,
                {
                    template: self.sTrace.getPlotTemplate(self.template)
                },
                config
            )
            .then(
                function(chartElement) {
                    sPlot.addEventDataFn(chartElement);

                    // check if we need to display no-data text
                    if (extrema.min === null && extrema.max === null) {
                        self.noDataElement = sPlot.createNoDataElement();
                        $(chartElement).append(self.noDataElement);
                    }
                }
            )
            .then(
                function() {
                    self.plotting.resolve();
                },
                function() {
                    self.plotting.resolve();
                })
            ;
        });

        return this.plotting;
    };

    sPlot.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }

        Plotly.purge(this.element[0]);
    };

    sPlot.prototype.loadWithIndication = function loadWithIndication() {
        var self = this;

        this.indicator = this.sProgressIndicator.attachAndHandlePromise(
            this.element,
            this.model.load().then(function() {
                self.plot();
            }).always(function() {
                digestIfNeeded(self.$scope);
            }),
            150,
            45000
        ).always(function() {
            self.indicator = null;
        });
    };

    /**
     * @param {{model: SimpleChange}, {trace: SimpleChange}, {template: SimpleChange}} changes
     */
    sPlot.prototype.$onChanges = function $onChanges(changes) {
        if (!this.inited) {
            return;
        }

        if (changes.model) {
            this.loadWithIndication();
            return;
        }

        if (this.model && this.model.entries.length) {
            this.plot();
        }
    };

    /**
     * @function
     * @name sAnalytics.Component.Controller.sPlot#alignXAxis
     * @param {Model.Analytics.Trace2d[]} data
     * @param {Object} layout
     * @returns void
     */
    sPlot.prototype.alignXAxis = function alignXAxis(data, layout) {
        if (!sPlot.isXAxisTimeRange(layout) || !this.model.dateRange) {
            return;
        }

        // get the maximum length of values for x-axis
        var xValuesLength = data.reduce(
            function (length, trace) {
                return Math.max(trace.x.length, length);
            },
            0
        );

        var xAxis = {};

        if (xValuesLength === 1) {
            xAxis.range = [this.model.dateRange.from.startOf('day').format(Model.Analytics.Entry.FORMAT_PLOTLY_TIME)];
        }
        else if (xValuesLength === 0 || sPlot.dataContainsScatter(data)) {
            xAxis.range = [
                this.model.dateRange.from.startOf('day').format(Model.Analytics.Entry.FORMAT_PLOTLY_TIME),
                this.model.dateRange.to.endOf('day').format(Model.Analytics.Entry.FORMAT_PLOTLY_TIME)
            ];
        }

        if (xValuesLength === 1
            || (layout.template
                && layout.template.layout
                && layout.template.layout.xaxis
                && layout.template.layout.xaxis.nticks
                && layout.template.layout.xaxis.nticks > xValuesLength)
        ) {
            xAxis.nticks = xValuesLength;

            if (xValuesLength <= 2) {
                xAxis.tickmode = 'linear';
            }
        }

        $.extend(true, layout, {
            xaxis: xAxis
        });
    };

    /**
     * @function
     * @name sAnalytics.Component.Controller.sPlot#alignYAxis
     * @param {Model.Analytics.Trace2d[]} data
     * @param {Object} layout
     * @returns void
     */
    sPlot.prototype.alignYAxis = function alignYAxis(data, layout) {
        var yAxis   = {},
            extrema = sPlot.getYExtremaByData(data)
        ;

        if (extrema.min === null && extrema.max === null) {
            yAxis.range = [-0.01, 1.01];
            if (this.variant !== 'relative') {
                yAxis.tickmode  = 'array';
                yAxis.tickvals = [0, 1];
            }
        }

        if (this.variant !== 'relative' && extrema.max <= 1 && extrema.min >= -1) {
            yAxis.dtick = 1;
        }

        $.extend(true, layout, {
            yaxis: yAxis
        });
    };

    /**
     * @function
     * @static
     * @name sAnalytics.Component.Controller.sPlot.createNoDataElement
     * @returns {jQuery}
     */
    sPlot.createNoDataElement = function createNoDataElement() {
        var element = $('<div class="chart-no-data"></div>');
        element.append('<span>No data for the selected filter</span>');
        return element;
    };

    /**
     * @function
     * @static
     * @name sAnalytics.Component.Controller.sPlot.getYExtremaByData
     * @param {Model.Analytics.Trace2d[]} data
     * @returns {{min: *, max: *}}
     */
    sPlot.getYExtremaByData = function getYExtremaByData(data) {
        var minY    = null,
            maxY    = null
        ;

        for (var i = 0; i < data.length; i++) {
            for (var k = 0; k < data[i].y.length; k++) {
                if (data[i].y[k] === null) {
                    continue;
                }
                if (minY === null || data[i].y[k] <= minY) {
                    minY = data[i].y[k];
                }
                if (maxY === null || data[i].y[k] >= maxY) {
                    maxY = data[i].y[k];
                }
            }
        }

        return {
            min: minY,
            max: maxY
        };
    };

    /**
     * @function
     * @static
     * @name sAnalytics.Component.Controller.sPlot.dataContainsScatter
     * @param {Model.Analytics.Trace2d[]} data
     * @returns {boolean}
     */
    sPlot.dataContainsScatter = function dataContainsScatter(data) {
        for (var i = 0; i < data.length; i++) {
            if (data[i].type === KEY_PLOTLY_TYPE_SCATTER) {
                return true;
            }
        }
        return false;
    };

    /**
     * @function
     * @static
     * @name sAnalytics.Component.Controller.sPlot.isStackedScatter
     * @param {Object} layout
     * @returns {boolean}
     */
    sPlot.isStackedScatter = function isStackedScatter(layout) {
        var stackedScatter = false;

        if (layout.template && layout.template.data && layout.template.data.scatter) {
            stackedScatter = layout.template.data.scatter.reduce(function (carry, scatterOption) {
                if (scatterOption.fill === 'tonexty') {
                    carry = true;
                }

                return carry;
            }, stackedScatter)
        }

        return stackedScatter;
    };

    /**
     * @function
     * @static
     * @name sAnalytics.Component.Controller.sPlot.isXAxisTimeRange
     * @param {Object} layout
     * @returns {boolean}
     */
    sPlot.isXAxisTimeRange = function isXAxisTimeRange(layout) {
        return layout.template
            && layout.template.layout
            && layout.template.layout.xaxis
            && layout.template.layout.xaxis.type === 'date'
            ;
    };

    /**
     *
     * @param {Model.Analytics.Trace2d[]} traces
     * @param {Number[]=} indices
     */
    sPlot.sumUpTraces = function sumUpTraces(traces, indices) {
        var prevTrace;
        traces.map(
            /**
             * @param {Model.Analytics.Trace2d} trace
             * @param {int} index
             * @returns {*}
             */
            function(trace, index) {
                // skip if not found
                if (indices && indices.indexOf(index) === -1) {
                    return;
                }

                if (trace.yOriginal) {
                    trace.y.splice.apply(trace.y, [0, trace.y.length].concat(trace.yOriginal));
                }

                if (prevTrace) {
                    var newTrace = trace.sumUp(prevTrace);

                    newTrace.name = trace.name;
                    newTrace.yOriginal = trace.y;
                    traces.splice(index, 1, newTrace);
                }

                prevTrace = traces.slice(index, 1).pop();
            }
        );
    };

    /**
     * @param {[]} data
     * @param {Boolean=} needsAsc
     */
    sPlot.sortByValues = function sortByValues(data, needsAsc) {
        // calculate the weight/sum of traces
        data.map(function(trace) {
            if (trace.getMeta(KEY_GROUPED_TRACE)) {
                return;
            }

            trace.sum = trace.y.reduce(function(sum, el) {
                sum += el;
                return sum;
            }, 0);
        });

        data.sort(function(trace1, trace2) {
            return trace2.sum - trace1.sum * (needsAsc ? -1 : 1);
        });
    };

    /**
     * Group s data if more then KEY_MAX_UNGROUPED traces are present. KEY_MAX_UNGROUPED number of traces will be used as normal,
     * the rest will be grouped into a new trace and shown as 'others'
     * If an ungrouped trace is hidden (labelonly) then one trace comes out form the group.
     * @param data
     */
    sPlot.groupDataIfNeeded = function groupDataIfNeeded(data) {
        var setGroupTraceDefaults = function setGroupTraceDefaults(groupTrace) {
            var colorObj = { color: '#EDEEEE'};

            groupTrace.setMeta(KEY_GROUPED_TRACE, true);
            groupTrace.setMeta(KEY_LINE, colorObj);
            groupTrace.setMeta(KEY_BAR, colorObj);
            groupTrace.setMeta(KEY_MARKER, colorObj);
        };

        var groupTrace,
            type
        ;

        if (!data.filter(function(element) {
                return element.x.length;
            }).length) {
                return;
        }

        // set all trace to be visible in the beginning
        data.filter(function(trace) {
            if (trace.visible === false) {
                trace.visible = true;
            }
            return trace.getMeta(KEY_GROUPED_TRACE);
        })
        // find the "group" one and mark it as "hidden"
        .map(function(trace) {
            trace.visible = KEY_LEGEND_ONLY;
            groupTrace = trace;
        });

        if (!groupTrace) {
            groupTrace = new Model.Analytics.Trace2d();
            groupTrace.visible = KEY_LEGEND_ONLY;
            setGroupTraceDefaults(groupTrace);
            data.push(groupTrace);
        }

        // get all "hidden" traces
        var hiddenIndices = data.reduce(function(carry, trace, index) {
            if (trace.visible === KEY_LEGEND_ONLY) {
                carry.push(index);
            }
            return carry;
        }, []);

        // if we have less traces then max + number of hiddens we're done, but first fully hide the group trace
        if (data.length <= (KEY_MAX_UNGROUPED + 1 + hiddenIndices.length)) {
            if (groupTrace) {
                groupTrace.visible = false;
            }
            return;
        }

        var last = data.pop();

        sPlot.sortByValues(data);

        data.push(last);

        var sortableIndices = data.filter(function(trace, index) {
            return hiddenIndices.indexOf(index) === -1;
        }).map(function(el, index) {
            return index;
        });

        sortableIndices.slice(KEY_MAX_UNGROUPED).map(function(index, iteration) {
            data[index].visible = false;
            if (!iteration) {
                type = data[index].type;
                groupTrace = data[index];
            } else {
                groupTrace = groupTrace.sumUp(data[index]);
            }
        });

        // sumUp will always return a new trace so these need to be set
        groupTrace.type     = type;
        groupTrace.name     = KEY_GROUPED_TRACE_NAME;
        groupTrace.visible  = true;
        setGroupTraceDefaults(groupTrace);

        // exchange the groupedTrace
        data.splice(-1, 1, groupTrace);
    };

    /**
     * Adds more detailed hover-data used in the hover-function
     */
    sPlot.addEventDataFn = function addEventDataFn(chart) {
        $.each(chart._fullData, function(key, val) {
            if (val._module.eventData) {
                return true;
            }
            val._module.eventData = function eventData(out, pt, trace, cd, pointNumber) {
                out = $.extend(out, pt);
                return out;
            };
        });
    };

    /**
     * @param {SVGElement} s
     * @param {String} family
     * @param {Number} size
     * @param {String} color
     * @param {Number} weight
     */
    sPlot.setFont = function(s, family, size, color, weight) {
        // also allow the form font(s, {family, size, color})
        if(Object.prototype.toString.call(family) === '[object Object]'
            && Object.getPrototypeOf(family) === Object.prototype
        ) {
            color = family.color;
            size = family.size;
            family = family.family;
            weight = family.weight;
        }
        if(family) s.style('font-family', family);
        if(size + 1) s.style('font-size', size + 'px');
        if(color) s.call(tinycolor.fillSVGElementWithColor, color);
        if(weight) s.style('font-weight', weight);
    };

    /**
     * @param {SVGElement} s
     * @param {Number} x
     * @param {Number} y
     */
    sPlot.positionText = function positionText(s, x, y) {
        return s.each(function() {
            var text = Plotly.d3.select(this);

            function setOrGet(attr, val) {
                if(val === undefined) {
                    val = text.attr(attr);
                    if(val === null) {
                        text.attr(attr, 0);
                        val = 0;
                    }
                }
                else text.attr(attr, val);
                return val;
            }

            var thisX = setOrGet('x', x);
            var thisY = setOrGet('y', y);

            if(this.nodeName === 'text') {
                text.selectAll('tspan.line').attr({x: thisX, y: thisY});
            }
        });
    };

    /**
     * @param {String} _str
     * @returns {String}
     */
    sPlot.toPlainText = function toPlainText(_str) {
        var TAG_STYLES = {
            // would like to use baseline-shift for sub/sup but FF doesn't support it
            // so we need to use dy along with the uber hacky shift-back-to
            // baseline below
            sup: 'font-size:70%',
            sub: 'font-size:70%',
            b: 'font-weight:bold',
            i: 'font-style:italic',
            a: 'cursor:pointer',
            span: '',
            em: 'font-style:italic;font-weight:bold'
        };
        var STRIP_TAGS = new RegExp('</?(' + Object.keys(TAG_STYLES).join('|') + ')( [^>]*)?/?>', 'g');
        // strip out our pseudo-html so we have a readable
        // version to put into text fields
        return (_str || '').replace(STRIP_TAGS, ' ');
    };

    /**
     * @param {Number} x
     * @param {Number} y
     * @param {Number} w
     * @param {Number} h
     * @param {Number} r1
     * @param {Number} r2
     * @param {Number} r3
     * @param {Number} r4
     * @returns {string}
     */
    sPlot.getRoundedRectStr = function getRoundedRectStr(x, y, w, h, r1, r2, r3, r4) {
        var p = function point(x,y){
            return x+" "+y+" ";
        };

        var strPath = "M"+p(x+r1,y); //A
        strPath+="L"+p(x+w-r2,y)+"Q"+p(x+w,y)+p(x+w,y+r2); //B
        strPath+="L"+p(x+w,y+h-r3)+"Q"+p(x+w,y+h)+p(x+w-r3,y+h); //C
        strPath+="L"+p(x+r4,y+h)+"Q"+p(x,y+h)+p(x,y+h-r4); //D
        strPath+="L"+p(x,y+r1)+"Q"+p(x,y)+p(x+r1,y); //A
        strPath+="Z";

        return strPath;
    };

    /*
     * Positioning helpers
     * Note: do not use `setPosition` with <text> nodes modified by
     * `svgTextUtils.convertToTspans`. Use `svgTextUtils.positionText`
     * instead, so that <tspan.line> elements get updated to match.
     */
    /**
     *
     * @param {SVGElement} s
     * @param {Number} x
     * @param {Number} y
     */
    sPlot.setPosition = function(s, x, y) { s.attr('x', x).attr('y', y); };

    /**
     * @param {SVGElement} s
     * @param {Number} w
     * @param {Number} h
     */
    sPlot.setSize = function(s, w, h) { s.attr('width', w).attr('height', h); };

    /**
     * @param {String} s
     * @param {Number} x
     * @param {Number} y
     * @param {Number} w
     * @param {Number} h
     */
    sPlot.setRect = function(s, x, y, w, h) {
        s.call(sPlot.setPosition, x, y).call(sPlot.setSize, Math.abs(w), Math.abs(h));
    };

    /**
     * @param defs
     * @param {String} name
     * @param {Number} width
     * @param {Number} xShift
     * @param {Number} yShift
     * @param {string} color
     */
    sPlot.createShadow = function createShadow(defs, name, width, xShift, yShift, color) {
        if (defs.selectAll('filter').size() > 0) {
            return;
        }

        // create filter with id #drop-shadow
        // height=130% so that the shadow is not clipped
        var filter = defs.append("filter")
            .attr("id", name)
            .attr("height", "130%");

        // SourceAlpha refers to opacity of graphic that this filter will be applied to
        // convolve that with a Gaussian with standard deviation 3 and store result
        // in blur
        filter.append("feGaussianBlur")
            .attr("in", "SourceAlpha")
            .attr("stdDeviation", width)
            .attr("result", "blur");

        // translate output of Gaussian blur to the right and downwards with 2px
        // store result in offsetBlur
        filter.append("feOffset")
            .attr("in", "blur")
            .attr("dx", xShift)
            .attr("dy", yShift)
            .attr("result", "offsetBlur");

        filter.append("feFlood")
            .attr("flood-color", color)
            .attr("flood-opacity", "0.5")
            .attr("result", "offsetColor");

        filter.append("feComposite")
            .attr("in", "offsetColor")
            .attr("in2", "offsetBlur")
            .attr("operator", "in")
            .attr("result", "offsetBlur");

        // overlay original SourceGraphic over translated blurred opacity by using
        // feMerge filter. Order of specifying inputs is important!
        var feMerge = filter.append("feMerge");

        feMerge.append("feMergeNode")
            .attr("in", "offsetBlur");
        feMerge.append("feMergeNode")
            .attr("in", "SourceGraphic");
    };

    /**
     * @static
     * @function
     * @name sAnalytics.Component.Controller.sPlot.fixFillForNoData
     * @param {Model.Analytics.Trace2d[]} data
     */
    sPlot.fixFillForNoData = function fixFillForNoData(data) {
        data.map(function(trace) {
            var extrema = sPlot.getYExtremaByData([trace]);
            if (extrema.min === null && extrema.max === null) {
                trace.setMeta('fill', 'none');
            }
        });
    };

    /**
     * @param {HTMLElement} chartElem
     * @param {Object} hoverData
     */
    sPlot.customHoverFn = function customHoverFn(chartElem, hoverData) {
        var DEFAULTS = {
            BACKGROUND          : '#FFF',
            HOVERFONT           : 'Avenir Next',
            FONTSIZETITLE       : 14,
            FONTSIZE            : 13,
            BORDERRADIUS        : 5,
            BORDERSIZE          : 0,
            BORDERCOLOR         : 'lightgray',
            BOXPADDING          : 10,
            VERTICALPADDING     : 8,
            HOVEROFFSET         : 10,
            HOVERARROWSIZE      : 6,
            HOVERTEXTPAD        : 2, // text-clipping-fix
            YANGLE              : 60,
            YSHIFTX             : Math.cos(Math.PI * 60 / 180),
            YSHIFTY             : Math.sin(Math.PI * 60 / 180)
        };

        var fullLayout          = chartElem._fullLayout,
            container           = fullLayout._hoverlayer,
            outerContainer      = fullLayout._paperdiv,
            outerContainerBB    = outerContainer.node().getBoundingClientRect(),
            outerWidth          = outerContainerBB.width,
            outerHeight         = outerContainerBB.height
        ;

        var bgColor = tinycolor.combineColor(
            fullLayout.plot_bgcolor || DEFAULTS.BACKGROUND,
            fullLayout.paper_bgcolor
            )
        ;

        // ### first hide all default labels ###
        var hoverLabelsOrig = container.selectAll('g.hovertext');
        hoverLabelsOrig.each(function () {
            Plotly.d3.select(this).style('display', 'none');
        });

        // ### now create on hover-elements ###
        var hoverLabels = container.selectAll('g.custom-hovertext')
            .data(hoverData, function(d) {
                return [d.trace.index, d.index, d.x0, d.y0, d.name, d.attr, d.xa, d.ya || ''].join(',');
            });

        // ### create the skeleton elements ###
        hoverLabels.enter().append('g')
            .classed('custom-hovertext', true)
            .each(function(d) {
                var g = Plotly.d3.select(this);

                if (d.trace.type === KEY_PLOTLY_TYPE_SCATTER) {
                    g.append('circle')
                        .style('stroke-width', '1px')
                    ;
                } else if (d.trace.type === 'bar') {
                    g.append('rect');
                }

                // trace data label (path and text.value)
                g.append('path')
                    .classed('background', true)
                    .style('stroke-width', DEFAULTS.BORDERSIZE + 'px')
                ;
                g.append('text')
                    .classed('value', true)
                ;

                // background for title
                g.append('path')
                    .classed('title', true)
                ;
                // title
                g.append('text')
                    .classed('title', true)
                ;
            });
        hoverLabels.exit().remove();

        // ### fill the elements ###
        // then put the text in, position the pointer to the data,
        // and figure out sizes
        hoverLabels.each(function(d) {
            var g = Plotly.d3.select(this).attr('transform', '');
            var name = '';
            var text = '';

            // combine possible non-opaque trace color with bgColor
            var color0 = d.bgcolor || d.color;
            // main color
            var mainColor = tinycolor.combineColor(
                tinycolor.getOpacity(color0) ? color0 : tinycolor.defaults.defaultLine,
                bgColor
            );

            // to get custom 'name' labels pass cleanPoint
            if (d.nameOverride !== undefined) d.name = d.nameOverride;

            if (d.name) {
                // strip out our pseudo-html elements from d.name (if it exists at all)
                name = sPlot.toPlainText(d.name || '');

                var nameLength = Math.round(d.nameLength);

                if(nameLength > -1 && name.length > nameLength) {
                    if(nameLength > 3) name = name.substr(0, nameLength - 3) + '...';
                    else name = name.substr(0, nameLength);
                }
            }

            var elemTextValue = g.select('text.value')
                .attr('data-notex', 1)
                .attr('text-anchor', 'start')
                .call(sPlot.setFont,
                    DEFAULTS.HOVERFONT,
                    DEFAULTS.FONTSIZE,
                    tinycolor.getMostReadableColor(bgColor)
                )
            ;
            var separateValue;

            // mostly original stuff for fetching the correct text
            if (d.zLabel !== undefined) {
                if(d.xLabel !== undefined) text += 'x: ' + d.xLabel + '<br>';
                if(d.yLabel !== undefined) text += 'y: ' + d.yLabel + '<br>';
                text += (text ? 'z: ' : '') + d.zLabel;
            }
            else if (d.xLabel === undefined) {
                if(d.yLabel !== undefined) {
                    text = d.yLabel;
                }
            }
            else if (d.yLabel === undefined) {
                text = d.xLabel;
            }
            else {
                text = d.xLabel + ': ';
                separateValue = d.yLabel;
                if (fullLayout.yaxis.tickformat) {
                    separateValue = Plotly.d3.format(fullLayout.yaxis.tickformat)(separateValue);
                }
            }

            if ((d.text || d.text === 0) && !Array.isArray(d.text)) {
                text += (text ? '<br>' : '') + d.text;
            }

            // used by other modules (initially just ternary) that
            // manage their own hoverinfo independent of cleanPoint
            // the rest of this will still apply, so such modules
            // can still put things in (x|y|z)Label, text, and name
            // and hoverinfo will still determine their visibility
            if (d.extraText !== undefined) text += (text ? '<br>' : '') + d.extraText;

            // if 'text' is empty at this point,
            // put 'name' in main label and don't show secondary label
            if (text === '') {
                // if 'name' is also empty, remove entire label
                if(name === '') g.remove();
                text = name;
            }

            elemTextValue.text(text);
            if (separateValue) {
                elemTextValue
                    .append("tspan")
                    .style("font-weight", 'bold')
                    .text(separateValue);
            }

            var elemTextValueRect = elemTextValue.node().getBoundingClientRect();
            var minWidth = elemTextValueRect.width;
            var valueHeight = elemTextValueRect.height + 2 * DEFAULTS.VERTICALPADDING;

            sPlot.createShadow(chartElem._fullLayout._defs, 'hoverbox-shadow', 1, 0, 2, "rgba(0,0,0,0.2)");
            g.select('path.background')
                .style("filter", "url(#hoverbox-shadow)")
                .style({
                    fill: bgColor,
                    stroke: tinycolor.addOpacityToColor(DEFAULTS.BORDERCOLOR, 0.5)
                })
            ;

            var elemTextTitle = g.select('text.title');
            var elemPathTitle = g.select('path.title');

            var showTitle = name && name !== text;
            var elemTextTitleRect;
            var titleHeight = 0;

            if (showTitle) {
                elemTextTitle
                    .text(name)
                    .attr('data-notex', 1)
                    .attr('text-anchor', 'start')
                    .attr('dy', '-.1em')
                    .call(sPlot.setFont,
                        DEFAULTS.HOVERFONT,
                        DEFAULTS.FONTSIZETITLE,
                        tinycolor.getMostReadableColor(mainColor)

                    )
                ;
                elemTextTitleRect = elemTextTitle.node().getBoundingClientRect();

                minWidth = Math.max(minWidth, elemTextTitleRect.width);
                titleHeight = elemTextTitleRect.height + 2 * DEFAULTS.VERTICALPADDING;

                elemPathTitle
                    .call(tinycolor.fillSVGElementWithColor, mainColor)
                ;
            }
            else {
                elemTextTitle.remove();
                elemPathTitle.remove();
            }

            var totalWidth = (DEFAULTS.BOXPADDING + DEFAULTS.BORDERSIZE) * 2 + minWidth;
            var htx = d.xa._offset + (d.x0 + d.x1) / 2;
            var hty = d.ya._offset + (d.y0 + d.y1) / 2;
            var dx = Math.abs(d.x1 - d.x0);
            var dy = Math.abs(d.y1 - d.y0);

            d.showTitle = showTitle;
            d.pos = hty;
            d.elemTextTitleRect = elemTextTitleRect;
            d.elemTextValueRect = elemTextValueRect;
            d.rawWidth = minWidth;
            d.titleHeight = titleHeight;
            d.valueHeight = valueHeight;
            d.totalHeight = valueHeight + titleHeight;

            var anchorRightOK = htx + dx / 2 + totalWidth <= outerWidth;
            var anchorLeftOK = htx - dx / 2 - totalWidth >= 0;
            var anchorTopOK = hty - dy / 2 - d.totalHeight  >= 0;
            var anchorBottomOK = hty + dy / 2 + d.totalHeight <= outerHeight;

            // check horizontal borders (original functionality)
            if ((d.idealAlign === 'left' || !anchorRightOK) && anchorLeftOK) {
                htx -= dx / 2;
                d.anchor = 'end';
            }
            else if(anchorRightOK) {
                htx += dx / 2;
                d.anchor = 'start';
            }
            else d.anchor = 'middle';

            // check vertical borders
            if (anchorBottomOK && anchorTopOK) {
                d.anchorVertical = 'middle';
            } else if (!anchorBottomOK) {
                hty -= dy / 2;
                d.anchorVertical = 'bottom';
            } else {
                hty += dy / 2;
                d.anchorVertical = 'top';
            }

            // hover highlighting
            if (d.trace.type === KEY_PLOTLY_TYPE_SCATTER) {
                g.select('circle')
                    .attr("r", 3)
                    .style("fill", mainColor)
                    .style("stroke", mainColor)
                    .call(sPlot.setPosition, 0, 0)
                ;
            } else if (d.trace.type === 'bar') {
                var x = d.x0 + d.xa._offset,
                    y = d.y1 + d.ya._offset,
                    rectWidth = (d.x1 + d.xa._offset) - x,
                    rectHeight = (d.ya.c2p(d.cd[d.index].s0, true) + d.ya._offset) - y
                ;

                g.select('rect')
                    .call(tinycolor.fillSVGElementWithColor, tinycolor.addOpacityToColor(bgColor, 0.1))
                    .call(sPlot.setRect,
                        x - htx,
                        y - hty,
                        rectWidth,
                        rectHeight
                    )
                ;
            }

            // positions the actual hover element
            g.attr('transform', 'translate(' + htx + ',' + hty + ')');
        });

        // ### position the hover elements ###
        // finally set the text positioning relative to the data and draw the
        // box around it
        hoverLabels.each(function(d) {
            var g = Plotly.d3.select(this);
            if(d.del) {
                g.remove();
                return;
            }

            var alignShift = {start: 1, end: -1, middle: 0}[d.anchor];
            var xShift = {
                start: 0,
                end: alignShift * (d.rawWidth + (DEFAULTS.BOXPADDING + DEFAULTS.BORDERSIZE) * 2),
                middle: -1 * (d.rawWidth + (DEFAULTS.BOXPADDING + DEFAULTS.BORDERSIZE) * 2) / 2
            }[d.anchor];
            var yShift = {
                top: 0,
                bottom: -1 * d.totalHeight,
                middle: -1 * (d.totalHeight / 2)
            }[d.anchorVertical];
            var offsetX = DEFAULTS.HOVEROFFSET;
            var posX = alignShift * offsetX + xShift;
            var posY = yShift;

            if (d.showTitle) {
                g.select('path.title')
                    .attr('d', sPlot.getRoundedRectStr(
                        posX,
                        posY,
                        d.rawWidth + (DEFAULTS.BOXPADDING) * 2, // width
                        d.titleHeight, // height
                        DEFAULTS.BORDERRADIUS, // TL
                        DEFAULTS.BORDERRADIUS, // TR
                        0, // BR
                        0  // BL
                    ))
                ;

                g.select('text.title')
                    .call(sPlot.positionText,
                        posX + DEFAULTS.BOXPADDING,
                        posY + d.titleHeight - DEFAULTS.VERTICALPADDING - DEFAULTS.HOVERTEXTPAD
                    )
                ;
            }

            g.select('path.background')
                .attr('d', sPlot.getRoundedRectStr(
                    posX,
                    posY,
                    d.rawWidth + (DEFAULTS.BOXPADDING) * 2, // width
                    d.totalHeight, // height
                    DEFAULTS.BORDERRADIUS, // TL
                    DEFAULTS.BORDERRADIUS, // TR
                    DEFAULTS.BORDERRADIUS, // BR
                    DEFAULTS.BORDERRADIUS  // BL
                ))
            ;

            g.select('text.value')
                .call(sPlot.positionText,
                    posX + DEFAULTS.BOXPADDING,
                    posY + d.totalHeight - DEFAULTS.VERTICALPADDING - DEFAULTS.HOVERTEXTPAD
                )
            ;
        });
    };

    ns.sPlot = sPlot;
})(Object.namespace('sAnalytics.Component.Controller'));
