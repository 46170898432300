(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sMemberSubscriptions
     * @constructor
     *
     * @param $scope
     */
    var sMemberSubscriptions = function sMemberSubscriptions($scope) {
        var channels        = [],
            subscriptions   = [],
            queued          = [], // store subscriptions being processed
            self            = this,
            loadingPromise  = null
        ;

        this.isLoading = function isLoading() {
            return loadingPromise !== null;
        };

        Object.defineProperties(
            this,
            {
                channels: {
                    get: function () {
                        return channels;
                    },
                    set: function (val) {
                        if (val === channels) {
                            return;
                        }

                        var toAdd = channels.concat(queued).diff(val),
                            toRemove = val.diff(channels.concat(queued))
                        ;

                        if (toAdd.length) {
                            toAdd.filter(function (element) {
                                var subscriptionToAdd = new Model.Subscription();
                                subscriptionToAdd.memberId = self.memberId;
                                subscriptionToAdd.segmentId = element;
                                queued.push(element);
                                subscriptionToAdd.save().then(function () {
                                    subscriptions.push(subscriptionToAdd);
                                    if (queued.indexOf(element) !== -1) {
                                        queued.splice(queued.indexOf(element), 1);
                                    }
                                });
                            });
                        }

                        if (toRemove.length) {
                            var byChannels = subscriptions.map(function (elem) {
                                return elem.segmentId;
                            });
                            toRemove.filter(function (element) {
                                var index = byChannels.indexOf(element),
                                    subscriptionToDelete = subscriptions[index]
                                ;

                                if (index === -1) {
                                    return;
                                }
                                queued.push(element);
                                subscriptionToDelete.delete().then(function () {
                                    subscriptions.splice(index, 1);
                                    if (queued.indexOf(element) !== -1) {
                                        queued.splice(queued.indexOf(element), 1);
                                    }
                                });

                            });
                        }
                        channels = val;
                    }
                }
            }
        );

        this.fetchChannelsForUserId = function fetchChannelsForUserId(userId) {
            if (!loadingPromise) {
                subscriptions = [];
                loadingPromise = $.ajax({
                    url: Model.Subscription.endPoint,
                    data: {'hUserId': userId}
                }).then(function (data) {
                    if (!data.collection) {
                        return;
                    }

                    channels = data.collection.map(function (elem) {
                        var subscription = Model.Subscription.fromJson(JSON.stringify(elem));
                        subscriptions.push(subscription);
                        return elem.segmentId;
                    });
                }).always(function () {
                    loadingPromise = null;
                    digestIfNeeded($scope);
                });
            }

            return loadingPromise;
        }
    };

    /**
     * @function
     * @name Controller.Component.sMemberSubscriptions#$onChanges
     * @param {Object.<String, SimpleChange>} $changes
     */
    sMemberSubscriptions.prototype.$onChanges = function ($changes) {
        if ($changes.memberId) {
            this.fetchChannelsForUserId($changes.memberId.currentValue);
        }
    };

    ns.sMemberSubscriptions = sMemberSubscriptions;
})(Object.namespace('Controller.Component'));
