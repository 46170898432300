(function(ns){
    var MODE_SELECT         = 'select',
        MODE_CUT            = 'cut',
        THRESHOLD_MOUSE_OUT = 5,
        DATA_MESSAGE_KEY    = 'data-msg-key',
        EVENT_MODEL_CHANGED = 'sEventMessageCollectionChanged'
    ;

    /**
     * @namespace
     * @alias Controller.Component.sMinimap
     * @param $element
     * @param $scope
     * @param $location
     * @param {Service.sMessageCollection} sMessageCollection
     */
    var sMinimap = function($element, $scope, $location, sMessageCollection) {
        this.$element               = $element;
        this.$scope                 = $scope;
        this.$location              = $location;
        this.currentScale           = 1;
        this.translate              = [0,0];
        this.filteredMessages       = [];
        this.$deRegister            = [];
        this.collapseConnections    = false;
        this.hideConnectionLabels   = false;
        this.sMessageCollection     = sMessageCollection;

        var mode          = MODE_SELECT,
            paths         = {},
            /**
             * @type {String[]}
             */
            activeSubTree,
            mouseOutTimeOut,
            hoverLocked = false
        ;

        /**
         * @type Model.Message.Collection
         * @name Controller.Component.sMinimap#model
         */

        /**
         * @type Array
         * @name Controller.Component.sMinimap#messageLane
         */

        /**
         * @param {String} newMode
         */
        this.handleModeChange = function handleModeChange(newMode) {
            if ([MODE_SELECT, MODE_CUT].indexOf(newMode) === -1 || newMode === mode) {
                return;
            }

            mode = newMode;

            this.calculatePaths();
            this.redraw();
        };

        this.calculatePaths = function calculatePaths() {
            if (mode !== MODE_CUT) {
                paths = {};
                return;
            }

            paths = this.model.messages
                .filter(function (message) { return !message.isIncoming && !message.isRoot})
                .reduce(function (carry, message) {
                    var path = message.extractPathAsRootMessage();
                    if (!path) {
                        return carry;
                    }
                    carry[message.uuid] = path.map(function (message) {
                        return message.uuid;
                    });
                    return carry;
                }, {})
        };

        /**
         * @param evt
         */
        this.handleMouseOver = function (evt) {
            if (this.mode !== MODE_CUT || !evt.target.hasAttribute(DATA_MESSAGE_KEY) || hoverLocked) {
                return;
            }

            if (mouseOutTimeOut) {
                clearTimeout(mouseOutTimeOut);
            }

            var msgUuid = evt.target.getAttribute(DATA_MESSAGE_KEY);
            if (!this.isRootCandidate(msgUuid) || activeSubTree === paths[msgUuid]) {
                return;
            }

            activeSubTree = paths[msgUuid];
            this.redraw();
        };

        this.handleMouseOut = function () {
            if (this.mode !== MODE_CUT || !activeSubTree || hoverLocked) {
                return;
            }

            if (mouseOutTimeOut) {
                clearTimeout(mouseOutTimeOut);
            }

            mouseOutTimeOut = setTimeout(function () {
                activeSubTree = null;
                this.redraw();
            }.bind(this), THRESHOLD_MOUSE_OUT);
        };

        /**
         * @param {Event} evt
         */
        this.handleMouseUp = function (evt) {
            if (!evt.target.hasAttribute(DATA_MESSAGE_KEY)) {
                return;
            }

            if (this.mode === MODE_SELECT) {
                this.$location.hash('message-focus-' + evt.target.getAttribute(DATA_MESSAGE_KEY));
                this.$scope.$evalAsync();
            }

            if (this.mode === MODE_CUT) {
                var msgUuid = evt.target.getAttribute(DATA_MESSAGE_KEY);
                if (!this.isRootCandidate(msgUuid)) {
                    return;
                }

                hoverLocked = true;

                this.sMessageCollection.extractPathAsNewConversation(this.model, this.model.getMessageByUuid(msgUuid))
                    .then(function (cloneCollection) {
                        if (!cloneCollection) {
                            return;
                        }

                        this.model = cloneCollection;
                        this.$scope.$emit(EVENT_MODEL_CHANGED, cloneCollection);
                    }.bind(this))
                    .always(function () {
                        activeSubTree = null;
                        hoverLocked = false;
                        this.redraw();
                    }.bind(this));
            }

            this.redraw();
        };

        /**
         * @param {String} uuid
         * @return {Boolean}
         */
        this.isRootCandidate = function isRootCandidate(uuid) {
            return this.mode === MODE_CUT && !!paths[uuid];
        };

        /**
         * @param {Model.Message|Model.Connection} payload
         * @return {Boolean}
         */
        this.isInActiveSubTree = function isInActiveSubTree(payload) {
            if (this.mode !== MODE_CUT || !activeSubTree) {
                return false;
            }

            if (payload instanceof Model.Message) {
                return activeSubTree.indexOf(payload.uuid) !== -1;
            }

            if (payload instanceof Model.Connection) {
                return this.isInActiveSubTree(payload.getFromMessage()) && this.isInActiveSubTree(payload.getToMessage());
            }
        };

        Object.defineProperties(
            this,
            {
                mode: {
                    enumerable: true,
                    get: function () {
                        return mode;
                    }
                    /**
                     * @property
                     * @name Controller.Component.sMinimap#mode
                     * @type {String}
                     */
                },
                MODE_SELECT: {
                    value: MODE_SELECT
                    /**
                     * @property
                     * @constant
                     * @name Controller.Component.sMinimap#MODE_SELECT
                     * @type {String}
                     */
                },
                MODE_CUT: {
                    value: MODE_CUT
                    /**
                     * @property
                     * @constant
                     * @name Controller.Component.sMinimap#MODE_CUT
                     * @type {String}
                     */
                }
            });
    };

    sMinimap.prototype.$onInit = function $onInit() {
        var self = this;
        this.$deRegister.push(this.$scope.$watch(
            function() {
                return self.model.length + '-' + self.model.getRelations().length;
            },
            function() {
                self.filterMessages();
                self.calculatePaths();
                self.redraw();
            })
        );

        this.$deRegister.push(this.$scope.$watch(function() {
                return self.model.getRootMessage().isIncoming
            },
            function() {
                self.redraw();
            })
        );

        this.$deRegister.push(this.$scope.$on(sSidebar.Service.sSidebar.EVENT_SIDEBAR_RESIZED, function() {
            self.reset();
        }));

        this.$deRegister.push(this.$scope.$on(sSidebar.Service.sSidebar.EVENT_SIDEBAR_ATTACH, function() {
            self.reset();
        }));

        this.$deRegister.push(this.$scope.$on(sSidebar.Service.sSidebar.EVENT_SIDEBAR_DETACH, function() {
            self.reset();
        }));

        this.$deRegister.push(this.$scope.$watch(function() {
                return self.model.getRootMessage().uuid
            },
            function() {
                self.filterMessages();
                self.redraw();
            })
        );

        this.$deRegister.push(this.$scope.$watch(function() {
                return self.hideConnectionLabels;
            },
            function() {
                self.filterMessages();
                self.redraw();
            })
        );

        this.$deRegister.push(this.$scope.$watch(function() {
                return self.collapseConnections;
            },
            function() {
                self.filterMessages();
                self.redraw();
            })
        );

        this.$deRegister.push(this.$scope.$watchCollection(
            function() {
                return self.messageLane;
            },
            function() {
                self.redraw();
            })
        );

        this.$deRegister.push(
            this.$scope.$on('redraw-minimap', self.redraw.bind(self))
        );

        this.$deRegister = this.$deRegister.concat(this.$element.$on('mouseup', this.handleMouseUp.bind(this)));
        this.$deRegister = this.$deRegister.concat(this.$element.$on('mouseover', this.handleMouseOver.bind(this)));
        this.$deRegister = this.$deRegister.concat(this.$element.$on('mouseout', this.handleMouseOut.bind(this)));
    };

    /**
     *
     */
    sMinimap.prototype.filterMessages = function filterMessages() {
        this.filteredMessages = this.model.messages.filter(function(message) {
            return message.needsCounter();
        });
    };

    /**
     * @function
     * @name Controller.Component.sMinimap#$onDestroy
     */
    sMinimap.prototype.$onDestroy = function() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     *
     * @param message
     * @returns {String}
     */
    sMinimap.prototype.getNodeClass = function getNodeClass(message) {
        var classString;

        if (this.mode === MODE_SELECT) {
            classString = (message.isOrphan ? 'orphan ' : '') + (this.isOnActiveLane(message.uuid) ? 'active ' : '') + (message.hasNoContent() ? 'empty ' : '');

            if (message.isRoot && message.isIncoming) {
                classString += ' incoming';
            }
        }

        if (this.mode === MODE_CUT) {
            classString = this.isInActiveSubTree(message) ? 'selected' : (!this.isRootCandidate(message.uuid) ? 'faded' : '');
        }

        return classString;
    };

    /**
     * @name Controller.Component.sMinimap#getMessageIndex
     * @param {Model.Message} message
     * @returns {*}
     */
    sMinimap.prototype.getMessageIndex = function getMessageIndex(message) {
        Object.instanceOf(message, Model.Message);
        for (var i = 0; i < this.filteredMessages.length; i++) {
            if (this.filteredMessages[i].uuid === message.uuid) {
                return i;
            }
        }
        return false;
    };

    /**
     * @name Controller.Component.sMinimap#isOnActiveLane
     * @param uuid
     * @returns {boolean}
     */
    sMinimap.prototype.isOnActiveLane = function isOnActiveLane(uuid) {

        if (!this.messageLane) {
            return false;
        }

        return this.messageLane.indexOf(uuid) !== -1;
    };

    /**
     * @param {Model.Connection} conn
     */
    sMinimap.prototype.isConnectionActive = function isConnectionActive(conn) {
        return this.mode === MODE_SELECT && this.isOnActiveLane(conn.relationA.uuid) && (!conn.relationB || this.isOnActiveLane(conn.relationB.uuid));
    };

    /**
     * @param label
     * @return {String}
     */
    sMinimap.prototype.getEdgeLabelClass = function getEdgeLabelClass(label) {
        var cssClasses = ['edgelabel'];

        if (this.isConnectionActive(label.connection) && this.mode !== MODE_CUT) {
            cssClasses.push('active');
        }

        if (this.isInActiveSubTree(label.connection) && this.mode !== MODE_SELECT) {
            cssClasses.push('selected');
        }


        return cssClasses.join(' ');
    };

    /**
     * @param {Model.Message} message
     * @return {String}
     */
    sMinimap.prototype.getMessageHint = function getMessageHint(message) {
        if (this.mode === MODE_SELECT) {
            return "Click to navigate to message";
        }

        if (this.mode === MODE_CUT) {
            if (this.isRootCandidate(message.uuid)) {
                return "Click on the message to extract the highlighted path";
            } else {
                return "This message cannot be extracted";
            }
        }
    };

    /**
     *
     */
    sMinimap.prototype.redraw = function redraw() {
        var self        = this,
            links       = [],
            linksIndex  = {},
            prevTick    = 0,
            routes      = {},
            texts
            ;

        if (this.force) {
            this.force.stop();
        }

        for (var i = 0; i < self.filteredMessages.length; i++) {
            var msg = self.filteredMessages[i];

            msg
                 .setMeta('fixed', msg.getMeta('fixed'))
                 .setMeta('x', msg.getMeta('x'))
                 .setMeta('y', msg.getMeta('y'));

            var relations = msg.getRelationsFrom();
            for (var j = 0; j < relations.length; j++) {
                var relation = relations[j]
                    ;

                if (!relation.from || !relation.to) {
                    continue;
                }

                var connectingRelations;

                if (msg.isRoot && msg.isIncoming) {
                    connectingRelations = [null];
                } else {
                    connectingRelations = relation.to.getRelationsFrom();
                }

                for (var k = 0; k < connectingRelations.length; k++) {
                    var connection = new Model.Connection(relation, connectingRelations[k]);
                    if (!connection.getToMessage() || !connection.getFromMessage()) {
                        continue;
                    }

                    if (!linksIndex[connection.uuid]) {

                        routes[connection.getRouteUuid()] = routes[connection.getRouteUuid()] || [];
                        routes[connection.getRouteUuid(true)] = routes[connection.getRouteUuid(true)] || [];

                        if (routes[connection.getRouteUuid()].length && this.collapseConnections) {
                            continue;
                        }
                        routes[connection.getRouteUuid()].push(connection.uuid);
                        routes[connection.getRouteUuid(true)].push('-' + connection.uuid);
                        linksIndex[connection.uuid] = connection;

                        links.push({
                            connection  : connection,
                            source      : self.getMessageIndex(connection.getFromMessage()),
                            target      : self.getMessageIndex(connection.getToMessage()),
                            label       : connection.label,
                            uuid        : connection.uuid
                        });
                    }
                }
            }
        }
        var $svg = this.$element.find('svg');
        $svg.html('');

        function dragstart(d) {
            d3.event.sourceEvent.stopPropagation();
            d3.select(this).classed("fixed", d.fixed = true);
        }

        var svg = d3.select($svg[0]);

        function zoom() {
            self.currentScale = d3.event.scale;
            self.translate    = d3.event.translate;
            svg.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")");
            digestIfNeeded(self.$scope);
        }

        svg = svg.call(d3.behavior.zoom().scale(self.currentScale).translate(self.translate).scaleExtent([0.1, 4]).on("zoom", zoom))
            .append("svg:g")
        ;

        this.force = d3.layout.force()
            .nodes(this.filteredMessages)
            .links(links)
            .size([$svg.width(), $svg.height()])
            .linkDistance([100])
            .charge(function(node){
                /** @name {Model.Message} node */
                return node.isRoot ? -1000 : Math.max(1, node.getRelationsAsArray().length) * -100;
            })
            .theta(0.8)
            .gravity(0.1)
            .start();

        svg.attr("transform", "translate(" + (self.translate) + ")scale(" + (self.currentScale) + ")");

        var drag = this.force.drag()
            .on("dragstart", dragstart);

        var edges = svg.selectAll("path")
            .data(links)
            .enter()
            .append("path")
            .attr("id", function(d,i) {return 'edge'+i})
            .attr('marker-end', function(d) { return 'url(#arrowhead' + (self.isConnectionActive(d.connection) ? '-active' :'') + ')'; })
            .style("pointer-events", "all")
            .attr("class", function(d) { return (self.isConnectionActive(d.connection) ? 'active' :'') + ' ' + d.connection.type })
            ;

        var nodes = svg.selectAll("circle")
            .data(this.filteredMessages)
            .enter()
            .append("circle")
            .attr({"r":function(d){
                return d.isRoot ? 20 : 15;
            }})
            .attr("class", self.getNodeClass.bind(self))
            .attr("data-msg-key", function(d){return d.uuid;})
            .call(drag)
            ;

        svg.selectAll("circle")
            .append('title')
            .text(self.getMessageHint.bind(self));

        var nodelabels = svg.selectAll(".node-label")
            .data(this.filteredMessages)
            .enter()
            .append("text")
            .attr({
                'class': self.getNodeClass.bind(self),
                'text-anchor': 'middle',
                'dy':   5
            })
            .attr("data-msg-key", function(d){return d.uuid;})
            .text(function(d){return d.counter;})
            .call(drag)
            ;

        svg.selectAll('text')
            .append('title')
            .text(self.getMessageHint.bind(self))
        ;

        if (!this.hideConnectionLabels) {
            texts = svg.append("svg:g").selectAll("g")
                .data(links)
                .enter()
                .append("text")
                .attr("x", function(l) { return l.target.x; })
                .attr("y", function(l) { return l.target.y; })
                .attr('text-anchor', 'middle')
                .attr('class', this.getEdgeLabelClass.bind(self))
                .text(function(l) { return l.connection.label });
        }

        svg.append('defs').append('marker')
            .attr({'id':'arrowhead',
                'viewBox':'-0 -5 10 10',
                'refX':25,
                'refY':0,
                'orient':'auto',
                'markerWidth':5,
                'markerHeight':5,
                'x-overflow':'visible'})
            .append('svg:path')
            .attr('d', 'M 0,-5 L 10 ,0 L 0,5');

        svg.append('defs').append('marker')
            .attr({'id':'arrowhead-active',
                'viewBox':'-0 -5 10 10',
                'refX':25,
                'refY':0,
                'orient':'auto',
                'markerWidth':5,
                'markerHeight':5,
                'x-overflow':'visible'})
            .append('svg:path')
            .attr('d', 'M 0,-5 L 10 ,0 L 0,5');



        this.force.on("tick", function() {
            var tick = new Date().getTime();

            if (tick-prevTick < 33) {
                return
            }

            if (links.length) {
                edges.attr({
                    "d": function (d) {
                        var key     = d.connection.getRouteUuid();

                        if (!routes[key]) {
                            return;
                        }

                        var median  = (routes[key].length - 1) / 2,
                            vector  = {x: d.target.x - d.source.x, y: d.target.y - d.source.y},
                            pos
                            ;

                        if ((pos = routes[key].indexOf(d.connection.uuid)) === median) {
                            d.mid = null;
                            return 'M' + d.source.x + ' ' + d.source.y + ' ' + d.target.x + ' ' + d.target.y;
                        } else {
                            var prefix = 1;
                            if (pos > 0) {
                                if ((routes[key][0][0] === '-' && routes[key][pos][0] !== routes[key][0][0])
                                    || (routes[key][pos][0] === '-' && routes[key][pos][0] !== routes[key][0][0])) {
                                    prefix = -1
                                }
                            }
                            var rank        = (pos - median) / median / 2,
                                positions   = [
                                    d.source.x + vector.x / 2 + prefix * vector.y * rank,
                                    d.source.y + vector.y / 2 - prefix * vector.x * rank
                                ]
                                ;

                            d.mid = positions;

                            return 'M' + d.source.x + ' ' + d.source.y +  'Q '+ positions.join(' ') + ' ' +  d.target.x + ' ' + d.target.y;
                        }
                    }
                });

                if (texts) {
                    texts.attr({
                        'x' : function(l) { return l.mid ? (l.mid[0] + (l.target.x + l.source.x) / 2) / 2 : (l.target.x + l.source.x) / 2 },
                        'y' : function(l) { return l.mid ? (l.mid[1] + (l.target.y + l.source.y) / 2) / 2 : (l.target.y + l.source.y) / 2 }
                    });
                }
            }

            nodes.attr({"cx":function(d){return d.x;},
                "cy":function(d){return d.y;}
            });

            nodelabels.attr("x", function(d) { return d.x; })
                .attr("y", function(d) { return d.y; });


            prevTick = tick;
        });
    };

    /**
     * Resets the minimap into the center with no zoom.
     * @param {Number=} focusIndex The message with this number shall become the center (rootMsg by default)
     */
    sMinimap.prototype.reset = function(focusIndex) {
        var $def    = $.Deferred()
        ;

        this.currentScale   = 1;

        focusIndex = focusIndex || 1;

        var msgToFocus = this.filteredMessages.filter(function(msg) {
            return msg.counter === focusIndex;
        }).pop();

        msgToFocus.fixed = true;

        var $svg = this.$element.find('svg');

        this.translate      = [-1 * Math.round(msgToFocus.x - $svg.width() / 2), -1 * Math.round(msgToFocus.y - $svg.height() / 2)];
        this.redraw();

        return $def;
    };

    Object.defineProperties(
        sMinimap,
        {
            EVENT_MODEL_CHANGED: {
                value: EVENT_MODEL_CHANGED
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sMinimap#EVENT_MODEL_CHANGED
                 * @type {String}
                 */
            }
        });

    ns.sMinimap = sMinimap;

})(Object.namespace('Controller.Component'));
