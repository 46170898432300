(function (ns) {
    /**
     * @namespace
     * @alias sAnalytics.Controller.Retention
     *
     * @param {Object} cohort
     * @param $location
     * @param $scope
     * @param $rootScope
     * @param {sAnalytics.Service.sTrace} sTrace
     * @constructor
     */
    var Retention = function (
        cohort,
        $location,
        $scope,
        $rootScope,
        sTrace
    ) {
        this.$scope             = $scope;
        this.$location          = $location;
        this.$rootScope         = $rootScope;
        this.chart              = cohort;
        this.$deRegister        = [];

        var defaultDateRange        = new Model.DateRange(),
            self                    = this,
            variant                 = 'relative',
            loadTimeOut             ,
            data
        ;

        defaultDateRange.from       = moment().subtract(1, 'months').startOf('day');
        defaultDateRange.to         = moment().endOf('day');

        Object.defineProperties(
            this,
            {
                variant: {
                    enumerable: true,
                    configurable: true,
                    get: function () {
                        return variant;
                    },
                    set: function (val) {
                        if (val === variant) {
                            return;
                        }
                        variant = val;
                        var $search = self.$location.search();
                        if ($search.variant !== variant) {
                            self.$location.search('variant', variant);
                        }
                    }
                    /**
                     * @property
                     * @name sAnalytics.Controller.Charts#variant
                     * @type {String}
                     */
                },
                data: {
                    enumerable: true,
                    get: function () {
                        return data;
                    }
                    /**
                     * @property
                     * @name sAnalytics.Controller.Retention#data
                     * @type {$.Deferred|Promise}
                     */
                }
            });

        this.filtersConfig = {
            time: {
                component: 'sDateRangePicker',
                template: '_component:s-dynamic-form-row',
                options: {
                    label: 'Date Range',
                    defaultValue: defaultDateRange,
                    asJson: function (key, value) {
                        if (key) {
                            return value;
                        }
                        var dateRange = new Model.DateRange();
                        dateRange.from = moment(value.from);
                        dateRange.to = moment(value.to);
                        return dateRange;
                    }
                }
            },
            interval: {
                component: 'sSelect',
                template: '_component:s-dynamic-form-row',
                options: {
                    label: 'Interval',
                    defaultValue: 'week',
                    attrs: {
                        'choices'       : '[{key: \'day\', label: \'Day\', value: 86400}, {key: \'week\', label: \'Week\', value: \'604800\'}]',
                        'class'         : 'interval-select',
                        'view-field'    : 'label',
                        'value-field'   : 'key',
                        'ng-required'   : true
                    }
                }
            }
        };

        var $search = $location.search();

        if ($search.variant) {
            this.variant = $search.variant;
        }

        $scope.$on('$destroy', this.$onDestroy.bind(this));

        this.$deRegister.push(this.$rootScope.$on('$locationChangeStart', function() {
            var $search = self.$location.search();

            if ($search.variant && $search.variant !== self.variant) {
                self.variant = $search.variant;
            }
        }));

        this.$deRegister.push(this.$scope.$watch(function() {
                return JSON.stringify(cohort.bag.filters) + '-' + self.variant;
            },
            /**
             * @param {String} val
             * @param {String} oldVal
             */
            function(val, oldVal) {
                if (val === oldVal) {
                    return;
                }

                if (loadTimeOut) {
                    clearTimeout(loadTimeOut);
                }

                loadTimeOut = setTimeout(function() {
                    data = $.Deferred();
                    digestIfNeeded($scope);
                    cohort.bag.load().then(function() {
                        data.resolve(sTrace.executeTraceFactory(cohort.traceFactory, cohort.bag.entries, cohort.bag.period, self.variant).shift().data);
                    });
                }, 50);
            }
        ));
    };

    Retention.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    ns.Retention = Retention;
})(Object.namespace('sAnalytics.Controller'));
