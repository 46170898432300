/**
 * Created by Gabor on 20-Feb-17.
 */

(function(ns) {
    /**
     * @namespace
     * @alias Service.sConfirm
     *
     * @param $mdDialog
     * @param $sce
     */
    var sConfirm = function ($mdDialog, $sce) {

        /**
         * @type $mdDialog
         * @name Service.sConfirm#$mdDialog
         */
        this.$mdDialog = $mdDialog;
        this.$sce = $sce;
    };

    /**
     * @name Service.sConfirm#open
     * @param _options
     * @returns {*}
     */
    sConfirm.prototype.open = function open(_options) {
        var $deferred = $.Deferred();

        var defaults = {
                confirm: 'Yes',
                decline: 'No',
                title: 'Confirmation required',
                content: '',
                ariaLabel: '',
                theme: 's-dialog',
                class: 's-dialog-small'
            },
            options = $.extend(true, {}, defaults, _options || {}),
            dialog
        ;

        if (options.decline) {
            dialog = this.$mdDialog.confirm()
                .cancel(options['decline']);
        } else {
            dialog = this.$mdDialog.alert();
        }

        dialog
            .title(options['title'])
            .htmlContent(this.$sce.trustAsHtml(options['content']))
            .ariaLabel(options['ariaLabel'])
            .ok(options['confirm'])
            .theme(options['theme'])
            .css(options['class'])
        ;

        this.$mdDialog.show(dialog).then(
            function () {
                $deferred.resolve.apply(this, arguments)
            },
            function () {
                $deferred.reject.apply(this, arguments)
            }
        );

        return $deferred.promise();
    };

    ns.sConfirm = sConfirm;
})(Object.namespace('Service'));
