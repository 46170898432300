(function (ns) {
    var KEY_EXPAND = 'expand';

    /**
     * @namespace
     * @alias Controller.Component.sMemberAttributeCollection
     *
     * @constructor
     * @param $scope
     */
    var sMemberAttributeCollection = function ($scope) {
        var self = this;
        this.panelEditConfig = {
            rules: {
                "!attribute": {
                    component: 's-member-attribute-select',
                    options: {
                        label: false,
                        attrs: {
                            'allow-new'     : function() { return self.editable === KEY_EXPAND; },
                            'is-required'   : true
                        }
                    }
                }
            },
            callback: function(newAttribute, editedAttribute) {
                var compare = function(a, b) {
                        return a.key === b.key && a.value === b.value;
                    },
                    sameKeyValueLength = this.attributes.filter(function(attribute) {
                        return compare(attribute, newAttribute);
                    }).length,
                    editedIndex = this.attributes.indexOf(editedAttribute)
                ;

                if (sameKeyValueLength && editedIndex === -1) {
                    return;
                }

                if (editedIndex !== -1) {
                    if (sameKeyValueLength) {
                        // edited, but combination exits, remove the one being updated
                        this.attributes.splice(editedIndex, 1);
                    } else {
                        this.attributes.splice(editedIndex, 1, newAttribute);
                    }
                    return;
                }
                this.attributes.push(newAttribute);
            }.bind(this),
            new: function() {
                return new Model.Member.CustomData();
            }
        };

        this.$scope = $scope;
    };


    ns.sMemberAttributeCollection = sMemberAttributeCollection;
})(Object.namespace('Controller.Component'));