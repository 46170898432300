(function(ns) {
    /**
     * @namespace
     * @alias Controller.Directive.AutoCompleter.Panel
     *
     * @param $scope
     * @constructor
     */
    var Panel = function ($scope) {
        var self            = this,
            selected        = 0,
            max             ,
            matches         ,
            loading         = false,
            panelDimensions = []
        ;

        this.$scope   = $scope;

        Object.defineProperties(
            this,
            {
                'selected' : {
                    set: function(val) {
                        if (val >= max) {
                            selected = 0;
                        } else if (val < 0) {
                            selected = max - 1;
                        } else {
                            selected = val;
                        }
                    },
                    get: function() {
                        if (selected >= 0 && selected < max) {
                            return selected;
                        } else {
                            return 0;
                        }
                    }
                }
            }
        );

        this.$deRegister = [];

        this.$deRegister.push(
            $scope.$watch(
                function() {
                    // should tolerate +/- 1px in both dimensions
                    return Math.round(self.mdPanelRef.panelEl.width() / 3) + '-' + Math.round(self.mdPanelRef.panelEl.height() / 3);
                },
                function(val) {
                    // only prevent loops where the value is not the preceding one
                    if (panelDimensions.indexOf(val) !== panelDimensions.length - 1) {
                        panelDimensions.splice(0, panelDimensions.length);
                        return;
                    }
                    panelDimensions.push(val);
                    $scope.$evalAsync(function(){
                        self.mdPanelRef._updatePosition();
                    });
                }
            )
        );

        this.$deRegister.push(
            $scope.$on(Controller.Directive.AutoCompleter.EVENT_UPDATE_RESULTS,
                /**
                 * @param event
                 * @param {{matches: Array, hint: string}} payload
                 */
                function(event, payload) {
                    self.matches = payload.matches;
                    self.hint = payload.hint;
                    setTimeout(digestIfNeeded.bind(null, $scope), 10);
                })
        );

        this.$deRegister.push($scope.$on(Controller.Directive.AutoCompleter.EVENT_PARENT_KEYDOWN, function(ev, data) {
            self.onKeyDown(data)
        }));

        this.$deRegister.push($scope.$on('$destroy', this.$onDestroy.bind(this)));

        Object.defineProperties(
            this,
            {
                matches: {
                    enumerable: true,
                    configurable: true,
                    get: function () {
                        return matches;
                    },
                    set: function (val) {
                        if (val && val.then) {
                            loading = true;
                            digestIfNeeded($scope);
                            val.then(function(resolved) {
                                self.matches = resolved;
                            }).always(function () {
                                loading = false;
                                digestIfNeeded($scope);
                            });
                            return;
                        }

                        if (val) {
                            Object.instanceOf(val, Array);
                        }

                        if (val === matches) {
                            return;
                        }
                        matches = val;
                        max = matches.length;
                    }
                    /**
                     * @property
                     * @name Controller.Directive.AutoCompleter.Panel#matches
                     * @type {[]|$.Deferred}
                     */
                },
                loading: {
                    enumerable: true,
                    get: function () {
                        return loading;
                    }
                    /**
                     * @property
                     * @name Controller.Directive.AutoCompleter.Panel#loading
                     * @type {Boolean}
                     */
                }
            });
    };
    
    Panel.prototype.$onInit = function $onInit() {
        var self = this,
            $unWatchPanelEl = this.$scope.$watch(function() {
                return self.mdPanelRef.panelEl;
            }, function(value) {
                if (!value) {
                    return;
                }
                self.bindEventListeners(value);
                $unWatchPanelEl();
            });
    };

    Panel.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    Panel.prototype.bindEventListeners = function bindEventListeners($element) {
        this.$deRegister = this.$deRegister
            .concat($element.$on('keydown', this.onKeyDown.bind(this)))
        ;
    };

    /**
     * @name Controller.Directive.AutoCompleter.Panel#selectElement
     * @param index
     */
    Panel.prototype.selectElement = function selectElement(index) {
        this.selected = parseInt(index);
    };

    /**
     * @name Controller.Directive.AutoCompleter.Panel#useElement
     * @param  {Object} element
     */
    Panel.prototype.useElement = function useElement(element) {
        this.mdPanelRef.result = element;
        this.mdPanelRef.close(true);
    };

    /**
     * @name Controller.Directive.AutoCompleter.Panel#onKeyDown
     * @param originalEvent
     */
    Panel.prototype.onKeyDown = function onKeyDown(originalEvent) {
        var selected = this.selected;
        if (!originalEvent.shiftKey) {
            switch (originalEvent.which) {
                case 37:
                case 38:
                    this.selected--;
                    break;
                case 9:
                case 39:
                case 40:
                    this.selected++;
                    break;
                case 13:
                    this.useElement(this.matches[this.selected]);
                    return;
            }
        } else if (originalEvent.which === 9) {
            this.selected--;
        }

        if (selected !== this.selected) {
            digestIfNeeded(this.$scope);
            setTimeout(function() {
                var $el = $('.selected', this.mdPanelRef.panelEl);
                if (!$el.inScrolledView()) {
                    $el[0].scrollIntoView(selected > this.selected);
                }
            }.bind(this), 5);
        }
    };

    ns.Panel = Panel;
})(Object.namespace('Controller.Directive.AutoCompleter'));
