(function (ns) {
    /**
     * @namespace
     * @alias sSettings.Route.DefaultReactions
     * @extends sSettings.Route.Base
     * @constructor
     */
    var DefaultReactions = function DefaultReactions() {
        this.controller     = Controller.sBotReaction.Index;
        this.templateUrl    = 'sbotreaction:index';
        this.controllerAs   = '$ctrl';
        this.accessControl  = {'defaultReaction.byDomain.all': Const.Method.GET};

        DefaultReactions._pProto.constructor.apply(this, arguments);
    };

    Object.extendProto(DefaultReactions, ns.Base);

    /**
     * @function
     * @name sSettings.Route.DefaultReactions#resolveReactions
     * @param {Service.sDomain} sDomainService
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @returns {$.Deferred}
     */
    DefaultReactions.prototype.resolveReactions = function (sDomainService, sAPIAccess) {
        return sDomainService.getCurrentDomainId().then(function(currentDomainId) {
            return Model.AI.Reaction.retrieve(
                sAPIAccess.endpoint('defaultReaction.byDomain.all').get(currentDomainId)
            );
        });
    };

    ns.DefaultReactions = DefaultReactions;
})(Object.namespace('sSettings.Route'));