(function(ns) {
    /**
     * @namespace
     * @alias sContentFeed.Controller.Overview
     * @constructor
     *
     * @param $scope
     * @param $rootScope
     * @param $location
     * @param {sSegment.Service.sSegment} sSegment
     * @param Notification
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {Service.sDomain} sDomainService
     */
    var Overview = function (
        $scope,
        $rootScope,
        $location,
        sSegment,
        Notification,
        sAPIAccess,
        sDomainService
    ) {
        var self            = this,
            listEndpoint    = sAPIAccess.endpoint('segment.byDomain.all').get(sDomainService.currentDomainId)
        ;

        this.$scope             = $scope;
        this.sSegmentService    = sSegment;
        this.notification       = Notification;
        this.sAPIAccess         = sAPIAccess;
        this.$location          = $location;
        this.$deRegister        = [];

        $scope.$on('$destroy', this.$onDestroy.bind(this));

        this.list = this.getList(listEndpoint);

        // initial check
        this.checkLocationSearch();

        // open popup to create new segment by search-string
        this.$deRegister.push($rootScope.$on('$locationChangeSuccess',
            function() {
                self.checkLocationSearch();
            }
        ));
    };

    /**
     * @function
     * @name sSegment.Controller.Overview#$onDestroy
     */
    Overview.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name sSegment.Controller.Overview#checkLocationSearch
     */
    Overview.prototype.checkLocationSearch = function checkLocationSearch() {
        if (this.$location.search().create) {
            this.$location.search('create', null);
            this.createSegment();
        }
    };

    /**
     * @function
     * @name sSegment.Controller.Overview#getList
     * @param {string} listEndpoint
     * @returns {Model.List}
     */
    Overview.prototype.getList = function getList(listEndpoint) {
        var column,
            list = new Model.List.RESTAccess(Model.Segment, listEndpoint)
        ;

        column = list.columns.createAndAdd('name');
        column.isSortable = true;
        column.label = 'Name';

        column = list.columns.createAndAdd('typeLabel');
        column.label = 'Type';

        column = list.columns.createAndAdd('activeMembers');
        column.label = 'Active Users';

        column = list.columns.createAndAdd('timeCreated');
        column.isSortable = true;
        column.label = 'Created';

        column = list.columns.createAndAdd('timeUpdated');
        column.isSortable = true;
        column.label = 'Updated';

        column = list.columns.createAndAdd('status');
        column.isSortable = true;
        column.label = 'Status';

        list.actions = {};
        if(this.sAPIAccess.isAllowed('segment.RESTAccess', Const.Method.PUT)) {
            list.actions['edit'] = new Model.Menu.Action('edit', this.editSegment.bind(this));
        }
        if(this.sAPIAccess.isAllowed('segment.activate', Const.Method.PUT)) {
            list.actions['activate'] = new Model.Menu.Action('activate', this.activateSegment.bind(this));
        }
        if(this.sAPIAccess.isAllowed('segment.deactivate', Const.Method.PUT)) {
            list.actions['deactivate'] = new Model.Menu.Action('deactivate', this.deactivateSegment.bind(this));
        }
        if(this.sAPIAccess.isAllowed('segment.RESTAccess', Const.Method.DELETE)) {
            list.actions['delete'] = new Model.Menu.Action('delete', this.deleteSegment.bind(this));
        }

        if (Object.keys(list.actions).length) {
            column = list.columns.createAndAdd('options');
            column.label = 'Options';
        }

        return list;
    };

    /**
     * @function
     * @name sSegment.Controller.Overview#createSegment
     */
    Overview.prototype.createSegment = function createSegment() {
        var self = this;
        this.sSegmentService.createSegment().then(
            function () {
                self.notification.success('Successfully created.');
                self.list.load(false, true);
            },
            function (response) {
                if (response) {
                    self.notification.error('Creation failed.');
                }
            }
        );
    };

    /**
     * @function
     * @name sSegment.Controller.Overview#editSegment
     * @param {Model.Segment} segment
     */
    Overview.prototype.editSegment = function editSegment(segment) {
        var self = this;
        this.sSegmentService.editSegment(segment).then(
            function () {
                self.notification.success('Successfully changed.');
                self.list.load(false, true);
            },
            function (response) {
                if (response) {
                    self.notification.error('Change failed.');
                }
            }
        );
    };

    /**
     * @function
     * @name sSegment.Controller.Overview#activateSegment
     * @param {Model.Segment} segment
     * @return {$.Deferred}
     */
    Overview.prototype.activateSegment = function activateSegment(segment) {
        var self = this,
            trackingData = { 
                title: segment.name,
                status: 'active',
                type: segment.type
            }
        ;

        return segment
            .activate()
            .then(
                function () {
                    self.notification.success('Successfully activated.');
                    self.list.load(false, true);
                    self.$scope.$emit('sSegmentStatusChanged', trackingData);
                },
                function () {
                    self.notification.error('Activation failed.');
                }
            );
    };

    /**
     * @function
     * @name sSegment.Controller.Overview#deactivateSegment
     * @param {Model.Segment} segment
     * @return {$.Deferred}
     */
    Overview.prototype.deactivateSegment = function deactivateSegment(segment) {
        var self = this,
            trackingData = { 
                title: segment.name,
                status: 'deactivated',
                type: segment.type
            }
        ;

        return segment
            .deactivate()
            .then(
                function () {
                    self.notification.success('Successfully deactivated.');
                    self.list.load(false, true);
                    self.$scope.$emit('sSegmentStatusChanged', trackingData);
                },
                function () {
                    self.notification.error('Deactivation failed.');
                }
            );
    };

    /**
     * @function
     * @name sSegment.Controller.Overview#deleteSegment
     * @param {Model.Segment} segment
     * @return {$.Deferred}
     */
    Overview.prototype.deleteSegment = function deleteSegment(segment) {
        var self = this;

        return this.sSegmentService.deleteSegment(segment).then(
            function () {
                self.notification.success('Successfully deleted.');
                self.list.load(false, true);
            },
            function (response) {
                if (response) {
                    self.notification.error('Deletion failed.');
                }
            }
        );
    };

    ns.Overview = Overview;
})(Object.namespace('sSegment.Controller'));
