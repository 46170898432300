(function (ns) {
    /**
     * @namespace
     * @alias Controller.Component.View.sMatchTextCollection
     * @constructor
     */
    var sMatchTextCollection = function () {
        /**
         * @property
         * @name Component.Controller.View.sMatchTextCollection#model
         * @type {Object}
         */

        /**
         * @property
         * @name Component.Controller.View.sMatchTextCollection#highlightMatchText
         * @type {Model.AI.MatchText}
         */
    };

    /**
     * @param {Model.AI.MatchText} matchText
     * @return {Boolean}
     */
    sMatchTextCollection.prototype.isHighlighted = function isHighlighted(matchText) {
        if (!this.highlightMatchText || typeof this.highlightMatchText.equals !== 'function') {
            return false;
        }

        return this.highlightMatchText.equals(matchText);
    };

    ns.sMatchTextCollection = sMatchTextCollection;
})(Object.namespace('Controller.Component.View'));
