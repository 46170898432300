(function(ns){
    /**
     * @namespace
     * @alias Model.Time
     *
     * @constructor
     */
    var Time = function() {
        var self        = this,
            momentObj   = moment(),
            hours,
            minutes,
            seconds,
            timezoneOffset,
            inSeconds
        ;

        function calcInSeconds() {
            inSeconds = parseInt(self.hours) * 3600
                + parseInt(self.minutes)  * 60
                + parseInt(self.seconds)
            ;
        }

        Object.defineProperties(this, {
            time: {
                get: function () {
                    return momentObj.format("HH:mm");
                },
                set: function(value) {
                    var timeParts = Time.extractTimeParts(value);
                    self.hours = timeParts.hours;
                    self.minutes = timeParts.minutes;
                    self.seconds = timeParts.seconds;
                    calcInSeconds();
                }
            },
            hours: {
                enumerable: true,
                get: function () {
                    return hours;
                },
                set: function (value) {
                    momentObj.hours(value);
                    hours = momentObj.format("HH");
                    calcInSeconds();
                }
            },
            minutes: {
                enumerable: true,
                get: function () {
                    return minutes;
                },
                set: function (value) {
                    momentObj.minutes(value);
                    minutes = momentObj.format("mm");
                    calcInSeconds()
                }
            },
            seconds: {
                enumerable: true,
                get: function () {
                    return seconds;
                },
                set: function (value) {
                    momentObj.seconds(value);
                    seconds = momentObj.format("ss");
                    calcInSeconds();
                }
            },
            timezoneOffset: {
                enumerable: true,
                get: function () {
                    return timezoneOffset;
                },
                set: function (value) {
                    momentObj.utcOffset(value);
                    timezoneOffset = momentObj.format("Z");
                }
            },
            timezoneOffsetInSeconds: {
                enumerable: true,
                get: function () {
                    return momentObj.utcOffset() * 60;
                }
                /**
                 * @property
                 * @name Model.Time#timezoneOffsetInSeconds
                 * @type {Number}
                 */
            },
            inSeconds: {
                enumerable: true,
                configurable: true,
                get: function () {
                    return inSeconds;
                },
                set: function (val) {
                    var incoming = val;
                    if (typeof(val) !== 'number') {
                        val = parseInt(val);
                    }

                    if (isNaN(val) || String(val) !== incoming) {
                        inSeconds = incoming;
                        return;
                    }

                    if (val === inSeconds) {
                        return;
                    }

                    self.seconds = val % 60;
                    self.minutes = Math.floor(val % 3600 / 60);
                    self.hours   = Math.floor(val / 3600);

                    inSeconds = val;
                }
                /**
                 * @property
                 * @name Model.Time#inSeconds
                 * @type {Number}
                 */
            },
            isoTime: {
                enumerable: true,
                get: function () {
                    return momentObj.format('HH:mm:ssZ');
                }
            }
        });

        /**
         * @name Model.Time#getISOTime
         * @return {String}
         */
        this.getISOTime = function getISOTime() {
            return self.isoTime;
        };

        /**
         * @function
         * @name Model.Time#valueOf
         * @return {Number}
         */
        this.valueOf = function valueOf() {
            return momentObj.valueOf();
        };

        // init
        this.hours          = momentObj.hours();
        this.minutes        = momentObj.minutes();
        this.seconds        = momentObj.seconds();
        this.timezoneOffset = momentObj.format('Z');

        calcInSeconds();
    };

    /**
     * @name Model.Time#toJSON
     * @returns {String}
     */
    Time.prototype.toJSON = function toJSON() {
        return this.getISOTime();
    };

    /**
     * @static
     * @method Model.Time#extractTimeParts
     * @param string
     * @returns {{hours: number, minutes: number, seconds: number}}
     */
    Time.extractTimeParts = function extractTimeParts(string) {
        var expression = "^(\d{1,2}):(\d{1,2})$",
            regex = new RegExp(expression, 'i'),
            matches,
            returnObj = {
                hours: 0,
                minutes: 0,
                seconds: 0
            };

        matches = string.match(regex);

        if (matches && matches[1]) {
            returnObj.hours = matches[1];
        }

        if (matches && matches[2]) {
            returnObj.minutes = matches[2];
        }

        return returnObj;
    };

    /**
     * @static
     * @name Model.Time#create
     * @param {Number} hours
     * @param {Number} minutes
     * @param {Number} seconds
     * @param {string} timezoneOffset
     * @return {Model.Time}
     */
    Time.create = function create(hours, minutes, seconds, timezoneOffset) {
        var time = new Time();

        time.hours          = hours || 0;
        time.minutes        = minutes || 0;
        time.seconds        = seconds || 0;
        time.timezoneOffset = timezoneOffset || moment().format('Z');

        return time;
    };

    /**
     * @static
     * @name Model.Time#fromISOTime
     * @param {String} isoTimeString
     * @returns {Model.Time}
     */
    Time.fromISOTime = function fromISOTime(isoTimeString) {
        var tmpMomentObj = moment(
                [
                    moment().format('YYYY-MM-DD'),
                    isoTimeString
                ].join(' ')
            )
        ;

        return Time.create(
            tmpMomentObj.hours(),
            tmpMomentObj.minutes(),
            tmpMomentObj.seconds(),
            tmpMomentObj.format('Z')
        );
    };

    ns.Time = Time;
})(Object.namespace('Model'));
