/**
 * Created by Gabor on 17-Oct-17.
 */

(function(ns) {

var sSideNavigation = ns.sSideNavigation = function($rootScope) {
    var self = this;

    this.$deRegister = [];

    this.$deRegister.push($rootScope.$on('nav-item-active',
        /**
         * @param event
         * @param {Model.Menu.Menu} item
         */
        function(event, item) {
            Object.instanceOf(item, Model.Menu.Menu);
            var menu = new Model.Menu.Menu();
            self.menu = menu.merge(item);
    }));
};

sSideNavigation.prototype.$onDestroy = function $onDestroy() {
    var $destroyFn;

    while(($destroyFn = this.$deRegister.pop())) {
        $destroyFn();
    }
};

})(Object.namespace('sNavigation.Controller.Component'));
