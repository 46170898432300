(function (ns) {
    /**
     * @namespace
     * @alias sFacebook.Controller.Component.sFacebookPageSelect
     *
     * @constructor
     * @param {sFacebook.Service.sFacebookPage} sFacebookPage
     */
    var sFacebookPageSelect = function (sFacebookPage) {
        this.sFacebookPage = sFacebookPage;
    };

    /**
     * @return {$.Deferred<Model.Sender.FacebookPage[]>}
     */
    sFacebookPageSelect.prototype.getChoices = function getChoices() {
        return this.sFacebookPage.loadAll(['isAvailable', 'isBusinessIntegrated', 'isPublished']);
    };

    ns.sFacebookPageSelect = sFacebookPageSelect;
})(Object.namespace('sFacebook.Controller.Component'));
