(function (ns) {
    /**
     * @namespace
     * @alias sEntity.Controller.Edit
     *
     * @param $scope
     * @param Notification
     * @param $mdDialog
     */
    var Edit = function ($scope, Notification, $mdDialog) {
        var hasActiveSaving  = false;
        this.$scope          = $scope;
        this.Notification    = Notification;
        this.$mdDialog       = $mdDialog;
        this.newKeyword      = null;
        this.entityHasLength = this.entity.hasLength;

        Object.defineProperties(
            this,
            {
                hasActiveSaving : {
                    enumerable : true,
                    get        : function () {
                        return hasActiveSaving;
                    }
                    /**
                     * @property
                     * @name sEntity.Controller.Edit#hasActiveSaving
                     * @type {$.Deferred|null}
                     */
                }
            });

        this.save = function save() {
            hasActiveSaving = this.entity
                .save()
                .then(
                    function () {
                        this.$mdDialog.hide(this.entity);
                        $scope.$emit('sEntitySaved', this.entity);
                    }.bind(this),
                    function () {
                        this.Notification.error('An error occurred while saving the Entity, please try again.');
                    }.bind(this)
                ).always(function () {
                    hasActiveSaving = null;
                    digestIfNeeded($scope);
                })
                ;
        };
    };

    Edit.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    /**
     * @return {String[]}
     */
    Edit.prototype.getEntityTypes = function getEntityTypes() {
        return Model.AI.Entity.getTypesAsArray();
    };

    Edit.prototype.addKeyword = function addKeyword() {
        if (!this.newKeyword || this.entity.type !== Model.AI.Entity.TYPE_KEY_KEYWORD) {
            return;
        }

        this.entity.addKeyword(this.newKeyword);
        this.newKeyword = null;
    };

    /**
     * @param {Number} index
     */
    Edit.prototype.removeKeyword = function removeKeyword(index) {
        if (index < 0 || this.entity.type !== Model.AI.Entity.TYPE_KEY_KEYWORD || this.entity.typeOptions.length <= index) {
            return;
        }

        this.entity.typeOptions.splice(index, 1);
    };

    ns.Edit = Edit;
})(Object.namespace('sEntity.Controller'));
