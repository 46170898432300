(function(angular) {

    var endpointMapping = {
        // action
        'action.byDomain.all': {
            get: 'GET_domain_domainId_action_definition_'
        },
        'action.memberAttributes': {
            get: 'GET_action_member_attributes_',
            post: 'POST_action_member_attributes_'
        },
        'action.memberAttributes.value': {
            get: 'GET_action_member_attributes_name_'
        },
        'action.notificationLabels': {
            get: 'GET_action_notification_labels_',
            post: 'POST_action_notification_labels_'
        },
        'ai.similarWords': {
            get: 'GET_ai_similar_words'
        },
        // analytics
        'analytics.byDomain.chartData': {
            get: 'GET_domain_domainId_analytics_chartIdent'
        },
        'analytics.byDomain.chartDataAggregated': {
            get: 'GET_domain_domainId_analytics_chartIdent_aggregated'
        },
        'analytics.byDomain.getCampaign': {
            get: 'GET_domain_domainId_analytics_campaigns'
        },
        'analytics.timeSeries' : {
            get: 'GET_analytics_timeseries_type_identifier_'
        },
        // api
        'api.list': {
            get: 'apiList'
        },
        // automation
        'automation.all': {
            get: 'GET_automation_'
        },
        'automation.event.all': {
            get: 'GET_automation_events_'
        },
        'automation.RESTAccess': {
            get: 'GET_automation_uuid',
            post: 'POST_automation_',
            put: 'PUT_automation_uuid',
            delete: 'DELETE_automation_uuid'
        },
        'automation.activate': {
            put: 'PUT_automation_uuid_activate'
        },
        'automation.deactivate': {
            put: 'PUT_automation_uuid_deactivate'
        },
        'automation.analytics' : {
            get: 'GET_automation_uuid_analytics'
        },
        //content
        'content.parse': {
            get: 'GET_message_content_parse'
        },
        //contentFeed
        'contentFeed.byDomain.all': {
            get: 'GET_domain_domainId_content_feed_'
        },
        'contentFeed.byDomain.toConversation': {
            get: 'GET_domain_domainId_content_feed_to_conversation_'
        },
        'contentFeed.setProcessed': {
            put: 'PUT_content_feed_uuid_set_processed'
        },
        //defaultReaction
        'defaultReaction.byDomain.all': {
            get: 'GET_reaction_by_domain_domainId_bot_defaults'
        },
        // domain
        'domain.RESTAccess': {
            get: 'GET_domain_domainId'
        },
        'domain.getAll': {
            get: 'GET_domain_get_all'
        },
        'domain.domainData.RESTAccess': {
            get: 'GET_domain_domainId_data_key',
            post: 'POST_domain_domainId_data_key',
            put: 'PUT_domain_domainId_data_key',
            delete: 'DELETE_domain_domainId_data_key'
        },
        'domain.domainData.fuzzyMatchThreshold': {
            get: 'GET_domain_domainId_data_fuzzy_match_threshold',
            post: 'POST_domain_domainId_data_fuzzy_match_threshold',
            put: 'PUT_domain_domainId_data_fuzzy_match_threshold'
        },
        'domain.domainMeta.all': {
            get: 'GET_domain_hid_meta'
        },
        'domain.domainMeta.RESTAccess': {
            get: 'GET_domain_hid_meta_key',
            post: 'POST_domain_hid_meta',
            put: 'PUT_domain_hid_meta_key'
        },
        'domain.domainMeta.RESTAccess.facebookPersistentMenu': {
            get: 'GET_domain_hid_meta_facebook_persistent_menu',
            post: 'POST_domain_hid_meta_facebook_persistent_menu',
            put: 'PUT_domain_hid_meta_facebook_persistent_menu'
        },
        'domain.domainMeta.RESTAccess.facebookGetStarted': {
            get: 'GET_domain_hid_meta_facebook_get_started',
            post: 'POST_domain_hid_meta_facebook_get_started',
            put: 'PUT_domain_hid_meta_facebook_get_started'
        },
        'domain.domainMeta.RESTAccess.facebookGreeting': {
            get: 'GET_domain_hid_meta_facebook_greeting',
            post: 'POST_domain_hid_meta_facebook_greeting',
            put: 'PUT_domain_hid_meta_facebook_greeting'
        },
        'domain.feature.list': {
            get: 'GET_domain_domainId_feature'
        },
        'domain.feature.relate': {
            post: 'POST_domain_domainId_feature_featureUuid',
            delete: 'DELETE_domain_domainId_feature_featureUuid'
        },
        'domain.plan.relate': {
            post: 'POST_domain_domainId_plan_planUuid'
        },
        'domain.logo': {
            post: 'POST_domain_logo_'
        },
        'entity.RESTAccess' : {
            get:    'GET_entity_',
            post:   'POST_entity_',
            put:    'PUT_entity_uuid'
        },
        'entity.management' : {
            get:    'GET_entity_management_'
        },
        //entryPoint
        'entryPoint.RESTAccess' : {
            get  : 'GET_entry_point_',
            post : 'POST_entry_point_',
            put  : 'PUT_entry_point_uuid'
        },
        //facebook
        'facebook.adAccount.all': {
            get: 'GET_facebook_ad_account_'
        },
        'facebook.adAccount.connect': {
            put: 'PUT_facebook_ad_account_senderId_connect'
        },
        'facebook.adAccount.skeleton': {
            get: 'GET_facebook_ad_account_skeleton'
        },
        'facebook.adAccount.synchronize': {
            put: 'PUT_facebook_ad_account_senderId_synchronize'
        },
        'facebook.adAccount.RESTAccess': {
            get: 'GET_facebook_ad_account_senderId',
            post: 'POST_facebook_ad_account_',
            put: 'PUT_facebook_ad_account_senderId',
            delete: 'DELETE_facebook_ad_account_senderId'
        },
        'facebook.member.handover' : {
            get: 'GET_facebook_member_memberId_thread_control_info',
            post: 'POST_facebook_member_memberId_take_thread_control'
        },
        'facebook.page.all': {
          get: 'GET_facebook_page_'
        },
        'facebook.page.synchronize': {
            put: 'PUT_facebook_page_senderId_synchronize'
        },
        'facebook.page.connect': {
            put: 'PUT_facebook_page_senderId_connect'
        },
        'facebook.page.disable': {
            put: 'PUT_facebook_page_senderId_disable'
        },
        'facebook.page.enable': {
            put: 'PUT_facebook_page_senderId_enable'
        },
        'facebook.page.skeleton': {
            get: 'GET_facebook_page_skeleton'
        },
        'facebook.page.RESTAccess': {
            get: 'GET_facebook_page_senderId',
            post: 'POST_facebook_page_',
            put: 'PUT_facebook_page_senderId',
            delete: 'DELETE_facebook_page_senderId'
        },
        //feature
        'feature.all': {
            get: 'GET_feature_'
        },
        //marker
        'marker.RESTAccess': {
            get    : 'GET_marker_',
            post   : 'POST_marker_',
            put    : 'PUT_marker_uuid',
            delete : 'DELETE_marker_uuid'
        },
        //member
        'member.RESTAccess': {
            get: 'GET_member_hMemberId',
            delete: 'DELETE_member_hMemberId'
        },
        'member.customData': {
            get: 'GET_member_hMemberId_custom_data',
            post: 'POST_member_hMemberId_custom_data',
            delete: 'DELETE_member_hMemberId_custom_data_hId'
        },
        'member.attribute': {
            get: 'GET_member_attribute',
            put: 'PUT_member_attribute'
        },
        'member.attribute.values': {
            get: 'GET_member_attribute_key'
        },
        'member.botDisableUntil': {
            get: 'GET_member_hMemberId_bot_disable_until',
            post: 'POST_member_hMemberId_bot_disable_until',
            delete: 'DELETE_member_hMemberId_bot_disable_until'
        },
        'member.blockStatus': {
            get: 'GET_member_hMemberId_block_status',
            post: 'POST_member_hMemberId_block_status',
            delete: 'DELETE_member_hMemberId_block_status'
        },
        'member.count' : {
            get: 'GET_member_count'
        },
        'member.list' : {
            get: 'GET_member_list'
        },
        'member.export' : {
            get: 'GET_member_export'
        },
        // message
        'message.RESTAccess' : {
            post: 'POST_message_',
            put: 'PUT_message_uuid',
            delete: 'DELETE_message_uuid'
        },
        'message.complete': {
            get: 'message.route.message.get_complete'
        },
        'message.validateAsSponsored' : {
            post: 'POST_message_is_valid_as_sponsored'
        },
        //messageAnchor
        'messageAnchor.RESTAccess' : {
            delete: 'DELETE_message_anchor_uuid'
        },
        'messageAnchor.byDomain.all': {
            get: 'GET_domain_domainId_message_anchor_'
        },
        'messageAnchor.duplicate': {
            post: 'POST_message_anchor_uuid_duplicate'
        },
        'messageAnchor.duplicateMultiple': {
            get: '',
            post: 'POST_message_anchor_duplicate'
        },
        'messageAnchor.updateArchivedState': {
            put: 'PUT_message_anchor_uuid_archive_archive'
        },
        'messageAnchor.byMessage': {
            get: 'GET_message_anchor_by_message_uuid'
        },
        'messageAnchor.usages': {
            get: 'GET_message_anchor_uuid_usages'
        },
        'messageAnchor.send': {
            put: 'PUT_message_anchor_uuid_send'
        },
        'messageAnchor.sendAsOneTimeNotification': {
            put: 'PUT_message_anchor_uuid_sendAsOneTimeNotification'
        },
        'messageAnchor.sendAsSponsored': {
            put: 'PUT_message_anchor_uuid_sendAsSponsored'
        },
        'messageAnchor.sendMultiple': {
            post: 'POST_message_anchor_send'
        },
        'messageAnchor.publish': {
            put: 'PUT_message_anchor_uuid_publish'
        },
        'messageAnchor.analytics': {
            get: 'GET_message_anchor_uuid_analytics'
        },
        'messageAnchor.analytics.versions': {
            get: 'GET_message_anchor_uuid_analytics_versions'
        },
        //messageIncoming
        'messageIncoming.all': {
            get: 'GET_message_incoming_'
        },
        'messageIncoming.feedback.all': {
            get: 'GET_message_incoming_feedback_member_'
        },
        'messageIncoming.matched.all': {
            get: 'GET_message_incoming_matched_'
        },
        'messageIncoming.matched.markDone': {
            put: 'PUT_message_incoming_matched_messageId_processed'
        },
        'messageIncoming.RESTAccess': {
            put: 'PUT_message_incoming_messageId'
        },
        'messageIncoming.unmatched.all': {
            get: 'GET_message_incoming_unmatched_'
        },
        'messageIncoming.unmatched.markDone': {
            put: 'PUT_message_incoming_unmatched_messageId_processed'
        },
        //messageLog
        'messageLog.byMember': {
            get: 'GET_message_log_member_memberId'
        },
        'messageLog.bySegment': {
            get: 'GET_message_log_channel_channelId'
        },
        //permission
        'permission.all': {
            get: 'GET_security_permission_'
        },
        //plan
        'plan.RESTAccess' : {
            post   : 'POST_plan_',
            put    : 'PUT_plan_uuid',
            delete : 'DELETE_plan_uuid'
        },
        'plan.all': {
            get: 'GET_plan_'
        },
        'plan.featureToggle': {
            post: 'POST_plan_planUuid_feature_featureUuid',
            delete: 'DELETE_plan_planUuid_feature_featureUuid'
        },
        //reaction
        'reaction.RESTAccess': {
            get: 'GET_reaction_reactionId',
            post: 'POST_reaction_',
            put: 'PUT_reaction_reactionId',
            delete: 'DELETE_reaction_reactionId'
        },
        //role
        'role.RESTAccess': {
            get: 'GET_security_role_uuid',
            post: 'POST_security_role_',
            put: 'PUT_security_role_uuid',
            delete: 'DELETE_security_role_uuid'
        },
        'role.all': {
            get: 'get.security.role.list'
        },
        'role.permissionToggle': {
            post: 'POST_security_role_roleUuid_permission_permissionUuid',
            delete: 'DELETE_security_role_roleUuid_permission_permissionUuid'
        },
        //messageSchedule
        'messageSchedule.bySegment': {
            get: 'GET_message_schedule_channel_channelId'
        },
        'messageSchedule.RESTAccess': {
            get: 'GET_message_schedule_hId',
            delete: 'DELETE_message_schedule_hId'
        },
        //security
        'security.authUser': {
            get: 'GET_security_auth_user'
        },
        'security.authenticate': {
            get: '',
            post: 'POST_auth_',
            put: '',
            delete: ''
        },
        'security.logout': {
            get: 'GET_auth_logout',
            post: 'POST_auth_logout'
        },
        'security.forgotPassword': {
            post: 'POST_auth_forgot_password'
        },
        'security.resetPassword': {
            get: 'GET_auth_reset_password',
            post: 'POST_auth_reset_password'
        },
        // segment
        'segment.byDomain.all': {
            get: 'GET_domain_domainId_channel_'
        },
        'segment.byDomain.selectable': {
            get: 'GET_domain_domainId_channel_selectable'
        },
        'segment.RESTAccess': {
            get: 'GET_channel_channelId',
            post: 'POST_channel_',
            put: 'PUT_channel_channelId',
            delete: 'DELETE_channel_channelId'
        },
        'segment.activate': {
            put: 'PUT_channel_channelId_activate'
        },
        'segment.deactivate': {
            put: 'PUT_channel_channelId_deactivate'
        },
        'segment.definitions' : {
            get: 'GET_channel_condition_definition_'
        },
        // Sender
        'sender.instagramDirect.all': {
            get: 'GET_sender_instagram_direct_'
        },
        'sender.instagramDirect.connect': {
            put: 'PUT_sender_instagram_direct_senderId_connect'
        },
        'sender.instagramDirect.disable': {
            put: 'PUT_sender_instagram_direct_senderId_disable'
        },
        'sender.instagramDirect.enable': {
            put: 'PUT_sender_instagram_direct_senderId_enable'
        },
        'sender.instagramDirect.RESTAccess': {
            get: 'GET_sender_instagram_direct_senderId',
            post: 'POST_sender_instagram_direct_',
            put: 'PUT_sender_instagram_direct_senderId',
            delete: 'DELETE_sender_instagram_direct_senderId'
        },
        'sender.instagramDirect.skeleton': {
            get: 'GET_sender_instagram_direct_skeleton'
        },
        'sender.instagramDirect.synchronize': {
            put: 'PUT_sender_instagram_direct_senderId_synchronize'
        },
        'sender.WhatsApp.messageTemplate': {
            get: 'GET_sender_whatsapp_messages_templates'
        },
        // Source
        'source.RESTAccess' : {
            get    : 'GET_source_',
            post   : 'POST_source_',
            put    : 'PUT_source_sourceId',
            delete : 'DELETE_source_sourceId'
        },
        'source.clear' : {
            put    : 'PUT_source_sourceId_clear'
        },
        'source.refresh' : {
            put    : 'PUT_source_sourceId_refresh'
        },
        'source.productTemplate' : {
            get    : 'GET_source_product_template_'
        },
        'source.global': {
            get: 'GET_source_global'
        },
        'source.byType': {
            get: 'GET_source_byType_sourceTypeId'
        },
        'source.content.all': {
            get: 'GET_source_sourceId_content_'
        },
        'source.content.export': {
            get: 'GET_source_sourceId_content_export'
        },
        'source.type.all': {
            get: 'GET_source_type_'
        },
        'source.condition': {
            get: 'GET_source_sourceId_condition'
        },
        'source.fieldsAsAttributes' : {
            get: 'GET_source_attribute_'
        },
        'source.column.values' : {
            get: 'GET_source_column_values_'
        },
        //subscription
        'subscription.RESTAccess': {
            post: 'POST_subscription_',
            delete: 'DELETE_subscription_hSubscriptionId'
        },
        'subscription.all': {
            get: 'GET_subscription_'
        },
        //tagging
        'tagging.byDomain.autocomplete.anchor': {
            get: 'GET_domain_domainId_tagging_autocomplete_anchor_'
        },
        'tagging.byDomain.autocomplete.entryPoint': {
            get: 'GET_domain_domainId_tagging_autocomplete_entryPoint_'
        },
        //user
        'user.RESTAccess': {
            get: 'GET_user_uuid',
            post: 'POST_user_',
            put: 'PUT_user_uuid'
        },
        'user.byDomain.list': {
            get: 'GET_domain_domainId_user_'
        },
        'user.resendInvitation': {
            post: 'POST_user_userUuid_invite_'
        },
        'user.byDomain.userRole': {
            delete: 'DELETE_domain_domainId_user_userUuid_role_roleUuid'
        },
        'user.role.all': {
            get: 'GET_user_role_'
        },
        //validation
        'validation.url': {
            post: 'POST_validate_url'
        },
        'validation.password': {
            post: 'POST_validate_password'
        },
        // media
        'media': {
            post: 'POST_media_'
        }
    };

    var module = angular.module('sAPIAccess', []);

    module.provider('sAPIAccess', sAPIAccess.Provider.sAPIAccess);

    module.config(['sAPIAccessProvider', function (sAPIAccessProvider) {

        sAPIAccess.Service.sAPIAccess.endpointPrefix    = sFollowPubNG.baseUrl;
        sAPIAccess.Service.sAPIAccess.imagePath         = sFollowPubNG.apiHost + '/img/';

        sAPIAccessProvider.endpointMapping = endpointMapping.clone();
        sAPIAccessProvider.fixedEndpoints  = {
            'apiList'                : {path : '/api/'},
            'GET_domain_get_all'     : {path : '/domain/get-all'},
            'GET_security_auth_user' : {path : '/security/auth-user'}
        };
    }]);

    module.run([
        '$rootScope',
        'sAPIAccess',
        function($rootScope, sAPIAccess) {
            $rootScope.hasPermission = sAPIAccess.check.bind(sAPIAccess);
        }
    ])

})(angular);
