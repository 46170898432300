(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sDatePicker
     *
     * @param $scope
     * @param $mdPanel
     */
    var sDatePicker = function ($scope, $mdPanel) {
        this.$mdPanel       = $mdPanel;
        this.$scope         = $scope;
        this.mdPanelRef     = null;
        this.$deRegister    = [];
        this.timeZoneName   = moment.tz.guess();
    };

    /**
     * @function
     * @name Controller.Component.sDatePicker#$onInit
     */
    sDatePicker.prototype.$onInit = function $onInit() {
        var self = this;
        this.dateTemplates  = this.dateTemplates || sDatePicker.DEFAULT_TEMPLATES;
        this.keepTime       = this.keepTime !== false;

            this.$deRegister.push(this.$scope.$on(
            Controller.Component.sCalendar.EVENT_DATE_CLICK,
            function(event, data) {
                self.setDate(data.date);
            }
        ));
    };

    /**
     * @function
     * @name Controller.Component.sDatePicker#$onDestroy
     */
    sDatePicker.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
        // if it is not closed + destroyed it will still be there after changed route
        if (this.mdPanelRef) {
            this.mdPanelRef.close();
            this.mdPanelRef.destroy();
        }
    };

    /**
     * @function
     * @name Controller.Component.sDatePicker#showDialog
     * @param {jQuery.Event} $event
     */
    sDatePicker.prototype.showDialog = function showDialog($event) {
        var elementClicked = $event.currentTarget,
            position = this.$mdPanel
                .newPanelPosition()
                .relativeTo(elementClicked)
                .addPanelPosition(
                    this.$mdPanel.xPosition.ALIGN_START,
                    this.$mdPanel.yPosition.BELOW
                )
            ;

        var panelAnimation = this.$mdPanel.newPanelAnimation()
            .openFrom(elementClicked)
            .duration(200)
            .closeTo(elementClicked)
            .withAnimation(this.$mdPanel.animation.SCALE);

        var config = {
            // TODO: whenever https://github.com/angular/material/pull/9379#pullrequestreview-65210421 is fixed uncomment this line
            //id                : 's-date-picker-dialog',
            attachTo            : angular.element(Const.PanelAnchor),
            controller          : function() {
            },
            controllerAs        : '$ctrl',
            locals              : {
                dateTemplates   : this.dateTemplates,
                keepTime        : this.keepTime,
                model           : this.model,
                setDate         : this.setDate.bind(this),
                closeDialog     : this.closeDialog.bind(this)
            },
            templateUrl         : '_component:s-date-picker-dialog',
            scope               : this.$scope.$new(true),
            hasBackdrop         : false,
            panelClass          : 's-date-picker-dialog-panel',
            position            : position,
            trapFocus           : true,
            zIndex              : 150,
            animation           : panelAnimation,
            clickOutsideToClose : true,
            escapeToClose       : true,
            focusOnOpen         : true,
            onCloseSuccess      : function(mdPanelRef) {
                // enforce destroy of inner scope, so all the components get freed properly
                if (!mdPanelRef) {
                    return;
                }

                mdPanelRef.destroy();
            }
        };

        this.mdPanelRef = this.$mdPanel.create(config);
        this.mdPanelRef.open();
    };

    /**
     * @function
     * @name Controller.Component.sDatePicker#setDate
     * @param {Moment} date
     */
    sDatePicker.prototype.setDate = function setDate(date) {
        var newDate
        ;

        if (this.keepTime) {
            newDate = moment(this.model)
                .year(date.year())
                .month(date.month())
                .date(date.date());
        } else {
            newDate = moment(date).startOf('day');
        }

        this.model = newDate;
        digestIfNeeded(this.$scope);
        this.closeDialog();
    };

    /**
     * @function
     * @name Controller.Component.sDatePicker#closeDialog
     */
    sDatePicker.prototype.closeDialog = function closeDialog() {
        this.mdPanelRef && this.mdPanelRef.close();
    };

    sDatePicker.DEFAULT_TEMPLATES = [
        {
            label: 'Today',
            date: moment().startOf('day')
        }
    ];

    ns.sDatePicker = sDatePicker;
})(Object.namespace('Controller.Component'));
