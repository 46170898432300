(function(ns) {
    /**
     * Class to manage multiple app-connections via the facebook-sdk.
     * Every prototyped function calls FB.init first to assure to have a connection to the correct
     * facebook app. The class also tries to add promise-like behaviour to the facebook-sdk-calls
     * to handle the loading better.
     *
     * @namespace
     * @alias Model.Facebook.Connection
     * @constructor
     *
     * @param appId
     * @param version
     */
    var Connection = function Connection(appId, version) {

        /**
         * @function
         * @name Model.Facebook.Connection#refreshInit
         */
        this.refreshInit = function refreshInit() {
            FB.init({
                appId   : appId,
                version : version,
                status  : false, // enabling conflicts with getLoginStatus
                cookie  : true
            });
        };

        /**
         * @typedef {Object} FacebookPage
         * @property {String} id
         * @property {String} name
         * @property {String[]} tasks
         * @property {String} access_token
         * @property {InstagramDirectAccount} instagram_business_account
         */

        /**
         * @typedef {Object} FacebookAdAccount
         * @property {String} id
         * @property {String} name
         */

        /**
         * @typedef {Object} InstagramDirectAccount
         * @property {String} id
         * @property {String} username
         */
    };

    /**
     * @function
     * @name Model.Facebook.Connection#checkUserPermissions
     * @param {String[]} permissions
     * @returns {Promise}
     */
    Connection.prototype.checkUserPermissions = function checkUserPermissions(permissions) {
        var deferred = $.Deferred();

        if (!permissions || !permissions.length) {
            return deferred.resolve();
        }

        FB.api('/me/permissions', function (response) {
            var declined    = [],
                granted     = [];

            if (response.data && (response.data instanceof Array)) {
                granted = response.data
                    .filter(function (permission) {
                        return (permission.status === 'granted');
                    })
                    .map(function (permission) {
                        return permission.permission;
                    });

                declined = granted.diff(permissions);
            }

            if (declined.length === 0) {
                deferred.resolve(granted);
            }
            else {
                deferred.reject(declined);
            }
        });
        return deferred.promise();
    };

    /**
     * @function
     * @name Model.Facebook.Connection#logout
     * @returns {Promise}
     */
    Connection.prototype.logout = function logout() {
        var self        = this,
            deferred    = $.Deferred();

        FB.logout(function (response) {
            if (!self.isStatusConnected(response)) {
                deferred.resolve(response);
                return;
            }
            deferred.reject(response);
        });

        return deferred.promise();
    };

    /**
     * @function
     * @name Model.Facebook.Connection#api
     * @param {String} path
     * @param {String?} method
     * @param {*?} params
     * @returns {Promise}
     */
    Connection.prototype.api = function api(path, method, params) {
        var args        = Array.prototype.slice.call(arguments, 0),
            deferred    = $.Deferred()
        ;

        args.push(function apiCallback(response) {
            if (!response || response.error) {
                deferred.reject(response);
            }

            deferred.resolve(response);
        });

        // Where we delegate the call to the FB.api method.
        FB.api.apply(null, args);

        return deferred.promise();
    };

    /**
     * @function
     * @name Model.Facebook.Connection#apiFetchAll
     * @param {String}  path
     * @param {String?} method
     * @param {*?}      params
     * @return {Promise<[]>}
     */
    Connection.prototype.apiFetchAll = function apiFetchAll(path, method, params) {
        var self = this,
            args = Array.prototype.slice.call(arguments)
        ;

        return this.api.apply(this, args).then(function (response) {
            var data = [];

            if (!response) {
                return data;
            }

            if (response.data && response.data instanceof Array) {
                data = response.data;
            }

            if (response.paging && response.paging.next) {
                return self.apiFetchAll(response.paging.next).then(function (nextData) {
                    return data.concat(nextData);
                });
            }

            return data;
        });
    };

    /**
     * @function
     * @name Model.Facebook.Connection#login
     * @param opts
     * @returns {Promise}
     */
    Connection.prototype.login = function login(opts) {
        opts = opts || {};
        var self        = this,
            deferred    = $.Deferred()
        ;

        FB.login(function (response) {
            if (self.isStatusConnected(response)) {
                deferred.resolve.apply(this, arguments);
                return;
            }
            deferred.reject.apply(this, arguments);
        }, opts);

        return deferred.promise();
    };

    /**
     * @function
     * @name Model.Facebook.Connection#reRequestPermissions
     * @param {String[]} permissions
     * @returns {Promise}
     */
    Connection.prototype.reRequestPermissions = function reRequestPermissions(permissions) {

        if (!permissions instanceof Array) {
            permissions = [];
        }

        return this.login({
            scope       : permissions.join(','),
            auth_type   : 'rerequest'
        });
    };

    /**
     * @function
     * @name Model.Facebook.Connection#isStatusConnected
     * @param response
     * @returns {boolean}
     */
    Connection.prototype.isStatusConnected = function isStatusConnected(response) {
        return (response.status === 'connected');
    };

    /**
     * @function
     * @name Model.Facebook.Connection#isLoggedIn
     * @returns {Promise}
     */
    Connection.prototype.isLoggedIn = function isLoggedIn() {
        var self        = this,
            deferred    = $.Deferred();

        // fallback for 'X-Frame-Options' to deny if app is in dev-mode
        // otherwise we would get stuck here
        setTimeout(function () {
            if (deferred.state() === 'pending') {
                deferred.reject();
            }
        }, 3000);

        // check the login-status
        // will just work properly if app is not in dev-mode
        FB.getLoginStatus(function (response) {
            if (deferred.state() === 'pending') {
                return;
            }

            if (self.isStatusConnected(response)) {
                deferred.resolve(response);
            }
            else {
                deferred.reject(response);
            }
        }, true);

        return deferred.promise();
    };

    /**
     * The facebook SDK is not made to maintain an instance to more than
     * one app-id at the same time so we manually refresh the app-id we use
     */
    $.each(Connection.prototype, function (name, fn) {
        if (typeof fn !== 'function') {
            return false;
        }
        Connection.prototype[name] = function () {
            this.refreshInit();
            return fn.apply(this, arguments)
        };
    });

    ns.Connection = Connection;
})(Object.namespace('Model.Facebook'));
