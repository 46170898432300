/**
 * Created by Gabor on 09-Jun-17.
 */

jQuery.fn.extend({
    'lastNotVisible': function (element) {

        var base = document.body;
        if (element) {
            if (element instanceof jQuery) {
                base = element[0];
            } else if (element instanceof HTMLElement) {
                base = element;
            } else if (typeof(element) === 'string') {
                base = $(element)[0];
            } else {
                throw 'Invalid element type!';
            }
        }

        var containerRect = base.getBoundingClientRect(),
            list = Array.prototype.slice.call(this, 0)
        ;

        list = list.filter(function (element) {
            var rect = element.getBoundingClientRect();
            return rect.top <= containerRect.top;
        });


        return $(list.pop());
    }
});