(function(ns) {
    /**
     * @namespace
     * @alias sAutomation.Controller.CreateAndEdit
     * @constructor
     * @param {Model.Automation} automation
     * @param {sAutomation.Service.sAutomation} sAutomation
     * @param $scope
     * @param sContextNavigationMenu
     */
    var CreateAndEdit = function CreateAndEdit(automation, sAutomation, $scope, sContextNavigationMenu) {
        var self = this;
        this.automation             = automation;
        this.sAutomationService     = sAutomation;
        this.$scope                 = $scope;
        this.sContextNavigationMenu = sContextNavigationMenu;

        this.$deRegister = [];

        this.fetchAnalyticsAction = new Model.Menu.ContextAction(
            function() {
                return automation.isActive();
            },
            function() {
                if (!self.showAnalytics) {
                    return automation.fetchAnalyticsData.call(automation).then(function () {
                        self.showAnalytics = true;
                        digestIfNeeded($scope);
                    });
                } else {
                    self.showAnalytics = false;
                }
            },
            {
                label: 'Analytics',
                icon: '<i class="fal fa-chart-pie"></i>',
                isActive : function() {
                    return self.showAnalytics;
                }
            }
        );

        this.sContextNavigationMenu.insertItem(this.fetchAnalyticsAction, 0);

        this.$deRegister.push($scope.$on('$destroy', this.$onDestroy.bind(this)));
    };

    CreateAndEdit.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }

        this.sContextNavigationMenu.removeItem(this.fetchAnalyticsAction);
    };

    /**
     * @name sAutomation.Controller.CreateAndEdit#onSave
     * @return $.Deferred
     */
    CreateAndEdit.prototype.onSave = function onSave() {
        return this.sAutomationService
            .save(this.automation, this.$scope)
            .then(function () {
                this.$scope.$emit('sAutomationSaved', this.automation);
            }.bind(this))
    };

    /**
     * @name sAutomation.Controller.CreateAndEdit#onActivate
     * @return $.Deferred
     */
    CreateAndEdit.prototype.onActivate = function onActivate() {
        return this.sAutomationService.activate(this.automation, this.$scope, true);
    };

    ns.CreateAndEdit = CreateAndEdit;
})(Object.namespace('sAutomation.Controller'));
