(function (ns) {
    var EVENT_VALUE_DISABLED        = 'sEventSelectValueDisabled',
        EVENT_VALUE_NOT_AVAILABLE   = 'sEventSelectValueNotAvailable'
    ;

    /**
     * @namespace
     * @alias Controller.Component.sSelect
     * @constructor
     *
     * @param $scope
     * @param $attrs
     */
    var sSelect = function ($scope, $attrs) {
        var self = this,
            getSelectedChoiceInvoked,
            updateHelperInvoked
        ;

        // component internal
        this.$scope         = $scope;
        this.$attrs         = $attrs;
        this.choicesCache   = [];
        this.$deRegister    = [];
        this.groupHeads     = [];
        this.loading        = null;
        this.searchTerm     = '';

        Object.defineProperties(
            this,
            {
                modelTracker: {
                    enumerable: true,
                    get: function () {
                        if (self.valueField) {
                            return;
                        }

                        return self.trackBy;
                    }
                    /**
                     * @property
                     * @name Controller.Component.sSelect#modelTracker
                     * @type {String|null}
                     */
                },
                repeatTracker: {
                    enumerable: true,
                    get: function () {
                        if (self.trackBy) {
                            return self.trackBy;
                        }

                        if (self.valueField) {
                            return self.valueField;
                        }

                        return false;
                    }
                    /**
                     * @property
                     * @name Controller.Component.sSelect#repeatTracker
                     * @type {String}
                     */
                },
                requireMatch: {
                    enumerable: true,
                    get: function () {
                        return !self.allowNew
                            || !self.searchTermMatches(
                                (self.viewField ? (self.selectedItem ? self.selectedItem[self.viewField] : null) : self.model)
                                || self.searchTerm
                            );
                    },
                    set: function() {
                        // fake setter required by angular
                    }
                    /**
                     * @property
                     * @name Controller.Component.sSelect#requireMatch
                     * @type {Boolean}
                     */
                }
            });

        this.getSelectedChoice = function getSelectedChoice() {
            if (!getSelectedChoiceInvoked || getSelectedChoiceInvoked.value !== self.model) {
                getSelectedChoiceInvoked = {};
                getSelectedChoiceInvoked.value = self.model;
                getSelectedChoiceInvoked.promise = sSelect.prototype.getSelectedChoice.call(self);
            }

            return getSelectedChoiceInvoked.promise;
        };

        this.refreshChoices = function refreshChoices() {
            // nothing to refresh the choices was never inited and no model is set
            if (!getSelectedChoiceInvoked && !this.model) {
                return $.Deferred().reject();
            }
            getSelectedChoiceInvoked = null;
            updateHelperInvoked = null;
            return sSelect.prototype.refreshChoices.call(this);
        }

        this.updateHelper = function updateHelper(callChangeHandler, oldValue) {
            var retVal = sSelect.prototype.updateHelper.call(this, callChangeHandler || !updateHelperInvoked, oldValue)
            updateHelperInvoked = true;
            return retVal;
        }

        /**
         * @property
         * @name Controller.Component.sSelect#searchTerm
         * @type String
         */

        /**
         * @property
         * @name Controller.Component.sSelect#selectedText
         * @type String
         */

        /**
         * @property
         * @name Controller.Component.sSelect#selectedItem
         * @type *
         */

        /**
         * BEGIN BINDINGS
         */

        /**
         * @property
         * @name Controller.Component.sSelect#model
         * @type *
         */

        /**
         * @property
         * @name Controller.Component.sSelect#choices
         * @type Function
         */

        /**
         * @property
         * @name Controller.Component.sSelect#onHandleChange
         * @type Function
         */

        /**
         * @property
         * @name Controller.Component.sSelect#selectedTextFn
         * @type Function
         */

        /**
         * @property
         * @name Controller.Component.sSelect#sortBy
         * @type Function|String
         */

        /**
         * @property
         * @name Controller.Component.sSelect#searchEnabled
         * @type Boolean
         */

        /**
         * @property
         * @name Controller.Component.sSelect#ngRequired
         * @type Boolean
         */

        /**
         * @property
         * @name Controller.Component.sSelect#ngDisabled
         * @type Boolean
         */

        /**
         * @property
         * @name Controller.Component.sSelect#showTooltip
         * @type String|Boolean
         */

        /**
         * @property
         * @name Controller.Component.sSelect#asyncSearch
         * @type Boolean
         */

        /**
         * @property
         * @name Controller.Component.sSelect#refreshEventName
         * @type String
         */

        /**
         * @property
         * @name Controller.Component.sSelect#trackBy
         * @type String
         */

        /**
         * @property
         * @name Controller.Component.sSelect#valueField
         * @type String
         */

        /**
         * @property
         * @name Controller.Component.sSelect#viewField
         * @type String
         */

        /**
         * @property
         * @name Controller.Component.sSelect#groupField
         * @type String
         */

        /**
         * @property
         * @name Controller.Component.sSelect#placeholder
         * @type String
         */

        /**
         * @property
         * @name Controller.Component.sSelect#compareFn
         * @type {?Function}
         */

        /**
         * @property
         * @name Controller.Component.sSelect#onCreateNew
         * @type {?Function}
         */

        /**
         * @property
         * @name Controller.Component.sSelect#noLazyLoading
         * @type {Boolean}
         */

        /**
         * @property
         * @name Controller.Component.sSelect#filterFn
         * @type {?Function}
         */

        /**
         * END BINDINGS
         */
    };

    /**
     * @function
     * @name Controller.Component.sSelect#$onInit
     */
    sSelect.prototype.$onInit = function $onInit() {
        var self = this;

        // set default for bindings
        this.searchEnabled = this.searchEnabled || false;
        this.asyncSearch   = this.asyncSearch || false;
        this.showTooltip   = this.showTooltip || false;
        this.selectedText  = this.placeholder || '';
        this.placeholder   = this.placeholder || 'Choose...';

        if (this.refreshEventName) {
            this.$deRegister.push(this.$scope.$on(
                this.refreshEventName,
                function() {
                    self.refreshChoices().then(function () {
                        self.updateHelper(true);
                    });
                }
            ));
        }

        if (this.multiple) {
            this.$deRegister.push(this.$scope.$watchCollection(
                function () {
                    return self.model;
                },
                function (newVal, oldVal) {
                    // init loading if not happening already
                    if (!self.loading) {
                        self.getChoices().then(function() {
                            // noinspection JSIncompatibleTypesComparison
                            self.updateHelper(newVal !== oldVal, oldVal);
                            // and execute the watcher once ready
                            digestIfNeeded(self.$scope, 5);
                        });
                    }

                    // this will basically filter out everything until loaded
                    // newVal and oldVal will always have the same type if they are set, but the type can't be defined
                    // noinspection JSIncompatibleTypesComparison
                    if (newVal === oldVal) {
                        return;
                    }

                    // noinspection JSIncompatibleTypesComparison
                    self.updateHelper(newVal !== oldVal, oldVal);
                }
            ));
        }
        else {
            this.$deRegister.push(this.$scope.$watch(
                function () {
                    return self.model;
                },
                function (newVal, oldVal) {
                    function handleNewValue(newValue, oldValue) {
                        // noinspection JSIncompatibleTypesComparison
                        self.updateHelper(newValue !== oldValue, oldValue);

                        if (self.model) {
                            self.getSelectedChoice().then(function(selected) {
                                if (!selected) {
                                    self.$scope.$emit(EVENT_VALUE_NOT_AVAILABLE, self.model);
                                } else if (self.allowNew) {
                                    var modelFromSelection = (self.valueField ? selected[self.valueField] : selected);
                                    if (self.model !== modelFromSelection) {
                                        self.selectedItem = selected;
                                        self.model = modelFromSelection;
                                        self.handleChange(newVal);
                                        digestIfNeeded(self.$scope);
                                    }
                                }
                            });
                        }
                    }

                    // load the list if we have a value and it not loading yet
                    if (!self.loading && (newVal || self.noLazyLoading)) {
                        self.getChoices().then(function() {
                            handleNewValue(newVal, oldVal);
                            digestIfNeeded(self.$scope, 5);
                        });
                    }

                    // newVal and oldVal will always have the same type if they are set, but the type can't be defined
                    // noinspection JSIncompatibleTypesComparison
                    if (newVal === oldVal) {
                        return;
                    }

                    handleNewValue(newVal, oldVal);
                }
            ));
        }

        if ((this.groupField || this.multiple) && this.searchEnabled) {
            throw 'Grouping and multiple do not work with autocomplete!';
        }
    };

    /**
     * @function
     * @name Controller.Component.sSelect#$onDestroy
     */
    sSelect.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name Controller.Component.sSelect#$onChanges
     * @param {Object} $changes
     */
    sSelect.prototype.$onChanges = function $onChanges($changes) {
        if ($changes.searchEnabled && !$changes.searchEnabled.isFirstChange()) {
            this.updateHelper();
        }

        if ($changes.valueField || $changes.viewField) {
            if (($changes.valueField.currentValue && !$changes.viewField.currentValue)) {
                throw 'You have to define `viewField` if `valueField` is defined.'
            }
        }
    };

    /**
     * @function
     * @name Controller.Component.sSelect#updateHelper
     * @param {boolean=} callChangeHandler
     * @param {*=} oldValue
     */
    sSelect.prototype.updateHelper = function updateHelper(callChangeHandler, oldValue) {
        if (this.searchEnabled) {
            // for auto-complete
            if (this.model) {
                if (!this.viewField) {
                    this.searchTerm = this.model;
                } else if (this.newChoice) {
                    this.searchTerm = this.newChoice[this.viewField];
                }
            }
            return this.updateSelectedItem().then(function (data) {
                if (callChangeHandler) {
                    this.handleChange(oldValue);
                }
                return data;
            }.bind(this));
        } else {
            // for select
            var retVal =  this.updateSelectedText();
            if (callChangeHandler) {
                this.handleChange(oldValue);
            }

            return retVal;
        }
    };

    /**
     * @function
     * @name Controller.Component.sSelect#createChoicesFilterFn
     * @param {String} searchTerm
     * @returns Function
     */
    sSelect.prototype.createChoicesFilterFn = function createChoicesFilterFn(searchTerm) {
        var self = this,
            // case insensitive search wherever the searchTerm matches
            regexObj = new RegExp(searchTerm.toString().replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'), 'i');

        return function filter(item) {
            if (self.searchEnabled && item.isGroup) {
                return true;
            }

            if (self.$attrs.filterFn) {
                return self.filterFn({regexp: regexObj, item: item});
            }

            if (self.viewField) {
                return (regexObj.exec(item[self.viewField]));
            }
            return (regexObj.exec(item));
        };
    };

    /**
     * @function
     * @name Controller.Component.sSelect#getFilteredChoices
     * @param {String} searchTerm
     * @returns Promise
     */
    sSelect.prototype.getFilteredChoices = function getFilteredChoices(searchTerm) {
        var self                = this
        ;

        if (this.allowNew) {
            if (searchTerm && this.searchTermMatches(searchTerm)) {
                // create an entry that will exist to be used in the getChoices call as well
                this.newChoice = this.onCreateNew({'term': searchTerm}) || searchTerm;
            } else {
                this.newChoice = null;
            }
        }

        return this.getChoices().then(function (choices) {
            if (searchTerm && self.searchEnabled && !self.asyncSearch) {
                choices = choices.filter(self.createChoicesFilterFn.call(self, searchTerm));
            }

            // eliminate group headers that are not followed by an item
            if (self.searchEnabled) {
                choices = choices.filter(function(item, index) {
                    return !item.isGroup || (choices[index + 1] && !choices[index + 1].isGroup);
                });
            }

            return choices;
        });
    };

    /**
     * @function
     * @name Controller.Component.sSelect#getChoices
     * @returns Promise
     */
    sSelect.prototype.getChoices = function getChoices() {
        var self        = this,
            needsDigest = !this.loading || this.loading.state() === 'pending',
            resolveFn   = function resolveFn(choices) {
                self.choicesCache = choices;
                self.sortChoices(self.choicesCache);
                self.groupChoices(self.choicesCache);
                // prevent every function calling digest, by scheduling a digest only if there was an async request
                if (needsDigest) {
                    digestIfNeeded(self.$scope, 5);
                }
                return self.choicesCache;
            };

        if (!this.loading) {
            this.loading = this.loadChoices().then(resolveFn);
        }

        return this.loading.then(function() {
            // check if newChoice is already present in the list, and remove if so
            if (self.newChoice) {
                var choicesMatching = self.choicesCache.filter(function(existingChoice) {
                    return self.doCompare(existingChoice, self.newChoice);
                });

                if (choicesMatching.length) {
                    self.newChoice = null;
                }
            }

            return self.newChoice ? [self.newChoice].concat(self.choicesCache) : self.choicesCache;
        },
        function () {
            self.loading = false;
        });
    };

    /**
     * @function
     * @name Controller.Component.sSelect#sortChoices
     * @param {Array} choices
     */
    sSelect.prototype.sortChoices = function sortChoices(choices) {
        if (typeof this.sortBy === 'string' || this.sortBy instanceof String) {
            choices.sort(Array.sortFnByProperty(this.sortBy));
        } else if (this.sortBy instanceof Function) {
            choices.sort(this.sortBy);
        } else {
            choices.sortLocaleCompare();
        }
    };

    /**
     * @function
     * @name Controller.Component.sSelect#groupChoices
     * @param {Array} choices
     */
    sSelect.prototype.groupChoices = function groupChoices(choices) {
        var self = this;
        choices.map(function(element) {
            if (self.groupHeads.indexOf(element[self.groupField]) === -1) {
                self.groupHeads.push(element[self.groupField]);
            }
        });
    };

    /**
     * @function
     * @name Controller.Component.sSelect#refreshChoices
     * @returns Promise
     */
    sSelect.prototype.refreshChoices = function refreshChoices() {
        this.loading = null;
        this.choicesCache = [];
        return this.getChoices();
    };

    /**
     * @function
     * @name Controller.Component.sSelect#loadChoices
     * @returns Promise
     */
    sSelect.prototype.loadChoices = function loadChoices() {
        var choicesFnResult = this.asyncSearch ? this.choices({searchString: this.searchString}) : this.choices();

        // deal with not promise-returning functions
        if (!choicesFnResult.then) {
            if (!(choicesFnResult instanceof Array)) {
                console.trace();
                console.warn('Choices should be a function returning an array or promise. Type ' + (typeof choicesFnResult) + ' given');
                choicesFnResult = [];
            }
            choicesFnResult = $.Deferred().resolve(choicesFnResult).promise();
        }

        return choicesFnResult.then(function (choices) {
            if (choices instanceof Array) {
                return choices.slice();
            }
            return [];
        });
    };

    /**
     * @function
     * @name Controller.Component.sSelect#getSelectedChoice
     * @returns Promise
     */
    sSelect.prototype.getSelectedChoice = function getSelectedChoice() {
        var self = this;

        return this.getChoices().then(function (choices) {
            var selected = choices.filter(function(element) {
                if (element.isDisabled) {
                    return false;
                }
                return self.doCompare(element, self.model);
            }).shift();

            if (!selected && self.model) {
                if (!self.newChoice && self.searchEnabled && self.allowNew  && self.model && self.searchTermMatches(self.model)) {
                    self.newChoice = self.onCreateNew({'item': self.model}) || self.model;
                }
                selected = self.newChoice;
            }

            return selected;
        });
    };

    /**
     * @function
     * @name Controller.Component.sSelect#getSelectedChoices
     * @returns Promise
     */
    sSelect.prototype.getSelectedChoices = function getSelectedChoices() {
        var self = this;

        return this.getChoices().then(function (choices) {
            return choices.filter(function(element) {
                for (var i = 0; i < self.model.length; i++) {
                    if (self.doCompare(element, self.model[i])) {
                        return true;
                    }
                }
                return false;
            });
        });
    };

    /**
     * @function
     * @name Controller.Component.sSelect#handleOnBlur
     * @param {String} lastSearchTerm
     */
    sSelect.prototype.handleOnBlur = function handleOnBlur(lastSearchTerm) {
        var self = this;

        if (!lastSearchTerm && !this.selectedItem) {
            this.updateSelectedItem();
            return;
        }

        this.getChoices().then(function (choices) {
            var filteredChoices;

            filteredChoices = choices.filter(function (choice) {
                return (self.viewField && choice[self.viewField] === lastSearchTerm)
                    || (!self.viewField && choice === lastSearchTerm);
            });

            if (!filteredChoices.length) {
                self.updateSelectedItem();
                return;
            }

            // if we find more than one choice with the same name/label we
            // do a compare to find the most suiting one
            if (filteredChoices.length > 1) {
                var foundInCurrentModel = filteredChoices.find(function (choice) {
                    return self.doCompare(choice, self.model);
                });

                if (foundInCurrentModel) {
                    self.selectedItem = foundInCurrentModel;
                    return;
                }
            }

            self.selectedItem = filteredChoices.shift();
        });
    };

    /**
     * @function
     * @name Controller.Component.sSelect#handleChange
     * @param {*=} oldVal
     */
    sSelect.prototype.handleChange = function handleChange(oldVal) {
        var self = this;

        if (!this.$attrs.onHandleChange) {
            return;
        }

        if (this.multiple) {
            this.getSelectedChoices().then(function (choices) {
                self.onHandleChange({items:choices, previous: oldVal});
            });
        } else {
            this.getSelectedChoice().then(function (choice) {
                self.onHandleChange({item: choice, isNewItem: choice === self.newChoice, previous: oldVal});
            });
        }

        if (this.allowNew && this.newChoice) {
            // give some time for the message handlers to run
            setTimeout(function() {
                self.loading = null;
                self.choicesCache.splice(0);
            }, 1);
        }
    };

    /**
     * Handles the item-change for md-autocomplete
     *
     * @function
     * @name Controller.Component.sSelect#handleSelectedItemChange
     */
    sSelect.prototype.handleSelectedItemChange = function handleSelectedItemChange(item) {
        // don't do anything if loading is in progress
        if (this.loading && this.loading.state() === 'pending') {
            return;
        }

        if (!item && this.newChoice) {
            // somehow keeps getting here, but this should not happen at all
            return;
        }

        var self        = this,
            candidate
        ;

        if (!item) {
            candidate = null;
        } else {
            candidate = this.valueField ? item[this.valueField] : item;
        }

        if (this.model === candidate) {
            return;
        }

        this.model = candidate;

        self.handleChange(item);
    };

    /**
     * Updates the selected item for md-autocomplete
     *
     * @function
     * @name Controller.Component.sSelect#updateSelectedItem
     */
    sSelect.prototype.updateSelectedItem = function updateSelectedItem() {
        var self = this;

        return this.getSelectedChoice().then(function (choice) {
            if (self.selectedItem !== choice) {
                self.selectedItem = choice;
            }
        });
    };

    /**
     * @function
     * @name Controller.Component.sSelect#getDefaultMultipleText
     *
     * @param {Array} choices
     * @return {string}
     */
    sSelect.prototype.getDefaultMultipleText = function getDefaultMultipleText(choices) {
        if (!choices.length) {
            return this.placeholder || '';
        }

        var self = this;
        return choices.map(function (item) {
            return self.viewField ? item[self.viewField] : item;
        }).join(', ');
    };

    /**
     * Updates the displayed text for md-select
     *
     * @function
     * @name Controller.Component.sSelect#updateSelectedText
     */
    sSelect.prototype.updateSelectedText = function updateSelectedText() {
        function setTextFromMultiple(choices) {
            var currentText = this.selectedText,
                text        = null
            ;

            if (this.$attrs.selectedTextFn) {
                text = this.selectedTextFn({choices: choices});
            }

            if (text === null) {
                text = this.getDefaultMultipleText(choices);
            }

            if (currentText === text) {
                return;
            }

            this.selectedText = text;
        }

        function setTextFromSingle(choice) {
            if (!choice) {
                return;
            }

            if (this.viewField && this.selectedText !== choice[this.viewField]) {
                this.selectedText = choice[this.viewField];
            } else if (!this.viewField && this.selectedText !== choice) {
                this.selectedText = choice;
            }
        }

        if (this.multiple) {
            return this.getSelectedChoices().then(setTextFromMultiple.bind(this));
        }

        return this.getSelectedChoice().then(setTextFromSingle.bind(this));
    };

    /**
     * @function
     * @name Controller.Component.sSelect#checkDisabled
     * @param {*} item
     * @returns {Boolean}
     */
    sSelect.prototype.checkDisabled = function checkDisabled(item) {
        if (!this.$attrs.isItemDisabled) {
            return false;
        }

        var disabled    = Boolean(this.isItemDisabled({item: item})),
            self        = this
        ;

        if (!disabled) {
            return false;
        }

        // if the selected item got disabled move the selection to the first non disabled item
        this.getSelectedChoice().then(function(selectedItem) {
            if (!selectedItem || !self.doCompare(selectedItem, item)) {
                return;
            }

            self.getChoices().then(function(choices) {
                var newItem = choices.filter(function(choice) {
                    return !self.doCompare(choice, item);
                }).shift();

                self.handleSelectedItemChange(newItem);

                setTimeout(function() {
                    self.$scope.$emit(EVENT_VALUE_DISABLED, {oldVal: item, newVal: newItem});
                }, 0);
            });
        });

        return true;
    };

    /**
     * @param {*} itemA
     * @param {*} itemB
     * @return {boolean}
     */
    sSelect.prototype.doCompare = function doCompare(itemA, itemB) {
        return (this.compareFn instanceof Function && this.compareFn(itemA, itemB))          // compareFn
            || (this.valueField && itemA[this.valueField] === itemB)                         // valueField
            || (this.modelTracker && itemB && itemA[this.trackBy] === itemB[this.trackBy])   // trackBy
            || (itemA === itemB);
    };

    /**
     * @name Controller.Component.sSelect#searchTermMatches
     * @param {String} searchTerm
     * @return {boolean}
     */
    sSelect.prototype.searchTermMatches = function searchTermMatches(searchTerm) {
        if (typeof(this.allowNew) === 'boolean' && this.allowNew) {
            return true;
        }

        if (typeof(this.allowNew) === 'string') {
            var regExp = new RegExp(this.allowNew.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'), 'i');
            return regExp.test(searchTerm);
        }

        return false;
    };

    /**
     * Callback to check if a given option is enabled or not
     * By setting the `diabledHint` property of the item a reason will be shown as an html tooltip
     * @callback Controller.Component.sSelect~isItemDisabled
     * @param {Object} item
     * !!Additional parameters might be set!!
     */
    
    Object.defineProperties(
        sSelect,
        {
            EVENT_VALUE_DISABLED: {
                get: function () {
                    return EVENT_VALUE_DISABLED;
                }
                /**
                 * @property
                 * @name #EVENT_VALUE_DISABLED
                 * @type {String}
                 */
            },
            EVENT_VALUE_NOT_AVAILABLE: {
                get: function () {
                    return EVENT_VALUE_NOT_AVAILABLE;
                }
                /**
                 * @property
                 * @name #EVENT_VALUE_NOT_AVAILABLE
                 * @type {String}
                 */
            }
        });
    ns.sSelect = sSelect;
})(Object.namespace('Controller.Component'));
