/**
 * Created by Gabor on 25-Oct-17.
 */

(function(angular){
    var sAppCookiesModule = angular.module('sAppCookies', ['ngCookies']);

    sAppCookiesModule
        .value('APP_COOKIE_NAME', 'app.spectrm')
        .service('sAppCookies', sAppCookies.Service.sAppCookies);

})(angular);