(function (ns) {
    /**
     * @namespace
     * @alias sSettings.Route.Platform.FacebookMessenger
     * @extends sSettings.Route.Base
     * @constructor
     */
    var FacebookMessenger = function() {
        this.controller     = sSettings.Controller.Platform.FacebookMessenger;
        this.templateUrl    = 'ssettings:platform/facebook_messenger';
        this.controllerAs   = '$ctrl';
        this.accessControl  = function (sAPIAccess) {
            return (sAPIAccess.isAllowed('facebook.page.all', Const.Method.GET)
            || sAPIAccess.isAllowed('facebook.page.RESTAccess', Const.Method.GET));
        };

        FacebookMessenger._pProto.constructor.apply(this, arguments);
    };

    Object.extendProto(FacebookMessenger, sSettings.Route.Base);

    /**
     * @function
     * @name sSettings.Route.Platform.FacebookMessenger#resolveFacebookSDK
     * @param {sFacebook.Service.sFacebookClient} sFacebookClient
     * @returns {$.Deferred}
     */
    FacebookMessenger.prototype.resolveFacebookSDK = function resolveFacebookSDK(sFacebookClient) {
        return sFacebookClient.whenSDKReady();
    };

    /**
     * @function
     * @name sSettings.Route.Platform.FacebookMessenger#resolveMenu
     * @param {Service.sDomain} sDomainService
     * @param {Service.sAction} sActionService
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @returns {$.Deferred}
     */
    FacebookMessenger.prototype.resolveMenu = function (sDomainService, sActionService, sAPIAccess) {
        return sDomainService.getCurrentDomain().then(function (currentDomain) {
            return sActionService.getDefinitions().then(function() {
                var def = $.Deferred();

                // we need the post-endpoint because it has no 'key' pre-filled in url
                Model.DomainMeta.endPoint = sAPIAccess.endpoint('domain.domainMeta.RESTAccess').post(currentDomain.id);

                var menuLoading = Model.DomainMeta.getOrCreate(
                    Model.DomainMeta.FACEBOOK_PERSISTENT_MENU,
                    sSettings.Controller.Platform.FacebookMessenger.menuParser
                );
                menuLoading.then(
                    function (menu) {
                        def.resolve(menu);
                    },
                    function (reason) {
                        def.reject(new sRouteRejection(reason, {
                            domain: currentDomain ? currentDomain.name : null
                        }));
                    }
                );

                return def.promise();
            });
        });
    };

    /**
     * @function
     * @name sSettings.Route.Platform.FacebookMessenger#resolveGreeting
     * @param {Service.sDomain} sDomainService
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @returns {$.Deferred}
     */
    FacebookMessenger.prototype.resolveGreeting = function (sDomainService, sAPIAccess) {
        return sDomainService.getCurrentDomain().then(function (currentDomain) {
            // we need the post-endpoint because it has no 'key' pre-filled in url
            Model.DomainMeta.endPoint = sAPIAccess.endpoint('domain.domainMeta.RESTAccess').post(currentDomain.id);
            return Model.DomainMeta.getOrCreate('facebook_greeting', sSettings.Controller.Platform.FacebookMessenger.greetingParser);
        });
    };

    /**
     * @function
     * @name sSettings.Route.Platform.FacebookMessenger#resolveReactions
     * @param {Service.sDomain} sDomainService
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @returns {$.Deferred}
     */
    FacebookMessenger.prototype.resolveReactions = sSettings.Route.DefaultReactions.prototype.resolveReactions;

    /**
     * @function
     * @name sSettings.Route.Platform.FacebookMessenger#resolveGetStartedSettings
     * @param {Service.sDomain} sDomainService
     * @param {Service.sAction} sActionService
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @returns {$.Deferred}
     */
    FacebookMessenger.prototype.resolveGetStartedSettings = function resolveGetStartedSettings(
        sDomainService,
        sActionService,
        sAPIAccess
    ) {
        return sDomainService.getCurrentDomain().then(function (currentDomain) {
            return sActionService.getDefinitions().then(function () {
                var def = $.Deferred();

                // we need the post-endpoint because it has no 'key' pre-filled in url
                Model.DomainMeta.endPoint = sAPIAccess.endpoint('domain.domainMeta.RESTAccess').post(currentDomain.id);

                Model.DomainMeta.getOrCreate(
                    Model.DomainMeta.FACEBOOK_GET_STARTED,
                    sSettings.Controller.Platform.FacebookMessenger.getStartedSettingsParser
                ).then(
                    function (getStartedSettings) {
                        def.resolve(getStartedSettings);
                    },
                    function (reason) {
                        def.reject(new sRouteRejection(reason, {
                            domain : currentDomain ? currentDomain.name : null
                        }));
                    }
                );

                return def.promise();
            });
        });
    };

    ns.FacebookMessenger = FacebookMessenger;
})(Object.namespace('sSettings.Route.Platform'));
