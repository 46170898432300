/**
 * Created by Gabor on 10/9/2016.
 */

/**
 * @typedef {Object} ProductCatalogEntry
 * @property {String|Number} catalogId
 * @property {String|Number} productId
 */

(function() {
    var sFollowPubComponent = angular.module('sFollowPubComponents', []);

    // fixes for ng directive/component docComments
    /**
     * @ngdoc directive
     * @name ngInclude
     *
     * @param {String} src
     */
    // end fixes

    sFollowPubComponent
    /**
     * @ngdoc component
     * @name sMessageInputBox
     * @restrict E
     *
     * @param {Function=} onAddMessage
     * @param {Function=} onEditMessage
     * @param {Object=} actionsMenu
     * @param {Function=} onSave
     * @param {Boolean=} botOnly
     * @param {Boolean=} isEnterAddingMessage
     * @param {Model.Message.Part} messagePart
     * @param {Number=} maxLength
     * @param {String=} placeholder
     * @param {Boolean} autoFocus
     * @param {Boolean=} actionInProgress
     */
        .component('sMessageInputBox', {
            templateUrl : '_component:s-message-input-box',
            controller  : Controller.Component.sMessageInputBox,
            transclude  : true,
            bindings: {
                onAddMessage        : '&',
                onEditMessage       : '&',
                actionsMenu         : '=',
                onSave              : '&',
                botOnly             : '=?',
                isEnterAddingMessage: '=?',
                messagePart         : '=',
                maxLength           : '<',
                placeholder         : '<',
                autoFocus           : '<',
                actionInProgress    : '=?'
            }
        })
        /**
         * @ngdoc component
         * @name sMessageCollectionActionBox
         * @restrict E
         *
         * @param {Model.Message.Collection} collection
         * @param {Function=} onAddMessage
         * @param {Function=} onEditMessage
         * @param {Function=} onUpload
         * @param {Number=} maxLength
         */
        .component('sMessageCollectionActionBox', {
            templateUrl : '_component:s-message-collection-action-box',
            controller  : Controller.Component.sMessageCollectionActionBox,
            bindings    : {
                collection    : '=',
                onAddMessage  : '&',
                onEditMessage : '&',
                onUpload      : '&',
                maxLength     : '<',
                placeholder   : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sTimeInput
         * @restrict E
         *
         * @param {Object}      model
         * @param {boolean=}    disabled
         */
        .component('sTimeInput', {
            templateUrl : '_component:s-time-input',
            bindings    : {
                model       : '=',
                disabled    : '<',
                ngRequired  : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sDatetime
         * @restrict E
         *
         * @param {Object} model
         * @param {String} timezone
         */
        .component('sDatetime', {
            templateUrl : '_component:s-datetime',
            controller  : Controller.Component.sDatetime,
            bindings: {
                model       : '=',
                timezone    : '@',
                min         : '<'

            }
        })
        /**
         * @ngdoc component
         * @name sCalendar
         * @restrict E
         *
         * @param {moment|null} model
         * @param {moment} activeSheetDate
         */
        .component('sCalendar', {
            templateUrl : '_component:s-calendar',
            controller  : Controller.Component.sCalendar,
            bindings: {
                model                   : '=?',
                activeSheetDate         : '=?'
            }
        })
        /**
         * @ngdoc component
         * @name sDatePicker
         * @restrict E
         *
         * @param {moment|null} model
         * @param {Boolean=}    showTimezone
         * @param {Boolean=}    keepTime
         */
        .component('sDatePicker', {
            templateUrl : '_component:s-date-picker',
            controller  : Controller.Component.sDatePicker,
            bindings: {
                model           : '=?',
                showTimezone    : '<',
                keepTime        : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sDateRangeCalendar
         * @restrict E
         *
         * @param {Model.DateRange|null}    model
         * @param {Function=}               onRangeSelected
         * @param {Boolean=}                keepTime
         */
        .component('sDateRangeCalendar', {
            templateUrl : '_component:s-date-range-calendar',
            controller  : Controller.Component.sDateRangeCalendar,
            bindings: {
                model               : '=?',
                onRangeSelectedFn   : '&?onRangeSelected',
                keepTime            : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sDateRangePicker
         * @restrict E
         *
         * @param {Model.DateRange|null}    model
         * @param {Boolean=}                showTimezone
         * @param {Array=}                  rangeTemplates
         * @param {Boolean=}                keepTime
         */
        .component('sDateRangePicker', {
            templateUrl : '_component:s-date-range-picker',
            controller  : Controller.Component.sDateRangePicker,
            bindings: {
                model           : '=?',
                showTimezone    : '<',
                rangeTemplates  : '<',
                keepTime        : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sChannelSelect
         * @restrict E
         *
         * @param {Boolean=}    multiSelect
         * @param {Boolean=}    isRequired
         * @param {Object}      model
         * @param {Boolean=}    disabled
         * @param {Function=}   onHandleChange
         * @param {String=}     type
         */
        .component('sChannelSelect', {
            templateUrl : '_component:s-channel-select',
            controller  : Controller.Component.sChannelSelect,
            bindings: {
                multiSelect     : '<?',
                isRequired      : '<?',
                model           : '=',
                disabled        : '<?',
                onHandleChange  : '&?',
                type            : '@?'
            }
        })
        /**
         * @ngdoc component
         * @name sNotificationLabelSelect
         * @restrict E
         *
         * @param {Object}      model
         * @param {Boolean=}    isRequired
         * @param {Boolean=}    inUseOnly
         * @param {Boolean=}    disabled
         */
        .component('sNotificationLabelSelect', {
            templateUrl : '_component:s-notification-label-select',
            controller  : Controller.Component.sNotificationLabelSelect,
            bindings    : {
                model      : '=',
                isRequired : '<?',
                inUseOnly  : '<?',
                disabled   : '<?'
            }
        })
        /**
         * @ngdoc component
         * @name sContentSourceTypeSelect
         * @restrict E
         *
         * @param {Boolean=}    isRequired
         * @param {Object}      model
         * @param {Boolean=}    disabled
         * @param {Function=}   onHandleChange
         * */
        .component('sContentSourceTypeSelect', {
            templateUrl : '_component:s-content-source-type-select',
            controller  : Controller.Component.sContentSourceTypeSelect,
            bindings: {
                isRequired      : '<?',
                model           : '=',
                disabled        : '<?',
                onHandleChange  : '&?'
            }
        })
        /**
         * @ngdoc component
         * @name sWATemplateSelect
         * @restrict E
         *
         * @param {Boolean=}    isRequired
         * @param {Object}      model
         * @param {Boolean=}    disabled
         * @param {Function=}   onHandleChange
         * */
        .component('sWATemplateSelect', {
            templateUrl : '_component:s-wa-template-select',
            controller  : Controller.Component.sWATemplateSelect,
            bindings: {
                isRequired      : '<?',
                model           : '=',
                disabled        : '<?',
                onHandleChange  : '&?'
            }
        })
        /**
         * @ngdoc component
         * @name sDelayPicker
         * @restrict E
         *
         * @param {Object} model
         * @param {Boolean=} disabled
         */
        .component('sDelayPicker', {
            templateUrl : '_component:s-delay-picker',
            controller  : Controller.Component.sDelayPicker,
            bindings    : {
                model           : '=',
                disabled        : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sScheduleRestraint
         * @restrict E
         *
         * @param {Object}      model
         * @param {Boolean=}    disabled
         */
        .component('sScheduleRestraint', {
            templateUrl : '_component:s-schedule-restraint',
            controller  : Controller.Component.sScheduleRestraint,
            bindings    : {
                model           : '=',
                disabled        : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sMemberSubscriptions
         * @restrict E
         *
         * @param {String} memberId
         * @param {Boolean=} disabled
         */
        .component('sMemberSubscriptions', {
            templateUrl: '_component:s-member-subscriptions',
            controller: Controller.Component.sMemberSubscriptions,
            bindings: {
                memberId: '<',
                disabled: '<'
            }
        })
        /**
         * @ngdoc component
         * @name sMemberCustomData
         * @restrict E
         *
         * @param {String} memberId
         * @param {Boolean=} disabled
         */
        .component('sMemberCustomData', {
            templateUrl: '_component:s-member-custom-data',
            controller: Controller.Component.sMemberCustomData,
            bindings: {
                memberId: '<',
                disabled: '<'
            }
        })
        /**
         * @ngdoc component
         * @name sMemberChatBotStatus
         * @restrict E
         *
         * @param {String} memberId
         * @param {Boolean=} disabled
         */
        .component('sMemberChatBotStatus', {
            templateUrl: '_component:s-member-chat-bot-status',
            controller: Controller.Component.sMemberChatBotStatus,
            bindings: {
                memberId: '<',
                disabled: '<'
            }
        })
        /**
         * @ngdoc component
         * @name sMemberBlockStatus
         * @restrict E
         *
         * @param {String} memberId
         * @param {Boolean=} disabled
         */
        .component('sMemberBlockStatus', {
            templateUrl: '_component:s-member-block-status',
            controller: Controller.Component.sMemberBlockStatus,
            bindings: {
                memberId: '<',
                disabled: '<'
            }
        })
        /**
         * @ngdoc component
         * @name lazyMdSelect
         * @restrict E
         *
         * @param {Function=} onLoadFn
         * @param {Function=} onHandleChange
         * @param {Function|String} sortBy
         * @param {Object} model
         * @param {String=} placeholder
         * @param {String=} trackBy
         * @param {String=} description
         * @param {String=} onlyField
         * @param {String=} refreshEventName
         * @param {Boolean=} isRequired
         * @param {Boolean=} isDisabled
         */
        .component('lazyMdSelect', {
            templateUrl : '_component:lazy-md-select',
            controller  : Controller.Component.lazyMdSelectController,
            bindings: {
                onLoadFn        : '&',
                onHandleChange  : '&',
                sortBy          : '<',
                model           : '=',
                placeholder     : '@',
                trackBy         : '@',
                description     : '@',
                onlyField       : '@',
                refreshEventName: '@',
                isRequired: '=',
                isDisabled: '='
            }
        })
        /**
         * @ngdoc component
         * @name sSelect
         * @restrict E
         *
         * @param {*}                           model
         * @param {Function}                    choices
         * @param {Function=}                   onHandleChange
         * @param {Function=}                   selectedTextFn
         * @param {Controller.Component.sSelect~isItemDisabled=}    isItemDisabled
         * @param {Function=}                   onCreateNew  Requires allowNew to be true
         *
         * @param {Function|String=}            sortBy
         * @param {Boolean=}                    searchEnabled
         * @param {Boolean=}                    asyncSearch
         * @param {Boolean=}                    ngRequired
         * @param {Boolean=}                    ngDisabled
         * @param {Boolean=}                    sMultiple
         * @param {Boolean|String=}             showTooltip
         * @param {Boolean=}                    noLazyLoading
         * @param {Boolean|String=}             allowNew
         * @param {String=}                     refreshEventName
         * @param {String=}                     trackBy
         * @param {String=}                     valueField
         * @param {String=}                     viewField
         * @param {String=}                     groupField
         * @param {Function=}                   compareFn
         * @param {String=}                     placeholder
         * @param {Number=}                     maxInputLength
         */
        .component('sSelect', {
            template : function($element, $attrs, $templateCache) {
                var template = $templateCache.get('_component:s-select'),
                    mdItemTemplate = $element.find('md-item-template').detach()
                ;

                // if we have `md-item-template` defined use it for md-autocomplete.
                // this is the only way of injecting a template for autocomplete right now
                if (mdItemTemplate.length) {
                    var $template = $(template);

                    $template.find('md-item-template').html(mdItemTemplate.html());
                    template = $template.wrapAll('<div>').parent().html();
                }

                return template;
            },
            controller  : Controller.Component.sSelect,
            bindings: {
                model               : '=',
                choices             : '&',
                onHandleChange      : '&',
                selectedTextFn      : '&',
                isItemDisabled      : '&',
                onCreateNew         : '&',
                filterFn            : '&',
                sortBy              : '<',
                searchEnabled       : '<',
                asyncSearch         : '<',
                ngRequired          : '<',
                ngDisabled          : '<',
                multiple            : '<sMultiple',
                showTooltip         : '<',
                noLazyLoading       : '<',
                allowNew            : '<',
                refreshEventName    : '@',
                trackBy             : '@',
                valueField          : '@',
                viewField           : '@',
                groupField          : '@',
                compareFn           : '<',
                placeholder         : '@',
                panelClass          : '@',
                maxInputLength      : '<'
            }
        })
        /**
         * @ngdoc component
         * @name fileUpload
         * @restrict E
         *
         * @param {String=} dndArea
         * @param {Function=} onUpload
         * @param {Object=} model
         * @param {String} accept
         * @param {String=} buttonText
         * @param {Boolean=} isRequired
         * @param {Number=} customMaxFileSize
         * @param {Boolean=} isDisabled
         */
        .component('fileUpload', {
            templateUrl : '_component:file-upload',
            controller  : Controller.Component.FileUploadController,
            transclude  : true,
            bindings    : {
                dndArea           : '@',
                onUpload          : '&',
                model             : '=?',
                accept            : '@',
                buttonText        : '@',
                isRequired        : '<',
                customMaxFileSize : '<?',
                isDisabled        : '<?'
            }
        })
        /**
         * @ngdoc component
         * @name imageUpload
         * @restrict E
         *
         * @param {String=} dndArea
         * @param {Function=} onUpload
         * @param {Object=} model
         * @param {Number=} maxDimension
         * @param {String} aspectRatio
         * @param {Boolean=} isRequired
         * @param {Boolean=} isDisabled
         */
        .component('imageUpload', {
            // This magic includes the file-upload component's template,
            // it is needed to maintain the transclude slot inside the included template,
            // which would otherwise be lost
            template : function($element, $attrs, $templateCache) {
                var $templateImageUpload = $($templateCache.get('_component:image-upload')),
                    $templateFileUpload  = $($templateCache.get('_component:file-upload'))
                ;

                $templateImageUpload.find('canvas').before($templateFileUpload);

                return $templateImageUpload.html();
            },
            controller  : Controller.Component.ImageUploadController,
            transclude  : true,
            bindings: {
                dndArea         : '@',
                maxDimension    : '<',
                aspectRatio     : '<',
                onUpload        : '&',
                model           : '=?',
                accept          : '@',
                buttonText      : '@',
                isRequired      : '=',
                isDisabled      : '<?'
            }
        })
        /**
         * @ngdoc component
         * @name sDynamicForm
         * @restrict E
         *
         * @param {Object} rules
         * @param {Object} model
         * @param {String} batchProperty
         * @param {String} componentName
         * @param {Object} helperControls
         */
        .component('sDynamicForm', {
            controller  : Controller.Component.sDynamicForm,
            bindings: {
                rules                       : '=',
                model                       : '=',
                batchProperty               : '<',
                componentName               : '<',
                helperControls              : '<'
            },
            require: {
                formCtrl: '^^form'
            }
        })
        /**
         * @ngdoc component
         * @name sDynamicFormRow
         * @restrict E
         *
         * @param {String=} label
         * @param {String=} labelClass
         * @param {String=} rowClass
         * @param {String} componentName
         * @param {String} key
         * @param {Object} helperControls
         */
        .component('sDynamicFormRow', {
            transclude: true,
            templateUrl: function($element, $attrs) {
                if ($attrs.template) {
                    return $attrs.template;
                }

                return '_component:s-dynamic-form-row';
            },
            bindings: {
                label           : '@',
                labelClass      : '@',
                rowClass        : '@',
                componentName   : '@',
                key             : '@',
                helperControls  : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sDynamicFormAccordion
         * @restrict E
         */
        .component('sDynamicFormAccordion', {
            controller: Controller.Component.sDynamicFormAccordionController,
            templateUrl: '_component:s-dynamic-form-accordion',
            require: {
                dynamicForm: '^^sDynamicForm'
            }
        })
        /**
         * @ngdoc component
         * @name sDynamicFormGroupedRow
         * @restrict E
         *
         * @param {Array} groups
         * @param {Object} model
         * @param {Object} helperControls
         */
        .component('sDynamicFormGroupedRow', {
            controller  : Controller.Component.sDynamicFormGroupedRowController,
            templateUrl : '_component:s-dynamic-form-grouped-row',
            require     : {
                dynamicForm : '^^sDynamicForm'
            },
            bindings    : {
                groups         : '<',
                model          : '=',
                helperControls : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sDynamicFormCollection
         * @restrict E
         *
         * @param {Object} model
         * @param {String} batchProperty
         */
        .component('sDynamicFormCollection', {
            templateUrl: function($element, $attrs) {
                if ($attrs.template) {
                    return $attrs.template;
                }

                return '_component:s-dynamic-form-collection'
            },
            bindings: {
                model: '=',
                batchProperty  : '<'
            },
            require: {
                dynamicForm: '^^sDynamicForm'
            }
        })
        /**
         * @ngdoc component
         * @name sEventPicker
         * @restrict E
         *
         * @param {Object}      model
         * @param {Boolean=}    isDisabled
         */
        .component('sEventPicker', {
            templateUrl : '_component:s-event-picker',
            controller  : Controller.Component.sEventPicker,
            bindings: {
                model       : '=',
                isDisabled  : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sNotificationFrequencySelect
         * @restrict E
         *
         * @param {Object}      model
         * @param {Object}      parentModel
         * @param {Boolean=}    isDisabled
         */
        .component('sNotificationFrequencySelect', {
            templateUrl : '_component:s-notification-frequency-select',
            controller  : Controller.Component.sNotificationFrequencySelect,
            bindings: {
                model       : '=',
                parentModel : '=',
                isDisabled  : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sMedia
         * @restrict E
         *
         * @param {Object} model
         * @param {Boolean} isRequired
         */
        .component('sMedia', {
            controller  : Controller.Component.sMediaController,
            templateUrl : '_component:s-media',
            bindings: {
                model       : '=',
                accept      : '@',
                isRequired  : '='
            }
        })
        /**
         * @ngdoc component
         * @name sMessageSelect
         * @restrict E
         *
         * @param {Object} model
         */
        .component('sMessageSelect', {
            templateUrl: '_component:s-message-select',
            bindings: {
                model: '=',
                isDisabled: '='
            },
            require: {
                sMessageRepository : '^^sMessageRepository'
            }
        })
        /**
         * @ngdoc component
         * @name sConversationSelect
         * @restrict E
         *
         * @param {Object} model
         * @param {Boolean=} isDisabled
         * @param {Boolean=} isAiTemplate
         * @param {Function=} onHandleChange
         */
        .component('sConversationSelect', {
            templateUrl: '_component:s-conversation-select',
            bindings: {
                model: '=',
                any: '<',
                isDisabled: '=',
                isRequired: '=',
                isAiTemplate: '=',
                onHandleChange : '<'
            },
            require: {
                sMessageRepository : '^^sMessageRepository'
            }
        })
        /**
         * @ngdoc component
         * @name sAction
         * @restrict E
         *
         * @param {Object} model
         */
        .component('sAction', {
            controller: Controller.Component.sAction,
            templateUrl: '_component:s-action',
            bindings: {
                model       : '='
            },
            require: {
                sActionRepositoryCtrl: '^sActionRepository'
            }
        })
        /**
         * @ngdoc component
         * @name sActionCollection
         * @restrict E
         *
         * @param {Object} model
         */
        .component('sActionCollection', {
            controller: Controller.Component.sActionCollection,
            templateUrl: '_component:s-action-collection',
            bindings : {
                model       : '='
            },
            require: {
                formCtrl    : '^^form',
                contextCtrl : '?^sActionContext'
            }
        })
        /**
         * @ngdoc component
         * @name sActionFields
         * @restrict E
         *
         * @param {Object} model
         * @param {ActionFieldDefinition[]} fieldsDefinition
         */
        .component('sActionFields', {
            controller: Controller.Component.sActionFields,
            templateUrl: '_component:s-action-fields',
            bindings: {
                model               : '=',
                fieldsDefinition    : '<',
                isDisabled          : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sCta
         * @restrict E
         *
         * @param {Object} model
         */
        .component('sCta', {
            controller: Controller.Component.sCTA,
            templateUrl: '_component:s-cta',
            bindings: {
                model       : '='
            }
        })
        /**
         * @ngdoc component
         * @name sCtaCollection
         * @restrict E
         *
         * @param {Object} model
         * @param {String} maxAllowedCta
         * @param {String} minAllowedCta
         * @param {Boolean=} withoutTitle
         * @param {Boolean=} withoutOffset
         */
        .component('sCtaCollection', {
            controller: Controller.Component.sCTACollectionController,
            templateUrl: '_component:s-cta-collection',
            require: {
                dynamicForm: '^^sDynamicForm'
            },
            bindings: {
                model           : '=',
                maxAllowedCta   : '@',
                minAllowedCta   : '@',
                withoutTitle    : '<',
                withoutOffset   : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sPasswordStrengthMeter
         * @restrict E
         *
         * @param {Object=} model
         */
        .component('sPasswordStrengthMeter', {
            controller: Controller.Component.sPasswordStrengthMeter,
            templateUrl: '_component:s-password-strength-meter',
            bindings: {
                model: '<'
            }
        })
        /**
         * @ngdoc component
         * @name sScoreMeter
         * @restrict E
         *
         * @param {Model.Behavior.QualityScore} model
         * @param {Number=}                     scoreSectionCount
         */
        .component('sScoreMeter', {
            controller: Controller.Component.sScoreMeter,
            templateUrl: '_component:s-score-meter',
            bindings: {
                model             : '<',
                scoreSectionCount : '<?'
            }
        })
        /**
         * @ngdoc component
         * @name sTextInput
         * @restrict E
         *
         * @param {Object} model
         * @param {Boolean=} withEmoji
         * @param {String} maxLength
         * @param {Boolean=} isRequired
         * @param {=} equalsTo
         * @param {String} inputType
         * @param {Boolean=} isDisabled
         * @param {String} placeholder
         * @param {Object=} ngModelOptions
         * @param {Boolean=} validatePassword
         * @param {Boolean=} validateUrl
         * @param {Function=} onHandleBlur
         * @param {Function=} onHandleFocus
         */
        .component('sTextInput', {
            controller: Controller.Component.sTextInputController,
            templateUrl: '_component:s-text-input',
            bindings: {
                model           : '=',
                withEmoji       : '<',
                maxLength       : '@',
                isRequired      : '<',
                equalsTo        : '<',
                inputType       : '@',
                isDisabled      : '<',
                placeholder     : '@',
                ngModelOptions  : '<',
                validatePassword: '<',
                validateUrl     : '<',
                onHandleBlur    : '&',
                onHandleFocus   : '&'
            }
        })
        /**
         * @ngdoc component
         * @name sCheckbox
         * @restrict E
         *
         * @param {Object} model
         */
        .component('sCheckbox', {
            templateUrl: '_component:s-checkbox',
            bindings: {
                model: '='
            }
        })
        /**
         * @ngdoc component
         * @name sSenderCheckList
         * @restrict E
         *
         * @param {Model.Sender|null} model
         * @param {Model.Sender[]} senders
         */
        .component('sSenderCheckList', {
            controller: Controller.Component.sSenderCheckList,
            templateUrl: '_component:s-sender-check-list',
            bindings: {
                model   : '=',
                senders : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sMessage
         * @restrict E
         *
         * @param {Model.Message.Message} model
         * @param {Number=} sequence
         * @param {Boolean=} showAnalytics
         * @param {Model.Menu.Menu=} messageControls
         * @param {Function=} onHandleCtaClick
         */
        .component('sMessage', {
            controller: Controller.Component.View.sMessage,
            templateUrl: '_component:view/s-message',
            bindings: {
                model                   : '=',
                sequence                : '<',
                showAnalytics           : '<',
                messageControls         : '<',
                onHandleCtaClick        : '&'
            },
            require: {
                sMessageLogCtrl: '?^sMessageLog'
            }
        })
        /**
         * @ngdoc component
         * @name sMessagePart
         * @restrict E
         *
         * @param {Model.Message.Part} model
         * @param {Boolean=} syncWithActiveGroup
         */
        .component('sMessagePart', {
            controller: Controller.Component.View.sMessagePart,
            templateUrl: '_component:view/s-message-part',
            bindings: {
                model                   : '=',
                syncWithActiveGroup     : '<'
            },
            require: {
                messageCtrl: '^?sMessage'
            }
        })
        /**
         * @ngdoc component
         * @name sContentGroup
         * @restrict E
         *
         * @param {Object} model
         * @param {Boolean=} groupUnHighlighted
         */
        .component('sContentGroup', {
            templateUrl: '_component:view/s-content-group',
            bindings: {
                model               : '=',
                groupUnHighlighted  : '@'
            },
            require: {
                messageCtrl: '?^^sMessage'
            }
        })
        /**
         * @ngdoc component
         * @name sCtaCollectionView
         * @restrict E
         *
         * @param {Model.CTA.Collection} model
         */
        .component('sCtaCollectionView', {
            controller: Controller.Component.View.sCTACollection,
            templateUrl: '_component:view/s-cta-collection',
            bindings: {
                model: '='
            },
            require: {
                messageCtrl         : '?^^sMessage',
                sMessageRepository  : '?^^sMessageRepository'
            }
        })
        /**
         * @ngdoc component
         * @name sMediaView
         * @restrict E
         *
         * @param {Model.sFile} model
         * @param {*} context
         */
        .component('sMediaView', {
            controller: Controller.Component.View.sMediaController,
            templateUrl: '_component:view/s-media',
            bindings: {
                model   : '<',
                context : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sMinimap
         * @restrict E
         *
         * @param {Object} model
         * @param {Object} messageLane
         * @param {Function} onNodeChange
         */
        .component('sMinimap', {
            controller: Controller.Component.sMinimap,
            templateUrl: '_component:s-minimap',
            bindings: {
                model           : '<',
                messageLane     : '=',
                onNodeChange    : '&'
            }
        })
        /**
         * @ngdoc component
         * @name sMarkersList
         * @restrict E
         *
         * @param {Object} model
         * @param {Boolean} showAnalytics
         */
        .component('sMarkersList', {
            controller  : Controller.Component.sMarkersList,
            templateUrl : '_component:s-markers-list',
            bindings    : {
                model         : '<',
                showAnalytics : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sConnection
         * @restrict E
         *
         * @param {String} from
         * @param {String} to
         * @param {String} appendTo
         */
        .component('sConnection', {
            transclude  : true,
            controller  : Controller.Component.sConnection,
            template    : '<ng-transclude></ng-transclude>',
            bindings: {
                from            : '<',
                to              : '<',
                appendTo        : '@'
            }
        })
        /**
         * @ngdoc component
         * @name sMessageActions
         * @restrict E
         *
         * @param {Object} model
         * @param {Model.Menu.Menu} menu
         */
        .component('sMessageActions', {
            templateUrl : '_component:s-message-actions',
            bindings: {
                model   : '='
            },
            require: {
                messageCtrl: '?^^sMessage'
            }
        })
        /**
         * @ngdoc component
         * @name sMenu
         * @restrict E
         *
         * @param {Model.Menu.Menu} model
         * @param {String} width
         * @param {Object=} context Specifies context of the menu
         */
        .component('sMenu' , {
            controller  : Controller.Component.sMenu,
            templateUrl : '_component:s-menu',
            bindings: {
                model:      '=',
                width:      '@',
                context:    '='
            }
        })
        /**
         * @ngdoc component
         * @name sTags
         * @restrict E
         *
         * @param {Object} model
         * @param {Boolean} readonly
         * @param {Boolean} isDisabled
         * @param {Boolean} existingOnly
         * @param {Number} maxLengthInput
         * @param {Object|Function} autocompleteSource
         * @param {Function} onTriggerSelect
         * @param {String} placeholder
         */
        .component('sTags' , {
            controller  : Controller.Component.sTags,
            templateUrl : '_component:s-tags',
            bindings: {
                model               : '=',
                readonly            : '<',
                isDisabled          : '<',
                existingOnly        : '<',
                maxLengthInput      : '<',
                autocompleteSource  : '<',
                onTriggerSelect     : '&',
                placeholder         : '@'
            }
        })
        /**
         * @ngdoc component
         * @name sPaginator
         * @restrict E
         *
         * @param {String} model
         * @param {Boolean=} readOnly
         */
        .component('sPaginator' , {
            controller  : Controller.Component.sPaginator,
            templateUrl : '_component:s-paginator',
            bindings: {
                model       : '<',
                readonly    : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sListItemCollection
         * @restrict E
         *
         * @param {Object} model
         * @param {String} itemCount
         */
        .component('sListItemCollection', {
            templateUrl: '_component:s-list-item-collection',
            bindings: {
                model       : '=',
                itemCount   : '@'
            }
        })
        /**
         * @ngdoc component
         * @name sAiRules
         * @restrict E
         *
         * @param {Object} model
         */
        .component('sAiRules', {
            templateUrl: '_component:s-ai-rules',
            bindings: {
                model: '='
            }
        })
        /**
         * @ngdoc component
         * @name sMessageLog
         * @restrict E
         *
         * @param {Model.Message.Collection} messageCollection
         * @param {Boolean=} disableDnd
         * @param {Boolean=} isInteractive
         * @param {Boolean=} silent Silent mode removes the add message part + so it is only visible once there is content already
         * @param {Boolean=} noPostbackMessages Removes postback_message option > prevents creation of new messages
         * @param {Model.Menu.Menu=} messageControls
         * @param {Function} onMessageLaneChange
         * @param {Boolean=} showAnalytics
         */
        .component('sMessageLog', {
            controller  : Controller.Component.sMessageLog,
            templateUrl : '_component:s-message-log',
            bindings    : {
                messageCollection   : '<',
                isInteractive       : '=',
                disableDnd          : '=',
                noPostbackMessages  : '=',
                silent              : '=',
                messageControls     : '<',
                onMessageLaneChange : '&',
                showAnalytics       : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sMessagePlaceholder
         * @restrict E
         *
         * @param {Object} model
         */
        .component('sMessagePlaceholder', {
            templateUrl: '_component:s-message-placeholder',
            bindings: {
                model : '='
            },
            require: {
                sMessageLogCtrl: '^^sMessageLog'
            }
        })
        /**
         * @ngdoc component
         * @name sMenuEdit
         *
         * @restrict E
         * @param {Object} ngModel
         * @param {Integer[]} maxElements
         * @param {Integer=} maxMenuDepth
         * @param {Array=} staticElements
         * @param {Function=} onSaveCallback
         */
        .component('sMenuEdit', {
            controller: Controller.Component.sMenuEdit,
            templateUrl: '_component:s-menu-edit',
            bindings: {
                ngModel         : '=',
                maxElements     : '<',
                maxMenuDepth    : '<',
                staticElements  : '=',
                onSaveCallback  : '&'
            },
            require: {
                'ngModelCtrl': '?ngModel'
            }
        })
        /**
         * @ngdoc component
         * @name sRadioList
         * @restrict E
         *
         * @param {Object} model
         * @param {Array} options
         * @param {Function=} onChange
         */
        .component('sRadioList', {
            templateUrl: '_component:s-radio-list',
            bindings: {
                model   : '=',
                options : '=',
                onChange: '&'
            }
        })
        /**
         * @ngdoc component
         * @name sRadioTabs
         * @restrict E
         *
         * @param {Object} model
         * @param {Array} options
         */
        .component('sRadioTabs', {
            templateUrl: '_component:s-radio-tabs',
            bindings: {
                model   : '=',
                options : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sSubMenu
         * @param {Model.Menu.Menu} model
         * @restrict E
         */
        .component('sSubMenu', {
            templateUrl: '_component:s-sub-menu',
            bindings: {
                model: '='
            }
        })
        /**
         * @ngdoc component
         * @name sReactionCollection
         *
         * @restrict E
         * @param {Object} model
         * @param {Object} activeReaction
         * @param {Function=} onEditReaction
         * @param {Boolean=} readonly
         */
        .component('sReactionCollection', {
            controller  : function ($scope) {
                this.$scope = $scope;
            },
            templateUrl : '_component:s-reaction-collection',
            bindings    : {
                model          : '=',
                activeReaction : '=',
                onEditReaction : '&',
                readonly       : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sReaction
         *
         * @restrict E
         * @param {Object} model
         * @param {Boolean} isDisabled
         */
        .component('sReaction', {
            controller: Controller.Component.sReaction,
            templateUrl: '_component:s-reaction',
            bindings: {
                model       : '=',
                isDisabled  : '='
            },
            require: {
                '$collCtrl': '^sReactionCollection'
            }
        })
        /**
         * @ngdoc component
         * @name sMemberData
         *
         * @restrict E
         * @param {String} memberId
         */
        .component('sMemberData', {
            controller: Controller.Component.sMemberData,
            templateUrl: '_component:s-member-data',
            bindings: {
                memberId: '<'
            }
        })
        /**
         * @ngdoc component
         * @name sPostbackAlternatives
         *
         * @restrict E
         * @param {Object} model
         */
        .component('sPostbackAlternatives', {
            controller: Controller.Component.sPostbackAlternatives,
            templateUrl: '_component:s-postback-alternatives',
            bindings: {
                model: '='
            }
        })
        /**
         * @ngdoc component
         * @name sMatchTextInput
         *
         * @restrict E
         * @param {String} model
         */
        .component('sMatchTextInput', {
            templateUrl: '_component:s-match-text-input',
            bindings: {
                model: '='
            }
        })
        /**
         * @ngdoc component
         * @name sMatchTextCollection
         *
         * @restrict E
         * @param {Model.AI.MatchTextCollection} model
         * @param {Number}                       min Required to control number of options
         * @param {Number}                       max Required to control number of options
         * @param {Boolean=}                     showQualityScore
         */
        .component('sMatchTextCollection', {
            controller: Controller.Component.sMatchTextCollection,
            templateUrl: '_component:s-match-text-collection',
            bindings: {
                model               : '=',
                min                 : '<',
                max                 : '<',
                showQualityScore    : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sMatchText
         *
         * @restrict E
         * @param {Model.AI.MatchText} model
         * @param {Boolean}            isNegative
         * @param {Boolean=}           showQualityScore
         */
        .component('sMatchText', {
            templateUrl : '_component:s-match-text',
            bindings    : {
                model               : '=',
                isNegative          : '=',
                showQualityScore    : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sMatchTextCollectionView
         *
         * @restrict E
         * @param {Model.AI.MatchTextCollection} model
         * @param {Model.AI.MatchText=} highlightMatchText
         */
        .component('sMatchTextCollectionView', {
            templateUrl : '_component:view/s-match-text-collection',
            controller  : Controller.Component.View.sMatchTextCollection,
            bindings    : {
                model              : '=',
                highlightMatchText : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sShortInformationButton
         *
         * @restrict E
         */
        .component('sShortInformationButton', {
            templateUrl : '_component:s-short-information-button',
            transclude  : true
        })
        /**
         * @ngdoc component
         * @name sPrioritySign
         * @param {Number} model
         * @param {Boolean} withTooltip
         *
         * @restrict E
         */
        .component('sPrioritySign', {
            templateUrl : '_component:s-priority-sign',
            bindings: {
                model       : '=',
                withTooltip : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sDomain
         * @param {Boolean} compact
         *
         * @restrict E
         */
        .component('sDomain', {
            controller  : Controller.Component.sDomain,
            templateUrl : '_component:s-domain',
            bindings: {
                compact : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sImg
         * @param {String} src
         * @param {Number} aspectRatio
         * @param {String=} fallbackSrc
         * @param {?String} fillingType
         *
         * @restrict E
         */
        .component('sImg', {
            controller  : Controller.Component.sImage,
            bindings: {
                src         : '<',
                aspectRatio : '<',
                fallbackSrc : '<?',
                fillingType : '@?'
            }
        })
        /**
         * @ngdoc component
         * @name sFilter
         * @restrict E
         *
         * @param controller
         * @param {Model.Filter.Collection=}    model
         * @param {FilterFields}                fields
         * @param {String=}                     queryPrefix
         * @param {Boolean=}                    readonly
         * @param {string=}                     label
         */
        .component('sFilter' , {
            controller  : Controller.Component.sFilter,
            templateUrl : '_component:s-filter',
            bindings    : {
                model       : '=',
                fields      : '<',
                queryPrefix : '<',
                readonly    : '<',
                label       : '<'
            },
            transclude: {
                'sFilterBtnSubmitLabel': '?sFilterBtnSubmitLabel'
            }
        })
        /**
         * @ngdoc component
         * @name sTable
         * @restrict E
         *
         * @param controller
         * @param {tableData}                   data
         * @param {Number=}                     fixedColumns
         * @param {Number=}                     fixedRows
         */
        .component('sTable', {
            controller : Controller.Component.sTable,
            bindings : {
                data            : '<',
                fixedColumns    : '<',
                fixedRows       : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sActionsPerformed
         * @restrict E
         *
         * @param controller
         * @param {Object.<String, *>[]}    actions
         */
        .component('sActionsPerformed', {
            controller: Controller.Component.View.sActionsPerformed,
            bindings: {
                actions        : '<'
            },
            templateUrl: '_component:view/s-actions-performed'
        })
        /**
         * @ngdoc component
         * @name sSegmentCollection
         * @restrict E
         *
         * @param controller
         * @param {String[]}    hashes
         */
        .component('sSegmentCollection', {
            controller: Controller.Component.View.sSegmentCollection,
            bindings: {
                hashes        : '<'
            },
            templateUrl: '_component:view/s-segment-collection'
        })
        /**
         * @ngdoc component
         * @name sSplitButton
         * @restrict E
         *
         * @param {Model.Menu.Menu} actions
         * @param {String=} menuClass
         */
        .component('sSplitButton', {
            transclude  : true,
            bindings    : {
                actions     : '<',
                menuClass   : '@'
            },
            require: {
                sButtonIndicatorCtrl: '?sButtonIndicator'
            },
            // inline controller, should not get extended or modified to ensure that it bridges the s-button-indicator properly
            controller: function ($scope, $element) {
                var self = this;
                var $removeListeners = [];
                this.$onInit = function $onInit() {
                    $removeListeners.push($scope.$on(Controller.Component.sMenu.EVENT_ACTION_INVOKED, function (event, actionResult) {
                        if (!self.sButtonIndicatorCtrl || !actionResult || !actionResult.always) {
                            return;
                        }

                        self.sButtonIndicatorCtrl.$busy = true;

                        actionResult.always(function () {
                            self.sButtonIndicatorCtrl.$busy = false;
                            self.sButtonIndicatorCtrl.digest();
                        });
                    }));

                    $removeListeners = $removeListeners.concat($element.$on('click', function($event) {
                        if ($event.target !== $event.currentTarget) {
                            return;
                        }
                        $event.preventDefault();
                        $event.stopImmediatePropagation();
                    }));
                };

                this.$onDestroy = function $onDestroy() {
                    var $destroyFn;
                    while (($destroyFn = $removeListeners.pop())) {
                        $destroyFn();
                    }
                };

            },
            templateUrl: '_component:s-split-button'
        })
        /**
         * @ngdoc component
         * @name sMemberAttributeCollection
         * @restrict E
         *
         * @param {Object[]}    attributes
         */
        .component('sMemberAttributeCollection', {
            controller: Controller.Component.sMemberAttributeCollection,
            bindings: {
                attributes  : '=',
                editable    : '<'
            },
            templateUrl: '_component:s-member-attribute-collection'
        })
        /**
         * @ngdoc component
         * @name sMemberAttributeSelect
         * @restrict E
         *
         * @param {Object[]}    model
         * @param {Boolean=}     isRequired
         * @param {Boolean=}     isDisabled
         * @param {Boolean=}     allowNew
         * @param {Boolean=}     keyOnly
         */
        .component('sMemberAttributeSelect', {
            controller: Controller.Component.sMemberAttributeSelect,
            bindings: {
                model       : '=',
                isDisabled  : '<',
                isRequired  : '<',
                allowNew    : '<',
                keyOnly     : '<'
            },
            templateUrl: '_component:s-member-attribute-select'
        })
        /**
         * @ngdoc component
         * @name reCaptcha
         * @restrict E
         *
         * @param {String}    siteKey
         * @param {String}    version
         */
        .component('reCaptcha', {
            controller: Controller.Component.ReCaptcha,
            bindings: {
                siteKey: '<',
                version: '<'
            },
            require: {
                formCtrl: '^^form'
            }
        })
        /**
         * @ngdoc component
         * @name sContentSourcePicker
         * @restrict E
         *
         * @param {Model.Source} model
         * @param {Number}       sourceTypeId
         * @param {Function=}    onHandleChange
         * @param {Boolean=}     isDisabled
         */
        .component('sContentSourcePicker', {
            controller  : Controller.Component.sContentSourcePicker,
            templateUrl : '_component:s-content-source-picker',
            bindings: {
                model          : '=',
                sourceTypeId   : '<',
                onHandleChange : '&?',
                isDisabled     : '<?'
            }
        })
        /**
         * @ngdoc component
         * @name sPrioritySelect
         * @restrict E
         *
         * @param {Number} model
         */
        .component('sPrioritySelect', {
            templateUrl : '_component:s-priority-select',
            bindings: {
                model: '='
            }
        })
        /**
         * @ngdoc component
         * @name sCopyToClipboardButton
         * @restrict E
         *
         * @param {String} text
         * @param {String=} tooltip
         */
        .component('sCopyToClipboardButton', {
            controller  : Controller.Component.sCopyToClipboardButton,
            templateUrl : '_component:s-copy-to-clipboard-button',
            bindings    : {
                text       : '<',
                buttonText : '@',
                tooltip    : '@'
            }
        })
        /**
         * @ngdoc component
         * @name sMarker
         * @restrict E
         *
         * @param {String}    model
         * @param {String=}   defaultName
         * @param {String=}   label
         * @param {String=}   orientation
         */
        .component('sMarker', {
            controller  : Controller.Component.sMarker,
            templateUrl : '_component:s-marker',
            bindings    : {
                model       : '=',
                defaultName : '@',
                label       : '@',
                orientation : '@'
            }
        })
        /**
         * @ngdoc component
         * @name sMarkerSelect
         * @restrict E
         *
         * @param {String}   model
         * @param {String=}  defaultName
         * @param {Boolean=} allowNew
         * @param {Boolean=} isRequired
         * @param {Boolean=} isDisabled
         */
        .component('sMarkerSelect', {
            controller  : Controller.Component.sMarkerSelect,
            templateUrl : '_component:s-marker-select',
            bindings    : {
                model          : '=',
                defaultName    : '@',
                onHandleChange : '&',
                allowNew       : '<',
                isRequired     : '<',
                isDisabled     : '<'
            }
        })
        /**
         * @ngdoc component
         * @name sResourceLimitInfo
         * @restrict E
         *
         * @param {String} endpointName
         * @param {String} resourceName
         */
        .component('sResourceLimitInfo', {
            /**
             * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
             */
            controller  : function (sAPIAccess) {
                this.$onInit = function $onInit() {
                    this.endpoint = sAPIAccess.endpoint(this.endpointName);
                };
            },
            templateUrl : '_component:s-resource-limit-info',
            bindings    : {
                endpointName : '@',
                resourceName : '@'
            }
        })
        /**
         * @ngdoc component
         * @name sProductCatalogEntrySelect
         * @restrict E
         *
         * @param {ProductCatalogEntry} model
         * @param {Boolean=} catalogSelector toggle whether to show catalog selector
         * @param {Boolean=} isRequired
         * @param {Boolean=} isDisabled
         */
        .component('sProductCatalogEntrySelect', {
            controller: Controller.Component.sProductCatalogEntrySelect,
            templateUrl: '_component:s-product-catalog-entry-select',
            bindings: {
                model           : '=',
                catalogSelector : '<?',
                isRequired      : '<',
                isDisabled      : '<',
            }
        })
    ;
})(window);
