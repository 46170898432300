(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sTextInputController
     * @constructor
     *
     * @param $attrs
     */
    var sTextInputController = function ($attrs) {
        this.inputName = 'input';
        if ($attrs.name) {
            this.inputName = $attrs.name;
        }
    };

    /**
     * @function
     * @name Controller.Component.sTextInputController#
     */
    sTextInputController.prototype.$onInit = function $onInit() {
        this.isDisabled     = this.isDisabled || false;
        this.ngModelOptions = this.ngModelOptions || {};
        this.validateUrl    = this.validateUrl === true;
    };

    ns.sTextInputController = sTextInputController;
})(Object.namespace('Controller.Component'));