(function(angular) {
    /**
     * Adds support for individual readonly chip(s) inside an md-chips component
     * @type {angular.Module}
     */

    var mdReadOnlyChipModule = angular.module('mdReadOnlyChipModule', ['ngMaterial']);

    var EVENT_CHECK_CHIP_REMOVEABLE = 'sEventCheckChipRemovable';

    (function (ns) {
        var sManagedChip = function($scope) {
            this.$deRegister = [];
            this.$scope      = $scope;
        };

        sManagedChip.prototype.$onInit = function $onInit() {
            // sign up for the parent broadcasted event to interrupt the deletion when needed
            this.$deRegister.push(this.$scope.$on(EVENT_CHECK_CHIP_REMOVEABLE, function (event, payload, carry) {
                if (payload !== this.$scope.$chip) {
                    return;
                }
                carry.objection = this.readOnly;
            }.bind(this)));

            // Overwrite parent's removeChip to become interruptable
            if (!this.mdChipsCtrl.hasOwnProperty('removeChip')) {
                this.mdChipsCtrl.removeChip = function removeChip(index) {
                    var objection = {objection: false};
                    this.mdChipsCtrl.$scope.$broadcast(EVENT_CHECK_CHIP_REMOVEABLE, this.mdChipsCtrl.items[index], objection);

                    if (objection.objection) {
                        return;
                    }

                    Object.getPrototypeOf(this.mdChipsCtrl).removeChip.apply(this.mdChipsCtrl, arguments);
                }.bind(this);
            }
        };

        sManagedChip.prototype.$onDestroy = function $onDestroy() {
            var $destroyFn;
            while (($destroyFn = this.$deRegister.pop())) {
                $destroyFn();
            }
        };

        ns.sManagedChip = sManagedChip;
    })(Object.namespace('Controller.Directive'));

    mdReadOnlyChipModule.directive('mdReadOnly', function () {
        return {
            require: {
                mdChipsCtrl : '^mdChips',
                mdChipCtrl  : '^mdChip'
            },
            bindToController: {
                readOnly: '=mdReadOnly'
            },
            controller: Controller.Directive.sManagedChip,
            controllerAs: '$mdReadOnlyCtrl'
        };
    });

    /**
     *
     */
    mdReadOnlyChipModule.run([
        '$injector',
        function($injector){
            var $mdChipsDirective = $injector.get('mdChipsDirective')[0];
            var ancestorTemplate = $mdChipsDirective.template;
            $mdChipsDirective.template = function(element, attrs) {
                var retVal = ancestorTemplate.apply($mdChipsDirective, arguments);
                if(attrs.mdReadOnlyChip === undefined) {
                    return retVal;
                }

                var $element = $(retVal),
                    $chips   = $element.find('md-chip')
                ;

                $chips.attr('md-read-only', attrs.mdReadOnlyChip);

                var ngClassAttr    = $chips.attr('ng-class') || '{}',
                    $removeElement = $element.find('div.md-chip-remove-container'),
                    ngRemoveIf     = $removeElement.attr('ng-if') || ''
                ;

                $chips.attr('ng-class', ngClassAttr.replace('}', ", 'read-only' : $mdReadOnlyCtrl.readOnly}"));
                $chips.append('<div ng-if="$mdReadOnlyCtrl.readOnly" class="md-chip-remove-container">' +
                    '<md-tooltip>This action is managed from somewhere else.</md-tooltip>' +
                    '<i class="fal fa-lock-alt"></i></div>');

                ngRemoveIf += (ngRemoveIf.length ? ' && ' : '') + ' !$mdReadOnlyCtrl.readOnly';

                $removeElement.attr('ng-if', ngRemoveIf);

                retVal = $('<textarea />').html($element[0].outerHTML).text();

                return retVal;
            };
        }]);
})(angular);
