(function (ns) {
    /**
     * @namespace
     * @alias sSettings.Route.Platform.InstagramDirect
     * @extends sSettings.Route.Base
     * @constructor
     */
    var InstagramDirect = function() {
        this.controller     = sSettings.Controller.Platform.InstagramDirect;
        this.templateUrl    = 'ssettings:platform/instagram_direct';
        this.controllerAs   = '$ctrl';
        this.accessControl  = function (sAPIAccess) {
            return (sAPIAccess.isAllowed('sender.instagramDirect.all', Const.Method.GET));
        };

        InstagramDirect._pProto.constructor.apply(this, arguments);
    };

    Object.extendProto(InstagramDirect, sSettings.Route.Base);

    /**
     * @function
     * @name sSettings.Route.Platform.InstagramDirect#resolveFacebookSDK
     * @param {sFacebook.Service.sFacebookClient} sFacebookClient
     * @returns {$.Deferred}
     */
    InstagramDirect.prototype.resolveFacebookSDK = function resolveFacebookSDK(sFacebookClient) {
        return sFacebookClient.whenSDKReady();
    };

    ns.InstagramDirect = InstagramDirect;
})(Object.namespace('sSettings.Route.Platform'));
