(function (ns) {
    /**
     * @namespace
     * @alias Model.RESTAccessSubResource
     *
     * @constructor
     * @extends Model.RESTAccessByUUID
     * @param {Model.RESTAccessByUUID} parent
     * @param {String} uuid
     */
    var RESTAccessSubResource = function (parent, uuid) {
        Object.instanceOf(parent, Model.RESTAccessByUUID);

        Object.defineProperties(
            this,
            {
                parent: {
                    enumerable: true,
                    get: function () {
                        return parent;
                    }
                    /**
                     * @property
                     * @name Model.RESTAccessSubResource#parent
                     * @type {Model.RESTAccessByUUID}
                     */
                }
            });

        // base endpoint on the parent resource
        this.endPoint = parent.endPoint + parent.uuid + this.endPoint;

        RESTAccessSubResource._pProto.constructor.call(this, uuid);

        var parentGetCloneArguments = this.__getCloneArguments;
        this.__getCloneArguments = function __getCloneArguments() {
            return [parent].concat(parentGetCloneArguments.call(this));
        }
    };

    Object.extendProto(RESTAccessSubResource, Model.RESTAccessByUUID);

    ns.RESTAccessSubResource = RESTAccessSubResource;
})(Object.namespace('Model'));