(function(ns) {
    /**
     * @namespace
     * @alias Controller.sMessageAdmin.IndexController
     * @param $scope
     * @param {sContextNavigationMenu.Service.sContextNavigationMenu} sContextNavigationMenu
     * @param {Service.sMessageCollection} sMessageCollection
     * @param {Model.Message.Collection} messageCollection
     * @param $route
     * @param moment
     * @param sDomainService
     * @param $rootScope
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {$sce} $sce
     * @param $location
     * @constructor
     */
    var IndexController = function(
        $scope,
        sContextNavigationMenu,
        sMessageCollection,
        messageCollection,
        $route,
        moment,
        sDomainService,
        $rootScope,
        sAPIAccess,
        $sce,
        $location
    ) {
        this.$scope                 = $scope;
        this.sContextNavigationMenu = sContextNavigationMenu;
        this.sMessageCollection     = sMessageCollection;
        this.toolbar                = false;
        this.parameters             = $route.current.params;
        this.moment                 = moment;
        this.validators             = [];
        this.editable               = true;
        this.collection             = messageCollection;
        this.messageAnchor          = this.collection.getAnchor();
        this.$rootScope             = $rootScope;
        this.$location              = $location;
        this.$deRegister            = [];
        this.$deRegisterForIsNew    = null;

        /**
         * @name Controller.sMessageAdmin.IndexController#showAnalytics
         */
        this.showAnalytics          = false;

        /**
         * @name Controller.sMessageAdmin.IndexController#analyticsVersion
         */
        this.analyticsVersion = 0;

        /**
         * @name Controller.sMessageAdmin.IndexController#analyticsVersions
         */
        this.analyticsVersions = [];

        /**
         * @name Controller.sMessageAdmin.IndexController#analyticsVersionMenu
         */
        this.analyticsVersionMenu = null;

        /**
         * @name Controller.sMessageAdmin.IndexController#analyticsIsLoading
         */
        this.analyticsIsLoading = false;

        var self = this,
            msg
        ;

        this.tagsAutocompleteSource = sAPIAccess.endpoint('tagging.byDomain.autocomplete.anchor').get(sDomainService.currentDomainId, 'anchor');

        this.messageLane = [];

        if (this.collection.getRootMessage().isNew) {
            this.$deRegisterForIsNew = $scope.$watch(
                function () {
                    return self.collection.getRootMessage().isNew;
                },
                function (isNew) {
                    if (isNew) {
                        return;
                    }
                    self.$deRegisterForIsNew();
                    self.$location.search('asConversation', null);
                    self.$location.path('/creator/' + self.collection.getRootMessage().uuid);
                }
            );
        }

        this.$deRegister.push(this.$scope.$on('content-changed', function() {
            self.$rootScope.$broadcast('redraw-minimap');
        }));

        this.$deRegister.push(this.$rootScope.$on(Controller.Component.sMinimap.EVENT_MODEL_CHANGED, function (event, data) {
            this.collection = data;
        }.bind(this)));

        this.$deRegister.push(
            this.$rootScope.$on(
                Model.Marker.EVENT_MARKER_MODEL_CHANGE,
                function ($event, newVal, oldVal) {
                    $event.conversationId = this.messageAnchor.messageUuid;
                    $rootScope.$broadcast('sMessageAdminMarkerChanged', $event, newVal, oldVal);
                }.bind(this)
            )
        );

        // display the edit dialog if it is an AI-Template
        if ((msg = this.collection.getRootMessage()) && msg.isIncoming) {
            setTimeout(function() {
                $scope.$broadcast('edit-message-part', msg.firstPart());
            }, 1);
        }

        var isAllowedHashChange = function () {
            if(!self.$location.hash()) {
                return false;
            }

            var allowedHashes = [Model.Message.REGEX_MESSAGE_URL_HASH];

            var result = allowedHashes.filter(function (hashRegex) {
                return self.$location.hash().match(hashRegex);
            });

            return result.length;
        };

        this.$deRegister.push($rootScope.$on('$routeUpdate',
            /**
             * @param {Event} $event
             * @param {Object} route
             */
            function ($event, route) {
                if (route.params.uuid !== 'new' || isAllowedHashChange()) {
                    return;
                }

                $route.reload();
            }
        ));

        this.$deRegister.push($rootScope.$on('$routeChangeStart',
            /**
             * @param {Event} $event
             * @returns {boolean}
             */
            function ($event) {
                if (self.collection.isModified()) {
                    if (!window.confirm('You have unsaved changes, if you continue you will lose them!')) {
                        $event.preventDefault();
                        return false;
                    }
                }
            }));

        // push into 1st spot in the listeners queue
        /** @name $rootScope.$$listeners.$routeChangeStart
         *  @type Array
         */
        $rootScope.$$listeners.$routeChangeStart.unshift($rootScope.$$listeners.$routeChangeStart.pop());

        this.$deRegister = this.$deRegister.concat($(window).$on('beforeunload', function() {
            if (!self.collection.isModified()) {
                return;
            }

            return true;
        }));

        this.$deRegister.push(this.$rootScope.$on('sEventMessageTypeToggle',
            /**
             * @param event
             * @param {Model.Message} data
             */
            function (event, data) {
                self.$location.search('asConversation', data.isIncoming ? null : '1');
            }));

        this.$deRegister.push($scope.$on(
            Controller.Component.sMessageCollectionActionBox.EVENT_MESSAGE_ACTION_BOX_ACTION_IN_PROGRESS,
            this.handleActionInProgressChange.bind(this)
        ));

        this.$scope.$on('$destroy', this.$onDestroy.bind(this));

        this.messageControls = new Model.Menu.Menu();

        this.messageControls
            .addAction(
                $sce.trustAsHtml('<i class="fa fa-pencil-alt fa-pencil"></i>'),
                function() {
                    $scope.$broadcast.apply($scope, ['edit-message-part'].concat(Array.prototype.slice.call(arguments, 0)));
                },
                {
                    isEdit: true,
                    tooltip: 'Edit message'
                }
            )
            .addAction(
                $sce.trustAsHtml('<i class="fa fa-trash-alt fa-trash danger"></i>'),
                function(messagePart) {
                    $scope.$broadcast.apply($scope, ['remove-message-part'].concat(Array.prototype.slice.call(arguments, 0)));
                },
                {
                    tooltip: 'Remove message'
                }
            );

        this.initAnalytics();
    };

    IndexController.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;

        this.$deRegisterForIsNew && this.$deRegisterForIsNew();

        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }

        if (this.fetchAnalyticsAction) {
            this.sContextNavigationMenu.removeItem(this.fetchAnalyticsAction);
        }
    };

    /**
     * @name Controller.sMessageAdmin.IndexController#initAnalytics
     */
    IndexController.prototype.initAnalytics = function initAnalytics() {
        var self = this;

        if (this.collection instanceof Model.Message.Collection) {
            if (!this.collection._getVersions) {
                Model.Versionable.call(this.collection);
            }

            if (this.messageAnchor && this.messageAnchor.isActivated()) {
                this.analyticsIsLoading = true;
                this.sMessageCollection
                    .fetchAnalyticsVersions(this.collection)
                    .then(function (analyticsVersions) {
                        self.analyticsVersions = analyticsVersions;
                        self.analyticsVersion  = analyticsVersions.length ? analyticsVersions.length - 1 : 0;
                        self.updateAnalyticsVersionMenu();
                    })
                    .always(function () {
                        self.analyticsIsLoading = false;
                        digestIfNeeded(self.$scope);
                    });
            }

            this.fetchAnalyticsAction = new Model.Menu.ContextAction(
                function () {
                    var anchor = self.collection.getAnchor();
                    return anchor && anchor.isActivated();
                },
                function () {
                    var latestVersion = self.analyticsVersions.length ? self.analyticsVersions.length - 1 : 0;

                    if (self.showAnalytics) {
                        self.showAnalytics = false;
                        return null;
                    }

                    return self.enableAnalytics.call(self, latestVersion);
                },
                {
                    label     : 'Analytics',
                    icon      : '<i class="fal fa-chart-pie"></i>',
                    isActive  : function () {
                        return self.showAnalytics;
                    },
                    isLoading : function () {
                        return self.analyticsIsLoading;
                    },
                    submenu   : function () {
                        return self.analyticsVersionMenu;
                    }
                }
            );

            this.sContextNavigationMenu.insertItem(this.fetchAnalyticsAction, 0);
        }
    };

    /**
     * @function
     * @name Controller.sMessageAdmin.IndexController#updateAnalyticsVersionMenu
     */
    IndexController.prototype.updateAnalyticsVersionMenu = function updateAnalyticsVersionMenu() {
        var self = this,
            menu = new Model.Menu.Menu(),
            label
        ;

        for (var i in this.analyticsVersions) {
            if (this.analyticsVersion === this.analyticsVersions[i].version) {
                continue;
            }

            label = 'version ' + (this.analyticsVersions[i].version+1) + ' - ';
            label += moment(self.analyticsVersions[i].time).format('DD.MM.YYYY HH:mm');

            menu.addAction(
                label,
                (function (version) { return function () {
                    return self.enableAnalytics.call(self, version);
                }})(self.analyticsVersions[i].version)
            );
        }

        this.analyticsVersionMenu = menu;
        digestIfNeeded(this.$scope);
    };


    /**
     * @function
     * @name Controller.sMessageAdmin.IndexController#enableAnalytics
     * @param {Number} version
     * @returns {$.Deferred|null}
     */
    IndexController.prototype.enableAnalytics = function enableAnalytics(version) {
        var self = this;

        self.$scope.$emit(
            'eventConversationAnalyticsOpened',
            self.collection.getAnchor().uuid,
            self.analyticsVersions[version]
        );

        this.analyticsIsLoading = true;
        return this.sMessageCollection
            .fetchAnalyticsData(this.collection, this.$scope, version)
            .then(function () {
                self.analyticsVersion   = version;
                self.showAnalytics      = true;
                self.updateAnalyticsVersionMenu();
            })
            .always(function () {
                self.analyticsIsLoading = false;
                digestIfNeeded(self.$scope);
            });
    };

    IndexController.prototype.onMessageLaneChange = function onMessageLaneChange(items) {
        this.messageLane = items;
    };

    /**
     * @function
     * @name Controller.sMessageAdmin.IndexController#handleActionInProgressChange
     * @param {Event} $event
     * @param {Boolean} actionInProgress
     */
    IndexController.prototype.handleActionInProgressChange = function handleActionInProgressChange($event, actionInProgress) {
        this.editable = !actionInProgress;
    };

    /**
     * @function
     * @name Controller.sMessageAdmin.IndexController#triggerRedrawConnections
     */
    IndexController.prototype.triggerRedrawConnections = function triggerRedrawConnections() {
        this.$scope.$broadcast('redraw-connection');
    };

    /**
     * @function
     * @name Controller.sMessageAdmin.IndexController#addSimpleText
     * @param content
     */
    IndexController.prototype.addSimpleText = function addSimpleText(content) {
        this.$scope.$broadcast(Controller.Component.sMessageLog.EVENT_ADD_MESSAGE_BY_CONTENT, content);
        this.$rootScope.$broadcast('redraw-minimap');
    };

    /**
     * @name Controller.sMessageAdmin.IndexController#handleUpload
     * @param files
     */
    IndexController.prototype.handleUpload = function onUpload(files) {
        this.$scope.$broadcast('trigger-handle-upload', files);
    };

    IndexController.prototype.showDebugInfo = function showDebugInfo() {
        console.log(this.collection, this.messageLane);
    };

    ns.IndexController = IndexController;

})(Object.namespace('Controller.sMessageAdmin'));
