(function (ns) {
    var TYPE_PROCESSED_ARCHIVED = 'archived',
        TYPE_PROCESSED_MANUAL = 'manual',
        TYPE_PROCESSED_ADDED_TO_NEW = 'added-to-new',
        TYPE_PROCESSED_ADDED_TO_EXISTING = 'added-to-existing'
    ;

    /**
     * @namespace
     * @alias Model.ApiMessage
     *
     * @param uuid
     * @extends Model.Message
     */
    var ApiMessage = function (uuid) {
        var changeRequests = []
        ;

        /**
         * @property
         * @type {Object}
         * @name Model.ApiMessage#processedMeta
         */
        this.processedMeta = null;

        /**
         * @property
         * @type {Object}
         * @name Model.ApiMessage#matching
         */
        this.matching = null;

        Object.defineProperties(
            this,
            {
                endPoint : {
                    configurable : true,
                    enumerable   : false,
                    get          : function () {
                        return Model.ApiMessage.endPoint;
                    }
                    /**
                     * @property
                     * @type {String}
                     * @name Model.RESTAccessByUUID#endPoint
                     */
                },

                changeRequests : {
                    enumerable : true,
                    get        : function () {
                        return changeRequests;
                    }
                },

                TYPE_PROCESSED_ARCHIVED : {
                    get : function () {
                        return TYPE_PROCESSED_ARCHIVED;
                    }
                    /**
                     * @property
                     * @type {String}
                     * @name Model.ApiMessage#TYPE_PROCESSED_ARCHIVED
                     */
                },

                TYPE_PROCESSED_ADDED_TO_NEW : {
                    get : function () {
                        return TYPE_PROCESSED_ADDED_TO_NEW;
                    }
                    /**
                     * @property
                     * @type {String}
                     * @name Model.ApiMessage#TYPE_PROCESSED_ADDED_TO_NEW
                     */
                },

                TYPE_PROCESSED_ADDED_TO_EXISTING : {
                    get : function () {
                        return TYPE_PROCESSED_ADDED_TO_EXISTING;
                    }
                    /**
                     * @property
                     * @type {String}
                     * @name Model.ApiMessage#TYPE_PROCESSED_ADDED_TO_EXISTING
                     */
                }
            }
        );

        var protecteds = ApiMessage._pProto.constructor.apply(this, arguments);

        protecteds.setAsNew(false);

        this.setProcessed = function setProcessed() {
            changeRequests.push({'processingType' : TYPE_PROCESSED_MANUAL});
        };

        this.setArchived = function setArchived() {
            changeRequests.push({'processingType' : TYPE_PROCESSED_ARCHIVED});
        };

        /**
         * @param {string} AITemplateUuid
         */
        this.setAddedToNew = function setAddedToNew(AITemplateUuid) {
            changeRequests.push({
                'processingType' : TYPE_PROCESSED_ADDED_TO_NEW,
                'processedMeta'  : {'AITemplateUuid' : AITemplateUuid}
            });
        };

        /**
         * @param {string} AITemplateUuid
         */
        this.setAddedToExisting = function setAddedToExisting(AITemplateUuid) {
            changeRequests.push({
                'processingType' : TYPE_PROCESSED_ADDED_TO_EXISTING,
                'processedMeta'  : {'AITemplateUuid' : AITemplateUuid}
            });
        };
    };

    Object.extendProto(ApiMessage, Model.Message);

    ApiMessage.prototype.getFormData = function getFormData(formData, name) {

        var fData = Model.Message._pProto.getFormData.call(this, formData),
            msgData = this.changeRequests.reduce(function (carry, element) {
                    carry = $.extend(carry, element);
                    return carry;
                },
                {'uuid' : this.uuid})
        ;

        if (name && name instanceof Object) {
            name['dataName'] = 'message';
            name = JSON.stringify(name).bin2hex();
        }

        fData.append(name ? name : 'message', JSON.stringify(msgData));

        return fData;
    };

    ApiMessage.prototype.updateByData = function updateByData(data) {
        if (data.matching) {
            this.matching = data.matching;
        }

        if (data.processedMeta) {
            this.processedMeta = data.processedMeta;
        }

        ApiMessage._pProto.updateByData.apply(this, arguments);

        return this;
    };

    ns.ApiMessage = ApiMessage;
})(Object.namespace('Model'));
