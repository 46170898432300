(function(ns) {
    /**
     * @namespace
     * @alias Model.Exception.MultipleInteractiveParts
     *
     * @param {Model.Message.Part} messagePart
     * @param {Model.Message} message
     * @constructor
     */
    var MultipleInteractiveParts = function(messagePart, message) {
        Object.instanceOf(messagePart, Model.Message.Part);
        Object.instanceOf(message, Model.Message);

        this.name           = 'MultipleInteractiveParts';
        this.message        = 'Message needs to be split to continue';
        this.target         = message;

        this.messagePart    = messagePart;
    };

    Object.extendProto(MultipleInteractiveParts, Error);

    ns.MultipleInteractiveParts = MultipleInteractiveParts;

})(Object.namespace('Model.Exception'));