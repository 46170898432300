(function (ns) {
    /**
     * @namespace
     * @alias Model.List
     *
     * @param {String} endPoint
     * @param {Number=} defaultRecordsPerPage
     *
     * @extends Model.Series
     * @constructor
     */
    var List = function(endPoint, defaultRecordsPerPage) {
        List._pProto.constructor.call(this, endPoint);
        var pager           = new Model.Paginator(null, defaultRecordsPerPage),
            orderBy         = {},
            columns         = new Model.List.Column.Collection()
        ;

        Object.defineProperties(
            this,
            {
                pager : {
                    get : function() {
                        return pager;
                    }
                    /**
                     * @name Model.List#records
                     * @property
                     * @type {Model.List.Paginator}
                     */
                },
                orderBy : {
                    get: function() {
                        if (!$.isEmptyObject(this.defaultOrderBy) && $.isEmptyObject(orderBy)) {
                            return $.extend({}, this.defaultOrderBy);
                        } else {
                            return $.extend({}, orderBy);
                        }
                    }
                    /**
                     * @name Model.List#orderBy
                     * @property
                     * @type {Object}
                     */
                },
                columns : {
                    get: function() {
                        return columns;
                    }
                    /**
                     * @name Model.List.Column.Collection#columns
                     * @property
                     * @type {String}
                     */
                },
                hasEmptyState : {
                    get: function() {
                        return !this.records.length && !this.loading && !this.filters.editableFilters.length;
                    }
                    /**
                     * @name Model.List.Column.Collection#hasEmptyState
                     * @property
                     * @type {Boolean}
                     */
                }
            }
        );

        /**
         * @function
         * @name Model.List#resetOrderBy
         * @description Resets the orderBy object
         * @return {Boolean} True if it was really reset, false if reset was not needed
         */
        this.resetOrderBy = function resetOrderBy() {
            if ($.isEmptyObject(orderBy)) {
                return false;
            }
            orderBy = {};

            return true;
        };

        this.addOrderBy = function addOrderBy(name, type) {
            orderBy[name] = type;
        };

        this.removeOrderBy = function removeOrderBy(name) {
            if (orderBy.hasOwnProperty(name)) {
                delete orderBy[name];
            }
        };
    };

    Object.extendProto(List, Model.Series);

    List.prototype.getQueryParameters = function getQueryParameters() {
        var orderBy = this.orderBy,
            queryParams = {
                page: this.pager.currentPage,
                rpp : this.pager.recordsPerPage
            };

        if (!$.isEmptyObject(orderBy)) {
            queryParams['orderBy'] = orderBy;
        }
        return $.extend(queryParams, List._pProto.getQueryParameters.call(this));
    };

    /**
     * @name Model.List
     * @param {{ current_page, records_per_page, records_total, pages_total }} meta
     */
    List.prototype.parseMeta = function parseMeta(meta) {
        if (!(meta instanceof Object)) {
            return;
        }

        this.pager.recordsPerPage   = meta.records_per_page;
        this.pager.totalRecords     = meta.records_total;
        this.pager.pagesTotal       = meta.pages_total;
        this.pager.currentPage      = meta.current_page;
    };

    /**
     * @param {Object} queryParams
     * @returns {$.Deferred|Promise}
     */
    List.prototype.load = function load(queryParams) {
        var self = this;

        return List._pProto.load.call(this, queryParams).then(
            /**
             * @param {{_meta, records}} responseData
             * @return {Array}
             */
            function(responseData) {
                if (!responseData || !(responseData instanceof Object)) {
                    return null;
                }
                self.parseMeta(responseData._meta);
                if (!responseData.records) {
                    return [];
                }

                return responseData.records;
            }
        );
    };

    ns.List = List;
})(Object.namespace('Model'));


