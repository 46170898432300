(function(ns) {
    var TYPE_NLP            = 'NLP',
        TYPE_FOLLOWED_BY    = 'followedBy'
    ;

    /**
     * @namespace
     * @alias Model.Message.Relation
     * @param uuid
     * @constructor
     * @extends Model.RESTAccessByUUID
     */
    var Relation = function(uuid) {
        var sides = {},
            type,
            options                 = {},
            self                    = this,
            associatedCTA           = null,
            addRelationToMessage    = function(msg, side) {
                if (side !== 'to' && side !== 'from') {
                    throw 'Invalid argument!';
                }

                if (msg === null  && sides[side]) {
                    self.removeMessage(sides[side]);
                    return;
                } else if (msg === null) {
                    return;
                }
                Object.instanceOf(msg, Model.Message);

                if (!sides[side] || sides[side].uuid !== msg.uuid) {
                    if (sides[side]) {
                        sides[side].removeRelation(self);
                    }

                    sides[side] = msg;

                    if (sides.from && sides.to) {
                        if (sides.from.isIncoming === sides.to.isIncoming) {
                            var newMsg = Model.Message.createMessageForRelation(self);
                            self.to = newMsg;
                            return newMsg.getRelationsFrom().pop().to = msg;
                        }
                    }

                    msg.addRelation(self);
                }
            }
            ;

        this.removeMessage = function removeMessage(msg, followRelation) {
            Object.instanceOf(msg, Model.Message);

            for (var i in sides) {
                if (sides[i] && sides[i].uuid === msg.uuid) {
                    sides[i] = null;
                    msg.removeRelation(self);
                } else if (followRelation && sides[i]) {
                    if (sides[i].isIncoming && sides[i].isForwardAction()) {
                        sides[i].removeRelations(followRelation);
                    } else {
                        sides[i].removeRelation(self);
                    }
                    sides[i] = null;
                }
            }
        };

        Object.defineProperties(
            this,
            {

                from : {
                    enumerable  : false,
                    set         : function(msg) {
                        addRelationToMessage(msg, 'from');
                        associatedCTA = undefined;
                    },
                    get         : function () {
                        return sides['from'];
                    }
                    /**
                     * @property
                     * @type {Model.Message}
                     * @name Model.Message.Relation#from
                     */
                },
                to : {
                    enumerable  : false,
                    set         : function(msg) {
                        addRelationToMessage(msg, 'to');
                    },
                    get         : function () {
                        return sides['to'];
                    }
                    /**
                     * @property
                     * @type {Model.Message}
                     * @name Model.Message.Relation#to
                     */
                },
                type : {
                    enumerable  : true,
                    set         : function(val) {
                        type = val;
                    },
                    get         : function() {
                        return type;
                    }
                    /**
                     * @property
                     * @type {String}
                     * @name Model.Message.Relation#type
                     */
                },
                options : {
                    enumerable  : true,
                    set         : function(val) {
                        if (val === options) {
                            return;
                        }
                        options         = val;
                        associatedCTA   = undefined;
                    },
                    get         : function() {
                        return options;
                    }
                    /**
                     * @property
                     * @type {Object}
                     * @name Model.Message.Relation#options
                     */
                },
                fromUuid: {
                    enumerable  : true,
                    get         : function() {
                        return sides['from'] ? sides['from'].uuid : null;
                    }
                    /**
                     * @property
                     * @type {String}
                     * @name Model.Message.Relation#fromUuid
                     */
                },
                toUuid: {
                    enumerable  : true,
                    get         : function() {
                        return sides['to'] ? sides['to'].uuid : null;
                    }
                    /**
                     * @property
                     * @type {String}
                     * @name Model.Message.Relation#toUuid
                     */
                },
                distanceCost: {
                    enumerable  : false,
                    get         : function() {
                        // more logic can be added here i.e. cost based on type
                        return 1;
                    }
                    /**
                     * @property
                     * @type {int}
                     * @name Model.Message.Relation#distanceCost
                     */
                },
                associatedCTA: {
                    enumerable: true,
                    get: function () {
                        if (associatedCTA === undefined) {
                            if (self.from.isIncoming) {
                                associatedCTA = self.from.getCTAByActionUuid(options.uuid);
                            } else {
                                associatedCTA = self.from.getCTAByUuid(options.CTAuuid);
                            }
                        }
                        return associatedCTA;
                    }
                    /**
                     * @property
                     * @name Model.Message.Relation#associatedCTA
                     * @type {Model.CTA}
                     */
                }
                
            }
        );

        // set endpoint
        this.endPoint = Relation.endPoint;

        this.updateByData = function updateByData(data) {
            options = {};
            Relation.prototype.updateByData.call(this, data);
        };

        var protecteds = Relation._pProto.constructor.call(this, uuid);

        // TODO: remove with IT-5459
        this.setAsNew = function setAsNew() {
            protecteds.setAsNew(true);
        };

        this.resetAssociatedCTACache = function resetAssociatedCTACache() {
            associatedCTA = undefined;
        }

        /**
         * @property {String}
         * @name Model.Message.Relation#uuid
         */

        /**
         * @property
         * @type {boolean}
         * @name Model.Message.Relation#isNew
         */

        /**
         * @function
         * @name Model.Message.Relation#delete
         */

        /**
         * @name Model.Message.Relation#_pProto
         * @property _pProto
         * @type {Object}
         * @static
         */
    };

    Object.extendProto(Relation, Model.RESTAccessByUUID);

    /**
     * @param {FormData=} formData
     * @param name
     * @returns {*}
     */
    Relation.prototype.getFormData = function getFormData(formData, name) {

        var fData = Relation._pProto.getFormData.call(this, formData),
            msgRelationData = this.getRelationData()
        ;

        if (name && name instanceof Object) {
            name['dataName'] = 'messageRelation';
            name = JSON.stringify(name).bin2hex();
        }

        fData.append(name ? name : 'messageRelation', JSON.stringify(msgRelationData));

        return fData;
    };

    /**
     * Updates the message-relation by data
     * @function Model.Message.Relation#updateByData
     * @param {object} relationData
     * @param {Model.Message=} msgFrom
     * @param {Model.Message=} msgTo
     */
    Relation.prototype.updateByData = function updateByData(relationData, msgFrom, msgTo) {
        if (msgFrom) {
            this.from = msgFrom;
        }

        if (msgTo) {
            this.to = msgTo;
        }

        for (var i in relationData.options) {
            this.options[i] = (relationData.options[i]);
        }

        this.type = relationData.options.type || Relation.TYPE_FOLLOWED_BY;

        // TODO: this is needed because the CTA puts the same key into the KV store overwriting the original
        if (this.type === Const.PostbackMessage) {
            this.type = Relation.TYPE_FOLLOWED_BY;
        }

        Relation._pProto.updateByData.call(this);
    };

    /**
     * @return {{options: {}, messageFromUuid: String, uuid: String, messageToUuid: String}}
     */
    Relation.prototype.getRelationData = function getRelationData() {
        return {
            'uuid':             this.uuid,
            'options':          $.extend(true, {}, this.options, {type: this.type}),
            'messageFromUuid':  this.fromUuid,
            'messageToUuid':    this.toUuid
        }
    };

    /**
     * Creates a message-relation by data
     * @function Model.Message.Relation#createRelationByData
     * @param {object} relationData
     * @param {Model.Message} msgFrom
     * @param {Model.Message} msgTo
     * @static
     * @returns Model.Message.Relation
     */
    Relation.createRelationByData = function createRelationByData(relationData, msgFrom, msgTo) {

        var msgRelation;

        // return error if no uuid is fetchable
        if (!relationData.uuid) {
            // TODO: exception handling
            console.log(relationData);
            throw "No uuid given";
        }

        msgRelation = new Relation(relationData.uuid);
        msgRelation.updateByData(relationData, msgFrom, msgTo);

        return msgRelation;
    };

    Object.defineProperties(
        Relation,
        {
            TYPE_NLP: {
                value: TYPE_NLP
                /**
                 * @property
                 * @constant
                 * @name Model.Message.Relation#TYPE_NLP
                 * @type {String}
                 */
            },
            TYPE_FOLLOWED_BY: {
                value: TYPE_FOLLOWED_BY
                /**
                 * @property
                 * @constant
                 * @name Model.Message.Relation#TYPE_FOLLOWED_BY
                 * @type {String}
                 */
            }
        });

    ns.Relation = Relation;
})(Object.namespace('Model.Message'));
