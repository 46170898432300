(function (ns) {
    /**
     * Holds values as dynamically added properties.
     *
     * @namespace
     * @alias Model.Analytics.Value
     *
     * @implements Model.Behavior.Meta
     * @constructor
     */
    var Value = function () {
        Model.Behavior.Meta.call(this);
    };

    /**
     * @param {Object} data
     */
    Value.prototype.updateByData = function updateByData(data) {
        var self = this;

        if (!(data instanceof Array)) {
            return;
        }

        data.map(
            /**
             * @param {{key: String, value: String}} element
             */
            function(element) {
                self.setMeta(element.key, element.value);
        });
    };

    ns.Value = Value;
})(Object.namespace('Model.Analytics'));