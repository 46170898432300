(function(ns) {
    /**
     * @namespace
     * @alias Model.RepairCase.EmptyMessage
     * @constructor
     * @extends Model.RepairCase
     */
    var EmptyMessage = function() {

        var options = {
            confirm     : 'Got it.',
            decline     : null,
            title       : 'Oh, that looks empty …',
            content     : 'At least one of your messages is empty. Please review your Conversation and either add a message part or delete the message(s).',
            ariaLabel   : ''
        };

        /**
         * @name Model.RepairCase.EmptyMessage#faultyMessages
         */

        EmptyMessage._pProto.constructor.call(
            this,
            this.checkEmptyMessages,
            null,
            options
        );
    };

    Object.extendProto(EmptyMessage, Model.RepairCase);

    /**
     * @function
     * @name Model.RepairCase.EmptyMessage#checkEmptyMessages
     * @param {Model.Message.Collection} collection
     */
    EmptyMessage.prototype.checkEmptyMessages = function checkEmptyMessages(collection) {
        var empties = [];
        for (var i = 0; i < collection.messages.length; i++) {
            if (collection.messages[i].hasNoContent()) {
                empties.push(collection.messages[i]);
            }
        }

        if (empties.length){
            this.faultyMessages = empties;
            throw new Model.Exception.InvalidMessages(empties);
        }
    };
    ns.EmptyMessage = EmptyMessage;
})(Object.namespace('Model.RepairCase'));