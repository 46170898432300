(function(ns) {
    /**
     * @namespace
     * @alias Model.CTA.Collection
     * @constructor
     */
    var Collection = function() {

        var items = [],
            itemsCache = [],
            refreshItemsCache = function() {
                itemsCache.splice.apply(itemsCache, [0, itemsCache.length].concat(items));
            },
            minLength,
            maxLength
            ;

        /**
         * @property
         * @name Model.CTA.Collection#ctas
         * @type Model.CTA[]
         */

        /**
         * @property
         * @name Model.CTA.Collection#minLength
         * @type Number
         */

        /**
         * @property
         * @name Model.CTA.Collection#maxLength
         * @type Number|undefined
         */

        Object.defineProperties(
            this,
            {
                'ctas' : {
                    enumerable: true,
                    get: function () {
                        return itemsCache;
                    }
                },
                'minLength' : {
                    get : function() {
                        return minLength === undefined ? 0 : minLength;
                    },
                    set :
                        function(val) {
                        minLength = parseInt(val);
                    }
                },
                'maxLength' : {
                    get : function() {
                        return maxLength;
                    },
                    set : function(val) {
                        maxLength = parseInt(val);
                    }
                },
                'length' : {
                    get : function() {
                        return items.length;
                    }
                }
            }
        );

        /**
         * @name Model.CTA.Collection#addCTA
         * @param {Model.CTA} cta
         */
        this.addCTA = function addCTA(cta) {
            if (!this.canGainCTA()) {
                return;
            }

            Object.instanceOf(cta, Model.CTA);
            var pos;
            if ((pos = this.getIndex(cta)) !== -1) {
                items.splice(pos, 1, cta);
            } else {
                items.push(cta);
            }

            refreshItemsCache();
            cta.parent = this;
        };

        /**
         * @name Model.CTA.Collection#removeCTA
         * @param {Model.CTA} cta
         */
        this.removeCTA = function(cta) {
            if (!this.canLoseCTA()) {
                return;
            }

            var pos;
            if ((pos = this.getIndex(cta)) !== -1) {
                items.splice(pos, 1);
            }

            refreshItemsCache();

            // prevent endless loop
            if (cta.parent) {
                cta.parent = null;
            }
        };

        /**
         * @param fromPos
         * @param toPos
         */
        this.moveCTA = function moveCTA (fromPos, toPos) {
            if (toPos > fromPos) {
                toPos -= 1;
            }

            if (fromPos === toPos) {
                return;
            }

            var cta = items.splice(fromPos, 1).pop();
            items.splice(toPos, 0, cta);

            refreshItemsCache();
        };

        this.removeAll = function() {
            for (var i = items.length - 1; i >= 0; i--) {
                this.removeCTA(items[i]);
            }
        };

        /**
         * @name Model.CTA.Collection#canLoseCTA
         */
        this.canLoseCTA = function canLoseCTA() {
            return items.length && items.length >= this.minLength;
        };

        /**
         * @name Model.CTA.Collection#canGainCTA
         */
        this.canGainCTA = function canGainCTA() {
            return this.maxLength === undefined || items.length < this.maxLength;
        };

        /**
         * @name Model.CTA.Collection#__extendClone
         * @param {Model.CTA.Collection} original
         */
        this.__extendClone = function(original) {
            for (var i = 0; i < original.ctas.length; i++) {
                this.addCTA(original.ctas[i].clone());
            }
        }
    };

    /**
     * @name Model.CTA.Collection#createAndAddCTA
     * @return Model.CTA
     */
    Collection.prototype.createAndAddCTA = function createAndAddCTA() {
        var cta = new Model.CTA();

        this.addCTA(cta);

        return cta;
    };

    /**
     * @param {Model.CTA} cta
     * @name Model.CTA.Collection#getIndex
     */
    Collection.prototype.getIndex = function getIndex(cta) {
        for (var i = 0; i < this.ctas.length; i++) {
            if (this.ctas[i].uuid === cta.uuid) {
                return i;
            }
        }

        return -1;
    };

    /**
     * @name Model.CTA.Collection#toString
     */
    Collection.prototype.toString = function toString() {
        return this.ctas.toString();
    };

    /**
     * @name Model.CTA.Collection#getAllActions
     * @returns Model.Action[]
     */
    Collection.prototype.getAllActions = function getAllActions() {
        var actionObjects = [],
            actions;

        for (var i = 0; i < this.ctas.length; i++) {
            if (!(actions = this.ctas[i].getAllActions())) {
                continue;
            }
            for (var j = 0; j < actions.length; j++) {
                actionObjects.push({
                    cta     : this.ctas[i],
                    action : actions[j]
                });
            }
        }
        return actionObjects;
    };

    /**
     * @name Model.CTA.Collection#getPostbackActions
     */
    Collection.prototype.getPostbackActions = function getPostbackActions() {
        var actions = [],
            action
            ;

        for (var i = 0; i < this.ctas.length; i++) {
            if ((action = this.ctas[i].getPostbackAction())) {
                actions.push({
                    cta     : this.ctas[i],
                    action  : action
                });
            }
        }
        return actions;
    };

    /**
     * @name Model.CTA.Collection#getMessageTriggeringActions
     */
    Collection.prototype.getMessageTriggeringActions = function getMessageTriggeringActions() {
        var actions = [],
            action
            ;

        for (var i = 0; i < this.ctas.length; i++) {
            if ((action = this.ctas[i].getMessageTriggeringAction())) {
                actions.push({
                    cta     : this.ctas[i],
                    action  : action
                });
            }
        }
        return actions;
    };

    /**
     * @name Model.CTA.Collection#updateByData
     */
    Collection.prototype.updateByData = function(data) {
        var i,
            cta,
            ctasAdded   = [],
            self        = this
        ;

        if (!data.ctas) {
            // support for old structure
            if (data.length) {
                for (i = 0; i < data.length; i++) {
                    var uuid = data[i].type === Const.PostbackMessage ? data[i].uuid : null,
                        action = new Model.Action(uuid)
                        ;

                    cta = new Model.CTA(data[i].uuid);
                    action.updateByData(data[i]);

                    cta.actions.addAction(action);
                    cta.label = data[i].label;
                    this.addCTA(cta);
                }
            }
            return;
        } else if (data.ctas.length === 0) {
            return;
        }

        for (i = 0; i < data.ctas.length; i++) {
            cta = new Model.CTA(data.ctas[i].uuid);
            cta.updateByData(data.ctas[i]);
            ctasAdded.push(cta);
            this.addCTA(cta);
        }

        ctasAdded.diff(this.ctas).map(function(ctaToRemove) {
            self.removeCTA(ctaToRemove);
        });
    };

    /**
     * @method Model.CTA.Collection#validate
     * @returns {boolean}
     */
    Collection.prototype.validate = function validate() {

        if (this.ctas.length < this.minLength || this.ctas.length > this.maxLength) {
            return false;
        }

        for (var i = 0; i < this.ctas.length; i++) {

            if (!this.ctas[i].validate()) {
                return false;
            }
        }
        return true;
    };

    ns.Collection = Collection;
})(Object.namespace('Model.CTA'));