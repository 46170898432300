(function(ns) {
    /**
     * @namespace
     * @alias sBase.Controller.RouteErrorDialog
     * @constructor
     *
     * @param $mdDialog
     * @param {string} errorMessage
     * @param $window
     */
    var RouteErrorDialog = function ($mdDialog, errorMessage, $window) {
        this.$mdDialog      = $mdDialog;
        this.errorMessage   = errorMessage;
        this.$window        = $window;
    };

    /**
     * @function
     * @name sBase.Controller.RouteErrorDialog#back
     */
    RouteErrorDialog.prototype.back = function back() {
        this.$window.history.back();
    };

    /**
     * @function
     * @name sBase.Controller.RouteErrorDialog#close
     */
    RouteErrorDialog.prototype.close = function close() {
        this.$mdDialog.cancel();
    };

    ns.RouteErrorDialog = RouteErrorDialog;
})(Object.namespace('sBase.Controller'));