(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.View.sMessage
     *
     * @param $sce
     * @param $scope
     * @param $element
     * @param {Model.Menu.Menu} sMenuSendAllTypes
     * @param {Service.sDomain} sDomainService
     */
    var sMessage = function ($sce, $scope, $element, sMenuSendAllTypes, sDomainService) {
        /**
         * @type Model.Message
         * @name Controller.Component.View.sMessage#model
         */

        /**
         * @function
         * @name Controller.Component.View.sMessage#onHandleCtaClick
         */

        this.$scope = $scope;

        this.$element = $element;

        this.sMessageTypes = sMenuSendAllTypes.splitByLabels();

        this.$deRegister = [];

        this.actionInProgress = false;

        this.isCreatorEnabled = !sDomainService.currentDomain.isFeatureEnabledByIdent('vStructure')

        var isEditableCache = null,
            self            = this
        ;

        Object.defineProperties(
            this,
            {
                isEditable: {
                    get: function() {
                        if (isEditableCache === null) {
                            isEditableCache = false;

                            if (self.messageControls) {
                                isEditableCache = Controller.Component.View.sMessage.getActionMarkedAsEdit(self.messageControls);
                            }
                        }

                        return isEditableCache;
                    }
                    /**
                     * @property
                     * @name Controller.Component.View.sMessage#isEditable
                     * @type {Boolean}
                     */
                },
                activeRelation: {
                    get: function () {
                         if (!this.sMessageLogCtrl || !this.sequence) {
                             return null;
                         }
                         return this.sMessageLogCtrl.getActiveRelationFrom(this.model, this.sequence);
                    }
                    /**
                     * @property
                     * @name Controller.Component.View.sMessage#activeRelation
                     * @type {?Model.Message.Relation}
                     */
                }

            }
        );

        /**
         * @property
         * @name Controller.Component.View.sMessage#sequence
         * @type {?Number}
         */

        /**
         * @property
         * @name Controller.Component.View.sMessage#actionInProgress
         * @type {Boolean}
         */

        this.resetEditableCache = function resetEditableCache() {
            isEditableCache = null;
        };

        this.$deRegister.push($scope.$on(
            Controller.Component.sMessageCollectionActionBox.EVENT_MESSAGE_ACTION_BOX_ACTION_IN_PROGRESS,
            this.handleActionInProgressChange.bind(this)
        ));

        $scope.$on('$destroy', function () {
            var $destroyFn;
            while (($destroyFn = self.$deRegister.pop())) {
                $destroyFn();
            }
        });
    };

    /**
     * @name Controller.Component.View.sMessage#$onChanges
     * @param changes
     */
    sMessage.prototype.$onChanges = function $onChanges(changes) {
        if (changes.messageControls && changes.messageControls.currentValue) {
            Object.instanceOf(changes.messageControls.currentValue, Model.Menu.Menu);
        }
        this.resetEditableCache();
    };

    /**
     * @param {Event} $event
     */
    sMessage.prototype.onInsertButtonClick = function onInsertButtonClick($event) {
        // If there are no actions available open edit window, otherwise disable click handling
        if (this.model.isRoot && this.model.isIncoming) {
            this.insertPartAction(this.model);
        } else {
            $event.stopImmediatePropagation();
        }
    };

    /**
     * @param {Model.Message} msg
     * @param {String=} type
     */
    sMessage.prototype.insertPartAction = function insertPartAction(msg, type) {
        type = type || Model.Message.Part.TYPE.TEXT;

        if (msg.isRoot && msg.isIncoming) {
            type = Model.Message.Part.TYPE_AI_REACTION;
        }

        var menuAction,
            part
        ;

        if (!this.messageControls || !(menuAction = Controller.Component.View.sMessage.getActionMarkedAsEdit(this.messageControls))) {
            return;
        }

        part = Model.Message.Part.create(type);

        menuAction.action(part, msg);
    };

    sMessage.prototype.toggleMessageType = function toggleRootMessageType() {
        if (!this.model.isRoot && !this.model.isEmpty()) {
            return;
        }
        if (this.model.isIncoming) {
            this.model.setOutgoing();
        } else {
            this.model.setIncoming();
        }

        this.$scope.$emit('sEventMessageTypeToggle', this.model);
    };

    /**
     * @param {Model.Menu.Menu} menu
     * @return {Model.Menu.Action|null}
     */
    sMessage.getActionMarkedAsEdit = function getActionMarkedAsEdit(menu) {
        Object.instanceOf(menu, Model.Menu.Menu);
        var actions = menu.getElementsOfType(Model.Menu.Action);

        for (var i = 0; i < actions.length; i++) {
            //noinspection JSUnresolvedVariable
            if (actions[i].options.isEdit) {
                return actions[i];
            }
        }

        return null;
    };

    /**
     * @name Controller.Component.View.sMessage#$onChanges
     * @param changes
     */
    sMessage.prototype.$onChanges = function $onChanges(changes) {
        var context;
        if (changes.activeRelation && changes.activeRelation.currentValue) {
            if (!(context = this.model.getContextByActionUuid(changes.activeRelation.currentValue.options.uuid)) || !context.content) {
                return;
            }

            context.content.parent.activeGroup = (context.content.parent.contentIndex(context.content) + 1);
        }
    };

    /**
     * @function
     * @name Controller.Component.View.sMessage#onBeforeCtaClick
     * @param {Event} $event
     */
    sMessage.prototype.onBeforeCtaClick = function onBeforeCtaClick($event) {
        // Prevent click handling if lines in message log are being redrawn
        $event = this.$scope.$emit('sEventMessageCtaClick', this.model);

        return $event.defaultPrevented;
    };

    /**
     * @function
     * @name Controller.Component.View.sMessage#handleActionInProgressChange
     * @param {Event} $event
     * @param {Boolean} actionInProgress
     */
    sMessage.prototype.handleActionInProgressChange = function handleActionInProgressChange($event, actionInProgress) {
        this.actionInProgress = actionInProgress;
    };

    ns.sMessage = sMessage;

})(Object.namespace('Controller.Component.View'));
