(function(ns) {
    /**
     * @namespace
     * @alias Controller.sMessageLog.Channel
     * @constructor
     *
     * @param {Model.Message.FetchAbleCollection} sent
     * @param {Model.Message.FetchAbleCollection} queued
     * @param $scope
     * @param {String} hChannelId
     * @param {$sce} $sce
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {Service.sConfirm} sConfirm
     * @param Notification
     */
    var Channel = function (sent, queued, $scope, hChannelId, $sce, sAPIAccess, sConfirm, Notification) {
        this.$scope         = $scope;
        this.hChannelId     = hChannelId;
        this.sent           = sent;
        this.queued         = queued;

        var self = this;

        /**
         * @name Controller.sMessageLog.Channel#scheduleControls
         * @type {Model.Action.Collection|*}
         */
        this.scheduleControls = new Model.Menu.Menu();

        if (sAPIAccess.isAllowed('messageSchedule.RESTAccess', Const.Method.DELETE)) {
            this.scheduleControls.addAction(
                $sce.trustAsHtml('<i class="fa fa-ban danger"></i>'),
                function(messagePart) {
                    sConfirm.open({
                        confirm: 'Yes, cancel it',
                        decline: 'No',
                        title: 'Cancel scheduled sending?',
                        content: 'This removes the message from the sending Queue. <br>To send it at a different time, please schedule it again via the Conversation<br><br>',
                        ariaLabel: ''
                    }).then(function() {
                        $.ajax({
                            url: sAPIAccess.endpoint('messageSchedule.RESTAccess').delete(messagePart.parent.uuid),
                            method: Const.Method.DELETE
                        }).then(function(data) {
                            Notification.success(
                                'Message removed from sending queue.' +
                                '<br>Re-schedule it <a href="#/creator/' + data.uuid + '">here</a>'
                            );
                            self.queued.removeItem(messagePart.parent, true);
                        }).always(function() {
                            digestIfNeeded($scope);
                        });
                    });
                },
                {
                    tooltip: 'Stop sending'
                }
            );
        }

    };

    ns.Channel = Channel;
})(Object.namespace('Controller.sMessageLog'));
