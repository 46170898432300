(function(ns) {
    /**
     * @namespace
     * @alias Controller.Directive.sEmojiRenderer
     */
    var sEmojiRenderer = function ($scope, $element, $filter) {
        this.$filter        = $filter;
        this.$deRegister    = [];
        this.$element       = $element;

        /**
         * @property
         * @name Controller.Directive.sEmojiRenderer#ngModelCtrl
         * @type {Object}
         */
    };

    /**
     * @function
     * @name Controller.Directive.sEmojiRenderer#$onInit
     */
    sEmojiRenderer.prototype.$onInit = function $onInit() {

        this.ngModelCtrl.$formatters.push(this.formatModelValue.bind(this));
        this.ngModelCtrl.$parsers.unshift(this.parseViewValue.bind(this));

        this.ngModelCtrl.$render = this.render.bind(this);
    };

    /**
     * @function
     * @name Controller.Directive.sEmojiRenderer#render
     */
    sEmojiRenderer.prototype.render = function render() {
        this.$element.html(this.ngModelCtrl.$viewValue || "");
    };

    /**
     * @function
     * @name Controller.Directive.sEmojiRenderer#parseModelValue
     * @param {*} valueFromModel
     * @returns {*}
     */
    sEmojiRenderer.prototype.formatModelValue = function parseModelValue(valueFromModel) {
        return this.$filter('emoji')(valueFromModel, true);
    };

    /**
     * @function
     * @name Controller.Directive.sEmojiRenderer#parseViewValue
     * @param {*} valueFromView
     * @returns {*}
     */
    sEmojiRenderer.prototype.parseViewValue = function parseViewValue(valueFromView) {
        return valueFromView.replace(/\<img class\="emoji.*?\>/gim, function() {
            arguments[0].search(/alt\=\"(.*?)\"/gi);

            return RegExp.$1.replace(/\&\#x(.*);/, function () {
                if (!arguments[1]) {
                    return arguments[0];
                }

                var fragments = arguments[1].split(';&#x');

                return fragments.map(function(element) {
                    return String.fromCodePoint(parseInt(element, 16));
                }).join('');
            });
        });
    };

    ns.sEmojiRenderer = sEmojiRenderer;
})(Object.namespace('Controller.Directive'));