/**
 * Created by Gabor on 25-May-17.
 */

jQuery.fn.extend({
    'firstInView': function (element) {

        var base = document.body;
        if (element) {
            if (element instanceof jQuery) {
                base = element[0];
            } else if (element instanceof HTMLElement) {
                base = element;
            } else {
                throw 'Invalid element type!';
            }
        }

        var bodyRect = base.getBoundingClientRect(),
            list = Array.prototype.slice.call(this, 0),
            outOfView = function (element) {
                var rect = element.getBoundingClientRect();

                if (rect.bottom < bodyRect.top) {
                    return -1;
                } else if (rect.top > bodyRect.bottom) {
                    return 1;
                } else {
                    return 0;
                }
            },
            quickFind = function (arr) {
                var first = arr.slice(0, 1).pop(),
                    last = arr.slice(-1).pop(),
                    mIndex = Math.floor(arr.length / 2),
                    middle = arr.slice(mIndex, mIndex + 1).pop(),
                    direction,
                    nextDirection,
                    result = null
                ;

                if (!arr.length) {
                    return result;
                }

                if ((direction = outOfView(first)) === 0) {
                    result = first;
                    return result;
                }

                if ((nextDirection = outOfView(middle)) !== direction) {
                    if (nextDirection === 0) {
                        result = middle;
                    }
                    // only overwrite if we found anything
                    result = quickFind(arr.slice(1, mIndex)) || result;
                    return result;
                } else {
                    direction = nextDirection;
                }

                if ((nextDirection = outOfView(last)) !== direction) {
                    if (nextDirection === 0) {
                        result = last;
                    }
                    result = quickFind(arr.slice(mIndex + 1, arr.length - 2)) || result;
                    return result;
                }
            };

        return $(quickFind(list));
    }
});