
(function() {
    var w = window;
    w.Controller = w.Controller || {};
    w.Controller.sMessageAdmin = w.Controller.sMessageAdmin || {};

    var PublishController = w.Controller.sMessageAdmin.PublishController = function($scope, $mdDialog) {

        this.$mdDialog = $mdDialog;
    };

    PublishController.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    PublishController.prototype.onPublish = function onPublish() {
        this.$mdDialog.hide({});
    };

})(window);