/**
 * Created by Gabor on 24-Aug-17.
 */

Number.prototype.toLettersRadix = function () {
    return this.toString(26).split('').map(function(element, index, array) {
        element = parseInt(element, 26);

        if (!index && array.length > 1) {
            element -= 1;
        }

        return String.fromCharCode(97 + element);
    }).join('');
};

/**
 * @see https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number/13627586#13627586
 * @return {string}
 */
Number.prototype.toOrdinalSuffixedString = function toOrdinalSuffixedString() {
    var j = this % 10,
        k = this % 100;
    if (j === 1 && k !== 11) {
        return this + "st";
    }
    if (j === 2 && k !== 12) {
        return this + "nd";
    }
    if (j === 3 && k !== 13) {
        return this + "rd";
    }
    return this + "th";
};

Object.defineProperties(
    Number.prototype,
    {
        'toLettersRadix': {
            enumerable: false
        },
        'toOrdinalSuffixedString': {
            enumerable: false
        }
    }
);
