(function (ns) {
    var KEY_PLAN = 'plan';
    /**
     * @namespace
     * @alias Model.Plan
     *
     * @param {String=} uuid
     * @constructor
     * @extends Model.RESTAccessByUUID
     */
    var Plan = function (uuid) {
        var features        = [],
            featuresCache   = [],
            featuresSaving  = null,
            featuresState   = '',
            refreshFeaturesCache = function () {
                featuresCache.splice.apply(
                    featuresCache,
                    [0, featuresCache.length].concat(features));
            }
            ;
        Object.defineProperties(
            this,
            {
                'name'         : {
                    writable   : true,
                    enumerable : true
                    /**
                     * @property
                     * @name Model.Plan#name
                     * @type {String}
                     */
                },
                'domainsCount' : {
                    writable   : true,
                    enumerable : true
                    /**
                     * @property
                     * @name Model.Plan#domainsCount
                     * @type {Number}
                     */
                },
                'features'     : {
                    get : function () {
                        return featuresCache;
                    }
                    /**
                     * @property
                     * @name Model.Plan#features
                     * @type {Model.Feature[]}
                     */
                }
            }
        );
        /**
         * @param {Model.Feature} feature
         * @returns {Model.Plan}
         */
        this.addFeature = function addFeature(feature) {
            Object.instanceOf(feature, Model.Feature);

            if (this.getFeatureByUuid(feature.uuid)) {
                return this;
            }

            features.push(feature);
            refreshFeaturesCache();
            return this;
        };

        /**
         * @param {Model.Feature} feature
         * @returns {Model.Plan}
         */
        this.removeFeature = function removeFeature(feature) {
            Object.instanceOf(feature, Model.Feature);
            var key;

            if ((key = features.indexOf(feature)) === -1) {
                return this;
            }

            features.splice(key, 1);
            refreshFeaturesCache();
            return this;
        };

        /**
         * @param {String} uuid
         * @returns {?Model.Feature}
         */
        this.getFeatureByUuid = function getFeatureByUuid(uuid) {
            return features.filter(function(feature) {
                return feature.uuid === uuid;
            }).pop()
        };

        var protecteds = Plan._pProto.constructor.call(this, uuid);
        protecteds.registerRelationHandler(this.endPoint, function(data) {
            if (!data.features) {
                return;
            }

            var featureEndPoint = new Model.Feature().endPoint;

            return data.features.map(function(featureData) {
                var obj = {};
                obj.uuid = featureData.uuid;
                obj.endPoint = featureEndPoint;
                return obj;
            });
        });

        this.setFeaturesJSONState = function() {
            featuresState = JSON.stringify(this.features);
        };

        var parentSave = this.save;
        this.save = function save() {
            var self = this,
                executeSaveFeatures = function executeSaveFeatures() {
                    if (JSON.stringify(self.features) === featuresState) {
                        return $.Deferred().resolve(self).promise();
                    }

                    var featuresFromFeatureState = JSON.parse(featuresState);
                    featuresFromFeatureState = featuresFromFeatureState.map(function(featureJson) {
                        return Model.Feature.createByData(featureJson);
                    });

                    var mapFn = function(feature) {
                        return feature.uuid
                    };

                    var
                        executables         = [],
                        /**
                         * Extract data for the aggregation
                         * @param options
                         * @param data
                         * @param counter
                         */
                        dataTransformFunc   = function(options, data, counter) {
                            var nameObj = {
                                method  : options.method,
                                url     : options.url,
                                counter : counter
                            };
                            data.append(JSON.stringify(nameObj).bin2hex(), '');
                        }
                    ;

                    featuresFromFeatureState.diff(self.features, mapFn).map(function(feature) {
                        executables.push(function() {
                            // these need to be added > POST
                            $.ajax({
                                url     : Plan.prototype.featureToggleEndPoint(self.uuid, feature.uuid),
                                method  : Const.Method.POST
                            });
                        });
                    });
                    self.features.diff(featuresFromFeatureState, mapFn).map(function(feature) {
                        executables.push(function() {
                            // these need to be removed
                            $.ajax({
                                url     : Plan.prototype.featureToggleEndPoint(self.uuid, feature.uuid),
                                method  : Const.Method.DELETE
                            });
                        })
                    });

                    var $deferred = $.aggregateAction(executables, Model.RESTAccessByUUID.endpoint_batch(true), dataTransformFunc);

                    // capture the state that is submitted
                    self.setFeaturesJSONState();
                    return $deferred;
                }
            ;

            return parentSave.apply(this).then(function(data) {
                if (!featuresSaving) {
                    featuresSaving = executeSaveFeatures(data).always(function() {
                        featuresSaving = null;
                    });
                } else {
                    featuresSaving.then(executeSaveFeatures);
                }
            });
        };

        this.setFeaturesJSONState();

        /**
         * @property
         * @name Model.Plan#features
         * @type Model.Feature[]
         */

        /**
         * @property
         * @name Model.Plan#name
         * @type String
         */
    };

    Object.extendProto(Plan, Model.RESTAccessByUUID);

    /**
     * @param {Object} data
     */
    Plan.prototype.updateByData = function updateByData(data) {
        var self            = this,
            featuresCreated = []
        ;

        Object.updateByData(this, data);

        if ((data.features instanceof Array)) {
            data.features.map(function(json) {
                var feature = Model.Feature.createByData(json);
                featuresCreated.push(feature);
                self.addFeature(feature);
            });
        }

        featuresCreated.diff(this.features, function(feature) {
            return feature.uuid;
        }).map(function(feature) {
            self.removeFeature(feature);
        });

        Plan._pProto.updateByData.call(this, data);
        this.setFeaturesJSONState();
    };

    /**
     * @param formData
     * @param {string=} name
     * @returns {*|FormData}
     */
    Plan.prototype.getFormData = function getFormData(formData, name) {
        var fData = Plan._pProto.getFormData.call(this, formData),
            planData = JSON.stringify(this);

        name = name || KEY_PLAN;

        fData.append(name, planData);

        return fData;
    };

    /**
     * @return {$.Deferred}
     */
    Plan.query = function query() {
        return new Model.Collection.RESTAccess(Plan).load().then(function(collection) {
            return collection.items;
        })
    };

    /**
     * @param {Object} data
     * @returns {Model.Plan}
     */
    Plan.createByData = function createByData(data) {
        var plan = new Plan(data['uuid']);

        plan.updateByData(data);

        return plan;
    };

    ns.Plan = Plan;
})(Object.namespace('Model'));
