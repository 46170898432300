(function(ns) {
    /**
     * @namespace
     * @alias Model.Sender.BaseFacebookSender
     * @extends Model.Sender
     * @constructor
     *
     * @param {String} _type
     * @param {String=} uuid
     */
    var BaseFacebookSender = function BaseFacebookSender(_type, uuid) {
        /**
         * @property {String|Integer}
         * @name Model.Sender.FacebookAdAccount#appId
         */
        this.appId = null;
        /**
         * @property {String}
         * @name Model.Sender.FacebookAdAccount#permissionsRequired
         */
        this.permissionsRequired = '';
        /**
         * @property {String}
         * @name Model.Sender.FacebookAdAccount#permissionsOptional
         */
        this.permissionsOptional = '';

        BaseFacebookSender._pProto.constructor.call(this, _type, uuid);
    };

    Object.extendProto(BaseFacebookSender, Model.Sender);

    ns.BaseFacebookSender = BaseFacebookSender;
})(Object.namespace('Model.Sender'));
