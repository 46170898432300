(function(ns) {
    var EVENT_SIDEBAR_ATTACH      = 'sSidebarAttachEvent',
        EVENT_SIDEBAR_DETACH      = 'sSidebarDetachEvent',
        EVENT_SIDEBAR_TOGGLE      = 'sSidebarToggleEvent',
        EVENT_SIDEBAR_ATTACH_RECT = 'sSidebarAttachRectEvent',
        EVENT_SIDEBAR_RESIZED     = 'sSidebarResizedEvent',
        EVENT_SIDEBAR_REFRESH     = 'sSidebarRefreshEvent'
    ;

    /**
     * @namespace
     * @alias sSidebar.Service.sSidebar
     *
     * @param $rootScope
     * @param rules
     * @param $mdPanel
     */
    var sSidebar = function ($rootScope, rules, $mdPanel) {
        var $route          = {},
            self            = this,
            active          = -1,
            matchingRules   = [],
            detached        = false,
            /**
             * @type {{left: float, top: float, width: float, height: float}}
             */
            attachmentRect,
            $mdPanelRefCache
            ;

        $rootScope.hasActiveSidebar = false;

        this.$rootScope  = $rootScope;
        this.$deRegister = [];

        this.$onDestroy = function $onDestroy() {
            var $destroyFn;

            if ($mdPanelRefCache) {
                $mdPanelRefCache.close();
            }

            while (($destroyFn = this.$deRegister.pop())) {
                $destroyFn.call(this);
            }
        };

        $rootScope.$on('$destroy', this.$onDestroy.bind(this));

        this.$deRegister.push($rootScope.$on('$routeChangeSuccess', function (event, _$route) {
            $route = _$route;
        }));

        this.$deRegister.push($rootScope.$on('$routeChangeStart',
            /**
             * @param {Object} $event
             */
            function($event) {
            if ($event.defaultPrevented) {
                return;
            }
            self.toggle(false);
        }));


        this.$deRegister.push($rootScope.$watch(
            function () {
                return self.hostController
            },
            function (val) {
                if (!val) {
                    return;
                }

                for (var i = 0; i < rules.length; i++) {
                    if (val instanceof rules[i].type) {
                        // we need to wait one iteration so the old sidenav is switched off first
                        setTimeout(function(i) {
                            return function () {
                                self.toggle(null, i);
                            }
                        }(i));
                        break;
                    }
                }
            }
        ));

        this.$deRegister.push($rootScope.$on(EVENT_SIDEBAR_ATTACH_RECT,
            /**
             * @param event
             * @param {{left: float, top: float, width: float, height: float}} rect
             */
            function(event, rect) {
                if (attachmentRect || !self.template) {
                    return;
                }

                // position the sidebar 1.5 width and -100 pixels lower then the container as requested
                rect.left = Math.round(rect.left - 0.5 * rect.width);
                rect.width += Math.round(rect.width * 0.5);
                rect.height -= 100;


                attachmentRect = rect;
        }));

        Object.defineProperties(
            this,
            {
                hostController: {
                    get: function () {
                        if ($route.$$route && $route.$$route.controllerAs) {
                            return $route.scope[$route.$$route.controllerAs];
                        } else {
                            return $route;
                        }
                    }
                    /**
                     * @property
                     * @name sSidebar.Service.sSidebar#hostController
                     * @type {Object}
                     */
                },
                template: {
                    get: function () {
                        if (active === -1) {
                            return '';
                        }
                        return rules[active].details.template;
                    }
                    /**
                     * @property
                     * @name sSidebar.Service.sSidebar#template
                     * @type {String}
                     */
                },
                title: {
                    get: function () {
                        if (active === -1) {
                            return '';
                        }
                        return rules[active].details.title;
                    }
                    /**
                     * @property
                     * @name sSidebar.Service.sSidebar#title
                     * @type {String}
                     */
                },
                icon: {
                    get: function () {
                        if (active === -1) {
                            return '';
                        }
                        return rules[active].details.icon;
                    }
                    /**
                     * @property
                     * @name sSidebar.Service.sSidebar#icon
                     * @type {String}
                     */
                },
                rules : {
                    get: function() {
                        matchingRules.splice.apply(
                            matchingRules,
                            [0, (matchingRules.length || 1)]
                                .concat(rules.filter(function(element) {
                                    return self.hostController instanceof element.type;
                                })));

                        return matchingRules;
                    }
                    /**
                     * @property
                     * @name sSidebar.Service.sSidebar#rules
                     * @type {[]}
                     */
                },
                detached: {
                    get: function () {
                        return Boolean(detached);
                    }
                    /**
                     * @property
                     * @name sSidebar.Service.sSidebar#detached
                     * @type {Boolean}
                     */
                },
                detachable: {
                    enumerable: true,
                    get: function () {
                        if (active === -1) {
                            return false;
                        }

                        return Boolean(rules[active].details.detachable);
                    }
                    /**
                     * @property
                     * @name sSidebar.Service.sSidebar#detachable
                     * @type {Boolean}
                     */
                }
            }
        );

        var resolveIdentifier = function resolveIdentifier(identifier) {
            if (!angular.isNumber(identifier)) {
                identifier = rules.reduce(function(carry, element, index) {
                    if (element.details.key === identifier) {
                        carry = index;
                    }
                    return carry;
                }, null);
            }

            return identifier;
        };

        this.toggle = function toggle(event, identifier) {
            identifier = resolveIdentifier(identifier);

            var tmpActive = active;

            if (identifier === null || identifier === active) {
                active = -1;
            } else if (rules[identifier]) {
                active = identifier;
            }

            $rootScope.hasActiveSidebar = (active !== -1);

            if (tmpActive !== active) {
                $rootScope.$broadcast(
                    EVENT_SIDEBAR_TOGGLE,
                    (rules[tmpActive] && rules[tmpActive].details) ? rules[tmpActive].details.key : null,
                    (rules[active] && rules[active].details) ? rules[active].details.key : null,
                    !!event
                );
            }

            if (!detached || active === -1) {
                return;
            }

            if (!this.detachable) {
                this.attach();
                return;
            }

            if (this.detachable) {
                this.detach(event);
            } else if ($mdPanelRefCache) {
                $mdPanelRefCache.close();
            }
        };

        this.detach = function detach(event) {
            if (!this.detachable) {
                return;
            }

            var self            = this,
                position        = $mdPanel.newPanelPosition()
            ;

            var rect = attachmentRect;
            position.absolute()
                .left(rect.left - 5 + 'px')
                .top(rect.top - 5 + 'px')
            ;


            var pinPoint = event ? event.currentTarget.getBoundingClientRect() : document.body;

            var panelAnimation = $mdPanel.newPanelAnimation()
                .openFrom(pinPoint)
                .duration(200)
                .closeTo(pinPoint)
                .withAnimation($mdPanel.animation.SCALE);

            var config = {
                attachTo            : angular.element(Const.PanelAnchor),
                controller          : function() {
                    this.cancel = function cancel(cancelEvent) {
                        self.toggle(cancelEvent);
                        this.mdPanelRef.close();
                    }
                },
                controllerAs        : '$ctrl',
                bindToController    : true,
                templateUrl         : 'ssidebar:s-sidebar-detached',
                position            : position,
                disableParentScroll : false,
                clickOutsideToClose : false,
                locals              : {
                    sSidebar: self
                },
                escapeToClose       : true,
                focusOnOpen         : false,
                propagateContainerEvents: true,
                zIndex              : 50,
                animation           : panelAnimation,
                /**
                 * @param mdPanelRef
                 */
                'onCloseSuccess'      : function(mdPanelRef) {
                    if (!mdPanelRef) {
                        return;
                    }
                    mdPanelRef.destroy();
                    $mdPanelRefCache = null;
                }
            };

            return $mdPanel.open(config).then(function($mdPanelRef) {
                var stopHandler = function(event, ui) {
                    var changed = false,
                        type    = event.type
                    ;

                    if (ui.originalPosition.left !== ui.position.left) {
                        position.left(ui.position.left + 'px');
                        attachmentRect.left = ui.position.left;
                        changed = true;
                    }

                    if (ui.originalPosition.top !== ui.position.top) {
                        position.top(ui.position.top + 'px');
                        attachmentRect.top = ui.position.top;
                        changed = true;
                    }

                    if (changed) {
                        // material keeps track of the position separated from the element, so any digest loop would
                        // reset the position into its starting state, therefore it needs to be updated
                        $mdPanelRef.updatePosition(position);
                    }

                    // update the attachmentRect sizes once the animation has finished
                    $mdPanelRef.panelEl.one('transitionend webkitTransitionEnd oTransitionEnd', function() {
                        attachmentRect.width  = $mdPanelRef.panelEl.width();
                        attachmentRect.height = $mdPanelRef.panelEl.height();
                        if (type === 'resizestop') {
                            self.$rootScope.$broadcast(EVENT_SIDEBAR_RESIZED);
                        }
                    });
                };

                // restore panel size to the previously set size on open ~ default is sidebar position -5px in left and top
                $mdPanelRef.panelEl.width(attachmentRect.width).height(attachmentRect.height);

                $($mdPanelRef.panelEl)
                    .resizable({
                        containment: $mdPanelRef.panelContainer,
                        handles: 'all',
                        helper: "s-sidebar-resizable-helper",
                        stop: stopHandler
                    })
                    .draggable({
                        stop    : stopHandler,
                        handle  : '.s-dialog-header'
                    })
                ;

                detached = true;
                $mdPanelRefCache = $mdPanelRef;
                $rootScope.$broadcast(EVENT_SIDEBAR_DETACH, (rules[active] && rules[active].details) ? rules[active].details.key : null);
                $rootScope.hasActiveSidebar = false;
            });
        };

        this.attach = function attach(event) {
            if (detached) {
                if ($mdPanelRefCache) {
                    $mdPanelRefCache.close(event).then(function() {
                        detached = false;
                    });
                } else {
                    detached = false;
                }

                $rootScope.hasActiveSidebar = true;
            }

            $rootScope.$broadcast(EVENT_SIDEBAR_ATTACH, (rules[active] && rules[active].details) ? rules[active].details.key : null);
        };

        /**
         * @param identifier
         * @return {boolean}
         */
        this.isActive = function isActive(identifier) {
            identifier = resolveIdentifier(identifier);

            return active === identifier;
        };

        this.refresh = function refresh() {
            $rootScope.$broadcast(EVENT_SIDEBAR_REFRESH);
        }
    };

    Object.defineProperties(
        sSidebar,
        {
            EVENT_SIDEBAR_TOGGLE: {
                value: EVENT_SIDEBAR_TOGGLE
                /**
                 * @property
                 * @name sSidebar.Service.sSidebar#EVENT_SIDEBAR_TOGGLE
                 * @type {String}
                 */
            },
            EVENT_SIDEBAR_ATTACH: {
                value: EVENT_SIDEBAR_ATTACH
                /**
                 * @property
                 * @name sSidebar.Service.sSidebar#EVENT_SIDEBAR_ATTACH
                 * @type {String}
                 */
            },
            EVENT_SIDEBAR_DETACH: {
                value:  EVENT_SIDEBAR_DETACH
                /**
                 * @property
                 * @name sSidebar.Service.sSidebar#EVENT_SIDEBAR_DETACH
                 * @type {String}
                 */
            },
            EVENT_SIDEBAR_ATTACH_RECT: {
                value: EVENT_SIDEBAR_ATTACH_RECT
                /**
                 * @property
                 * @name sSidebar.Service.sSidebar#EVENT_SIDEBAR_ATTACH_RECT
                 * @type {String}
                 */
            },
            EVENT_SIDEBAR_RESIZED: {
                value: EVENT_SIDEBAR_RESIZED
                /**
                 * @property
                 * @name sSidebar.Service.sSidebar#EVENT_SIDEBAR_RESIZED
                 * @type {String}
                 */
            },
            EVENT_SIDEBAR_REFRESH: {
                value: EVENT_SIDEBAR_REFRESH
                /**
                 * @property
                 * @constant
                 * @name sSidebar.Service.sSidebar#EVENT_SIDEBAR_REFRESH
                 * @type {String}
                 */
            },
            KEY_SIDEBAR_CONVERSATION_GOALS: {
                value: 'conversationGoals'
                /**
                 * @property
                 * @name sSidebar.Service.sSidebar#KEY_SIDEBAR_CONVERSATION_GOALS
                 * @type {String}
                 */
            }
        });

    ns.sSidebar = sSidebar;
})(Object.namespace('sSidebar.Service'));
