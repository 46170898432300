(function(ns) {
    var KEY_ID                    = 'id',
        KEY_PLAN                  = 'plan',
        KEY_EFF_FEATURE_RELATIONS = 'effectiveFeatureRelations',
        KEY_DOMAIN_DATA           = 'domainData'
    ;
    /**
     * @namespace
     * @alias Model.Domain
     *
     * @constructor
     * @extends Model.RESTAccessByUUID
     */
    var Domain = function(uuid) {
        var self                     = this,
            plan                     = null,
            effFeatureRelState       = '',
            domainDataState          = '',
            planState                = '',
            asyncSaving              = null,
            effFeatureRelations      = [],
            effFeatureRelationsCache = [],
            domainData               = {}
        ;

        /**
         * @property
         * @name Model.Domain#id
         * @type {string|int}
         */

        /**
         * @property
         * @name Model.Domain#name
         * @type {string}
         */

        /**
         * @property
         * @name Model.Domain#plan
         * @type {?Model.Plan}
         */

        /**
         * @property
         * @name Model.Domain#planUuid
         * @type {?string}
         */

        /**
         * @property
         * @name Model.Domain#effectiveFeatureRelations
         * @type {Model.Feature.Relation[]}
         */

        Object.defineProperties(
            this,
            {
                id : {
                    configurable : false,
                    enumerable   : true,
                    get          : function () {
                        return self.uuid;
                    }
                },
                name : {
                    writable   : true,
                    enumerable : true
                },
                plan : {
                    configurable : true,
                    get          : function () {
                        return plan;
                    },
                    set          : function (val) {
                        if (val !== null) {
                            Object.instanceOf(val, Model.Plan);
                        }
                        plan = val;
                    }
                },
                planUuid : {
                    configurable : false,
                    get          : function () {
                        return plan ? plan.uuid : null;
                    }
                },
                effectiveFeatureRelations : {
                    get : function () {
                        return effFeatureRelationsCache;
                    }
                },
                domainData : {
                    get : function () {
                        return domainData;
                    }
                    /**
                     * @property
                     * @name Model.Domain#domainData
                     * @type {object}
                     */
                }
            }
        );

        /**
         * private functions:
         */

        /**
         * @function
         * @name refreshEffFeatureRelationsCache
         */
        var refreshEffFeatureRelationsCache = function refreshEffFeatureRelationsCache() {
            effFeatureRelationsCache.splice.apply(
                effFeatureRelationsCache,
                [0, effFeatureRelationsCache.length].concat(effFeatureRelations));
        };

        /**
         * Extract data for the aggregation
         * @function
         * @name dataTransformFunc
         * @param options
         * @param data
         * @param counter
         */
        var dataTransformFunc = function dataTransformFunc(options, data, counter) {
            var nameObj = {
                method  : options.method,
                url     : options.url,
                counter : counter
            };

            if (options.context === KEY_DOMAIN_DATA) {
                nameObj['dataName'] = KEY_DOMAIN_DATA;
            }

            data.append(JSON.stringify(nameObj).bin2hex(), nameObj['dataName'] ? options.data : '');
        };

        /**
         * @function
         * @name syncFeatureRelation
         * @param {Object} featureRelData
         */
        var syncFeatureRelation = function syncFeatureRelation(featureRelData) {
            if (!(featureRelData && featureRelData.feature && featureRelData.feature.uuid)) {
                return;
            }
            var featureRelation = self.getEffFeatureRelationByFeatureUuid(featureRelData.feature.uuid);
            if (!featureRelation) {
                featureRelation = Model.Feature.Relation.create(
                    Model.Feature.createByData(featureRelData.feature)
                );
            }
            featureRelation.meta = featureRelData.meta || {};
        };

        /**
         * @function
         * @name asyncSaveDomainData
         */
        var asyncSaveDomainData = function asyncSaveDomainData() {
            var prevDomainData              = JSON.parse(domainDataState),
                effectiveDomainDataState    = JSON.stringify(self.domainData),
                requestStorage              = []
            ;

            // return if no changes
            if (effectiveDomainDataState === domainDataState) {
                return;
            }

            Object.keys(prevDomainData).concat(Object.keys(self.domainData)).unique().map(
                function (key) {
                    // state did not change
                    if (prevDomainData[key] === self.domainData[key]) {
                        return;
                    }

                    var requestData = {};
                    requestData[key] = self.domainData[key];

                    // prev: undefined|false -> latest: non falsy value
                    if (!prevDomainData.hasOwnProperty(key) && self.domainData[key]) {
                        // these need to be added > POST
                        requestStorage.push($.ajax({
                            url         : Domain.APIEndpoint.domainData.endpoint.post(self.id, key),
                            method      : Const.Method.POST,
                            data        : JSON.stringify(requestData),
                            processData : false,
                            contentType : false,
                            context     : KEY_DOMAIN_DATA // we need this for the aggregate
                        }));
                        return;
                    }

                    if (prevDomainData[key] && !self.domainData.hasOwnProperty(key)) {
                        // these need to be removed > DELETE
                        requestStorage.push($.ajax({
                            url     : Domain.APIEndpoint.domainData.endpoint.delete(self.id, key),
                            method  : Const.Method.DELETE
                        }));
                        return;
                    }

                    // these need to be updated
                    requestStorage.push($.ajax({
                        url         : Domain.APIEndpoint.domainData.endpoint.put(self.id, key),
                        method      : Const.Method.PUT,
                        data        : JSON.stringify(requestData),
                        processData : false,
                        contentType : false,
                        context     : KEY_DOMAIN_DATA // we need this for the aggregate
                    }));
                }
            );


            $.when.apply($, requestStorage).then(function () {
                self.setDomainDataState();
            });
        };

        /**
         * @function
         * @name asyncSaveEffFeatureRelations
         */
        var asyncSaveEffFeatureRelations = function asyncSaveEffFeatureRelations() {
            var prevEffFeatureRelStateObj   = JSON.parse(effFeatureRelState),
                effFeatureRelStateObj       = self.createEffFeatureRelStateObj(),
                requestStorage              = [],
                newEffFeatureRelJSONState   = JSON.stringify(effFeatureRelStateObj)
            ;

            if (newEffFeatureRelJSONState === effFeatureRelState) {
                return;
            }

            // both objects have the feature-uuid as property
            Object.keys(prevEffFeatureRelStateObj).concat(Object.keys(effFeatureRelStateObj)).unique().map(
                function(uuid) {
                    // state did not change for this feature
                    if (prevEffFeatureRelStateObj[uuid] === effFeatureRelStateObj[uuid]) {
                        return;
                    }

                    // prev: undefined|false -> latest: true
                    if (!prevEffFeatureRelStateObj[uuid] && effFeatureRelStateObj[uuid]) {
                        // these need to be added > POST
                        requestStorage.push($.ajax({
                            url     : Domain.prototype.featureRelateEndpoint(self.uuid, uuid),
                            method  : Const.Method.POST
                        }).then(syncFeatureRelation));
                        return;
                    }

                    // these need to be removed
                    requestStorage.push($.ajax({
                        url     : Domain.prototype.featureRelateEndpoint(self.uuid, uuid),
                        method  : Const.Method.DELETE
                    }).then(syncFeatureRelation));
                }
            );

            $.when.apply($, requestStorage).then(function () {
                self.setEffFeatureRelJSONState(newEffFeatureRelJSONState);
            });
        };

        /**
         * @function
         * @name asyncSavePlan
         */
        var asyncSavePlan = function asyncSavePlan() {
            // removing a plan is not intended for now
            if (self.planUuid === planState || !self.planUuid) {
                return;
            }

            $.ajax({
                url     : Domain.prototype.planRelateEndpoint(self.uuid, self.planUuid),
                method  : Const.Method.POST
            }).then(function() {
                self.setPlanState();
            });
        };

        /**
         * @function
         * @name executeAdditionalSaving
         * @returns {$.Deferred}
         */
        var executeAdditionalSaving = function executeAdditionalSaving() {
            return $.aggregateAction(
                [asyncSaveEffFeatureRelations, asyncSaveDomainData, asyncSavePlan],
                Model.RESTAccessByUUID.endpoint_batch(true),
                dataTransformFunc
            );
        };

        /**
         * public functions with access to private properties:
         */

        /**
         * @function
         * @name Model.Domain#clearEffFeatureRelations
         */
        this.clearEffFeatureRelations = function clearEffFeatureRelations() {
            effFeatureRelations = [];
            refreshEffFeatureRelationsCache();
        };

        /**
         * @function
         * @name Model.Domain#addEffFeatureRelation
         * @param {Model.Feature.Relation} featureRelation
         */
        this.addEffFeatureRelation = function addEffFeatureRelation(featureRelation) {
            Object.instanceOf(featureRelation, Model.Feature.Relation);
            if (effFeatureRelations.indexOf(featureRelation) === -1) {
                effFeatureRelations.push(featureRelation);
                refreshEffFeatureRelationsCache();
            }
        };

        /**
         * @function
         * @name Model.Domain#removeEffFeatureRelation
         * @param {Model.Feature.Relation} featureRelation
         */
        this.removeEffFeatureRelation = function removeEffFeatureRelation(featureRelation) {
            Object.instanceOf(featureRelation, Model.Feature.Relation);
            var key;

            if ((key = effFeatureRelations.indexOf(featureRelation)) !== -1) {
                effFeatureRelations.splice(key, 1);
                refreshEffFeatureRelationsCache();
            }
        };

        /**
         * @function
         * @name Model.Domain#setEffFeatureRelJSONState
         * @param {string=} state
         */
        this.setEffFeatureRelJSONState = function(state) {
            effFeatureRelState = state || JSON.stringify(this.createEffFeatureRelStateObj());
        };

        /**
         * @function
         * @name Model.Domain#setDomainDataState
         */
        this.setDomainDataState = function setDomainDataState() {
            domainDataState = JSON.stringify(this.domainData);
        };

        /**
         * @function
         * @name Model.Domain#setPlanState
         */
        this.setPlanState = function() {
            planState = this.planUuid;
        };

        /**
         * @name Model.Domain#__dontCloneProperties
         * @returns string[]
         */
        this.__dontCloneProperties = function () {
            return ['$$hashKey', KEY_PLAN, KEY_EFF_FEATURE_RELATIONS];
        };

        /**
         * @name Model.Domain#__extendClone
         * @param {Model.Domain} original
         */
        this.__extendClone = function(original) {
            this.plan = original.plan;

            for (var i = 0; i < original.effectiveFeatureRelations.length; i++) {
                this.addEffFeatureRelation(Model.Feature.Relation.create(
                    original.effectiveFeatureRelations[i].feature,
                    original.effectiveFeatureRelations[i].meta
                ));
            }
        };

        var protecteds = Domain._pProto.constructor.call(self, uuid);

        protecteds.registerRelationHandler(this.endPoint, function(data) {
            if (!data.plan) {
                return;
            }

            var planEndPoint = new Model.Plan().endPoint;

            var obj = {};
            obj.uuid = planEndPoint.uuid;
            obj.endPoint = planEndPoint;
            return [obj];
        });

        var parentSave = this.save;
        /**
         * @function
         * @name Model.Domain#save
         * @returns {$.Deferred}
         */
        this.save = function save() {
            return parentSave.apply(self).then(function() {
                var def = $.Deferred();
                if (!asyncSaving) {
                    asyncSaving = executeAdditionalSaving()
                        .then(def.resolve, def.reject)
                        .always(function() {
                            asyncSaving = null;
                        });
                } else {
                    asyncSaving.then(executeAdditionalSaving).then(def.resolve, def.reject);
                }

                return def.promise();
            });
        };

        this.setEffFeatureRelJSONState();
        this.setDomainDataState();
        this.setPlanState();
    };

    Object.extendProto(Domain, Model.RESTAccessByUUID);

    /**
     * @function
     * @name Model.Domain#enableFeature
     * @param {Model.Feature} feature
     */
    Domain.prototype.enableFeature = function enableFeature(feature) {
        var featureRelation = this.getEffFeatureRelationByFeature(feature);

        if (featureRelation) {
            featureRelation.enable();
            return;
        }

        featureRelation = Model.Feature.Relation.create(feature);
        this.addEffFeatureRelation(featureRelation);
    };

    /**
     * @function
     * @name Model.Domain#disableFeature
     * @param {Model.Feature} feature
     */
    Domain.prototype.disableFeature = function disableFeature(feature) {
        var featureRelation = this.getEffFeatureRelationByFeature(feature);

        if (!featureRelation) {
            return;
        }

        if (!this.plan || (this.plan && !this.plan.getFeatureByUuid(feature.uuid))) {
            this.removeEffFeatureRelation(featureRelation);
        } else {
            featureRelation.disable();
        }
    };

    /**
     * @function
     * @name Model.Domain#getEffFeatureRelationByFeatureUuid
     * @param {string} uuid
     * @returns {?Model.Feature.Relation}
     */
    Domain.prototype.getEffFeatureRelationByFeatureUuid = function getEffFeatureRelationByFeatureUuid(uuid) {
        return this.effectiveFeatureRelations.filter(function (featureRelation) {
            return featureRelation.feature.uuid === uuid;
        }).slice(0,1).pop() || null;
    };

    /**
     * @function
     * @name Model.Domain#getEffFeatureRelationByFeature
     * @param {Model.Feature} feature
     * @returns {?Model.Feature.Relation}
     */
    Domain.prototype.getEffFeatureRelationByFeature = function getEffFeatureRelationByFeature(feature) {
        return this.getEffFeatureRelationByFeatureUuid(feature.uuid);
    };

    /**
     * @param {Model.Feature} feature
     * @returns {boolean}
     */
    Domain.prototype.isFeatureEnabled = function isFeatureEnabled(feature) {
        return this.effectiveFeatureRelations.filter(function (featureRelation) {
            return featureRelation.feature.uuid === feature.uuid && !featureRelation.isDisabled();
        }).length > 0;
    };

    /**
     * @param {string} featureIdent
     * @returns {boolean}
     */
    Domain.prototype.isFeatureEnabledByIdent = function isFeatureEnabled(featureIdent) {
        return this.effectiveFeatureRelations.filter(function (featureRelation) {
            return featureRelation.feature.ident === featureIdent && !featureRelation.isDisabled();
        }).length > 0;
    };

    /**
     * @function
     * @name Model.Domain#createEffFeatureRelStateObj
     * @returns Object
     */
    Domain.prototype.createEffFeatureRelStateObj = function createEffFeatureRelStateObj() {
        var stateObj = {};
        this.effectiveFeatureRelations.map(function(featureRelation) {
            stateObj[featureRelation.feature.uuid] = !featureRelation.isDisabled();
        });
        return stateObj;
    };

    /**
     * @function
     * @name Model.Domain#updateByData
     * @param {Object.<String, *>} data
     */
    Domain.prototype.updateByData = function updateByData(data) {
        var self = this;
        for (var key in data) {
            if (key === KEY_EFF_FEATURE_RELATIONS) {
                if (!(data[key] instanceof Array)) {
                    continue;
                }
                data[key].map(function(arr) {
                    var feature;
                    if (arr.feature && (feature = Model.Feature.createByData(arr.feature))) {
                        self.addEffFeatureRelation(Model.Feature.Relation.create(
                            feature,
                            arr.meta || {}
                        ));
                    }
                })
            } else if (key === KEY_DOMAIN_DATA) {
                for (var domainDataKey in data.domainData) {
                    if (!data.domainData.hasOwnProperty(domainDataKey)) {
                        continue;
                    }
                    this.domainData[domainDataKey] = data.domainData[domainDataKey];
                }
            } else if (Object.prototype.hasOwnProperty.call(self, key)) {
                switch (key) {
                    case KEY_PLAN:
                        if (data[key]) {
                            self[key] = new Model.Plan.createByData(data[key]);
                        }
                        break;
                    default:
                        self[key] = data[key];
                }
            }
        }

        Domain._pProto.updateByData.call(this, data);
        this.setEffFeatureRelJSONState();
        this.setDomainDataState();
        this.setPlanState();
    };

    /**
     * @function
     * @name Model.Domain#createByData
     * @static
     * @param {Object} data
     * @returns {Model.Domain}
     */
    Domain.createByData = function createByData(data) {
        var uuid,
            domain;

        // since our domains do not have uuids yet we use the hashed id instead
        if (data && data.hasOwnProperty(KEY_ID)) {
            uuid = data.id;
        }

        domain = new Domain(uuid);
        domain.updateByData(data);

        return domain;
    };

    Domain.APIEndpoint = {
        generic      : false,
        domainData   : {
            endpoint: false
        }
    };

    ns.Domain = Domain;
})(Object.namespace('Model'));