(function(ns) {
    /**
     * @namespace
     * @alias sSidebar.Component.Controller.sSidebar
     *
     * @param {sSidebar.Service.sSidebar} sSidebar
     * @param $element
     * @param $scope
     * @param $mdPanel
     */
    var sSidebarCtrl = function (sSidebar, $element, $scope, $mdPanel) {
        this.sSidebar       = sSidebar;
        this.$element       = $element;
        this.$scope         = $scope;

        this.$deRegister    = [];
        this.$mdPanel       = $mdPanel;
    };

    sSidebarCtrl.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while(($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    sSidebarCtrl.prototype.$onInit = function $onInit() {
        var self = this;

        this.$deRegister.push(this.$scope.$on(sSidebar.Service.sSidebar.EVENT_SIDEBAR_TOGGLE, this.handleToggleEvent.bind(this)));
        this.$deRegister.push(this.$scope.$on(sSidebar.Service.sSidebar.EVENT_SIDEBAR_DETACH, this.detach.bind(this)));
        this.$deRegister.push(this.$scope.$on(sSidebar.Service.sSidebar.EVENT_SIDEBAR_ATTACH, this.attach.bind(this)));
        this.$deRegister.push(this.$scope.$on(sSidebar.Service.sSidebar.EVENT_SIDEBAR_REFRESH, this.refresh.bind(this)));
        this.$deRegister.push(this.$scope.$watch(
            function() {
                return JSON.stringify(self.$element[0].getBoundingClientRect());
            },
            /**
             * @param {String} val
             */
            function(val) {
                var rect = JSON.parse(val);

                if (!rect.width || !rect.height) {
                    return;
                }
                self.$scope.$emit(sSidebar.Service.sSidebar.EVENT_SIDEBAR_ATTACH_RECT, rect);
            }));
    };

    /**
     * @param event
     * @param {Number=} state
     */
    sSidebarCtrl.prototype.handleToggleEvent = function handleToggleEvent(event, state) {
        var visible = state !== -1;

        this.$element.toggle(visible);
        this.visible = visible;
    };

    sSidebarCtrl.prototype.detach = function detach() {
        this.$element.toggle(false);
        this.visible = false;
    };

    sSidebarCtrl.prototype.attach = function attach() {
        this.$element.toggle(true);
        this.visible = true;
    };

    sSidebarCtrl.prototype.refresh = function refresh() {
        digestIfNeeded(this.$scope);
    };

    ns.sSidebar = sSidebarCtrl;
})(Object.namespace('sSidebar.Component.Controller'));

