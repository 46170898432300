(function(ns) {
    var EVENT_UPDATE_RESULTS    = 'sEventUpdateResults',
        EVENT_EXECUTE_SEARCH    = 'sEventSearchTerm',
        EVENT_PARENT_KEYDOWN    = 'sEventParentKeyDown'
    ;

    /**
     * @namespace
     * @alias Controller.Directive.AutoCompleter
     *
     * @param $scope
     * @param $element
     * @param $attrs
     * @param $mdPanel
     * @param $sce
     * @constructor
     */
    var AutoCompleter = function ($scope, $element, $attrs, $mdPanel, $sce) {
        var rules = [];

        this.$scope             = $scope;
        this.$element           = $element;
        this.$attrs             = $attrs;
        this.$mdPanel           = $mdPanel;
        this.isDisabled         = true;
        this.$deRegister        = [];
        this.$runtimeDeRegister = [];
        this.$sce               = $sce;

        Object.defineProperties(
            this,
            {
                rules: {
                    set: function(val) {
                        if (!val) {
                            return;
                        }
                        Object.instanceOf(val, Array);
                        rules = val;
                    },
                    get: function() {
                        return rules;
                    }
                    /**
                     * @property
                     * @name Controller.Directive.AutoCompleter#rules
                     * @type {Array}
                     */
                }
            }
        );

        /**
         * @property
         * @name Controller.Directive.AutoCompleter#contentEditableCtrl
         * @type {Controller.Directive.ContentEditableController}
         */
    };

    /**
     * @function
     * @name Controller.Directive.AutoCompleter#$onInit
     */
    AutoCompleter.prototype.$onInit = function $onInit() {
        var self = this;

        this.$deRegister = this.$deRegister.concat(
            this.$element.$on('keydown', this.onKeyDown.bind(this))
        );

        this.$deRegister = this.$deRegister.concat(
            this.$element.$on('keyup', this.updatePosition.bind(this))
        );

        this.$deRegister = this.$deRegister.concat(
            this.$element.$on('mouseup', this.updatePosition.bind(this))
        );

        // 'contenteditable' contains interpolation
        this.$deRegister.push(this.$attrs.$observe(
            'contenteditable',
            /**
             * @param {String} newVal
             */
            function (newVal) {
                if (newVal === 'false') {
                    self.disableAutoCompleter();
                    self.removeHelperWrapper();
                } else {
                    self.enableAutoCompleter();
                    self.addHelperWrapper();
                }
            }
        ));

        this.$deRegister.push(this.$scope.$watchCollection(
            function() {
                return self.rules.map(function(rule) {
                    return rule.triggerIcon;
                })
            },
            function() {
                self.syncTriggerIcons();
            })
        );

        this.$deRegister.push(this.$scope.$on(EVENT_EXECUTE_SEARCH,
            /**
             * @param evt
             * @param {String} word
             */
            function(evt, word) {
                var wordAdjusted = word;

                if (!self.panelRef || !self.panelRef.rule) {
                    return;
                }

                var rule = self.panelRef.rule;

                // If the pattern would fail, transform it softly, so the pattern matches it
                if (!word.match(rule.pattern)) {
                    wordAdjusted = rule.transformer(word, true);
                }

                var matches;

                if ((matches = AutoCompleter.matchWithRule(wordAdjusted, rule)) === null) {
                    return;
                }

                self.updateMatches(matches, word, rule);
            })
        );
    };

    /**
     * @function
     * @name Controller.Directive.AutoCompleter#$onDestroy
     */
    AutoCompleter.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;

        this.disableAutoCompleter();

        while(($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name Controller.Directive.AutoCompleter#enableAutoCompleter
     */
    AutoCompleter.prototype.enableAutoCompleter = function enableAutoCompleter() {
        var self = this,
            readFnCache = this.contentEditableCtrl.read
        ;

        this.isDisabled = false;

        this.$runtimeDeRegister.push(function restoreReadFn() {
            self.contentEditableCtrl.read = readFnCache;
        });
        this.contentEditableCtrl.read = this.onChange.bind(this);

    };

    /**
     * @function
     * @name Controller.Directive.AutoCompleter#disableAutoCompleter
     */
    AutoCompleter.prototype.disableAutoCompleter = function disableAutoCompleter() {
        var $destroyFn;

        this.isDisabled = false;

        while(($destroyFn = this.$runtimeDeRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @param {String} word
     * @param {{results: Function, pattern: String}} rule
     * @static
     * @returns {[]|null}
     */
    AutoCompleter.matchWithRule = function matchWithRule(word, rule) {
        var matches;
        if ((matches = word.match(rule.pattern)) === null) {
            return null;
        }

        return rule.results(matches);
    };

    /**
     * @param {[]} matches
     * @param {{hint}} rule
     * @param {String} word
     */
    AutoCompleter.prototype.updateMatches = function updateMatches(matches, word, rule) {
        this.$scope.$broadcast(
            EVENT_UPDATE_RESULTS,
            {
                matches     : matches,
                hint        : rule.hint instanceof Function ? rule.hint(word) : rule.hint,
                searchTerm  : word
            });
    };

    AutoCompleter.prototype.onChange = function onChange() {
        var found = false,
            self = this,
            word
        ;

        Object.getPrototypeOf(this.contentEditableCtrl).read.call(this.contentEditableCtrl);
        this.updatePosition();

        if (!this.position || !this.position.node) {
            return;
        }

        word = this.position.node.textContent.slice(this.position.start, this.position.end);

        // and the type is matching
        if (this.rules && word) {
            $.each(this.rules, function (index, rule) {
                var matches;

                if ((matches = AutoCompleter.matchWithRule(word, rule)) !== null
                    && (matches.then || matches.length)) {

                    if (self.lastPanelTimer) {
                        clearTimeout(self.lastPanelTimer);
                    }

                    if (matches.length === 1 && word.localeCompare(rule.transformer(matches[0]), undefined, { sensitivity: 'accent' }) === 0) {
                        self.resolveTerm(matches[0], rule.transformer);
                    } else {
                        found = true;
                        self.lastPanelTimer = setTimeout(function () {
                            self.openPanel(matches, word, rule);
                        }, Const.DebounceTime / 2);
                    }
                }
            });
        }

        if (!found) {
            if (this.lastPanelTimer) {
                clearTimeout(this.lastPanelTimer);
            }
            if (this.panelRef && this.panelRef.close) {
                this.panelRef.close();
            }
        }
    };

    AutoCompleter.prototype.addHelperWrapper = function addHelperWrapper() {
        var self = this;
        if (!this.$wrapper) {
            this.$wrapper = $('<div class="autocompleter-wrapper"></div>');
            this.$triggerWrapper = $('<div class="trigger-wrapper"></div>');
            this.$deRegister = this.$deRegister.concat($(this.$wrapper).$on('click', 'span[data-trigger]', function(evt) {
                // add generic click handler to open panel for specific rule
                var trigger = $(evt.currentTarget).data('trigger');
                if (self.rules[trigger] === undefined) {
                    return;
                }

                var rule = self.rules[trigger];
                self.openPanel(rule.results(null), null, rule);
            }))
        }

        this.$element.before(this.$wrapper);
        this.$wrapper.append(this.$element);
        this.$wrapper.append(this.$triggerWrapper);

        this.syncTriggerIcons();
    };

    AutoCompleter.prototype.syncTriggerIcons = function syncTriggerIcons() {
        if (!this.$triggerWrapper) {
            return;
        }

        $('span[data-trigger]', this.$triggerWrapper).remove();
        var self = this;
        this.rules.map(function(rule, index) {
            if (!rule.triggerIcon) {
                return;
            }
            self.$triggerWrapper.append($('<span data-trigger="' + index + '">' + rule.triggerIcon + '</span>'));
        });

        this.$element.css('padding-right', this.$triggerWrapper.width() + 'px');
    };

    AutoCompleter.prototype.removeHelperWrapper = function removeHelperWrapper() {
        if (!this.$wrapper) {
            return;
        }
        this.$wrapper.before(this.$element);
        this.$wrapper.detach(); // detach keeps the click-event on the picker
    };

    /**
     *
     * @param {Array} matches
     * @param {String} word
     * @param {Object} rule
     */
    AutoCompleter.prototype.openPanel = function openPanel(matches, word, rule) {
        var self = this
        ;

        if (this.panelRef) {
            this.updateMatches(matches, word, rule);
            return;
        }

        var clientRect = this.$element[0].getBoundingClientRect(),
            viewPortRect = $('body')[0].getBoundingClientRect(),
            position = this.$mdPanel.newPanelPosition()
                .relativeTo(this.$element)
                .addPanelPosition(
                    (word === null ? this.$mdPanel.xPosition.ALIGN_END : this.$mdPanel.xPosition.ALIGN_START),
                    (clientRect.top - viewPortRect.top) > (viewPortRect.bottom - clientRect.bottom) ? this.$mdPanel.yPosition.ABOVE : this.$mdPanel.yPosition.BELOW
                ),
            locals = {
                itemTemplate: rule.itemTemplate,
                hint        : rule.hint instanceof Function ? rule.hint(word) : rule.hint,
                searchTerm  : word
            }
            ;

        if (rule.dependencies) {
            for (var i in rule.dependencies) {
                locals[i] = rule.dependencies[i]
            }
        }

        var panelCssClass = ['autocompleter-panel'];

        if (rule.panelClass) {
            panelCssClass.push(rule.panelClass);
        }

        var config = {
            attachTo            : angular.element(Const.PanelAnchor),
            controller          : rule.panelCtrl ? rule.panelCtrl : AutoCompleter.Panel,
            controllerAs        : 'ctrl',
            bindToController    : true,
            templateUrl         : rule.panelTpl ? rule.panelTpl : '_directive:autocompleter',
            panelClass          : panelCssClass.join(' '),
            position            : position,
            locals              : locals,
            scope               : this.$scope.$new(),
            clickOutsideToClose : true,
            escapeToClose       : true,
            focusOnOpen         : word === null,
            zIndex              : Const.PanelAboveDialogZIndex,
            onRemoving          : function () {
                self.handlePanelClose(rule.transformer, word);
            }
        };

        // if we're not in hint mode, then append the selection
        if (word === null && this.position) {
            this.position.start = this.position.end;
        }

        if (!this.panelRef) {
            this.panelRef = this.$mdPanel.open(config).then(function (data) {
                self.panelRef = data;
                self.panelRef.rule = rule;
                self.updateMatches(matches, word, rule);
            });
        }

        digestIfNeeded(this.$scope);
    };

    /**
     * @param transformer
     * @param word
     */
    AutoCompleter.prototype.handlePanelClose = function (transformer, word) {
        if (this.lastPanelTimer) {
            clearTimeout(this.lastPanelTimer);
        }
        // nothing was set, return
        if (!this.panelRef || !this.panelRef.result) {
            if (this.panelRef) {
                this.panelRef.destroy();
                this.panelRef = null;
            }
            if (word) {
                this.declinedWord = word.trim();
            }
            return;
        }

        this.resolveTerm(this.panelRef.result, transformer, word);

        this.panelRef.destroy();
        this.panelRef = null;
    };

    /**
     * @param {String} term
     * @param {Function} transformer
     * @param {String=} word
     */
    AutoCompleter.prototype.resolveTerm = function (term, transformer, word) {
        var range = document.createRange(),
            targetNode
        ;
        try {
            // the position may be outdated, so check if the range element is actually a child of the current input
            if (!this.$element.find(this.position.node).length && this.$element[0] !== this.position.node) {
                if (!word) {
                    // noinspection ExceptionCaughtLocallyJS
                    throw 'Invalid element!';
                }
                var nodesByContent = this.$element.nodeByContent(word);
                if (!nodesByContent.length) {
                    // noinspection ExceptionCaughtLocallyJS
                    throw 'Invalid element!';
                }
                this.position.node = nodesByContent[0];
            } else if (this.$element[0] === this.position.node && (targetNode = this.position.node.childNodes[this.position.start]) && targetNode.nodeType !== Node.TEXT_NODE) {
                var newTarget = targetNode.nextSibling;

                if (!newTarget || newTarget.nodeType !== Node.TEXT_NODE) {
                    newTarget = document.createTextNode('');
                    $(newTarget).insertAfter(targetNode);
                }

                this.position.node = newTarget;
                this.position.start = this.position.end = 0;
                targetNode = null;
            }

            range.setStart(this.position.node, this.position.start !== -1 ? this.position.start : this.position.end);
            range.setEnd(this.position.node, this.position.end);
        } catch (exc) {
            range.setStart(this.$element[0], 0);
            range.setEnd(this.$element[0], 0);
        }

        var contentNode = document.createTextNode(transformer(term));
        range.deleteContents();
        range.insertNode(contentNode);

        var sel = window.getSelection();
        sel.removeAllRanges();
        range.collapse(false);
        sel.addRange(range);

        Object.getPrototypeOf(this.contentEditableCtrl).read.call(this.contentEditableCtrl);

        this.updatePosition();
    };

    AutoCompleter.prototype.onKeyDown = function onKeyDown(event) {
        if (!this.panelRef || this.isDisabled) {
            return;
        }

        if (Const.ArrowKeys.concat([Const.EnterKey, Const.TabKey]).indexOf(event.which) !== -1) {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
        }

        this.$scope.$broadcast(EVENT_PARENT_KEYDOWN, event);
    };

    AutoCompleter.prototype.updatePosition = function updatePosition() {
        var sel     = window.getSelection(),
            text
        ;

        if (this.isDisabled || !this.$element.find(sel.anchorNode).length && this.$element[0] !== sel.anchorNode) {
            return;
        }

        // only process if there is data
        if (sel.anchorNode.nodeType === Node.TEXT_NODE) {
            text = sel.anchorNode.nodeValue;
            var lastSpacePos = text.lastIndexOf(Const.Unicode.SPACE, sel.anchorOffset - 1),
                startPos = Math.max(lastSpacePos, 0),
                word = text.slice((lastSpacePos === -1 ? startPos : startPos - -1), sel.anchorOffset)
            ;

            // for some reason it doesn't find the space sometimes in the front
            if (word.search(/^[\s]/) !== -1) {
                lastSpacePos = word.search(/[\s]/);
                word = word.slice(lastSpacePos - -1);
            }

            if (word.trim() === this.declinedWord) {
                return;
            }

            this.position = {
                node: sel.anchorNode,
                // if startPos is zero don't adjust, otherwise increase with one (for the whitespace)
                start: (lastSpacePos === -1 ? startPos : startPos - -1),
                end: startPos + (lastSpacePos === -1 ? word.length : word.length - -1)
            };
        } else {
            if (sel.anchorNode.childNodes[sel.anchorOffset]) {
                if (sel.anchorNode.childNodes[sel.anchorOffset].nodeType !== Node.TEXT_NODE) {
                    var node = document.createTextNode('');
                    sel.anchorNode.insertBefore(node, sel.anchorNode.childNodes[sel.anchorOffset]);
                }
                this.position = {
                    node: sel.anchorNode.childNodes[sel.anchorOffset],
                    start: 0,
                    end: 0
                }
            }
        }
    };

    AutoCompleter.MAX_RESULTS = 30;

    Object.defineProperties(
        AutoCompleter,
        {
            EVENT_UPDATE_RESULTS: {
                get: function () {
                    return EVENT_UPDATE_RESULTS;
                }
                /**
                 * @property
                 * @name #EVENT_UPDATE_RESULTS
                 * @type {String}
                 */
            },
            EVENT_EXECUTE_SEARCH: {
                get: function () {
                    return EVENT_EXECUTE_SEARCH;
                }
                /**
                 * @property
                 * @name #EVENT_EXECUTE_SEARCH
                 * @type {String}
                 */
            },
            EVENT_PARENT_KEYDOWN: {
                get: function () {
                    return EVENT_PARENT_KEYDOWN;
                }
                /**
                 * @property
                 * @name #EVENT_PARENT_KEYDOWN
                 * @type {String}
                 */
            }
        });

    ns.AutoCompleter = AutoCompleter;

})(Object.namespace('Controller.Directive'));
