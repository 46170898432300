(function(angular) {
    var module = angular.module('sMemberModule', []);

    module.config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/audience', new sMember.Route.Overview())
            .when('/user-attributes/overview', new sMember.Route.AttributeOverview())
        ;
    }]);

    module.config(['sTitleProvider', function(sTitleProvider) {
        sTitleProvider
            .when(
                '/audience',
                'Audience',
                'Overview'
            )
            .when(
                '/user-attributes/overview',
                'User Attributes',
                'Overview'
            )
        ;
    }]);
})(angular);
