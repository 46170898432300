(function(ns) {
    /**
     * @namespace
     * @alias Model.Menu.Entry
     *
     * @param _type
     * @constructor
     */
    var Entry = function(_type) {
        var parent,
            type    = _type,
            self    = this
            ;

        for (var i in Entry.TYPES) {
            if (_type === Entry.TYPES[i]) {
                type = _type;
                break;
            }
        }

        if (!type) {
            throw 'Invalid argument!';
        }

            /**
             * @property
             * @name Model.Menu.Entry#parent
             * @type Model.Menu.Entry
             */

            Object.defineProperties(
                self,
                {
                parent: {
                    get: function() {
                        return parent;
                    },
                    set: function(val) {
                        if (!val) {
                            parent = val;
                            return;
                        }

                        Object.instanceOf(val, ns.Menu);
                        parent = val;
                    }
                },
                /**
                 * @property
                 * @name Model.Menu.Entry#type
                 */
                type: {
                    enumerable: true,
                    get: function() {
                        for (var i in Entry.TYPES) {
                            if (Entry.TYPES[i] === type) {
                                return i.toLowerCase();
                            }
                        }
                        return '';
                    }
                }
            }
        );

        $.each(Entry.TYPES, function(index, element) {
            if (element === undefined) {
                throw 'Element for index ' + index + ' is undefined!';
            }
            var propName = 'is' + index.split('_')
                .map(function(nameFragment){ return nameFragment.substr(0, 1) + nameFragment.toLowerCase().substr(1)})
                .join('');

            if (self.hasOwnProperty(propName)) {
                return false;
            }
            Object.defineProperty(
                self,
                propName,
                {
                    get: function () {
                        return type === element;
                    }
                }
            );
        });
    };

    Entry.TYPES = {
    };

    ns.Entry = Entry;
})(Object.namespace('Model.Menu'));