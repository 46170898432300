(function(ns) {
    /**
     * @namespace
     * @alias sMessageAnchor.Service.MessageAnchor
     * @constructor
     *
     * @param $mdDialog
     * @param apiEndpoints
     * @param Notification
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param $exceptionHandler
     */
    var MessageAnchor = function ($mdDialog, apiEndpoints, Notification, sAPIAccess, $exceptionHandler) {
        this.$mdDialog          = $mdDialog;
        this.apiEndpoints       = apiEndpoints;
        this.notification       = Notification;
        this.sAPIAccess         = sAPIAccess;
        this.$exceptionHandler  = $exceptionHandler;
    };

    /**
     * @function sMessageAnchor.Service.MessageAnchor#duplicate
     * @param {Model.Message.Anchor} msgAnchor
     * @returns Promise
     */
    MessageAnchor.prototype.duplicate = function duplicate(msgAnchor) {
        var self = this;

        return this.$mdDialog.show({
            controller          : sMessageAnchor.Controller.DuplicateController,
            controllerAs        : '$ctrl',
            templateUrl         : 'smessageanchor:duplicate',
            parent              : angular.element(Const.PanelAnchor),
            clickOutsideToClose : false,
            locals: {
                messageAnchors: msgAnchor
            }
        }).then(
            function (data) {
                if (data.success) {
                    self.notification.success('Duplicate created');
                } else {
                    self.notification.error('Duplicating failed');
                }
            }
        ).catch(function (err) {
            if (err) {
                self.$exceptionHandler(err);
            }
        });
    };

    /**
     * @function sMessageAnchor.Service.MessageAnchor#activate
     * @param {Model.Message.Anchor} msgAnchor
     * @returns Promise
     */
    MessageAnchor.prototype.activate = function activate(msgAnchor) {
        var self = this;
        return msgAnchor
            .updateActiveState(false)
            .then(
                function() {
                    self.notification.success('Reactivation successful');
                },
                function(jqXHR) {
                    if (jqXHR.status === 403 && jqXHR.getResponseHeader(Const.Headers.X_RESOURCE_LIMIT_REACHED)) {
                        return self.notification.limitReached( 'info', jqXHR.getResponseHeader(Const.Headers.X_RESOURCE_LIMIT_REACHED));
                    }
                    self.notification.error('Reactivation failed');
                }
            );
    };

    /**
     * @function sMessageAnchor.Service.MessageAnchor#deactivate
     * @param {Model.Message.Anchor} msgAnchor
     * @returns Promise
     */
    MessageAnchor.prototype.deactivate = function deactivate(msgAnchor) {
        var self = this,
            def = $.Deferred()
        ;
        if (msgAnchor.type.alias === Model.Message.TYPE_ALIAS_REACTION) {
            return msgAnchor.updateActiveState(true).then(
                function() {
                    self.notification.success('Deactivation successful');
                },
                function() {
                    self.notification.error('Deactivation failed');
                });
        }

        $.ajax({
            url         : this.apiEndpoints.messageAnchor.usages(msgAnchor.uuid),
            method      : Const.Method.GET,
            context     : self,
            processData : false,
            contentType : false
        }).then(
            function(usages) {
                var innerDef;

                // open popup if usages are found
                if (usages
                    && ((usages.messageAnchors && usages.messageAnchors.length) || (usages.reactions && usages.reactions.length))) {
                    innerDef = $.when(self.deactivateWithUsages(msgAnchor, usages));
                }
                // just deactivate if no usages are found
                else {
                    innerDef = $.when(msgAnchor.updateActiveState(true));
                }

                return innerDef.then(
                    function() {
                        self.notification.success('Deactivation successful');
                        def.resolve();
                    },
                    function(isError) {
                        if (isError) {
                            self.notification.error('Deactivation failed');
                        }
                        def.reject();
                    }
                ).always(function() {
                });
            },
            function () {
                self.notification.error('Could not fetch usages');
                return def.reject();
            }
        );

        return def.promise();
    };

    /**
     * @function sMessageAnchor.Service.MessageAnchor#deactivateWithUsages
     * @param {Model.Message.Anchor} msgAnchor
     * @param {*} usages
     * @returns Promise
     */
    MessageAnchor.prototype.deactivateWithUsages = function deactivateWithUsages(msgAnchor, usages) {
        var def = $.Deferred();

        // workaround because it returns a different promise object: Promise {$$state: Object}
        this.$mdDialog.show({
            controller          : sMessageAnchor.Controller.DeactivateController,
            controllerAs        : '$ctrl',
            templateUrl         : 'smessageanchor:deactivate',
            parent              : angular.element(Const.PanelAnchor),
            clickOutsideToClose : false,
            locals: {
                messageAnchor   : msgAnchor,
                usages          : usages
            }
        }).then(def.resolve, def.reject);

        return def.promise();
    };

    /**
     * @function sMessageAnchor.Service.MessageAnchor#removeAnchor
     * @param {Model.Message.Anchor} msgAnchor
     * @returns jqXHR
     */
    MessageAnchor.prototype.removeAnchor = function removeAnchor(msgAnchor) {
        var deleteEndpoint = this.sAPIAccess.endpoint('messageAnchor.RESTAccess').delete(msgAnchor.uuid);

        return $.ajax({
            url         : deleteEndpoint,
            method      : Const.Method.DELETE,
            context     : self,
            processData : false,
            contentType : false
        });
    };

    ns.MessageAnchor = MessageAnchor;
})(Object.namespace('sMessageAnchor.Service'));
