(function (ns) {
    /**
     * @namespace
     * @alias sInbox.Route.Index
     * @extends sRoute
     * @constructor
     */
    var Index = function Index() {
        this.controller     = sInbox.Controller.Index;
        this.controllerAs   = '$indexCtrl';
        this.templateUrl    = 'sinbox:index';
        this.reloadOnSearch = false;
        this.accessControl  = {'messageIncoming.feedback.all': Const.Method.GET};

        Index._pProto.constructor.call(this);
    };

    Object.extendProto(Index, sRoute);

    /**
     * @function
     * @name sInbox.Route.Index#resolveListUri
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @returns Promise
     */
    Index.prototype.resolveListUri = function resolveListUri(sAPIAccess) {
        return sAPIAccess.endpoint('messageIncoming.feedback.all').get();
    };

    /**
     * @function
     * @name sInbox.Route.Index#resolveConditionDefinitions
     * @param {sSegment.Service.sConditionDefinition} sSegmentConditionDefinition
     * @returns {*}
     */
    Index.prototype.resolveConditionDefinitions = function resolveConditionDefinitions(sSegmentConditionDefinition) {
        return sSegmentConditionDefinition.init();
    };

    ns.Index = Index;
})(Object.namespace('sInbox.Route'));
