(function (ns) {
    /**
     * @namespace
     * @alias Service.sFormPanel
     *
     * @constructor
     * @param $mdPanel
     */
    var sFormPanel = function ($mdPanel) {
        this.$mdPanel   = $mdPanel;
        this.mdPanelRef = null;

        var isOpen      = null;

        /**
         * @param $event
         * @param {Object} model
         * @param {Object} rules
         * @param {String=} templateUrl
         * @param {Object=} extraLocals
         * @return {$.Deferred} Returns success if the form was submitted and rejection if canceled
         */
        this.open = function open($event, model, rules, templateUrl, extraLocals) {
            if (!isOpen) {
                isOpen  = Object.getPrototypeOf(this).open.call(this, $event, model, rules, templateUrl, extraLocals).always(function() {
                    isOpen = null;
                });
            }

            return isOpen;
        };

        this.handleClose = function handleClose() {
            if (!this.mdPanelRef) {
                return;
            }

            this.mdPanelRef.destroy();
            isOpen = null;
        };
    };

    /**
     * @param $event
     * @param {Object} model
     * @param {Object} rules
     * @param {String=} templateUrl
     * @param {Object=} extraLocals
     * @return {$.Deferred} Returns success if the form was submitted and rejection if canceled
     */
    sFormPanel.prototype.open = function open($event, model, rules, templateUrl, extraLocals) {
        var position       = this.$mdPanel.newPanelPosition(),
            $anchorElement = $event.target ? $($event.target).closest('div') : $($event.currentTarget),
            clientRect     = $anchorElement[0].getBoundingClientRect(),
            viewPortRect   = $('body')[0].getBoundingClientRect(),
            $deferred      = $.Deferred(),
            self = this
            ;

        angular.element($event.currentTarget).on('$destroy', function () {
            self.handleClose();
        });

        if (!templateUrl) {
            templateUrl = '_services/form-panel';
        }

        extraLocals = extraLocals || {};

        position.relativeTo($anchorElement)
            .addPanelPosition(
                (clientRect.left - viewPortRect.left) > (viewPortRect.right - clientRect.right)
                    ? this.$mdPanel.xPosition.ALIGN_END
                    : this.$mdPanel.xPosition.ALIGN_START,
                (clientRect.top - viewPortRect.top) > (viewPortRect.bottom - clientRect.bottom)
                    ? this.$mdPanel.yPosition.ALIGN_BOTTOMS
                    : this.$mdPanel.yPosition.ALIGN_TOPS
            );

        var panelAnimation = this.$mdPanel.newPanelAnimation()
            .openFrom($anchorElement)
            .duration(100)
            .closeTo($anchorElement)
            .withAnimation(this.$mdPanel.animation.SCALE);

        var clone = model.clone(),
            config = {
                attachTo            : angular.element(Const.PanelAnchor),
                controller          : function() {
                    this.cancel = function cancel() {
                        this.mdPanelRef.close().then(function() {
                            $deferred.reject();
                        });
                    }
                },
                controllerAs        : '$ctrl',
                bindToController    : true,
                templateUrl         : templateUrl,
                position            : position,
                disableParentScroll : true,
                locals: $.extend({
                    model: clone,
                    rules: rules,
                    onSubmit: function() {
                        this.mdPanelRef.close().then(function() {
                            $deferred.resolveWith(this, [this.model, model]);
                        }.bind(this));
                    }
                }, extraLocals),
                clickOutsideToClose : true,
                escapeToClose       : true,
                focusOnOpen         : false,
                zIndex              : 30,
                animation           : panelAnimation,
                onCloseSuccess      : this.handleClose.bind(this)
        };

        this.$mdPanel.open(config).then(function (mdPanelRef) {
            self.mdPanelRef = mdPanelRef;
        });

        return $deferred.promise();
    };

    ns.sFormPanel = sFormPanel;
})(Object.namespace('Service'));
