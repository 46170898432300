(function(ns){
    /**
     * @namespace
     * @alias Controller.Component.View.sCTACollection
     *
     * @param $scope
     * @param $element
     * @param {Service.sAction} sActionService
     */
    var sCTACollection = function($scope, $element, sActionService) {
        var self = this;

        /**
         * @name Controller.Component.View.sCTACollection#model
         * @type Model.CTA.Collection
         */

        /**
         * @name Controller.Component.View.sCTACollection#messageCtrl
         * @type Controller.Component.View.sMessage
         */

        /**
         * @name Controller.Component.View.sCTACollection#sMessageRepository
         */

        /**
         * @name Controller.Component.View.sCTACollection#$scope
         * @type Scope
         */
        this.$scope     = $scope;

        /**
         * @name Controller.Component.View.sCTACollection#$element
         * @type jQuery
         */
        this.$element   = $element;

        /**
         * @name Controller.Component.View.sCTACollection#definitions
         * @type {?Model.Action.Definition.Collection}
         */
        this.definitions = null;
        sActionService.getDefinitions().then(function (defCollection) {
             self.definitions = defCollection;
            digestIfNeeded($scope);
        });

        this.$deRegister = [];
    };

    /**
     * @name Controller.Component.View.sCTACollection#$onInit
     */
    sCTACollection.prototype.$onInit = function $onInit() {
        var self = this;
        this.$deRegister.push(this.$scope.$watch(
            self.getActiveRelation.bind(self),
            /**
             * @param {Model.Message.Relation} val
             */
            function(val) {
                if (!val || !self.model || !(val.options.hasOwnProperty('uuid'))) {
                    return;
                }

                for (var i = 0; i < self.model.ctas.length; i++) {
                    var action = self.model.ctas[i].getPostbackAction();
                    if (action && action.uuid === val.options.uuid) {
                        setTimeout(
                            (function(i) {
                                return function() {self.scrollTo(i)};
                            })(i),
                        1);
                        break;
                    }
                }
            }
        ));
        /**
         * @name Controller.Component.View.sCTACollection#$ctaContainer
         * @type jQuery
         */
        this.$ctaContainer          = this.$element.find('.cta-container');
        this.selected               = 0;
    };

    sCTACollection.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    /**
     * @name Controller.Component.View.sCTACollection#scrollTo
     */
    sCTACollection.prototype.scrollTo = function scrollTo(index) {
        var $target = $(this.$ctaContainer.children()[index]),
            $posT = $target.position(),
            $posE = this.$ctaContainer.position()
            ;

        this.selected = index;

        if (!$posT) {
            return;
        }

        if (this.$ctaContainer.css('flex-direction') === 'row-reverse') {
            this.$ctaContainer.scrollLeft(
                $posT.left + $target.outerWidth()
                + this.$ctaContainer.scrollLeft()
                - ($posE.left + this.$ctaContainer.width())
            );
        } else {
            this.$ctaContainer.scrollLeft(
                $posT.left
                + this.$ctaContainer.scrollLeft()
                - $posE.left
                - parseInt(this.$ctaContainer.css('padding-left'))
            );
        }
        digestIfNeeded(this.$scope);
    };

    /**
     * Retrieves the currently active relation for a message
     * @name Controller.Component.View.sCTACollection#getActiveRelation
     * @returns {Model.Message.Relation|null}
     */
    sCTACollection.prototype.getActiveRelation = function getActiveRelation() {
        if (!this.messageCtrl
            || !this.messageCtrl.activeRelation) {
            return null;
        }

        return this.messageCtrl.activeRelation;
    };

    /**
     * @name Controller.Component.View.sCTACollection#handleClick
     * @param {Model.CTA} cta
     */
    sCTACollection.prototype.handleCTAClick = function(cta) {
        for (var i in this.model.ctas) {
            if (this.model.ctas[i].uuid === cta.uuid) {
                this.scrollTo(i);
                break;
            }
        }

        if (!this.messageCtrl) {
            return;
        }

        this.messageCtrl.onHandleCtaClick({
            message: this.messageCtrl.model,
            visibility: !this.isCTAActive(cta),
            cta: cta
        });
    };

    /**
     * @name Controller.Component.View.sCTACollection#isUnbound
     */
    sCTACollection.prototype.isUnbound = function isUnbound(cta) {
        var postbackAction = cta.getPostbackAction(),
            message
            ;

        if (!postbackAction) {
            return false;
        }

        if (this.sMessageRepository) {
            message = this.sMessageRepository.repository.message.findMessageInCollections(postbackAction.value);
        } else if (this.messageCtrl) {
            message = this.messageCtrl.model.findMessageInCollections(postbackAction.value);
        } else {
            return true;
        }

        return !message || message.hasNoContent();
    };

    /**
     * Returns if the active relation equals to a give CTA
     * @name Controller.Component.View.sCTACollection#isCTAActive
     * @param cta
     * @returns {boolean}
     */
    sCTACollection.prototype.isCTAActive = function isCTAActive(cta) {
        var relation;
        if ((relation = this.getActiveRelation()) && relation.options.uuid) {
            var postbackAction = cta.getPostbackAction();
            return postbackAction !== null && relation.options.uuid === postbackAction.uuid;
        }

        return false;
    };

    /**
     *
     * @param {String} uuid
     * @returns {object}
     */
    sCTACollection.prototype.getRate = function getRate(uuid) {
        if (!this.messageCtrl) {
            return null;
        }

        var analytics = this.messageCtrl.model.getMeta(Model.Message.KEY_ANALYTICS_META);

        if (!analytics || !analytics.ctas || !analytics.ctas[uuid]) {
            return {};
        }

        return analytics.ctas[uuid];
    };

    /**
     * @function
     * @name Controller.Component.View.sCTACollection#isRateAvailable
     * @param {Model.CTA} cta
     * @returns {boolean}
     */
    sCTACollection.prototype.isRateAvailable = function isRateAvailable(cta) {
        var withoutCombinable, action;

        if (!this.definitions
            || !this.definitions.length
            || !cta.actions
            || !cta.actions.length
        ) {
            return false;
        }

        if (cta.actions.length > 1) {
            return true;
        }

        withoutCombinable = this.definitions.definitions
            .filter(function (definition) {
                // url is a special case here and has rates even without combinables
                if (definition.name === Model.Action.TYPE_URL) {
                    return false;
                }
                return !definition.combinables.length;
            })
            .map(function (definition) {
                return definition.name;
            });

        // here we are sure we just have exactly one element inside
        action = cta.actions.actions.slice().pop();

        return withoutCombinable.indexOf(action.type) === -1;
    };

    ns.sCTACollection = sCTACollection;
})(Object.namespace('Controller.Component.View'));
