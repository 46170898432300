/**
 * Created by Gabor on 17-Oct-17.
 */

(function(angular) {
    var sEmojiModule = angular.module('sEmoji', []);

    sEmojiModule.service('sEmojiSheet', ['emojiEndPoint', sEmoji.Service.sEmojiSheet]);


    sEmojiModule.run(function($rootScope, sEmojiSheet) {
        $rootScope.$on('$routeChangeStart', function(event, $route) {
            $route.resolve = $route.resolve || {};
            $route.resolve['sEmoji'] = sEmojiSheet.init.bind(sEmojiSheet);
        });
    });
})(angular);