(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sDomain
     *
     * @param sDomainService
     * @param {sDomain.Service.sDomainLogo} sDomainLogoService
     * @param $element
     * @param $mdPanel
     * @param $mdSidenav
     * @param $scope
     */
    var sDomain = function(sDomainService, sDomainLogoService, $element, $mdPanel, $mdSidenav, $scope) {
        this.sDomainService         = sDomainService;
        this.sDomainLogoService     = sDomainLogoService;
        this.$element               = $element;
        this.$mdPanel               = $mdPanel;
        this.$mdSidenav             = $mdSidenav;
        this.$scope                 = $scope;
        this.hoveredDomainIndex     = null;

        /**
         * @name Controller.Component.sDomain#activeList
         * @property
         * @type Array|null
         */
    };

    sDomain.prototype.handleClick = function handleClick() {
        if (this.sDomainService.domains.length <= 1) {
            return;
        }

        if (this.compact) {
            this.$mdSidenav(Const.SideNavLeft).$rScope     = this.$scope;
            this.$mdSidenav(Const.SideNavLeft).templateUrl = sDomain.TEMPLATE_URL;

            if (!this.$mdSidenav(Const.SideNavLeft).isOpen()) {
                this.$mdSidenav(Const.SideNavLeft).open().then(this.focusInputField.bind(this));

            }
        } else {
            this.openPanel();
        }
    };

    sDomain.prototype.openPanel = function openPanel() {
        var position = this.$mdPanel.newPanelPosition()
                .relativeTo(this.$element)
                .addPanelPosition(
                    this.$mdPanel.xPosition.ALIGN_START,
                    this.$mdPanel.yPosition.BELOW
                ),
            self = this
            ;

        var panelAnimation = this.$mdPanel.newPanelAnimation()
            .openFrom(this.$element)
            .duration(200)
            .closeTo(this.$element)
            .withAnimation(this.$mdPanel.animation.FADE);

        var config = {
            attachTo                : angular.element(Const.PanelAnchor),
            controller              : function() {
                self.mdPanelRef = this.mdPanelRef;
                return self;
            },
            controllerAs            : '$ctrl',
            templateUrl             : sDomain.TEMPLATE_URL,
            panelClass              : 'panel-default',
            position                : position,
            animation               : panelAnimation,
            clickOutsideToClose     : true,
            escapeToClose           : true,
            focusOnOpen             : false,
            zIndex                  : Const.PanelAboveDialogZIndex,
            onCloseSuccess          : function(mdPanelRef) {
                if (!mdPanelRef) {
                    return;
                }
                mdPanelRef.destroy();
            }
        };

        return this.$mdPanel.open(config).then(this.focusInputField.bind(this));
    };

    sDomain.prototype.useDomain = function useDomain(domain) {
        this.sDomainService.currentDomain = domain;
        if (this.mdPanelRef) {
            this.mdPanelRef.close();
            this.mdPanelRef = null;
        }

        if (this.$mdSidenav(Const.SideNavLeft).isOpen()) {
            this.$mdSidenav(Const.SideNavLeft).close();
        }

        if (this.fragment) {
            this.fragment = null;
        }
    };

    sDomain.prototype.handleKeyDown = function handleKeyDown(event) {
        var handled = [Const.DownArrow, Const.UpArrow, Const.EnterKey]
            ;

        if (handled.indexOf(event.keyCode) === -1) {
            return;
        }

        event.preventDefault();

        if (!this.activeList) {
            return;
        }

        this.hoveredDomainIndex = this.hoveredDomainIndex || 0;

        switch (event.keyCode) {
            case Const.DownArrow:
                this.hoveredDomainIndex++;
                break;
            case Const.UpArrow:
                this.hoveredDomainIndex--;
                break;
            case Const.EnterKey:
                this.useDomain(this.activeList[this.hoveredDomainIndex]);
                break;
        }

        if (this.hoveredDomainIndex < 0) {
            this.hoveredDomainIndex = this.activeList.length -1;
        } else if (this.hoveredDomainIndex >= this.activeList.length) {
            this.hoveredDomainIndex = 0;
        }
    };

    sDomain.prototype.handleMouseOver = function handleMouseOver(event, index) {
        this.hoveredDomainIndex = index;
    };

    sDomain.prototype.focusInputField = function focusInputField() {
        $('.domain-select-panel input').focus();
    };

    sDomain.TEMPLATE_URL = '_domain-select';

    ns.sDomain = sDomain;
})(Object.namespace('Controller.Component'));
