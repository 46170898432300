(function(ns) {
    /**
     * @namespace
     * @alias sUserManagement.Service.sUser
     * @constructor
     *
     * @param apiEndpoints
     * @param {Service.sConfirm} sConfirm
     * @param {Service.sDomain} sDomainService
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param $mdDialog
     * @param Notification
     */
    var sUser = function (apiEndpoints, sConfirm, sDomainService, $mdDialog, Notification, sAPIAccess) {
        this.apiEndpoints   = apiEndpoints;
        this.sConfirm       = sConfirm;
        this.sDomainService = sDomainService;
        this.$mdDialog      = $mdDialog;
        this.notification   = Notification;
        this.sAPIAccess     = sAPIAccess;
    };

    /**
     * @function
     * @name sUserManagement.Service.sUser#removeUserRole
     * @param {Model.User} user
     * @param {Model.User.Role} role
     * @param domainId
     * @returns {$.Deferred}
     */
    sUser.prototype.removeUserRole = function removeUserRole(user, role, domainId) {
        domainId = domainId || null;
        Object.instanceOf(user, Model.User);
        Object.instanceOf(role, Model.User.Role);

        // for now we just want to handle removal of role in a domain
        if (!domainId) {
            return $.Deferred().resolve();
        }

        return $.ajax({
            url         : this.apiEndpoints.user.byDomain(domainId).userRole(user.uuid, role.uuid),
            method      : Const.Method.DELETE
        }).then(function () {
            user.removeRole(role, domainId);
        })
    };

    /**
     * @function
     * @name sUserManagement.Service.sUser#resendInvitation
     * @param {Model.User} user
     *
     * @returns {$.Deferred}
     */
    sUser.prototype.resendInvitation = function resendInvitation(user) {
        var self = this;

        Object.instanceOf(user, Model.User);

        return $.ajax({
            url    : this.sAPIAccess.endpoint('user.resendInvitation').post(user.uuid),
            method : Const.Method.POST
        }).then(
            function () {
                self.notification.success('New invitation was sent to ' + user.email);
            },
            function () {
                self.notification.error('An error occurred when trying to resend invitation.');
            }
        );
    };

    /**
     * @function
     * @name sUserManagement.Service.sUser#openRemoveUserDialog
     * @param {Model.User} user
     * @returns {Promise}
     */
    sUser.prototype.openRemoveUserDialog = function removeUserDialog(user) {
        Object.instanceOf(user, Model.User);

        var self = this,
            def = $.Deferred(),
            confirmOptions = {
                confirm: 'Remove',
                decline: 'Cancel',
                title: 'Remove user',
                content: 'Do you really want to remove the user <i>' + user.email + '</i>?',
                ariaLabel: ''
            };

        this.sConfirm.open(confirmOptions).then(
            function() {
                var role = user.getRolesByDomainId(self.sDomainService.currentDomainId).slice(0, 1).pop();
                if (!role) {
                    return def.resolve();
                }
                self.removeUserRole(user, role, self.sDomainService.currentDomainId).then(def.resolve, def.reject);
            },
            function() {
                def.reject(true);
            }
        );

        def.then(
            function () {
                self.notification.success('User successfully removed');
            },
            function (isCancel) {
                if (!isCancel) {
                    self.notification.error('Removing of user failed');
                }
            }
        );

        return def.promise();
    };

    /**
     * @function
     * @name sUserManagement.Service.sUser#openAddUserDialog
     * @returns {Promise}
     */
    sUser.prototype.openAddUserDialog = function openAddUserDialog() {
        return this.editUser();
    };

    /**
     * @function
     * @name sUserManagement.Service.sUser#openEditUserDialog
     * @param {Model.User} user
     * @returns {Promise}
     */
    sUser.prototype.openEditUserDialog = function openEditUserDialog(user) {
        Object.instanceOf(user, Model.User);

        return this.editUser(user);
    };

    /**
     * @function
     * @name sUserManagement.Service.sUser#editUser
     * @param {Model.User=} user
     * @returns {Promise}
     */
    sUser.prototype.editUser = function editUser(user) {
        var self = this,
            def = $.Deferred();

        this.$mdDialog.show({
            controller: sUserManagement.Controller.Edit,
            controllerAs: '$ctrl',
            templateUrl: 'susermanagement:edit',
            parent: angular.element(Const.PanelAnchor),
            clickOutsideToClose: false,
            locals: {
                user: user
            }
        }).then(def.resolve, def.reject);

        def.then(
            function () {
                self.notification.success('User successfully saved');
            }
        );

        return def.promise();
    };

    ns.sUser = sUser;
})(Object.namespace('sUserManagement.Service'));