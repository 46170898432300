/**
 * Created by Gabor on 01-Apr-17.
 */

var ns = Object.namespace('Model.Rule'),
    Collection = ns.Collection = function() {
    this.items = [];

    var items       = [],
        itemsCache  = [],
        refreshItemsCache = function() {
            itemsCache.splice.apply(itemsCache, [0, itemsCache.length].concat(items));
        }
    ;

    /**
     * @name Model.Rule.Collection#rules
     */
    Object.defineProperties(
        this,
        {
            rules: {
                configurable: true,
                enumerable  : true,
                get         : function() {
                    return itemsCache;
                }
            }
        }
    );

    /**
     * @name Model.Rule.Collection#addRule
     * @param {Model.Rule} rule
     * @returns {Model.Rule.Collection}
     */
    this.addRule = function addRule(rule) {
        Object.instanceOf(rule, Model.Rule);

        items.push(rule);

        refreshItemsCache();
        return this;
    };

        /**
         * @name Model.Rule.Collection#getRuleByType
         * @param {String} type
         * @returns {Model.Rule}
         */
        this.getRuleByType = function getRuleByType(type) {
            return items.filter(function (element) {
                return element.type === type;
            }).pop();
        };

    /**
     * @name Model.Rule.Collection#removeRule
     * @param {Model.Rule} rule
     * @returns {Model.Rule.Collection}
     */
    this.removeRule = function removeRule(rule) {
        Object.instanceOf(rule, Model.Action);

        var pos;

        if ((pos = items.indexOf(rule)) !== -1) {
            items.splice(pos, 1);
        }


        return this;
    };

    /**
     * @name Model.Rule.Collection#__extendClone
     * @param {Model.Rule.Collection} original
     */
    this.__extendClone = function(original) {
        for (var i = 0; i < original.rules.length; i++) {
            this.addRule(original.rules[i].clone());
        }
    }
};

/**
 * @name Model.Rule.Collection#updateByData
 */
Collection.prototype.updateByData = function(data) {
    if (data && data.length) {
        for (var i = 0; i < data.length; i++) {
            var rule = this.getRuleByType(data[i].type);
            for (var j in rule) {
                rule[j] = data[i][j];
            }
        }
    }
};

Collection.prototype.toJSON = function toString() {
    return this.rules;
};

/**
 * @name Model.Rule.Collection#initWithAll
 * @returns {Model.Rule.Collection}
 */
Collection.prototype.initWithAll = function initWithAll() {
    for (var i in Model.Rule.TYPES) {
        var rule = new Model.Rule(i);
        this.addRule(rule);
    }

    return this;
};