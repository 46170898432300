(function (ns) {

    var MATCH_TEXT_HELPER_OPENED_CLASS    = 'match-text-helper-opened',
        MATCH_TEXT_HELPER_CONTAINER_CLASS = 's-match-text-helper-container',
        SELECTED_CLASS                    = 'selected',

        EVENT_REQUEST_GET_MANAGED_ACTION          = 'sEventRequestGetManagedAction',
        EVENT_REQUEST_UPDATE_MANAGED_ACTION_STATE = 'sEventRequestUpdateManaged',
        EVENT_REQUEST_SYNC_MANAGED_ACTIONS        = 'sEventRequestSyncManagedActions'
    ;

    /**
     * @param {Model.AI.MatchGroup} group
     */
    var forceEntityAssocRefresh = function forceEntityAssocRefresh(group) {
        if (!group.isEntity || group.entity) {
            return;
        }

        group.refreshEntityAssoc();
    };

    /**
     *
     * @namespace
     * @alias Controller.Directive.MatchTextHelper
     * @constructor
     *
     * @param $scope
     * @param $element
     * @param $filter
     * @param $mdPanel
     * @param $compile
     * @param {Model.AI.EntityCollection} sEntityRepository
     */
    var MatchTextHelper = function($scope, $element, $filter, $mdPanel, $compile, sEntityRepository) {
        this.$scope             = $scope;
        this.$element           = $element;
        this.$filter            = $filter;
        this.$mdPanel           = $mdPanel;
        this.$deRegister        = [];
        this.enabled            = true;
        this.$compile           = $compile;
        this.groups             = [];
        this.sEntityRepository  = sEntityRepository;
        this.hasFocus           = false;

        this.modelWatcher = function modelWatcher(val) {
            this.$filter('matchTextTokenize')(val, this.groups, true);
            this.groups.map(function (group) {
                forceEntityAssocRefresh(group);
            });
        };

        /**
         * @property
         * @name Controller.Directive.MatchTextHelper#ngModelCtrl
         * @type {Object}
         */

        /**
         * @property
         * @name Controller.Directive.MatchTextHelper#contentEditableCtrl
         * @type {Object}
         */

        /**
         * @property
         * @name Controller.Directive.MatchTextHelper#autocompleterCtrl
         * @type {Object|null}
         */

        /**
         * @property
         * @name Controller.Directive.MatchTextHelper#hintPlaceholderCtrl
         * @type {Object|null}
         */

        /**
         * @property
         * @name Controller.Directive.MatchTextHelper#matchTextCollectionCtrl
         * @type {Controller.Component.sMatchTextCollection}
         */
    };

    /**
     * @function
     * @name Controller.Directive.MatchTextHelper#$onInit
     */
    MatchTextHelper.prototype.$onInit = function $onInit() {
        if (this.autocompleterCtrl && this.hintPlaceholderCtrl) {
            throw 'Match text helper conflicts with directive "hintPlaceholder"';
        }

        if (this.matchTextCtrl && this.matchTextCtrl.model) {
            this.groups = this.matchTextCtrl.model.groups;
        }

        var self = this;

        this.$toggleElement = $('<i ng-click="$matchTextCtrl.toggle()" class="fa toggler" ng-class="{\'fa-toggle-on\' : $matchTextCtrl.enabled, \'fa-toggle-off\' : !$matchTextCtrl.enabled}">' +
            '<md-tooltip>' +
            '<span ng-if="$matchTextCtrl.enabled">Turn off syntax highlighter to see the underlying raw template. Use at you own risk!</span>' +
            '<span ng-if="!$matchTextCtrl.enabled">Turn on syntax highlighter to have a convenient way of managing the match text.</span>' +
            '</md-tooltip>' +
            '</i>'
        );

        this.ngModelCtrl.$parsers.push(function(valueFromView) {
            if (!self.enabled) {
                return valueFromView;
            }

            return self.parseViewValue(valueFromView);
        });

        this.ngModelCtrl.$formatters.push(function(valueFromModel) {
            if (!self.enabled) {
                return valueFromModel;
            }

            return self.parseModelValue(valueFromModel);
        });

        this.$deRegister.push(this.$scope.$on(Controller.Directive.MatchTextHelperPanel.EVENT_CREATE_NEW_ENTITY_FROM_UI, function () {
            var promise;

            if (self.autocompleterCtrl.panelRef) {
                promise = self.autocompleterCtrl.panelRef.close();
            } else {
                promise = $.Deferred().resolve();
            }

            promise.then(function () {
                self.$element.focus();
                self.autocompleterCtrl.resolveTerm(Model.AI.MatchGroup.ENTITY_PREFIX, function(val) { return val});
            });
        }));

        this.$deRegister.push(
            this.$scope.$watch(
                function () {
                    return self.ngModelCtrl.$modelValue;
                },
                this.modelWatcher.bind(this)
            )
        );

        this.$deRegister = this.$deRegister.concat(this.$element.$on('click', 'span[data-group]', this.handleGroupClick.bind(this)));

        this.$deRegister = this.$deRegister.concat(this.$element.$on('copy', this.handleCopy.bind(this)));

        this.$deRegister = this.$deRegister.concat(this.$element.$on('blur', function() {
            // force refresh, the caret position must have changed because of the focus lost
            self.groups.map(forceEntityAssocRefresh);
            self.ngModelCtrl.$validate();
            self.hasFocus = false;
        }));

        this.$deRegister = this.$deRegister.concat(this.$element.$on('focus', function() {
            self.hasFocus = true;
        }));

        this.$deRegister.push(this.$scope.$on(Controller.Component.sMatchTextCollection.EVENT_UPDATE_ENTITY_MATCH_GROUPS, function (event, entities) {
            self.groups.map(function (group, key) {
                if (!group.entity) {
                    return;
                }

                var matchingEntity = entities.filter(function (entity) {
                    return entity.uuid === group.entity.uuid;
                }).shift();

                if (matchingEntity) {
                    group.setMeta('partialEntity', matchingEntity.getMeta('partialEntity'));
                }

                self.updateDom(key, true);
                setTimeout(function() {
                    self.ngModelCtrl.$validate();
                }, 10);
                digestIfNeeded(self.$scope);
            })
        }));

        this.$element.parent().addClass(MATCH_TEXT_HELPER_CONTAINER_CLASS);

        this.$element.parent().append(this.$compile(this.$toggleElement[0].outerHTML)(this.$scope));

        this.attachAutocompleter();
    };

    /**
     * @param event
     */
    MatchTextHelper.prototype.handleGroupClick = function handleGroupClick(event) {
        var $target    = $(event.currentTarget),
            groupId    = parseInt($target.data('group'))
        ;

        if (!this.groups[groupId]) {
            return;
        }

        var clientRect = event.currentTarget.getBoundingClientRect(),
            viewPortRect = $('body')[0].getBoundingClientRect(),
            textHelperTotalWidth = 580,
            offsetX = (clientRect.left + textHelperTotalWidth) > viewPortRect.width ?
                (viewPortRect.width - textHelperTotalWidth - clientRect.left) : 0,
            position = this.$mdPanel.newPanelPosition()
                .relativeTo(event.currentTarget)
                .addPanelPosition(
                    this.$mdPanel.xPosition.ALIGN_START,
                    (clientRect.top - viewPortRect.top) > (viewPortRect.bottom - clientRect.bottom) ? this.$mdPanel.yPosition.ABOVE : this.$mdPanel.yPosition.BELOW
                ).withOffsetX(offsetX),
            self = this
        ;

        this.$element.parent().addClass(MATCH_TEXT_HELPER_OPENED_CLASS);
        $target.addClass(SELECTED_CLASS);

        var panelAnimation = this.$mdPanel.newPanelAnimation()
            .openFrom(event.currentTarget)
            .duration(200)
            .closeTo(event.currentTarget)
            .withAnimation(this.$mdPanel.animation.SCALE);

        this.contentEditableCtrl.handleFocusLost();

        var groupToUse = this.groups[groupId];

        // has no associated entity, then force an event emit
        // this can happen
        //   > typing an entity that has only the caret marker > these are ignored because might be still in progress
        //   > if the model value is parsed before the entitiesService is initialized (those are in the same layer, but not necessarily sync-ed)
        forceEntityAssocRefresh(groupToUse);

        //noinspection JSUnusedGlobalSymbols
        var config = {
            attachTo            : angular.element(Const.PanelAnchor),
            controller          : Controller.Directive.MatchOptions,
            controllerAs        : '$matchOptionsCtrl',
            bindToController    : true,
            templateUrl         : '_directive:match_options',
            panelClass          : 's-dialog syntax-highlighter',
            position            : position,
            animation           : panelAnimation,
            disableParentScroll : true,
            locals: {
                groups             : this.groups,
                group              : groupToUse,
                groupId            : groupId,
                updateFn           : this.updateGroup.bind(this),
                checkManagedAction : this.matchTextCollectionCtrl.model.isNegative ? null : this.getManagedAction.bind(this)
            },
            clickOutsideToClose : true,
            escapeToClose       : true,
            focusOnOpen         : true,
            zIndex              : Const.PanelAboveDialogZIndex,
            onRemoving          : function (mdPanelRef) {
                self.handlePanelClose($target);
                if (mdPanelRef.entity) {
                    self.updateManagedActionState(mdPanelRef.entity, mdPanelRef.managedAction);
                }
            }
        };

        if (!this.panelRef) {
            this.panelRef = this.$mdPanel.open(config).then(function (data) {
                self.panelRef = data;
                setTimeout(function() {
                    $('.synonym-helper input').focus();
                }, 200);
            });
        }
        digestIfNeeded(this.$scope);
    };

    /**
     * @param {Object} $target
     */
    MatchTextHelper.prototype.handlePanelClose = function ($target) {
        this.$element.parent().removeClass(MATCH_TEXT_HELPER_OPENED_CLASS);
        $target.removeClass(SELECTED_CLASS);

        if (this.lastPanelTimer) {
            clearTimeout(this.lastPanelTimer);
        }
        this.ngModelCtrl.$$lastCommittedViewValue = '';
        this.ngModelCtrl.$setViewValue(this.parseModelValue(Model.AI.MatchText.buildPattern(this.groups)));

        this.$element.focus();

        var destroyFunction = function () {
            this.panelRef.destroy();
            this.panelRef = null;
        }.bind(this);

        if (this.panelRef) {
            // If panel reference is still a promise
            if (this.panelRef.then instanceof Function) {
                this.panelRef.then(destroyFunction.bind(this));
            } else {
                destroyFunction.call(this);
            }
        }
    };

    /**
     * @param {String} valueFromView
     * @returns {String}
     */
    MatchTextHelper.prototype.parseViewValue = function parseViewValue(valueFromView) {
        var self    = this,
            $el     = $('<div></div>').html(valueFromView)
            ;

        $el.find('span[data-group]').each(function(index, element) {
            var $element    = $(element),
                groupId     = parseInt($element.data('group'))
            ;

            if (self.groups && self.groups[groupId]) {
                if (self.groups[groupId].elements.length >= 1) {
                    self.groups[groupId].elements[0].value = $element.text();
                    $element.text(self.groups[groupId].toString(true));
                }
            }
        });

        return $el.html();
    };

    /**
     * @param {Number} groupId
     * @param {Boolean=} attrOnly
     */
    MatchTextHelper.prototype.updateDom = function updateDom(groupId, attrOnly) {
        var $refElement = $(this.groups[groupId].toHTML(groupId)),
            $liveSpan   = this.$element.find('span[data-group="' + groupId + '"]')
        ;

        if (!$liveSpan.length || !$refElement.length) {
            return;
        }

        var outerOld = $liveSpan[0].outerHTML,
            outerNew = $refElement[0].outerHTML
        ;

        if (outerOld.replace(Controller.Directive.ContentEditableController.SELECTION_MARKERS_REGEXP, '') === outerNew.replace(Controller.Directive.ContentEditableController.SELECTION_MARKERS_REGEXP, '')) {
            $liveSpan.html($refElement.html());
        } else {
            $liveSpan.replaceWith($refElement);
            $liveSpan = $refElement;
        }

        if (attrOnly) {
            return;
        }

        var parsedValue = this.groups[groupId].toString(true);
        if (!this.hasFocus) {
            parsedValue.replace(Controller.Directive.ContentEditableController.SELECTION_MARKERS_REGEXP, '');
        }

        for (var i = this.ngModelCtrl.$formatters.length -1; i >= 0; i--) {
            parsedValue = this.ngModelCtrl.$formatters[i](parsedValue);
        }

        parsedValue = $(parsedValue).html();

        if ($liveSpan.html().replace(Controller.Directive.ContentEditableController.SELECTION_MARKERS_REGEXP, '')
            !== parsedValue.replace(Controller.Directive.ContentEditableController.SELECTION_MARKERS_REGEXP, '')) {
            $liveSpan.html(parsedValue);
        }
    };

    /**
     * @param {String} valueFromModel
     * @returns {String}
     */
    MatchTextHelper.prototype.parseModelValue = function parseModelValue(valueFromModel) {
        if (!valueFromModel) {
            return valueFromModel;
        }

        var replaced = this.$filter('matchTextTokenize')(valueFromModel, this.groups, true);

        return replaced || valueFromModel;
    };

    /**
     * @param {Number} groupId
     * @param {Model.AI.MatchGroup?} group
     */
    MatchTextHelper.prototype.updateGroup = function updateGroup(groupId, group) {
        var groupClone = group.clone();
        if (group.entity) {
            var entityClone = group.entity.clone();
            entityClone.name = entityClone.name.replace(Controller.Directive.ContentEditableController.SELECTION_MARKERS_REGEXP, '');
            var toRemove = this.sEntityRepository.getIndex(entityClone, true);
            if (toRemove !== -1) {
                this.sEntityRepository.removeItem(this.sEntityRepository.items[toRemove]);
            }
            this.sEntityRepository.addItem(entityClone);
            // if it did use clone, that this needs to be populated across all occurrences
            if (this.matchTextCollectionCtrl) {
                this.matchTextCollectionCtrl.handleEntityNameChanged(entityClone);
            }
        }
        this.groups[groupId] = groupClone;
        groupClone.elements.map(function (matchGroupElement) {
            matchGroupElement.value = matchGroupElement.value.replace(Controller.Directive.ContentEditableController.SELECTION_MARKERS_REGEXP, '');
        });
        this.updateDom(groupId);
    };

    /**
     * @function
     * @name Controller.Directive.MatchTextHelper#$onDestroy
     */
    MatchTextHelper.prototype.$onDestroy = function $onDestroy() {
        var $destroyFunc;
        while (($destroyFunc = this.$deRegister.pop())) {
            $destroyFunc();
        }
    };

    MatchTextHelper.prototype.toggle = function() {
        this.enabled = !this.enabled;
        this.ngModelCtrl.$$lastCommittedViewValue = '';

        var parsedValue = this.ngModelCtrl.$modelValue;
        for (var i = this.ngModelCtrl.$formatters.length -1; i >= 0; i--) {
            parsedValue = this.ngModelCtrl.$formatters[i](parsedValue);
        }

        this.ngModelCtrl.$setViewValue(parsedValue);
        if (this.enabled) {
            this.contentEditableCtrl.read(true);
        }
        this.$element.html(parsedValue);
    };

    /**
     * @param {$.Event} event
     */
    MatchTextHelper.prototype.handleCopy = function handleCopy(event) {
        if (!this.enabled) {
            return;
        }

        var clipboardEvent  = event.originalEvent,
            selectionString = document.getSelection().toString().replace(Controller.Directive.ContentEditableController.SELECTION_MARKERS_REGEXP, ''),
            buildUpGroupHeader = ''
        ;

        // build up group by group to see what is the last group fully contained in the selection
        var builtUpUsingFullGroups = this.groups.reduce(function(carry, group) {
            var test = buildUpGroupHeader + group.glue + group.toString().replace(Controller.Directive.ContentEditableController.SELECTION_MARKERS_REGEXP, '');

            if (selectionString.search(test) !== 0) {
                carry += selectionString.substr(buildUpGroupHeader.length);
                return carry;
            }

            carry += (group.glue + group.toString(true).replace(Controller.Directive.ContentEditableController.SELECTION_MARKERS_REGEXP, ''));
            buildUpGroupHeader = test;
            return carry
        }, '');

        clipboardEvent.clipboardData.setData('text/plain', builtUpUsingFullGroups);
        clipboardEvent.preventDefault();
    };

    MatchTextHelper.prototype.attachAutocompleter = function attachAutocompleter() {
        if (!this.autocompleterCtrl) {
            return;
        }

        var sEntityRepository = this.sEntityRepository,
            entityMapper        = function (entity) {
                return entity.name;
            }
        ;

        this.autocompleterCtrl.rules.unshift({
            pattern     : new RegExp("^@([^" + Controller.Directive.ContentEditableController.UNICODE_NBSP + Controller.Directive.ContentEditableController.UNICODE_SPACE +"]+)?$", 'i'),
            results     : function (term) {
                return sEntityRepository.getEntities().then(function (entities) {
                    /**
                     * @type {Array}
                     */
                    var res;
                    if (!term) {
                        res = entities.unique(entityMapper);

                    } else {
                        res = entities.filter(function (element) {
                            return element.toString().search(new RegExp(term[1], 'i')) !== -1;
                        }).unique(entityMapper);
                    }

                    return res.sort(Array.sortFnByProperty('name'));
                });
            },
            transformer : function (term) { return Model.AI.MatchGroup.ENTITY_PREFIX + term},
            itemTemplate: '_directive:panel_item',
            panelClass  : 'panel-item',
            panelCtrl   : Controller.Directive.MatchTextHelperPanel,
            triggerIcon : '<i class="fal fa-at" title="Open entity picker"></i>',
            dependencies: {'sEntityRepository' : sEntityRepository},
            hint        : function (word) {
                if (!word) {
                    return 'Select an entity';
                }
                return 'Entities matching: <b>' + word + '</b>';
            }
        });
    };

    /**
     * @param {Model.UUID} payload
     * @return {Boolean}
     */
    MatchTextHelper.prototype.getManagedAction = function getManagedAction(payload) {
        var actions = [];
        this.$scope.$emit(EVENT_REQUEST_GET_MANAGED_ACTION, payload, actions);
        return Boolean(actions.length);
    };

    /**
     * @param {Model.UUID} payload
     * @param {Boolean} state
     */
    MatchTextHelper.prototype.updateManagedActionState = function updateManagedActionState(payload, state) {
        this.$scope.$emit(EVENT_REQUEST_UPDATE_MANAGED_ACTION_STATE, payload, state);
    };

    Object.defineProperties(
        MatchTextHelper,
        {
            EVENT_REQUEST_GET_MANAGED_ACTION: {
                value: EVENT_REQUEST_GET_MANAGED_ACTION
                /**
                 * @property
                 * @constant
                 * @name Controller.Directive.MatchTextHelper#EVENT_REQUEST_GET_MANAGED_ACTION
                 * @type {String}
                 */
            },
            EVENT_REQUEST_UPDATE_MANAGED_ACTION_STATE: {
                value: EVENT_REQUEST_UPDATE_MANAGED_ACTION_STATE
                /**
                 * @property
                 * @constant
                 * @name Controller.Directive.MatchTextHelper#EVENT_REQUEST_UPDATE_MANAGED_ACTION_STATE
                 * @type {String}
                 */
            },
            EVENT_REQUEST_SYNC_MANAGED_ACTIONS: {
                value: EVENT_REQUEST_SYNC_MANAGED_ACTIONS
                /**
                 * @property
                 * @constant
                 * @name Controller.Directive.MatchTextHelper#EVENT_REQUEST_SYNC_MANAGED_ACTIONS
                 * @type {String}
                 */
            }
        });

    ns.MatchTextHelper = MatchTextHelper;
})(Object.namespace('Controller.Directive'));
