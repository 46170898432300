(function (ns) {
    /**
     * @namespace
     * @constructor
     * @alias Controller.Component.sContentSourceTypeSelect
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     */
    var sContentSourceTypeSelect = function (sAPIAccess) {
        var self = this,
            types = [],
            loadingPromise = null;

        this.sAPIAccess = sAPIAccess;
        this.getSourceTypes = function getSourceTypes() {
            if (types.length === 0) {

                if (!loadingPromise) {
                    loadingPromise = self.loadTypes().then(function (data) {
                        loadingPromise = null;
                        return data;
                    });
                }

                return loadingPromise;
            }

            return $.when(types);
        };
    };

    /**
     * @function
     * @return {Deferred}
     * @name Controller.Component.sContentSourceTypeSelect#loadTypes
     */
    sContentSourceTypeSelect.prototype.loadTypes = function loadTypes() {
        return $.ajax({
            url         : this.sAPIAccess.endpoint('source.type.all').get(),
            method      : Const.Method.GET,
            context     : this,
            contentType : false,
            dataType    : 'json'
        }).then(
            function (responseData) {
                var records = responseData.records ? responseData.records : [];

                return records.map(function (typeData) {
                    return Model.Source.Type.createByData(typeData);
                });
            }
        );
    };

    ns.sContentSourceTypeSelect = sContentSourceTypeSelect;
})(Object.namespace('Controller.Component'));