(function (ns) {
    /**
     * @namespace
     * @alias sAutomation.Service.sAutomation
     *
     * @constructor
     * @param Notification
     * @param {Service.sConfirm} sConfirm
     */
    var sAutomation = function (Notification, sConfirm) {
        this.notification   = Notification;
        this.sConfirm       = sConfirm;
    };

    /**
     * @name sAutomation.Service.sAutomation#save
     * @param {Model.Automation} automation
     * @param $scope
     * @returns {$.Deferred}
     */
    sAutomation.prototype.save = function save(automation, $scope) {
        var self = this,
            isModified = automation.isModified()
        ;

        return automation.save().then(function() {
            if (isModified) {
                self.notification.success('Saved successfully.');
            } else {
                self.notification.info('Everything saved already.');
            }
        }, function() {
            self.notification.error('Saving failed');
        }).always(function() {
            digestIfNeeded($scope);
        })
    };

    /**
     * @name sAutomation.Service.sAutomation#activate
     * @param {Model.Automation} automation
     * @param $scope
     * @param {boolean} includeSave = false
     * @returns {$.Deferred}
     */
    sAutomation.prototype.activate = function activate(automation, $scope, includeSave) {
        var self = this,
            options = {
            confirm: 'Activate',
            decline: 'Cancel',
            title: 'Confirm activation',
            content: 'The automation will start sending conversations to users who match the criteria.',
            ariaLabel: ''
        };

        return this.sConfirm.open(options)
            .then(function() {
                if (includeSave) {
                    return self.save(automation, $scope)
                }
                return $.Deferred().resolve();
            })
            .then(function() {
                // TODO: https://spectrm.atlassian.net/browse/IT-3151
                return automation.activate().then(function() {
                    self.notification.success('Activation successful');
                }, function() {
                    self.notification.error('Activation failed');
                })
            })
            .always(function() {
                digestIfNeeded($scope);
            });
    };

    /**
     * @name sAutomation.Service.sAutomation#deActivate
     * @param {Model.Automation} automation
     * @param $scope
     * @returns {$.Deferred}
     */
    sAutomation.prototype.deActivate = function activate(automation, $scope) {
        var self = this;

        // TODO: https://spectrm.atlassian.net/browse/IT-3151
        return automation.deactivate().then(function() {
                self.notification.success('Deactivation successful');
            }, function() {
                self.notification.error('Deactivation failed');
            })
            .always(function() {
                digestIfNeeded($scope);
            });
    };

    /**
     * @name sAutomation.Service.sAutomation#remove
     * @param {Model.Automation} automation
     * @param $scope
     * @returns {$.Deferred}
     */
    sAutomation.prototype.remove = function remove(automation, $scope) {
        var self = this;

        // TODO: https://spectrm.atlassian.net/browse/IT-3151
        return automation.delete().then(function() {
            self.notification.success('Removal successful');
        }, function() {
            self.notification.error('Removal failed');
        })
            .always(function() {
                digestIfNeeded($scope);
            });
    };

    ns.sAutomation = sAutomation;
})(Object.namespace('sAutomation.Service'));
