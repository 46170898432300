(function(ns) {
    var FILLING_TYPE_FILL_PARENT = 'fill-parent';

    /**
     * @namespace
     * @alias Controller.Component.sImage
     * @constructor
     *
     * @param $element
     * @param $scope
     */
    var sImage = function($element, $scope) {
        var img     = new Image,
            self    = this
            ;

        this.$element           = $element;
        this.$scope             = $scope;
        this.$deRegister        = [];

        img.onload = function handleImageLoad() {
            setTimeout(function() {
                self.drawImage();
                digestIfNeeded($scope);
            },1);
        };

        Object.defineProperties(
            this,
            {
                image: {
                    get: function() {
                        return img;
                    }
                }
            }
        );

        /**
         * @property
         * @name Controller.Component.sImage#fallbackSrc
         * @type {String}
         */
    };

    /**
     * @function
     * @name Controller.Component.sImage#$onInit
     */
    sImage.prototype.$onInit = function $onInit() {
        var self = this;

        // do not allow inline elements and override in worst case
        this.$deRegister.push(this.$scope.$watch(
            function () {
                return self.$element.parent().css('display');
            },
            /**
             * @param {String} display
             */
            function (display) {
                if (display === 'inline') {
                    self.$element.parent().css('display', 'inline-block');
                }
            }
        ));

        this.$deRegister.push(this.$scope.$watch(
            function() {
                var $parent = self.$element.parent();
                // inner width & height without border and padding
                return $parent.width() + '-' + $parent.height();
            },
            this.drawImage.bind(this)
        ));
    };

    sImage.prototype.$onDestroy = function $onDestroy() {
        $(this.image).off('error');

        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    /**
     * @param {Object} changes
     */
    sImage.prototype.$onChanges = function $onChanges(changes) {
        var self = this;

        if (changes.src) {
            $(this.image).off('error');
            // the fallbackSrc just needs to be checked when 'src' is changed because
            // it fires the onerror directly when src fails.
            if (this.fallbackSrc) {
                // .one to prevent endless-loop for error-throwing fallback
                $(this.image).one('error', function () {
                    self.image.src = self.fallbackSrc;
                    setTimeout(function() {
                        self.drawImage();
                        digestIfNeeded(self.$scope);
                    },1);
                });
            }

            this.image.src = changes.src.currentValue || this.fallbackSrc || '';
        }

    };

    sImage.prototype.drawImage = function drawImage() {
        var $parent = this.$element.parent(),
            // inner width & height without border and padding
            rect    = {
                width   : $parent.width(),
                height  : $parent.height()
            },
            imageAR = this.image.height ? (this.image.width / this.image.height) : 0,
            AR      = this.aspectRatio || imageAR
            ;

        if (imageAR === 0) return;

        this.$element.css('background-image', 'url(' + this.image.src + ')');

        if (!rect.width && !rect.height) {
            if (AR === imageAR) {
                this.$element.css({
                    width   : this.image.width + 'px',
                    height  : this.image.height + 'px'
                });
            } else if (imageAR < AR) {
                // image is taller then needed > adjust height
                this.$element.css({
                    width   : this.image.width + 'px',
                    height  : Math.round(this.image.width / AR) + 'px'
                });
            } else if (imageAR > AR) {
                // image is wider then needed > adjust weight
                this.$element.css({
                    width   : Math.round(this.image.height * AR) + 'px',
                    height  : this.image.height + 'px'
                });
            }
            // get dimensions based on aspect ratio
        } else if (rect.height && !rect.width) {
            // get dimension based on height & AR
            this.$element.css({
                width   : Math.round(rect.height * AR) + 'px',
                height  : rect.height + 'px'
            });
        } else if (rect.width && !rect.height) {
            // get dimension based on width & AR
            this.$element.css({
                width   : rect.width + 'px',
                height  : Math.round(rect.width / AR) + 'px'
            });
        } else if (rect.width && rect.height) {
            if (this.fillingType === FILLING_TYPE_FILL_PARENT) { // image must fill parent
                this.$element.css({
                    width  : rect.width + 'px',
                    height : rect.height + 'px'
                });
            } else { // re-calc based on the width of the rect and keep aspect ratio
                this.$element.css({
                    width  : rect.width + 'px',
                    height : Math.round(rect.width / AR) + 'px'
                });
            }
        }
    };

    ns.sImage = sImage;
})(Object.namespace('Controller.Component'));
