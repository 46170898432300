(function (ns) {
    /**
     * @namespace
     * @alias sEntryPoint.Controller.Edit
     *
     * @param $scope
     * @param Notification
     * @param $mdDialog
     * @param {Service.sMemberAttribute} sMemberAttribute
     */
    var Edit = function ($scope, Notification, $mdDialog, sMemberAttribute) {
        var hasActiveSaving   = false;
        this.$scope           = $scope;
        this.notification     = Notification;
        this.$mdDialog        = $mdDialog;
        this.sAPIAccess       = sAPIAccess;
        this.sMemberAttribute = sMemberAttribute;
        this.lastSavedState   = null;
        this.entryPointSaved  = false;

        Object.defineProperties(
            this,
            {
                hasActiveSaving : {
                    enumerable : true,
                    get        : function () {
                        return hasActiveSaving;
                    }
                    /**
                     * @property
                     * @name sEntryPoint.Controller.Edit#hasActiveSaving
                     * @type {Boolean}
                     */
                }
            });

        /**
         * @return {$.Deferred}
         */
        this.save = function save() {
            var isNew = this.entryPoint.isNew;

            hasActiveSaving = true;

            return Edit.prototype.save.call(this)
                .then(function () {
                    this.lastSavedState = this.entryPoint.clone();

                    if (isNew) {
                        this.entryPointSaved = true;
                    } else {
                        this.$mdDialog.hide(this.lastSavedState);
                    }

                    $scope.$emit('sEntryPointSaved', this.lastSavedState);
                }.bind(this))
                .always(function () {
                    hasActiveSaving = false;
                    digestIfNeeded($scope);
                });
        };

        this.entryPoint = this.entryPoint || new Model.EntryPoint();
    };

    /**
     * @function
     * @name sEntryPoint.Controller.Edit#save
     * @return {$.Deferred}
     */
    Edit.prototype.save = function save() {
        var self             = this,
            attributes = this.entryPoint.actions.actions.filter(function (action) {
                return action.type === Model.Action.TYPE_USER_ATTRIBUTE
            })
                .map(function(action) {
                    return Service.sMemberAttribute.createKeyValueObjectByData(JSON.parse(action.value));
                }),
            handleSaveEntryPoint = function () {
                return self.entryPoint
                    .save()
                    .then(
                        function () {
                            self.notification.success('Entry Point was successfully saved.');
                        },
                        function (jqXHR) {
                            if (jqXHR && jqXHR.status === 403 && jqXHR.getResponseHeader(Const.Headers.X_RESOURCE_LIMIT_REACHED)) {
                                return self.notification.limitReached('info', jqXHR.getResponseHeader(Const.Headers.X_RESOURCE_LIMIT_REACHED));
                            }

                            self.notification.error('An error occurred while saving the Entry Point, please try again.');
                        }
                    )
                    ;
            }
        ;

        return $.when(
            handleSaveEntryPoint(),
            this.sMemberAttribute.save(attributes)
        );
    };

    /**
     * @function
     * @name sEntryPoint.Controller.Edit#download
     * @param {String} file
     */
    Edit.prototype.download = function download(file) {
        var blob            = dataURItoBlob(file),
            downloadHelper  = new sDownload(blob, 'QRCode', blob.type)
        ;
        downloadHelper.downloadFile();
    };

    Edit.prototype.cancel = function cancel() {
        if (this.lastSavedState) {
            this.$mdDialog.hide(this.lastSavedState);
        } else {
            this.$mdDialog.cancel();
        }
    };

    ns.Edit = Edit;
})(Object.namespace('sEntryPoint.Controller'));
