(function (ns) {
    /**
     * @namespace
     * @alias Controller.Component.sNotificationFrequencySelect
     *
     * @constructor
     * @param $scope
     */
    var sNotificationFrequencySelect = function ($scope) {

        this.$deRegister       = [];
        this.$scope            = $scope;

        /**
         * @property
         * @name Controller.Component.sNotificationFrequencySelect#isDisabled
         * @type {Boolean}
         */

        /**
         * @property
         * @name Controller.Component.sNotificationFrequencySelect#model
         * @type {String}
         */

        /**
         * @property
         * @name Controller.Component.sNotificationFrequencySelect#parentModel
         * @type {Object}
         */

        this.recurrenceOptions = [[
            {
                value: "recurrent",
                label: "Recurring"
            },
            {
                value: Model.Message.Part.OTN_FREQUENCY_ONE_TIME,
                label: "One-time"
            }
        ]];
        this.frequencyOptions = [
            {
                value: Model.Message.Part.OTN_FREQUENCY_DAILY,
                label: "Daily"
            },
            {
                value: Model.Message.Part.OTN_FREQUENCY_WEEKLY,
                label: "Weekly"
            },
            {
                value: Model.Message.Part.OTN_FREQUENCY_MONTHLY,
                label: "Monthly"
            },
        ];
    };

    sNotificationFrequencySelect.prototype.$onInit = function $onInit() {
        this.parentModel.content.links.ctas[0].unsetMeta(Model.Action.META_READ_ONLY);

        // For existing convos recurrence is not set and should be "one time" by default
        this.recurrence = this.model !== Model.Message.Part.OTN_FREQUENCY_ONE_TIME
            ? 'recurrent' : Model.Message.Part.OTN_FREQUENCY_ONE_TIME;
        this.frequency = (this.model && this.model !== Model.Message.Part.OTN_FREQUENCY_ONE_TIME)
            ? this.model : Model.Message.Part.OTN_FREQUENCY_WEEKLY;

        var self = this;
        this.$deRegister.push(this.$scope.$watch(
            function() {
                return self.recurrence;
            },
            function(newVal) {
                if(newVal === 'recurrent') {
                    self.model = Model.Message.Part.OTN_FREQUENCY_WEEKLY;
                    self.parentModel.content.body = 'You may receive additional messages on this topic over the next 9 months.';
                    self.parentModel.content.links.ctas[0].label = 'Get Weekly Messages';
                    $('.otn-in-edit-media').removeClass('s-dynamic-form-row-hidden');
                } else {
                    self.model = Model.Message.Part.OTN_FREQUENCY_ONE_TIME;
                    self.parentModel.content.media = undefined;
                    self.parentModel.content.body = 'By clicking “Notify Me”, you may receive a message in the future about this topic.';
                    self.parentModel.content.links.ctas[0].label = 'Notify me';
                    $('.otn-in-edit-media').addClass('s-dynamic-form-row-hidden');
                }
            })
        );

        this.$deRegister.push(this.$scope.$watch(
            function() {
                return self.frequency;
            },
            function(newVal) {
                if(self.recurrence === Model.Message.Part.OTN_FREQUENCY_ONE_TIME){
                    return;
                }

                if(newVal === Model.Message.Part.OTN_FREQUENCY_DAILY) {
                    self.parentModel.content.links.ctas[0].label = 'Get Daily Messages';
                    self.parentModel.content.body = 'You may receive additional messages on this topic over the next 6 months.';
                } else if(newVal === Model.Message.Part.OTN_FREQUENCY_WEEKLY) {
                    self.parentModel.content.links.ctas[0].label = 'Get Weekly Messages';
                    self.parentModel.content.body = 'You may receive additional messages on this topic over the next 9 months.';
                } else if(newVal === Model.Message.Part.OTN_FREQUENCY_MONTHLY) {
                    self.parentModel.content.links.ctas[0].label = 'Get Monthly Messages';
                    self.parentModel.content.body = 'You may receive additional messages on this topic over the next 12 months.';
                }
            })
        );
    };

    /**
     * @function
     * @name Controller.Component.sNotificationFrequencySelect#$onDestroy
     */
    sNotificationFrequencySelect.prototype.$onDestroy = function() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @param item
     */
    sNotificationFrequencySelect.prototype.handleFrequencyChange = function handleFrequencyChange(item) {
        this.model = item.item.value;
    };

    ns.sNotificationFrequencySelect = sNotificationFrequencySelect;
})(Object.namespace('Controller.Component'));
