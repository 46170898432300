(function (ns) {
    /**
     * @namespace
     * @alias Service.sFile
     *
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     */
    var sFile = function (sAPIAccess) {
        this.sAPIAccess = sAPIAccess;
    };


    /**
     * @function
     * @name Service.sFile#uploadFile
     * @param {Model.sFile} file
     * @return {$.Deferred}
     */
    sFile.prototype.uploadFile = function uploadFile(file) {
        var formData = new FormData();

        if (!file.file.name || !file.file.fileName) {
            file.file.name = file.file.fileName = file.uuid;
        }

        formData.append(file.uuid, file.file, file.file.name);

        return $.ajax({
            url         : this.sAPIAccess.endpoint('media').post(),
            data        : formData,
            method      : Const.Method.POST,
            contentType : false,
            processData : false
        });
    };

    ns.sFile = sFile;
})(Object.namespace('Service'));
