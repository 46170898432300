(function (ns) {
    /**
     * @namespace
     * @alias Controller.Component.sMenuEdit
     * @constructor
     *
     * @param {Service.sConfirm} sConfirm
     * @param $element
     * @param $scope
     * @param $mdPanel
     */
    var sMenuEdit = function (sConfirm, $element, $scope, $mdPanel) {

        /**
         * @name Controller.Component.sMenuEdit#model
         * @type {Model.Menu.Menu}
         */

        /**
         * @name Controller.Component.sMenuEdit#activeMenu
         * @type {Model.Menu.Menu}
         */
        this.activeMenu         = null;
        this.level              = 0;
        this.sConfirm           = sConfirm;
        this.levelChangeTimer   = null;
        this.$deRegister        = [];
        this.$element           = $element;
        this.$scope             = $scope;
        this.$mdPanel           = $mdPanel;
        this.mdPanelRef         = null;

        /**
         * @name Controller.Component.sMenuEdit#payload
         */

        /**
         * @property {Integer[]}
         * @name Controller.Component.sMenuEdit#maxElements
         */

        /**
         * @property {Integer}
         * @name Controller.Component.sMenuEdit#maxMenuDepth
         */

        /**
         * @name Controller.Component.sMenuEdit#onSaveCallback
         */
    };

    sMenuEdit.prototype.$onInit = function $onInit() {
        var self = this;

        this.ngModelCtrl.$setViewValue(
            (this.ngModel instanceof Model.Menu.Menu) ? this.ngModel : new Model.Menu.Menu()
        );
        this.activeMenu = this.ngModelCtrl.$viewValue;

        this.$deRegister = this.$deRegister.concat(this.$element.$on('dragover', '[data-traverse]', this.onDragLevelChange.bind(this)));
        this.$deRegister = this.$deRegister.concat(this.$element.$on('dragleave', '[data-traverse]', this.onDragLeave.bind(this)));
        this.$deRegister.push(this.$scope.$watch(
            function() {
                if (!self.mdPanelRef || !self.mdPanelRef.panelEl || !self.mdPanelRef.isAttached) {
                    return null;
                }

                return self.mdPanelRef.panelEl.width() + '-' + self.mdPanelRef.panelEl.height();
            },
            /**
             * @param {String} val
             * @param {String} oldVal
             */
            function(val, oldVal) {
                if (val === null) {
                    return;
                }

                self.mdPanelRef._updatePosition();
                if (self.mdPanelRef.config.position._isOnscreen(self.mdPanelRef.panelEl)) {
                    return;
                }

                var height  = oldVal.split('-').pop(),
                    element = self.mdPanelRef.panelEl.children().first()
                ;

                element.css('max-height', height + 'px');
                element.css('overflow', 'auto');
                element.addClass('s-scroll-bar');
            }
        ));
    };

    sMenuEdit.prototype.$onDestroy = function $onDestroy() {
        if (this.mdPanelRef && this.mdPanelRef.destroy) {
            this.mdPanelRef.destroy();
        }

        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    sMenuEdit.prototype.$postLink = function $postLink() {
        this.ngModelCtrl.$validate();
    };

    /**
     * @name Controller.Component.sMenuEdit#canHaveMoreElements
     * @returns {boolean}
     */
    sMenuEdit.prototype.canHaveMoreElements = function canHaveMoreElements() {
        if (!this.maxElements) {
            return true;
        }

        return this.activeMenu.items.length < this.maxElements[this.level];
    };

    /**
     * @return {Number}
     */
    sMenuEdit.prototype.getMaxElements = function getMaxElements() {
        return this.maxElements ? this.maxElements[this.level] : 0;
    };

    /**
     * @name Controller.Component.sMenuEdit#traverseMenu
     * @param {Model.Menu.Entry} menuEntry
     * @param {Boolean=} back
     */
    sMenuEdit.prototype.traverseMenu = function traverseMenu(menuEntry, back) {
        if (!(menuEntry instanceof Model.Menu.Menu)) {
            return;
        }

        if (!menuEntry.isMenu) {
            throw "Can only traverse menus!";
        }

        if (back && menuEntry.parent) {
            this.level--;
            this.activeMenu = menuEntry.parent;
        } else {
            this.level++;
            this.activeMenu = menuEntry;
        }
    };

    /**
     * @name Controller.Component.sMenuEdit#deleteEntry
     * @param {Event} $event
     * @param {Model.Menu.Entry|Model.Menu.Menu} menuEntry
     */
    sMenuEdit.prototype.deleteEntry = function deleteEntry($event, menuEntry) {
        var callback = function () {
                this.activeMenu.removeItem(menuEntry);
                this.ngModelCtrl.$validate();
            }
        ;

        $event.stopPropagation();
        Object.instanceOf(menuEntry, Model.Menu.Entry);

        if (!menuEntry.isMenu || !menuEntry.items.length) {
            callback.call(this);
            return;
        }

        this.sConfirm.open().then(callback.bind(this));
    };

    /**
     * @name Controller.Component.sMenuEdit#onMoveEntry
     * @param {Model.Menu.Menu} trgtMenu
     * @param {Number} trgtIndex
     */
    sMenuEdit.prototype.onMoveEntry = function onMoveEntry(trgtMenu, trgtIndex) {
        var srcMenu = this.payload.parent,
            srcIndex = srcMenu.getItemIndex(this.payload)
        ;

        if (!this.level && this.staticElements) {
            trgtIndex -= this.staticElements.length;
        } else if (this.level) {
            trgtIndex -= 1;
        }

        if (srcMenu === trgtMenu && srcIndex === trgtIndex) {
            return false;
        }

        if (srcMenu === trgtMenu) {
            if (srcIndex + 1 < trgtIndex) {
                trgtIndex -= 1;
            }
        }

        var item = srcMenu.removeItem(srcMenu.items[srcIndex]);
        trgtMenu.insertItem(item, trgtIndex);
        item.parent = trgtMenu;

        this.ngModelCtrl.$validate();

        this.$scope.$digest();
    };

    /**
     * @name Controller.Component.sMenuEdit#isDragAllowed
     * @param index
     * @returns {boolean}
     */
    sMenuEdit.prototype.isDragAllowed = function isDragAllowed(index) {
        if (!this.level && this.staticElements) {
            index -= this.staticElements.length;
        }

        return (index - (this.level ? 1 : 0)) >= 0
            && (index - !this.canHaveMoreElements()) <= (this.activeMenu.items.length || 1);
    };

    /**
     * @name Controller.Component.sMenuEdit#onDragLevelChange
     * @param {Event} event
     */
    sMenuEdit.prototype.onDragLevelChange = function onDragLevelChange(event) {
        this.levelChangeTimer = this.levelChangeTimer || Date.now();

        var $toElement = $(event.target),
            index
        ;

        $toElement.addClass('drag-level-change');

        if ((Date.now() - this.levelChangeTimer) > 1000) {
            if ((index = $toElement.data('traverse')) !== "") {
                this.traverseMenu(this.activeMenu.items[index]);
            } else {
                this.traverseMenu(this.activeMenu, true);
            }
            this.$scope.$digest();
            this.levelChangeTimer = 0;
        }

        return false;
    };

    /**
     * @name Controller.Component.sMenuEdit#startDragging
     * @param {Model.Menu.Entry} entry
     */
    sMenuEdit.prototype.startDragging = function startDragging(entry) {
        Object.instanceOf(entry, Model.Menu.Entry);
        this.payload = entry;
    };

    /**
     * @name Controller.Component.sMenuEdit#onDragLeave
     * @param {Event} event
     */
    sMenuEdit.prototype.onDragLeave = function onDragLeave(event) {
        $(event.target).removeClass('drag-level-change');
        this.levelChangeTimer = 0;
    };

    /**
     * @name Controller.Component.sMenuEdit#onEditEntry
     * @param $event
     * @param {Model.Menu.Entry|Model.Menu.Action|Model.Menu.Menu} entry
     */
    sMenuEdit.prototype.onEditEntry = function onEditEntry($event, entry) {
        $event.stopPropagation();

        if(!entry && !this.canHaveMoreElements()){
            return;
        }

        var editable = {
            'label': entry ? entry.label : '',
            'action': entry && entry.isAction ? entry.action.clone() : new Model.Action.Collection(),
            'type': (entry && entry.isAction) || this.level === 2 ? 'action' : 'menu'
        };

        var self            = this,
            position        = this.$mdPanel.newPanelPosition(),
            $anchorElement  = $event.target ? $($event.target).closest('div') : $($event.currentTarget),
            clientRect      = $anchorElement[0].getBoundingClientRect(),
            viewPortRect    = $('body')[0].getBoundingClientRect()
        ;


        position.relativeTo($anchorElement)
            .addPanelPosition(
                this.$mdPanel.xPosition.ALIGN_START,
                (clientRect.top - viewPortRect.top) > (viewPortRect.bottom - clientRect.bottom)
                    ? this.$mdPanel.yPosition.ALIGN_BOTTOMS
                    : this.$mdPanel.yPosition.ALIGN_TOPS
            );

        var panelAnimation = this.$mdPanel.newPanelAnimation()
            .openFrom($anchorElement)
            .duration(200)
            .closeTo($anchorElement)
            .withAnimation(this.$mdPanel.animation.SCALE);

        var config = {
            attachTo            : angular.element(Const.PanelAnchor),
            controller          : Controller.Component.sMenuEditForm,
            controllerAs        : '$ctrl',
            templateUrl         : '_component:s-menu-edit-form',
            position            : position,
            disableParentScroll : true,
            locals              : {
                editable        : editable,
                level           : this.level,
                maxMenuDepth    : this.maxMenuDepth,
                successCallback : function () {
                    this.onSave(editable, entry);
                }.bind(this)
            },
            animation           : panelAnimation,
            openFrom            : $event,
            clickOutsideToClose : true,
            escapeToClose       : true,
            focusOnOpen         : false,
            zIndex              : 2,
            onCloseSuccess      : function(mdPanelRef) {
                if (!mdPanelRef) {
                    return;
                }

                mdPanelRef.destroy();
                self.mdPanelRef = null;
            }.bind(this)
        };

        this.mdPanelRef = this.$mdPanel.create(config);
        return this.mdPanelRef.open();
    };

    /**
     * @name Controller.Component.sMenuEdit#onSave
     * @param {Object} editable
     * @param {Model.Menu.Entry|Model.Menu.Action|Model.Menu.Menu} entry
     */
    sMenuEdit.prototype.onSave = function onSave(editable, entry) {
        var newEntry,
            pos
        ;

        if (!entry || entry['is' + editable.type.ucFirst()] === false) {
            switch (editable.type) {
                case 'action':
                    newEntry = new Model.Menu.Action();
                    break;
                case 'menu':
                    newEntry = new Model.Menu.Menu();
                    break;
            }
            if (entry) {
                this.activeMenu.removeItem(entry);
            }
            entry = newEntry;
        }

        entry.label = editable.label;

        if (entry.isAction) {
            entry.action = editable.action;
        }

        if ((pos = this.activeMenu.getItemIndex(entry)) !== -1) {
            this.activeMenu.insertItem(entry, pos, true);
        } else {
            this.activeMenu.addItem(entry);
        }

        this.ngModelCtrl.$validate();

        if (this.onSaveCallback) {
            this.onSaveCallback({menu: this.ngModel});
        }
    };

    ns.sMenuEdit = sMenuEdit;
})(Object.namespace('Controller.Component'));
