(function (ns) {
    /**
     * @namespace
     * @alias Model.Condition.Definition.Value
     *
     * @param {String} type
     * @param {Array} requiredByOperators
     * @param {Array} options
     * @param {Boolean=} allowAny
     * @constructor
     */
    var Value = function (type, requiredByOperators, options, allowAny) {
        requiredByOperators = requiredByOperators || [];
        options = options || [];
        allowAny = allowAny || false;

        Object.defineProperties(
            this,
            {
                'type': {
                    get: function() {
                        return type;
                    }
                },
                'allowAny': {
                    get: function() {
                        return allowAny;
                    }
                },
                'requiredByOperators': {
                    get: function() {
                        return [].concat(requiredByOperators);
                    }
                },
                'options' : {
                    get: function() {
                        return [].concat(options);
                    }
                }
            }
        );
    };

    ns.Value = Value;
})(Object.namespace('Model.Condition.Definition'));