(function(angular) {

    /**
     * Minimalistic application to handle user login related functions
     * @type {angular.Module}
     */
    var sNGExtern = angular.module('sNGExtern', [
        'sFollowPubNG'
    ]);

    sNGExtern.config(['$routeProvider', 'sMenuAuthWrapperProvider', function ($routeProvider, sMenuAuthWrapperProvider) {
        $routeProvider
            .when('/auth/accept-invite', new sSecurity.Route.ResetPassword(sMenuAuthWrapperProvider.$get()))
            .when('/auth/reset-password', new sSecurity.Route.ResetPassword(sMenuAuthWrapperProvider.$get()))
            .when('/auth/:catchAll*', new sSecurity.Route.Auth(sMenuAuthWrapperProvider.$get()))
            .when('/', {
                redirectTo: '/auth/login'
            })
            .otherwise('/');
    }]);

})(angular);
