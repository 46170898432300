(function (ns) {
    var KEY_LAYER = 'layer';

    /**
     * @namespace
     * @alias Model.Analytics.Layer
     *
     * @extends Model.Analytics.Category
     * @constructor
     */
    var Layer = function (key, label) {
        Layer._pProto.constructor.call(this, key, KEY_LAYER, label);

        var summary;

        Object.defineProperties(
            this,
            {
                summary: {
                    get: function() {
                        return summary;
                    },
                    set: function(val) {
                        if (val) {
                            Object.instanceOf(val, Model.Analytics.Summary);
                        }

                        if (val === summary) {
                            return;
                        }

                        summary = val;
                    }
                    /**
                     * @property
                     * @name Model.Analytics.Layer#summary
                     * @type {Model.Analytics.Summary}
                     */
                }
            }
        )
    };

    Object.extendProto(Layer, Model.Analytics.Category);

    /**
     * @param {Object} data
     */
    Layer.prototype.updateByData = function updateByData(data) {
        // if summary is set, create an empty object, so that its updateByData kicks in to interpret the data
        if (data.summary) {
            this.summary = new Model.Analytics.Summary();
        }

        Object.updateByData(this, data);
    };

    /**
     * @param {Object} data
     * @returns {Model.Analytics.Layer}
     */
    Layer.createByData = function createByData(data) {
        var layer = new Model.Analytics.Layer(data.key, data.label);
        layer.updateByData(data);

        return layer;
    };

    /**
     * @param {String} key
     * @returns {?Model.Analytics.Category}
     */
    Layer.getByKey = function getByKey(key) {
        return Model.Analytics.Category.getByTypeAndKey(KEY_LAYER, key);
    };

    ns.Layer = Layer;
})(Object.namespace('Model.Analytics'));