(function(ns) {
    /**
     * @namespace
     * @alias Model.RepairCase.EmptyPostbackMessageIncoming
     *
     * @constructor
     * @extends Model.RepairCase
     */
    var EmptyPostbackMessageIncoming = function() {
            var options = {
                confirm     : 'I’ll fix this myself',
                decline     : null,
                title       : 'Some user input won’t trigger a reaction …',
                content     : 'User input or fallbacks which trigger messages shouldn’t link nowhere or to empty messages. Please fix this before saving.',
                ariaLabel   : ''
            };

            EmptyPostbackMessageIncoming._pProto.constructor.call(
                this,
                this.checkEmptyPostbackMessageIncoming,
                null,
                options
            );

            $.extend(this.options, {forIncoming: true});
        };


    Object.extendProto(EmptyPostbackMessageIncoming, Model.RepairCase);

    /**
     * @name Model.RepairCase.EmptyPostbackMessageIncoming#checkEmptyPostbackMessageIncoming
     * @param {Model.Message.Collection} collection
     * @throws {Model.Exception.InvalidMessages}
     */
    EmptyPostbackMessageIncoming.prototype.checkEmptyPostbackMessageIncoming = function checkEmptyPostbackMessageIncoming(collection) {
        var lastPart,
            actions,
            faultyMessages = []
        ;

        messagesLoop:
        for (var i = 0; i < collection.messages.length; i++) {

            if (!collection.messages[i].isIncoming || !(lastPart = collection.messages[i].lastPart()) || !lastPart.isInteractive || collection.messages[i].needsCounter()) {
                continue;
            }

            if ((actions = lastPart.getAllMessagePostbackActions())) {
                for (var j in actions) {
                    if (actions[j].action.type === Const.PostbackMessage && !actions[j].action.value) {
                        faultyMessages.push(collection.messages[i]);
                        continue messagesLoop;
                    }
                }
            }
        }

        if (faultyMessages.length) {
            throw new Model.Exception.InvalidMessages(faultyMessages);
        }
    };
    ns.EmptyPostbackMessageIncoming = EmptyPostbackMessageIncoming;
})(Object.namespace('Model.RepairCase'));
