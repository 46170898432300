(function(ns) {
    /**
     * @namespace
     * @alias Model.List.Column.Collection
     * @extends Model.Collection
     * @constructor
     */
    var Collection = function() {
        Collection._pProto.constructor.call(this, Model.List.Column, 'column');

        Object.defineProperty(
            this,
            'visibleColumns',
            {
                get: function() {
                    return this.columns.filter(function(element) {
                        return !element.isHidden;
                    });
                }
            }
        );
    };

    Object.extendProto(Collection, Model.Collection);

    /**
     * @function
     * @name Model.List.Column.Collection#getColumnByName
     * @param {string} name
     * @returns void|Model.List.Column
     */
    Collection.prototype.getColumnByName = function getColumnByName(name) {
        var index = this.columns.map(function(element) {
            return element.name;
        }).indexOf(name);

        if (index === -1) {
            return;
        }

        return this.columns[index];
    };

    ns.Collection = Collection;
})(Object.namespace('Model.List.Column'));