(function(ns) {
    var EVENT_ACTION_INVOKED = 'sEventsMenuActionInvoked';
    /**
     * @namespace
     * @alias Controller.Component.sMenu
     *
     * @param $element
     * @param $mdPanel
     * @param $scope
     */
    var sMenu = function($element, $mdPanel, $scope) {
        this.$mdPanel   = $mdPanel;
        this.$element   = $element;
        this.$scope     = $scope;

        /**
         * @property
         * @name Controller.Component.sMenu#context
         * @type {context}
         */
    };

    /**
     * @param {Function} fn
     */
    sMenu.prototype.applyOnContext = function applyOnContext(fn) {
        if (!(fn instanceof Function)) {
            return;
        }
        return fn.apply(this.context);
    };

    /**
     * @param {*} item
     */
    sMenu.prototype.invokeAction = function(item) {
        var actionResult = item.action.call(this.$scope, item);
        this.$scope.$emit(EVENT_ACTION_INVOKED, actionResult);
    };
    
    Object.defineProperties(
        sMenu,
        {
            EVENT_ACTION_INVOKED: {
                value: EVENT_ACTION_INVOKED
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sMenu#EVENT_ACTION_INVOKED
                 * @type {String}
                 */
            }
        });

    ns.sMenu = sMenu;
})(Object.namespace('Controller.Component'));
