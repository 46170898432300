(function (ns){
    /**
     * @namespace
     * @alias Service.sMessageCollection
     * @constructor
     *
     * @param $rootScope
     * @param {*} Notification
     * @param $location
     * @param $mdDialog
     * @param {Service.sMemberAttribute} sMemberAttribute
     * @param {Service.sConversationValidator} sConversationValidator
     * @param {Service.sDomain} sDomainService
     * @param {Service.sAction} sActionService
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {Service.sConfirm} sConfirm
     * @param {Model.AI.EntityCollection} sEntityRepository
     * @param {sFacebook.Service.sFacebookAdAccount} sFacebookAdAccount
     * @param {sFacebook.Service.sFacebookPage} sFacebookPage
     * @param {Service.sMarkerRepository} sMarkerRepository
     * @param {Service.sNotificationLabel} sNotificationLabel
     */
    var sMessageCollection = function(
        $rootScope,
        Notification,
        $location,
        $mdDialog,
        sMemberAttribute,
        sConversationValidator,
        sDomainService,
        sActionService,
        sAPIAccess,
        sConfirm,
        sEntityRepository,
        sFacebookAdAccount,
        sFacebookPage,
        sMarkerRepository,
        sNotificationLabel
    ) {

        this.$rootScope             = $rootScope;
        this.notification           = Notification;
        this.$location              = $location;
        this.$mdDialog              = $mdDialog;
        this.sMemberAttribute       = sMemberAttribute;
        this.sConversationValidator = sConversationValidator;
        this.sDomainService         = sDomainService;
        this.sActionService         = sActionService;
        this.sAPIAccess             = sAPIAccess;
        this.sConfirm               = sConfirm;
        this.sEntityRepository      = sEntityRepository;
        this.sFacebookAdAccount     = sFacebookAdAccount;
        this.sFacebookPage          = sFacebookPage;
        this.sMarkerRepository      = sMarkerRepository;
        this.sNotificationLabel     = sNotificationLabel;
    };

    /**
     * Validates and saves the messages and relations
     *
     * @function
     * @name Service.sMessageCollection#validateAndSave
     * @param {Model.Message.Collection} collection
     * @param {String=} validationLevel
     * @param {Boolean=} dontShowPrompt = false
     * @returns {$.Deferred}
     */
    sMessageCollection.prototype.validateAndSave = function validateAndSave(collection, validationLevel, dontShowPrompt) {
        var self = this;

        validationLevel = validationLevel || Provider.sConversationValidator.DEFAULT_LEVEL;

        if (dontShowPrompt) {
            if (!this.sConversationValidator.validate(collection, validationLevel)) {
                return $.Deferred().reject('Invalid collection');
            }
            return save.call(self, collection);
        }

        return this.sConversationValidator.validateWithPrompt(collection, validationLevel).then(function () {
            return save.call(self, collection);
        });
    };

    /**
     * @function
     * @name Service.sMessageCollection#validateAndSaveAndActivate
     * @param {Model.Message.Anchor} anchor
     * @param {Boolean=} dontShowPrompt = false
     * @returns {$.Deferred}
     */
    sMessageCollection.prototype.validateAndSaveAndActivate = function validateAndSaveAndActivate(
        anchor,
        dontShowPrompt
    ) {
        var self        = this,
            collection  = anchor.message.collections.slice(0,1).pop();

        return this.validateAndSave(
            collection,
            Provider.sConversationValidator.ACTIVATE_LEVEL,
            dontShowPrompt
        ).then(function() {
            return activate.call(self, anchor);
        });
    };

    /**
     * @function
     * @name Service.sMessageCollection#confirmAndSaveAndActivate
     * @param {Model.Message.Anchor} anchor
     * @returns {$.Deferred}
     */
    sMessageCollection.prototype.confirmAndSaveAndActivate = function confirmAndSaveAndActivate(anchor) {
        var self = this,
            options = {
                confirm     : 'Activate',
                decline     : 'Cancel',
                title       : 'Do you really want to activate?',
                ariaLabel   : ''
            }
        ;

        if (anchor.message.isIncoming) {
            options.content = 'Once activated your chatbot will be using the matches to react on user messages.'
                + '<br>'
                + 'It might take a few minutes for these changes to be fully reflected throughout the system.'
            ;
        } else {
            options.content = 'Once completed, your Conversation is available throughout the system.\n'
                + 'Which means you can trigger it with interactions or set as welcome message.'
            ;
        }

        return this.sConfirm.open(options).then(function() {
            return self.validateAndSaveAndActivate(anchor)
        });
    };

    /**
     * @function
     * @name Service.sMessageCollection#setupAndSaveAndSend
     * @param {Model.Message.Anchor} anchor
     * @param $scope
     * @returns {$.Deferred}
     */
    sMessageCollection.prototype.setupAndSaveAndSend = function setupAndSaveAndSend(anchor, $scope) {
        var $deferred   = $.Deferred(),
            self        = this
        ;

        this.$mdDialog.show({
            controller          : Controller.sMessageAdmin.FacebookSendController,
            controllerAs        : '$ctrl',
            templateUrl         : 'smessageadmin:facebook-send',
            bindToController    : true,
            parent              : angular.element(Const.PanelAnchor),
            clickOutsideToClose : false,
            locals: {
                anchor: anchor
            }
        }).then(function(data) {
            return self.validateAndSaveAndSend(
                anchor,
                data.segment,
                null,
                data.timeScheduled,
                $scope,
                data.sendAsSponsoredData
            );
        })
        .then(
            function () {
                $deferred.resolve();
            },
            function () {
                $deferred.reject();
            }
        );

        return $deferred;
    };

    /**
     * @function
     * @name Service.sMessageCollection#validateAndSaveAndSend
     * @param {Model.Message.Anchor} msgAnchor
     * @param {Model.Segment} segment
     * @param {String=} userId
     * @param {Object=} timeScheduled
     * @param $scope
     * @param {Object=} sendAsSponsoredData
     * @returns {$.Deferred}
     */
    sMessageCollection.prototype.validateAndSaveAndSend = function validateAndSaveAndSend(
        msgAnchor,
        segment,
        userId,
        timeScheduled,
        $scope,
        sendAsSponsoredData
    ) {
        var self        = this,
            collection  = msgAnchor.message.collections.slice(0,1).pop()
        ;

        return this.validateAndSave(
            collection,
            Provider.sConversationValidator.ACTIVATE_LEVEL
        ).then(function() {
            return self.send(msgAnchor, segment, userId, timeScheduled, $scope, sendAsSponsoredData);
        });
    };

    /**
     * @function
     * @name Service.sMessageCollection#validateAndSend
     * @param {Model.Message.Anchor} msgAnchor
     * @param {Model.Segment} segment
     * @param {String=} userId
     * @param {Object=} timeScheduled
     * @param $scope
     * @param {Object=} sendAsSponsoredData
     * @returns {$.Deferred}
     */
    sMessageCollection.prototype.validateAndSend = function validateAndSend(
        msgAnchor,
        segment,
        userId,
        timeScheduled,
        $scope,
        sendAsSponsoredData
    ) {
        var self        = this,
            collection  = msgAnchor.message.collections.slice(0,1).pop()
        ;

        return this.sConversationValidator.validateWithPrompt(
            collection,
            Provider.sConversationValidator.ACTIVATE_LEVEL
        ).then(function() {
            return self.send(msgAnchor, segment, userId, timeScheduled, $scope, sendAsSponsoredData);
        });
    };

    /**
     * @param {Model.Message.Anchor} msgAnchor
     * @param {Model.Segment} segment
     * @param {String=} userId
     * @param {Object=} timeScheduled
     * @param $scope
     * @param {Object=} sendAsSponsoredData
     * @returns {*|null}
     */
    sMessageCollection.prototype.send = function send(msgAnchor, segment, userId, timeScheduled, $scope, sendAsSponsoredData) {
        var self        = this,
            formData    = new FormData(),
            messageSucc = 'Sending complete.',
            collection  = msgAnchor.message.collections.slice(0,1).pop(),
            url,
            method,
            addCollectionToFormData = function addCollectionToFormData(formData, collection) {
                collection.messages.reduce(
                    /**
                     * @param {FormData} carry
                     * @param {Model.Message} element
                     */
                    function(carry, element) {
                        element.getFormData(carry, 'messages[' + element.uuid + ']');
                        return carry;
                    },
                    formData
                );
            }
        ;

        if (collection.getRootMessage().isNew) {
            method = Const.Method.POST;
            addCollectionToFormData(formData, collection);
            url = self.sAPIAccess.endpoint('messageAnchor.sendMultiple').post(msgAnchor.uuid)
        } else {
            method = Const.Method.PUT;
            url = self.sAPIAccess.endpoint('messageAnchor.send').put(msgAnchor.uuid)
        }

        if (segment) {
            formData.append('segment', JSON.stringify(segment));
        }

        if (userId) {
            formData.append('hUserId', userId);
        }

        if (sendAsSponsoredData) {
            for (var i in sendAsSponsoredData) {
                if (i === 'campaignEnd' && sendAsSponsoredData[i].unix) {
                    sendAsSponsoredData[i] = sendAsSponsoredData[i].unix();
                }
                formData.append(i, sendAsSponsoredData[i]);
            }
        }

        if (timeScheduled) {
            if (timeScheduled.unix) {
                timeScheduled = timeScheduled.unix();
            }
            formData.append('timeScheduled', timeScheduled);
            messageSucc = 'Scheduling successful.'
        }

        return $.ajax({
            url         : url,
            method      : method,
            data        : formData,
            processData : false,
            contentType : false
        }).then(
            function () {
                self.notification.success(messageSucc);

                var trackingData = {
                    conversationId: msgAnchor.messageUuid,
                    sentTo: segment.uuid
                };
                $scope.$emit('sConversationSent', trackingData);
            },
            function (jqXHR) {
                var apiException = Model.Exception.APIResponseError.createFromXMLHttpRequest(jqXHR, this);

                self.notification.errorByAPIException(apiException);
            }
        ).always(function () {
            self.sendPromise = null;
            digestIfNeeded($scope);
        });
    };

    /**
     * @function
     * @name Service.sMessageCollection#fetchAnalyticsData
     * @param {Model.Message.Collection} collection
     * @param $scope
     * @param {Number=} version
     * @returns {$.Deferred}
     */
    sMessageCollection.prototype.fetchAnalyticsData = function(collection, $scope, version) {
        var anchor = collection.getRootMessage().messageAnchor,
            data = {};

        if (!isNaN(version)) {
            data.version = version;
        }

        var aggregatedStats = collection.getRootMessage().getMeta(Model.Message.KEY_ANALYTICS_META, null, Model.Message.KEY_AGGREGATED_STATS);
        if (!aggregatedStats) {
            data.includeAggregates = true;
        }

        return $.ajax({
            url: this.sAPIAccess.endpoint('messageAnchor.analytics').get(anchor.uuid),
            data: data
        }).then(function(response) {
            collection.extendWithAnalytics(response);
            return response;
        }).always(function() {
            digestIfNeeded($scope);
        });
    };

    /**
     * @function
     * @name Service.sMessageCollection#fetchAnalyticsVersions
     * @param {Model.Message.Collection} collection
     * @returns {$.Deferred}
     */
    sMessageCollection.prototype.fetchAnalyticsVersions = function fetchAnalyticsVersions(collection) {
        var anchor = collection.getRootMessage().messageAnchor;

        return $.ajax({
            url: this.sAPIAccess.endpoint('messageAnchor.analytics.versions').get(anchor.uuid)
        }).then(function (data) {
            Object.instanceOf(data, Array);

            data.sort(Array.sortFnByProperty('timeMs'));

            var analyticsVersions = [];

            for (var i = 0; i < data.length; i++) {
                analyticsVersions.unshift({version: i, time: data[i].timeMs});
            }

            return analyticsVersions;
        });
    };

    /**
     * @function
     * @name Service.sMessageCollection#createInitialCollection
     * @param {Boolean=} isIncoming
     * @returns {Model.Message.Collection}
     */
    sMessageCollection.prototype.createInitialCollection = function createInitialCollection(isIncoming) {
        var collection  =  new Model.Message.Collection(),
            msg         = collection.addMessage()
        ;

        msg.createAndSetMessageAnchor(this.sDomainService.currentDomainId);

        if (isIncoming) {
            msg.setIncoming();
        }
        return collection;
    };

    /**
     * @function
     * @name Service.sMessageCollection#buildByContentFeed
     * @param {String} template
     * @param {String[]} uuids
     * @returns {$.Deferred}
     */
    sMessageCollection.prototype.buildByContentFeed = function buildByContentFeed(template, uuids) {
        return loadWithActionDefinitions.call(
            this,
            this.sAPIAccess
                .endpoint('contentFeed.byDomain.toConversation')
                .get(this.sDomainService.currentDomainId),
            {
                template: template,
                uuids: uuids
            }
        ).then(function(collection) {
            collection.items.map(function (item) {
                item.setAsNew();
            });
            collection.getRelations().map(function (relation) {
                relation.setAsNew();
            });
            return collection;
        });
    };

    /**
     * @function
     * @name Service.sMessageCollection#loadByMessageUuid
     * @param {String} uuid
     * @returns {$.Deferred}
     */
    sMessageCollection.prototype.loadByMessageUuid = function loadByMessageUuid(uuid) {
        return loadWithActionDefinitions.call(
            this,
            this.sAPIAccess.endpoint('message.complete').get(uuid)
        );
    };

    /**
     * @function
     * @name Service.sMessageCollection#loadByAnchor
     * @param {Model.Message.Anchor} anchor
     * @returns {$.Deferred}
     */
    sMessageCollection.prototype.loadByAnchor = function loadByAnchor(anchor) {
        return this.loadByMessageUuid(anchor.messageUuid);
    };

    /**
     * Validates if the rootMessage complies as sponsored message
     * @param {Model.Message.Collection} collection
     * @return {$.Deferred}
     */
    sMessageCollection.prototype.validateAsSponsored = function validateAsSponsored(collection) {
        var rootMessage = collection.getRootMessage();
        if (!rootMessage) {
            return $.Deferred().reject();
        }

        // For OTN and recurring notification sending the first message needs to have exactly one
        // message part, and it can be of any type. I'm leaving the rest of the function here if
        // we ever decide to switch back (it was calling backend validation endpoint).
        return $.Deferred().resolve(rootMessage.partsLength() === 1);

        // for the validation real images are not attached - to save unnecessary traffic, so a smaller placeholder images is used
        // only affects Files without url
        var replacerFn = function(key, value) {
            if ((value instanceof Model.sFile) && key) {
                if (!value.url && value.uuid) {
                    value = JSON.parse(JSON.stringify(value, replacerFn));
                    value.url = window.location.origin + '/img/logo.png';
                }
            }
            return value;
        };

        var data = JSON.stringify(rootMessage.getMessageData(), replacerFn);

        return $.ajax({
                url: this.sAPIAccess.endpoint('message.validateAsSponsored').post(),
                method: Const.Method.POST,
                data: {
                    message: data
                }
            }
        ).then(function (result) {
            return result;
        });
    };

    /**
     * @param {Model.Message.Collection} collection
     * @param {Model.Message} rootCandidate
     * @return {$.Deferred}
     */
    sMessageCollection.prototype.extractPathAsNewConversation = function extractPathAsNewConversation(collection, rootCandidate) {
        // path carries info about the original convo
        var path            ,
            $deferred       = $.Deferred(),
            self            = this,
            cloneCollection = collection.clone()
        ;

        return this.sConversationValidator.validateWithPrompt(cloneCollection, Provider.sConversationValidator.ACTIVATE_LEVEL).then(function () {
            rootCandidate = cloneCollection.getMessageByUuid(rootCandidate.uuid);
            path = rootCandidate.extractPathAsRootMessage();

            if (!path) {
                $deferred.reject();
                return $deferred;
            }

            // mdDialog doesn't return a promise that has an always handler, so we resolve/reject the $.Deferred
            // which will have an always handler
            this.$mdDialog.show({
                controller          : Controller.sMessageAdmin.ExtractController,
                controllerAs        : '$ctrl',
                bindToController    : true,
                templateUrl         : 'smessageadmin:extract',
                parent              : angular.element(Const.PanelAnchor),
                escapeToClose       : false,
                locals              : {
                    path               : path,
                    hostCollection     : cloneCollection
                }
            }).then(
                function(newCollection) {
                    if (newCollection) {
                        self.notification.success('Path extracted successfully');
                        $deferred.resolve(cloneCollection, newCollection);
                    }
                    $deferred.resolve();
                },
                function() {
                    self.notification.error('Path extraction failed');
                    $deferred.reject();
                });

            return $deferred;
        }.bind(this));
    };

    /**
     * @return {String}
     */
    sMessageCollection.prototype.getTagsAutocompleteSource = function getTagsAutocompleteSource() {
        return this.sAPIAccess.endpoint('tagging.byDomain.autocomplete.anchor').get(this.sDomainService.currentDomainId, 'anchor');
    };

    /**
     * Saves the messages and relations
     *
     * @function
     * @private
     * @name Service.sMessageCollection~save
     * @param {Model.Message.Collection} collection
     * @returns {$.Deferred}
     */
    var save = function save(collection) {
        var self = this;
        Object.instanceOf(self, Service.sMessageCollection);

        var $deferred   = $.Deferred(),
            $localScope = self.$rootScope.$new(true),
            progress    = 0,
            hasDialog   = false,
            dialogTO    = setTimeout(function() {
                self.$mdDialog.show({
                    templateUrl         : '_progress_dialog',
                    parent              : angular.element(Const.PanelAnchor),
                    clickOutsideToClose : false,
                    scope               : $localScope,
                    escapeToClose       : false,
                    preserveScope       : true,
                    multiple            : true,
                    onRemoving: function () {
                        $localScope && $localScope.$destroy();
                    }
                });
                hasDialog = true;
            }, 250);

        self.sEntityRepository.syncWithCollection(collection);

        var markersToSave            = self.sMarkerRepository.getNewMarkersInConversation(collection).map(function (marker) {
                return marker.save.bind(marker);
            }),
            notificationLabelsToSave = []
        ;

        self.sNotificationLabel.getNewLabelsInCollection(collection).then(function (notificationLabels) {
            notificationLabelsToSave = notificationLabels.map(function (notificationLabel) {
                return notificationLabel.save.bind(notificationLabel);
            });
        }).always(function () {
            $.when(
                collection.save([].concat(markersToSave, notificationLabelsToSave)).always(function() {
                    clearTimeout(dialogTO);
                    dialogTO = null;

                    if (hasDialog) {
                        self.$mdDialog.hide();
                        hasDialog = false;
                    }
                }).progress(function(e) {

                    if (dialogTO && e.loaded === e.total) {
                        clearTimeout(dialogTO);
                        dialogTO = null;

                        if (hasDialog) {
                            self.$mdDialog.hide();
                            hasDialog = false;
                            return;
                        }
                    }

                    progress = parseInt(e.loaded / e.total * 100);

                    $localScope.$progress = progress;
                    digestIfNeeded($localScope);
                }),
                // add filter to only save those that are actually present in the convo
                self.sMemberAttribute.save(self.sMemberAttribute.extractAttributesFromConversation(collection.messages)),
                self.sEntityRepository.save()
            ).then(
                function (response, resultsLabels, resultsEntities) {
                    if (response && !response.length && !resultsLabels && resultsEntities && !resultsEntities.length) {
                        self.notification.success('Everything saved already.');
                    } else {
                        self.notification.success('Saved successfully.');
                    }

                    $deferred.resolve();
                },
                function (responses) {
                    var firstFailedResponse = responses.filter(function (response) {
                        return response.state() === 'rejected';
                    }).shift();

                    if (!firstFailedResponse) {
                        self.notification.error("Unknown error while saving. Please notify the support.");
                        $deferred.reject();
                        return;
                    }

                    firstFailedResponse.fail(function (jqXHR) {
                        var apiException = Model.Exception.APIResponseError.createFromXMLHttpRequest(jqXHR, this);

                        self.notification.errorByAPIException(apiException);
                    });

                    $deferred.reject();
                }
            );
        });

        return $deferred.promise();
    };

    /**
     * @function
     * @private
     * @name Service.sMessageCollection~activate
     * @param {Model.Message.Anchor} anchor
     * @returns {$.Deferred}
     */
    var activate = function activate(anchor) {
            var data = new FormData(),
                self = this
            ;

            Object.instanceOf(self, Service.sMessageCollection);

            data.append('uuid', anchor.uuid);

            return $.ajax({
                url         : self.sAPIAccess.endpoint('messageAnchor.publish').put(anchor.uuid),
                method      : Const.Method.PUT,
                data        : data,
                processData : false,
                contentType : false
            }).then(
                function (response) {
                    if (response && response.uuid === anchor.uuid) {
                        anchor.updateByData(response);
                        anchor.message.setJSONState();
                    }
                }
            ).fail(
                function (jqXHR) {
                    if (jqXHR && jqXHR.status === 403 && jqXHR.getResponseHeader(Const.Headers.X_RESOURCE_LIMIT_REACHED)) {
                        return self.notification.limitReached( 'info', jqXHR.getResponseHeader(Const.Headers.X_RESOURCE_LIMIT_REACHED));
                    }

                    var apiException = Model.Exception.APIResponseError.createFromXMLHttpRequest(jqXHR, this);

                    self.notification.errorByAPIException(apiException);
                }
            )
        },
        /**
         *
         * @param {String} endPoint
         * @param {Object=} data
         * @return {$.Deferred}
         */
        loadWithActionDefinitions = function loadWithActionDefinitions(endPoint, data) {
            return $.aggregateAction([
                    function() {
                        var collection  = new Model.Message.Collection();
                        return collection.load(endPoint, data);
                    },
                    this.sActionService.getDefinitions.bind(this.sActionService)
                ],
                Model.RESTAccessByUUID.endpoint_batch()
            ).then(function(results) {
                return results[0];
            });
        }
    ;

    ns.sMessageCollection = sMessageCollection;
})(Object.namespace('Service'));
