(function(angular) {

    var module = angular.module('sSegmentModule');

    /**
     * @ngdoc directive
     * @name sSegmentRecipients
     * @restrict A
     */
    module.directive(
        'sSegmentRecipients', function() {
            return {
                restrict: 'A',
                controller: sSegment.Directive.Controller.SegmentRecipients,
                controllerAs: '$segmentRecipientsCtrl',
                require: {
                    ngFormCtrl: '^form'
                },
                bindToController: {
                    segment : '<sSegmentRecipients'
                },
                scope: true
            }
        });
})(angular);
