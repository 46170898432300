(function(ns) {
    /**
     * @namespace
     * @alias Model.RepairCase.LonelyNLPFallback
     *
     * @extends Model.RepairCase
     */
    var LonelyNLPFallback = function() {

        var options = {
            confirm     : 'Ignore & continue saving',
            decline     : 'I’ll fix this myself',
            title       : 'Some messages are like WIFI cables … they link nowhere',
            content     : 'Buttons which trigger messages shouldn’t link to empty messages. That happened in message(s): X, Y and Z. They will be removed when saving. Ignore and go on? (Tip: Use the minimap to find them)',
            ariaLabel   : ''
        };

        LonelyNLPFallback._pProto.constructor.call(
            this,
            this.checkLonelyNLPFallback,
            this.removeLonelyNLPFallback,
            options
        );

        this.faultyMessages = [];
    };

    Object.extendProto(LonelyNLPFallback, Model.RepairCase);

    /**
     * @name Model.RepairCase.LonelyNLPFallback#checkLonelyNLPFallback
     * @param {Model.Message[]} messages
     * @throws {Model.Exception.InvalidMessages}
     */
    LonelyNLPFallback.prototype.checkLonelyNLPFallback = function checkLonelyNLPFallback(messages) {
        for (var i = 0; i < messages.length; i++) {
            var relations = messages[i].getRelationsByType(Model.Message.Relation.TYPE_NLP);
            if (relations.length === 1 && relations.pop().isFallback) {
                this.faultyMessages.push(messages[i]);
            }
        }

        if (this.faultyMessages.length) {
            throw new Model.Exception.InvalidMessages(this.faultyMessages);
        }
    };

    /**
     * @name Model.RepairCase.LonelyNLPFallback#removeLonelyNLPFallback
     */
    LonelyNLPFallback.prototype.removeLonelyNLPFallback = function removeLonelyNLPFallback() {
        for (var i = 0; i < this.faultyMessages.length; i++) {
            var relations = this.faultyMessages[i].getRelationsByType(Model.Message.Relation.TYPE_NLP);
            for (var j in relations) {
                this.faultyMessages[i].removeRelation(relations[j]);
            }
        }
    };

    ns.LonelyNLPFallback = LonelyNLPFallback;
})(Object.namespace('Model.RepairCase'));

