(function (ns) {
    /**
     * @namespace
     * @alias Service.sMarkerRepository
     *
     * @constructor
     * @extends Model.Collection.RESTAccess
     */
    var sMarkerRepository = function () {
        sMarkerRepository._pProto.constructor.call(this, Model.Marker);
    };

    Object.extendProto(sMarkerRepository, Model.Collection.RESTAccess);

    /**
     * @param {Model.Message.Collection} collection
     * @return {Model.Marker[]}
     */
    sMarkerRepository.prototype.getNewMarkersInConversation = function getNewMarkersInConversation(collection) {
        var markersInCollection = collection.messages.reduce(function (carry, message) {
            var markerUUID = message.getMeta(Model.Message.KEY_MARKER_UUID);

            if (!markerUUID || carry.indexOf(markerUUID) !== -1) {
                return carry;
            }
            carry.push(markerUUID);
            return carry;
        }, []);
        return this.items.filter(function (element) {
            return element.isNew && markersInCollection.indexOf(element.uuid) !== -1;
        });
    };

    ns.sMarkerRepository = sMarkerRepository;
})(Object.namespace('Service'));
