(function(ns) {
    /**
     *
     * @param {String} data
     * @param {String} filename
     * @param {String} type
     */
    var sDownload = function (data, filename, type) {
        this.blob = new Blob([data], {type: type});
        Object.defineProperties(
            this,
            {
                'filename': {
                    enumerable: true,
                    get: function () {
                        return filename;
                    }
                }
            }
        );
    };

    sDownload.prototype.downloadFile = function () {
        var windowUrl = (window.URL || window.webkitURL);
        var downloadUrl = windowUrl.createObjectURL(this.blob);
        var anchor = document.createElement("a");
        anchor.className = "ui-hidden-accessible";
        anchor.href = downloadUrl;
        anchor.download = this.filename;
        document.body.appendChild(anchor);
        anchor.click();
        windowUrl.revokeObjectURL(this.blob);
        anchor.remove();
    };

    ns.sDownload = sDownload;
})(Object.namespace(''));