(function (ns) {
    /**
     * @namespace
     * @alias Model.Facebook.ThreadOwnerInfo
     *
     * @constructor
     * @param {String} appId
     * @param {String} ownerAppId
     * @param {String} ownerAppName
     * @param {Boolean} primary
     */
    var ThreadOwnerInfo = function (appId, ownerAppId, ownerAppName, primary) {
        Object.defineProperties(
            this,
            {
                appId: {
                    enumerable: true,
                    value: appId
                    /**
                     * @property
                     * @constant
                     * @name Model.Facebook.ThreadOwnerInfo#appId
                     * @type {String}
                     */
                },
                ownerAppId: {
                    enumerable: true,
                    value: ownerAppId
                    /**
                     * @property
                     * @constant
                     * @name Model.Facebook.ThreadOwnerInfo#ownerAppId
                     * @type {String}
                     */
                },
                ownerAppName: {
                    enumerable: true,
                    value: ownerAppName
                    /**
                     * @property
                     * @constant
                     * @name Model.Facebook.ThreadOwnerInfo#ownerAppName
                     * @type {String}
                     */
                },
                isPrimary: {
                    enumerable: true,
                    value: !!primary
                    /**
                     * @property
                     * @constant
                     * @name Model.Facebook.ThreadOwnerInfo#primary
                     * @type {Boolean}
                     */
                },
                isOwner: {
                    enumerable: true,
                    get: function () {
                        return this.ownerAppId === this.appId;
                    }
                    /**
                     * @property
                     * @name Model.Facebook.ThreadOwnerInfo#isOwner
                     * @type {Boolean}
                     */
                }
            });
    };

    ns.ThreadOwnerInfo = ThreadOwnerInfo;
})(Object.namespace('Model.Facebook'));
