(function(ns) {
    /**
     * @namespace
     * @alias Model.Message.Collection
     * @extends Model.Collection.RESTAccess
     * @constructor
     */
    var Collection = function() {
        var protecteds  = Collection._pProto.constructor.call(this, Model.Message)
        ;

        Object.defineProperties(
            this,
            {
                messages: {
                    get: function() {
                        return this.items;
                    }
                    /**
                     * @property
                     * @name Model.Message.Collection#messages
                     * @type {Model.Message[]}
                     */
                }
            }
        );

        /**
         * @name Model.Message.Collection#removeMessage
         * @param {Model.Message} msg
         * @param {Boolean=} force
         */
        this.removeMessage = function removeMessage(msg, force) {
            if (msg.isRoot && !force) {
                return;
            }

            this.removeItem(msg);
            msg.cleanRelations(true);

            msg.removeCollection(this);
        };

        /**
         * @param {Function} closure
         * @param {*} invoker
         * @return {Boolean}
         */
        this.supportsChange = function supportsChange(closure, invoker) {
            return invoker === this;
        };

        /**
         * @return {Object}
         */
        this.getJSONState = function getJSONState() {
            return this.messages.reduce(function(carry, message) {
                carry[message.uuid] = JSON.parse(message.getData());
                return carry;
            }, {});
        };

        var parentIsModified = this.isModified;

        /**
         * @returns {Boolean}
         */
        this.isModified = function isModified() {
            var parentModified = parentIsModified.call(this),
                i
            ;

            if (parentModified) {
                if (this.items.length !== 1 || !this.items[0].isEmpty()) {
                    return true;
                }
            }

            var relations = this.getRelations();
            for (i = 0; i < relations.length; i++) {
                if (relations[i].isModified()) {
                    return true;
                }
            }

            return false;
        };

        /**
         * Find a relation in the protected deleteStack
         * @param {Model.Message.Relation} relation
         * @return {Number}
         */
        var indexInStack = function(relation) {
            return protecteds.deleteStack.reduce(function (carry, relationInStack, key) {
                if (carry !== -1) {
                    return carry;
                }

                if (relationInStack instanceof Model.Message.Relation && relationInStack.uuid === relation.uuid) {
                    carry = key;
                }

                return carry;
            }, -1);
        };

        /**
         * Adds a relation into the remove queue
         * @param {Model.Message.Relation} relation
         */
        this.registerRelationForDeletion = function registerRelationForDeletion(relation) {
            var index = indexInStack(relation);
            if (relation.isNew || index !== -1) {
                return;
            }

            protecteds.deleteStack.unshift(relation);
        };

        /**
         * Removes a relation from the remove queue
         * @param {Model.Message.Relation} relation
         */
        this.unRegisterRelationFromDeletion = function unRegisterRelationFromDeletion(relation) {
            var index = indexInStack(relation);
            if (relation.isNew ||  index === -1) {
                return;
            }

            protecteds.deleteStack.splice(index, 1);
        };

        var parentAddItem = this.addItem;
        this.addItem = function addItem(item, prepend) {
            if (this.getIndex(item) !== -1) {
                return;
            }
            item.addCollection(this);
            parentAddItem.call(this, item, prepend);
        };

        var parentSave = this.save;
        this.save = function save(additionalPreExecutables) {
            var additionalExecutables = {};

            additionalPreExecutables = additionalPreExecutables || [];
            Object.instanceOf(additionalPreExecutables, Array);

            additionalExecutables = {
                pre: additionalPreExecutables,
                post: this.getRelations().map(function (relation) {
                    return relation.save.bind(relation);
                })
            };

            protecteds.extraExecutablesBeforeSave = additionalExecutables;
            return parentSave.call(this);
        };

        /**
         * @param {Model.Message.Collection} original
         * @private
         */
        this.__extendClone = function __extendClone(original) {
            original.messages.map(function (message) {
                message.__afterCloneConstructed = function (collection, clone) {
                    var candidate = collection.getMessageByUuid(this.uuid);
                    if (candidate) {
                        return candidate;
                    }

                    // updateRootDistance needs to be turned off until the cloning of all relations and messages has finished
                    clone.__updateRootDistanceOriginal = clone.updateRootDistance;
                    clone.__updatePostBackContent = clone.updatePostBackContent;
                    clone.updateRootDistance = function () {};
                    clone.addCollection(collection);
                }.bind(message, this)
            }.bind(this));

            original.getRootMessage().clone();

            // root distance update must be set back and invoked once all messages are added to the collection
            this.messages.map(function (message) {
                message.updatePostBackContent = message.__updatePostBackContent;
                message.updateRootDistance = message.__updateRootDistanceOriginal;
                delete message.__updatePostBackContent;
                delete message.__updateRootDistanceOriginal;
                return message;
            }).map(function (message) {
                message.updatePostBackContent();
                message.updateRootDistance();
            });

            original.messages.map(function (message) {
                delete message.__afterCloneConstructed;
            });
        };
    };

    Object.extendProto(Collection, Model.Collection.RESTAccess);

    /**
     * @param {String} uuid
     * @return {?Model.Message}
     */
    Collection.prototype.getMessageByUuid = function getMessageByUuid(uuid) {
        for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].uuid === uuid) {
                return this.items[i];
            }
        }
        return null;
    };

    /**
     * @param {String} uuid
     * @return {?Model.Message}
     */
    Collection.prototype.getMessageByCTAUuid = function getMessageByCTAUuid(uuid) {
        for (var i = 0; i < this.items.length; i++) {
            var cta = this.items[i].getCTAByUuid(uuid);
            if (cta) {
                return this.items[i];
            }
        }
        return null;
    };

    /**
     * @name Model.Message.Collection#appendMessage
     * @param {Model.Message} msg
     * @param {Boolean=} prepend
     */
    Collection.prototype.appendMessage = function appendMessage(msg, prepend) {
        Object.instanceOf(msg, Model.Message);


        if (this.getMessageByUuid(msg.uuid)) {
            return;
        }

        this.addItem(msg, prepend);
    };

    /**
     *
     * @param {String} url
     * @param {Object=} data
     * @return {$.Deferred}
     */
    Collection.prototype.load = function load(url, data) {
        var self = this;

        return Collection._pProto.load.call(this, url, data).then(function (collection, itemsAndErrors) {
            itemsAndErrors = itemsAndErrors || [];

            var i,
                multipleInteractiveErrors = []
            ;

            itemsAndErrors.map(function(itemAndError) {
                if (!itemAndError[1]) {
                    return;
                }

                itemAndError[1].map(function(error) {
                    if (error instanceof Model.Exception.MultipleInteractiveParts) {
                        multipleInteractiveErrors.push(error);
                    }
                });
            });

            for (i = 0; i < self.messages.length; i++) {
                self.messages[i].updatePostBackRelations();
                self.messages[i].getRelationsAsArray().map(
                    /**
                     * @param {Model.Message.Relation} element
                     */
                    function (element) {
                        element.setJSONState();
                    });
                self.messages[i].setJSONState();
            }

            multipleInteractiveErrors.map(
                /**
                 * @param {Model.Exception.MultipleInteractiveParts} error
                 */
                function (error) {
                    error.target.splitMessage(error.messagePart);
                });

            self.repair();

            self.messages.map(function (message) {
                message.resolvePlaceholderAttachments(message.getContextSpecificPlaceholders());
            });

            return self;
        });
    };

    Collection.prototype.repair = function repair() {
        var i;

        for (i = 0; i < this.messages.length; i++) {
            this.messages[i].repair();
        }
    };

    /**
     * @function
     * @name Model.Message.Collection#getRelations
     * @returns {Model.Message.Relation[]}
     */
    Collection.prototype.getRelations = function getRelations() {
        var self        = this,
            relationsUuids = [],
            relations = [];

        for (var qKey in self.messages) {
            for (var rKey in self.messages[qKey].messageRelations) {
                if (relationsUuids.indexOf(self.messages[qKey].messageRelations[rKey].uuid) === -1) {
                    relationsUuids.push(self.messages[qKey].messageRelations[rKey].uuid);
                    relations.push(self.messages[qKey].messageRelations[rKey]);
                }
            }
        }

        return relations;
    };

    /**
     * @function
     * @name Model.Message.Collection#getAnchor
     * @returns {?Model.Message.Anchor}
     */
    Collection.prototype.getAnchor = function getAnchor() {
        var msgAnchor = null;

        try {
            msgAnchor = this.getRootMessage().messageAnchor;
        } catch (err) {
        }

        return msgAnchor;
    };

    /**
     * @name Model.Message.Collection#addAndRelateMessage
     * @param {Model.Message.Relation} relation
     * @returns {Model.Message}
     */
    Collection.prototype.addAndRelateMessage = function(relation) {
        Object.instanceOf(relation, Model.Message.Relation);

        var newMsg,
            actionContext
        ;

        // need to set where we going
        if (!relation.to) {
            newMsg = Model.Message.createMessageForRelation(relation);
            relation.to = newMsg;
        } else {
            newMsg = relation.to;
        }

        // check if we need to create a new relation immediately
        if (relation.type === Model.Message.Relation.TYPE_FOLLOWED_BY
            && relation.to
            && !relation.from.isIncoming
            && newMsg.firstPart()
            && (actionContext = newMsg.firstPart().getAllMessagePostbackActions().shift())
        ) {
            var relationsFrom = relation.to.getRelationsFrom();
            if (relationsFrom.length === 0) {
                var newRelation = newMsg.getOrCreateMessageRelationByOption(actionContext.action);
                this.addAndRelateMessage(newRelation);
                relation.from.updatePostBackContent();
            }
        }

        return newMsg;
    };

    /**
     * Adds a new message to the queue and returns it
     * @name Model.Message.Collection#addMessage
     * @param {String=} uuid
     * @param {Boolean=} prepend
     * @returns {Model.Message}
     */
    Collection.prototype.addMessage = function addMessage(uuid, prepend) {
        var msg = new Model.Message(uuid);
        this.appendMessage(msg, prepend);

        return msg;
    };

    /**
     * @name Model.Message.Collection#getOrCreateMessage
     * @returns {Model.Message} msg
     */
    Collection.prototype.getOrCreateMessage = function getOrCreateMessage() {
        if (!this.messages.length) {
            this.addMessage();
        }

        return this.messages.slice(-1).pop();
    };

    /**
     * @name Model.Message.Collection#getCounterMax
     * @return {*}
     */
    Collection.prototype.getCounterMax = function getCounterMax() {
        return this.messages.reduce(
            /**
             * @param {Number} max
             * @param {Model.Message} element
             * @returns {*}
             */
            function(max, element) {
            if (!element.counter) {
                return max;
            }
            return Math.max(element.counter, max)
        }, 0);
    };

    /**
     * @name Model.Message.Collection#getRootMessage
     * @return {Model.Message|*}
     */
    Collection.prototype.getRootMessage = function getRootMessage() {
        for (var i = 0; i < this.messages.length; i++) {
            if (this.messages[i].isRoot) {
                return this.messages[i];
            }
        }
        throw 'No root message was found!';
    };

    /**
     * @name Model.Message.Collection#waitForMediaToLoad
     * @return {$.Deferred}
     */
    Collection.prototype.waitForMediaToLoad = function waitForMediaToLoad() {
        var mediaPromises = [];

        for (var i = 0; i < this.messages.length; i++) {
            for (var j in this.messages[i].messageParts) {
                for (var k = 0; k < this.messages[i].messageParts[j].messageContents.length; k++) {
                    mediaPromises.push(this.messages[i].parts[j].messageContents[k].waitForMediaToLoad());
                }
            }
        }

        return $.when.apply($, mediaPromises);
    };

    /**
     * @param {{messages: Array, aggregated: Array}} data
     */
    Collection.prototype.extendWithAnalytics = function extendWithAnalytics(data) {
        function asOfTotal(value, total) {
            if (total === 0) {
                return 0;
            }

            return value / total;
        }

        var messagesStats   = data.messages || [],
            self          = this
        ;

        // map analytics data to messages
        messagesStats.map(function(analytics) {
            var message = self.getMessageByUuid(analytics.uuid);

            if (!message) {
                return;
            }

            message.setMeta(Model.Message.KEY_ANALYTICS_META, analytics);
        });

        if (data.aggregated) {
            this.getRootMessage().setMeta(Model.Message.KEY_ANALYTICS_META, data.aggregated, Model.Message.KEY_AGGREGATED_STATS);
        }

        // get root message analytics - if possible
        var analytics = this.getRootMessage().getMeta(Model.Message.KEY_ANALYTICS_META);
        if (!analytics) {
            return;
        }

        var totalRecipients = analytics.recipients;

        // calculate ...OfTotals using the root message recipients
        this.messages.map(function(message) {
            var analytics = message.getMeta(Model.Message.KEY_ANALYTICS_META);

            if (!analytics) {
                if (!message.isIncoming) {
                    return;
                }

                // Incoming messages only have relative CTR - calculate absolute number
                var relationsTo = message.getRelationsTo();

                if (!relationsTo.length || !relationsTo[0].from || !relationsTo[0].associatedCTA) {
                    return;
                }

                var analyticsParent = relationsTo[0].from.getMeta(Model.Message.KEY_ANALYTICS_META),
                    CTAUuid = relationsTo[0].associatedCTA.uuid;

                if (!analyticsParent.ctas[CTAUuid]) {
                    return;
                }
                var calculatedRecipients = Math.round(analyticsParent.recipients * analyticsParent.ctas[CTAUuid].CTR / 100);
                analytics = {'recipients' : calculatedRecipients, 'read' : calculatedRecipients};
            }
            // these are absolute values
            ['recipients', 'read'].map(function(element) {
                analytics[element + 'OfTotal'] = asOfTotal(analytics[element], totalRecipients) * 100;
            });

            // these are already percentages calculated based on the read value so
            // to get the percentages relative to the totalRecipients we must first
            // get the original absolute value
            ['IAR', 'CTR', 'dropOff'].map(function(element) {
                analytics[element + 'OfTotal'] = asOfTotal(analytics[element] * analytics.read / 100, totalRecipients) * 100;
            });

            // CTR for ctas is also already a percentage - the absolute value needs to be obtained
            for (var i in analytics.ctas) {
                // In case of NLP analytics.read is null (because this component is never presented to user)
                // so we take the value analytics.read of the "parent" message
                if (analytics.read === null && message.isIncoming && message.messageRelations) {
                    var relations = message.getRelationsAsArray();

                    for (var j = 0; j < relations.length; j++) {
                        if (relations[j].toUuid === message.uuid) {
                            var parentAnalytics = self.getMessageByUuid(relations[j].fromUuid).getMeta(Model.Message.KEY_ANALYTICS_META);
                            analytics.read = parentAnalytics.read;
                            break;
                        }
                    }
                }

                analytics.ctas[i].CTROfTotal = asOfTotal(analytics.ctas[i].CTR * analytics.read / 100, totalRecipients) * 100;
            }

            message.setMeta(Model.Message.KEY_ANALYTICS_META, analytics);
        });
    };

    /**
     * @param {number} domainId
     * @returns {Model.Message.Collection}
     */
    Collection.createEmptyAITemplate = function createEmptyAITemplate(domainId) {
        var collection = new Model.Message.Collection(),
            message = collection.addMessage(),
            messagePart = message.createAndAddPart('AIReaction'),
            cta = new Model.CTA(),
            action = new Model.Action();

        action.type = Model.Action.TYPE_POSTBACK_STORY;
        cta.actions.addAction(action);
        cta.label = Number(0).toLettersRadix().toUpperCase();
        messagePart.initWithEmptyContent();
        messagePart.content.alternatives.ctas.push(cta);
        message.createAndSetMessageAnchor(domainId);
        message.setIncoming();

        return collection;
    };

    /**
     * @param {Model.Message[]} path
     * @return {Model.Message.Collection}
     */
    Collection.createFromSubTree = function createFromSubTree(path) {
        Object.instanceOf(path, Array);
        var exchanges  = {}
        ;

        // make json > during that find and replace uuids
        var jsonString = JSON.stringify(path.map(function (message) {
            var data = message.getMessageData(),
                messageRelations = []
            ;
            for (var i in message.messageRelations) {
                messageRelations.push(message.messageRelations[i].getRelationData())
            }
            data.messageRelations = messageRelations;
            return data;
        }), function (key, value) {
            if (key === 'messageCounter' || key === 'fixed') {
                return null;
            }

            if (exchanges[value]) {
                return exchanges[value];
            }

            if (key === 'uuid') {
                if (!exchanges[value]) {
                    exchanges[value] = new Model.UUID().uuid;
                }

                return exchanges[value];
            }

            // For newly added media we only have locally stored image, which would get
            // lost with JSON.stringify, so we pass it ass base 64 encoded data.
            if (key === 'media' && value instanceof Model.sImage && !value.url) {
                var result = {
                    mediaAsBase64 : value.content
                };

                if(value.reSizedImage instanceof Model.sImage) {
                    result.croppingData = value.reSizedImage.content;
                    result.croppingOptions = value.reSizedImage.options.crop;
                }

                return result;
            }

            return value;
        });

        // replace all uuids found during the json turn
        for (var fromChange in exchanges) {
            var toChange = exchanges[fromChange];
            jsonString = jsonString.replace(new RegExp(fromChange, 'g'), toChange);
        }

        var json = JSON.parse(jsonString);

        // now create the collection from the json with exchanges
        var collection = new Model.Message.Collection();
        json.map(function (messageJSON) {
            var message = new Model.Message(messageJSON.uuid);
            if (messageJSON.isIncoming) {
                message.setIncoming();
            }
            collection.appendMessage(message);
            return messageJSON;
        }).map(function (messageJSON) {
            var message = collection.getMessageByUuid(messageJSON.uuid);
            message.updateByData(messageJSON);
            return message;
        }).map(function (message) {
            message.resolvePlaceholderAttachments(message.getContextSpecificPlaceholders());
        });


        return collection;
    };

    ns.Collection = Collection;
})(Object.namespace('Model.Message'));
