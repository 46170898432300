(function (ns) {
    /**
     * @namespace
     * @alias Model.Exception.InvalidValue
     *
     * @constructor
     */
    var InvalidValue = function (value) {
        this.message = 'Value `' + value + '` is not allowed.';
        
        Object.defineProperties(
            this,
            {
                value: {
                    enumerable: true,
                    get: function () {
                        return value;
                    }
                    /**
                     * @property
                     * @name Model.Exception.InvalidValue#value
                     * @type {*}
                     */
                }
            });
    };

    Object.extendProto(InvalidValue, Error);

    ns.InvalidValue = InvalidValue;
})(Object.namespace('Model.Exception'));
