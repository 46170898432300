(function (ns) {
    /**
     * @namespace
     * @alias Model.Analytics.Trace.VsTime
     *
     * @extends Model.Analytics.Trace2d
     * @constructor
     */
    var VsTime = function () {
        VsTime._pProto.constructor.call(this);
    };

    Object.extendProto(VsTime, Model.Analytics.Trace2d);

    /**
     * @param {Model.Analytics.Entry[]} entries
     * @param {String=} type
     * @returns {Model.Analytics.Trace2d}
     */
    VsTime.createFromEntries = function(entries, type) {
        return Model.Analytics.Trace2d.createFromEntries(
            entries,
            Model.Analytics.Entry.plotlyTimeExtractor,
            function (entry) {
                // get the first meta entry if none specified
                type = type || entry.getMetaFields().shift();

                // use the value type stored in meta (eg.: absolute, relative)
                if (type && typeof entry[type] !== 'undefined') {
                    return entry[type];
                }

                return null;
            });
    };

    ns.VsTime = VsTime;
})(Object.namespace('Model.Analytics.Trace'));