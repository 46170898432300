(function(ns) {
    /**
     * @namespace
     * @alias FilenameFromHeaderParser
     * @constructor
     */
    var FilenameFromHeaderParser = function () {};

    /**
     * @function
     * @static
     * @name FilenameFromHeaderParser.getFilename
     * @param {String} contentDispositionHeader
     * @return {String|Boolean}
     */
    FilenameFromHeaderParser.getFilename = function getFilename(contentDispositionHeader) {
        if (!contentDispositionHeader || contentDispositionHeader.indexOf('attachment') === -1) {
            return false;
        }

        var filenameRegex   = /filename\s*\*?=\s*['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/gi,
            matches         = filenameRegex.exec(contentDispositionHeader)
        ;

        if (matches === null || !matches[1]) {
            return false;
        }

        return matches[1].replace(/['"]/g, '');
    };

    ns.FilenameFromHeaderParser = FilenameFromHeaderParser;
})(Object.namespace(''));
