(function (ns) {
    var KEY_STATIC_SEGMENT  = 'static',
        KEY_DYNAMIC_SEGMENT = 'dynamic'
    ;

    /**
     * @namespace
     * @alias sSegment.Component.Controller.sSegmentPicker
     *
     * @constructor
     * @param {Service.sDomain} sDomainService
     * @param $scope
     */
    var sSegmentPicker = function (sDomainService, $scope) {
        var self                = this,
            dynamicSegment      = new Model.Segment(),
            staticSegment
            ;

        dynamicSegment.domainId     = sDomainService.currentDomainId;
        dynamicSegment.type         = Model.Segment.KEY_BEHAVIOR_BASED;

        this.segmentUsed            = KEY_STATIC_SEGMENT;
        this.$deRegister            = [];

        this.$scope                 = $scope;

        var tmpModel                = this.model;

        Object.defineProperties(
            this,
            {
                model : {
                    enumerable      : true,
                    get: function() {
                        return self.segmentUsed === KEY_DYNAMIC_SEGMENT ? dynamicSegment : staticSegment;
                    },
                    set: function(val) {
                        if (!val) {
                            // was already reseted
                            if (staticSegment === val) {
                                return;
                            }
                            // don't play with undefined !== null
                            staticSegment = val;
                            dynamicSegment.conditions.reset();
                            dynamicSegment.saveAsNewSegment = false;
                            dynamicSegment.name = null;

                            return;
                        }

                        try {
                            Object.instanceOf(val, Model.Segment);
                        } catch (e) {
                            // no idea why this keeps getting a `{}` everything is either null or undefined
                            return;
                        }

                        if (val.isNew) {
                            dynamicSegment          = val;
                            self.segmentUsed        = KEY_DYNAMIC_SEGMENT;
                        } else {
                            staticSegment           = val;
                            self.segmentUsed        = KEY_STATIC_SEGMENT;
                        }
                    }
                    /**
                     * @name sSegment.Component.Controller.sSegmentPicker#model
                     * @property
                     * @type {Model.Segment}
                     */
                }
            }
        );

        this.model = tmpModel;

        /**
         * @name sSegment.Component.Controller.sSegmentPicker#dynamicName
         * @type {String}
         */
    };

    sSegmentPicker.prototype.$onInit = function $onInit() {
        this.labels = $.extend({}, {'existing' : 'Choose existing segment', 'new' : 'Create a new Segment'}, this.labels);
    };

    /**
     * @name sSegment.Component.Controller.sSegmentPicker#handleSegmentChange
     * @param {Model.Segment} item
     */
    sSegmentPicker.prototype.handleSegmentChange = function handleSegmentChange(item) {
        this.model = item;
        this.segmentUsed = KEY_STATIC_SEGMENT;
    };

    ns.sSegmentPicker = sSegmentPicker;
})(Object.namespace('sSegment.Component.Controller'));