(function(ns) {
    var KEY_COUNTER_META        = 'messageCounter',
        KEY_PRIORITY            = 'priority',
        KEY_CTA_UUID            = 'CTAuuid',
        KEY_UUID                = 'uuid',
        KEY_DYNAMIC_ACTION_KEY  = 'dynamicActionKey',
        KEY_TEMPLATE_ID         = 'templateId',
        KEY_ANALYTICS_META      = 'analytics',
        KEY_MESSAGE_NAME        = 'messageName',
        KEY_MARKER_UUID         = 'markerUuid',
        TYPE_ALIAS_CONVERSATION = 'conversation',
        TYPE_ALIAS_REACTION     = 'reaction',
        EVENT_MESSAGE_NAME_BLUR = 'EVENT_MESSAGE_NAME_BLUR',
        REGEX_MESSAGE_URL_HASH  = /^message-(goal-|focus-)?(.*)$/,
        DEFAULT_MESSAGE_LABEL   = 'Message',
        KEY_MESSAGE_DATA_NAME   = 'message',
        KEY_AGGREGATED_STATS    = 'aggregated'
    ;

    /**
     * @namespace
     * @alias Model.Message
     *
     * @extends Model.RESTAccessByUUID
     * @extends Model.Behavior.Meta
     * @constructor
     *
     * @param {String=} uuid
     */
    var Message = function(uuid) {
        var metaOptions = {};

        metaOptions[Model.Behavior.Meta.VALUE_TRANSFORM_FUNCTION] = function (key, val) {
            return ((key === 'x' || key === 'y') && val.toFixed) ? parseFloat(val.toFixed(2)) : val;
        };

        Model.Behavior.Meta.call(this, metaOptions);

        this.setMeta(KEY_MESSAGE_NAME, null);
        this.setMeta(KEY_MARKER_UUID, null);

        var parts               = {},
            partsCache          = {},
            type                = Const.Outgoing,
            messageAnchor       = null,
            partCount           = 0,
            isOpen              = true,
            relations           = {},
            relationsCache      = {},
            self                = this,
            rootDistance        = null,
            collections         = [],
            counter             ,
            relationsUpdating   = false,
            contentUpdating     = false,
            needsRelationUp     = false,
            needsContentUp      = false,
            cachedContext       = null,
            isRoot              = false,
            placeholderCache    = null,
            setRootDistance     = function setRootDistance(newRootDistance) {
                var i;

                // if the new value is greater than the current one (orphaned in closed loop)
                if (newRootDistance && rootDistance && newRootDistance > rootDistance) {
                    setRootDistance(null);
                    self.updateRootDistance();
                    return;
                }

                // just override rootDistance and update related messages if there is a change
                if (newRootDistance !== rootDistance) {
                    rootDistance = newRootDistance;

                    var relationsFrom = self.getRelationsFrom();
                    for (i = 0; i < relationsFrom.length; i++) {
                        if (relationsFrom[i].to) {
                            relationsFrom[i].to.updateRootDistance();
                        }
                    }
                }

                if (self.isOrphan) {
                    if (self.isEmpty()) {
                        self.removeFromCollections();
                        return;
                    }

                    // check if it is template-message (dynamic content)
                    if (self.getMeta(KEY_TEMPLATE_ID)) {
                        var relationsTo = self.getRelationsTo();

                        if (!relationsTo.length) {
                            self.removeFromCollections();
                            return;
                        }

                        for (i = 0; i < relationsTo.length; i++) {
                            // from is incoming intermediate message
                            if (!relationsTo[i].from || !relationsTo[i].from.getRelationsTo().length) {
                                self.removeFromCollections();
                                return;
                            }
                        }
                    }
                }
            }
        ;
        /**
         * @function
         * @name Model.Message#delete
         */

        /**
         * @type {boolean}
         * @name Model.Message#isRoot
         */

        this.isRoot = counter === 1;

        /**
         * @name Model.Message#getRelationByOptionValue
         * @param {String} key
         * @param {*} value
         * @returns {Model.Message.Relation|null}
         */
        this.getRelationByOptionValue = function getRelationByOption(key, value) {
            for (var i in relations) {
                if (relations[i].options[key] === value) {
                    return relations[i];
                }
            }

            return null;
        };

        /**
         * @function
         * @name Model.Message#getRelationsByType
         * @param {String} _type
         * @returns {Array}
         */
        this.getRelationsByType = function getRelationsByType(_type) {
            var matches = [];
            for (var i in relations) {
                if (relations[i].type === _type) {
                    matches.push(relations[i]);
                }
            }

            return matches;
        };

        /**
         * @function
         * @name Model.Message#setIncoming
         */
        this.setIncoming = function() {
            type = Const.Incoming;

            if (!this.isRoot && this.counter) {
                this.counter = null;
            } else if (this.isRoot && this.messageAnchor) {
                this.messageAnchor.type = Model.Message.TYPE_ALIAS_REACTION;
            }

            if (this.isRoot) {
                this.setMeta('priority', Const.DefaultPriority);
            }
        };

        /**
         * @function
         * @name Model.Message#setOutgoing
         */
        this.setOutgoing = function() {
            type = Const.Outgoing;

            if (!this.isRoot && !this.counter && collections.length) {
                this.counter = collections.slice(0,1).pop().getCounterMax() + 1;
            }

            if (this.isRoot && this.messageAnchor) {
                this.messageAnchor.type = TYPE_ALIAS_CONVERSATION;
            }

            if (this.isRoot) {
                this.unsetMeta('priority');
            }
        };

        this.addPart = function addPart(part, disableOrder) {
            Object.instanceOf(part, Model.Message.Part);

            var lastPart
                ;

            if (parts[part.uuid] === part) {
                return;
            }

            // there are already messageParts
            if (this.partCount &&
                (lastPart = this.lastPart())
            ) {
                // if the part is interactive and would be inserted before the last part
                if (part.isInteractive && typeof(part.number) === 'number' && part.number < lastPart.number) {
                    if (lastPart.uuid !== part.uuid) {
                        throw new Model.Exception.MultipleInteractiveParts(part, this);
                    }
                }
                // if the lastPart is interactive and the non-interactive part would be inserted after it, add it just before
                else if (lastPart.isInteractive && (!part.number || part.number >= lastPart.number) && lastPart.uuid !== part.uuid) {
                    return this.insertBeforePosition(part, lastPart.number);
                }
            }

            if (!parts[part.uuid]) {
                partCount++;
            }

            parts[part.uuid] = part;
            updatePartsCache();
            part.parent = this;

            this.cachedContext = null;

            if (!part.number) {
                part.number = partCount;
            }

            if (!disableOrder) {
                this.orderParts();
            }

            if (this.isIncoming) {
                part.updateActionsMeta(part.getAllActions().map(function(actionContext) {
                    return actionContext.action
                }));
                this.updatePostBackRelations();
                return this;
            }

            this.getRelationsFrom().filter(function(relation) {
                return relation.to && relation.type !== Model.Message.Relation.TYPE_NLP;
            }).map(function(relation) {
                setAsIntermediateMessageForRelation(relation.to, relation);
            });

            this.updatePostBackRelations();
            return this;
        };

        /**
         * @param {Model.Message.Relation} relation
         */
        this.addRelation = function addRelation(relation) {
            Object.instanceOf(relation, Model.Message.Relation);
            if (relation.type === Model.Message.Relation.TYPE_FOLLOWED_BY) {
                for (var i in relations) {
                    if (((relations[i].options.uuid && relations[i].options.uuid === relation.options.uuid) ||
                        (relations[i].options.CTAuuid && relations[i].options.CTAuuid === relation.options.CTAuuid))
                        && relation.fromUuid === relations[i].fromUuid) {
                        if (relation.toUuid) {
                            var targetMsg   = this.findMessageInCollections(relation.toUuid);

                            if (!targetMsg) {
                                return;
                            }


                            if (!contentUpdating) {
                                var actions = targetMsg.getAllActions();
                                if (!actions.length) {
                                    targetMsg.removeFromCollections();
                                    return;
                                }

                                if (actions.length === 1 && !actions.pop().value) {
                                    targetMsg.removeFromCollections();
                                    return;
                                }
                            }
                        }
                        return;
                    }
                }
            }

            relations[relation.uuid] = relation;
            updateRelationsCache();

            if (relation.from === this) {
                this.updatePostBackContent();
            } else if (relation.from && relation.to === this) {
                relation.from.updatePostBackContent();
            }

            if (relation.to === this) {
                this.updateRootDistance();
                this.resetPlaceholderCache();
            } else if (relation.from === this && relation.to) {
                relation.to.updateRootDistance();
            }

            this.addRelationWithCollections(relation);
        };

        /**
         * @function
         * @name Model.Message#updateRootDistance
         */
        this.updateRootDistance = function updateRootDistance() {
            if (this.isRoot) {
                return;
            }

            var relationsTo = this.getRelationsTo(),
                relationFromMinParent
            ;

            if (!relationsTo.length) {
                setRootDistance(null);
                return;
            }

            // find the parent with the smallest rootDistance
            relationFromMinParent = relationsTo.reduce(function(prevRel, currRel) {
                if (currRel.from
                    && currRel.from.rootDistance
                    && (!prevRel || (currRel.from.rootDistance < prevRel.from.rootDistance))) {
                    return currRel;
                }
                return prevRel;
            }, null);

            if (!relationFromMinParent) {
                setRootDistance(null);
                return;
            }

            setRootDistance(relationFromMinParent.from.rootDistance + relationFromMinParent.distanceCost);
        };

        /**
         * @param {Model.Message.Relation} relation
         * @param {Boolean=} followRelation
         */
        this.removeRelation = function removeRelation(relation, followRelation) {
            Object.instanceOf(relation, Model.Message.Relation);
            // check if any relation left to non-orphan messages!
            if (relations[relation.uuid]) {
                delete(relations[relation.uuid]);
                updateRelationsCache();
                relation.removeMessage(this, followRelation);
                this.removeRelationWithCollections(relation);
                this.updatePostBackContent();
            }

            this.updateRootDistance();
            this.resetPlaceholderCache();
        };

        /**
         * @param {Boolean=}followRelation
         */
        this.removeRelations = function removeRelations(followRelation) {
            for (var i in relations) {
                relations[i].removeMessage(this, followRelation);
            }

            this.updatePostBackContent();
            this.updateRootDistance();
            this.resetPlaceholderCache();
        };

        /**
         * Creates a new messagePart
         * @param {String} type
         * @returns {Model.Message.Part}
         */
        this.createPart = function createPart(type) {
            return Model.Message.Part.create(type);
        };

        /**
         * @returns {String}
         */
        this.getMessageDescription = function getMessageDescription() {
            if(!this.isIncoming) {
                return this.getPreview();
            }

            var firstPart = this.firstPart();

            if (!firstPart || !firstPart.messageContents.length) {
                return DEFAULT_MESSAGE_LABEL;
            }

            if ([Model.Message.Part.TYPE_AI_REACTION, Model.Message.Part.AI_TEMPLATE].indexOf(firstPart.type) === -1) {
                var relationTo = this.getRelationsTo().shift(),
                    cta
                ;

                if (!relationTo || !relationTo.from) {
                    return DEFAULT_MESSAGE_LABEL;
                }

                cta = relationTo.associatedCTA;

                return cta.label;
            }

            return firstPart.messageContents[0].intent || 'AI template';
        };

        this.removePart = function removePart(part) {
            var actions;

            if (part.isInteractive) {
                actions = part.getAllMessagePostbackActions();
                for (var i = 0; i < actions.length; i++) {
                    for (var j in this.relations) {
                        if (this.relations[j].options.uuid !== actions[i].action.uuid && this.relations[j].options.CTAuuid !== actions[i].cta.uuid) {
                            continue;
                        }
                        // remove relations bound to this part
                        $.event.trigger({
                            type: "relation-removed",
                            message: this.relations[j].uuid,
                            time: new Date()
                        }, this.relations[j]);
                        this.removeRelation(this.relations[j], true);
                    }
                }
            }

            if (parts[part.uuid]) {
                delete(parts[part.uuid]);
                updatePartsCache();
            }
            this.orderParts();

            if (this.isIncoming && !this.partCount) {
                this.removeFromCollections();
            }

            this.updatePostBackRelations();
        };

        this.addCollection = function addCollection(val) {
            if (collections.indexOf(val) !== -1) {
                return;
            }

            if (!isRoot && !val.length) {
                isRoot = true;
                this.counter = 1;
            } else if (isRoot) {
                val.messages.map(function(element) {
                    if (element.isRoot && element.uuid !== self.uuid) {
                        throw "Can't have multiple roots on a collection";
                    }
                });
            }

            // add counter
            if (!this.counter && this.needsCounter()) {
                this.counter = (val.getCounterMax() + 1);
            }
            collections.push(val);
            val.appendMessage(this);
        };

        this.removeCollection = function removeCollection(val) {
            var pos;
            if ((pos = collections.indexOf(val) === -1)) {
                return;
            }

            collections.splice(pos, 1);

            if (!collections.length && isRoot) {
                isRoot = false;
            }
        };

        this.createMessageInCollections = function createMessageInCollections() {
            var newMsg = new Message();
            for (var i = 0; i < collections.length; i++) {
                collections[i].appendMessage(newMsg);
            }

            return newMsg;
        };

        /**
         *
         * @param uuid
         * @returns {Model.Message|null}
         */
        this.findMessageInCollections = function findMessageInCollections(uuid) {
            var msg = null;

            for (var i = 0; i < collections.length; i++) {
                if ((msg = collections[i].getMessageByUuid(uuid))) {
                    return msg;
                }
            }
            return msg;
        };

        this.removeFromCollections = function removeFromCollections() {
            for (var i = 0; i < collections.length; i++) {
                collections[i].removeMessage(this);
            }
        };

        this.removeRelationWithCollections = function removeRelationWithCollections(relation) {
            Object.instanceOf(relation, Model.Message.Relation);

            $.event.trigger('remove-relation', relation);
            if (relation.isNew) {
                return;
            }

            for (var i = 0; i < collections.length; i++) {
                collections[i].registerRelationForDeletion(relation);
            }
        };

        this.addRelationWithCollections = function addRelationWithCollections(relation) {
            Object.instanceOf(relation, Model.Message.Relation);

            if (relation.isNew) {
                return;
            }
            for (var i = 0; i < collections.length; i++) {
                collections[i].unRegisterRelationFromDeletion(relation);
            }
        };

        /**
         * @returns {Model.Message.Anchor|null}
         */
        this.findMessageAnchor = function findMessageAnchor() {
            var anchor = null;

            for (var i = 0; i < collections.length; i++) {
                if ((anchor = collections[i].getAnchor())) {
                    return anchor;
                }
            }
            return anchor;
        };

        /**
         * @function
         * @param type
         * @name Model.Message#createAndAddPart
         * @returns {Model.Message.Part}
         */
        this.createAndAddPart = function createAndAddPart(type) {
            var part = this.createPart(type);
            this.addPart(part);

            return part;
        };


        Object.defineProperties(this, {
            messageParts: {
                enumerable: true,
                configurable: true,
                get: function () {
                    return partsCache;
                }
                /**
                 * @property
                 * @name Model.Message#messageParts
                 * @type {Object.<string, Model.Message.Part>}
                 */
            },
            parts: {
                enumerable: false,
                configurable: true,
                get: function () {
                    return self.messageParts;
                }
                /**
                 * @property
                 * @name Model.Message#parts
                 * @type {Object.<string, Model.Message.Part>}
                 */
            },
            messageAnchor: {
                enumerable: true,
                configurable: true,
                get: function () {
                    return messageAnchor;
                },
                set: function(anchor) {
                    if (anchor === messageAnchor) {
                        return;
                    }
                    if (anchor === null) {
                        messageAnchor.message = null;
                    } else {
                        Object.instanceOf(anchor, Model.Message.Anchor);
                    }

                    messageAnchor = anchor;

                    if (messageAnchor) {
                        messageAnchor.message = this;
                    }
                }
                /**
                 * @property
                 * @name Model.Message#messageAnchor
                 * @type {?Model.Message.Anchor}
                 */
            },
            type: {
                enumerable: true,
                get: function() {
                    return type;
                }
                /**
                 * @property {String}
                 * @name Model.Message#type
                 * @type {String}
                 */
            },
            processingType: {
                enumerable: true,
                writable: true
                /**
                 * @property {String}
                 * @name Model.Message#processingType
                 * @type {String}
                 */
            },
            isOpen: {
                get: function() {
                    return isOpen;
                }
                /**
                 * @property {Boolean}
                 * @name Model.Message#isOpen
                 * @type {Boolean}
                 */
            },
            messageRelations: {
                enumerable: true,
                configurable: true,
                get: function () {
                    return relationsCache;
                }
                /**
                 * @property {Object}
                 * @name Model.Message#messageRelations
                 * @type {Object.<string, Model.Message.Relation>}
                 */
            },
            relations : {
                enumerable  : false,
                get         : function() {
                    return self.messageRelations;
                }
                /**
                 * @property
                 * @name Model.Message#relations
                 * @type {Object.<string, Model.Message.Relation>}
                 */
            },
            counter: {
                get: function() {
                    if (!counter) {
                        counter = self.getMeta(KEY_COUNTER_META);
                    }

                    return counter;
                },
                set: function(val) {
                    if (val === counter && val === self.getMeta(KEY_COUNTER_META)) {
                        return;
                    }
                    counter = val;
                    self.setMeta(KEY_COUNTER_META, counter);
                }
                /**
                 * Auto increment number
                 * @property {Number}
                 * @name Model.Message#counter
                 */
            },
            preview: {
                get: Message.prototype.getPreview
                /**
                 * Returns a sort preview for the message
                 * @property
                 * @name Model.Message#preview
                 * @type {String}
                 */
            },
            partCount: {
                enumerable  : false,
                get         : function() {
                    return partCount;
                }
                /**
                 * @property
                 * @name Model.Message#partCount
                 * @type {Number}
                 */
            },
            isOrphan: {
                get: function() {
                    return self.rootDistance === null;
                }
                /**
                 * @property
                 * @name Model.Message#isOrphan
                 * @type {Boolean}
                 */
            },
            rootDistance: {
                get: function() {
                    if (self.isRoot) {
                        // start at 1 for preventing comparison mistakes
                        return 1;
                    }
                    return rootDistance;
                }
                /**
                 * @property
                 * @name Model.Message#rootDistance
                 * @type {?int}
                 */
            },
            isIncoming: {
                enumerable: true,
                get: function() {
                    return Number(type === Const.Incoming);
                }
                /**
                 * @property
                 * @name Model.Message#isIncoming
                 * @type {Boolean}
                 */
            },
            metaInformation: {
                enumerable: true,
                get: function () {
                    return this.getMetasJson();
                }
                /**
                 * @property
                 * @name Model.Message#metaInformation
                 * @type {Object.<string,*>}
                 */
            },
            isRoot: {
                get: function() {
                    return isRoot;
                }
                /**
                 * @property
                 * @name Model.Message#isRoot
                 * @type {Boolean}
                 */
            },
            collections: {
                get: function() {
                    return collections.slice(0);
                }
                /**
                 * @property
                 * @name Model.Message#collections
                 * @type {Model.Message.Collection[]}
                 */
            },
            cachedContext: {
                value       : cachedContext,
                enumerable  : false
                /**
                 * @property
                 * @name Model.Message#cachedContext
                 * @type {?{content, action, cta}}
                 */
            },

            /**
             * fields that are set by some lib but should not be part of the json
             */
            px: {
                writable: true
            },
            py: {
                writable: true
            },
            index: {
                writable: true
            },
            weight: {
                writable: true
            }
        });

        this.orderParts = function orderParts() {
            var tmp = [],
                i
                ;

            for (i in this.parts) {
                tmp.push(this.parts[i]);
            }

            tmp.sort(function(a,b) {
                return a.number - b.number;
            });

            parts = {};

            for (i = 0; i < tmp.length; i++) {
                tmp[i].number = i + 1;
                parts[tmp[i].uuid] = tmp[i];
            }

            partCount = tmp.length;
            updatePartsCache();
        };

        // set endpoint by static value
        this.endPoint = Message.endPoint;

        this.updatePostBackContent = function() {
            if (relationsUpdating) {
                needsContentUp = true;
                return;
            }

            if (contentUpdating) {
                return;
            }

            contentUpdating = true;
            Object.getPrototypeOf(this).updatePostBackContent.call(this);
            contentUpdating = false;

            if (needsRelationUp) {
                this.updatePostBackRelations();
            }
        };

        this.updatePostBackRelations = function() {
            if (contentUpdating) {
              needsRelationUp = true;
              return;
            }

            if (relationsUpdating) {
                return;
            }

            relationsUpdating = true;
            Object.getPrototypeOf(this).updatePostBackRelations.call(this);
            relationsUpdating = false;

            if (needsContentUp) {
                this.updatePostBackContent();
            }
        };

        this.resetPlaceholderCache = function resetPlaceholderCache() {
            placeholderCache = null;
        };

        /**
         * @return {Model.Source.Placeholder[]}
         */
        this.getContextSpecificPlaceholders = function getContextSpecificPlaceholders() {
            if (placeholderCache === null) {
                placeholderCache = Message.prototype.getContextSpecificPlaceholders.call(this);
            }

            return placeholderCache;
        };

        var __extendCloneParent = this.__extendClone;
        /**
         * @param {Model.Message} original
         */
        this.__extendClone = function(original) {
            __extendCloneParent.call(this, original);

            if (original.isIncoming) {
                this.setIncoming();
            }

            contentUpdating   = true;
            relationsUpdating = true;

            for (i in original.relations) {
                this.addRelation(original.relations[i].clone());
            }

            for (var i in original.parts) {
                this.addPart(original.parts[i].clone());
            }

            contentUpdating   = false;
            relationsUpdating = false;
            needsRelationUp   = false;
            needsContentUp    = false;

            // this is needed because the counter will be manipulated when messages gets added during clone
            this.counter = original.counter;

            this.activeGroup = original.activeGroup;
        };

        this.__dontCloneProperties = function() {
            return ['counter'];
        };

        /**
         * @property {Model.RESTAccessByUUID#prototype}
         * @name Model.Message#_pProto
         */
        var protecteds = Message._pProto.constructor.call(this, uuid);

        protecteds.registerRelationHandler(this.endPoint, function(data) {
            if (!data.messageRelations) {
                return;
            }

            var relationEndPoint = new Model.Message.Relation().endPoint;

            return data.messageRelations.map(function(relationData) {
                var obj = {};
                obj.uuid = relationData.uuid;
                obj.endPoint = relationEndPoint;
                return obj;
            });
        });

        // TODO: remove with IT-5459
        this.setAsNew = function() {
            protecteds.setAsNew(true);
        };


        this.updateByData = function updateByData(data) {
            contentUpdating     = true;
            relationsUpdating   = true;

            Object.getPrototypeOf(this).updateByData.call(this, data);

            contentUpdating     = false;
            relationsUpdating   = false;
            needsContentUp      = false;
            needsRelationUp     = false;

            return this;
        };

        function updateRelationsCache() {
            relationsCache = $.extend(true, {}, relations);
        }

        function updatePartsCache() {
            partsCache = $.extend(true, {}, parts);
        }

        // expose protected for inheritance
        if (this.constructor !== Message && this instanceof Message) {
            return protecteds;
        }

        /**
         * @function
         * @name Model.Message#delete
         */

        /**
         * @type {boolean}
         * @name Model.Message#isRoot
         */

        /**
         * @property
         * @type Model.Message.Anchor
         * @name Model.Message#messageAnchor
         */
    };

    Object.extendProto(Message, Model.RESTAccessByUUID);

    Message.prototype.getAttachments = function getAttachments() {
        var attachments = {};

        for (var i in this.parts) {
            $.extend(attachments, this.parts[i].getAttachments());
        }

        return attachments;
    };

    /**
     * Gets the formdata-representation of the message-object
     * @function Model.Message#getFormData
     * @param {FormData=} formData
     * @param name
     * @returns FormData
     */
    Message.prototype.getFormData = function getFormData(formData, name) {

        var attachments = this.getAttachments(),
            fData = Message._pProto.getFormData.call(this, formData);

        var msgData = this.getMessageData(),
            key;

        if (name && name instanceof Object) {
            name['dataName'] = KEY_MESSAGE_DATA_NAME;
            name = JSON.stringify(name).bin2hex();
        }

        fData.append(name ? name : KEY_MESSAGE_DATA_NAME, JSON.stringify(msgData));

        for (key in attachments) {
            /**
             * @function
             * @name FormData#append
             * @param {string} name
             * @param {Object} value
             * @param {String=""}
             * @return {void}
             */
            fData.append(key, attachments[key], attachments[key].name ? attachments[key].name : key);
        }

        return fData;
    };

    /**
     * @return {{messageAnchor: Model.Message.Anchor?, messageParts: Model.Message.Part[], metaInformation: Object, uuid: String, isIncoming: Boolean}}
     */
    Message.prototype.getMessageData = function getMessageData() {
        var metaClone = this.metaInformation.clone();

        metaClone = this.metaInformation.clone();

        return {
            'uuid': this.uuid,
            'isIncoming': this.isIncoming,
            'messageParts': this.messageParts,
            'messageAnchor': this.messageAnchor,
            'metaInformation': metaClone
        };
    };

    /**
     * @name Model.Message#getRelationsTo
     * @returns {Model.Message.Relation[]}
     */
    Message.prototype.getRelationsTo = function getRelationsTo() {
        var relatedTo = [];
        for (var i in this.relations) {
            if (this.relations[i].to && this.relations[i].to.uuid === this.uuid) {
                relatedTo.push(this.relations[i]);
            }
        }

        return relatedTo;
    };

    /**
     * @name Model.Message#getRelationsFrom
     * @returns {Model.Message.Relation[]}
     */
    Message.prototype.getRelationsFrom = function getRelationsFrom() {
        var relatedFrom = [];
        for (var i in this.relations) {
            if (this.relations[i].from && this.relations[i].from.uuid === this.uuid) {
                relatedFrom.push(this.relations[i]);
            }
        }

        return relatedFrom;
    };

    /**
     * Gets a message-part by it's uuid
     * @function Model.Message#getMessagePartByUuid
     * @param {string} uuid
     * @returns null|Model.Message.Part
     */
    Message.prototype.getMessagePartByUuid = function getMessagePartByUuid(uuid) {
        for (var i in this.messageParts) {
            if (i === uuid) {
                return this.messageParts[i];
            }
        }
        return null;
    };

    Message.prototype.filterOutAlreadyRelateds = function filterOutAlreadyRelateds(messages) {
        var tmp         = []
            ;

        if (this.isIncoming && messages.length === 1) {
            return tmp;
        }

        for (var i = 0; i  < messages.length; i++) {
            if ((!this.isIncoming && messages[i].uuid === this.uuid) || messages[i].isIncoming) {
                continue; // don't add self or intermediate messages
            }

            tmp.push(messages[i]);
        }

        return tmp;
    };

    /**
     * Returns a short preview for the message
     * @returns {string}
     */
    Message.prototype.getPreview = function getPreview() {
        var firstPart
            ;

        if (this.messageName) {
            return '#' + this.counter + ' ' + this.messageName;
        }

        if ((firstPart = this.firstPart())) {
            return '#' + this.counter + ' ' + firstPart.content.preview;
        }

        return '#' + this.counter + ' - without content';
    };

    /**
     * @name Model.Message#firstPart
     * @returns {Model.Message.Part|null}
     */
    Message.prototype.firstPart = function() {
        var partIds = Object.getOwnPropertyNames(this.parts);
        return partIds.length ? this.parts[partIds.shift()] : null;
    };

    /**
     * @name Model.Message#lastPart
     * @returns {Model.Message.Part|null}
     */
    Message.prototype.lastPart = function() {
        var partIds = Object.getOwnPropertyNames(this.parts);
        return partIds.length ? this.parts[partIds.pop()] : null;
    };

    /**
     * @name Model.Message#getPart
     * @param {Number} index
     * @returns Model.Message.Part
     */
    Message.prototype.getPart = function(index) {
        var partIds = Object.getOwnPropertyNames(this.parts);
        if (index < 0) {
            return null;
        }
        return partIds.length > index ? this.parts[partIds[index]] : null;
    };

    /**
     * @name Model.Message#partsLength
     * @returns {Number}
     */
    Message.prototype.partsLength = function partsLength() {
        return Object.getOwnPropertyNames(this.parts).length;
    };

    /**
     * @param followRelation Whether the other side of the relation should be cleaned as well
     * @name Model.Message#cleanRelations
     * @function
     */
    Message.prototype.cleanRelations = function cleanRelations(followRelation) {
        for (var i in this.relations) {
            if (!this.relations[i]) {
                continue;
            }
            this.relations[i].removeMessage(this, followRelation);
        }
    };

    /**
     * @name Model.Message#insertBeforePosition
     * @param {Model.Message.Part} partToInsert
     * @param {Number} position
     */
    Message.prototype.insertBeforePosition = function insertBeforePosition(partToInsert, position) {
        Object.instanceOf(partToInsert, Model.Message.Part);
        this.orderParts();

        partToInsert.number = position;

        var partsAfterPosition = [],
            i
            ;

        for (i in this.parts) {
            if (this.parts[i].number >= position && this.parts[i].uuid !== partToInsert.uuid) {
                partsAfterPosition.push(this.parts[i]);
            }
        }

        if (partsAfterPosition.length && partToInsert.isInteractive) {
            throw new Model.Exception.MultipleInteractiveParts(partToInsert, this);
        }

        for (i = 0; i < partsAfterPosition.length; i++) {
            partsAfterPosition[i].number++;
        }

        if (partToInsert.parentUUID !== this.uuid) {
            // if part comes from other message, detach from that and add it here
            if (partToInsert.parent) {
                partToInsert.parent.removePart(partToInsert);
            }
            this.addPart(partToInsert);
        }

        this.orderParts();
    };

    Message.prototype.removeParts = function() {
        for (var i in this.parts) {
            this.removePart(this.parts[i]);
        }
    };

    /**
     * Splits a message into two
     * @param {Model.Message.Part} part
     * @returns {Model.Message}
     */
    Message.prototype.splitMessage = function splitMessage(part) {

        var newMessage = this.createMessageInCollections(),
            position = part.number || this.lastPart().number
            ;

        // copy parts from position from the message into the new message
        for (var i in this.parts) {
            var oldPart = this.parts[i];
            if (oldPart.number >= position && oldPart.uuid !== part.uuid) {
                var oldPartClone = oldPart.clone();
                this.removePart(oldPart);

                    oldPartClone.number = null;
                    newMessage.addPart(oldPartClone);
                }
            }

        if (part.parentUUID !== this.uuid) {
            // if part comes from other message, detach from that and add it here
            if (part.parent) {
                part.parent.removePart(part);
            }
        }
        this.addPart(part);

        return newMessage;
    };

    /**
     * @name Model.Message#getOrCreateMessageRelationByOption
     * @param {Object} payload
     * @return {Model.Message.Relation|null}
     */
    Message.prototype.getOrCreateMessageRelationByOption = function(payload) {
        if (!payload) {
            throw 'Missing argument!';
        }

        // handle different cases by type
        if (payload instanceof Model.Message) {
            return fnHandlePayloadIsMessage(this, payload);
        }

        if (payload instanceof Model.CTA) {
            // non incoming are to be processed
            if (!this.isIncoming) {
                return fnHandlePayloadIsCTA(this, payload);
            }
            // incoming shall be reduced to postback action
            payload = payload.getPostbackAction();
        }

        if (payload instanceof Model.Action.Collection) {
            payload = payload.getPostbackAction();

            // so it seems that there was no postback action > we still might want to do something
            // if we are in an NLP template that doesn't have an outgoing connection
            if (!payload) {
                if (this.isIncoming) {
                    return this.getRelationsTo().filter(function(relation) {
                        return relation.type === Model.Message.Relation.TYPE_NLP;
                    }).pop();

                }
            }
        }

        if (payload instanceof Model.Action) {
            return fnHandlePayloadIsAction(this, payload);
        }
    };

    /**
     * @function Model.Message#repair
     */
    Message.prototype.repair = function repair() {
        var uuid        ,
            msgParts    = this.messageParts,
            self        = this
        ;

        this.getRelationsTo().map(function(relation) {
            if (!relation.from) {
                self.removeRelation(relation);
            }
        });

        for (uuid in msgParts) {
            if (msgParts.hasOwnProperty(uuid)) {
                msgParts[uuid].repair();
            }
        }
    };

    /**
     * Updates the message by data
     * @function Model.Message#updateByData
     * @param {object} messageData
     * @returns Model.Message
     */
    Message.prototype.updateByData = function updateByData(messageData) {
        var msgParts = [],
            self     = this,
            i
        ;

        if (messageData.isIncoming) {
            this.setIncoming();
        } else {
            this.setOutgoing();
        }

        /*
         * Handle Message-Parts
         */

        // fetchable messageParts
        if (messageData.messageParts) {
            $.each(messageData.messageParts, function(key, messagePartData) {
                // skip if no uuid is given
                if (!messagePartData.uuid) {
                    return true;
                }

                var messagePart = self.getMessagePartByUuid(messagePartData.uuid);
                // if not already there -> create
                if (!messagePart) {
                    messagePart = new Model.Message.Part(messagePartData.uuid);
                }
                messagePart.updateByData(messagePartData);

                msgParts.push(messagePart);
            });
        }

        // reset old parts
        self.parts = {};
        self.partCount = 0;
        var messagePartNumberSetters = [];

        // needs to be sorted
        msgParts.sort(function(partA, partB) {
            return partA.number - partB.number;
        }).map(
            /**
             * @param {Model.Message.Part} part
             */
            function (part) {
                part.setMeta('number', part.number, 'dataUpdate');
                self.addPart(part, true);
                return part;
            }
        ).map(
            /**
             * @param {Model.Message.Part} part
             */
            function (part) {
                var dataNumber = part.getMeta('number', null, 'dataUpdate');
                if (dataNumber !== part.number) {
                    messagePartNumberSetters.push(function(number) {
                        part.number = number;
                    }.bind(null, part.number));
                    part.number = dataNumber;
                }
                part.clearMetaNamespace('dataUpdate');
            }
        );

        /*
         * Handle Message-Anchor
         */

        // fetchable messageAnchor
        if (messageData.messageAnchor && messageData.messageAnchor.uuid) {

            if (!self.messageAnchor) {
                self.messageAnchor = Model.Message.Anchor.createByData(messageData.messageAnchor);
            }
            else {
                self.messageAnchor.updateByData(messageData.messageAnchor);
            }
        }
        else
        {
            self.messageAnchor = null;
        }

        this.clearMetaNamespace();
        if (messageData.metaInformation) {
            for (i in messageData.metaInformation) {
                self.setMeta(i, messageData.metaInformation[i]);
            }
        }

        var relationsFound = [];
        if (messageData.messageRelations) {
            for (i = 0; i < messageData.messageRelations.length; i++) {
                var relationData = messageData.messageRelations[i],
                    otherMsg,
                    otherMsgUuid,
                    relation = null
                ;

                if (relationData.messageFromUuid === this.uuid) {
                    otherMsgUuid = relationData.messageToUuid;
                } else if (relationData.messageToUuid === this.uuid) {
                    otherMsgUuid = relationData.messageFromUuid;
                }

                if ((otherMsg = this.findMessageInCollections(otherMsgUuid))) {
                    if (otherMsg.relations[relationData.uuid]) {
                        relation = otherMsg.relations[relationData.uuid];
                    }
                }

                if (!relation) {
                    relation = new Model.Message.Relation(relationData.uuid);
                    relation.updateByData(relationData);
                }
                relationsFound.push(relation);

                if (relationData.messageFromUuid === this.uuid) {
                    relation.from = this;
                    if (otherMsg) {
                        relation.to = otherMsg;
                    }
                } else if (relationData.messageToUuid === this.uuid) {
                    relation.to = this;
                    if (otherMsg) {
                        relation.from = otherMsg;
                    }
                }
            }

            // calc diff by uuid and remove what was not present in the data
            relationsFound.diff(this.getRelationsAsArray(), function(relation) {
                return relation.uuid;
            }).map(function (relation) {
                self.removeRelation(relation);
            });
        }

        Message._pProto.updateByData.call(this);

        if (!this.isIncoming) {
            this.getAllCTAs().map(function(cta) {
                // support for old format: relation is indexed by action uuid, retrieve, fix and return
                if (cta.getPostbackAction()
                    && (relation = self.getRelationByOptionValue(KEY_UUID, cta.getPostbackAction().uuid))
                    && relation.from.uuid === self.uuid) {
                    relation.options[KEY_CTA_UUID] = cta.uuid;
                    if (relation.options[KEY_UUID]) {
                        delete (relation.options[KEY_UUID]);
                    }
                }
            })
        }

        // creates a one-time override for setJSONState to properly set the part numbers as modified to be called later
        if (messagePartNumberSetters.length) {
            var originalSetJSONState = this.setJSONState;
            this.setJSONState = function() {
                originalSetJSONState.call(self);
                messagePartNumberSetters.map(function (messagePartNumberSetter) {
                    messagePartNumberSetter();
                });
                // revert back to normal
                self.setJSONState = originalSetJSONState;
            }
        }

        if (messageData.processingType) {
            self.processingType = messageData.processingType;
        }

        return this;
    };

    /**
     * @name Model.Message#getRelationsAsArray
     * @method
     * @returns {Model.Message.Relation[]}
     */
    Message.prototype.getRelationsAsArray = function getRelationsAsArray() {
        var relations = [];
        for (var i in this.messageRelations) {
            relations.push(this.messageRelations[i]);
        }

        return relations;
    };

    /**
     * @param {Boolean=} ignoreRelations Ignore relations when checking emptiness
     * @returns {Boolean}
     */
    Message.prototype.isEmpty = function isEmpty(ignoreRelations) {
        var firstPart = this.firstPart();

        if (this.isIncoming
            && (this.isForwardAction() || (firstPart && firstPart.type === Const.DynamicDispatcher))
        ) {
            return this.hasNoRelations();
        }

        return this.hasNoContent() && this.hasNoRelations();
    };

    Message.prototype.hasNoContent = function hasNoContent() {
        return !Object.getOwnPropertyNames(this.messageParts).length;
    };

    Message.prototype.hasNoRelations = function hasNoContent() {
        return !Object.getOwnPropertyNames(this.relations).length;
    };

    /**
     * @name Model.Message#canHaveNLPFallback
     * @param {boolean=} allowWithoutNLP Allows fallback without any NLP template set
     * @returns {boolean}
     */
    Message.prototype.canHaveNLPFallback = function canHaveNLPFallback(allowWithoutNLP) {
        var numberOfNLP = 0,
            hasFallbackAlready = false
            ;

        for (var i in this.relations) {
            if (this.relations[i].fromUuid !== this.uuid) {
                continue;
            }

            if (this.relations[i].type === Model.Message.Relation.TYPE_NLP) {
                if (this.relations[i].to.firstPart().isFallback) {
                    hasFallbackAlready = true;
                } else {
                    numberOfNLP++;
                }
            }
        }

        return !hasFallbackAlready && (numberOfNLP > 0 || Boolean(allowWithoutNLP));
    };

    /**
     * Check all outgoing messages
     * CTAs > check if there is a relation without a CTA for outgoing messages
     * if so > remove the relation
     * Check if there is a CTA without relation > add the relation
     */
    Message.prototype.updateOutgoingRelations = function updateOutgoingRelations() {
        if (this.isIncoming) {
            return this.updatePostBackRelations();
        }

        if (!this.counter) {
            return;
        }


        var relationsFromByType     = this.getRelationsFrom().filter(function(relation) {
                return relation.type === Model.Message.Relation.TYPE_FOLLOWED_BY
            })                      ,
            relationsFromByCTAUuid  = relationsFromByType.map(function(relation) {
                return relation.options[KEY_CTA_UUID];
            })                      ,
            ctas                    = this.getAllCTAs(),
            ctasByUuid              = ctas.map(function(cta) {
                return cta.uuid
            })                      ,
            self                    = this,
            relation                ,
            pos
        ;

        // go through all relations look for missing CTAs
        relationsFromByCTAUuid.map(function(CTAUuid, index) {
            if (ctasByUuid.indexOf(CTAUuid) === -1) {
                // relation not found
                self.removeRelation(relationsFromByType[index], true);
            }
        });

        ctasByUuid.map(function(CTAUuid) {
            if ((pos = relationsFromByCTAUuid.indexOf(CTAUuid)) === -1) {
                relation                        = new Model.Message.Relation();
                relation.type                   = Model.Message.Relation.TYPE_FOLLOWED_BY;
                relation.from                   = self;
                relation.options[KEY_CTA_UUID]  = CTAUuid;
                $.event.trigger('create-relation', relation);
            } else {
                relation = relationsFromByType[pos];
            }

            if (!relation.to) {
                relation.to = Model.Message.createMessageForRelation(relation);
            }

            setAsIntermediateMessageForRelation(relation.to, relation);

            relation.to.updatePostBackRelations();
        });
    };

    /**
     * Checks all relations and adds missing relations defined in content or removes existing relations missing content counterpart
     */
    Message.prototype.updatePostBackRelations = function updatePostBackRelations() {
        if (!this.isIncoming) {
            return this.updateOutgoingRelations();
        }

        var relationsFrom               = this.getRelationsFrom(),
            lastPart                    = this.lastPart(),
            firstPart                   = this.firstPart(),
            actions                     = lastPart ? lastPart.getAllMessagePostbackActions() : [],
            relationsFromByType         = relationsFrom
                .filter(function(rel){
                    return (rel.type === Model.Message.Relation.TYPE_FOLLOWED_BY); }),
            relationsFromByActionUuid   = relationsFromByType.map(function(rel) { return rel.options.uuid }),
            actionsByUuid               = actions.map(function(action) { return action.action.uuid }),
            relation,
            pos                         = -1,
            i
            ;


        /**
         * if Action type of "dynamic action?" then add a new message that is
         * returned by the API (caching is needed!!!)
         */

        // go through all relations look for missing actions
        for (i = 0; i < relationsFromByActionUuid.length; i++) {
            if ((pos = actionsByUuid.indexOf(relationsFromByActionUuid[i])) === -1) {
                // relation not found
                this.removeRelation(relationsFromByType[i], true);
            }
        }
        // go through all action look for missing relations
        for (i = 0; i < actionsByUuid.length; i++) {
            if ((pos = relationsFromByActionUuid.indexOf(actionsByUuid[i])) === -1) {
                // we need a new relation only if we can set the target, don't create relations that point nowhere
                if (!actions[i].action.value) {
                    continue;
                }

                relation                    = new Model.Message.Relation();
                relation.type               = Model.Message.Relation.TYPE_FOLLOWED_BY;
                relation.from               = this;
                relation.options[KEY_UUID]  = actions[i].action.uuid;
                $.event.trigger('create-relation', relation);
            } else {
                relation = relationsFromByType[pos];
                if (!relation.to) {
                    this.removeRelation(relation);
                    continue;
                }
            }

            relation.options.uuid = actions[i].action.uuid;
            // direct connection
            relation.to = this.findMessageInCollections(actions[i].action.value);
        }

        this.getRelationsTo().map(function(relation) {
            relation.resetAssociatedCTACache();
            var cta = relation.from.getCTAByUuid(relation.options[KEY_CTA_UUID]);
            if (!cta) {
                return;
            }
            firstPart.updateActionsMeta(cta.actions.actions);
        });
    };

    Message.prototype.updateOutgoingContent = function updateOutgoingContent() {
        if (this.isIncoming) {
            return this.updatePostBackContent();
        }

        if (!this.counter) {
            return;
        }

        var self = this;

        this.getAllCTAs().map(function(cta) {

            var relation;

            if (!(relation = self.getRelationByOption(cta)) || !relation.to) {
                if (!relation) {
                    var postbackAction = cta.getPostbackAction();
                    if (postbackAction) {
                        postbackAction.value = null;
                    }
                }
                return;
            }

            relation.to.updatePostBackContent();

            var postbackActions;

            if (!cta.getPostbackAction()
                || !relation.to.lastPart()
                || !(postbackActions = relation.to.lastPart().getAllMessagePostbackActions())
                || postbackActions.length !== 1) {
                return;
            }

            cta.getPostbackAction().value = postbackActions.shift().action.value;
        });
    };

    /**
     * Updates the message content with data retrievable from the relations
     */

    Message.prototype.updatePostBackContent = function updatePostBackContent() {
        if (!this.isIncoming) {
            return this.updateOutgoingContent();
        }

        var relationsFrom               = this.getRelationsFrom(),
            lastPart                    = this.lastPart(),
            actions                     = lastPart ? lastPart.getAllMessagePostbackActions() : [],
            relationsFromByType         = relationsFrom
                .filter(function(rel){
                    return (rel.type === Model.Message.Relation.TYPE_FOLLOWED_BY);
                })                      ,
            relationsFromByActionUuid   = relationsFromByType.map(function(rel) { return rel.options.uuid }),
            actionsByUuid               = actions.map(function(action) { return action.action.uuid }),
            pos                         = -1,
            i
        ;

        // go through all relations look for matching actions
        for (i = 0; i < relationsFromByActionUuid.length; i++) {
            if ((pos = actionsByUuid.indexOf(relationsFromByActionUuid[i])) !== -1 && relationsFrom[i].to) {
                // relation has an action pair
                actions[pos].action.value = relationsFromByType[i].toUuid;
            }
        }
    };

    /**
     * @name Model.Message#createAndSetMessageAnchor
     * @return Model.Message
     */
    Message.prototype.createAndSetMessageAnchor = function createAndSetMessageAnchor(domain) {
        var msgAnchor   = Model.Message.Anchor.createByData({title: 'Untitled', type: {label: 'Conversation', alias: TYPE_ALIAS_CONVERSATION}})
            ;

        msgAnchor.domainId = domain;
        this.messageAnchor = msgAnchor;

        return this;
    };

    /**
     * @name Model.Message#getCTAByUuid
     * @param {String} uuidToMatch
     * @returns {Model.CTA|null}
     */
    Message.prototype.getCTAByUuid = function getCTAByUuid(uuidToMatch) {
        var i,
            found;

        for (i in this.parts) {
            if ((found = this.parts[i].getAllCTAs().filter(function(cta) {
                return cta.uuid === uuidToMatch;
            }).shift())) {
                return found;
            }
        }

        return null;
    };

    /**
     * Returns all CTAs from all message parts
     * @return {Model.CTA[]}
     */
    Message.prototype.getAllCTAs = function getAllCTAs() {
        var ctas = [];
        for (var i in this.parts) {
            ctas.push.apply(ctas, this.parts[i].getAllCTAs());
        }

        return ctas;
    };

    /**
     * Returns all Actions from all message parts
     * @return {Model.Action[]}
     */
    Message.prototype.getAllActions = function getAllActions() {
        var actions = [];
        for (var i in this.parts) {
            actions.push.apply(actions, this.parts[i].getAllActions());
        }

        return actions.map(function (actionWithContext) {
            return actionWithContext.action;
        });
    };

    /**
     * @name Model.Message#getCTAByActionUuid
     * @param uuid
     * @returns {*}
     */
    Message.prototype.getCTAByActionUuid = function getCTAByActionUuid(uuid) {
        var context = this.getContextByActionUuid(uuid);

        if (!context || !context.cta) {
            return null;
        }

        return context.cta;
    };

    /**
     * @param uuid
     * @returns {?{content, action, cta}}
     */
    Message.prototype.getContextByActionUuid = function getContextByActionUuid(uuid) {
        var context = [],
            i
        ;

        // adding cache to a frequently called function
        if (this.cachedContext && this.cachedContext.action && uuid === this.cachedContext.action.uuid) {
            return this.cachedContext;
        }

        for (i in this.parts) {
            context.push.apply(context, this.parts[i].getAllMessagePostbackActions());
        }

        for (i = 0; i < context.length; i++) {
            if (context[i].action && context[i].action.uuid === uuid) {
                this.cachedContext = context[i];
                return context[i];
            }
        }

        return null;
    };

    /**
     * @function
     * @name Model.Message#containsAITemplate
     * @return {boolean}
     */
    Message.prototype.containsAITemplate = function containsAITemplate() {
        for (var i in this.messageParts) {
            if (this.messageParts[i].type === Model.Message.Part.TYPE_AI_TEMPLATE) {
                return true;
            }
        }
        return false;
    };

    /**
     * @name Model.Message#isForwardAction
     * @returns {boolean}
     */
    Message.prototype.isForwardAction = function isForwardAction() {
        var firstPart;
        if (!this.isIncoming || !(firstPart = this.firstPart())) {
            return false;
        }

        return firstPart.type === Const.ForwardAction;
    };

    Message.prototype.needsCounter = function() {
        return this.isRoot || !this.isIncoming;
    };

    /**
     * @param {String} key
     * @param {*} element
     * @returns {*}
     */
    Message.prototype.mapFn = function mapFn(key, element) {
        if (this instanceof Model.Message && key === 'metaInformation' && element instanceof Object) {
            var clone = element.clone();
            delete(clone.analytics);

            if (!clone.fixed) {
                var obj = {};
                obj[KEY_COUNTER_META]   = clone[KEY_COUNTER_META];
                obj[KEY_PRIORITY]       = clone[KEY_PRIORITY];
                obj[KEY_MESSAGE_NAME]   = clone[KEY_MESSAGE_NAME];
                obj[KEY_MARKER_UUID]    = clone[KEY_MARKER_UUID];
                return obj;
            }

            return clone;
        }
        return Message._pProto.mapFn.apply(this, arguments);
    };

    /**
     * @param {Model.CTA|Model.Action} optionObj
     * @return {Model.Message.Relation}
     */
    Message.prototype.getRelationByOption = function getRelationByOption(optionObj) {
        if (this.isIncoming) {
            Object.instanceOf(optionObj, Model.Action);

            return this.getRelationByOptionValue(KEY_UUID, optionObj.uuid);
        }

        Object.instanceOf(optionObj, Model.CTA);

        return this.getRelationByOptionValue(KEY_CTA_UUID, optionObj.uuid);
    };

    /**
     * @return {Model.Source.Placeholder[]}
     */
    Message.prototype.getContextSpecificPlaceholders = function getContextSpecificPlaceholders() {
        var relationsTo,
            relationTo,
            foundPlaceholders = []
        ;

        if ((relationsTo = this.getRelationsTo()).length !== 1) {
            return foundPlaceholders;
        }

        relationTo = relationsTo.pop();

        var cta,
            actionKey
        ;

        if (!(cta = relationTo.from.getCTAByActionUuid(relationTo.options.uuid))) {
            return foundPlaceholders;
        }

        actionKey = cta.getMeta(Model.Message.KEY_DYNAMIC_ACTION_KEY, []);

        // map the key as array into type and id
        var type    = actionKey[0],
            id      = actionKey[1]
        ;

        var actionDefinition = Model.Action.Definition.all.findByName(type);

        if (!actionDefinition) {
            return foundPlaceholders;
        }

        return actionDefinition.templates.reduce(
            /**
             * @param {Array} carry
             * @param {Model.Message.Template} template
             * @return {Model.Source.Placeholder[]}
             */
            function(carry, template) {
                if (template.id !== id) {
                    return carry;
                }

                carry.push.apply(carry, template.placeholders);

                return carry;
            }, foundPlaceholders);
    };

    /**
     * @param {Model.Source.Placeholder[]} placeholders
     */
    Message.prototype.resolvePlaceholderAttachments = function resolvePlaceholderAttachments(placeholders) {
        for (var i in this.parts) {
            this.parts[i].messageContents.map(function(content) {
                content.resolvePlaceholderMediaIfAny(placeholders);
            });
        }
    };

    /**
     * @return {boolean}
     */
    Message.prototype.isDynamicContentMessage = function isDynamicContentMessage() {
        var relationsTo = this.getRelationsTo(),
            fromMessage
        ;

        if (!relationsTo.length || !(fromMessage = relationsTo.pop().from) || !fromMessage.firstPart()) {
            return false;
        }

        return fromMessage.firstPart().type === Const.DynamicDispatcher;
    };

    /**
     * @return {$.Deferred<{}>}
     */
    Message.prototype.save = function save() {
        return Message._pProto.save.call(this).then(function (response) {
            if (response && response.messageAnchor && this.messageAnchor) {
                this.messageAnchor.updateByData(response.messageAnchor);
            }
            return response;
        }.bind(this)
        );
    };

    /**
     * @return {null|Array}
     */
    Message.prototype.extractPathAsRootMessage = function extractPathAsRootMessage() {
        /**
         * @param {Model.Message} startMsg
         * @param {Array} path
         */
        function walkThroughFromRelations(startMsg, path) {
            // stop following if
            //  - goes trough root message
            //  - goes trough walked message
            if (startMsg.isRoot || path.indexOf(startMsg) !== -1 || (startMsg.isDynamicContentMessage() && path.length === 0)) {
                return;
            }
            path.push(startMsg);
            // follow every from relation
            startMsg.getRelationsFrom().map(function (relation) {
                if (relation.to.isIncoming) {
                    return walkThroughFromRelations(relation.to, path);
                }

                if (path.indexOf(relation.to) !== -1) {
                    return path;
                }

                var rootCandidate = path.slice(0,1).shift();

                // deny if
                //- goes trough a message with lower or equal rank but not start msg and not root
                if (relation.to.rootDistance <= rootCandidate.rootDistance && !relation.to.isRoot && relation.to !== rootCandidate) {
                    throw 'Goes through outside element!';
                }

                // - destination has relation from a message with lower or same rank but not start msg
                relation.to.getRelationsTo().map(function (relationFrom) {
                    if (relationFrom.from.rootDistance <= rootCandidate.rootDistance && relationFrom.from !== rootCandidate) {
                        throw 'Has incoming link from outside!';
                    }
                });

                walkThroughFromRelations(relation.to, path);
            });

            return path;
        }


        try {
            var path = walkThroughFromRelations(this, []);
            // if not already denied then we have followed all the messages that can be reached from our start,
            // now lets go trough all relations that lead to these messages and filter out those that have a source not included
            path.map(function (message, index) {
                // rootCandidate can have outer links
                if (index === 0) {
                    return ;
                }
                message.getRelationsTo().map(function(relationTo) {
                    if (path.indexOf(relationTo.from) === -1) {
                        throw "Has incoming link from outside2!";
                    }
                })
            });
        } catch (exception) {
            return null;
        }
        return path;
    };

    /**
     * @param {$.Deferred<Model.Message.Anchor>} messageAnchorDeferred
     * @param {Model.Message[]=} messages Optional filter to limit the change from certain messages
     */
    Message.prototype.exchangeIncomingTriggerMessageWithTriggerConversation = function exchangeIncomingTriggerMessageWithTriggerConversation(messageAnchorDeferred, messages) {
        var self = this;
        // check that the target is not incoming and in a published convo
        if (this.isIncoming) {
            return;
        }

        // get all incoming relations and follow to the source message if it is forwarded intermediate type
        var sourceMessages = this.getRelationsTo().reduce(function (carry, relation) {
                var intermediate = relation.from;
                if (intermediate.isForwardAction()) {
                    carry.push(intermediate.getRelationsTo().shift().from);
                }

                if (intermediate.containsAITemplate()) {
                    carry.push(intermediate);
                }

                return carry;
            }, []);

        sourceMessages = sourceMessages.unique();

        // filter by the optional messages
        if (messages && messages.length) {
            sourceMessages = sourceMessages.filter(function (message) {
                return messages.indexOf(message) !== -1;
            });
        }

        var actions = [];
        // iterate through messages and exchange the trigger message type to trigger convo and the value to the anchor uuid
        sourceMessages.map(function (message) {
            message.getAllActions().map(function (action) {
                if (action.type !== Model.Action.TYPE_POSTBACK_MESSAGE || action.value !== self.uuid) {
                    return;
                }
                action.type  = Model.Action.TYPE_POSTBACK_STORY;
                action.value = null;

                actions.push(action);
            });
            message.updateOutgoingRelations();
        });

        // resolve
        messageAnchorDeferred.then(function (messageAnchor) {
            actions.map(function (action) {
                action.value = messageAnchor.message.uuid;
            })
        });
    };

    Message.prototype.getOriginatingDynamicContentAction = function getOriginatingDynamicContentAction() {
        if (!this.isDynamicContentMessage()) {
            return null;
        }

        var relationToFollow = this.getRelationsTo().pop();

        // get the CTA that associated to the "Results" path
        var matchingCTAs = relationToFollow.from.getAllCTAs().filter(function (cta) {
            var meta = cta.getMeta(KEY_DYNAMIC_ACTION_KEY, []);
            if (!meta.length || meta.length < 2) {
                return false
            }

            var definition = Model.Action.Definition.all.findByName(meta[0]);
            return definition.templates.filter(function (template) {
                return template.id === meta[1] && template.label === "Results";
            }).length;
        });

        var meta = relationToFollow.associatedCTA.getMeta(KEY_DYNAMIC_ACTION_KEY);

        // check that the relation has that CTA associated
        if (matchingCTAs.indexOf(relationToFollow.associatedCTA) === -1) {
            return null;
        }

        if (!relationToFollow.from || !relationToFollow.from.getRelationsTo().length) {
            return null;
        }

        relationToFollow = relationToFollow.from.getRelationsTo().pop();

        if (!relationToFollow.associatedCTA) {
            return null;
        }

        var originatingAction = relationToFollow.associatedCTA.getAllActions().filter(function (action) {
            return action.type === meta[0];
        }).pop();
        
        return originatingAction;
    }

    /**
     * This will create a message and add the Forward contents if needed
     * @static
     * @name Model.Message.createMessageForRelation
     * @param {Model.Message.Relation} relation
     * @returns {Model.Message}
     */
    Message.createMessageForRelation = function createMessageForRelation(relation) {
        Object.instanceOf(relation, Model.Message.Relation);
        if (!relation.from) {
            throw 'A source is required!';
        }

        var msg = relation.from;

        var newMsg = msg.createMessageInCollections();

        if (msg.isIncoming) {
            return newMsg;
        }

        newMsg.setIncoming();
        setAsIntermediateMessageForRelation(newMsg, relation);

        return newMsg;
    };

    /**
     * Creates a message-object by data
     * @function Model.Message#createMessageByData
     * @param {object} messageData
     * @static
     * @returns Model.Message
     */
    Message.createMessageByData = function createMessageByData(messageData) {

        var message;

        // throw error if uuid is not provided
        if (!messageData.uuid) {
            // TODO IT-4732: exception handling
            console.log(messageData);
            throw "No uuid given";
        }

        message = new Message(messageData.uuid);
        message.updateByData(messageData);

        return message;
    };

    // private methods
    var
        /**
         * Handles relation between outgoing and NLP message
         * @private
         * @method
         * @name Model.Message~fnHandlePayloadIsMessage
         * @param {Model.Message} msg
         * @param {Model.Message} fromMsg
         * @return {Model.Message.Relation} relation
         */
        fnHandlePayloadIsMessage = function fnHandlePayloadIsMessage(msg, fromMsg) {
            var nlpRelations    = fromMsg.getRelationsByType(Model.Message.Relation.TYPE_NLP),
                i
            ;

            for (i = 0; i < nlpRelations.length; i++) {
                if (nlpRelations[i].from.uuid === fromMsg.uuid && msg.uuid === nlpRelations[i].to.uuid) {
                    return nlpRelations[i];
                }
            }

            var relation = new Model.Message.Relation();
            relation.type = Model.Message.Relation.TYPE_NLP;
            relation.to = msg;
            relation.from = fromMsg;

            return relation;
        },
        /**
         * Handles relations between outgoing message and intermediate message
         * @private
         * @method
         * @name Model.Message~fnHandlePayloadIsCTA
         * @param {Model.Message} msg
         * @param {Model.CTA} cta
         * @return {Model.Message.Relation}
         */
        fnHandlePayloadIsCTA = function fnHandlePayloadIsCTA(msg, cta) {
            var relation;

            // found by the CTA uuid, cleanest exit
            if ((relation = msg.getRelationByOptionValue(KEY_CTA_UUID, cta.uuid))) {
                return relation;
            }

            // support for old format: relation is indexed by action uuid, retrieve, fix and return
            if (cta.getPostbackAction()
                && (relation = msg.getRelationByOptionValue(KEY_UUID, cta.getPostbackAction().uuid))
                && relation.from.uuid === msg.uuid) {
                    relation.options[KEY_CTA_UUID] = cta.uuid;
                    if (relation.options[KEY_UUID]) {
                        delete (relation.options[KEY_UUID]);
                    }
                return relation;
            }

            relation = new Model.Message.Relation();

            relation                        = new Model.Message.Relation();
            relation.options[KEY_CTA_UUID]  = cta.uuid;
            relation.from                   = msg;
            relation.type                   = Model.Message.Relation.TYPE_FOLLOWED_BY;

            return relation;

        },
        /**
         * Handles postback message relations between intermediate message and outgoing message
         * @private
         * @method
         * @name Model.Message~fnHandlePayloadIsAction
         * @param {Model.Message} msg
         * @param {Model.Action} action
         * @return {Model.Message.Relation}
         */
        fnHandlePayloadIsAction = function fnHandlePayloadIsAction(msg, action) {
            var relation;

            if ((relation = msg.getRelationByOptionValue(KEY_UUID, action.uuid))) {
                return relation;
            }

            relation                    = new Model.Message.Relation();
            relation.from               = msg;
            relation.options[KEY_UUID]  = action.uuid;
            relation.type               = Model.Message.Relation.TYPE_FOLLOWED_BY;

            return relation;
        },
        /**
         * @private
         * @method
         * @name Model.Message~setAsIntermediateMessageForRelation
         * @param {Model.Message} msg
         * @param {Model.Message.Relation} relation
         */
        setAsIntermediateMessageForRelation = function setAsIntermediateMessageForRelation(msg, relation) {
            Object.instanceOf(relation, Model.Message.Relation);

            if (!msg.isIncoming) {
                return;
            }

            var cta,
                callbacks = [],
                callback
            ;

            if (!relation.options[KEY_CTA_UUID] || !(cta = relation.from.getCTAByUuid(relation.options[KEY_CTA_UUID]))) {
                return;
            }

            var typeToAdd       = Const.ForwardAction,
                // there should just be one template-action per cta allowed!
                templateAction  = cta.getAllActions().filter(function(action) {
                    if (!action.hasTemplate()) {
                        return false;
                    }

                    typeToAdd   = Const.DynamicDispatcher;
                    return true;
                }).shift(),
                ctaAction       = cta.getPostbackAction(),
                part            = msg.firstPart() || msg.createAndAddPart(typeToAdd)
            ;

            if (part.type !== typeToAdd) {
                part.type = typeToAdd;
            }

            var jsonState = JSON.stringify(msg.getData());
            switch (typeToAdd) {
                case Const.DynamicDispatcher:
                    part.content.body = cta.label;

                    part.updateActionsMeta(cta.getAllActions().filter(function(action) {
                        return action !== templateAction;
                    }));

                    templateAction.getTemplates().map(function(alternative) {

                        var templateId = [templateAction.type, alternative.id],
                            ctaWithSameDynamicActionKey = part.content.alternatives.ctas.filter(
                                function (ctaIterating) {
                                    return JSON.stringify(ctaIterating.getMeta(KEY_DYNAMIC_ACTION_KEY)) === JSON.stringify(templateId);
                                }
                            ).pop()
                        ;

                        if (ctaWithSameDynamicActionKey) {
                            // The CTA has already been set up, return works because of inside array.map
                            return;
                        }

                        var cta             = new Model.CTA(),
                            templateMessage = alternative.message,
                            newMsg          = msg.createMessageInCollections(),
                            action          = new Model.Action()
                        ;

                        cta.setMeta(KEY_DYNAMIC_ACTION_KEY, templateId);

                        callbacks.push(function() {
                            newMsg.setMeta(KEY_TEMPLATE_ID, templateMessage.uuid);
                        });

                        for (var i in templateMessage.parts) {
                            newMsg.addPart(templateMessage.parts[i].duplicate());
                        }

                        newMsg.resolvePlaceholderAttachments(alternative.placeholders);

                        // create connection to it
                        cta.label       = alternative.label;
                        action.type     = Const.PostbackMessage;
                        action.value    = newMsg.uuid;
                        cta.actions.addAction(action);

                        part.content.alternatives.addCTA(cta);
                    });

                    // also need to clean those ctas that are no longer needed
                    part.content.alternatives.ctas.filter(function (cta) {
                        var metaValue = cta.getMeta(KEY_DYNAMIC_ACTION_KEY);
                        return (!metaValue || metaValue.indexOf(templateAction.type) === -1);
                    }).map(function (cta) {
                        part.content.alternatives.removeCTA(cta);
                    });

                    break;
                case Const.ForwardAction:
                    part.content.body = cta.label;

                    part.updateActionsMeta(cta.getAllActions().filter(function(action) {
                        return action !== ctaAction;
                    }));

                    var action = part.content.actions.getPostbackAction() || new Model.Action();

                    if (ctaAction) {
                        action.type = ctaAction.type;
                        action.key = ctaAction.key;
                        // if there is a value set already, forward it so the target message can be retrieved
                        if (ctaAction.value || part.content.actions.length) {
                            action.value = ctaAction.value
                        }
                        part.content.actions.addAction(action);
                    } else if ((action = part.content.actions.getPostbackAction())) {
                        part.content.actions.removeAction(action);
                    }
                    break;
            }

            part.resetCache();

            if (jsonState !== JSON.stringify(msg.getData())) {
                msg.updatePostBackRelations();
            }

            while ((callback = callbacks.pop())) {
                callback();
            }
        }
    ;

    // private methods end

    ns.Message = Message;

    Object.defineProperties(
        Message,
        {
            KEY_CTA_UUID: {
                value: KEY_CTA_UUID
                /**
                 * @property
                 * @constant
                 * @name Model.Message#KEY_CTA_UUID
                 * @type {String}
                 */
            },
            KEY_DYNAMIC_ACTION_KEY: {
                value: KEY_DYNAMIC_ACTION_KEY
                /**
                 * @property
                 * @constant
                 * @name Model.Message#KEY_DYNAMIC_ACTION_KEY
                 * @type {String}
                 */
            },
            KEY_ANALYTICS_META: {
                value: KEY_ANALYTICS_META
                /**
                 * @property
                 * @constant
                 * @name Model.Message#KEY_ANALYTICS_META
                 * @type {String}
                 */
            },
            TYPE_ALIAS_CONVERSATION: {
                value: TYPE_ALIAS_CONVERSATION
                /**
                 * @property
                 * @constant
                 * @name Model.Message#TYPE_ALIAS_CONVERSATION
                 * @type {String}
                 */
            },
            TYPE_ALIAS_REACTION: {
                value: TYPE_ALIAS_REACTION
                /**
                 * @property
                 * @constant
                 * @name Model.Message#TYPE_ALIAS_REACTION
                 * @type {String}
                 */
            },
            EVENT_MESSAGE_NAME_BLUR: {
                value: EVENT_MESSAGE_NAME_BLUR
                /**
                 * @property
                 * @constant
                 * @name Model.Message#EVENT_MESSAGE_NAME_BLUR
                 * @type {String}
                 */
            },
            REGEX_MESSAGE_URL_HASH: {
                value: REGEX_MESSAGE_URL_HASH
                /**
                 * @property
                 * @constant
                 * @name Model.Message#REGEX_MESSAGE_URL_HASH
                 * @type {RegExp}
                 */
            },
            KEY_AGGREGATED_STATS: {
                value: KEY_AGGREGATED_STATS
                /**
                 * @property
                 * @constant
                 * @name Model.Message#KEY_AGGREGATED_STATS
                 * @type {String}
                 */
            },
            KEY_MARKER_UUID: {
                value: KEY_MARKER_UUID
                /**
                 * @property
                 * @constant
                 * @name Model.Message#KEY_MARKER_UUID
                 * @type {String}
                 */
            }
        });

})(Object.namespace('Model'));
