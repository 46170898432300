(function (ns) {
    /**
     * @namespace
     * @alias sSettings.Route.DomainManagement
     * @extends sSettings.Route.Base
     * @constructor
     */
    var DomainManagement = function () {
        this.controller     = sDomain.Controller.Admin.Overview;
        this.templateUrl    = 'sdomain:admin-overview';
        this.controllerAs   = '$ctrl';
        this.accessControl  = {'domain.RESTAccess': Const.Method.GET};

        DomainManagement._pProto.constructor.call(this, arguments);
    };

    Object.extendProto(DomainManagement, ns.Base);

    /**
     * @function
     * @name sSettings.Route.DomainManagement#resolveDomain
     * @param {Service.sDomain} sDomainService
     * @returns {$.Deferred}
     */
    DomainManagement.prototype.resolveDomain = function resolveDomain(sDomainService) {
        return sDomainService.getCurrentDomain().then(function(currDomain) {
            return sDomainService.getDomainById(currDomain.id, true);
        });
    };

    ns.DomainManagement = DomainManagement;
})(Object.namespace('sSettings.Route'));