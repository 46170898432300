(function(ns) {
    var KEY_DISABLED = 'disabled';
    /**
     * @namespace
     * @alias Model.Feature.Relation
     * @constructor
     */
    var Relation = function() {
        var feature = null,
            meta = {}
            ;

        /**
         * @property
         * @name Model.Feature.Relation#feature
         * @type Model.Feature
         */

        /**
         * @property
         * @name Model.Feature.Relation#meta
         * @type Object
         */

        Object.defineProperties(
            this,
            {
                feature: {
                    configurable: true,
                    get: function () {
                        return feature;
                    },
                    set: function (val) {
                        if (val !== null) {
                            Object.instanceOf(val, Model.Feature);
                        }
                        feature = val;
                    }
                },
                meta: {
                    configurable: true,
                    get: function () {
                        return meta;
                    },
                    set: function (val) {
                        if (val instanceof Object) {
                            meta = val;
                        }
                    }
                }
            }
        );
    };

    /**
     * @function
     * @name Model.Feature.Relation#isDisabled
     * @returns Boolean
     */
    Relation.prototype.isDisabled = function isDisabled() {
        return this.meta[KEY_DISABLED] && this.meta[KEY_DISABLED] === 1;
    };

    /**
     * @function
     * @name Model.Feature.Relation#enable
     */
    Relation.prototype.enable = function enable() {
        delete this.meta[KEY_DISABLED];
    };

    /**
     * @function
     * @name Model.Feature.Relation#disable
     */
    Relation.prototype.disable = function disable() {
        this.meta[KEY_DISABLED] = 1;
    };

    /**
     * @function
     * @static
     * @name Model.Feature.Relation#create
     * @param {Model.Feature} feature
     * @param {Object=} meta
     * @returns Model.Feature.Relation
     */
    Relation.create = function create(feature, meta) {
        var featureRelation    = new Relation();

        featureRelation.feature = feature;
        featureRelation.meta    = meta || {};

        return featureRelation;
    };

    ns.Relation = Relation;
})(Object.namespace('Model.Feature'));