(function(ns) {
    /**
     * @namespace
     * @alias sSidebar.Component.Controller.sSidebarToggleGroup
     *
     * @param {sSidebar.Service.sSidebar} sSidebar
     */
    var sSidebarToggleGroup = function(sSidebar) {
        this.sSidebar = sSidebar;
    };

    ns.sSidebarToggleGroup = sSidebarToggleGroup;

})(Object.namespace('sSidebar.Component.Controller'));