(function(ns) {
    /**
     * @type {{timeout, lastValue, lastQuery}}
     */
    var validationQueue = {};

    /**
     * @namespace
     * @alias Service.sAPIValidation
     *
     * @param apiEndpoints
     * @constructor
     */
    var APIValidation = function (apiEndpoints) {
        this.apiEndpoints = apiEndpoints;
    };

    /**
     * @function
     * @name Service.sAPIValidation#validateUrl
     * @param {string} url
     * @returns {$.Deferred}
     */
    APIValidation.prototype.validateUrl = function validateUrl(url) {
        return $.ajax({
            url: this.apiEndpoints.validation.url(),
            data: url,
            method: 'POST'
        });
    };

    /**
     * @function
     * @name Service.sAPIValidation#validatePassword
     * @param {string} password
     * @returns {$.Deferred}
     */
    APIValidation.prototype.validatePassword = function validatePassword(password) {
        return $.ajax({
            url: this.apiEndpoints.validation.password(),
            data: password.bin2hex(),
            method: 'POST'
        });
    };

    /**
     *
     * @param value
     * @param {Function} validation
     * @param {string} queueId
     * @param delay
     * @returns {$.Deferred}
     */
    APIValidation.prototype.executeQueuedValidation = function executeQueuedValidation(value, validation, queueId, delay) {
        if (!queueId) {
            return validation(value);
        }

        delay = isNaN(parseInt(delay)) ? 0 : parseInt(delay);

        var queueEntry = validationQueue[queueId] || {};

        if (queueEntry.timeout) {
            clearTimeout(queueEntry.timeout);
        }

        if (queueEntry.lastValue === value && queueEntry.lastQuery) {
            return queueEntry.lastQuery;
        }

        var $deferred = $.Deferred();
        queueEntry.timeout = setTimeout(function() {
                return validation(value).then(
                    function(data) {
                        $deferred.resolve(data);
                    },
                    function() {
                        $deferred.reject();
                    }
                ).always(function() {
                    queueEntry.lastQuery = $deferred;
                    queueEntry.lastValue = value;
                    queueEntry.timeout = null;
                });
            }, delay
        );

        validationQueue[queueId] = queueEntry;

        return $deferred;
    };

    ns.sAPIValidation = APIValidation;

})(Object.namespace('Service'));