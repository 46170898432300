(function(ns) {
    var OWNER_MODEL = 'model',
        OWNER_JSON  = 'json'
    ;

    /**
     * @namespace
     * @alias Controller.Component.sActionFields
     * @constructor
     *
     * @param $scope
     */
    var sActionFields = function ($scope) {
        var tmpFieldsDefinition = this.fieldsDefinition,
            fieldsBag           = new Model.Action.Field.Bag([]),
            fieldsDefinition
        ;

        this.$scope         = $scope;
        this.$deRegister    = [];

        /**
         * @property
         * @name Controller.Component.sActionFields#model
         * @type string
         */

        /**
         * @property
         * @name Controller.Component.sActionFields#fieldsDefinition
         * @type Object
         */

        Object.defineProperties(this, {
            fieldsDefinition: {
                enumerable: true,
                get: function () {
                    return fieldsDefinition;
                },
                set: function (val) {
                    if (!Array.isArray(val)
                        || JSON.stringify(val) === JSON.stringify(fieldsDefinition)
                    ) {
                        return;
                    }
                    fieldsBag = new Model.Action.Field.Bag(val);
                    fieldsDefinition = val;
                }
                /**
                 * @note This will not get triggered if inner elements get changed
                 * @property
                 * @name Controller.Component.sActionFields#fieldsDefinition
                 * @type {Object[]}
                 */
            },
            fieldsBag: {
                enumerable: true,
                get: function () {
                    return fieldsBag;
                }
                /**
                 * @property
                 * @name Controller.Component.sActionFields#fieldsBag
                 * @type {Model.Action.Field.Bag}
                 */
            }
        });

        /**
         * @function
         * @name Controller.Component.sActionFields#$onInit
         */
        this.$onInit = function $onInit() {
            var self        = this,
                // inspired by the count of monte christo > prevent both running in the same digest
                hasTheKing
            ;

            this.$deRegister.push(this.$scope.$watch(
                function () {
                    return self.model;
                },
                function (val) {
                    if (hasTheKing === OWNER_JSON) {
                        hasTheKing = null;
                        return;
                    }

                    hasTheKing = OWNER_MODEL;
                    if (val && self.fieldsBag.json !== val) {
                        var parsedModel = {};
                        try {
                            parsedModel = JSON.parse(val)
                        } catch (error) {
                            console.log(error, val);
                        }

                        self.fieldsBag.updateFields(parsedModel);
                    } else if (!val) {
                        // without parameter will reset to default
                        self.fieldsBag.clearFields();
                    }
                }
            ));

            this.$deRegister.push(this.$scope.$watch(
                function () {
                    return self.fieldsBag.json;
                },
                function () {
                    if (hasTheKing === OWNER_MODEL) {
                        hasTheKing = null;
                        return;
                    }

                    hasTheKing = OWNER_JSON;
                    // instead of empty stringified object use null
                    if (self.fieldsBag.json === '{}') {
                        self.model = null;
                        return;
                    }

                    self.model = self.fieldsBag.json;
                }
            ));
        };

        this.fieldsDefinition = tmpFieldsDefinition;
    };
    
     /**
      * @param {Object.<String, SimpleChange>} $changes
      */
     sActionFields.prototype.$onChanges = function $onChanges($changes) {
         if ($changes.isDisabled) {
             this.fieldsBag.isDisabled = this.isDisabled;
         }
     }; 
     

    ns.sActionFields = sActionFields;
})(Object.namespace('Controller.Component'));
