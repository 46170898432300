(function(ns) {
    /**
     * @namespace
     * @alias Model.RepairCase.EmptyPostbackMessage
     *
     * @constructor
     * @extends Model.RepairCase
     */
    var EmptyPostbackMessage = function() {

        var options = {
            confirm     : 'Ignore & continue saving',
            decline     : 'I’ll fix this myself',
            title       : 'Some user clicks on messages won’t trigger a reaction …',
            content     : 'Buttons which trigger messages shouldn’t link nowhere or to empty messages. In that case it will be removed when saving. Ignore and go on?',
            ariaLabel   : ''
        };

        EmptyPostbackMessage._pProto.constructor.call(
            this,
            this.checkEmptyPostbackMessage,
            this.removeEmptyPostbackMessageCTAs,
            options
        );
    };

    Object.extendProto(EmptyPostbackMessage, Model.RepairCase);

    /**
     * @param {Model.Message.Collection} collection
     * @throws {Model.Exception.InvalidMessages}
     */
    EmptyPostbackMessage.prototype.checkEmptyPostbackMessage = function checkEmptyPostbackMessage(collection) {
        var lastPart,
            actions,
            faultyMessages = []
            ;

        messagesLoop:
        for (var i = 0; i < collection.messages.length; i++) {
            if (!(lastPart = collection.messages[i].lastPart()) || !lastPart.isInteractive || !collection.messages[i].needsCounter()) {
                continue;
            }

            if ((actions = lastPart.getAllMessagePostbackActions())) {
                for (var j in actions) {
                    if (actions[j].action.type === Const.PostbackMessage && !actions[j].action.value) {
                        faultyMessages.push(collection.messages[i]);
                        continue messagesLoop;
                    }
                }
            }
        }

        if (faultyMessages.length) {
            throw new Model.Exception.InvalidMessages(faultyMessages);
        }
    };

    /**
     * @param {Model.Message.Collection} collection
     */
    EmptyPostbackMessage.prototype.removeEmptyPostbackMessageCTAs = function removeEmptyPostbackMessageCTAs(collection) {
        var lastPart,
            i,
            j
            ;

        for (i = 0; i < collection.messages.length; i++) {
            if (!(lastPart = collection.messages[i].lastPart()) || !lastPart.isInteractive || !collection.messages[i].needsCounter()) {
                continue;
            }

            var actions = lastPart.getAllActions();

            for (j = 0; j < actions.length; j++) {

                if (!actions[j].cta || !actions[j].cta instanceof Model.CTA) {
                    continue;
                }

                if (actions[j].action.type === Const.PostbackMessage && !actions[j].action.value) {
                    actions[j].cta.actions.removeAction(actions[j].action);
                }

                if (actions[j].cta.actions.length === 0) {
                    actions[j].cta.parent.removeCTA(actions[j].cta);
                }
            }

            // special treatment for QuickReplies
            if (lastPart.type === Const.QuickReplies
                && lastPart.getAllActions().length === 0
                && lastPart.parent) {
                lastPart.parent.removePart(lastPart);
            }

            collection.messages[i].updatePostBackRelations();
        }
    };

    ns.EmptyPostbackMessage = EmptyPostbackMessage;
})(Object.namespace('Model.RepairCase'));