(function(angular) {

    var module = angular.module('sAnalyticsModule', []);

    module.config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/analytics/audience', new sAnalytics.Route.Audience())
            .when('/analytics/interaction', new sAnalytics.Route.Interactions())
            .when('/analytics/retention', new sAnalytics.Route.Retention())
            .when('/analytics/campaign', new sAnalytics.Route.Campaign())
        ;
    }]);

    module.config(['sTitleProvider', function(sTitleProvider) {
        sTitleProvider
            .when(
                '/analytics/audience',
                'Analytics',
                'Audience'
            )
            .when(
                '/analytics/interaction',
                'Analytics',
                'Interaction'
            )
            .when(
                '/analytics/retention',
                'Analytics',
                'User Retention'
            )
            .when(
                '/analytics/campaign',
                'Analytics',
                'Campaign'
            )
        ;
    }]);

    // provider for traces
    /**
     * allow entries to be filtered and grouped
     * returns a collection of traces in the end when executed
     */

    module.service('sTrace', sAnalytics.Service.sTrace);

    // register scatter types
    module.run(['sTrace', '$filter', function(sTrace, $filter) {
        sTrace.registerTraceFactory('scatter',
            /**
             * @param {Model.Analytics.Entry[]} entries
             * @param {Number=} period
             * @param {String=} variant
             */
            function(entries, period, variant) {
                var mapFn   = function mapFn(entry) {
                        return entry.categories.categorys[0].label;
                    },
                    results = [],
                    values  = entries.map(mapFn).unique();


                values.map(function(value) {
                    var filtered    = entries.filter(function(entry) {
                            return mapFn(entry) === value;
                        }),
                        trace       = Model.Analytics.Trace.VsTime.createFromEntries(filtered, variant)
                    ;

                    trace.name  = value;
                    trace.type  = 'scatter';
                    results.push(trace);
                });

                return results;
            }
        ).registerTraceFactory('scatterWithDiff',
            /**
             * @param {Model.Analytics.Entry[]} entries
             * @param {Number=} period
             * @param {String=} variant
             */
            function(entries, period, variant) {
                var traces  = this.executeTraceFactory('scatter', entries, period, variant),
                    diff    = (traces[0] && traces[1])
                        ? traces[0].subtract(traces[1])
                        : new Model.Analytics.Trace.VsTime()
                ;

                diff.name = 'Net growth';
                diff.type = 'bar';

                traces.unshift(diff);

                return traces;
            })
            .registerTraceFactory('categoryDistribution',
            /**
             * Calculates the average value distribution over categories
             * @param {Model.Analytics.Entry[]} entries
             * @param {Number} period
             * @param {String} variant
             * @param {function?} extractFn
             */
            function(entries, period, variant, extractFn) {
                var byCategory  = {},
                    exchanges   = {
                        'timezone_offset'   : 'Timezone',
                        'locale'            : 'Locale'
                    },
                    name
                ;

                // index entries by the first category
                entries.map(function(entry) {
                    var firstCategory   = entry.categories.categorys.slice(0,1).pop(),
                        key             = firstCategory.label
                    ;

                    if (!name) {
                        name = firstCategory.type;
                    }

                    byCategory[key] = byCategory[key] || [];
                    variant = variant || entry.getMetaFields().shift();
                    byCategory[key].push(entry[variant]);
                });

                var trace   = new Model.Analytics.Trace2d(),
                    raw     = []
                ;

                // iterate over the first category indexed array of values to average them
                for (var x in byCategory) {
                    var y = 0;
                    if (byCategory[x].length) {
                        y = byCategory[x].reduce(
                            function(sum, val) {
                                return sum += val;
                            },
                            0
                        ) / byCategory[x].length
                    }

                    raw.push(
                        {
                            x: x,
                            y: y
                        }
                    );
                }

                raw.sort(function(a, b) {
                    var tmpA = a,
                        tmpB = b
                    ;

                    if (extractFn) {
                        tmpA = extractFn.call(this, a.clone());
                        tmpB = extractFn.call(this, b.clone());
                    }

                    // order by Y ascending if X axis has no natural order
                    if (isNaN(parseInt(tmpA.x)) && !isNaN(parseInt(tmpA.y))) {
                        return tmpB.y - tmpA.y;
                    }
                    // order by X axis natural order
                    return tmpA.x - tmpB.x;
                }).reduce(function(traceCarry, element) {
                    traceCarry.x.push(element.x);
                    traceCarry.y.push(element.y);
                    return traceCarry;
                }, trace);

                trace.type = 'bar';
                trace.name = exchanges[name] || name;
                return [trace];
            })
        .registerTraceFactory('localeDistribution',
            /**
             * @param entries
             * @param period
             * @param variant
             */
            function(entries, period, variant) {
                var trace       = this.executeTraceFactory('categoryDistribution', entries, period, variant).slice(0,1).pop(),
                    threshold   = 9
                ;

                if (trace.x.length > (threshold + 1)) {
                    trace.y.splice.apply(trace.y, [threshold, trace.y.length].concat(trace.y.slice(threshold).reduce(function(sum, y) {
                        sum += y;
                        return sum;
                    }, 0)));

                    trace.x.splice(threshold, trace.x.length, 'others');
                }

                return [trace];

        })
        .registerTraceFactory('timezoneOffsetDistribution',
            /**
             * @param entries
             * @param period
             * @param variant
             */
            function(entries, period, variant) {
                var extractFn = function extractFn(tuple) {

                    if (!(typeof tuple.x === 'string' || tuple.x instanceof String)) {
                        return tuple;
                    }

                    var regex = new RegExp(/[+-]?\d+(\.\d+)?/g),
                        matchX = tuple.x.match(regex)
                    ;

                    if (matchX) {
                        tuple.x = parseFloat(matchX);
                    }

                    return tuple;
                };
                return this.executeTraceFactory('categoryDistribution', entries, period, variant, extractFn);
            })
        .registerTraceFactory('cohort',
            /**
             * @param entries
             * @param period
             * @param variant
             */
            function(entries, period, variant) {
                var HEADER_QUALIFIED_NUMBER = 'Users',
                    getColor = function getColor(val, colorScale, min, max) {
                        min = min || 0;
                        max = max || 1;
                        var _range = [];
                        for(var i = 0; i < colorScale.length; i++) {
                            var rgba = tinycolor(colorScale[i]).toRgb();
                            _range[i] = [rgba.r, rgba.g, rgba.b, rgba.a];
                        }
                        var scaleFn = Plotly.d3.scale.linear()
                            .domain([min, max])
                            .range(_range)
                            .clamp(false);

                        var colorArray = scaleFn(val);

                        var colorObj = {
                            r: colorArray[0],
                            g: colorArray[1],
                            b: colorArray[2],
                            a: colorArray[3]
                        };

                        return tinycolor(colorObj).toRgbString();
                    };

                var colorScale = ['#FFF','#3594BD'];

                var tableData = entries.reduce(function(carry, entry) {
                    var retention = entry.categories.categorys.filter(function(element) {
                            return element.type === 'retention';
                        }).pop(),

                        qualification = entry.categories.categorys.filter(function(element) {
                            return element.type === 'qualification';
                        }).pop(),

                        offset          = retention ? retention.label : HEADER_QUALIFIED_NUMBER,
                        label           = qualification.label
                    ;

                    if (carry.header.indexOf(offset) === -1) {
                        carry.header.push(offset);
                    }

                    carry.rows = carry.rows || {};

                    carry.rows[label] = carry.rows[label] || {'value' : qualification.key, 'valuesByOffset' : {}};

                    carry.rows[label].valuesByOffset[offset] = entry;

                    return carry;
                }, {
                    header : [],
                    values: [],
                    colors: [],
                    bgColors: []
                });

                tableData.header.sort(function(a, b) {
                    return a - b;
                });

                $.each(tableData.rows, function(y, row) {
                    var rowsData    = [moment(y).format('DD MMM YY')],
                        colors      = ['#000'],
                        bgColors    = ['#FFF']
                    ;
                    tableData.header.map(function(x, index) {
                        if (row.valuesByOffset[x] === undefined) {
                            return;
                        }

                        var value = row.valuesByOffset[x];

                        // take first column as header
                        if (index < 1) {
                            rowsData.push(value.absolute);
                            bgColors.push(getColor(0, colorScale));
                            colors.push(tinycolor.getMostReadableColor(bgColors.slice(-1).pop()));
                            return;
                        }

                        var cellValue = value[variant];

                        if (cellValue && variant === 'relative') {
                            cellValue = $filter('number')(cellValue * 100, 2) + '%'
                        }


                        var offset  = (isNaN(parseInt(x)) ? 0 : parseInt(x)) + 1,
                            until   = moment(y).add(moment.duration(offset * period * 1000)),
                            cell = new Model.Cell(cellValue || 0),
                            color
                        ;
                        // check if the cell is actually closed (more ppl may not qualify)

                        if (until < moment()) {
                            color = getColor(value.relative, colorScale);
                            bgColors.push(color);
                            colors.push(tinycolor.getMostReadableColor(color));
                        } else {
                            color = getColor(0, colorScale);
                            bgColors.push(color);
                            colors.push(tinycolor.addOpacityToColor(tinycolor.getMostReadableColor(color), 0.35));
                            cell.setMeta('tooltip', 'Users may qualify until ' + until.format('DD MMM YY'));
                            cell.setMeta('cssClass', 'open');
                        }

                        rowsData.push(cell);
                    });

                    tableData.values.push(rowsData);
                    tableData.colors.push(colors);
                    tableData.bgColors.push(bgColors);
                }, tableData.header);

                if (!tableData.header.length) {
                    tableData.header.unshift(HEADER_QUALIFIED_NUMBER);
                    var cell = new Model.Cell('No data for selected filter');
                    cell.setMeta('colSpan', 4);
                    cell.setMeta('rowSpan', 4);
                    cell.setMeta('cssClass', 'no-border');
                    tableData.header.push('W1', 'W2', 'W3', 'W4');
                    tableData.values.push(['W1', Const.Unicode.NON_BREAKING_SPACE, cell]);
                    tableData.values.push(['W2', Const.Unicode.NON_BREAKING_SPACE, null]);
                    tableData.values.push(['W3', Const.Unicode.NON_BREAKING_SPACE, null]);
                    tableData.values.push(['W4', Const.Unicode.NON_BREAKING_SPACE, null]);
                }

                tableData.header.unshift('Date');
                var arr = [];
                arr.length = tableData.header.length;
                arr.fill('#FFF', 0, arr.length);

                if (tableData.header.length > 2) {
                    var replacement = tableData.header.slice(2,3).pop() + ' ';
                    switch (period) {
                        case 604800:
                            replacement += 'week';
                            break;
                        case 86400:
                            replacement += 'day';
                            break;
                    }
                    tableData.header.splice(2,1, replacement);
                }
                tableData.bgColors.unshift(arr.clone());

                arr.fill(tinycolor.getMostReadableColor('#FFF'), 0, arr.length);
                tableData.colors.unshift(arr.clone());

                var trace = new Model.Analytics.Trace2d();

                trace.type = 'table';
                trace.setMeta('data', tableData);

                return [trace];
            }
        )
        ;
    }]);

    module.run(['sTrace', function(sTrace) {
        var colorway = [
            '#FFB32C',
            '#13AE92',
            '#99267E',
            '#5DCD62',
            '#597AE8',
            '#F04760',
            '#B3D528',
            '#3594BD',
            '#C4366F',
            '#40BF7F',
            '#5258D6',
            '#D03434',
            '#E77D30',
            '#753FAA',
            '#46BFBD'
        ];
        sTrace
            .registerPlotTemplate('defaultColors', {
                layout: {
                    colorway: colorway
                }
            })
            .registerPlotTemplate('percent', {
                layout : {
                    yaxis: {
                        tickformat: '.2%',
                        range: [0,1]
                    }
                }
            })
            .registerPlotTemplate('default', {
                data: {
                    scatter: [
                        {
                            mode: 'markers',
                            marker: {
                                size: 3
                            }
                        }
                    ]
                },
                layout: {
                    font: {
                        family: 'Avenir Next'
                    },
                    showlegend  : false,
                    autosize    : true,
                    margin      : {
                        "b" :25,
                        "l" :70,
                        "r" :35,
                        "t" :5
                    },
                    xaxis       : {
                        automargin      : true,
                        fixedrange      : true,
                        showgrid        : false,
                        showline        : false,
                        zeroline        : false,
                        ticks           : 'outside',
                        tickcolor       : 'transparent',
                        nticks          : 12,
                        tickmode        : 'auto',
                        tickangle       : 0,
                        tickfont        : {
                            size: 11
                        }
                    },
                    yaxis       : {
                        fixedrange      : true,
                        ticks           : 'outside',
                        tickcolor       : 'rgba(0, 0, 0, 0.1)',
                        gridcolor       : 'rgba(0, 0, 0, 0.1)',
                        rangemode       : 'tozero',
                        zeroline        : true,
                        showline        : true,
                        mirror          : false,
                        zerolinecolor   : 'rgba(0, 0, 0, 0.3)',
                        linecolor       : 'rgba(0, 0, 0, 0.1)',
                        nticks          : 7,
                        tickfont        : {
                            size: 11
                        }
                    }
                }
            })
            .registerPlotTemplate('timeRangeXAxis', {
                layout: {
                    xaxis: {
                        type: 'date',
                        tickformat: '%d %b %y',
                        hoverformat: '%d %b %y'
                    }
                }
            })
            .registerPlotTemplate('timeRangeXAxisHour', {
                layout: {
                    xaxis: {
                        type: 'date',
                        tickformat: '%d %b %H:%M',
                        hoverformat: '%d %b %y %H:%M'
                    }
                }
            })
            .registerPlotTemplate('scatter', {
                data: {
                    scatter: [
                        {
                            mode: "lines+markers",
                            type: "scatter"
                        }
                    ]
                }
            })
            .registerPlotTemplate('stackedScatter', {
                data: {
                    scatter: [
                        {
                            mode: "lines+markers",
                            fill: "tonexty",
                            type: "scatter"
                        }
                    ]
                }
            })
            .registerPlotTemplate('hoverSpike',  {
                layout: {
                    spikedistance: -1,
                    xaxis: {
                        showspikes      : true,
                        spikemode       : 'across+marker',
                        spikecolor      : 'lightgrey',
                        spikethickness  : 1,
                        spikedash       : 'solid',
                        spikesnap       : 'cursor',
                        spikesides      : false
                    }
                }
            })
            .registerPlotTemplate('histogram', {
                layout: {
                    xaxis       : {
                        tickmode        : 'linear'
                    }
                }
            })
            .registerPlotTemplate('hoverMarkers', {
                layout: {
                    hovermode:'closest',
                    yaxis: {
                        hoverformat: '~g'
                    }
                }
            })
            .registerPlotTemplate('legend', {
                layout: {
                    showlegend  : true,
                    legend: {
                        orientation: 'h',
                        y: 1.2
                    }
                }
            })
            .registerPlotTemplate('stackedBar', {
                layout: {
                    barmode: 'stack'
                }
            })
            .registerPlotTemplate('grayFirst', {
                layout: {
                    colorway:  [
                        '#EDEEEE'
                    ].concat(colorway)
                }
            })
            .registerPlotTemplate('thumb', {
                data: {
                    scatter: [
                        {
                            mode: "lines+markers",
                            fill: "tozeroy",
                            fillcolor: 'rgba(53, 148, 189, 0.15)',
                            line: {
                                color: 'rgba(53, 148, 189, 1)',
                                width: 1
                            },
                            marker: {
                                color: 'rgba(53, 148, 189, 1)',
                                size: 1
                            }
                        }
                    ],
                    bar: [
                        {
                            marker: {
                                color: 'rgba(53, 148, 189, 0.15)',
                                line: {
                                    color: 'rgba(53, 148, 189, 1)',
                                    width: 1
                                }
                            }
                        }
                    ]
                },
                layout: {
                    hovermode: false,
                    margin: {
                        "b":0,
                        "l":0,
                        "r":0,
                        "t":0
                    },
                    xaxis: {
                        showticklabels  : false,
                        ticks           : '',
                        showline        : false

                    },
                    yaxis: {
                        showticklabels  : false,
                        ticks           : '',
                        showgrid        : false,
                        zerolinecolor   : 'lightgrey'
                    }
                }
            })
            .registerPlotTemplate('autoTiltLabels', {
                layout: {
                    xaxis: {
                        tickangle: 'auto'
                    }
                }
            })
        ;
    }]);

    /**
     * between analytics navigation keep the filter parameters
     */
    module.run(['$rootScope', '$route', function($rootScope, $route) {
        $rootScope.$on('$routeChangeSuccess', function(event, $current, $previous) {
            var pathRegExp = new RegExp('/analytics');

            if ($current.$$route
                && $current.$$route.originalPath
                && $current.$$route.originalPath.search(pathRegExp) !== -1
                && $previous
                && $previous.$$route
                && $previous.$$route.originalPath
                && $previous.$$route.originalPath.search(pathRegExp) !== -1
                && $current.originalPath !== $previous.originalPath) {
                $route.updateParams($previous.params);
            }
        });
    }]);
})(angular);
