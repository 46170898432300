(function (ns) {
    /**
     * @namespace
     * @alias sAnalytics.Route.Interactions
     * @extends sRoute
     * @constructor
     */
    var Interactions = function Interactions() {
        this.controller     = sAnalytics.Controller.Charts;
        this.controllerAs   = '$ctrl';
        this.templateUrl    = 'sanalytics:charts';
        this.reloadOnSearch = false;
        this.accessControl  = {'analytics.byDomain.chartData': Const.Method.GET};

        Interactions._pProto.constructor.call(this);
    };

    Object.extendProto(Interactions, sRoute);

    /**
     * @name sAnalytics.Route.Interactions#resolveCharts
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {Service.sDomain} sDomainService
     * @return {{}}
     */
    Interactions.prototype.resolveCharts = function resolveCharts(sAPIAccess, sDomainService) {
        var charts = {},
            sBag
        ;

        sBag = new Model.Analytics.StatisticsBag(sAPIAccess.endpoint('analytics.byDomain.chartData').get(sDomainService.currentDomainId, 'messages_outgoing'));
        sBag.filters.createAndAdd('categories', {'interaction': ['interacted']}, true);
        charts.interactions = {
            bag             : sBag,
            traceFactory    : 'scatter',
            template        : 'default|grayFirst|legend|hoverMarkers|hoverSpike|scatter|timeRangeXAxis|type:{"Sent": "bar"}',
            title           : 'Interactions',
            thumbExtra      : 'type:{"Sent": "bar"}',
            hints           : {
                description     : 'How many messages were received or buttons were clicked?',
                absolute        : 'Total interactions',
                relative        : 'Total of interactions relative to messages with interactive elements'
            }
        };

        sBag = new Model.Analytics.StatisticsBag(sAPIAccess.endpoint('analytics.byDomain.chartData').get(sDomainService.currentDomainId, 'messages_incoming'));
        sBag.filters.createAndAdd('categories', ['matching'], true);
        charts.understood = {
            bag             : sBag,
            traceFactory    : 'scatter',
            // specify the order of the traces
            template        : 'default|defaultColors|legend|hoverMarkers|hoverSpike|stackedBar|timeRangeXAxis|order:["Unmatched", "Matched"]|type:"bar"',
            title           : 'Incoming messages',
            thumbExtra      : 'type:"bar"',
            hints           : {
                description     : 'How many manually typed messages have your users sent to you and how many did the bot understand?',
                absolute        : 'Total incoming messages',
                relative        : 'Total of matched messages relative to incoming messages'
            }
        };

        sBag = new Model.Analytics.StatisticsBag(sAPIAccess.endpoint('analytics.byDomain.chartData').get(sDomainService.currentDomainId, 'urls'));
        sBag.filters.createAndAdd('categories', {'clicks' : ['clicked']}, true);
        charts.urls = {
            bag             : sBag,
            traceFactory    : 'scatter',
            template        : 'default|grayFirst|legend|hoverMarkers|hoverSpike|scatter|timeRangeXAxis|type:{"Sent": "bar"}',
            title           : 'Clicks on URLs',
            thumbExtra      : 'type:{"Sent": "bar"}',
            hints           : {
                description     : 'How many users clicked on a link in a message when it was sent to them?',
                absolute        : 'Total clicks on links',
                relative        : 'Total clicks on links relative to messages containing links'
            }
        };

        return charts;
    };

    ns.Interactions = Interactions;
})(Object.namespace('sAnalytics.Route'));