(function(ns) {

    /**
     * @namespace
     * @alias Controller.Directive.Accordion.sAccordionHeadController
     * @constructor
     *
     * @param $scope
     * @param $element
     * @param $attrs
     */
    var sAccordionHeadController = function sAccordionHeadController($scope, $element, $attrs) {
        sAccordionHeadController._pProto.constructor.call(this);

        this.$scope         = $scope;
        this.$element       = $element;
        this.$attrs         = $attrs;
        this.$deRegister    = [];

        /**
         * @property
         * @name $attrs.sAccordionHead
         * @type Controller.Directive.Accordion.sAccordionHeadController
         */

        /**
         * There will be a parent scope, worst case the rootScope
         * @property
         * @name $scope.$parent
         * @type $attrs
         */
    };

    Object.extendProto(sAccordionHeadController, ns.sAccordionBehaviorAwareController);

    /**
     * @function
     * @name Controller.Directive.Accordion.sAccordionHeadController#$onInit
     */
    sAccordionHeadController.prototype.$onInit = function $onInit() {
        var self = this;

        this.$deRegister.push(this.$scope.$watch(
            function () {
                return self.$attrs.sAccordionBody;
            },
            function (value) {
                if (!value || value === self.accordionGroupId) {
                    return;
                }
                self.removeFromAccordion();
                self.accordionGroupId = value;
                self.accordionBehaviorController = self.sAccordionBehaviorCtrl;
        }
        ));

        var clickListener = function ($event) {
            if (self.triggerGroup()) {
                $event.stopPropagation();

                var $scope = self.accordionBehaviorController.$scope;
                digestIfNeeded($scope);
            }
        };
        this.$element[0].addEventListener(
            'click',
            clickListener,
            true
        );
        this.$deRegister.push(function () {
            self.$element[0].removeEventListener('click', clickListener, true);
        });
    };

    /**
     * @function
     * @name Controller.Directive.Accordion.sAccordionHeadController#$onDestroy
     */
    sAccordionHeadController.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }

        this.removeFromAccordion();
    };

    /**
     * @function
     * @name Controller.Directive.Accordion.sAccordionHeadController#$postLink
     */
    sAccordionHeadController.prototype.$postLink = function $postLink() {
        this.accordionGroupId               = this.$attrs.sAccordionHead;
        this.accordionBehaviorController    = this.sAccordionBehaviorCtrl;
    };

    /**
     * @function
     * @name Controller.Directive.Accordion.sAccordionHeadController#triggerGroup
     * @return {Boolean}
     */
    sAccordionHeadController.prototype.triggerGroup = function triggerGroup() {
        return this.accordionBehaviorController.changeActive(this.accordionGroupId);
    };

    ns.sAccordionHeadController = sAccordionHeadController;
})(Object.namespace('Controller.Directive.Accordion'));
