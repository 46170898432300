/**
 * Helper function to create prototypal inheritance
 * The parent's prototype will be stored as _pProto property of the Object. Use it to access the parent's functions.
 * eg.: <Object>._pProto.constructor.apply(this, arguments)
 * This way even if the parent changes, the code remains functional.
 * @param o1
 * @param o2
 */
Object.extendProto = function(o1, o2) {
    o1.prototype = Object.create(o2.prototype);
    o1.prototype.constructor = o1;
    o1._pProto = o2.prototype;
    o1._parent = o2;
};

/**
 * Checks if o1 is instanceof o2 and throws an error if not.
 * @param o1
 * @param o2
 */
Object.instanceOf = function(o1, o2)
{
    if (!(o1 instanceof o2)) {
        throw o1 + ' must be type of ' + o2 + '! ' + Object.getPrototypeOf(o1) + ' was given.';
    }
};

/**
 * Checks if the protoype of c2 can be found in the prototype chain of c1.
 * @param c1
 * @param c2
 * @returns {boolean}
 */
Object.inherits = function(c1,c2)
{
    var proto = c1;
    while  (proto._pProto && (proto = proto._pProto.constructor)) {
        if (proto === c2) {
            return true;
        }
    }

    return false;
};

/**
 * Creates an object of the provided type
 * @param className
 * @param args
 * @returns {className}
 */
Object.construct = function(className, args) {
    var obj = Object.create(className.prototype);
    className.prototype.constructor.apply(obj, args);
    return obj;
};

/**
 * Fakes a namespace by creating the object hierarchy provided by the string
 * @param nsString
 * @param root
 * @returns {Window | *}
 */
Object.namespace = function(nsString, root) {
    var path = nsString.split(/[\/.]/),
        elem
    ;

    root = root || typeof(window) !== 'undefined' ? window : global;

    while ((elem = path.shift())) {
        root[elem] = root[elem] || {};
        root = root[elem];
    }

    return root;
};

Object.getFirstPropertyName = function getFirstPropertyName(obj) {
    if (!(obj instanceof Object)) {
        throw 'Invalid argument!';
    }

    var names = Object.getOwnPropertyNames(obj);
    return names.shift();
};

Object.getFirstProperty = function getFirstProperty(obj) {
    if (!(obj instanceof Object)) {
        throw 'Invalid argument!';
    }

    //noinspection LoopStatementThatDoesntLoopJS
    for (var i in obj) {
        return obj[i];
    }
};

Object.updateByData = function updateByData(object, data) {
    var keysSet = [],
        sample,
        defaults
    ;

    sample = Object.construct(object.constructor);
    // get the keys which have a default value
    defaults = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(sample)));

    for (var key in data) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            keysSet.push(key);
            if (object[key] instanceof Object && object[key].updateByData) {
                object[key].updateByData(data[key]);
                continue;
            }

            object[key] = data[key];
        }
    }

    // remove keys that were not set
    var allKeys = Object.getOwnPropertyNames(object);
    allKeys = defaults.diff(allKeys);

    keysSet.diff(allKeys)
        .filter(function(keyToCheck) {
            return typeof(object[keyToCheck]) !== 'function';
        })
        .map(function(keyToRemove) {
            if (!Object.getOwnPropertyDescriptor(object, keyToRemove).enumerable) {
                return;
            }

            object[keyToRemove] = undefined;
        });
};

Object.prototype.clone = function() {
    if (this.__isBeingCloned) {
        return this.__isBeingCloned;
    }
    var clonedInstances = [],
        executeClone = function executeClone(subject) {
            var clone = subject.clone();
            if (subject.__isBeingCloned) {
                return clone;
            }

            // add the flag back - if possible - to avoid circular references,
            // because the clone is created now and the subject has released its own flag
            try {
                Object.defineProperty(
                    subject,
                    '__isBeingCloned',
                    {
                        configurable: true,
                        value: clone
                    }
                );
            } catch (e) {
                // just don't die if it is not possible (eg setting on primitives...)
            }

            if (clonedInstances.indexOf(subject) === -1) {
                clonedInstances.push(subject);
            }
            return clone;
        }
    ;

    if (this.constructor.name === 'String'
        || this.constructor.name === 'Number'
        || this.constructor.name === 'Boolean') {
        return this.valueOf();
    }

    if (this.then && this.then instanceof Function) {
        return this;
    }

    var args = [],
        i
    ;
    if (this.__getCloneArguments || this.__getCloneArguments instanceof Function) {
        args = this.__getCloneArguments();
    }

    var clone;

    if (this.constructor.name === 'Array') {
        clone = [];
    } else {
        clone = Object.construct(this.constructor, args);
        Object.defineProperty(
            this,
            '__isBeingCloned',
            {
                configurable: true,
                value: clone
            }
        );
    }

    if (this.__afterCloneConstructed && this.__afterCloneConstructed instanceof Function) {
        var retVal = this.__afterCloneConstructed(clone);

        if (retVal && retVal instanceof this.constructor) {
            clone = retVal;
            delete this.__isBeingCloned;
            return clone;
        }
    }

    if (clone instanceof Array) {
        for (i = 0; i < this.length; i++) {
            clone.push(this[i] ? executeClone(this[i]) : this[i]);
        }
    } else {
        var names = Object.getOwnPropertyNames(clone),
            ignoredProperties = [],
            index,
            alreadyChecked = []
        ;

        if (clone.__dontCloneProperties && this.__dontCloneProperties instanceof Function) {
            ignoredProperties = clone.__dontCloneProperties();
        }

        for (i = 0; i < ignoredProperties.length; i++) {
            if ((index = names.indexOf(ignoredProperties[i])) > -1) {
                names.splice(index, 1);
            }

            alreadyChecked.push(ignoredProperties[i]);
        }

        for (i = 0; i < names.length; i++) {
            var propertyName = names[i],
                descriptor;

            alreadyChecked.push(propertyName);

            if (!propertyName || this[propertyName] === null || this[propertyName] === undefined || this[propertyName] instanceof Function) {
                continue;
            }

            if (
                !((descriptor = Object.getOwnPropertyDescriptor(clone, propertyName))
                    && (
                        (descriptor.get instanceof Function && descriptor.set instanceof Function)
                        || descriptor.writable
                    )
                )
            ) {
                continue;
            }

            clone[propertyName] = executeClone(this[propertyName]);
        }

        for (i in this) {
            if (alreadyChecked.indexOf(i) !== -1
                || this[i] instanceof Function
                || this[i] === null
                || this[i] === undefined) {
                continue;
            }

            clone[i] = executeClone(this[i]);
        }
    }

    if (clone.__extendClone && clone.__extendClone instanceof Function) {
        clone.__extendClone(this);
    }


    clonedInstances.map(function (instance) {
        delete instance.__isBeingCloned;
    });

    delete this.__isBeingCloned;

    return clone;
};

Object.prototype.isEmpty = function isEmpty() {
    var self = this,
        i
    ;
    //noinspection LoopStatementThatDoesntLoopJS
    for (i in self) {
        return false;
    }
    return true;
};


Object.defineProperties(
    Object.prototype,
    {
        clone: {
            enumerable: false
        },
        isEmpty: {
            enumerable: false
        }
    }
);

/**
 * Optional cloning lifecycle hook, that is invoked right after the clone instance was created.
 * If it returns a value that is an instance of the actually cloned object, the returned value will replace the clone.
 * @name Object#__afterCloneConstructed
 * @param {*} The cloned skeleton
 * @return {?} Optional replacement for the clone
 */

/**
 * Optional cloning lifecycle hook, that is invoked after all properties have been cloned.
 * Use it to set/clone props that require extra work to be cloned.
 * @name Object#__extendClone
 */

/**
 * Optional cloning behavior function, will add values to be injected into the constructor during cloning.
 * Use it to set immutable props that are set via the constructor.
 * @name Object#__getCloneArguments
 */

/**
 * Optional cloning behavior function, will add property names to be ignored during cloning.
 * Use it to exclude props that are accessible but should not be cloned.
 * @name Object#__dontCloneProperties
 */
