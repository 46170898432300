(function(ns) {
    /**
     * @namespace
     * @alias Service.sUserDataLabel
     *
     * @param sDomainService
     * @param apiEndpoints
     */
    var sUserDataLabel = function (sDomainService, apiEndpoints) {
            var self = this,
                labels = [],
                loaded = false
            ;

            this.sDomainService     = sDomainService;
            this.apiEndpoints       = apiEndpoints;
            this.unpersistedLabels  = [];
            this.loading            = null;

            // function with access to private property
            this.getLabels = function getLabels() {
                if (loaded !== sDomainService.currentDomainId) {
                    this.clearLabels();
                    return self.loadLabels().then(function (data) {
                        labels = labels.concat(data).unique();
                        loaded = sDomainService.currentDomainId;
                        return labels;
                    });
                }

                return $.when(labels);
            };
            this.addLabel = function addLabel(value) {
                if (labels.indexOf(value) === -1) {
                    labels.push(value);
                    self.unpersistedLabels.push(value)
                }
            };
            this.clearLabels = function clearLabels() {
                labels = [];
                self.unpersistedLabels = [];
            };
        };

    sUserDataLabel.prototype.loadLabels = function loadLabels() {
        var domainId = this.sDomainService.currentDomainId ? this.sDomainService.currentDomainId : 0,
            self = this
        ;

        if (this.loading === null) {
            this.loading = $.ajax(this.apiEndpoints.action.byDomain(domainId).userDataLabels()).then(function (data) {
                var labelsData = angular.fromJson(data);

                return labelsData.map(function (element) {
                    return element.value;
                });
            }).always(function () {
                self.loading = null;
            });
        }

        return this.loading;
    };

    sUserDataLabel.prototype.saveLabels = function saveLabels() {
        var self = this,
            formData = new FormData(),
            domainId = this.sDomainService.currentDomainId ? this.sDomainService.currentDomainId : 0;

        if (!this.unpersistedLabels.length) {
            return $.Deferred().resolve();
        }

        formData.append('userDataLabels', angular.toJson(this.unpersistedLabels));

        return $.ajax({
            url         : this.apiEndpoints.action.byDomain(domainId).userDataLabels(),
            method      : Const.Method.POST,
            data        : formData,
            context     : self,
            processData : false,
            contentType : false
        }).then(function (data) {
            self.unpersistedLabels = [];
            return data;
        });
    };

    ns.sUserDataLabel = sUserDataLabel;
})(Object.namespace('Service'));