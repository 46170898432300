(function (ns) {
    var KEY_ATTRIBUTE   = 'users_label',
        KEY_USER_LABEL  = 'attribute_label',
        DEFAULT_KEY     = KEY_ATTRIBUTE
    ;

    /**
     * @param {Object} data
     * @return {Object}
     */
    var migrateData = function migrateData(data) {
        // migrate user labels into user attributes, naming is super confusing :)
        if (data && data.name === KEY_USER_LABEL) {
            data.name = KEY_ATTRIBUTE;

            data.value = data.value.map(function(value) {
                return JSON.stringify({key: value, value: null});
            });
        }

        return data;
    };

    /**
     * @namespace
     * @alias Model.Segment.Condition
     *
     * @constructor
     * @extends Model.Condition
     */
    var Condition = function () {
        Condition._pProto.constructor.call(this);

        this.key = DEFAULT_KEY;

        // do not clone condition definition
        this.__dontCloneProperties = function () {
            return ['definition'];
        };
    };

    Object.extendProto(Condition, Model.Condition);

    /**
     * @param {Object} data
     */
    Condition.prototype.updateByData = function updateByData(data) {
        data = migrateData(data);
        Condition._pProto.updateByData.call(this, data);
    };

    Object.defineProperties(
        Condition,
        {
            DEFAULT_KEY: {
                value: DEFAULT_KEY
                /**
                 * @property
                 * @constant
                 * @name Model.Segment.Condition#DEFAULT_KEY
                 * @type {String}
                 */
            },
            KEY_ATTRIBUTE: {
                value: KEY_ATTRIBUTE
                /**
                 * @property
                 * @constant
                 * @name Model.Segment.Condition#KEY_ATTRIBUTE
                 * @type {String}
                 */
            }
        });

    ns.Condition = Condition;
})(Object.namespace('Model.Segment'));
