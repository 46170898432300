(function (ns) {
    /**
     * @param {Object} diff The diff between prev version and this version
     * @param {String?} label Human readable info about the version
     * @constructor
     */
    var Version = function(diff, label) {
        if (typeof(diff) !== 'object') {
            throw 'Diff must be an object!';
        }

        diff = JSON.stringify(diff);

        Object.defineProperties(
            this,
            {
                diff: {
                    get: function () {
                        // noinspection JSCheckFunctionSignatures > input and stored values are different types
                        return JSON.parse(diff);
                    }
                    /**
                     * @property
                     * @name Version#diff
                     * @type {Object}
                     */
                },
                label: {
                    get: function () {
                        return label;
                    }
                    /**
                     * @property
                     * @name Version#label
                     * @type {String}
                     */
                }
                
            });
    };

    /**
     * @type {Model.Versionable[]}
     */
    var versionablesCollection = [];

    /**
     * @namespace
     * @alias Model.Versionable
     *
     * @abstract
     * @constructor
     */
    var Versionable = function () {
        if (this.constructor === Versionable) {
            throw 'Behavior should not be instantiated!'
        }
        var self                    = this,
            __versions              = [],
            __currentVersionIndex   = 0,
            __rootState
        ;

        // check for methods like an interface
        if (!(this.supportsChange instanceof Function)) {
            throw 'Method "supportsChange" is not implemented!';
        }

        if (!(this.getJSONState instanceof Function)) {
            throw 'Method "getJSONState" is not implemented!';
        }

        Object.defineProperties(
            this,
            {
                _getLastVersionIndex: {
                    get: function () {
                        return function() {
                            return __versions.length - 1;
                        }
                    }
                    /**
                     * @final
                     * @property
                     * @name Model.Versionable#_getLastVersionIndex
                     * @type {function() : number}
                     */
                },
                _getVersions: {
                    get: function () {
                        return function(version) {
                            if (typeof(version) === 'number') {
                                // has to be +1 to include that version
                                version = version + 1;
                            }
                            return __versions.slice(0, version);
                        }
                    }
                    /**
                     * @final
                     * @property
                     * @name Model.Versionable#_getVersions
                     * @type {function(number?) : Version[]}
                     */
                },
                _hasVersions: {
                    get: function () {
                        return function() {
                            return __versions.length !== 0;
                        }
                    }
                    /**
                     * @final
                     * @property
                     * @name Model.Versionable#_hasVersions
                     * @type {function() : boolean}
                     */
                },
                _addVersion: {
                    get: function () {
                        return function(version) {
                            Object.instanceOf(version, Version);
                            __versions.push(version);
                        }
                    }
                    /**
                     * @final
                     * @property
                     * @name Model.Versionable#_addVersion
                     * @type {function(Version)}
                     */
                },
                _resetRootState: {
                    get: function () {
                        return function() {
                            __versions.splice(0, __versions.length);
                            __currentVersionIndex = 0;
                            __rootState = JSON.stringify(self.getJSONState());
                        };
                    }
                    /**
                     * @property
                     * @name Model.Versionable#_resetRootState
                     * @type {function()}
                     */
                },
                _getRootState: {
                    get: function () {
                        return function () {
                            return JSON.parse(__rootState);
                        }
                    }
                    /**
                     * @property
                     * @name Model.Versionable#_getRootState
                     * @type {Object}
                     */
                }

            });

        /**
         * @property
         * @name Model.Versionable#supportsChange
         * @type {function(Function, *) : boolean}
         */

        /**
         * @property
         * @name Model.Versionable#getJSONState
         * @type {function() : {}}
         */

        this._resetRootState();
        versionablesCollection.push(this);
    };

    /**
     * Cuts the versionable off the execution chain
     * @param {Model.Versionable} versionable
     * @return {boolean}
     */
    Versionable.unregister = function(versionable) {
        var index;
        index = versionablesCollection.indexOf(versionable);

        if (index === -1) {
            return false;
        }
        versionablesCollection.splice(index, 1);

        return true;
    };

    /**
     *
     * @param {Function} closure
     * @param {*} invoker
     * @param {String?} label
     */
    Versionable.executeChange = function(closure, invoker, label) {
        // filter out those that reacted on the change
        var versionablesAffected = versionablesCollection.filter(function(versionable) {
            return versionable.supportsChange(closure, invoker);
        });

        label = label || closure.name;

        versionablesAffected.map(function(versionable) {
            if (!versionable._hasVersions()) {
                return;
            }
            doVersionChange.call(versionable);
        });


        // apply the change
        var retVal = closure.apply(invoker, Array.prototype.slice.call(arguments,3 ));

        // handle promises differently
        if (retVal && typeof(retVal.then) === 'function') {
            return retVal.then(function() {
                versionablesAffected.map(function(versionable) {
                    doVersionChange.call(versionable, label);
                });
            })
        }

        versionablesAffected.map(function(versionable) {
            doVersionChange.call(versionable, label);
        });
    };

    // private methods to avoid conflicts in the implementing model
    var
        /**
         * Adds a version by calculating the diff
         * @param {String?} label
         */
        doVersionChange = function doVersionChange(label) {
            var diff = DeepDiff.diff(getJSONStateForVersion.call(this), this.getJSONState());

            if (!diff) {
                return;
            }

            this._addVersion(new Version(diff, label));
        },

        /**
         * @param {Number|String?} capIndex Can be between -_getLastVersionIndex() and +_getLastVersionIndex(), - means step back x times
         */
        getVersionIndex = function getVersionIndex(capIndex) {
            // "this" is versionable
            var maxIndex = this._getLastVersionIndex();

            // noting to do, there are no managed versions
            if (maxIndex === -1) {
                return -1;
            }

            if (capIndex) {
                if (typeof(capIndex) === 'string') {
                    capIndex = parseInt(capIndex);
                }

                if (typeof(capIndex) !== 'number') {
                    capIndex = undefined;
                }

                // invalidate capIndex, if too big
                if (capIndex > maxIndex) {
                    capIndex = undefined;
                }

                // or too small
                if (capIndex < 0 && capIndex * -1 > maxIndex) {
                    capIndex = undefined;
                }

                if (capIndex < 0) {
                    capIndex = maxIndex + capIndex;
                }
            }
            return capIndex || maxIndex;
        },
        /**
         * @param {Number|String?} version
         */
        getJSONStateForVersion = function getJSONStateForVersion(version) {
            // "this" is versionable
            var index   = getVersionIndex.call(this, version),
                builtUp = this._getRootState()
            ;

            if (index === -1) {
                return this._getRootState();
            }

            var versions    = this._getVersions(index);

            // build up from all the changes in all versions
            versions.map(function(version) {
                version.diff.map(function (change) {
                    DeepDiff.applyChange(builtUp, null, change);
                });
            });

            return builtUp;
        }
    ;

    ns.Versionable = Versionable;
})(Object.namespace('Model'));