(function (ns) {
    /**
     * @namespace
     * @alias sFacebook.Controller.Component.sFacebookAdAccountSelect
     *
     * @constructor
     * @param {sFacebook.Service.sFacebookAdAccount} sFacebookAdAccount
     */
    var sFacebookAdAccountSelect = function (sFacebookAdAccount) {
        this.sFacebookAdAccount = sFacebookAdAccount;
    };

    /**
     * @return {$.Deferred<Model.Sender.FacebookAdAccount[]>}
     */
    sFacebookAdAccountSelect.prototype.getChoices = function getChoices() {
        return this.sFacebookAdAccount.loadAll(['isAvailable']);
    };

    ns.sFacebookAdAccountSelect = sFacebookAdAccountSelect;
})(Object.namespace('sFacebook.Controller.Component'));
