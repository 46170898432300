(function(ns) {
    /**
     * @namespace
     * @alias Controller.Directive.sEmojiPickerWrapper
     *
     * @param $element
     * @param {sEmoji.Service.sEmojiSheet} sEmojiSheet
     */
     var PickerWrapperCtrl = function ($element, sEmojiSheet) {
        this.sEmojiSheet    = sEmojiSheet;

        this.$element       = $element;

        /**
         * @property
         * @name Controller.Directive.sEmojiPickerWrapper#$element
         */

        /**
         * @property
         * @name Controller.Directive.sEmojiPickerWrapper#$wrapper
         */

        /**
         * @property
         * @name Controller.Directive.sEmojiPickerWrapper#$picker
         */

        /**
         * @property
         * @name Controller.Directive.sEmojiPickerWrapper#autocompleterCtrl
         * @type {Object}
         */
    };

    /**
     * @function
     * @name Controller.Directive.sEmojiPickerWrapper#$onInit
     */
    PickerWrapperCtrl.prototype.$onInit = function $onInit() {


        // take sure to set a value for rules and tracker
        this.autocompleterCtrl.tracker = '';
        this.autocompleterCtrl.rules = this.autocompleterCtrl.rules || [];

        this.autocompleterCtrl.rules.push({
            panelCtrl   : Controller.Directive.Emoji.PickerController,
            panelTpl    : '_directive:emoji/picker',
            panelClass  : 'emoji-panel',
            pattern     : '^:([a-z_\\\-A-Z]+:?$)',
            results     : this.searchByTerm.bind(this),
            transformer : this.transformer.bind(this),
            triggerIcon : '<svg xmlns="http://www.w3.org/2000/svg" class="emoji picker"><!--suppress HtmlUnknownAttribute --><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#emoji-1f642"><title>Open emoji picker</title></use></svg>',
            dependencies: {
                sEmojiSheet: this.sEmojiSheet
            }
        });
    };

    /**
     * @function
     * @name Controller.Directive.sEmojiPickerWrapper#transformer
     * @param {string} term
     * @param {Boolean=} soft Indicates whether the transform should be soft - leaving it search ready - or not
     * @returns string
     */
    PickerWrapperCtrl.prototype.transformer = function transformer(term, soft) {
        term = ':' + term + (soft ? '' : ':');
        return term;
    };

    /**
     * @function
     * @name Controller.Directive.sEmojiPickerWrapper#searchByTerm
     * @param {string} term
     * @returns {[]}
     */
    PickerWrapperCtrl.prototype.searchByTerm = function searchByTerm(term) {
        var matchesContain = [],
            matchesStartWith = [],
            pos
        ;

        if (term === null) {
            return this.sEmojiSheet.byCategory;
        }

        this.sEmojiSheet.aliases.map(function (element) {
            if ((pos = element.indexOf(term.slice(1))) !== -1) {
                if (pos === 0) {
                    matchesStartWith.push(element);
                } else {
                    matchesContain.push(element);
                }
            }
        });

        matchesContain.sort(function (a, b) {
            return a.toLowerCase().replace(/[_\-]/gm, '') < b.toLowerCase().replace(/[_\-]/gm, '') ? -1 : 1;
        });

        matchesStartWith.sort(function (a, b) {
            return a.length - b.length;
        });


        return matchesStartWith.concat(matchesContain);
    };

    ns.sEmojiPickerWrapper = PickerWrapperCtrl;
})(Object.namespace('Controller.Directive'));