(function (ns) {
    var KEY_FORM_NAME           = 'source',
        KEY_TIME_UPDATED        = 'timeUpdated',
        KEY_UUID                = 'uuid',
        VALUE_CONTEXT_SPECIFIC  = 'specific',
        STATUS_ACTIVE           = 'active',
        STATUS_INACTIVE         = 'inactive';

    /**
     * @namespace
     * @alias Model.Source
     * @extends Model.RESTAccessByUUID
     *
     * @constructor
     * @param {String=} uuid
     */
    var Source = function (uuid) {
        var label,
            description,
            isCollection = true,
            context,
            url,
            mapping,
            fields,
            timeUpdated,
            type,
            domainId,
            placeholders = [],
            placeholdersCache = [],
            updatePlaceholdersCache = function updatePlaceholdersCache() {
                placeholdersCache.splice.apply(placeholdersCache, [0, placeholdersCache.length].concat(placeholders));
            },
            addPlaceholder =
                /**
                 * @param {Model.Source.Placeholder} placeholder
                 * @return {Model.Source}
                 */
                function addPlaceholder(placeholder) {
                    Object.instanceOf(placeholder, Model.Source.Placeholder);

                    if (placeholders.indexOf(placeholder) !== -1) {
                        return;
                    }

                    if (placeholder.source !== this) {
                        return;
                    }

                    placeholders.push(placeholder);
                    updatePlaceholdersCache();
                    return this;
                }
        ;

        Object.defineProperties(
            this,
            {
                label              : {
                    enumerable : true,
                    get        : function () {
                        return label;
                    },
                    set        : function (val) {
                        label = val;
                    }
                    /**
                     * @property
                     * @name Model.Source#label
                     * @type {String}
                     */
                },
                domainId           : {
                    enumerable : true,
                    writable   : true
                    /**
                     * @property
                     * @name Model.Source#domainId
                     * @type {String}
                     */
                },
                description        : {
                    enumerable : true,
                    get        : function () {
                        return description;
                    },
                    set        : function (val) {
                        description = val;
                    }
                    /**
                     * @property
                     * @name Model.Source#description
                     * @type {String}
                     */
                },
                url                : {
                    enumerable : true,
                    get        : function () {
                        return url;
                    },
                    set        : function (val) {
                        url = val;
                    }
                    /**
                     * @property
                     * @name Model.Source#url
                     * @type {String}
                     */
                },
                mapping            : {
                    enumerable : true,
                    get        : function () {
                        return mapping;
                    },
                    set        : function (val) {
                        if (val && typeof val === 'string') {
                            val = JSON.parse(val);
                        }

                        mapping = val;
                    }
                    /**
                     * @property
                     * @name Model.Source#mapping
                     * @type {Object}
                     */
                },
                fields             : {
                    enumerable : true,
                    get        : function () {
                        return fields;
                    },
                    set        : function (val) {
                        fields = val;
                    }
                    /**
                     * @property
                     * @name Model.Source#fields
                     * @type {Array}
                     */
                },
                timeUpdated        : {
                    enumerable : true,
                    get        : function () {
                        return timeUpdated;
                    },
                    set        : function (val) {
                        if (!moment.isMoment(val)) {
                            val = moment(val);
                        }

                        timeUpdated = val;
                    }
                    /**
                     * @property
                     * @name Model.Source#timeUpdated
                     * @type {Moment}
                     */
                },
                placeholders       : {
                    enumerable : true,
                    get        : function () {
                        if (placeholdersCache.length !== placeholders.length) {
                            updatePlaceholdersCache();
                        }
                        return placeholdersCache;
                    }
                    /**
                     * @property
                     * @name Model.Source#placeholders
                     * @type {[]}
                     */
                },
                fullyQualifiedName : {
                    get : function () {
                        return label;
                    }
                    /**
                     * @property
                     * @name Model.Source#fullyQualifiedName
                     * @type {String}
                     */
                },
                isCollection       : {
                    enumerable : true,
                    get        : function () {
                        return isCollection;
                    },
                    set        : function (val) {
                        isCollection = Boolean(val);
                    }
                    /**
                     * @property
                     * @name Model.Source#isCollection
                     * @type {Boolean}
                     */
                },
                context            : {
                    enumerable : true,
                    writable   : true
                    /**
                     * @property
                     * @name Model.Source#context
                     * @type {String}
                     */
                },
                status             : {
                    enumerable : true,
                    writable   : true
                    /**
                     * @property
                     * @name Model.Source#status
                     * @type {String}
                     */
                },
                type               : {
                    enumerable : true,
                    get        : function () {
                        return type;
                    },
                    set        : function (val) {
                        if (val === type) {
                            return;
                        }

                        if (val !== null) {
                            Object.instanceOf(val, Model.Source.Type);
                        }

                        type = val;
                    }
                    /**
                     * @property
                     * @name Model.Source#type
                     * @type {Model.Source.Type}
                     */
                }
            }
        );

        var protecteds = Source._pProto.constructor.call(this, uuid);

        this.setAsExisting = function() {
            protecteds.setAsNew(false);
        };

        // make function public
        this.addPlaceholder = addPlaceholder;
    };

    Object.extendProto(Source, Model.RESTAccessByUUID);

    /**
     * @function
     * @name Model.Source#updateByData
     * @param {Object} data
     */
    Source.prototype.updateByData = function updateByData(data) {
        if (data.timeUpdated) {
            data.timeUpdated *= 1000;
        }

        if (data.type) {
            data.type = Model.Source.Type.createByData(data.type);
        }

        Object.updateByData(this, data);
        Source._pProto.updateByData.call(this);

        if (data.placeholders && typeof data.placeholders.map === 'function') {
            var self = this;

            data.placeholders.map(function (placeholderData) {
                placeholderData.source = self;
                self.addPlaceholder(Model.Source.Placeholder.createByData(placeholderData));
            });
        }

        if (data.timeUpdated) {
            data.timeUpdated /= 1000;
        }
    };

    /**
     * @function
     * @name Model.Source.createByData
     *
     * @param {{
     *      placeholders    : [{
     *          id          : Number=,
     *          label       : String,
     *          token       : String,
     *          type        : String,
     *          description : String=,
     *          url         : String=
     *      }]=,
     *      uuid            : String|Integer,
     *      label           : String,
     *      description     : String=,
     *      isCollection    : Boolean=,
     *      context         : String=,
     *      mapping         : String=,
     *      timeUpdated     : String=
     *  }} data
     *
     * @returns {Model.Source}
     */
    Source.createByData = function createByData(data) {
        var source = new Source(data.uuid ? data.uuid : null);
        source.updateByData(data);

        return source;
    };

    /**
     * @name Model.Source.getFormData
     * @param {FormData=} formData
     * @param {string=} name
     *
     * @returns FormData
     */
    Source.prototype.getFormData = function getFormData(formData, name) {
        name = name || KEY_FORM_NAME;
        formData = Source._pProto.getFormData.call(this, formData);
        var sourceData = {};

        for (var key in this) {
            if (this.hasOwnProperty(key) && !(this[key] instanceof Function)) {
                if (key === KEY_TIME_UPDATED || key === KEY_UUID) {
                    continue;
                }

                sourceData[key] = this[key];
            }
        }

        formData.append(name, JSON.stringify(sourceData));

        return formData;
    };

    /**
     * @name Model.Source.hasCompleteMapping
     *
     * @returns {Boolean}
     */
    Source.prototype.hasCompleteMapping = function hasCompleteMapping() {
        if (!this.mapping) {
            return false;
        }

        for (var key in this.mapping) {
            if (this.mapping.hasOwnProperty(key) && this.mapping[key] === null) {
                return false;
            }
        }

        return true;
    };

    /**
     * @name Model.Source#isActive
     *
     * @returns {Boolean}
     */
    Source.prototype.isActive = function isActive() {
        return this.status === STATUS_ACTIVE;
    };

    /**
     * @name Model.Source.initMapping
     */
    Source.prototype.initMapping = function initMapping() {
        var self = this,
            mapping = {}
        ;

        if (this.type && this.type.fields && this.type.fields.length > 0) {
            var hasFields = this.fields && this.fields.length > 0;

            this.type.fields.map(function (field) {
                mapping[field.name] = (hasFields && self.fields.indexOf(field.name) !== -1) ? field.name : null;
            });
        }

        this.mapping = mapping;
    };

    Object.defineProperties(
        Source,
        {
            VALUE_CONTEXT_SPECIFIC : {
                value : VALUE_CONTEXT_SPECIFIC
                /**
                 * @property
                 * @constant
                 * @name Model.Source.VALUE_CONTEXT_SPECIFIC
                 * @type {String}
                 */
            },
            STATUS_ACTIVE          : {
                value : STATUS_ACTIVE
                /**
                 * @property
                 * @constant
                 * @name Model.Source.STATUS_ACTIVE
                 * @type {String}
                 */
            },
            STATUS_INACTIVE        : {
                value : STATUS_INACTIVE
                /**
                 * @property
                 * @constant
                 * @name Model.Source.STATUS_INACTIVE
                 * @type {String}
                 */
            }
        }
    );

    ns.Source = Source;
})(Object.namespace('Model'));
