(function(ns) {
    /**
     * @namespace
     * @alias Model.RepairCase
     *
     * @param {Function} checkFn
     * @param {Function=} repairFn
     * @param {Object=} options
     * @constructor
     */
    var RepairCase = function(checkFn, repairFn, options) {
            Object.instanceOf(checkFn, Function);

            this.check = function check(args) {
                try {
                    checkFn.apply(this, args);
                }
                catch (e) {
                    this.errorMessages = e.messages;
                    return false;
                }
                return true;
            };

            this.repair = function repair(args) {
                if (!repairFn) {
                    return;
                }
                repairFn.apply(this, args);
            };

            this.options        = options;

            Object.defineProperties(
                this,
                {
                    hasRepairFn: {
                        enumerable: true,
                        get: function () {
                            return !!repairFn;
                        }
                        /**
                         * @property
                         * @name Model.RepairCase#hasRepairFn
                         * @type {Boolean}
                         */
                    }
                    
                });

            this.errorMessages  = [];
        };


    ns.RepairCase = RepairCase;

})(Object.namespace('Model'));

