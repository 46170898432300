(function (ns) {
    var KEY_FIELD_NAME      = 'g-recaptcha-response-v3',
        KEY_FIELD_SELECTOR  =  '[name="' + KEY_FIELD_NAME + '"]'
    ;

    /**
     * @namespace
     * @alias Controller.Component.ReCaptcha
     *
     * @constructor
     * @param $element
     * @param {Service.ReCAPTCHA} ReCAPTCHA
     */
    var ReCaptcha = function ($element, ReCAPTCHA) {
        this.$element           = $element;
        this.ReCAPTCHAService   = ReCAPTCHA;
        this.$deRegister        = [];

        /**
         * @property
         * @name Controller.Component.ReCaptcha#siteKey
         * @type {String}
         */

        /**
         * @property
         * @name Controller.Component.ReCaptcha#version
         * @type {String|Number}
         */
    };
    
    ReCaptcha.prototype.$onInit = function $onInit() {
        var parentEl        = this.formCtrl.$$element.parent()[0],
            submitListener  = this.handleFormSubmit.bind(this)
        ;
        parentEl.addEventListener('submit', submitListener, true);
        this.$deRegister.push(function() {
            parentEl.removeEventListener('submit', submitListener, true);
        });
    };

    ReCaptcha.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    /**
     * @param {Object.<String, SimpleChange>} $changes
     */
    ReCaptcha.prototype.$onChanges = function $onChanges($changes) {
        var self = this;
        if ($changes.version && $changes.version.currentValue !== $changes.version.previousValue) {
            this.ReCAPTCHAService.reload(parseInt($changes.version.currentValue) === 3 ? this.siteKey : null).then(function() {

                if (parseInt($changes.version.currentValue) === 2) {
                    self.ReCAPTCHAService.render(self.siteKey, self.formCtrl.$$element.find('[type="submit"]')[0], function () {
                        self.formCtrl.$$element.submit();
                        self.ReCAPTCHAService.reset();
                    });
                }

                if (!$changes.version.isFirstChange()) {
                    self.ReCAPTCHAService.reset();
                    self.formCtrl.$$element.find(KEY_FIELD_SELECTOR).remove();
                }
            });
        }
    };

    /**
     * @param event
     * @return {boolean}
     */
    ReCaptcha.prototype.handleFormSubmit = function handleFormSubmit(event) {
        // only execute for v3
        if (parseInt(this.version) !== 3) {
            return true;
        }

        var reCaptchaField  = this.formCtrl.$$element.find(KEY_FIELD_SELECTOR),
            self            = this
        ;

        if (reCaptchaField.length && this.ReCAPTCHAService.lastResponse && reCaptchaField.val() === this.ReCAPTCHAService.lastResponse) {
            return true;
        }

        event.cancelBubble = true;
        event.preventDefault();

        if (!reCaptchaField.length) {
            reCaptchaField = $('<input type="hidden" name="' + KEY_FIELD_NAME + '">');
            this.formCtrl.$$element.prepend(reCaptchaField);
        }

        if (!this.ReCAPTCHAService.lastResponse) {
            this.ReCAPTCHAService.execute(this.siteKey, this.formCtrl.$$element.attr('name')).then(function(lastToken) {
                reCaptchaField.val(lastToken);
                self.formCtrl.$$element.submit();
            });
        }
    };

    ns.ReCaptcha = ReCaptcha;
})(Object.namespace('Controller.Component'));