(function (ns) {
    /**
     * @namespace
     * @alias Model.Connection
     *
     * @param {Model.Message.Relation} relationA
     * @param {Model.Message.Relation=} relationB
     * @constructor
     * @extends Model.UUID
     */
    var Connection = function(relationA, relationB) {
        var label;

        Object.defineProperties(
            this,
            {
                relationA: {
                    get: function() {
                        return relationA;
                    }
                    /**
                     * @type Model.Message.Relation
                     * @name Model.Connection#relationA
                     */
                },
                relationB: {
                    get: function() {
                        return relationB;
                    }
                    /**
                     * @type Model.Message.Relation
                     * @name Model.Connection#relationB
                     */
                },
                type: {
                    get: function() {
                        return relationA.type;
                    }
                    /**
                     * @type String
                     * @name Model.Connection#type
                     */
                },
                label: {
                    get: function() {
                        if (!label) {
                            var msg, cta;
                            if (relationA.to.isIncoming) {
                                msg = relationA.to;
                            } else if (relationB) {
                                msg = relationB.to;
                            } else {
                                msg = relationA.from;
                            }

                            if ((cta = msg.getCTAByActionUuid(relationA.options.uuid)) instanceof Model.CTA) {
                                label = cta.label;
                            }

                            if (msg.firstPart().content.intent) {
                                label = msg.firstPart().content.intent;
                            }

                            if (msg.firstPart().content.body) {
                                label = msg.firstPart().content.body
                            }
                        } else {
                            return label;
                        }
                    }
                    /**
                     * @type String
                     * @name Model.Connection#label
                     */
                }
            }
        );

        Connection._pProto.constructor.call(this);
    };

    Object.extendProto(Connection, Model.UUID);

    /**
     * @name Model.Connection#getRouteUuid
     * @param {Boolean=} invert
     * @returns {string}
     */
    Connection.prototype.getRouteUuid = function getRouteUuid(invert) {
        if (invert) {
            return this.getToMessage().uuid + '-' + this.getFromMessage().uuid;
        }
        return this.getFromMessage().uuid + '-' + this.getToMessage().uuid;
    };

    /**
     * @name Model.Connection#getFromMessage
     * @returns {Model.Message}
     */
    Connection.prototype.getFromMessage = function getFromMessage() {
        return this.relationA.from;
    };

    /**
     * @name Model.Connection#getToMessage
     * @returns {Model.Message}
     */
    Connection.prototype.getToMessage = function getToMessage() {
        return this.relationB ? this.relationB.to : this.relationA.to;
    };

    ns.Connection = Connection;
})(Object.namespace('Model'));





