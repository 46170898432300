(function (angular) {

    var module = angular.module('sDomainModule', []);

    module
        .provider('sDomainService', sDomain.Provider.sDomain)
        .service('sDomainLogoService', sDomain.Service.sDomainLogo)
    ;

})(angular);
