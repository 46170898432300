(function(ns) {
    var URL_MEMBER_CHATBOT_DISABLE = 'member.botDisableUntil';

    /**
     * @namespace
     * @alias Controller.Component.sMemberChatBotStatus
     * @constructor
     *
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param $scope
     */
    var sMemberChatBotStatus = function sMemberChatBotStatus(sAPIAccess, $scope) {
        var self = this,
            urlFunction = function(method) {
                var endPoint = sAPIAccess.endpoint(URL_MEMBER_CHATBOT_DISABLE);

                switch (method) {
                    case Const.Method.POST:
                        return endPoint.post(self.memberId);
                    case Const.Method.DELETE:
                        return endPoint.delete(self.memberId);
                    default:
                        return endPoint.get(self.memberId);
                }
            }
        ;

        sMemberChatBotStatus._pProto.constructor.call(this, urlFunction, $scope);

        Object.defineProperties(
            this,
            {
                disabledUntil: {
                    get: function() {
                        return this.rawState * 1000;
                    }
                }
            }
        );

        this.label = 'Deactivate chatbot';
    };

    Object.extendProto(sMemberChatBotStatus, Controller.Component.sAsyncCheckbox);
    
    Object.defineProperties(
        sMemberChatBotStatus,
        {
            URL_MEMBER_CHATBOT_DISABLE: {
                value: URL_MEMBER_CHATBOT_DISABLE
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sMemberChatBotStatus#URL_MEMBER_CHATBOT_DISABLE
                 * @type {String}
                 */
            }
        });

    ns.sMemberChatBotStatus = sMemberChatBotStatus;
})(Object.namespace('Controller.Component'));
