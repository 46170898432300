(function (ns) {
    /**
     * @namespace
     * @alias Controller.Directive.ReplaceFilters
     *
     * @constructor
     * @param $scope
     * @param $element
     */
    var ReplaceFilters = function ($scope, $element) {
        this.$scope   = $scope;
        this.$element = $element;
    };

    ReplaceFilters.prototype.$postLink = function $postLink() {
        var self = this;
        var replaceFilters = this.replaceFilters;

        // Convert search strings to regular expressions
        replaceFilters.map(function (replaceFilter) {
            replaceFilter.search = new RegExp(replaceFilter.search, replaceFilter.flags ? replaceFilter.flags : '');
        });

        function addParserToNgModelCtrl(ngModelCtrl) {
            ngModelCtrl.$parsers.unshift(function (viewValue) {
                if (!viewValue || !(viewValue.replace instanceof Function)) {
                    return viewValue;
                }

                var modelValue = replaceFilters.reduce(function (carry, filter) {
                    if (!carry || !carry.replace) {
                        return carry;
                    }
                    return carry.replace(filter.search, filter.replace);
                }, viewValue);


                if (modelValue !== viewValue) {
                    ngModelCtrl.$setViewValue(modelValue);
                    ngModelCtrl.$render();
                }

                return modelValue;
            });
        }

        if (this.$ngModelCtrl) {
            addParserToNgModelCtrl(this.$ngModelCtrl);
            return true;
        }

        var $deferredBinding = this.$scope.$watch(function () {
            return self.$element.find('[ng-model]');
        }, function (element) {
            if (!element.length) {
                return;
            }

            // remove watcher as the ngModelCtrl has been found
            $deferredBinding();
            addParserToNgModelCtrl(element.controller('ngModel'));
        });
    };

    ns.ReplaceFilters = ReplaceFilters;
})(Object.namespace('Controller.Directive'));
