(function(ns){
    var VALUE_AR_WIDE = 1.91;

    /**
     * @namespace
     * @alias Model.Message.Content
     *
     * @param {Object} fields
     * @param {String=} uuid
     *
     * @extends Model.UUID
     */
    var Content = function(fields, uuid) {
        var fragments           = {},
            storedFieldConfig   = {},
            self                = this,
            parent,
            version             = 1,
            mediaToResolve      = null,
            mediaDeferred       = null
            ;

        // add expose placeholder behavior with the option to follow placeholders form the parent
        Model.Behavior.ExposePlaceholder.call(
            this,
            function () {
                return false;
            },
            'parent'
        );

        $.each(fields, function(index, element) {
            if (element.options && element.options.isMeta) {
                return true;
            }

            /**
             * ensure that the property name is not an integer
             * there are two cases
             * 1.
             * ['element1', ..., 'elementN']
             * 2.
             * {'element1': { options }, ..., {'elementN' : { options }}
             */
            var name            = (index instanceof Number) ? element : index,
                options         = element.options || {}
                ;

            // store field-config for validation
            storedFieldConfig[name] = element;
            // set initial value
            // check if function
            if (element.default instanceof Function) {
                fragments[name] = element.default.apply(self);
                // grants the ability to return promises in default init functions
                // eg placeholder images get resolved like that
                if (fragments[name].then) {
                    fragments[name].then(function (data) {
                        fragments[name] = data;
                    })
                }
            }
            // check if string
            else if (element.default instanceof String || typeof element.default === 'string') {
                fragments[name] = element.default;
            }
            // should be Object or Array
            else if (typeof element.default !== 'undefined') {
                fragments[name] = element.default.clone();
            }
            else {
                fragments[name] = element.default;
            }

            if (options.highlightFromContent) {
                // if highlighted, shall not be visible in the content
                Object.defineProperty(fragments, name, { enumerable  : false });
            }

            /**
             * define property accessors
             */
            Object.defineProperty(
                self,
                name,
                {
                    // property shall only be enumerable, if highlightFromContent is specified in the type descriptor
                    enumerable  : Boolean(options.highlightFromContent),
                    configurable: true,
                    get         : function() {
                        return fragments[name];
                    },
                    set         : function(val) {
                        if (fragments[name] === val) {
                            return;
                        }

                        fragments[name] = val;
                        if (this.parent) {
                            this.parent.resetCache();
                        }
                        version++;
                    }
                }
            );
        });

        Object.defineProperties(
            this,
            {
                version: {
                    get: function() {
                        return version;
                    }
                },
                preview: {
                    get: function () {
                        return 'Message';
                    }
                    /**
                     * What the content should return a s short preview
                     * @property
                     * @type {String}
                     * @name Model.Message.Content#preview
                     */
                },
                content: {
                    enumerable  : true,
                    get         : function() {
                        return fragments;
                    }
                    /**
                     * Exposes non highlighted content
                     * @property
                     * @type {Object}
                     * @name Model.Message.Content#content
                     */
                },
                fieldNames: {
                    get: function() {
                        return Object.getOwnPropertyNames(fragments);
                    }
                    /**
                     * Returns all available field names, regardless if highlighted or not
                     * @property
                     * @type {String[]}
                     * @name Model.Message.Content#fieldNames
                     */
                },
                parent: {
                    get: function() {
                        return parent;
                    },
                    set: function(val) {
                        if (val) {
                            Object.instanceOf(val, Model.Message.Part);
                        }

                        if (val !== parent) {
                            if (parent) {
                                parent.removeContent(self);
                            }
                            parent = val;
                            parent.addContent(self);
                        }
                        this.resetContextSpecificPlaceholderCache();
                    }
                    /**
                     * @property
                     * @type {?Model.Message.Part}
                     * @name Model.Message.Content#parent
                     */
                }
            }
        );

        this.isFieldHighlighted = function(fieldName) {
            return this.propertyIsEnumerable(fieldName);
        };

        this.getFieldConfig = function getFieldConfig(fieldName) {
            if (storedFieldConfig[fieldName]) {
                return storedFieldConfig[fieldName];
            }
            return false;
        };

        this.getFieldLength = function getFieldLength(key) {
            if (this.parent.parent.isIncoming) {
                return undefined;
            }
            var length;

            try {
                length = this.getFieldConfig(key).options.attrs['max-length'];
            } catch (e) {
                if (e instanceof ReferenceError) {
                    return undefined;
                }
            }

            return length;
        };

        /**
         * Override for proto function to access private property mediaToResolve
         * @param {Object} messageContentData
         * @return PromiseLike
         */
        this.updateByData = function updateByData(messageContentData) {
            mediaDeferred = $.Deferred();
            Object.getPrototypeOf(this).updateByData.call(this, messageContentData).progress(function(obj) {
                mediaToResolve = obj;
            }).then(function () {
                mediaDeferred.resolve();
            }, function () {
                mediaDeferred.reject();
            });

            return mediaDeferred.promise();
        };

        /**
         * @param {Model.Source.Placeholder[]} placeholders
         */
        this.resolvePlaceholderMediaIfAny = function resolvePlaceholderMediaIfAny(placeholders) {
            var self = this;
            if (!mediaToResolve) {
                return;
            }

            var foundPlaceholder = placeholders.filter(function(placeholder) {
                return placeholder.token === mediaToResolve.url;
            }).pop();

            if (!foundPlaceholder) {
                return;
            }

            if (foundPlaceholder.url) {
                Model.sFile.loadFromUrl(foundPlaceholder.url, {fileName: '@' + foundPlaceholder.fullyQualifiedName, url: foundPlaceholder.token}).then(function(file) {
                    self.handleFileLoadedFromUrl(file, mediaToResolve).then(function () {
                        mediaDeferred && mediaDeferred.resolve();
                    },
                    function () {
                        mediaDeferred && mediaDeferred.reject();
                    });
                });
                return;
            }

            var blob = new Blob([]);
            blob.fileName = foundPlaceholder.fullyQualifiedName;
            self.handleFileLoadedFromUrl(blob, mediaToResolve).then(function () {
                   mediaDeferred && mediaDeferred.resolve();
                },
                function () {
                    mediaDeferred && mediaDeferred.reject();
                });
        };

        /**
         * Duplicates the current content by creating a content model
         * with the same fields and replace certain uuids.
         *
         * @return {Model.Message.Content}
         */
        this.duplicate = function duplicate() {
            var ctaCollectionFields     = self.getFieldNamesOfType(Model.CTA.Collection),
                actionCollectionFields  = self.getFieldNamesOfType(Model.Action.Collection),
                contentData             = JSON.parse(JSON.stringify(self)),
                contentDuplicate        = new Content(fields),
                i,
                j
            ;

            function walkActionCollection(actionCollection) {
                var k;

                for (k = 0; k < actionCollection.actions.length; k++) {
                    actionCollection.actions[k].uuid = new Model.UUID().uuid;
                }
            }

            for (i = 0; i < ctaCollectionFields.length; i++) {
                if (!contentData.content.hasOwnProperty(ctaCollectionFields[i])) {
                    continue;
                }

                for (j = 0; j < contentData.content[ctaCollectionFields[i]].ctas.length; j++) {
                    contentData.content[ctaCollectionFields[i]].ctas[j].uuid = new Model.UUID().uuid;

                    walkActionCollection(contentData.content[ctaCollectionFields[i]].ctas[j].actions);
                }
            }

            for (i = 0; i < actionCollectionFields.length; i++) {
                if (contentData.content.hasOwnProperty(actionCollectionFields[i])) {
                    walkActionCollection(contentData.content[actionCollectionFields[i]]);
                }
            }

            contentDuplicate.updateByData(contentData);

            if (!self.media) {
                return contentDuplicate;
            }

            self.media.loadContent().then(
                /**
                 * @param {Model.sFile} sFile
                 */
                function (sFile) {
                    var blob = dataURItoBlob(sFile.content);

                    if (sFile.file.fileName) {
                        blob.fileName = blob.name = sFile.file.fileName;
                    }

                    if (sFile.url) {
                        Model.sFile.extendBlobWithUrl(blob, sFile.url);
                    }

                    contentDuplicate.media = self.media instanceof Model.sImage
                        ? new Model.sImage(blob)
                        : new Model.sFile(blob)
                    ;
                });

            return contentDuplicate;
        };

        /**
         * @name Model.Message.Content#waitForMediaToLoad
         * @returns {$.Deferred}
         */
        this.waitForMediaToLoad = function waitForMediaToLoad() {
            return mediaDeferred ? mediaDeferred.promise() : $.Deferred().resolve();
        };


        this.__getCloneArguments = function() {
            return [fields, this.uuid];
        };

        this.__dontCloneProperties = function() {
            return ['parent', 'content'];
        };

        Object.defineProperty(this, '__getCloneArguments', {enumerable: false});

        Content._pProto.constructor.call(this, uuid);
    };

    Object.extendProto(Content, Model.UUID);

    /**
     * Updates the message-content by data
     * @function Model.Message.Content#updateByData
     * @param {object} messageContentData
     * @return {$.Deferred}
     */
    Content.prototype.updateByData = function updateByData(messageContentData) {
        var $deferred = $.Deferred();

        /*
         * Handle MessageContent-Content
         */
        if (messageContentData.content) {
            // migrate from legacy structure
            if (messageContentData.content.rules) {
                messageContentData.content.matches = messageContentData.content.rules;
                delete(messageContentData.content.rules);
            }

            var parentPreserve = this.parent;
            Object.updateByData(this, messageContentData.content);
            this.parent = parentPreserve;

            if (this.alternatives) {
                this.alternatives.ctas.map(function(cta) {
                    cta.label = cta.label.replace(/^\#/, '');
                });
            }
        }

        // Media is passed as base64 encoded image - migrate it to sImage
        if (messageContentData.media && messageContentData.media.mediaAsBase64) {
            var blob = dataURItoBlob(messageContentData.media.mediaAsBase64);

            this.media = new Model.sImage(blob);

            if (messageContentData.media.croppingData) {
                this.media.setResizedImageContent(messageContentData.media.croppingData, messageContentData.media.croppingOptions).always(
                    function () {
                        $deferred.resolve();
                    }
                );
            } else {
                $deferred.resolve();
            }

            return $deferred.promise();
        }

        if (!messageContentData.media || !messageContentData.media.url) {
            $deferred.resolve();
            return $deferred.promise();
        }

        // if the media url contains '@' that indicates we have a placeholder inside (urls can't have '@')
        if (messageContentData.media.url.search('@') !== -1) {
            messageContentData.media.uuid = new Model.UUID().uuid;
            $deferred.notify(messageContentData.media);

            return $deferred.promise();
        }

        Model.sFile.loadFromUrl(messageContentData.media.url, messageContentData.media).then(this.handleFileLoadedFromUrl.bind(this)).always(function() {
            $deferred.resolve();
        });

        return $deferred.promise();
    };

    /**
     *
     * @param {Blob} blob
     * @param {Object} mediaData
     * @return {$.Deferred}
     */
    Content.prototype.handleFileLoadedFromUrl = function handleFileLoadedFromUrl(blob, mediaData) {
        var self      = this,
            $deferred = $.Deferred()
        ;

        if (!blob) {
            $deferred.resolve();
            return $deferred;
        }

        if (blob.type && blob.type.search('image') !== -1) {
            this.media = new Model.sImage(blob, mediaData.uuid);
            if (mediaData.related && mediaData.related instanceof Array && mediaData.related.length) {
                var related = mediaData.related.pop() || {},
                    /**
                     * @type {{crop}}
                     */
                    options = related.options || {}
                ;

                if (!related.url) {
                 return this.media.loadContent();
                }

                return Model.sFile.loadFromUrl(related.url).then(function(file) {
                    file = new Model.sFile(file);
                    return file.loadContent().then(function() {
                        return self.media.setResizedImageContent(
                            file.content,
                            options.crop,
                            related.uuid,
                            related.url
                        ).then(function () {
                            self.parent.parent.setJSONState();
                        });
                    });
                })
            }
        } else {
            this.media = new Model.sFile(blob, mediaData.uuid);
            return this.media.loadContent();
        }

        if (this.parent && this.parent.parent) {
            this.parent.parent.setJSONState();
        }

        $deferred.resolve();
        return $deferred;
    };

    /**
     * @function Model.Message.Content#repair
     */
    Content.prototype.repair = function repair() {
        var fieldName;

        for (fieldName in this.content) {
            if (this.content.hasOwnProperty(fieldName)
                && this.content[fieldName] instanceof Object
                && this.content[fieldName].repair instanceof Function
            ) {
                this.content[fieldName].repair();
            }
        }
    };

    /**
     * @function
     * @name Model.Message.Content#isEmpty
     */
    Content.prototype.isEmpty = function isEmpty(_ignores) {
        var names   = this.fieldNames,
            isEmpty = true,
            ignores = _ignores || []
            ;

        for (var i = 0; i < names.length; i++) {
            if (ignores.indexOf(names[i]) !== -1) {
                continue;
            }
            isEmpty = isEmpty && (!this.content[names[i]] || this.content[names[i]].toString().length === 0);
        }


        return isEmpty;
    };

    /**
     * @name Model.Message.Content#getElementsOfType
     * @param {Object} type
     * @returns {*[]}
     */
    Content.prototype.getElementsOfType = function getElementsOfType(type) {
        var fieldNames  = this.fieldNames,
            matches     = []
            ;

        for (var i = 0; i < fieldNames.length; i++) {
            if (this[fieldNames[i]] instanceof type) {
                matches.push(this[fieldNames[i]]);
            }
        }

        return matches;
    };

    /**
     * @name Model.Message.Content#getFieldNamesOfType
     * @param {Object} type
     * @returns {String[]}
     */
    Content.prototype.getFieldNamesOfType = function getFieldNamesOfType(type) {
        var fieldNames = [];

        for (var i = 0; i < this.fieldNames.length; i++) {
            if (this[this.fieldNames[i]] instanceof type) {
                fieldNames.push(this.fieldNames[i]);
            }
        }

        return fieldNames;
    };

    /**
     * @name Model.Message.Content#validate
     * @returns {boolean}
     */
    Content.prototype.validate = function validate() {
        var fieldConfig,
            fieldName,
            dependentValidations = {};

        for (var i = 0; i<this.fieldNames.length; i++) {
            if (!this.hasOwnProperty(this.fieldNames[i])) {
                continue;
            }

            fieldName = this.fieldNames[i];
            fieldConfig = this.getFieldConfig(fieldName);

            if (this[fieldName] instanceof Model.CTA.Collection && !this[fieldName].validate()) {
                return false;
            }

            if (!fieldConfig
                || !fieldConfig.component
                || !fieldConfig.options
                || !fieldConfig.options.attrs
                || fieldConfig.options.dontValidate) {
                continue;
            }

            for (var attr in fieldConfig.options.attrs) {
                if (!fieldConfig.options.attrs.hasOwnProperty(attr)) {
                    continue;
                }

                if (!this.validateField(
                        this[fieldName],
                        fieldConfig.component,
                        attr,
                        fieldConfig.options.attrs[attr],
                        dependentValidations
                    )) {
                    return false;
                }
            }
        }

        var dependentFieldsValid =  this.validateDependentFields(dependentValidations);

        // the product should not expose a placeholder if the proper context is missing
        if (!Object.prototype.hasOwnProperty.call(this, 'product')
            || this.product.productId !== Model.Message.Part.TOKEN_PRODUCT_ID) {
            return dependentFieldsValid;
        }

        if (!this.parent || !this.parent.parent) {
            return false;
        }

        var action = this.parent.parent.getOriginatingDynamicContentAction();
        // make it fail when there is no such action or the action's catalogId doesn't match
        if (!action || JSON.parse(action.value).sources_id !== this.product.catalogId) {
            return false;
        }

        return dependentFieldsValid;
    };

    /**
     * @name Model.Message.Content#validateField
     * @param {*} fieldValue
     * @param {String} componentName
     * @param {String} attrToken
     * @param {*} attrValue
     * @param {Object} dependentValidations
     * @returns {boolean}
     */
    Content.prototype.validateField = function validateField(fieldValue,
                                                             componentName,
                                                             attrToken,
                                                             attrValue,
                                                             dependentValidations) {
        switch (attrToken) {
            case 'max-length':
            case 's-maxlength':
                return Validator.maxLength(fieldValue, attrValue);
            case 'is-required':
            case 'ng-required':
            case 'required':
                return attrValue !== true || Validator.required(fieldValue);
            case 'required-any':
                if (typeof dependentValidations[attrToken] === 'undefined') {
                    dependentValidations[attrToken] = {};
                }
                dependentValidations[attrToken][attrValue] = !!dependentValidations[attrToken][attrValue] || Validator.required(fieldValue);
                break;
        }
        return true;
    };

    /**
     * @param {Object} dependentValidations
     * @returns {boolean}
     */
    Content.prototype.validateDependentFields = function validateDependentFields(dependentValidations) {
        for (var attr in dependentValidations) {
            if (!dependentValidations.hasOwnProperty(attr)) {
                continue;
            }

            switch (attr) {
                case 'required-any':
                    for (var group in dependentValidations[attr]) {
                        if (dependentValidations[attr].hasOwnProperty(group) && !dependentValidations[attr][group]) return false;
                    }
            }
        }
        return true;
    };

    /**
     * @return {Number}
     */
    Content.prototype.getMediaAspectRatio = function getMediaAspectRatio() {
        var parent = this.parent;
        if ([Model.Message.Part.TYPE_LISTVIEW, Model.Message.Part.TYPE_CAROUSEL].indexOf(parent.type) === -1) {
            return 0;
        }

        if (parent.type === Model.Message.Part.TYPE_CAROUSEL) {
            return this.parent.coverOption ? VALUE_AR_WIDE : 1;
        }

        return this.parent.coverOption && this.parent.contentIndex(this) === 0 ? VALUE_AR_WIDE : 1;
    };

    ns.Content = Content;
})(Object.namespace('Model.Message'));
