(function(ns) {

    /**
     * @namespace
     * @alias sSecurity.Controller.Login
     *
     * @param $scope
     * @param $window
     * @param apiEndpoints
     * @param sMenuAuthWrapper
     * @param $templateCache
     * @param $route
     * @param captchaConfig
     * @constructor
     */
    var Login = function (
        $scope,
        $window,
        apiEndpoints,
        sMenuAuthWrapper,
        $templateCache,
        $route,
        captchaConfig
    ) {
        this.$scope           = $scope;
        this.$window          = $window;
        this.apiEndpoints     = apiEndpoints;
        this.sMenuAuthWrapper = sMenuAuthWrapper;
        this.$templateCache   = $templateCache;
        this.$route           = $route;
        this.captchaConfig    = captchaConfig;
        this.hideTabs         = $route.mfaAuthenticationInProgress === true
            || sMenuAuthWrapper.mfaAuthenticationInProgress === true;

        this.ajaxHandlers = [
            this.handleAjaxSuccess.bind(this),
            this.handleAjaxError.bind(this),
            this.handleAjaxStart.bind(this)
        ];

        if (!captchaConfig.versions || Object.isEmpty(captchaConfig.versions)) {
            return;
        }


        if (captchaConfig.versions['3']) {
            this.siteKey = captchaConfig.versions['3'];
            this.version = 3;
        } else {
            this.version = Object.getFirstPropertyName(captchaConfig.versions);
            this.siteKey = captchaConfig.versions[this.version];
        }
    };

    /**
     *
     * @param response
     * @param stateString
     * @param jqXhr
     */
    Login.prototype.handleAjaxSuccess = function(response, stateString, jqXhr) {
        var status  = jqXhr.status,
            path
            ;

        if (status === 203 && (path = jqXhr.getResponseHeader('X-RENDERED-FOR'))) {
            this.$templateCache.put(path, response);
            var first = this.sMenuAuthWrapper.items.slice(0,1).pop();
            first.options.templateUrl = path;

            if (titles && titles[path]) {
                first.options.title = titles[path];
            }

            if (jqXhr.getResponseHeader('X-MFA-AUTHENTICATION-IN-PROGRESS')) {
                this.$route.mfaAuthenticationInProgress = true;
            }

            this.$route.reload();
        } else if (status === 200) {
            this.$window.location.href = this.apiEndpoints.baseUrl();
        }
    };

    /**
     * @param response
     */
    Login.prototype.handleAjaxError = function(response) {
        this.errorMessage = response.responseText;

        if (parseInt(this.version) === 3 && this.captchaConfig.versions['2']) {
            this.version = 2;
            this.siteKey = this.captchaConfig.versions['2'];
        }
    };

    Login.prototype.handleAjaxStart = function() {
        this.errorMessage = null;
    };

    Object.defineProperties(
        Login,
        {
            KEY_COOKIE_REDIRECT_URL : {
                value : 'KEY_COOKIE_REDIRECT_URL'
                /**
                 * @property
                 * @constant
                 * @name sSecurity.Controller#KEY_COOKIE_REDIRECT_URL
                 * @type {String}
                 */
            }
        });

    ns.Login = Login;

})(Object.namespace('sSecurity.Controller'));