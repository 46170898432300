(function(angular) {

    var module = angular.module('sFacebookModule', ['ngMaterial', 'sFollowPubComponents']);

    module
        .service('sFacebookClient', sFacebook.Service.sFacebookClient)
        .service('sFacebookPage', sFacebook.Service.sFacebookPage)
        .service('sFacebookAdAccount', sFacebook.Service.sFacebookAdAccount)
        .service('sFacebookHandover', sFacebook.Service.sHandover)
    ;

})(angular);
