(function (ns) {

    var EVENT_GET_MANAGED_ACTION          = 'sEventCheckManagedAction',
        EVENT_UPDATE_MANAGED_ACTION_STATE = 'sEventUpdateManaged',
        EVENT_SYNC_MANAGED_ACTIONS        = 'sEventSyncManagedActions'
    ;

    /**
     * @namespace
     * @alias Controller.Directive.sActionContext
     *
     * @constructor
     */
    var sActionContext = function ($scope) {
        this.$deRegister = [];
        this.$scope      = $scope;
    };
    
    sActionContext.prototype.$onInit = function $onInit() {
        this.$deRegister.push(
            this.$scope.$on(
                Controller.Directive.MatchTextHelper.EVENT_REQUEST_GET_MANAGED_ACTION,
                this.findManagedActions.bind(this)
            )
        );

        this.$deRegister.push(
            this.$scope.$on(
                Controller.Directive.MatchTextHelper.EVENT_REQUEST_UPDATE_MANAGED_ACTION_STATE,
                this.updateManagedActionState.bind(this)
            )
        );

        this.$deRegister.push(
            this.$scope.$on(
                Controller.Component.sMatchTextCollection.EVENT_COLLECTION_ENTITIES_CHANGED,
                this.syncManagedActions.bind(this)
            )
        )
    };


    sActionContext.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    /**
     * @param {$.Event} event
     * @param {Model.UUID} payload
     * @param {[]} actions
     */
    sActionContext.prototype.findManagedActions = function findManagedActions(event, payload, actions) {
        this.$scope.$broadcast(EVENT_GET_MANAGED_ACTION, payload, actions);
    };

    /**
     * @param event
     * @param {Model.UUID} payload
     * @param {Boolean} state
     */
    sActionContext.prototype.updateManagedActionState = function updateManagedActionState(event, payload, state) {
        this.$scope.$broadcast(EVENT_UPDATE_MANAGED_ACTION_STATE, payload, state);
    };

    /**
     * @param event
     * @param  {Model.UUID[]} payload
     */
    sActionContext.prototype.syncManagedActions = function syncManagedActions(event, payload) {
        this.$scope.$broadcast(EVENT_SYNC_MANAGED_ACTIONS, payload);
    };

    Object.defineProperties(
        sActionContext,
        {
            EVENT_GET_MANAGED_ACTION: {
                value: EVENT_GET_MANAGED_ACTION
                /**
                 * @property
                 * @constant
                 * @name Controller.Directive.sActionContext#EVENT_GET_MANAGED_ACTION
                 * @type {String}
                 */
            },
            EVENT_UPDATE_MANAGED_ACTION_STATE: {
                value: EVENT_UPDATE_MANAGED_ACTION_STATE
                /**
                 * @property
                 * @constant
                 * @name Controller.Directive.sActionContext#EVENT_UPDATE_MANAGED_ACTION
                 * @type {String}
                 */
            },
            EVENT_SYNC_MANAGED_ACTIONS: {
                value: EVENT_SYNC_MANAGED_ACTIONS
                /**
                 * @property
                 * @constant
                 * @name Controller.Directive.sActionContext#EVENT_SYNC_MANAGED_ACTIONS
                 * @type {String}
                 */
            }
        });

    ns.sActionContext = sActionContext;
})(Object.namespace('Controller.Directive'));
