(function (ns) {
    /**
     * @namespace
     * @alias Controller.Directive.sTimeTransformer
     *
     * @constructor
     * @param $scope
     * @param $element
     */
    var sTimeTransformer = function ($scope, $element) {
        this.$deRegister = [];
        this.$element    = $element;

        var posStart        = -1,
            posEnd          = -1,
            separatorSet    = false,
            hadZeroHours    = false,
            hadZeroMinutes  = false,
            leadingZeroH    = false,
            leadingZeroM    = false,
            wasInvalid      = false,
            hasFocus        = false
        ;

        /**
         * @property
         * @name Controller.Directive.sTimeTransformer#$ngModelController
         * @type {Object}
         */

        this.handleBlur = function handleBlur() {
            hasFocus = false;
            sTimeTransformer.prototype.handleBlur.call(this);
        };

        this.handleFocus = function handleFocus() {
            sTimeTransformer.prototype.handleFocus.call(this);
            hasFocus = true;
        };

        function resetFlags() {
            hadZeroMinutes = hadZeroHours = leadingZeroM = leadingZeroH = wasInvalid = false;
        }

        /**
         * @param {*} modelValue
         * @return {String}
         */
        function formatModelValue(modelValue) {
            if (!modelValue) {
                return modelValue;
            }

            var parsed = parseInt(modelValue);

            if (isNaN(parsed)) {
                return modelValue;
            }

            if (wasInvalid && String(parsed) === String(modelValue).replace(/\<br.*?\>$/, '') && !hasFocus) {
                resetFlags();
            }

            if (wasInvalid) {
                return modelValue;
            }

            var minutes = Math.floor(parsed % 3600 / 60),
                hours = Math.floor(parsed / 3600),
                formattedValue = '',
                separator = ''
            ;

            // if there is no cursor marker > be as detailed as possible
            if (!hasFocus) {
                if (minutes < 10) {
                    leadingZeroM = true;
                }
                if (hours < 10) {
                    leadingZeroH = true;
                }

                if (!minutes) {
                    hadZeroMinutes = true;
                }
                if (!hours) {
                    hadZeroHours = true;
                }
            }

            if (hours || hadZeroHours) {
                formattedValue += ((leadingZeroH && hours < 10 ? '0' : '') + String(hours));
                separator = ':';
            }

            if (minutes || hadZeroMinutes) {
                formattedValue += (separator + ((leadingZeroM && minutes < 10 ? '0' : '') + String(minutes)));
            } else if (separatorSet) {
                formattedValue += separator;
            }


            if (posStart > -1) {
                var index;
                if (!separatorSet && (index = formattedValue.search(':') !== -1) && posStart >= index) {
                    posStart += 1;
                    if (posEnd > -1) {
                        posEnd += 2;
                    }
                }

                formattedValue = formattedValue.split('');
                formattedValue.splice(posStart, 0, Controller.Directive.ContentEditableController.UNICODE_SELECTION_START_PLACEHOLDER);

                if (posEnd > -1) {
                    formattedValue.splice(posEnd, 0, Controller.Directive.ContentEditableController.UNICODE_SELECTION_END_PLACEHOLDER);
                }
                formattedValue = formattedValue.join('');
            }

            return formattedValue;
        }

        /**
         * @param {*} viewValue
         * @return {Number|String}
         */
        function parseViewValue(viewValue) {
            // Stores the position of the caret, needed to retain position after transformation
            posStart = viewValue.search(Controller.Directive.ContentEditableController.UNICODE_SELECTION_START_PLACEHOLDER);
            posEnd = viewValue.search(Controller.Directive.ContentEditableController.UNICODE_SELECTION_END_PLACEHOLDER);

            resetFlags();

            function handleInvalid() {
                wasInvalid = true;
                return viewValue;
            }

            var fragments = viewValue
                .replace(Controller.Directive.ContentEditableController.UNICODE_SELECTION_START_PLACEHOLDER, '')
                .replace(Controller.Directive.ContentEditableController.UNICODE_SELECTION_END_PLACEHOLDER, '')
                .replace(/\<br.*?\>$/, '')
                .toString().split(':')
            ;

            separatorSet = fragments.length > 1;

            if (fragments.length > 2) {
                return handleInvalid();
            }

            // handle the case of 115 or 710, when no separator is set, but the string is already too long
            if (fragments[0] && !separatorSet && fragments[0].length > 2) {
                // by default 115 should become 11:5 so we cut after 2nd char
                var cutAt = 2,
                    valueIn = fragments[0]
                ;
                fragments[0] = valueIn.substr(0, cutAt);

                // for 710 71:0 would be invalid so try doing 7:10 > cut after 1st char
                if (parseInt(fragments[0]) > 23 && valueIn.length < 4) {
                    cutAt = 1;
                }

                // execute the cuts
                fragments[0] = valueIn.substr(0, cutAt);
                fragments[1] = valueIn.substr(cutAt);
            }

            var hours = parseInt(fragments[0]),
                minutes = parseInt(fragments[1])
            ;

            if (isNaN(hours)) {
                return handleInvalid();
            }

            if (isNaN(minutes)) {
                return handleInvalid();
            }

            if (fragments[0]) {
                if (String(hours) !== fragments[0]) {
                    // if they don't match leading zero should be the saving grace
                    if (('0' + String(hours)) !== fragments[0] || hours >= 10) {
                        return handleInvalid();
                    }
                    leadingZeroH = true;
                }
                if (!hours) {
                    hadZeroHours = true;
                }
            }

            if (fragments[1]) {
                if (String(minutes) !== fragments[1]) {
                    // if they don't match leading zero should be the saving grace
                    if (('0' + String(minutes)) !== fragments[1] || minutes >= 10) {
                        return handleInvalid();
                    }
                    leadingZeroM = true;
                }
                if (!minutes) {
                    hadZeroMinutes = true;
                }
            }

            if (hours > 23 || hours < 0 || minutes > 59 || minutes < 0) {
                return handleInvalid();
            }

            var retVal = hours * 3600 + minutes * 60;

            if (!retVal && !hadZeroHours && !hadZeroMinutes) {
                return handleInvalid();
            }


            return retVal;
        }

        /**
         * @param {Object} $ngModelController
         */
        this.hookOnNgModelController = function($ngModelController) {

            $ngModelController.$validators.validTime = function validTime() {
                return !wasInvalid;
            };

            $ngModelController.$parsers.unshift(parseViewValue);

            $ngModelController.$formatters.unshift(formatModelValue);
        }
    };

    sTimeTransformer.prototype.$onInit = function $onInit() {
        this.$deRegister.concat(
            this.$element.$on('blur', this.handleBlur.bind(this))
        );

        this.$deRegister.concat(
            this.$element.$on('focus', this.handleFocus.bind(this))
        );

        this.hookOnNgModelController(this.$ngModelController);
    };

    sTimeTransformer.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    sTimeTransformer.prototype.handleBlur = function handleBlur() {
        var viewValue = this.$ngModelController.$$lastCommittedViewValue;
        this.$ngModelController.$$lastCommittedViewValue = null;
        this.$ngModelController.$setViewValue(viewValue);
        this.$ngModelController.$render();
    };

    sTimeTransformer.prototype.handleFocus = function handleFocus() {
        var sel   = window.getSelection(),
            range = document.createRange()
        ;

        range.selectNodeContents(this.$element[0]);
        sel.removeAllRanges();
        sel.addRange(range);
    };
    
    ns.sTimeTransformer = sTimeTransformer;
})(Object.namespace('Controller.Directive'));
