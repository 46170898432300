(function (ns) {
    var format = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

    /**
     * @namespace
     * @alias Model.UUID
     *
     * @param {String=} uuid
     */
    var UUID = function (uuid) {

        // if uuid is not yet there create one
        if (!uuid) {
            uuid = UUID.generateUuidV4();
        }

        Object.defineProperty(
            this,
            'uuid',
            {
                enumerable   : true,
                configurable : true,
                get          : function () {
                    return uuid;
                }
            }
            /**
             * @property
             * @type {String}
             * @name Model.UUID#uuid
             */
        );
    };

    /**
     * Returns the uuid's of the given collection
     * @param {Model.UUID[]} arr
     * @function Model.UUID.mapByUUID
     * @type {function}
     * @returns {Array}
     */
    UUID.mapByUUID = function (arr) {
        if (!arr) {
            return [];
        }

        return arr.map(function (elem) {
            return elem.uuid;
        });
    };

    /**
     * Generates random UUID
     *
     * @return {String}
     */
    UUID.generateUuidV4 = function () {
        var uuid = '';

        // TODO IT-4806: use external generator for more generic uuids
        for (var i in format) {
            if (format[i] === 'x') {
                uuid += Math.floor(Math.random() * 16).toString(16);
            } else if (format[i] === 'y') {
                uuid += Math.floor(8 + Math.random() * 4).toString(16);
            } else {
                uuid += format[i];
            }
        }

        return uuid;
    };

    /**
     * @function
     * Recursively replace all uuid properties inside object with new uuids
     *
     * @param {Object|Array} input
     * @param {function} replaceFn
     */
    UUID.replaceUuids = function (input, replaceFn) {
        if (typeof input !== 'object') {
            return;
        }

        for (var property in input) {
            if (input.hasOwnProperty(property)) {
                if (!input[property]) {
                    continue;
                }

                if (typeof input[property] === 'object') {
                    UUID.replaceUuids(input[property], replaceFn);
                } else if (property === 'uuid') {
                    input[property] = replaceFn();
                }
            }
        }
    };

    ns.UUID = UUID;

})(Object.namespace('Model'));





