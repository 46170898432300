(function(ns) {
    const DISABLED_ACTIONS = [
        'startUserfeedback',
        'show_unsubscribeable_pois',
        'show_subscribeable_pois',
        'show_pois_manager',
        'sRecommend_search',
        'sRecommend_similar',
        'set_users_data',
        'quizbot',
        'voucher_generate',
        'sample_start',
        'sample_finish',
        'fb_game_play',
        'fb_extension_url',
        'adlingo_click_call'
    ];

    var migrateData = function(data) {

        var type;
        data.filter(function(element, index) {
            if (element.type.search(/^(set|remove|unset)_users_label$/) !== -1) {
                type = RegExp.$1;
                if (type === 'remove') {
                    type = 'unset';
                }
                data.splice(index, 1);
                return true;
            }
        }).map(function(element) {
            // map values into attribute describers and push into data
            element.value.map(function(label) {
                data.push({
                    type: 'set_users_attribute',
                    uuid: Model.UUID.generateUuidV4(),
                    value: JSON.stringify({
                        type    : type,
                        key     : label,
                        value   : null
                    })
                });
            });
        });
        return data;
    };

    /**
     * @namespace
     * @alias Model.Action.Collection
     * @constructor
     */
    var Collection = function() {
        var self                = this,
            items               = [],
            itemsCache          = [],
            context             = null,
            refreshItemsCache   = function() {
                itemsCache
                    .splice.apply(itemsCache, [0, itemsCache.length])
                    .push.apply(itemsCache, items);
            }
        ;


        /**
         * @typedef {Model.Action[]} ActionArray
         * @property {Model.Action.Collection} parent
         */

        Object.defineProperties(
            itemsCache,
            {
                parent: {
                    get: function () {
                        return self;
                    }
                    /**
                     * @type {Model.Action.Collection}
                     */
                }
            });

        Object.defineProperties(
            this,
            {
                actions : {
                    configurable: true,
                    enumerable  : true,
                    get         : function() {
                        return itemsCache;
                    }
                    /**
                     * @property
                     * @name Model.Action.Collection#actions
                     * @type ActionArray
                     */
                },
                context : {
                    get: function() {
                        return context;
                    },
                    set: function(val) {
                        if (context !== val) {
                            context = val;
                        }
                    }
                    /**
                     * @property
                     * @name Model.Action.Collection#context
                     * @type {String}
                     */
                },
                length: {
                    get: function() {
                        return items.length;
                    }
                    /**
                     * @property
                     * @name Model.Action.Collection#length
                     * @type {Number}
                     */
                },
                allowAlternatives : {
                    writable: true
                    /**
                     * @property
                     * @name Model.Action.Collection#allowAlternatives
                     * @type {Boolean}
                     */
                }
            }
            /**
             * @property
             * @name Model.Action.Collection#minLength
             * @type Number
             */
        );

        /**
         * @name Model.Action.Collection#addAction
         * @param {Model.Action} action
         * @returns {Model.Action.Collection}
         */
        this.addAction = function addAction(action) {
            Object.instanceOf(action, Model.Action);

            if (!this.canHaveAction(action)) {
                if (DISABLED_ACTIONS.includes(action.type)) {
                    // Do not throw error for sunsetted actions, just hide them in the UI
                    return this;
                }

                throw new Model.Exception.InvalidActionError(action);
            }

            var pos;

            if ((pos = this.getIndex(action)) !== -1) {
                items.splice(pos, 1, action);
            } else {
                items.push(action);
            }

            refreshItemsCache();
            return this;
        };

        /**
         * @name Model.Action.Collection#moveAction
         * @param {Model.Action} action
         * @param {Number} position
         * @return {Model.Action.Collection}
         */
        this.moveAction = function moveAction(action, position) {
            var startPos = this.getIndex(action);
            if (startPos === -1 || position > this.length) {
                return this;
            }

            var tmp = items.splice(startPos, 1).pop();
            items.splice(position, 0, tmp);
            refreshItemsCache();
            return this;
        };

        /**
         * @function
         * @name Model.Action.Collection#updateAction
         * @param {Model.Action} action
         * @returns {Model.Action.Collection}
         */
        this.updateAction = function updateAction(action) {
            Object.instanceOf(action, Model.Action);

            var pos;
            if ((pos = this.getIndex(action)) !== -1) {
                if (this.actions[pos].type !== action.type && !this.canHaveAction(action)) {
                    if (DISABLED_ACTIONS.includes(action.type)) {
                        // Do not throw error for sunsetted actions, just hide them in the UI
                        return this;
                    }

                    throw new Model.Exception.InvalidActionError(action);
                }
                items.splice(pos, 1, action);
                refreshItemsCache();
            }

            return this;
        };

        /**
         * @function
         * @name Model.Action.Collection#removeAction
         * @param {Model.Action} action
         * @returns {Model.Action.Collection}
         */
        this.removeAction = function removeAction(action) {
            Object.instanceOf(action, Model.Action);

            var pos;

            if ((pos = items.indexOf(action)) !== -1) {
                items.splice(pos, 1);
            } else {
            }

            refreshItemsCache();
            return this;
        };

        this.removeAll = function removeAll() {
            for (var i = items.length - 1; i >= 0; i--) {
                this.removeAction(items[i]);
            }
        };

        /**
         * @function
         * @name Model.Action.Collection#__extendClone
         * @param {Model.Action.Collection} original
         */
        this.__extendClone = function(original) {
            for (var i = 0; i < original.actions.length; i++) {
                this.addAction(original.actions[i].clone());
            }
        }
    };

    /**
     * Checks if the given action can be added to the collection
     * @function
     * @name Model.Action.Collection#canHaveAction
     * @param {Model.Action} action
     * @returns boolean
     */
    Collection.prototype.canHaveAction = function canHaveAction(action) {
        return this.canActionHaveType(action, action.type);
    };

    /**
     * Checks if the given action with type can be added to the collection
     * @name Model.Action.Collection#canActionHaveType
     * @param {Model.Action} action
     * @param {string} type
     * @returns boolean
     */
    Collection.prototype.canActionHaveType = function canActionHaveType(action, type) {
        return this.isAllowedActionType(type, action);
    };

    /**
     * Checks if the given action-type can be added to the collection
     * @function
     * @name Model.Action.Collection#canHaveActionType
     * @param {string} actionType
     * @returns boolean
     */
    Collection.prototype.canHaveActionType = function canHaveActionType(actionType) {
        return this.isAllowedActionType(actionType);
    };

    /**
     * @function
     * @name Model.Action.Collection#alreadyHasActionType
     * @param {string} type
     * @param {Model.Action} [action]
     * @returns boolean
     */
    Collection.prototype.alreadyHasActionType = function alreadyHasActionType(type, action) {
        for (var i = 0; i < this.actions.length; i++) {
            if (action && action.uuid === this.actions[i].uuid) {
                continue;
            }
            if (type === this.actions[i].type) {
                return true;
            }
        }

        return false;
    };

    /**
     * @function
     * @name Model.Action.Collection#isAllowedActionType
     * @param {string} type
     * @param {Model.Action} [action]
     * @returns boolean
     */
    Collection.prototype.isAllowedActionType = function isAllowedActionType(type, action) {
        var allowedOnes = this.getAllowedActions(action);

        if (!allowedOnes.length) {
            return false;
        }

        for (var i = 0; i < allowedOnes.length; i++) {
            if (allowedOnes[i] === type) {
                return true;
            }
        }
        return false;
    };

    /**
     * Get the remaining allowed actions
     * @param {Model.Action} [action]
     * @name Model.Action.Collection#getAllowedActions
     */
    Collection.prototype.getAllowedActions = function getAllowedActions(action) {
        var allowedOnes = [],
            actionDefinition,
            isCombinable = false,
            i,k
        ;

        for (i = 0; i < Model.Action.Definition.all.length; i++) {

            actionDefinition = Model.Action.Definition.all.definitions[i];

            if (this.context && !actionDefinition.hasContext(this.context)) {
                continue;
            }

            if (!this.allowAlternatives) {
                isCombinable = true;
                for (k = 0; k < this.actions.length; k++) {
                    if (action && action.uuid === this.actions[k].uuid) {
                        continue;
                    }

                    if (!actionDefinition.combinables.findByName(this.actions[k].type)) {
                        isCombinable = false;
                        break;
                    }
                }
                if (!isCombinable) {
                    continue;
                }
            }

            allowedOnes.push(actionDefinition.name);
        }

        return allowedOnes;
    };

    /**
     * @function
     * @name Model.Action.Collection#toString
     * @returns string
     */
    Collection.prototype.toString = function toString() {
        return this.actions.toString();
    };

    /**
     * @function
     * @name Model.Action.Collection#getIndex
     * @param {Model.Action} object
     * @returns {number}
     */
    Collection.prototype.getIndex = function getIndex(object) {
        var uuids = this.actions.map(function(elem) {
                return elem.uuid;
            })
        ;

        return uuids.indexOf(object.uuid);
    };

    /**
     * @function
     * @name Model.Action.Collection#getPostbackAction
     * @returns {?Model.Action}
     */
    Collection.prototype.getPostbackAction = function() {
        for (var i = 0; i < this.actions.length; i++) {
            if (this.actions[i].isPostbackMessageAction()) {
                return this.actions[i];
            }
        }
        return null;
    };

    /**
     * @function
     * @name Model.Action.Collection#getMessageTriggeringAction
     * @returns {?Model.Action}
     */
    Collection.prototype.getMessageTriggeringAction = function() {
        for (var i = 0; i < this.actions.length; i++) {
            if (this.actions[i].triggersMessage()) {
                return this.actions[i];
            }
        }
        return null;
    };

    /**
     * @function
     * @name Model.Action.Collection#updateByData
     */
    Collection.prototype.updateByData = function(data) {
        var actionsAdded = [];
        if (data.actions && data.actions.length) {
            data.actions = migrateData(data.actions);
            for (var i = 0; i < data.actions.length; i++) {
                var action = new Model.Action(data.actions[i].uuid);
                action.updateByData(data.actions[i]);
                actionsAdded.push(action);
                this.addAction(action);
            }
        }

        // noinspection JSCheckFunctionSignatures: typedef not correctly recognizing extended Array type
        actionsAdded.diff(this.actions).map(function(actionToRemove) {
            this.removeAction(actionToRemove);
        });

    };

    /**
     * @function
     * @name Model.Action.Collection#repair
     */
    Collection.prototype.repair = function repair() {
        var i;

        for (i = 0; i < this.actions.length; i++) {
            this.actions[i].repair();
        }
    };

    /**
     * @function
     * @name Model.Action.Collection#validate
     * @returns {boolean}
     */
    Collection.prototype.validate = function validate() {
        return this.actions.reduce(
            /**
             * @param {Boolean} carry
             * @param {Model.Action} action
             * @returns {Boolean}
             */
            function(carry, action) {
                if (!carry) {
                    return false;
                }

                return carry && action.validate();
            },
            this.length > 0
        );
    };

    Collection.jsonReviver = function (index, element) {
        if (index === 'action') {
            var actionCollection = new Collection();
            actionCollection.updateByData(element);
            return actionCollection;
        }
        return element;
    };

    ns.Collection = Collection;
})(Object.namespace('Model.Action'));
