(function (ns) {
    /**
     * @namespace
     * @alias Model.Analytics.StatisticsBag
     *
     * @param {String} endPoint
     * @extends Model.Series
     * @constructor
     */
    var StatisticsBag = function (endPoint) {
        StatisticsBag._pProto.constructor.call(this, endPoint);

        var period          ,
            dateRange       ,
            variants        = [],
            variantsCache   = [],
            updateVariantsCache = function updateVariantsCache() {
                variantsCache.splice.apply(variantsCache, [0, variantsCache].concat(
                    variants.map(function(variant) {
                        return {
                            label: variant.ucFirst(),
                            value: variant
                        };
                    })
                ));
            }
        ;

        Object.defineProperties(
            this,
            {
                entries: {
                    enumerable: true,
                    get: function () {
                        return this.records;
                    }
                    /**
                     * @property
                     * @name Model.Analytics.StatisticsBag#entries
                     * @type {Array}
                     */
                },
                period: {
                    enumerable: true,
                    get: function () {
                        return period;
                    }
                    /**
                     * @property
                     * @name Model.Analytics.StatisticsBag#period
                     * @type {Number}
                     */
                },
                dateRange: {
                    enumerable: true,
                    get: function () {
                        return dateRange;
                    }
                    /**
                     * @property
                     * @name Model.Analytics.StatisticsBag#dateRange
                     * @type {?Model.DateRange}
                     */
                },
                variants: {
                    enumerable: true,
                    get: function () {
                        if (variantsCache.length !== variants.length) {
                            updateVariantsCache();
                        }
                        return variantsCache;
                    }
                    /**
                     * Stores the different summary types available in the dataset
                     * @property
                     * @name Model.Analytics.StatisticsBag#variants
                     * @type {String[]}
                     */
                }
                
            });

        this.mapFn = function(entryData) {
            var entry = Model.Analytics.Entry.createByData(entryData);

            entry.getMetaFields().map(function(meta) {
                if (variants.indexOf(meta) === -1) {
                    variants.push(meta);
                }
            });

            return entry;
        };

        var ancestorLoad = this.load;

        /**
         * @method
         * @name Model.Analytics.StatisticsBag#load
         * @param {Boolean=} metaOnly
         * @param {Boolean=} force
         * @return {$.Deferred<Model.Analytics.StatisticsBag>}
         */
        this.load = function load(metaOnly, force) {
            return ancestorLoad.apply(this, arguments).then(function(data, _period, _dateRange) {
                if (_period) {
                    period = _period;
                }
                if (_dateRange) {
                    dateRange = _dateRange;
                }
                return this;
            }.bind(this));
        }
    };

    Object.extendProto(StatisticsBag, Model.Series);

    /**
     * @ignore
     * @param {Object} queryParams
     * @returns {$.Deferred|Promise}
     */
    StatisticsBag.prototype.load = function load(queryParams) {
        return StatisticsBag._pProto.load.call(this, queryParams).then(
            /**
             * @param {{categories, layers, entries}} responseData
             * @return {Array}
             */
            function(responseData) {
                if (!responseData || !(responseData instanceof Object)) {
                    return [];
                }

                responseData.categories.map(function(categoriesData) {
                    categoriesData.values.map(function(categoryData) {
                        categoryData.type = categoriesData.type;
                        // this will put this into the global cache for later use
                        Model.Analytics.Category.createByData(categoryData);
                    });
                });

                responseData.layers.map(function(element) {
                    // this will put this into the global cache for later use
                    Model.Analytics.Layer.createByData(element);
                });

                if (!responseData.entries) {
                    return [];
                }

                var dateRange;
                if (responseData.timeFrom && responseData.timeTo) {
                    dateRange = new Model.DateRange(
                        moment(responseData.timeFrom),
                        moment(responseData.timeTo)
                    );
                }

                return $.Deferred().resolve(responseData.entries, responseData.period, dateRange);
            });
    };

    /**
     * @returns {Model.Analytics.Layer|undefined}
     */
    StatisticsBag.prototype.getLayer = function getLayer() {
        var entry = this.entries.slice(0,1).pop();
        if (!entry) {
            return;
        }
        return entry.layer;
    };

    ns.StatisticsBag = StatisticsBag;
})(Object.namespace('Model.Analytics'));
