(function(ns) {

    /**
     * @namespace
     * @alias Model.Message.FetchAbleCollection
     *
     * @constructor
     * @extends Model.Collection.RESTAccess
     * @param {String} fetchUrl
     * @param {Boolean?} reverse
     * @param {Object?} data
     */
    var FetchAbleCollection = function(fetchUrl, reverse, data) {
        var self        = this,
            nextOffset,
            fetching
            ;

        Object.defineProperties(
            this,
            {
                url : {
                    get: function() {
                        return fetchUrl;
                    }
                    /**
                     * @property
                     * @name Model.Message.FetchAbleCollection#url
                     * @type {String}
                     */
                },
                isReverse : {
                    get: function() {
                        return Boolean(reverse);
                    }
                    /**
                     * @property
                     * @name Model.Message.FetchAbleCollection#isReverse
                     * @type {Boolean}
                     */
                },
                fetching : {
                    get: function() {
                        return fetching;
                    }
                    /**
                     * @property
                     * @name Model.Message.FetchAbleCollection#fetching
                     * @type {Boolean}
                     */
                },
                hasMore : {
                    get: function() {
                        return nextOffset !== 0;
                    }
                    /**
                     * @property
                     * @name Model.Message.FetchAbleCollection#hasMore
                     * @type {Boolean}
                     */
                },
                messages: {
                    enumerable: true,
                    get: function () {
                        return this.items;
                    }
                    /**
                     * @property
                     * @name Model.Message.FetchAbleCollection#messages
                     * @type {Model.Message[]}
                     */
                }
                
            }
        );

        FetchAbleCollection._pProto.constructor.call(this, Model.Message);

        this.fetchNext = function fetchNext() {
            if (nextOffset === 0) {
                return $.Deferred().resolve(this);
            }

            var headerInfo = undefined;

            if (!fetching) {
                var fn = function(ev, xhr, options) {
                    if (options.url.search(new RegExp(self.url + '\\\?*?')) === -1) {
                        return;
                    }

                    options.dataFilter = function(raw, type) {
                        // already parsed just do nothing
                        if (headerInfo) {
                            return raw;
                        }

                        var wasString = typeof(raw) === 'string';
                        if (wasString && type === 'json') {
                            raw = JSON.parse(raw);
                        }

                        if (!raw) {
                            return [];
                        }

                        var coll = raw.collection || [];
                        delete (raw.collection);
                        headerInfo = raw;

                        if (wasString && type === 'json') {
                            coll = JSON.stringify(coll);
                        }

                        return coll;
                    }
                };
                $(document).ajaxSend(fn);
                // store the current length for comparison whether the load has yielded results or not
                var currLength = this.length;
                fetching = Object.getPrototypeOf(this).load.call(this, this.url, $.extend(true, {}, data || {}, {offset: nextOffset}))
                    .then(function(collection) {
                        nextOffset = headerInfo.offset + (headerInfo.limit || 0);
                        if (!(collection.length - currLength) || nextOffset >= headerInfo.count) {
                            nextOffset = 0;
                        }

                        return collection;
                    })
                    .always(function() {
                        fetching = null;
                    });
                $(document).off("ajaxSend", fn);
            }
            return fetching;
        };

        var parentReset = this.reset;
        /**
         * @param {Boolean?} newData
         */
        this.reset = function reset(newData) {
            if (newData) {
                data = newData;
            }

            parentReset.call(this);
            nextOffset = undefined;
        };

        var parentAddItem = this.addItem;
        this.addItem = function(item, prepend) {
            if (prepend === undefined) {
                prepend = this.isReverse;
            }
            parentAddItem.call(this, item, prepend);
        }
    };

    Object.extendProto(FetchAbleCollection, Model.Collection.RESTAccess);

    ns.FetchAbleCollection = FetchAbleCollection;
})(Object.namespace('Model.Message'));
