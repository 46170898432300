(function(ns) {
    var DEFAULT_IMPORTANT_WEIGHT            = 5,
        GROUP_SEPARATOR                     = '|',
        ENTITY_PREFIX                       = '@',
        ENTITY_SPACE_REPLACEMENT            = '_',
        MATCH_GROUP_IS_ENTITY_CHANGED_EVENT = 'match-group-is-entity-changed-event',
        IS_ENTITY_PATTERN                   = new RegExp('^\u200E?' + ENTITY_PREFIX)
    ;

    /**
     * @namespace
     * @alias Model.AI.MatchGroup
     * @constructor
     */
    var MatchGroup = function() {
        Model.Behavior.Meta.call(this);
        var optional    = false,
            important   = false,
            isEntity    = false,
            self        = this,
            entity      = null
            ;

        Object.defineProperties(
            this,
            {
                isEntity : {
                    enumerable: true,
                    set : function (val) {
                        if (isEntity === Boolean(val)) {
                            return;
                        }

                        var synonyms,
                            retVal
                        ;

                        if (val) {
                            synonyms = self.elements.splice(1, self.elements.length -1 );

                            // Remove all synonyms and check that the word starts with entity prefix
                            self.elements = self.elements.slice(0,1).map(function (element) {
                                if (element.value.search(IS_ENTITY_PATTERN) !== 0) {
                                    element.value = ENTITY_PREFIX + element.value;
                                }

                                // Remove spaces from entity
                                element.value = element.value.replace(new RegExp(Const.Unicode.SPACE, 'g'), ENTITY_SPACE_REPLACEMENT);

                                return element;
                            });

                            optional = false;
                        } else {
                            // Remove entity prefix from the first element
                            self.elements[0].value = self.elements[0].value.replace(new RegExp(IS_ENTITY_PATTERN), '');
                            if (this.entity && this.entity.type === Model.AI.Entity.TYPE_KEY_KEYWORD) {
                                synonyms = this.entity.typeOptions;
                            }
                            entity = null;
                        }

                        isEntity = val;
                        retVal = $.event.trigger(MATCH_GROUP_IS_ENTITY_CHANGED_EVENT, [self, synonyms]);

                        if (retVal && retVal instanceof Model.AI.Entity) {
                            entity = retVal;
                            if (optional) {
                                optional = false;
                            }
                        }
                    },
                    get : function () {
                        return Boolean(isEntity);
                    }
                    /**
                     * @property
                     * @type Boolean
                     * @name Model.AI.MatchGroup#isEntity
                     */
                },
                optional: {
                    set: function(val) {
                        if (optional === Boolean(val)) {
                            return;
                        }

                        optional = Boolean(val) && !isEntity;

                        if (optional && important) {
                            self.important = false;
                        }
                    },
                    get: function() {
                        return Boolean(optional);
                    }
                    /**
                     * @property
                     * @type Boolean
                     * @name Model.AI.MatchGroup#optional
                     */
                },
                important: {
                    set: function(val) {
                        if (important === Boolean(val)) {
                            return;
                        }

                        important = Boolean(val);
                        if (optional && important) {
                            self.optional = false;
                        }

                        for (var i = 0; i < self.elements.length; i++) {
                            self.elements[i].weight = important ? DEFAULT_IMPORTANT_WEIGHT : null;
                        }
                    },
                    get: function() {
                        return Boolean(important);
                    }
                    /**
                     * @property
                     * @type Boolean
                     * @name Model.AI.MatchGroup#important
                     */
                },
                entity: {
                    enumerable: true,
                    get: function () {
                        return entity;
                    }
                    /**
                     * @property
                     * @name Model.AI.MatchGroup#entity
                     * @type {Model.AI.Entity}
                     */
                }
            }
        );

        this.refreshEntityAssoc = function refreshEntityAssoc() {
            isEntity = false;
            this.isEntity = true;
        };

        /**
         * @param {Model.AI.MatchGroup} source
         * @private
         */
        this.__extendClone = function __extendClone(source) {
            isEntity = source.isEntity;
            if (source.entity) {
                entity = source.entity.clone();
            }
        };

        /**
         * @private
         * @return {[]}
         */
        this.__dontCloneProperties = function __dontCloneProperties() {
            return ['isEntity'];
        };

        this.matchAll   = false;
        this.elements   = [];
        this.glue       = '';

        /**
         * @property
         * @name Model.AI.MatchGroup#partialEntity
         * @type {Boolean}
         */
    };

    /**
     * @function
     * @name Model.AI.MatchGroup#getNotEmptyElements
     * @return {Model.AI.MatchGroupElement[]}
     */
    MatchGroup.prototype.getNotEmptyElements = function getNotEmptyElements() {
        return this.elements.filter(function (element) {
            return element.value.trim().length > 0;
        });
    };

    /**
     * @name Model.AI.MatchGroup#toString
     * @function
     * @param {Boolean=} full
     * @return {*}
     */
    MatchGroup.prototype.toString = function toString(full) {
        full = full || false;

        var groupSeparator  = '()',
            concat          = this.elements.join(GROUP_SEPARATOR)
            ;

        if (this.optional) {
            groupSeparator = '[]';
        }

        if (!this.elements.length || !concat.length) {
            return '';
        }

        if (full) {
            if (this.elements.length > 1
                || this.optional
                || this.important
                || (concat.search(Const.Unicode.SPACE) !== -1)
                || this.isEntity
            ){
                return groupSeparator[0] + concat + groupSeparator[1];
            } else {
                return concat;
            }
        }

        return this.elements.slice(0,1).pop().value;
    };

    /**
     * @name Model.AI.MatchGroup#addOption
     * @param {String} name
     * @param {Boolean=} asFirst
     * @return {Model.AI.MatchGroup}
     */
    MatchGroup.prototype.addOption = function addOption(name, asFirst) {
        var removeChars = [Const.Unicode.SELECTION_START_PLACEHOLDER, Const.Unicode.SELECTION_END_PLACEHOLDER];

        name = name.replace(new RegExp(removeChars.join('|'), 'g'), '');

        if (!name || this.hasOption(name) || this.isEntity) {
            return this;
        }

        var element = ns.MatchGroupElement.fromString(name);
        if (this.important) {
            element.weight = DEFAULT_IMPORTANT_WEIGHT;
        }

        if (asFirst) {
            this.elements.unshift(element);
        } else {
            this.elements.push(element);
        }

        return this;
    };

    /**
     * @name Model.AI.MatchGroup#hasOption
     * @param name
     * @return {boolean}
     */
    MatchGroup.prototype.hasOption = function hasOption(name) {
        return this.elements.map(function(element) { return element.value}).indexOf(name) !== -1;
    };

    /**
     * @name Model.AI.MatchGroup#removeOption
     * @param name
     * @return {Model.AI.MatchGroup}
     */
    MatchGroup.prototype.removeOption = function removeOption(name) {
        if(this.isEntity) {
            return this;
        }

        var pos;

        if ((pos = this.elements.map(function(element) { return element.value}).indexOf(name)) === -1) {
            return this;
        }

        this.elements.splice(pos, 1);

        return this;
    };

    /**
     * @param {Number=} index
     * @return {String} The html form of the element
     */
    MatchGroup.prototype.toHTML = function toHTML(index) {
        if (index === undefined) {
            index = 0;
        }

        var cssClass = [],
            retVal   = ''
        ;

        if (!this.toString()) {
            retVal += this.glue;
            return retVal;
        }

        if (this.optional) {
            cssClass.push('optional');
        }

        if (this.elements.length > 1) {
            cssClass.push('multiple');
        }

        if (this.matchAll) {
            cssClass.push('matchAll');
        }

        if (this.important) {
            cssClass.push('important');
        }

        if (this.entity) {
            cssClass.push('entity');

            if (this.getMeta(Model.AI.MatchTextCollection.KEY_PARTIAL_ENTITY)) {
                cssClass.push('partial');
            }

            if (this.entity.type === Model.AI.Entity.TYPE_KEY_CONTENT_SOURCE) {
                cssClass.push('content-source');
            }
        }

        var synonymCount    = this.elements.length > 1 ? 'data-synonym-count="[' + this.elements.length + ']" ' : ' ',
            isEditable      = !(this.entity && this.entity.type === Model.AI.Entity.TYPE_KEY_CONTENT_SOURCE),
            contentEditable = isEditable ? ' ' : 'contenteditable="false" ',
            asString        = this.toString(),
            caretMarker     = ''
        ;

        if (!isEditable && asString.search(new RegExp(Const.Unicode.SELECTION_START_PLACEHOLDER)) !== -1) {
            asString = asString.replace(new RegExp(Const.Unicode.SELECTION_START_PLACEHOLDER, 'g'), '');
            caretMarker = Const.Unicode.SELECTION_START_PLACEHOLDER;
        }

        retVal +=
            this.glue +
            '<span class="'
            + cssClass.join(' ') + '" '
            + 'data-group="' + index + '" '
            + synonymCount
            + contentEditable
            + '>'
            + asString
            + '</span>' +
            caretMarker;


        return retVal;
    };

    /**
     * @param {String} val
     */
    MatchGroup.fromString = function fromString(val) {
        var matchGroup = new MatchGroup(),
            isImportant = true,
            isEntity = false
            ;

        matchGroup.elements = val
            .split(GROUP_SEPARATOR)
            .map(function(element) {
                var matchGroupElement = ns.MatchGroupElement.fromString(element);
                isEntity = element.search(new RegExp(IS_ENTITY_PATTERN)) === 0;
                isImportant = isImportant && (matchGroupElement.weight === DEFAULT_IMPORTANT_WEIGHT);
                return matchGroupElement;
            });

        matchGroup.important = isImportant;
        matchGroup.isEntity = isEntity && matchGroup.elements.length === 1;

        return matchGroup;
    };

    Object.defineProperties(
        MatchGroup,
        {
            WEIGHT_OPTIONAL  : {
                value : 'optional'
                /**
                 * @property
                 * @name Model.AI.MatchGroup#WEIGHT_OPTIONAL
                 * @type {string}
                 */
            },
            WEIGHT_NORMAL    : {
                value : 'normal'
                /**
                 * @property
                 * @name Model.AI.MatchGroup#WEIGHT_NORMAL
                 * @type {string}
                 */
            },
            WEIGHT_IMPORTANT : {
                value : 'important'
                /**
                 * @property
                 * @name Model.AI.MatchGroup#WEIGHT_IMPORTANT
                 * @type {string}
                 */
            },
            MATCH_GROUP_IS_ENTITY_CHANGED_EVENT : {
                value : MATCH_GROUP_IS_ENTITY_CHANGED_EVENT
                /**
                 * @property
                 * @name Model.AI.MatchGroup#MATCH_GROUP_IS_ENTITY_CHANGED_EVENT
                 * @type {string}
                 */
            },
            ENTITY_PREFIX: {
                value: ENTITY_PREFIX
                /**
                 * @property
                 * @constant
                 * @name Model.AI.MatchGroup#ENTITY_PREFIX
                 * @type {String}
                 */
            },
            IS_ENTITY_PATTERN: {
                value: IS_ENTITY_PATTERN
                /**
                 * @property
                 * @constant
                 * @name Model.AI.MatchGroup#IS_ENTITY_PATTERN
                 * @type {String}
                 */
            }
        }
    );

    ns.MatchGroup = MatchGroup;
})(Object.namespace('Model.AI'));
