(function(ns) {
    /**
     * @namespace
     * @alias Model.Event.Definition
     * @constructor
     *
     * @param {string} name
     * @param {string} [label]
     * @param {string} [contentType]
     */
    var Definition = function (name, label, contentType) {
        name        = name || '';
        label       = label || name;
        contentType = contentType || false;

        Object.defineProperties(this, {
            /**
             * @property {string}
             * @name Model.Event.Definition#name
             */
            name: {
                enumerable: true,
                configurable: false,
                get: function () {
                    return name;
                }
            },
            /**
             * @property {string}
             * @name Model.Event.Definition#label
             */
            label: {
                enumerable: true,
                configurable: false,
                get: function () {
                    return label;
                }
            },
            /**
             * @property {string}
             * @name Model.Event.Definition#contentType
             */
            contentType: {
                enumerable: true,
                configurable: false,
                get: function () {
                    return contentType;
                }
            }
        });

        this.__getCloneArguments = function () {
            return [this.name, this.label, this.contentType];
        };

        this.__dontCloneProperties = function () {
            return ['$$hashKey'];
        };
    };

    /**
     * @function
     * @static
     * @name Model.Event.Definition#create
     * @returns Model.Event.Definition
     */
    Definition.create = function create(data) {
        var name, label, contentType;

        if (data) {
            if (data.hasOwnProperty('name')) {
                name = data.name;
            }
            if (data.hasOwnProperty('label')) {
                label = data.label;
            }
            if (data.hasOwnProperty('contentType')) {
                contentType = data.contentType;
            }
        }
        return new Definition(name, label, contentType);
    };

    ns.Definition = Definition;
})(Object.namespace('Model.Event'));