(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.View.sMessagePart
     *
     * @param $scope
     * @param $element
     */
    var sMessagePart = function($scope, $element) {
        var activePage  = 0,
            /** @type {Controller.Component.View.sMessagePart} */
            self        = this
            ;

        this.$deRegister = [];

        this.pageWidth = 0;

        /**
         * @type {$scope}
         * @name Controller.Component.View.sMessagePart#$scope
         */
        this.$scope = $scope;

        this.$element = $element;

        /**
         * @type {Boolean}
         * @property
         * @name Controller.Component.View.sMessagePart#syncWithActiveGroup
         */

        /**
         * @type {Model.Message.Part}
         * @property
         * @name Controller.Component.View.sMessagePart#model
         */

        /**
         * @property
         * @name Controller.Component.View.sMessagePart#sMessageCtrl
         * @type {Controller.Component.View.sMessage}
         */

        Object.defineProperties(
            this,
            {
                /**
                 * @property
                 * @name Controller.Component.View.sMessagePart#activePage
                 */
                activePage: {
                    configurable: true,
                    enumerable  : false,
                    set         : function(val) {
                        if (val >= self.model.messageContents.length) {
                            val = self.model.messageContents.length - 1;
                        } else if (val < 0) {
                            val = 0;
                        }
                        activePage = val;
                        if (self.syncWithActiveGroup) {
                            self.model.activeGroup = activePage + 1;
                        }

                    },
                    get         : function() {
                        return activePage;
                    }
                }
            }
        );
    };

    /**
     * @function
     * @name Controller.Component.View.sMessagePart#$onInit
     */
    sMessagePart.prototype.$onInit = function $onInit() {
        var self                 = this,
            pageSizeCheckPending = false,
            checkPageSize        = function (numberOfExpectedElements) {
                if (pageSizeCheckPending) {
                    return;
                }

                pageSizeCheckPending = true;

                function executeCheck () {
                    var $elements = self.$element.find('s-content-group');

                    if ($elements.length !== parseInt(numberOfExpectedElements)) {
                        setTimeout(executeCheck, 50);
                        return;
                    }
                    pageSizeCheckPending = false;
                    if ($elements.length < 2) {
                        return;
                    }
                    self.pageWidth = Math.abs($elements[0].offsetLeft - $elements[1].offsetLeft);
                    digestIfNeeded(self.$scope);
                }

                setTimeout(executeCheck, 250);
            }
        ;



        if (this.syncWithActiveGroup) {
            this.$deRegister.push(this.$scope.$watch(
                function() {
                    return self.model.activeGroup;
                },
                function(value) {
                    if (value - 1 !== self.activePage) {
                        self.activePage = value - 1;
                    }
            }));
        }
        else {
            this.activePage = 0;
        }

        this.$deRegister.push(this.$scope.$watch(
            this.getActiveRelation.bind(this),
            /**
             * @param {Model.Message.Relation} val
             */
            function(val) {
                if (!val
                    || !self.model
                    || !(val instanceof Model.Message.Relation)
                    || !(val.options.hasOwnProperty('uuid'))
                    || self.model.messageContents.length <= 1) {
                    return;
                }

                var matchingAction = self.model.getAllMessagePostbackActions().filter(function(element) {
                    return element.action.uuid === val.options.uuid;
                }).pop();

                if (!matchingAction) {
                    return;
                }

                var content = matchingAction.content;

                if (!content) {
                    return;
                }

                for (var i = 0; i < self.model.messageContents.length; i++) {
                    if (content === self.model.messageContents[i]) {
                        setTimeout(
                            (function(i) {
                                return function () {
                                    self.setActivePage(i);
                                }
                            })(i),
                        1);
                        break;
                    }
                }
            }
        ));

        this.$deRegister.push(this.$scope.$watch(
            function() {
                return self.$element[0].scrollWidth + '-' + self.model.messageContents.length;
            },
            function(val, oldVal) {
                if (oldVal === val) {
                    return;
                }

                var numberOfExpectedElements = val.split('-').pop();

                checkPageSize(numberOfExpectedElements);
            }
        ));
    };

    /**
     * Retrieves the currently active relation for a message
     * @name Controller.Component.View.sMessagePart#getActiveRelation
     * @returns {Model.Message.Relation|null}
     */
    sMessagePart.prototype.getActiveRelation = function getActiveRelation() {
        if (!this.messageCtrl
            || !this.messageCtrl.activeRelation) {
            return null;
        }

        return this.messageCtrl.activeRelation;
    };

    /**
     * @function
     * @name Controller.Component.View.sMessagePart#$onDestroy
     */
    sMessagePart.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    sMessagePart.prototype.next = function next() {
        this.activePage++;
    };

    sMessagePart.prototype.previous = function previous() {
        this.activePage--;
    };

    sMessagePart.prototype.setActivePage = function setActivePage(page) {
        this.activePage = page;
    };

    /**
     * @function
     * @name Controller.Component.View.sMessagePart#onDoubleClick
     */
    sMessagePart.prototype.onDoubleClick = function onDoubleClick($event) {
        var excludedFromDoubleClick = [
            'button.carousel-control',
            'div.control>button'
        ];

        // Exclude controls from double click handling
        for (var i = 0; i < excludedFromDoubleClick.length; i++) {
            if ($($event.target).is(excludedFromDoubleClick[i]) || $(excludedFromDoubleClick[i]).has($event.target).length > 0) {
                return;
            }
        }

        var messageControls = this.messageCtrl.messageControls;

        if (!(messageControls instanceof Model.Menu.Menu)) {
            return;
        }

        // Open message editor where applicable
        for (i = 0; i < messageControls.items.length; i++) {
            if (messageControls.items[i].options.isEdit) {
                messageControls.items[i].action(this.model);
                break;
            }
        }
    };

    ns.sMessagePart = sMessagePart;
})(Object.namespace('Controller.Component.View'));
