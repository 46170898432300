(function (ns) {
    /**
     * @namespace
     * @alias Model.AI.Match
     * @constructor
     */
    var Match = function () {
        var matchText,
            matchTextCollection
        ;

        Object.defineProperties(
            this,
            {
                type                : {
                    enumerable : true,
                    writable   : true
                    /**
                     * @property
                     * @name Model.AI.Match#type
                     * @type String
                     */
                },
                score               : {
                    enumerable : true,
                    writable   : true
                    /**
                     * @property
                     * @name Model.AI.Match#score
                     * @type Number
                     */
                },
                matchText           : {
                    get : function () {
                        return matchText;
                    },
                    set : function (val) {
                        matchText = (val ? Model.AI.MatchText.createByData(val) : val);

                        matchTextCollection = new Model.AI.MatchTextCollection();

                        if (matchText) {
                            matchTextCollection.addMatchText(matchText);
                        }
                    }
                    /**
                     * @property
                     * @name Model.AI.Match#matchText
                     * @type {Model.AI.MatchText}
                     */
                },
                matchTextCollection : {
                    enumerable : false,
                    get        : function () {
                        return matchTextCollection;
                    }
                    /**
                     * @property
                     * @name Model.AI.Match#matchTextCollection
                     * @type {Model.AI.MatchTextCollection}
                     */
                },
                isBotReply          : {
                    enumerable : true,
                    writable   : true
                    /**
                     * @property
                     * @name Model.AI.Match#isBotReply
                     * @type Boolean
                     */
                },
                botReplyOrder       : {
                    enumerable : true,
                    writable   : true
                    /**
                     * @property
                     * @name Model.AI.Match#botReplyOrder
                     * @type Number
                     */
                }
            }
        );
    };

    /**
     * @function
     * @name Model.AI.Match#createByData
     * @param {object} data
     * @static
     * @return {Model.AI.Match}
     */
    Match.createByData = function createByData(data) {
        var match = new Match();
        match.updateByData(data);

        return match;
    };

    /**
     * @function
     * @name Model.AI.Match#updateByData
     * @param {object} data
     */
    Match.prototype.updateByData = function updateByData(data) {
        Object.updateByData(this, data);
    };

    ns.Match = Match;
})(Object.namespace('Model.AI'));
