(function (ns) {
    var TYPE_DATE       = 'date',
        TYPE_DATE_RANGE = 'dateRange',
        KEY_RANGE       = 'range'
    ;

    /**
     * @namespace
     * @alias Model.Condition
     *
     * @constructor
     */
    var Condition = function () {
        var self = this,
            key,
            value = [],
            /**
             * Contains merged fields
             * @type {[{fields: [], model: Object, type: String}]}
             */
            ranges = [],
            operator,
            definition,
            formConfig = {},
            initValuesIfNeeded = function initValuesIfNeeded() {
                if (!definition) {
                    return;
                }
                var prevDate;

                if (ranges.length) {
                    ranges.map(function (element, index) {
                        self[KEY_RANGE + index] = null;
                        delete self[KEY_RANGE + index];
                    });

                    ranges.splice(0, ranges.length);
                    formConfig = {};
                }
                // get rid of extra values
                if (value.length > definition.values.length) {
                    value.splice(definition.values.length, value.length - definition.values.length);
                }
                definition.values.map(function(element, index) {
                    if ((!element.requiredByOperators.length || element.requiredByOperators.indexOf(operator) !== -1)) {
                        if (prevDate && element.type !== TYPE_DATE) {
                            prevDate = null;
                        }
                        switch (element.type) {
                            case TYPE_DATE:
                                if (!prevDate) {
                                    if (!value[index]) {
                                        value[index] = moment();
                                    }
                                    prevDate = value[index];
                                } else {
                                    var range = new Model.DateRange();
                                    range.from = prevDate;
                                    if (!value[index] || value[index] < value[index - 1]) {
                                        value[index] = moment(value[index - 1]);
                                    }
                                    range.to = value[index];

                                    var rIndex = ranges.length;
                                    Object.defineProperty(
                                        self,
                                        KEY_RANGE + rIndex,
                                        {
                                            configurable: true,
                                            get: function() {
                                                return ranges[rIndex].model;
                                            },
                                            set: function(val) {
                                                ranges[rIndex].model = val;

                                                if (val !== null) {
                                                    value[ranges[rIndex].fields[0]] = val.from;
                                                    value[ranges[rIndex].fields[1]] = val.to;
                                                }
                                            }
                                        }
                                    );
                                    ranges.push({
                                        fields  : [index - 1 , index],
                                        model   : range,
                                        type    : TYPE_DATE_RANGE
                                    });
                                    prevDate = null;
                                    formConfig = {};
                                }
                                break;
                            default:
                                if (!value[index]) {
                                    value[index] = null;
                                }
                                break;
                        }
                    } else if (value[index]) {
                        value[index] = null;
                    }
                });
            }
        ;

        Object.defineProperties(
            this,
            {
                key: {
                    get: function() {
                        return key;
                    },
                    set: function (val) {
                        if (key === val) {
                            return;
                        }
                        key = val;

                        if (!(this.definition = Model.Condition.Definition.getByName(key))) {
                            console.trace();
                            throw 'Unable to find definition for "' + val + '"';
                        }
                    }
                },
                name: {
                    enumerable: true,
                    get: function() {
                        return key;
                    },
                    set: function(val) {
                        self.key = val;
                    }
                    /**
                     * @name Model.Condition#key
                     * @type {String}
                     **/
                },
                value: {
                    enumerable: true,
                    get: function() {
                        return value;
                    }
                    /**
                     * @name Model.Condition#value
                     * @type {Array}
                     **/
                },
                operator: {
                    enumerable: true,
                    get: function() {
                        return operator;
                    },
                    set: function(val) {
                        if (val === operator) {
                            return;
                        }

                        operator = val;
                        initValuesIfNeeded();
                    }
                    /**
                     * @name Model.Condition#oprator
                     * @type {String}
                     **/
                },
                definition: {
                    get: function() {
                        return definition;
                    },
                    set: function(val) {
                        if (val) {
                            Object.instanceOf(val, Model.Condition.Definition);
                        }

                        if (val === definition) {
                            return;
                        }

                        formConfig = {};
                        /**
                         * @name definition
                         * @type {Model.Condition.Definition}
                         * */
                        definition = val;
                        key = definition ? definition.name : null;

                        value.splice(0, value.length);
                        if (definition && definition.operators.length) {
                            var newOperator = definition.operators.slice(0,1).pop().key;
                            if (newOperator !== operator) {
                                this.operator = newOperator;
                            } else {
                                initValuesIfNeeded();
                            }
                        } else {
                            this.operator = null;
                        }
                    }
                    /**
                     * @name Model.Condition#definition
                     * @type {Model.Condition.Definition}
                     **/
                },
                ranges: {
                    get: function() {
                        return ranges;
                    }
                    /**
                     * @name Model.Condition#ranges
                     * @type {Array}
                     **/
                }
            }
        );

        /**
         * @return {Object}
         */
        this.getFormFields = function getFormFields() {
            if (!this.definition) {
                return formConfig;
            }

            $.extend(formConfig, this.definition.getFormFields());

            if (!this.ranges.length) {
                return formConfig;
            }

            this.ranges.reduce(function(carry, element, index){
                    carry[KEY_RANGE + index + ''] = {
                        component   : 's-date-range-picker',
                        template    : '_inline_input',
                        options     : {}
                    }
                },
                formConfig);

            return formConfig;
        };

        this.__extendClone = function __extendClone(original) {
            // do magic stuff after cloning all properties
            this.updateByData(
                {
                    operator    : original.operator,
                    value       : original.value
                }
            );
        }
    };

    /**
     * @name Model.Condition#isValueVisible
     * @param {Number} index
     * @returns {Boolean}
     */
    Condition.prototype.isValueVisible = function isValueVisible(index) {
        return (this.definition.values[index]
            && (!this.definition.values[index].requiredByOperators.length
                || this.definition.values[index].requiredByOperators.indexOf(this.operator) !== -1
                )
            )
            // so far not restricted by operators
            && (!this.ranges.length || this.ranges.reduce(function(carry, element) {
                return carry.concat(element.fields);
            }, []).indexOf(index) === -1)
    };

    /**
     * @name Model.Condition#getVisibleValues
     * @returns {Array}
     */
    Condition.prototype.getVisibleValues = function getVisibleValues() {
        var visibleValues = [];
        var parameterSize = this.value.length;
        for (var i = 0; i < parameterSize; i++) {
            if (this.isValueVisible(i)) {
                visibleValues.push(this.value[i]);
            }
        }
        if (this.ranges.length) {
            visibleValues.push(this.ranges);
        }
        return visibleValues;
    };

    /**
     * @param {Object} data
     */
    Condition.prototype.updateByData = function updateByData(data) {
        var key,
            self = this
        ;

        for (key in data) {
            if (key === 'value') {
                continue;
            }
            if (Object.hasOwnProperty.call(this, key)) {
                this[key] = data[key];
            }
        }

        if (data.value && self.definition) {
            var mappedValues = data.value.map(function(value, index) {
                switch(self.definition.values[index].type) {
                    case TYPE_DATE:
                        return moment(value);
                    default:
                        return value;
                }
            });

            this.value.splice.apply(this.value, [0, this.value.length].concat(mappedValues));
        }

        this.ranges.map(function(element) {
            if (element.type !== TYPE_DATE_RANGE) {
                return;
            }

            element.model.from = self.value[element.fields.slice(0,1).pop()];
            element.model.to = self.value[element.fields.slice(1,2).pop()];
        });
    };

    ns.Condition = Condition;
})(Object.namespace('Model'));
