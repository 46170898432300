(function (ns) {
    /**
     * @namespace
     * @alias sAutomation.Service.sEventDefinition
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {Service.sDomain} sDomainService
     * @constructor
     */
    var sEventDefinition = function (sAPIAccess, sDomainService) {
        var definitions         = [],
            definitionsLoading  = null
            ;

        Object.defineProperties(
            this,
            {
                sAPIAccess: {
                    get: function() {
                        return sAPIAccess;
                    }
                },
                sDomainService: {
                    get: function() {
                        return sDomainService;
                    }
                }
            }
        );

        /**
         * @function
         * @name sAutomation.Service.sEventDefinition#getDefinitions
         * @returns Promise
         */
        this.getDefinitions = function getDefinitions() {
            if (!definitionsLoading) {
                definitionsLoading = this.loadDefinitions().then(function (data) {
                    definitions = data;
                    return definitions;
                });
                return definitionsLoading;
            }

            if (definitionsLoading && definitionsLoading.state() === 'pending') {
                return definitionsLoading;
            }

            return $.Deferred().resolve(definitions).promise();
        };

        /**
         * @function
         * @name sAutomation.Service.sEventDefinition#refreshDefinitions
         * @returns Promise
         */
        this.refreshDefinitions = function refreshDefinitions() {
            definitionsLoading = null;
            return this.getDefinitions();
        };
    };

    /**
     * @function
     * @name sAutomation.Service.sEventDefinition#loadDefinitions
     * @returns $.Deferred
     */
    sEventDefinition.prototype.loadDefinitions = function loadDefinitions() {
        var self = this;
        return this.sDomainService.getCurrentDomain().then(function () {
            var endPoint = self.sAPIAccess.endpoint('automation.event.all').get();
            return $.ajax({
                url: endPoint
            }).then(function (data) {
                var events = [];
                for (var i in data) {
                    events.push(Model.Event.Definition.create(data[i]));
                }
                return events;
            });
        });
    };

    /**
     * @function
     * @name sAutomation.Service.sEventDefinition#getDefinitions
     * @return {PromiseLike}
     */
    sEventDefinition.prototype.getDefinitions = function getDefinitions() {
        var self = this;
        return this.sDomainService.getCurrentDomain().then(function () {
            var endPoint = self.sAPIAccess.endpoint('automation.event.all').get();
            return $.ajax({
                url: endPoint
            }).then(function (data) {
                var events = [];
                for (var i in data) {
                    events.push(Model.Event.Definition.create(data[i]));
                }
                return events;
            });
        });
    };

    ns.sEventDefinition = sEventDefinition;
})(Object.namespace('sAutomation.Service'));