(function(ns) {

    var SYNONYMS_TEXT_INPUT_ID      = 'synonyms-text-input',
        SUGGESTIONS_CLASS           = 'suggestions',
        SUGGESTIONS_PANEL_CLASS     = 'suggestions-panel',
        EVENT_REFRESH_ENTITY_SELECT = 'sEventRefreshEntitySelect'
    ;

    /**
     * @namespace
     * @alias Controller.Directive.MatchOptions
     *
     * @param $scope
     * @param group
     * @param groupId
     * @param updateFn
     * @param apiEndpoints
     * @param sProgressIndicator
     * @param {Model.AI.EntityCollection} sEntityRepository
     * @constructor
     */
    var MatchOptions = function($scope, group, groupId, updateFn, apiEndpoints, sProgressIndicator, sEntityRepository) {

        var synonymLoading;

        /**
         * @type {Model.AI.MatchGroup}
         */
        this.group                  = group;
        this.groupId                = groupId;
        this.updateFn               = updateFn;
        this.$deRegister            = [];
        this.apiEndpoints           = apiEndpoints;
        this.showSuggestions        = false;
        this.$scope                 = $scope;
        this.sEntityRepository      = sEntityRepository;

        var self                    = this,
            $suggCont
            ;

        /**
         * @return {$.Deferred}
         */
        this.loadSynonyms = function loadSynonyms() {
            if (synonymLoading) {
                return synonymLoading;
            }

            synonymLoading = Object.getPrototypeOf(this).loadSynonyms.apply(this, arguments)
                .always(function() {
                    synonymLoading = null;
                    digestIfNeeded($scope);
                });

            if (self.suggestionsContainer) {
                sProgressIndicator.attach(self.suggestionsContainer).then(function(loader) {
                    if (!synonymLoading) {
                        return loader.close();
                    }
                    synonymLoading.always(loader.close.bind(loader));
                });
            }

            return synonymLoading;
        };

        this.$deRegister.push($scope.$watch(
            function() {
                if (self.mdPanelRef) {
                    if (!self.suggestionsContainer && self.mdPanelRef.panelEl && ($suggCont = self.mdPanelRef.panelEl.find('.' + SUGGESTIONS_CLASS)) && $suggCont.length) {
                        self.suggestionsContainer = $suggCont;
                    }
                }
                return self.suggestionsContainer;
            },
            function(val) {
                if (synonymLoading && val) {
                    sProgressIndicator.attach(val).then(function(loader) {
                        if (!synonymLoading) {
                            return loader.close();
                        }
                        synonymLoading.always(loader.close.bind(loader));
                    });
                }
            })
        );

        this.similarWords = [];

        Object.defineProperties(
            this,
            {
                synonymsLoading : {
                    get: function() {
                        return synonymLoading;
                    }
                    /**
                     * @property
                     * @name Controller.Directive.MatchOptions#synonymsLoading
                     * @type {$.Deferred}
                     */
                },
                EVENT_REFRESH_ENTITY_SELECT: {
                    value: EVENT_REFRESH_ENTITY_SELECT
                    /**
                     * @property
                     * @constant
                     * @name Controller.Directive.MatchOptions#EVENT_REFRESH_ENTITY_SELECT
                     * @type {String}
                     */
                },
                TYPE_KEY_CONTENT_SOURCE: {
                    value: Model.AI.Entity.TYPE_KEY_CONTENT_SOURCE
                    /**
                     * @property
                     * @constant
                     * @name Controller.Directive.MatchOptions#TYPE_KEY_CONTENT_SOURCE
                     * @type {String}
                     */
                }
            }
        );

        /**
         * @property
         * @name Controller.Directive.MatchOptions#groupWeight
         * @type {String}
         */

        /**
         * @property
         * @name Controller.Directive.MatchOptions#entityForm
         * @type {Object} FormController
         */

        /**
         * @property
         * @name Controller.Directive.MatchOptions#entityType
         * @type {String}
         */
    };

    MatchOptions.prototype.$onInit = function $onInit() {
        var self   = this,
            $scope = this.$scope
        ;

        this.mdPanelRef.close = function (reason) {
            if(!self.group.isEntity || (self.entityForm && self.entityForm.$valid)) {
                return Object.getPrototypeOf(self.mdPanelRef).close.call(self.mdPanelRef, reason);
            }

            return $.Deferred().reject().promise();
        };

        this.$deRegister.push($scope.$watch(
            function() {
                return (self.group ? self.group.toString(true) : '').replace(Controller.Directive.ContentEditableController.SELECTION_MARKERS_REGEXP, '');
            },
            /**
             * @param {String} val
             * @param {String} oldVal
             */
            function(val, oldVal) {
                if (oldVal === val) {
                    return;
                }
                self.updateFn(self.groupId, self.group);
            })
        );

        this.$deRegister.push($scope.$on('$destroy', this.$onDestroy.bind(this)));

        this.$deRegister.push(
            $scope.$watch(
                function() {
                    return self.group.optional === (self.groupWeight === Model.AI.MatchGroup.WEIGHT_OPTIONAL)
                        && self.group.important === (self.groupWeight === Model.AI.MatchGroup.WEIGHT_IMPORTANT);
                },
                function() {
                    self.groupWeight = self.group.optional ? Model.AI.MatchGroup.WEIGHT_OPTIONAL :
                        (self.group.important ? Model.AI.MatchGroup.WEIGHT_IMPORTANT : Model.AI.MatchGroup.WEIGHT_NORMAL);
                }
            )
        );

        this.$deRegister.push(
            $scope.$watch(
                function() {
                    if (!self.group.entity) {
                        return null;
                    }
                    return self.group.entity.name;
                },
                function(val) {
                    if (!val) {
                        return;
                    }
                    self.group.elements[0].value = Model.AI.MatchGroup.ENTITY_PREFIX + val;
                }
            )
        );

        this.$deRegister.push(
            $scope.$watch(
                function () {
                    return JSON.stringify(self.entity);
                },
                function () {
                    if (self.entity) {
                        self.entityType = self.entity.type;
                    }
                }
            )
        );

        this.loadSynonyms();
        this.handleGroupTypeChange();
    };

    /**
     * @name Controller.Directive.MatchOptions#loadSynonyms
     * @return {$.Deferred}
     */
    MatchOptions.prototype.loadSynonyms = function loadSynonyms() {
        var groupId     = this.groupId,
            modifier    = 1,
            sentence    = this.groups.filter(function(element, index) {
                if (index === groupId || !element.optional) {
                    return true
                } else if (index < groupId) {
                    modifier--;
                }
            }).join(' ');

        groupId += modifier;

        return $.get(this.apiEndpoints.ai.similarWords(),
            {
                word:       this.group.elements.slice(0,1).pop().value,
                position:   groupId,
                sentence:   sentence
            })
            .then(this.processSynonyms.bind(this));
    };

    /**
     * @name Controller.Directive.MatchOptions#processSynonyms
     * @param {Array} data
     */
    MatchOptions.prototype.processSynonyms = function processSynonyms(data) {
        if (!(data instanceof Array)) {
            return;
        }

        // Remove duplicates
        var dataFiltered = data.reduce(function (carry, word) {
            var wordLowerCase = word.word.toLowerCase();

            // When removing duplicates always choose the one with higher score
            if (!carry.hasOwnProperty(wordLowerCase) || carry[wordLowerCase].score < word.score) {
                carry[wordLowerCase] = word;
            }

            return carry;
        }, {});

        for (var i in dataFiltered) {
            this.similarWords.push(dataFiltered[i]);
        }

        this.similarWords = this.similarWords.sort(function (a, b) {
            return b.score - a.score;
        }).map(function (element) {
            return element.word;
        });
    };

    /**
     * @name Controller.Directive.MatchOptions#$onDestroy
     */
    MatchOptions.prototype.$onDestroy = function $onDestroy() {
        var $destroyFunc;
        while (($destroyFunc = this.$deRegister.pop())) {
            $destroyFunc();
        }
    };

    /**
     * Handle manual change of the free text entity length checkbox
     *
     * @name Controller.Directive.MatchOptions#handleEntityHasLengthChange
     */
    MatchOptions.prototype.handleEntityHasLengthChange = function handleEntityHasLengthChange() {
        if (!this.entityHasLength) {
            // If length was removed, reset the type options of the entity
            this.entity.typeOptions = new (Model.AI.Entity.getTypeByKey(this.entityType).options);
        } else if (!this.entity.typeOptions.length > 0) {
            // If length was ticked but this.entityTypeOptions is not initialized
            this.entity.typeOptions = {
                'length' : 1,
                'unit'   : Model.AI.Entity.LENGTH_UNIT_WORDS
            };
        }
    };

    /**
     * @name Controller.Directive.MatchOptions#addOption
     * @param {String=} value
     */
    MatchOptions.prototype.addOption = function addOption(value) {
        if (!value && this.newOption) {
            value = this.newOption;
            this.newOption = null;
        }

        if (!value) {
            return;
        }

        if (this.entity) {
            this.entity.addKeyword(value);
            return;
        }

        this.group.addOption(value);
    };

    /**
     * @name Controller.Directive.MatchOptions#hasOption
     * @param {String} value
     * @return {Boolean}
     */
    MatchOptions.prototype.hasOption = function hasOption(value) {
        return this.group.hasOption(value);
    };

    /**
     * @name Controller.Directive.MatchOptions#removeOption
     * @param {Number} index
     * @return {null|Model.AI.MatchGroupElement}
     */
    MatchOptions.prototype.removeOption = function removeOption(index) {
        if (index < 0) {
            return null;
        }

        var repo = [];

        if (!this.entity) {
            repo = this.group.elements;
        } else if (this.entity.type === Model.AI.Entity.TYPE_KEY_KEYWORD) {
            repo = this.entity.typeOptions;
        } else {
            return null;
        }

        if (repo.length <= index) {
            return null;
        }

        return repo.splice(index, 1).pop();
    };

    /**
     * @name Controller.Directive.MatchOptions#makeOptionFirst
     * @param {Number} index
     */
    MatchOptions.prototype.makeOptionFirst = function makeOptionFirst(index) {
        var option = this.removeOption(index);

        if (option) {
            this.group.addOption(option.value, true);
        }
    };

    /**
     * @name Controller.Directive.MatchOptions#handleWeightChange
     */
    MatchOptions.prototype.handleWeightChange = function handleWeightChange() {
        this.group.optional = (this.groupWeight === Model.AI.MatchGroup.WEIGHT_OPTIONAL);
        this.group.important = (this.groupWeight === Model.AI.MatchGroup.WEIGHT_IMPORTANT);
    };

    /**
     * @name Controller.Directive.MatchOptions#endSynonymEditing
     * @param {Object} $event
     */
    MatchOptions.prototype.endSynonymEditing = function endSynonymEditing($event) {
        $($event.target).blur();
    };

    /**
     * @name Controller.Directive.MatchOptions#handlePanelClick
     * @param {Object} $event
     */
    MatchOptions.prototype.handlePanelClick = function handlePanelClick($event) {
        var $target = $($event.target);

        if ($target.is('#' + SYNONYMS_TEXT_INPUT_ID)) {
            this.showSuggestions = true;
            return;
        }

        if (this.showSuggestions === false) {
            return;
        }

        if (this.showSuggestions && !$target.hasClass(SUGGESTIONS_PANEL_CLASS) && $target.parents('.' + SUGGESTIONS_PANEL_CLASS).length === 0) {
            this.showSuggestions = false;
        }
    };

    MatchOptions.prototype.updateManageAction = function updateManageAction() {
        if (!this.checkManagedAction) {
            return;
        }

        if (!this.group.isEntity) {
            this.mdPanelRef.managedAction = false;
            this.mdPanelRef.entity        = null;
            return;
        }

        this.mdPanelRef.entity        = this.group.entity;
        this.mdPanelRef.managedAction = this.checkManagedAction(this.group.entity);
    };

    /**
     * @return {Boolean}
     */
    MatchOptions.prototype.isIndeterminate = function isIndeterminate() {
        return Boolean(this.group.partialEntity && this.mdPanelRef.managedAction);
    };

    /**
     * @return {String[]}
     */
    MatchOptions.prototype.getEntityTypes = function getEntityTypes() {
        return Model.AI.Entity.getTypesAsArray();
    };

    /**
     * @return {String|null}
     */
    MatchOptions.prototype.getEntityType = function getEntityType() {
        if (!this.entityType) {
            return null;
        }
        return Model.AI.Entity.getTypeByKey(this.entityType);
    };

    /**
     * @param {Model.AI.Entity} item
     * @param {Boolean=} isNewItem
     */
    MatchOptions.prototype.handleEntityChange = function handleEntityChange(item, isNewItem) {
        // if we had managed action on the old entity that should be updated now
        if (!item) {
            return;
        }

        if (isNewItem) {
            this.sEntityRepository.addItem(item);
        }

        var cloneGroupElement = this.group.elements.shift().clone();
        cloneGroupElement.value = Model.AI.MatchGroup.ENTITY_PREFIX + item.name;
        this.group.elements.push(cloneGroupElement);
        this.group.refreshEntityAssoc();

        this.group.entity.type      = item.type;
        this.entityHasLength        = item.typeOptions && item.typeOptions.length > 0;
        // entity has changed, so the managed actions state needs to be updated
        this.updateManageAction();
    };

    /**
     * @param {EntityType} newType
     * @param {String=} oldType
     */
    MatchOptions.prototype.handleEntityTypeChange = function handleEntityTypeChange(newType, oldType) {
        if (newType.key === Model.AI.Entity.TYPE_KEY_CONTENT_SOURCE || oldType === Model.AI.Entity.TYPE_KEY_CONTENT_SOURCE) {
            if (this.group.entity.type === newType.key) {
                this.entity = this.group.entity;
            } else {
                this.entity = null;
            }
        }

        if (this.entity) {
            this.entity.type     = newType.key;
            this.entityHasLength = this.entity.typeOptions && this.entity.typeOptions.length > 0;
        }

        this.$scope.$broadcast(EVENT_REFRESH_ENTITY_SELECT);
    };

    /**
     * @param {String} input
     * @return {Model.AI.Entity|Boolean}
     */
    MatchOptions.prototype.handleCreateNew = function handleCreateNew(input) {
        if (this.sEntityRepository.getItemByName(input)) {
            return false;
        }
        var entity = new Model.AI.Entity();

        entity.name = input;
        entity.type = this.entityType;


        return entity;
    };

    MatchOptions.prototype.handleGroupTypeChange = function handleGroupTypeChange() {
        this.updateManageAction();
        if (this.group.isEntity) {
            this.entity = this.group.entity;
        } else {
            this.entity = null;
            this.entityType = null;
        }
    };

    ns.MatchOptions = MatchOptions;
})(Object.namespace('Controller.Directive'));
