(function(ns) {
    /**
     * @namespace
     * @alias Model.Menu.Divider
     *
     * @constructor
     */
    var Divider = function() {
            Divider._pProto.constructor.call(this, Divider);
    };

    Object.extendProto(Divider, ns.Entry);

    Divider.TYPE_IDENT = 'DIVIDER';
    ns.Entry.TYPES.DIVIDER = Divider;
    ns.Divider = Divider
})(Object.namespace('Model.Menu'));