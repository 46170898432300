(function (ns) {
    var KEY_BEHAVIOR_BASED          = 'behavior_based',
        KEY_SUBSCRIPTION_BASED      = 'subscription_based',
        KEY_CONDITIONS              = 'conditions',
        KEY_SUBSCRIPTION            = 'subscription',
        TYPE_SEGMENT_BEHAVIOR       = 'segment-behaviour',
        TYPE_SEGMENT_SUBSCRIPTION   = 'segment-subscription'
    ;

    /**0
     * @namespace
     * @alias Model.Segment
     *
     * @extends Model.RESTAccessByUUID
     * @param {String=} uuid
     * @constructor
     */
    var Segment = function (uuid) {
        var timeCreated ,
            timeUpdated ,
            conditions  = new Model.Segment.Condition.Collection(),
            type        ,
            typeLabel
        ;

        Object.defineProperties(
            this,
            {
                type: {
                    enumerable: true,
                    get: function () {
                        return type;
                    },
                    set: function (val) {
                        if (val === type || !val) {
                            return;
                        }

                        type = val;

                        if (type === KEY_BEHAVIOR_BASED) {
                            conditions.min = 1;
                        } else {
                            conditions.min = 0;
                        }

                        typeLabel = type.split('_').map(function(el) {
                            return el.ucFirst()
                        }).join(' ');
                    }
                    /**
                     * @property
                     * @type {String}
                     * @name Model.Segment#type
                     */
                },
                typeLabel: {
                    get: function() {
                        return typeLabel;
                    }
                },
                name: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @type {String}
                     * @name Model.Segment#name
                     */
                },
                saveAsNewSegment: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @type {Boolean}
                     * @name Model.Segment#saveAsNewSegment
                     */
                },
                conditions: {
                    enumerable: true,
                    get: function () {
                        return conditions;
                    }
                    /**
                     * @property
                     * @type {Model.Segment.Condition.Collection}
                     * @name Model.Segment#conditions
                     */
                },
                timeCreated: {
                    enumerable: true,
                    configurable: true,
                    get: function () {
                        return timeCreated;
                    },
                    set: function (val) {
                        if (!moment.isMoment(val)) {
                            val = moment(val);
                        }
                        timeCreated = val;
                    }
                    /**
                     * @property
                     * @type {moment|null}
                     * @name Model.Segment#timeCreated
                     */
                },
                timeUpdated: {
                    enumerable: true,
                    configurable: true,
                    get: function () {
                        return timeUpdated;
                    },
                    set: function (val) {
                        if (!moment.isMoment(val)) {
                            val = moment(val);
                        }
                        timeUpdated = val;
                    }
                    /**
                     * @property
                     * @type {moment|null}
                     * @name Model.Segment#timeUpdated
                     */
                },
                status: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @type {String}
                     * @name Model.Segment#status
                     */
                },
                activeMembers: {
                    value: 0,
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @type {int}
                     * @name Model.Segment#activeMembers
                     */
                }
            }
        );

        this.type = KEY_SUBSCRIPTION_BASED;

        this.__getCloneArguments = function __getCloneArguments() {
            return [this.uuid];
        };

        this.__extendClone = function __extendClone(original) {
            // clone conditions despite that conditions doesn't have a setter
            this.conditions.__extendClone(original.conditions);
            this.setJSONState();
        };

        Segment._pProto.constructor.call(this, uuid);

        var parentUuidDesc = Object.getOwnPropertyDescriptor(this, 'uuid');

        Object.defineProperty(
            this,
            'uuid',
            {
                get: function() {
                    return this.isNew ? null : parentUuidDesc.get();
                }
            }
        );
    };

    Object.extendProto(Segment, Model.RESTAccessByUUID);

    /**
     * @function
     * @name Model.Segment#isActive
     * @returns Boolean
     */
    Segment.prototype.isActive = function isActive() {
        return this.status === Segment.STATUS_ACTIVE;
    };

    /**
     * @function Model.Segment#activate
     * @returns jqXHR
     */
    Segment.prototype.activate = function activate() {
        var endpoint    = Segment.APIEndpoint.activate(this.uuid);

        return $.ajax({
            url         : endpoint,
            method      : Const.Method.PUT
        });
    };

    /**
     * @function Model.Segment#deactivate
     * @returns jqXHR
     */
    Segment.prototype.deactivate = function deactivate() {
        var endpoint    = Segment.APIEndpoint.deactivate(this.uuid);

        return $.ajax({
            url         : endpoint,
            method      : Const.Method.PUT
        });
    };

    /**
     * Gets the formdata-representation of the segment-object
     * @function Model.Segment#getFormData
     * @param {FormData=} formData
     * @returns FormData
     */
    Segment.prototype.getFormData = function getFormData(formData) {

        var fData = Segment._pProto.getFormData.call(this, formData),
            segmentData = {
                'name'      : this.name,
                'domainId'  : this.domainId
            };

        if (!this.isNew) {
            segmentData['uuid'] = this.uuid;
        }

        if (this.getDispatchConditions()) {
            segmentData[KEY_CONDITIONS] = this.getDispatchConditions();
        }

        fData.append('segment', JSON.stringify(segmentData));

        return fData;
    };

    /**
     * Updates the segment by data
     * @function
     * @name Model.Segment#updateByData
     * @param {object} data
     * @returns Model.Segment
     */
    Segment.prototype.updateByData = function updateByData(data) {
        ['timeCreated','timeUpdated'].map(function(key) {
            if (data[key]) {
                data[key] = data[key] * 1000;
            }
        });

        Object.updateByData(this, data);

        // TODO: https://spectrm.atlassian.net/browse/IT-5351
        ['timeCreated','timeUpdated'].map(function(key) {
            if (data[key]) {
                data[key] = data[key] / 1000;
            }
        });

        if (this.conditions.length) {
            this.type = KEY_BEHAVIOR_BASED;
        } else {
            this.type = KEY_SUBSCRIPTION_BASED;
        }

        return this;
    };

    /**
     * Creates a segment by data
     * @static
     * @function
     * @name Model.Segment.createByData
     * @param {object} data
     * @returns Model.Segment
     */
    Segment.createByData = function createByData(data) {
        var uuid,
            segment;

        // in this case it is the hashed id
        if (data && data.hasOwnProperty('uuid')) {
            uuid = data.uuid;
        }

        segment = new Segment(uuid);
        segment.updateByData(data);

        return segment;
    };

    /**
     * @param {Boolean=} force
     * @returns {*}
     */
    Segment.prototype.getDispatchConditions = function getConditions(force) {
        if (!this.isNew && force) {
            var conditions = new Model.Segment.Condition.Collection(),
                subscriptionCondition = new Model.Segment.Condition()
            ;

            subscriptionCondition.key = KEY_SUBSCRIPTION;
            subscriptionCondition.value.unshift(this.uuid);
            subscriptionCondition.value.splice(1); // remove whatever was there
            subscriptionCondition.operator = 'is_subscribed';

            conditions.addCondition(subscriptionCondition);

            return conditions;
        } else {
            if (this.type === KEY_BEHAVIOR_BASED) {
                return this.conditions;
            }

            return [];
        }
    };
    
    Segment.prototype.valueOf = function valueOf() {
        var blackList = [
            'timeCreated',
            'timeUpdated',
            '$$hashKey',
            'activeMembers'
        ],
            whiteList = [];

        return JSON.stringify(this, function(key, element) {
            if (!key && element instanceof Model.RESTAccessByUUID && !element.isNew) {
                whiteList.push('uuid');
            }

            if (whiteList.length && key && whiteList.indexOf(key) === -1) {
                return;
            }
            if (blackList.indexOf(key) !== -1) {
                return;
            }

            if (key && !element) {
                return;
            }

            return element;
        });
    };

    Segment.KEY_BEHAVIOR_BASED      = KEY_BEHAVIOR_BASED;
    Segment.KEY_SUBSCRIPTION_BASED  = KEY_SUBSCRIPTION_BASED;
    Segment.STATUS_ACTIVE           = 'active';
    Segment.STATUS_INACTIVE         = 'deactivated';

    Segment.APIEndpoint = {
        generic      : false,
        activate     : false,
        deactivate   : false
    };

    ns.Segment = Segment;

    Model.Condition.Definition.registerType(
        'pois',
        function(field, element, index) {
            field.component     = 'sChannelSelect';
            var attrs = {
                'ng-required'   : true,
                'model'         : '$ctrl.model.value[' + index +']',
                'multi-select'  : false
            };
            if (element.type === TYPE_SEGMENT_BEHAVIOR) {
                $.extend(attrs, {
                    'type' : Model.Segment.KEY_BEHAVIOR_BASED
                });
            }
            if (element.type === TYPE_SEGMENT_SUBSCRIPTION) {
                $.extend(attrs, {
                    'type' : Model.Segment.KEY_SUBSCRIPTION_BASED
                });
            }
            $.extend(field.options, {
                attrs: attrs
            });
        },
        ['segment', TYPE_SEGMENT_BEHAVIOR, TYPE_SEGMENT_SUBSCRIPTION]
    );
})(Object.namespace('Model'));
