(function (angular) {
    var dndSortableDirective = angular.module('sDndSortableDirective', ['ngMaterial', 'dndLists']);

    /**
     * @ngdoc directive
     * @name dndSortable
     * @description
     * @element md-chips
     *
     * @param {Function} dndSortable
     * @restrict A
     */
    dndSortableDirective
        .directive('dndSortable', function() {
            return {
                restrict: 'A',
                require : ['mdChips', 'dndSortable'],
                controller: function() {},
                bindToController: {
                    dropHandle: '&dndSortable'
                },
                link: function($scope, $element, $attr, $controllers) {
                    var mdChipCtrl      = $controllers[0],
                        dndSortableCtrl = $controllers[1]
                    ;

                    var mdChipsScope = mdChipCtrl.$scope;
                    mdChipsScope.dropCallback = function(srcList, srcIndex, targetList, targetIndex) {
                        var retVal = true;

                        if (targetIndex > srcIndex && srcList === targetList) {
                            targetIndex--;
                        }

                        if (dndSortableCtrl.dropHandle) {
                            retVal = dndSortableCtrl.dropHandle({
                                "srcList"       : srcList,
                                "srcIndex"      : srcIndex,
                                "targetList"    : targetList,
                                "targetIndex"   : targetIndex
                            });
                        }
                        mdChipCtrl.selectChip(targetIndex);

                        return retVal;
                    };

                    mdChipsScope.dragStart = function(event) {
                        var width = event.target.offsetWidth;
                        event.dataTransfer.setDragImage(event.target, 0, 0);

                        var interval = setInterval(function() {
                                var $placeholder = $element.find('.dndPlaceholder');
                                if (!$placeholder.length) {
                                    return;
                                }
                                $placeholder.css('width', width + 'px');
                                digestIfNeeded($scope);
                                clearInterval(interval);
                            },
                            50);
                    }
                }
            }
        });

    /**
     * Get the directive 'md-chips' and add the directives before tranclusion to support dndList
     */
    dndSortableDirective.run([
        '$injector',
        function($injector){
            var $mdChipsDirective = $injector.get('mdChipsDirective')[0];
            var ancestorTemplate = $mdChipsDirective.template;
            $mdChipsDirective.template = function(element, attrs) {
                var retVal = ancestorTemplate.apply($mdChipsDirective, arguments);
                if(attrs.dndSortable === undefined) {
                    return retVal;
                }

                var enrichTagWithAttributes = function enrichTagWithAttributes(text, tag, attributes) {
                    return text.replace('<' + tag + ' ', function() {
                        var retString = '<' + tag;

                        for (var i in attributes) {
                            retString += (' ' + i + '="' + attributes[i] + '"');
                        }

                        return retString;
                    })
                };

                retVal = enrichTagWithAttributes(
                    retVal,
                    'md-chips-wrap',
                    {
                        'dnd-list'              : true,
                        'dnd-horizontal-list'   : true,
                        'dnd-drop'              : 'callback({targetList: $mdChipsCtrl.items, targetIndex: index})',
                        'dnd-dragover'          : 'index <= $mdChipsCtrl.items.length'
                    });

                retVal = enrichTagWithAttributes(
                    retVal,
                    'md-chip',
                    {
                        'dnd-draggable'         : null,
                        'dnd-effect-allowed'    : 'move',
                        'dnd-dragstart'         : 'dragStart(event)',
                        'dnd-callback'          : 'dropCallback($mdChipsCtrl.items, $index, targetList, targetIndex)'
                    });

                // add a placeholder chip
                retVal = retVal.replace('<md-chip ', '<md-chip class="dndPlaceholder"></md-chip><md-chip ');

                return retVal;
            };
        }]);
})(angular);
