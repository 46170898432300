(function (ns) {
    var initializing = null,
        definitions = []
        ;

    /**
     * @namespace
     * @alias sSegment.Service.sConditionDefinition
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @constructor
     */
    var sConditionDefinition = function (sAPIAccess) {
        Object.defineProperties(
            this,
            {
                sAPIAccess: {
                    get: function() {
                        return sAPIAccess;
                    }
                }
            }
        );
    };

    /**
     * @returns {$.Deferred}
     */
    sConditionDefinition.prototype.init = function init() {
        if (!initializing) {
            initializing = $.ajax({
                url: this.sAPIAccess.endpoint('segment.definitions').get()
            }).then(function(data) {
                if (!(data instanceof Array)) {
                    return [];
                }

                definitions.clear();
                data.map(function(element) {
                    var def = new Model.Condition.Definition(
                        element.category,
                        element.label,
                        element.name,
                        element.operators,
                        element.values,
                        element.internal
                    );
                    definitions.push(def);
                });

                return definitions;
            })
        }

        return initializing;
    };

    ns.sConditionDefinition = sConditionDefinition;
})(Object.namespace('sSegment.Service'));
