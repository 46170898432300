(function(ns) {
    /**
     * @namespace
     * @alias sMessageAnchor.Controller.DuplicateErrorController
     * @constructor
     *
     * @param $mdDialog
     * @param $scope
     * @param {sMessageAnchor.Service.MessageAnchor[]} messageAnchors
     * @param {string} domainName
     * @param {object} problemsByAnchorUuid
     */
    var DuplicateErrorCtrl = function (
        $mdDialog,
        $scope,
        messageAnchors,
        domainName,
        problemsByAnchorUuid
    ) {
        this.$scope                 = $scope;
        this.$mdDialog              = $mdDialog;
        this.messageAnchors         = messageAnchors;
        this.domainName             = domainName;
        this.problemsByAnchorUuid   = problemsByAnchorUuid;
    };

    /**
     * @function
     * @name sMessageAnchor.Controller.DuplicateErrorController#cancel
     */
    DuplicateErrorCtrl.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    ns.DuplicateErrorController = DuplicateErrorCtrl;
})(Object.namespace('sMessageAnchor.Controller'));