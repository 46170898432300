(function (ns) {
    /**
     * @namespace
     * @alias Controller.Component.sConditionCollection
     *
     * @constructor
     * @abstract
     */
    var sConditionCollection = function () {
        // Ensure context will be the controller instance
        this.sortDefinitions = sConditionCollection.prototype.sortDefinitions.bind(this);
    };

    /**
     * @abstract
     * @return {Model.Condition.Definition[]|PromiseLike.<Model.Condition.Definition[]>}
     */
    sConditionCollection.prototype.getDefinitions = function getDefinitions() {
        throw 'Must implement "getDefinitions" method!';
    };

    /**
     * @return {Array}
     */
    sConditionCollection.prototype.getCategoriesOrder = function getCategoriesOrder() {
        return [];
    };

    /**
     * @param {{category: String, label: String}} definitionA
     * @param {{category: String, label: String}} definitionB
     * @return {Number}
     */
    sConditionCollection.prototype.sortDefinitions = function sortDefinitions(definitionA, definitionB) {
        var categoriesOrder = this.getCategoriesOrder(),
            catIndexA = categoriesOrder.indexOf(definitionA.category.toLowerCase()),
            catIndexB = categoriesOrder.indexOf(definitionB.category.toLowerCase())
        ;

        if (catIndexA !== catIndexB) {
            return (catIndexB - catIndexA) * -1;
        } else {
            return definitionA.label.toString().localeCompare(definitionB.label.toString())
        }
    };

    /**
     * @abstract
     * @param {*} item
     * @return {Boolean}
     */
    sConditionCollection.prototype.isItemDisabled = function isItemDisabled(item) {
        throw 'Must implement "isItemDisabled" method!';
    };

    ns.sConditionCollection = sConditionCollection;
})(Object.namespace('Controller.Component'));
