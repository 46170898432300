(function (ns) {
    /**
     * @namespace
     * @alias sMember.Route.Overview
     * @extends sRoute
     * @constructor
     */
    var Overview = function Overview() {
        this.controller     = sMember.Controller.Overview;
        this.controllerAs   = '$ctrl';
        this.templateUrl    = 'smember:overview';
        this.reloadOnSearch = false;
        this.accessControl  = {'member.list': Const.Method.GET};

        Overview._pProto.constructor.call(this);
    };

    Object.extendProto(Overview, sRoute);

    /**
     * @function
     * @name sMember.Route.Overview#resolveConditionDefinitions
     * @param {sSegment.Service.sConditionDefinition} sSegmentConditionDefinition
     * @returns {*}
     */
    Overview.prototype.resolveConditionDefinitions = function resolveConditionDefinitions(sSegmentConditionDefinition) {
        return sSegmentConditionDefinition.init();
    };

    ns.Overview = Overview;
})(Object.namespace('sMember.Route'));
