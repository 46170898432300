(function (ns) {
    var TYPE_EVENT      = 'event',
        STATUS_DRAFT    = 'draft',
        STATUS_ACTIVE   = 'active',
        STATUS_INACTIVE = 'inactive'
    ;

    /**
     * @namespace
     * @alias Model.Automation
     *
     * @constructor
     * @extends Model.RESTAccessByUUID
     * @implements {Model.Behavior.Meta}
     *
     * @param {string} uuid
     * @param {string} status
     * @param {*} timeCreated
     * @param {*} timeUpdated
     * @param {*} timeActivated
     */
    var Automation = function (uuid, status, timeCreated, timeUpdated, timeActivated) {
        Model.Behavior.Meta.call(this);
        var steps       = new Model.Automation.Step.Collection(),
            typeLabel   = '',
            triggerEvent,
            type
        ;
        steps.min = 1;

        if (timeCreated && !(timeCreated instanceof moment)) {
            timeCreated = moment(timeCreated);
        }

        if (timeUpdated && !(timeUpdated instanceof moment)) {
            timeUpdated = moment(timeUpdated);
        }

        if (timeActivated && !(timeActivated instanceof moment)) {
            timeActivated = moment(timeActivated);
        }

        Automation._pProto.constructor.call(this, uuid);

        Object.defineProperties(
            this,
            {
                name : {
                    enumerable: true,
                    writable: true
                    /**
                     * @name Model.Automation#name
                     * @type {String}
                     */
                },
                steps : {
                    enumerable: true,
                    get: function() {
                        return steps;
                    }
                    /**
                     * @name Model.Automation#steps
                     * @type {Model.Automation.Step.Collection}
                     */
                },
                type: {
                    enumerable: true,
                    get: function() {
                        return type;
                    },
                    set: function(val) {
                        if (type === val) {
                            return;
                        }

                        type = val;
                        if (type === TYPE_EVENT) {
                            this.triggerEvent = new Model.Event();
                        } else {
                            this.triggerEvent = null;
                        }

                        if (!type) {
                            return;
                        }
                        typeLabel = type.split('_').map(function(el) {
                            return el.ucFirst()
                        }).join(' ');
                    }
                    /**
                     * @name Model.Automation#type
                     * @type {String}
                     */
                },
                typeLabel: {
                    get: function() {
                        return typeLabel;
                    }
                    /**
                     * @name Model.Automation#typeLabel
                     * @type {String}
                     * @readonly
                     */
                },
                status: {
                    enumerable: true,
                    get: function() {
                        return status;
                    }
                    /**
                     * @name Model.Automation#status
                     * @type {String}
                     * @readonly
                     */
                },
                timeCreated: {
                    enumerable: true,
                    get: function() {
                        return timeCreated;
                    }
                    /**
                     * @name Model.Automation#timeCreated
                     * @type {moment}
                     * @readonly
                     */
                },
                timeUpdated: {
                    enumerable: true,
                    get: function() {
                        return timeUpdated;
                    }
                    /**
                     * @name Model.Automation#timeUpdated
                     * @type {moment}
                     * @readonly
                     */
                },
                timeActivated: {
                    enumerable: true,
                    get: function() {
                        return timeActivated;
                    }
                    /**
                     * @name Model.Automation#timeActivated
                     * @type {moment}
                     * @readonly
                     */
                },
                triggerEvent: {
                    enumerable: true,
                    get: function() {
                        return triggerEvent;
                    },
                    set: function(val) {
                        if (triggerEvent === val) {
                            return;
                        }

                        if (val) {
                            Object.instanceOf(val, Model.Event);
                        }

                        triggerEvent = val;
                        type = TYPE_EVENT;
                    }
                    /**
                     * @name Model.Automation#triggerEvent
                     * @type {Model.Event}
                     */
                },
                triggerInterval: {
                    enumerable: true,
                    writable: true
                    /**
                     * @name Model.Automation#triggerInterval
                     */
                }
            }
        );

        this.__getCloneArguments = function __getCloneArguments() {
            return [
                this.uuid,
                this.status,
                this.timeCreated,
                this.timeUpdated,
                this.timeActivated
            ];
        };

        var pExtendClone = this.__extendClone;

        this.__extendClone = function __extendClone(original) {
            // clone conditions despite that conditions doesn't have a setter
            this.steps.__extendClone(original.steps);
            pExtendClone.call(this, original);
            this.setJSONState();
        };

        /**
         * @name Model.Automation#activate
         * @returns {PromiseLike|$.Deferred|Promise}
         */
        this.activate = function activate() {
            return Object.getPrototypeOf(this).activate.apply(this).then(function () {
                status = STATUS_ACTIVE;
                return self;
            });
        };

        /**
         * @name Model.Automation#deactivate
         * @returns {PromiseLike|$.Deferred|Promise}
         */
        this.deactivate = function deactivate() {
            return Object.getPrototypeOf(this).deactivate.apply(this).then(function () {
                status = STATUS_INACTIVE;
                return self;
            });
        };

        this.type = TYPE_EVENT;
        status = status || STATUS_DRAFT;
    };

    Object.extendProto(Automation, Model.RESTAccessByUUID);

    /**
     * @function
     * @name Model.Automation#isActive
     * @returns Boolean
     */
    Automation.prototype.isActive = function isActive() {
        return this.status === STATUS_ACTIVE;
    };

    /**
     * @function
     * @name Model.Automation#activate
     * @return {PromiseLike|Promise}
     */
    Automation.prototype.activate = function activate() {
        var endpoint    = Automation.APIEndpoint.activate(this.uuid);

        return $.ajax({
            url         : endpoint,
            method      : Const.Method.PUT
        });
    };

    /**
     * @function
     * @name Model.Automation#deactivate
     * @returns {PromiseLike|Promise}
     */
    Automation.prototype.deactivate = function deactivate() {
        var endpoint    = Automation.APIEndpoint.deactivate(this.uuid);

        return $.ajax({
            url         : endpoint,
            method      : Const.Method.PUT
        });
    };

    /**
     * @param {Object} data
     * @returns {Model.Automation}
     */
    Automation.prototype.updateByData = function updateByData(data) {
        if (data.triggerEvent && !this.triggerEvent) {
            this.triggerEvent = new Model.Event();
        } else if (this.triggerEvent && !data.triggerEvent) {
            this.triggerEvent = null;
        }

        var tmp = data.steps;
        data.steps = {steps: tmp || []};

        Object.updateByData(this, data);

        data.steps = tmp;

        Automation._pProto.updateByData.call(this, data);

        return this;
    };

    /**
     * @return {$.Deferred|Promise|PromiseLike}
     */
    Automation.prototype.fetchAnalyticsData = function fetchAnalyticsData() {
        var endPoint    = Automation.APIEndpoint.fetchAnalyticsData(this.uuid),
            self        = this
        ;

        return $.ajax({
            url: endPoint
        }).then(function(analytics) {
            self.setMeta('analytics', {triggered: analytics.triggered});

            self.steps.steps.map(function(automationStep, index) {
                if (index >= analytics.steps.length) {
                    return;
                }
                automationStep.setMeta('analytics', analytics.steps[index]);
            });
        });
    };

    /**
     * @param {Object} data
     * @returns {Model.Automation}
     */
    Automation.createByData = function createByData(data) {
        var props = [
            'uuid',
            'status',
            'timeCreated',
            'timeUpdated',
            'timeActivated'
            ],
            automation
        ;

        var values = props.map(function(element) {
            if (data && data.hasOwnProperty(element)) {
                if (element.indexOf('time') === 0) {
                    return data[element] * 1000;
                }
                return data[element];
            }
        });

        automation = Object.construct(Automation, values);
        automation.updateByData(data);

        return automation;
    };

    /**
     *
     * @param {FormData=} formData
     * @param {String=} name
     */
    Automation.prototype.getFormData = function getFormData(formData, name) {
        var fData = Automation._pProto.getFormData.call(this, formData);

        name = name || 'automation';

        fData.append(name, JSON.stringify(this));

        return fData;
    };

    Automation.APIEndpoint = {
        generic      : false,
        activate     : false,
        deactivate   : false
    };

    ns.Automation = Automation;
})(Object.namespace('Model'));