(function (angular) {
    var mdAutocompleterLoadingSync = angular.module('mdAutocompleterLoadingSync', ['ngMaterial']);

    /**
     * @ngdoc directive
     * @name loadingSync
     * @description
     * @element md-autocompleter
     *
     * @param {Boolean} loadingSync
     * @restrict A
     */
    mdAutocompleterLoadingSync
        .directive('loadingSync', function() {
            return {
                restrict: 'A',
                require: {
                    mdAutocompleteCtrl: 'mdAutocomplete'
                },
                controller: function () {
                    this.$onChanges = function (changes) {
                        if (!changes.loadingState) {
                            return;
                        }

                        if (!changes.loadingState.currentValue) {
                            return;
                        }

                        if (changes.loadingState.currentValue.then) {
                            changes.loadingState.currentValue.then(
                                function () {
                                    this.mdAutocompleteCtrl.loading = false;
                                }.bind(this),
                                function () {
                                    this.mdAutocompleteCtrl.loading = false;
                                }.bind(this)
                            );
                        }
                        this.mdAutocompleteCtrl.loading = !!changes.loadingState.currentValue;
                    }
                },
                bindToController: {
                    loadingState: '<loadingSync'
                }
            }
        });
})(angular);
