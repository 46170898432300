(function (ns) {
    var KEY_STEP_ONE = 'general',
        KEY_STEP_TWO = 'mapping',
        KEY_STEP_THREE = 'configuration';

    /**
     * @namespace
     * @alias sSource.Controller.EditContentSource
     * @constructor
     *
     * @param $scope
     * @param $mdDialog
     * @param {Model.Source} source
     * @param {sSource.Service.sSourceService} sSource
     * @param {Service.sFile} sFile
     * @param {RegExp} AWSURLRegEx
     */
    var EditContentSource = function EditContentSource(
        $scope,
        $mdDialog,
        source,
        sSource,
        sFile,
        AWSURLRegEx
    ) {
        var self = this;

        this.$scope = $scope;
        this.$mdDialog = $mdDialog;
        this.sFile = sFile;
        this.AWSURLRegEx = AWSURLRegEx;
        this.source = source;
        this.isNew = source.isNew;
        this.currentStepKey = KEY_STEP_ONE;
        this.dialogTitle = source.isNew ? 'Create Content Source' : 'Edit Content Source';
        this.saving = false;
        this.showMappingWarning = false;
        this.showURLLoader = false;
        this.showTypeLoader = false;
        this.editSourceForm = null;
        this.sSourceService = sSource;
        this.sourceTypeId = source.type ? source.type.id : null;
        this.contentSourceFile = null;
        this.uploadingSourceFileFailed = false;
        this.steps = [
            {
                key             : KEY_STEP_ONE,
                title           : 'Create',
                buttonText      : this.isNew ? 'Next' : 'Save',
                processFunction : this.saveSource,
                isStepEnabled   : function () {
                    return true;
                },
                isStepComplete  : function () {
                    return true;
                }
            },
            {
                key             : KEY_STEP_TWO,
                title           : 'Map',
                // buttonText      : this.isNew ? 'Next' : 'Save', TODO uncomment after adding config tab
                buttonText      : this.isNew ? 'Finish' : 'Save',
                processFunction : this.saveSource,
                isStepEnabled   : function () {
                    // Once the source is created, map tab should be always enabled
                    return !self.source.isNew;
                },
                isStepComplete  : function () {
                    return !self.showMappingWarning && (self.isNew || self.source.isModified() || self.source.hasCompleteMapping());
                }
            }
            // Hiding for now as it is empty tab
            // ,
            // {
            //     key             : KEY_STEP_THREE,
            //     title           : 'Configure',
            //     buttonText      : this.isNew ? 'Finish' : 'Save',
            //     processFunction : this.configureSource,
            //     isStepEnabled   : function () {
            //         return !self.source.isNew && !self.source.isModified();
            //     },
            //     isStepComplete  : function () {
            //         return true;
            //     }
            // }
        ];
        this.stepKeys = this.steps.map(function (step) {
            return step.key;
        });

        if (!source.isNew && !source.mapping) {
            source.initMapping();
        }
    };

    /**
     * @name sSource.Controller.EditContentSource#handleFileUpload
     * @param {Object} files
     */
    EditContentSource.prototype.handleFileUpload = function handleFileUpload(files) {
        if (this.saving || !files.length || !(files[0] instanceof Model.sFile)) {
            return;
        }

        this.uploadingSourceFileFailed = false;
        var self                       = this;

        this.sFile.uploadFile(files[0]).then(
            function (response) {
                self.source.url = response.url;
            },
            function () {
                self.uploadingSourceFileFailed = true;
            }
        ).always(function () {
            self.contentSourceFile = null;
            digestIfNeeded(self.$scope);
        });
    };

    /**
     * @function
     * @name sSource.Controller.EditContentSource#cancel
     */
    EditContentSource.prototype.cancel = function cancel() {
        // When canceling after creating new source - delete the created source
        if (this.isNew && !this.source.isNew) {
            this.$mdDialog.cancel(this.source);
        } else {
            this.$mdDialog.cancel();
        }
    };

    /**
     * @function
     * @name sSource.Controller.EditContentSource#changeStep
     *
     * @param stepKey
     */
    EditContentSource.prototype.changeStep = function changeStep(stepKey) {
        this.currentStepKey = stepKey;
    };

    /**
     * @function
     * @name sSource.Controller.EditContentSource#handleSaveClick
     * @return {$.Deferred}
     */
    EditContentSource.prototype.handleSaveClick = function handleSaveClick() {
        if (this.saving) {
            return;
        }

        var self = this;
        this.saving = true;
        this.errorOnSave = false;
        this.showMappingWarning = false;

        return self.processStep(this.currentStepKey).then(function () {
            // If we edit source and no other action is required (e.g. re-mapping fields) close the popup
            if (!self.isNew) {
                self.$mdDialog.hide();
                return;
            }

            self.saving = false;

            var nextStep = self.getNextStep();

            if (nextStep === null) {
                self.$mdDialog.hide();
                return;
            }

            // Switch to next step if it's enabled
            if (nextStep && self.isStepEnabled(nextStep)) {
                self.currentStepKey = nextStep;
            }
        }).fail(function () {
            self.saving = false;
            self.errorOnSave = true;
        }).always(function() {
            digestIfNeeded(self.$scope);
        });
    };

    /**
     * @function
     * @name sSource.Controller.EditContentSource#isStepEnabled
     *
     * @param {String} stepKey
     * @return {boolean}
     */
    EditContentSource.prototype.isStepEnabled = function isStepEnabled(stepKey) {
        var step = this.getStepByKey(stepKey);
        return step.isStepEnabled.call(this);
    };

    /**
     * @function
     * @name sSource.Controller.EditContentSource#isStepComplete
     *
     * @param {String} stepKey
     * @return {boolean}
     */
    EditContentSource.prototype.isStepComplete = function isStepComplete(stepKey) {
        var step = this.getStepByKey(stepKey);
        return step.isStepComplete.call(this);
    };

    /**
     * @function
     * @name sSource.Controller.EditContentSource#getNextStep
     *
     * @return {String|null}
     */
    EditContentSource.prototype.getNextStep = function getNextStep() {
        var currentIndex = this.stepKeys.indexOf(this.currentStepKey);

        if (this.stepKeys.length > currentIndex + 1) {
            return this.stepKeys[currentIndex + 1];
        }

        return null;
    };

    /**
     * @function
     * @name sSource.Controller.EditContentSource#getStepByKey
     * @param {String} stepKey
     *
     * @return {Object}
     */
    EditContentSource.prototype.getStepByKey = function getStepByKey(stepKey) {
        return this.steps[this.stepKeys.indexOf(stepKey)];
    };

    /**
     * @function
     * @name sSource.Controller.EditContentSource#processStep
     * @param {String} stepKey
     *
     * @return {$.Deferred}
     */
    EditContentSource.prototype.processStep = function processStep(stepKey) {
        var step = this.getStepByKey(stepKey);
        return step.processFunction.call(this);
    };

    /**
     * @function
     * @name sSource.Controller.EditContentSource#saveSource
     * @return {$.Deferred}
     */
    EditContentSource.prototype.saveSource = function saveSource() {
        if (!this.source.isModified()) {
            return $.Deferred().resolve();
        }

        var self = this;

        return this.source.save().then(function (data) {
            if (!data || !data.uuid) {
                return true;
            }

            // TODO: IT-5435
            // When saving source for the first time (creating) new id is generated so we have to recreate the object
            if (data.uuid !== self.source.uuid) {
                self.source = Model.Source.createByData(data);
                self.source.setAsExisting();
                self.source.initMapping();
            } else {
                // Make sure to always update by incoming data so source fields and mapping gets updated
                self.source.updateByData(data);
            }

            self.sSourceService.invalidateFieldAsAttributeCache();

            self.$scope.$emit('sContentSourceSaved', self.source);

            return true;
        });
    };

    /**
     * @function
     * @name sSource.Controller.EditContentSource#configureSource
     * @return {$.Deferred}
     */
    EditContentSource.prototype.configureSource = function configureSource() {
        return $.Deferred().resolve();
    };

    /**
     * @function
     * @name sSource.Controller.EditContentSource#handleTypeChange
     * @param {Model.Source.Type} item
     */
    EditContentSource.prototype.handleTypeChange = function handleTypeChange(item) {
        if (!this.isNew && item && item.id !== this.source.type.id) {
            this.source.type = item;
            this.saveSourceAfterInputChange('showTypeLoader');
        } else {
            this.source.type = item ? item : null;
        }
    };

    /**
     * @function
     * @name sSource.Controller.EditContentSource#handleUrlFocus
     */
    EditContentSource.prototype.handleUrlFocus = function handleUrlFocus() {
        this.sourceUrl = this.source.url;
    };

    /**
     * @function
     * @name sSource.Controller.EditContentSource#handleUrlBlur
     */
    EditContentSource.prototype.handleUrlBlur = function handleUrlBlur() {
        this.uploadingSourceFileFailed = false;

        if (this.source.isNew || !this.sourceUrl || this.sourceUrl === this.source.url || !this.editSourceForm.$valid) {
            return;
        }

        this.saveSourceAfterInputChange('showURLLoader');
    };

    /**
     * @function
     * @name sSource.Controller.EditContentSource#saveSourceAfterInputChange
     * @param {String} progressIndicator
     */
    EditContentSource.prototype.saveSourceAfterInputChange = function saveSourceAfterInputChange(progressIndicator) {
        var self = this;
        self[progressIndicator] = true;
        this.saving = true;
        this.errorOnSave = false;

        this.source.save().then(
            function (data) {
                if (data && data.uuid) {
                    self.source.updateByData(data);
                }

                self.source.initMapping();

                // If mapping can be done automatically we save the source with the new mapping
                if (self.source.hasCompleteMapping()) {
                    self.source.save().then(function () {
                        self[progressIndicator] = false;
                        self.saving = false;
                        self.errorOnSave = false;
                        digestIfNeeded(self.$scope);
                        self.sSourceService.invalidateFieldAsAttributeCache();
                    });
                } else {
                    self.showMappingWarning = true;
                    self[progressIndicator] = false;
                    self.saving = false;
                    self.errorOnSave = false;
                    digestIfNeeded(self.$scope);
                    self.sSourceService.invalidateFieldAsAttributeCache();
                }
            },
            function () {
                self.errorOnSave = true;
                digestIfNeeded(self.$scope);
            }
        );
    };

    /**
     * @function
     * @name sSource.Controller.EditContentSource#isItemDisabled
     * @param {Model.Source.Type} item
     */
    EditContentSource.prototype.isItemDisabled = function isItemDisabled(item) {
        if (this.source.mapping && this.source.mapping[item]) {
            item.disabledHint = 'This field is already used.';
            return true;
        }

        return false;
    };

    /**
     * @function
     * @name sSource.Controller.EditContentSource#isSelfHostedSourceFile
     * @return {Boolean}
     */
    EditContentSource.prototype.isSelfHostedSourceFile = function isSelfHostedSourceFile() {
        if(!this.source.url) {
            return false;
        }

        return this.source.url.match(this.AWSURLRegEx) !== null;
    };

    ns.EditContentSource = EditContentSource;
})(Object.namespace('sSource.Controller'));
