(function (ns) {
    var EVENT_DOMAIN_SET = 'sEventDomainSet',
        EVENT_DOMAIN_CHANGED = 'sEventDomainChanged';

    /**
     * @namespace
     * @alias Service.sDomain
     *
     * @param $rootScope
     * @param $location
     * @param sAppCookies
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     */
    var sDomain = function ($rootScope, $location, sAppCookies, sAPIAccess) {
        var self = this,
            domains = [],
            currentDomain = null,
            recentDomains = [],
            loading = null,
            initialized
        ;

        this.sAppCookies = sAppCookies;
        this.sAPIAccess = sAPIAccess;
        this.$location = $location;

        Object.defineProperties(this, {
            recentDomainIds: {
                get: function() {
                    return sAppCookies.get(sDomain.KEY_COOKIE_NAME) || [];
                }
            },
            recentDomains: {
                get: function() {
                    var recentTmp   = self.recentDomainIds,
                        mostRecent  = recentDomains.slice(0,1).pop()
                        ;

                    if (mostRecent && mostRecent.id === recentTmp.slice(0,1).pop()) {
                        return recentDomains;
                    }

                    var args = [0, (recentDomains.length || 1)].concat(domains.filter(function(element) {
                            return recentTmp.indexOf(element.id) !== -1;
                        }).sort(function(el1, el2) {
                            return recentTmp.indexOf(el1.id) - recentTmp.indexOf(el2.id);
                        })
                    );

                    recentDomains.splice.apply(
                        recentDomains,
                        args
                    );

                    return recentDomains;
                }
            },
            /**
             * @property {Service.sDomain}
             * @name Service.sDomain#currentDomainId
             */
            currentDomainId: {
                get: function () {
                    return currentDomain ? currentDomain.id : 0;
                }
            },
            /**
             * @property {Model.Domain[]}
             * @name Service.sDomain#domains
             */
            domains: {
                get: function () {
                    return domains;
                }
            },
            currentDomain: {
                get: function() {
                    return currentDomain;
                },
                set: function (value) {
                    // do not set for invalid value
                    if (value !== null && !(value instanceof Model.Domain)) {
                        return;
                    }

                    var previousDomain = currentDomain;
                    currentDomain = value;

                    if (currentDomain && currentDomain !== previousDomain) {
                        self.recentDomainIds.unshift(currentDomain.id);
                        sAppCookies.put(sDomain.KEY_COOKIE_NAME, self.recentDomainIds.unique().slice(0, 6));

                        // We are setting domain for the first time
                        if (previousDomain == null) {
                            $rootScope.$broadcast(EVENT_DOMAIN_SET, currentDomain);
                        } else {
                            $rootScope.$broadcast(EVENT_DOMAIN_CHANGED, currentDomain);
                        }
                    }
                }
                /**
                 * @property {Service.sDomain}
                 * @name Service.sDomain#currentDomain
                 * @type {Model.Domain}
                 */
            },
            isLoading: {
                get: function() {
                    return loading && loading.state() === 'pending';
                }
            }
        });

        $(document).$on('ajaxSend', function addDomainHeader(e, xhr) {
            if (self.currentDomain) {
                xhr.setRequestHeader('X-DOMAIN-ID', self.currentDomain.id);
            }
        });

        /**
         * Initial load domains
         * @function
         * @name Service.sDomain#init
         * @returns {$.Deferred}
         */
        this.init = function init() {
            if (!initialized) {
                initialized = this.getDomains();
            }

            return initialized;
        };

        /**
         * @function
         * @name Service.sDomain#getDomains
         * @returns {$.Deferred}
         */
        this.getDomains = function getDomains() {
            var self = this;
            if (!loading) {
                loading = this.loadDomains().then(function (loadedDomains) {
                    domains = loadedDomains;
                    if (!self.currentDomain) {
                        self.currentDomain = self.guessCurrentDomain();
                    }
                    return domains;
                });
                return loading;
            }

            if (loading && loading.state() === 'pending') {
                return loading;
            }

            return $.Deferred().resolve(domains).promise();
        };

        /**
         * @function
         * @name Service.sDomain#refreshDomains
         * @returns {$.Deferred}
         */
        this.refreshDomains = function refreshDomains() {
            loading = null;
            return this.getDomains();
        };

        /**
         * @function
         * @name Service.sDomain#getDomainById
         * @param id
         * @param {Boolean=} forceReload = false
         * @returns {$.Deferred}
         */
        this.getDomainById = function getDomainById(id, forceReload) {
            var loadDomain = function loadDomain() {
                return $.ajax({
                    url: sAPIAccess.endpoint('domain.RESTAccess').get(id)
                }).then(function(data) {
                    return Model.Domain.createByData(data);
                })
            };

            if (forceReload) {
                return loadDomain();
            }

            return this.getDomains().then(function(domains) {
                var foundDomain = domains.filter(function (domain) {
                    return domain.id === id;
                }).slice(0,1).pop();

                if (foundDomain) {
                    return foundDomain;
                }

                return loadDomain();
            });
        };

        /**
         * @property {Service.sDomain}
         * @name Service.sDomain#currentDomainId
         */
    };

    /**
     * Loads the domains from an API-endpoint
     * @function
     * @name Service.sDomain#loadDomains
     * @returns {$.Deferred<Model.Domain[]>}
     */
    sDomain.prototype.loadDomains = function loadDomains() {
        var collection = new Model.Collection.RESTAccess(Model.Domain);
        return collection.load(this.sAPIAccess.endpoint('domain.getAll').get()).then(function (domainCollection) {
            return domainCollection.items;
        });
    };

    /**
     * @function
     * @name Service.sDomain#isCurrentDomainId
     * @returns {Boolean}
     */
    sDomain.prototype.isCurrentDomainId = function isCurrentDomainId() {
        return (this.currentDomainId && this.currentDomainId !== 0);
    };

    /**
     * @function
     * @name Service.sDomain#getCurrentDomain
     * @returns {$.Deferred}
     */
    sDomain.prototype.getCurrentDomain = function getCurrentDomain() {
        var self = this;
        return this.getDomains().then(function () {
            return self.currentDomain;
        });
    };

    /**
     * @function
     * @name Service.sDomain#getCurrentDomainId
     * @returns {$.Deferred}
     */
    sDomain.prototype.getCurrentDomainId = function getCurrentDomainId() {
        var self = this;
        return this.getDomains().then(function () {
            return self.currentDomainId;
        });
    };

    /**
     * @function
     * @name Service.sDomain#guessCurrentDomain
     * @returns {Model.Domain}
     */
    sDomain.prototype.guessCurrentDomain = function guessCurrentDomain() {
        if (!this.domains.length) {
            return null;
        }

        var urlDomainName = this.getDomainNameFromUrl();

        // Try to guess domain from url
        var domain = this.domains.filter(function (element) {
            return element.name === urlDomainName;
        }).pop();

        // If domain can't be set from url, use the first users domain
        return domain || this.domains.slice(0, 1).pop();
    };

    /**
     * @function
     * @name Service.sDomain#replaceSubdomain
     * @param {String} url
     * @param {String} newSubdomain
     * @returns {String}
     */
    sDomain.prototype.replaceSubdomain = function replaceSubdomain(url, newSubdomain) {
        var replace = newSubdomain + ".";

        return url.replace(/^(https*:\/\/|)(\w+\.)/, function (fullMatch, firstGroup) {
            return firstGroup + replace;
        });
    };

    /**
     * @function
     * @name Service.sDomain#getDomainNameFromUrl
     * @returns {String}
     */
    sDomain.prototype.getDomainNameFromUrl = function getDomainNameFromUrl() {
        return window.location.host.split('.')[0];
    };

    /**
     * @function
     * @name Service.sDomain#redirectToCurrentDomain
     */
    sDomain.prototype.redirectToCurrentDomain = function redirectToCurrentDomain() {
        this.$location.search('changingDomains');
        window.location.href = this.replaceSubdomain(this.$location.absUrl(), this.currentDomain.name);
    };

    sDomain.KEY_COOKIE_NAME = 'recentDomains';

    Object.defineProperties(
        sDomain,
        {
            EVENT_DOMAIN_SET     : {
                value : EVENT_DOMAIN_SET
                /**
                 * @property
                 * @name Service.sDomain#EVENT_DOMAIN_SET
                 * @type {String}
                 */
            },
            EVENT_DOMAIN_CHANGED : {
                value : EVENT_DOMAIN_CHANGED
                /**
                 * @property
                 * @name Service.sDomain#EVENT_DOMAIN_CHANGED
                 * @type {String}
                 */
            }
        });

    ns.sDomain = sDomain;
})(Object.namespace('Service'));
