(function (ns) {
    /**
     * @namespace
     * @alias Controller.Component.sContentSourcePicker
     *
     * @constructor
     * @param $scope
     */
    var sCopyToClipboardButton = function ($scope) {
        this.$scope     = $scope;
        this.elementId  = 'copy-to-clipboard-' + Model.UUID.generateUuidV4();
        this.tooltip = this.tooltip ? this.tooltip : 'Copy to clipboard';
        this.textCopied = false;

        /**
         * @property
         * @name Controller.Component.sContentSourcePicker#text
         * @type {String}
         */

        /**
         * @property
         * @name Controller.Component.sContentSourcePicker#tooltip
         * @type {String}
         */
    };

    /**
     * @function
     * @name Controller.Component.sCopyToClipboardButton#copyToClipboard
     */
    sCopyToClipboardButton.prototype.copyToClipboard = function copyToClipboard() {
        this.textCopied = true;
        var self = this;

        setTimeout(function() {
            self.textCopied = false;
            digestIfNeeded(self.$scope);
        }, 2000);

        if (!this.element) {
            this.element = document.querySelectorAll('[data-id="' + this.elementId + '"]')[0];
        }

        var range = document.createRange();

        range.selectNode(this.element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
    };

    ns.sCopyToClipboardButton = sCopyToClipboardButton;
})(Object.namespace('Controller.Component'));
