(function(angular){
    var module = angular.module('sNavigation', ['sAppCookies']);

    module.provider('sNavigationMenu', sNavigation.Provider.sNavigationMenu);

    module.config(['sNavigationMenuProvider', function(sNavigationMenuProvider) {
        sNavigationMenuProvider
            .$on(Service.sDomain.EVENT_DOMAIN_SET, [function() {
                sNavigationMenuProvider.mainMenu.removeItems();
            }])
            .$on(sAPIAccess.Service.sAPIAccess.EVENT_ENDPOINTS_LOADED, [
                'sSegment',
                '$rootScope',
                'sProgressIndicator',
                'sAPIAccess',
                '$route',
                '$location',
                'sDomainService',
                function (event, sSegment, $rootScope, sProgressIndicator, sAPIAccess, $route, $location, sDomainService) {
                    // TODO uncomment when releasing new modules
                    // // BEGIN dashboard menu
                    // // if (sAPIAccess.isAllowed('--dashboard', Const.Method.GET)) {
                    // sNavigationMenuProvider.mainMenu.add(
                    //     Model.Menu.LocationAction.TYPE_IDENT,
                    //     'Dashboard',
                    //     '#/dashboard',
                    //     {iconClass : 'far fa-home color-palette-1'}
                    // );
                    // // }
                    // // END dashboard menu
                    //
                    // // BEGIN Ad Conversion menu
                    // // if (sAPIAccess.isAllowed('--adconversion', Const.Method.GET)) {
                    // sNavigationMenuProvider.mainMenu.add(
                    //     Model.Menu.LocationAction.TYPE_IDENT,
                    //     'Ad Conversion',
                    //     '#/ad-conversion-module',
                    //     {iconClass : 'far fa-bullhorn has-background color-palette-2'}
                    // );
                    // // }
                    // // END Ad Conversion menu
                    //
                    // // BEGIN AI Conversion menu
                    // // if (sAPIAccess.isAllowed('--aiconversion', Const.Method.GET)) {
                    // sNavigationMenuProvider.mainMenu.add(
                    //     Model.Menu.LocationAction.TYPE_IDENT,
                    //     'Ad Conversion',
                    //     '#/ai-conversion-module',
                    //     {iconClass : 'fa fa-magnet has-background color-palette-3'}
                    // );
                    // // }
                    // // END AI Conversion menu

                    // BEGIN Structure menu
                    sDomainService.getCurrentDomain().then(function (domain) {
                        if (!domain.isFeatureEnabledByIdent('vStructure')) {

                            // BEGIN Create new menu
                            if (sAPIAccess.isAllowed('message.RESTAccess', Const.Method.POST)) {
                                sNavigationMenuProvider.mainMenu.add(
                                    Model.Menu.LocationAction.TYPE_IDENT,
                                    'Create New',
                                    '/creator/new?asConversation=1',
                                    {
                                        iconClass        : 'far fa-plus color-palette-3',
                                        forceReload : true
                                    }
                                );
                            }
                            // END Create New menu

                            var structureMenu = new Model.Menu.Menu();
                            structureMenu.setLabel('Structure')
                                .setOptions({iconClass : 'fal fa-sitemap color-palette-4'})
                            ;

                            if (sAPIAccess.isAllowed('messageAnchor.byDomain.all', Const.Method.GET)) {
                                structureMenu
                                    .add(Model.Menu.LocationAction.TYPE_IDENT, 'Conversations', '#/message?type=conversations')
                                    .add(Model.Menu.LocationAction.TYPE_IDENT, 'AI-Templates', '#/message?type=ai-templates')
                                ;
                            }
                            if (structureMenu.items.length) {
                                sNavigationMenuProvider.mainMenu.sub(structureMenu);
                            }
                            if (sAPIAccess.isAllowed('entryPoint.RESTAccess', Const.Method.POST)) {
                                structureMenu.add(Model.Menu.LocationAction.TYPE_IDENT, 'Entry Points', '#/entry-points');
                            }
                        } else if (domain.isFeatureEnabledByIdent('vStructure')) {
                            sNavigationMenuProvider.mainMenu.add(
                                Model.Menu.LocationAction.TYPE_IDENT,
                                'Structure',
                                '/v2',
                                {iconClass : 'fal fa-sitemap color-palette-4'}
                            );

                            sNavigationMenuProvider.mainMenu.add(
                                Model.Menu.LocationAction.TYPE_IDENT,
                                'AI Templates',
                                '#/message?type=ai-templates',
                                {iconClass : 'fal fa-user-robot color-palette-4'}
                            );
                        }
                    });
                    // END Structure menu

                    // BEGIN Assets menu
                    var assetsMenu = new Model.Menu.Menu();

                    assetsMenu.setLabel('Assets')
                        .setOptions({iconClass : 'far fa-puzzle-piece color-palette-5'})
                    ;

                    if (sAPIAccess.isAllowed('entity.management', Const.Method.GET)) {
                        assetsMenu.add(Model.Menu.LocationAction.TYPE_IDENT, 'Entities', '#/entities/overview');
                    }

                    if (sAPIAccess.isAllowed('member.attribute', Const.Method.GET)) {
                        assetsMenu.add(Model.Menu.LocationAction.TYPE_IDENT, 'User Attributes', '#/user-attributes/overview');
                    }

                    if (sAPIAccess.isAllowed('source.RESTAccess', Const.Method.GET)) {
                        assetsMenu.add(Model.Menu.LocationAction.TYPE_IDENT, 'Content Sources', '#/content-source/overview');
                    }

                    if (assetsMenu.items.length) {
                        sNavigationMenuProvider.mainMenu.sub(assetsMenu);
                    }
                    // END Assets menu

                    // BEGIN automation-menu
                    if (sAPIAccess.isAllowed('automation.all', Const.Method.GET)) {
                        sNavigationMenuProvider.mainMenu.add(
                            Model.Menu.LocationAction.TYPE_IDENT,
                            'Automation',
                            '#/automation',
                            {iconClass : 'far fa-bolt color-palette-6'}
                        );
                    }
                    // END automation-menu

                    // BEGIN Audience menu
                    var audienceMenu = new Model.Menu.Menu();

                    audienceMenu
                        .setLabel('Audience')
                        .setOptions({iconClass : 'fa fa-users color-palette-7'});

                    if (sAPIAccess.isAllowed('member.list', Const.Method.GET)) {
                        audienceMenu.add(Model.Menu.LocationAction.TYPE_IDENT, 'Overview', '#/audience');
                    }

                    if (sAPIAccess.isAllowed('segment.byDomain.all', Const.Method.GET)) {
                        audienceMenu.add(Model.Menu.LocationAction.TYPE_IDENT, 'Segments', '#/segment');
                    }

                    if (audienceMenu.items.length) {
                        sNavigationMenuProvider.mainMenu.sub(audienceMenu);
                    }
                    // END Audience menu

                    // BEGIN inbox-menu
                    if (sAPIAccess.isAllowed('messageIncoming.feedback.all', Const.Method.GET)
                        || sAPIAccess.isAllowed('messageIncoming.matched.all', Const.Method.GET)
                        || sAPIAccess.isAllowed('messageIncoming.unmatched.all', Const.Method.GET)
                    ) {

                        var inboxMenu = new Model.Menu.Menu();
                        inboxMenu.setLabel('Inbox')
                            .setOptions({iconClass : 'fa fa-inbox color-palette-8'});

                        if (sAPIAccess.isAllowed('messageIncoming.feedback.all', Const.Method.GET)) {
                            inboxMenu.add(Model.Menu.LocationAction.TYPE_IDENT, 'Messages', '#/message/inbox');
                        }

                        if (sAPIAccess.isAllowed('messageIncoming.unmatched.all', Const.Method.GET)) {
                            inboxMenu.add(Model.Menu.LocationAction.TYPE_IDENT, 'Review Unmatched', '#/message/unmatched');
                        }

                        if (sAPIAccess.isAllowed('messageIncoming.matched.all', Const.Method.GET)) {
                            inboxMenu.add(Model.Menu.LocationAction.TYPE_IDENT, 'Validate Matches', '#/message/matched');
                        }

                        sNavigationMenuProvider.mainMenu.sub(inboxMenu);
                    }
                    // END inbox-menu

                    // BEGIN analytics-menu
                    sDomainService.getCurrentDomain().then(function (domain) {
                        if (!domain.isFeatureEnabledByIdent('vAnalyticsDashboard') && sAPIAccess.isAllowed('analytics.byDomain.chartData', Const.Method.GET)) {
                            sNavigationMenuProvider.mainMenu.sub()
                                .setLabel('Analytics')
                                .setOptions({iconClass : 'fa fa-bar-chart fa-chart-line color-palette-10'})
                                .add(Model.Menu.LocationAction.TYPE_IDENT, 'Audience', '#/analytics/audience')
                                .add(Model.Menu.LocationAction.TYPE_IDENT, 'Interactions', '#/analytics/interaction')
                                .add(Model.Menu.LocationAction.TYPE_IDENT, 'User Retention', '#/analytics/retention')
                                .add(Model.Menu.LocationAction.TYPE_IDENT, 'Campaign', '#/analytics/campaign')
                                .end();
                        } else if (domain.isFeatureEnabledByIdent('vAnalyticsDashboard')) {
                            sNavigationMenuProvider.mainMenu.add(
                                Model.Menu.LocationAction.TYPE_IDENT,
                                'Analytics',
                                '/v2/dashboard',
                                {iconClass : 'fa fa-bar-chart fa-chart-line color-palette-10'}
                            );
                        }
                    });
                    // END analytics-menu
                }
            ])
        ;
    }]);

})(angular);
