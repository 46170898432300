(function (ns) {
    /**
     * @namespace
     * @alias sDomain.Controller.Admin.LogoEdit
     *
     * @param $scope
     * @param {sDomain.Service.sDomainLogo} sDomainLogoService
     * @param dialog
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @constructor
     */
    var LogoEdit = function ($scope, sDomainLogoService, dialog, sAPIAccess) {
        this.$scope                       = $scope;
        this.sDomainLogoService           = sDomainLogoService;
        this.dialog                       = dialog;
        this.sAPIAccess                   = sAPIAccess;
        this.acceptedImageUploadMimeTypes = Model.Message.Part.acceptedImageUploadMimeTypes;
        this.fileUploadModel              = null;
        this.dialogTitle                  = sDomainLogoService.hasLogo ? 'Edit domain logo' : 'Add domain logo';
        this.errorOnSave                  = false;
        this.loadingOriginalLogo          = false;
        var self                          = this;
        this.$deRegister                  = [];

        // Preload the logo original so it can be re-cropped
        if (sDomainLogoService.hasLogo) {
            this.loadingOriginalLogo = true;

            sDomainLogoService.getLogoURL().then(function (logoURL) {
                Model.sFile.loadFromUrl(logoURL).then(function (blob) {
                    var image = new Model.sImage(blob);

                    image.imagePromise.then(function () {
                        self.loadingOriginalLogo = false;

                        // If new file was selected in the meantime, do not change it
                        if (!self.fileUploadModel) {
                            image.file.name      = image.file.fileName = 'domain-logo';
                            self.fileUploadModel = image;
                            digestIfNeeded(self.$scope);
                        }
                    });
                });
            });
        }

        this.$deRegister.push($scope.$on(
            Controller.Component.FileUploadController.EVENT_FILE_CHANGED,
            digestIfNeeded.bind(null, $scope)
        ));
    };

    LogoEdit.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    /**
     * @function
     * @name sDomain.Controller.Admin.LogoEdit#save
     * @return {$.Deferred}
     */
    LogoEdit.prototype.save = function save() {
        var fileUpload = this.fileUploadModel;

        if (!fileUpload || (!fileUpload.isLocal && !fileUpload.cropCanvasChanged)) {
            this.cancel(true);
            return $.Deferred().reject();
        }

        var self         = this;
        this.errorOnSave = false;

        return this.sDomainLogoService.updateDomainLogo(fileUpload).then(
            function () {
                self.dialog.hide();
            },
            function () {
                self.errorOnSave = true;
                digestIfNeeded(self.$scope);
            }
        );
    };

    /**
     * @function
     * @name sDomain.Controller.Admin.LogoEdit#cancel
     */
    LogoEdit.prototype.cancel = function cancel() {
        this.dialog.cancel();
    };

    ns.LogoEdit = LogoEdit;
})(Object.namespace('sDomain.Controller.Admin'));
