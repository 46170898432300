/**
 * Created by Gabor on 24-Oct-16.
 */


var ns = Object.namespace('Controller.Directive.Accordion');

var sAccordionBehaviorAwareController = ns.sAccordionBehaviorAwareController = function() {
    var accordionBehaviorController,
        accordionGroupId
        ;

    Object.defineProperties(
        this,
        {
            accordionBehaviorController: {
                enumerable: true,
                configurable: false,
                get: function () {
                    return accordionBehaviorController;
                },
                set: function (val) {
                    Object.instanceOf(val, Controller.Directive.sAccordionBehaviorController);
                    accordionBehaviorController = val;
                    val.addElement(accordionGroupId);
                }
            },
            accordionGroupId: {
                enumerable: true,
                configurable: false,
                get: function () {
                    return accordionGroupId;
                },
                set: function (val) {
                    accordionGroupId = val;
                }
            }
        }
    );
};

sAccordionBehaviorAwareController.prototype.removeFromAccordion = function() {
    this.accordionBehaviorController.remove(this.accordionGroupId);
};

sAccordionBehaviorAwareController.prototype.isVisible = function() {
    return this.accordionBehaviorController.getActiveElement() === this.accordionGroupId;
};

