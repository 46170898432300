(function (ns) {

    /**
     * @namespace
     * @memberOf Model.AI
     * @constructor
     *
     * @extends Model.Collection.RESTAccess
     * @param {sSource.Service.sSourceService} sSource
     */
    var EntityCollection = function EntityCollection(sSource) {
        EntityCollection._pProto.constructor.call(this, Model.AI.Entity);

        this.sSourceService = sSource;

        var parentGetIndex = this.getIndex;
        /**
         * @function
         * @name Model.AI.EntityCollection#getIndex
         * @param {Model.AI.Entity} entity
         *
         * @param {Boolean?} forceFindByUuid
         * @returns {number}
         */
        this.getIndex = function getIndex(entity, forceFindByUuid ) {
            var existingEntity;

            existingEntity = this.getItemByName(entity.name);
            if (forceFindByUuid ) {
                return parentGetIndex.call(this, entity);
            }

            return existingEntity ? this.items.indexOf(existingEntity) : -1;
        };

        $(document).on(Model.AI.MatchGroup.MATCH_GROUP_IS_ENTITY_CHANGED_EVENT, this.onEntityChangedHandler.bind(this));
    };

    Object.extendProto(EntityCollection, Model.Collection.RESTAccess);

    /**
     * @function
     * @name Model.AI.EntityCollection#getItemByName
     * @param {String} name
     *
     * @returns {?Model.AI.Entity}
     */
    EntityCollection.prototype.getItemByName = function getItemByName(name) {
        return this.items.filter(function (item) {
            return item.name === name;
        }).shift();
    };

    /**
     * @param event
     * @param {Model.AI.MatchGroup} matchGroup
     * @param {array} synonyms
     */
    EntityCollection.prototype.onEntityChangedHandler = function onEntityChangedHandler(event, matchGroup, synonyms) {
        if (this.loading) {
            return;
        }

        var entityName    = matchGroup.toString().replace(Model.AI.MatchGroup.IS_ENTITY_PATTERN, ''),
            hadCaretAtEnd = entityName.substr(-1, 1) === Const.Unicode.SELECTION_START_PLACEHOLDER
        ;

        if (matchGroup.isEntity) {
            entityName = entityName
                .replace(Const.Unicode.SELECTION_START_PLACEHOLDER, '')
                .replace(Const.Unicode.SELECTION_END_PLACEHOLDER, '')
            ;

            var entity = matchGroup.entity;
            if (!entity || entity.name !== entityName) {
                // we need to find the entity, as addItem would overwrite it and the matchText doesn't hold info about the meta of the entity
                entity = this.getItemByName(entityName);
                if (!entity && !EntityCollection.dontRegister) {
                    entity = Model.AI.Entity.createByData({name: entityName});
                }
            }

            if (!entity) {
                return;
            }

            event.result = entity;

            // if we had the caret at the end, then let's jump out with the entity that was resolved
            if (hadCaretAtEnd) {
                return;
            }

            if (synonyms && synonyms.length) {
                entity.type = Model.AI.Entity.TYPE_KEY_KEYWORD;

                entity.typeOptions = synonyms.map(function(synonym) {
                    return synonym.value;
                });

                entity.typeOptions.unshift(entityName);
            }

            this.addItem(entity);
        } else if (!matchGroup.isEntity) {
            if (synonyms && synonyms.length) {
                synonyms.map(function(synonym) {
                    matchGroup.addOption(synonym);
                });
            }
        }
    };

    /**
     * @param {Model.Message.Collection} collection
     */
    EntityCollection.prototype.syncWithCollection = function syncWithCollection(collection) {
        var self        = this,
            matchTexts  = collection.messages.reduce(function(carry, message) {
                if (!message.isIncoming) {
                    return carry;
                }

                for (var i in message.messageParts) {
                    carry = message.messageParts[i].messageContents.reduce(function(innerCarry, msgContent) {
                        innerCarry = msgContent.getElementsOfType(Model.AI.MatchTextCollection).reduce(function(innerCarry2, matchTextCollection) {
                            innerCarry2 = innerCarry2.concat(matchTextCollection.matchTexts);
                            return innerCarry2;
                        }, innerCarry);
                        return innerCarry;
                    }, carry);
                }
                return carry;
            }, []),
            entitiesInCollection = matchTexts.reduce(function(carry, matchText){
                carry = matchText.groups.reduce(function(innerCarry, matchGroup) {
                    if (!matchGroup.isEntity) {
                        return innerCarry;
                    }

                    innerCarry.push(matchGroup.toString());
                    return innerCarry;
                }, carry);
                return carry;
            }, []);

        this.items.map(function(entity) {
            if (!entity.isNew || entitiesInCollection.indexOf(Model.AI.MatchGroup.ENTITY_PREFIX + entity.name) !== -1) {
                return entity;
            }

            self.removeItem(entity);
        });
    };

    /**
     * Returns all entities that can be used (include derivatives as well)
     * @param {String?} entityType
     * @return {$.Deferred}
     */
    EntityCollection.prototype.getEntities = function getEntities(entityType) {
        var $deferred = $.Deferred();
        $.when(EntityCollection._pProto.getItems.call(this), this.sSourceService.getAvailableSourceFieldsAsAttributes()).then(function (entitiesFromRepo, contentSourcesAsAttribute) {
            // build up entities
            var entitiesFromColumns = contentSourcesAsAttribute.reduce(
                function (carry, value) {
                    // TODO: IT-6335 remove hardcoded values and use dynamic from attribute array
                    var entity,
                        types = ['ProductFeed']
                    ;

                    types.map(function (type) {
                        entity = new Model.AI.Entity();
                        entity.name = type + '.' + value.name;
                        entity.type = Model.AI.Entity.TYPE_KEY_CONTENT_SOURCE;
                        carry.push(entity);
                    });

                    return carry;
                },
                []
            );

            entitiesFromRepo = entitiesFromRepo.concat(entitiesFromColumns).filter(function (entity) {
                return !entityType || entity.type === entityType;
            });

            entitiesFromRepo = entitiesFromRepo.unique(function (item) {
                return item.name;
            });

            $deferred.resolve(entitiesFromRepo);
        });

        return $deferred;
    };

    /**
     * While set only resolved entities will be used, new entities won't be added
     * @property
     * @name EntityCollection#dontRegister
     * @type {Boolean}
     */

    ns.EntityCollection = EntityCollection;
})(Object.namespace('Model.AI'));
