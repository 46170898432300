(function (ns) {
    /**
     * @namespace
     * @alias sFacebook.Service.sHandover
     *
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @constructor
     */
    var sHandover = function (sAPIAccess) {
        this.sAPIAccess   = sAPIAccess;
    };

    /**
     * @param {String} memberId
     * @return {$.Deferred<>}
     */
    sHandover.prototype.getThreadOwnerInfo = function getThreadOwnerInfo(memberId) {
        return $.get(this.sAPIAccess.endpoint('facebook.member.handover').get(memberId)).then(function (data) {
            return new Model.Facebook.ThreadOwnerInfo(data.appId, data.threadOwnerAppId, data.threadOwnerAppName, data.primary);
        });
    };

    /**
     *
     * @param memberId
     * @return {$.Deferred<>}
     */
    sHandover.prototype.takeThreadControl = function takeThreadControl(memberId) {
        return $.ajax({
            url    : this.sAPIAccess.endpoint('facebook.member.handover').post(memberId),
            method : Const.Method.POST
        }).then(function (data, state, jqXHR) {
            if (jqXHR.status !== 200) {
                return;
            }
            return new Model.Facebook.ThreadOwnerInfo(data.appId, data.threadOwnerAppId, data.threadOwnerAppName, data.primary);
        });
    };

    ns.sHandover = sHandover;
})(Object.namespace('sFacebook.Service'));
