(function (ns) {
    /**
     * @namespace
     * @alias Decorator.Notification
     * @constructor
     *
     * @param {*} $delegate
     * @param {*} $rootScope
     * @param {*} $location
     * @param {*} $filter
     * @param {*} $sanitize
     * @return {*}
     */
    var Notification = function ($delegate, $rootScope, $location, $filter, $sanitize) {
        this.$delegate  = $delegate;
        this.$rootScope = $rootScope;
        this.$location  = $location;
        this.$filter    = $filter;
        this.$sanitize  = $sanitize;

        overrideDefaults.call(this, 'error', {closeOnClick: false, delay: false});
        overrideDefaults.call(this, 'warning', {closeOnClick: false, delay: false});

        $delegate.errorByAPIException = errorByAPIException.bind(this);
        $delegate.limitReached        = limitReached.bind(this);

        return $delegate;
    };

    /**
     * @function
     * @name Decorator.Notification~limitReached
     * @param {String} type
     * @param {String} messageKey
     */
    var limitReached = function limitReached(type, messageKey) {
        var message     = TemplateTexts.notification[messageKey] || TemplateTexts.notification['defaultLimitReached'],
            $ancestorFn = this.$delegate[type];

        var options = {
            message      : message,
            templateUrl  : '_limit-reached-notification',
            closeOnClick : false,
            delay        :false
        };

        return $ancestorFn.call(this.$delegate, options);
    };

    /**
     * @function
     * @name Decorator.Notification~errorByAPIException
     * @param {Model.Exception.APIResponseError} apiException
     */
    var errorByAPIException = function errorByAPIException(apiException) {
        this.$delegate.error(transformAPIExceptionToErrorNotificationOptions.call(this, apiException));
    };

    /**
     * @function
     * @name Decorator.Notification~transformAPIExceptionToErrorNotificationOptions
     * @param {Model.Exception.APIResponseError} apiException
     * @return Object
     */
    var transformAPIExceptionToErrorNotificationOptions = function transformAPIExceptionToErrorNotificationOptions(apiException) {
        var self           = this,
            exceptionBody  = this.$sanitize(apiException.body),
            exceptionTitle = this.$sanitize(apiException.title),
            actionHandlers = {}
        ;

        if (exceptionBody) {
            actionHandlers.message = focusMessage.bind(self);

            exceptionBody = this.$filter('sExceptionNotificationParser')(exceptionBody, apiException.context, actionHandlers);
        }

        var $scope = this.$rootScope.$new();

        $scope.exceptionId    = apiException.exceptionId;
        $scope.actionHandlers = actionHandlers;

        var options = {
            message    : exceptionBody || exceptionTitle,
            scope      : $scope,
            templateUrl: '_exception-notification'
        };

        if (exceptionBody) {
            options.title = exceptionTitle;
        }

        return options;
    };

    /**
     * @function
     * @name Decorator.Notification~focusMessage
     * @param {String} messageUuid
     */
    var focusMessage = function focusMessage(messageUuid) {
        this.$location.hash('message-focus-' + messageUuid);
    };

    /**
     * Allows easily overriding default values for the registered templates
     * @example
           overrideDefaults.call(this, 'error', {closeOnClick: false, delay: false}); // > removes auto close + close on click
     * @function
     * @name Decorator.Notification~overrideDefaults
     * @param {String} type
     * @param {*}      overrides
     */
    var overrideDefaults = function overrideDefaults(type, overrides) {
        var self                = this,
            $delegateAncestorFn = this.$delegate[type];

        if (!$delegateAncestorFn) {
            return;
        }

        this.$delegate[type] = function (args) {
            if (typeof args !== 'object' || args === null) {
                args = { message:args };
            }

            args = $.extend(true, {}, overrides, args);

            return $delegateAncestorFn.call(self.$delegate, args);
        }
    };

    ns.Notification = Notification;
})(Object.namespace('Decorator'));
