(function(ns) {
    var WEIGHT_SUFFIX = ':weight=';

    /**
     * @namespace
     * @alias Model.AI.MatchGroupElement
     * @param value
     * @param {Number} weight
     * @constructor
     */
    var MatchGroupElement = function(value, weight) {
        Object.defineProperties(
            this,
            {
                value: {
                    get: function() {
                        return String(value || '');
                    },
                    set: function(val) {
                        value = val;
                    }
                },
                weight: {
                    get: function() {
                        // if not parsed type safe check will become false #noIdeaWhy
                        // noinspection JSCheckFunctionSignatures
                        return parseInt(weight);
                    },
                    set: function(val) {
                        weight = val;
                    }
                }
            }
        );
    };

    /**
     * @returns {String}
     */
    MatchGroupElement.prototype.toString = function toString() {
        return this.value + (this.weight ? (WEIGHT_SUFFIX + this.weight) : '');
    };

    /**
     * @param {String} val
     */
    MatchGroupElement.fromString = function fromString(val) {
        return Object.construct(MatchGroupElement, val.split(WEIGHT_SUFFIX));
    };

    ns.MatchGroupElement = MatchGroupElement;
})(Object.namespace('Model.AI'));
