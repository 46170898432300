(function(ns) {
    /**
     * @namespace
     * @alias Controller.sMessageLog.Member
     *
     * @param {Model.Message.FetchAbleCollection} collection
     * @param $scope
     * @param hMemberId
     * @param sDomainService
     * @param sConfirm
     * @param Notification
     * @param {sSidebar.Service.sSidebar} sSidebar
     * @param $sce
     * @param {Service.sMessageCollection} sMessageCollection
     * @param {Facebook.Service.sHandover} sFacebookHandover
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @constructor
     */
    var Member = function Member(
        collection,
        $scope,
        hMemberId,
        sDomainService,
        sConfirm,
        Notification,
        sSidebar,
        $sce,
        sMessageCollection,
        sFacebookHandover,
        sAPIAccess
    ) {

        this.collection             = collection;
        this.sendCollection         = new Model.Message.Collection();
        this.$scope                 = $scope;
        this.hMemberId              = hMemberId;
        this.showChannelMessages    = true;
        this.sMessageCollection     = sMessageCollection;

        this.sDomainService         = sDomainService;
        this.sConfirm               = sConfirm;
        this.notification           = Notification;
        this.sSidebar               = sSidebar;
        this.sFacebookHandover      = sFacebookHandover;

        this.sendCollection.addMessage();

        this.messageControls = new Model.Menu.Menu();

        this.messageControls
            .addAction(
                $sce.trustAsHtml('<i class="fa fa-pencil-alt fa-pencil"></i>'),
                function() {
                    $scope.$broadcast.apply($scope, ['edit-message-part'].concat(Array.prototype.slice.call(arguments, 0)));
                },
                {
                    isEdit: true,
                    tooltip: 'Edit message'
                }
            )
            .addAction(
                $sce.trustAsHtml('<i class="fa fa-trash-alt fa-trash danger"></i>'),
                function(messagePart) {
                    $scope.$broadcast.apply($scope, ['remove-message-part'].concat(Array.prototype.slice.call(arguments, 0)));
                },
                {
                    tooltip: 'Remove message'
                }
            );

        var self = this;
        this.threadOwnerInfoLoading = this.sFacebookHandover.getThreadOwnerInfo(this.hMemberId).then(
            function (threadOwnerInfo) {
                self.threadOwnerInfo = threadOwnerInfo;
            },
            function () {
                self.threadOwnerInfo = false;
            }
        ).always(function () {
            self.threadOwnerInfoLoading = null;
            self.sSidebar.refresh();
        });

        this.sendActions = new Model.Menu.Menu();
        this.sendActions
            .addAction('Send & Resolve', function () {
                var self = this;

                return this.send().then(function () {
                    var $deferred = $.Deferred();
                    $.ajax({
                        url: sAPIAccess.endpoint(Controller.Component.sMemberChatBotStatus.URL_MEMBER_CHATBOT_DISABLE).delete(self.hMemberId),
                        method: Const.Method.DELETE
                    }).always(function () {
                        $deferred.resolve()
                    });
                    return $deferred;
                })
            }.bind(this))
            .addAction('Send & Keep open', function () {
                var self = this;

                return this.send().then(function () {
                    var $deferred = $.Deferred();
                    $.ajax({
                        url: sAPIAccess.endpoint(Controller.Component.sMemberChatBotStatus.URL_MEMBER_CHATBOT_DISABLE).post(self.hMemberId),
                        method: Const.Method.POST
                    }).always(function () {
                        $deferred.resolve()
                    });
                    return $deferred;
                })
            }.bind(this));
    };

    /**
     * @name Controller.sMessageLog.Member#addMessageToSend
     * @param content
     */
    Member.prototype.addMessageToSend = function addMessageToSend(content) {
        this.$scope.$broadcast(Controller.Component.sMessageLog.EVENT_ADD_MESSAGE_BY_CONTENT, content);
    };

    /**
     * @name Controller.sMessageLog.Member#send
     * @return {$.Deferred}
     */
    Member.prototype.send = function send()
    {
        var self            = this,
            message         = this.sendCollection.messages.slice(0,1).pop(),
            messageAnchor   = message.createAndSetMessageAnchor(this.sDomainService.currentDomainId).messageAnchor
            ;

        return this.sMessageCollection.validateAndSend(messageAnchor, [], this.hMemberId, null, this.$scope).then(function() {
            self.notification.success("Message sent.");
            self.addQueuedMessageToLog();
        });
    };

    Member.prototype.addQueuedMessageToLog = function addQueuedMessageToLog() {
        var msg = this.sendCollection.messages[0],
            clone = msg.clone()
            ;

        clone.setMeta('messageType', {'type' : 'manual'});
        clone.setMeta('history', {'sent' : new Date().getTime()});
        this.collection.addItem(clone, false);

        msg.removeParts();
    };

    Member.prototype.deleteMember = function deleteMember() {
        var options = {
            confirm: 'Yes, delete',
            decline: 'Cancel',
            title: 'Delete this user permanently?',
            content: 'You are about to delete this user permanently and eventually remove all data, including subscriptions and labels. <br><br>This process can\'t be undone.<br><br> Continue?',
            ariaLabel: ''
        };

        this.sConfirm.open(options).then(function() {

        });
    };

    /**
     * @name Controller.sMessageLog.Member#toggleShowChannelMessages
     */
    Member.prototype.toggleShowChannelMessages = function toggleShowChannelMessages() {
        var self = this;
        this.collection.reset({hideChannelMessages: (!this.showChannelMessages ? 1 : 0)});
        return this.collection.fetchNext().then(function() {
            digestIfNeeded(self.$scope);
        })
    };

    Member.prototype.toggleSideBar = function toggleSideBar() {
        this.sSidebar.toggle('member-details');
    };

    Member.prototype.isSideBarOpen = function isSideBarOpen() {
        return this.sSidebar.isActive('member-details');
    };

    Member.prototype.takeThreadControl = function takeThreadControl() {
        var self = this;
        return this.sFacebookHandover.takeThreadControl(this.hMemberId).then(
            function (threadOwnerInfo) {
                self.threadOwnerInfo = threadOwnerInfo;
            }
        ).always(function () {
            self.sSidebar.refresh();
        });
    };

    ns.Member = Member;
})(Object.namespace('Controller.sMessageLog'));
