(function (ns) {
    /**
     * @namespace
     * @alias sMessageAnchor.Route.Overview
     * @extends sRoute
     * @constructor
     */
    var Overview = function Overview() {
        this.controller     = sMessageAnchor.Controller.Overview;
        this.controllerAs   = '$ctrl';
        this.templateUrl    = 'smessageanchor:overview';
        this.reloadOnSearch = false;
        this.requireDomain  = true;
        this.accessControl  = {'messageAnchor.byDomain.all': Const.Method.GET};

        Overview._pProto.constructor.call(this);
    };

    Object.extendProto(Overview, sRoute);

    ns.Overview = Overview;
})(Object.namespace('sMessageAnchor.Route'));
