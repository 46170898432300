(function (ns) {
    var DEFAULT_RENDER  = 'explicit'
    ;
    /**
     * @namespace
     * @alias Service.ReCAPTCHA
     *
     * @constructor
     * @param {{apiUrl: String, versions: Object.<String, String>}} captchaConfig
     */
    var ReCAPTCHA = function (captchaConfig) {
        var $rdyDeferred    = null,
            lastRender      = null,
            lastResponse    = null
        ;


        this.ready = function ready(render) {
            if ($rdyDeferred !== null) {
                return $rdyDeferred;
            }
            render = render || DEFAULT_RENDER;
            $rdyDeferred = $.Deferred();
            var addScriptIfNeeded = function addScriptIfNeeded() {
                    var deferredScriptTags = $('script[defer][src*="render=' + render + '"]'),
                        deferredScriptsCount = deferredScriptTags.length,
                        $scriptDeferred = $.Deferred()
                    ;

                    if (!window.grecaptcha) {
                        if (!deferredScriptsCount) {
                            var s = document.createElement('script');
                            s.setAttribute('src', captchaConfig.apiUrl + '?render=' + render);
                            s.async = true;
                            s.defer = true;
                            s.onload = function () {
                                waitForFunctions().then(function () {
                                    lastRender = render;
                                    $rdyDeferred.resolve(window.grecaptcha);
                                });
                            };

                            s.onerror = function() {
                                $rdyDeferred.reject('Failed to load the script!');
                            };
                            document.body.appendChild(s);
                            return $rdyDeferred.promise();
                        }

                        $.each(deferredScriptTags, function (index, element) {
                            element.onload = function () {
                                deferredScriptsCount--;
                                if (!deferredScriptsCount) {
                                    $scriptDeferred.resolve();
                                }
                            }
                        });
                    } else {
                        $scriptDeferred.resolve();
                    }

                    return $scriptDeferred.promise();
                },
                waitForFunctions = function waitForFunctions() {
                    var killTO = 1000,
                        $funcDeferred = $.Deferred()
                    ;

                    var interval = setInterval(function () {
                        if (Object.getOwnPropertyNames(window.grecaptcha).length !== 1) {
                            clearInterval(interval);
                            $funcDeferred.resolve();
                        }

                        killTO -= 10;
                        if (killTO <= 0) {
                            clearInterval(interval);
                            $funcDeferred.reject('ReCaptcha initialization timed out!');
                        }
                    }, 10);

                    return $funcDeferred.promise();
                }
            ;

            addScriptIfNeeded().then(function () {
                waitForFunctions().then(function () {
                    lastRender = render;
                    $rdyDeferred.resolve(window.grecaptcha);
                })
            });

            return $rdyDeferred.promise();
        };

        this.reload = function reload(render) {
            var self = this;
            if (render === lastRender && $rdyDeferred) {
                return $rdyDeferred;
            }

            if ($rdyDeferred !== null) {
                return $rdyDeferred.then(function() {
                    $rdyDeferred = null;
                    try {
                        window.grecaptcha.reset();
                    } catch (err) {
                        // don't show no client errors
                    }
                    delete window.grecaptcha;
                    $('script[defer][src*="render=' + render + '"]').remove();

                    return self.ready(render);
                });
            }

            return self.ready(render);
        };

        /**
         * @param siteKey
         * @param action
         * @return {PromiseLike}
         */
        this.execute = function execute(siteKey, action) {
            var $execDeferred = $.Deferred();

            this.ready(lastRender).then(function(gReCaptcha) {
                return gReCaptcha.execute(siteKey, {action: action}).then(function(token) {
                    lastResponse = token;
                    $execDeferred.resolve(lastResponse);
                });
            });

            return $execDeferred.promise();
        };

        /**
         * @param siteKey
         * @param element
         * @param callback
         * @return {PromiseLike}
         */
        this.render = function render(siteKey, element, callback) {
            callback = callback || function() {};
            return this.ready(lastRender).then(function(gReCaptcha) {
                return gReCaptcha.render(element, {
                    sitekey: siteKey,
                    callback: callback
                });
            });
        };

        /**
         * @param widgetId
         * @return {PromiseLike}
         */
        this.reset = function reset(widgetId) {
            return this.ready(lastRender).then(function(gReCaptcha) {
                gReCaptcha.reset(widgetId);
            });
        };
        
        Object.defineProperties(
            this,
            {
                lastResponse: {
                    enumerable: true,
                    get: function () {
                        return lastResponse;
                    }
                    /**
                     * @property
                     * @name Service.ReCAPTCHA#lastResponse
                     * @type {String}
                     */
                }
            });
    };


    ns.ReCAPTCHA = ReCAPTCHA;
})(Object.namespace('Service'));