(function (ns) {
    /**
     * @namespace
     * @alias Model.Facebook.ConnectionException
     *
     * @constructor
     * @extends Error
     * @param {String} message
     */
    var ConnectionException = function (message) {
        this.name       = 'FacebookConnectionException';
        this.message    = message;
        this.stack      = Error().stack;
    };

    Object.extendProto(ConnectionException, Error);

    ns.ConnectionException = ConnectionException;
})(Object.namespace('Model.Facebook'));
