(function(ns) {
    var STEP_OVERVIEW   = 'overview',
        STEP_PAGE_LIST  = 'pageList',
        STEP_PAGE_INPUT = 'pageInput'
    ;
    /**
     * @namespace
     * @alias sFacebook.Controller.sFacebookPage
     * @constructor
     *
     * @param $scope
     * @param $mdDialog
     * @param {sFacebook.Service.sFacebookPage} sFacebookPage
     */
    var PageSetupDialog = function PageSetupDialog($scope, $mdDialog, sFacebookPage) {
        var self = this;

        this.$scope         = $scope;
        this.$mdDialog      = $mdDialog;
        this.sFacebookPage  = sFacebookPage;

        /**
         * @property
         * @name sFacebook.Controller.PageSetupDialog#step
         * @type {String}
         */
        this.step = STEP_OVERVIEW;

        /**
         * @property
         * @name sFacebook.Controller.PageSetupDialog#pageSender
         * @type {?Model.Sender.FacebookPage}
         */
        this.pageSender = null;

        /**
         * @property
         * @name sFacebook.Controller.PageSetupDialog#currentConnection
         * @type {?Model.Facebook.Connection}
         */

        /**
         * @property
         * @name sFacebook.Controller.PageSetupDialog#readableGraphPages
         * @type {Array}
         */

        this.resetProcess();

        /**
         * @property
         * @name sFacebook.Controller.PageSetupDialog#loading
         * @type {PromiseLike}
         */
        this.loading = this.sFacebookPage.getSkeletonPage().then(function (pageSender) {
            self.pageSender         = pageSender;
            self.currentConnection  = self.sFacebookPage.getConnectionInstance(pageSender);
        }).always(function () {
            digestIfNeeded(self.$scope);
        });
    };

    /**
     * @function
     * @name sFacebook.Controller.PageSetupDialog#resetProcess
     */
    PageSetupDialog.prototype.resetProcess = function resetProcess() {
        this.step               = STEP_OVERVIEW;
        this.readableGraphPages = [];
    };

    /**
     * @function
     * @name sFacebook.Controller.PageSetupDialog#startManualProcess
     */
    PageSetupDialog.prototype.startManualProcess = function startManualProcess() {
        this.step = STEP_PAGE_INPUT;
    };

    /**
     * @function
     * @name sFacebook.Controller.PageSetupDialog#startAutomatedProcess
     */
    PageSetupDialog.prototype.startAutomatedProcess = function startAutomatedProcess() {
        var self = this;

        this.loading = this.sFacebookPage.authenticateWithFacebook(this.currentConnection, this.pageSender).then(
            function () {
                return self.sFacebookPage.getReadableGraphPages(self.currentConnection).then(function (graphPages) {
                    self.readableGraphPages = graphPages;
                    self.step               = STEP_PAGE_LIST;
                });
            }
        ).always(function () {
            digestIfNeeded(self.$scope);
        });
    };

    /**
     * @function
     * @name sFacebook.Controller.PageSetupDialog#onHandleSelectionChange
     * @param {FacebookPage} facebookGraphPage
     */
    PageSetupDialog.prototype.onHandleSelectionChange = function onHandleSelectionChange(facebookGraphPage) {
        if (!facebookGraphPage) {
            return;
        }

        this.pageSender.pageId          = facebookGraphPage.id;
        this.pageSender.pageName        = facebookGraphPage.name;

        this.pageSender.setMeta('pageAccessToken', facebookGraphPage.pageAccessToken);
    };

    /**
     * @function
     * @name sFacebook.Controller.PageSetupDialog#isItemDisabled
     * @param {FacebookPage} facebookGraphPage
     * @return {Boolean}
     */
    PageSetupDialog.prototype.isItemDisabled = function isItemDisabled(facebookGraphPage) {
        return !!facebookGraphPage.alreadyInList;
    };

    /**
     * @function
     * @name sFacebook.Controller.PageSetupDialog#cancel
     */
    PageSetupDialog.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    /**
     * @function
     * @name sFacebook.Controller.PageSetupDialog#confirmSelection
     */
    PageSetupDialog.prototype.confirmSelection = function confirmSelection() {
        this.$mdDialog.hide(this.pageSender);
    };

    ns.PageSetupDialog = PageSetupDialog;
})(Object.namespace('sFacebook.Controller'));
