/**
 * Created by Gabor on 29-May-17.
 */

var ns = Object.namespace('Model.AI.Reaction'),
    Collection = ns.Collection = function () {

        var items = [],
            itemsCache = [],
            refreshItemsCache = function () {
                itemsCache.splice.apply(itemsCache, [0, itemsCache.length].concat(items));
            },
            minLength,
            maxLength
        ;

        /**
         * @property name
         * @type String
         */

        /**
         * @property description
         * @type String
         */

        /**
         * @property groupKey
         * @type String
         */

        /**
         * @property domainId
         * @type String
         */

        /**
         * @property
         * @name Model.AI.Reaction.Collection#reactions
         * @type Model.AI.Reaction[]
         */

        /**
         * @property
         * @name Model.AI.Reaction.Collection#minLength
         * @type Number
         */

        /**
         * @property
         * @name Model.AI.Reaction.Collection#maxLength
         * @type Number|undefined
         */

        Object.defineProperties(
            this,
            {
                'reactions': {
                    enumerable: true,
                    get: function () {
                        return itemsCache;
                    }
                },
                'minLength': {
                    get: function () {
                        return minLength === undefined ? 0 : minLength;
                    },
                    set: function (val) {
                        minLength = parseInt(val);
                    }
                },
                'maxLength': {
                    get: function () {
                        return maxLength;
                    },
                    set: function (val) {
                        maxLength = parseInt(val);
                    }
                }
            }
        );

        /**
         * @name Model.AI.Reaction.Collection#addReaction
         * @param {Model.AI.Reaction} reaction
         */
        this.addReaction = function addReaction(reaction) {
            if (!this.canGainReaction()) {
                return;
            }

            Object.instanceOf(reaction, Model.AI.Reaction);
            var pos;
            if ((pos = this.getIndex(reaction)) !== -1) {
                items.splice(pos, 1, reaction);
            } else {
                items.push(reaction);
            }

            refreshItemsCache();
        };

        /**
         * @name Model.AI.Reaction.Collection#removeReaction
         * @param {Model.AI.Reaction} reaction
         */
        this.removeReaction = function (reaction) {
            if (!this.canLoseReaction()) {
                return;
            }

            var pos;
            if ((pos = this.getIndex(reaction)) !== -1) {
                items.splice(pos, 1);
            }

            refreshItemsCache();
        };

        /**
         * @name Model.AI.Reaction.Collection#replaceReaction
         * @param rxToReplace
         * @param rxReplacing
         */
        this.replaceReaction = function replaceReaction(rxToReplace, rxReplacing) {
            var index;

            if ((index = this.getIndex(rxToReplace)) === -1) {
                return;
            }

            items.splice(index, 1, rxReplacing);
            refreshItemsCache();
        };

        /**
         * @name Model.AI.Reaction.Collection#canLoseReaction
         */
        this.canLoseReaction = function canLoseReaction() {
            return items.length && items.length >= this.minLength;
        };

        /**
         * @name Model.AI.Reaction.Collection#canGainReaction
         */
        this.canGainReaction = function canGainReaction() {
            return this.maxLength === undefined || items.length < this.maxLength;
        };
    };

/**
 * @param {Model.AI.Reaction} reaction
 * @returns {number}
 */
Collection.prototype.getIndex = function getIndex(reaction) {
    return this.reactions.indexOf(reaction);
};

/**
 * @name Model.AI.Reaction.Collection#findInArray
 * @param {Array} reactions
 */
Collection.prototype.findInArray = function findInArray(reactions) {
    for (var i = reactions.length - 1; i >= 0; i--) {
        if (reactions[i].ident === this.groupKey) {
            this.addReaction(reactions.splice(i, 1).pop());
        }
    }
};

/**
 * @name Model.AI.Reaction.Collection#createAndAddReaction
 * @returns {Model.AI.Reaction}
 */
Collection.prototype.createAndAddReaction = function createAndAddReaction() {
    var reaction = new Model.AI.Reaction();
    reaction.domainId = this.domainId;
    reaction.ident = this.groupKey;
    this.addReaction(reaction);

    return reaction;
};

Model.AI.Reaction.Collection.identMap = {
    'welcome_message': {
        groupKey:       'welcome_message',
        name:           'Welcome message',
        description:    'This is the first message the user will receive when they click "Get Started".',
        minLength:      1,
        maxLength:      1
    },
    'welcome_existing_user': {
        groupKey:       'welcome_existing_user',
        name:           'Welcome existing user',
        description:    'This message will be sent for the first time a user messages the bot outside the Get Started flow.',
        minLength:      1
    },
    'get_thread_control_conversation': {
        groupKey:       'get_thread_control_conversation',
        name:           null,
        description:    'This message will be sent when the bot is handed back over the Thread Control.',
        minLength:      1,
        maxLength:      1
    },
    'confirmation_unknowable': {
        groupKey:       'confirmation_unknowable',
        name:           'Fallback response',
        description:    'This message will be sent if the bot doesn\'t understand  what the user meant.',
        minLength:      1
    },
    'confirmation_stop': {
        groupKey:       'confirmation_stop',
        name:           'Deactivate user',
        description:    'This message confirms that the user has been deactivated.',
        minLength:      1
    },
    'confirmation_addPOI': {
        groupKey:       'confirmation_addPOI',
        name:           'Confirm subscription',
        description:    'This message will be sent when the user subscribes to a Segment.',
        minLength:      0
    },
    'confirmation_removePOI': {
        groupKey:       'confirmation_removePOI',
        name:           'Confirm cancellation',
        description:    'This message will be sent when the user unsubscribes from a Segment.',
        minLength:      0
    },
    'confirmation_startUserfeedback': {
        groupKey:       'confirmation_startUserfeedback',
        name:           'Trigger user feedback',
        description:    'This message should tell the user that her next message will be received by a human.',
        minLength:      0
    },
    'confirmation_sendUserfeedback': {
        groupKey:       'confirmation_sendUserfeedback',
        name:           'Receive user feedback',
        description:    'This message informs the user that their feedback message was received by a human.',
        minLength:      0
    },
    'conversation_deactivated': {
        groupKey:       'conversation_deactivated',
        name:           'Content not available',
        description:    'This message will be sent when user tries to request content that is not available anymore.',
        minLength:      1
    },
    'bot_error': {
        groupKey:       'bot_error',
        name:           'Bot error',
        description:    'This message will be sent when an error occurs in the chatbot.',
        minLength:      0
    }
};