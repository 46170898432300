(function(ns) {
    /**
     * @namespace
     * @alias sSettings.Controller.Platform.FacebookMessenger
     * @constructor
     *
     * @param {Service.sDomain} sDomainService
     * @param $mdDialog
     * @param Notification
     * @param {Service.sConfirm} sConfirm
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param $scope
     * @param {sFacebook.Service.sFacebookPage} sFacebookPage
     * @param {sFacebook.Service.sFacebookAdAccount} sFacebookAdAccount
     * @param {Model.Menu.Menu} menu
     * @param {Model.DomainMeta} greeting
     * @param {Array} reactions
     * @param {Model.DomainMeta} getStartedSettings
     * @param $rootScope
     * @param sBugsnag
     */
    var FacebookMessenger = function (
        sDomainService,
        $mdDialog,
        Notification,
        sConfirm,
        sAPIAccess,
        $scope,
        sFacebookPage,
        sFacebookAdAccount,
        menu,
        greeting,
        reactions,
        getStartedSettings,
        $rootScope,
        sBugsnag
    ) {
        var self = this,
            lockFn
        ;

        this.staticElements         = [];
        this.sDomainService         = sDomainService;
        this.notification           = Notification;
        this.menu                   = menu;
        this.greeting               = greeting;
        this.$scope                 = $scope;
        this.sFacebookPage          = sFacebookPage;
        this.sFacebookAdAccount     = sFacebookAdAccount;
        this.$deRegister            = [];
        this.imagesEndpoint         = Object.namespace('sAPIAccess.Service.sAPIAccess').imagePath;
        this.sConfirm               = sConfirm;
        this.sAPIAccess             = sAPIAccess;
        this.$mdDialog              = $mdDialog;
        this.getStartedSettings     = getStartedSettings;
        this.savingGetStarted       = false;
        this.setupReactionCollections(reactions);
        this.setupGetStartedActionContext();

        $scope.$on('$destroy', this.$onDestroy.bind(this));

        this.$deRegister.push(
            this.$scope.$watch(function () {
                    if (self.menu.value) {
                        return self.menu.value.allowUserInput;
                    }
                },
                function (val) {
                    if (val) {
                        self.staticElements = ['Send a message ...'];
                    } else {
                        self.staticElements = [];
                    }
                })
        );

        this.lockRouteChange = function lockRouteChange() {
            this.releaseRouteChange();

            lockFn = $rootScope.$on('$routeChangeStart', function(event) {
                event.preventDefault();
                sBugsnag.notify('Route change attempt while messenger connect dialog in progress', {});
            });

            // put first
            $rootScope.$$listeners.$routeChangeStart.splice(0, 0, $rootScope.$$listeners.$routeChangeStart.pop());
        };

        this.releaseRouteChange = function releaseRouteChange() {
            if (!lockFn) {
                return;
            }

            lockFn();
        };

        this.pagesList      = this.getPagesList();
        this.adAccountList  = this.getAdAccountList();

        /**
         * @property {*}
         * @name sSettings.Controller.Platform.FacebookMessenger#greetingForm
         */

        /**
         * @property {*}
         * @name sSettings.Controller.Platform.FacebookMessenger#persistentMenuForm
         */
    };

    /**
     * @function
     * @name sSettings.Controller.Platform.FacebookMessenger#$onDestroy
     */
    FacebookMessenger.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name sSettings.Controller.Platform.FacebookMessenger#getPagesList
     * @return {Model.List}
     */
    FacebookMessenger.prototype.getPagesList = function getPagesList() {
        var self = this,
            column,
            list = new Model.List.RESTAccess(
                Model.Sender.FacebookPage,
                this.sAPIAccess.endpoint('facebook.page.all').get()
            )
        ;

        // Only show current domain pages
        list.filters.createAndAdd('domainId', this.sDomainService.currentDomainId, true);

        column = list.columns.createAndAdd('messengerLink');
        column.isSortable = false;
        column.label = '';

        column = list.columns.createAndAdd('pageName');
        column.isSortable = false;
        column.label = 'Name';

        column = list.columns.createAndAdd('timeUpdated');
        column.isSortable = false;
        column.label = 'Updated';

        column = list.columns.createAndAdd('status');
        column.isSortable = false;
        column.label = 'Status';

        list.actions = {};

        if (this.sAPIAccess.isAllowed('facebook.page.synchronize', Const.Method.PUT)) {
            list.actions['synchronize'] = new Model.Menu.Action(
                'synchronize',
                function (fbPage) {
                    return self.sFacebookPage.synchronize(fbPage).always(
                        function () {
                            self.notification.success('Connection status updated');
                            list.load(false, true);
                            digestIfNeeded(self.$scope);
                        }
                    );
                }
            );
        }

        if (this.sAPIAccess.isAllowed('facebook.page.connect', Const.Method.PUT)) {
            list.actions['connect'] = new Model.Menu.Action(
                'connect',
                function (fbPage) {
                    return self.sFacebookPage.connectWithAuthentication(fbPage, true).then(
                        function () {
                            self.notification.success('Connected page');
                        },
                        function () {
                            self.notification.error('Could not connect page');
                        }).always(
                        function () {
                            list.load(false, true);
                            digestIfNeeded(self.$scope);
                        }
                    );
                }
            );
        }

        if (this.sAPIAccess.isAllowed('facebook.page.enable', Const.Method.PUT)) {
            list.actions['enable'] = new Model.Menu.Action(
                'enable',
                function (fbPage) {
                    return self.sFacebookPage.enable(fbPage).then(
                        function () {
                            self.notification.success('Enabled page');
                        },
                        function () {
                            self.notification.error('Could not enable page');
                        }).always(
                        function () {
                            list.load(false, true);
                            digestIfNeeded(self.$scope);
                        }
                    );
                }
            );
        }

        if (this.sAPIAccess.isAllowed('facebook.page.disable', Const.Method.PUT)) {
            list.actions['disable'] = new Model.Menu.Action(
                'disable',
                function (fbPage) {
                    return self.sFacebookPage.disable(fbPage).then(
                        function () {
                            self.notification.success('Disabled page');

                            self.$scope.$emit('sSenderDeactivated', fbPage);
                        },
                        function () {
                            self.notification.error('Could not disable page');
                        }).always(
                        function () {
                            list.load(false, true);
                            digestIfNeeded(self.$scope);
                        }
                    );
                }
            );
        }

        if (this.sAPIAccess.isAllowed('facebook.page.RESTAccess', Const.Method.DELETE)) {
            list.actions['delete'] = new Model.Menu.Action(
                'delete',
                function (fbPage) {
                    return self.sFacebookPage.remove(fbPage).then(
                        function () {
                            self.notification.success('Deleted page');

                            self.$scope.$emit('sSenderRemoved', fbPage);
                        },
                        function () {
                            self.notification.error('Could not delete page');
                        }).always(
                        function () {
                            list.load(false, true);
                            digestIfNeeded(self.$scope);
                        }
                    );
                }
            );
        }

        if (Object.keys(list.actions).length) {
            column = list.columns.createAndAdd('options');
            column.label = 'Options';
        }

        return list;
    };

    /**
     * @function
     * @name sSettings.Controller.Platform.FacebookMessenger#getAdAccountList
     * @return {Model.List}
     */
    FacebookMessenger.prototype.getAdAccountList = function getAdAccountList() {
        var self = this,
            column,
            list = new Model.List.RESTAccess(
                Model.Sender.FacebookAdAccount,
                this.sAPIAccess.endpoint('facebook.adAccount.all').get()
            )
        ;

        // Only show current domain pages
        list.filters.createAndAdd('domainId', this.sDomainService.currentDomainId, true);

        column = list.columns.createAndAdd('adAccountName');
        column.isSortable = false;
        column.label = 'Name';

        column = list.columns.createAndAdd('timeUpdated');
        column.isSortable = false;
        column.label = 'Updated';

        column = list.columns.createAndAdd('integrationStatus');
        column.isSortable = false;
        column.label = 'Status';

        list.actions = {};

        if (this.sAPIAccess.isAllowed('facebook.adAccount.connect', Const.Method.PUT)) {
            list.actions['connect'] = new Model.Menu.Action(
                'connect',
                function (adAccount) {
                    return self.sFacebookAdAccount.connect(adAccount).then(
                        function () {
                            self.notification.success('Connected Ad Account');
                        },
                        function () {
                            self.notification.error('Could not connect Ad Account');
                        }).always(
                        function () {
                            list.load(false, true);
                            digestIfNeeded(self.$scope);
                        }
                    );
                }
            );
        }

        if (this.sAPIAccess.isAllowed('facebook.adAccount.synchronize', Const.Method.PUT)) {
            list.actions['synchronize'] = new Model.Menu.Action(
                'synchronize',
                function (adAccount) {
                    return self.sFacebookAdAccount.synchronize(adAccount).then(
                        function () {
                            self.notification.success('Synchronized Ad Account');
                        },
                        function () {
                            self.notification.error('Could not synchronize Ad Account');
                        }).always(
                        function () {
                            list.load(false, true);
                            digestIfNeeded(self.$scope);
                        }
                    );
                }
            );
        }

        if (this.sAPIAccess.isAllowed('facebook.adAccount.RESTAccess', Const.Method.DELETE)) {
            list.actions['remove'] = new Model.Menu.Action(
                'remove',
                function (adAccount) {
                    return self.sFacebookAdAccount.remove(adAccount).then(
                        function () {
                            self.notification.success('Removed Ad Account');
                        },
                        function () {
                            self.notification.error('Could not remove Ad Account');
                        }).always(
                        function () {
                            list.load(false, true);
                            digestIfNeeded(self.$scope);
                        }
                    );
                }
            );
        }

        if (Object.keys(list.actions).length) {
            column = list.columns.createAndAdd('options');
            column.label = 'Options';
        }

        return list;
    };

    /**
     * @function
     * @name sSettings.Controller.Platform.FacebookMessenger#addNewPage
     * @return {PromiseLike<Model.Sender.FacebookPage>}
     */
    FacebookMessenger.prototype.addNewPage = function addNewPage() {
        var self = this;

        return this.sFacebookPage.addNewPage().then(function (facebookPage) {
            self.notification.success('New page successfully added');
            
            self.$scope.$emit('sSenderConnected', { type: Model.Sender.FacebookPage.TYPE });
        }, function (reason) {
            if (reason === sFacebook.Service.sFacebookPage.REJECT_REASON_MANUAL) {
                return;
            }
            self.notification.error('Could not add new page');
        }).always(function () {
            self.pagesList.load(false, true);
            digestIfNeeded(self.$scope);
        });
    };

    /**
     * @function
     * @name sSettings.Controller.Platform.FacebookMessenger#addNewAdAccount
     * @return {PromiseLike<Model.Sender.FacebookAdAccount>}
     */
    FacebookMessenger.prototype.addNewAdAccount = function addNewAdAccount() {
        var self = this;

        return this.sFacebookAdAccount.addNewAdAccount().then(function () {
            self.notification.success('New Ad Account successfully added');
        }, function (reason) {
            if (reason === sFacebook.Service.sFacebookAdAccount.REJECT_REASON_MANUAL) {
                return;
            }
            self.notification.error('Could not add new Ad Account');
        }).always(function () {
            self.adAccountList.load(false, true);
            digestIfNeeded(self.$scope);
        });
    };

    /**
     * @function
     * @name sSettings.Controller.Platform.FacebookMessenger#hasMenuDeprecatedDepth
     * @return {Boolean}
     */
    FacebookMessenger.prototype.hasMenuDeprecatedDepth = function hasMenuDeprecatedDepth() {
        return !!(this.persistentMenuForm.$error && this.persistentMenuForm.$error.menuMaxLevelValidator);
    };

    /**
     * @function
     * @param {Boolean=} force
     */
    FacebookMessenger.prototype.onSaveMenu = function onSaveMenu(force) {
        var self = this;

        if (!this.autoSaveMenu && !force) {
            return;
        }

        if (this.menuTO) {
            clearTimeout(this.menuTO);
        }

        if (this.persistentMenuForm.$invalid) {
            this.disableAutoSaveMenu();
            return;
        }

        this.menuTO = setTimeout(
            function () {
                self.menuSaving = true;
                digestIfNeeded(self.$scope);
                self.menu.save().then(function () {
                    self.autoSaveMenu = true;
                    self.notification.success('Persistent menu saved.');
                }, function (error) {
                    if (error && error.messages && error.messages instanceof Array) {
                        for (var i = 0; i<error.messages.length; i++) {
                            self.notification.error('Persistent menu error: ' + error.messages[i]);
                        }
                    }
                }).always(function () {
                    self.menuSaving = false;
                    digestIfNeeded(self.$scope);
                });
            },
            force ? 0 : 1000);
    };

    /**
     * @function
     * @param {Boolean=} force
     */
    FacebookMessenger.prototype.onSaveGreeting = function onSaveGreeting(force) {
        var self = this;

        if (!this.autoSaveGreeting && !force) {
            return;
        }

        if (this.greetingTO) {
            clearTimeout(this.greetingTO);
        }

        if (this.greetingForm.$invalid) {
            this.disableAutoSaveGreeting();
            return;
        }

        this.greetingTO = setTimeout(function () {
                self.greetingSaving = true;
                digestIfNeeded(self.$scope);
                self.greeting.save().then(function () {
                    self.autoSaveGreeting = true;
                    self.notification.success('Greeting saved.');
                }, function (error) {
                    if (error && error.messages && error.messages instanceof Array) {
                        for (var i = 0; i<error.messages.length; i++) {
                            self.notification.error('Greeting error: ' + error.messages[i]);
                        }
                    }
                }).always(function () {
                    self.greetingSaving = false;
                    digestIfNeeded(self.$scope);
                });
            },
            force ? 0 : 1000);
    };

    /**
     * @function
     */
    FacebookMessenger.prototype.disableAutoSaveMenu = function disableAutoSaveMenu() {
        this.autoSaveMenu = false;
    };

    /**
     * @function
     */
    FacebookMessenger.prototype.disableAutoSaveGreeting = function disableAutoSaveGreeting() {
        this.autoSaveGreeting = false;
    };

    /**
     * @function
     * @param {Model.AI.Reaction} reaction
     */
    FacebookMessenger.prototype.setActiveReaction = function setActiveReaction(reaction) {
        this.activeReaction = reaction;
    };

    /**
     * @function
     */
    FacebookMessenger.prototype.saveGetStartedSettings = function saveGetStartedSettings() {
        var self = this;
        this.savingGetStarted = true;

        $.when(this.saveActiveReaction()).always(function () {
            self.getStartedSettings.save().then(function () {
                self.notification.success('Preferences were successfully saved.');
            }, function () {
                self.notification.error('Preferences could not be saved.');
            }).always(function () {
                self.savingGetStarted = false;
            });
        });
    };

    /**
     * @function
     * @return {Boolean|String}
     */
    FacebookMessenger.prototype.saveActiveReaction = function saveActiveReaction() {
        if (!this.getStartedSettings.value.showGetStartedButton || !this.activeReaction) {
            return true;
        }

        return this.activeReaction.save();
    };

    /**
     * @function
     * @param {Array} reactions
     */
    FacebookMessenger.prototype.setupReactionCollections = function setupReactionCollections(reactions) {
        var reactionCollections = {},
            allowedReactions = [
                'welcome_message',
                'get_thread_control_conversation'
            ],
            identMap = Model.AI.Reaction.Collection.identMap,
            reactionCollection,
            groupKey
        ;

        for (var i = 0; i < allowedReactions.length; i++) {
            groupKey = allowedReactions[i];

            reactionCollection = new Model.AI.Reaction.Collection();
            reactionCollection.groupKey = groupKey;
            reactionCollection.domainId = this.sDomainService.currentDomainId ? this.sDomainService.currentDomainId : null;
            reactionCollection.findInArray(reactions);

            for (var prop in identMap[groupKey]) {
                reactionCollection[prop] = identMap[groupKey][prop];
            }

            reactionCollections[groupKey] = reactionCollection;
        }

        this.reactionCollections = reactionCollections;
        this.activeReaction = null;
    };

    /**
     * @function
     * Setup context for actions - only actions allowed for get started button can be selected
     */
    FacebookMessenger.prototype.setupGetStartedActionContext = function setupGetStartedActionContext() {
        Object.defineProperty(
            this,
            'getStartedActionContext',
            {
                get : function () {
                    return 'GetStarted';
                }
            }
        );
    };

    /**
     * @function
     * @param {String} index
     * @param element
     * @returns {*}
     */
    FacebookMessenger.menuParser = function (index, element) {
        if (element && element.items && element.type === 'menu') {
            var menu = new Model.Menu.Menu();
            return menu.fromJSON(element);
        }
        return Model.Action.Collection.jsonReviver.call(this, index, element);
    };

    /**
     * @function
     * Slow migration of old @first_name@ > @firstname @full_name@ shall be replace with @firstname @lastname
     * @param {String} index
     * @param element
     * @returns {*}
     */
    FacebookMessenger.greetingParser = function(index, element) {
        if (index === 'text') {
            element = element.replace(/@(first|last|full)_(name)@/g, function() {
                if (arguments[1] === 'full') {
                    return '@firstname @lastname';
                }

                return '@' + arguments[1] + arguments[2];
            });
        }

        return element;
    };

    /**
     * @function
     * @param {String} index
     * @param element
     * @returns {*}
     */
    FacebookMessenger.getStartedSettingsParser = function (index, element) {
        return Model.Action.Collection.jsonReviver.call(this, index, element);
    };

    ns.FacebookMessenger = FacebookMessenger;
})(Object.namespace('sSettings.Controller.Platform'));
