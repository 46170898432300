(function(ns){
    /**
     * @namespace
     * @alias Model.Exception.APIResponseError
     * @constructor
     *
     * @param {Object}  XMLHttpRequest
     * @param {*}       context
     * @param {String}  exceptionId
     * @param {String=} title
     * @param {*=}      body
     */
    var APIResponseError = function APIResponseError(XMLHttpRequest, context, exceptionId, title, body) {
        exceptionId = exceptionId || Model.UUID.generateUuidV4();
        title       = title || 'Sadly something went wrong';
        body        = body || '';

        Object.defineProperties(
            this,
            {
                exceptionId: {
                    enumerable: true,
                    get: function () {
                        return exceptionId;
                    }
                    /**
                     * @property
                     * @name Model.Exception.APIResponseError#exceptionId
                     * @type {*}
                     */
                },
                context: {
                    enumerable: true,
                    get: function () {
                        return context;
                    }
                    /**
                     * @property
                     * @name Model.Exception.APIResponseError#context
                     * @type {*}
                     */
                },
                name: {
                    enumerable: true,
                    value     : 'APIResponseError'
                    /**
                     * @property
                     * @name Model.Exception.APIResponseError#name
                     * @type {String}
                     */
                },
                title: {
                    enumerable: true,
                    get: function () {
                        return title;
                    }
                    /**
                     * @property
                     * @name Model.Exception.APIResponseError#title
                     * @type {String}
                     */
                },
                body: {
                    enumerable: true,
                    get: function () {
                        return body;
                    }
                    /**
                     * @property
                     * @name Model.Exception.APIResponseError#body
                     * @type {*}
                     */
                }
            }
        );

        this.XMLHttpRequest = XMLHttpRequest;

        APIResponseError._pProto.constructor.call(this, title + ' - ' + body);
    };

    Object.extendProto(APIResponseError, Error);

    /**
     * @static
     * @function
     * @name Model.Exception.APIResponseError.createFromXMLHttpRequest
     * @param {Object} XMLHttpRequest
     * @param {*}      context
     * @return {Model.Exception.APIResponseError}
     */
    APIResponseError.createFromXMLHttpRequest = function createFromXMLHttpRequest(XMLHttpRequest, context) {
        var exceptionId   = XMLHttpRequest.getResponseHeader("X-Exception-Id"),
            errorResponse = XMLHttpRequest.responseJSON
        ;

        if (!errorResponse || typeof errorResponse !== 'object') {
            return new APIResponseError(XMLHttpRequest, context, exceptionId);
        }

        return new APIResponseError(XMLHttpRequest, context, exceptionId, errorResponse.title, errorResponse.body);
    };

    ns.APIResponseError = APIResponseError;
})(Object.namespace('Model.Exception'));
