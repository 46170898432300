var Validator = Validator || {};

Validator.maxLength = function maxLength(value, maxLength) {
    maxLength = parseInt(maxLength);

    if (value && maxLength && maxLength > 0) {
        return value.countSymbols() <= maxLength;
    }
    return true;
};

Validator.required = function required(value) {
    if (value instanceof Model.sImage || value instanceof Model.sFile) {
        return true;
    }
    if (value instanceof Model.CTA.Collection) {
        return value.ctas.length > 0;
    }
    return (typeof value !== 'undefined') && value && (value.length > 0);
};