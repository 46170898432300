(function(ns) {
    /**
     * @namespace
     * @alias Model.AI.TemplateMatch
     * @extends Model.Message.AnchorWithContent
     * @constructor
     *
     */
    var TemplateMatch = function() {
        var self = this;

        TemplateMatch._pProto.constructor.apply(this, arguments);

        this.score = 0;

        this.matchType = '';

        Object.defineProperties(
            this,
            {
                matchTypeLabel: {
                    enumerable: true,
                    configurable: false,
                    get: function () {
                        if (self.matchType && TemplateMatch.MATCH_TYPE_LABELS.hasOwnProperty(self.matchType)) {
                            return TemplateMatch.MATCH_TYPE_LABELS[self.matchType];
                        }
                        return self.matchType;
                    }
                }
            }
        );
    };

    Object.extendProto(TemplateMatch, Model.Message.AnchorWithContent);

    /**
     * Creates template-match by data
     * @function Model.AI.TemplateMatch#createByData
     * @param {object} data
     * @static
     * @returns Model.AI.TemplateMatch
     */
    TemplateMatch.createByData = function createByData(data) {
        var uuid,
            aiTemplateMatch;

        if (data && data.hasOwnProperty('uuid')) {
            uuid = data.uuid;
        }

        aiTemplateMatch = new TemplateMatch(uuid);
        aiTemplateMatch.updateByData(data);

        return aiTemplateMatch;
    };

    TemplateMatch.MATCH_TYPE_KEY_FUZZY = 'fuzzy';
    TemplateMatch.MATCH_TYPE_KEY_RULE = 'rule';

    TemplateMatch.MATCH_TYPE_LABELS = {};
    TemplateMatch.MATCH_TYPE_LABELS[TemplateMatch.MATCH_TYPE_KEY_FUZZY] = 'AI Matched';
    TemplateMatch.MATCH_TYPE_LABELS[TemplateMatch.MATCH_TYPE_KEY_RULE] = 'Matched';

    ns.TemplateMatch = TemplateMatch;
})(Object.namespace('Model.AI'));