(function (ns) {
    var FORM_GROUPED_ROW_GROUP_CHANGED = 'FORM_GROUPED_ROW_GROUP_CHANGED';

    /**
     * @namespace
     * @alias Controller.Component.sDynamicFormGroupedRowController
     * @constructor
     *
     * @param $scope
     * @param $location
     */
    var sDynamicFormGroupedRowController = function sDynamicFormGroupedRowController($scope, $location) {
        this.$scope      = $scope;
        this.$location   = $location;
        this.$deRegister = [];

        /**
         * @name Controller.Component.sFilter#groups
         * @type Array
         */

        /**
         * @name Controller.Component.sFilter#model
         * @type Object
         */

        /**
         * @name Controller.Component.sFilter#helperControls
         * @type Object
         */
    };

    /**
     * @function
     * @name Controller.Component.sDynamicFormGroupedRowController#$onInit
     */
    sDynamicFormGroupedRowController.prototype.$onInit = function $onInit() {
        this.setSelectedGroup();

        var self = this;

        this.$deRegister.push(this.$scope.$watch(
            function () {
                return self.selectedGroup;
            },
            /**
             * @param {String} newVal
             * @param {String} oldVal
             */
            function (newVal, oldVal) {
                if (oldVal === newVal) {
                    return;
                }

                self.$scope.$emit(FORM_GROUPED_ROW_GROUP_CHANGED, newVal);
            })
        );
    };

    /**
     * @function
     * @name Controller.Component.sDynamicFormGroupedRowController#setSelectedGroup
     */
    sDynamicFormGroupedRowController.prototype.setSelectedGroup = function setSelectedGroup() {
        // Set selected group from URL
        var groupKey = this.$location.search()[Controller.Component.sFilter.PARAM_SELECTED_FILTER_GROUP];

        if (groupKey) {
            for (var i = 0; i < this.groups.length; i++) {
                if (this.groups[i].groupKey === groupKey) {
                    this.selectedGroup = groupKey;
                }
            }
        }

        // Select first group by default
        if (!this.selectedGroup && this.groups.length) {
            this.selectedGroup = this.groups[0].groupKey;
        }
    };

    /**
     * @function
     * @name Controller.Component.sDynamicFormGroupedRowController#$onDestroy
     */
    sDynamicFormGroupedRowController.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    Object.defineProperties(
        sDynamicFormGroupedRowController,
        {
            FORM_GROUPED_ROW_GROUP_CHANGED : {
                value : FORM_GROUPED_ROW_GROUP_CHANGED
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sDynamicFormGroupedRowController#FORM_GROUPED_ROW_GROUP_CHANGED
                 * @type {String}
                 */
            }
        }
    );

    ns.sDynamicFormGroupedRowController = sDynamicFormGroupedRowController;
})(Object.namespace('Controller.Component'));
