(function(ns) {
    var CONST_MAX_IMAGE_SIZE_IN_PX  = 960
    ;

    /**
     * @namespace
     * @alias Controller.Component.View.sMediaController
     *
     * @param $element
     * @param $scope
     */
    var sMediaController = function($element, $scope) {
        this.$deRegister = [];

        this.$element = $element;

        /**
         * @property
         * @type $scope
         * @name Controller.Component.View.sMediaController#$scope
         */
        this.$scope = $scope;

        /**
         * @property
         * @name Controller.Component.View.sMediaController#model
         * @type {Model.sFile}
         */

        /**
         * @property
         * @name Controller.Component.View.sMediaController#context
         * @type {*}
         */

        /**
         * @property
         * @name Controller.Component.View.sMediaController#aspectRatio
         * @type {Number}
         */
    };

    /**
     * @function
     * @name Controller.Component.View.sMediaController#$onInit
     */
    sMediaController.prototype.$onInit = function $onInit() {
        var self = this
            ;

        this.$deRegister.push(this.$scope.$watch(function() {
                if (!self.model || !self.model.reSizedImage ) {
                    return -1;
                }

                if (self.model.reSizedImage) {
                    return self.model.reSizedImage.content;
                }
            },
            /**
             * @param {String|Number} val
             */
            function(val) {
                if (val !== -1) {
                    self.src = val;
                }
            })
        );
    };

    sMediaController.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };
    
    /**
     * @param {Object.<String, SimpleChange>} $changes
     */
    sMediaController.prototype.$onChanges = function $onChanges($changes) {
        var self = this;
        if ($changes.model) {
            this.model = $changes.model.currentValue;
        }

        if (!this.model || !this.model.file) {
            this.type = null;
            return;
        }

        if ($changes.context && $changes.context.currentValue instanceof Model.Message.Content) {
            this.aspectRatio = $changes.context.currentValue.getMediaAspectRatio();
        }

        this.type = this.model.file.type.split('/').shift() || 'video';

        switch (this.type) {
            case Model.sImage.CONST_TYPE_IMAGE:
                if (this.model.reSizedImage) {
                    this.model.reSizedImage.loadContent().then(function(file) {
                        self.src = file.content;
                        digestIfNeeded(self.$scope);
                    });
                } else {
                    var model = this.model;
                    model.loadContent().then(function(file) {
                        if (model.isAnimated()) {
                            self.src = file.content;
                            digestIfNeeded(self.$scope);
                            return;
                        }

                        model.resize(CONST_MAX_IMAGE_SIZE_IN_PX).then(function (file) {
                            self.src = file.content;
                            model.resetReSizedImage();
                            digestIfNeeded(self.$scope);
                        });
                    });
                }
                break;
            case 'video':
            case 'audio':
                this.src = this.model.content;
                break;
        }
    };

    ns.sMediaController = sMediaController;
})(Object.namespace('Controller.Component.View'));
