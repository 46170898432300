(function (ns) {
    /**
     * @namespace
     * @alias sEntryPoint.Controller.Overview
     *
     * @param $scope
     * @param {Model.Sender[]} senders
     * @param Notification
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {Service.sDomain} sDomainService
     * @param $mdDialog
     * @param {sHeadline.Service.sTitle} sTitle
     */
    var Overview = function (
        $scope,
        senders,
        Notification,
        sAPIAccess,
        sDomainService,
        $mdDialog,
        sTitle
    ) {
        this.$scope         = $scope;
        this.entryPoint     = new Model.EntryPoint();
        this.senders        = senders;
        this.facebookSenders = senders.filter(function (sender) {
            return sender.type === Model.Sender.FacebookPage.TYPE;
        });
        this.notification   = Notification;
        this.sAPIAccess     = sAPIAccess;
        this.sDomainService = sDomainService;
        this.list           = this.getList();
        this.$mdDialog      = $mdDialog;
        this.sTitle         = sTitle;
        this.tagsEndpoint   = sAPIAccess.endpoint('tagging.byDomain.autocomplete.anchor')
            .get(sDomainService.currentDomainId, 'entryPoint');
    };

    /**
     * @function
     * @name sEntryPoint.Controller.Overview#getList
     * @returns {Model.List}
     */
    Overview.prototype.getList = function getList() {
        var list = new Model.List.RESTAccess(
            Model.EntryPoint,
            this.sAPIAccess.endpoint('entryPoint.RESTAccess').get()
            ),
            self = this,
            column
        ;

        list.filters.createAndAdd('title', null, false);
        list.filters.createAndAdd('tag', [], false);

        this.filterFields = {
            title : {
                component : 'sTextInput',
                options   : {
                    defaultValue : '',
                    attrs        : {
                        'placeholder' : 'Name',
                        'ng-disabled' : '$ctrl.helperControls.readonly',
                        'class'       : 'textsearch',
                        'is-disabled' : '$ctrl.helperControls.readonly'
                    },
                    label        : null
                }
            },
            tag   : {
                component : 'sTags',
                options   : {
                    defaultValue : [],
                    attrs        : {
                        'placeholder'         : 'Tags',
                        'is-disabled'         : '$ctrl.helperControls.readonly',
                        'autocomplete-source' : '$ctrl.helperControls.fields.tag.options.tagsEndpoint()',
                        'existing-only'       : true
                    },
                    tagsEndpoint : function () {
                        return self.tagsEndpoint;
                    },
                    asArray      : true,
                    label        : null
                }
            }
        };

        list.defaultOrderBy = {timeUpdated : 'DESC'};

        column            = list.columns.createAndAdd('title');
        column.isSortable = true;
        column.label      = 'Name';
        column            = list.columns.createAndAdd('tags');
        column.label      = 'Tags';
        // TODO IT-5933 uncomment this again
        // column            = list.columns.createAndAdd('actions');
        // column.label      = 'Actions';
        column            = list.columns.createAndAdd('timeUpdated');
        column.isSortable = true;
        column.label      = 'Updated';
        column            = list.columns.createAndAdd('status');
        column.isSortable = true;
        column.label      = 'Status';

        list.actions = {};

        if(this.facebookSenders.length) {
            list.actions['messengerLinks'] = new Model.Menu.Action('messengerLinks', this.showMessengerLinks.bind(this));
        }

        if (this.sAPIAccess.isAllowed('entryPoint.RESTAccess', Const.Method.PUT)) {
            list.actions['edit']       = new Model.Menu.Action('edit', this.edit.bind(this));
            list.actions['activate']   = new Model.Menu.Action('activate', this.toggleActiveStatus.bind(this));
            list.actions['deactivate'] = new Model.Menu.Action('deactivate', this.toggleActiveStatus.bind(this));
        }

        if (Object.keys(list.actions).length) {
            column       = list.columns.createAndAdd('options');
            column.label = 'Options';
        }

        return list;
    };

    /**
     * @function
     * @name sEntryPoint.Controller.Overview#edit
     * @param event
     * @param {Model.EntryPoint} entryPoint
     * @param {Number=} index
     * @return {$.Deferred<Model.EntryPoint>}
     */
    Overview.prototype.edit = function (event, entryPoint, index) {
        var clone = entryPoint ? entryPoint.clone() : null,
            self  = this
        ;

        return this.$mdDialog.show(
            this.$mdDialog.sEntryPointEdit()
                .openFrom(event.currentTarget)
                .closeTo(event.currentTarget)
                .entryPoint(clone)
        ).then(
            function (entryPoint) {
                if (self.list.orderBy && JSON.stringify(self.list.orderBy) === JSON.stringify({timeUpdated : "DESC"})) {
                    // if it is already in the list and not first, then cut out
                    if (index !== undefined) {
                        self.list.records.splice(index, 1);
                    }
                    self.list.records.unshift(entryPoint);
                    if (self.list.pager.recordsPerPage < self.list.records.length) {
                        self.list.records.pop();
                    }
                } else {
                    self.list.load(false, true);
                }

                if (clone) {
                    self.notification.success('Entry Point was successfully saved.');
                }
            },
            function () {
                // Handler to avoid PossiblyUnhandledRejection error
            }
        );
    };

    /**
     * @function
     * @name sEntryPoint.Controller.Overview#showMessengerLinks
     * @param event
     * @param {Model.EntryPoint} entryPoint
     */
    Overview.prototype.showMessengerLinks = function (event, entryPoint) {
        if(!this.facebookSenders.length) {
            return;
        }

        this.$mdDialog.show(
            this.$mdDialog.sEntryPointMessengerLinks()
                .openFrom(event.currentTarget)
                .closeTo(event.currentTarget)
                .entryPoint(entryPoint)
                .facebookSenders(this.facebookSenders)
        );
    };

    /**
     * @function
     * @name sEntryPoint.Controller.Overview#toggleActiveStatus
     * @param {Model.EntryPoint} entryPoint
     * @return {Promise}
     */
    Overview.prototype.toggleActiveStatus = function (entryPoint) {
        var self = this;

        if (entryPoint.isActive()) {
            entryPoint.deactivate();
        } else {
            entryPoint.activate();
        }

        return entryPoint
            .save()
            .then(
                function () {
                    self.notification.success('Status was successfully changed.');
                    self.list.load(false, true);
                },
                function (jqXHR) {
                    // Revert activation changes
                    if (entryPoint.isActive()) {
                        entryPoint.deactivate();
                    } else {
                        entryPoint.activate();
                    }

                    if (jqXHR && jqXHR.status === 403 && jqXHR.getResponseHeader(Const.Headers.X_RESOURCE_LIMIT_REACHED)) {
                        return self.notification.limitReached('info', jqXHR.getResponseHeader(Const.Headers.X_RESOURCE_LIMIT_REACHED));
                    }

                    self.notification.error('An error occurred while saving the Entry Point.');
                }
            );
    };

    ns.Overview = Overview;
})(Object.namespace('sEntryPoint.Controller'));
