(function(ns) {
    var EVENT_TITLE_SET = 'sEventTitleSet';

    /**
     * @namespace
     * @alias sHeadline.Service.sTitle
     * @constructor
     *
     * @param $rootScope
     * @param {Object} rules
     */
    var sTitle = function ($rootScope, rules) {
        var self = this
            ;

        this.title          = '';
        this.subTitle       = '';
        this.$deRegister    = [];

        $rootScope.$on('$destroy', this.$onDestroy.bind(this));

        var updateFn = function (event, $route) {
            for (var i in rules) {
                var fragments = ['Spectrm'];
                if (!rules.hasOwnProperty(i) || !rules[i].path || rules[i].path !== $route.originalPath) continue;

                if (rules[i].subTitle) {
                    if (rules[i].subTitle instanceof Function) {
                        self.subTitle = rules[i].subTitle.call(self, $route);
                    } else {
                        self.subTitle = rules[i].subTitle;
                    }
                    fragments.unshift(self.subTitle);
                }

                if (rules[i].title) {
                    if (rules[i].title instanceof Function) {
                        self.title = rules[i].title.call(self, $route);
                    } else {
                        self.title = rules[i].title;
                    }
                    fragments.unshift(self.title);
                }

                document.title = fragments.join(' - ');
            }

            $rootScope.$emit(EVENT_TITLE_SET);
        };

        this.$deRegister.push($rootScope.$on('$routeChangeSuccess', updateFn));
        this.$deRegister.push($rootScope.$on('$routeUpdate', updateFn));

        this.$deRegister.push($rootScope.$on('$routeChangeStart', function() {
            self.title      = '';
            self.subTitle   = '';
        }));

    };

    /**
     * @method
     * @name sHeadline.Service.sTitle#$onDestroy
     */
    sTitle.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    Object.defineProperties(
        sTitle,
        {
            EVENT_TITLE_SET : {
                value : EVENT_TITLE_SET
                /**
                 * @property
                 * @constant
                 * @name sHeadline.Service.sTitle#EVENT_TITLE_SET
                 * @type {String}
                 */
            }
        }
    );

    ns.sTitle = sTitle;
})(Object.namespace('sHeadline.Service'));
