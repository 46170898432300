(function (ns) {
    /**
     * @namespace
     * @alias Model.Message.Template
     *
     * @constructor
     * @param {Number} id
     * @param {String} label
     * @param {Model.Message} message
     * @param {Model.Source[]} sources
     */
    var Template = function (id, label, message, sources) {
        var sourcesCache    = [].concat(sources || [])
        ;

        Object.defineProperties(
            this,
            {
                id: {
                    enumerable: true,
                    get: function () {
                        return id;
                    }
                    /**
                     * @property
                     * @name Model.Message.Template#id
                     * @type {Number}
                     */
                },
                label: {
                    enumerable: true,
                    get: function () {
                        return label;
                    }
                    /**
                     * @property
                     * @name Model.Message.Template#label
                     * @type {String}
                     */
                }
                ,
                message: {
                    enumerable: true,
                    get: function () {
                        return message;
                    }
                    /**
                     * @property
                     * @name Model.Message.Template#message
                     * @type {Model.Message}
                     */
                },
                sources: {
                    enumerable: true,
                    get: function () {
                        return sourcesCache;
                    }
                    /**
                     * @property
                     * @name Model.Message.Template#sources
                     * @type {Model.Source[]}
                     */
                },
                placeholders: {
                    get: function () {
                        return sources.reduce(function(carry, source) {
                            carry.push.apply(carry, source.placeholders);
                            return carry;
                        }, []);
                    }
                    /**
                     * @property
                     * @name Model.Message.Template#placeholders
                     * @type {Model.Source.Placeholder[]}
                     */
                }
            });

        message.resolvePlaceholderAttachments(this.placeholders);
    };

    /**
     * @static
     * @param {Object} dataObj
     * @return {Model.Message.Template}
     */
    Template.createByData = function createByData(dataObj) {
        var id,
            label,
            message,
            sources
        ;

        if (dataObj.hasOwnProperty('id')) {
            id = dataObj.id;
        }

        if (dataObj.hasOwnProperty('label')) {
            label = dataObj.label;
        }

        if (dataObj.hasOwnProperty('message')) {
            message = Model.Message.createMessageByData(dataObj.message);
        }

        if (dataObj.hasOwnProperty('sources') && dataObj.sources instanceof Array) {
            sources = dataObj.sources.map(function (sourceData){
                return Model.Source.createByData(sourceData);
            });
        }

        return new Template(id, label, message, sources);
    };

    ns.Template = Template;
})(Object.namespace('Model.Message'));