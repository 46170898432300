(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sAsyncCheckbox
     * @constructor
     *
     * @param {Function} urlFunction
     * @param $scope
     */
    var sAsyncCheckbox = function sAsyncCheckbox(urlFunction, $scope) {
        var state   = 0,
            loading = false,
            self    = this
        ;

        this.$deRegister = [];

        this.getState = function getState() {
            loading = true;
            $.ajax({
                url: urlFunction(Const.Method.GET)
            }).then(function(data) {
                state = data;
            }).always(function() {
                loading = false;
                digestIfNeeded($scope);
            });
        };

        this.enable = function enable() {
            loading = true;
            $.ajax({
                url: urlFunction(Const.Method.DELETE),
                method: Const.Method.DELETE
            }).then(function(){
                state = 0;
            }).always(function() {
                loading = false;
                digestIfNeeded($scope);
            })
        };

        this.disable = function disable() {
            loading = true;
            $.ajax({
                url: urlFunction(Const.Method.POST),
                method: Const.Method.POST
            }).then(function(data){
                state = data;
            }).always(function() {
                loading = false;
                digestIfNeeded($scope);
            })
        };

        Object.defineProperties(
            this,
            {
                state: {
                    get: function() {
                        return Boolean(state);
                    },
                    set: function(val) {
                        if (val === state) {
                            return;
                        }

                        if (loading) {
                            return;
                        }

                        if (val) {
                            self.disable();
                        } else {
                            self.enable();
                        }
                    }
                },
                loading: {
                    get: function() {
                        return loading;
                    }
                },
                rawState: {
                    get: function() {
                        return state;
                    }
                }
            }
        );

        /**
         * @function
         * @name Controller.Component.sAsyncCheckbox#$onInit
         */
        this.$onInit = function $onInit() {
            this.$deRegister = this.$deRegister.concat(
                $(document).$on('ajaxSuccess', function (event, status, requestOptions, data) {
                    if (requestOptions.url !== urlFunction(requestOptions.type)) {
                        return;
                    }
                    if (requestOptions.type.toString().toUpperCase() === Const.Method.DELETE) {
                        data = 0;
                    }
                    if (data !== state) {
                        state = data;
                        digestIfNeeded($scope);
                    }
                })
            );
            this.getState();
        };
    };

    sAsyncCheckbox.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };


    ns.sAsyncCheckbox = sAsyncCheckbox;
})(Object.namespace('Controller.Component'));
