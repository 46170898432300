(function (ns) {
    /**
     * @namespace
     * @alias sSource.Controller.Overview
     * @constructor
     *
     * @param $scope
     * @param $mdDialog
     * @param Notification
     * @param {Service.sDomain} sDomainService
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param $location
     * @param {Service.sConfirm} sConfirm
     * @param {sSource.Service.sSource} sSource
     */
    var Overview = function (
        $scope,
        $mdDialog,
        Notification,
        sDomainService,
        sAPIAccess,
        $location,
        sConfirm,
        sSource
    ) {
        this.$scope = $scope;
        this.$mdDialog = $mdDialog;
        this.notification = Notification;
        this.sAPIAccess = sAPIAccess;
        this.sDomainService = sDomainService;
        this.$location = $location;
        this.sConfirm = sConfirm;
        this.sSource = sSource;

        this.list = this.getList();
    };

    /**
     * @function
     * @name sSource.Controller.Overview#getList
     * @returns {Model.List}
     */
    Overview.prototype.getList = function getList() {
        var column,
            list = new Model.List.RESTAccess(Model.Source, this.sAPIAccess.endpoint('source.RESTAccess').get())
        ;

        column = list.columns.createAndAdd('label');
        column.isSortable = true;
        column.label = 'Name';

        column = list.columns.createAndAdd('url');
        column.label = 'URL';

        column = list.columns.createAndAdd('mapping');
        column.label = 'Mapped Fields';

        column = list.columns.createAndAdd('timeUpdated');
        column.isSortable = true;
        column.label = 'Last Updated';

        list.actions = {};

        list.actions['inspect'] = new Model.Menu.Action('inspect', this.inspectSource.bind(this));

        if (this.sAPIAccess.isAllowed('source.RESTAccess', Const.Method.PUT)) {
            list.actions['edit'] = new Model.Menu.Action('edit', this.openEditSourceDialog.bind(this));
            list.actions['refresh'] = new Model.Menu.Action('refresh', this.refreshSource.bind(this));
            list.actions['activate'] = new Model.Menu.Action('activate', this.activateSource.bind(this));
            list.actions['deactivate'] = new Model.Menu.Action('deactivate', this.deactivateSource.bind(this));
            list.actions['clearAllEntries'] = new Model.Menu.Action('clearAllEntries', this.clearAllEntries.bind(this));
        }

        if (this.sAPIAccess.isAllowed('source.content.export', Const.Method.GET)) {
            list.actions['export'] = new Model.Menu.Action('export', this.sSource.exportSourceContents.bind(this));
        }

        if (this.sAPIAccess.isAllowed('source.RESTAccess', Const.Method.DELETE)) {
            list.actions['delete'] = new Model.Menu.Action('delete', this.deleteSource.bind(this));
        }

        if (Object.keys(list.actions).length) {
            column = list.columns.createAndAdd('options');
            column.label = 'Options';
        }

        return list;
    };

    /**
     * @function
     * @name sSource.Controller.Overview#createSource
     */
    Overview.prototype.createSource = function createSource() {
        var source = Model.Source.createByData({
            context : Model.Source.VALUE_CONTEXT_SPECIFIC,
            status  : Model.Source.STATUS_ACTIVE
        });

        this.openEditSourceDialog(source);
    };

    /**
     * @function
     * @name sSource.Controller.Overview#inspectSource
     * @param {Model.Source} source
     */
    Overview.prototype.inspectSource = function inspectSource(source) {
        this.$location.url('/content-source/' + source.uuid + '/content');
    };

    /**
     * @param {Model.Source} source
     * @return {$.Deferred}
     */
    Overview.prototype.refreshSource = function refreshSource(source) {
        var self = this;

        return this.sSource.refreshSource(source).then(
            function () {
                self.list.load(false, true);
                self.notification.success('Content Source was successfully refreshed.');
            },
            function () {
                self.notification.error('An Error occurred while refreshing the Source.');
            }
        );
    };

    /**
     * @param {Model.Source} source
     * @return {$.Deferred}
     */
    Overview.prototype.clearAllEntries = function clearAllEntries(source) {
        var self = this,
            confirmOptions = {
                confirm : 'Clear',
                decline : 'Cancel',
                title   : 'Clear Source Entries',
                content : 'Do you want to clear all Content Source Entries of <b>' + source.label + '</b>?'
            };

        return this.sConfirm.open(confirmOptions).then(
            function () {
                return self.sSource.clearAllEntries(source).then(
                    function () {
                        self.list.load(false, true);
                        self.notification.success('All Entries in Content Source were successfully cleared.');
                    },
                    function () {
                        self.notification.error('An Error occurred while clearing the Source Entries.');
                    }
                );
            }
        );
    };

    /**
     * @param {Model.Source} source
     * @return {$.Deferred}
     */
    Overview.prototype.activateSource = function activateSource(source) {
        var self = this;

        source.status = Model.Source.STATUS_ACTIVE;

        return source
            .save()
            .then(
                function () {
                    self.list.load(false, true);
                    self.notification.success('Content Source was successfully activated.');
                    self.$scope.$emit('sContentSourceStatusChanged', source);
                },
                function () {
                    self.notification.error('An Error occurred while activating the Source.');
                }
            );
    };

    /**
     * @param {Model.Source} source
     * @return {$.Deferred}
     */
    Overview.prototype.deactivateSource = function deactivateSource(source) {
        var self = this;

        source.status = Model.Source.STATUS_INACTIVE;

        return source
            .save()
            .then(
                function () {
                    self.list.load(false, true);
                    self.notification.success('Content Source was successfully deactivated.');
                    self.$scope.$emit('sContentSourceStatusChanged', source);
                },
                function () {
                    self.notification.error('An Error occurred while deactivating the Source.');
                }
            );
    };

    /**
     * @param {Model.Source} source
     * @return {$.Deferred}
     */
    Overview.prototype.deleteSource = function deleteSource(source) {
        var self = this,
            confirmOptions = {
                confirm : 'Remove',
                decline : 'Cancel',
                title   : 'Remove Content Source',
                content : 'Do you want to remove the Content Source <b>' + source.label + '</b>?'
            };

        return this.sConfirm.open(confirmOptions).then(
            function () {
                return source.delete().then(
                    function () {
                        self.list.load(false, true);
                        self.notification.success('Content Source was successfully removed.');
                    },
                    function () {
                        self.notification.error('An Error occurred while deleting the Source.');
                    }
                );
            }
        );
    };

    /**
     * @function
     * @name sSource.Controller.Overview#openEditSourceDialog
     * @param {Model.Source} source
     */
    Overview.prototype.openEditSourceDialog = function openEditSourceDialog(source) {
        var self = this,
            isNew = source.isNew,
            originalSourceData = source.getData();

        this.$mdDialog.show({
            controller          : sSource.Controller.EditContentSource,
            locals              : {
                source : source
            },
            controllerAs        : '$ctrl',
            templateUrl         : 'ssource:edit-content-source',
            parent              : angular.element(document.body),
            clickOutsideToClose : false,
            escapeToClose       : false
        }).then(function () {
            self.notification.success('Content Source was successfully ' + (source.isNew ? 'created' : 'updated') + '.');
            self.list.load(false, true);
            digestIfNeeded(self.$scope);
        }).catch(function (sourceToDelete) {
            if (isNew && sourceToDelete && typeof sourceToDelete.delete === 'function') {
                sourceToDelete.delete();
            } else if (!isNew) {
                originalSourceData = JSON.parse(originalSourceData);

                // If source was saved during editing revert the changes
                if (source.timeUpdated.toJSON() !== originalSourceData.timeUpdated) {
                    source.updateByData(originalSourceData);
                    source.timeUpdated = moment();

                    source.save().then(function () {
                        digestIfNeeded(self.$scope);
                        self.list.load(false, true);
                    });
                }
            }
        });
    };

    ns.Overview = Overview;
})(Object.namespace('sSource.Controller'));
