var Const = Const || {};

Object.defineProperties(
    Const,
    {
        Unicode: {
            get: function() {
                return {
                    SELECTION_START_PLACEHOLDER : "\u200E",
                    SELECTION_END_PLACEHOLDER   : "\u200F",
                    SPACE_CAP                   : "\u200B",
                    NON_BREAKING_SPACE          : "\u00A0",
                    SPACE                       : "\u0020",
                    SOFT_HYPHEN                 : "\u00AD",
                    ZERO_WIDTH_JOINER           : "\u200D"
                }
            }
            /**
             * @property
             * @constant
             * @name Const#Unicode
             * @type {Object}
             */
        },
        LeftArrow: {
            value: 37
        /**
         * @property
         * @constant
         * @name Const#LeftArrow
         * @type {Number}
         */
        },
        UpArrow: {
            value: 38
            /**
             * @property
             * @constant
             * @name Const#UpArrow
             * @type {Number}
             */
        },
        RightArrow: {
            value: 39
            /**
             * @property
             * @constant
             * @name Const#RightArrow
             * @type {Number}
             */
        },
        DownArrow: {
            value: 40
            /**
             * @property
             * @constant
             * @name Const#DownArrow
             * @type {Number}
             */
        },
        ArrowKeys: {
            get: function() {
                return [Const.LeftArrow, Const.RightArrow, Const.UpArrow, Const.DownArrow];
            }
            /**
             * @property
             * @constant
             * @name Const#ArrowKeys
             * @type {Number[]}
             */
        },
        EscapeKey: {
            value: 27
            /**
             * @property
             * @constant
             * @name Const#EscapeKey
             * @type {Number}
             */
        },
        TabKey: {
            value: 9
            /**
             * @property
             * @constant
             * @name Const#TabKey
             * @type {Number}
             */
        },
        EnterKey: {
            value: 13
            /**
             * @property
             * @constant
             * @name Const#EnterKey
             * @type {Number}
             */
        },
        BACKSPACE: {
            value: 8
            /**
             * @property
             * @constant
             * @name Const#BACKSPACE
             * @type {String}
             */
        },
        SpaceKey: {
            value: 32
            /**
             * @property
             * @constant
             * @name Const#SpaceKey
             * @type {Number}
             */
        },
        NavigationKeys: {
            get: function() {
                return Const.ArrowKeys.concat([Const.EscapeKey, Const.EnterKey, Const.TabKey]);
            }
            /**
             * @property
             * @constant
             * @name Const#NavigationKeys
             * @type {Number[]}
             */
        },
        PostbackMessage: {
            value: 'postback_message'
            /**
             * @property
             * @constant
             * @name Const#PostbackMessage
             * @type {String}
             */
        },
        GoToURL: {
            value: 'url'
            /**
             * @property
             * @constant
             * @name Const#GoToURL
             * @type {String}
             */
        },
        PostbackMessageId: {
            value: 'messages_outgoing_id'
            /**
             * @property
             * @constant
             * @name Const#PostbackMessageId
             * @type {String}
             */
        },
        TriggerMessage: {
            value: 'Trigger a message'
            /**
             * @property
             * @constant
             * @name Const#TriggerMessage
             * @type {String}
             */
        },
        NewMessage: {
            value: 'New message'
            /**
             * @property
             * @constant
             * @name Const#NewMessage
             * @type {String}
             */
        },
        Outgoing: {
            value: 'outgoing'
            /**
             * @property
             * @constant
             * @name Const#Outgoing
             * @type {String}
             */
        },
        Incoming: {
            value: 'incoming'
            /**
             * @property
             * @constant
             * @name Const#Incoming
             * @type {String}
             */
        },
        ForwardAction: {
            value: 'ForwardAction'
            /**
             * @property
             * @constant
             * @name Const#ForwardAction
             * @type {String}
             */
        },
        DynamicDispatcher: {
            value: 'DynamicDispatcher'
            /**
             * @property
             * @constant
             * @name Const#DynamicDispatcher
             * @type {String}
             */
        },
        QuickReplies: {
            value: 'QuickReplies'
            /**
             * @property
             * @constant
             * @name Const#QuickReplies
             * @type {String}
             */
        },
        DebounceTime: {
            value: 300
            /**
             * @property
             * @constant
             * @name Const#DebounceTime
             * @type {Number}
             */
        },
        DefaultPriority: {
            value: 3
            /**
             * @property
             * @constant
             * @name Const#DefaultPriority
             * @type {Number}
             */
        },
        Method: {
            get: function() {
                    return {
                        POST: 'POST',
                        GET: 'GET',
                        PUT: 'PUT',
                        DELETE: 'DELETE',
                        OPTIONS: 'OPTIONS'
                    };
            }
            /**
             * @property
             * @constant
             * @name Const#Method
             * @type {Object}
             */
        },
        NodeType: {
            get: function() {
                return {
                    Text: 3
                };
            }
            /**
             * @property
             * @constant
             * @name Const#NodeType
             * @type {Number}
             */
        },
        PanelAnchor: {
            value: '#panel-anchor'
            /**
             * @property
             * @constant
             * @name Const#PanelAnchor
             * @type {String}
             */
        },
        Headers: {
            get: function() {
                return {
                    X_RESOURCE_LIMIT_REACHED : 'x-resource-limit-reached',
                }
            }
            /**
             * @property
             * @constant
             * @name Const#Headers
             * @type {Object}
             */
        },
        PanelAboveDialogZIndex: {
            value: 100
            /**
             * @property
             * @constant
             * @name Const#PanelAboveDialogZIndex
             * @type {Number}
             */
        }
    }
);
