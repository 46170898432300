(function(ns) {
    /**
     * @namespace
     * @alias Service.sProgressIndicator
     *
     * @param $mdPanel
     * @param $rootScope
     */
    var sProgressIndicator  = function ($mdPanel, $rootScope) {
        this.$mdPanel       = $mdPanel;
        var defPanel,
            $deRegisterRootScopeListeners   = [],
            waitFor                         = [],
            self                            = this

            openDelayed = function openDelayed() {
                var $deferred = $.Deferred()
                ;

                $deferred.rejectable = true;

                $deferred.to = setTimeout(function () {
                    $deferred.rejectable = false;
                    self.attach(document.body).then(function (value) {
                        $deferred.resolve(value);
                    });
                }, 200);

                var custPromise = $deferred.promise();

                /**
                 * Creates a cancel method that is checking if the indicator has already been initiated or not.
                 * If it is not (rejectable), then simply clears the timeout and prevents the opening of the indicator.
                 */
                custPromise.cancel = function () {
                    if ($deferred.rejectable === true) {
                        clearTimeout($deferred.to);
                        $deferred.reject();
                    }
                    return custPromise;
                };

                return custPromise;
            }
        ;

        this.handleRouteChangeStart = function handleRouteChangeStart($event) {
            if ($event.defaultPrevented) {
                return;
            }
            if (!defPanel) {
                defPanel = openDelayed.call(this);
            }
        };

        this.clearAnyDelayed = function clearAnyDelayed() {
            if (!defPanel) {
                return;
            }

            $.when.apply(this, waitFor).always(function() {
                defPanel && defPanel.cancel();

                defPanel && defPanel.then(function(mdPanelRef) {
                    if (!mdPanelRef) {
                        return;
                    }
                    mdPanelRef.close();
                    mdPanelRef.destroy();
                }).always(function() {
                    defPanel    = null;
                    waitFor     = [];
                });
            });
        };

        this.attachToRootScope = function attachToRootScope() {
            if ($deRegisterRootScopeListeners.length !== 0) {
                throw 'Already attached to rootScope!';
            }
            $deRegisterRootScopeListeners.push($rootScope.$on('$routeChangeStart', this.handleRouteChangeStart.bind(this)));
            // move to first spot
            $rootScope.$$listeners.$routeChangeStart.unshift($rootScope.$$listeners.$routeChangeStart.pop());
            $deRegisterRootScopeListeners.push($rootScope.$on('$routeChangeError', this.clearAnyDelayed.bind(this)));
            $deRegisterRootScopeListeners.push($rootScope.$on('$routeChangeSuccess', this.clearAnyDelayed.bind(this)));
        };

        this.detachFromRootScope = function detachFromRootScope() {
            var $destroyFn;
            while (($destroyFn = $deRegisterRootScopeListeners.pop())) {
                $destroyFn();
            }
        };

        /**
         * Adds a deferred to the wait queue before the progress indicator can be closed
         * @param deferred
         */
        this.addWaitFor = function addWaitFor(deferred) {
            waitFor.push(deferred);
        };

        $rootScope.$on('$destroy', this.detachFromRootScope.bind(this));
    };

    /**
     * Attaches a loading indicator to an element.
     * The element is completely hidden by the overlay.
     * IMPORTANT: The element should have a relative position set!
     *
     * @param element
     * @param {int=} maxTime
     * @returns {*}
     */
    sProgressIndicator.prototype.attach = function attach(element, maxTime) {
        var position = this.$mdPanel
                .newPanelPosition()
                .absolute()
                .center(),
            $element = $(element)
        ;

        maxTime = maxTime || 10000;

        var config = {
            attachTo            : $element,
            templateUrl         : '_progress_indicator',
            panelClass          : 'progress_indicator',
            position            : position,
            hasBackdrop         : true,
            clickOutsideToClose : false,
            escapeToClose       : false,
            focusOnOpen         : false,
            zIndex              : 300
        };


        return this.$mdPanel.open(config).then(function ($mdPanelRef) {
            setTimeout(function () {
                if (!$mdPanelRef) {
                    return;
                }
                $mdPanelRef.close();
                $mdPanelRef.destroy();
            }, maxTime);

            return $mdPanelRef;
        });
    };

    /**
     * Attach load indicator to element and watch for promise to remove the indicator
     * @param element
     * @param {$.Deferred|Promise} promise
     * @param {int=} delay How long to wait before attaching it
     * @param {int=} maxTime After maxTime the indicator will get removed regardless the promise's state
     */
    sProgressIndicator.prototype.attachAndHandlePromise = function attachAndHandlePromise(element, promise, delay, maxTime) {
        var $deferred   = $.Deferred(),
            self        = this
        ;

        $deferred.rejectable = true;
        var to = setTimeout(function() {
            $deferred.rejectable = false;
            self.attach(element, maxTime).then(function($mdPanelRef) {
                $deferred.resolve($mdPanelRef);
            })
        }, delay || 0);

        promise.then(function() {
            if ($deferred.rejectable) {
                clearTimeout(to);
                $deferred.resolve();
            }
        });

        return $.when($deferred.promise(), promise).done(function($mdPanelRef) {
            if (!$mdPanelRef) {
                return;
            }

            $mdPanelRef.close();
            $mdPanelRef.destroy();
        });
    };


    ns.sProgressIndicator = sProgressIndicator;
})(Object.namespace('Service'));