(function (ns) {
    /**
     * @namespace
     * @alias sAutomation.Route.Create
     * @extends sRoute
     * @constructor
     */
    var Create = function Create() {
        this.controller     = sAutomation.Controller.CreateAndEdit;
        this.controllerAs   = '$ctrl';
        this.templateUrl    = 'sautomation:create-and-edit';
        this.reloadOnSearch = false;
        this.accessControl  = {'automation.RESTAccess': Const.Method.POST};

        Create._pProto.constructor.call(this);
    };

    Object.extendProto(Create, sRoute);

    /**
     * @name sAutomation.Route.Create#resolveAutomation
     * @returns {$.Deferred}
     */
    Create.prototype.resolveAutomation = function resolveAutomation() {
        return $.Deferred().resolve(new Model.Automation());
    };

    ns.Create = Create;
})(Object.namespace('sAutomation.Route'));