(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sDateRangeCalendar
     *
     * @param $scope
     */
    var sDateRangeCalendar = function ($scope) {
        var dateRange   = null;

        this.$deRegister            = [];
        this.$scope                 = $scope;

        // stores displayed calendar-month
        this.calendarFromActiveSheet    = moment().startOf('month').subtract(1, 'month');
        this.calendarToActiveSheet      = moment().startOf('month');

        Object.defineProperties(
            this,
            {
                /**
                 * @property {Model.DateRange|null}
                 * @name Controller.Component.sDateRangeCalendar#dateRange
                 */
                dateRange: {
                    get: function () {
                        return dateRange;
                    },
                    set: function (val) {
                        dateRange = val instanceof Model.DateRange ? new Model.DateRange(val) : null;
                    }
                }
            }
        );
    };

    /**
     * @function
     * @name Controller.Component.sDateRangeCalendar#$onInit
     */
    sDateRangeCalendar.prototype.$onInit = function $onInit() {
        var self = this;

        this.dateRange  = new Model.DateRange(self.model);
        this.keepTime   = this.keepTime !== false;

        this.$deRegister.push(
            this.$scope.$watch(
                function () {
                    return self.model;
                },
                function (newVal, oldVal) {
                    if (!(moment.isMoment(oldVal.from)
                        && moment.isMoment(newVal.from)
                        && newVal.from.isSame(oldVal.from))) {
                        self.dateRange.from = self.model.from;
                    }
                    if (!(moment.isMoment(oldVal.to)
                        && moment.isMoment(newVal.to)
                        && newVal.to.isSame(oldVal.to))) {
                        self.dateRange.to = self.model.to;
                    }
                }
            )
        );

        this.$deRegister.push(this.$scope.$watchCollection(
            function () {
                // do not watch whole object because of preselection
                return [self.dateRange.from, self.dateRange.to];
            },
            function (newVal, oldVal) {
                if (newVal !== oldVal && self.dateRange.from && self.dateRange.to) {
                    self.onRangeSelected();
                }
            }
        ));

        this.$deRegister.push(this.$scope.$watch(
            function () {
                return self.calendarFromActiveSheet.valueOf();
            },
            function () {
                self.onChangeActiveSheetCalendarFrom(self.calendarFromActiveSheet);
            }
        ));

        this.$deRegister.push(this.$scope.$watch(
            function () {
                return self.calendarToActiveSheet.valueOf();
            },
            function () {
                self.onChangeActiveSheetCalendarTo(self.calendarToActiveSheet);
            }
        ));

        this.$deRegister.push(this.$scope.$on(
            Controller.Component.sCalendar.EVENT_DATE_CLICK,
            function(event, data) {
                self.onDateClick(data.date);
            }
        ));

        // pre-select the active-sheets
        if (this.dateRange.to) {
            this.calendarToActiveSheet = moment(this.dateRange.to).startOf('month');
        }
        if (this.dateRange.from && this.isActiveSheetApart(this.dateRange.from, this.calendarToActiveSheet)) {
            this.calendarFromActiveSheet = moment(this.dateRange.from).startOf('month')
        }
    };

    /**
     * @function
     * @name Controller.Component.sDateRangeCalendar#$onDestroy
     */
    sDateRangeCalendar.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name Controller.Component.sDateRangeCalendar#onRangeSelected
     */
    sDateRangeCalendar.prototype.onRangeSelected = function onRangeSelected() {
        var from, to;

        if (!this.model || !this.model.from || !this.keepTime) {
            from = moment(this.dateRange.from).startOf('day');
        } else {
            // clone for triggering change but just change year/month/date
            from = moment(this.model.from)
                .year(this.dateRange.from.year())
                .month(this.dateRange.from.month())
                .date(this.dateRange.from.date());
        }

        if (!this.model || !this.model.to || !this.keepTime) {
            to = moment(this.dateRange.to).endOf('day');
        } else {
            // clone for triggering change but just change year/month/date
            to = moment(this.model.to)
                .year(this.dateRange.to.year())
                .month(this.dateRange.to.month())
                .date(this.dateRange.to.date());
        }

        this.model = new Model.DateRange(from, to);

        if (this.onRangeSelectedFn instanceof Function) {
            this.onRangeSelectedFn({dateRange: this.dateRange});
        }
    };

    /**
     * Checks if both calendars are at least one month apart (not the same month displayed)
     * @function
     * @name Controller.Component.sDateRangeCalendar#isActiveSheetApart
     * @param {moment} activeSheetFrom
     * @param {moment} activeSheetTo
     * @returns Boolean
     */
    sDateRangeCalendar.prototype.isActiveSheetApart = function isActiveSheetApart(activeSheetFrom, activeSheetTo) {
        return !activeSheetFrom.isSame(moment(activeSheetTo).subtract(1, 'months'), 'month');
    };

    /**
     * @function
     * @name Controller.Component.sDateRangeCalendar#onChangeActiveSheetCalendarFrom
     * @param {moment} activeDate
     */
    sDateRangeCalendar.prototype.onChangeActiveSheetCalendarFrom = function onChangeActiveSheetCalendarFrom(activeDate) {
        this.calendarFromActiveSheet = moment(activeDate);

        if (this.calendarFromActiveSheet.isSameOrAfter(this.calendarToActiveSheet, 'month')) {
            this.calendarToActiveSheet = moment(this.calendarFromActiveSheet)
                .add(1, 'months');
        }
        digestIfNeeded(this.$scope);
    };

    /**
     * @function
     * @name Controller.Component.sDateRangeCalendar#onChangeActiveSheetCalendarTo
     * @param {moment} activeDate
     */
    sDateRangeCalendar.prototype.onChangeActiveSheetCalendarTo = function onChangeActiveSheetCalendarTo(activeDate) {
        this.calendarToActiveSheet = moment(activeDate);

        if (this.calendarToActiveSheet.isSameOrBefore(this.calendarFromActiveSheet, 'month')) {
            this.calendarFromActiveSheet = moment(this.calendarToActiveSheet)
                .subtract(1, 'months');
        }
        digestIfNeeded(this.$scope);
    };

    /**
     * @function
     * @name Controller.Component.sDateRangeCalendar#onDateClick
     * @param {moment} date
     */
    sDateRangeCalendar.prototype.onDateClick = function onDateClick(date) {

        //# equals first click on a date at the calendar:
        if (this.dateRange.from === null || this.dateRange.to !== null) {
            this.dateRange.from = date;
            this.dateRange.to = null;
            return;
        }

        //# equals second click on a date at the calendar:
        // dateTe should be always after dateFrom:
        if (!this.dateRange.from.isBefore(date)) {
            this.dateRange.to = this.dateRange.from;
            this.dateRange.from = date;
        } else {
            this.dateRange.to = date;
        }
        digestIfNeeded(this.$scope);
    };

    ns.sDateRangeCalendar = sDateRangeCalendar;
})(Object.namespace('Controller.Component'));
