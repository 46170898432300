(function (ns) {
    /**
     * @namespace
     * @alias sFeatureManagement.Controller.Plan
     *
     * @param $scope
     * @param Notification
     * @param {Service.sConfirm} sConfirm
     * @param {Model.Plan[]} plans
     * @param {Model.Feature[]} features
     * @param {Service.sFormPanel} sFormPanel
     * @constructor
     */
    var Plan = function ($scope, Notification, sConfirm, plans, features, sFormPanel) {
        this.notification = Notification;
        this.sConfirm     = sConfirm;
        this.plans        = plans;
        this.features     = features;
        this.sFormPanel   = sFormPanel;

        var self = this;

        /**
         * @return {$.Deferred}
         */
        Plan.prototype.addPlan = function addPlan() {
            var plan  = new Model.Plan();
            plan.name = 'New Plan';
            this.plans.push(plan);

            return plan.save().then(
                function () {
                    self.notification.success('Plan was successfully created.');
                },
                function () {
                    self.notification.error('Plan could not be created.');
                }
            ).always(function () {
                digestIfNeeded($scope);
            });
        };

        /**
         * @param {Model.Plan} plan
         * @return {$.Deferred}
         */
        Plan.prototype.deletePlan = function deletePlan(plan) {
            if (plan.domainsCount > 0) {
                return null;
            }

            var options = {
                confirm : 'Delete',
                decline : 'Cancel',
                content : 'Do you really want to delete the plan "' + plan.name + '"?'
            };

            return this.sConfirm.open(options).then(function () {
                return plan.delete().then(
                    function () {
                        for (var i = 0; i < self.plans.length; i++) {
                            if (self.plans[i].uuid === plan.uuid) {
                                self.plans.splice(i, 1);
                                break;
                            }
                        }

                        self.notification.success('Plan was successfully deleted.');
                    },
                    function () {
                        self.notification.error('Plan could not be deleted.');
                    }
                ).always(function () {
                    digestIfNeeded($scope);
                });
            });
        };
    };

    /**
     * @param {Model.Feature} feature
     * @param {Model.Plan} plan
     */
    Plan.prototype.toggleFeatureForPlan = function toggleFeatureForPlan(feature, plan) {
        var featureGranted;
        if ((featureGranted = plan.getFeatureByUuid(feature.uuid))) {
            plan.removeFeature(featureGranted);
        } else {
            plan.addFeature(feature);
        }

        plan.save();
    };

    /**
     * @param {$.Event} $event
     * @param {Model.Plan} plan
     */
    Plan.prototype.editPlan = function editPlan($event, plan) {
        var self  = this,
            rules = {
                name : {
                    component : 'sTextInput',
                    options   : {
                        attrs : {
                            'max-length'       : 30,
                            'is-required'      : true,
                            'ng-model-options' : '{"allowInvalid" : true}'
                        },
                        label : 'Name'
                    }
                }
            };

        this.sFormPanel.open(
            $event,
            plan,
            rules
        ).then(function (clone) {
            plan.name = clone.name;
            plan.save().then(
                function () {
                    self.notification.success('Plan was successfully updated.');
                },
                function () {
                    self.notification.error('Plan could not be updated.');
                }
            );
        }.bind(this));
    };

    ns.Plan = Plan;
})(Object.namespace('sFeatureManagement.Controller'));
