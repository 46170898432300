(function(ns) {
    var DEFAULT_MAX_DIMENSION = 1000;

    /**
     * @namespace
     * @alias Controller.Component.ImageUploadController
     *
     * @param $element
     * @param $document
     * @param $scope
     * @param $timeout
     * @param maxFileSize
     * @param $mdPanel
     * @param $injector
     * @constructor
     */
    var ImageUploadController = function($element, $document, $scope, $timeout, maxFileSize, $mdPanel, $injector) {
        var tmp             = this.model,
            self            = this
            ;

        /**
         * @property {Number}
         * @name Controller.Component.ImageUploadController#previewSize
         */

        /**
         * @property
         * @type jQuery
         * @name Controller.Component.ImageUploadController#$element
         */

        this.$injector  = $injector;
        this.$scope     = $scope;

        ImageUploadController._pProto.constructor.apply(this, arguments);

        var parentModelDescriptor = Object.getOwnPropertyDescriptor(this, 'model'),
            previewSize,
            cropArea
            ;

        this.accept = 'image/*';

        this.$canvas = $element.find('canvas');
        /**
         * @name Controller.Component.ImageUploadController#$cropper
         * @type {jQuery}
         */
        this.$cropper = $('<div class="cropper"></div>');

        var initCropArea = function(model) {
            if (model.reSizedImage && model.reSizedImage.getMeta('crop')) {
                cropArea = model.reSizedImage.getMeta('crop');

                // BUG-45: check if loaded crop area is within the actual image boundaries
                if (cropArea.left < 0
                    || cropArea.top < 0
                    || cropArea.width > self.model.imageObj.width
                    || cropArea.height > self.model.imageObj.height
                ) {
                    cropArea = null;
                }
                var widthCompression = self.previewSize.width / self.model.imageObj.width,
                    heightCompression = self.previewSize.height / self.model.imageObj.height,
                    cssCropArea = {}
                ;

                for (var i in cropArea) {
                    if (i === 'top' || i === 'height') {
                        cssCropArea[i] = Math.round(cropArea[i] * heightCompression);
                    } else {
                        cssCropArea[i] = Math.round(cropArea[i] * widthCompression);
                    }
                }
                self.$cropper.css(cssCropArea);
            } else if (model) {
                self.clearCropArea();
            }

            if (!cropArea) {
                self.setDefaultCropArea();
            } else {
                self.applyCropArea(cropArea);
            }
        };

        Object.defineProperties(this, {
                model: {
                    get: function () {
                        return parentModelDescriptor.get.call(this);
                    },
                    set: function (val) {
                        if (val instanceof Array) {
                            val = val.shift();
                        }

                        // no changes happened
                        if (val === parentModelDescriptor.get.call(this)) {
                            if (val && val instanceof Model.sImage) {
                                previewSize = self.model.calculateImageSize(300);
                                self.drawPreviewImage();
                                initCropArea(val);
                            }
                            return;
                        }

                        if (!val || !val.file) {
                            var ctx = self.$canvas[0].getContext("2d");
                            ctx.clearRect(0, 0, self.$canvas[0].width, self.$canvas[0].height);
                            self.clearCropArea();
                            parentModelDescriptor.set.call(this, val);
                            return;
                        }

                        if (!(val instanceof Model.sImage)) {
                            var model = new Model.sImage(val.file);
                            delete(val);
                        } else {
                            model = val;
                        }

                        parentModelDescriptor.set.call(this, model);

                        // we use setTimeout because imagePromise wasn't always resolved (see discussion for IT-4594)
                        setTimeout(function () {
                            // once imagePromise resolves, everything is set for drawing
                            self.model.imagePromise.then(function () {
                                previewSize = self.model.calculateImageSize(300);
                                self.drawPreviewImage();
                                initCropArea(model);
                                digestIfNeeded($scope);
                            });
                        });
                    }
                },
                previewSize : {
                    get : function() {
                        return previewSize;
                    }
                },
                cropArea : {
                    get : function() {
                        return cropArea;
                    }
                }
            }
        );

        this.clearCropArea = function() {
            cropArea = undefined;
        };

        this.model = tmp;
    };

    Object.extendProto(ImageUploadController, Controller.Component.FileUploadController);

    /**
    * Handles image upload and resize, if canvas is visible
    *
    * @inheritDoc
    */
    ImageUploadController.prototype.uploadFiles = function uploadFiles() {
        var self = this;

        return ImageUploadController._pProto.uploadFiles.apply(this, arguments).then(function () {
            self.$scope.$digest();
            if (!arguments) {
                return;
            }

            return Array.prototype.slice.call(arguments);
        });
    };

    /**
     * @inheritDoc
     */
    ImageUploadController.prototype.$onInit = function $onInit() {
        ImageUploadController._pProto.$onInit.apply(this);

        var self = this
            ;

        this.$deRegister = this.$deRegister.concat(this.$element.$on('click', 'canvas', function() {
            if (self.isDisabled) {
                return;
            }
            self.$element.find(Controller.Component.FileUploadController.inputSelector).click()
        }));

        var $cropContainer = $('<div class="crop-container"></div>');
        $cropContainer.insertBefore(this.$canvas);
        $cropContainer.append(this.$canvas);
        $cropContainer.append(this.$cropper);


        var directiveName = 'ngShowDirective';
        if (this.$injector.has(directiveName)) {
            var directive = this.$injector.get(directiveName)[0];
            var $attrs = {'ngShow': '$ctrl.isCroppingAvailable()'};
            var link = directive.compile(this.$cropper[0], $attrs);
            link(this.$scope, this.$cropper[0], $attrs);
        }

        this.$cropper.resizable({
                containment: "parent",
                stop: self.onNewCropArea.bind(self),
                aspectRatio: self.aspectRatio
            })
            .draggable({
                containment: "parent",
                stop: self.onNewCropArea.bind(self)
            });
    };

    /**
     * @param {Object} changes
     * @name Controller.Component.ImageUploadController#$onChanges
     */
    ImageUploadController.prototype.$onChanges = function $onChanges(changes) {
        if (changes.aspectRatio && this.model) {
            this.aspectRatio = changes.aspectRatio.currentValue;
            this.$cropper.resizable({aspectRatio: this.aspectRatio});

            if ((!this.$cropper.width() || !this.$cropper.height())
                // only re-crop if the aspect ratio differs more then 20%
                || Math.abs(((this.$cropper.width() / this.$cropper.height()) - this.aspectRatio))
                    >= 0.025 * (this.previewSize.width / this.$cropper.width() + this.previewSize.height / this.$cropper.height())
            ) {
                this.setDefaultCropArea();
            }
        }
    };

    ImageUploadController.prototype.drawPreviewImage = function() {
        this.$canvas[0].width = this.previewSize.width;
        this.$canvas[0].height = this.previewSize.height;

        var ctx = this.$canvas[0].getContext('2d');
        ctx.drawImage(this.model.imageObj, 0, 0, this.previewSize.width, this.previewSize.height);
    };

    /**
     * @function
     * @name Controller.Component.ImageUploadController#setDefaultCropArea
     */
    ImageUploadController.prototype.setDefaultCropArea = function() {
        if (!this.previewSize || !this.isCroppingAvailable()) {
            return;
        }

        var previewWHRatio = this.previewSize.width / this.previewSize.height;

        if (previewWHRatio > this.aspectRatio) {
            var newWidth = this.previewSize.height * this.aspectRatio;
            this.$cropper.css({
                'top': 0,
                'height': this.previewSize.height,
                'left': (this.previewSize.width - newWidth) / 2,
                'width': newWidth
            });
        } else {
            var newHeight = this.previewSize.width / this.aspectRatio;
            this.$cropper.css({
                'left': 0,
                'width': this.previewSize.width,
                'top': (this.previewSize.height - newHeight) / 2,
                'height': newHeight
            });
        }

        this.onNewCropArea();
    };

    /**
     * @function
     * @name Controller.Component.ImageUploadController#onNewCropArea
     */
    ImageUploadController.prototype.onNewCropArea = function() {
        if (!this.previewSize) {
            return;
        }

        var widthCompression = this.previewSize.width / this.model.imageObj.width,
            heightCompression = this.previewSize.height / this.model.imageObj.height,
            cropArea = {
                'top': Math.ceil(parseInt(this.$cropper.css('top')) / heightCompression),
                'left': Math.ceil(parseInt(this.$cropper.css('left')) / widthCompression),
                'width': Math.ceil(parseInt(this.$cropper.width() / widthCompression)),
                'height': Math.ceil(parseInt(this.$cropper.height() / heightCompression))
            };

        this.applyCropArea(cropArea);
    };

    ImageUploadController.prototype.$onDestroy = function $onDestroy() {
        var destroyFn;
        while ((destroyFn = this.$deRegister.pop())) {
            destroyFn();
        }
    };

    /**
     * @function
     * @param {Object} cropArea
     * @name Controller.Component.ImageUploadController#applyCropArea
     */
    ImageUploadController.prototype.applyCropArea = function(cropArea) {
        var self = this,
            maxDimension = (this.maxDimension && this.maxDimension > 0) ? this.maxDimension : DEFAULT_MAX_DIMENSION
        ;

        this.model.resize(maxDimension, cropArea).then(function(){
            self.$scope.$emit(Controller.Component.FileUploadController.EVENT_FILE_CHANGED);
        }).always(function () {
            digestIfNeeded(self.$scope, 1);
        });
    };

    /**
     * @returns {Boolean}
     */
    ImageUploadController.prototype.isCroppingAvailable = function isCroppingAvailable() {
        // if the media url contains '@' that indicates we have a placeholder inside (urls can't have '@')
        // it doesn't make sense to crop placeholders
        return this.model && this.model.url && this.model.url.search('@') === -1;
    };


    ImageUploadController.prototype.clear = function clear() {
        ImageUploadController._pProto.clear.call(this);

        this.clearCropArea();
    };

    ns.ImageUploadController = ImageUploadController;
})(Object.namespace('Controller.Component'));
