(function (ns) {
    /**
     * @namespace
     * @alias sDomain.Controller.Admin.Overview
     * @constructor
     *
     * @param {Model.Domain} domain
     * @param $mdDialog
     * @param $route
     * @param $exceptionHandler
     * @param sConfirm
     * @param Notification
     * @param {sDomain.Service.sDomainLogo} sDomainLogoService
     */
    var Overview = function (domain, $mdDialog, $route, $exceptionHandler, sConfirm, Notification, sDomainLogoService) {
        this.$mdDialog          = $mdDialog;
        this.$route             = $route;
        this.sConfirm           = sConfirm;
        this.notification       = Notification;
        this.sDomainLogoService = sDomainLogoService;
        this.domain             = domain;
        this.$exceptionHandler  = $exceptionHandler;
        this.removingLogo       = false;
    };

    /**
     * @function
     * @name sDomain.Controller.Admin.Overview#editPlan
     */
    Overview.prototype.editPlan = function editPlan() {
        var self = this;

        this.$mdDialog.show({
            controller          : sDomain.Controller.Admin.PlanEdit,
            controllerAs        : '$ctrl',
            templateUrl         : 'sdomain:admin-plan-edit',
            parent              : angular.element(Const.PanelAnchor),
            clickOutsideToClose : false,
            locals              : {
                origDomain : this.domain
            }
        }).then(function () {
            self.$route.reload();
        }).catch(function (err) {
            if (err) {
                self.$exceptionHandler(err);
            }
        });
    };

    /**
     * @function
     * @name sDomain.Controller.Admin.Overview#editLogo
     */
    Overview.prototype.editLogo = function editLogo() {
        var self = this;

        this.$mdDialog.show({
            controller          : sDomain.Controller.Admin.LogoEdit,
            controllerAs        : '$logoEditCtrl',
            templateUrl         : 'sdomain:domain-logo-edit',
            parent              : angular.element(Const.PanelAnchor),
            clickOutsideToClose : false,
            locals              : {
                dialog : this.$mdDialog
            }
        }).then(
            function () {
                self.notification.success('Domain logo was updated.');
            },
            function (error) {
                if (error) {
                    self.notification.error('There was an error while updating the domain logo. Please try again later.');
                }
            }
        );
    };

    /**
     * @function
     * @name sDomain.Controller.Admin.Overview#removeLogo
     * @param $event
     * @return {$.Deferred}
     */
    Overview.prototype.removeLogo = function removeLogo($event) {
        // Delete button is inside clickable div, so we don't want the event to be catched by the overlay
        $event.stopPropagation();

        var self = this,
            confirmOptions = {
                confirm : 'Remove',
                decline : 'Cancel',
                title   : 'Remove Domain Logo',
                content : 'Do you really want to remove the domain logo?'
            };

        return this.sConfirm.open(confirmOptions).then(
            function () {
                self.removingLogo = true;
                self.sDomainLogoService.removeDomainLogo().then(
                    function () {
                        self.notification.success('Domain logo was removed.');
                    },
                    function () {
                        self.notification.error('There was an error while deleting the domain logo. Please try again later.');
                    }
                ).always(function () {
                    self.removingLogo = false;
                });
            },
            function () {
                return false;
            }
        );
    };

    ns.Overview = Overview;
})(Object.namespace('sDomain.Controller.Admin'));