(function(ns) {
    var TYPE = 'facebook_ad_account';

    /**
     * @namespace
     * @alias Model.Sender.FacebookAdAccount
     * @extends Model.Sender.BaseFacebookSender
     * @constructor
     *
     * @param {String=} uuid
     */
    var FacebookAdAccount = function FacebookAdAccount(uuid) {
        var self = this;

        /**
         * @property {?String}
         * @name Model.Sender.FacebookAdAccount#adAccountId
         */
        this.adAccountId = null;
        /**
         * @property {String}
         * @name Model.Sender.FacebookAdAccount#adAccountName
         */
        this.adAccountName = '';
        /**
         * @property {?String}
         * @name Model.Sender.FacebookAdAccount#adAccountCurrency
         */
        this.adAccountCurrency = null;

        Object.defineProperties(
            this,
            {
                /**
                 * @property
                 * @name Model.Sender.FacebookAdAccount#alias
                 */
                alias: {
                    enumerable: true,
                    get: function() {
                        return self.adAccountName || self.adAccountId;
                    }
                }
            }
        );

        FacebookAdAccount._pProto.constructor.call(this, FacebookAdAccount, uuid);
    };

    Object.extendProto(FacebookAdAccount, Model.Sender.BaseFacebookSender);

    /**
     * Creates a facebook-ad-account by data
     * @function
     * @static
     * @name Model.Sender.FacebookAdAccount.createByData
     * @param {object} data
     * @returns Model.Sender.FacebookAdAccount
     */
    FacebookAdAccount.createByData = function createByData(data) {
        return Model.Sender.createByData(TYPE, data);
    };

    ns.TYPES[TYPE] = FacebookAdAccount;

    Object.defineProperties(
        FacebookAdAccount,
        {
            TYPE : {
                value : TYPE
                /**
                 * @property
                 * @constant
                 * @name Model.Sender.FacebookAdAccount#TYPE
                 * @type {String}
                 */
            }
        }
    );

    ns.FacebookAdAccount = FacebookAdAccount;
})(Object.namespace('Model.Sender'));
