(function(ns) {
    var KEY_MANAGED_BY = 'managedBy';
    /**
     * @namespace
     * @alias Controller.Component.sActionCollection
     * @constructor
     *
     * @param $element
     * @param $scope
     */
    var sActionCollection = function($element, $scope) {
        var actionManagers  = [];
        this.$deRegister    = [];
        /**
         * @type {jQuery}
         * @name Controller.Component.sActionCollection#$element
         */
        this.$element       = $element;
        this.$scope         = $scope;
        this.required       = $element[0].hasAttribute('required');

        // See IT-2886
        Object.defineProperties(
            this,
            {
                'actions':
                {
                    get: function() {
                        return this.model.actions;
                    },
                    set: function(val) {
                        // empty function, we don't really want to set any value
                    }
                },
                isNewAction: {
                    enumerable: true,
                    get: function () {
                        return this.selectedAction && this.model.getIndex(this.selectedAction) === -1;
                    }
                    /**
                     * @property
                     * @name Controller.Component.sActionCollection#isNewAction
                     * @type {Boolean}
                     */
                }
            }
        );

        /**
         * @param event
         * @param {Model.UUID[]} payload
         */
        this.handleSyncManagedActions = function syncManagedActions(event, payload) {
            actionManagers = payload;
            sActionCollection.prototype.handleSyncManagedActions.apply(this, arguments);
            this.$scope.modelForm.model.$validate();
        };

        /**
         * @param {Model.Action[]} modelValue
         * @return {Boolean}
         */
        this.hasPartialManagedAction = function hasPartialManagedAction(modelValue) {
            if (!modelValue || !modelValue.length) {
                return true;
            }

            var managedActions = modelValue.filter(function (action) {
                return action.managedBy;
            });

            var indexedActionManagers = actionManagers.reduce(function (carry, actionManager) {
                if (!(actionManager instanceof Model.AI.Entity)) {
                    return carry;
                }
                carry[actionManager.uuid] = actionManager;
                return carry;
            }, {});

            for (var i = managedActions.length -1; i >= 0; i--) {
                var managerEntity = indexedActionManagers[managedActions[i].managedBy];
                if (!managerEntity) {
                    continue;
                }

                if (managerEntity.partialEntity) {
                    continue;
                }

                managedActions.splice(i, 1);
            }

            return !managedActions.length;
        };

        /**
         * @property
         * @name Controller.Component.sActionCollection#selectedAction
         * @type Model.Action
         */

        /**
         * @property
         * @name Controller.Component.sActionCollection#model
         * @type Model.Action.Collection
         */

        /**
         * @property
         * @name Controller.Component.sActionCollection#contextCtrl
         */
    };

    /**
     * @name Controller.Component.sActionCollection#$onInit
     */
    sActionCollection.prototype.$onInit = function() {
        var self = this;

        this.$deRegister.push(
            this.$scope.$on(
                'creator-edit-active-group-change',
                function() {
                    if (self.selectedAction && self.selectedAction.type === Const.PostbackMessage) {
                        self.onAddAction();
                    }
                }
            )
        );

        this.$deRegister = this.$deRegister.concat(
            // add backup for handling chip click if the component is readonly
            this.$element.$on('click', 'md-chip',
                /**
                 * @param {Event} event
                 */
                function (event) {
                    if (self.model.getAllowedActions().length) {
                        return;
                    }
                    var index = event.currentTarget.getAttribute('index');
                    if (self.model.actions[index]) {
                        if (self.selectedAction && self.selectedAction.uuid === self.model.actions[index].uuid) {
                            return;
                        }

                        self.selectChip(self.model.actions[index]);
                        digestIfNeeded(self.$scope);
                    }
                })
        );

        // must be before createEmptyAction
        if (this.contextCtrl) {
            this.model.context = this.contextCtrl.context;
        }

        if (this.required && this.model.actions.length < 1) {
            this.createEmptyAction();
        }

        this.$parentForm = this.$element.closest('form');
        this.$deRegister = this.$deRegister.concat(
            this.$parentForm.$on('pre-submit', function() {
                if (self.formCtrl.$valid && self.selectedAction) {
                    self.onAddAction();
                }
            })
        );

        this.$deRegister.push(
            this.$scope.$on(
                Controller.Directive.sActionContext.EVENT_GET_MANAGED_ACTION,
                this.handleGetManagedActions.bind(this)
            )
        );

        this.$deRegister.push(
            this.$scope.$on(
                Controller.Directive.sActionContext.EVENT_UPDATE_MANAGED_ACTION_STATE,
                this.handleUpdateManagedAction.bind(this)
            )
        );

        this.$deRegister.push(
            this.$scope.$on(
                Controller.Directive.sActionContext.EVENT_SYNC_MANAGED_ACTIONS,
                this.handleSyncManagedActions.bind(this)
            )
        );

        // Select recurring notification token action by default if it has no value set
        for (var i = 0; i < this.actions.length; i++) {
            if(this.actions[i].type === Model.Action.TYPE_ONE_TIME_NOTIFICATION && !this.actions[i].value) {
                this.selectedAction = this.actions[i];
                this.refreshSelected();
                break;
            }
        }
    };

    /**
     * @name Controller.Component.sActionCollection#$onDestroy
     */
    sActionCollection.prototype.$onDestroy = function() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    sActionCollection.prototype.$postLink = function $postLink() {
        this.$scope.modelForm.model.$validators.hasPartialManagedAction = this.hasPartialManagedAction.bind(this);
    };

    /**
     * @name Controller.Component.sActionCollection#refreshSelected
     */
    sActionCollection.prototype.refreshSelected = function refreshSelected() {
        var pos;

        this.$element.find('md-chip').removeClass('md-focused');
        if (this.selectedAction) {
            if ((pos = this.model.getIndex(this.selectedAction)) !== -1) {
                this.$element.find('md-chip:eq(' + pos + ')').addClass('md-focused');
            }
        }
    };


    /**
     * @name Controller.Component.sActionCollection#onRemoveAction
     */
    sActionCollection.prototype.onRemoveAction = function onRemoveAction(action) {
        if (this.selectedAction && this.selectedAction.uuid === action.uuid) {
            this.selectedAction = null;
        }

        this.model.removeAction(action);

        if (this.model.actions.length < 1 && this.required) {
            this.createEmptyAction();
        }

        this.refreshSelected();
    };

    /**
     * @name Controller.Component.sActionCollection#createEmptyAction
     */
    sActionCollection.prototype.createEmptyAction = function createEmptyAction(event) {
        if (event && event.relatedTarget && event.relatedTarget.className.search('md-chip-remove') !== -1) {
            return;
        }
        var action              = new Model.Action(),
            allowedActionTypes  = this.model.getAllowedActions(),
            defaultActions      = [
                Model.Action.TYPE_POSTBACK_MESSAGE,
                Model.Action.TYPE_USER_ATTRIBUTE
            ];

        action.type = allowedActionTypes.filter(function(actionType) {
            return defaultActions.indexOf(actionType) !== -1;
        }).sort(function(a, b) {
            return defaultActions.indexOf(a) - defaultActions.indexOf(b);
        }).shift() || allowedActionTypes.slice(0,1).pop() || Const.PostbackMessage;

        this.selectedAction = action;
        this.refreshSelected();
    };

    /**
     * @name Controller.Component.sActionCollection#onAddAction
     */
    sActionCollection.prototype.onAddAction = function onAddAction() {
        try {
            if (this.model.getIndex(this.selectedAction) === -1) {
                this.model.addAction(this.selectedAction);
            } else {
                this.model.updateAction(this.selectedAction);
            }
        } catch (e) {
            if (e instanceof Model.Exception.InvalidActionError) {
                return;
            }

            throw e;
        }

        this.$scope.$emit('action-added', this.selectedAction);
        this.selectedAction = null;

        this.refreshSelected();
    };

    /**
     * @name Controller.Component.sActionCollection#selectChip
     * @param {Model.Action} action
     */
    sActionCollection.prototype.selectChip = function selectChip(action) {
        if (!action) {
            this.selectedAction = null;
            return;
        }

        this.selectedAction = action.clone();
    };

    /**
     * @name Controller.Component.sActionCollection#resetSelected
     */
    sActionCollection.prototype.resetSelected = function resetSelected() {
        if (this.model.actions.length < 1 && this.required) {
            return;
        }
        this.selectedAction = null;
        this.refreshSelected();
    };

    /**
     * @name Controller.Component.sActionCollection#handleDrop
     * @param {ActionArray} srcList
     * @param {Number} srcIndex
     * @param {ActionArray} targetList
     * @param {Number} targetIndex
     * @return {boolean}
     */
    sActionCollection.prototype.handleDrop = function handleDrop(srcList, srcIndex, targetList, targetIndex) {
        var toMove = srcList.parent.actions[srcIndex];

        if (srcList !== targetList) {
            srcList.parent.removeAction(toMove);
            targetList.parent.addAction(toMove);
        }

        targetList.parent.moveAction(toMove, targetIndex);
        return true;
    };

    /**
     * @param {$.Event} event
     * @param {Model.UUID} payload
     * @param {[]} actionsFound
     */
    sActionCollection.prototype.handleGetManagedActions = function getManagedActions(event, payload, actionsFound) {
        var managedAction = this.getActionManagedBy(payload);

        if (!managedAction) {
            return;
        }

        actionsFound.push(managedAction);
    };

    /**
     * @param event
     * @param {Model.UUID} payload
     * @param {Boolean} shouldBeManaged
     */
    sActionCollection.prototype.handleUpdateManagedAction = function updateManagedAction(event, payload, shouldBeManaged) {
        var managedAction = this.getActionManagedBy(payload);

        function updateActionFromPayload(action, payload) {
            if (payload instanceof Model.AI.Entity) {
                action.type = Model.Action.TYPE_USER_ATTRIBUTE;
                action.value = JSON.stringify({
                    type: 'set',
                    key: payload.name,
                    value: Model.AI.MatchGroup.ENTITY_PREFIX + payload.name
                });
            }
        }

        if (shouldBeManaged === Boolean(managedAction)) {
            // the action and the state are in sync
            if (managedAction) {
                if (this.selectedAction && managedAction.uuid === this.selectedAction.uuid) {
                    this.selectedAction = managedAction;
                }
                updateActionFromPayload(managedAction, payload);
            }

            return;
        }

        if (!shouldBeManaged && managedAction) {
            // the state demands the action to be removed
            this.onRemoveAction(managedAction);
            return;
        }

        // finally the state demands the action to be created

        var action = new Model.Action();
        action.setMeta(KEY_MANAGED_BY, payload.uuid);
        updateActionFromPayload(action, payload);

        this.model.addAction(action);
    };

    /**
     * @param event
     * @param {Model.UUID[]} payload
     */
    sActionCollection.prototype.handleSyncManagedActions = function syncManagedActions(event, payload) {
        var managedActionsByUuids = this.model.actions.reduce(function (carry, action) {
                var uuid = action.getMeta(KEY_MANAGED_BY);
                if (!uuid) {
                    return carry;
                }

                carry[uuid] = action;
                return carry;
            }, {}),
            uuidsPresent = payload.map(function(model) {
                return model.uuid;
            }),
            uuidsToDelete = uuidsPresent.diff(Object.getOwnPropertyNames(managedActionsByUuids))
        ;

        uuidsToDelete.map(function (uuid) {
            this.onRemoveAction(managedActionsByUuids[uuid]);
        }.bind(this));

        digestIfNeeded(this.$scope);
    };

    /**
     * @param {Model.UUID} payload
     */
    sActionCollection.prototype.getActionManagedBy = function getActionManagedBy(payload) {
        return this.model.actions.filter(function (action) {
            return action.getMeta(KEY_MANAGED_BY) === payload.uuid;
        }).shift();
    };

    ns.sActionCollection = sActionCollection;
})(Object.namespace('Controller.Component'));
