(function ($) {
    $.fn.extend({
        'inScrolledView':
            /**
             * Returns whether a given element is inside the visible section of it is scrollParent or not
             * @example
             * $('li').inScrolledView();
             */
            function () {
                var domElement = this[0];
                if (!domElement) {
                    return;
                }

                if (domElement instanceof $) {
                    domElement = domElement[0];
                } else if (typeof(domElement) === 'string') {
                    domElement = $(domElement)[0];
                } else if (!(domElement instanceof HTMLElement)) {
                    throw 'Invalid element type!';
                }

                var scrollParent = domElement.offsetParent;

                if (!scrollParent) {
                    return;
                }

                var parentRect = scrollParent.getBoundingClientRect(),
                    clientRect = domElement.getBoundingClientRect()
                ;

                return parentRect.bottom >= clientRect.bottom
                    && parentRect.top <= clientRect.top
                    && parentRect.left <= clientRect.left
                    && parentRect.right >= clientRect.right;
            }
    });
})(jQuery);
