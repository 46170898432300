(function (ns) {
    var TYPE_MATCHED    = 'matched',
        TYPE_UNMATCHED  = 'unmatched',
        FLAG_POSITIVE   = 1,
        FLAG_NO_ADD     = 0,
        FLAG_NEGATIVE   = -1
    ;

    var isBatchUnanimousCache = null;

    /**
     * @namespace
     * @alias sInbox.Controller.IncomingMessage
     *
     * @param {String} type
     * @param listUrl
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param Notification
     * @param $scope
     * @param $mdDialog
     * @param $location
     * @param $sce
     * @param {Service.sMessageCollection} sMessageCollection
     * @param {Service.sDomain} sDomainService
     * @param {sHeadline.Service.sTitle} sTitle
     * @param $exceptionHandler
     * @constructor
     */
    var IncomingMessage = function (
        type,
        listUrl,
        sAPIAccess,
        Notification,
        $scope,
        $mdDialog,
        $location,
        $sce,
        sMessageCollection,
        sDomainService,
        sTitle,
        $exceptionHandler
    ) {
        this.type = type;
        this.notification = Notification;
        this.$scope = $scope;
        this.$mdDialog = $mdDialog;
        this.sMessageCollection = sMessageCollection;
        this.sDomainService = sDomainService;
        this.sAPIAccess = sAPIAccess;
        this.$location = $location;
        this.$exceptionHandler = $exceptionHandler;
        this.sending = null;
        this.beingReplied = null;
        this.$deRegister = [];
        this.structure = ['new', 'archive'];
        this.currentView = this.structure[0];
        this.views = {};

        this.createLists(listUrl, type);
        this.fetchViewFromLocation();
        this.fetchMetaForLists();

        $scope.$on('$destroy', this.$onDestroy.bind(this));
    };

    /**
     * @function
     * @returns {Deferred}
     */
    IncomingMessage.prototype.fetchMetaForLists = function fetchMetaForLists() {
        var actions = [],
            self = this,
            listTitle
        ;

        for (listTitle in this.views) {
            if (listTitle === this.currentView) {
                continue;
            }

            var list = this.views[listTitle];

            actions.push(function () {
                return list.load(false, true);
            });
        }

        return $.aggregateAction(actions, Model.RESTAccessByUUID.endpoint_batch()).always(function () {
            digestIfNeeded(self.$scope);
        });
    };

    /**
     * @function
     * @name sInbox.Controller.IncomingMessage#createLists
     * @param {String} listUrl
     * @param {String} type
     */
    IncomingMessage.prototype.createLists = function createLists(listUrl, type) {
        var self = this,
            list, column;

        this.views = this.structure.reduce(
            function (collection, listTitle) {
                list = new Model.List(listUrl);

                list.pager.recordsPerPage = 25;
                list.filters.createAndAdd('status', listTitle, true);
                list.filters.createAndAdd('type', type, true);

                list.filterFields = {
                    title: {
                        component: 'sTextInput',
                        options: {
                            defaultValue: '',
                            attrs: {
                                'placeholder': 'Text',
                                'class'      : 'textsearch',
                            },
                            label: null
                        }
                    }
                };

                column = list.columns.createAndAdd('message');
                column.label = 'Message';

                switch (listTitle) {
                    case 'new':
                        if (type === TYPE_UNMATCHED) {
                            column = list.columns.createAndAdd('similarTo');
                            column.label = 'Similar to ...';
                        } else {
                            column = list.columns.createAndAdd('matchedTo');
                            column.label = 'Matched to ...';
                        }

                        column = list.columns.createAndAdd('similarity');
                        column.label = 'Similarity';
                        break;
                    case 'archive':
                        column = list.columns.createAndAdd('actionTaken');
                        column.label = 'Action taken';
                        break;
                    default:
                        break;
                }

                column = list.columns.createAndAdd('received');
                column.label = 'Received';
                column.isSortable = true;

                list.actions = list.actions || {};

                if (listTitle === 'new') {
                    list.isMultipleAllowed = false;

                    // Actions for unmatched messages
                    if (type === TYPE_UNMATCHED) {
                        if (self.sAPIAccess.isAllowed('messageIncoming.unmatched.markDone', Const.Method.PUT)
                            && self.sAPIAccess.isAllowed('messageAnchor.publish', Const.Method.PUT)) {
                            list.actions.addUnmatchedToAITemplate      = new Model.Menu.Action('addUnmatchedToAITemplate', self.handleAddUnmatchedToAITemplate.bind(self));
                            list.actions.createAITemplateFromUnmatched = new Model.Menu.Action('createAITemplateFromUnmatched', self.handleCreateAITemplateFromUnmatched.bind(self));
                        }
                    }
                    // Actions for matched messages
                    else if (type === TYPE_MATCHED) {
                        if (self.sAPIAccess.isAllowed('messageIncoming.matched.markDone', Const.Method.PUT)
                            && self.sAPIAccess.isAllowed('messageAnchor.publish', Const.Method.PUT)) {
                            list.actions.addAsCorrectMatch      = new Model.Menu.Action('done', self.handleAddMatchAsCorrect.bind(self));
                            list.actions.addAsNegativeExample   = new Model.Menu.Action('addUnmatchedToAITemplate', self.handleAddMatchAsNegativeExample.bind(self));
                            list.actions.addToDifferentTemplate = new Model.Menu.Action('createAITemplateFromUnmatched', self.handleAddMatchToDifferentTemplate.bind(self));
                            list.actions.openTemplateForEditing = new Model.Menu.Action('edit', self.handleOpenTemplateForEditing.bind(self));
                        }
                    }

                    if (self.sAPIAccess.isAllowed('messageIncoming.unmatched.markDone', Const.Method.PUT)) {
                        list.actions.done = new Model.Menu.Action('done', self.handleMarkAsDone.bind(self));
                    }

                    if (Object.keys(list.actions).length) {
                        list.isMultipleAllowed = true;
                        column                 = list.columns.createAndAdd('options');
                        column.label           = 'Options';
                    }
                }

                list.mapFn = function (element) {
                    var message = new Model.ApiMessage(element.message.uuid);
                    return message.updateByData(element.message);
                };

                collection[listTitle] = list;
                return collection;
            },
            {}
        );
    };

    /**
     * @function
     * @param {String} view
     */
    IncomingMessage.prototype.changeView = function changeView(view) {
        if (this.views[this.currentView].loading) {
            return;
        }

        var changed = this.$location.search().isEmpty() || view !== this.currentView;

        if (changed) {
            this.currentView = view;
            this.$location.search('type', this.currentView);
        }
    };

    IncomingMessage.prototype.fetchViewFromLocation = function fetchViewFromLocation() {
        var $search = this.$location.search(),
            view = $search.type || Object.getFirstPropertyName(this.views)
        ;

        this.changeView(view);
    };

    IncomingMessage.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @param {Model.ApiMessage[]} selection
     * @param {Boolean} toggleSelection
     */
    IncomingMessage.prototype.handleCreateAITemplateFromUnmatched = function handleCreateAITemplateFromUnmatched(selection, toggleSelection) {
        this.openEditingWindow(selection, true, toggleSelection);
    };

    /**
     * @function
     * @param {Model.ApiMessage[]} selection
     * @param {Boolean} toggleSelection
     */
    IncomingMessage.prototype.handleAddUnmatchedToAITemplate = function handleAddUnmatchedToAITemplate(selection, toggleSelection) {
        this.openEditingWindow(selection, false, toggleSelection);
    };

    /**
     * @function
     * @param {Model.ApiMessage[]} selection
     * @param {boolean} createNewAITemplate
     * @param {boolean} toggleSelection
     * @param {string} AITemplateUuid
     * @return {$.Deferred}
     */
    IncomingMessage.prototype.handleMarkAsDone = function handleMarkAsDone(selection, toggleSelection, createNewAITemplate, AITemplateUuid) {
        var calls = selection.map(function (message) {
                if (AITemplateUuid) {
                    if (createNewAITemplate) {
                        message.setAddedToNew(AITemplateUuid);
                    } else {
                        message.setAddedToExisting(AITemplateUuid);
                    }
                } else {
                    message.setArchived();
                }

                return message.save.bind(message);
            }),
            self = this
        ;

        return $.aggregateAction(
            calls,
            Model.RESTAccessByUUID.endpoint_batch(),
            function (options, data, counter) {
                var nameObj = {
                    method  : options.method,
                    url     : options.url,
                    counter : counter,
                    params  : options.data
                };
                options.context.getFormData(data, nameObj);
            })
            .then(function () {
                self.fetchMetaForLists();
                self.notification.success(calls.length + ' message' + (calls.length > 1 ? 's' : '') + ' moved to Archive tab');

                if (toggleSelection) {
                    for (var i = selection.length - 1; i >= 0; i--) {
                        self.$scope.$emit(sList.Component.Controller.sList.EVENT_TOGGLE_SELECTION, selection[i]);
                    }
                }

                self.views[self.currentView].load(false, true);
            });
    };

    /**
     * @function
     * @param {Model.ApiMessage[]} selection
     * @param {Boolean} toggleSelection
     */
    IncomingMessage.prototype.handleAddMatchAsCorrect = function handleAddMatchAsCorrect(selection, toggleSelection) {
        this.openEditingWindow(selection, false, toggleSelection);
    };

    /**
     * @function
     * @param {Model.ApiMessage[]} selection
     * @param {Boolean} toggleSelection
     */
    IncomingMessage.prototype.handleAddMatchAsNegativeExample = function handleAddMatchAsNegativeExample(selection, toggleSelection) {
        this.openEditingWindow(selection, false, toggleSelection, FLAG_NEGATIVE);
    };

    /**
     * @function
     * @param {Model.ApiMessage[]} selection
     * @param {Boolean} toggleSelection
     */
    IncomingMessage.prototype.handleAddMatchToDifferentTemplate = function handleAddMatchToDifferentTemplate(selection, toggleSelection) {
        this.openEditingWindow(selection, false, toggleSelection, FLAG_POSITIVE, true);
    };

    IncomingMessage.prototype.handleOpenTemplateForEditing = function handleOpenTemplateForEditing(selection, toggleSelection) {
        this.openEditingWindow(selection, false, toggleSelection, FLAG_NO_ADD, false);
    };

    /**
     * @function
     * @param {Model.ApiMessage[]} selection
     * @param {Boolean} createNew
     * @param {Boolean} toggleSelection
     * @param {Number=} addAsFlag
     * @param {Boolean=} forceAITemplateSelection
     */
    IncomingMessage.prototype.openEditingWindow = function openEditingWindow(selection, createNew, toggleSelection, addAsFlag, forceAITemplateSelection) {
        var self = this,
            AITemplateUuid = null
        ;

        if (addAsFlag === undefined || isNaN(parseInt(addAsFlag)))  {
            addAsFlag = FLAG_POSITIVE;
        }

        if (!forceAITemplateSelection && !createNew && selection[0].matching && selection[0].matching.length && selection[0].matching[0]) {
            AITemplateUuid = selection[0].matching[0].aiTemplateUuid;
        }

        this.$mdDialog.show({
            controller          : sInbox.Controller.AddUnmatchedToAITemplate,
            controllerAs        : '$ctrl',
            templateUrl         : 'sinbox:add-unmatched-to-ai-template',
            parent              : angular.element(document.body),
            clickOutsideToClose : false,
            bindToController    : true,
            locals              : {
                userInputs          : selection,
                domainId            : self.sDomainService.currentDomainId,
                AITemplateUuid      : AITemplateUuid,
                createNewAITemplate : createNew,
                addAsFlag           : addAsFlag
            }
        }).then(function (result) {
            self.handleMarkAsDone(result.selection, toggleSelection, result.createNewAITemplate, result.messageUuid).then(function () {
                    self.notification.success({
                        delay   : 10000,
                        message : 'Template <a href="#/creator/' + result.messageUuid + '" target="_blank" title="'
                            + result.title + '">' + result.title + '</a> saved ' + (result.activate ? 'and activated ' : '') + 'successfully.'
                    });
                }
            );
        }).catch(function (err) {
            if (err) {
                self.$exceptionHandler(err);
            }
        });
    };

    /**
     * @function
     * @param {Object} record
     * @param {Array} records
     * @param {Number|String} index
     * @returns {{}}
     */
    IncomingMessage.prototype.getRowClass = function getRowClass(record, records, index) {
        var classes = {};
        if (index < (records.length - 1)) {
            classes.noBorder = !(records[parseInt(index) + 1].from);
        }

        if (this.beingReplied) {
            classes.disabled = this.beingReplied.uuid !== record.message.uuid;
        }
        return classes;
    };

    /**
     * Returns whether the batch items are of the same match type and returns the type name if true
     * Will return false if the batch contains multiple types
     * @param {[]=} entries
     * @return {Boolean|String}
     */
    IncomingMessage.prototype.isBatchUnanimous = function isBatchUnanimous(entries) {
        entries = entries || [];
        var newKey = [entries.length].concat(entries.map(function(entry) {
                return entry.uuid;
            })).join('-')
        ;

        if (!isBatchUnanimousCache || isBatchUnanimousCache.key !== newKey) {
            isBatchUnanimousCache = isBatchUnanimousCache || {};
            isBatchUnanimousCache.key = newKey;
            var variants = entries.reduce(function(types, entry) {
                var first = entry.matching.slice(0,1).pop(),
                    typeToAdd = null;
                if (first) {
                    typeToAdd = first.type;
                }

                if (types.indexOf(typeToAdd) === -1) {
                    types.push(typeToAdd);
                }
                return types;
            },[]);

            if (variants.length !== 1) {
                isBatchUnanimousCache.value = false;
            } else {
                isBatchUnanimousCache.value = variants.pop();
            }
        }

        return isBatchUnanimousCache.value;
    };

    Object.defineProperties(
        IncomingMessage,
        {
            TYPE_MATCHED : {
                value : TYPE_MATCHED
                /**
                 * @property
                 * @constant
                 * @name sInbox.Controller.IncomingMessage#TYPE_MATCHED
                 * @type {String}
                 */
            },
            TYPE_UNMATCHED : {
                value : TYPE_UNMATCHED
                /**
                 * @property
                 * @constant
                 * @name sInbox.Controller.IncomingMessage#TYPE_UNMATCHED
                 * @type {String}
                 */
            },
            FLAG_POSITIVE: {
                value: FLAG_POSITIVE
                /**
                 * @property
                 * @constant
                 * @name sInbox.Controller.IncomingMessage#FLAG_POSITIVE
                 * @type {String}
                 */
            },
            FLAG_NEGATIVE: {
                value: FLAG_NEGATIVE
                /**
                 * @property
                 * @constant
                 * @name sInbox.Controller.IncomingMessage#FLAG_POSITIVE
                 * @type {String}
                 */
            },
            FLAG_NO_ADD: {
                value: FLAG_NO_ADD
                /**
                 * @property
                 * @constant
                 * @name sInbox.Controller.IncomingMessage#FLAG_NO_ADD
                 * @type {String}
                 */
            }
        }
    );

    ns.IncomingMessage = IncomingMessage;
})(Object.namespace('sInbox.Controller'));
