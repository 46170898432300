(function (ns) {
    /**
     * @namespace
     * @alias Model.Action.Field.Collection
     * @constructor
     *
     * @param {object} fieldsDefinition
     */
    var Collection = function (fieldsDefinition) {
        var formFields          = null,
            fieldValues         = [],
            minLength           = fieldsDefinition.required ? 1 : 0,
            isArrayWithFields   = fieldsDefinition.type  === Model.Action.Field.Bag.TYPE_FIELDS
        ;

        /**
         * @function
         * @name Model.Action.Field.Collection~addEmptyField
         */
        var addEmptyField = function addEmptyField() {
            if (fieldsDefinition.type === Model.Action.Field.Bag.TYPE_FIELDS) {
                fieldValues.push(new Model.Action.Field.Bag(fieldsDefinition.fields));
                return;
            }
            fieldValues.push(undefined);
        };

        /**
         * @function
         * @name Model.Action.Field.Collection~buildFormFields
         */
        var buildFormFields = function buildFormFields() {
            var formField;
            formFields = {};

            for (var key in fieldValues) {
                formField                   = {};
                formField[key]              = Model.Action.Field.Bag.getFormField(fieldsDefinition);
                formField[key]['template']  = '_form_collection_row_action_field';
                formFields[key]             = formField;
            }
        };

        Object.defineProperties(
            this,
            {
                fieldValues: {
                    enumerable: true,
                    get: function () {
                        return fieldValues;
                    }
                    /**
                     * @property
                     * @name Model.Action.Field.Collection#fieldValues
                     * @type {Object[]}
                     */
                },
                isArrayWithFields: {
                    enumerable: true,
                    get: function () {
                        return isArrayWithFields;
                    }
                    /**
                     * @property
                     * @name Model.Action.Field.Collection#isArrayWithFields
                     * @type {boolean}
                     */
                },
                formFields: {
                    enumerable: true,
                    get: function () {
                        if (!formFields) {
                            buildFormFields();
                        }
                        return formFields;
                    }
                }
                /**
                 * @property
                 * @name Model.Action.Field.Collection#formFields
                 * @type {Object}
                 */
            }
        );

        /**
         * Updates the current fieldValues or clear set values
         *
         * @function
         * @name Model.Action.Field.Collection#updateFields
         * @param {*[]} data
         */
        this.updateFields = function updateFields(data) {
            var i,
                fieldBag
            ;

            data = data || [];
            fieldValues = [];

            buildFormFields();
            for (i = 0; i < data.length; i++) {
                if (fieldsDefinition.type === Model.Action.Field.Bag.TYPE_FIELDS) {
                    fieldBag = new Model.Action.Field.Bag(fieldsDefinition.fields);
                    fieldBag.updateFields(data[i]);
                    fieldValues.push(fieldBag);
                    continue;
                }
                fieldValues.push(data[i]);
            }
            while (fieldValues.length < minLength) {
                addEmptyField();
            }
            buildFormFields();
        };

        /**
         * @function
         * @name Model.Action.Field.Collection#clearFields
         */
        this.clearFields = function clearFields() {
            this.updateFields();
            for (var key in fieldValues) {
                if (fieldValues[key] instanceof Model.Action.Field.Bag) {
                    fieldValues[key].clearFields();
                    continue;
                }
                fieldValues[key] = fieldsDefinition[Model.Action.Field.Bag.KEY_DEFAULT_VALUE] || undefined;
            }
        };

        /**
         * @function
         * @name Model.Action.Field.Collection#canLooseItem
         * @returns {boolean}
         */
        this.canLooseItem = function canLooseItem() {
            return fieldValues.length > minLength;
        };

        /**
         * @function
         * @name Model.Action.Field.Collection#addItem
         */
        this.addItem = function addItem() {
            addEmptyField();
            buildFormFields();
        };

        /**
         * @function
         * @name Model.Action.Field.Collection#removeItem
         */
        this.removeItem = function removeItem(key) {
            fieldValues.splice(key, 1);
            buildFormFields();
        };

        /**
         * Used by the stringify method
         *
         * @function
         * @name Model.Action.Field.Collection#toJSON
         */
        this.toJSON = function toJSON() {
            var tmpFields = [];

            for (var key in this.fieldValues) {
                if (typeof this.fieldValues[key] !== 'undefined') {
                    tmpFields.push(this.fieldValues[key]);
                }
            }
            return tmpFields;
        };

        // prefill fieldValues
        this.updateFields([]);
    };

    ns.Collection = Collection;
})(Object.namespace('Model.Action.Field'));