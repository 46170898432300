/**
 * Created by Gabor on 19-Oct-17.
 */

jQuery.fn.extend({
    'nodeByContent': function (contentSearched) {
        var lookup  = function(startElement) {
                var contents = startElement.contents();

                for (var i = 0; i < contents.length; i++) {
                    var $content = $(contents[i]);
                    if (!contents[i].nodeValue && $content.text().search(contentSearched) !== -1) {
                        lookup($content);
                    } else if (contents[i].nodeValue && contents[i].nodeValue.search(contentSearched) !== -1) {
                        if (results.indexOf(contents[i]) === -1) {
                            results.push(contents[i]);
                        }
                    }
                }
            },
            results     = []
            ;

        $.each(this, function(index, element) {
           lookup($(element));
        });

        return $(results);
    }
});