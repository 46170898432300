(function (ns) {
    /**
     * @namespace
     * @alias Service.sConversationValidator
     * @constructor
     *
     * @param {ValidationLevels} validatorLevels
     * @param {Service.sConfirm} sConfirm
     * @param sBugsnag
     * @param Notification
     */
    var sConversationValidator = function sConversationValidator(
        validatorLevels,
        sConfirm,
        sBugsnag,
        Notification
    ) {
        this.sConfirm       = sConfirm;
        this.sBugsnag       = sBugsnag;
        this.Notification   = Notification;

        this.isValidLevel = function isValidLevel(level) {
            return validatorLevels.hasOwnProperty(level);
        };

        this.getValidatorsForLevel = function getValidatorsForLevel(level) {
            var validators = [];
            if (!this.isValidLevel(level)) {
                return [];
            }

            if (validatorLevels[level].requires.length) {
                for (var i = 0; i < validatorLevels[level].requires.length; i++) {
                    validators = validators.concat(this.getValidatorsForLevel(validatorLevels[level].requires[i]));
                }
            }

            validators = validators.concat(validatorLevels[level].validators);
            return validators.unique();
        };
    };

    /**
     * @function
     * @name Service.sConversationValidator#validate
     * @param {Model.Message.Collection} messageCollection
     * @param _level
     * @return {Boolean}
     */
    sConversationValidator.prototype.validate = function validate(messageCollection, _level) {
        var level = _level || Provider.sConversationValidator.DEFAULT_LEVEL,
            validators = this.getValidatorsForLevel(level),
            valid = true;

        for (var i = 0; i < validators.length; i++) {
            var options = validators[i].options.clone();
            if (!validators[i].check([messageCollection])) {
                if (!validators[i].hasRepairFn || options.autoRepair !== true) {
                    valid = false;
                    break;
                }
                validators[i].repair([messageCollection]);
            }
        }

        return valid;
    };

    /**
     * @function
     * @name Service.sConversationValidator#validate
     * @param {Model.Message.Collection} messageCollection
     * @param _level
     * @return {$.Deferred}
     */
    sConversationValidator.prototype.validateWithPrompt = function validateWithPrompt(messageCollection, _level) {
        var level = _level || Provider.sConversationValidator.DEFAULT_LEVEL,
            validators = this.getValidatorsForLevel(level)
        ;

        var failingValidators = validators.filter(function (validator) {
            return !validator.check([messageCollection]);
        }).map(function (validator) {
            var options = validator.options.clone(),
                resolveFn = function () {
                    if (!validator.hasRepairFn) {
                        return $.Deferred().reject().promise();
                    }
                    // try/catch handling for bugs in repair-cases to prevent endless saving
                    try {
                        validator.repair([messageCollection]);
                        // TODO: the activeLane might contain the orphan so should be redrawn...
                    }
                    catch (e) {
                        this.Notification.error("Error in the final check. Some part of your Conversation is wrong.");
                        this.sBugsnag.notify(e);
                        return $.Deferred().reject().promise();
                    }

                    return this.validate(messageCollection, _level);
                }.bind(this)
            ;

            if (validator.hasRepairFn && options.autoRepair === true) {
                return $.Deferred().resolve().promise().then(resolveFn);
            }

            if (!options.decline) {
                options.decline = null;
            }

            if (!validator.options.forIncoming) {
                options.content += '<br><br>The following message(s) are affected: ' + validator.errorMessages.map(function (msg) {
                    return msg.counter;
                });
                options.content += '<br><br>You can find them using the minimap';
            } else {
                options.content += '<br><br>The following user-input(s) are affected: ' + validator.errorMessages.map(
                    function (msg) {
                        var lastPart,
                            rel,
                            returnString = '(';
                        if ((rel = msg.getRelationsByType(Model.Message.Relation.TYPE_NLP).shift()) && rel.from && rel.from.counter) {
                            returnString += 'Message ' + rel.from.counter + ': ';
                        }
                        if ((lastPart = msg.lastPart()) && lastPart.content && lastPart.content.intent) {
                            returnString += '"' + lastPart.content.intent + '"';
                        } else {
                            returnString += 'unknown';
                        }
                        returnString += ')';
                        return returnString;
                    }
                );
            }

            return this.sConfirm.open(options).then(resolveFn);
        }.bind(this));

        return $.when.apply(this, failingValidators);
    };

    ns.sConversationValidator = sConversationValidator;
})(Object.namespace('Service'));
