(function (ns) {
    /**
     * @namespace
     * @alias Model.Automation.Step.Collection
     *
     * @constructor
     * @extends Model.Collection
     */
    var Collection = function () {
        Collection._pProto.constructor.call(this, Model.Automation.Step, 'step');

        /**
         * @name Model.Automation.Step.Collection#steps
         * @property
         * @type {Model.Automation.Step[]}
         */
    };

    Object.extendProto(Collection, Model.Collection);
    
    Collection.prototype.toJSON = function toJSON() {
        return this.steps;
    };

    ns.Collection = Collection;
})(Object.namespace('Model.Automation.Step'));