(function (angular) {
    var module = angular.module('sSidebar');

    module
        /**
         * @ngdoc component
         * @name sSidebar
         * @restrict E
         */
        .component('sSidebar', {
            controller: sSidebar.Component.Controller.sSidebar,
            templateUrl: 'ssidebar/components:s-sidebar'
        })
        /**
         * @ngdoc component
         * @name sSidebarToggleGroup
         * @restrict E
         */
        .component('sSidebarToggleGroup', {
            controller: sSidebar.Component.Controller.sSidebarToggleGroup,
            templateUrl: 'ssidebar/components:s-sidebar-toggle-group'
        })
    ;
})(angular);