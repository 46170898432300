(function (ns) {
    /**
     * @namespace
     * @alias Model.Exception.RESTAccessNoEndpointSet
     *
     * @constructor
     * @extends Error
     */
    var RESTAccessNoEndpointSet = function () {
        RESTAccessNoEndpointSet._pProto.constructor.call(this, 'No endPoint given. Did you register it in sFollowPubNG.js? (look for EVENT_ENDPOINTS_LOADED)');
    };

    Object.extendProto(RESTAccessNoEndpointSet, Error);

    ns.RESTAccessNoEndpointSet = RESTAccessNoEndpointSet;
})(Object.namespace('Model.Exception'));