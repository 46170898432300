(function(angular) {
    var module = angular.module('sAutomationModule', []);

    module
        .service('sEventDefinition', sAutomation.Service.sEventDefinition)
        .service('sAutomation', sAutomation.Service.sAutomation)
        ;

    module.config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/automation', new sAutomation.Route.Overview())
            .when('/automation/create', new sAutomation.Route.Create())
            .when('/automation/:uuid', new sAutomation.Route.Edit())
        ;
    }]);

    module.config(['sTitleProvider', function(sTitleProvider) {
        sTitleProvider
            .when(
                '/automation',
                'Automation',
                'Overview'
            )
            .when(
                '/automation/create',
                'Automation',
                'Create new'
            )
            .when(
                '/automation/:uuid',
                'Automation',
                'Edit automation'
            )
        ;
    }]);

})(angular);
