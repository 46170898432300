(function (ns) {
    /**
     * @namespace
     * @alias Controller.Component.sNotificationLabelSelect
     * @constructor
     *
     * @param {Service.sNotificationLabel} sNotificationLabel
     */
    var sNotificationLabelSelect = function (sNotificationLabel) {
        this.sNotificationLabel = sNotificationLabel;

        /**
         * @property
         * @name Controller.Component.sNotificationLabelSelect#isRequired
         * @type {Boolean}
         */

        /**
         * @property
         * @name Controller.Component.sNotificationLabelSelect#inUseOnly
         * @type {Boolean}
         */
    };

    /**
     * @function
     * @name Controller.Component.sNotificationLabelSelect#$onInit
     */
    sNotificationLabelSelect.prototype.$onInit = function $onInit() {
        this.isRequired = !!this.isRequired;
        this.inUseOnly  = !!this.inUseOnly;
    };

    /**
     * @function
     * @name Controller.Component.sNotificationLabelSelect#onHandleChange
     * @param {Model.NotificationLabel} notificationLabel
     * @param {Boolean} isNewItem
     */
    sNotificationLabelSelect.prototype.onHandleChange = function onHandleChange(notificationLabel, isNewItem) {
        if (isNewItem) {
            this.sNotificationLabel.addNewNotificationLabel(notificationLabel);
        }
    };

    /**
     * @function
     * @name Controller.Component.sNotificationLabelSelect#onCreateNew
     * @param {?String} term
     *
     * @return {?Model.NotificationLabel}
     */
    sNotificationLabelSelect.prototype.onCreateNew = function onCreateNew(term) {
        if (!term) {
            return null;
        }

        return Model.NotificationLabel.createByData({name : term});
    };

    ns.sNotificationLabelSelect = sNotificationLabelSelect;
})(Object.namespace('Controller.Component'));
