(function(ns) {
    /**
     * @namespace
     * @alias Model.Sender.Collection
     * @extends Model.Collection.RESTAccess
     *
     * @param {Function} object Represented by it's constructor
     */
    var Collection = function (object) {
        Collection._pProto.constructor.call(this, object);

        this.__getCloneArguments = function() {
            return [object];
        }
   };

    Object.extendProto(Collection, Model.Collection.RESTAccess);

    /**
     * @function
     * @name Model.Sender.Collection#findByUuid
     * @param {string} uuid
     */
    Collection.prototype.findByUuid = function findByUuid(uuid) {
       return  this.items.find(function (item) {
            return item.uuid === uuid;
        })
    };

    ns.Collection = Collection;
})(Object.namespace('Model.Sender'));
