(function(ns) {
    /**
     * @class Model.Message.Node
     */
    var Node = function() {
        /**
         * @type {Model.Message|null}
         * @name Model.Message.Node#message
         */
        this.message = null;

        /**
         * @type {int}
         * @name Model.Message.Node#weight
         */
        this.weight = 0;

        /**
         * @type {Model.Message.Node|null}
         * @name Model.Message.Node#prev
         */
        this.prev = 0;

        /**
         * @property {boolean}
         * @name Model.Message.Node#isDeadEnd
         */
        this.isDeadEnd = false;
    };

    /**
     * Creates a message-node-object
     * @function Model.Message.Node#create
     * @param {Model.Message|null} [message]
     * @param {int} [weight]
     * @param {Model.Message.Node|null} [prev]
     * @param {boolean} [isDeadEnd]
     * @static
     * @returns Model.Message.Node
     */
    Node.create = function create(message, weight, prev, isDeadEnd) {
        var node = new Model.Message.Node();
        node.message = message || null;
        node.weight = weight || 0;
        node.prev = prev || null;
        node.isDeadEnd = isDeadEnd || false;
        return node;
    };

    /**
     * Walks nodes backwards and calculates node-weights based on Dijkstra's algorithm
     *
     * @static
     * @name Model.Message.Node#createGraphNodesReversed
     * @param {Model.Message} startMsg
     * @param {Model.Message} goalMsg
     * @returns {Model.Message.Node[]}
     */
    Node.createGraphNodesReversed = function createGraphNodesReversed(startMsg, goalMsg) {
        if (goalMsg.isRoot) {
            return Node.createGraphNodesFromRoot(startMsg);
        }

        return Node.createGraphNodesFromNode(startMsg, goalMsg) || Node.createGraphNodesFromRoot(startMsg);
    };

    /**
     * @static
     * @name Model.Message.Node#createGraphNodesFromRoot
     * @returns {Model.Message.Node[]}
     */
    Node.createGraphNodesFromRoot = function createGraphNodesFromRoot(startMsg) {
        var msg     = startMsg,
            path    = [],
            resMsg,
            found   = false
        ;

        do {
            path.push(Model.Message.Node.create(msg, path.length, path.slice(-1).pop()));
            resMsg = msg.getRelationsTo().reduce(function(carry, relation) {
                if (relation.from.rootDistance && relation.from.rootDistance < carry.rootDistance) {
                    carry = relation.from;
                }
                return carry;
            }, msg);
            found = resMsg !== msg;
            msg = resMsg;
        } while (found);

        path.slice(-1).pop().isDeadEnd = true;

        return path;
    };

    /**
     * Walks nodes backwards and calculates node-weights based on Dijkstra's algorithm
     *
     * @static
     * @name Model.Message.Node#createGraphNodesFromNode
     * @param {Model.Message} startMsg
     * @param {Model.Message} goalMsg
     * @returns {Model.Message.Node[]}
     */
    Node.createGraphNodesFromNode = function createGraphNodesFromNode(startMsg, goalMsg) {
        var defaultWeight = 1,
            startNode = Model.Message.Node.create(startMsg),
            queue = [startNode], // nodes where vectors are not processed yet
            msgNodes = [startNode], // all found nodes
            currNode,
            relationsTo,
            nodeByMsg,
            weight,
            i,
            found
        ;

        while (queue.length) {
            // sort queue (smallest to the end)
            queue.sort(function(nodeA, nodeB) {
                return nodeA.weight - nodeB.weight;
            });

            // get the node with the smallest weight (which is the last atm)
            currNode = queue.pop();

            msgNodes.push(currNode);
            if (currNode.message.isRoot) {
                continue;
            }

            relationsTo = currNode.message.getRelationsTo().sort(function(rel1, rel2) {
                // noinspection JSReferencingMutableVariableFromClosure
                return Math.abs(rel1.from.rootDistance - currNode.message.rootDistance) -
                    Math.abs(rel2.from.rootDistance - currNode.message.rootDistance);
            });

            if (!relationsTo.length) {
                currNode.isDeadEnd = true;
                continue;
            }

            for (i = 0; i < relationsTo.length; i++) {
                // weight for new node: previous weight + default weight for vector
                // (later we can also set the weight based on the relation-type)
                weight = currNode.weight + defaultWeight;

                // fetch the node which is already connected with current node
                nodeByMsg = msgNodes.concat(queue).filter(function(node) {
                    return node.message.uuid === relationsTo[i].from.uuid;
                }).pop();

                // when we do not have this previous requested node, create a new one
                if (!nodeByMsg) {
                    nodeByMsg = Model.Message.Node.create(
                        relationsTo[i].from,
                        weight,
                        currNode
                    );
                    queue.push(nodeByMsg);
                } else if (nodeByMsg.weight > weight) {
                    nodeByMsg.prev = currNode;
                    nodeByMsg.weight = weight;
                }

                if (nodeByMsg.message.uuid === goalMsg.uuid) {
                    found = nodeByMsg;
                }
            }
        } // end while

        if (found) {
            found.isDeadEnd = true;
            var results = [found],
                prev;
            while ((prev = found.prev)) {
                results.unshift(prev);
                found = prev;
            }

            return results.reverse();
        }

        return null;
    };

    ns.Node = Node;

})(Object.namespace('Model.Message'));
