(function (angular) {

    var module = angular.module('sEntryPointModule', ['ngMaterial']);

    module.config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/entry-points', new sEntryPoint.Route.Index())
        ;
    }]);

    module.config(['sTitleProvider', function(sTitleProvider) {
        sTitleProvider
            .when(
                '/entry-points',
                'Entry Points',
                ''
            )
        ;
    }]);

    module.config(['$mdDialogProvider', function ($mdDialogProvider) {
        $mdDialogProvider.addPreset('sEntryPointEdit', {
            options: function() {
                return {
                    templateUrl         : 'sentrypoint:edit',
                    controller          : sEntryPoint.Controller.Edit,
                    controllerAs        : '$ctrl',
                    bindToController    : true,
                    clickOutsideToClose : false,
                    parent              : angular.element(Const.PanelAnchor),
                    escapeToClose       : true,
                    onComplete          : function($scope) {
                        $scope.$ctrl.entryPoint.setJSONState();
                    },
                    resolve             : {
                        /**
                         * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
                         * @param {Service.sDomain} sDomainService
                         */
                        tagsEndpoint : function(sAPIAccess, sDomainService) {
                            return sAPIAccess.endpoint('tagging.byDomain.autocomplete.anchor')
                                .get(sDomainService.currentDomainId, 'entryPoint');
                        }
                    }
                };
            },
            methods : ['entryPoint']
        });

        $mdDialogProvider.addPreset('sEntryPointMessengerLinks', {
            options : function () {
                return {
                    templateUrl         : 'sentrypoint:messenger-links',
                    controller          : function ($mdDialog) {
                        this.cancel = function () {
                            $mdDialog.cancel();
                        };
                    },
                    controllerAs        : '$ctrl',
                    bindToController    : true,
                    clickOutsideToClose : true,
                    parent              : angular.element(Const.PanelAnchor),
                    escapeToClose       : true
                };
            },
            methods : ['entryPoint', 'facebookSenders']
        });
    }]);

})(angular);
