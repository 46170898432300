(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sMemberCustomData
     * @constructor
     *
     * @param $scope
     * @param {Service.sMemberAttribute} sMemberAttribute
     */
    var sMemberCustomData = function sMemberCustomData($scope, sMemberAttribute) {
        // has to be null, otherwise isFetched empty array would not trigger the watcher
        var attributes      = null,
            customDataColl  = [],
            isFetched         = false,
            // store subscriptions being processed
            queued          = [],
            self            = this
        ;

        this.$scope = $scope;
        this.$deRegister = [];

        this.sMemberAttribute = sMemberAttribute;

        Object.defineProperties(
            this,
            {
                isFetched: {
                    enumerable: true,
                    get: function () {
                        return isFetched;
                    }
                    /**
                     * @property
                     * @name Controller.Component.sMemberCustomData#isFetched
                     * @type {Boolean}
                     */
                },
                attributes: {
                    enumerable: true,
                    get: function () {
                        return attributes;
                    },
                    set: function (val) {
                        if (val) {
                            Object.instanceOf(val, Array);
                        }

                        if (val === attributes) {
                            return;
                        }
                        attributes = val;
                    }
                    /**
                     * @property
                     * @name Controller.Component.sMemberCustomData#attributes
                     * @type {Array}
                     */
                }
            }
        );

        this.queueChanges = function queueChanges(prevVal) {
            var toAdd = prevVal.concat(queued).diff(attributes),
                toRemove = attributes.diff(prevVal.concat(queued))
            ;

            if (toAdd.length) {
                toAdd.filter(function (element) {
                    var customDataToAdd = new Model.Member.CustomData(null, self.memberId);
                    customDataToAdd.key     = element.key;
                    customDataToAdd.value   = element.value;
                    queued.push(element);
                    customDataToAdd.save().then(function () {
                        customDataColl.push(customDataToAdd);
                        if (queued.indexOf(element) !== -1) {
                            queued.splice(queued.indexOf(element), 1);
                        }
                    });
                });
                self.sMemberAttribute.save();
            }

            if (toRemove.length) {
                var byJSON = customDataColl.map(function (elem) {
                    return JSON.stringify({key: elem.key, value: elem.value});
                });
                toRemove.filter(function (element) {
                    var index = byJSON.indexOf(JSON.stringify({key: element.key, value: element.value})),
                        customDataToDelete = customDataColl[index]
                    ;

                    if (index === -1) {
                        return;
                    }
                    queued.push(element);
                    customDataToDelete.delete().then(function () {
                        customDataColl.splice(index, 1);
                        if (queued.indexOf(element) !== -1) {
                            queued.splice(queued.indexOf(element), 1);
                        }
                    });
                });
            }
        };

        /**
         * @param memberId
         */
        this.fetchCustomDataForMemberId = function fetchCustomDataForMemberId(memberId) {
            $.ajax({
                url: Model.Member.CustomData.endPoint(memberId)
            }).then(function (data) {
                if (!(data instanceof Array)) {
                    attributes = [];
                    return;
                }

                attributes = data.map(function (elem) {
                    var memberCustomData = Model.Member.CustomData.fromJson(JSON.stringify(elem));
                    customDataColl.push(memberCustomData);
                    return memberCustomData;
                });
            }).always(function () {
                isFetched = true;
                digestIfNeeded($scope);
            });
        };
    };

    /**
     * @function
     * @name Controller.Component.sMemberCustomData#$onChanges
     * @param {Object.<String, SimpleChange>} $changes
     */
    sMemberCustomData.prototype.$onChanges = function $onChanges($changes) {
        if ($changes.memberId) {
            this.fetchCustomDataForMemberId($changes.memberId.currentValue);
        }
    };

    sMemberCustomData.prototype.$onInit = function $onInit() {
        var self = this,
            prevVal;

        this.$deRegister.push(
            this.$scope.$watchCollection(
                function() {
                    return self.attributes;
                },
                /**
                 * @param {Array} val
                 */
                function(val) {
                    if (!self.isFetched) {
                        return;
                    }

                    // handle initial value setting
                    if (prevVal === undefined) {
                        prevVal = val.slice();
                        return;
                    }

                    self.queueChanges(prevVal);
                    prevVal = val.slice();
                }
            )
        );
    };

    sMemberCustomData.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    ns.sMemberCustomData = sMemberCustomData;
})(Object.namespace('Controller.Component'));
