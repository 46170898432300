(function (ns) {
    var TYPE_USER_ATTRIBUTE        = 'set_users_attribute',
        TYPE_URL                   = 'url',
        TYPE_SET_USERS_LABEL       = 'set_users_label',
        TYPE_POSTBACK_MESSAGE      = Const.PostbackMessage,
        TYPE_POSTBACK_STORY        = 'postback_story',
        TYPE_ONE_TIME_NOTIFICATION = 'set_one_time_notification_token',
        META_READ_ONLY             = 'readOnly'
    ;

    /**
     * @namespace
     * @alias Model.Action
     * @extends Model.UUID
     * @constructor
     *
     * @param uuid
     */
    var Action = function (uuid) {
        Model.Behavior.Meta.call(this);
        var self = this,
            key,
            value,
            type
        ;

        Object.defineProperties(
            this,
            {
                /**
                 * @name Model.Action#key
                 */
                'key': {
                    enumerable: true,
                    get: function () {
                        return key;
                    },
                    set: function (val) {
                        key = val;
                    }
                },
                /**
                 * @name Model.Action#value
                 */
                'value': {
                    enumerable: true,
                    get: function () {
                        return value;
                    },
                    set: function (val) {
                        value = val;
                    }
                },

                'type': {
                    enumerable: true,
                    get: function () {
                        return type;
                    },
                    set: function (val) {
                        type = val;

                        // default-value for postback-message should be null
                        if (type === Const.PostbackMessage && typeof self.value === 'undefined') {
                            self.value = null;
                        }
                    }
                    /**
                     * @name Model.Action#type
                     * @type string
                     */
                },
                metaInformation: {
                    enumerable: true,
                    get: function () {
                        return this.getMetasJson();
                    }
                    /**
                     * @property
                     * @name Model.Action#metaInformation
                     * @type {Object}
                     */
                }
            }
        );

        this.__getCloneArguments = function () {
            return [this.uuid];
        };

        this.__dontCloneProperties = function () {
            return ['$$hashKey'];
        };

        Action._pProto.constructor.call(this, uuid);
    };

    Object.extendProto(Action, Model.UUID);

    /**
     * @function
     * @name Model.Action#triggersMessage
     * @returns {boolean}
     */
    Action.prototype.triggersMessage = function triggersMessage() {
        return Action.messageTriggerTypes.indexOf(this.type) !== -1 ||
            this.getDefinition().hasTemplate();
    };

    /**
     * @function
     * @name Model.Action#isPostbackMessageAction
     * @returns {boolean}
     */
    Action.prototype.isPostbackMessageAction = function isPostbackMessageAction() {
        return this.type === Const.PostbackMessage;
    };

    Action.prototype.updateByData = function updateByData(data) {
        var self = this;

        $.each(data, function (keyName, keyValue) {
            if (self.hasOwnProperty(keyName)) {
                self[keyName] = keyValue;
            } else if (keyName === 'valueKey') {
                self.key = keyValue;
            }
        });

        if (data.metaInformation) {
            this.updateMetaByData(data.metaInformation);
        }
    };

    /**
     * @function
     * @name Model.Action#repair
     */
    Action.prototype.repair = function repair() {
        // fix set_users_label actions which got broken after converting AI-templates
        if (this.type === Action.TYPE_SET_USERS_LABEL
            && (this.value instanceof String || typeof this.value === 'string')
        ) {
            try {
                var parsedValue = JSON.parse(this.value);
                if (parsedValue instanceof Object && parsedValue.hasOwnProperty('value')) {
                    this.value = [parsedValue.value];
                }
            } catch (error) {
                console.warn('Could not fix action', error);
            }
        }
    };

    /**
     * @returns {Boolean}
     */
    Action.prototype.validate = function validate() {
        if (!Model.Action.Definition.all) {
            return false;
        }
        try {
            var definition = this.getDefinition()
        } catch (err) {
            if (err instanceof Model.Exception.ActionDefinitionNotFound) {
                return false;
            }

            throw err;
        }

        var whiteList = [
            Model.Action.Definition.CONTENT_TYPE_JSON,
            Model.Action.Definition.CONTENT_TYPE_MESSAGE
        ];

        if (!definition.contentType || whiteList.indexOf(definition.contentType) !== -1) {
            return true;
        }

        return this.value != null;
    };

    /**
     * @return {boolean}
     */
    Action.prototype.hasTemplate = function hasTemplate() {
        try {
            var definition = this.getDefinition();
        } catch (err) {
            if (err instanceof Model.Exception.ActionDefinitionNotFound) {
                return false;
            }
            throw err;
        }

        return definition.hasTemplate();
    };

    /**
     * @return {Model.Message.Template[]}
     */
    Action.prototype.getTemplates = function getTemplates() {
        try {
            var definition = this.getDefinition();
        } catch (err) {
            if (err instanceof Model.Exception.ActionDefinitionNotFound) {
                return [];
            }
            throw err;
        }

        return definition.templates;
    };

    /**
     * @return {Model.Action.Definition}
     * @throws Model.Exception.ActionDefinitionNotFound
     */
    Action.prototype.getDefinition = function getDefinition() {
        var definition = Model.Action.Definition.all.findByName(this.type);

        if (!definition) {
            throw new Model.Exception.ActionDefinitionNotFound(this.type);
        }

        return definition;
    };

    Object.defineProperties(
        Action,
        {
            TYPE_USER_ATTRIBUTE: {
                value: TYPE_USER_ATTRIBUTE
                /**
                 * @property
                 * @constant
                 * @name Model.Action#TYPE_USER_ATTRIBUTE
                 * @type {String}
                 */
            },
            TYPE_URL: {
                value: TYPE_URL
                /**
                 * @property
                 * @constant
                 * @name Model.Action#TYPE_URL
                 * @type {String}
                 */
            },
            TYPE_SET_USERS_LABEL: {
                value: TYPE_SET_USERS_LABEL
                /**
                 * @property
                 * @constant
                 * @name Model.Action#TYPE_SET_USERS_LABEL
                 * @type {String}
                 */
            },
            TYPE_POSTBACK_MESSAGE: {
                value: TYPE_POSTBACK_MESSAGE
                /**
                 * @property
                 * @constant
                 * @name Model.Action#TYPE_POSTBACK_MESSAGE
                 * @type {String}
                 */
            },
            TYPE_POSTBACK_STORY: {
                value: TYPE_POSTBACK_STORY
                /**
                 * @property
                 * @constant
                 * @name Model.Action#TYPE_POSTBACK_STORY
                 * @type {String}
                 */
            },
            TYPE_ONE_TIME_NOTIFICATION: {
                value: TYPE_ONE_TIME_NOTIFICATION
                /**
                 * @property
                 * @constant
                 * @name Model.Action#TYPE_ONE_TIME_NOTIFICATION
                 * @type {String}
                 */
            },
            META_READ_ONLY: {
                value: META_READ_ONLY
                /**
                 * @property
                 * @constant
                 * @name Model.Action#META_READ_ONLY
                 * @type {String}
                 */
            },
            messageTriggerTypes: {
                enumerable: true,
                get: function () {
                    return [
                        Action.TYPE_POSTBACK_MESSAGE,
                        Action.TYPE_POSTBACK_STORY
                    ];
                }
                /**
                 * @property
                 * @name Model.Action#messageTriggeringTypes
                 * @type {Array}
                 */
            }
        });

    ns.Action = Action;
})(Object.namespace('Model'));
