(function (ns) {
    /**
     * @namespace
     * @alias sEntity.Controller.Overview
     * @param $mdDialog
     * @param Notification
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     */
    var Overview = function ($mdDialog, Notification, sAPIAccess) {
        this.$mdDialog    = $mdDialog;
        this.Notification = Notification;
        this.sAPIAccess   = sAPIAccess;
        this.list         = this.getList();
    };

    /**
     * @function
     * @name sEntity.Controller.Overview#getList
     * @returns {Model.List}
     */
    Overview.prototype.getList = function getList() {
        var list = new Model.List.RESTAccess(
            Model.AI.Entity,
            this.sAPIAccess.endpoint('entity.management').get()
            ),
            column
        ;

        column            = list.columns.createAndAdd('name');
        column.isSortable = true;
        column.label      = 'Name';
        column            = list.columns.createAndAdd('type');
        column.label      = 'Type';
        column.isSortable = true;
        column            = list.columns.createAndAdd('matching');
        column.label      = 'Matching';

        list.actions = {};

        if (this.sAPIAccess.isAllowed('entity.RESTAccess', Const.Method.PUT)) {
            list.actions['edit'] = new Model.Menu.Action('edit', this.edit.bind(this));
        }

        if (Object.keys(list.actions).length) {
            column       = list.columns.createAndAdd('options');
            column.label = 'Options';
        }

        return list;
    };

    /**
     * @function
     * @name sEntity.Controller.Overview#edit
     * @param event
     * @param {Model.AI.Entity} entity
     * @param {Number=} index
     * @return {$.Deferred<Model.AI.Entity>}
     */
    Overview.prototype.edit = function (event, entity, index) {
        return this.$mdDialog.show(
            this.$mdDialog.sEntityEdit()
                .openFrom(event.currentTarget)
                .closeTo(event.currentTarget)
                .entity(entity ? entity : new Model.AI.Entity())
        ).then(
            function () {
                this.Notification.success('Entity was successfully saved.');
                this.list.load(false, true);
            }.bind(this),
            function () {
                if (entity && !entity.isNew && entity.isModified()) {
                    this.list.load(false, true);
                }
            }.bind(this)
        );
    };

    /**
     * @param {Model.AI.Entity} entity
     *
     * @return {String}
     */
    Overview.prototype.entityUnitToString = function unitToString(entity) {
        if (entity.type !== Model.AI.Entity.TYPE_KEY_FREE_TEXT || !entity.typeOptions.unit) {
            return '';
        }

        if (entity.typeOptions.length > 1) {
            return entity.typeOptions.unit;
        }

        switch (entity.typeOptions.unit) {
            case Model.AI.Entity.LENGTH_UNIT_WORDS:
                return 'word';
            case Model.AI.Entity.LENGTH_UNIT_DIGITS:
                return 'digit';
            default:
                return entity.typeOptions.unit;
        }
    };

    ns.Overview = Overview;
})(Object.namespace('sEntity.Controller'));
