(function(ns) {
    var pageKey                      = 'page',
        recordsPerPageKey            = 'rpp',
        orderByKey                   = 'orderBy',
        EVENT_CLEAR_SELECTION        = 'sEventClearSelection',
        EVENT_TOGGLE_SELECTION       = 'sEventListToggleSelection'
    ;

    /**
     * @namespace
     * @alias sList.Component.Controller.sList
     *
     * @param $rootScope
     * @param $scope
     * @param $location
     * @param $element
     * @param $attrs
     * @param $templateCache
     */
    var sList = function ($rootScope, $scope, $location, $element, $attrs, $templateCache) {
        this.$rootScope     = $rootScope;
        this.$scope         = $scope;
        this.$deRegister    = [];
        this.$location      = $location;
        this.$element       = $element;
        this.$templateCache = $templateCache;
        this.selectedRows   = [];
        this.routeChanging  = false;

        /**
         * @name sList.Component.Controller.sList#model
         * @type Model.List
         * @property
         */

        if (this.$templateCache.get('s-list-cell-' + $attrs.randomId)) {
            this.cellTemplate = 's-list-cell-' + $attrs.randomId;
        }

        if (this.$templateCache.get('s-list-batch-' + $attrs.randomId)) {
            this.batchTemplate = 's-list-batch-' + $attrs.randomId;
        }

        if (this.$templateCache.get('s-pre-row-' + $attrs.randomId)) {
            this.preRowTemplate = 's-pre-row-' + $attrs.randomId;
        }

        /**
         * @name sList.Component.Controller.sList#selectedRows
         * @type Array
         */

        /**
         * @name sList.Component.Controller.sList#trackBy
         * @type String
         */

        /**
         * @name sList.Component.Controller.sList#allowSelection
         * @type Boolean
         */

        /**
         * @name sList.Component.Controller.sList#rowClass
         * @method
         * @returns string
         */

        /**
         * @name sList.Component.Controller.sList#queryPrefix
         * @type String
         */

        /**
         * @name sList.Component.Controller.sList#noResultsTemplate
         * @type String
         */
    };

    sList.prototype.$onInit = function $onInit() {
        var self = this,
            updating
        ;

        this.noResultsTemplate = this.noResultsTemplate || 'slist/components:s-no-results';

        this.$deRegister.push(this.$rootScope.$on('$locationChangeSuccess', this.handleLocationChange.bind(this)));
        this.$deRegister.push(this.$rootScope.$on('$routeChangeStart', this.handleRouteChangeStart.bind(this)));
        this.$deRegister.push(this.$rootScope.$on(EVENT_TOGGLE_SELECTION, function(event, record) { self.toggleSelection(record) }));
        this.$deRegister.push(
            this.$scope.$watch(
                function () {
                    return JSON.stringify(self.model.filters)
                        + JSON.stringify(self.model.orderBy)
                        + self.model.pager.currentPage
                        + self.model.pager.recordsPerPage;
                },
                function () {
                    self.model.load();
                }
            )
        );

        this.$deRegister.push(this.$scope.$watch(function () {
                return self.model.loading;
            },
            /**
             * @param {Object} val
             */
            function (val) {
                if (val === null || !val.then || val.state() !== 'pending') {
                    return;
                }
                updating = true;
                val.then(function () {
                    self.updateLocation();
                    self.$scope.$evalAsync();
                });
            }));

        this.$deRegister.push(this.$scope.$on(EVENT_CLEAR_SELECTION, this.clearSelection.bind(this)));

        // update parameters from URL on page load ($locationChangeSuccess is not triggered on page load)
        this.updateFromLocation();
    };

    sList.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }

        this.$templateCache.remove(this.cellTemplate);
        this.$templateCache.remove(this.batchTemplate);
        this.$templateCache.remove(this.preRowTemplate);
    };

    /**
     * @name sList.Component.Controller.sList#$onChanges
     * @param changes
     */
    sList.prototype.$onChanges = function $onChanges(changes) {
        if (changes.model && !changes.model.isFirstChange()) {
            if (!(changes.model.currentValue instanceof Model.List)) {
                throw 'Invalid model!';
            }

            this.updateFromLocation();
            this.selectedRows.splice(0, this.selectedRows.length);
            this.model.load();
        }

        if (changes.allowSelection) {
            this.renderCheckboxes = Boolean(changes.allowSelection.currentValue)
        }
    };

    sList.prototype.handleLocationChange = function handleLocationChange() {
        if (!this.routeChanging && this.updateFromLocation()) {
            this.model.load();
        }
    };

    /**
     * @function
     * @name sList.Component.Controller.sList#updateFromLocation
     * @return {Boolean}
     */
    sList.prototype.updateFromLocation = function updateFromLocation() {
        var changes                     = false,
            $search                     = this.$location.search(),
            queryPrefix                 = queryPrefix || '',
            namespacedPageKey           = queryPrefix + pageKey,
            namespacedRecordsPerPageKey = queryPrefix + recordsPerPageKey,
            namespacedOrderByKey        = queryPrefix + orderByKey,
            i
        ;

        if (($search[namespacedPageKey] && this.model.pager.currentPage !== $search[namespacedPageKey]) || this.model.pager.currentPage !== 1) {
            this.model.pager.currentPage = $search[namespacedPageKey] || 1;
            changes = true;
        }

        if ($search[namespacedRecordsPerPageKey] && this.model.pager.recordsPerPage !== $search[namespacedRecordsPerPageKey]) {
            this.model.pager.recordsPerPage = $search[namespacedRecordsPerPageKey];
            changes = true;
        }

        var sortableColumns = this.model.columns.columns.filter(function (element) {
            return element.isSortable;
        });

        var orderByStrings = [],
            normedStrings = []
        ;

        if ($search[namespacedOrderByKey]) {
            orderByStrings = $search[namespacedOrderByKey].split(',');
            normedStrings = orderByStrings.map(function (element) {
                return element.toString().replace(/^-/, '');
            });
        }

        for (i = 0; i < sortableColumns.length; i++) {
            var columnName = sortableColumns[i].name,
                index
            ;

            if (normedStrings.indexOf(columnName) === -1 && this.model.orderBy[columnName]) {
                this.model.removeOrderBy(columnName);
                changes = true;
                continue;
            }

            if ((index = normedStrings.indexOf(columnName)) !== -1 && !this.model.orderBy[columnName]) {
                this.model.addOrderBy(columnName, orderByStrings[index].search('-') === 0 ? 'DESC' : 'ASC');
                changes = true;
            }
        }

        return changes;
    };

    /**
     * @name sList.Component.Controller.sList#toggleSelection
     * @param record
     */
    sList.prototype.toggleSelection = function toggleSelection(record) {
        var mapFn,
            propertyName = this.trackBy
            ;

        if (!record) {
            if (propertyName) {
                mapFn = function(element) {
                    return element[propertyName];
                }
            }
            if (!this.isSelected()) {
                this.selectedRows = this.selectedRows.concat(this.model.records).unique(mapFn);
            } else {
                this.selectedRows = this.model.records.diff(this.selectedRows, mapFn);
            }
            return;
        }

        var index = this.getSelectionIndex(record);
        if (index === -1) {
            this.selectedRows.push(record);
        } else {
            this.selectedRows.splice(index, 1);
        }
    };

    /**
     * @param {Object=} record
     * @returns {boolean}
     */
    sList.prototype.isSelected = function isSelected(record) {
        var mapFn,
            propertyName = this.trackBy
        ;

        if (!record) {
            if (propertyName) {
                mapFn = function (element) {
                    return element[propertyName];
                };
            }

            var duplicates = this.selectedRows.intersect(this.model.records, mapFn);
            return duplicates.length === this.model.records.length;
        }

        return this.getSelectionIndex(record) !== -1;
    };

    /**
     * @param {Object} record
     * @returns {*}
     */
    sList.prototype.getSelectionIndex = function getSelectionIndex(record) {
        var propertyName = this.trackBy;

        if (!propertyName) {
            return this.selectedRows.indexOf(record);
        }
        return this.selectedRows.map(function (element) {
            return element[propertyName];
        }).indexOf(record[propertyName]);
    };

    /**
     * @param {String} name
     * @param {String} value
     * @param {*=} defaultValue
     */
    sList.prototype.filterByColumn = function filterByColumn(name, value, defaultValue) {
        var filter  = this.model.filters.getOrCreateByName(name);

        filter.value = filter.value || defaultValue || '';

        if (filter.value instanceof Array && !(value instanceof Array)) {
            if (filter.value.indexOf(value) !== -1) {
                return;
            }
            filter.value.push(value);
        } else {
            filter.value = value;
        }
    };

    /**
     * @param {Model.List.Column} column
     */
    sList.prototype.orderByColumn = function orderByColumn(column) {
        if (!column.isSortable) {
            return;
        }

        var direction = column.defaultOrder || 'ASC';

        if (this.model.orderBy[column.name]) {
            direction = this.model.orderBy[column.name] === 'ASC' ? 'DESC' : 'ASC';
        }
        this.model.resetOrderBy();
        this.model.pager.currentPage = 1;
        this.model.addOrderBy(column.name, direction);
    };

    sList.prototype.updateLocation = function updateLocation() {
        var queryPrefix                 = queryPrefix || '',
            namespacedPageKey           = queryPrefix + pageKey,
            namespacedRecordsPerPageKey = queryPrefix + recordsPerPageKey,
            namespacedOrderByKey        = queryPrefix + orderByKey
        ;

        if (!$.isEmptyObject(this.model.orderBy)) {
            for (var i in this.model.orderBy) {
                this.$location.search(namespacedOrderByKey, (this.model.orderBy[i] === 'DESC' ? '-' : '') + i);
            }
        }

        if (this.model.pager.currentPage > 1) {
            this.$location.search(namespacedPageKey, this.model.pager.currentPage);
        } else if (this.$location.search()[namespacedPageKey]) {
            this.$location.search(namespacedPageKey, undefined);
        }

        if (this.model.pager.recordsPerPage !== this.model.pager.defaultRecordsPerPage) {
            this.$location.search(namespacedRecordsPerPageKey, this.model.pager.recordsPerPage);
        } else if (this.$location.search()[namespacedRecordsPerPageKey]) {
            this.$location.search(namespacedRecordsPerPageKey, undefined);
        }
    };

    sList.prototype.handleRouteChangeStart = function handleRouteChangeStart() {
        this.routeChanging = true;
    };

    /**
     * @name sList.Component.Controller.sList#isNotAllSelected
     * @returns {Boolean}
     */
    sList.prototype.isNotAllSelected = function isNotAllSelected() {
        var elements = this.model.records;
        if (!this.selectedRows.length) {
            return false;
        }

        return !this.isSelected() && elements.length;
    };

    /**
     * @param {Number} from
     * @param {Number} to
     * @param {Boolean=} partially
     * @return {Boolean}
     */
    sList.prototype.isRangeSelected = function isRangeSelected(from, to, partially) {
        if (to < from) {
            var _tmp = to;
            to = from;
            from = _tmp;
        }

        var areAllSelected  = true,
            numSelected = 0
        ;

        for (var i = from;  i < to + 1; i++) {
            var isSelected = this.isSelected(this.model.records[i]);
            areAllSelected = areAllSelected && isSelected;
            if (isSelected) {
                numSelected++;
                numSelected++;
            }
        }

        return !!((areAllSelected && !partially) || (partially && !areAllSelected && numSelected));
    };

    /**
     * @param {Number} from
     * @param {Number} to
     */
    sList.prototype.toggleRangeSelection = function toggleRangeSelection(from, to) {
        var isRangeSelected = this.isRangeSelected(from, to);

        if (to < from) {
            var _tmp = to;
            to = from;
            from = _tmp;
        }

        for (var i = from;  i < to + 1; i++) {
            if (isRangeSelected == this.isSelected(this.model.records[i])) {
                this.toggleSelection(this.model.records[i]);
            }
        }
    };

    sList.prototype.clearSelection = function clearSelection() {
        this.selectedRows = [];
    };

    sList.prototype.isMultiPageSelection = function isMultiPageSelection() {
        var mapFn,
            propertyName = this.trackBy
            ;

        if (propertyName) {
            mapFn = function(element) {
                return element[propertyName];
            }
        }

        return this.model.records.diff(this.selectedRows, mapFn).length !== 0;
    };

    ns.sList = sList;

    Object.defineProperties(
        sList,
        {
            EVENT_CLEAR_SELECTION: {
                value: EVENT_CLEAR_SELECTION
                /**
                 * @property
                 * @constant
                 * @name sList.Component.Controller.sList#EVENT_CLEAR_SELECTION
                 * @type {String}
                 */
            },
            EVENT_TOGGLE_SELECTION: {
                value: EVENT_TOGGLE_SELECTION
                /**
                 * @property
                 * @constant
                 * @name sList.Component.Controller.sList#EVENT_TOGGLE_SELECTION
                 * @type {String}
                 */
            }
        });
})(Object.namespace('sList.Component.Controller'));
