(function (ns) {
    /**
     * @namespace
     * @alias sAnalytics.Route.Retention
     * @extends sRoute
     * @constructor
     */
    var Retention = function Retention() {
        this.controller     = sAnalytics.Controller.Retention;
        this.controllerAs   = '$ctrl';
        this.templateUrl    = 'sanalytics:retention';
        this.reloadOnSearch = false;
        this.accessControl  = {'analytics.byDomain.chartData': Const.Method.GET};

        Retention._pProto.constructor.call(this);
    };

    Object.extendProto(Retention, sRoute);

    /**
     * @name sAnalytics.Route.Retention#resolveCohort
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {Service.sDomain} sDomainService
     * @returns {{bag: Model.Analytics.StatisticsBag, type: string, template: string, title: string}}
     */
    Retention.prototype.resolveCohort = function resolveCohort(sAPIAccess, sDomainService) {
        var sBag = new Model.Analytics.StatisticsBag(sAPIAccess.endpoint('analytics.byDomain.chartData').get(sDomainService.currentDomainId, 'users'));
        sBag.filters.createAndAdd('categories', ['retention'], true);
        return {
            bag             : sBag,
            traceFactory    : 'cohort',
            template        : ''
        };
    };

    ns.Retention = Retention;
})(Object.namespace('sAnalytics.Route'));