(function (ns) {
    var SEGMENT_EVENT_POSTFIX            = ' s_app',
        APP_NAME                         = 'sFollowPubNG',
        requiredKeys                     = {},
        // Tracked entity names
        ENTITY_AI_TEMPLATE               = 'AI_Template',
        ENTITY_AUTOMATION                = 'Automation',
        ENTITY_CONVERSATION              = 'Conversation',
        ENTITY_CONTENT_SOURCE            = 'Content_Source',
        ENTITY_ENTITY                    = 'Entity',
        ENTITY_ENTRY_POINT               = 'EntryPoint',
        ENTITY_INVITE                    = 'Invite',
        ENTITY_SIDEBAR                   = 'Sidebar',
        ENTITY_CONVERSATION_GOAL         = 'Conversation_Goal',
        ENTITY_CONVERSATION_GOAL_POPUP   = 'Conversation_Goal_Popup',
        ENTITY_CONVERSATION_ANALYTICS    = 'Conversation_Analytics',
        ENTITY_SEGMENT                   = 'Segment',
        ENTITY_USER                      = 'User',
        ENTITY_SENDER                    = 'Sender',
        // Tracked actions names
        ACTION_SAVED                     = 'saved',
        ACTION_ACTIVATED                 = 'activated',
        ACTION_DEACTIVATED               = 'deactivated',
        ACTION_EDITED                    = 'edited',
        ACTION_REMOVED                   = 'removed',
        ACTION_OPENED                    = 'opened',
        ACTION_CLOSED                    = 'closed',
        ACTION_ATTACHED                  = 'attached',
        ACTION_DETACHED                  = 'detached',
        ACTION_STATUS_CHANGED            = 'status changed',
        ACTION_SENT                      = 'sent',
        ACTION_LOGOUT                    = 'logged out',
        ACTION_CONNECTED                 = 'connected'
    ;

    if(
        window && window.frameElement
        && window.frameElement.classList
        && typeof window.frameElement.classList.contains === 'function'
        && window.frameElement.classList.contains('s-pub-ng-iframe')
    ) {
        APP_NAME += ' - iframe';
    }

    // Define required keys for action
    requiredKeys[ENTITY_CONVERSATION + ACTION_SAVED] = ['success'];
    requiredKeys[ENTITY_AI_TEMPLATE + ACTION_SAVED]  = ['success'];

    /**
     * @namespace
     * @alias Service.sAnalyticsTracker
     *
     * @param $rootScope
     * @param segment
     * @param {Service.sMarkerRepository} sMarkerRepository
     * @param {Service.sDomain} sDomainService
     * @param {sSecurity.Service.sAuth} sAuth
     * @constructor
     */
    var sAnalyticsTracker = function ($rootScope, segment, sMarkerRepository, sDomainService, sAuth) {
        this.segment  = segment;
        this.sMarkerRepository  = sMarkerRepository;
        this.sDomainService  = sDomainService;
        this.sAuth = sAuth;

        this.checkRequiredKeys = function (key, data) {
            if (!requiredKeys[key]) {
                return;
            }

            for (var i = 0; i < requiredKeys[key].length; i++) {
                if (!data.hasOwnProperty(requiredKeys[key][i])) {
                    console.warn('Analytics data for ' + key + ' are missing required key "' + requiredKeys[key][i] + '".');
                }
            }
        };

        this.setUpEventListeners($rootScope);
    };

    /**
     * Use this function to log occurrence of one-time event like click, opening of a panel and so on
     *
     * @function
     * @name Service.sAnalyticsTracker#logEvent
     * @param {String} entity
     * @param {String} action
     * @param {Object=} data
     * @param {Number=} duration
     */
    sAnalyticsTracker.prototype.logEvent = function logEvent(entity, action, data, duration) {
        entity = entity || 'undefined';
        action = action || 'undefined';

        this.checkRequiredKeys(entity + action, data);

        var payload = {
            action   : entity + ' ' + action,
            URL      : window.location.href,
            app      : APP_NAME,
            domainId : this.sDomainService.currentDomainId,
            userId   : this.sAuth.user.uuid,
            data     : data || {}
        };

        if (duration) {
            payload.duration = duration;
        }

        this.segment.track(payload.action + SEGMENT_EVENT_POSTFIX, payload);
    };

    /**
     * @function
     * @name Service.sAnalyticsTracker#setUpEventListeners
     * @param $rootScope
     */
    sAnalyticsTracker.prototype.setUpEventListeners = function setUpEventListeners($rootScope) {
        var handleMarkerModelChange = function (orginalEvent, $event, newVal, oldVal) {
                if (newVal === oldVal) {
                    return;
                }

                var uuid = newVal || oldVal,
                    index = this.sMarkerRepository.getIndex({uuid: uuid}),
                    marker = this.sMarkerRepository.items[index],
                    data = {
                        conversationId: $event.conversationId,
                        goalId: uuid,
                        goalName: marker.name
                    };

                if (!newVal && oldVal) {
                    this.logEvent(ENTITY_CONVERSATION_GOAL, ACTION_REMOVED, data);
                    return;
                }

                if (newVal && !oldVal) {
                    data.editType = 'created';
                    this.logEvent(ENTITY_CONVERSATION_GOAL, ACTION_EDITED, data);
                    return;
                }

                data.editType = 'updated';
                this.logEvent(ENTITY_CONVERSATION_GOAL, ACTION_EDITED, data);
            },
            handleMarkerSaved = function ($event, marker) {
                this.logEvent(
                    ENTITY_CONVERSATION_GOAL,
                    ACTION_SAVED,
                    {
                        goalId: marker.uuid,
                        goalName: marker.name
                    }
                );
            },
            handleConversationSaved = function ($event, startTime, anchorType, data) {
                if(!startTime || !anchorType) {
                    return;
                }

                this.logEvent(
                    anchorType === Model.Message.TYPE_ALIAS_REACTION ? ENTITY_AI_TEMPLATE : ENTITY_CONVERSATION,
                    ACTION_SAVED,
                    data,
                    ((new Date()).getTime() - startTime)
                );
            },
            handleConversationSent = function ($event, data) {
                this.logEvent(
                    ENTITY_CONVERSATION,
                    ACTION_SENT,
                    data
                );
            },
            handleConversationActivated = function ($event, startTime, anchorType, data) {
                if(!startTime || !anchorType) {
                    return;
                }

                this.logEvent(
                    anchorType === Model.Message.TYPE_ALIAS_REACTION ? ENTITY_AI_TEMPLATE : ENTITY_CONVERSATION,
                    ACTION_ACTIVATED,
                    data,
                    ((new Date()).getTime() - startTime)
                );
            },
            handleEntryPointSaved = function ($event, entryPoint) {
                this.logEvent(
                    ENTITY_ENTRY_POINT,
                    ACTION_SAVED,
                    {
                        status: entryPoint.status,
                        tags: entryPoint.tags,
                        title: entryPoint.title,
                        payload: entryPoint.payload
                    }
                );
            },
            handleConversationAnalyticsOpened = function ($event, conversationUuid, version) {
                var label = 'Version ' + (version.version + 1) + ' - ' + moment(version.time).format('DD.MM.YYYY HH:mm');

                this.logEvent(
                    ENTITY_CONVERSATION_ANALYTICS,
                    ACTION_OPENED,
                    {
                        conversationId: conversationUuid,
                        version: label
                    }
                );
            },
            handleContentSourceSaved = function ($event, source) {
                this.logEvent(
                    ENTITY_CONTENT_SOURCE,
                    ACTION_SAVED,
                    {
                        title: source.label,
                        source: source.url,
                        type: source.type.name
                    }
                );
            },
            handleContentSourceStatusChanged = function ($event, source) {
                this.logEvent(
                    ENTITY_CONTENT_SOURCE,
                    ACTION_STATUS_CHANGED,
                    {
                        title: source.label,
                        url: source.url,
                    }
                );
            },
            handleSegmentSaved = function ($event, segment) {
                this.logEvent(
                    ENTITY_SEGMENT,
                    ACTION_SAVED,
                    {
                        title: segment.name,
                        status: segment.status,
                        type: segment.type
                    }
                );
            },
            handleSegmentRemoved= function ($event, segment) {
                this.logEvent(
                    ENTITY_SEGMENT,
                    ACTION_REMOVED,
                    {
                        title: segment.name,
                        status: segment.status,
                        type: segment.type
                    }
                );
            },
            handleSegmentStatusChanged = function ($event, data) {
                this.logEvent(
                    ENTITY_SEGMENT,
                    ACTION_STATUS_CHANGED,
                    data
                );
            },
            handleAutomationSaved = function ($event, automation) {
                this.logEvent(
                    ENTITY_AUTOMATION,
                    ACTION_SAVED,
                    {
                        title: automation.name,
                        status: automation.status,
                        type: automation.type
                    }
                );
            },
            handleAutomationStatusChanged = function ($event, automation) {
                this.logEvent(
                    ENTITY_AUTOMATION,
                    ACTION_STATUS_CHANGED,
                    {
                        title: automation.name,
                        status: automation.status,
                        type: automation.type
                    }
                );
            },
            handleUserLogout = function ($event, userUuid) {
                this.logEvent(
                    ENTITY_USER,
                    ACTION_LOGOUT,
                    {
                        userId: userUuid
                    }
                );
            },
            handleEntitySaved = function ($event, entity) {
                this.logEvent(
                    ENTITY_ENTITY,
                    ACTION_SAVED,
                    {
                        title: entity.name,
                        type: entity.type
                    }
                );
            },
            handleSenderRemoved = function ($event, sender) {
                this.logEvent(
                    ENTITY_SENDER,
                    ACTION_REMOVED,
                    {
                        type: sender.type
                    }
                );
            },
            handleSenderDeactivated = function ($event, sender) {
                this.logEvent(
                    ENTITY_SENDER,
                    ACTION_DEACTIVATED,
                    {
                        type: sender.type
                    }
                );
            },
            handleSenderConnected = function ($event, data) {
                this.logEvent(
                    ENTITY_SENDER,
                    ACTION_CONNECTED,
                    data
                );
            },
            handleInviteSent = function ($event, user) {
                this.logEvent(
                    ENTITY_INVITE,
                    ACTION_SENT,
                    {
                        inviteeUuid: user.uuid,
                        inviteeEmail: user.email
                    }
                );
            }
        ;

        $rootScope.$on('sMessageAdminMarkerChanged', handleMarkerModelChange.bind(this));
        $rootScope.$on(Controller.Component.sMessageCollectionActionBox.EVENT_SAVING_FINISHED, handleConversationSaved.bind(this));
        $rootScope.$on(Controller.Component.sMessageCollectionActionBox.EVENT_ACTIVATION_FINISHED, handleConversationActivated.bind(this));
        $rootScope.$on('sConversationSent', handleConversationSent.bind(this));
        $rootScope.$on('sContentSourceSaved', handleContentSourceSaved.bind(this));
        $rootScope.$on('sInviteSent', handleInviteSent.bind(this));
        $rootScope.$on('sContentSourceStatusChanged', handleContentSourceStatusChanged.bind(this));
        $rootScope.$on('sEntryPointSaved', handleEntryPointSaved.bind(this));
        $rootScope.$on('eventConversationAnalyticsOpened', handleConversationAnalyticsOpened.bind(this));
        $rootScope.$on('sSegmentSaved', handleSegmentSaved.bind(this));
        $rootScope.$on('sSegmentStatusChanged', handleSegmentStatusChanged.bind(this));
        $rootScope.$on('sSegmentRemoved', handleSegmentRemoved.bind(this));
        $rootScope.$on('sAutomationSaved', handleAutomationSaved.bind(this));
        $rootScope.$on('sAutomationStatusChanged', handleAutomationStatusChanged.bind(this));
        $rootScope.$on('sAuthUserLogout', handleUserLogout.bind(this));
        $rootScope.$on('sEntitySaved', handleEntitySaved.bind(this));
        $rootScope.$on('sSenderConnected', handleSenderConnected.bind(this));
        $rootScope.$on('sSenderDeactivated', handleSenderDeactivated.bind(this));
        $rootScope.$on('sSenderRemoved', handleSenderRemoved.bind(this));
        $(document).on('eventMarkerSaved', handleMarkerSaved.bind(this));
    };

    sAnalyticsTracker.APP_NAME = APP_NAME;

    ns.sAnalyticsTracker = sAnalyticsTracker;
})(Object.namespace('Service'));
