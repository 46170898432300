(function (ns) {
    var DELAY_TYPE_IMMEDIATELY  = 'immediately',
        DELAY_TYPE_AFTER        = 'after',
        delayOptions = [
        {
            label: 'immediately',
            value: DELAY_TYPE_IMMEDIATELY
        },
        {
            label: 'after',
            value: DELAY_TYPE_AFTER
        }
    ];
    /**
     * @namespace
     * @alias sAutomation.Component.Controller.sAutomationStep
     *
     *
     * @param {Service.sDomain} sDomainService
     * @param $scope
     * s
     * @constructor
     */
    var sAutomationStep = function (sDomainService, $scope) {
        this.exceptionEnabled   = false;
        this.segmentEnabled     = false;

        this.exceptionEvent     = new Model.Event();
        this.segment            = null;

        this.sDomainService     = sDomainService;

        this.$scope             = $scope;
        this.$deRegister        = [];
        this.delayOptions       = delayOptions;
        this.delay              = new Model.Delay();

        /**
         * @name sAutomation.Component.Controller.sAutomationStep#model
         * @type {Model.Automation.Step}
         */

        /**
         * @name sAutomation.Component.Controller.sAutomationStep#event
         * @type {?Model.Event}
         */
    };

    /**
     * @function
     * @name sAutomation.Component.Controller.sAutomationStep#toggleSegment
     */
    sAutomationStep.prototype.toggleSegment = function toggleSegment() {
        this.segmentEnabled = !this.segmentEnabled;

        if (!this.segmentEnabled) {
            this.model.segment = null;
        }
    };

    /**
     * @function
     * @name sAutomation.Component.Controller.sAutomationStep#toggleException
     */
    sAutomationStep.prototype.toggleException = function toggleException() {
        this.exceptionEnabled = !this.exceptionEnabled;
        if (this.exceptionEnabled) {
            this.model.exceptionEvents.addEvent(this.exceptionEvent);
        } else {
            this.model.exceptionEvents.reset();
        }
    };

    /**
     * @function
     * @name sAutomation.Component.Controller.sAutomationStep#$onInit
     */
    sAutomationStep.prototype.$onInit = function $onInit() {
        var self = this;

        this.$deRegister.push(this.$scope.$watch(
            function() {
                return self.model.delay;
            },
            function(val) {
                if (val) {
                    self.delayType = DELAY_TYPE_AFTER;
                    self.delay = val;
                } else {
                    self.delayType = DELAY_TYPE_IMMEDIATELY;
                }
            }
        ));

        this.$deRegister.push(this.$scope.$watchCollection(
            function() {
                return self.model.exceptionEvents.events
            },
            /** @type {Array} */
            function(val) {
                if (!(val instanceof Array) || Boolean(val.length) === self.exceptionEnabled) {
                    return;
                }

                if (val.length) {
                    self.exceptionEvent = val.slice(0,1).shift();
                    self.exceptionEnabled = true;
                } else {
                    self.exceptionEnabled = false;
                }
            }
        ));

        this.$deRegister.push(this.$scope.$watch(
            function() {
                return self.model.segment;
            },
            /** @type {Array} */
            function(val) {
                if (!val && self.segment === val) {
                    return;
                }
                if (val) {
                    self.segment = self.model.segment;
                    self.segmentEnabled = true;
                } else {
                    self.segmentEnabled = false;
                }
            }
        ));

        this.$deRegister.push(this.$scope.$watch(
            function() {
                return self.delayType;
            },
            function(val) {
                if (val !== DELAY_TYPE_IMMEDIATELY) {
                    self.model.delay = self.delay;
                }
                else {
                    self.model.delay = null;
                }
            }
        ));

        // we need to watch as the segment might change, yet we need to keep the assignment
        this.$deRegister.push(this.$scope.$watch(
            function() {
                return self.segment;
            },
            function(val) {
                if (self.segmentEnabled) {
                    self.model.segment = val;
                }
            }
        ));

        if (!this.delayType) {
            this.delayType = this.model.delay ? DELAY_TYPE_AFTER : this.delayOptions.slice().shift().value;
        }
    };

    /**
     * @function
     * @name sAutomation.Component.Controller.sAutomationStep#$onDestroy
     */
    sAutomationStep.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    }; 

    ns.sAutomationStep = sAutomationStep;
})(Object.namespace('sAutomation.Component.Controller'));
