(function(angular) {

    var module = angular.module('sListModule');

    module.directive('sListCellExpose', function() {
        return {
            restrict: 'A',
            require: ['^sList'],
            controller  : function ($scope) {
                var $deRegister = [];

                $deRegister.push($scope.$watch(function() {
                        return $scope.$row;
                    }, function(val) {
                        if (!val) {
                            return;
                        }
                        $scope.$key  = this.key;
                        $scope.$cell = val[$scope.$key];
                    }.bind(this)));

                $scope.$on('$destroy', function () {
                    var $destroyFn;
                    while (($destroyFn = $deRegister.pop())) {
                        $destroyFn();
                    }
                });

            },
            bindToController: {
                key      : '<sListCellExpose'
            }
        }
    });


})(angular);
