(function (ns) {
    /**
     * @namespace
     * @alias sSettings.Route.FeatureManagement
     * @extends sSettings.Route.Base
     * @constructor
     */
    var FeatureManagement = function FeatureManagement() {
        this.controller     = sFeatureManagement.Controller.FeatureList;
        this.templateUrl    = 'sfeaturemanagement:feature-list';
        this.controllerAs   = '$ctrl';
        this.accessControl  = {'domain.feature.list': Const.Method.GET};

        FeatureManagement._pProto.constructor.apply(this, arguments);
    };

    Object.extendProto(FeatureManagement, ns.Base);

    /**
     * @name sSettings.Route.FeatureManagement#resolveFeatures
     * @return {$.Deferred}
     */
    FeatureManagement.prototype.resolveFeatures = function resolveFeatures() {
        return Model.Feature.query();
    };

    /**
     * @function
     * @name sSettings.Route.FeatureManagement#resolveDomain
     * @param {Service.sDomain} sDomainService
     * @returns {$.Deferred}
     */
    FeatureManagement.prototype.resolveDomain = function resolveDomain(sDomainService) {
        return sDomainService.getCurrentDomain().then(function(currDomain) {
            return sDomainService.getDomainById(currDomain.id, true);
        });
    };

    ns.FeatureManagement = FeatureManagement;
})(Object.namespace('sSettings.Route'));