(function (ns) {
    /**
     * @namespace
     * @alias Model.Segment.Condition.Collection
     *
     * @constructor
     * @extends Model.Condition.Collection
     */
    var Collection = function () {
        Collection._pProto.constructor.call(this, Model.Segment.Condition);
    };

    Object.extendProto(Collection, Model.Condition.Collection);

    ns.Collection = Collection;
})(Object.namespace('Model.Segment.Condition'));