(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sReaction
     * @constructor
     *
     * @param $scope
     */
    var sReaction = function sReaction($scope) {
        this.$scope         = $scope;
        this.$deRegister    = [];
        this.revertValue    = null;

        /**
         * @property
         * @name Controller.Component.sReaction#$collCtrl
         * @type {Object}
         */
    };

    /**
     * @function
     * @name Controller.Component.sReaction#$onInit
     */
    sReaction.prototype.$onInit = function $onInit() {
        var self = this;

        this.$deRegister.push(this.$scope.$watch(
            function () {
                return JSON.stringify({'type': self.model.type, 'value': self.model.value});
            },
            function (val, prevVal) {
                if (val === prevVal) {
                    return;
                }

                if (self.revertValue === null) {
                    self.onEdit();
                    prevVal = JSON.parse(prevVal);
                    self.revertValue.type = prevVal.type;
                    self.revertValue.value = prevVal.value;
                }
            }
        ));

        if (this.model.isNew) {
            this.onEdit();
        }
    };

    /**
     * @function
     * @name Controller.Component.sReaction#$onDestroy
     */
    sReaction.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name Controller.Component.sReaction#onSave
     * @return {PromiseLike}
     */
    sReaction.prototype.onSave = function onSave() {
        var self = this;

        return this.model.save().then(function () {
            self.$collCtrl.onEditReaction({'reaction': null});
            digestIfNeeded(self.$collCtrl.$scope.$parent);
            setTimeout(function () {
                self.revertValue = null;
            }, 1);
        });
    };

    /**
     * @function
     * @name Controller.Component.sReaction#onDelete
     * @return {PromiseLike}
     */
    sReaction.prototype.onDelete = function onDelete() {
        var self = this;

        if (!this.$collCtrl.model.canLoseReaction()) {
            return $.Deferred().reject();
        }

        return this.model.delete().then(function () {
            self.$collCtrl.model.removeReaction(self.model);
            digestIfNeeded(self.$scope);
        });
    };

    /**
     * @function
     * @name Controller.Component.sReaction#onEdit
     */
    sReaction.prototype.onEdit = function onEdit() {

        this.revertValue = this.model.clone();

        this.$collCtrl.onEditReaction({'reaction': this.model});
    };

    /**
     * @function
     * @name Controller.Component.sReaction#loadTypes
     * @return {PromiseLike}
     */
    sReaction.prototype.loadTypes = function loadTypes() {
        return $.when([
            {
                'type': Model.AI.Reaction.TYPE_CONVERSATION,
                'name': 'Conversation'
            },
            {
                'type': Model.AI.Reaction.TYPE_TEXT,
                'name': 'SimpleText'
            }
        ]);
    };

    /**
     * @function
     * @name Controller.Component.sReaction#onCancel
     */
    sReaction.prototype.onCancel = function onCancel() {
        var self = this;

        if (this.model.isNew) {
            this.$collCtrl.model.removeReaction(this.model);
        } else {
            this.model.type = this.revertValue.type;
            this.model.value = this.revertValue.value;
        }

        this.$collCtrl.onEditReaction({'reaction': null});
        // detach it from the current digest cycle, as it would then set the value again
        setTimeout(function () {
            self.revertValue = null;
        }, 1);
    };

    /**
     * @function
     * @name Controller.Component.sReaction#isConversation
     * @return {Boolean}
     */
    sReaction.prototype.isConversation = function isConversation() {
        return this.model.type === Model.AI.Reaction.TYPE_CONVERSATION;
    };

    /**
     * @function
     * @name Controller.Component.sReaction#isActiveInCollection
     * @return {Boolean}
     */
    sReaction.prototype.isActiveInCollection = function isActiveInCollection() {
        return this.$collCtrl.activeReaction === this.model;
    };

    ns.sReaction = sReaction;
})(Object.namespace('Controller.Component'));
