(function (ns) {
    /**
     * @namespace
     * @alias Model.RepairCase.InvalidMessageNumber
     *
     * @extends Model.RepairCase
     * @constructor
     */
    var InvalidMessageNumber = function () {
        var options = {
            autoRepair  : true
        };

        InvalidMessageNumber._pProto.constructor.call(
            this,
            this.checkInvalidMessageNumber,
            this.fixInvalidMessageNumber,
            options
        );

        this.faultyMessages = [];
    };

    Object.extendProto(InvalidMessageNumber, Model.RepairCase);

    /**
     * @name Model.RepairCase.EmptyPostbackMessageIncoming#checkEmptyPostbackMessageIncoming
     * @param {Model.Message.Collection} collection
     * @throws {Model.Exception.InvalidMessages}
     */
    InvalidMessageNumber.prototype.checkInvalidMessageNumber = function checkInvalidMessageNumber(collection) {
        collection.messages.reduce(function (carry, message) {
            for (var i in message.messageParts) {
                if (!message.messageParts[i].number) {
                    carry.push(message);
                }
            }

            return carry;
        }, this.faultyMessages);

        if (this.faultyMessages.length) {
            throw new InvalidMessages(this.faultyMessages);
        }
    };

    InvalidMessageNumber.prototype.fixInvalidMessageNumber = function fixInvalidMessageNumber() {
        var messageToFix;

        while ((messageToFix = this.faultyMessages.pop())) {
            var faultyMessageParts = [],
                faultyMessagePart,
                maxPartNumber = 0
            ;

            for (var i in messageToFix.messageParts) {
                if (!messageToFix.messageParts[i].number) {
                    faultyMessageParts.push(messageToFix.messageParts[i]);
                }

                if (!maxPartNumber || maxPartNumber < messageToFix.messageParts[i].number) {
                    maxPartNumber = messageToFix.messageParts[i].number;
                }
            }

            if (faultyMessageParts.length !== 1) {
                throw new Model.Exception.MessagePartsConflict(messageToFix);
            }

            faultyMessagePart = faultyMessageParts.pop();

            // if the faulty part is interactive, put it as last
            if (faultyMessagePart.isInteractive) {
                if (messageToFix.lastPart().isInteractive && faultyMessagePart !== messageToFix.lastPart()) {
                    throw new Model.Exception.MessagePartsConflict(messageToFix);
                }
                faultyMessagePart.number = maxPartNumber + 1;
            }

            messageToFix.orderParts();
        }
    };

    ns.InvalidMessageNumber = InvalidMessageNumber;
})(Object.namespace('Model.RepairCase'));
