(function (ns) {
    var KEY_ANY = 'any',
        KEY_ALL = 'all'
        ;
    /**
     * @namespace
     * @alias Model.Condition.Collection
     *
     * @constructor
     * @extends Model.Collection
     */
    var Collection = function (type) {
        Collection._pProto.constructor.call(this, type, 'condition');
        var strategy        = KEY_ANY,
            choices         = [{label: KEY_ANY}, {label: KEY_ALL}]
            ;

        Object.defineProperties(
            this,
            {
                strategy: {
                    get: function() {
                        return strategy;
                    },
                    set: function(val) {
                        var length = this.choices.filter(function(element) {
                            return element.label === val;
                        }).length;

                        if (!length) {
                            return;
                        }

                        strategy = val;
                    }
                    /**
                     * @name Model.Condition.Collection#strategy
                     * @type {String}
                     */
                },
                choices: {
                    get: function() {
                        return choices;
                    }
                    /**
                     * @name Model.Condition.Collection#choices
                     * @type {Array}
                     */
                }
            }
        );

        var parentUpdateByData = this.updateByData.bind(this);

        /**
         * @param {Object} data
         */
        this.updateByData = function updateByData(data) {
            if (!(data instanceof Array)) {
                return;
            }

            if (data.length === 1) {
                this.strategy = KEY_ALL;
            }

            // data is coming in as an array reflecting type but should be forwarded as a collection + type
            // [[cond1, cond2, cond3]] => [cond1, cond2, cond3] + ALL
            // [[cond1],[cond2],[cond3]] => [cond1, cond2, cond3] + ANY
            // flattening means here to reduce the array dimension to 1
            var flattened = data.reduce(function (carry, element) {
                carry.push.apply(carry, element.slice(0, element.length));
                return carry;
            }, []);

            parentUpdateByData({conditions: flattened});
        };

        var parentExtendClone = this.__extendClone;

        /**
         * @param {Model.Condition.Collection} original
         * @private
         */
        this.__extendClone = function __extendClone(original) {
            this.strategy = original.strategy;
            parentExtendClone.call(this, original);
        };

        /**
         * @method
         * @name Model.Condition.Collection#addCondition
         */

        /**
         * @method
         * @name Model.Condition.Collection#removeCondition
         */

        /**
         * @property
         * @name Model.Condition.Collection#conditions
         * @type {Array}
         */
    };

    Object.extendProto(Collection, Model.Collection);

    Collection.prototype.toJSON = function toJSON() {
        if (this.strategy === KEY_ANY) {
            return this.conditions.reduce(function(carry, element) {
                carry.push([element]);
                return carry;
            }, []);
        }

        return [this.conditions];
    };

    ns.Collection = Collection;
})(Object.namespace('Model.Condition'));