(function(ns) {
    /**
     * @namespace
     * @alias Model.Paginator
     *
     * @param rangeEachSide
     * @param defaultRecordsPerPage
     * @constructor
     */
    var Paginator = function (rangeEachSide, defaultRecordsPerPage) {
        defaultRecordsPerPage   = defaultRecordsPerPage || Paginator.DEFAULT_RECORDSPERPAGE;
        rangeEachSide           = rangeEachSide || 1;

        var self            = this,
            currentPage     = 1,
            pagesTotal      = 0,
            recordsPerPage  = defaultRecordsPerPage,
            totalRecords    = 0
            ;

        /**
         * @property {int[]}
         * @name Model.Paginator#pages
         */
        this.pages = [];
        /**
         * @property {int}
         * @name Model.Paginator#firstElement
         */
        this.firstElement = 0;
        /**
         * @property {int}
         * @name Model.Paginator#lastElement
         */
        this.lastElement = 0;

        Object.defineProperties(this, {
            /**
             * @property {int}
             * @name Model.Paginator#currentPage
             */
            currentPage: {
                enumerable: true,
                configurable: true,
                get: function () {
                    return currentPage;
                },
                set: function (val) {

                    val = parseInt(val);
                    if (isNaN(val) || val < 0) {
                        return;
                    }
                    currentPage = val;
                    self.recalculateElements();
                }
            },
            /**
             * @property {int}
             * @name Model.Paginator#pagesTotal
             */
            pagesTotal: {
                enumerable: true,
                configurable: true,
                get: function () {
                    return pagesTotal;
                },
                set: function (val) {
                    if(!val || val < 0) {
                        return;
                    }
                    pagesTotal = val;
                    self.recalculateElements();
                }
            },
            /**
             * @property {int}
             * @name Model.Paginator#rangeEachSide
             */
            rangeEachSide: {
                enumerable: true,
                configurable: false,
                get: function () {
                    return rangeEachSide;
                },
                set: function (val) {
                    if (!val || val < 0) {
                        return;
                    }

                    rangeEachSide = val;
                    self.recalculateElements();
                }
            },

            /**
             * @property {int}
             * @name Model.Paginator#totalRecords
             */
            totalRecords: {
                enumerable: true,
                set: function(val) {
                    val = parseInt(val);
                    if (isNaN(val) || val < 0) {
                        return;
                    }

                    totalRecords = val;
                },
                get: function() {
                    return totalRecords;
                }
            },
            /**
             * @property {int}
             * @name Model.Paginator#defaultRecordsPerPage
             */
            defaultRecordsPerPage: {
                enumerable: false,
                get: function() {
                    return defaultRecordsPerPage;
                }
            },
            /**
             * @property {int}
             * @name Model.Paginator#recordsPerPage
             */
            recordsPerPage: {
                enumerable: true,
                set: function(val) {
                    val = parseInt(val);
                    if (isNaN(val) || val < 0) {
                        return;
                    }

                    recordsPerPage = val;
                },
                get: function() {
                    return recordsPerPage;
                }
            }
        });

        this.recalculateElements();
    };

    /**
     * @name Model.Paginator#recalculateElements
     */
    Paginator.prototype.recalculateElements = function recalculateElements() {
        // logic from http://stackoverflow.com/questions/11272108/logic-behind-pagination-like-google/31836340#31836340

        // not enough pages, so display them all
        if (this.pagesTotal <= (2*this.rangeEachSide)+5) {
            this.firstElement   = 1;
            this.lastElement    = this.pagesTotal;
        }
        // current is to close to the start
        else if (this.currentPage <= this.rangeEachSide+2) {
            this.firstElement   = 1;
            this.lastElement    = (2*this.rangeEachSide)+2;
        }
        // current is too close to the end
        else if (this.currentPage >= this.pagesTotal - (this.rangeEachSide+1)) {
            this.firstElement   = this.pagesTotal - (2 * this.rangeEachSide) - 1;
            this.lastElement    = this.pagesTotal;
        }
        else {
            this.firstElement   = this.currentPage - this.rangeEachSide;
            this.lastElement    = this.currentPage + this.rangeEachSide;
        }

        // clear pages but use same reference
        this.pages.splice(0,this.pages.length);

        for (var i=this.firstElement; i<=this.lastElement; i++) {
            this.pages.push(i);
        }
    };

    Paginator.DEFAULT_RECORDSPERPAGE = 25;

     ns.Paginator = Paginator;
})(Object.namespace('Model'));
