(function(ns) {
    var CONST_EMOJI         = 'emoji',
        CONST_HEADER        = 'header',
        CONST_CLS_SELECTED  = 'selected'
    ;

    /**
     * @namespace
     * @alias Controller.Directive.Emoji.PickerController
     *
     * @param $scope
     * @param $sce
     * @param {sEmoji.Service.sEmojiSheet} sEmojiSheet
     */
    var PickerController = function($scope, $sce, sEmojiSheet) {
        var self = this;

        this.sEmojiSheet    = sEmojiSheet;
        this.$scope         = $scope;
        this.$sce           = $sce;

        this.options        = [];
        this.$deRegister    = [];
        this.emojiLookup    = {};

        var posX = 0,
            posY = 1
            ;

        Object.defineProperties(
            this,
            {
                'posX': {

                    get: function() {
                        return posX;
                    },
                    set: function(val) {
                        if (val >= self.options[posY].values.length) {
                            posX = 0;
                            this.posY += 1;
                        } else if (val < 0) {
                            this.posY -= 1;
                            posX = self.options[posY].values.length -1;
                        } else {
                            posX = val;
                        }
                    }
                    /**
                     * @property
                     * @name sEmoji.Service.sEmojiSheet#posX
                     * @type {Number}
                     */
                },
                'posY' : {
                    get: function() {
                        return posY;
                    },
                    set: function(val) {
                        var prevPos = posY;
                        if (val < 0) {
                            posY = self.options.length - 1;
                        } else if (val >= self.options.length) {
                            posY = 0;
                        } else {
                            posY = val;
                        }

                        if (!self.options[posY].values) {
                            return this.posY += (val - prevPos);
                        }

                        if (this.posX >= self.options[posY].values.length) {
                            this.posX = self.options[posY].values.length - 1;
                        }
                    }
                    /**
                     * @property
                     * @name sEmoji.Service.sEmojiSheet#posY
                     * @type {Number}
                     */
                }
            }
        );

        this.highlighted = false;

        /**
         * Override for private access
         * @param {[]} results
         */
        this.mapCategories = function mapCategories(results) {
            PickerController.prototype.mapCategories.call(this, results);
            posX = 0;
            posY = 1;
        };

        /**
         * @type {jQuery}
         * @name Controller.Directive.Emoji.PickerController#$panelElement
         */
    };
    
    PickerController.prototype.$onInit = function $onInit() {
        var self    = this,
            to      ,
            $unWatchPanelEl = this.$scope.$watch(function() {
                return self.mdPanelRef.panelEl;
            }, function(value) {
                if (!value) {
                    return;
                }
                self.bindEventListeners(value);
                self.$panelElement = value;
                if (self.mdPanelRef.config.focusOnOpen) {
                    self.$panelElement.find('.search-bar > input').focus();
                    self.mdPanelRef.config.focusOnOpen = false;
                }
                $unWatchPanelEl();
            });

        this.$deRegister.push(this.$scope.$on('$destroy', this.$onDestroy.bind(this)));
        this.$deRegister.push(
            this.$scope.$on(Controller.Directive.AutoCompleter.EVENT_UPDATE_RESULTS,
                /**
                 * @param event
                 * @param {{matches: Array, hint: string, searchTerm: string}} payload
                 */
                function(event, payload) {
                    self.hint = payload.hint;
                    self.searchTerm = payload.searchTerm;
                    self.updateMatches(payload.matches);
                    setTimeout(digestIfNeeded.bind(null ,self.$scope), 10);
                })
        );

        this.$deRegister.push(
            this.$scope.$on(Controller.Directive.AutoCompleter.EVENT_PARENT_KEYDOWN, function(ev, data) {
                self.onKeyDown(data);
            })
        );

        this.$deRegister.push(
            this.$scope.$watch(function () {
                return self.topIndex;
            },
            function () {
                if (to) {
                    clearTimeout(to);
                }
                to = setTimeout(function() {
                    $.each(self.$panelElement.find('[data-href]'), function(index, element) {
                        var $element = $(element);

                        $element.attr('href', $element.data('href'));
                    });
                }, 150);
            })
        );

        this.updateMatches(this.matches);
    };
    
    PickerController.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    }; 

    /**
     * @param {String} emoji
     */
    PickerController.prototype.highlight = function highlight(emoji) {
        if (this.highlighted === emoji) {
            return;
        }

        this.posX = this.emojiLookup[emoji].posX;
        this.posY = this.emojiLookup[emoji].posY;

        //noinspection JSJQueryEfficiency
        $('[data-' + CONST_EMOJI + '="' + this.highlighted + '"]').removeClass(CONST_CLS_SELECTED);
        this.highlighted = emoji;
        //noinspection JSJQueryEfficiency
        $('[data-' + CONST_EMOJI + '="' + this.highlighted + '"]').addClass(CONST_CLS_SELECTED);
        digestIfNeeded(this.$scope);
    };

    /**
     * @name Controller.Directive.Emoji.PickerController#onKeyDown
     * @param ev
     */
    PickerController.prototype.onKeyDown = function onKeyDown(ev) {
        if (this.searchTerm) {
            if ([Const.LeftArrow, Const.RightArrow, Const.UpArrow, Const.DownArrow].indexOf(ev.which) !== -1) {
                ev.preventDefault();
            }

            if (!this.options.filter(function(element) {
                    return element.values;
                }).length) {
                return;
            }
        }

        if (!this.$listHeight) {
            this.$listHeight = Math.floor(this.$panelElement.find('md-virtual-repeat-container').outerHeight() / this.$panelElement.find('[md-virtual-repeat]').not(":eq(0)").outerHeight()) - 1;
        }

        switch (ev.which) {
            case Const.LeftArrow:
                this.posX--;
                break;
            case Const.RightArrow:
                this.posX++;
                break;
            case Const.UpArrow:
                this.posY--;
                break;
            case Const.DownArrow:
                this.posY++;
                break;
            case Const.EnterKey:
                if (this.highlighted) {
                    this.useEmoji(this.highlighted);
                }
                break;
        }

        if (this.posY >= this.topIndex + this.$listHeight) {
            this.topIndex = (this.posY - this.$listHeight) + 2;
        } else if (this.posY !== 0 && this.posY < this.topIndex) {
            this.topIndex = this.posY;
        }

        this.highlight(this.options[this.posY].values[this.posX]);
    };

    /**
     * @name Controller.Directive.Emoji.PickerController#bindEventListeners
     * @param $element
     */
    PickerController.prototype.bindEventListeners = function bindEventListeners($element) {
        this.$deRegister = this.$deRegister
            .concat($element.$on('keydown', this.onKeyDown.bind(this)))
            .concat($element.$on('mouseover', 'td', this.onMouseOver.bind(this)))
            .concat($element.$on('click', 'td', this.onClick.bind(this)))
        ;
    };

    /**
     * @name Controller.Directive.Emoji.PickerController#onMouseOver
     * @param ev
     */
    PickerController.prototype.onMouseOver = function onMouseOver(ev) {
        var emoji;
        if ((emoji = $(ev.currentTarget).data(CONST_EMOJI))) {
            this.highlight(emoji);
        }
    };

    /**
     * @name Controller.Directive.Emoji.PickerController#onClick
     * @param ev
     */
    PickerController.prototype.onClick = function onClick(ev) {
        var emoji;
        if ((emoji = $(ev.currentTarget).data(CONST_EMOJI))) {
            this.useEmoji(emoji);
        }
    };

    /**
     * @name Controller.Directive.Emoji.PickerController#useEmoji
     * @param {String} emoji
     */
    PickerController.prototype.useEmoji = function useEmoji(emoji) {
        this.mdPanelRef.result = this.sEmojiSheet.getEmoji(emoji).aliases[0];
        this.mdPanelRef.close(true);
    };

    /**
     * @name Controller.Directive.Emoji.PickerController#extractHeader
     * @param {jQuery} $element
     * @return {String}
     */
    PickerController.prototype.extractHeader = function extractHeader($element) {
        return $element.data(CONST_HEADER);
    };

    PickerController.prototype.updateSearch = function updateSearch() {
        if (this.searchTerm) {
            this.$scope.$emit(Controller.Directive.AutoCompleter.EVENT_EXECUTE_SEARCH, this.searchTerm);
        } else {
            this.updateMatches(this.sEmojiSheet.byCategory);
        }

        this.topIndex = 0;
    };

    /**
     * @param {[]?} matches
     */
    PickerController.prototype.updateMatches = function updateMatches(matches) {
        var self = this;
        if (this.searchTerm) {
            matches = (matches || []).map(function(alias) {
                return self.sEmojiSheet.getByAlias(alias);
            }).unique();

            var results = [{
                    name    : 'Search results (' + matches.length + ')',
                    emojis  : matches
                }]
            ;

            this.mapCategories(results);
            setTimeout(function() {
                if (!self.options[self.posY] || !self.options[self.posY].values[self.posX]) {
                    return;
                }
                self.highlight(self.options[self.posY].values[self.posX]);
            }, Const.DebounceTime);
        } else {
            this.mapCategories(this.sEmojiSheet.byCategory);
        }
    };

    /**
     * @param {[]} results
     */
    PickerController.prototype.mapCategories = function mapCategories(results) {
        this.options.splice(0, this.options.length);
        var indexY  = 0,
            indexX  = 0,
            tmp     = [],
            html    = '',
            rows    = [],
            self    = this
        ;

        // reset the lookup
        this.emojiLookup = {};
        this.highlighted = null;

        // map function should be re-used

        results.map(
            /**
             * @param {{id: string, name: string, emojis: []}} categoryElement
             * @param {Number} index
             */
            function(categoryElement, index) {
                if (index) {
                    if (tmp.length) {
                        self.options.push({values: tmp, html: self.$sce.trustAsHtml(html)});
                    }

                    indexY++;
                }

                // reset all we have
                tmp = [];
                html = '';
                indexX = 0;

                self.options.push({
                    label: categoryElement.name,
                    html: self.$sce.trustAsHtml('<td data-' + CONST_HEADER + '="' + categoryElement.name + '" class="head" colspan="999">' + categoryElement.name + '</td>')
                });
                indexY++;

                categoryElement.emojis.map(function(emojiCode) {
                    tmp.push(emojiCode);
                    html += '<td data-' + CONST_HEADER + '="' + categoryElement.name + '" data-' + CONST_EMOJI + '="' + emojiCode + '"><svg class="emoji"><use href="#emoji-' + emojiCode + '" xlink:href="#emoji-' + emojiCode + '"/></svg></td>';
                    self.emojiLookup[emojiCode] = {posX: indexX, posY: indexY };
                    if (rows.indexOf(indexY) === -1) {
                        rows.push(indexY);
                    }
                    if (tmp.length === 9) {
                        self.options.push({values: tmp, html: self.$sce.trustAsHtml(html)});
                        tmp = [];
                        html = '';
                        indexX = 0;
                        indexY++;
                    } else {
                        indexX++;
                    }
                });
            });

        if (tmp.length) {
            if (indexY === 1) {
                html += '<td colspan="999"><div class="placeholder"></div></td>';
            }
            this.options.push({values: tmp, html: this.$sce.trustAsHtml(html)});
        }
    };

    ns.PickerController = PickerController;

})(Object.namespace('Controller.Directive.Emoji'));
