(function(angular) {

    var module = angular.module('sSecurityModule', []);

    module.service('sAuth', sSecurity.Service.sAuth);

    module.factory('zxcvbn', [function() {
        return {
            score: function() {
                var compute = zxcvbn.apply(null, arguments);
                return compute && compute.score;
            }
        };
    }]);

    module.provider('sMenuAuthWrapper', sSecurity.Provider.sMenuAuthWrapper);

    module.config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/auth/logout', new sSecurity.Route.Logout())
        ;
    }]);

})(angular);
