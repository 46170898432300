(function(ns) {
    /**
     * @namespace
     * @alias Model.Menu.ContextAction
     *
     * @param {Function|Boolean} condition
     * @param {Function} action
     * @param {Object} options
     * @constructor
     */
    var ContextAction = function(condition, action, options) {
        ContextAction._pProto.constructor.call(this, ContextAction);

        Object.defineProperties(
            this,
            {
                condition : {
                    get: function() {
                        return condition;
                    }
                },
                action : {
                    get: function() {
                        return action;
                    }
                },
                options : {
                    get: function() {
                        return options;
                    }
                }
            }
        );
    };

    Object.extendProto(ContextAction, ns.Entry);

    ContextAction.prototype.isAvailable = function isAvailable() {
        if (this.condition instanceof Function) {
            return this.condition.apply(this, arguments);
        } else {
            return this.condition;
        }
    };

    ns.Entry.TYPES.CONTEXT_ACTION = ContextAction;
    ContextAction.TYPE_IDENT = 'CONTEXT_ACTION';

    ns.ContextAction = ContextAction;
})(Object.namespace('Model.Menu'));