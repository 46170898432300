(function(ns) {
    /**
     * @namespace
     * @alias Model.RepairCase.QuickRepliesWOAttachment
     *
     * @constructor
     * @extends Model.RepairCase
     */
    var QuickRepliesWOAttachment = function() {

        var options = {
            confirm     : 'Go back',
            decline     : null,
            title       : 'Activation not possible',
            content     : 'Some Quick Replies do not have any content before them.',
            ariaLabel   : ''
        };

        QuickRepliesWOAttachment._pProto.constructor.call(
            this,
            this.checkQuickRepliesWOAttachment,
            null,
            options
        );
    };

    Object.extendProto(QuickRepliesWOAttachment, Model.RepairCase);

    /**
     * @param {Model.Message.Collection} collection
     */
    QuickRepliesWOAttachment.prototype.checkQuickRepliesWOAttachment = function checkQuickRepliesWOAttachment(collection) {
        var faultyMessages = [];

        for (var i = 0; i < collection.messages.length; i++) {
            if (collection.messages[i].partsLength() === 1
                && collection.messages[i].firstPart().type === 'QuickReplies'
                ) {
                faultyMessages.push(collection.messages[i]);
            }
        }

        if (faultyMessages.length) {
            throw new Model.Exception.InvalidMessages(faultyMessages);
        }
    };

    ns.QuickRepliesWOAttachment = QuickRepliesWOAttachment;
})(Object.namespace('Model.RepairCase'));