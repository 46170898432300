(function(angular) {

    var module = angular.module('sAutomationModule');

    /**
     * @ngdoc component
     * @name sAutomationStep
     * @restrict E
     *
     * @param {Model.Automation.Step}   model
     * @param {Boolean}                 showAnalytics
     * @param {?Model.Event}            event
     */
    module.component(
        'sAutomationStep', {
            controller      : sAutomation.Component.Controller.sAutomationStep,
            controllerAs    : '$ctrl',
            templateUrl     : 'sautomation/components:s-automation-step',
            bindings: {
                model           : '=',
                showAnalytics   : '=?',
                event           : '<?'
            }
        });
})(angular);
