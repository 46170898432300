(function(ns) {
    /**
     * @namespace
     * @alias sInbox.Controller.Index
     *
     * @param $rootScope
     * @param listUri
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param Notification
     * @param $scope
     * @param $mdDialog
     * @param $sce
     * @param sMessageCollection
     * @param sDomainService
     * @param sTitle
     * @constructor
     */
    var Index = function(
        $rootScope,
        listUri,
        sAPIAccess,
        Notification,
        $scope,
        $mdDialog,
        $sce,
        sMessageCollection,
        sDomainService,
        sTitle
    ) {
        this.notification       = Notification;
        this.$scope             = $scope;
        this.$mdDialog          = $mdDialog;
        this.sMessageCollection = sMessageCollection;
        this.sDomainService     = sDomainService;
        this.sAPIAccess         = sAPIAccess;

        this.list = new Model.List(listUri);

        this.filterFields = {
            segment: {
                component: 'sSegmentPicker',
                options: {
                    attrs: {
                        labels: "{existing:'An existing Segment', new:'A new Segment'}",
                        'is-required': false
                    },
                    label: 'segment',
                    asJson: function(key, value) {
                        if (key) {
                            return value;
                        }
                        return Model.Segment.createByData(value);
                    },
                    defaultValue: null
                }
            }
        };

        var findMessageInList = function (message) {
            return this.list.records.filter(function (record) {
                return record.message.uuid === message.uuid
            }).pop()
        }.bind(this);

        this.sendActions = new Model.Menu.Menu();
        this.sendActions
            .addAction('Send & Resolve', function () {
                var record = findMessageInList(this.beingReplied);
                return this.send(record).then(function() {
                    var $deferred = $.Deferred();
                    $.ajax({
                        url: sAPIAccess.endpoint(Controller.Component.sMemberChatBotStatus.URL_MEMBER_CHATBOT_DISABLE).delete(record.from.id),
                        method: Const.Method.DELETE
                    }).always(function () {
                        $deferred.resolve()
                    });

                    var range = this.getRangeForMember(record.from);

                    var selection = this.list.records.slice(range.from, range.to + 1);
                    return $.when(this.handleMarkAsDone(selection), $deferred);
                }.bind(this)).then(function () {
                    this.resetSelection();
                }.bind(this));
            }.bind(this))
            .addAction('Send & Keep open', function () {
                var record = findMessageInList(this.beingReplied);

                return this.sendAndMarkAsDone(record).then(function () {
                    var $deferred = $.Deferred();
                    $.ajax({
                        url: sAPIAccess.endpoint(Controller.Component.sMemberChatBotStatus.URL_MEMBER_CHATBOT_DISABLE).post(record.from.id),
                        method: Const.Method.POST
                    }).always(function () {
                        $deferred.resolve()
                    });
                    return $deferred;
                })
            }.bind(this));

        this.list.pager.recordsPerPage  = 10;

        this.sending        = null;
        this.beingReplied   = null;
        this.replyThread    = null;

        this.$deRegister    = [];

        var setTitleFunction = function setTitleFunction() {
            sTitle.title = 'Inbox';
            sTitle.subTitle = 'Messages from users (' + self.list.pager.totalRecords + ')';
        };

        var self = this,
        column = this.list.columns.createAndAdd('message');
        column.label = 'Message';

        column = this.list.columns.createAndAdd('received');
        column.label = 'Received';

        this.list.isMultipleAllowed = false;
        this.list.actions = this.list.actions || {};
        if (sAPIAccess.isAllowed('messageAnchor.sendMultiple', Const.Method.POST)) {
            this.list.actions.answer = new Model.Menu.Action('answer', this.handleAnswerMessage.bind(this));
        }
        if (sAPIAccess.check({'messageIncoming.RESTAccess': [Const.Method.PUT]})) {
            this.list.isMultipleAllowed = true;
            this.list.actions.done = new Model.Menu.Action('done', this.handleMarkAsDone.bind(this));
        }

        if (Object.keys(this.list.actions).length) {
            column = this.list.columns.createAndAdd('options');
            column.label = 'Options';
        }

        this.sendCollection     = new Model.Message.Collection();
        this.messageControls    = new Model.Menu.Menu();

        this.sendCollection.addMessage();

        this.list.mapFn = function(element) {
            var message = new Model.ApiMessage(element.message.uuid),
                val = {
                    'message'   : message.updateByData(element.message)
                };

            if (element.from) {
                val['from'] = Model.Member.fromJson(element.from);
            }

            return val;
        };

        this.messageControls
            .addAction(
                $sce.trustAsHtml('<i class="fa fa-pencil-alt fa-pencil"></i>'),
                function() {
                    $scope.$broadcast.apply($scope, ['edit-message-part'].concat(Array.prototype.slice.call(arguments, 0)));
                },
                {
                    isEdit: true,
                    tooltip: 'Edit message'
                }
            )
            .addAction(
                $sce.trustAsHtml('<i class="fa fa-trash-alt fa-trash danger"></i>'),
                function(messagePart) {
                    $scope.$broadcast.apply($scope, ['remove-message-part'].concat(Array.prototype.slice.call(arguments, 0)));
                },
                {
                    tooltip: 'Remove message'
                }
            );

        $scope.$on('$destroy', this.$onDestroy.bind(this));

        this.$deRegister.push($rootScope.$on(sHeadline.Service.sTitle.EVENT_TITLE_SET, function () {
            setTitleFunction();
        }));

        this.$deRegister.push($scope.$watch(function () {
            return self.list.pager.totalRecords;
        }, function() {
            setTitleFunction();
        }));


        this.$deRegister = this.$deRegister.concat($(document).$on(
            'ajaxSend',
            function(event, jqXHR, request) {
                if (request.url.search(listUri) === -1) {
                    return true;
                }
                var filter      = self.list.filters.getByName('segment'),
                    segmentUuid = jqXHR.getResponseHeader('x-segment-uuid')
                ;

                jqXHR.done(function() {
                    if (segmentUuid && filter) {
                        filter.value = new Model.Segment(segmentUuid);
                    }
                });
            }
        ));
    };

    Index.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @param {Array} selection
     */
    Index.prototype.handleAnswerMessage = function handleAnswerMessage(selection) {
        var messageCollection   = new Model.Message.Collection(),
            message             = selection.slice(0,1).pop().message
            ;

        this.sendCollection.getRootMessage().removeParts();

        messageCollection.appendMessage(message);

        this.beingReplied = message;
        this.replyThread  = messageCollection;
    };

    /**
     * @param selection
     * @param toggleSelection
     */
    Index.prototype.handleMarkAsDone = function handleMarkAsDone(selection, toggleSelection) {
        var calls = selection.map(function(element) {
                element.message.setProcessed();
                return element.message.save.bind(element.message);
            }),
            self = this
            ;


        return $.aggregateAction(
            calls,
            Model.RESTAccessByUUID.endpoint_batch(),
            function(options, data, counter) {
                var nameObj = {
                    method      : options.method,
                    url         : options.url,
                    counter     : counter,
                    params      : options.data
                };
                options.context.getFormData(data, nameObj);
            })
            .then(function() {
                self.notification.success(calls.length + ' message' + (calls.length > 1 ? 's' : '')  + ' marked as processed!');
                if (toggleSelection) {
                    for (var i = selection.length - 1; i >= 0; i--) {
                        self.$scope.$emit(sList.Component.Controller.sList.EVENT_TOGGLE_SELECTION, selection[i]);
                    }
                }

                return self.list.load(false, true);
            }).then(function () {
                digestIfNeeded(self.$scope);
            });
    };

    /**
     * @param {{message, from}} record
     */
    Index.prototype.markAsDone = function markAsDone(record) {
        record.message.setProcessed();
        return record.message.save();
    };

    /**
     * @param {Object} record
     * @param {Array} records
     * @param {Number|String} index
     * @returns {{}}
     */
    Index.prototype.getRowClass = function getRowClass(record, records, index) {
        var classes = {};
        if (index < (records.length - 1)) {
            classes.noBorder = !(records[parseInt(index) + 1].from)
        }

        if (this.beingReplied) {
            classes.disabled = this.beingReplied.uuid !== record.message.uuid
        }
        return classes;
    };

    /**
     * @name sInbox.Controller.Index#send
     * @param {{message, from}} record
     * @return {$.Deferred}
     */
    Index.prototype.send = function send(record)
    {
        var self            = this,
            message         = this.sendCollection.messages.slice(0,1).pop(),
            messageAnchor   = message.createAndSetMessageAnchor(self.sDomainService.currentDomainId).messageAnchor
        ;

        /**
         * @name sInbox.Controller.Index#addMessageToSend
         * @param content
         */
        this.sending = this.sMessageCollection.validateAndSend(messageAnchor, null, record.from.id, null, this.$scope).then(function() {
            self.notification.success("Message sent.");
        }).always(function() {
            self.sending = null;
        });

        return this.sending;
    };

    /**
     * @param {{message, from}} record
     * @return {$.Deferred}
     */
    Index.prototype.sendAndMarkAsDone = function sendAndMarkAsDone(record) {
        var self = this;
        return this.send(record).then(function() {
            return self.markAsDone(record);
        }).then(function () {
            self.resetSelection();
            return self.list.load(false, true);
        }).always(function() {
            digestIfNeeded(self.$scope);
        });
    };

    Index.prototype.addMessageToSend = function addMessageToSend(content) {
        this.$scope.$broadcast(Controller.Component.sMessageLog.EVENT_ADD_MESSAGE_BY_CONTENT, content);
    };

    Index.prototype.resetSelection = function resetSelection() {
        this.beingReplied = null;
    };

    /**
     * @function
     * @name sInbox.Controller.Index#onMessageClick
     * @param {Model.ApiMessage} apiMessage
     */
    Index.prototype.onMessageClick = function onMessageClick(apiMessage) {
        if(this.sAPIAccess.isAllowed('messageAnchor.sendMultiple', Const.Method.POST)) {
            this.list.actions.answer.action([apiMessage]);
        }
    };

    /**
     * @param {Model.Member} member
     * @return {{from: number, to: number}}
     */
    Index.prototype.getRangeForMember = function getRangeForMember(member) {
        var rows       = this.list.records,
            i,
            range      = {
                from : -1,
                to   : -1
            }
        ;

        for (i = 0; i < rows.length; i++) {
            if (rows[i].from.id === member.id) {
                range.from = i;
                break;
            }
        }

        // not found
        if (range.from === -1) {
            return range;
        }

        for (i = range.from; i < rows.length; i++) {
            if (rows[i + 1] && rows[i + 1].from.id !== member.id) {
                range.to = i;
                return range;
            }
        }

        range.to = rows.length - 1;
        return range;
    };

    ns.Index = Index;
})(Object.namespace('sInbox.Controller'));
