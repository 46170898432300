(function(ns) {
    var KEY_SMINION_DUPLICATECHECK_DURATION = 'sMinion_DuplicateCheck_duration',
        KEY_FUZZY_MATCH_THRESHOLD = 'fuzzy_match_threshold',
        DATA_KEY_WHITELIST = [
            'url_shortening',
            'url_parameters',
            KEY_SMINION_DUPLICATECHECK_DURATION,
            KEY_FUZZY_MATCH_THRESHOLD,
            'default_pois_id',
            'timezone',
            'language',
            'public_key',
        ];
    /**
     * @namespace
     * @alias sSettings.Controller.BotConfiguration.General
     * @constructor
     *
     * @param $scope
     * @param Notification
     * @param {Model.Domain} domain
     */
    var General = function ($scope, Notification, domain) {

        this.$scope         = $scope;
        this.notification   = Notification;
        this.domain         = domain;

        this.checkboxHelper = {};
        this.timezoneNames  = moment.tz.names();
        this.settingsSaving = null;

        // preset checkboxHelper
        // info: for easy adding of new keys we dont diff between key with checkboxes and the ones without
        for (var key in DATA_KEY_WHITELIST) {
            if (this.domain.domainData[DATA_KEY_WHITELIST[key]]) {
                this.checkboxHelper[DATA_KEY_WHITELIST[key]] = true;
            }
        }
    };

    /**
     * @function
     * @name sSettings.Controller.BotConfiguration.General#onSave
     */
    General.prototype.onSave = function onSave() {
        var self = this;

        this.settingsSaving = this.domain.save()
            .then(
                function () {
                    self.notification.success('Saved successfully.');
                },
                function () {
                    self.notification.error('Saving failed.');
                }
            )
            .always(function () {
                setTimeout(function () {
                    self.settingsSaving = null;
                    digestIfNeeded(self.$scope);
                }, 100);
            });
    };

    /**
     * @function
     * @name sSettings.Controller.BotConfiguration.General#checkboxChanged
     * @param {string} key
     */
    General.prototype.checkboxChanged = function checkboxChanged(key) {
        if (!this.checkboxHelper[key]) {
            delete this.domain.domainData[key];
        } else {
            switch (key) {
                case KEY_SMINION_DUPLICATECHECK_DURATION:
                    this.domain.domainData[key] = 172800; // 48 hours
                    break;
                case KEY_FUZZY_MATCH_THRESHOLD:
                    this.domain.domainData[key] = 0.75;
                    break;
            }
        }
        digestIfNeeded(this.$scope);
    };

    /**
     * @function
     * @name sSettings.Controller.BotConfiguration.General#getLanguages
     * @returns {Object}
     */
    General.prototype.getLanguages = function getLanguages() {
        return ISO6391.getLanguages(ISO6391.getAllCodes());
    };

    ns.General = General;
})(Object.namespace('sSettings.Controller.BotConfiguration'));
