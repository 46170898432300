(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sCalendar
     * @constructor
     *
     * @param $scope
     * @param $element
     */
    var sCalendar = function ($scope, $element) {
        var model           = this.model || null,
            activeSheetDate = moment().startOf('month')
        ;

        this.$deRegister        = [];
        this.$scope             = $scope;
        this.dates              = [];
        this.weekDays           = moment.weekdaysMin(true);

        // dateHovered-helper (if a `first-date` is clicked the hover is stored in here)
        this.dateHovered        = null;

        /**
         * @property
         * @name Controller.Component.sCalendar#model
         * @type moment|null
         */
        Object.defineProperties(
            this,
            {
                'model': {
                    enumerable: true,
                    configurable: true,
                    get: function () {
                        return model;
                    },
                    set: function (val) {
                        if (!(val === null || moment.isMoment(val) || val instanceof Model.DateRange)) {
                            throw 'Model must be a moment object or null.';
                        }
                        model = val;
                    }
                },
                '$element' : {
                    get: function () {
                        return $element;
                    }
                }
            }
        );

        /**
         * @property
         * @name Controller.Component.sCalendar#activeSheetDate
         * @type moment
         */
        Object.defineProperty(
            this,
            'activeSheetDate',
            {
                enumerable: true,
                configurable: true,
                get: function () {
                    return activeSheetDate;
                },
                set: function(val) {
                    if (!moment.isMoment(val)) {
                        throw 'ActiveSheetDate must be a moment object or null.';
                    }
                    activeSheetDate = val;
                }
            }
        );
    };

    /**
     * @function
     * @name Controller.Component.sCalendar#$onInit
     */
    sCalendar.prototype.$onInit = function $onInit() {
        var self = this;

        this.$deRegister.push(this.$scope.$watch(
            function () {
                // watching the milliseconds
                return self.activeSheetDate.valueOf();
            },
            function () {
                self.calculateDates();
            }
        ));

        // if there is a model we should move the active month to it
       if (!this.activeSheetDate && moment.isMoment(this.model)) {
            this.activeSheetDate
                .year(this.model.year())
                .month(this.model.month());
       }
    };

    /**
     * @function
     * @name Controller.Component.sCalendar#$onDestroy
     */
    sCalendar.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    sCalendar.prototype.$postLink = function $postLink() {
        var selector    = '.calendar-date-grid > span.calendar-date',
            self        = this
            ;

        this.$deRegister = this.$deRegister.concat(
            this.$element.$on('mouseleave', selector, this.onMouseLeaveDate.bind(this))
        );

        this.$deRegister = this.$deRegister.concat(
            this.$element.$on('mouseenter', selector, self.onMouseEnterDate.bind(this))
        );

        this.$deRegister = this.$deRegister.concat(
            this.$element.$on('hover', selector, this.onHoverDate.bind(this))
        );
    };


    /**
     * @function
     * @name Controller.Component.sCalendar#isToday
     * @param {moment} date
     * @returns Boolean
     */
    sCalendar.prototype.isToday = function isToday(date) {
        return moment().isSame(date, 'day');
    };

    /**
     * @function
     * @name Controller.Component.sCalendar#isSelectedDate
     * @param {moment} date
     * @returns Boolean
     */
    sCalendar.prototype.isSelectedDate = function isSelectedDate(date) {
        if (this.model instanceof Model.DateRange) {
            if (this.model.from === null && this.model.to === null) {
                return false;
            }
            if (this.model.to === null) {
                return this.model.from.isSame(date, 'day');
            }
            return date.isBetween(this.model.from, this.model.to, 'day', '[]');
        }
        return this.model && this.model.isSame(date, 'day');
    };

    /**
     * @function
     * @name Controller.Component.sCalendar#isSelectionBeginDate
     * @param {moment} date
     * @returns Boolean
     */
    sCalendar.prototype.isSelectionBeginDate = function isSelectionBeginDate(date) {
        return this.model instanceof Model.DateRange
            && this.model.from
            && this.model.from.isSame(date, 'day');
    };

    /**
     * @function
     * @name Controller.Component.sCalendar#isSelectionEndDate
     * @param {moment} date
     * @returns Boolean
     */
    sCalendar.prototype.isSelectionEndDate = function isSelectionEndDate(date) {
        return this.model instanceof Model.DateRange
            && this.model.to
            && this.model.to.isSame(date, 'day');
    };

    /**
     * @function
     * @name Controller.Component.sCalendar#isActiveMonth
     * @param {moment} date
     * @returns Boolean
     */
    sCalendar.prototype.isActiveMonth = function isActiveMonth(date) {
        return this.activeSheetDate.year() === date.year() && this.activeSheetDate.month() === date.month();
    };

    /**
     * @function
     * @name Controller.Component.sCalendar#isHoveredDate
     * @param {moment} date
     * @returns Boolean
     */
    sCalendar.prototype.isHoveredDate = function isHoveredDate(date) {
        return this.dateHovered !== null
            && date.isSame(this.dateHovered, 'day');
    };

    /**
     * @function
     * @name Controller.Component.sCalendar#isInHoveredDateRange
     * @param {moment} date
     * @returns Boolean
     */
    sCalendar.prototype.isInHoveredDateRange = function isInHoveredDateRange(date) {
        return this.model instanceof Model.DateRange
            && this.model.isDateBetweenPreselection(date);
    };

    /**
     * @function
     * @name Controller.Component.sCalendar#onMouseEnterDate
     * @param event
     */
    sCalendar.prototype.onMouseEnterDate = function onMouseEnterDate(event) {
        var date = $(event.currentTarget).data('date');
        if (!date) {
            return;
        }

        date = moment(date);

        var $scope = this.$scope;

        if (this.model instanceof Model.DateRange) {
            this.model.preselection = date;
            $scope = $scope.$parent;
        }
        this.dateHovered = moment(date);
        digestIfNeeded($scope);
    };

    /**
     * @function
     * @name Controller.Component.sCalendar#onMouseLeaveDate
     */
    sCalendar.prototype.onMouseLeaveDate = function onMouseLeaveDate() {
        if (this.model instanceof Model.DateRange) {
            this.model.preselection = null;
        }
        this.dateHovered = null;
        digestIfNeeded(this.$scope);
    };

    /**
     * @function
     * @name Controller.Component.sCalendar#onHoverDate
     * @param {moment} date
     */
    sCalendar.prototype.onHoverDate = function onHoverDate(date) {
        this.$scope.$emit(sCalendar.EVENT_DATE_HOVER, {date: date});
    };

    /**
     * @function
     * @name Controller.Component.sCalendar#onNextClick
     */
    sCalendar.prototype.onNextClick = function onNextClick() {
        this.activeSheetDate.add(1, 'months');
    };

    /**
     * @function
     * @name Controller.Component.sCalendar#onNextClick
     */
    sCalendar.prototype.onPreviousClick = function onPreviousClick() {
        this.activeSheetDate.subtract(1, 'months');
    };

    /**
     * @function
     * @name Controller.Component.sCalendar#onDateClick
     * @param {moment} date
     */
    sCalendar.prototype.onDateClick = function onDateClick(date) {
        // update visible sheet
        this.activeSheetDate
            .year(date.year())
            .month(date.month());

        this.$scope.$emit(sCalendar.EVENT_DATE_CLICK, {date: date});
    };

    /**
     * Calculates all visible days in full weeks
     * for the current month and assigns them
     * to the `dates`-variable
     *
     * @function
     * @name Controller.Component.sCalendar#calculateDates
     */
    sCalendar.prototype.calculateDates = function calculateDates() {
        var dates = [],
            date = {},
            currentDate = this.activeSheetDate,

            // full visible month first date of month
            monthStartDate = moment(currentDate)
                .date(1),

            // full visible month last date of month
            monthEndDate = moment(currentDate)
                .add(1, 'months')
                .subtract(1, 'days'),

            // previous month last visible days
            calendarStartDate = moment(monthStartDate)
                .subtract(monthStartDate.weekday(), 'days'),

            // next month first visible days
            calendarEndDate = moment(monthEndDate)
                .add(6 - monthEndDate.weekday(), 'days'),

            calendar = calendarStartDate
        ;

        while (calendar.isSameOrBefore(calendarEndDate, 'day')) {
            date = {
                model           : calendar,
                isToday         : this.isToday(calendar),
                isActiveMonth   : this.isActiveMonth(calendar)
            };
            dates.push(date);
            calendar = moment(calendar).add(1, 'days');
        }
        this.dates = dates;
    };

    sCalendar.EVENT_DATE_HOVER = 'sCalendar-date-hover';
    sCalendar.EVENT_DATE_CLICK = 'sCalendar-date-click';

    ns.sCalendar = sCalendar;
})(Object.namespace('Controller.Component'));
