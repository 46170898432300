(function (ns) {
    /**
     * @namespace
     * @alias sTermsOfService.Controller.TermsOfServiceDialog
     * @constructor
     *
     * @param $mdDialog
     * @param {sTermsOfService} sTermsOfService
     */
    var TermsOfServiceDialog = function TermsOfServiceDialog($mdDialog, sTermsOfService) {
        this.$mdDialog = $mdDialog;
        this.sTermsOfService = sTermsOfService;
    };

    /**
     * @function
     * @name sTermsOfService.Controller.TermsOfServiceDialog#confirmSelection
     */
    TermsOfServiceDialog.prototype.confirmSelection = function confirmSelection() {
        var self = this;
        sTermsOfService.Service.sTermsOfService.prototype.saveUserAcceptedTOS().then(function () {
            self.$mdDialog.hide(self);
        });
    };

    ns.TermsOfServiceDialog = TermsOfServiceDialog;
})(Object.namespace('sTermsOfService.Controller'));
