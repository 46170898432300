(function(angular){
    var INT_KEYCAP              = 8419,         // \u20E3
        INT_VARIATION_SELECTOR  = 65039         // \uFE0F
    ;

    angular.module('sEmoji')
    .filter('emoji',
        /**
         * @type $sce
         * @param $sce
         * @param {sEmoji.Service.sEmojiSheet} sEmojiSheet
         * @returns {Function}
         */
        function($sce, sEmojiSheet) {
            var fn = function(input, dontEscape) {
                if (input instanceof Object && input.$$unwrapTrustedValue) {
                    input = input.$$unwrapTrustedValue();
                }

                var svgUri,
                    output          = '',
                    replacements    = [],
                    changed         = false
                ;

                input = input || '';

                if (!input.length) {
                    return;
                }

                var $el = $('<div></div>');
                $el.html(input);

                for (var i = 0; i < $el[0].childNodes.length; i++) {
                    replacements = [];
                    if ($el[0].childNodes[i].nodeType !== Node.TEXT_NODE) {
                        var innerHtml       = $el[0].childNodes[i].innerHTML,
                            clone           = $el[0].childNodes[i].cloneNode(false)
                        ;

                        if (!innerHtml) {
                            continue;
                        }

                        var newInnerHtml    = fn(innerHtml, true);

                        if (newInnerHtml !== innerHtml) {
                            changed = true;
                        }

                        clone.innerHTML = newInnerHtml;
                        output += clone.outerHTML;

                        continue;
                    }

                    /**
                     * workaround for preventing unescaping html entities
                     * (since it is "textContent" it should have no 'real' html in it)
                     */
                    var textContent = $('<div></div>').text($el[0].childNodes[i].textContent).html();

                    // iterate over each character (2 bytes)
                    for (var j = 0; j < textContent.length; j++) {
                        // read the character with extensions (more then 2 bytes)
                        var codeAt = textContent.codePointAt(j);

                        if ((codeAt > 2048
                                    && textContent[j] !== Controller.Directive.ContentEditableController.UNICODE_SELECTION_START_PLACEHOLDER
                                    && textContent[j] !== Controller.Directive.ContentEditableController.UNICODE_SELECTION_END_PLACEHOLDER
                                    && textContent[j] !== INT_KEYCAP)
                            || textContent.codePointAt(j + 1) === INT_KEYCAP || textContent.codePointAt(j + 1) === INT_VARIATION_SELECTOR) {
                            replacements.push({
                                index   : j,
                                value   : codeAt,
                                width   : 1
                            });

                            /**
                             * Be greedy and catch as many chars as possible if
                             * 1. the code is more then 2 bytes
                             * 2. is followed by an emoji variation selector char
                             */
                            if (codeAt >= 65536 || textContent.codePointAt(j + 1) === INT_VARIATION_SELECTOR) {
                                j++;
                                replacements[replacements.length - 1].width++;
                            }
                        }
                    }

                    for (j = replacements.length - 1; j >= 0; j--) {
                        var sheetEntry      = sEmojiSheet.getEmoji(replacements[j].value.toString(16).lpad(4)),
                            indexOffset     = 0
                        ;

                        if (!sheetEntry && replacements[j + 1]) {
                            var combined = replacements.slice(j, j + 2),
                                parsed = combined.map(function(element) {
                                    return element.value.toString(16).lpad(4);
                                }).join('-')
                            ;


                            sheetEntry = sEmojiSheet.getEmoji(parsed);
                            indexOffset = 1;
                        }

                        if (replacements[j + 1] && replacements[j + 1].value === INT_VARIATION_SELECTOR) {
                            indexOffset = 1;
                        }

                        if (!sheetEntry) {
                            continue;
                        }


                        textContent = textContent.substring(0, replacements[j].index)
                            + ':' + sheetEntry.aliases[0] + ':'
                            + textContent.substring(replacements[j + indexOffset].index + replacements[j + indexOffset].width);

                        if (indexOffset) {
                            replacements.splice(j + indexOffset, 1);
                        }

                        replacements.splice(j, 1);
                    }

                    textContent = textContent.replace(/\:([^\s\/\?\<\>\#]*?)\:/mgi, function() {
                        if ((svgUri = sEmojiSheet.getEmojiSvg(arguments[1]))) {
                            var unicode = sEmojiSheet.getByAlias(arguments[1]).split('-'),
                                unicodeStr = unicode.map(function(element) { return '&#x' + element + ';'}).join(''),
                                unicodeColouredStr = unicodeStr
                            ;

                            // always force the colored version if that exists
                            if (unicode.length === 1 && sEmojiSheet.getEmoji(unicode.slice(0, 1).pop() + '-fe0f')) {
                                unicodeColouredStr += (parseInt(unicode.unshift(), 16) < 65536 ? '&#xfe0f;' : '');
                            }
                            changed = true;

                            return '<img class="emoji ' + ' ' + unicode + '" title="' + arguments[1] + '" src="' + svgUri + '" alt="' + unicodeColouredStr + '">';
                        }

                        return arguments[0];
                    });

                    output += textContent;
                    delete($textContentElement);
                }

                if (!changed) {
                    output = input;
                }

                if (dontEscape === true) {
                    return output;
                }

                return $sce.trustAsHtml(output);
            };


            return fn;
        })
})(angular);
