(function(window) {
    /**
     * @param {String} dataURI
     * @return {Blob}
     */
    function dataURItoBlob(dataURI) {
        var uIntArray = dataURItoUint8Array(dataURI);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the ArrayBuffer to a blob, and you're done
        return new Blob([uIntArray.buffer], {type: mimeString});
    }

    /**
     * @param {String} dataURI
     * @return {Uint8Array}
     */
    function dataURItoUint8Array(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return ia;
    }

    window.dataURItoBlob        = dataURItoBlob;
    window.dataURItoUint8Array  = dataURItoUint8Array;
})(window);