(function(ns) {

    var FORWARD_ACTION        = 'ForwardAction',
        AI_TEMPLATE           = 'AITemplate',
        AI_REACTION           = 'AIReaction',
        TYPE_CAROUSEL         = 'HScroll',
        TYPE_PRODUCT_TEMPLATE = 'ProductTemplate',
        TYPE_LISTVIEW         = 'ListView',
        TEXT                  = 'Text',
        RULES_TEXT_INPUT      = {
            component: 'div',
            options: {
                model: 'ng-model',
                attrs: {
                    'contenteditable'   : true,
                    's-emoji-renderer'  : true,
                    's-emoji-picker'    : true,
                    'autocompleter'     : true,
                    'hint-placeholder'  : '[]',
                    's-form-wrapper'    : '{errorTemplate: \'sbase:text_errors\', class: \'s-text-input \'}',
                    'class'             : 'input',
                    'ng-model-options'  : '{allowInvalid: true}'
                }
            }
        },
        KEY_NS_ACTIONS      = 'actions',
        META_GLOBAL_LINKS   = 'globalLinks',
        PLACEHOLDER_TYPE_PRODUCT_TEMPLATE = 'ProductTemplate',
        DEFAULT_PRODUCT_IMAGE_URL = 'https://content.spectrm.de/3176ab0cacdd51466ecda94aab295cbc',
        TOKEN_PRODUCT_ID       = '@catalog_id',
        OTN_FREQUENCY_ONE_TIME = 'onetime',
        OTN_FREQUENCY_DAILY    = 'daily',
        OTN_FREQUENCY_WEEKLY   = 'weekly',
        OTN_FREQUENCY_MONTHLY  = 'monthly'
    ;



    /**
     * @namespace
     * @alias Model.Message.Part
     *
     * @extends Model.Behavior.Meta
     * @extends Model.UUID
     */
    var Part = function(uuid, _type, number, msgContents) {
        var self                = this,
            type,
            parent,
            config,
            messageContents     = [],
            activeGroup         = 0,
            isInteractiveCache  = null,
            showAsCache         = null,
            contentsCache       = []
        ;

        Model.Behavior.Meta.call(this);

        // add expose placeholder behavior with the option to follow placeholders of the parent
        Model.Behavior.ExposePlaceholder.call(
            this,
            function (placeholder) {
                return placeholder.source.label === 'Product'
                    && this.type === PLACEHOLDER_TYPE_PRODUCT_TEMPLATE
                    && placeholder.token !== TOKEN_PRODUCT_ID;
            }.bind(this),
            'parent');


        number = number || null;

        Object.defineProperties(
            this,
            {
                messageContents: {
                    enumerable: true,
                    configurable: true,
                    get: function () {
                        contentsCache.splice.apply(contentsCache, [0, contentsCache.length].concat(messageContents));
                        return contentsCache;
                    }
                    /**
                     * @property
                     * @name Model.Message.Part#messageContents
                     * @type Model.Message.Content[]
                     */
                },
                type: {
                    enumerable: true,
                    configurable: true,
                    set: function (val) {
                        if (!Part.getTypes()[val]) {
                            throw 'Invalid argument!';
                        }

                        if (val === type) {
                            return;
                        }

                        var oldContents = messageContents.splice(0, messageContents.length),
                            oldType     = type;

                        self.clearMetaNamespace();
                        config = Part.getTypes()[val];
                        self.clearContents();
                        parseConfig();
                        self.initWithEmptyContent();
                        type = val;
                        switch (type) {
                            case "AIReaction":
                                self.setMeta('topics', ['random']);
                                break;
                        }
                        this.resetContextSpecificPlaceholderCache();
                        self.migrateType(oldType, oldContents);
                    },
                    get: function () {
                        return type;
                    }
                    /**
                     * @property
                     * @name Model.Message.Part#type
                     * @type String
                     */
                },
                activeGroup: {
                    enumerable  : false,
                    configurable: true,
                    get         : function() {
                        return activeGroup;
                    },
                    set         : function(val) {
                        var current = activeGroup;

                        if (isNaN(parseInt(val))) {
                            val = 1;
                        }

                        if ((!activeGroup || val === 0) && messageContents.length) {
                            val = 1;
                        }

                        if (!config.allowMultiple && val > 1) {
                            val = 1;
                        } else if (val >= messageContents.length) {
                            val = messageContents.length;
                        }

                        if (current !== val) {
                            activeGroup = val;
                        }
                    }
                    /**
                     * @property
                     * @type {Number}
                     * @name Model.Message.Part#activeGroup
                     */
                },
                content : {
                    enumerable  : false,
                    configurable: true,
                    get         : function() {
                        return messageContents[activeGroup - 1];
                    }
                    /**
                     * @property
                     * @type {Model.Message.Content}
                     * @name Model.Message.Part#content
                     */
                },
                showAs : {
                    enumerable  : false,
                    configurable: true,
                    get         : function() {
                        if (showAsCache !== null) {
                            return showAsCache;
                        }

                        var retVal  = 'structuredBubble',
                            sets    = []
                            ;

                        if (messageContents.length > 1) {
                            showAsCache = retVal;
                            return showAsCache;
                        }

                        if (self.parent && self.parent.isIncoming) {
                            if ((self.type === AI_TEMPLATE && self.isFallback) || self.type === FORWARD_ACTION) {
                                showAsCache = 'nlp-fallback';

                                return showAsCache;
                            }
                            showAsCache = 'nlp';
                            return showAsCache;
                        }

                        for (var i in self.content.fieldNames) {
                            var name = self.content.fieldNames[i];
                            if (self.content[name] !== null && !(self.content[name] instanceof Object)) {
                                sets.push(name);
                            } else if (self.content[name] instanceof Object && self.content[name].toString && self.content[name].toString().length) {
                                sets.push(name);
                            }
                        }

                        if (!sets.length) {
                            // return, default, but don't cache
                            return retVal;
                        }

                        if (sets.length === 1) {
                            switch (sets.pop()) {
                                case 'body':
                                    showAsCache = 'bubble';
                                    return showAsCache;
                                case 'links':
                                    showAsCache = 'quick-replies';
                                    return showAsCache;
                            }
                        }

                        showAsCache = retVal;
                        return showAsCache;
                    }
                    /**
                     * @property
                     * @type {String}
                     * @name Model.Message.Part#showAs
                     */
                },
                parent : {
                    configurable: true,
                    set         : function(val) {
                        Object.instanceOf(val, Model.Message);

                        if (!parent || val.uuid !== parent.uuid) {
                            parent = val;
                            val.addPart(self);
                        }
                        this.resetContextSpecificPlaceholderCache();
                    },
                    get         : function() {
                        return parent;
                    }
                    /**
                     * @property
                     * @name Model.Message.Part#parent
                     * @type Model.Message
                     */
                },
                number : {
                    configurable: true,
                    enumerable  : true,
                    set         : function(val) {
                        number = val;
                    },
                    get         : function() {
                        return number;
                    }
                    /**
                     * Stores what position the messagePart is in
                     * @property {Number}
                     * @name Model.Message.Part#number
                     */
                },
                parentUUID : {
                    configurable: true,
                    enumerable  : true,
                    get         : function() {
                        if (self.parent) {
                            return self.parent.uuid;
                        } return null;
                    }
                    /**
                     * @property
                     * @name Model.Message.Part#parentUUID
                     * @type String
                     */
                },
                isInteractive : {
                    get : function() {
                        if (isInteractiveCache !== null) {
                            return isInteractiveCache;
                        }

                        if (self.type === 'QuickReplies') {
                            isInteractiveCache = true;
                            return isInteractiveCache;
                        }

                        if (self.globalLinks && self.globalLinks.getMessageTriggeringActions().length) {
                            isInteractiveCache = true;
                            return isInteractiveCache;
                        }

                        var isInteractive = false,
                            j
                            ;

                        for (var i in self.messageContents) {
                            var content             = self.messageContents[i],
                                ctaCollections      = content.getElementsOfType(Model.CTA.Collection),
                                actionCollections   = content.getElementsOfType(Model.Action.Collection)
                                ;

                            if (ctaCollections.length) {
                                for (j = 0; j < ctaCollections.length; j++) {
                                    if (ctaCollections[j].getMessageTriggeringActions().length) {
                                        isInteractive = true;
                                        break;
                                    }
                                }
                            }

                            if (actionCollections.length) {
                                for (j = 0; j < actionCollections.length; j++) {
                                    if (actionCollections[j].getMessageTriggeringAction()) {
                                        isInteractive = true;
                                        break;
                                    }
                                }
                            }
                        }
                        isInteractiveCache = isInteractive;
                        return isInteractiveCache;
                    }
                    /**
                     * @property
                     * @type {Boolean}
                     * @name Model.Message.Part#isInteractive
                     */
                },
                metaInformation: {
                    enumerable: true,
                    get: function () {
                        return this.getMetasJson();
                    }
                    /**
                     * @property
                     * @name Model.Message.Part#meta
                     * @type {Object}
                     */
                }
            }
        );

        this.resetCache = function resetCache() {
            isInteractiveCache = null;
            showAsCache = null;
        };


        this.canHaveMoreContent = function() {
            return !(messageContents.length >= (config.maxContent ? config.maxContent : 1)
                || (messageContents.length >= 1 && !config.allowMultiple));
        };

        this.canHaveLessContent = function() {
            return messageContents.length > (config.minContent || 1);
        };

        this.isPaginated = function() {
            return config.paginated;
        };

        this.needsPagination = function() {
            return config.paginated && this.messageContents.length > 1;
        };

        /**
         * @method
         * @param {Model.Message.Content} msgContent
         * @name Model.Message.Part#addContent
         */
        this.addContent = function addContent(msgContent) {

            Object.instanceOf(msgContent, Model.Message.Content);

            if (this.contentIndex(msgContent) > -1 || !this.canHaveMoreContent()) {
                return this;
            }

            messageContents.push(msgContent);
            if (!msgContent.parent) {
                msgContent.parent = this;
            }
            this.activeGroup = messageContents.length;
            return this;
        };

        /**
         * @method
         * @name Model.Message.Part#moveContent
         * @param {Number} fromPos
         * @param {Number} toPos
         */
        this.moveContent = function moveContent(fromPos, toPos) {
            if (fromPos === toPos) {
                return;
            }

            var content = messageContents.splice(fromPos, 1).pop();
            messageContents.splice(toPos, 0, content);
        };

        /**
         * Returns the index of the content, -1 if not found
         * @param msgContent
         * @returns {Number}
         */
        this.contentIndex = function contentIndex(msgContent) {
            Object.instanceOf(msgContent, Model.Message.Content);
            for (var i = 0; i < messageContents.length; i++) {
                if (messageContents[i].uuid === msgContent.uuid) {
                    return i;
                }
            }

            return -1;
        };

        /**
         * @method
         * @name Model.Message.Part#addEmptyContent
         */
        this.addEmptyContent = function addEmptyContent() {
            var msgContent = new Model.Message.Content(config.fields);
            return this.addContent(msgContent);
        };

        /**
         * Removes the active content
         */
        this.removeContent = function removeContent(content) {
            var index,
                contentIndex
                ;

            if (content) {
                Object.instanceOf(content, Model.Message.Content);
                if ((contentIndex = this.contentIndex(content)) > -1) {
                    index = contentIndex
                }
            } else {
                index = activeGroup - 1;
            }

            if (this.canHaveLessContent()) {
                messageContents.splice(index, 1);
            }

            if (activeGroup > messageContents.length) {
                activeGroup--;
            }

            this.resetCache();
        };

        /**
         * Clears the content-list
         */
        this.clearContents = function clearContents() {
            activeGroup = 0;
            messageContents = [];

            this.resetCache();
        };

        this.initWithEmptyContent = function() {
            if (this.messageContents.length < (config.minContent || 1)) {
                for (var i = 0; i < (config.minContent || 1); i++) {
                    this.addEmptyContent();
                }
            }
        };

        var parseConfig = function() {
            $.each(config.fields, function(index, element) {
                if (!element.options || !element.options.isMeta) {
                    return true;
                }
                self.setMeta(index, null);
                // TODO: check for type and then clone if necessary
                if (element.default instanceof Function) {
                    self[index] = element.default();
                } else {
                    self[index] = element.default;
                }
            });
        };
        this.type = _type || Part.DEFAULT_TYPE_OUTGOING;

        // init msgContents
        if (msgContents && msgContents.length > 0) {
            $.each(msgContents, function(index, value) {
                self.addContent(value);
            });
        }
        else {
            this.initWithEmptyContent();
        }

        /**
         * @property {Object}
         * @name Model.Message.Part#_pProto
         */
        Part._pProto.constructor.call(this, uuid);

        /**
         * Override default Model.Behavior.Meta#updateMetaByData function to resolve segment (un)subscriptions
         * @param {Object} data
         */
        this.updateMetaByData = function updateMetaByData(data) {
            data = this.prepareMetaData(data);
            // put action keys into the namespace 'actions'
            for (var propName in data) {
                var ns = null;
                if (Model.Action.Definition.all.findByName(propName)) {
                    ns = KEY_NS_ACTIONS;
                }

                // subscription value can contain segment(s)
                if (propName.search(/subscribe_user$/) !== -1) {
                    if (!(data[propName] instanceof Array)) {
                        data[propName] = [data[propName]];
                    }

                    data[propName] = data[propName].map(function(subscriptionValue) {
                        if (subscriptionValue.uuid) {
                            return Model.Segment.createByData(subscriptionValue);
                        }

                        return subscriptionValue;
                    })
                }


                if (propName === META_GLOBAL_LINKS) {
                    var ctaCollection = new Model.CTA.Collection();
                    ctaCollection.updateByData(data[propName]);
                    this.setMeta(propName, ctaCollection, ns);
                    continue;
                }

                this.setMeta(propName, data[propName], ns)
            }
        };

        this.__dontCloneProperties = function() {
            return ['parent', 'content'];
        };

        this.__getCloneArguments = function() {
            return [this.uuid, this.type];
        };

        var __extendCloneParent = this.__extendClone;

        this.__extendClone = function(original) {
            __extendCloneParent.call(this, original);
            this.clearContents();

            for (var i = 0; i < original.messageContents.length; i++) {
                this.addContent(original.messageContents[i].clone());
            }

            this.activeGroup = original.activeGroup;

        };

        /**
         * @property
         * @name Model.Message.Part#isFallback
         * @type {Boolean}
         */
    };

    Object.extendProto(Part, Model.UUID);

    /**
     * Default type for message-parts
     * @static
     */
    Part.DEFAULT_TYPE_OUTGOING = "Text";
    Part.DEFAULT_TYPE_INCOMING = "AIReaction";

    /**
     *
     * @param {string} fromType
     * @param fromData
     */
    Part.prototype.migrateType = function migrateType(fromType, fromData) {
        // try to convert data between types, not supported atm
    };

    /**
     * Removes the current part from the parent
     * @name Model.Message.Part#remove
     */
    Part.prototype.remove = function remove() {
        if (!this.parent) {
            return;
        }

        this.parent.removePart(this);
    };

    /**
     * Returns all attachment in the messagePart
     * @returns {{}}
     */
    Part.prototype.getAttachments = function getAttachments() {
        var attachments = {};
        for (var i = 0; i < this.messageContents.length; i++) {
            var group = this.messageContents[i];
            for (var j in group) {
                if (group[j] instanceof Model.sImage && group[j].reSizedImage && group[j].reSizedImage.isLocal) {
                    attachments[group[j].reSizedImage.uuid] = group[j].reSizedImage.file;

                    // it must be explicitly set that the original must be kept
                    if (!group[j].reSizedImage.getMeta('keepOriginal')) {
                        group[j] = group[j].reSizedImage;
                        continue;
                    }
                }
                if (group[j] instanceof Model.sFile && group[j].isLocal) {
                    attachments[group[j].uuid] = group[j].file;
                }
            }
        }

        return attachments;
    };

    /**
     * Updates the message-part by data
     * @method Model.Message.Part#getMessageContentByUuid
     * @param {string} uuid
     * @returns null|Model.Message.Content
     */
    Part.prototype.getMessageContentByUuid = function getMessageContentByUuid(uuid) {
        for (var i = 0; i < this.messageContents.length; i++) {
            if (this.messageContents[i].uuid === uuid) {
                return this.messageContents[i];
            }
        }
        return null;
    };

    /**
     * @returns {Model.CTA[]}
     */
    Part.prototype.getAllCTAs = function getAllCTAs() {
        var ctas =  [],
            content,
            ctaCollections,
            i
        ;

        if (this.globalLinks && this.globalLinks instanceof Model.CTA.Collection) {
            ctas.push.apply(ctas, this.globalLinks.ctas);
        }

        for (i in this.messageContents) {
            content             = this.messageContents[i];
            ctaCollections      = content.getElementsOfType(Model.CTA.Collection);

            if (!ctaCollections.length) {
                continue;
            }

            ctaCollections.map(function(ctaCollection) {
                ctas.push.apply(ctas, ctaCollection.ctas);
            });
        }

        return ctas;
    };

    /**
     * Returns all actions - with their related cta - found in a messagePart
     * @returns {{cta, action}[]}
     */
    Part.prototype.getAllActions = function getAllActions() {
        var actions = [],
            content,
            actionCollections,
            ctaCollections,
            i, j, k,
            allActions;

        if (this.globalLinks && this.globalLinks instanceof Model.CTA.Collection) {
            actions.push.apply(actions, this.globalLinks.getAllActions());
        }

        for (i in this.messageContents) {
            content             = this.messageContents[i];
            actionCollections   = content.getElementsOfType(Model.Action.Collection);
            ctaCollections      = content.getElementsOfType(Model.CTA.Collection);

            if (!ctaCollections.length && !actionCollections.length) {
                continue;
            }

            for (j = 0; j < actionCollections.length; j++) {
                var fakeCta = null;

                /**
                 * Emulate a CTA for AI Templates, because they act as a CTA
                 */
                if (this.type === AI_TEMPLATE) {
                    fakeCta = new Model.CTA(this.uuid);
                    fakeCta.actions = actionCollections[j];
                }
                allActions = actionCollections[j].actions;
                if (!allActions) {
                    continue;
                }
                for (k = 0; k < allActions.length; k++) {
                    actions.push.call(actions, {action: allActions[k], cta: fakeCta});
                }
            }

            for (j = 0; j < ctaCollections.length; j++) {
                actions.push.apply(actions, ctaCollections[j].getAllActions());
            }
        }
        return actions;
    };

    /**
     * Returns true if message part is a one time notification
     * @returns {boolean}
     */
    Part.prototype.isOneTimeNotification = function isOneTimeNotification() {
        return this.content && this.content.content && this.content.content.frequency === OTN_FREQUENCY_ONE_TIME;
    }

    /**
     * Returns all postback actions - with their related cta - found in a messagePart
     * @returns {{cta, action, content}[]}
     */
    Part.prototype.getAllMessagePostbackActions = function getAllMessagePostbackActions() {
        var postbackActions = [];

        if (!this.isInteractive) {
            return postbackActions;
        }

        if (this.globalLinks && this.globalLinks instanceof Model.CTA.Collection) {
            postbackActions.push.apply(postbackActions, this.globalLinks.getPostbackActions());
        }

        for (var i in this.messageContents) {
            var content             = this.messageContents[i],
                actionCollections   = content.getElementsOfType(Model.Action.Collection),
                ctaCollections      = content.getElementsOfType(Model.CTA.Collection),
                j
                ;

            if (!ctaCollections.length && !actionCollections.length) {
                continue;
            }

            for (j = 0; j < actionCollections.length; j++) {
                var fakeCta = null;

                if (this.type === AI_TEMPLATE) {
                    fakeCta = new Model.CTA(this.uuid);
                    fakeCta.actions = actionCollections[j];
                }
                if (actionCollections[j].getPostbackAction()) {
                    postbackActions.push.call(postbackActions, {action: actionCollections[j].getPostbackAction(), content: content, cta: fakeCta});
                }
            }

            for (j = 0; j < ctaCollections.length; j++) {
                postbackActions.push.apply(postbackActions, ctaCollections[j].getPostbackActions().map(function(element) { return $.extend(element, {content: content});}));
            }
        }

        return postbackActions;
    };

    /**
     * Updates the message-part by data
     * @method Model.Message.Part#updateByData
     * @param {object} messagePartData
     */
    Part.prototype.updateByData = function updateByData(messagePartData) {
        var msgContents = [],
            self        = this;

        /*
         * Handle MessagePart-Number
         */

        // reset number
        self.number = null;

        // fetchable message-part-number
        if (messagePartData.number) {
            self.number = messagePartData.number;
        }

        /*
         * Handle MessagePart-Type
         */

        // fetchable message-part-type
        if (typeof(messagePartData.type) === 'object' && messagePartData.type !== null) {
            self.type = messagePartData.type.alias;
        } else if (typeof(messagePartData.type) === 'string') {
            self.type = messagePartData.type;
        } else {
            self.type = Part.DEFAULT_TYPE_OUTGOING;
        }

        /*
         * Handle MessagePart-Contents
         */

        // fetchable message-part-contents
        if (messagePartData.messageContents && messagePartData.messageContents instanceof Array) {
            messagePartData.messageContents
                .filter(function(messageContentData) {
                    return messageContentData.uuid;
                })
                .sort(function(contentA, contentB) {
                    return contentA.number - contentB.number;
                })
                .map(function(messageContentData) {
                    var fields = Part.getTypes()[self.type].fields,
                        messageContent = self.getMessageContentByUuid(messageContentData.uuid);

                    // if not already there -> create
                    if (!messageContent) {
                        messageContent = new Model.Message.Content(fields, messageContentData.uuid);
                    }
                    messageContent.updateByData(messageContentData);

                    msgContents.push(messageContent);
                })
                ;
        }

        // add meta information
        if (messagePartData.metaInformation) {
            if (messagePartData.metaInformation.globalLinks && this.hasOwnProperty(META_GLOBAL_LINKS)) {
                this.globalLinks = new Model.CTA.Collection();
                this.globalLinks.updateByData(messagePartData.metaInformation.globalLinks);

                delete(messagePartData.metaInformation.globalLinks);
            }

            this.updateMetaByData(messagePartData.metaInformation);
        }

        // reset old contents
        self.clearContents();

        // fill contents
        $.each(msgContents, function(key, msgContent) {
            self.addContent(msgContent);
        });

        self.activeGroup = 0;
    };

    /**
     * @function Model.Message.Part#repair
     */
    Part.prototype.repair = function repair() {
        var i,
            msgContents = this.messageContents;

        for (i = 0; i < msgContents.length; i++) {
            msgContents[i].repair();
        }
    };

    /**
     * Alias for addEmptyContent
     */
    Part.prototype.addItem = function() {
        return this.addEmptyContent();
    };

    /**
     * Alias for removeContent
     */
    Part.prototype.removeItem = function() {
        return this.removeContent();
    };

    /**
     * Alias for canHaveMoreContent
     */
    /* istanbul ignore next */
    Part.prototype.canGainItem = function() {
        return this.canHaveMoreContent();
    };

    /**
     * Alias for canHaveLessContent
     */
    /* istanbul ignore next */
    Part.prototype.canLoseItem = function() {
        return this.canHaveLessContent();
    };

    /**
     * @method Model.Message.Part#isEmpty
     * @returns {boolean}
     */
    Part.prototype.isEmpty = function() {
        var contents    = this.messageContents,
            isEmpty     = true
            ;

        if (contents.length === 0) {
            return isEmpty;
        }

        for (var i = 0; i < contents.length; i++) {
            isEmpty = isEmpty && contents[i].isEmpty();
        }

        return isEmpty;
    };

    /**
     * @method Model.Message.Part#validate
     * @returns {boolean}
     */
    Part.prototype.validate = function validate() {
        for (var i = 0; i < this.messageContents.length; i++) {

            if (!this.messageContents[i].validate()) {
                return false;
            }
        }
        return true;
    };

    /**
     * @name Model.Message.Part#changeCoverOption
     */
    Part.prototype.changeCoverOption = function changeCoverOption() {
        for (var i = 0; i < this.messageContents.length; i++) {
            if (i === (this.activeGroup - 1)) {
                continue;
            }
            if (this.messageContents[i].media) {
                this.messageContents[i].media.resetReSizedImage();
            }
        }
    };

    /**
     * @param {Model.Action[]} actions
     */
    Part.prototype.updateActionsMeta = function updateActionsMeta(actions) {
        var self = this;

        if (this.type === TEXT) {
            return;
        }

        // update action meta info
        this.clearMetaNamespace(KEY_NS_ACTIONS);
        actions.map(function(action) {
            var value;

            value = action.value;
            try {
                value = JSON.parse(action.value);
            } catch (err) {
                // fail silently, it was only an attempt
            }


            if (action.type === Model.Action.TYPE_URL) {
                self.content.body = value;
                self.setMeta('isUrl', true, KEY_NS_ACTIONS);
                return;
            }

            var meta = self.getMeta(action.type, null, KEY_NS_ACTIONS);
            if (meta) {
                if (!(meta instanceof Array)) {
                    meta = [meta];
                    self.setMeta(action.type, meta, KEY_NS_ACTIONS);
                }
                meta.push(value);
                return;
            }

            self.setMeta(action.type, value, KEY_NS_ACTIONS);
        });

    };

    /**
     * Creates clone of current part but with unique uuids
     *
     * @return {Model.Message.Part}
     */
    Part.prototype.duplicate = function duplicate() {
        var cloneJson = JSON.parse(JSON.stringify(this)),
            newPart = new Model.Message.Part();

        Model.UUID.replaceUuids(cloneJson, Model.UUID.generateUuidV4);
        // type is expected to be a serialized type obj, so fake it
        cloneJson.type = {alias: cloneJson.type};
        newPart.updateByData(cloneJson);

        return newPart;
    };

    /**
     * @return {String[]}
     */
    Part.prototype.getEntities = function getEntities() {
        return this.messageContents.reduce(function(entities, content) {
            content.getElementsOfType(Model.AI.MatchTextCollection).map(function(matchTextCollection) {
                entities.push.apply(
                    entities,
                    matchTextCollection.getEntities()
                        .filter(function (entity) {
                            return !entity.getMeta(Model.AI.MatchTextCollection.KEY_PARTIAL_ENTITY);
                        })
                        .map(function (entity) {
                            return entity.name;
                        })
                );
            });

            return entities;
        }, []);
    };

    /**
     * @method
     * @static
     * @name Model.Message.Part#getTypes
     * @param {String=} restriction
     * @returns {Object}
     */
    Part.getTypes = function getTypes(restriction) {
        var outgoing =  {
            Text: {
                name: 'Text and Buttons',
                icon: '/svg/icons/messageType/text_and_buttons.svg',
                fields: {
                    body: $.extend(true, {}, RULES_TEXT_INPUT, {
                        options: {
                            attrs: {
                                's-maxlength'       : 640,
                                'required'          : true,
                                's-length-counter'  : '{model: $ctrl.model.body, max: \'sMaxlength\'}'
                            }
                        }}),
                    links: {
                        component: 'sCtaCollection',
                        template: '_form_row_action_collection',
                        default: function() {
                            return new Model.CTA.Collection()
                        },
                        options: {
                            label: 'Call to action'
                        },
                        labels: {
                            add: 'Add a Call-To-Action Button',
                            remove: 'Remove this Call-to-Action'
                        }
                    }
                }
            },
            Media: {
                name: 'Media',
                icon: '/svg/icons/messageType/media.svg',
                fields: {
                    media: {
                        component: 'sMedia',
                        options: {
                            highlightFromContent: true,
                            attrs: {
                                'is-required'   : true,
                                'accept'        : Part.acceptedMediaMimeTypes
                            }
                        }
                    },
                    links: {
                        component: 'sCtaCollection',
                        template: '_form_row_action_collection',
                        default: function() {
                            return new Model.CTA.Collection()
                        },
                        options: {
                            label: 'Call to action'
                        },
                        labels: {
                            add: 'Add a Call-To-Action Button',
                            remove: 'Remove this Call-to-Action'
                        }
                    }
                }
            },
            QuickReplies : {
                name: 'Quick Replies',
                icon: '/svg/icons/messageType/quick_replies.svg',
                fields : {
                    links: {
                        component: 'sCtaCollection',
                        template: '_form_row_action_collection',
                        default: function() {
                            return new Model.CTA.Collection()
                        },
                        options: {
                            attrs: {
                                'max-allowed-cta': 10,
                                'min-allowed-cta': 1
                            }
                        },
                        labels: {
                            add: 'Add a Quick Reply',
                            remove: 'Remove this Quick Reply'
                        }
                    }
                }
            },
            HScroll: {
                name: 'Carousel',
                icon: '/svg/icons/messageType/carousel.svg',
                allowMultiple: true,
                maxContent: 10,
                batchEdit: 'content',
                paginated: true,
                fields: {
                    coverOption: {
                        component: 'sRadioList',
                        default:   true,
                        options: {
                            label: 'Aspect ratio',
                            isMeta: true,
                            attrs: {
                                'options'   : '[[{"value": true, "label": "Landscape"}, {"value": false, "label": "Square"}]]',
                                'on-change' : '$ctrl.model.changeCoverOption()'
                            }
                        }
                    },
                    media: {
                        component: 'ImageUpload',
                        options: {
                            highlightFromContent: true,
                            attrs: {
                                'aspect-ratio'  : '$ctrl.model.getMediaAspectRatio()',
                                'max-dimension' : 1000,
                                'accept'        : Part.acceptedImageUploadMimeTypes,
                                'required-any'  : 'hScroll'
                            }
                        }
                    },
                    headline: $.extend(true, {}, RULES_TEXT_INPUT, {
                        options: {
                            attrs: {
                                's-maxlength'       : 80,
                                'required'          : true,
                                's-length-counter'  : '{model: $ctrl.model.headline, max: \'sMaxlength\'}'
                            }
                        }
                    }),
                    body: $.extend(true, {}, RULES_TEXT_INPUT, {
                        options: {
                            attrs: {
                                's-maxlength'       : 80,
                                'required-any'      : 'hScroll',
                                's-length-counter'  : '{model: $ctrl.model.body, max: \'sMaxlength\'}'
                            }
                        }
                    }),
                    links: {
                        component: 'sCtaCollection',
                        template: '_form_row_action_collection',
                        default: function() {
                            return new Model.CTA.Collection()
                        },
                        options: {
                            label: 'Call to action',
                            attrs: {
                                'required-any': 'hScroll'
                            }
                        },
                        labels: {
                            add: 'Add a Call-To-Action Button',
                            remove: 'Remove this Call-to-Action'
                        }
                    }
                }
            },
            ProductTemplate: {
                name: 'Product card',
                icon: '/svg/icons/messageType/product_card.svg',
                allowMultiple: true,
                maxContent: 10,
                batchEdit: 'content',
                paginated: true,
                permission : {'source.productTemplate' : ['GET']},
                fields: {
                    product: {
                        component: 'sProductCatalogEntrySelect',
                        options: {
                            attrs: {
                            },
                            label: 'Product ID'
                        },
                        default: function (value) {
                            var $deferred = $.Deferred();
                            setTimeout(function() {
                                if (this.product && Object.prototype.hasOwnProperty.call(this.product, 'catalogId')) {
                                    $deferred.resolve(this.product);
                                    return;
                                }
                                if (!this.parent || !this.parent.parent) {
                                    $deferred.resolve(null);
                                    return;
                                }
                                var action = this.parent.parent.getOriginatingDynamicContentAction();
                                if (!action) {
                                    $deferred.resolve(null);
                                    return;
                                }

                                var parsedValue = JSON.parse(action.value);
                                if (!parsedValue.sources_id) {
                                    $deferred.resolve(null);
                                    return;
                                }

                                $deferred.resolve({
                                    catalogId: parsedValue.sources_id,
                                    productId: TOKEN_PRODUCT_ID
                                });
                            }.bind(this), 0);

                            return $deferred;
                        }
                    },
                    media: {
                        component: 'ImageUpload',
                        options: {
                            highlightFromContent: true,
                            attrs: {
                                'is-disabled'   : true,
                                'accept'        : Part.acceptedMediaMimeTypes
                            }
                        },
                        default: function () {
                            return Model.sFile.loadFromUrl(DEFAULT_PRODUCT_IMAGE_URL, {url: '@image_link', fileName: '@Product.Image'}).then(function(file) {
                                return new Model.sImage(file);
                            });
                        }
                    },
                    headline: $.extend(true, {}, RULES_TEXT_INPUT, {
                        options: {
                            attrs: {
                                'contenteditable'   : false,
                                'class'             : 'input disabled',
                                's-maxlength'       : 80,
                                's-length-counter'  : '{model: $ctrl.model.headline, max: \'sMaxlength\'}'
                            }
                        },
                        default: '@title'
                    }),
                    body: $.extend(true, {}, RULES_TEXT_INPUT, {
                        options: {
                            attrs: {
                                'contenteditable'   : false,
                                'class'             : 'input disabled',
                                's-maxlength'       : 80,
                                's-length-counter'  : '{model: $ctrl.model.body, max: \'sMaxlength\'}'
                            }
                        },
                        default: '@price'
                    })
                }
            },
            ListView: {
                name: 'List view',
                icon: '/svg/icons/messageType/list_view.svg',
                minContent: 2,
                maxContent: 4,
                allowMultiple: true,
                paginated: false,
                batchEdit: 'messageContents',
                component: 'sDynamicFormAccordion',
                fields: {
                    coverOption: {
                        component: 'sCheckbox',
                        default: true,
                        options: {
                            label: 'Cover image',
                            isMeta: true,
                            attrs: {
                                'class'     : 'pull-right'
                            }
                        }
                    },
                    media: {
                        component: 'ImageUpload',
                        options: {
                            highlightFromContent: true,
                            attrs: {
                                'aspect-ratio'  : '$ctrl.model.getMediaAspectRatio()',
                                'max-dimension' : 300,
                                'is-required'   : '$ctrl.model.parent.coverOption && $ctrl.model.parent.contentIndex($ctrl.model) == 0 ',
                                'required-any'  : 'listView-{{$ctrl.model.parent.contentIndex($ctrl.model)}}',
                                'accept'        : Part.acceptedImageUploadMimeTypes
                            }
                        }
                    },
                    headline: $.extend(true, {}, RULES_TEXT_INPUT, {
                        options: {
                            attrs: {
                                's-maxlength'       : 80,
                                'required'          : true,
                                's-length-counter'  : '{model: $ctrl.model.headline, max: \'sMaxlength\'}'
                            }
                        }}),
                    body: $.extend(true, {}, RULES_TEXT_INPUT, {
                        options: {
                            attrs: {
                                's-maxlength'       : 80,
                                'required-any'      : 'listView-{{$ctrl.model.parent.contentIndex($ctrl.model)}}',
                                's-length-counter'  : '{model: $ctrl.model.body, max: \'sMaxlength\'}'
                            }
                        }}),
                    links: {
                        component: 'sCtaCollection',
                        template: '_form_row_action_collection',
                        default: function() {
                            return new Model.CTA.Collection()
                        },
                        options: {
                            label: 'Call to action',
                            attrs: {
                                'max-allowed-cta'   : 1,
                                'required-any'      : 'listView-{{$ctrl.model.parent.contentIndex($ctrl.model)}}'
                            }
                        },
                        labels: {
                            add: 'Add a Call-To-Action Button',
                            remove: 'Remove this Call-to-Action'
                        }
                    },
                    globalLinks: {
                        component: 'sCtaCollection',
                        template: '_form_row_action_collection',
                        default: function() {
                            return new Model.CTA.Collection()
                        },
                        options: {
                            isMeta: true,
                            label: 'Call to action',
                            attrs: {
                                'max-allowed-cta': 1
                            }
                        }
                    }
                }
            },
            OneTimeNotification : {
                name       : 'Re-engagement token',
                icon       : '/svg/icons/messageType/one_time_notification.svg',
                permission : {'messageAnchor.sendAsOneTimeNotification' : ['PUT']},
                fields     : {
                    frequency: {
                        component: 'sNotificationFrequencySelect',
                        default: OTN_FREQUENCY_WEEKLY,
                        options: {
                            attrs: {
                                'parent-model' : '$ctrl.model.parent',
                                'is-required'  : true,
                            },
                        }
                    },
                    media: {
                        component: 'sMedia',
                        options: {
                            highlightFromContent : true,
                            rowClass : 'otn-in-edit-media',
                            attrs: {
                                'aspect-ratio'  : '$ctrl.model.getMediaAspectRatio()',
                                'is-disabled'   : '$ctrl.model.parent.isOneTimeNotification()',
                                'accept'        : Part.acceptedImageUploadMimeTypes,
                                'is-required'   : '!$ctrl.model.parent.isOneTimeNotification()',
                            }
                        }
                    },
                    headline: $.extend(true, {}, RULES_TEXT_INPUT, {
                        options: {
                            attrs: {
                                's-maxlength'       : 65,
                                'required'          : false,
                                's-length-counter'  : '{model: $ctrl.model.headline, max: \'sMaxlength\'}'
                            }
                        }
                    }),
                    body  : {
                        component : 'div',
                        default   : 'You may receive additional messages on this topic over the next 9 months.',
                        options   : {
                            model : 'ng-model',
                            attrs : {
                                'contenteditable' : false,
                                'class'           : 'input disabled'
                            }
                        }
                    },
                    links : {
                        component : 'sCtaCollection',
                        template  : '_form_row_action_collection',
                        default   : function () {
                            var collection = new Model.CTA.Collection(),
                                CTA        = collection.createAndAddCTA(),
                                action     = new Model.Action();

                            CTA.label   = 'Get Weekly Messages';
                            action.type = Model.Action.TYPE_ONE_TIME_NOTIFICATION;
                            action.setMeta(Model.Action.META_READ_ONLY, true);
                            CTA.actions.addAction(action);

                            return collection;
                        },
                        options   : {
                            label : 'Call to action',
                            attrs : {
                                'max-allowed-cta' : 1,
                                'min-allowed-cta' : 1,
                                'disable-title'   : true
                            }
                        }
                    }
                }
            }
        };

        var incoming = {
            AIReaction: {
                name: 'AI Reaction',
                fields: {
                    matches: {
                        component: 'sMatchTextCollection',
                        options: {
                            attrs: {
                                'show-quality-score': true,
                                min: 1
                            }
                        },
                        default: function() {
                            return new Model.AI.MatchTextCollection();
                        }
                    },
                    negativeMatches: {
                        component: 'sMatchTextCollection',
                        options: {
                            label: 'Does not match',
                            attrs: {
                                'show-quality-score': true
                            }
                        },
                        default: function() {
                            var collection = new Model.AI.MatchTextCollection();
                            collection.isNegative = true;
                            return collection;
                        }
                    },
                    alternatives: {
                        component: 'sPostbackAlternatives',
                        default: function() {
                            return new Model.CTA.Collection();
                        },
                        options: {
                            label: '... then respond with',
                            attrs: {
                                'required-any'  : 'actions'
                            }
                        }
                    },
                    actions: {
                        component: 'sActionCollection',
                        default: function () {
                            return new Model.Action.Collection();
                        },
                        options: {
                            label: 'and/or perform',
                            attrs: {
                                'required-any'  : 'actions'
                            }
                        }
                    },
                    topics: {
                        component: 's-select',
                        options: {
                            attrs: {
                                'ng-required': true,
                                's-multiple': true,
                                'view-field' : "label",
                                'value-field' : "value",
                                'class' : "values-select",
                                'choices': "[{value: 'random', label: 'Direct messages'}, {value: 'comment', label: 'Post comments'}]",
                                'track-by' : "value",
                                'model' : '$ctrl.model.parent.topics' // get the meta of the message part
                            },
                            dontValidate: true,
                            label : 'Define template use'
                        }
                    }
                }
            },
            TextIn: {
                name: 'Text and Buttons',
                fields: {
                    body: {
                        component: 'sTextInput',
                        options: {
                            attrs: {
                                'with-emoji': true,
                                'max-length': 640,
                                'is-required': true
                            }
                        }
                    }
                }
            },
            AITemplate : {
                name: 'AI Template',
                icon: '/svg/icons/messageType/user_message.svg',
                fields: {
                    intent: {
                        component: 'sTextInput',
                        visibility: '!$ctrl.model.parent.isFallback',
                        options: {
                            label: 'User intent',
                            attrs: {
                                'is-required': true
                            }
                        }
                    },
                    matches: {
                        component: 'sMatchTextCollection',
                        default: function() {
                            return new Model.AI.MatchTextCollection();
                        },
                        visibility: '!$ctrl.model.parent.isFallback',
                        options: {
                            'show-quality-score': true,
                            label: 'If the user\'s message ...',
                            attrs: {
                                'min': '!$ctrl.model.parent.isFallback ? 1 : 0'
                            }
                        }
                    },
                    negativeMatches: {
                        component: 'sMatchTextCollection',
                        default: function() {
                            var collection = new Model.AI.MatchTextCollection();
                            collection.isNegative = true;
                            return collection;

                        },
                        visibility: '!$ctrl.model.parent.isFallback',
                        options: {
                            label: 'And the user\'s message ...',
                            attrs: {
                                'show-quality-score': true,
                                min : 0
                            }
                        }
                    },
                    actions: {
                        component: 'sActionCollection',
                        default: function() {
                            return new Model.Action.Collection()
                        },
                        options: {
                            label: '... then perform',
                            attrs: {
                                'required' : ''
                            }
                        }
                    },
                    isFallback: {
                        default: false,
                        options: {
                            isMeta: true
                        }
                    }
                }
            },
            ForwardAction : {
                name: 'Forward Action',
                fields: {
                    body: {
                        component: 'sTextInput',
                        options: {
                            attrs: {
                                'with-emoji': true
                            }
                        }
                    },
                    actions: {
                        component: 'sActionCollection',
                        default: function() {
                            return new Model.Action.Collection()
                        },
                        options: {
                            label: '... then perform'
                        }
                    }
                }
            },
            DynamicDispatcher: {
                name: 'Dynamic Action Dispatcher',
                fields: {
                    body: {
                        component: 'sTextInput',
                        options: {
                            attrs: {
                                'with-emoji': true,
                                'max-length': 640
                            }
                        }
                    },
                    alternatives: {
                        component: 'sCtaCollection',
                        default: function() {
                            return new Model.CTA.Collection();
                        },
                        options: {
                        }
                    }
                }
            }
        };

        if (restriction === Const.Outgoing) {
            return outgoing;
        } else if (restriction === Const.Incoming) {
            return incoming;
        } else {
            return $.extend(outgoing, incoming);
        }
    };

    /**
     * @param {String} type
     * @returns {Boolean}
     */
    Part.isIncomingType = function isIncomingType(type) {
        var types = Part.getTypes(Const.Incoming);

        return Boolean(types[type]);
    };

    /**
     * @param {String} type
     * @return {Model.Message.Part}
     */
    Part.create = function create(type) {
        if (!Part.getTypes()[type]) {
            throw 'Invalid type (' + type + ') requested!';
        }
        return new Part(null, type);
    };

    Part.TYPE = {};
    Part.TYPE.TEXT = 'Text';

    var acceptedMediaMimeTypes = 'image/gif,image/png,image/jpeg,image/bmp,image/x-ms-bmp,video/mp4,video/x-m4v,video/*,audio/*', // 'image/*,video/mp4,video/x-m4v,video/*,audio/*'
        acceptedImageUploadMimeTypes = 'image/gif,image/png,image/jpeg,image/bmp,image/x-ms-bmp';

    Object.defineProperties(
        Part,
        {
            acceptedMediaMimeTypes: {
                value: acceptedMediaMimeTypes
                /**
                 * @property
                 * @static
                 * @name Model.Message.Part#acceptedMediaMimeTypes
                 * @type {String}
                 */
            },
            acceptedImageUploadMimeTypes: {
                value: acceptedImageUploadMimeTypes
                /**
                 * @property
                 * @static
                 * @name Model.Message.Part#acceptedImageUploadMimeTypes
                 * @type {String}
                 */
            },
            TYPE_AI_TEMPLATE : {
                value: AI_TEMPLATE
                /**
                 * @property
                 * @static
                 * @name Model.Message.Part#TYPE_AI_TEMPLATE
                 * @type {String}
                 */
            },
            TYPE_AI_REACTION: {
                value: AI_REACTION
                /**
                 * @property
                 * @static
                 * @name Model.Message.Part#TYPE_AI_REACTION
                 * @type {String}
                 */
            },
            KEY_NS_ACTIONS: {
                value : KEY_NS_ACTIONS
                /**
                 * @static
                 * @property
                 * @name Model.Message.Part#KEY_NS_ACTIONS
                 * @type {String}
                 */
            },
            FORWARD_ACTION: {
                value : FORWARD_ACTION
                /**
                 * @static
                 * @property
                 * @name Model.Message.Part#FORWARD_ACTION
                 * @type {String}
                 */
            },
            AI_TEMPLATE: {
                value : AI_TEMPLATE
                /**
                 * @static
                 * @property
                 * @name Model.Message.Part#AI_TEMPLATE
                 * @type {String}
                 */
            },
            TYPE_CAROUSEL: {
                value : TYPE_CAROUSEL
                /**
                 * @static
                 * @property
                 * @name Model.Message.Part#TYPE_CAROUSEL
                 * @type {String}
                 */
            },
            TYPE_PRODUCT_TEMPLATE: {
                value : TYPE_PRODUCT_TEMPLATE
                /**
                 * @static
                 * @property
                 * @name Model.Message.Part#TYPE_PRODUCT_TEMPLATE
                 * @type {String}
                 */
            },
            TYPE_LISTVIEW: {
                value : TYPE_LISTVIEW
                /**
                 * @static
                 * @property
                 * @name Model.Message.Part#TYPE_LISTVIEW
                 * @type {String}
                 */
            },
            TOKEN_PRODUCT_ID: {
                value : TOKEN_PRODUCT_ID
                /**
                 * @static
                 * @property
                 * @name Model.Message.Part#TOKEN_PRODUCT_ID
                 * @type {String}
                 */
            },
            OTN_FREQUENCY_ONE_TIME: {
                value : OTN_FREQUENCY_ONE_TIME
                /**
                 * @static
                 * @property
                 * @name Model.Message.Part#OTN_FREQUENCY_ONE_TIME
                 * @type {String}
                 */
            },
            OTN_FREQUENCY_DAILY: {
                value : OTN_FREQUENCY_DAILY
                /**
                 * @static
                 * @property
                 * @name Model.Message.Part#OTN_FREQUENCY_DAILY
                 * @type {String}
                 */
            },
            OTN_FREQUENCY_WEEKLY: {
                value : OTN_FREQUENCY_WEEKLY
                /**
                 * @static
                 * @property
                 * @name Model.Message.Part#OTN_FREQUENCY_WEEKLY
                 * @type {String}
                 */
            },
            OTN_FREQUENCY_MONTHLY: {
                value : OTN_FREQUENCY_MONTHLY
                /**
                 * @static
                 * @property
                 * @name Model.Message.Part#OTN_FREQUENCY_MONTHLY
                 * @type {String}
                 */
            }
        });

    ns.Part = Part;

})(Object.namespace('Model.Message'));
