(function (ns) {
    /**
     * @namespace
     * @alias sSettings.Route.BotConfiguration.General
     * @extends sSettings.Route.Base
     * @constructor
     */
    var General = function General() {
        this.controller     = sSettings.Controller.BotConfiguration.General;
        this.templateUrl    = 'ssettings:botconfiguration/general';
        this.controllerAs   = '$ctrl';
        this.accessControl  = {'domain.domainData.RESTAccess': Const.Method.GET};

        General._pProto.constructor.apply(this, arguments);
    };

    Object.extendProto(General, sSettings.Route.Base);

    /**
     * @function
     * @name sSettings.Route.BotConfiguration.General#resolveDomain
     * @param {Service.sDomain} sDomainService
     * @returns {$.Deferred}
     */
    General.prototype.resolveDomain = function resolveDomain(sDomainService) {
        return sDomainService.getCurrentDomain();
    };

    ns.General = General;
})(Object.namespace('sSettings.Route.BotConfiguration'));