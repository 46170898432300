(function(ns) {
    var EVENT_PLACEHOLDERS_LIST = 'sPlaceholdersListEvent';

    /**
     * @namespace
     * @alias sSource.Directive.Controller.HintPlaceholder
     *
     * @param $scope
     * @param $filter
     * @param {sSource.Service.sSource} sSource
     * @constructor
     */
    var HintPlaceholder = function ($scope, $filter, sSource) {
        this.$scope     = $scope;
        this.$filter    = $filter;
        this.sSource    = sSource;
    };

    /**
     * @function
     * @name sSource.Directive.Controller.HintPlaceholder#$onInit
     */
    HintPlaceholder.prototype.$onInit = function $onInit() {
        var self = this,
            allowedTypes,
            allowedPlaceholders
        ;

        if (this.allowedTypes && this.allowedTypes.hasOwnProperty('allowedPlaceholders')) {
            allowedTypes        = [];
            allowedPlaceholders = this.allowedTypes.allowedPlaceholders;
        } else {
            allowedTypes = this.allowedTypes || [];
        }

        this.autocompleterCtrl.rules.unshift({
            pattern     : new RegExp("^@([^" + Controller.Directive.ContentEditableController.UNICODE_NBSP + Controller.Directive.ContentEditableController.UNICODE_SPACE +"]+)?$", 'i'),
            results     : function (term) {
                if (term === null) {
                    return self.sSource.getPlaceholders(self.getScopePlaceholders(), allowedTypes, allowedPlaceholders);
                }

                return self.sSource.getMatchingPlaceholders(term[1], self.getScopePlaceholders(), allowedTypes, allowedPlaceholders);
            },
            transformer : sSource.Service.sSource.transformer,
            itemTemplate: 'ssource:panel_item',
            panelClass  : 'panel-item',
            triggerIcon : '<i class="fal fa-at" title="Open variable picker"></i>',
            hint        : function(word) {
                if (!word) {
                    return 'Select a variable';
                }
                return 'Variable(s) matching: <b>' + word + '</b>';
            }
        });

        this.ngModelCtrl.$formatters.push(function (valueFromModel) {
            return self.$filter('resolvePlaceholders')(valueFromModel, true, self.getScopePlaceholders(), allowedTypes, allowedPlaceholders);
        });

        var $$runValidatorsAncestor = this.ngModelCtrl.$$runValidators;

        this.sSource.getPlaceholders(this.getScopePlaceholders()).then(
            /**
             * @param {Model.Source.Placeholder[]} placeholders
             */
            function(placeholders) {
                self.ngModelCtrl.$$runValidators = function $$runValidators(modelValue, viewValue, doneCallback) {
                    var valueResolve = function(carry, placeholder) {
                        if (!carry || !carry.replace) {
                            return carry;
                        }

                        carry = carry.replace(placeholder.token, placeholder.sampleValue);
                        return carry;
                    };

                // use a resolved value for validation
                $$runValidatorsAncestor.call(self.ngModelCtrl, placeholders.reduce(valueResolve, modelValue), placeholders.reduce(valueResolve, viewValue), doneCallback);
            }
        });

        // restore the $$runValidators to initial
        this.$scope.$on('$destroy', function() {
            self.ngModelCtrl.$$runValidators = $$runValidatorsAncestor;
        });
    };

    /**
     * @return {null|Model.Source.Placeholder[]}
     */
    HintPlaceholder.prototype.getScopePlaceholders = function getScopePlaceholders() {
        var placeholders = [];
        this.$scope.$emit(EVENT_PLACEHOLDERS_LIST, placeholders);

        return placeholders;
    };

    Object.defineProperties(
        HintPlaceholder,
        {
            EVENT_PLACEHOLDERS_LIST : {
                value : EVENT_PLACEHOLDERS_LIST
                /**
                 * @property
                 * @constant
                 * @name sSource.Directive.Controller.HintPlaceholder#EVENT_PLACEHOLDERS_LIST
                 * @type {String}
                 */
            }
        }
    );

    ns.HintPlaceholder = HintPlaceholder;
})(Object.namespace('sSource.Directive.Controller'));
