(function(ns) {
    /**
     * @namespace
     * @alias Model.Menu.Action
     *
     * @param label
     * @param action
     * @param options
     * @constructor
     */
    var Action = function(label, action, options) {
            /**
             * @name Model.Menu.Action#label
             * @type String
             */
        this.label  = label;

            /**
             * @name Model.Menu.Action#action
             * @type Function
             */
        this.action = action;

            /**
             * @name Model.Menu.Action#options
             * @type String
             */
        this.options  = options;

            /**
             * @property
             * @name Model.Menu.Entry#isAction
             * @returns {Boolean}
             */

        Action._pProto.constructor.call(this, Action);
    };

    Object.extendProto(Action, ns.Entry);

    Action.prototype.fromJSON = function fromJSON(json) {
        this.label = json.label || this.label;
        this.action = json.action || this.action;
        this.options = json.options || this.options;
    };

    ns.Entry.TYPES.ACTION = Action;
    Action.TYPE_IDENT = 'ACTION';

    ns.Action = Action;

})(Object.namespace('Model.Menu'));