(function(ns) {
    var KEY_TIME_AT         = 'at',
        KEY_TIME_BETWEEN    = 'between',
        timeOperatorOptions = [
            {
                value: KEY_TIME_AT,
                label: 'at'
            },
            {
                value: KEY_TIME_BETWEEN,
                label: 'between'
            }
        ]
    ;

    /**
     * This component holds a copy of a schedule-model to have the elements visible and working
     * even if the checkbox for time or day is not checked.
     * The values of it are just copied to the bound model if the related checkbox is checked,
     * otherwise it is nulled in the bound model or the model itself is set to null.
     *
     * @namespace
     * @alias Controller.Component.sScheduleRestraint
     * @constructor
     *
     * @param $scope
     */
    var sScheduleRestraint = function ($scope) {
        this.$scope                 = $scope;
        this.$deRegister            = [];
        this.refreshValuesEventName = 's-schedule-restraint_refreshValueChoices';

        this.isTimeRestraint        = false;
        this.isDayRestraint         = false;
        this.isRepeatRestraint      = false;
        this.timeRestraintOperator  = KEY_TIME_AT;
        this.timeOperatorOptions    = timeOperatorOptions;
        this.timezoneInfo           = moment.tz.guess() + ' (' + moment().tz(moment.tz.guess()).format('Z z') + ')';

        /**
         * @property
         * @name Controller.Component.sScheduleRestraint#model
         * @type Model.Schedule
         */

        /**
         * @property
         * @name Controller.Component.sScheduleRestraint#isTimeRestraint
         * @type boolean
         */

        /**
         * @property
         * @name Controller.Component.sScheduleRestraint#isDayRestraint
         * @type boolean
         */

        /**
         * @property
         * @name Controller.Component.sScheduleRestraint#isRepeatRestraint
         * @type boolean
         */
    };

    /**
     * @function
     * @name Controller.Component.sScheduleRestraint#$onInit
     */
    sScheduleRestraint.prototype.$onInit = function $onInit() {
        var self = this,
            handleModelChange = function handleModelChange() {
                if (self.model) {
                    self.schedule           = self.model.clone();
                    self.isTimeRestraint    = !!(self.model.time || self.model.timeEnd || self.model.useUserTimezone);
                    self.isDayRestraint     = !!(self.model.type || (self.model.values && self.model.values.length));
                    self.isRepeatRestraint  = !!self.model.repeat;
                }

                if (!self.schedule) {
                    self.schedule = new Model.Schedule();
                }
                digestIfNeeded(self.$scope);
            }
        ;

        this.$deRegister.push(this.$scope.$watch(
            function () {
                return JSON.stringify(self.model);
            },
            function () {
                handleModelChange();
            }
        ));

        this.$deRegister.push(this.$scope.$watch(
            function () {
                return JSON.stringify([
                    self.isTimeRestraint,
                    self.isDayRestraint,
                    self.isRepeatRestraint,
                    self.schedule
                ]);
            },
            function () {
                self.timeRestraintOperator = self.schedule.isTimeRange ? KEY_TIME_BETWEEN : KEY_TIME_AT;

                if (self.schedule.timeEnd && self.schedule.time > self.schedule.timeEnd) {
                    self.scheduleRestraintForm.$setValidity('timeEnd', false);
                } else {
                    self.scheduleRestraintForm.$setValidity('timeEnd', true);
                }

                if (!self.isTimeRestraint && !self.isDayRestraint && !self.isRepeatRestraint) {
                    self.model = null;
                    return;
                }

                var model = self.model || self.schedule.clone();

                if (self.isTimeRestraint) {
                    model.time              = self.schedule.time;
                    model.timeEnd           = self.schedule.timeEnd;
                    model.useUserTimezone   = self.schedule.useUserTimezone;
                    model.repeat            = null;
                } else {
                    model.time              = null;
                    model.timeEnd           = null;
                    model.useUserTimezone   = false;
                }

                if (self.isDayRestraint) {
                    model.type      = self.schedule.type;
                    model.values    = self.schedule.values.slice();
                } else {
                    model.type      = null;
                    model.values    = [];
                }

                if (self.isRepeatRestraint) {
                    model.repeat = self.schedule.repeat;
                } else {
                    model.repeat = null;
                }

                if (!self.model) {
                    self.model = model;
                }
                digestIfNeeded(self.$scope);
            }
        ));

        // initial set
        handleModelChange();
    };

    /**
     * @function
     * @name Controller.Component.sScheduleRestraint#$onDestroy
     */
    sScheduleRestraint.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name Controller.Component.sScheduleRestraint#compareTypeValues
     * @param {object} valA
     * @param {object} valB
     * @return {boolean}
     */
    sScheduleRestraint.prototype.compareTypeValues = function compareTypeValues(valA, valB) {
        return valA && valB && valA.value == valB.value;
    };

    /**
     * @function
     * @name Controller.Component.sScheduleRestraint#dayRestraintValueText
     * @param {Array} choices
     * @return {string|null}
     */
    sScheduleRestraint.prototype.dayRestraintValueText = function dayRestraintValueText(choices) {
        if (!choices || !choices.length || this.schedule.type !== Model.Schedule.TYPE_WEEKDAYS) {
            return null;
        }

        return choices.map(function (item) {
            return item.shorthand;
        }).join(', ');
    };

    /**
     * @function
     * @name Controller.Component.sScheduleRestraint#handleTimeRestraintOperatorChange
     * @param {object} item
     */
    sScheduleRestraint.prototype.handleTimeRestraintOperatorChange = function handleTimeRestraintOperatorChange(item) {
        if (!item) {
            throw 'There must be always an item given';
        }
        this.schedule.isTimeRange = item.value === KEY_TIME_BETWEEN;
    };

    /**
     * @function
     * @name Controller.Component.sScheduleRestraint#handleTypeSelectorChange
     * @param {object} item
     */
    sScheduleRestraint.prototype.handleTypeSelectorChange = function handleTypeSelectorChange(item) {
        this.$scope.$broadcast(this.refreshValuesEventName);
    };

    ns.sScheduleRestraint = sScheduleRestraint;
})(Object.namespace('Controller.Component'));
