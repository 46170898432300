(function (ns) {

    /**
     * @namespace
     * @memberOf Model.Source
     * @constructor
     */
    var Type = function Type(id, name, fields) {
        var fieldsCache = [],
            updateFieldsCache = function updateFieldsCache() {
                fieldsCache.splice.apply(fieldsCache, [0, fieldsCache.length].concat(fields));
            };

        Object.defineProperties(
            this,
            {
                id     : {
                    enumerable : true,
                    get        : function () {
                        return id;
                    }
                    /**
                     * @property
                     * @type Number
                     * @name Model.Source.Type#id
                     */
                },
                name   : {
                    enumerable : true,
                    get        : function () {
                        return name;
                    }
                    /**
                     * @property
                     * @type String
                     * @name Model.Source.Type#name
                     */
                },
                fields : {
                    enumerable : true,
                    get        : function () {
                        if (fieldsCache.length !== fields.length) {
                            updateFieldsCache();
                        }

                        return fieldsCache;
                    }
                    /**
                     * @property
                     * @type {Model.Source.Type.Field[]}
                     * @name Model.Source.Type#fields
                     */
                }
            }
        );
    };

    /**
     * @function
     * @name Model.Source.Type.createByData
     * @param {Object} data
     *
     * @returns {Model.Source.Type}
     */
    Type.createByData = function createByData(data) {
        var fields = data.fields.map(function (field) {
            return Model.Source.Type.Field.createByData(field);
        });

        return new Type(data.id, data.name, fields);
    };

    ns.Type = Type;
})(Object.namespace('Model.Source'));