(function(ns) {
    var mimeCheck = [
            {"a1 b2 c3 d4|d4 c3 b2 a1" : "pcap"},
            {"0a 0d 0d 0a" : "pcapng"},
            {"ed ab ee db" : "rpm"},
            {"53 50 30 31" : "bin"},
            {"00 00 00 00 00 00 00 00|00 00 00 00 00 00 00 00|00 00 00 00 00 00 00 00" : "PDB"},
            {"BE BA FE CA" : "DBA"},
            {"00 01 42 44" : "DBA"},
            {"00 01 44 54" : "TDA"},
            {"00 00 01 00" : "ico"},
            {"66 74 79 70 33 67" : "3gp|3g2"},
            {"1F 9D" : "z|tar.z"},
            {"1F A0" : "z|tar.z"},
            {"42 41 43 4B 4D 49 4B 45|44 49 53 4B" : "bac"},
            {"42 5A 68" : "bz2"},
            {"47 49 46 38 37 61|47 49 46 38 39 61" : "gif"},
            {"49 49 2A 00|4D 4D 00 2A|" : "tif|tiff"},
            {"49 49 2A 00 10 00 00 00|43 52" : "cr2"},
            {"80 2A 5F D7" : "cin"},
            {"53 44 50 58|58 50 44 53" : "dpx"},
            {"76 2F 31 01" : "exr"},
            {"42 50 47 FB" : "bpg"},
            {"FF D8 FF DB|FF D8 FF E0 00 10 4A 46 49 46 00 01|FF D8 FF EE|FF D8 FF E1 ?? ?? 45 78 69 66 00 00|FF D8 FF E2|FF D8 FF E3|FF D8 FF E8" : "jpg|jpeg"},
            {"46 4F 52 4D ?? ?? ?? ?? 49 4C 42 4D" : "ilbm|lbm|ibm|iff"},
            {"46 4F 52 4D ?? ?? ?? ?? 38 53 56 58" : "8svx|8sv|svx|snd|iff"},
            {"46 4F 52 4D ?? ?? ?? ?? 41 43 42 4D" : "acbm|iff"},
            {"46 4F 52 4D ?? ?? ?? ?? 41 4E 42 4D" : "anbm|iff"},
            {"46 4F 52 4D ?? ?? ?? ?? |41 4E 49 4D" : "anim|iff"},
            {"46 4F 52 4D ?? ?? ?? ?? 46 41 58 58" : "faxx|fax|iff"},
            {"46 4F 52 4D ?? ?? ?? ?? 46 54 58 54" : "ftxt|iff"},
            {"46 4F 52 4D ?? ?? ?? ?? 53 4D 55 53" : "smus|smu|mus|iff"},
            {"46 4F 52 4D ?? ?? ?? ?? 43 4D 55 53" : "cmus|mus|iff"},
            {"46 4F 52 4D ?? ?? ?? ?? 59 55 56 4E" : "yuvn|yuv|iff"},
            {"46 4F 52 4D ?? ?? ?? ?? 46 41 4E 54" : "iff"},
            {"46 4F 52 4D ?? ?? ?? ?? 41 49 46 46" : "aiff|aif|aifc|snd|iff"},
            {"49 4E 44 58" : "idx"},
            {"4C 5A 49 50" : "lz"},
            {"4D 5A" : "exe"},
            {"50 4B 03 04|50 4B 05 06|50 4B 07 08" : "zip|jar|odt|ods|odp|docx|xlsx|pptx|vsdx|apk|aar"},
            {"52 61 72 21 1A 07 00" : "rar"},
            {"52 61 72 21 1A 07 01 00" : "rar"},
            {"89 50 4E 47 0D 0A 1A 0A" : "png"},
            {"CA FE BA BE" : "class"},
            {"25 21 50 53" : "ps"},
            {"25 50 44 46 2d" : "pdf"},
            {"30 26 B2 75 8E 66 CF 11|A6 D9 00 AA 00 62 CE 6C" : "asf|wma|wmv"},
            {"4F 67 67 53" : "ogg|oga|ogv"},
            {"38 42 50 53" : "psd"},
            {"52 49 46 46 ?? ?? ?? ?? 57 41 56 45" : "wav"},
            {"52 49 46 46 ?? ?? ?? ?? 41 56 49 20" : "avi"},
            {"FF FB" : "mp3"},
            {"49 44 33" : "mp3"},
            {"42 4D" : "bmp|dib"},
            {"43 44 30 30 31" : "iso"},
            {"53 49 4D 50 4C 45 20 20|3D 20 20 20 20 20 20 20|20 20 20 20 20 20 20 20|20 20 20 20 20 54" : "fits"},
            {"66 4C 61 43" : "flac"},
            {"4D 54 68 64" : "mid|midi"},
            {"D0 CF 11 E0 A1 B1 1A E1" : "doc|xls|ppt|msg"},
            {"64 65 78 0A 30 33 35 00" : "dex"},
            {"4B 44 4D" : "vmdk"},
            {"43 72 32 34" : "crx"},
            {"41 47 44 33" : "fh8"},
            {"05 07 00 00 42 4F 42 4F|05 07 00 00 00 00 00 00|00 00 00 00 00 01" : "cwk"},
            {"06 07 E1 00 42 4F 42 4F|06 07 E1 00 00 00 00 00|00 00 00 00 00 01" : "cwk"},
            {"45 52 02 00 00 00|8B 45 52 02 00 00 00" : "toast"},
            {"78 01 73 0D 62 62 60" : "dmg"},
            {"78 61 72 21" : "xar"},
            {"50 4D 4F 43 43 4D 4F 43" : "dat"},
            {"4E 45 53 1A" : "nes"},
            {"75 73 74 61 72 00 30 30|75 73 74 61 72 20 20 00" : "tar"},
            {"74 6F 78 33" : "tox"},
            {"4D 4C 56 49" : "MLV"},
            {"37 7A BC AF 27 1C" : "7z"},
            {"1F 8B" : "gz|tar.gz"},
            {"FD 37 7A 58 5A 00 00" : "xz|tar.xz"},
            {"04 22 4D 18" : "lz4"},
            {"4D 53 43 46" : "cab"},
            {"46 4C 49 46" : "flif"},
            {"1A 45 DF A3" : "mkv|mka|mks|mk3d|webm"},
            {"4D 49 4C 20" : "stg"},
            {"41 54 26 54 46 4F 52 4D ?? ?? ?? ?? 44 4A 56" : "djvu|djv"},
            {"30 82" : "der"},
            {"44 49 43 4D" : "dcm"},
            {"77 4F 46 46" : "woff"},
            {"77 4F 46 32" : "woff2"},
            {"3c 3f 78 6d 6c 20" : "XML"},
            {"00 61 73 6d" : "wasm"},
            {"cf 84 01" : "lep"},
            {"43 57 53|46 57 53" : "swf"},
            {"21 3C 61 72 63 68 3E" : "deb"},
            {"52 49 46 46 ?? ?? ?? ?? 57 45 42 50" : "webp"},
            {"7B 5C 72 74 66 31" : "rtf"},
            {"00 00 01 BA" : "m2pvob"},
            {"00 00 01 BA|47|00 00 01 B3" : "mpgmpeg"},
            {"78 01|78 9C|78 DA" : "zlib"},
            {"62 76 78 32" : "lzfse"},
            {"4F 52 43" : "orc"},
            {"4F 62 6A 01" : "avro"},
            {"53 45 51 36" : "rc"},
            {"65 87 78 56" : "p25|obt"},
            {"55 55 aa aa" : "pcv"},
            {"78 56 34" : "pbt|pdt|pea|peb|pet|pgt|pict|pjt|pkt|pmt"},
            {"45 4D 58 32" : "ez2"},
            {"45 4D 55 33" : "ez3iso"},
            {"1B 4C 75 61" : "luac"},
            {"62 6F 6F 6B 62 6F 6F 6B 00 00 00 00 6D 61 72 6B" : "alias"},
            {"52 65 63 65 69 76 65 64" : "eml"},
            {"20 02 01 62 A0 1E AB 07 02 00 00 00" : "tde"},
            {"28 B5 2F FD" : "zst"}
        ],
        byMime          = {},
        bySignature     = {},
        buildIndices    = function buildIndices() {
            mimeCheck.map(function(element) {
                var signatureString = Object.getFirstPropertyName(element),
                    mimeString      = Object.getFirstProperty(element),
                    signatures      = signatureString.split('|').map(function(signature) {
                        return signature.replace(/[\s]/g, '').replace(/\?+/, '.*?');
                    }),
                    mimes           = mimeString.split('|')
                ;

                signatures.filter(function(signature){
                    return signature;
                }).map(function(signature) {
                    bySignature[signature] = bySignature[signature] || [];
                    bySignature[signature].push.apply(bySignature[signature], mimes);
                });

                mimes.map(function(mime) {
                    byMime[mime] = byMime[mime] || [];
                    byMime[mime].push.apply(byMime[mime], signatures);
                });
            });
        },
        checkMimeAndSignature = function(mime, signature) {
            mime = mime.toLowerCase();
            if (byMime.isEmpty() && bySignature.isEmpty()) {
                buildIndices();
            }

            var mimesBySignature = [],
                signaturesByMime,
                checkSignatureRegExp = function(signatureRegExp, _signature) {
                    var regExp = new RegExp("^" + signatureRegExp, 'i');
                    return _signature.search(regExp) !== -1;
                }
            ;

            for (var signatureRegExp in bySignature) {
                if (checkSignatureRegExp(signatureRegExp, signature)) {
                    mimesBySignature.push.apply(mimesBySignature, bySignature[signatureRegExp]);
                }
            }

            signaturesByMime = byMime[mime] || [];

            if (!signaturesByMime.length && !mimesBySignature.length) {
                return true;
            }

            return mimesBySignature.indexOf(mime) !== -1 && signaturesByMime.reduce(function(carry, element){
                if (carry) {
                    return carry;
                }
                carry = checkSignatureRegExp(element, signature);

                return carry;
            }, false);
        };

    /**
     * @namespace
     * @alias Model.sFile
     * Immutable File object
     * Handles file loading on the client layer
     * @param {Blob} file
     * @param {String=} uuid
     * @extends Model.UUID
     */
    var sFile = function(file, uuid) {

        Object.instanceOf(file, Blob);
        var self        = this,
            content,
            isLoading
        ;

        Object.defineProperties(
            this,
            {
                file: {
                    enumerable  : false,
                    configurable: false,
                    get         : function() {
                        return file;
                    }
                    /**
                     * @property
                     * @name Model.sFile#file
                     * @type {Blob}
                     */
                },
                content: {
                    enumerable  : false,
                    configurable: false,
                    get         : function() {
                        if (!content) {
                            self.loadContent();
                        }

                        return content;
                    }
                    /**
                     * @property
                     * @name Model.sFile#content
                     * @type {String}
                     */
                },
                isLocal: {
                    enumerable  : false,
                    configurable: false,
                    get         : function() {
                        return !uuid && !self.url;
                    }
                    /**
                     * @property
                     * @name Model.sFile#isLocal
                     * @type {Boolean}
                     */
                },
                url: {
                    enumerable: true,
                    get: function () {
                        return file.url ? file.url : '';
                    }
                    /**
                     * @property
                     * @name Model.sFile#url
                     * @type {String}
                     */
                },
                isLoaded: {
                    get: function () {
                        return isLoading && isLoading.state() !== 'pending';
                    }
                    /**
                     * @property
                     * @name Model.sFile#isLoaded
                     * @type {Boolean}
                     */
                }
            }
        );

        /**
         * Execute file loading and return a promise
         * @property {Function}
         * @returns {$.Deferred<Model.sFile>}
         */
        this.loadContent = function() {
            if (isLoading) {
                return isLoading;
            }

            isLoading       = $.Deferred();

            var fileReader      = new FileReader(),
                headerReader    = new FileReader()
            ;

            headerReader.onloadend = function(evt) {
                var loaded     = evt.currentTarget.result;
                var arr = (new Uint8Array(loaded));
                var header = "";
                for(var i = 0; i < arr.length; i++) {
                    var asString = arr[i].toString(16);
                    header += asString.length === 1 ? ('0' + asString) : asString;
                }

                var ext = file.type.split('/').pop();

                if (!checkMimeAndSignature(ext, header)) {
                    isLoading.reject('File signature and extension (mime type) mismatch!');
                }

                // resolve the promise with the file and the result
                fileReader.onloadend = function(evt) {
                    content     = evt.currentTarget.result;
                    isLoading.resolve(self);
                };

                fileReader.readAsDataURL(file);
            };

            headerReader.readAsArrayBuffer(file.slice(0,16));

            return isLoading.promise();
        };

        sFile._pProto.constructor.call(this, uuid);
    };

    Object.extendProto(sFile, Model.UUID);

    sFile.prototype.clone = function() {
        return this;
    };

    /**
     * Tries to load the file contents from an url.
     * @static
     * @name Model.sFile#loadFromUrl
     * @param {String} url
     * @param {Object=} data Optional data to be passed to the callback
     * @return {deferred.promise}
     */
    sFile.loadFromUrl = function(url, data) {
        data = data || {};
        return $.ajax({
            url: url,
            xhrFields: {
                withCredentials: false
            },
            dataType: "binary",
            processData: false
        }).then(function(blob, status, jqXhr) {
            blob.type = jqXhr.getResponseHeader('Content-Type');
            blob.fileName = blob.name = data.fileName;
            sFile.extendBlobWithUrl(blob, data.url || url);
            return $.Deferred().resolve(blob, data);
        });
    };

    /**
     * @param {Blob} blob
     * @param {String} url
     */
    sFile.extendBlobWithUrl = function extendBlobWithUrl(blob, url) {
        // add the url from where the file was loaded
        Object.defineProperties(
            blob,
            {
                url: {
                    value: url
                    /**
                     * @property
                     * @constant
                     * @name Blob#url
                     * @type {String}
                     */
                }
            });
    };

    /**
     * @name Model.sFile.
     * @param {File[]} files
     * @param {Number} maxFileSize
     * @param {String[]} acceptedTypes
     * @returns {Promise|void|*|undefined}
     */
    sFile.uploadFiles = function uploadFiles(files, maxFileSize, acceptedTypes) {
        var $deferred   = $.Deferred(),
            $deferreds  = [],
            self        = this
        ;

        this.exceptions = [];

        if (!files.length) {
            return $deferred.reject();
        }

        setTimeout(function() {
            $.each(files, function (index, element) {
                var sFile,
                    fileTypePassed,
                    acceptedTypeRegex,
                    i;

                sFile = new Model.sFile(element);
                if (sFile.file.size >= maxFileSize) {
                    $deferred.notify(new Model.Exception.FileTooLarge(sFile.file));
                    return true;
                }
                // check file-type
                fileTypePassed = false;
                if (sFile.file.type) {
                    for (i = 0; i < acceptedTypes.length; i++) {
                        acceptedTypeRegex = acceptedTypes[i].replace('*', '.*');
                        if (sFile.file.type.match(acceptedTypeRegex)) {
                            fileTypePassed = true;
                        }
                    }
                }
                if (!sFile.file.type || !fileTypePassed) {
                    $deferred.notify(new Model.Exception.FileInvalidType(sFile.file, sFile.file.type, self.acceptedTypes));
                    return true;
                }

                if (sFile.file.type.split('/').shift() === Model.sImage.CONST_TYPE_IMAGE) {
                    sFile = new Model.sImage(element);
                }

                // collect all deferreds
                $deferreds.push(
                    sFile.loadContent().fail(function(reason) {
                        // on failure push a notification with the failure
                        $deferred.notify(new Error(reason));
                    }).always(function() {
                        // check if there is any pending
                        if ($deferreds.filter(function($def) {
                                return $def.state() === 'pending';
                            }).length) {
                            return;
                        }

                        var resolved = $deferreds.filter(function($def) {
                            return $def.state() === 'resolved';
                        });

                        if (!resolved.length) {
                            $deferred.reject();
                            return;
                        }

                        // if all completed with resolve or reject, return the ones that are resolved
                        $.when.apply(window, resolved).then(function() {
                            $deferred.resolve(Array.prototype.slice.call(arguments));
                        });
                    })
                );
            });
        }, 0);

        return $deferred.promise();
    };

    ns.sFile = sFile;

})(Object.namespace('Model'));
