(function(ns) {
    /**
     * @namespace
     * @alias Model.Message.AnchorWithContent
     * @extends Model.Message.Anchor
     * @constructor
     */
    var AnchorWithContent = function() {
        AnchorWithContent._pProto.constructor.apply(this, arguments);

        var AIMatch = null,
            content
        ;

        Object.defineProperties(
            this,
            {
                'content' : {
                    get: function() {
                        return content;
                    },
                    set: function(val) {
                        Object.instanceOf(val, Model.Message.Content);
                        if (content !== val) {
                            content = val;
                        }
                    }
                },
                AIMatch: {
                    enumerable: true,
                    get: function() {
                        return AIMatch;
                    },
                    set: function (val) {
                        AIMatch = (val ? Model.AI.Match.createByData(val) : null);
                    }
                    /**
                     * @property
                     * @name Model.Message.AnchorWithContent#AIMatch
                     * @type {Model.AI.Match}
                     */
                }
            }
        )
    };

    Object.extendProto(AnchorWithContent, Model.Message.Anchor);

    /**
     * @name Model.Message.AnchorWithContent#updateByData
     * @param {Object} data
     */
    AnchorWithContent.prototype.updateByData = function updateByData(data) {
        AnchorWithContent._pProto.updateByData.call(this, data);

        if (data.firstContent) {
            var typeRules = Model.Message.Part.getTypes(),
                content;
            if (data.firstContent.type && typeRules[data.firstContent.type]) {
                content = new Model.Message.Content(typeRules[data.firstContent.type].fields);
                content.updateByData(data.firstContent);
            } else {
                content = new Model.Message.Content([]);
            }

            this.content = content;
        }

        if (data.priority) {
            this.priority = data.priority;
        }
    };

    /**
     * Creates a message-anchor-with-content by data
     * @function Model.Message.AnchorWithContent#createByData
     * @param {object} data
     * @static
     * @returns Model.Message.Anchor
     */
    AnchorWithContent.createByData = function createByData(data) {
        var uuid,
            msgAnchor;

        if (data && data.hasOwnProperty('uuid')) {
            uuid = data.uuid;
        }

        msgAnchor = new AnchorWithContent(uuid);

        msgAnchor.updateByData(data);

        return msgAnchor;
    };

    ns.AnchorWithContent = AnchorWithContent;
})(Object.namespace('Model.Message'));


