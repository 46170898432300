(function(angular) {
    var sDatadogRUM = angular.module('sDatadogRUMModule', []);

    sDatadogRUM.run([
        '$rootScope',
        function ($rootScope) {
            window.DD_RUM.init({
                applicationId: '93fa384b-0f5d-4786-8e44-cb34358c27d1',
                clientToken: 'pub839cca0ef268906e14e21c8f1567da80',
                site: 'datadoghq.eu',
                service: 'sfollowpung',
                env: sFollowPubNG.BugsnagReleaseStage, // environment is same as the one for Bugsnag
                sampleRate: 100,
                premiumSampleRate: 100, // if not included - default 100
                trackInteractions: true,
            })
             window.DD_RUM.startSessionReplayRecording();

            // specify the route/view to Datadog
            $rootScope.$on("$routeChangeStart", function (e, current) {
                window.DD_RUM.startView(
                    current.$$route.originalPath // route name with parameters /creator/:uuid for example
                );
            });
        }
    ]);
})(angular);