(function(ns) {
    var KEY_RESOURCE_LIMIT = 'resourceLimit',
        KEY_RESOURCE_USED  = 'resourceUsed',
        KEY_LIMIT_QUERY    = 'limitQuery'
    ;

    /**
     * @namespace
     * @alias Model.Endpoint
     * @constructor
     *
     * @param {String} name
     * @param {String=} pathPrefix
     */
    var Endpoint = function (name, pathPrefix) {
        pathPrefix = pathPrefix || '';
        /** @type {Object.<string, ?string>} */
        var values = {},
            resourceLimit,
            resourceUsed
        ;

        Object.defineProperties(this, {
            name          : {
                enumerable   : true,
                configurable : false,
                get          : function () {
                    return name;
                }
                /**
                 * @property
                 * @type {String}
                 * @name Model.Endpoint#name
                 */
            },
            limitQuery : {
                enumerable : true,
                writable   : true,
                /**
                 * @property
                 * @name Model.Endpoint#limitQuery
                 * @type {String}
                 */
            },
            resourceLimit : {
                enumerable : true,
                get        : function () {
                    return resourceLimit;
                },
                set        : function (val) {
                    val = parseInt(val);

                    resourceLimit = (val >= 0 ? val : null);
                }
                /**
                 * @property
                 * @name Model.Endpoint#resourceLimit
                 * @type {?Number}
                 */
            },
            resourceUsed  : {
                enumerable : true,
                get        : function () {
                    return resourceUsed;
                },
                set        : function (val) {
                    val = parseInt(val);

                    resourceUsed = (val >= 0 ? val : null);
                }
                /**
                 * @property
                 * @name Model.Endpoint#resourceUsed
                 * @type {?Number}
                 */
            },
            isLimited     : {
                enumerable : false,
                get        : function () {
                    return typeof this.resourceLimit === 'number' && typeof this.resourceUsed  === 'number';
                }
                /**
                 * @property
                 * @name Model.Endpoint#isLimited
                 * @type {Boolean}
                 */
            }
        });

        /**
         * Resets the endpoint to an initial state
         *
         * @function
         * @name Model.Endpoint#clear
         */
        this.clear = function clear() {
            [
                Const.Method.GET,
                Const.Method.POST,
                Const.Method.PUT,
                Const.Method.DELETE
            ].map(function(method) {
                values[method] = null;
            });
        };

        /**
         * Links a method with a path for this endpoint
         *
         * @function
         * @name Model.Endpoint#set
         * @param {String} method
         * @param {String} path
         */
        this.set = function set(method, path) {
            method = method.toUpperCase();
              if (!values.hasOwnProperty(method)) {
                  return;
              }
              values[method] = path;
        };

        /**
         * @function
         * @name Model.Endpoint#hasPath
         * @param {String} method
         * @returns {Boolean}
         */
        this.hasPath = function hasPath(method) {
            method = method.toUpperCase();
            return values.hasOwnProperty(method) ? !!values[method] : false;
        };

        /**
         * Links a method with a path for this endpoint
         *
         * @function
         * @name Model.Endpoint#path
         * @param {String} method
         * @param {...*} [dynamic_args] - zero or more args.
         */
        this.path = function path(method, dynamic_args) {
            method = method.toUpperCase();

            var rawPath = values.hasOwnProperty(method) ? values[method] : '',
                args    = Array.prototype.slice.call(arguments, 1);

            if (!rawPath) {
                return '';
            }

            return pathPrefix + rawPath.replace(/{[^{}]+}/gi, function(match){
                var found = args.shift();
                return found || match;
            });
        };

        this.clear();
    };

    /**
     * Shortcut for getting the path for the method 'get'
     *
     * @function
     * @name Model.Endpoint#get
     * @param {...*} [dynamic_args] - zero or more args.
     * @returns {String}
     */
    Endpoint.prototype.get = function get(dynamic_args) {
        var args = Array.prototype.slice.call(arguments, 0);
        args.unshift(Const.Method.GET);
        return this.path.apply(this, args);
    };

    /**
     * Shortcut for getting the path for the method 'post'
     *
     * @function
     * @name Model.Endpoint#post
     * @param {...*} [dynamic_args] - zero or more args.
     * @returns {String}
     */
    Endpoint.prototype.post = function post(dynamic_args) {
        var args = Array.prototype.slice.call(arguments, 0);
        args.unshift(Const.Method.POST);
        return this.path.apply(this, args);
    };

    /**
     * Shortcut for getting the path for the method 'put'
     *
     * @function
     * @name Model.Endpoint#put
     * @param {...*} [dynamic_args] - zero or more args.
     * @returns {String}
     */
    Endpoint.prototype.put = function put(dynamic_args) {
        var args = Array.prototype.slice.call(arguments, 0);
        args.unshift(Const.Method.PUT);
        return this.path.apply(this, args);
    };

    /**
     * Shortcut for getting the path for the method 'delete'
     *
     * @function
     * @name Model.Endpoint#delete
     * @param {...*} [dynamic_args] - zero or more args.
     * @returns {String}
     */
    Endpoint.prototype.delete = function (dynamic_args) {
        var args = Array.prototype.slice.call(arguments, 0);
        args.unshift(Const.Method.DELETE);
        return this.path.apply(this, args);
    };

    Object.defineProperties(
        Endpoint,
        {
            KEY_RESOURCE_LIMIT : {
                value : KEY_RESOURCE_LIMIT
                /**
                 * @static
                 * @property
                 * @name Model.Endpoint#KEY_RESOURCE_LIMIT
                 * @type {String}
                 */
            },
            KEY_RESOURCE_USED : {
                value : KEY_RESOURCE_USED
                /**
                 * @static
                 * @property
                 * @name Model.Endpoint#KEY_RESOURCE_USED
                 * @type {String}
                 */
            },
            KEY_LIMIT_QUERY : {
                value : KEY_LIMIT_QUERY
                /**
                 * @static
                 * @property
                 * @name Model.Endpoint#KEY_LIMIT_QUERY
                 * @type {String}
                 */
            }
        }
    );

    ns.Endpoint = Endpoint;
})(Object.namespace('Model'));
