(function(angular) {

    var module = angular.module('sSourceModule');

    module
        /**
         * @ngdoc component
         * @name sSourceConditionCollection
         * @restrict E
         *
         * @param {Model.Source.Condition.Collection}    model
         * @param {Model.Source} source
         */
        .component(
            'sSourceConditionCollection', {
                templateUrl : 'ssource/components:s-source-condition-collection',
                controller  : sSource.Controller.Component.sConditionCollection,
                bindings: {
                    model   : '=',
                    source  : '<'
                }
        })
        /**
         * @ngdoc component
         * @name sSourceSortColumnPicker
         * @restrict E
         *
         * @param {Model.Source.Condition.Collection}    model
         * @param {Model.Source} source
         * @param {Boolean=} ngRequired
         */
        .component(
            'sSourceSortColumnPicker', {
                templateUrl : 'ssource/components:s-source-sort-column-picker',
                controller  : sSource.Controller.Component.sSourceSortColumnPicker,
                bindings: {
                    model       : '=',
                    source      : '<',
                    ngRequired  : '='
                }
            }
        )
    ;
})(angular);
