(function(ns){
    /**
     * @namespace
     * @alias Model.Exception.InvalidActionError
     * @constructor
     *
     * @param {Model.Action} action
     */
    var InvalidActionError = function(action) {
        Object.instanceOf(action, Model.Action);

        this.name       = 'InvalidActionError';
        this.message    = 'Action ' + (action.type ? ('`'+action.type+'`') : '') + ' can\'t be added!';
        this.action     = action;
    };

    Object.extendProto(InvalidActionError, Error);

    ns.InvalidActionError = InvalidActionError;
})(Object.namespace('Model.Exception'));
