(function (ns) {
    /**
     * @namespace
     * @alias sSource.Controller.ContentOverview
     * @constructor
     *
     * @param $rootScope
     * @param $scope
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {sHeadline.Service.sTitle} sTitle
     * @param {sSource.Service.sSource} sSource
     * @param {Model.Source} source
     */
    var ContentOverview = function Overview($rootScope, $scope, sAPIAccess, sTitle, sSource, source) {
        var self = this;

        this.sAPIAccess     = sAPIAccess;
        this.sSource        = sSource;
        this.source         = source;
        this.list           = this.getList();
        this.$deRegister    = [];

        var setTitleFunction = function setTitleFunction() {
            sTitle.title = 'Source > ' + source.label;
            sTitle.subTitle = 'Content Entries (' + self.list.pager.totalRecords + ')';
        };

        $scope.$on('$destroy', this.$onDestroy.bind(this));

        this.$deRegister.push($rootScope.$on(sHeadline.Service.sTitle.EVENT_TITLE_SET, function () {
            setTitleFunction();
        }));

        this.$deRegister.push($scope.$watch(function () {
            return self.list.pager.totalRecords;
        }, function() {
            setTitleFunction();
        }));
    };

    /**
     * @function
     * @name sSource.Controller.ContentOverview#$onDestroy
     */
    ContentOverview.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name sSource.Controller.ContentOverview#getList
     * @returns {Model.List}
     */
    ContentOverview.prototype.getList = function getList() {
        var self = this,
            column,
            boundConstructor =
                // this is js magic, the constructor will have the parent object bound, this makes also the next call to use the uuid as first argument
                // see:
                // var A = function(first, second) {
                //     console.log(first, second);
                // }
                // new (A.bind(null, 'one'))('two')
                Model.Source.Content.bind(null, self.source)
        ;

        boundConstructor._pProto = Model.Source.Content._pProto;
        boundConstructor.createByData = function(data) {
            return new boundConstructor(data.uuid, data.content, data.timeCreated);
        };

        var list = new Model.List.RESTAccess(
                boundConstructor,
                this.sAPIAccess.endpoint('source.content.all').get(this.source.uuid)
            )
        ;

        if (!this.source.fields || typeof this.source.fields.map !== 'function') {
            return list;
        }

        column = list.columns.createAndAdd('timeCreated');
        column.isSortable = true;
        column.label = 'Created';

        this.source.fields.map(function (fieldName) {
            var dynColumn = list.columns.createAndAdd(
                Model.Source.Content.KEY_CONTENT_FIELD_PREFIX + fieldName
            );
            dynColumn.isSortable = true;
            dynColumn.label = fieldName;
        });

        list.filterComponents = {
            keyword: {
                component: 'sTextInput',
                options: {
                    defaultValue: '',
                    attrs: {
                        'placeholder': 'Text',
                        'class'      : 'textsearch',
                        'is-disabled': '$ctrl.helperControls.readonly'
                    },
                    label: null
                }
            }
        };

        return list;
    };

    ns.ContentOverview = ContentOverview;
})(Object.namespace('sSource.Controller'));
