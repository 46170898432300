(function($) {
    /**
     * This transport is using/extracting results from the results of another xhr response instead of firing a new.
     */
    $.ajaxTransport("+resolvewithxhr", function(options, originalOptions, jqXHR){
        if (!options.extractorFn) {
            return;
        }

        return {
            // create new XMLHttpRequest
            send: function(headers, callback){
                var res = options.extractorFn(options);
                options.responseType = 'text';
                jqXHR.responseType = 'text';
                callback(200, 'success', {resolvewithxhr: res});
            },
            abort: function(){
                jqXHR.abort();
            }
        };
    });
})(jQuery);