(function (ns) {
    /**
     * @namespace
     * @alias Controller.Component.View.sSegmentCollection
     *
     * @constructor
     * @param {sSegment.Service.sSegment} sSegment
     * @param $scope
     */
    var sSegmentCollection = function (sSegment, $scope) {
        this.sSegment       = sSegment;
        this.segments       = [];

        /**
         * @property
         * @name Component.Controller.View.sSegmentCollection#hashes
         * @type {String[]}
         */
    };

    /**
     * @param {Object.<string, SimpleChange>} $changes
     */
    sSegmentCollection.prototype.$onChanges = function $onChanges($changes) {
        if ($changes && $changes.hashes) {
            this.resolveHashes($changes.hashes.currentValue);
        }
    };

    /**
     * @param {String[]} hashes
     */
    sSegmentCollection.prototype.resolveHashes = function resolveHashes(hashes) {
        var self = this;
        Object.instanceOf(hashes, Array);
        self.segments.splice(0);
        hashes.map(function(hash) {
            if (hash instanceof Model.Segment) {
                self.segments.push(hash);
                return;
            }

            self.sSegment.resolveSegmentHash(hash).then(function(segment) {
                self.segments.push(segment);
            });
        });
    };

    ns.sSegmentCollection = sSegmentCollection;
})(Object.namespace('Controller.Component.View'));