(function(ns){
    /**
     * @alias {Model.Message.Placeholder}
     * @param {Model.Message} message
     * @param {String} type
     */
    var PlaceHolder = function(message, type) {
    /**
     * @name Model.Message.PlaceHolder#uuid
     */

        Object.defineProperties(
            this,
            {
                'message' : {
                    get: function() {
                        return message;
                    }
                },
                'type' : {
                    get: function() {
                        return type;
                    }
                }
            }
        );

        Model.Message.PlaceHolder._pProto.constructor.call(this);
    };

    Object.extendProto(PlaceHolder, Model.UUID);

    ns.PlaceHolder = PlaceHolder;

})(Object.namespace('Model.Message'));

