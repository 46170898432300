(function(ns) {
    /**
     * @namespace
     * @alias Controller.sMessageAdmin.EditorController
     *
     * @param {Model.Message.Part} messagePart
     * @param $mdDialog
     * @param $scope
     * @param sDomainService
     * @param noPostbackMessages
     * @param apiEndpoints
     * @param $mdPanel
     * @constructor
     */
    var EditorController = function(
        messagePart,
        $mdDialog,
        $scope,
        sDomainService,
        noPostbackMessages,
        apiEndpoints,
        $mdPanel
    ) {
        var isIncoming  = Model.Message.Part.isIncomingType(messagePart.type),
            self        = this,
            context
        ;

        /** @type {Model.Message.Part} */
        this.messagePart            = messagePart.clone();
        this.$mdDialog              = $mdDialog;
        this.$mdPanel               = $mdPanel;
        this.types                  = Model.Message.Part.getTypes(isIncoming ? Const.Incoming : Const.Outgoing);
        this.$scope                 = $scope;
        this.dummyMessage           = new Model.Message();
        this.originalMessagePart    = messagePart;
        this.sDomainService         = sDomainService;
        this.$deRegister            = [];
        this.apiEndpoints           = apiEndpoints;
        this.groupsLoading          = [];

        this.dummyMessage.addPart(this.messagePart);

        // the dummy message should reflect the original message's placeholders
        this.dummyMessage.getContextSpecificPlaceholders = function getContextSpecificPlaceholders() {
            return self.message.getContextSpecificPlaceholders();
        };

        // the dummy message should reflect the original message's originating action
        this.dummyMessage.getOriginatingDynamicContentAction = function getOriginatingDynamicContentAction() {
            return self.message.getOriginatingDynamicContentAction();
        }

        this.$deRegister.push($scope.$on('action-added', this.handleActionAdded.bind(this)));

        this.$deRegister.push($scope.$on(
            sSource.Directive.Controller.HintPlaceholder.EVENT_PLACEHOLDERS_LIST,
            this.handleGetContextSpecificPlaceholders.bind(this)
        ));

        this.$deRegister.push($scope.$on(
            Controller.Component.sMemberAttributeSelect.EVENT_REQUEST_CONTEXT,
            this.handleContextForMemberAttributes.bind(this)
        ));

        this.$deRegister.push($scope.$on(
            Controller.Component.sMatchTextCollection.EVENT_COLLECTION_ENTITIES_CHANGED,
            function() {
                $scope.$broadcast(Controller.Component.sMemberAttributeSelect.EVENT_MEMBER_VALUE_REFRESH)
            }
        ));

        this.$deRegister.push($scope.$on(
            Controller.Component.sProductCatalogEntrySelect.EVENT_GET_CONTEXT_ON_CATALOG_ID,
            this.handleGetContextOnCatalogId.bind(this)
            )
        );

        this.$deRegister.push($scope.$on(
            Controller.Component.FileUploadController.EVENT_FILE_CHANGED,
            digestIfNeeded.bind(null, $scope)
        ));

        this.$deRegister.push($scope.$on(
            Controller.Component.FileUploadController.EVENT_BROWSE_REMOTE_FILES,
            function(event) {
                event.files = self.message.getContextSpecificPlaceholders().reduce(
                    /**
                     * @param {Array} carry
                     * @param {Model.Source.Placeholder} placeholder
                     * @return {Array}
                     */
                    function(carry, placeholder) {
                        if (!placeholder.isFile) {
                            return carry;
                        }

                        carry.push(placeholder);
                        return carry;
                    }, []);
            }
        ));

        this.$deRegister.push($scope.$watch(
            function() {
                return self.messagePart.activeGroup;
            },
            function() {
                self.$scope.$broadcast('creator-edit-active-group-change');
            }
        ));

        $scope.$on('$destroy', function() {
            var $destroyFn;
            while (($destroyFn = self.$deRegister.pop())) {
                $destroyFn();
            }
        });

        Object.defineProperty(
            this,
            'actionContext',
            {
                get : function() {
                    if (!context) {
                        context = '';
                        if (Model.Message.Part.isIncomingType(self.messagePart.type)) {
                            context += self.messagePart.isFallback ? 'Fallback' : 'NLP';
                        } else {
                            context += self.messagePart.type;
                        }

                        context += (noPostbackMessages ? '-no-postback' : '');

                    }
                    return context;
                }
            }
        );

        this.changeMessageType = function changeMessageType(type) {
            var changeHelperFn = function changeHelperFn() {
                // reset cached context
                context = null;
                self.messagePart.type = type;
                digestIfNeeded(self.$scope);
            };

            if (!this.messagePart.isEmpty()) {
                // @info: mdDialog or sConfirm will not work since there is just one dialog at a time allowed
                if (true === window.confirm('You are going to lose your content for this message')) {
                    changeHelperFn();
                }
            } else {
                changeHelperFn();
            }
        };

        if ([Model.Message.Part.TYPE_CAROUSEL, Model.Message.Part.TYPE_PRODUCT_TEMPLATE].indexOf(messagePart.type) !== -1) {
            this.removeActionMenu = new Model.Menu.Menu();
            this.removeActionMenu.addAction('Remove all others', this.removeAllOthers.bind(this));
            this.removeActionMenu.addAction(
                'Remove all after current',
                this.removeAllAfterActive.bind(this),
                {
                    isDisabled: function() {
                        return self.messagePart.activeGroup === self.messagePart.messageContents.length;
                    }
                }
            );

            this.addActionMenu = new Model.Menu.Menu();
            this.addActionMenu.addAction('Add one new page based on current', this.cloneToNextBubble.bind(this));
            this.addActionMenu.addAction('Fill all empty pages based on current', this.fillWithClone.bind(this));
        }

        /**
         * @property
         * @name Controller.sMessageAdmin.EditorController#messages
         * @type {Model.Message[]}
         */

        /**
         * @property
         * @name Controller.sMessageAdmin.EditorController#message
         * @type {Model.Message}
         */
    };

    EditorController.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    EditorController.prototype.handleGetContextOnCatalogId = function handleGetContextOnCatalogId(event, data) {
        var action = this.message.getOriginatingDynamicContentAction();

        if (!action) {
            return;
        }

        if (JSON.parse(action.value)['sources_id'] === data.catalogId) {
            data.matching = true;
        }
    };

    EditorController.prototype.handleActionAdded = function handleActionAdded(event, action) {
        var self = this
            ;

        if (!self.messagePart.content.isEmpty(['links'])) {
            return;
        }

        if (action.type === Const.GoToURL) {
            var group       = self.messagePart.activeGroup,
                groupIndex  = group - 1
                ;

            self.groupsLoading.push(group);
            $.get(this.apiEndpoints.content.parse(), {url: action.value}).then(function(data) {
                if (self.messagePart.content.hasOwnProperty('headline')) {

                    var jsonContent = {
                        content: {
                            headline: data.title,
                            body    : data.description
                        }
                    };

                    if (data.images.length) {
                        var image = data.images.shift();
                        jsonContent.media = {
                            url: image.secureUrl ? image.secureUrl : image.url.replace(/^http:/, 'https:')
                        }
                    }

                    self.messagePart.messageContents[groupIndex].updateByData(jsonContent).then(function() {
                        digestIfNeeded(self.$scope);
                    });
                }
            }).always(function() {
                digestIfNeeded(self.$scope);
                var index;
                if ((index = self.groupsLoading.indexOf(group)) !== -1) {
                    self.groupsLoading.splice(index, 1);
                }
            })
        }
    };

    /**
     * Updates incoming event with additional placeholders convenient in current context
     *
     * @function
     * @param {Object} event
     * @param {[]} placeholders
     */
    EditorController.prototype.handleGetContextSpecificPlaceholders = function handleGetContextSpecificPlaceholders(event, placeholders) {
        var contextPlaceholders = this.messagePart.getContextSpecificPlaceholders() || [];
        placeholders.push.apply(placeholders, contextPlaceholders);

        // add the product

        this.messagePart.getEntities().map(function (matchGroupValue) {
            var sourceData = {
                    label        : 'Entity',
                    description  : 'Entities captured from user input',
                    isCollection : false,
                    context      : 'string'
                },
                placeholderData = {
                    label       : matchGroupValue.substring(1),
                    description : matchGroupValue.substring(1),
                    token       : matchGroupValue,
                    type        : 'string',
                    source      : Model.Source.createByData(sourceData)
                };

            placeholders.push(Model.Source.Placeholder.createByData(placeholderData));
        });
    };

    EditorController.prototype.onSave = function onSave() {
        this.messagePart.number = this.originalMessagePart.number;
        this.$mdDialog.hide({
            part        : this.messagePart,
            message     : this.message,
            index       : this.index,
            relateFrom  : this.relateFrom
        });
    };

    /**
     * @name Controller.sMessageAdmin.EditorController#triggerButton
     * @param cta
     */
    EditorController.prototype.triggerButton = function triggerButton(cta) {
        var collection  = this.messagePart.content.getElementsOfType(Model.CTA.Collection)
            ;

        if (!collection.length) {
            return;
        }

        for (var i = 0; i < collection[0].ctas.length; i++) {
            if (cta.uuid === collection[0].ctas[i].uuid) {
                this.$scope.$broadcast('accordion-changed', (i - -1));
            }
        }
    };

    EditorController.prototype.removeAllAfterActive = function removeAllAfterActive() {
        var self = this,
            index = (this.messagePart.activeGroup - 1)
        ;

        this.messagePart.messageContents.map(function(messageContent) {
            if (self.messagePart.contentIndex(messageContent) <= index) {
                return;
            }

            self.messagePart.removeContent(messageContent);
        })
    };

    EditorController.prototype.removeAllOthers = function removeAllOthers() {
        var fromPos = this.messagePart.activeGroup - 1;
        this.messagePart.moveContent(fromPos, 0);
        this.messagePart.activeGroup = 1;
        this.removeAllAfterActive();
    };

    EditorController.prototype.cloneToNextBubble = function cloneToNextBubble() {
        this.messagePart.addContent(this.messagePart.content.duplicate());
    };

    EditorController.prototype.fillWithClone = function fillWithClone() {
        var activeGroup = this.messagePart.activeGroup;

        while (this.messagePart.canHaveMoreContent()) {
            this.cloneToNextBubble();
            this.messagePart.activeGroup = activeGroup;
        }
    };

    /**
     * Enriches the received context based on the part (key or value) of the attribute
     * @param event
     * @param context
     */
    EditorController.prototype.handleContextForMemberAttributes = function handleContextForMemberAttributes(event, context) {
        // for name consider messages in the user journey before the current message
        // only the shortest path to the root is considered
        if (context.type === Controller.Component.sMemberAttributeSelect.TYPE_ATTRIBUTE_NAME) {
            // get attribute names that were set previously to support the user selection
            var messageNodes = Model.Message.Node.createGraphNodesFromRoot(this.message);

            messageNodes.shift();
            context.messages = messageNodes.map(function(messageNode) {
                return messageNode.message;
            });
            event.stopPropagation();
        }

        // for values consider entities
        if (context.type === Controller.Component.sMemberAttributeSelect.TYPE_ATTRIBUTE_VALUE) {
            // expand values with entities
            context.entities = this.messagePart.getEntities();

            // expand with available placeholders
            context.placeholders = this.message.getContextSpecificPlaceholders();
        }
    };

    /**
     * @function
     * @name Controller.sMessageAdmin.EditorController#showSuggestionModal
     * @return {PromiseLike}
     */
    EditorController.prototype.showSuggestionModal = function showSuggestionModal() {
        var position = this.$mdPanel
            .newPanelPosition()
            .absolute()
            .center()
        ;

        var config = {
            attachTo: angular.element(Const.PanelAnchor),
            templateUrl: 'smessageadmin:intent-suggestion-modal',
            panelClass: 'panel-width-400 panel-theme-1',
            controllerAs: '$ctrl',
            bindToController: true,
            controller: function (mdPanelRef) {
                this.cancel = function () {
                    mdPanelRef.close();
                }
            },
            hasBackdrop: true,
            position: position,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: Const.PanelAboveDialogZIndex,
            onCloseSuccess: function(mdPanelRef) {
                // enforce destroy of inner scope, so all the components get freed properly
                if (!mdPanelRef) {
                    return;
                }

                mdPanelRef.destroy();
            }
        };

        return this.$mdPanel.open(config);
    };

    ns.EditorController = EditorController;
})(Object.namespace('Controller.sMessageAdmin'));
