(function(ns) {
    var REJECT_REASON_MANUAL = 'manually_closed';

    /**
     * @namespace
     * @alias sFacebook.Service.sFacebookAdAccount
     * @constructor
     *
     * @param $mdDialog
     * @param $mdPanel
     * @param $exceptionHandler
     * @param sBugsnag
     * @param {Service.sProgressIndicator} sProgressIndicator
     * @param {Service.sConfirm} sConfirm
     * @param {sFacebook.Service.sFacebookClient} sFacebookClient
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     */
    var sFacebookAdAccount = function sFacebookAdAccount(
        $mdDialog,
        $mdPanel,
        $exceptionHandler,
        sBugsnag,
        sProgressIndicator,
        sConfirm,
        sFacebookClient,
        sAPIAccess
    ) {
        this.$mdDialog          = $mdDialog;
        this.$mdPanel           = $mdPanel;
        this.$exceptionHandler  = $exceptionHandler;
        this.sBugsnag           = sBugsnag;
        this.sProgressIndicator = sProgressIndicator;
        this.sConfirm           = sConfirm;
        this.sFacebookClient    = sFacebookClient;
        this.sAPIAccess         = sAPIAccess;
    };

    /**
     * @function
     * @name sFacebook.Service.sFacebookAdAccount#loadAll
     * @param {[]?} filters
     * @return {$.Deferred<Model.Sender[]>}
     */
    sFacebookAdAccount.prototype.loadAll = function loadAll(filters) {
        filters = filters || [];
        var collection = new Model.Sender.Collection(Model.Sender.FacebookAdAccount);
        return collection
            .load(this.sAPIAccess.endpoint('facebook.adAccount.all').get(), {dontPaginate: true})
            .then(function (collection) {
                return collection.items.filter(function(page) {
                    return filters.reduce(function(carry, filter) {
                        if (carry && carry[filter]) {
                            return carry;
                        } else {
                            return null;
                        }
                    }, page);
                });
            });
    };

    /**
     * @function
     * @name sFacebook.Service.sFacebookAdAccount#addNewAdAccount
     * @return {PromiseLike<Model.Sender.FacebookAdAccount>}
     */
    sFacebookAdAccount.prototype.addNewAdAccount = function addNewAdAccount() {
        var self = this;

        return this.showSetupAdAccountDialog()
            .then(function (adAccountSender) {

                return self.save(adAccountSender).fail(function (reason) {
                    self.sBugsnag.notify(
                        new Model.Facebook.ConnectionException('Save failed'),
                        {
                            metaData: {reason: reason}
                        }
                    );
                });
            })
            .then(function (facebookAdAccount) {
                return self.connect(facebookAdAccount).fail(function (reason) {
                    self.sBugsnag.notify(
                        new Model.Facebook.ConnectionException('Connect failed'),
                        {
                            metaData: {reason: reason}
                        }
                    );
                });
            });
    };

    /**
     * @function
     * @name sFacebook.Service.sFacebookAdAccount#save
     * @param {Model.Sender.FacebookAdAccount} facebookAdAccount
     * @return {PromiseLike<Model.Sender.FacebookPage>}
     */
    sFacebookAdAccount.prototype.save = function save(facebookAdAccount) {
        return facebookAdAccount.save().then(function (data) {
            if (!data || !data.uuid) {
                return facebookAdAccount;
            }

            // recreate model to have proper uuid which is returned from backend
            var fbData = JSON.parse(JSON.stringify(facebookAdAccount));
            fbData.uuid = data.uuid;
            facebookAdAccount = Model.Sender.createByData(Model.Sender.FacebookAdAccount.TYPE, fbData);
            facebookAdAccount.setAsExisting();

            return facebookAdAccount;
        });
    };

    /**
     * @function
     * @name sFacebook.Service.sFacebookAdAccount#remove
     * @param {Model.Sender.FacebookAdAccount} facebookAdAccount
     * @return {PromiseLike}
     */
    sFacebookAdAccount.prototype.remove = function remove(facebookAdAccount) {
        var confirmOptions = {
            confirm: 'Remove',
            decline: 'Cancel',
            title: 'Remove Ad Account',
            content: 'Do you want to remove the Facebook Ad Account <b>' + facebookAdAccount.alias + '</b> ?'
        };

        return this.sConfirm.open(confirmOptions).then(
            function () {
                return facebookAdAccount.delete()
            },
            function () {
                return false;
            });
    };

    /**
     * @function
     * @name sFacebook.Service.sFacebookAdAccount#connect
     * @param {Model.Sender.FacebookAdAccount} facebookAdAccount
     * @return {PromiseLike}
     */
    sFacebookAdAccount.prototype.connect = function connect(facebookAdAccount) {
        var formData = new FormData();

        if (facebookAdAccount.meta.userAccessToken) {
            formData.append('userAccessToken', facebookAdAccount.meta.userAccessToken);
        }

        return $.ajax({
            url         : this.sAPIAccess.endpoint('facebook.adAccount.connect').put(facebookAdAccount.uuid),
            method      : Const.Method.PUT,
            data        : formData,
            processData : false
        });
    };

    /**
     * @function
     * @name sFacebook.Service.sFacebookAdAccount#synchronize
     * @param {Model.Sender.FacebookAdAccount} facebookAdAccount
     * @return {PromiseLike}
     */
    sFacebookAdAccount.prototype.synchronize = function synchronize(facebookAdAccount) {
        return $.ajax({
            url     : this.sAPIAccess.endpoint('facebook.adAccount.synchronize').put(facebookAdAccount.uuid),
            method  : Const.Method.PUT
        });
    };

    /**
     * @function
     * @name sFacebook.Service.sFacebookAdAccount#showSetupAdAccountDialog
     * @return {PromiseLike<Model.Sender.FacebookAdAccount>}
     */
    sFacebookAdAccount.prototype.showSetupAdAccountDialog = function showSetupAdAccountDialog() {
        var self = this,
            deferred = $.Deferred()
        ;

        this.$mdDialog.show({
            controller          : sFacebook.Controller.AdAccountSetupDialog,
            controllerAs        : '$ctrl',
            bindToController    : true,
            templateUrl         : 'sfacebook:adaccount-setup-dialog',
            parent              : angular.element(Const.PanelAnchor),
            clickOutsideToClose : false,
            locals: {
            }
        })
            .then(deferred.resolve)
            .catch(function (err) {
                if (!err) {
                    deferred.reject(REJECT_REASON_MANUAL);
                    return;
                }
                self.$exceptionHandler(err);
                deferred.reject(err);
            });

        return deferred.promise();
    };

    /**
     * @function
     * @name sFacebook.Service.sFacebookAdAccount#getConnectionInstance
     * @param {Model.Sender.FacebookAdAccount} adAccountSender
     * @return {Model.Facebook.Connection}
     */
    sFacebookAdAccount.prototype.getConnectionInstance = function getConnectionInstance(adAccountSender) {
        return this.sFacebookClient.createConnection(adAccountSender.appId);
    };

    /**
     * @function
     * @name sFacebook.Service.sFacebookAdAccount#getSkeletonAdAccount
     * @return {PromiseLike<Model.Sender.FacebookAdAccount>}
     */
    sFacebookAdAccount.prototype.getSkeletonAdAccount = function getSkeletonAdAccount() {
        return $.ajax({
            url: this.sAPIAccess.endpoint('facebook.adAccount.skeleton').get()
        }).then(function (data) {
            return Model.Sender.createByData(Model.Sender.FacebookAdAccount.TYPE, data);
        });
    };

    /**
     * @function
     * @name sFacebook.Service.sFacebookAdAccount#authenticateWithFacebook
     * @param {Model.Facebook.Connection} connection
     * @param {Model.Sender.FacebookAdAccount} adAccountSender
     * @return {PromiseLike}
     */
    sFacebookAdAccount.prototype.authenticateWithFacebook = function authenticateWithFacebook(connection, adAccountSender) {
        var permissions = adAccountSender.permissionsRequired.concat(adAccountSender.permissionsOptional);

        return connection.login({
            scope           : permissions.join(','),
            return_scopes   : true,
            auth_type       : 'rerequest',
            auth_nonce      : Math.random().toString(36).substring(2, 15)
        }).then(function(response) {

            if (!response || !response.authResponse) {
                return $.Deferred().reject('User cancelled login or did not fully authorize.');
            }

            adAccountSender.setMeta('grantedScopes', response.authResponse.grantedScopes);
            adAccountSender.setMeta('userAccessToken', response.authResponse.accessToken);

            return response;
        });
    };

    /**
     * @function
     * @name sFacebook.Service.sFacebookAdAccount#getReadableGraphAdAccounts
     * @param {Model.Facebook.Connection} connection
     * @return {PromiseLike<FacebookAdAccount[]>}
     */
    sFacebookAdAccount.prototype.getReadableGraphAdAccounts = function getReadableGraphAdAccounts(connection) {
        var fetchAllDeferred,
            domainAdAccountsDeferred
        ;

        fetchAllDeferred = connection.apiFetchAll(
            '/me/adaccounts',
            Const.Method.GET,
            {fields: 'account_status,account_id,name'}
        );

        domainAdAccountsDeferred = this.loadAll().then(function (adAccountSenders) {
            return adAccountSenders.map(function (adAccountSender) {
                return adAccountSender.adAccountId ? adAccountSender.adAccountId.toString() : null;
            })
        });

        return $.when(fetchAllDeferred, domainAdAccountsDeferred).then(function (facebookAdAccountsResult, domainAdAccountIds) {
            if (!(facebookAdAccountsResult instanceof Array)) {
                return [];
            }

            return facebookAdAccountsResult.map(function (facebookAdAccountResult) {
                return {
                    id              : facebookAdAccountResult.account_id,
                    name            : facebookAdAccountResult.name,
                    alreadyInList   : domainAdAccountIds.indexOf(facebookAdAccountResult.account_id) !== -1
                };
            });
        });
    };

    Object.defineProperties(
        sFacebookAdAccount,
        {
            REJECT_REASON_MANUAL : {
                value : REJECT_REASON_MANUAL
                /**
                 * @property
                 * @constant
                 * @name sFacebook.Service.sFacebookAdAccount#REJECT_REASON_MANUAL
                 * @type {String}
                 */
            }
        }
    );

    ns.sFacebookAdAccount = sFacebookAdAccount;
})(Object.namespace('sFacebook.Service'));
