(function(window)  {
    /**
     * Check if there are more then one frame inside a gif represented by an Uint8Array
     * @param {Uint8Array} uIntArr
     * @return {boolean}
     */
    var isAnimatedGif = function isAnimatedGif(uIntArr) {
        var i, len, frames = 0, length = uIntArr.length;

        for (i = 0, len = length - 3; i < len && frames < 2; ++i) {
            if (uIntArr[i] === 0x00 && uIntArr[i + 1] === 0x21 && uIntArr[i + 2] === 0xF9) {
                var blockLength = uIntArr[i + 3],
                    afterBlock = i + 4 + blockLength
                ;
                if (afterBlock + 1 < length &&
                    uIntArr[afterBlock] === 0x00 &&
                    (uIntArr[afterBlock + 1] === 0x2C || uIntArr[afterBlock + 1] === 0x21)) {
                    frames++;
                }
            }
        }

        return frames !== 0;
    };

    window.isAnimatedGif = isAnimatedGif;
})(window);

