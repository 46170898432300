(function(angular) {
    /**
     * The main application
     * @type {angular.Module}
     */
    var sNGApp = angular.module('sNGApp', [
        'sFollowPubNG',

        'sEmoji',
        'sMenuModule',
        'sNavigation',
        'sContextNavigationModule',
        'sSidebar',
        'sMessageLog',
        'sMessageAdmin',
        'sSettings',
        'sUserManagementModule',
        'sListModule',
        'sMemberModule',
        'sSegmentModule',
        'sMessageAnchorModule',
        'sContentFeedModule',
        'sAnalyticsModule',
        'sInboxModule',
        'sEntryPointModule',
        'sAutomationModule',
        'sDomainModule',
        'sSourceModule',
        'sSenderModule',
        'sFacebookModule',
        'sEntityModule',
        'sTermsOfServiceModule'
    ]);

    sNGApp
        .service('sConfirm', Service.sConfirm)
        .service('sConversationValidator', Service.sConversationValidator)
        .service('sMessageCollection', Service.sMessageCollection)
        .service('sActionService', Service.sAction)
        .service('sFormPanel', Service.sFormPanel)
        .service('sMemberAttribute', Service.sMemberAttribute)
        .service('sMarkerRepository', Service.sMarkerRepository)
        .service('sFile', Service.sFile)
        .service('sAnalyticsTracker', Service.sAnalyticsTracker)
        .service('sNotificationLabel', Service.sNotificationLabel)
        .provider('sConversationValidator', Provider.sConversationValidator)
    ;

    // validator config
    sNGApp.config(['sConversationValidatorProvider', function(sConversationValidatorProvider) {
        sConversationValidatorProvider
            .addValidator(new Model.RepairCase.InvalidMessageNumber())
            .addValidator(new Model.RepairCase.MessageOrphans())
            .addValidator(new Model.RepairCase.EmptyMessage(), Provider.sConversationValidator.ACTIVATE_LEVEL)
            .addValidator(new Model.RepairCase.MediaQRToHScroll(), Provider.sConversationValidator.ACTIVATE_LEVEL)
            .addValidator(new Model.RepairCase.EmptyPostbackMessage(), Provider.sConversationValidator.ACTIVATE_LEVEL)
            .addValidator(new Model.RepairCase.QuickRepliesWOAttachment(), Provider.sConversationValidator.ACTIVATE_LEVEL)
            .addValidator(new Model.RepairCase.EmptyPostbackMessageIncoming(), Provider.sConversationValidator.ACTIVATE_LEVEL)
            .addValidator(new Model.RepairCase.InvalidStructuredMessage(), Provider.sConversationValidator.ACTIVATE_LEVEL)
            .addRequirementForLevel(Provider.sConversationValidator.ACTIVATE_LEVEL, Provider.sConversationValidator.DEFAULT_LEVEL)
        ;
    }]);

    sNGApp.config(['sSidebarProvider', function(sSidebarProvider) {
        sSidebarProvider
            .when(
                Controller.sMessageLog.Member,
                {
                    template: '_sidebar/member',
                    title   : 'Member details',
                    key     : 'member-details',
                    icon    : '<i class="fa fa-info"></i>'
                })
            .when(
                Controller.sMessageAdmin.IndexController,
                [
                    {
                        template   : '_sidebar/minimap',
                        title      : 'Minimap',
                        key        : 'minimap',
                        icon       : '<i class="fa fa-sitemap"></i>',
                        detachable : true
                    },
                    {
                        template   : '_sidebar/conversation-goals',
                        title      : 'Conversation Goals',
                        key        : sSidebar.Service.sSidebar.KEY_SIDEBAR_CONVERSATION_GOALS,
                        icon       : '<i class="fas fa-flag"></i>',
                        detachable : false
                    }
                ]
            )
        ;
    }]);

    sNGApp.config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/', {
                resolveRedirectTo : function ($cookies, sNavigationMenu, sDomainService) {
                    var redirectTo = '/message';

                    // if we are being redirected after login but another page then dashboard was requested
                    if ($cookies.get(sSecurity.Controller.Login.KEY_COOKIE_REDIRECT_URL)) {
                        redirectTo = $cookies.get(sSecurity.Controller.Login.KEY_COOKIE_REDIRECT_URL);
                        $cookies.remove(sSecurity.Controller.Login.KEY_COOKIE_REDIRECT_URL);
                    } else {
                        var firstNavigationItem = sNavigationMenu.mainMenu.items[0];

                        if (firstNavigationItem.url) {
                            redirectTo = firstNavigationItem.url;
                        } else {
                            for (var i = 0; i < firstNavigationItem.items.length; i++) {
                                if (firstNavigationItem.items[i].url) {
                                    redirectTo = firstNavigationItem.items[i].url;
                                    break;
                                }
                            }
                        }

                        if (sDomainService.currentDomain.isFeatureEnabledByIdent('vStructure')) {
                            // redirect to conversation overview
                            redirectTo = '/v2';
                            window.location = redirectTo;
                            return;
                        }

                        redirectTo = redirectTo.replace(/^#/, '');
                    }

                    return redirectTo;
                }
            })
            .otherwise('/');
    }]);

    sNGApp.config(['sSourceProvider', function (sSourceProvider) {
        sSourceProvider.url = function(sAPIAccess) {
            return sAPIAccess.endpoint('source.global').path(Const.Method.GET);
        }
    }]);

    sNGApp.run([
        'sAuth',
        'segment',
        'security_user',
        '$rootScope',
        'sDomainService',
        '$mdDialog',
        'sActionService',
        'sEventDefinition',
        '$route',
        'sAnalyticsTracker', // Init sAnalyticsTracker service
        /**
         *
         * @param sAuth
         * @param segment
         * @param security_user
         * @param $rootScope
         * @param {Service.sDomain} sDomainService
         * @param $mdDialog
         * @param {Service.sAction} sActionService
         * @param sEventDefinition
         * @param $route
         * @param {Service.sAnalyticsTracker} sAnalyticsTracker
         */
        function (
            sAuth,
            segment,
            security_user,
            $rootScope,
            sDomainService,
            $mdDialog,
            sActionService,
            sEventDefinition,
            $route,
            sAnalyticsTracker
        ) {
            $rootScope.$on(Service.sDomain.EVENT_DOMAIN_SET, function() {
                if (!sDomainService.isLoading) {
                    sActionService.refreshDefinitions();
                    sEventDefinition.refreshDefinitions();
                    setTimeout($route.reload, 1);
                }
            });

            $rootScope.$on('$routeChangeStart', function (event) {
                if (sDomainService.isLoading) {
                    event.preventDefault();
                    sDomainService.getDomains().then(function () {
                        // Check that domain in url matches current domain, if not, force redirect
                        if (sDomainService.getDomainNameFromUrl() !== sDomainService.currentDomain.name) {
                            sDomainService.redirectToCurrentDomain();
                            return;
                        }

                        $route.reload();
                    });
                }
            });
            // make it second after the progress indicator
            $rootScope.$$listeners.$routeChangeStart.splice(1, 0, $rootScope.$$listeners.$routeChangeStart.pop());

            $.ajaxPrefilter(function (options) {
                var error = options.error;
                options.error = function(jqXHR, textStatus, errorThrown) {
                    if (jqXHR.status === 401) {
                        $(document).trigger('auth-error', jqXHR);
                    }
                    // override error handling
                    if(typeof(error) === "function") return error(jqXHR, textStatus, errorThrown);
                };
            });

            $rootScope.$on(sSecurity.Service.sAuth.EVENT_AUTH_USER_UPDATED, function (event, user) {
                if (user) {
                    segment.identify(user.email, {
                        app: Service.sAnalyticsTracker.APP_NAME,
                        email: user.email,
                        userId: user.uuid,
                        firstname: user.firstname,
                        lastname: user.lastname,
                        isGroupOwner: user.isGroupOwner,
                        createdAt: user.timeCreated,
                        updatedAt: user.timeUpdated
                    }, {
                        Intercom: {
                            user_hash: user.meta.intercom_user_hash
                        }
                    });
                } else {
                    if (typeof window.Intercom !== undefined) {
                        window.Intercom('shutdown');
                    }
                    segment.reset();
                }
            });

            $rootScope.$on('$routeChangeStart', function(event, $route) {
                if (event.defaultPrevented) {
                    return true;
                }

                if (!document.getElementById('module-sTermsOfService-tosDialog')) {
                    $mdDialog.cancel(); // cancel any active dialog if we change route
                }
                $route.resolve = $route.resolve || {};
                $route.resolve['sActionServiceInit'] = sActionService.getDefinitions.bind(sActionService);
            });

            $rootScope.$on(sAPIAccess.Service.sAPIAccess.EVENT_ENDPOINTS_LOADED, function(event, isTransient) {
                if (!sDomainService.getCurrentDomainId()) {
                    return;
                }

                segment.group(!isTransient ? sDomainService.currentDomain.id : 'spectrm_users');
            });

            sDomainService.init();
            sAuth.init();
            sAuth.reloadUser();
        }]);

        sNGApp.run([
            '$mdPanel',
            '$mdDialog',
            'sBugsnag',
            'sDomainService',
            'sAuth',
            /**
             * @param $mdPanel
             * @param $mdDialog
             * @param sBugsnagClient
             * @param {Service.sDomain} sDomainService
             * @param {sSecurity.Service.sAuth} sAuth
             */
            function($mdPanel, $mdDialog, sBugsnagClient, sDomainService, sAuth) {
                var $mdPanelCreate = $mdPanel.create,
                    $mdDialogShow = $mdDialog.show
                ;

                // Enhance mdPanel to leave breadcrumbs on panel open and close
                $mdPanel.create = function(config) {
                    var mdPanelRef = $mdPanelCreate.apply(this, arguments);

                    // store original functions
                    var mdPanelRefOpen = mdPanelRef.open,
                        mdPanelRefClose = mdPanelRef.close;

                    // ignore tooltips...
                    if (config.panelClass === 'md-tooltip') {
                        return mdPanelRef;
                    }

                    // call original and in the promise handler leave the breadcrumb
                    mdPanelRef.open = (function () {
                        return mdPanelRefOpen.apply(mdPanelRef, arguments).then(function () {
                            sBugsnagClient.leaveBreadcrumb('Panel opened', {template: config.templateUrl}, 'state');
                        });
                    });

                    // call original and in the promise handler leave the breadcrumb
                    mdPanelRef.close = (function () {
                        return mdPanelRefClose.apply(mdPanelRef, arguments).then(function () {
                            sBugsnagClient.leaveBreadcrumb('Panel closed', {template: config.templateUrl}, 'state');
                        });
                    });

                    return mdPanelRef;
                };

                // same as for mdPanel, leave breadcrumbs on certains events
                $mdDialog.show = function(config) {
                    var completeFn, // after it was opened
                        removingFn  // before it gets closed
                    ;

                    // mdDialog is a bit weird and uses interimElement, so it is not possible to extend open and close so easily
                    // but dialog has some callback options
                    if ((completeFn = config.onComplete)) {
                        // if the callback was there already > extend
                        config.onComplete = function() {
                            sBugsnagClient.leaveBreadcrumb('Dialog opened',  { template: config.templateUrl }, 'state');
                        }
                    } else {
                        // otherwise just create the callback
                        config.onComplete = function() {
                            sBugsnagClient.leaveBreadcrumb('Dialog opened',  { template: config.templateUrl }, 'state');
                        }
                    }

                    // same as onComplete, but for onRemoving...
                    if ((removingFn = config.onRemoving)) {
                        config.onRemoving = function() {
                            sBugsnagClient.leaveBreadcrumb('Dialog remove started',  { template: config.templateUrl }, 'state');
                        }
                    } else {
                        config.onRemoving = function() {
                            sBugsnagClient.leaveBreadcrumb('Dialog remove started',  { template: config.templateUrl }, 'state');
                        }
                    }

                    return $mdDialogShow.apply($mdDialog, arguments);
                };

                // group errors that are caused by code by the name of the error and the domain
                $(document).on(sBugsnag.Provider.sBugsnag.EVENT_BUGSNAG_BEFORE_SEND, function(evt, report) {
                    if (sAuth && sAuth.user) {
                        report.user = { id: sAuth.user.email };
                    }
                    report.metaData = report.metaData || {};
                    switch (report.errorClass) {
                        case 'InvalidActionError':
                        case 'InvalidActionDefinitionCombination':
                            report.groupingHash         = '[' + sDomainService.currentDomainId + ']' + report.errorClass + ': ' + report.errorMessage;
                            report.metaData.domainId    = sDomainService.currentDomainId;
                            break;
                        case 'AjaxError':
                            report.groupingHash = '[' + report.metaData.responseStatus + ']' + report.errorClass + ': ' + report.metaData.requestUrl;
                            break;
                        case 'AggregatedAjaxError':
                            report.groupingHash = report.errorClass + ': ' + report.metaData.aggregationIdentifier;
                            break;
                    }
                });
        }]);

        // force source reload on domain change
        sNGApp.run([
            '$rootScope',
            'sSource',
            /**
             * @param $rootScope
             * @param {sSource.Service.sSource} sSource
             */
            function($rootScope, sSource) {
                $rootScope.$on(Service.sDomain.EVENT_DOMAIN_SET, function() {
                    sSource.invalidateCache();
                    sSource.invalidateFieldAsAttributeCache();
                });
            }
        ]);

        // force redirect on domain change
        sNGApp.run([
            '$rootScope',
            'sDomainService',
            /**
             * @param $rootScope
             * @param sDomainService
             */
            function ($rootScope, sDomainService) {
                $rootScope.$on(Service.sDomain.EVENT_DOMAIN_CHANGED, function () {
                    if (!sDomainService.isLoading) {
                        sDomainService.redirectToCurrentDomain();
                    }
                });
            }
        ]);

        sNGApp.run([
            '$rootScope',
            '$injector',
            function($rootScope, $injector) {
                $rootScope.$on('$routeChangeStart', function(event, $route) {
                    if (event.defaultPrevented) {
                        return;
                    }

                    if (!$route.resolve) {
                        return;
                    }

                    var aggregatedActions   = [],
                        aggregatedPromises  = {},
                        originals           = {}
                    ;

                    Object.getOwnPropertyNames($route.resolve).map(function(propName) {
                        var $deferred   = $.Deferred(),
                            fn          = $route.resolve[propName]
                        ;

                        originals[propName] = fn;

                        var instance = function() { return $injector.instantiate(fn, null, propName); };
                        aggregatedPromises[aggregatedActions.length] = $deferred;
                        aggregatedActions.push(instance);
                        $route.resolve[propName] = function() { return $deferred.promise() };
                    });

                    if (!aggregatedActions.length) {
                        return;
                    }

                    $route.resolve.aggregatedCalls = function() {
                        return $.aggregateAction(aggregatedActions, Model.RESTAccessByUUID.endpoint_batch()).then(function(results) {
                            results.map(function(result, index) {
                                aggregatedPromises[index].resolve(result);
                            });

                            for (var propName in originals) {
                                $route.resolve[propName] = originals[propName];
                            }
                            delete $route.resolve.aggregatedCalls;
                        });
                    }
                });
            }]);

        sNGApp.run([
            '$rootScope',
            'sTermsOfService',
            /**
             * @param $rootScope
             * @param {sTermsOfService.Service.sTermsOfService} sTermsOfService
             */
            function ($rootScope, sTermsOfService) {
                sTermsOfService.init();
            }
        ]);
 })(angular);
