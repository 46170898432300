(function(ns){
    /**
     * @namespace
     * @alias sAppCookies.Service.sAppCookies
     *
     * @param APP_COOKIE_NAME
     * @param $cookies
     */
    var sAppCookies = function(APP_COOKIE_NAME, $cookies) {
        var cookieName  = APP_COOKIE_NAME,
            cookie      = {}
            ;

        Object.defineProperty(
            this,
            'cookie',
            {
                get: function() {
                    cookie = $.extend(JSON.parse($cookies.get(cookieName) || '{}'), cookie);
                    return cookie;
                }
            }
        );

        /**
         * @param {String} key
         * @param value
         */
        this.put = function set(key, value) {
            Object.getPrototypeOf(this).put.call(this, key, value);
            $cookies.put(cookieName, JSON.stringify(this.cookie));
        }
    };

    /**
     * @param {String} key
     * @param value
     */
    sAppCookies.prototype.put = function set(key, value) {
        this.cookie[key] = value;
    };

    /**
     * @param {String} key
     * @param defaultVal
     * @returns {*}
     */
    sAppCookies.prototype.get = function get(key, defaultVal) {
        return this.cookie[key] || defaultVal;
    };

    ns.sAppCookies = sAppCookies;
})(Object.namespace('sAppCookies.Service'));