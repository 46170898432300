(function (ns) {
    /**
     * @namespace
     * @alias Model.Source.FieldAsAttribute
     *
     * @constructor
     * @param {String} name
     * @param {String[]} sourceNames
     */
    var FieldAsAttribute = function (name, sourceNames) {
        var sourceNamesCache = [];

        function createSourceNamesCache() {
            sourceNamesCache.splice.apply(sourceNamesCache, [0, sourceNamesCache.length].concat(sourceNames));
        }

        Object.defineProperties(
            this,
            {
                name: {
                    enumerable: true,
                    get: function () {
                        return name;
                    }
                    /**
                     * @property
                     * @name Model.Source.FieldAsAttribute#name
                     * @type {String}
                     */
                },
                sourceNames: {
                    enumerable: true,
                    get: function () {
                        if (sourceNamesCache.length !== sourceNames.length
                            || sourceNamesCache.toString() !== sourceNames.toString()) {
                            createSourceNamesCache();
                        }

                        return sourceNamesCache;
                    }
                    /**
                     * @property
                     * @name Model.Source.FieldAsAttribute#sourceNames
                     * @type {Array}
                     */
                }
            });
    };

    ns.FieldAsAttribute = FieldAsAttribute;
})(Object.namespace('Model.Source'));
