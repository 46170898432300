(function (angular) {
    var module = angular.module('sEntityModule', []);

    module
        .service('sEntityRepository', Model.AI.EntityCollection)
    ;

    module.config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/entities/overview', new sEntity.Route.Overview())
        ;
    }]);

    module.config(['sTitleProvider', function(sTitleProvider) {
        sTitleProvider
            .when(
                '/entities/overview',
                'Entities',
                'Overview'
            )
        ;
    }]);

    // bind $rootScope events
    module.run(['sEntityRepository', '$rootScope', 'sAPIAccess',
        /**
         * @param {Model.AI.EntityCollection} sEntityRepository
         * @param $rootScope
         * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
         */
        function(sEntityRepository, $rootScope, sAPIAccess) {
            $rootScope.$on('$routeChangeStart',
                /**
                 * @param event
                 * @param {Route} $route
                 */
                function(event, $route) {
                    if (event.defaultPrevented) {
                        return true;
                    }

                    $route.resolve = $route.resolve || {};
                    $route.resolve['sEntityRepositoryInit'] =
                        /**
                         * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
                         * @return {PromiseLike}
                         */
                        function (sAPIAccess) {
                            return sEntityRepository.load();
                        }
                });

            $rootScope.$on('$routeChangeSuccess',
                function() {
                    // this will clear all "new" entities that have not been saved yet
                    sEntityRepository.syncWithCollection(new Model.Message.Collection());
                });

            $rootScope.$on(Service.sDomain.EVENT_DOMAIN_CHANGED,
                /**
                 * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
                 * @param {Service.sDomain} sDomainService
                 */
                function(sAPIAccess, sDomainService) {
                    if (!(sAPIAccess.endpoint instanceof Function)) {
                        return;
                    }
                    sEntityRepository.load(null, {}, true);
                });
        }
    ]);

    // Add pop-up dialog presets
    module.config(['$mdDialogProvider', function ($mdDialogProvider) {
        $mdDialogProvider.addPreset('sEntityEdit', {
            options: function() {
                return {
                    templateUrl         : 'sentity:edit',
                    controller          : sEntity.Controller.Edit,
                    controllerAs        : '$ctrl',
                    bindToController    : true,
                    clickOutsideToClose : false,
                    parent              : angular.element(Const.PanelAnchor),
                    escapeToClose       : false
                };
            },
            methods : ['entity']
        });
    }]);
})(angular);
