(function(ns) {
    /**
     * @namespace
     * @alias sSecurity.Controller.RoleAdmin
     *
     * @param {Model.User.Role[]} roles
     * @param {Model.Feature[]} features
     * @param {Service.sFormPanel} sFormPanel
     * @constructor
     */
    var RoleAdmin = function(roles, features, sFormPanel) {
        this.roles      = roles;
        this.features   = features;
        /**
         * @type {Service.sFormPanel}
         */
        this.sFormPanel = sFormPanel;
    };

    /**
     * @param {Model.Feature} feature
     * @param {Model.User.Role} role
     * @returns {boolean}
     */
    RoleAdmin.prototype.isFeaturePartiallyGranted = function isFeaturePartiallyGranted(feature, role) {
        if (!role.permissions.length) {
            return false;
        }

        var featurePermissionsInRole = role.permissions.filter(function(permission) {
            return permission.feature.ident === feature.ident;
        });

        return featurePermissionsInRole.length > 0 && featurePermissionsInRole.length < feature.permissions.length;
    };

    /**
     * @param {Model.Feature} feature
     * @param {Model.User.Role} role
     * @returns {boolean}
     */
    RoleAdmin.prototype.isFeatureGranted = function isFeatureGranted(feature, role) {
        var featurePermissionsInRole = role.permissions.filter(function(permission) {
            return permission.feature.ident === feature.ident;
        });

        return featurePermissionsInRole.length === feature.permissions.length;
    };

    /**
     * @param permission
     * @param {Model.User.Role} role
     */
    RoleAdmin.prototype.togglePermission = function togglePermission(permission, role) {
        var rolePermission;
        if (rolePermission = role.getPermissionByUuid(permission.uuid)) {
            role.removePermission(rolePermission);
        } else {
            role.addPermission(permission);
        }

        role.save();
    };

    /**
     * @param {Model.Feature} feature
     * @param {Model.User.Role} role
     */
    RoleAdmin.prototype.toggleFeaturePermissions = function toggleFeaturePermissions(feature, role) {
        if (this.isFeatureGranted(feature, role)) {
            role.permissions.filter(
                /**
                 * @param {Model.User.Permission} permission
                 * @returns {boolean}
                 */
                function(permission) {
                return permission.feature.ident === feature.ident;
            }).map(function(permission) {
                role.removePermission(permission);
            });
        } else {
            feature.permissions.map(
                /**
                 * @param {Model.User.Permission} permission
                 */
                function(permission) {
                if (!role.getPermissionByUuid(permission.uuid)) {
                    role.addPermission(permission);
                }
            });
        }

        role.save();
    };

    /**
     * @param {$.Event} $event
     * @param {Model.User.Role} role
     */
    RoleAdmin.prototype.editRole = function editRole($event, role) {
        var rules = {
            name: {
                component: 'sTextInput',
                options: {
                    attrs: {
                        'max-length': 30,
                        'is-required': true,
                        'ng-model-options' : '{"allowInvalid" : true}'
                    },
                    label: 'Name'
                }
            }
        };

        this.sFormPanel.open(
            $event,
            role,
            rules
        ).then(function(clone) {
                role.name = clone.name;
                role.save();
            }.bind(this));
    };

    RoleAdmin.prototype.addRole = function addRole() {
        var role = new Model.User.Role();
        role.name = 'New Role';
        this.roles.push(role);

        role.save();
    };

    ns.RoleAdmin = RoleAdmin;
})(Object.namespace('sSecurity.Controller'));
