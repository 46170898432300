(function(ns) {
    var TYPE                  = 'facebook',
        FB_URL_MESSENGER      = 'https://www.messenger.com/t/',
        FB_URL_MESSENGER_M_ME = 'https://m.me/',
        FB_URL_PAGE           = 'https://www.facebook.com/'
    ;

    /**
     * @namespace
     * @alias Model.Sender.FacebookPage
     * @extends Model.Sender.BaseFacebookSender
     * @constructor
     *
     * @param {String=} uuid
     */
    var FacebookPage = function FacebookPage(uuid) {
        var self        = this,
            isPublished = false
        ;

        /**
         * @property {String|Integer}
         * @name Model.Sender.FacebookPage#pageId
         */
        this.pageId = null;
        /**
         * @property {String}
         * @name Model.Sender.FacebookPage#pageName
         */
        this.pageName = '';
        /**
         * @property {String|Integer}
         * @name Model.Sender.FacebookPage#authUserId
         */
        this.authUserId = null;
        /**
         * @property {String}
         * @name Model.Sender.FacebookPage#authUserName
         */
        this.authUserName = null;

        /**
         * @property {Boolean}
         * @name Model.Sender.FacebookPage#hasAllAppData
         */
        this.hasAllAppData = false;

        Object.defineProperties(
            this,
            {
                isBusinessIntegrated: {
                    get: function () {
                        return self.integrationStatus === Model.Sender.INTEGRATION_STATUS_CONNECTED;
                    },
                    set: function () {
                        // noop
                    }
                    /**
                     * @property
                     * @type {Boolean}
                     * @name Model.Sender.FacebookPage#isBusinessIntegrated
                     */
                },
                isPublished: {
                    enumerable: true,
                    get: function() {
                        return isPublished;
                    },
                    set: function(value) {
                        isPublished = !!value;
                    }
                    /**
                     * @property
                     * @name Model.Sender.FacebookPage#isPublished
                     * @type Boolean
                     */
                },
                alias: {
                    enumerable: true,
                    get: function() {
                        return self.pageName || self.pageId;
                    }
                    /**
                     * @property
                     * @name Model.Sender.FacebookPage#alias
                     * @type String
                     */
                }
            }
        );

        FacebookPage._pProto.constructor.call(this, FacebookPage, uuid);
    };

    Object.extendProto(FacebookPage, Model.Sender.BaseFacebookSender);

    /**
     * @function
     * @name Model.Sender.FacebookPage#getMessengerUrl
     * @returns {String|null}
     */
    FacebookPage.prototype.getMessengerUrl = function getMessengerUrl() {
        return this.pageId ? (FB_URL_MESSENGER + this.pageId) : null;
    };

    /**
     * @function
     * @name Model.Sender.FacebookPage#getMMeUrl
     * @param {String|null} payload
     * @returns {String|null}
     */
    FacebookPage.prototype.getMMeUrl = function getMMeUrl(payload) {
        if (!this.pageId) {
            return null;
        }

        var url = FB_URL_MESSENGER_M_ME + this.pageId;

        if (!payload) {
            return url;
        }

        return url + '?ref=' + payload;
    };

    /**
     * @function
     * @name Model.Sender.FacebookPage#getPageUrl
     * @returns {String}
     */
    FacebookPage.prototype.getPageUrl = function getPageUrl() {
        if (this.pageName && this.pageName.length > 0) {
            return FB_URL_PAGE + this.pageName + '-' + this.pageId;
        }
        return FB_URL_PAGE + this.pageId;
    };

    /**
     * Creates a facebook-page by data
     * @function
     * @static
     * @name Model.Sender.FacebookPage.createByData
     * @param {object} data
     * @returns Model.Sender.FacebookPage
     */
    FacebookPage.createByData = function createByData(data) {
        return Model.Sender.createByData(TYPE, data);
    };

    ns.TYPES[TYPE] = FacebookPage;

    Object.defineProperties(
        FacebookPage,
        {
            TYPE : {
                value : TYPE
                /**
                 * @property
                 * @constant
                 * @name Model.Sender.FacebookPage#TYPE
                 * @type {String}
                 */
            }
        }
    );

    ns.FacebookPage = FacebookPage;
})(Object.namespace('Model.Sender'));
