(function(ns) {
    var EVENT_BROWSE_REMOTE_FILES   = 'sEventBrowseRemoteFiles',
        EVENT_FILE_CHANGED          = 'sEventFileChanged'
    ;

    /**
     * @namespace
     * @alias Controller.Component.FileUploadController
     * Handles file uploads in the client, exposes content as bas64 encoded string
     * @param {jQuery} $element
     * @param $document
     * @param $scope
     * @param $timeout
     * @param {Number} maxFileSize
     * @param $mdPanel
     */
    var FileUploadController = function($element, $document, $scope, $timeout, maxFileSize, $mdPanel) {
        var tmp         = this.model,
            model
        ;

        this.$mdPanel       = $mdPanel;
        this.$deRegister    = [];
        this.$element       = $element;
        this.$document      = $document;
        this.$scope         = $scope;
        this.$timeout       = $timeout;
        this.maxFileSize    = maxFileSize;
        this.fileExceptions = [];
        this.acceptedTypes  = [];
        this.buttonText     = this.buttonText || 'Browse';
        this.isDisabled     = false;

        Object.defineProperty(
            this,
            'model',
            {
                configurable: true,
                get         : function() {
                    return model;
                },
                set         : function(val) {
                    if (model === val) {
                        return;
                    }
                    if (this.$fakeElement) {
                        this.$fakeElement.change();
                    }
                    model = val;

                    if (model && model.then && model.then instanceof Function) {
                        model.then(function(res) {
                            model = res;
                            digestIfNeeded($scope);
                        });
                    }
                }
            }
        );

        this.model = tmp;

        this.browseRemoteLabel  = 'Select media placeholder';
        this.hint               = 'Select a media placeholder';

        /**
         * @property
         * @name Controller.Component.FileUploadController#onUpload
         * @type {Function}
         */

        /**
         * @name Controller.Component.FileUploadController#dndArea
         */
    };

    /**
     * Static member to store input xpath
     *
     * @type {string}
     */
    FileUploadController.inputSelector = 'input[type="file"]';

    /**
     * Handle file uploads coming from an event
     *
     * @function
     * @name Controller.Component.FileUploadController#uploadFiles
     * @param {Array} files
     * @returns {Promise}
     */
    FileUploadController.prototype.uploadFiles = function uploadFiles(files) {
        var self        = this
        ;

        this.fileExceptions = [];
        this.isLoading      = false;

        if (!files.length) {
            return $.Deferred().reject();
        }

        var processedFiles;
        return Model.sFile.uploadFiles(
                files,
                self.maxFileSize,
                self.acceptedTypes
            )
            // add an intermediate layer for returned promises by the onUpload handler
            .then(function(_processedFiles) {
                processedFiles = _processedFiles;
                var retVal = self.onUpload({files: processedFiles});
                if (retVal && retVal.then) {
                    return retVal;
                } else {
                    return $.Deferred().resolve();
                }
            })
            // do the default behavior if no error happened
            .then(function() {
                self.model = processedFiles;
            })
            .progress(function(err) {
                self.addException(err);
            })
            .always(function() {
                self.isLoading = false;
                digestIfNeeded(self.$scope);
                self.$scope.$emit(EVENT_FILE_CHANGED);
            });
    };

    /**
     * On init the component should attach the following event listeners
     *
     * @function
     * @name Controller.Component.FileUploadController#handleUploadEvent
     * @param {jQuery.Event} evt
     * @returns Promise
     */
    FileUploadController.prototype.handleUploadEvent = function handleUploadEvent(evt) {
        var self    = this,
            files   = [];

        if (evt.type === 'drop') {
            evt.preventDefault();
            /**
             * @class DragEvent
             * @property dataTransfer
             */


            /** @type DragEvent */
            var oEvent = evt.originalEvent;

            files = oEvent.dataTransfer.files;
        } else if (evt.type === 'change') {
            files = this.$fileInput[0].files;
        }

        return this.uploadFiles(files).always(function () {
            // clear value of file-input selected file
            // this is needed for selecting the same file twice and get a change triggered
            self.$fileInput[0].value = '';
        });
    };

    /**
     * @name Controller.Component.FileUploadController#addException
     * @param {Error} exc
     */
    FileUploadController.prototype.addException = function addException(exc) {
        var self = this;

        self.fileExceptions.push(exc);
        self.$scope.$digest();

        // remove exception after x time
        self.$timeout(function () {
            if (self.fileExceptions.length > 0) {
                self.fileExceptions.shift();
            }
        }, 5000); // 5 sec
    };

    /**
     * On init the component should attach the following event listeners
     *
     * @function
     * @name Controller.Component.FileUploadController#$onInit
     */
    FileUploadController.prototype.$onInit = function $onInit() {
        var self = this;

        this.acceptedTypes = !!(this.accept) ? this.accept.split(',') : [];

        // $timeout because otherwise the ng-include is not rendered yet and no element will be found
        this.$timeout(function () {
            self.$fileInput     = self.$element.find(FileUploadController.inputSelector);
            self.$fakeElement   = self.$element.find('[name="upload"]');
        });

        // forward click to file-input
        this.$deRegister = this.$deRegister.concat(this.$element.$on('click', '.md-button[browse]', function() {
            self.$fileInput.click();
        }));

        this.$deRegister = this.$deRegister.concat(this.$element.$on(
            'change',
            FileUploadController.inputSelector,
            this.handleUploadEvent.bind(this)
        ));

        this.$deRegister = this.$deRegister.concat(this.$document.$on(
            'drop',
            this.dndArea,
            this.handleUploadEvent.bind(this)
        ));

        var $event = this.$scope.$emit(EVENT_BROWSE_REMOTE_FILES);

        if ($event.files && $event.files.length) {
            this.remoteFiles        = $event.files;
            this.browseRemoteLabel  = $event.browseRemoteLabel || this.browseRemoteLabel;
            this.hint               = $event.hint || this.hint;
        }
    };

    /**
     * @function
     * @name Controller.Component.FileUploadController#$onChanges
     * @param {Object.<String, SimpleChange>} $changes
     */
    FileUploadController.prototype.$onChanges = function $onChanges($changes) {
        if ($changes.customMaxFileSize) {
            this.maxFileSize = this.customMaxFileSize || this.maxFileSize;
        }
    };

    /**
     * On destroy remove the event listeners
     *
     * @function
     * @name Controller.Component.FileUploadController#$onDestroy
     */
    FileUploadController.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while(($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };


    FileUploadController.prototype.clear = function clear() {
        this.model = undefined;
        this.$element.find(FileUploadController.inputSelector).val('');
    };

    /**
     * @param $event
     */
    FileUploadController.prototype.openPanel = function openPanel($event) {
        var self = this
        ;

        if (this.panelRef) {
            return;
        }

        var element = $event ? $event.currentTarget : this.$element[0];

        var clientRect = element.getBoundingClientRect(),
            viewPortRect = $('body')[0].getBoundingClientRect(),
            position = this.$mdPanel.newPanelPosition()
                .relativeTo(element)
                .addPanelPosition(
                    this.$mdPanel.xPosition.ALIGN_START,
                    (clientRect.top - viewPortRect.top) > (viewPortRect.bottom - clientRect.bottom) ? this.$mdPanel.yPosition.ABOVE : this.$mdPanel.yPosition.BELOW
                ),
            locals = {
                itemTemplate    : '_component:file_upload_choice'
            }
        ;

        var panelCssClass = ['autocompleter-panel'];

        var config = {
            attachTo            : angular.element(Const.PanelAnchor),
            controller          : Controller.Directive.AutoCompleter.Panel,
            controllerAs        : 'ctrl',
            bindToController    : true,
            templateUrl         : '_directive:autocompleter',
            panelClass          : panelCssClass.join(' '),
            position            : position,
            locals              : locals,
            scope               : this.$scope.$new(),
            clickOutsideToClose : true,
            escapeToClose       : true,
            focusOnOpen         : true,
            zIndex              : Const.PanelAboveDialogZIndex,
            onRemoving          : function () {
                self.handlePanelClose();
            }
        };

        if (!this.panelRef) {
            this.panelRef = this.$mdPanel.open(config).then(function (data) {
                self.panelRef = data;

                self.$scope.$broadcast(
                    Controller.Directive.AutoCompleter.EVENT_UPDATE_RESULTS,
                    {
                        matches     : self.remoteFiles,
                        hint        : self.hint
                    });
            });
        }
        digestIfNeeded(this.$scope);
    };

    FileUploadController.prototype.handlePanelClose = function () {
        var self = this;
        // nothing was set, return
        if (!this.panelRef || !this.panelRef.result) {
            if (this.panelRef) {
                this.panelRef.destroy();
                this.panelRef = null;
            }

            return;
        }

        /** @type {Model.Source.Placeholder} */
        var placeholder = this.panelRef.result;

        Model.sFile.loadFromUrl(placeholder.url, {url: placeholder.token, fileName: '@' + placeholder.fullyQualifiedName}).then(function(file) {
            self.model = new Model.sFile(file);
            self.model.loadContent().then(function() {
                digestIfNeeded(self.$scope);
            });
        });

        this.panelRef.destroy();
        this.panelRef = null;
    };

    Object.defineProperties(
        FileUploadController,
        {
            EVENT_BROWSE_REMOTE_FILES: {
                value: EVENT_BROWSE_REMOTE_FILES
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.FileUploadController#EVENT_BROWSE_REMOTE_FILES
                 * @type {String}
                 */
            },
            EVENT_FILE_CHANGED: {
                value: EVENT_FILE_CHANGED
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.FileUploadController#EVENT_FILE_CHANGED
                 * @type {String}
                 */
            }
        });

    ns.FileUploadController = FileUploadController;
})(Object.namespace('Controller.Component'));
