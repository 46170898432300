(function(ns) {
    /**
     * @namespace
     * @alias sSettings.Controller.Platform.InstagramDirect
     * @constructor
     *
     * @param $mdDialog
     * @param Notification
     * @param {Service.sConfirm} sConfirm
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param $scope
     * @param {sSender.Service.sInstagramDirectAccount} sInstagramDirectAccount
     * @param $rootScope
     * @param sBugsnag
     */
    var InstagramDirect = function (
        $mdDialog,
        Notification,
        sConfirm,
        sAPIAccess,
        $scope,
        sInstagramDirectAccount,
        $rootScope,
        sBugsnag
    ) {
        var lockFn;

        this.notification            = Notification;
        this.$scope                  = $scope;
        this.sInstagramDirectAccount = sInstagramDirectAccount;
        this.$deRegister             = [];
        this.sConfirm                = sConfirm;
        this.sAPIAccess              = sAPIAccess;
        this.$mdDialog               = $mdDialog;

        $scope.$on('$destroy', this.$onDestroy.bind(this));

        this.lockRouteChange = function lockRouteChange() {
            this.releaseRouteChange();

            lockFn = $rootScope.$on('$routeChangeStart', function(event) {
                event.preventDefault();
                sBugsnag.notify('Route change attempt while messenger connect dialog in progress', {});
            });

            // put first
            $rootScope.$$listeners.$routeChangeStart.splice(0, 0, $rootScope.$$listeners.$routeChangeStart.pop());
        };

        this.releaseRouteChange = function releaseRouteChange() {
            if (!lockFn) {
                return;
            }

            lockFn();
        };

        this.accountList = this.getAccountList();
    };

    /**
     * @function
     * @name sSettings.Controller.Platform.InstagramDirect#$onDestroy
     */
    InstagramDirect.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name sSettings.Controller.Platform.InstagramDirect#getAccountList
     * @return {Model.List}
     */
    InstagramDirect.prototype.getAccountList = function getAccountList() {
        var self = this,
            column,
            list = new Model.List.RESTAccess(
                Model.Sender.InstagramDirectAccount,
                this.sAPIAccess.endpoint('sender.instagramDirect.all').get()
            )
        ;

        column = list.columns.createAndAdd('subjectName');
        column.isSortable = false;
        column.label = 'Name';

        column = list.columns.createAndAdd('timeUpdated');
        column.isSortable = false;
        column.label = 'Updated';

        column = list.columns.createAndAdd('status');
        column.isSortable = false;
        column.label = 'Status';

        list.actions = {};

        if (this.sAPIAccess.isAllowed('sender.instagramDirect.synchronize', Const.Method.PUT)) {
            list.actions['synchronize'] = new Model.Menu.Action(
                'synchronize',
                function (account) {
                    return self.sInstagramDirectAccount.synchronize(account).always(
                        function () {
                            self.notification.success('Connection status updated');
                            list.load(false, true);
                            digestIfNeeded(self.$scope);
                        }
                    );
                }
            );
        }

        if (this.sAPIAccess.isAllowed('sender.instagramDirect.connect', Const.Method.PUT)) {
            list.actions['connect'] = new Model.Menu.Action(
                'connect',
                function (account) {
                    return self.sInstagramDirectAccount.connectWithAuthentication(account, true).then(
                        function () {
                            self.notification.success('Connected account');
                        },
                        function () {
                            self.notification.error('Could not connect account');
                        }).always(
                        function () {
                            list.load(false, true);
                            digestIfNeeded(self.$scope);
                        }
                    );
                }
            );
        }

        if (this.sAPIAccess.isAllowed('sender.instagramDirect.enable', Const.Method.PUT)) {
            list.actions['enable'] = new Model.Menu.Action(
                'enable',
                function (account) {
                    return self.sInstagramDirectAccount.enable(account).then(
                        function () {
                            self.notification.success('Enabled account');
                        },
                        function () {
                            self.notification.error('Could not enable account');
                        }).always(
                        function () {
                            list.load(false, true);
                            digestIfNeeded(self.$scope);
                        }
                    );
                }
            );
        }

        if (this.sAPIAccess.isAllowed('sender.instagramDirect.disable', Const.Method.PUT)) {
            list.actions['disable'] = new Model.Menu.Action(
                'disable',
                function (account) {
                    return self.sInstagramDirectAccount.disable(account).then(
                        function () {
                            self.notification.success('Disabled account');

                            self.$scope.$emit('sSenderDeactivated', account);
                        },
                        function () {
                            self.notification.error('Could not disable account');
                        }).always(
                        function () {
                            list.load(false, true);
                            digestIfNeeded(self.$scope);
                        }
                    );
                }
            );
        }

        if (this.sAPIAccess.isAllowed('sender.instagramDirect.RESTAccess', Const.Method.DELETE)) {
            list.actions['remove'] = new Model.Menu.Action(
                'remove',
                function (account) {
                    return self.sInstagramDirectAccount.remove(account).then(
                        function () {
                            self.notification.success('Removed account');

                            self.$scope.$emit('sSenderRemoved', account);
                        },
                        function () {
                            self.notification.error('Could not remove account');
                        }).always(
                        function () {
                            list.load(false, true);
                            digestIfNeeded(self.$scope);
                        }
                    );
                }
            );
        }

        if (Object.keys(list.actions).length) {
            column = list.columns.createAndAdd('options');
            column.label = 'Options';
        }

        return list;
    };

    /**
     * @function
     * @name sSettings.Controller.Platform.InstagramDirect#addNewAccount
     * @return {PromiseLike<Model.Sender.InstagramDirectAccount>}
     */
    InstagramDirect.prototype.addNewAccount = function addNewAccount() {
        var self = this;

        return this.sInstagramDirectAccount.addNewAccount().then(function (account) {
            self.notification.success('New account successfully added');

            self.$scope.$emit('sSenderConnected', { type: Model.Sender.InstagramDirectAccount.TYPE });
        }, function (reason) {
            if (reason === sSender.Service.sInstagramDirectAccount.REJECT_REASON_MANUAL) {
                return;
            }
            self.notification.error('Could not add new account');
        }).always(function () {
            self.accountList.load(false, true);
            digestIfNeeded(self.$scope);
        });
    };

    ns.InstagramDirect = InstagramDirect;
})(Object.namespace('sSettings.Controller.Platform'));
