(function (ns) {
    /**
     * @namespace
     * @alias sMember.Route.AttributeOverview
     * @extends sRoute
     * @constructor
     */
    var AttributeOverview = function AttributeOverview() {
        this.controller     = sMember.Controller.AttributeOverview;
        this.controllerAs   = '$overviewCtrl';
        this.templateUrl    = 'smember:attributeoverview';
        this.reloadOnSearch = false;
        this.accessControl  = {'member.attribute': Const.Method.GET};

        AttributeOverview._pProto.constructor.call(this);
    };

    Object.extendProto(AttributeOverview, sRoute);

    ns.AttributeOverview = AttributeOverview;
})(Object.namespace('sMember.Route'));
