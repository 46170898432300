(function (ns) {
    var placeholders = [];

    /**
     * @namespace
     * @alias Model.Behavior.ExposePlaceholder
     *
     * @param {Function} filterFn
     * @param {string?} traversePropertyName
     * @constructor
     */
    var ExposePlaceholder = function (filterFn, traversePropertyName) {
        var cachedPlaceholders;
        if (this.constructor === ExposePlaceholder) {
            throw 'Behavior should not be instantiated!';
        }

        this.getContextSpecificPlaceholders = function getContextSpecificPlaceholders() {
            if (cachedPlaceholders) {
                return cachedPlaceholders;
            }
            cachedPlaceholders = placeholders.filter(filterFn.bind(this));

            if (traversePropertyName && this[traversePropertyName] && this[traversePropertyName].getContextSpecificPlaceholders instanceof Function) {
                cachedPlaceholders = cachedPlaceholders.concat(this[traversePropertyName].getContextSpecificPlaceholders()).unique();
            }

            return cachedPlaceholders;
        };

        this.resetContextSpecificPlaceholderCache = function resetContextSpecificPlaceholderCache() {
            cachedPlaceholders = null;
        }
    };

    /**
     * @param {Model.Source.Placeholder[]} placeholdersToAdd
     */
    ExposePlaceholder.addPlaceholders = function (placeholdersToAdd) {
        placeholdersToAdd.map(function (placeholder) {
            if (placeholders.indexOf(placeholder) === -1) {
                placeholders.push(placeholder);
            }
        });
    };

    ns.ExposePlaceholder = ExposePlaceholder;
})(Object.namespace('Model.Behavior'));
