(function() {

    var ns = Object.namespace('Model'),
        DateRange = ns.DateRange = function () {
            var from            = null,
                to              = null,
                preselection    = null
            ;

            Object.defineProperties(this, {
                /**
                 * @property {moment|null}
                 * @name Model.DateRange#from
                 */
                from: {
                    enumerable: true,
                    configurable: true,
                    get: function () {
                        return from;
                    },
                    set: function(val) {
                        if (val !== null && !moment.isMoment(val)) {
                            throw '`From` must be a moment object or null.';
                        }
                        from = moment.isMoment(val) ? moment(val) : null;
                    }
                },
                /**
                 * @property {moment|null}
                 * @name Model.DateRange#to
                 */
                to: {
                    enumerable: true,
                    configurable: true,
                    get: function () {
                        return to;
                    },
                    set: function(val) {
                        if (val !== null && !moment.isMoment(val)) {
                            throw '`To` must be a moment object or null.';
                        }
                        to = moment.isMoment(val) ? moment(val) : null;
                    }
                },
                /**
                 * @property {moment|null}
                 * @name Model.DateRange#preselection
                 */
                preselection: {
                    enumerable: true,
                    configurable: true,
                    get: function () {
                        return preselection;
                    },
                    set: function(val) {
                        if (val !== null && !moment.isMoment(val)) {
                            throw '`Preselection` must be a moment object or null.';
                        }
                        preselection = moment.isMoment(val) ? moment(val) : null;
                    }
                }
            });

            if (arguments[0] instanceof Model.DateRange) {
                this.from   = arguments[0].from;
                this.to     = arguments[0].to;
            } else if (typeof arguments[0] !== 'undefined' && typeof arguments[1] !== 'undefined') {
                this.from   = arguments[0];
                this.to     = arguments[1];
            }

        };

    /**
     * @function
     * @name Model.DateRange#isDateBetweenPreselection
     * @param {moment} date
     * @returns Boolean
     */
    DateRange.prototype.isDateBetweenPreselection = function isDateBetweenPreselection(date) {
        return moment.isMoment(date)
            && this.preselection
            && this.from
            && !this.to
            && (
                date.isBetween(this.from, this.preselection, 'day', '[]')
                || date.isBetween(this.preselection, this.from, 'day', '[]')
            );
    };

    DateRange.prototype.valueOf = function valueOf() {
        return JSON.stringify({
            from:   this.from ? this.from.format() : null,
            to:     this.to ? this.to.format() : null
        });
    };

})();