(function (ns) {
    /**
     * @namespace
     * @alias Model.Analytics.Trace2d
     *
     * @implements Model.Behavior.Meta
     * @constructor
     */
    var Trace2d = function () {
        Model.Behavior.Meta.call(this);
        var x = [],
            y = []
        ;

        Object.defineProperties(
            this,
            {
                name: {
                    writable: true
                    /**
                     * @property
                     * @name Model.Analytics.Trace2d#name
                     * @type {String}
                     */
                },
                type: {
                    writable: true
                    /**
                     * @property
                     * @name Model.Analytics.Trace2d#type
                     * @type {String}
                     */
                },
                x: {
                    get: function() {
                        return x;
                    }
                    /**
                     * @property
                     * @name Model.Analytics.Trace2d#x
                     * @type {[]}
                     */
                },
                y: {
                    get: function () {
                        return y;
                    }
                    /**
                     * @property
                     * @name Model.Analytics.Trace2d#y
                     * @type {[]}
                     */
                }
            }
        );

        var pExtendClone = this.__extendClone;

        this.__extendClone = function __extendClone(original) {
            this.x.splice.apply(this.x, [0, this.x.length].concat(original.x));
            this.y.splice.apply(this.y, [0, this.y.length].concat(original.y));

            pExtendClone.call(this, original);
        }
    };

    /**
     * @param {Model.Analytics.Trace2d} trace
     * @param {Function} mutatorFn
     * @returns {Model.Analytics.Trace2d}
     */
    Trace2d.prototype.mutateWith = function mutateWith(trace, mutatorFn) {
        var self            = this,
            indexedSelf     = {},
            indexedTrace    = {}
        ;

        this.x.reduce(function(obj, x, index) {
            obj[String(x)] = self.y[index];
            return obj;
        }, indexedSelf);

        trace.x.reduce(function(obj, x, index) {
            obj[String(x)] = trace.y[index];
            return obj;
        }, indexedTrace);

        var result = new Trace2d();

        for (var x in indexedSelf) {
            if (!indexedTrace.hasOwnProperty(String(x))) {
                continue;
            }

            result.x.push(x);
            result.y.push(mutatorFn(indexedSelf[x], indexedTrace[x]));
        }
        return result;
    };

    /**
     * @method
     * @name Model.Analytics.Trace2d#sumUp
     * @param {Model.Analytics.Trace2d} trace
     * @returns {Model.Analytics.Trace2d}
     */
    Trace2d.prototype.sumUp = function sumUp(trace) {
        return this.mutateWith(trace, function (a,b) {
            if (a === null && b === null) {
                return null;
            }
            return a + b;
        });
    };

    /**
     * @method
     * @name Model.Analytics.Trace2d#sumUp
     * @param {Model.Analytics.Trace2d} trace
     * @returns {Model.Analytics.Trace2d}
     */
    Trace2d.prototype.subtract = function subtract(trace) {
        return this.mutateWith(trace, function (a,b) {
            if (a === null && b === null) {
                return null;
            }
            return a - b;
        })
    };

    /**
     *
     * @param {Model.Analytics.Entry[]} entries
     * @param {Function} extractorX
     * @param {Function} extractorY
     * @return {Model.Analytics.Trace2d}
     */
    Trace2d.createFromEntries = function createFromEntries(entries, extractorX, extractorY) {

        return entries.reduce(
            /**
             * @param {Model.Analytics.Trace2d} trace
             * @param {Model.Analytics.Entry} entry
             */
            function(trace, entry) {
                trace.x.push(extractorX(entry));
                trace.y.push(extractorY(entry));

                return trace;
            },
            new Trace2d()
        );
    };

    ns.Trace2d = Trace2d;
})(Object.namespace('Model.Analytics'));