(function(ns) {
    var STATUS_INACTIVE = 'inactive',
        STATUS_ACTIVE   = 'active',
        STATUS_DRAFT    = 'draft'
    ;


    /**
     * @namespace
     * @alias Model.Message.Anchor
     * @extends Model.UUID
     * @constructor
     */
    var Anchor = function(uuid) {
        var self                = this,
            timeUpdated         = moment(null),
            timeLastPublished   = moment(null),
            timeArchived        = moment(null),
            timeAdded           = moment(null),
            message
            ;

        /**
         * @property {String}
         * @name Model.Message.Anchor#title
         */
        this.title = '';
        /**
         * @property {String}
         * @name Model.Message.Anchor#type
         */
        this.type = null;
        /**
         * @property {String}
         * @name Model.Message.Anchor#domainId
         */
        this.domainId = null;
        /**
         * @property {Array}
         * @name Model.Message.Anchor#tags
         */
        this.tags = [];
        /**
         * @property {Boolean}
         * @name Model.Message.Anchor#isOld
         */
        this.isOld = false;
        /**
         * @property {String}
         * @name Model.Message.Anchor#messageUuid
         */
        this.messageUuid = null;

        Object.defineProperties(self, {
            timeUpdated: {
                enumerable: true,
                configurable: true,
                get: function () {
                    return timeUpdated;
                },
                set: function(val) {
                    if (!moment.isMoment(val)) {
                        val = moment(val);
                    }
                    timeUpdated = val;
                }
                /**
                 * @property
                 * @name Model.Message.Anchor#timeUpdated
                 * @type {?Moment}
                 */
            },
            timeLastPublished: {
                enumerable: true,
                configurable: true,
                get: function () {
                    return timeLastPublished;
                },
                set: function(val) {
                    if (!moment.isMoment(val)) {
                        val = moment(val);
                    }
                    timeLastPublished = val;
                }
                /**
                 * @property
                 * @name Model.Message.Anchor#timeLastPublished
                 * @type {?Moment}
                 */
            },
            timeArchived: {
                enumerable: true,
                configurable: true,
                get: function () {
                    return timeArchived;
                },
                set: function(val) {
                    if (!moment.isMoment(val)) {
                        val = moment(val);
                    }
                    timeArchived = val;
                }
                /**
                 * @property
                 * @name Model.Message.Anchor#timeArchived
                 * @type {?Moment}
                 */
            },
            timeAdded: {
                enumerable: true,
                configurable: true,
                get: function () {
                    return timeAdded;
                },
                set: function(val) {
                    if (!moment.isMoment(val)) {
                        val = moment(val);
                    }
                    timeAdded = val;
                }
                /**
                 * @property
                 * @name Model.Message.Anchor#timeAdded
                 * @type {?Moment}
                 */
            },
            message: {
                get: function() {
                    return message;
                },
                set: function(val) {
                    if (val === message) {
                        return;
                    }

                    if (val === null) {
                        message.messageAnchor = null;
                    } else {
                        Object.instanceOf(val, Model.Message);
                    }

                    message = val;

                    if (message) {
                        message.messageAnchor = this;
                    }
                }
                /**
                 * @property
                 * @name Model.Message.Anchor#message
                 * @type {Model.Message}
                 */
            },
            status: {
                get: function() {
                    if (this.timeArchived.unix()) {
                        return STATUS_INACTIVE;
                    }

                    if (this.timeLastPublished.unix()) {
                        return STATUS_ACTIVE;
                    }
                    return STATUS_DRAFT;
                }
                /**
                 * @property
                 * @name Model.Message.Anchor#status
                 * @type {String}
                 */
            }
        });

        /**
         * @return {string[]}
         */
        this.__dontCloneProperties = function() {
            return ['message'];
        };

        /**
         * @return {[]}
         */
        this.__getCloneArguments = function () {
            return [this.uuid];
        };

        /**
         * @property {Object}
         * @name Model.Message.Anchor#_pProto
         */
        Anchor._pProto.constructor.call(this, uuid);
    };

    Object.extendProto(Anchor, Model.UUID);

    /**
     * Creates a message-anchor by data
     * @function Model.Message.Anchor#createByData
     * @param {object} data
     * @static
     * @returns Model.Message.Anchor
     */
    Anchor.createByData = function createByData(data) {
        var uuid,
            msgAnchor;

        if (data && data.hasOwnProperty('uuid')) {
            uuid = data.uuid;
        }

        msgAnchor = new Anchor(uuid);

        msgAnchor.updateByData(data);

        return msgAnchor;
    };

    /**
     * Updates the message-anchor by data
     * @function Model.Message.Anchor#updateByData
     * @param {object} data
     */
    Anchor.prototype.updateByData = function updateByData(data) {
        ['timeUpdated', 'timeLastPublished', 'timeArchived', 'timeAdded'].map(function(index) {
            if (data[index]) {
                data[index] = data[index] * 1000;
            }
        });
        Object.updateByData(this, data);
    };

    /**
     * @function Model.Message.Anchor#updateActiveState
     * @param {boolean} deactivate
     * @returns jqXHR
     */
    Anchor.prototype.updateActiveState = function updateActiveState(deactivate) {

        var self        = this,
            endpoint    = Anchor.apiEndPoint_updateActiveState;

        return $.ajax({
            url         : endpoint(self.uuid, deactivate),
            method      : Const.Method.PUT,
            context     : self,
            processData : false,
            contentType : false
        })
    };

    /**
     * @function
     * @returns {Boolean}
     */
    Anchor.prototype.isActivated = function isActivated() {
        try {
            return this.timeLastPublished
                && this.timeLastPublished.isValid()
                && this.timeLastPublished.valueOf();
        } catch (ex) {
            return false;
        }
    };

    /**
     * @function Model.Message.Anchor#getByMessage
     * @param {sFollowPubNG.apiEndpoints} apiEndpoints
     * @param uuid
     * @returns {$.Deferred|Promise}
     */
    Anchor.getByMessage = function getByMessage(apiEndpoints, uuid) {
        return $.ajax({
            url         : apiEndpoints.messageAnchor.byMessage(uuid),
            method: Const.Method.GET,
            context     : self,
            processData : false,
            contentType : false,
            dataType    : 'json',
            success: function(data) {
                return Model.Message.Anchor.createByData(data);
            },
            error: function() {
                return [];
            }
        });
    };

    Anchor.apiEndPoint_updateActiveState = false;

    Object.defineProperties(
        Anchor,
        {
            STATUS_DRAFT: {
                value: STATUS_DRAFT
                /**
                 * @property
                 * @constant
                 * @name Model.Message.Anchor#STATUS_DRAFT
                 * @type {String}
                 */
            }
        });

    ns.Anchor = Anchor;
})(Object.namespace('Model.Message'));
