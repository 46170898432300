(function(ns) {
    /**
     * @namespace
     * @alias sFacebook.Controller.SelectPageDialog
     * @constructor
     *
     * @param $mdDialog
     */
    var SelectPageDialog = function SelectPageDialog($mdDialog) {
        this.$mdDialog = $mdDialog;
    };

    /**
     * @function
     * @name sFacebook.Controller.SelectPageDialog#cancel
     */
    SelectPageDialog.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    /**
     * @function
     * @name sFacebook.Controller.SelectPageDialog#confirmSelection
     */
    SelectPageDialog.prototype.confirmSelection = function confirmSelection() {
        this.$mdDialog.hide(this.selectedFbPage);
    };

    /**
     * @function
     * @name sFacebook.Controller.SelectPageDialog#isItemDisabled
     * @param item
     * @return {Boolean}
     */
    SelectPageDialog.prototype.isItemDisabled = function isItemDisabled(item) {
        return !item.hasRoleToConnect || item.alreadyInList;
    };

    ns.SelectPageDialog = SelectPageDialog;
})(Object.namespace('sFacebook.Controller'));
