(function(angular) {

    var module = angular.module('sMenuModule', []);

    module.factory('sMenuBotTemplates', ['sAPIAccess', function(sAPIAccess) {
        var menu        = new Model.Menu.Menu().setLabel('Bot sends...'),
            msgTypes    = Model.Message.Part.getTypes(Const.Outgoing),
            triggerType = function(item, message) {
                var part    = Model.Message.Part.create(item.options.type),
                    $event  = $.Event(Controller.Component.sMessageLog.EVENT_APPEND_MESSAGE_PART, {context: message})
                ;

                $.event.trigger($event, part);
            },
            deprecatedItems = [Model.Message.Part.TYPE_LISTVIEW]
        ;

        for (var i in msgTypes) {
            if (deprecatedItems.indexOf(i) !== -1 || (msgTypes[i].permission && !sAPIAccess.check(msgTypes[i].permission))) {
                continue;
            }

            menu.addItem(new Model.Menu.Action(msgTypes[i].name, triggerType, {type: i, icon: msgTypes[i].icon}));
        }

        return menu;
    }]);

    module.factory('sMenuUserSends', function() {
        var menu        = new Model.Menu.Menu().setLabel('User sends...'),
            triggerType = function(item, message) {
                var part = Model.Message.Part.create(item.options.type),
                    $event = $.Event(Controller.Component.sMessageLog.EVENT_APPEND_MESSAGE_PART, {context: message})
                ;
                if (item.options.isFallback) {
                    part.isFallback = true;
                    part.content.intent = 'Fallback';
                }
                $.event.trigger($event, part);
            }
        ;

        menu.addItem(
            new Model.Menu.Action(
                'Text',
                triggerType,
                {
                    type: Model.Message.Part.TYPE_AI_TEMPLATE,
                    icon: '/svg/icons/messageType/user_message.svg'
                }
            )
        );

        menu.addItem(
            new Model.Menu.Action(
                'Unmatched text',
                triggerType,
                {
                    type: Model.Message.Part.TYPE_AI_TEMPLATE,
                    isFallback: true,
                    icon: '/svg/icons/messageType/fallback.svg',
                    isDisabled: function() {
                        if (!(this instanceof Model.Message)) {
                            return;
                        }
                        return !this.canHaveNLPFallback(true);
                    }
                }
            )
        );

        return menu;
    });

    module.factory('sMenuSendAllTypes', ['sMenuBotTemplates', 'sMenuUserSends', function(sMenuBotTemplates, sMenuUserSends) {
        return new Model.Menu.Menu().merge(sMenuBotTemplates).merge(sMenuUserSends);
    }]);

})(angular);
