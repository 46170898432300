(function (ns) {
    /**
     * @namespace
     * @alias Model.Cell
     *
     * @implements Model.Behavior.Meta
     * @constructor
     */
    var Cell = function (val) {
        Model.Behavior.Meta.call(this);
        
        Object.defineProperties(
            this,
            {
                val: {
                    enumerable: true,
                    get: function () {
                        return val;
                    }
                    /**
                     * @property
                     * @name Model.Cell#val
                     * @type {String}
                     */
                }
                
            });
    };

    Cell.prototype.valueOf = function valueOf() {
        return this.val;
    };

    Cell.prototype.toString = function toString() {
        return this.val;
    };

    Cell.prototype.toJSON = function toJSON() {
        return this.val;
    };

    ns.Cell = Cell;
})(Object.namespace('Model'));