(function (ns) {
    var KEY_WEEKDAYS        = 'weekdays',
        KEY_MONTHDAYS       = 'monthdays',
        KEY_REPEAT_DAILY    = 'daily',
        KEY_REPEAT_WEEKLY   = 'weekly',
        KEY_REPEAT_MONTHLY  = 'monthly',
        weekdayOptions      = [
            {
                value     : 0,
                label     : 'Monday',
                shorthand : 'Mon'
            },
            {
                value     : 1,
                label     : 'Tuesday',
                shorthand : 'Tue'
            },
            {
                value     : 2,
                label     : 'Wednesday',
                shorthand : 'Wed'
            },
            {
                value     : 3,
                label     : 'Thursday',
                shorthand : 'Thu'
            },
            {
                value     : 4,
                label     : 'Friday',
                shorthand : 'Fri'
            },
            {
                value     : 5,
                label     : 'Saturday',
                shorthand : 'Sat'
            },
            {
                value     : 6,
                label     : 'Sunday',
                shorthand : 'Sun'
            }
        ],
        monthdayOptions     = []
    ;

    for (var i = 31; i > 0; i--) {
        monthdayOptions.unshift({
            value       : i,
            label       : i.toOrdinalSuffixedString(),
            shorthand   : i.toOrdinalSuffixedString()
        });
    }

    /**
     * @namespace
     * @alias Model.Schedule
     *
     * @constructor
     */
    var Schedule = function () {
        var self            = this,
            values          = [],
            type,
            repeat,
            time            = Model.Time.create(10, 0),
            timeEnd         = null,
            useUserTimezone = false
            ;

        /**
         * @private
         * @param {*} val
         * @returns {Model.Time|null}
         */
        var normalizeTime = function normalizeTime(val) {
                if (val && !(val instanceof Model.Time)) {
                    if (moment.isMoment(val)) {
                        val = val.format('HH:mm:ssZ');
                    }
                    if (typeof(val) === 'string') {
                        val = Model.Time.fromISOTime(val);
                    } else {
                        return null;
                    }
                }

                return val;
            },
            valuesToJSON = function() {
                return values.map(function(element) {
                    return element.value;
                });
            }
        ;

        Object.defineProperties(
            this,
            {
                'type': {
                    enumerable: true,
                    get: function() {
                        return type;
                    },
                    set: function(val) {
                        if (val === type) {
                            return;
                        }

                        if (val
                            && !self.getTypeOptions().filter(function(element) {
                                return element.value === val;
                            }).length
                        ) {
                            return;
                        }

                        type = val;
                        self.values = [];
                    }
                    /**
                     * @name Model.Schedule#repeat
                     * @type {String}
                     */
                },
                'repeat': {
                    enumerable: true,
                    get: function() {
                        return repeat;
                    },
                    set: function(val) {
                        if (val === repeat) {
                            return;
                        }

                        if (val
                            && !self.getRepeatOptions().filter(function(element) {
                                return element.value === val;
                            }).length
                        ) {
                            return;
                        }

                        repeat = val;
                    }
                    /**
                     * @name Model.Schedule#repeat
                     * @type {String}
                     */
                },
                'time' : {
                    enumerable: true,
                    get: function() {
                        return time;
                    },
                    set: function(val) {
                        val = normalizeTime(val);

                        if (JSON.stringify(val) !== JSON.stringify(time)) {
                            time = val;
                        }
                    }
                    /**
                     * @name Model.Schedule#time
                     * @type {Model.Time}
                     */
                },
                'timeEnd' : {
                    enumerable: true,
                    get: function() {
                        return timeEnd;
                    },
                    set: function(val) {
                        val = normalizeTime(val);

                        if (JSON.stringify(val) !== JSON.stringify(timeEnd)) {
                            timeEnd = val;
                        }
                    }
                    /**
                     * @name Model.Schedule#timeEnd
                     * @type {Model.Time}
                     */
                },
                'values' : {
                    enumerable: true,
                    get: function() {
                        return values;
                    },
                    set: function(val) {
                        if (!(val instanceof Array)) {
                            val = [val];
                        }

                        var valueOptions = self.getValueOptions();
                        values = val.filter(function(value) {
                            for (var i = 0; i < valueOptions.length; i++) {
                                if (valueOptions[i].value == value.value) {
                                    return true;
                                }
                            }
                            return false;
                        });

                        values.toJSON = valuesToJSON;
                        Object.defineProperty(values, 'toJSON', {
                            enumerable: false
                        });
                    }
                    /**
                     * @name Model.Schedule#values
                     * @type {Array}
                     */
                },
                'isTimeRange' : {
                    get: function() {
                        return self.timeEnd !== null;
                    },
                    set: function(val) {
                        if (val && !self.timeEnd) {
                            self.timeEnd = Model.Time.create(18, 0);
                        } else if (!val && self.timeEnd) {
                            self.timeEnd = null;
                        }
                    }
                    /**
                     * @name Model.Schedule#isTimeRange
                     * @type {Boolean}
                     */
                },
                'useUserTimezone': {
                    enumerable: true,
                    get: function() {
                        return useUserTimezone;
                    },
                    set: function(val) {
                        val = Boolean(val);
                        if (useUserTimezone === val) {
                            return;
                        }
                        useUserTimezone = val;
                    }
                    /**
                     * @name Model.Schedule#useUserTimezone
                     * @type {Boolean}
                     */
                }
            }
        );

        this.type = this.getTypeOptions().slice().pop().value;
        this.repeat = this.getRepeatOptions().slice().shift().value;
    };

    /**
     * @name Model.Schedule#getTypeOptions
     * @returns {{value: string, label: string}[]}
     */
    Schedule.prototype.getTypeOptions = function getTypeOptions() {
        return [
            {
                value: KEY_WEEKDAYS,
                label: 'days of the week'
            },
            {
                value: KEY_MONTHDAYS,
                label: 'days of the month'
            }
        ];
    };

    /**
     * @name Model.Schedule#getRepeatOptions
     * @returns {{value: string, label: string}[]}
     */
    Schedule.prototype.getRepeatOptions = function getRepeatOptions() {
        return [
            {
                value: KEY_REPEAT_DAILY,
                label: 'daily'
            },
            {
                value: KEY_REPEAT_WEEKLY,
                label: 'weekly'
            },
            {
                value: KEY_REPEAT_MONTHLY,
                label: 'monthly'
            }
        ];
    };

    /**
     * @name Model.Schedule#getValueOptions
     * @returns {{value: string, label: string}[]}
     */
    Schedule.prototype.getValueOptions = function getValueOptions() {
        switch (this.type) {
            case KEY_WEEKDAYS:
                return weekdayOptions.slice();
            case KEY_MONTHDAYS:
                return monthdayOptions.slice();
            default:
                return [];
        }
    };

    /**
     * @name Model.Schedule#updateByData
     * @param {Object} data
     */
    Schedule.prototype.updateByData = function updateByData(data) {
        Object.updateByData(this, data);

        var values = data.values;

        if (!(values instanceof Array)) {
            values = [values];
        }

        this.values = this.getValueOptions().filter(function(option) {
            for (var i = 0; i < values.length; i++) {
                if (option.value == values[i]) {
                    return true;
                }
            }
            return false;
        });
    };

    /**
     * Creates a schedule by data
     * @static
     * @function
     * @name Model.Schedule.createByData
     * @param {object} data
     * @returns Model.Schedule
     */
    Schedule.createByData = function createByData(data) {
        var schedule = new Schedule();

        schedule.updateByData(data);
        return schedule;
    };

    Schedule.TYPE_WEEKDAYS  = KEY_WEEKDAYS;
    Schedule.TYPE_MONTHDAYS = KEY_MONTHDAYS;

    ns.Schedule = Schedule;
})(Object.namespace('Model'));
