(function (ns) {
    /**
     * @namespace
     * @alias sSource.Route.Overview
     * @extends sRoute
     * @constructor
     */
    var Overview = function Overview() {
        this.controller     = sSource.Controller.Overview;
        this.controllerAs   = '$overviewCtrl';
        this.templateUrl    = 'ssource:overview';
        this.reloadOnSearch = false;
        this.accessControl  = {'source.RESTAccess': Const.Method.GET};

        Overview._pProto.constructor.call(this);
    };

    Object.extendProto(Overview, sRoute);

    ns.Overview = Overview;
})(Object.namespace('sSource.Route'));
