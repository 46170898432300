(function(ns) {
    var STEP_OVERVIEW       = 'overview',
        STEP_ADACCOUNTLIST  = 'adAccountList',
        STEP_ADACCOUNTINPUT = 'adAccountInput'
    ;
    /**
     * @namespace
     * @alias sFacebook.Controller.AdAccountSetupDialog
     * @constructor
     *
     * @param $scope
     * @param $mdDialog
     * @param {sFacebook.Service.sFacebookAdAccount} sFacebookAdAccount
     */
    var AdAccountSetupDialog = function AdAccountSetupDialog($scope, $mdDialog, sFacebookAdAccount) {
        var self = this;

        this.$scope             = $scope;
        this.$mdDialog          = $mdDialog;
        this.sFacebookAdAccount = sFacebookAdAccount;

        /**
         * @property
         * @name sFacebook.Controller.AdAccountSetupDialog#step
         * @type {String}
         */
        this.step = STEP_OVERVIEW;

        /**
         * @property
         * @name sFacebook.Controller.AdAccountSetupDialog#adAccountSender
         * @type {?Model.Sender.FacebookAdAccount}
         */
        this.adAccountSender = null;

        /**
         * @property
         * @name sFacebook.Controller.AdAccountSetupDialog#currentConnection
         * @type {?Model.Facebook.Connection}
         */

        /**
         * @property
         * @name sFacebook.Controller.AdAccountSetupDialog#readableGraphAdAccounts
         * @type {Array}
         */

        this.resetProcess();

        /**
         * @property
         * @name sFacebook.Controller.AdAccountSetupDialog#loading
         * @type {PromiseLike}
         */
        this.loading = this.sFacebookAdAccount.getSkeletonAdAccount().then(function (adAccountSender) {
              self.adAccountSender      = adAccountSender;
              self.currentConnection    = self.sFacebookAdAccount.getConnectionInstance(adAccountSender);
        }).always(function () {
            digestIfNeeded(self.$scope);
        });
    };

    /**
     * @function
     * @name sFacebook.Controller.AdAccountSetupDialog#startAutomatedProcess
     */
    AdAccountSetupDialog.prototype.startAutomatedProcess = function startAutomatedProcess() {
        var self = this;

        this.loading = this.sFacebookAdAccount.authenticateWithFacebook(this.currentConnection, this.adAccountSender).then(
            function () {
                return self.sFacebookAdAccount.getReadableGraphAdAccounts(self.currentConnection).then(function (graphAdAccounts) {
                    self.readableGraphAdAccounts    = graphAdAccounts;
                    self.step                       = STEP_ADACCOUNTLIST;
                });
            }
        ).always(function () {
            digestIfNeeded(self.$scope);
        });
    };

    /**
     * @function
     * @name sFacebook.Controller.AdAccountSetupDialog#resetProcess
     */
    AdAccountSetupDialog.prototype.resetProcess = function resetProcess() {
        this.step                       = STEP_OVERVIEW;
        this.readableGraphAdAccounts    = [];
    };

    /**
     * @function
     * @name sFacebook.Controller.AdAccountSetupDialog#startManualProcess
     */
    AdAccountSetupDialog.prototype.startManualProcess = function startManualProcess() {
        this.step = STEP_ADACCOUNTINPUT;
    };

    /**
     * @function
     * @name sFacebook.Controller.AdAccountSetupDialog#onHandleSelectionChange
     * @param {FacebookAdAccount} facebookGraphAdAccount
     */
    AdAccountSetupDialog.prototype.onHandleSelectionChange = function onHandleSelectionChange(facebookGraphAdAccount) {
        if (!facebookGraphAdAccount) {
            return;
        }

        this.adAccountSender.adAccountId    = facebookGraphAdAccount.id;
        this.adAccountSender.adAccountName  = facebookGraphAdAccount.name;
    };

    /**
     * @function
     * @name sFacebook.Controller.AdAccountSetupDialog#isItemDisabled
     * @param {FacebookAdAccount} facebookGraphAdAccount
     * @return {Boolean}
     */
    AdAccountSetupDialog.prototype.isItemDisabled = function isItemDisabled(facebookGraphAdAccount) {
        return !!facebookGraphAdAccount.alreadyInList;
    };

    /**
     * @function
     * @name sFacebook.Controller.AdAccountSetupDialog#cancel
     */
    AdAccountSetupDialog.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    /**
     * @function
     * @name sFacebook.Controller.AdAccountSetupDialog#confirmSelection
     */
    AdAccountSetupDialog.prototype.confirmSelection = function confirmSelection() {
        this.$mdDialog.hide(this.adAccountSender);
    };

    ns.AdAccountSetupDialog = AdAccountSetupDialog;
})(Object.namespace('sFacebook.Controller'));
