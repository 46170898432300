(function(ns, angular, $) {
    /**
     * Helper to initialize and bootstrap angular applications.
     * @type {Application}
     */
    var Application = ns.Application = function(appName) {
        var self = this,
            ngInjector  = angular.injector(["ng", "sAppCookies"]),
            $cookies    = ngInjector.get("$cookies")
            ;

        // if we are entering login page by redirection from another page, save original path
        if (appName === 'sNGExtern' && location.hash && location.hash.indexOf('/auth/') === -1) {
            $cookies.put(sSecurity.Controller.Login.KEY_COOKIE_REDIRECT_URL, location.hash.split('#')[1]);
        }

        $(document).ready(function() {
            self.configurateJQueryAjax($cookies);
            self.boot(appName);
        });
    };

    /**
     * @function
     * @name Spectrm.Application#configurateJQueryAjax
     */
    Application.prototype.configurateJQueryAjax = function configurateJQueryAjax($cookies) {
        // fix deprecated field type
        $.ajaxPrefilter(function (options) {
            options.method = (options.method || options.type);
        });

        // adding csrf-token-header via preFilter
        $.ajaxPrefilter(function (options, originalOptions, jqXHR) {
            if (options.method && ['GET', 'HEAD', 'OPTIONS', 'TRACE'].indexOf(options.method.toUpperCase()) !== -1) {
                return;
            }
            var csrfToken = $cookies.get("XSRF-TOKEN");
            jqXHR.setRequestHeader("X-XSRF-TOKEN", csrfToken);
        });
    };

    /**
     * @function
     * @name Spectrm.Application#boot
     */
    Application.prototype.boot = function boot(appName) {
        return angular.bootstrap(document, [appName]);
    };

})(Object.namespace('Spectrm'), angular, $);