(function(ns) {
    /**
     * @namespace
     * @alias sAPIAccess.Provider.sAPIAccess
     * @constructor
     */
    var sAPIAccessProvider = function () {
        var self = this;

        /**
         * @property
         * @type {Object.<string, Object.<string, string>>} The first string is the token we use for mapping, second is the method-string and third is the mapped route-name
         * @name sAPIAccess.Provider.sAPIAccess#endpointMapping
         */
        this.endpointMapping = {};

        /**
         * @property
         * @type {Object.<string, string>}
         * @name sAPIAccess.Provider.sAPIAccess#fixedEndpoints
         */
        this.fixedEndpoints = {};

        this.$get = ['$rootScope', '$route', function($rootScope, $route) {
            return new sAPIAccess.Service.sAPIAccess(self.endpointMapping, self.fixedEndpoints, $rootScope, $route);
        }];
    };

    ns.sAPIAccess = sAPIAccessProvider;
})(Object.namespace('sAPIAccess.Provider'));