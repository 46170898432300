(function (ns) {
    /**
     * @namespace
     * @alias Model.Exception.InvalidActionDefinitionCombination
     * @constructor
     * @extends Error
     *
     * @param {Model.Action.Definition} actionDefinition
     * @param {...string} arguments
     */
    var Exception = function (actionDefinition) {

        var names = Array.prototype.slice.call(arguments, 1),
            relationStrings = names.map(function (name) {
                return "[" + name + " => " + actionDefinition.name + "]";
            });

        this.name               = 'InvalidActionDefinitionCombination';
        this.message            = 'Combination of actions is invalid. Missing relations: ' + relationStrings.join(',');
        this.actionDefinition   = actionDefinition;
    };

    Object.extendProto(Exception, Error);

    ns.InvalidActionDefinitionCombination = Exception;
})(Object.namespace('Model.Exception'));
