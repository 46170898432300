(function (ns) {
    /**
     * @namespace
     * @alias sEntity.Route.Overview
     * @extends sRoute
     * @constructor
     */
    var Overview = function Overview() {
        this.controller     =  sEntity.Controller.Overview;
        this.controllerAs   = '$overviewCtrl';
        this.templateUrl    = 'sentity:overview';
        this.reloadOnSearch = false;
        this.accessControl  = {'entity.management': [Const.Method.GET]};

        Overview._pProto.constructor.call(this);
    };

    Object.extendProto(Overview, sRoute);

    ns.Overview = Overview;
})(Object.namespace('sEntity.Route'));
