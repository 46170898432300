(function (ns) {
    /**
     * @namespace
     * @alias sSource.Provider.sSource
     *
     * @constructor
     */
    var sSourceProvider = function() {
        var sourcesUrl;
        
        Object.defineProperties(
            this,
            {
                url: {
                    get: function () {
                        return sourcesUrl;
                    },
                    set: function (val) {

                        if (val === sourcesUrl) {
                            return;
                        }
                        sourcesUrl = val;
                    }
                    /**
                     * @property
                     * @name sSource.Provider.sSource#url
                     * @type {String}
                     */
                }
            });

        /**
         *
         * @param $injector
         * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
         */
        this.$get = function $get($injector, sAPIAccess) {
            if (sourcesUrl instanceof Function) {
                var oldSourcesUrl = sourcesUrl;
                sourcesUrl = function() {
                    return $injector.invoke(oldSourcesUrl);
                }
            }
            return new sSource.Service.sSource(sourcesUrl, sAPIAccess);
        }
    };

    ns.sSource = sSourceProvider;
})(Object.namespace('sSource.Provider'));
