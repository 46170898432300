(function(ns){
    /**
     * @namespace
     * @alias Model.Menu.Menu
     *
     * @extends Model.Menu.Entry
     */
    var Menu = function() {
            /**
             * @name Model.Menu.Menu#items
             * @type {Model.Menu.Entry[]}
             */
        var items = []
            ;

            /**
             * @name Model.Menu.Menu#label
             * @type {string}
             */
        this.label = '';

            /**
             * @name Model.Menu.Menu#options
             * @type {Object}
             */
        this.options = {};

            /**
             * @property
             * @name Model.Menu.Entry#isMenu
             * @returns {Boolean}
             */

        Object.defineProperties(
            this,
            {
                /**
                 * @property {Array}
                 * @name Model.Menu.Menu#items
                 */
                items: {
                    enumerable: true,
                    get: function() {
                        return items;
                    }
                }
            }
        );

            /**
             * @name Model.Menu.Menu#addItem
             * @param item
             */
        this.addItem = function addItem(item) {
            Object.instanceOf(item, ns.Entry);

            items.push(item);
            item.parent = this;
            return this;
        };

        /**
         * @name Model.Menu.Menu#removeItem
         * @param item
         */
        this.removeItem = function removeItem(item) {
            Object.instanceOf(item, ns.Entry);
            var index;

            if ((index = items.indexOf(item)) !== -1) {
                var retVal = items.splice(index, 1).pop();
                retVal.parent = null;

                return retVal;
            }

            return null;
        };

        /**
         * @name Model.Menu.Menu#removeItems
         */
        this.removeItems = function removeItems() {
            for (var i = items.length - 1;  i >= 0; i--) {
                this.removeItem(items[i]);
            }
        };

        /**
         * @function
         * @name Model.Menu.Menu#insertItem
         * @param item
         * @param position
         * @param {Boolean=} replace
         */
        this.insertItem = function insertItem(item, position, replace) {
            if (position >= items.length) {
                items.push(item);
            } else if (position < 0) {
                items.unshift(item);
            } else {
                items.splice(position, Number(replace), item);
            }
        };

        /**
         * @name Model.Menu.Menu#getItemIndex
         * @param item
         */
        this.getItemIndex = function getItemIndex(item) {
            return items.indexOf(item);
        };

        this.findItemByLabel = function findItemByLabel(label) {
            var itemFound = false;
            for (var i = 0; i < items.length; i++) {
                /**
                 * @name item
                 * @type {Model.Menu.Entry}
                 */
                var item = items[i];
                if (item.label === label) {
                    itemFound = item;
                } else if (item.isMenu) {
                    itemFound = item.findItemByLabel(label);
                }

                if (itemFound) {
                    break;
                }
            }

            return itemFound;
        };

        Menu._pProto.constructor.call(this, Menu);
    };

    Object.extendProto(Menu, ns.Entry);

    /**
     * @name Model.Menu.Menu#sub
     */
    Menu.prototype.sub = function sub(menu) {
        if (!menu) {
            menu = new Menu();
        }
        this.addItem(menu);

        return menu;
    };

    /**
     * @name Model.Menu.Menu#end
     */
    Menu.prototype.end = function end() {
        if (this.parent) {
            return this.parent;
        } else {
            return this;
        }
    };

    /**
     * @name Model.Menu.Menu#setLabel
     * @param {String} val
     */
    Menu.prototype.setLabel = function setLabel(val) {
        this.label = val;
        return this;
    };

    Menu.prototype.setOptions = function setOptions(val) {
        this.options = val;
        return this;
    };

    /**
     * @name Model.Menu.Menu#addAction
     */
    Menu.prototype.addAction = function addAction() {
        return this.add.apply(this, Array.prototype.concat.apply(['ACTION'], arguments));
    };

    /**
     * @name Model.Menu.Menu#addDivider
     */
    Menu.prototype.addDivider = function addDivider() {
        return this.add.apply(this, Array.prototype.concat.apply(['DIVIDER'], arguments));
    };

    /**
     * @param {String} type
     */
    Menu.prototype.add = function add(type) {
        var typeClass;
        if (!(typeClass = ns.Entry.TYPES[type])) {
            throw 'Type not found: ' + type;
        }
        var item = Object.construct(typeClass, Array.prototype.slice.call(arguments, 1));
        this.addItem(item);
        return this;
    };

    /**
     * @name Model.Menu.Menu#merge
     * @param {Model.Menu.Menu} otherMenu
     * @param noDivider
     */
    Menu.prototype.merge = function (otherMenu, noDivider) {
        if (this.items.length && !noDivider) {
            this.addDivider();
        }

        if (otherMenu.label) {
            this.addItem(new ns.Label(otherMenu.label));
        }

        for (var i in otherMenu.items) {
            this.addItem(otherMenu.items[i]);
        }
        return this;
    };

    /**
     * @return {Model.Menu.Menu[]}
     */
    Menu.prototype.splitByLabels = function splitByLabels() {
        return this.items.reduce(function(carry, item) {
            if (item instanceof Model.Menu.Label) {
                var lastMenu = new Model.Menu.Menu();
                lastMenu.label = item.label;
                carry.push(lastMenu);
                return carry;
            } else  if (item instanceof Model.Menu.Divider) {
                return carry;
            }

            carry[carry.length -1].addItem(item);

            return carry;
        }, []);
    };

    /**
     * @name Model.Menu.Menu#isActive
     * @return {boolean}
     */
    Menu.prototype.isActive = function isActive() {
        var isActive = false;
        for (var i = 0; i < this.items.length; i++) {
            if (!this.items[i].isActive) {
                continue;
            }
            if (this.items[i].isActive()) {
                isActive = true;
                break;
            }
        }
        return isActive;
    };

    Menu.prototype.getActiveItem = function getActiveItem() {
        for (var i = 0; i < this.items.length; i++) {
            if (!this.items[i].isActive) {
                continue;
            }
            if (this.items[i].isActive()) {
                return this.items[i];
            }
        }

        return null;
    };

    Menu.prototype.fromJSON = function fromJSON(json) {
        this.label = json.label || this.label;

        if (json.items) {
            for (var i = 0; i < json.items.length; i++) {
                var item = json.items[i];
                if (!(item instanceof ns.Entry)) {
                    switch (item.type) {
                        case 'menu':
                            item = new ns.Menu();
                            break;
                        case 'action':
                            item = new ns.Action();
                            break;
                        case 'divider':
                            item = new ns.Divider();
                            break;
                        case 'label':
                            item = new ns.Label();
                            break;
                    }
                    item.fromJSON(json.items[i]);
                }
                this.addItem(item);
            }
        }

        return this;
    };

    /**
     * @name Model.Menu.Menu#getElementsOfType
     * @param {Object} type
     */
    Menu.prototype.getElementsOfType = function getElementsOfType(type) {
        return this.items.reduce(function (carry, value) {
            if (value instanceof type) {
                carry.push(value);
            } else if (value instanceof Menu) {
                carry = carry.concat(value.getElementsOfType(type));
            }
            return carry;
        }, []);
    };

    ns.Entry.TYPES.MENU = Menu;
    Menu.TYPE_IDENT = 'MENU';

    ns.Menu = Menu;
})(Object.namespace('Model.Menu'));