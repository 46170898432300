(function(ns) {
    var STEP_OVERVIEW      = 'overview',
        STEP_ACCOUNT_LIST  = 'accountList',
        STEP_ACCOUNT_INPUT = 'accountInput'
    ;
    /**
     * @namespace
     * @alias sSender.Controller.InstagramDirectAccountSetupDialog
     * @constructor
     *
     * @param $scope
     * @param $mdDialog
     * @param {sSender.Service.sInstagramDirectAccount} sInstagramDirectAccount
     */
    var InstagramDirectAccountSetupDialog = function InstagramDirectAccountSetupDialog(
        $scope,
        $mdDialog,
        sInstagramDirectAccount
    ) {
        var self = this;

        this.$scope                  = $scope;
        this.$mdDialog               = $mdDialog;
        this.sInstagramDirectAccount = sInstagramDirectAccount;

        /**
         * @property
         * @name sSender.Controller.InstagramDirectAccountSetupDialog#step
         * @type {String}
         */
        this.step = STEP_OVERVIEW;

        /**
         * @property
         * @name sSender.Controller.InstagramDirectAccountSetupDialog#instagramDirectAccountSender
         * @type {?Model.Sender.InstagramDirectAccount}
         */
        this.instagramDirectAccountSender = null;

        /**
         * @property
         * @name sSender.Controller.InstagramDirectAccountSetupDialog#currentConnection
         * @type {?Model.Facebook.Connection}
         */

        /**
         * @property
         * @name sSender.Controller.InstagramDirectAccountSetupDialog#readableGraphAccounts
         * @type {Array}
         */

        this.resetProcess();

        /**
         * @property
         * @name sSender.Controller.InstagramDirectAccountSetupDialog#getSkeletonAccount
         * @type {PromiseLike}
         */
        this.loading = this.sInstagramDirectAccount.getSkeletonAccount().then(function (instagramDirectAccountSender) {
              self.instagramDirectAccountSender = instagramDirectAccountSender;
              self.currentConnection            = self.sInstagramDirectAccount.getConnectionInstance(instagramDirectAccountSender);
        }).always(function () {
            digestIfNeeded(self.$scope);
        });
    };

    /**
     * @function
     * @name sSender.Controller.InstagramDirectAccountSetupDialog#startAutomatedProcess
     */
    InstagramDirectAccountSetupDialog.prototype.startAutomatedProcess = function startAutomatedProcess() {
        var self = this;

        this.loading = this.sInstagramDirectAccount.authenticateWithFacebook(this.currentConnection, this.instagramDirectAccountSender).then(
            function () {
                return self.sInstagramDirectAccount.getReadableGraphPages(self.currentConnection).then(function (graphAccounts) {
                    self.readableGraphAccounts = graphAccounts;
                    self.step                  = STEP_ACCOUNT_LIST;
                });
            }
        ).always(function () {
            digestIfNeeded(self.$scope);
        });
    };

    /**
     * @function
     * @name sSender.Controller.InstagramDirectAccountSetupDialog#resetProcess
     */
    InstagramDirectAccountSetupDialog.prototype.resetProcess = function resetProcess() {
        this.step                  = STEP_OVERVIEW;
        this.readableGraphAccounts = [];
    };

    /**
     * @function
     * @name sSender.Controller.InstagramDirectAccountSetupDialog#startManualProcess
     */
    InstagramDirectAccountSetupDialog.prototype.startManualProcess = function startManualProcess() {
        this.step = STEP_ACCOUNT_INPUT;
    };

    /**
     * @function
     * @name sSender.Controller.InstagramDirectAccountSetupDialog#onHandleSelectionChange
     * @param {Object} facebookGraphAccount
     */
    InstagramDirectAccountSetupDialog.prototype.onHandleSelectionChange = function onHandleSelectionChange(facebookGraphAccount) {
        if (!facebookGraphAccount) {
            return;
        }

        this.instagramDirectAccountSender.subjectId    = facebookGraphAccount.id;
        this.instagramDirectAccountSender.subjectName  = facebookGraphAccount.name;
        this.instagramDirectAccountSender.pageId       = facebookGraphAccount.pageId;
    };

    /**
     * @function
     * @name sSender.Controller.InstagramDirectAccountSetupDialog#isItemDisabled
     * @param {Object} facebookGraphAccount
     * @return {Boolean}
     */
    InstagramDirectAccountSetupDialog.prototype.isItemDisabled = function isItemDisabled(facebookGraphAccount) {
        return !!facebookGraphAccount.alreadyInList;
    };

    /**
     * @function
     * @name sSender.Controller.InstagramDirectAccountSetupDialog#cancel
     */
    InstagramDirectAccountSetupDialog.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    /**
     * @function
     * @name sSender.Controller.InstagramDirectAccountSetupDialog#confirmSelection
     */
    InstagramDirectAccountSetupDialog.prototype.confirmSelection = function confirmSelection() {
        this.$mdDialog.hide(this.instagramDirectAccountSender);
    };

    ns.InstagramDirectAccountSetupDialog = InstagramDirectAccountSetupDialog;
})(Object.namespace('sSender.Controller'));
