(function (ns) {
    /**
     * @namespace
     * @alias sMember.Controller.EditAttribute
     * @constructor
     *
     * @param $scope
     * @param $mdDialog
     * @param Notification
     * @param {Service.sBugsnag} sBugsnag
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {Model.Member.Attribute} attribute
     */
    var EditAttribute = function ($scope, $mdDialog, Notification, sBugsnag, sAPIAccess, attribute) {
        this.$scope = $scope;
        this.$mdDialog = $mdDialog;
        this.notification = Notification;
        this.sBugsnag = sBugsnag;
        this.sAPIAccess = sAPIAccess;
        this.attribute = attribute;
        this.attributeCopy = attribute.clone();
        this.saving = false;
        this.errorOnSave = false;
    };

    EditAttribute.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    EditAttribute.prototype.save = function cancel() {
        if (this.saving) {
            return;
        }

        if (this.attribute.key === this.attributeCopy.key) {
            this.$mdDialog.cancel();
        }

        this.saving = true;
        this.errorOnSave = false;

        var self = this,
            data = {
                key    : this.attribute.key,
                newKey : this.attributeCopy.key
            },
            formData = new FormData();

        formData.append('attribute', JSON.stringify(data));

        $.ajax({
            url         : this.sAPIAccess.endpoint('member.attribute').put(),
            method      : Const.Method.PUT,
            context     : self,
            processData : false,
            data        : formData
        }).then(
            function () {
                self.$mdDialog.hide();
            },
            function (response, error, message) {
                self.sBugsnag.notify({
                    name     : 'Renaming member attribute error',
                    message  : message,
                    metaData : data
                });

                self.$scope.$evalAsync(function () {
                    self.saving = false;
                    self.errorOnSave = true;
                });
            }
        );
    };

    ns.EditAttribute = EditAttribute;
})(Object.namespace('sMember.Controller'));
