(function (ns) {
    var EVENT_REQUEST_CONTEXT       = 'sEventAttributeRequestContext',
        EVENT_MEMBER_VALUE_REFRESH  = 'sMemberAttributeNameChanged',
        TYPE_ATTRIBUTE_NAME         = 'name',
        TYPE_ATTRIBUTE_VALUE        = 'value',
        HINT_USER_LABEL_DEPRECATED  = 'User Labels are being deprecated. Use "User Attributes" instead.'
    ;

    var GroupHead = function(name) {
        Object.defineProperties(
            this,
            {
                name: {
                    enumerable: true,
                    get: function () {
                        return name;
                    }
                    /**
                     * @property
                     * @name GroupHead#name
                     * @type {String}
                     */
                },
                isGroup: {
                    value: true
                    /**
                     * @property
                     * @constant
                     * @name GroupHead#isGroup
                     * @type {String}
                     */
                }
            });
    };
    GroupHead.prototype.toString = function() {
        return this.name;
    };

    /**
     * @namespace
     * @alias Controller.Component.sMemberAttributeSelect
     *
     * @constructor
     * @param {Service.sMemberAttribute} sMemberAttribute
     * @param $scope
     * @param {sSource.Service.sSource} sSource
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     */
    var sMemberAttributeSelect = function (sMemberAttribute, $scope, sSource, sAPIAccess) {
        this.sMemberAttribute         = sMemberAttribute;
        this.$scope                   = $scope;
        this.sSource                  = sSource;
        this.sAPIAccess               = sAPIAccess;

        this.changedEventName       = EVENT_MEMBER_VALUE_REFRESH;

        var self              = this,
            hasNoValue        = true,
            columnValuesCache = null
        ;

        Object.defineProperties(
            this,
            {
                key: {
                    enumerable: true,
                    configurable: true,
                    get: function () {
                        if (!self.model) {
                            return null;
                        }

                        return self.model.key;
                    },
                    set: function (val) {
                        if (!self.model) {
                            self.model = {};
                        }

                        if (self.model.key === val) {
                            return;
                        }

                        self.model.key = val;

                        if (!self.allowNew && self.model.key) {
                            // if new is not allowed then do the hasNoValue check
                            self.sMemberAttribute.getAttributeByKey(self.model.key).then(function(attribute) {
                                if (!attribute) {
                                    // can't have value if not found
                                    self.hasNoValue = true;
                                    return;
                                }
                                self.hasNoValue = !attribute.valueCount;
                            });
                        }
                    }
                    /**
                     * @property
                     * @name Controller.Component.sMemberAttributeSelect#key
                     * @type {String}
                     */
                },
                hasNoValue: {
                    enumerable: true,
                    configurable: true,
                    get: function () {
                        return hasNoValue;
                    },
                    set: function (val) {
                        if (val === hasNoValue) {
                            return;
                        }
                        hasNoValue = val;
                        digestIfNeeded(self.$scope);
                    }
                    /**
                     * @property
                     * @name Controller.Component.sMemberAttributeSelect#hasNoValue
                     * @type {Boolean}
                     */
                }
            });

        /**
         * @param {String} column
         * @return {$.Deferred<{column: String, value: String, operators: [], type: string}>}
         */
        this.getValuesForContentSourceColumn = function getValuesForContentSourceColumn(column) {
            if (!columnValuesCache || columnValuesCache.for !== column) {
                if (!columnValuesCache) {
                    columnValuesCache = {};
                }
                columnValuesCache.for = column;
                columnValuesCache.values = $.get(sAPIAccess.endpoint('source.column.values').get(column));
            }

            return columnValuesCache.values;
        };

        /**
         * @property
         * @name Controller.Component.sMemberAttributeSelect#model
         * @type {Object}
         */

        /**
         * @property
         * @name Controller.Component.sMemberAttributeSelect#allowNew
         * @type {Boolean}
         */
    };

    /**
     * @name Controller.Component.sMemberAttributeSelect#getAttributeNames
     * @return {PromiseLike}
     */
    sMemberAttributeSelect.prototype.getAttributeNames = function getAttributeNames() {
        context = {
            keyOnly : this.keyOnly,
            type    : TYPE_ATTRIBUTE_NAME
        };

        var sentContext = JSON.stringify(context);

        this.$scope.$emit(EVENT_REQUEST_CONTEXT, context);

        var attributes = this.sMemberAttribute.getAvailableAttributes()
        ;

        /**
         * @param {String[]} attributes
         * @param {String[]} highlighted
         * @param {[]?}      notFound
         * @return {[]}
         */
        function highlightAttributes (attributes, highlighted, notFound) {
            highlighted = highlighted || [];
            notFound    = notFound || [];

            return attributes.filter(function (attributeName) {
                if (highlighted.indexOf(attributeName) !== -1) {
                    return true;
                }
                notFound.push(attributeName);
                return false;
            });
        }

        // the event processing has altered the context >> let's process the changes
        if (sentContext !== JSON.stringify(context)) {
            // the event contains data to process
            if (context.messages && this.keyOnly) {
                var attributesInMessages = this.sMemberAttribute.extractAttributesFromConversation(context.messages);
                attributes.then(function (results) {
                    results.sortLocaleCompare();
                    var names = attributesInMessages.map(function (attribute) {
                            return attribute.key;
                        }),
                        notFoundGroup = [],
                        foundGroup    = highlightAttributes(results, names, notFoundGroup)
                    ;

                    if (foundGroup.length) {
                        foundGroup.unshift(new GroupHead('In this journey'));

                        if (notFoundGroup.length) {
                            notFoundGroup.unshift(new GroupHead('Not in this journey'));
                        }
                    }

                    results.splice.apply(results, [0, results.length].concat(foundGroup.concat(notFoundGroup)));
                    return results;
                });
                return attributes;
            }
        }

        if (!this.allowNew) {
            attributes.then(function(results, attributes) {
                results.sortLocaleCompare();
                var usageFoundGroup     = [],
                    usageNotFoundGroup  = [],
                    indexedAttributes   = {}
                ;

                indexedAttributes = attributes.reduce(
                    function (carry, element) {
                        carry[element.key] = element;
                        return carry;
                    }, {});

                results.map(function(result) {
                    if (indexedAttributes[result] && indexedAttributes[result].inUse) {
                        usageFoundGroup.push(result);
                    } else {
                        usageNotFoundGroup.push(result);
                    }
                });

                if (usageFoundGroup.length) {
                    usageFoundGroup.unshift(new GroupHead('Assigned to user'));
                    if (usageNotFoundGroup.length) {
                        usageNotFoundGroup.unshift(new GroupHead('No assignment yet'));
                    }
                }

                results.splice.apply(results, [0, results.length].concat(usageFoundGroup, usageNotFoundGroup));

                return results;
            });
        } else {
            var sourcePromise = this.sSource.getAvailableSourceFieldsAsAttributes();

            return attributes.then(function (results) {
                results.sortLocaleCompare();
                return sourcePromise.then(function (fieldsAsAttributes) {
                    if (!fieldsAsAttributes.length) {
                        return results;
                    }

                    var names         = fieldsAsAttributes.map(function (fieldAsAttribute) {
                            return fieldAsAttribute.name;
                        }).sortLocaleCompare(),
                        notFoundGroup = []
                    ;

                    highlightAttributes(results, names, notFoundGroup);

                    if (names.length) {
                        names.unshift(new GroupHead('Content source columns'));

                        if (notFoundGroup.length) {
                            notFoundGroup.unshift(new GroupHead('Other attributes'));
                        }
                    }

                    results.splice.apply(results, [0, results.length].concat(names.concat(notFoundGroup)));
                    return results;
                });
            });
        }

        return attributes;
    };

    /**
     * @name Controller.Component.sMemberAttributeSelect#getAttributeValues
     * @return {PromiseLike}
     */
    sMemberAttributeSelect.prototype.getAttributeValues = function getAttributeValues() {
        var $deferred = $.Deferred(),
            self      = this
        ;

        if (this.keyOnly || !this.model || !this.model.key) {
            $deferred.resolve([]);
            return $deferred.promise();
        }

        context = {
            type    : TYPE_ATTRIBUTE_VALUE
        };

        var sentContext = JSON.stringify(context),
            otherGroup  = false
        ;

        this.$scope.$emit(EVENT_REQUEST_CONTEXT, context);

        var deferreds = [];

        deferreds.push(this.sMemberAttribute.getAvailableAttributeValues(this.model.key, !this.allowNew).then(function(results) {
            results.sortLocaleCompare();
            if (self.hasNoValue && results.length) {
                self.hasNoValue = false;
            }
            return results;
        }));

        if (this.allowNew && !this.keyOnly) {
            context.columnValues = this.getValuesForContentSourceColumn(this.model.key);
        }

        // the event processing has altered the context >> let's process the changes
        if (sentContext !== JSON.stringify(context)) {
            var entitiesGroup = [];

            // we have entities set
            if (context.entities instanceof Array) {
                context.entities.map(function (entity) {
                    entitiesGroup.push(Model.AI.MatchGroup.ENTITY_PREFIX + entity);
                });

                if (entitiesGroup.length) {
                    entitiesGroup.unshift(new GroupHead('Available entities'));
                    otherGroup = true;
                }
            }

            if (context.placeholders instanceof Array) {
                var placeholdersGroup = [];
                context.placeholders.map(function(placeholder) {
                    if (placeholder.type !== 'string') {
                        return;
                    }

                    placeholdersGroup.push(placeholder.token);

                });
                if (placeholdersGroup.length) {
                    placeholdersGroup.unshift(new GroupHead('Available variables'));
                    otherGroup = true;
                }
            }

            if (context.columnValues && context.columnValues.then) {
                deferreds.push(context.columnValues.then(function (columnValues) {
                    var columnValuesGroup = [],
                        column
                    ;
                    columnValues.map(function (columnValue) {
                        if (columnValue.type !== 'string') {
                            return;
                        }
                        column = columnValue.column;
                        columnValuesGroup.push(columnValue.value);
                    });
                    if (columnValuesGroup.length) {
                        columnValuesGroup.unshift(new GroupHead('Values in source column "' + column  + '"'));
                        otherGroup = true;
                    }

                    return columnValuesGroup;
                }));
            }

            if (entitiesGroup.length && otherGroup) {
                entitiesGroup = entitiesGroup.concat(new GroupHead('Associated values'));
            }
        }

        return $.when.apply($, deferreds).then(function (results) {
            results.splice.apply(results, [0, 0].concat(placeholdersGroup || [], entitiesGroup || []));
            var restArgs = Array.prototype.slice.call(arguments, 1);
            if (restArgs.length) {
                // first array is empty, because the concat is applied as well, and the data is set as the "this" of concat
                results.splice.apply(results, [].concat.apply([results.length, 0], restArgs));
            }

            return results;
        });
    };

    /**
     * @name Controller.Component.sMemberAttributeSelect#handleNameChange
     * @param {String?} item
     */
    sMemberAttributeSelect.prototype.handleNameChange = function handleNameChange(item) {
        if (this.keyOnly) {
            return;
        }

        var self = this;
        if (!this.model) {
            return;
        }

        item = item || null;

        if (this.model.key === item) {
            return;
        }

        if (!item) {
            this.model.key = null;
        }


        // needs to be async, otherwise the model is not updated yet
        setTimeout(
            function() {
                self.model.value = null;
                self.$scope.$broadcast(self.changedEventName);
                if (self.model.key) {
                    self.sMemberAttribute.addAttribute(Service.sMemberAttribute.createKeyValueObjectByData({key: self.model.key}));
                }
            }
        );
    };

    /**
     * @name Controller.Component.sMemberAttributeSelect#handleValueChange
     * @param {String?} item
     */
    sMemberAttributeSelect.prototype.handleValueChange = function handleValueChange(item) {
        if (!this.model) {
            return;
        }

        item = item || null;

        if (this.model.value === item) {
            return;
        }

        if (item && (item.search(Model.AI.MatchGroup.IS_ENTITY_PATTERN) === -1)) {
            this.sMemberAttribute.addAttribute(Service.sMemberAttribute.createKeyValueObjectByData({
                key: this.model.key,
                value: item
            }));
        }
    };

    /**
     * @name Controller.Component.sMemberAttributeSelect#isValueFieldDisabled
     */
    sMemberAttributeSelect.prototype.isValueFieldDisabled = function isValueFieldDisabled() {
        return this.isDisabled || !this.model || !this.model.key || (this.hasNoValue && !this.allowNew);
    };

    /**
     * Disable sorting, because the order is set with the choices
     * @name Controller.Component.sMemberAttributeSelect#nullSort
     */
    sMemberAttributeSelect.prototype.nullSort = function nullSort() {
    };

    Object.defineProperties(
        sMemberAttributeSelect,
        {
            EVENT_REQUEST_CONTEXT: {
                value: EVENT_REQUEST_CONTEXT
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sMemberAttributeSelect#EVENT_REQUEST_CONTEXT
                 * @type {String}
                 */
            },
            TYPE_ATTRIBUTE_NAME: {
                value: TYPE_ATTRIBUTE_NAME
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sMemberAttributeSelect#TYPE_ATTRIBUTE_NAME
                 * @type {String}
                 */
            },
            TYPE_ATTRIBUTE_VALUE: {
                value: TYPE_ATTRIBUTE_VALUE
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sMemberAttributeSelect#TYPE_ATTRIBUTE_VALUE
                 * @type {String}
                 */
            },
            EVENT_MEMBER_VALUE_REFRESH: {
                value: EVENT_MEMBER_VALUE_REFRESH
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sMemberAttributeSelect#EVENT_MEMBER_VALUE_REFRESH
                 * @type {String}
                 */
            },
            HINT_USER_LABEL_DEPRECATED: {
                value: HINT_USER_LABEL_DEPRECATED
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sMemberAttributeSelect#HINT_USER_LABEL_DEPRECATED
                 * @type {String}
                 */
            }
        });

    ns.sMemberAttributeSelect = sMemberAttributeSelect;
})(Object.namespace('Controller.Component'));
