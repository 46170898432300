(function(ns) {
    var EVENT_REST_ACCESS_INSTANCE_RETRIEVED = 'restAccessInstanceRetrieved';

    var saving              = {},
        uuidRepo            = {},
        relationHandlers    = {}
    ;

    /**
     * @callback relationHandlerCallback
     * @param {Object} data
     * @return {{uuid: String, endPoint: String}[]}
     */

    /**
     * @param {String} endPoint
     * @param {relationHandlerCallback} handler
     */
    var registerRelationHandler = function registerRelationHandler(endPoint, handler) {
        Object.instanceOf(handler, Function);

        if (!endPoint || relationHandlers[endPoint]) {
            return;
        }

        relationHandlers[endPoint] = handler;
    };

    /**
     * Adds related RESTAccessByUUID objects from a result data if the relation handler returns any
     * @param {String} endPoint
     * @param {Object} data
     */
    var callRelationHandler = function callRelationHandler(endPoint, data) {
        if (!relationHandlers[endPoint]) {
            return;
        }

        var handlerResult = relationHandlers[endPoint](data);

        if (!handlerResult || !(handlerResult instanceof Array)) {
            return;
        }

        handlerResult.map(function(obj) {
            uuidRepo[obj.endPoint] = uuidRepo[obj.endPoint] || [];
            uuidRepo[obj.endPoint].push(obj.uuid);
        });
    };

    /**
     * @namespace
     * @alias Model.RESTAccessByUUID
     *
     * @extends Model.UUID
     * @param {String=} uuid
     */
    var RESTAccessByUUID = function(uuid) {

        var endPoint            = null,
            isNew               = true,
            JSONValue           = '',
            isAlreadyDeleted    = false
        ;

        // set endpoint if it's there
        if (this.endPoint) {
            endPoint = this.endPoint;
        }

        // if uuid is given this object is not new ???
        if (uuid && uuidRepo[endPoint] && uuidRepo[endPoint].indexOf(uuid) !== -1) {
            isNew = false;
        }

        Object.defineProperties(
            this,
            {
                endPoint : {
                    enumerable: false,
                    get: function() {
                        return endPoint;
                    }
                    /**
                     * @property
                     * @type {String}
                     * @name Model.RESTAccessByUUID#endPoint
                     */
                },
                isNew   : {
                    get: function() {
                        return isNew;
                    },
                    set: function(val) {
                        isNew = Boolean(val);
                    }
                    /**
                     * @property
                     * @type {Boolean}
                     * @name Model.RESTAccessByUUID#isNew
                     */
                },
                isAlreadyDeleted: {
                    get: function () {
                        return isAlreadyDeleted;
                    }
                    /**
                     * @property
                     * @type {Boolean}
                     * @name Model.RESTAccessByUUID#isAlreadyDeleted
                     */
                }
            }
        );

        RESTAccessByUUID._pProto.constructor.call(this, uuid);

        this.markAsDeleted = function () {
            isAlreadyDeleted = true;
        };

        this.setJSONState = function() {
            JSONValue = this.getData();
        };

        this.isModified = function() {
            return this.getData() !== JSONValue || this.isNew;
        };

        this.save = function() {
            return Object.getPrototypeOf(this).save.apply(this, arguments).then(function(data) {
                if (this.isNew) {
                    isNew = false;
                }

                this.setJSONState();
                return data;
            }.bind(this));
        };

        this.setJSONState();

        this.__getCloneArguments = function() {
            return [this.uuid];
        };

        function setAsNew(value) {
            isNew = Boolean(value);
        }

        // expose protected for inheritance
        if (this.constructor !== RESTAccessByUUID && this instanceof RESTAccessByUUID) {
            return {
                registerRelationHandler: registerRelationHandler,
                setAsNew: setAsNew
            }
        }
    };

    Object.extendProto(RESTAccessByUUID, Model.UUID);

    /**
     * Saves the object via an API
     * @function Model.RESTAccessByUUID#save
     * @returns {$.Deferred}
     */
    RESTAccessByUUID.prototype.save = function save() {
        var self = this,
            executeSave = function() {
                var formData = this.getFormData(),
                    url
                ;

                if (!this.isModified()) {
                    return $.Deferred().resolveWith('No changes').promise();
                }

                // check for endpoint
                if (!self.endPoint) {
                    throw new Model.Exception.RESTAccessNoEndpointSet();
                }

                url = self.endPoint + ((self.isNew) ? '' : self.uuid);

                return $.ajax({
                    url         : url,
                    method      : self.isNew ? Const.Method.POST : Const.Method.PUT,
                    data        : formData,
                    context     : self,
                    processData : false,
                    contentType : false
                }).then(
                    function(data) {
                        // TODO: better handling for changed uuid
                        if (data.uuid && self.uuid !== data.uuid) {
                            // removed because it makes problems atm
                            //throw "Uuid changed from " + self.uuid + " to " + data.uuid + ". Aborted.";
                        }

                        // object is not new anymore because it's saved at least once
                        if (self.isNew) {
                            uuidRepo[self.endPoint] = uuidRepo[self.endPoint] || [];
                            uuidRepo[self.endPoint].push(self.uuid);
                        }
                        self.setJSONState();
                        return data;
                    }
                );
            };

        // prevent saving race, only one save should be active at the time
        if (saving[this.uuid]) {
            return saving[this.uuid].then(function() {
                return executeSave.apply(self);
            });
        } else {
            saving[this.uuid] = executeSave.apply(this).always(function() {
                saving[self.uuid] = null;
            });
        }

        return saving[this.uuid];
    };

    /**
     * @name Model.RESTAccessByUUID#delete
     * @returns {*}
     */
    RESTAccessByUUID.prototype.delete = function () {
        if (this.isAlreadyDeleted || this.isNew) {
            return $.when(null);
        }

        var self = this,
            data = new FormData()
            ;

        // check for endpoint
        if (!this.endPoint) {
            throw new Model.Exception.RESTAccessNoEndpointSet();
        }

        this.markAsDeleted();

        return $.ajax({
            url         : (self.endPoint + self.uuid),
            method      : Const.Method.DELETE,
            data        : data,
            context     : self,
            processData : false,
            contentType : false
        }).then(function() {
            var index = uuidRepo[self.endPoint].indexOf(self.uuid);
            if (uuidRepo[self.endPoint] && index === -1) {
                return data;
            }
            uuidRepo[self.endPoint].splice(index, 1);
            return data;
        });
    };

    RESTAccessByUUID.prototype.updateByData = function updateByData() {
        this.setJSONState();
    };

    /**
     * Loads the object via an API and by uuid, requires a valid RESTAccessByUuid constructor bound as `this`
     * @function Model.RESTAccessByUUID#load
     * @param {string} endPoint
     * @param {string} uuid
     * @param {Array=} errorsContainer When provided errors will be suppressed and returned in that container
     * @static
     * @returns {$.Deferred}
     */
    RESTAccessByUUID.load = function load(endPoint, uuid, errorsContainer) {
        var self = this;

        if (!(Object.inherits(self, Model.RESTAccessByUUID))) {
            throw 'A RESTAccessByUUID constructor has to be provided as `this`.';
        }

        return $.ajax({
            url     : endPoint + uuid,
            method  : Const.Method.GET
        }).then(function(data) {
            uuidRepo[endPoint] = uuidRepo[endPoint] || [];
            uuidRepo[endPoint].push(data.uuid);
            callRelationHandler(endPoint, data);
            var instance;
            if (self.createByData) {
                instance = self.createByData(data);
            } else {
                instance = new self(data.uuid);
            }
            $.event.trigger(EVENT_REST_ACCESS_INSTANCE_RETRIEVED, instance);
            try {
                instance.updateByData(data);
            } catch (err) {
                if (!errorsContainer) {
                    throw err;
                }

                errorsContainer.push(err);
            }
            return $.Deferred().resolve(instance, errorsContainer);
        });
    };

    //noinspection JSUnusedLocalSymbols
    /**
     * Gets the formdata-representation of the object
     * @abstract
     * @function Model.RESTAccessByUUID#getFormData
     * @param {FormData=} formData
     * @param {string=} name
     * @returns FormData
     */
    RESTAccessByUUID.prototype.getFormData = function getFormData(formData, name) {
        return (formData && formData instanceof FormData) ? formData : new FormData();
    };

    /**
     * @name Model.RESTAccessByUUID#getData
     * @returns string
     */
    RESTAccessByUUID.prototype.getData = function getData() {
        return JSON.stringify(
            this,
            this.mapFn
        );
    };

    /**
     * Maps properties of the object to it's JSON representation
     * @param {String} key
     * @param {*} value
     * @returns {undefined|*}
     */
    RESTAccessByUUID.prototype.mapFn = function mapFn(key, value) {
        return (key === '$$hashKey') ? undefined : value;
    };

    Object.defineProperties(
        RESTAccessByUUID,
        {
            EVENT_REST_ACCESS_INSTANCE_RETRIEVED: {
                value: EVENT_REST_ACCESS_INSTANCE_RETRIEVED
                /**
                 * @property
                 * @constant
                 * @name Model.RESTAccessByUUID#EVENT_REST_ACCESS_INSTANCE_RETRIEVED
                 * @type {String}
                 */
            }
        });
    ns.RESTAccessByUUID = RESTAccessByUUID;
})(Object.namespace('Model'));
