(function(ns) {
    var AuthenticationExpired = ns.AuthenticationExpired = function (sAuth, $mdDialog) {
        this.$mdDialog  = $mdDialog;
        this.sAuth      = sAuth;
    };

    AuthenticationExpired.prototype.checkAuthState = function checkAuthState() {
        var self = this;
        this.sAuth.reloadUser().then(function () {
            self.$mdDialog.hide();
        });
    };

})(Object.namespace('sSecurity.Controller'));