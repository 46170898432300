(function(ns) {
    /**
     * @alias sSegment.Service.sSegment
     * @namespace
     *
     * @param $mdDialog
     * @param sConfirm
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {Service.sDomain} sDomainService
     * @param {sSegment.Service.sConditionDefinition} sSegmentConditionDefinition
     * @param $rootScope
     * @constructor
     */
    var sSegmentService = function ($mdDialog, sConfirm, sAPIAccess, sDomainService, sSegmentConditionDefinition, $rootScope) {
        this.$mdDialog                      = $mdDialog;
        this.sConfirm                       = sConfirm;
        this.sAPIAccess                     = sAPIAccess;
        this.sDomainService                 = sDomainService;
        this.sSegmentConditionCollection    = sSegmentConditionDefinition;
        this.$rootScope                     = $rootScope;

        var segmentCache = {},
            loadingInProgress
        ;

        $rootScope.$on(Service.sDomain.EVENT_DOMAIN_SET, function() {
            segmentCache = {};
        });

        this.loadSegmentsByDomainId = function loadSegmentsByDomainId() {
            if (!loadingInProgress) {
                loadingInProgress = sSegmentService.prototype.loadSegmentsByDomainId.apply(this, arguments).then(function (segmentCollection) {
                    segmentCollection.items.reduce(function (cache, segment) {
                        cache[segment.uuid] = segment;
                        return cache;
                    }, segmentCache);

                    return segmentCollection.items;
                }).always(function() {
                    loadingInProgress = null;
                })
            }

            return loadingInProgress;
        };

        /**
         * Resolves a segment hash from the cache, or re-fetches cache if needed
         * @param {String} hash
         * @return {*}
         */
        this.resolveSegmentHash = function resolveSegmentHash(hash) {
            if (segmentCache[hash]) {
                return $.Deferred().resolve(segmentCache[hash]);
            } else {
                return this.loadSegmentsByCurrentDomain().then(function() {
                    return segmentCache[hash];
                });
            }
        };
    };

    /**
     * @function
     * @name sSegment.Service.sSegment#createSegment
     * @returns Promise
     */
    sSegmentService.prototype.createSegment = function createSegment() {
        var segment = new Model.Segment();
        segment.domainId = this.sDomainService.currentDomainId;
        return this.editSegment(segment);
    };

    /**
     * @function
     * @name sSegment.Service.sSegment#editSegment
     * @param {Model.Segment} segment
     * @returns Promise
     */
    sSegmentService.prototype.editSegment = function editSegment(segment) {
        var self = this;
        return this.openSegmentEditDialog(segment).then(
            function(segmentClone) {
                return segmentClone
                    .save()
                    .then(function () {
                        self.$rootScope.$emit('sSegmentSaved', segmentClone);
                    });
            }
        );
    };

    /**
     * @function
     * @name sSegment.Service.sSegment#openSegmentEditDialog
     * @param {Model.Segment} segment
     * @returns Promise
     */
    sSegmentService.prototype.openSegmentEditDialog = function openSegmentEditDialog(segment) {
        var dialogSegment = segment.clone(),
            deferred = $.Deferred()
        ;

        this.$mdDialog.show({
            controller          : sSegment.Controller.EditDialog,
            controllerAs        : '$ctrl',
            templateUrl         : 'ssegment:edit-dialog',
            parent              : angular.element(Const.PanelAnchor),
            clickOutsideToClose : true,
            bindToController    : true,
            locals: {
                segment     : dialogSegment,
                origSegment : segment
            }
        }).then(
            function() {
                deferred.resolve(dialogSegment);
            },
            function () {
                deferred.reject(false);
            }
        );

        return deferred;
    };

    /**
     * @function
     * @name sSegment.Service.sSegment#deleteSegment
     * @param {Model.Segment} segment
     * @returns Promise
     */
    sSegmentService.prototype.deleteSegment = function deleteSegment(segment) {
        var self = this;
        var confirmOptions = {
                confirm: 'Remove',
                decline: 'Cancel',
                title: 'Remove segment',
                content: 'Do you really want to remove the segment <b>' + escapeHTML(segment.name) + '</b> ?'
            };

        function escapeHTML(str) {
            return str.replace(/[&<>"']/g, function (match) {
                return {
                    '&': '&amp;',
                    '<': '&lt;',
                    '>': '&gt;',
                    '"': '&quot;',
                    "'": '&#39;'
                }[match];
            });
        }
            

        return this.sConfirm.open(confirmOptions).then(
            function () {
                return segment
                    .delete()
                    .then(function () {
                        self.$rootScope.$emit('sSegmentRemoved', segment);
                    });
            },
            function () {
                return false;
            });
    };

    /**
     * @function
     * @name sSegment.Service.sSegment#loadSegmentsByDomainId
     * @param {int} domainId
     * @returns Promise
     */
    sSegmentService.prototype.loadSegmentsByDomainId = function loadSegmentsByDomainId(domainId) {
        var self = this;
        return $.aggregateAction([
                this.sSegmentConditionCollection.init.bind(this),
                function() {
                    var collection  = new Model.Collection.RESTAccess(Model.Segment);
                    return collection.load(self.sAPIAccess.endpoint('segment.byDomain.selectable').get(domainId));
                }
            ],
            Model.RESTAccessByUUID.endpoint_batch()
        ).then(function(results) {
            return results[1];
        });
    };
    /**
     * @function
     * @name sSegment.Service.sSegment#loadSegmentsByCurrentDomain
     * @returns Promise
     */
    sSegmentService.prototype.loadSegmentsByCurrentDomain = function loadSegmentsByCurrentDomain() {
        var self = this;

        return this.sDomainService.getCurrentDomainId().then(function (currentDomainId) {
            if (!currentDomainId) {
                return $.Deferred().reject('There must be a domain set!');
            }
            return self.loadSegmentsByDomainId(currentDomainId);
        });
    };

    /**
     * @param {Model.Segment} segment
     */
    sSegmentService.prototype.fetchRecipientCount = function fetchRecipientCount(segment) {
        return $.ajax({
            url: this.sAPIAccess.endpoint('member.count').get(),
            method: Const.Method.GET,
            data: {
                conditions: JSON.stringify(segment.getDispatchConditions(true))
            }
        }).then(function(count) {
            segment.activeMembers = count;
        }, function() {
            segment.activeMembers = 0;
        });
    };

    ns.sSegment = sSegmentService;

})(Object.namespace('sSegment.Service'));