(function (ns) {
    var STATUS_ACTIVE       = 'active',
        STATUS_INACTIVE     = 'inactive',
        TYPE_MESSENGER_CODE = 'messengerCode',
        TYPE_PAYLOAD_ONLY   = 'payloadOnly'
    ;

    /**
     * @namespace
     * @alias Model.EntryPoint
     * @param {String=} uuid
     * @param {String=} payload
     *
     * @extends Model.RESTAccessByUUID
     */
    var EntryPoint = function (uuid, payload) {
        var type    = TYPE_MESSENGER_CODE,
            actions = new Model.Action.Collection(),
            senders = new Model.Sender.Collection(Model.Sender.FacebookPage),
            timeUpdated,
            ident,
            status
        ;

        this.endPoint = Model.EntryPoint.APIEndpoint.generic();

        /**
         * @property {Array}
         * @name Model.EntryPoint#tags
         */
        this.tags = [];

        /**
         * @property
         * @name Model.EntryPoint#senders
         * @type Model.Sender.Collection
         */

        /**
         * @property
         * @name Model.EntryPoint#actions
         * @type Model.Action.Collection
         */

        Object.defineProperties(this, {
            title         : {
                enumerable : true,
                writable   : true
                /**
                 * @property
                 * @name Model.EntryPoint#title
                 * @type string
                 */
            },
            ident         : {
                enumerable : false,
                get        : function () {
                    return ident;
                },
                set        : function (val) {
                    if (!ident) {
                        ident = val;
                    }
                }
                /**
                 * @property
                 * @name Model.EntryPoint#ident
                 * @type string
                 */
            },
            status        : {
                enumerable : true,
                get        : function () {
                    return status;
                },
                set        : function (val) {
                    status = val;
                }
                /**
                 * @property
                 * @name Model.EntryPoint#status
                 * @type string
                 */
            },
            timeUpdated   : {
                enumerable   : true,
                configurable : true,
                get          : function () {
                    return timeUpdated;
                },
                set          : function (val) {
                    if (!moment.isMoment(val)) {
                        val = moment(val);
                    }
                    timeUpdated = val;
                }
                /**
                 * @property
                 * @name Model.EntryPoint#timeUpdated
                 * @type Moment
                 */
            },
            type          : {
                enumerable   : true,
                configurable : true,
                get          : function () {
                    return type;
                },
                set          : function (val) {
                    if (EntryPoint.getAvailableTypes().indexOf(val) !== -1) {
                        type = val;
                    }
                }
                /**
                 * @property
                 * @name Model.EntryPoint#type
                 * @type string
                 */
            },
            payload       : {
                enumerable : true,
                get        : function () {
                    return payload;
                }
                /**
                 * @property
                 * @name Model.EntryPoint#payload
                 * @type {String}
                 */
            },
            senders       : {
                enumerable   : true,
                configurable : true,
                get          : function () {
                    return senders;
                },
                set          : function (val) {
                    Object.instanceOf(val, Model.Sender.Collection);
                    senders = val;
                }
            },
            actionContext : {
                enumerable   : false,
                configurable : false,
                get          : function () {
                    return 'entryPoint-' + type;
                }
            },
            actions       : {
                enumerable   : true,
                configurable : true,
                get          : function () {
                    return actions;
                },
                set          : function (val) {
                    Object.instanceOf(val, Model.Action.Collection);
                    actions = val;
                }
            }
        });

        EntryPoint._pProto.constructor.call(this, uuid);

        // Overload non-prototype functions
        var saveAncestor = this.save;
        /**
         * @return {Promise<any | never>}
         */
        this.save = function () {
            return saveAncestor.apply(this, arguments).then(function (data) {
                payload = data.payload;
                return data;
            }.bind(this));
        };

        var __getCloneArgumentsAncestor = this.__getCloneArguments;
        this.__getCloneArguments        = function __getCloneArguments() {
            var retVal = __getCloneArgumentsAncestor.call(this);
            retVal.push(payload);
            return retVal;
        };
    };

    Object.extendProto(EntryPoint, Model.RESTAccessByUUID);

    /**
     * @function
     * @name Model.EntryPoint#updateByData
     * @param {Object} data
     */
    EntryPoint.prototype.updateByData = function updateByData(data) {
        Object.updateByData(this, data);

        EntryPoint._pProto.updateByData.call(this);
    };

    /**
     * @function
     * @name Model.EntryPoint#save
     * @returns Promise
     */
    EntryPoint.prototype.save = function save() {
        var self = this;

        return EntryPoint._pProto.save.call(this).then(function (data) {
            self.updateByData(data);

            return data;
        });
    };

    /**
     * Gets the formdata-representation of the entry-point-object
     * @function
     * @name Model.EntryPoint#getFormData
     * @param {FormData=} formData
     * @returns FormData
     */
    EntryPoint.prototype.getFormData = function getFormData(formData) {
        var fData = EntryPoint._pProto.getFormData.call(this, formData),
            data  = {};

        for (var key in this) {
            if (!this.hasOwnProperty(key) || (this[key] instanceof Function)) {
                continue;
            }

            if (key === 'senders') {
                data[key] = this[key].items.map(function (sender) {
                    return sender.uuid;
                });
            } else {
                data[key] = this[key];
            }
        }

        fData.append('entryPoint', JSON.stringify(data));

        return fData;
    };

    /**
     * @function
     * @name Model.EntryPoint#isActive
     * @returns Boolean
     */
    EntryPoint.prototype.isActive = function isActive() {
        return this.status === STATUS_ACTIVE;
    };

    /**
     * @function
     * @name Model.EntryPoint#activate
     */
    EntryPoint.prototype.activate = function activate() {
        this.status = STATUS_ACTIVE;
    };

    /**
     * @function
     * @name Model.EntryPoint#deactivate
     */
    EntryPoint.prototype.deactivate = function deactivate() {
        this.status = STATUS_INACTIVE;
    };

    /**
     * @function
     * @static
     * @name Model.EntryPoint.getAvailableTypes
     * @returns Array
     */
    EntryPoint.getAvailableTypes = function getAvailableTypes() {
        return [
            TYPE_MESSENGER_CODE,
            TYPE_PAYLOAD_ONLY
        ];
    };

    /**
     * @function
     * @static
     * @name Model.EntryPoint.getLabels
     * @returns Object
     */
    EntryPoint.getLabels = function getLabels() {
        var labels = {};

        labels[TYPE_MESSENGER_CODE] = 'Messenger Code';
        labels[TYPE_PAYLOAD_ONLY]   = 'Only Payload';

        return labels;
    };

    /**
     * @param {Object} data
     * @return Model.EntryPoint
     */
    EntryPoint.createByData = function createByData(data) {
        var entryPoint = new Model.EntryPoint(data.uuid, data.payload);
        entryPoint.updateByData(data);

        return entryPoint;
    };

    ns.EntryPoint = EntryPoint;

    var endpointNS     = Object.namespace('Model.EntryPoint.APIEndpoint');
    endpointNS.generic = false;

})(Object.namespace('Model'));
