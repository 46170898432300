(function (ns) {
    /**
     * @namespace
     * @alias sAutomation.Route.Edit
     * @extends sRoute
     * @constructor
     */
    var Edit = function Edit() {
        this.controller     = sAutomation.Controller.CreateAndEdit;
        this.controllerAs   = '$ctrl';
        this.templateUrl    = 'sautomation:create-and-edit';
        this.reloadOnSearch = false;
        this.accessControl  = {'automation.RESTAccess': Const.Method.PUT};

        Edit._pProto.constructor.call(this);
    };

    Object.extendProto(Edit, sRoute);

    /**
     * @name sAutomation.Route.Create#resolveAutomation
     * @param $route
     * @param {sSegment.Service.sConditionDefinition} sSegmentConditionDefinition
     * @returns {$.Deferred}
     */
    Edit.prototype.resolveAutomation = function resolveAutomation($route, sSegmentConditionDefinition) {
        return sSegmentConditionDefinition.init().then(function () {
            return Model.RESTAccessByUUID.load.call(
                Model.Automation,
                Model.Automation.prototype.endPoint,
                $route.current.params.uuid
            );
        });
    };

    ns.Edit = Edit;
})(Object.namespace('sAutomation.Route'));