(function (ns) {
    var EVENT_SOURCE_CHANGED             = 'sProductCatalogChangedEvent',
        EVENT_GET_CONTEXT_ON_CATALOG_ID  = 'sGetContextOnCatalogIdEvent',
        PRODUCT_CATALOG_SOURCE_TYPE_NAME = 'Facebook Product Catalog'
    ;

    /**
     * @namespace
     * @alias Controller.Component.sProductCatalogEntrySelect
     *
     * @param scope
     * @param {sSource.Service.sSourceService} sSource
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @constructor
     */
    var sProductCatalogEntrySelect = function ($scope, sSource, sAPIAccess) {
        this.sSourceService = sSource;
        this.sAPIAccess     = sAPIAccess;
        this.$scope         = $scope;

        var lastVal = null,
            catalog
        ;

        Object.defineProperty(
            this,
            'catalog',
            {
                get: function () {
                    return catalog;
                }
            }
            );

        this.verifyId = function (item) {
            catalog = item;
            digestIfNeeded($scope);
        };

        Model.Behavior.ExposePlaceholder.call(this,  function (placeholder) {
            return placeholder.source && placeholder.source.type.name === PRODUCT_CATALOG_SOURCE_TYPE_NAME;
        }.bind(this));

        this.$deRegister = [];


        this.productCatalogSourceTypeId = this.getContextSpecificPlaceholders().slice(0,1).pop().source.type.id;

        Object.defineProperties(
            this,
            {
                sourceChangedEvent: {
                    value: EVENT_SOURCE_CHANGED
                    /**
                     * @name Controller.Component.sProductCatalogEntrySelect#sourceChangedEvent
                     * @type {String}
                     */
                }
            });

        /**
         * @return {$.Deferred<{value: string, mpn: string, id: string, isDisable: Boolean}[]>}
         */
        this.getProductCatalogValueChoices = function getProductCatalogValueChoices() {
            if (!this.catalog) {
                return $.Deferred().reject();
            }

            var self = this;

            return $.get(this.sAPIAccess.endpoint('source.column.values').get(JSON.stringify(['title', 'id', 'mpn'])), {sourceId: self.catalog.uuid}).then(function(data) {
                return data.map(function (row) {
                    lastVal = self.catalog.uuid;
                    return JSON.parse(row.value);
                })
            }).then(function (data) {
                self.getContextSpecificPlaceholders().map(function (placeholder) {
                    if (placeholder.token !== '@catalog_id') {
                        return;
                    }

                    // the chosen catalog has to be of same type as the placeholder's source
                    if (self.catalog.type.id !== placeholder.source.type.id) {
                        return;
                    }

                    var payload = {catalogId: self.catalog.uuid, matching: false};
                    $scope.$emit(EVENT_GET_CONTEXT_ON_CATALOG_ID, payload);

                    // Product id should be disabled unless the message part's parent has an relation with an action specifying the same source
                    data.unshift({value: '@' + placeholder.fullyQualifiedName, mpn: placeholder.description, id: placeholder.token, isDisabled: !payload.matching});
                })
                return data;
            })
        };

        this.$deRegister.push($scope.$on(Controller.Component.sSelect.EVENT_VALUE_NOT_AVAILABLE, function (event, data) {
            if (this.model.catalogId === data) {
                this.model.catalogId = null;
                this.model.productId = null;
            }
        }.bind(this)));

        this.$onInit = function $onInit() {
            var self = this;
            this.$deRegister.push($scope.$watch(
                function () {
                    return self.catalog;
                },
                function (val) {
                    if (val && val.uuid !== lastVal) {
                        self.$scope.$broadcast(EVENT_SOURCE_CHANGED);
                    }
                }
            ))
        };


        /**
         * @property
         * @name Controller.Component.sProductCatalogEntrySelect#catalogId
         * @type {Number}
         */

        /**
         * @property
         * @name Controller.Component.sProductCatalogEntrySelect#catalogSelector
         * @type {Boolean}
         */
    };

    /**
     * @param {RegExp} regexp
     * @param {{value: String, mpn: String}} item
     * @return {Boolean}
     */
    sProductCatalogEntrySelect.prototype.filterItems = function filterItems(regexp, item) {
        return Boolean(regexp.exec(item.value) || regexp.exec(item.mpn));
    }

    sProductCatalogEntrySelect.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    }

    Object.defineProperties(
        sProductCatalogEntrySelect,
        {
            EVENT_GET_CONTEXT_ON_CATALOG_ID: {
                value: EVENT_GET_CONTEXT_ON_CATALOG_ID
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sProductCatalogEntrySelect#EVENT_GET_CONTEXT_ON_CATALOG_ID
                 * @type {String}
                 */
            }
        });

    ns.sProductCatalogEntrySelect = sProductCatalogEntrySelect;
})(Object.namespace('Controller.Component'));
