(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sAction
     * @constructor
     *
     * @param $scope
     * @param {Service.sAction} sActionService
     */
    var sAction = function ($scope, sActionService) {
        this.refreshEventName           = 's-action_refreshChoices';
        this.$scope                     = $scope;
        this.sActionService             = sActionService;
        this.$deRegister                = [];
        this.actionContentType          = false;
        this.relatedActionDefinition    = null;

        // used by sortActions
        this.categoriesOrder = [
            'interactions',
            'collect user data',
            'receive & send data',
            'facebook messenger',
            'manage the bot',
            'custom integration'
        ];

        this.sortActions = sAction.prototype.sortActions.bind(this);

        /**
         * @property
         * @name Controller.Component.sAction#model
         * @type Model.Action
         */

        /**
         * @property
         * @type Controller.Component.sActionCollection
         * @name Controller.Component.sAction#sActionCollectionCtrl
         */

        /**
         * @property
         * @name Controller.Component.sAction#actionContentType
         * @type {*}
         */

        /**
         * @property
         * @name Controller.Component.sAction#relatedActionDefinition
         * @type {?Model.Action.Definition}
         */
    };

    /**
     * @function
     * @name Controller.Component.sAction#$onInit
     */
    sAction.prototype.$onInit = function $onInit() {
        var self = this;

        this.$deRegister.push(this.$scope.$watchCollection(
            function () {
                return {
                    model   : self.model,
                    type    : self.model ? self.model.type : undefined
                }
            },
            function (newVal, oldVal) {
                if (newVal.type !== oldVal.type) {
                    if (newVal.model === oldVal.model) {
                        // if type changed but action-object not -> clear value
                        self.model.value = null;
                    }
                    self.$scope.$broadcast(self.refreshEventName);
                    self.updateRelatedActionDefinition();
                }
            }
        ));

        this.$deRegister.push(this.$scope.$watchCollection(
            function () {
                return self.sActionRepositoryCtrl.repository.actions;
            },
            function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    self.$scope.$broadcast(self.refreshEventName);
                }
            }
        ));

        // initial set
        this.updateRelatedActionDefinition();
    };

    /**
     * @function
     * @name Controller.Component.sAction#$onDestroy
     */
    sAction.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name Controller.Component.sAction#updateRelatedActionDefinition
     * @return {Promise}
     */
    sAction.prototype.updateRelatedActionDefinition = function updateRelatedActionDefinition() {
        var self = this;

        return this.sActionService.getDefinitions().then(function(defCollection) {
            if (!self.model || !self.model.type) {
                return;
            }

            var actionDefinition = defCollection.findByName(self.model.type);

            if (!actionDefinition) {
                return;
            }
            self.relatedActionDefinition = actionDefinition;
            digestIfNeeded(self.$scope);
        });
    };

    /**
     * @function
     * @name Controller.Component.sAction#getFilteredActionDefinitions
     * @returns Promise
     */
    sAction.prototype.getFilteredActionDefinitions = function getFilteredActionDefinitions()
    {
        var self = this;

        return this.sActionService.getDefinitions().then(function(defCollection) {
            var filteredDefinitions = defCollection.definitions.filter(function (definition) {
                if (self.sActionRepositoryCtrl.repository.canActionHaveType(self.model, definition.name)) {
                    return true;
                }

                if (definition.name === self.model.type) {
                    self.model.type = null;
                }
                return false;
            });

            if (self.model.type === null && filteredDefinitions.length) {
                self.model.type = filteredDefinitions.slice(0, 1).pop().name;
            }

            return filteredDefinitions;
        });
    };

    /**
     * @name Controller.Component.sAction#handleChange
     * @param item
     */
    sAction.prototype.handleChange = function(item) {
        if (this.model.key !== item.key) {
            this.model.key = item.key;
        }
        if (this.model.type !== item.name) {
            this.model.value = null;
        }
    };

    /**
     * @param {{category: String, label: String}} actionA
     * @param {{category: String, label: String}} actionB
     * @returns {Number}
     */
    sAction.prototype.sortActions = function sortActions(actionA, actionB) {
        return sSegment.Component.Controller.sSegmentConditionCollection.prototype.sortDefinitions.apply(this, arguments);
    };

    /**
     * @param {Model.Action.Definition} item
     * @return {boolean}
     */
    sAction.prototype.isItemDisabled = function isItemDisabled(item) {
        if (item.name === Model.Action.TYPE_SET_USERS_LABEL) {
            item.disabledHint = Controller.Component.sMemberAttributeSelect.HINT_USER_LABEL_DEPRECATED;
            return true;
        }

        return false;
    };

    /**
     * @function
     * @name Controller.Component.sAction#getCategoriesOrder
     * @return {String[]}
     */
    sAction.prototype.getCategoriesOrder = function getCategoriesOrder() {
        return this.categoriesOrder;
    };

    ns.sAction = sAction;
})(Object.namespace('Controller.Component'));
