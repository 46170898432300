(function (ns) {
    /**
     * @namespace
     * @alias Controller.Component.lazyMdSelectController
     */
    var lazyMdSelectController = function ($scope) {
        this.$scope         = $scope;
        this.choices        = [];
        this.$deRegister    = [];


        /**
         * @name Controller.Component.lazyMdSelectController#refreshEventName
         */

        /**
         * @name Controller.Component.lazyMdSelectController#isRequired
         */

        /**
         * @name Controller.Component.lazyMdSelectController#sortBy
         */
    };

    /**
     * @function
     * @name Controller.Component.lazyMdSelectController#$onInit
     */
    lazyMdSelectController.prototype.$onInit = function $onInit() {
        var self = this;

        this.placeholder    = (this.placeholder || '');
        this.label          = (this.label || this.placeholder);

        this.$deRegister.push(this.$scope.$watch(
            function () {
                return self.choices;
            },
            function (newVal, oldVal) {
                // sort the choices
                if (!newVal.equals(oldVal)) {
                    self.sortChoices();
                    digestIfNeeded(self.$scope);
                }
            }
        ));

        if (this.refreshEventName) {
            this.$deRegister.push(this.$scope.$on(
                this.refreshEventName,
                function() {
                    self.refreshChoices();
                }
            ));
        }

        this.$deRegister.push(this.$scope.$watch(
            function() {
                return self.model;
            },
            function () {
                self.refreshChoices();
            }
        ));
    };

    /**
     * @function
     * @name Controller.Component.lazyMdSelectController#$onDestroy
     */
    lazyMdSelectController.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name Controller.Component.lazyMdSelectController#sortChoices
     */
    lazyMdSelectController.prototype.sortChoices = function sortChoices() {
        if (typeof this.sortBy === 'string' || this.sortBy instanceof String) {
            this.choices.sort(Array.sortFnByProperty(this.sortBy));
        } else if (this.sortBy instanceof Function) {
            this.choices.sort(this.sortBy);
        } else {
            this.choices.sort();
        }
    };

    /**
     * @function
     * @name Controller.Component.lazyMdSelectController#refreshChoices
     * @returns {Promise}
     */
    lazyMdSelectController.prototype.refreshChoices = function refreshChoices() {
        var self = this;

        this.choices = [];
        return this.getChoices(true).then(function () {
            for (var i = 0; i < self.choices.length; i++) {
                if (((self.onlyField && self.choices[i][self.onlyField] === self.model)
                        || self.choices[i] === self.model)
                    && (!self.label || self.label !== self.choices[self.description])
                ) {
                    self.handleChange(self.choices[i]);
                    digestIfNeeded(self.$scope);
                    break;
                }
            }
        });
    };

    /**
     * Load the options with support for promises
     */
    lazyMdSelectController.prototype.getChoices = function getChoices(dontDigest) {
        if (this.choices.length) {
            return $.Deferred().resolve(this.choices).promise();
        }
        var ret     = this.onLoadFn(),
            self    = this
        ;

        if (ret.then) {
            return ret.then(function(data) {
                self.choices = data;
            }).always(function() {
                if (!dontDigest) {
                    self.$scope.$digest();
                }
            });
        } else {
            this.choices = ret;
            return $.Deferred().resolve(this.choices).promise();
        }
    };

    lazyMdSelectController.prototype.handleChange = function handleChange(item) {
        this.label = item[this.description];
        this.onHandleChange({'item':item});
    };

    ns.lazyMdSelectController = lazyMdSelectController;
})(Object.namespace('Controller.Component'));
