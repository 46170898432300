(function(ns) {
    /**
     * @namespace
     * @alias Model.Filter.Collection
     *
     * @extends Model.Collection
     * @constructor
     */
    var Collection = function() {
        Collection._pProto.constructor.call(this, Model.Filter, 'filter');

        Object.defineProperties(
            this,
            {
                editableFilters : {
                    get: function() {
                        return this
                            .filters
                            .filter(function(element) {
                                return !element.isHidden;
                            });
                    }
                    /**
                     * @property
                     * @name Model.Filter.Collection#editableFilters
                     * @type {Array}
                     */
                }
            }
        );

        /**
         * @function
         * @name Model.Filter.Collection#getIndex
         * @param {Model.Filter} item
         * @param {Boolean=} strict
         * @return {Number}
         */
        this.getIndex = function getIndex(item, strict) {
            var index;
            if (strict) {
                index = this.filters.map(function(element) {
                    return element.name + '-' + element.value.toString();
                }).indexOf(item.name + '-' + item.value.toString());
            } else {
                index = this.filters.map(function (element) {
                    return element.name;
                }).indexOf(item.name);
            }

            if (index !== -1 && this.filters[index].isHidden) {
                return -1;
            } else {
                return index;
            }
        };

        /**
         * @name Model.Filter.Collection#removeFilter
         * @method
         * @param {Model.Filter} index
         */

        /**
         * @name Model.Filter.Collection#addFilter
         * @method
         * @param {Model.Filter} filter
         */

        /**
         * @name Model.Filter.Collection#filters
         * @property
         * @type {Array}
         */
    };

    Object.extendProto(Collection, Model.Collection);

    /**
     * @name Model.Filter.Collection#clear
     * @method
     */
    Collection.prototype.clear = function clear() {
        this.editableFilters.map(this.removeFilter.bind(this));
    };

    Collection.prototype.valueOf = function valueOf() {
        return this.filters.reduce(
            /**
             * @param {Object} carry
             * @param {Model.Filter} element
             * @return {*}
             */
            function(carry, element) {
                carry[element.name] = element.value.valueOf();
                return carry;
            },
            {}
        );
    };

    /**
     * @name Model.Filter.Collection#removeByName
     * @param name
     */
    Collection.prototype.removeByName = function removeByName(name) {
        this.removeFilter(new Model.Filter(name, 1));
    };

    /**
     * @name Model.Filter.Collection#getByName
     * @param name
     * @return {Model.Filter|null}
     */
    Collection.prototype.getByName = function getByName(name) {
        var index;
        if ((index = this.getIndex(new Model.Filter(name, 1))) === -1) {
            return null;
        }

        return this.filters[index];
    };

    /**
     * @name Model.Filter.Collection#getOrCreateByName
     * @param {String} name
     * @returns {Model.Filter}
     */
    Collection.prototype.getOrCreateByName = function getOrCreateByName(name) {
        var filter = this.getByName(name);

        if (!filter) {
            filter = this.createAndAdd(name);
        }

        return filter;
    };

    ns.Collection = Collection;
})(Object.namespace('Model.Filter'));