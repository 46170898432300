(function (ns) {
    /**
     * @namespace
     * @alias Model.DomainUser
     *
     * @constructor
     * @extends Model.User
     * @param {String} domainId
     * @param {String} uuid
     */
    var DomainUser = function (domainId, uuid) {

        Object.defineProperties(
            this,
            {
                domainRoles: {
                    enumerable: true,
                    get: function() {
                        return this.getRolesByDomainId(domainId);
                    }
                    /**
                     * @property
                     * @name Model.DomainUser#domainRoles
                     * @type {Model.User.Role[]}
                     */
                },
                domainId: {
                    enumerable: true,
                    get: function () {
                        return domainId;
                    }
                    /**
                     * @property
                     * @name Model.DomainUser#domainId
                     * @type {String}
                     */
                }
            });

        DomainUser._pProto.constructor.call(this, uuid);

        var parentGetCloneArguments = this.__getCloneArguments;
        this.__getCloneArguments = function __getCloneArguments() {
            return [this.domainId].concat(parentGetCloneArguments.call(this));
        }
    };

    Object.extendProto(DomainUser, Model.User);

    ns.DomainUser = DomainUser;
})(Object.namespace('Model'));