(function (ns) {
    var EVENT_LOAD = 'model-series-load';

    /**
     * @namespace
     * @alias Model.Series
     *
     * @constructor
     * @param {String} endPoint
     */
    var Series = function (endPoint) {
        var filterBy        = new Model.Filter.Collection(),
            loading         ,
            records         = [],
            recordsCache    = [],
            lastQuery       ,
            mapFn           ,
            self            = this,
            pristine        = true
            ;

        var updateRecordsCache = function(){
            recordsCache.splice.apply(recordsCache, [0, recordsCache.length].concat(records));
        };

        Object.defineProperties(
            this,
            {
                endPoint : {
                    get: function() {
                        return endPoint;
                    }
                    /**
                     * @property
                     * @name Model.Series#endPoint
                     * @type {String}
                     */
                },
                loading : {
                    get : function() {
                        return loading;
                    }
                    /**
                     * @name Model.Series#loading
                     * @property
                     * @type {$.Deferred|Promise|PromiseLike}
                     */
                },
                filters : {
                    get: function() {
                        return filterBy;
                    }
                    /**
                     * @name Model.Series#filters
                     * @property
                     * @type {Model.Filter.Collection}
                     */
                },
                records : {
                    get: function() {
                        return recordsCache;
                    }
                    /**
                     * @name Model.Series#records
                     * @property
                     * @type {Array}
                     */
                },
                mapFn: {
                    get: function() {
                        return mapFn;
                    },
                    set: function(val) {
                        if (val) {
                            Object.instanceOf(val, Function);
                        }

                        mapFn = val;
                    }
                    /**
                     * @name Model.Series#mapFn
                     * @property
                     * @type {?Function}
                     */
                },
                pristine : {
                    get: function() {
                        return pristine
                    }
                    /**
                     * @name Model.Series#pristine
                     * @property
                     * @type {Boolean}
                     */
                }
            }
        );

        /**
         * @name Model.Series#load
         * @param {Boolean=} metaOnly
         * @param {Boolean=} force
         * @method
         * @return {$.Deferred}
         */
        this.load = function load(metaOnly, force) {
            function getQueryParameters () {
                var queryParams = self.getQueryParameters()
                ;

                if (metaOnly) {
                    queryParams.metaOnly = true;
                }
                return queryParams;
            }

            if (loading) {
                return loading;
            }

            var queryParams = getQueryParameters();

            if (!force && lastQuery === JSON.stringify(queryParams)) {
                return $.Deferred().resolve(self);
            }

            loading = Object.getPrototypeOf(self).load.apply(self, [queryParams]);

            $.event.trigger(EVENT_LOAD, [self,queryParams]);

            loading.then(function(_records) {
                    lastQuery = JSON.stringify(queryParams);
                    records.splice(0, records.length);
                    if (!(_records instanceof Array)) {
                        return;
                    }

                    if (self.mapFn && (self.mapFn instanceof Function)) {
                        _records = _records.map(self.mapFn);
                    }

                    records.push.apply(records, _records);

                    return self;
                })
                .always(function() {
                    loading = null;
                    pristine = false;
                    updateRecordsCache();
                });

            return loading;
        };
    };

    /**
     * @returns {Object}
     */
    Series.prototype.getQueryParameters = function getQueryParameters() {
        if (this.filters.length) {
            return { filterBy : this.filters.valueOf()};
        }

        return {};
    };

    /**
     * @ignore
     * @param {Object} queryParams
     * @returns {$.Deferred|Promise}
     */
    Series.prototype.load = function load(queryParams) {
        return $.ajax({
            url         : this.endPoint,
            method      : Const.Method.GET,
            data        : queryParams,
            contentType : false,
            dataType    : 'json'
        });
    };

    Series.EVENT_LOAD = EVENT_LOAD;
    ns.Series = Series;
})(Object.namespace('Model'));
