pica.WEBGL = true;

function digestIfNeeded($scope, delay) {
    if (delay) {
        if ($scope.__TO) {
            clearTimeout($scope.__TO);
        }
        $scope.__TO = setTimeout(digestIfNeeded.bind(this, $scope), delay);
        return;
    }

    if ($scope.$root && $scope.$root === $scope) {
        return $scope.$evalAsync();
    }

    if (!($scope.$root && $scope.$root.$$phase) && !$scope.$$phase) {
        $scope.$digest();
    }
}

/**
 * @typedef {Object} Route
 * @property {Boolean} [caseInsensitiveMatch]
 * @property {Object|String} controller
 * @property {String} [controllerAs]
 * @property {Boolean} [reloadOnSearch]
 * @property {Object} [resolve]
 * @property {String} templateUrl
 */

/**
 * Shared application between sNGApp and sNGExtern, contains the vital modules that both applications need
 * @type {angular.Module}
 */
var sFollowPubNG = angular.module('sFollowPubNG', [
    'ngRoute',
    'ngAria',
    'ngMessages',
    'ngAnimate',
    'ngSanitize',

    'ngMaterial',

    'dndLists',
    'ui-notification',
    'angularMoment',
    'ngSegment',

    'sAppCookies',
    'sAPIAccess',
    'sFollowPubComponents',
    'sFollowPubDirectives',
    'sFollowPubFilters',
    'sHeadlineModule',
    'sSecurityModule',
    'sBaseModule',
    'sBugsnagModule',
    'sDatadogRUMModule',
]);

sFollowPubNG.apiHost = '';
sFollowPubNG.baseUrl = '/app_dev.php';
sFollowPubNG.SegmentApiKey = null;

moment.updateLocale('en', {
    week : {
        dow : 1 // Monday is now set as the first day of the week.
    }
});


sFollowPubNG.value('security_user', null);

sFollowPubNG.value('maxFileSize', 1024*1024*20);

sFollowPubNG.value('AWSURLRegEx', /^.*sfollowcontent[a-z]*\.[a-z\d]+\.amazonaws.*$/);

sFollowPubNG.constant('DEBUG', false);

sFollowPubNG.value('configJSON', {
    'general': {
        'isSameDomain': true
    }
});


// Fixes mfVirtualRepeat attempting to create cache for more then 50 blocks at the time
sFollowPubNG.config(function($provide) {
    $provide.decorator('mdVirtualRepeatDirective', function ($delegate) {
        var originalFunction = $delegate[0].controller.prototype.updateIndexes_,
            MAX_BLOCK_COUNT = 50
        ;

        $delegate[0].controller.prototype.updateIndexes_ = function () {
            originalFunction.apply(this, arguments);
            if (this.newEndIndex - this.newStartIndex > MAX_BLOCK_COUNT) {
                this.newEndIndex = this.newStartIndex + MAX_BLOCK_COUNT;
            }
        }
        return $delegate;
    })
});

sFollowPubNG.constant('apiEndpoints', {
    baseUrl: function() {
        return sFollowPubNG.baseUrl;
    },
    action: {
        byDomain: function(domainId) {
            return {
                all: function() {
                    return sFollowPubNG.baseUrl + "/domain/" + domainId + "/action/definition/";
                }
            }
        }
    },
    ai: {
        similarWords: function() {
            return sFollowPubNG.baseUrl + "/ai/similar-words";
        }
    },
    analytics: {
        byDomain: function(domainId) {
            return {
                chartData: function(chartIdent) {
                    return sFollowPubNG.baseUrl + "/domain/" + domainId + "/analytics/" + chartIdent;
                },
                chartDataAggregated: function(chartIdent) {
                    return sFollowPubNG.baseUrl + "/domain/" + domainId + "/analytics/" + chartIdent + "/aggregated";
                }
            }
        }
    },
    channel: {
        byDomain: function(domainId) {
            return {
                all: function() {
                    return sFollowPubNG.baseUrl + "/domain/" + domainId + "/channel/";
                },
                selectable: function() {
                    return sFollowPubNG.baseUrl + "/domain/" + domainId + "/channel/selectable";
                }
            }
        },
        generic: function () {
            return sFollowPubNG.baseUrl + '/channel/';
        },
        activate: function(id) {
            return sFollowPubNG.baseUrl + "/channel/" + id + "/activate";
        },
        deactivate: function(id) {
            return sFollowPubNG.baseUrl + "/channel/" + id + "/deactivate";
        }
    },
    contentFeed: {
        byDomain: function(domainId) {
            return {
                all: function() {
                    return sFollowPubNG.baseUrl + "/domain/" + domainId + "/content-feed/";
                },
                toConversation: function () {
                    return sFollowPubNG.baseUrl + "/domain/" + domainId + "/content-feed/" + "to-conversation/";
                }
            }
        },
        setProcessed: function(uuid) {
            return sFollowPubNG.baseUrl + "/content-feed/" + uuid + "/set-processed";
        }
    },
    content: {
        parse:  function() {
            return sFollowPubNG.baseUrl + '/message/content/parse';
        }
    },
    defaultReactions: {
        byDomain: function(domainId) {
            return sFollowPubNG.baseUrl + '/reaction/by-domain/' + domainId + '/bot-defaults';
        }
    },
    domain: {
        RESTAccess: function() {
            return sFollowPubNG.baseUrl + '/domain/';
        },
        getAll: function () {
            return sFollowPubNG.baseUrl + "/domain/get-all";
        },
        domainMeta: function (domain) {
            return sFollowPubNG.baseUrl + '/domain/' + domain + '/meta'
        },
        feature: {
            list: function (domainId) {
                return sFollowPubNG.baseUrl + '/domain/' + domainId + '/feature';
            },
            relate: function (domainId, featureUuid) {
                return sFollowPubNG.baseUrl + '/domain/' + domainId + '/feature/' + featureUuid;
            }
        },
        plan: {
            relate: function(domainId, planUuid) {
                return sFollowPubNG.baseUrl + '/domain/' + domainId + '/plan/' + planUuid;
            }
        }
    },
    entryPoint: {
        generic: function () {
            return sFollowPubNG.baseUrl + '/entry-point/';
        }
    },
    feature: {
        RESTAccess: function() {
            return sFollowPubNG.baseUrl + '/feature/';
        }
    },
    group: {
        byDomain: function(domainId) {
            return sFollowPubNG.baseUrl + '/groups/by-domain/' + domainId;
        }
    },
    images: function () {
        return sFollowPubNG.apiHost + '/img/';
    },
    member: {
        byUserId        : function() { return sFollowPubNG.baseUrl + '/member/' },
        botDisableUntil : function(memberId) { return sFollowPubNG.baseUrl + '/member/' + memberId + '/bot/disable-until' },
        blockStatus     : function(memberId) { return sFollowPubNG.baseUrl + '/member/' + memberId + '/block-status' },
        delete          : function(memberId) { return sFollowPubNG.baseUrl + '/member/' + memberId }
    },
    messageAnchor: {
        byDomain: function(domainId) {
            return {
                all: function() {
                    return sFollowPubNG.baseUrl + "/domain/" + domainId + "/message/anchor/";
                }
            }
        },
        duplicate: function (anchorUuid) {
            return sFollowPubNG.baseUrl + "/message/anchor/" + anchorUuid + "/duplicate";
        },
        duplicateMultiple: function() {
            return sFollowPubNG.baseUrl + "/message/anchor/duplicate";
        },
        updateArchivedState: function(anchorUuid, toArchived) {
            return sFollowPubNG.baseUrl + "/message/anchor/" + anchorUuid + "/archive/" + (toArchived ? '1' : '0');
        },
        byMessage: function(uuid) {
            return sFollowPubNG.baseUrl + "/message/anchor/by-message/" + uuid;
        },
        usages: function(anchorUuid) {
            return sFollowPubNG.baseUrl + "/message/anchor/" + anchorUuid + "/usages";
        },
        send: function(anchorUuid) {
            return sFollowPubNG.baseUrl + '/message/anchor/' + (anchorUuid ? anchorUuid + '/' : '') + 'send';
        },
        publish: function(anchorUuid) {
            return sFollowPubNG.baseUrl + '/message/anchor/' + anchorUuid + '/publish';
        },
        analytics: function(anchorUuid) {
            return sFollowPubNG.baseUrl + '/message/anchor/' + anchorUuid + '/analytics';
        }
    },
    messageIncoming: {
        byDomain: function (hDomainId) {
            return sFollowPubNG.baseUrl + '/domain/' + hDomainId + '/message/incoming/';
        },
        RESTAccess: function() {
            // noinspection JSConstructorReturnsPrimitive
            return sFollowPubNG.baseUrl + '/message/incoming/';
        }
    },
    permission: {
        RESTAccess: function() {
            return sFollowPubNG.baseUrl + '/security/permission/';
        }
    },
    plan: {
        RESTAccess: function() {
            return sFollowPubNG.baseUrl + '/plan/';
        },
        featureToggle: function(planUuid, featureUuid) {
            return sFollowPubNG.baseUrl + '/plan/' + planUuid + '/feature/' + featureUuid;
        }
    },
    reaction: {
        generic: function () {
            return sFollowPubNG.baseUrl + '/reaction/';
        }
    },
    role: {
        permissionToggle: function(roleUuid, permissionUuid) {
            return sFollowPubNG.baseUrl + '/security/role/' + roleUuid + '/permission/' + permissionUuid;
        },
        RESTAccess: function() {
            return sFollowPubNG.baseUrl + '/security/role/';
        }
    },
    scheduled: {
        bySegment: function (hChannelId) {
            return sFollowPubNG.baseUrl + "/message/schedule/channel/" + hChannelId;
        },
        abort: function(hMessageId) {
            return sFollowPubNG.baseUrl + "/message/schedule/" + hMessageId;
        }
    },
    security: {
        authUser: function () {
            return sFollowPubNG.baseUrl + '/security/auth-user';
        },
        authenticate: function() {
            return sFollowPubNG.baseUrl + '/auth/';
        },
        logout: function() {
            return sFollowPubNG.baseUrl + '/auth/logout';
        },
        forgotPassword: function() {
            return sFollowPubNG.baseUrl + '/auth/forgot-password';
        },
        resetPassword: function() {
            return sFollowPubNG.baseUrl + '/auth/reset-password';
        }
    },
    tagging: {
        byDomain: function(domainId) {
            return {
                autocomplete: {
                    anchor: function() {
                        return sFollowPubNG.baseUrl + "/domain/" + domainId + "/tagging/autocomplete/anchor/";
                    }
                }
            }
        }
    },
    user: {
        generic: function () {
            return sFollowPubNG.baseUrl + "/user/";
        },
        byDomain: function (domainId) {
            return {
                list: function () {
                    return sFollowPubNG.baseUrl + "/domain/" + domainId + "/user/";
                },
                userRole: function (userUuid, roleUuid) {
                    return sFollowPubNG.baseUrl + "/domain/" + domainId + "/user/" + userUuid + "/role/" + roleUuid;
                }
            }
        }
    },
    validation: {
        url: function() { return sFollowPubNG.baseUrl + '/validate/url'; },
        password: function() { return sFollowPubNG.baseUrl + '/validate/password'; }
    }
});

//sFollowPubNG.constant("$MD_THEME_CSS","");

sFollowPubNG
    .service('sProgressIndicator', Service.sProgressIndicator)
    .service('sAPIValidation', Service.sAPIValidation)
    .service('ReCAPTCHA', Service.ReCAPTCHA)
    ;

sFollowPubNG.value('emojiEndPoint', {
    catalog : function() { return sFollowPubNG.baseUrl + '/emoji/json' },
    svg     : function() { return sFollowPubNG.baseUrl + '/emoji/svg' }
});

// Bugsnag API_KEY
sFollowPubNG.config(['sBugsnagProvider', function(sBugsnagProvider) {
    sBugsnagProvider.apiKey             = sFollowPubNG.BugsnagApiKey;
    sBugsnagProvider.releaseStage       = sFollowPubNG.BugsnagReleaseStage;
    // filter out requests made to 3rd party services
    sBugsnagProvider.allowBreadcrumb    = function (name, metaData, type) {
        return type !== 'request' || !metaData.request || metaData.request.search(' ' + sFollowPubNG.apiHost) !== -1;
    }
}]);

// static endpoints
sFollowPubNG.config(['apiEndpoints', function (apiEndpoints) {
    Model.AI.Reaction.restEndPoint = apiEndpoints.reaction.generic();

    Model.Message.Anchor.apiEndPoint_updateActiveState = apiEndpoints.messageAnchor.updateArchivedState;

    Model.ContentFeed.Entry.apiEndPoint_setProcessed = apiEndpoints.contentFeed.setProcessed;

    Model.Domain.prototype.endPoint                     = apiEndpoints.domain.RESTAccess();
    Model.Domain.prototype.featureRelateEndpoint        = apiEndpoints.domain.feature.relate;
    Model.Domain.prototype.planRelateEndpoint           = apiEndpoints.domain.plan.relate;
    Model.ApiMessage.endPoint                           = apiEndpoints.messageIncoming.RESTAccess();
    Model.Feature.prototype.endPoint                    = apiEndpoints.feature.RESTAccess();
    Model.Plan.prototype.endPoint                       = apiEndpoints.plan.RESTAccess();
    Model.Plan.prototype.featureToggleEndPoint          = apiEndpoints.plan.featureToggle;
    Model.User.Permission.prototype.endPoint            = apiEndpoints.permission.RESTAccess();
    Model.User.prototype.endPoint                       = apiEndpoints.user.generic();
    Model.User.Role.prototype.endPoint                  = apiEndpoints.role.RESTAccess();
    Model.User.Role.prototype.permissionToggleEndPoint  = apiEndpoints.role.permissionToggle;
    Model.EntryPoint.APIEndpoint.generic                = apiEndpoints.entryPoint.generic;

    // TODO: rewrite to use apiEndpoints // DO NOT ADD NEW STUFF UNDER THIS LINE!
    Model.RESTAccessByUUID.endpoint_batch = function (transactional) {
        var transactionalString = (transactional) ? "transactional" : "";
        return sFollowPubNG.baseUrl + "/batch/" + transactionalString;
    };
    Model.Message.endPoint = sFollowPubNG.baseUrl + "/message/";
    Model.Message.endPoint_complete = function (uuid) {
        return sFollowPubNG.baseUrl + "/message/" + uuid + "/complete";
    };
    Model.Message.Relation.endPoint = sFollowPubNG.baseUrl + "/message/relation/";
    Model.Message.Anchor.endPoint_getPublishedConversations = function (domain) {
        return sFollowPubNG.baseUrl + "/message/anchor/published-conversations/domain/" + domain;
    };
    Model.Message.endPoint_relations = function (uuid) {
        return sFollowPubNG.baseUrl + "/message/" + uuid + "/relations"
    };
    Model.Message.endPoint_publish = function () {
        return sFollowPubNG.baseUrl + "/message/publish";
    };
    Model.Message.endPoint_send = function () {
        return sFollowPubNG.baseUrl + "/message/send";
    };

    Model.Subscription.endPoint = sFollowPubNG.baseUrl + "/subscription/";
    Model.Member.CustomData.endPoint = function (memberId) {
        return sFollowPubNG.baseUrl + '/member/' + memberId + '/custom-data'
    };
}]);

// @see https://code.angularjs.org/1.7.8/docs/guide/migration#-location-
sFollowPubNG.config(['$locationProvider', function($locationProvider) {
    $locationProvider.hashPrefix('');
}]);

sFollowPubNG.config([
    '$mdAriaProvider',
    '$rootScopeProvider',
    '$compileProvider',
    'configServiceProvider',
    function ($mdAriaProvider,
              $rootScopeProvider,
              $compileProvider,
              configServiceProvider)
{
    var config = configServiceProvider.$get().getConfig();

    $mdAriaProvider.disableWarnings();
    $rootScopeProvider.digestTtl(10);
    $compileProvider.debugInfoEnabled(false);
    $compileProvider.commentDirectivesEnabled(false);
    $compileProvider.cssClassDirectivesEnabled(false);

    var ajaxSetupProperties = {};

    if (!config.general.isSameDomain) {
        $.extend(ajaxSetupProperties, {
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            }
        });
    }
    $.ajaxSetup(ajaxSetupProperties);

    // Make sure we can access the original request URL from any jqXHR objects
    $.ajaxPrefilter(function(options, originalOptions, jqXHR) {
        jqXHR.originalRequestOptions = originalOptions;
    });

    // exchange method for PHP-backend
    $(document).ajaxSend(function(e, xhr, options) {
        // only do transformation for !GET & !POST & !DELETE & !OPTIONS -methods
        options.method = (options.method || options.type);
        if (typeof options.method === 'string') {
            options.method = options.method.toUpperCase();
        }
        if ([Const.Method.GET, Const.Method.POST, Const.Method.DELETE, Const.Method.OPTIONS].indexOf(options.method) === -1 && !options.methodOverriden) {

            // if data is undefined make it formdata
            if (typeof options.data === 'undefined') {
                options.data = new FormData();
            }

            // if data is formdata add the magic-method
            if (options.data instanceof FormData) {
                options.processData = false;
                options.contentType = false;
                options.data.append('_method', options.method);
            } else if (typeof options.data === 'string') {
                if (options.data.length) {
                    options.data += '&';
                }
                options.data += '_method=' + options.method;
            }

            // what about other data-types? - add here later

            options.method = Const.Method.POST;
            options.methodOverriden = true;
            options.type = options.method;
        }
    });
}]);

sFollowPubNG.config(function (segmentProvider, DEBUG) {
    segmentProvider
        .setAutoload(false);

    if (!!sFollowPubNG.SegmentApiKey) {
        segmentProvider
            .setKey(sFollowPubNG.SegmentApiKey)
            .setAutoload(true);

        if (DEBUG) {
            segmentProvider.setDebug(true);
        }
    }
});

sFollowPubNG.config(function(NotificationProvider) {
    NotificationProvider.setOptions({
        container: '.notifications-anchor',
        templateUrl: '_notification'
    });
})
.config(function($provide) {
    $provide.decorator('Notification', [
        '$delegate',
        '$rootScope',
        '$location',
        '$filter',
        '$sanitize',
        Decorator.Notification
    ]);
});

sFollowPubNG.config(function($mdDateLocaleProvider) {
    $mdDateLocaleProvider.formatDate = function(date) {
        return moment(date).format('YYYY-MM-DD');
    };

    $mdDateLocaleProvider.parseDate = function(dateString) {
        var m = moment(dateString, 'YYYY-MM-DD', true);
        return m.isValid() ? m.toDate() : new Date(NaN);
    };
});

sFollowPubNG.factory('configService', ['configJSON', function(configJSON) {
    var config = new Model.Configuration(configJSON);

    return {
        getConfig: function() {
            return config;
        }
    };
}]);

// disabling ripple globally
sFollowPubNG.config(function ($mdInkRippleProvider) {
    $mdInkRippleProvider.disableInkRipple();
});

// extend $mdSelect to rip it off the document and place on a dedicated anchor
sFollowPubNG.config([ '$provide', function($provide) {
    $provide.decorator('$mdSelect', [
        '$delegate',
        function $mdSelect($delegate) {
            var show = $delegate.show;
            $delegate.show = function(config) {
                config.parent = '#md-select-anchor';
                return show.call($delegate, config);
            };
            return $delegate;
        }
    ]);
}]);

// Register s-dialog theme to prevent "attempted to use unregistered theme" warnings
sFollowPubNG.config(function ($mdThemingProvider) {
    $mdThemingProvider.theme('s-dialog');
});

sFollowPubNG.run([
    '$rootScope',
    '$location',
    '$window',
    'segment',
    'sProgressIndicator',
    function ($rootScope, $location, $window, segment, sProgressIndicator) {
        $($window).on('dragover', function(evt) {
            evt.originalEvent.dataTransfer.dropEffect = 'none';
            evt.preventDefault();
        });

        $($window).on('drop', function(evt) {
            evt.preventDefault();
        });

        sProgressIndicator.attachToRootScope();

        $rootScope.$on("$routeChangeSuccess", function (event, current, previous) {
            var path = $window.location.pathname + '#' + $location.path(),
                querystring = '',
                referrer = '',
                /* build path for referrer */
                getPath = function getPath(route, pathParams) {
                    return route.originalPath.replace(route.regexp, function () {
                        var replacedString = arguments[0],
                            parameter;

                        if (arguments.length > 3) {
                            for (var i = 1; i < (arguments.length - 2); i++) {
                                parameter = arguments[i].replace(/^:?/, '');
                                if (pathParams.hasOwnProperty(parameter)) {
                                    replacedString = replacedString.replace(arguments[i], pathParams[parameter]);
                                }
                            }
                        }
                        return $window.location.pathname + '#' + replacedString;
                    });
                };

            // check for querystring
            if (path.indexOf('?') !== -1) {
                querystring = path.substring(path.indexOf('?'), path.length);
            }

            // check for referrer
            if (previous && previous.$$route) {
                referrer = getPath(previous.$$route, previous.pathParams);
            }

            $location.search('changingDomains', null);

            segment.page({
                path: path,
                referrer: referrer,
                search: querystring,
                url: $location.absUrl(),
                app: Service.sAnalyticsTracker.APP_NAME
            });
        });
}]);

sFollowPubNG.run([
    'sAPIAccess',
    '$rootScope',
    function(sAPIAccessService, $rootScope) {
        $rootScope.$on(sAPIAccess.Service.sAPIAccess.EVENT_ENDPOINTS_LOADED, function() {
            Model.Domain.APIEndpoint.domainData.endpoint = sAPIAccessService.endpoint('domain.domainData.RESTAccess');
            Model.Segment.prototype.endPoint = sAPIAccessService.endpoint('segment.RESTAccess').path(Const.Method.POST);
            Model.Segment.APIEndpoint.activate = sAPIAccessService.endpoint('segment.activate').put.bind(sAPIAccessService.endpoint('segment.activate'));
            Model.Segment.APIEndpoint.deactivate = sAPIAccessService.endpoint('segment.deactivate').put.bind(sAPIAccessService.endpoint('segment.deactivate'));

            Model.Automation.prototype.endPoint =
                sAPIAccessService.endpoint('automation.RESTAccess').path(Const.Method.POST) ||
                sAPIAccessService.endpoint('automation.RESTAccess').path(Const.Method.GET);

            Model.Automation.APIEndpoint.activate = sAPIAccessService.endpoint('automation.activate').put.bind(sAPIAccessService.endpoint('automation.activate'));
            Model.Automation.APIEndpoint.deactivate = sAPIAccessService.endpoint('automation.deactivate').put.bind(sAPIAccessService.endpoint('automation.deactivate'));
            Model.Automation.APIEndpoint.fetchAnalyticsData = sAPIAccessService.endpoint('automation.analytics').get.bind(sAPIAccessService.endpoint('automation.analytics'));
            Model.Sender.FacebookPage.prototype.endPoint = sAPIAccessService.endpoint('facebook.page.RESTAccess').path(Const.Method.POST) || '/';
            Model.Sender.FacebookAdAccount.prototype.endPoint = sAPIAccessService.endpoint('facebook.adAccount.RESTAccess').path(Const.Method.POST) || '/';
            Model.Sender.InstagramDirectAccount.prototype.endPoint = sAPIAccessService.endpoint('sender.instagramDirect.all').path(Const.Method.GET) || '/';
            Model.Source.prototype.endPoint = sAPIAccessService.endpoint('source.RESTAccess').path(Const.Method.GET);
            Model.Marker.prototype.endPoint = sAPIAccessService.endpoint('marker.RESTAccess').path(Const.Method.GET);
            Model.AI.Entity.prototype.endPoint = sAPIAccessService.endpoint('entity.RESTAccess').path(Const.Method.GET);
            Model.NotificationLabel.prototype.endPoint = sAPIAccessService.endpoint('action.notificationLabels').path(Const.Method.POST);
        });
        sAPIAccessService.init();
    }
]);

/**
 * Handle route-change-errors
 */
sFollowPubNG.run([
    '$rootScope',
    '$mdDialog',
    '$location',
    function ($rootScope, $mdDialog, $location) {
        $rootScope.$on("$routeChangeError", function (event, current, previous, rejection) {

            // On route error while changing domains always go to home page
            if($location.search().changingDomains) {
                $location.path('/');
                return;
            }

            var defaultMessage = 'There is a problem with loading the requested page. If the problem persists please contact the support.',
                popupFn = function popupFn(msg) {
                    $mdDialog.show({
                        controller          : sBase.Controller.RouteErrorDialog,
                        controllerAs        : '$ctrl',
                        templateUrl         : 'sbase:route-error-dialog',
                        parent              : angular.element(Const.PanelAnchor),
                        clickOutsideToClose : true,
                        locals: {
                            errorMessage: msg
                        }
                    });
                };

            // every default rejection parameter
            if (!(rejection instanceof sRouteRejection)) {
                popupFn(typeof rejection === 'string' ? rejection : defaultMessage);
                return;
            }

            // every rejection reason which is not an error
            if (!(rejection.reason instanceof Error)) {
                popupFn(typeof rejection.reason === 'string' ? rejection.reason : defaultMessage);
                return;
            }

            // display more info for invalid-action-error
            if (rejection.reason instanceof Model.Exception.InvalidActionError) {
                popupFn("You do not have the permission to use a Conversation with the action `"+ rejection.reason.action.type +"`");
                return;
            }

            // do generic popup
            popupFn(defaultMessage);
        });
    }
]);

sFollowPubNG.run(function() {
    $(document).on(sBugsnag.Provider.sBugsnag.EVENT_BUGSNAG_BEFORE_SEND, function (evt, report) {
        report.context = window.location.pathname + window.location.hash;

        // only add to the report the breadcrumbs since the last hash change event
        var latestHashChangeIndex = report.breadcrumbs.reduce(function(carry, element, index) {
            if (element.name === 'Hash changed') {
                carry = Math.max(carry, index);
            }
            return carry;
        }, -1);

        report.breadcrumbs = report.breadcrumbs.filter(function(element, index) {
            return index >= latestHashChangeIndex;
        });
    });
});
