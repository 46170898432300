(function(angular) {

    var module = angular.module('sAnalyticsModule');

    module
        /**
         * @ngdoc component
         * @name sPlot
         * @restrict E
         *
         * @param {Model.Analytics.StatisticsBag}    model
         * @param {String} template
         * @param {String} traceFactory
         * @param {String} variant
         */
        .component('sPlot', {
            controller: sAnalytics.Component.Controller.sPlot,
            bindings : {
                model           : '<',
                template        : '<',
                traceFactory    : '<',
                variant         : '<'
            }
        });
})(angular);
