(function(ns) {
    /**
     * @namespace
     * @alias sAutomation.Controller.Overview
     * @constructor
     *
     * @param {*} $scope
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {*} $location
     * @param {sAutomation.Service.sAutomation} sAutomation
     */
    var Overview = function ($scope, sAPIAccess, $location, sAutomation) {
        var listEndpoint = sAPIAccess.endpoint('automation.all').get();

        this.$scope         = $scope;
        this.sAPIAccess     = sAPIAccess;
        this.$location      = $location;
        this.sAutomation    = sAutomation;

        this.list = this.buildList(listEndpoint);

        this.filterFields = {
            name: {
                component: 'sTextInput',
                options: {
                    defaultValue: '',
                    attrs: {
                        'placeholder': 'Name',
                        'class'      : 'textsearch',
                        'is-disabled': '$ctrl.helperControls.readonly'
                    },
                    label: null
                }
            },
            /* will be added once we have introduced different types to the customer
            type: {
                component: 'sSelect',
                options: {
                    attrs: {
                        'placeholder'           : 'Choose type...',
                        'ng-disabled'           : '$ctrl.helperControls.readonly',
                        's-multiple'            : false,
                        'choices'               : '$ctrl.helperControls.fields.type.options.choices()'
                    },
                    choices: function () {
                        return ['Event', 'Scheduled', 'Manual'];
                    },
                    label: null
                }
            },*/
            status: {
                component: 'sSelect',
                options: {
                    defaultValue: [],
                    attrs: {
                        'placeholder'           : 'Status',
                        'ng-disabled'           : '$ctrl.helperControls.readonly',
                        's-multiple'            : true,
                        'choices'               : '$ctrl.helperControls.fields.status.options.choices()'
                    },
                    choices: function () {
                        return ['active', 'inactive'];
                    },
                    label: null
                }
            }
        };
    };

    /**
     * @function
     * @name sAutomation.Controller.Overview#buildList
     * @param {string} listEndpoint
     * @returns {Model.List}
     */
    Overview.prototype.buildList = function buildList(listEndpoint) {
        var column,
            list = new Model.List.RESTAccess(Model.Automation, listEndpoint)
        ;

        column = list.columns.createAndAdd('name');
        column.isSortable = true;
        column.label = 'Name';

        column = list.columns.createAndAdd('typeLabel');
        column.label = 'Type';

        column = list.columns.createAndAdd('status');
        column.isSortable = true;
        column.label = 'Status';
        column.asArray  = true;

        column = list.columns.createAndAdd('timeUpdated');
        column.isSortable = true;
        column.label = 'Updated';

        list.actions = {};
        if (this.sAPIAccess.isAllowed('automation.RESTAccess', Const.Method.PUT)) {
            list.actions['edit'] = new Model.Menu.Action('edit', this.editAutomation.bind(this));
        }

        if(this.sAPIAccess.isAllowed('automation.activate', Const.Method.PUT)) {
            list.actions['activate'] = new Model.Menu.Action('activate', this.activateAutomation.bind(this));
        }
        if (this.sAPIAccess.isAllowed('automation.deactivate', Const.Method.PUT)) {
            list.actions['deactivate'] = new Model.Menu.Action('deactivate', this.deactivateAutomation.bind(this));
        }

        if (this.sAPIAccess.isAllowed('automation.RESTAccess', Const.Method.DELETE)) {
            list.actions['delete'] = new Model.Menu.Action('delete', this.deleteAutomation.bind(this));
        }

        if (Object.keys(list.actions).length) {
            column = list.columns.createAndAdd('options');
            column.label = 'Options';
        }

        return list;
    };

    /**
     * @function
     * @name sAutomation.Controller.Overview#editAutomation
     * @param {Model.Automation} automation
     */
    Overview.prototype.editAutomation = function editAutomation(automation) {
        this.$location.url('automation/' + automation.uuid);
    };

    /**
     * @function
     * @name sAutomation.Controller.Overview#activateAutomation
     * @param {Model.Automation} automation
     * @return {$.Deferred}
     */
    Overview.prototype.activateAutomation = function activateAutomation(automation) {
        var self = this;

        return this.sAutomation
            .activate(automation, this.$scope)
            .then(function() {
                self.$scope.$emit('sAutomationStatusChanged', automation);
            })
            .always(function () {
                self.list.load(false, true);
            });
    };

    /**
     * @function
     * @name sAutomation.Controller.Overview#deactivateAutomation
     * @param {Model.Automation} automation
     * @return {$.Deferred}
     */
    Overview.prototype.deactivateAutomation = function deactivateAutomation(automation) {
        var self = this;

        return this.sAutomation
            .deActivate(automation, this.$scope)
            .then(function() {
                self.$scope.$emit('sAutomationStatusChanged', automation);
            })
            .always(function () {
                self.list.load(false, true);
            });
    };

    /**
     * @function
     * @name sAutomation.Controller.Overview#deleteAutomation
     * @param {Model.Automation} automation
     * @return {$.Deferred}
     */
    Overview.prototype.deleteAutomation = function deleteAutomation(automation) {
        var self = this;

        return this.sAutomation
            .remove(automation, this.$scope)
            .always(function () {
                self.list.load(false, true);
            });
    };

    ns.Overview = Overview;
})(Object.namespace('sAutomation.Controller'));
