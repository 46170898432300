/**
 * Created by Gabor on 21-Jun-17.
 */

var sSidebarModule = angular.module('sSidebar', []);

sSidebarModule
    .provider('sSidebar', sSidebar.Provider.sSidebar);

sSidebarModule.run(['sSidebar',
    /**
     * @param {sSidebar.Service.sSidebar} sSidebar
     */
        function (sSidebar) {
        // this needs to be here to create the sidebar instance
    }]);
