(function(ns) {
    var ResetPassword = ns.ResetPassword = function (menu) {
        sSecurity.Route.ResetPassword._pProto.constructor.call(this, menu);
    };

    Object.extendProto(sSecurity.Route.ResetPassword, sSecurity.Route.Auth);

    /**
     * @function
     * @name sSecurity.Route.ResetPassword#resolveTokenAndTerms
     * @param {object} $route
     * @param {object} $location
     * @param {object} sMenuAuthWrapper
     * @param {object} apiEndpoints
     * @returns {$.Deferred}
     */
    ResetPassword.prototype.resolveTokenAndTerms = function resolveToken($route, $location, sMenuAuthWrapper, apiEndpoints) {
        var params = $route.current.params,
            queryData = {
                token: params.token
            },
            deferred = $.Deferred()
        ;

        $.ajax({
            url         : apiEndpoints.security.resetPassword(),
            method      : Const.Method.GET,
            data        : queryData,
            contentType : false,
            dataType    : 'json'
        }).then(
            function (data) {
                deferred.resolve({
                    token: params.token,
                    hasToAgreeTerms: data && data.hasToAgreeTerms
                });
            },
            function () {
                var action = sMenuAuthWrapper.getElementsOfType(Model.Menu.LocationAction).shift();
                $location.path(action.options.path);
                deferred.resolve({
                    token: false,
                    hasToAgreeTerms: false
                });
            }
        );

        return deferred;
    };
})(Object.namespace('sSecurity.Route'));