(function(ns) {

    /**
     * @namespace
     * @alias sAutomation.Controller.Overview
     * @constructor
     *
     * @param {*} $mdDialog
     * @param {*} $scope
     * @param {Object} apiEndpoints
     * @param {Model.ContentFeed.Entry[]} selectedFeeds
     */
    var TemplateSelect = function TemplateSelect($mdDialog, $scope, apiEndpoints, selectedFeeds) {
        this.$mdDialog      = $mdDialog;
        this.$scope         = $scope;
        this.imagesEndpoint = apiEndpoints.images();
        this.selectedFeeds  = selectedFeeds;
        this.templates      = sContentFeed.Controller.TemplateSelect.TEMPLATES;
    };

    /**
     * @function
     * @name sContentFeed.Controller.TemplateSelect#cancel
     */
    TemplateSelect.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    /**
     * @function
     * @name sContentFeed.Controller.TemplateSelect#useTemplate
     * @param {String} template
     */
    TemplateSelect.prototype.useTemplate = function useTemplate(template) {
        this.$mdDialog.hide(template);
    };

    TemplateSelect.TEMPLATES = [
        {
            name: 'hScroll',
            image: 'template-h-scroll.png',
            minSelected: 1,
            maxSelected: 10,
            title: 'Carousel',
            description: 'Creates one bubble for each item, inserts the\n' +
                         'image, headline and description in the Carousel.\n' +
                         'Automatically adds a CTA with the URL.'

        },
        {
            name: 'hScrollSummary',
            image: 'template-h-scroll-summary.png',
            minSelected: 1,
            maxSelected: 10,
            title: 'Carousel with summary',
            description: 'Creates one bubble for each item (similar to Carousel). ' +
                         'Besides the automatically added CTA with the URL, ' +
                         'one will be added that triggers a message that has the first ' +
                         'two paragraphs of the article as text body plus the URL CTA again.'
        }
    ];

    ns.TemplateSelect = TemplateSelect;
})(Object.namespace('sContentFeed.Controller'));
