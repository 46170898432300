(function(ns) {
    var KEY_ROLE = 'role';
    /**
     * @namespace
     * @alias Model.User.Role
     *
     *
     * @param uuid
     * @constructor
     * @extends Model.RESTAccessByUUID
     */
    var Role = function(uuid) {
        var permissions             = [],
            permissionsCache        = [],
            permissionsState        = '',
            permissionsSaving       = null,
            refreshPermissionsCache = function () {
                permissionsCache.splice.apply(
                    permissionsCache,
                    [0, permissionsCache.length].concat(permissions));
            }
            ;

        Object.defineProperties(
            this,
            {
                name : {
                    writable    : true,
                    enumerable  : true
                },
                permissions: {
                    get: function() {
                        return permissionsCache;
                    }
                }
            }
        );

        /**
         * @param {Model.User.Permission} permission
         */
        this.addPermission = function addPermission(permission) {
            var self = this;
            Object.instanceOf(permission, Model.User.Permission);

            if (this.getPermissionByUuid(permission.uuid)) {
                return;
            }

            permission.associatedPermissions.map(function(associatedPermission) {
                if (associatedPermission.type !== Model.User.Permission.CONST_REQUIRES || self.getPermissionByUuid(associatedPermission.permission.uuid)) {
                    return;
                }

                self.addPermission(associatedPermission.permission);
            });

            permissions.push(permission);
            refreshPermissionsCache();
        };

        /**
         * @param {Model.User.Permission} permission
         */
        this.removePermission = function removePermission(permission) {
            var key,
                self = this
                ;
            Object.instanceOf(permission, Model.User.Permission);

            var foundPermission = this.getPermissionByUuid(permission.uuid);

            if (!foundPermission) {
                return
            }

            if ((key = permissions.indexOf(foundPermission)) !== -1) {
                permissions.splice(key, 1);
                refreshPermissionsCache();
                permission.associatedPermissions.map(function(associatedPermission) {
                    if (associatedPermission.type !== ('-' + Model.User.Permission.CONST_REQUIRES) || !self.getPermissionByUuid(associatedPermission.permission.uuid)) {
                        return;
                    }
                    self.removePermission(associatedPermission.permission);
                });
            }
        };

        /**
         * @param {String} uuid
         * @returns {*}
         */
        this.getPermissionByUuid = function getPermissionByUuid(uuid) {
            return permissions.filter(function(permission) {
               return permission.uuid === uuid;
            }).pop();
        };

        var protecteds = Role._pProto.constructor.call(this, uuid);
        protecteds.registerRelationHandler(this.endPoint, function(data) {
            if (!data.permissions) {
                return;
            }

            var permissionEndPoint = new Model.User.Permission().endPoint;

            return data.permissions.map(function(permissionData) {
                var obj = {};
                obj.uuid = permissionData.uuid;
                obj.endPoint = permissionEndPoint;
                return obj;
            });
        });

        var parentSave = this.save;
        this.save = function save() {
            var self                    = this,
                executeSavePermissions  = function executeSavePermissions() {
                    if (JSON.stringify(self.permissions) === permissionsState) {
                        return $.Deferred().resolve(self).promise();
                    }

                    var permissionsFromPermissionState = JSON.parse(permissionsState);
                    permissionsFromPermissionState = permissionsFromPermissionState.map(function(permissionJson) {
                        return Model.User.Permission.createByData(permissionJson);
                    });

                    var mapFn = function(permission) {
                        return permission.uuid
                    };

                    var executables         = [],
                        /**
                         * Extract data for the aggregation
                         * @param options
                         * @param data
                         * @param counter
                         */
                        dataTransformFunc   = function(options, data, counter) {
                            var nameObj = {
                                method  : options.method,
                                url     : options.url,
                                counter : counter
                            };
                            data.append(JSON.stringify(nameObj).bin2hex(), '');
                        }
                    ;

                    permissionsFromPermissionState.diff(self.permissions, mapFn).map(function(permission) {
                        executables.push(function() {// these need to be added > POST
                            $.ajax({
                                url     : Role.prototype.permissionToggleEndPoint(self.uuid, permission.uuid),
                                method  : Const.Method.POST
                            });
                        });
                    });
                    self.permissions.diff(permissionsFromPermissionState, mapFn).map(function(permission) {
                        executables.push(function() {
                            // these need to be removed
                            $.ajax({
                                url     : Role.prototype.permissionToggleEndPoint(self.uuid, permission.uuid),
                                method  : Const.Method.DELETE
                            });
                        });
                    });


                    var $deferred = $.aggregateAction(executables, Model.RESTAccessByUUID.endpoint_batch(true), dataTransformFunc);
                    // capture the state that is submitted
                    self.setPermissionsJSONState();
                    return $deferred;
                }
            ;
            return parentSave.apply(this).then(function(data) {
                if (!permissionsSaving) {
                    permissionsSaving = executeSavePermissions(data).always(function() {
                        permissionsSaving = null;
                    });
                } else {
                    permissionsSaving.then(executeSavePermissions);
                }
            });
        };

        this.setPermissionsJSONState = function() {
            permissionsState = JSON.stringify(this.permissions);
        };

        this.setPermissionsJSONState();

        /**
         * @name Model.User.Role#permissions
         * @type Model.User.Permission[]
         */
    };

    Object.extendProto(Role, Model.RESTAccessByUUID);

    /**
     * @param formData
     * @param {string=} name
     * @returns {*|FormData}
     */
    Role.prototype.getFormData = function getFormData(formData, name) {
        var fData = Role._pProto.getFormData.call(this, formData),
            roleData = JSON.stringify(this);

        name = name || KEY_ROLE;

        fData.append(name, roleData);

        return fData;
    };

    /**
     * @param {Object} data
     */
    Role.prototype.updateByData = function updateByData(data) {
        var self = this,
            permissionsCreated = []
        ;

        Object.updateByData(this, data);

        if (data.permissions && data.permissions instanceof Array) {
            data.permissions.map(function(json) {
                var permission = Model.User.Permission.createByData(json);
                permissionsCreated.push(permission);
                self.addPermission(permission);
            });
        }

        permissionsCreated.diff(this.permissions, function(permission) {
            return permission.uuid;
        }).map(function(permission) {
            self.removePermission(permission);
        });

        Role._pProto.updateByData.call(this, data);
        this.setPermissionsJSONState();
    };

    /**
     * @param {string} endpoint
     * @param {Array=} domainIds
     * @param {boolean} includeSystem
     * @returns {PromiseLike<T>|Promise<T>|$.Deferred}
     */
    Role.query = function(endpoint, domainIds, includeSystem) {
        var queryParams = {};
        if (domainIds instanceof Array && domainIds.length) {
            queryParams.domainIds = domainIds;
        }
        if (includeSystem) {
            queryParams.includeSystem = true;
        }

        return new Model.Collection.RESTAccess(Role).load(endpoint, queryParams).then(function(collection) {
            return collection.items;
        });
    };

    /**
     * @param {Object} data
     * @returns {Model.User.Role}
     */
    Role.createByData = function createByData(data) {
        var role = new Role(data['uuid']);

        role.updateByData(data);

        return role;
    };

    ns.Role = Role;
})(Object.namespace('Model.User'));