(function(ns) {
    /**
     * @namespace
     * @alias sSegment.Controller.EditDialog
     *
     * @param $scope
     * @param $mdDialog
     * @constructor
     */
    var EditDialog = function ($scope, $mdDialog) {
            this.$mdDialog = $mdDialog;
        };

    /**
     * @function
     * @name sSegment.Controller.EditDialog#cancel
     */
    EditDialog.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    /**
     * @function
     * @name sSegment.Controller.EditDialog#onSave
     */
    EditDialog.prototype.onSave = function onSave() {
        // if nothing changed just cancel the dialog
        if (!this.segment.isModified()) {
            this.$mdDialog.cancel();
        } else {
            this.$mdDialog.hide();
        }
    };

    ns.EditDialog = EditDialog;

})(Object.namespace('sSegment.Controller'));