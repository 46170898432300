(function(ns) {
    var FACEBOOK_PERSISTENT_MENU = 'facebook_persistent_menu',
        FACEBOOK_GET_STARTED = 'facebook_get_started',
        DOMAIN_LOGO_URL = 'domain_logo_url',
        DOMAIN_LOGO_SQUARE_URL = 'domain_logo_square_url'
    ;

    /**
     * @namespace
     * @alias Model.DomainMeta
     *
     * @constructor
     */
    var DomainMeta = function () {
        this.isNew = true;
    };

    /**
     * @returns {$.Deferred}
     */
    DomainMeta.prototype.save = function save() {
        var self = this,
            url,
            data = {},
            key = (self.isNew ? self.key : 'value')
        ;

        url = DomainMeta.endPoint + ((self.isNew) ? '' : '/' + self.key);

        data[key] = this.value;

        return $.ajax({
            url: url,
            method: self.isNew ? Const.Method.POST : Const.Method.PUT,
            data: JSON.parse(JSON.stringify(data, function (key, value) {
                if (key !== '$$hashKey') {
                    return value;
                }
            }))
        }).then(
            function (data) {
                // object is not new anymore because it's saved at least once
                self.isNew = false;
                return data;
            }
        );
    };

    /**
     * @param {String} key
     * @param {Function=} parseFunc
     * @returns {$.Deferred}
     */
    DomainMeta.getOrCreate = function getOrCreate(key, parseFunc) {
        if (!DomainMeta.endPoint) {
            throw 'Set DomainMeta.endPoint before calling getOrCreate method.';
        }

        var $deferred = $.Deferred();

        $.ajax({
            url                 : DomainMeta.endPoint + '/' + key,
            method              : Const.Method.GET,
            dataType            : 'text'
        })
        .done(function (data) {
            if (data instanceof Object) {
                data = JSON.stringify(data);
            }

            try {
                data = JSON.parse(data, parseFunc);
            } catch (err) {
                // loading was successful but parsing failed
                return $deferred.reject(err);
            }

            var model = new DomainMeta();
            for (var i in data) {
                model[i] = data[i];
            }
            model.isNew = false;

            if (key === FACEBOOK_GET_STARTED && !model.value.action) {
                model.value.action = new Model.Action.Collection();
            }

            $deferred.resolve(model);
        })
        .fail(function () { // loading itself failed
            var model = new DomainMeta();
            model.key = key;

            if (key === FACEBOOK_PERSISTENT_MENU) {
                model.value = {
                   allowUserInput: true
                };
            }

            if (key === FACEBOOK_GET_STARTED) {
                model.value = {
                    showGetStartedButton: false,
                    action: new Model.Action.Collection()
                };
            }

            $deferred.resolve(model);
        });

        return $deferred.promise();
    };

    Object.defineProperties(
        DomainMeta,
        {
            FACEBOOK_PERSISTENT_MENU : {
                enumerable : true,
                get        : function () {
                    return FACEBOOK_PERSISTENT_MENU;
                }
                /**
                 * @property
                 * @name Model.DomainMeta.FACEBOOK_PERSISTENT_MENU
                 * @type {String}
                 */
            },
            FACEBOOK_GET_STARTED              : {
                enumerable : true,
                get        : function () {
                    return FACEBOOK_GET_STARTED;
                }
                /**
                 * @property
                 * @name Model.DomainMeta.FACEBOOK_GET_STARTED
                 * @type {String}
                 */
            },
            DOMAIN_LOGO_URL: {
                value: DOMAIN_LOGO_URL
                /**
                 * @property
                 * @constant
                 * @name Model.DomainMeta#DOMAIN_LOGO_URL
                 * @type {String}
                 */
            },
            DOMAIN_LOGO_SQUARE_URL: {
                value: DOMAIN_LOGO_SQUARE_URL
                /**
                 * @property
                 * @constant
                 * @name Model.DomainMeta#DOMAIN_LOGO_SQUARE_URL
                 * @type {String}
                 */
            }
        }
    );

    ns.DomainMeta = DomainMeta;
})(Object.namespace('Model'));