(function (ns) {
    var PLACEHOLDER_TYPE_STRING     = 'string',
        PLACEHOLDER_TYPE_INTEGER    = 'int',
        PLACEHOLDER_TYPE_URL        = 'url',
        PLACEHOLDER_TYPE_DEFAULT    = PLACEHOLDER_TYPE_STRING,
        samples                     = {}
    ;

    samples[PLACEHOLDER_TYPE_STRING]    = 'foo';
    samples[PLACEHOLDER_TYPE_INTEGER]   = function() { return Math.floor(Math.random() * 100).toString(); };
    samples[PLACEHOLDER_TYPE_URL]       = 'https://spectrm.io';

    /**
     * @namespace
     * @alias Model.Source.Placeholder
     * @param {String} label
     * @param {String} token
     * @param {String} type
     * @param {Model.Source} source
     * @param {String=} description
     * @param {String=} url
     *
     * @constructor
     */
    var Placeholder = function (label, token, type, source, description, url) {
        Object.defineProperties(
            this,
            {
                label: {
                    enumerable: true,
                    get: function () {
                        return label;
                    }
                    /**
                     * @property
                     * @name Model.Source.Placeholder#name
                     * @type {String}
                     */
                },
                token: {
                    enumerable: true,
                    get: function () {
                        return token;
                    }
                    /**
                     * @property
                     * @name Model.Source.Placeholder#token
                     * @type {String}
                     */
                },
                description: {
                    enumerable: true,
                    get: function () {
                        return description || '';
                    }
                    /**
                     * @property
                     * @name Model.Source.Placeholder#description
                     * @type {String}
                     */
                },
                source: {
                    get: function () {
                        return source;
                    }
                    /**
                     * @property
                     * @name Model.Source.Placeholder#source
                     * @type {Model.Source}
                     */
                },
                fullyQualifiedName: {
                    get: function () {
                        return source.fullyQualifiedName + '.' + label;
                    }
                    /**
                     * @property
                     * @name Model.Source.Placeholder#fullyQualifiedName
                     * @type {String}
                     */
                },
                url: {
                    enumerable: true,
                    get: function () {
                        return url;
                    }
                    /**
                     * @property
                     * @name Model.Source.Placeholder#url
                     * @type {String}
                     */
                },
                isFile: {
                    get: function () {
                        return ['audio', 'video', 'image'].indexOf(type) !== -1;
                    }
                    /**
                     * @property
                     * @name Model.Source.Placeholder#isFile
                     * @type {Boolean}
                     */
                },
                sampleValue: {
                    get: function () {
                        return samples[type] || null;
                    }
                    /**
                     * @property
                     * @name Model.Source.Placeholder#sampleValue
                     */
                },
                type : {
                    enumerable : true,
                    get        : function () {
                        return type ? type : PLACEHOLDER_TYPE_DEFAULT;
                    }
                    /**
                     * @property
                     * @name Model.Source.Placeholder#type
                     * @type {String}
                     */
                }
            });
    };

    /**
     * @function
     * @name Model.Source.Placeholder.createByData
     *
     * @param {{
     *      label       : String,
     *      token       : String=,
     *      type        : String=,
     *      source      : Model.Source=
     *      description : String=,
     *      url         : String=,
     *  }} data
     *
     * @returns {Model.Source.Placeholder}
     */
    Placeholder.createByData = function createByData(data) {
        return new Placeholder(data.label, data.token, data.type, data.source, data.description, data.url);
    };

    Object.defineProperties(
        Placeholder,
        {
            PLACEHOLDER_TYPE_DEFAULT : {
                value : PLACEHOLDER_TYPE_DEFAULT
                /**
                 * @property
                 * @constant
                 * @name Model.Source.Placeholder#PLACEHOLDER_TYPE_DEFAULT
                 * @type {String}
                 */
            },
            PLACEHOLDER_TYPE_URL: {
                value: PLACEHOLDER_TYPE_URL
                /**
                 * @property
                 * @constant
                 * @name Model.Source.Placeholder#PLACEHOLDER_TYPE_URL
                 * @type {String}
                 */
            },
            PLACEHOLDER_TYPE_INTEGER: {
                value: PLACEHOLDER_TYPE_INTEGER
                /**
                 * @property
                 * @constant
                 * @name Model.Source.Placeholder#PLACEHOLDER_TYPE_INTEGER
                 * @type {String}
                 */
            }
        }
    );

    ns.Placeholder = Placeholder;
})(Object.namespace('Model.Source'));