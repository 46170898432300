(function(ns) {
    /**
     * @namespace
     * @alias Model.RepairCase.MessageOrphans
     *
     * @constructor
     * @extends Model.RepairCase
     */
    var MessageOrphans = function() {

        var options = {
            confirm     : 'Continue saving',
            decline     : 'Cancel',
            title       : 'Saving will remove messages',
            content     : 'Some messages will be removed when saving because they have no connection to other messages.',
            ariaLabel   : ''
        };

        MessageOrphans._pProto.constructor.call(
            this,
            this.checkOrphans,
            this.removeOrphans,
            options
        );
    };

    Object.extendProto(MessageOrphans, Model.RepairCase);

    /**
     * @param {Model.Message.Collection} collection
     * @throws {Model.Exception.InvalidMessages}
     */
    MessageOrphans.prototype.checkOrphans = function checkOrphans(collection) {
        var orphans = [];
        for (var i = 0; i < collection.messages.length; i++) {
            if (collection.messages[i].isOrphan) {
                orphans.push(collection.messages[i]);
            }
        }

        if (orphans.length){
            throw new Model.Exception.InvalidMessages(orphans);
        }
    };

    /**
     * @param {Model.Message.Collection} collection
     */
    MessageOrphans.prototype.removeOrphans = function removeOrphans(collection) {
        for (var i = 0; i < collection.messages.length; i++) {
            if (collection.messages[i].isOrphan) {
                collection.removeMessage(collection.messages[i]);
                i--; // set the index back with one, as we removed it
            }
        }
    };
    ns.MessageOrphans = MessageOrphans;
})(Object.namespace('Model.RepairCase'));