(function() {

    var ns = Object.namespace('sMessageAnchor.Controller'),
        DeactivateController = ns.DeactivateController = function($mdDialog, $scope, $location, messageAnchor, usages) {
            this.$mdDialog          = $mdDialog;
            this.$scope             = $scope;
            this.$location          = $location;
            this.savingInProgress   = false;
            this.messageAnchor      = messageAnchor;
            this.usages             = {
                reactions: (usages.reactions || []),
                messageAnchors: (usages.messageAnchors || [])
            };

            // add readable reaction-name
            for (var i = 0; i<this.usages.reactions.length; i++) {
                if (typeof Model.AI.Reaction.Collection.identMap[this.usages.reactions[i].ident] !== 'undefined') {
                    this.usages.reactions[i].name = Model.AI.Reaction.Collection.identMap[this.usages.reactions[i].ident].name;
                }
            }
        };

    /**
     * @function
     * @name sMessageAnchor.Controller.DeactivateController#cancel
     */
    DeactivateController.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    /**
     * @function
     * @name sMessageAnchor.Controller.DeactivateController#deactivate
     */
    DeactivateController.prototype.deactivate = function deactivate() {
        var self = this;
        this.savingInProgress = true;

        return this.messageAnchor.updateActiveState(true).then(
            function() {
                self.$mdDialog.hide();
            },
            function() {
                self.$mdDialog.cancel(true);
            }
        ).always(function() {
            self.$scope.$evalAsync(function() {
                self.savingInProgress = false;
            });
        });
    };

})();