(function (ns) {
    /**
     * @namespace
     * @alias Model.NotificationLabel
     *
     * @param {?String} uuid
     * @param {?String} domainId
     * @param {?String} status
     * @param {?Boolean} hasActiveTokens
     * @constructor
     *
     * @extends Model.RESTAccessByUUID
     */
    var NotificationLabel = function (uuid, domainId, status, hasActiveTokens) {
        Object.defineProperties(
            this,
            {
                name            : {
                    enumerable : true,
                    writable   : true
                    /**
                     * @property
                     * @type {?String}
                     * @name Model.NotificationLabel#name
                     */
                },
                domainId        : {
                    enumerable : true,
                    get        : function () {
                        return domainId;
                    }
                    /**
                     * @property
                     * @type {?String}
                     * @name Model.NotificationLabel#domainId
                     */
                },
                status          : {
                    enumerable : true,
                    get        : function () {
                        return status;
                    }
                    /**
                     * @property
                     * @type {?String}
                     * @name Model.NotificationLabel#status
                     */
                },
                hasActiveTokens : {
                    enumerable : true,
                    get        : function () {
                        return hasActiveTokens;
                    }
                    /**
                     * @property
                     * @type {?Boolean}
                     * @name Model.NotificationLabel#hasActiveTokens
                     */
                },
            }
        );

        NotificationLabel._pProto.constructor.call(this, uuid);
    };

    Object.extendProto(NotificationLabel, Model.RESTAccessByUUID);

    /**
     * @function
     * @name Model.NotificationLabel.createByData
     * @param {Object} data
     *
     * @returns {Model.NotificationLabel}
     */
    NotificationLabel.createByData = function createByData(data) {
        data.uuid = data.uuid || new Model.UUID().uuid;

        var NotificationLabel = new Model.NotificationLabel(data.uuid, data.domainId, data.status, data.hasActiveTokens);
        Object.updateByData(NotificationLabel, data);

        return NotificationLabel;
    };

    /**
     * @function
     * @name Model.NotificationLabel.getFormData
     * @param {FormData=} formData
     * @param {string=} name
     * @return {FormData}
     */
    NotificationLabel.prototype.getFormData = function getFormData(formData, name) {
        var fData = NotificationLabel._pProto.getFormData.call(this, formData);

        if (name && name instanceof Object) {
            name['dataName'] = 'notificationLabel';
            name = JSON.stringify(name).bin2hex();
        }

        fData.append(name ? name : 'notificationLabel', JSON.stringify(this));

        return fData;
    };

    ns.NotificationLabel = NotificationLabel;
})(Object.namespace('Model'));
