(function (ns) {
    /**
     * @namespace
     * @alias Controller.Component.sContentSourcePicker
     *
     * @constructor
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     */
    var sContentSourcePicker = function (sAPIAccess) {
        this.sAPIAccess = sAPIAccess;

        /**
         * @property
         * @name Controller.Component.sContentSourcePicker#model
         * @type {Number}
         */

        /**
         * @property
         * @name Controller.Component.sContentSourcePicker#sourceTypeId
         * @type {Number|Array<Number>}
         */
    };

    /**
     * @returns {PromiseLike<Model.Source[]>}
     */
    sContentSourcePicker.prototype.getContentSourcesByType = function getContentSourcesByType() {
        this.sourceTypeId = typeof(this.sourceTypeId) === 'string' ? this.sourceTypeId : JSON.stringify(this.sourceTypeId);

        var url = this.sAPIAccess.endpoint('source.byType').get(this.sourceTypeId);

        if (!url) {
            throw 'No permission to read content sources.'
        }

        return $.get(url).then(function (results) {
            if (!results instanceof Array) {
                return [];
            }

            return results.map(function(row) {
                return Model.Source.createByData(row);
            });
        });
    };

    ns.sContentSourcePicker = sContentSourcePicker;
})(Object.namespace('Controller.Component'));
