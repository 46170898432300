(function (ns) {
    var DEFAULT_LEVEL   = 'default',
        ACTIVATE_LEVEL  = 'activate'
    ;
    /**
     * @namespace
     * @alias Provider.sConversationValidator
     * @constructor
     */
    var sConversationValidator = function sConversationValidator() {
        /**
         * @type {ValidationLevels}
         */
        var validationLevels = {};

        /**
         * @function
         * @name Provider.sConversationValidator~getOrCreateValidationLevel
         * @param {String} level
         * @return {ValidationLevel}
         */
        function getOrCreateValidationLevel(level) {
            return validationLevels[level] = validationLevels[level] || {requires : [], validators : []};
        }

        /**
         * @typedef {Object} ValidationLevel
         * @property {String[]} requires
         * @property {Model.RepairCase} validators
         */

        /**
         * @typedef {Object<String, ValidationLevel>} ValidationLevels
         */

        /**
         * @function
         * @name Provider.sConversationValidator#addValidator
         * @param {Model.RepairCase} repairCase
         * @param {String=} level
         * @return {Provider.sConversationValidator}
         */
        this.addValidator = function addValidator(repairCase, level) {
            Object.instanceOf(repairCase, Model.RepairCase);
            var validationLevel = getOrCreateValidationLevel(level || DEFAULT_LEVEL);
            validationLevel.validators.push(repairCase);

            return this;
        };

        /**
         * @function
         * @name Provider.sConversationValidator#addRequirementForLevel
         * @param {String} level - The level requires ...
         * @param {String} levelRequired - The level required
         * @return {Provider.sConversationValidator}
         */
        this.addRequirementForLevel = function addRequirementForLevel(level, levelRequired) {
            var validationLevel = getOrCreateValidationLevel(level);

            // create required level as well
            getOrCreateValidationLevel(levelRequired);

            validationLevel.requires.push(levelRequired);

            return this;
        };

        /**
         * @function
         * @name Provider.sConversationValidator#$get
         * @param {Service.sConfirm} sConfirm
         * @param sBugsnag
         * @param Notification
         * @return {Service.sConversationValidator}
         */
        this.$get = function $get(sConfirm, sBugsnag, Notification) {
            return new Service.sConversationValidator(validationLevels, sConfirm, sBugsnag, Notification);
        };
    };

    Object.defineProperties(
        sConversationValidator,
        {
            DEFAULT_LEVEL: {
                enumerable: true,
                get: function () {
                    return DEFAULT_LEVEL;
                }
                /**
                 * @property
                 * @name Provider.sConversationValidator.DEFAULT_LEVEL
                 * @type {String}
                 * @constant
                 */
            },
            ACTIVATE_LEVEL: {
                enumerable: true,
                get: function () {
                    return ACTIVATE_LEVEL;
                }
                /**
                 * @property
                 * @name Provider.sConversationValidator.ACTIVATE_LEVEL
                 * @type {String}
                 * @constant
                 */
            }
        });

    ns.sConversationValidator = sConversationValidator;
})(Object.namespace('Provider'));
