(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sDelayPicker
     * @constructor
     *
     */
    var sDelayPicker = function () {
        this.delayOptions = Model.Delay.getOptions();
        /**
         * @property
         * @name Controller.Component.sDelayPicker#model
         * @type Model.Delay
         */

        /**
         * @property
         * @name Controller.Component.sDelayPicker#disabled
         * @type boolean
         */

        /**
         * @property
         * @name Controller.Component.sDelayPicker#delayOptions
         * @type {{value: string, label: string}[]}
         */
    };

    ns.sDelayPicker = sDelayPicker;
})(Object.namespace('Controller.Component'));