(function(ns) {

    /**
     * @namespace
     * @alias sSecurity.Provider.sMenuAuthWrapper
     */
    var sMenuAuthWrapper = function() {
        var menu;

        this.$get = function() {
            if (!menu) {
                menu = this.initializeMenu();
            }

            return menu;
        }
    };

    /**
     * @returns Model.Menu.Menu
     */
    sMenuAuthWrapper.prototype.initializeMenu = function initializeMenu() {
        return new Model.Menu.Menu()
            .add(
                Model.Menu.LocationAction.TYPE_IDENT,
                'Sign in',
                function () {
                    return this.options.path;
                },
                {
                    title: 'Sign in to Spectrm',
                    path: '/auth/login',
                    controller: sSecurity.Controller.Login,
                    templateUrl: ''
                }
            )
            .add(
                Model.Menu.LocationAction.TYPE_IDENT,
                'Reset password',
                function () {
                    return this.options.path;
                },
                {
                    title: 'Request password reset',
                    path: '/auth/forgot-password',
                    controller: sSecurity.Controller.ForgotPassword,
                    templateUrl: 'ssecurity:forgot-password'
                }
            )
            .add(
                Model.Menu.LocationAction.TYPE_IDENT,
                'Reset password',
                function () {
                    return this.options.path;
                },
                {
                    title: 'Reset your password',
                    path: '/auth/reset-password',
                    controller: sSecurity.Controller.SetPassword,
                    templateUrl: 'ssecurity:set-password',
                    hide: true
                }
            )
            .add(
                Model.Menu.LocationAction.TYPE_IDENT,
                'Choose password',
                function () {
                    return this.options.path;
                },
                {
                    title: 'Choose your password',
                    path: '/auth/accept-invite',
                    controller: sSecurity.Controller.SetPassword,
                    templateUrl: 'ssecurity:set-password',
                    hide: true
                }
            )
            .add(
                Model.Menu.Action.TYPE_IDENT,
                'Terms',
                function () {
                    return false;
                },
                {
                    isExternalPath: true,
                    path: '/misc/spectrm_terms_of_service_v20200220.pdf',
                    title: 'Terms and conditions',
                    target: '_blank'
                }
            )
            .add(
                Model.Menu.Action.TYPE_IDENT,
                'Privacy policy',
                function () {
                    return false;
                },
                {
                    isExternalPath: true,
                    path: '/misc/Spectrm_Privacy_Policy_2018_03.pdf',
                    title: 'Privacy policy',
                    target: '_blank'
                }
            );
    };

    ns.sMenuAuthWrapper = sMenuAuthWrapper;

})(Object.namespace('sSecurity.Provider'));