(function (ns) {
    var EVENT_CREATE_NEW_ENTITY_FROM_UI = 'sEventCreateNewEntityFromUI';

    /**
     * @namespace
     * @alias Controller.Directive.MatchTextHelperPanel
     *
     * @param $scope
     * @param {Model.AI.EntityCollection} sEntityRepository
     *
     * @constructor
     * @extends Controller.Directive.AutoCompleter
     */
    var MatchTextHelperPanel = function ($scope, sEntityRepository) {
        MatchTextHelperPanel._pProto.constructor.apply(this, arguments);
    };

    Object.extendProto(MatchTextHelperPanel, Controller.Directive.AutoCompleter.Panel);
    
    MatchTextHelperPanel.prototype.handleCreateNew = function handleCreateNew($event) {
        $event.preventDefault();
        this.$scope.$emit(EVENT_CREATE_NEW_ENTITY_FROM_UI);
    };

    /**
     * @param {Model.AI.Entity} element
     * @return {?}
     */
    MatchTextHelperPanel.prototype.useElement = function useElement(element) {
        this.sEntityRepository.addItem(element);
        return MatchTextHelperPanel._pProto.useElement.call(this, element.name);
    };

    Object.defineProperties(
        MatchTextHelperPanel,
        {
            EVENT_CREATE_NEW_ENTITY_FROM_UI: {
                value: EVENT_CREATE_NEW_ENTITY_FROM_UI
                /**
                 * @property
                 * @constant
                 * @name Controller.Directive.MatchTextHelperPanel#EVENT_CREATE_NEW_ENTITY_FROM_UI
                 * @type {String}
                 */
            }
        });

    ns.MatchTextHelperPanel = MatchTextHelperPanel;
})(Object.namespace('Controller.Directive'));
