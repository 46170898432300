(function(ns){
    /**
     * @namespace
     * @alias Model.Exception.AggregatedAjaxError
     * @constructor
     * @extends AjaxError
     *
     * @param {String=} message
     */
    var AggregatedAjaxError = function AggregatedAjaxError(message) {
        AggregatedAjaxError._pProto.constructor.call(this, message);

        Object.defineProperties(
            this,
            {
                name: {
                    configurable: true,
                    enumerable  : true,
                    value       : 'AggregatedAjaxError'
                    /**
                     * @property
                     * @name Model.Exception.AggregatedAjaxError#name
                     * @type {String}
                     */
                }
            }
        );
    };

    Object.extendProto(AggregatedAjaxError, Model.Exception.AjaxError);

    ns.AggregatedAjaxError = AggregatedAjaxError;
})(Object.namespace('Model.Exception'));
