(function(ns) {
    /**
     * @namespace
     * @alias Model.List.Column
     * @constructor
     *
     * @param {String} name
     * @param {Boolean=} [hidden]
     */
    var Column = function(name, hidden) {
        var label   ,
            self    = this
        ;

        /**
         * @name Model.List.Column#name
         * @property
         * @type {String}
         */

        /**
         * @name Model.List.Column#label
         * @property
         * @type {String}
         */

        /**
         * @name Model.List.Column#isSortable
         * @property
         * @type {Boolean}
         */

        /**
         * @name Model.List.Column#attributes
         * @property
         * @type {Object}
         */

        /**
         * @name Model.List.Column#defaultOrder
         * @property
         * @type {String}
         */

        this.name = name;

        /**
         * @name Model.List.Column#isHidden
         * @property
         * @type {Boolean}
         */
        this.isHidden = hidden || false;

        Object.defineProperties(
            this,
            {
                label: {
                    enumerable: true,
                    get: function() {
                        return label || self.name;
                    },
                    set: function(val) {
                        label = val;
                    }
                    /**
                     * @property
                     * @name Model.List.Column#label
                     * @type {String}
                     */
                },
                htmlAttributes: {
                    get: function() {
                        var concat = '';
                        for (var i in self.attributes) {
                            if (concat.length) {
                                concat += ' ';
                            }

                            concat += i + '"' + self.attributes[i] + '"';
                        }
                        return concat;
                    }
                    /**
                     * @property
                     * @name Model.List.Column#htmlAttributes
                     * @type {String}
                     */
                }
            }
        );
    };

    ns.Column = Column;
})(Object.namespace('Model.List'));