(function (ns) {
    /**
     * @namespace
     * @alias sAnalytics.Route.Audience
     * @extends sRoute
     * @constructor
     */
    var Audience = function Audience() {
        this.controller     = sAnalytics.Controller.Charts;
        this.controllerAs   = '$ctrl';
        this.templateUrl    = 'sanalytics:charts';
        this.reloadOnSearch = false;
        this.accessControl  = {'analytics.byDomain.chartData': Const.Method.GET};

        Audience._pProto.constructor.call(this);
    };

    Object.extendProto(Audience, sRoute);

    /**
     * @name sAnalytics.Route.Audience#resolveCharts
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {Service.sDomain} sDomainService
     * @returns {{}}
     */
    Audience.prototype.resolveCharts = function resolveCharts(sAPIAccess, sDomainService) {
        var charts = {},
            sBag
        ;

        sBag = new Model.Analytics.StatisticsBag(sAPIAccess.endpoint('analytics.byDomain.chartData').get(sDomainService.currentDomainId, 'users'));
        charts.totalUsers = {
            bag             : sBag,
            traceFactory    : 'scatter',
            template        : 'default|defaultColors|legend|hoverMarkers|hoverSpike|scatter|timeRangeXAxis',
            title           : 'Total users',
            hints           : {
                description     : 'How many users are in your chatbot in the timespan?',
                absolute        : 'Total unique users'
            }
        };

        /* DELI-212
        sBag = new Model.Analytics.StatisticsBag(sAPIAccess.endpoint('analytics.timeSeries').get('UserInteracted', sDomainService.currentDomainId));
        charts.activeUsers = {
            bag             : sBag,
            traceFactory    : 'scatter',
            template        : 'default|defaultColors|legend|hoverMarkers|hoverSpike|scatter|timeRangeXAxis',
            title           : 'Active users',
            hints           : {
                description     : 'How many active users are in your chatbot in the timespan?',
                absolute        : 'Total unique active users',
                relative        : 'Total of active users relative to total unique users'
            }
        };*/

        sBag = new Model.Analytics.StatisticsBag(sAPIAccess.endpoint('analytics.byDomain.chartData').get(sDomainService.currentDomainId, 'users'));
        sBag.filters.createAndAdd('categories', ['reachable'], true);
        charts.reachable = {
            bag             : sBag,
            traceFactory    : 'scatter',
            template        : 'default|defaultColors|legend|hoverMarkers|hoverSpike|stackedScatter|timeRangeXAxis',
            title           : 'Reachable users',
            thumbExtra      : 'stackedScatter',
            hints           : {
                description     : 'How many users can be reached because they have interacted in the last 24 hours or have not received a message since?',
                absolute        : 'Total of users who can be reached',
                relative        : 'Total of users that can be reached relative to total unique users'
            }
        };

        sBag = new Model.Analytics.StatisticsBag(sAPIAccess.endpoint('analytics.byDomain.chartData').get(sDomainService.currentDomainId, 'users'));
        sBag.filters.createAndAdd('categories', ['status'], true);
        charts.status = {
            bag             : sBag,
            traceFactory    : 'scatterWithDiff',
            template        : 'default|defaultColors|legend|hoverMarkers|hoverSpike|scatter|timeRangeXAxis',
            title           : 'Net growth',
            // filter out which traces to show in the thumbs
            thumbExtra      : 'show:[0]',
            hints           : {
                description     : 'How many unique users were gained in the timespan?',
                absolute        : 'Net growth of unique users (added – removed)',
                relative        : 'Relative change of unique users'
            }
        };

        sBag = new Model.Analytics.StatisticsBag(sAPIAccess.endpoint('analytics.byDomain.chartData').get(sDomainService.currentDomainId, 'users'));
        sBag.filters.createAndAdd('categories', ['subscriptions'], true);
        charts.subscriptions = {
            bag             : sBag,
            traceFactory    : 'scatter',
            template        : 'default|grayFirst|legend|hoverMarkers|hoverSpike|scatter|timeRangeXAxis|type:["bar"]',
            title           : 'Subscriptions',
            // filter out which traces to show in the thumbs
            thumbExtra      : 'show:[0]|type:["bar"]',
            hints           : {
                description     : 'How many unique subscribers do you have in the timespan?',
                absolute        : 'Total of unique subscribers',
                relative        : 'Total of unique subscribers relative to total users'
            }
        };

        sBag = new Model.Analytics.StatisticsBag(sAPIAccess.endpoint('analytics.byDomain.chartData').get(sDomainService.currentDomainId, 'users'));
        sBag.filters.createAndAdd('categories', ['gender'], true);
        charts.gender = {
            bag             : sBag,
            traceFactory    : 'scatter',
            template        : 'default|defaultColors|legend|hoverMarkers|hoverSpike|stackedBar|timeRangeXAxis|type:"bar"',
            thumbExtra      : 'type:"bar"',
            title           : 'Gender',
            hints           : {
                description     : 'How many users have which gender in the timespan?',
                absolute        : '%(layer)s users',
                relative        : 'Proportion of %(layer)s users to the total'
            }
        };

        sBag = new Model.Analytics.StatisticsBag(sAPIAccess.endpoint('analytics.byDomain.chartData').get(sDomainService.currentDomainId, 'users'));
        sBag.filters.createAndAdd('categories', ['locale'], true);
        charts.locale = {
            bag             : sBag,
            traceFactory    : 'localeDistribution',
            template        : 'default|defaultColors|hoverMarkers|hoverSpike|stackedBar|histogram',
            title           : 'Locale',
            hints           : {
                description     : 'How many users have which locale in the timespan?',
                absolute        : 'Users from Language %(layer)s',
                relative        : 'Proportion of language %(layer)s users to the total'
            }
        };

        sBag = new Model.Analytics.StatisticsBag(sAPIAccess.endpoint('analytics.byDomain.chartData').get(sDomainService.currentDomainId, 'users'));
        sBag.filters.createAndAdd('categories', ['timezone_offset'], true);
        charts.timezone = {
            bag             : sBag,
            traceFactory    : 'timezoneOffsetDistribution',
            template        : 'default|defaultColors|hoverMarkers|hoverSpike|stackedBar|histogram|autoTiltLabels',
            title           : 'Timezone',
            hints           : {
                description     : 'How many users have which Timezone offset in the timespan?',
                absolute        : 'Users from Timezone offset %(layer)s',
                relative        : 'Proportion of Timezone offset %(layer)s users to the total'
            }
        };

        return charts;
    };

    ns.Audience = Audience;
})(Object.namespace('sAnalytics.Route'));
