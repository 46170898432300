(function(ns) {
    var EVENT_BEFORE_MESSAGE_TYPE_MENU_OPEN = 'sEventBeforeMessageTypeMenuOpen';

    /**
     * @namespace
     * @alias Controller.Component.sMessageInputBox
     * @constructor
     *
     * @param $element
     * @param $scope
     * @param $injector
     */
    var sMessageInputBox = function($element, $scope, $injector) {
        var self = this
            ;
        /**
         * @property
         * @name Controller.Component.sMessageInputBox#onAddMessage
         * @type Object
         */

        this.types                  = Model.Message.Part.getTypes();
        /**
         * @property
         * @name Controller.Component.sMessageInputBox#$inputElement
         */
        this.$inputElement          = $element.find('textarea');
        this.$element               = $element;

        this.isFocused              = false;

        /**
         * @name Controller.Component.sMessageInputBox#$scope
         */
        this.$scope                 = $scope;

        /**
         * Used to display the menu of available types
         * @name Controller.Component.sMessageInputBox#menu
         */

        var $unSubscribeBeforeInit = $scope.$on('on-before-init', function(event, $element) {
            /** @type jQuery */
            self.$inputElement = $element;
            self.$inputElement.on('keydown', self.handleKeyDown.bind(self));
            $unSubscribeBeforeInit();
        });

        Object.defineProperty(
            this,
            'menu',
            {
                get: function() {
                    return this.botOnly ? $injector.get('sMenuBotTemplates') : $injector.get('sMenuSendAllTypes')
                }
            }
            /**
             * @property,
             * @readonly
             * @name Controller.Component.sMessageInputBox#menu
             * @type {Model.Menu.Menu}
             */
        );

        /**
         * @name Controller.Component.sMessageInputBox#botOnly
         * @type Boolean
         */
    };

    /**
     * @function
     * @name Controller.Component.sMessageInputBox#$onInit
     */
    sMessageInputBox.prototype.$onInit = function $onInit() {
        /**
         * @name Controller.Component.sMessageInputBox#isEnterAddingMessage
         */
        this.isEnterAddingMessage = (this.isEnterAddingMessage || true);
    };

    /**
     * @name Controller.Component.sMessageInputBox#$handleKeyDown
     */
    sMessageInputBox.prototype.handleKeyDown = function handleKeyDown(ev) {
        if (ev.which === Const.EnterKey && !ev.shiftKey && this.isEnterAddingMessage) {
            ev.preventDefault();
            ev.stopPropagation();
            ev.stopImmediatePropagation();
            this.addMessage();
        }
    };

    /**
     * @name Controller.Component.sMessageInputBox#$onDestroy
     */
    sMessageInputBox.prototype.$onDestroy = function $onDestroy() {
        this.$inputElement.off('keydown');
    };

    /**
     * @name Controller.Component.sMessageInputBox#$addMessage
     */
    sMessageInputBox.prototype.addMessage = function addMessage() {
        if (this.isAddingMessageAlready) {
            return;
        }
        this.isAddingMessageAlready = true;
        var self = this;
        if (this.content && this.content.length) {
            this.onAddMessage({'content': this.content});
        }

        setTimeout(function() {
            self.content = '';
            self.$inputElement.focus();
            self.isAddingMessageAlready = false;
            digestIfNeeded(self.$scope);
        }, 10);
    };

    /**
     * @name Controller.Component.sMessageInputBox#handleFocus
     */
    sMessageInputBox.prototype.handleFocus = function handleFocus(state) {
        if (state === undefined) {
            this.isFocused = true;
        } else {
            this.isFocused = Boolean(state);
        }
    };

    sMessageInputBox.prototype.$postLink = function $postLink() {
        var $transclude = this.$element.find('ng-transclude');
        $.each($transclude.children(), function(index, element) {
            $(element).insertBefore($transclude);
        });

        if (this.autoFocus) {
            this.$inputElement.focus();
        }
    };

    /**
     * Triggers an event before the message type menu is opened, if the event is prevented,
     * the menu will not open
     */
    sMessageInputBox.prototype.onBeforeMessageTypeMenuOpen = function onBeforeMessageTypeMenuOpen() {
        var $event = $.Event(EVENT_BEFORE_MESSAGE_TYPE_MENU_OPEN, {});
        $.event.trigger($event);

        this.mappedMessage = $event.context;

        return $event.isDefaultPrevented();
    };

    Object.defineProperties(
        sMessageInputBox,
        {
            EVENT_BEFORE_MESSAGE_TYPE_MENU_OPEN: {
                value: EVENT_BEFORE_MESSAGE_TYPE_MENU_OPEN
                /**
                 * @property
                 * @constant
                 * @name #EVENT_BEFORE_MESSAGE_TYPE_MENU_OPEN
                 * @type {String}
                 */
            }
        });

    ns.sMessageInputBox = sMessageInputBox;
})(Object.namespace('Controller.Component'));
