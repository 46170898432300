(function (ns) {
    /**
     * @namespace
     * @alias sSidebar.Provider.sSidebar
     */
    var sSidebarProvider = function sSidebarProvider() {
        var rules = [];

        this.$get = function $get($rootScope, $mdPanel) {
            return new sSidebar.Service.sSidebar($rootScope, rules, $mdPanel);
        };

        this.when = function (controllerType, describer) {
            if (Array.isArray(describer)) {
                for (var i = 0; i < describer.length; i++) {
                    rules.push({type : controllerType, details : describer[i]});
                }
            } else {
                rules.push({type : controllerType, details : describer});
            }

            return this;
        };
    };

    ns.sSidebar = sSidebarProvider;
})(Object.namespace('sSidebar.Provider'));
