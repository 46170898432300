(function (ns) {
    /**
     * @namespace
     * @alias Model.Event.Collection
     *
     * @constructor
     * @extends Model.Collection
     */
    var Collection = function () {
        Collection._pProto.constructor.call(this, Model.Event, 'event');
    };

    Object.extendProto(Collection, Model.Collection);

    Collection.prototype.toJSON = function toJSON() {
        return this.events;
    };

    ns.Collection = Collection;
})(Object.namespace('Model.Event'));