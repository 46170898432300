/**
 * Created by Gabor on 24-Oct-16.
 */

(function() {
    var sFollowPubDirective = angular.module('sFollowPubDirectives', [
        'mdReadOnlyChipModule',
        'sDndSortableDirective',
        'sEditAnywhereDirective',
        'mdAutocompleteGrouperAndItemDisabler',
        'mdAutocompleterLoadingSync'
    ]);

    sFollowPubDirective
        /**
         * @ngdoc directive
         * @name sAccordionBehavior
         *
         * @restrict A
         */
        .directive('sAccordionBehavior', function() {
            return {
                restrict: 'A',
                controller: Controller.Directive.sAccordionBehaviorController,
                controllerAs: '$accordionBehaviorCtrl',
                bindToController: {
                    defaultGroup : '<sAccordionBehavior'
                },
                scope: true
            }
        })
        /**
         * @ngdoc directive
         * @name sAccordionChangeListener
         *
         * @restrict A
         */
        .directive('sAccordionChangeListener', function($parse) {
            return {
                restrict: 'A',
                require: ['^sAccordionBehavior'],
                link: function($scope, $element, $attr) {

                    var expressionHandler = $parse($attr.sAccordionChangeListener);

                    if (!expressionHandler instanceof Function) {
                        return;
                    }

                    // initial call:
                    expressionHandler($scope, {accordionGroupIdent: $scope.$accordionBehaviorCtrl.activeId});

                    $scope.$on('$destroy', function() {
                        $unWatch();
                    });

                    var $unWatch = $scope.$watch(
                        function() {
                            return $scope.$accordionBehaviorCtrl.activeId;
                        },
                        function(newVal, oldVal) {
                            if (newVal !== oldVal) {
                                expressionHandler($scope, {accordionGroupIdent: $scope.$accordionBehaviorCtrl.activeId});
                            }
                        }
                    );
                }
            }
        })
        /**
         * @ngdoc directive
         * @name sAccordionHead
         *
         * @restrict A
         */
        .directive('sAccordionHead', function() {
            return {
                restrict: 'A',
                require: {
                    sAccordionBehaviorCtrl:'^sAccordionBehavior'
                },
                controller: Controller.Directive.Accordion.sAccordionHeadController,
                controllerAs: '$accordionHeadCtrl',
                bindToController: true
            }
        })
        /**
         * @ngdoc directive
         * @name sAccordionBody
         *
         * @restrict A
         * @param {Object} sAccordionBehavior
         */
        .directive('sAccordionBody', function () {
            return {
                restrict: 'A',
                require: {
                    sAccordionBehaviorCtrl: '^sAccordionBehavior'
                },
                controller: Controller.Directive.Accordion.sAccordionBodyController,
                bindToController: true
            }
        })
        /**
         * @ngdoc directive
         * @name sEmojiRenderer
         *
         * @restrict A
         * @element ANY
         * @param {Object} ngModel
         * @requires {Object} ngModel
         */
        .directive('sEmojiRenderer', function()  {
            return {
                restrict        : 'A',
                require         : {
                    ngModelCtrl : 'ngModel'
                },
                controller      : Controller.Directive.sEmojiRenderer,
                controllerAs    : '$emojiCtrl',
                bindToController: true,
                priority        : 0
            }
        })
        /**
         * @ngdoc directive
         * @name sEmojiPicker
         *
         * @restrict A
         * @element ANY
         * @param {Object} contenteditable
         * @param {Object} ngModel
         * @param {Controller.Directive.AutoCompleter} autocompleter
         * @param {Controller.Directive.sEmojiRenderer} sEmojiRenderer
         * @requires {Object} contenteditable
         * @requires {Object} ngModel
         * @requires {Controller.Directive.AutoCompleter} autocompleter
         * @requires {Controller.Directive.sEmojiRenderer} sEmojiRenderer
         */
        .directive('sEmojiPicker', function()  {
            return {
                restrict        : 'A',
                require         : {
                    ngModelCtrl         : 'ngModel',
                    autocompleterCtrl   : 'autocompleter'
                },
                controller      : Controller.Directive.sEmojiPickerWrapper,
                controllerAs    : '$emojiCtrl',
                bindToController: true,
                priority        : 0
            }
        })
        /**
         * @ngdoc directive
         * @name sSegmentInfo
         *
         * @restrict A
         * @element ANY
         * @param {Model.Segment=} sSegmentInfo
         */
        .directive('sSegmentInfo', function()  {
            return {
                restrict        : 'A',
                controller      : Controller.Directive.sSegmentInfo,
                scope: {
                    sSegmentInfo: '='
                }
            }
        })
        /**
         * @ngdoc directive
         * @name bindHtmlCompile
         *
         * @restrict A
         * @element ANY
         */
        .directive('bindHtmlCompile', ['$compile', function ($compile) {
            return {
                restrict: 'A',
                link    : function ($scope, $element, $attr) {

                    $scope.$on('$destroy', function() {
                        $deRegister();
                    });

                    var $deRegister = $scope.$watch(
                        function ($scope) {
                            return $scope.$eval($attr.bindHtmlCompile);
                        },
                        function (value) {
                            $element.html(value && value.toString());
                            $compile($element.contents())($scope);
                        }
                    );
                }
            }
        }])
        /**
         * @ngdoc directive
         * @name copyToClipboard
         *
         * @restrict A
         * @element ANY
         */
        .directive('copyToClipboard', ['$compile', function ($compile) {
            return {
                restrict: 'A',
                link    : function ($scope, $element, $attr) {
                    $element.click(function () {
                        if (!$attr.copyToClipboard) {
                            return;
                        }

                        var $tempInput = $("<input>");

                        $tempInput.css('display', 'hidden');
                        $("body").append($tempInput);
                        $tempInput.val($attr.copyToClipboard).select();
                        document.execCommand("copy");
                        $tempInput.remove();

                        var $tempTooltip = $("<md-tooltip " +
                            "md-visible=\"true\" " +
                            "md-autohide=\"false\" " +
                            "md-direction=\"top\">Copied to clipboard</md-tooltip>");

                        $element.append($tempTooltip);
                        $compile($tempTooltip)($scope);

                        setTimeout(function() {
                            $tempTooltip.remove();
                        }, 2000);
                    });
                }
            }
        }])
        /**
         * @ngdoc directive
         * @name contenteditable
         *
         * @restrict A
         * @element ANY
         * @param {Boolean=} allowMultiline
         * @param {Object} ngModel
         * @requires {Object} ngModel
         */
        .directive('contenteditable', function() {
            return {
                restrict: 'A',
                priority: 1,
                require: {
                    ngModelCtrl: 'ngModel'
                },
                bindToController: true,
                controller: Controller.Directive.ContentEditableController
            };
        })
        /**
         * @ngdoc directive
         * @name productModuleInfoDialog
         *
         * @restrict A
         * @element ANY
         */
        .directive('productModuleInfoDialog', ['$mdDialog', function ($mdDialog) {
            return {
                restrict : 'A',
                priority : 1,
                link     : function ($scope, $element) {
                    $element.bind('click', function () {
                        $mdDialog.show({
                            controller          : Controller.Directive.sProductModuleInfoDialog,
                            controllerAs        : '$ctrl',
                            templateUrl         : '_directive:s-product-module-info-dialog',
                            parent              : angular.element(document.body),
                            escapeToClose       : true,
                            clickOutsideToClose : true
                        });
                    });
                }
            };
        }])
        /**
         * Use search and replace in content editable fields
         *
         * @ngdoc directive
         * @name replaceFilters
         *
         * @restrict A
         * @element ANY
         * @requires {Object} ngModel
         */
        .directive('replaceFilters', function () {
            return {
                restrict : 'A',
                require  : {
                    $ngModelCtrl: '?ngModel'
                },
                bindToController    : {
                    replaceFilters : '=replaceFilters'
                },
                controller: Controller.Directive.ReplaceFilters
            };
        })
        .directive('autocompleter', function () {
            return {
                restrict: 'A',
                priority: 0,
                require: {
                    contentEditableCtrl: 'contenteditable',
                    ngModelCtrl: 'ngModel'
                },
                bindToController: true,
                controller: Controller.Directive.AutoCompleter
            }
        })
        /**
         * @ngdoc directive
         * @name matchTextHelper
         *
         * @restrict A
         * @param {Object} ngModel
         * @param {Object} contenteditable
         * @param {Object=} autocompleter
         * @param {Object=} hintPlaceholder
         */
        .directive('matchTextHelper', function () {
            return {
                restrict        : 'A',
                require         : {
                    contentEditableCtrl     : 'contenteditable',
                    ngModelCtrl             : 'ngModel',
                    autocompleterCtrl       : '?autocompleter',
                    hintPlaceholderCtrl     : '?hintPlaceholder',
                    matchTextCtrl           : '?^sMatchText',
                    matchTextCollectionCtrl : '?^sMatchTextCollection'
                },
                controller      : Controller.Directive.MatchTextHelper,
                controllerAs    : '$matchTextCtrl',
                bindToController: true,
                priority        : -1
            }
        })
        .directive('sMaxlength', function() {
            return {
                restrict    : 'A',
                require     : 'ngModel',
                link        : function($scope, $element, $attr, $ngModelCtrl) {
                    $ngModelCtrl.$validators.maxlength = function maxlength(modelValue) {
                        return Validator.maxLength(modelValue, $attr['sMaxlength']);
                    };
                }
            }
        })
        .directive('sMessageContentValidator', function () {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function ($scope, $element, $attr, $ngModelCtrl) {

                    var validator = function sMessageContentValidator(modelValue) {
                        return modelValue.validate();
                    };

                    $scope.$on('$destroy', function() {
                        $unWatch();
                    });

                    var $unWatch = $scope.$watch($attr.sMessageContentValidator, function(newValue){
                        if (newValue) {
                            $ngModelCtrl.$validators.sMessageContentValidator = validator;
                            $ngModelCtrl.$validate();
                        }
                        else {
                            delete $ngModelCtrl.$validators.sMessageContentValidator;
                            $ngModelCtrl.$setValidity('sMessageContentValidator', true);
                        }
                    });
                }
            }
        })
        .directive('sMenuValidator', function ($injector) {
            return {
                restrict: 'A',
                priority: 0,
                require: ['sMenuEdit', 'ngModel'],
                link: function ($scope, $element, $attr, $controllers) {
                    //noinspection JSUnresolvedVariable
                    if (!$attr.ngForm) {
                        $attr.$set('ngForm', 'menuEdit');
                        var directive = $injector.get('ngFormDirective')[0];
                        directive.compile($element, $attr);
                    }
                    $controllers[1].$validators.menuMaxLevelValidator = function (modelValue) {
                        if ((typeof $controllers[0].maxMenuDepth === 'undefined') || $controllers[0].maxMenuDepth < 0) {
                            return true;
                        }
                        var maxLevel = 0,
                            setMaxLevel = function (start, _level) {
                                Object.instanceOf(start, Model.Menu.Menu);
                                var level = _level || 0;
                                for (var i = 0; i < start.items.length; i++) {
                                    if (!(start.items[i] instanceof Model.Menu.Menu)) {
                                        continue;
                                    }
                                    level++;
                                    maxLevel = Math.max(maxLevel, level);
                                    setMaxLevel(start.items[i], level);
                                    level--;
                                }
                            }
                        ;
                        if (modelValue instanceof Model.Menu.Menu) {
                            setMaxLevel(modelValue);
                        }

                        return maxLevel <= $controllers[0].maxMenuDepth;
                    };
                    $controllers[1].$validators.emptyMenuValidator = function (modelValue) {
                        var empty = [],
                            findEmpty = function (start) {
                                Object.instanceOf(start, Model.Menu.Menu);

                                if (!start.items.length) {
                                    empty.push(start)
                                }

                                for (var i = 0; i < start.items.length; i++) {
                                    if (!(start.items[i] instanceof Model.Menu.Menu)) {
                                        continue;
                                    }

                                    findEmpty(start.items[i]);
                                }
                            };
                        if (modelValue instanceof Model.Menu.Menu) {
                            findEmpty(modelValue);
                        }

                        return empty.length === 0;
                    };
                    $controllers[1].$validators.menuLevelCountExceeded = function (modelValue) {
                        if (!$controllers[0].maxElements || !($controllers[0].maxElements instanceof Array)) {
                            return true;
                        }

                        var exceeding = [],
                            findExceedingSubMenus = function (start, _level) {
                                var level = _level || 0
                                ;

                                Object.instanceOf(start, Model.Menu.Menu);

                                if (start.items.length > $controllers[0].maxElements[level]) {
                                    exceeding.push({
                                        level: level,
                                        menu: start
                                    });
                                }

                                for (var i = 0; i < start.items.length; i++) {
                                    if (!(start.items[i] instanceof Model.Menu.Menu)) {
                                        continue;
                                    }

                                    findExceedingSubMenus(start.items[i], ++level);
                                }
                            };
                        if (modelValue instanceof Model.Menu.Menu) {
                            findExceedingSubMenus(modelValue);
                        }

                        return exceeding.length === 0;
                    }
                }
            }
        })
        .directive('sFormHelper', function() {
            return {
                restrict    : 'A',
                require     : 'form',
                priority    :  -1,
                link        : function($scope, $element, $attr, $ngFormCtrl) {
                    var $formCtrl = $ngFormCtrl;

                    while ($formCtrl.$$parentForm && $formCtrl.$$parentForm.$name !== undefined) {
                        $formCtrl = $formCtrl.$$parentForm;
                    }

                    $ngFormCtrl.$superForm = $formCtrl;
                }
            }
        })
        .directive('equals', function() {
            return {
                restrict: 'A',
                require : 'ngModel',
                link    : function($scope, $element, $attr, ngModelCtrl) {
                    var $deRegister = [],
                        validate = function() {
                            var val1 = ngModelCtrl.$modelValue,
                                val2 = $scope.$eval($attr.equals);
                            // set validity
                            ngModelCtrl.$setValidity('equals', ! val1 || ! val2 || val1 === val2);
                        }
                    ;

                    $scope.$on('$destroy', function() {
                        var $destroyFn;
                        while(($destroyFn = $deRegister.pop())) {
                            $destroyFn();
                        }
                    });

                    // watch own value and re-validate on change
                    $deRegister.push($scope.$watch($attr.ngModel, function() {
                        validate();
                    }));

                    // watch the other value and re-validate on change
                    $deRegister.push($scope.$watch($attr.equals, function () {
                        validate();
                    }));
                }
            };
        })
        .directive('requiredAny', function() {
            return {
                restrict    : 'A',
                priority    : -1,
                require     : ['^^form', '?ngModel'],
                link        : {
                    pre: function($scope, $element, $attr, $controllers) {
                        var $ngFormCtrl     = $controllers[0],
                            $ngModelCtrl    = $controllers[1],
                            $unWatchColl    ,
                            $deRegister     = [],
                            alreadyVisited  = {}
                        ;

                        var addOverrideModelOptionsIfNeeded = function($ngModelCtrl) {
                            if (!$ngModelCtrl.$overrideModelOptions) {
                                $ngModelCtrl.$overrideModelOptions = function (override) {
                                    this.$$setOptions($.extend({}, this.$options, override));
                                }.bind($ngModelCtrl);
                            }
                        };

                        if (!$attr['requiredAny']) {
                            return;
                        }

                        if ($ngFormCtrl.$superForm) {
                            $ngFormCtrl = $ngFormCtrl.$superForm;
                        }

                        var findNgModelInScope = function($scopeToSearch) {
                            alreadyVisited[$scopeToSearch] = alreadyVisited[$scopeToSearch] || 0;
                            if (++alreadyVisited[$scopeToSearch] > 5) {
                                throw 'Too much recursion!';
                            }

                            for (var i in $scopeToSearch) {
                                if ($scopeToSearch[i] instanceof Object
                                    && $scopeToSearch[i].hasOwnProperty('$$parentForm')
                                    && $scopeToSearch[i].hasOwnProperty('$superForm')
                                ) {
                                    return $scopeToSearch[i];
                                }
                            }

                            var foundFormCtrl,
                                $nextScope
                            ;

                            if ($scopeToSearch.$$childHead) {
                                if ((foundFormCtrl = findNgModelInScope($scopeToSearch.$$childHead))) {
                                    return foundFormCtrl;
                                }
                            }

                            while (($nextScope = $scopeToSearch.$$nextSibling)) {
                                if ((foundFormCtrl = findNgModelInScope($nextScope))) {
                                    return foundFormCtrl;
                                }
                            }
                            return false;
                        };

                        $scope.$on('$destroy', function() {
                            var $destroyFn;
                            while(($destroyFn = $deRegister.pop())) {
                                $destroyFn();
                            }
                        });

                        var attachValidator = function attachValidator() {
                            if (!$ngFormCtrl.$requiredAnyGroups) {
                                $ngFormCtrl.$requiredAnyGroups = {};
                            }

                            if (!$ngFormCtrl.$requiredAnyGroups[$attr['requiredAny']]) {
                                $ngFormCtrl.$requiredAnyGroups[$attr['requiredAny']] = [];
                            }

                            var group = $ngFormCtrl.$requiredAnyGroups[$attr['requiredAny']];

                            group.push($ngModelCtrl);

                            $ngModelCtrl.$validators.requiredAny = function () {
                                var anySet = 0,
                                    i
                                ;

                                for (i in group) {
                                    if (group[i].$$rawModelValue && Validator.required(group[i].$$rawModelValue)) {
                                        anySet += 1;
                                    }
                                }

                                for (i in group) {
                                    group[i].$setValidity('required-any', anySet !== 0);
                                }

                                return anySet.length !== 0;
                            };

                            $deRegister.push(($unWatchColl = $scope.$watchCollection(function () {
                                    return $ngModelCtrl.$$rawModelValue;
                                },
                                function (val) {
                                    if (val instanceof Model.sFile) {
                                        $unWatchColl();
                                        return;
                                    }

                                    $ngModelCtrl.$$parseAndValidate();
                                }))
                            );
                        };

                        if (!$ngModelCtrl) {
                            setTimeout(function() {
                                var $scopeWithForm = findNgModelInScope($scope);
                                for (var i in $scopeWithForm) {
                                    if (i.search(/^\$/) !== -1) {
                                        continue;
                                    }
                                    if ($scopeWithForm[i].hasOwnProperty('$$rawModelValue')) {
                                        $ngModelCtrl = $scopeWithForm[i];

                                        addOverrideModelOptionsIfNeeded($ngModelCtrl);
                                        $ngModelCtrl.$overrideModelOptions({allowInvalid: true, updateOnDefault: true});
                                        attachValidator();
                                    }
                                }
                            }, 1);
                        } else {
                            addOverrideModelOptionsIfNeeded($ngModelCtrl);
                            $ngModelCtrl.$overrideModelOptions({allowInvalid: true, updateOnDefault: true});
                            attachValidator();
                        }
                    }
                }
            }
        })
        .directive('mdChipCorrector', function() {
            return {
                restrict: 'A',
                require : ['mdChips', '?ngModel'],
                link    : function($scope, $element, $attr, $controllers) {
                    //noinspection JSPotentiallyInvalidConstructorUsage
                    var proto = $controllers[0].constructor.prototype;

                    proto.onInputBlur = function () {
                        var self = this;
                        setTimeout(function() {
                            self.inputHasFocus = false;

                            var chipBuffer = self.getChipBuffer().trim();

                            var isModelValid = true;

                            if (self.userInputNgModelCtrl) {
                                isModelValid &= self.userInputNgModelCtrl.$valid;
                            }

                            // Only append the chip and reset the chip buffer if the chips and input ngModel is valid.
                            if (self.addOnBlur && chipBuffer && !self.hasMaxChipsReached() && isModelValid) {
                                self.appendChip(chipBuffer);
                                self.resetChipBuffer();
                            }
                            digestIfNeeded($scope);
                        }, 1);
                    };
                }
            }
        })
        .directive('sMessageRepository', function() {
            return {
                restrict: 'A',
                scope: true,
                bindToController: {
                    repository: '=sMessageRepository'
                },
                controller: Controller.Directive.sMessageRepository
            }
        })
        .directive('sActionRepository', function() {
            return {
                restrict: 'A',
                scope: true,
                bindToController: {
                    repository: '=sActionRepository'
                },
                controller: function() {
                },
                controllerAs: 'sActionRepositoryCtrl'
            }
        })
        .directive('sActionContext', function() {
            return {
                restrict: 'A',
                scope: true,
                bindToController: {
                    context: '=sActionContext'
                },
                controller: Controller.Directive.sActionContext
            }
        })
        /**
         * @ngdoc directive
         * @name input
         *
         * @restrict E
         * @param {Object} ngModel
         */
        .directive('input', function(sAPIValidation) {
            return {
                restrict: 'E',
                require: '?ngModel',
                priority: -1, // default input directive has '1'
                link: {
                    pre: function ($scope, $element, $attrs, $controller) {
                        // skip if no ngModel
                        if (!$controller) {
                            return true;
                        }

                        if (!$attrs.type || !$attrs.type.toLowerCase) {
                            return;
                        }

                        switch ($attrs.type.toLowerCase()) {
                            case 'url':
                                // remove default url-validator
                                if ($controller.$validators.url) {
                                    delete $controller.$validators.url;
                                }
                                // add async url validator
                                $controller.$asyncValidators.url = function url(value) {
                                    return sAPIValidation.executeQueuedValidation(value, sAPIValidation.validateUrl.bind(sAPIValidation), 'url-' + $scope.$id, 250);
                                };
                                break;
                            case 'password':
                                var validatePassword = $scope.$eval($attrs.validatePassword);
                                if (!validatePassword) {
                                    break;
                                }
                                // remove default password-validator
                                if ($controller.$validators.password) {
                                    delete $controller.$validators.password;
                                }
                                // add async password validator
                                $controller.$asyncValidators.password = function password(value) {
                                    return sAPIValidation.executeQueuedValidation(value, sAPIValidation.validatePassword.bind(sAPIValidation), 'url-' + $scope.$id, 250);
                                };
                                break;
                        } // end switch
                    }
                }
            };
        })
        /**
         * Registers a pre-submit event
         *
         * @ngdoc directive
         * @name ngSubmit
         *
         * @restrict A
         */
        .directive('ngSubmit', function () {
            return {
                restrict: 'A',
                priority: -1, // default directive has '1'
                compile: function() {
                    return function ngEventHandler($scope, $element) {
                        var $deRegister = $element.$on('submit', function(event) {
                            $element.trigger('pre-submit', event);
                        });

                        $scope.$on('$destroy', function() {
                            var $destroyFn;
                            while (($destroyFn = $deRegister.pop())) {
                                $destroyFn();
                            }
                        });
                    };
                }
            };
        })
        .directive('sStickyHeader', function () {
            return {
                restrict: 'A',
                controller: Controller.Directive.sStickyHeader,
                controllerAs: 'sStickyHeaderCtrl',
                require: ['sStickyHeader'],
                bindToController: {
                    header: '<sStickyHeader'
                }
            }
        })
        .directive('sWatcherFreeze', function () {
            return {
                restrict: 'A',
                controller: function() {},
                bindToController: {
                    freeze: '=sWatcherFreeze'
                },
                require: 'sWatcherFreeze',
                link: function ($scope, $element, $attr, $controller) {
                    var $watchers = {},
                        $watcher,
                        frozen = false,
                        $scrollParent = $element.scrollParent(),
                        freezeWatchers = function ($scope) {
                            if (!$scope.$$watchers) {
                                return;
                            }
                            while (($watcher = $scope.$$watchers.shift())) {
                                $watchers[$scope.$id] = $watchers[$scope.$id] || [];
                                $watchers[$scope.$id].push($watcher);
                            }

                            var $childScope = $scope.$$childHead;
                            if (!$childScope) {
                                return;
                            }

                            freezeWatchers($childScope);

                            while (($childScope = $childScope.$$nextSibling)) {
                                freezeWatchers($childScope);
                            }

                            frozen = true;
                        },

                        unFreezeWatchers = function ($scope) {
                            if (!$watchers[$scope.$id]) {
                                return;
                            }

                            while (($watcher = $watchers[$scope.$id].shift())) {
                                $scope.$$watchers.push($watcher);
                            }
                            digestIfNeeded($scope);

                            var $childScope = $scope.$$childHead;
                            if (!$childScope) {
                                return;
                            }

                            unFreezeWatchers($childScope);

                            while (($childScope = $childScope.$$nextSibling)) {
                                unFreezeWatchers($childScope);
                            }

                            frozen = false;
                        },
                        isFrozen = function () {
                            return frozen;
                        }
                    ;

                    var $destroyFns = $scrollParent.$on('scroll', function () {
                        var parentRect = $scrollParent[0].getBoundingClientRect(),
                            rect = $element[0].getBoundingClientRect()
                        ;

                        if (!$controller.freeze) {
                            return;
                        }

                        if (isFrozen()) {
                            if (parentRect.top < rect.bottom && rect.top < parentRect.bottom) {
                                unFreezeWatchers($scope);
                            }

                        } else {
                            if (parentRect.bottom <= rect.top || rect.bottom <= parentRect.top) {
                                freezeWatchers($scope);
                            }
                        }
                    });

                    $scope.$on('$destroy', function () {
                        var destroyFn;
                        while ((destroyFn = $destroyFns.pop())) {
                            destroyFn();
                        }
                    });
                }
            }
        })
        /**
         * @ngdoc directive
         * @name mdNavBarCustomized
         * @description Fixes the width for updated tabs
         *
         * @restrict A
         * @param {Object} mdNavBar
         */
        .directive('mdNavBarCustomized', function() {
            return {
                restrict: 'A',
                controller: function mdNavBarCustomized() {}, // without controller it can not be required
                require: 'mdNavBar',
                link: {
                    post: function($scope, $element, $attrs, $controller) {
                        var $tabWatcher;

                        $scope.$on('$destroy', function() {
                            $tabWatcher();
                        });

                        $tabWatcher = $scope.$watch(
                            function() {
                                if ($controller._getSelectedTab() && $controller._getSelectedTab().getButtonEl()) {
                                    return $controller._getSelectedTab().getButtonEl().offsetWidth  + '-'  +
                                            // also watch the "left" position
                                        $controller._getSelectedTab().getButtonEl().offsetLeft;
                                }
                                return false;
                            },
                            function (oldOffset, newOffset) {
                                if (oldOffset === newOffset) {
                                    return;
                                }
                                var tabs = $controller._getTabs(),
                                    selectedTab = $controller._getSelectedTab(),
                                    index = tabs.indexOf(selectedTab);
                                $controller._updateInkBarStyles(selectedTab, index, index)
                            }
                        );
                    }
                }
            }
        })
        /**
         * @ngdoc directive
         * @name mdNavItem
         * @description Workaround for click-event by material directive which destroys the flow
         *
         * @restrict E
         * @param {Object} mdNavItem
         * @param {Object} mdNavBar
         */
        .directive('mdNavItem', function($$rAF) {
            return {
                restrict: 'E',
                require: ['mdNavItem', '^^mdNavBar', '^^?mdNavBarCustomized'],
                priority: 1,
                link: function($scope, $element, $attrs, $controllers) {
                    // just do the fix for navBar in mdNavBarCustomized
                    if (!$controllers[2]) {
                        return;
                    }

                    $$rAF(function() {
                        var navButton = angular.element($element[0].querySelector('._md-nav-button'));
                        if (!navButton.length) {
                            return;
                        }
                        var events = $._data(navButton[0], 'events');
                        // last element should be in the mdNavBarCustomized case always the wanted one
                        // @see also: https://github.com/angular/material/blob/v1.1.4/src/components/navBar/navBar.js#L468
                        events.click.pop();
                    });
                }
            }
        })
        /**
         * @ngdoc directive
         * @name sSidenavContent
         * @description Content wrapper per templateUrl for mdSidenav
         *
         * @restrict A
         */
        .directive('sSidenavContent', function($compile, $templateCache) {
            return {
                restrict: 'A',
                require: ['mdSidenav'],
                link: function($scope, $element, $attrs, $controllers) {
                    var controller = $controllers[0],
                        templateUrl,
                        $rScope,
                        needsReRender = false,
                        renderingTo,
                        reRender = function reRender() {
                            if (!controller.isOpen()) {
                                needsReRender = true;
                            }

                            var contents = $templateCache.get(templateUrl);
                            $element.html('');
                            if (!contents) {
                                return;
                            }
                            var tmp = $compile(angular.element(contents))($rScope);
                            $element.append(tmp);
                        },
                        scheduleRendering = function scheduleRendering(delay) {
                            delay = delay || 1;
                            if (renderingTo) {
                                clearTimeout(renderingTo);
                            }

                            renderingTo = setTimeout(function() {
                                $rScope.$evalAsync(reRender);
                            }, delay);
                        }
                        ;

                    Object.defineProperties(
                        controller,
                        {
                            'templateUrl' : {
                                get: function() {
                                    return templateUrl;
                                },
                                set: function(val) {
                                    if (val === templateUrl) {
                                        return;
                                    }

                                    templateUrl = val;
                                    scheduleRendering();
                                }
                            },
                            '$rScope' : {
                                get: function() {
                                    return $rScope;
                                },
                                set: function(val) {
                                    if ($rScope && val === $rScope.$parent) {
                                        return;
                                    }

                                    if ($rScope) {
                                        $rScope.$destroy();
                                    }
                                    $rScope = val.$new();
                                    scheduleRendering();
                                }
                            }
                        }
                    );

                    var originalOpen = controller.open;
                    controller.open = function() {
                        return originalOpen.call(controller, arguments).then(function() {
                            if (needsReRender) {
                                $rScope.$evalAsync(reRender)
                            }
                        });
                    };

                    controller.$rScope = $scope;
                }
            };
        })
        /**
         * @ngdoc directive
         * @name sInputTemplateValid
         * @description
         *
         * @restrict A
         */
        .directive('sInputTemplateValid', function($filter) {
            return {
                restrict    : 'A',
                require     : 'ngModel',
                link        : function($scope, $element, $attr, $ngModelCtrl) {
                    $ngModelCtrl.$validators.doesMatchAll = function doesMatchAll(modelValue) {
                        var groups = [];
                        Model.AI.EntityCollection.dontRegister = true;
                        groups = Model.AI.MatchText.tokenize(modelValue || '');
                        Model.AI.EntityCollection.dontRegister = false;

                        if (!groups.length) {
                            return false;
                        }

                        return !groups.reduce(function(carry, element) {
                            if (!element.toString() || !carry) {
                                return carry;
                            }

                            carry = carry && (element.optional || element.matchAll || (
                                element.isEntity && element.entity && element.entity.typeOptions && element.entity.typeOptions.isEmpty()
                            ));
                            return carry;
                        }, true);
                    };
                    $ngModelCtrl.$validators.emptyAlternative = function emptyAlternative(modelValue) {
                        var groups = [];
                        Model.AI.EntityCollection.dontRegister = true;
                        $filter('matchTextTokenize')(modelValue, groups);
                        Model.AI.EntityCollection.dontRegister = false;

                        return !groups.reduce(function(carry, matchGroup) {
                            if (carry) {
                                // already failed
                                return carry;
                            }
                            if (matchGroup.length <= 1) {
                                return carry;
                            }
                            return matchGroup.elements.reduce(function(inCarry, matchText) {
                                    if (inCarry) {
                                        // already failed
                                        return inCarry;
                                    }

                                    inCarry = matchText.value.trim().replace(Controller.Directive.ContentEditableController.SELECTION_MARKERS_REGEXP, '').length === 0 && (matchGroup.optional || matchGroup.important);
                                    return inCarry;
                                },
                                carry);
                        }, false);
                    };
                    $ngModelCtrl.$validators.invalidEntity = function invalidEntity(modelValue, viewValue) {
                        var groups = [];
                        Model.AI.EntityCollection.dontRegister = true;
                        groups = Model.AI.MatchText.tokenize(modelValue || '');
                        Model.AI.EntityCollection.dontRegister = false;

                        if (!groups.length) {
                            return false;
                        }

                        return groups.reduce(function(carry, element) {
                            if (!element.isEntity || !carry) {
                                return carry;
                            }

                            carry = carry && (element.isEntity && element.entity && element.entity.name.length);
                            return carry;
                        }, true);
                    }
                }
            }
        })
        /**
         * @ngdoc directive
         * @name sButtonIndicator
         * @description
         *
         * @restrict A
         */
        .directive('sButtonIndicator', function($parse, $compile) {
            var PRIORITY    = 650, // must be before ng-if (600), but after ng-repeat (1000)
                KEY_MANUAL  = 'manual',
                OWNER_SCOPE = 'scope',
                OWNER_CTRL  = 'ctrl'
            ;

            return {
                restrict     : 'A',
                priority     : PRIORITY,
                bindToController: {
                    $busy: '=sButtonIndicator'
                },
                terminal     : true,
                scope        : true,
                /**
                 * This is an inline controller to have a new non isolated scope that also has two-way data binding
                 * It is a workaround and therefore hidden in here to never get modified
                 * Contains a nasty double watch to create two way data bind between $ctrl.$busy and $scope.$busy
                 * @param $scope
                 */
                controller   : function ($scope) {
                    var $deRegister = [],
                        initiator   = null
                    ;

                    this.digest = function () {
                        digestIfNeeded($scope);
                    };

                    this.$onInit = function () {
                        $deRegister.push($scope.$watch(function () {
                                return this.$busy;
                            }.bind(this),
                            function (value) {
                                if (initiator === OWNER_SCOPE) {
                                    initiator = null;
                                    return;
                                }

                                if (value === KEY_MANUAL) {
                                    return;
                                }
                                initiator = OWNER_CTRL;
                                $scope.$busy = value;
                            }.bind(this))
                        );

                        $deRegister.push($scope.$watch(function () {
                                return $scope.$busy;
                            },
                            function (value) {
                                if (initiator === OWNER_CTRL) {
                                    initiator = null;
                                    return;
                                }


                                if (value === KEY_MANUAL) {
                                    return;
                                }
                                initiator = OWNER_SCOPE;
                                this.$busy = value;
                            }.bind(this))
                        );
                    }.bind(this);

                    this.$onDestroy = function $onDestroy() {
                        var $destroyFn;
                        while (($destroyFn = $deRegister.pop())) {
                            $destroyFn();
                        }
                    };
                },
                compile: function($element, attr) {
                    var fn = $parse(attr['ngClick'], null, true);
                    delete(attr['ngClick']);
                    return function ngEventHandler(scope, element, attr) {
                        var scopeId = scope.$id;
                        element.removeAttr('ng-click');

                        scope['sButtonIndicatorClickHelper' + scopeId] = (function(scope, fn, attr) {
                            return function (event) {
                                element = $(event.currentTarget);
                                var callback = function() {
                                    var res = fn(scope.$parent, {$event:event}),
                                        alwaysHandler = function() {
                                            if (attr['sButtonIndicator'] !== KEY_MANUAL) {
                                                element.removeAttr('disabled');
                                            }
                                            scope.$busy = false;
                                            digestIfNeeded(scope);
                                        }
                                    ;

                                    if (!res || !res.then) {
                                        return res;
                                    }
                                    if (attr['sButtonIndicator'] !== KEY_MANUAL) {
                                        element.attr('disabled', true);
                                    }
                                    scope.$busy = true;

                                    if (res.always) {
                                        res.always(alwaysHandler);
                                    } else if (res.finally) {
                                        res.finally(alwaysHandler);
                                    }
                                };
                                scope.$evalAsync(callback);
                            }
                        })(scope, fn, attr);

                        element.attr('ng-click', 'sButtonIndicatorClickHelper' + scopeId + '($event)');
                        element = $compile(element, null, PRIORITY)(scope);
                    };
                }
            }
        })
        /**
         * @ngdoc directive
         * @name sAjaxSubmit
         * @description
         *
         * @param {Array} sAjaxSubmit Array containing ajax handlers
         * @restrict A
         */
        .directive('sAjaxSubmit', function() {
            return {
                restrict: 'A',
                scope: {
                    'sAjaxSubmit'   : '='
                },
                link: {
                    pre: function (scope, $element, attr) {
                        if ($element[0].nodeName !== 'FORM') {
                            throw 'Invalid element, must be defined on form!';
                        }
                        scope.$parent.$$loading = null;

                        var $form = $($element),
                            fn;

                        $form.$on('submit', function(event) {
                            event.preventDefault();

                            if (scope.$parent.$$loading) {
                                return scope.$parent.$$loading;
                            }

                            if (scope.sAjaxSubmit.length > 2 && (fn = scope.sAjaxSubmit.slice(2,3).pop()) && fn instanceof Function) {
                                fn();
                            }
                            scope.$parent.$$loading = $.ajax({
                                url: attr.action,
                                method: attr.method,
                                data: $form.serialize()
                            }).always(function() {
                                scope.$parent.$$loading = null;
                                digestIfNeeded(scope.$parent);
                            });

                            scope.$parent.$$loading.then.apply(scope.$parent, (scope.sAjaxSubmit || []).slice(0,2));
                            digestIfNeeded(scope);
                        });
                    }
                }
            }
        })
        /**
         * @ngdoc directive
         * @name sLengthCounter
         * @param {Object} sLengthCounter
         * @description
         *
         * @restrict A
         */
        .directive('sLengthCounter', function($compile, $templateCache) {
            return {
                restrict: 'A',
                require: 'sLengthCounter',
                controller: function() {},
                bindToController: {
                    options: '=sLengthCounter'
                },
                controllerAs: '$lengthCtrl',
                link: function($scope, $element, $attr, $controller) {
                    var contents        = $templateCache.get('_directive:s_length_counter')
                        ;

                    if (!contents) {
                        return;
                    }

                    if (typeof($controller.options.max) === 'string') {
                        $controller.max = $attr[$controller.options.max];
                    } else {
                        $controller.max = $controller.options.max;
                    }

                    $element.after($compile(angular.element(contents))($scope));
                }
            }
        })
        /**
         * @ngdoc directive
         * @name sFormWrapper
         * @param {Object} sFormWrapper
         * @description
         *
         * @restrict A
         */
        .directive('sFormWrapper', function($templateCache, $injector, $compile) {
            return {
                restrict        : 'A',
                priority        : 2000,
                bindToController: {
                    options: '=sFormWrapper'
                },
                scope           : true,
                controllerAs    : '$formWrapperCtrl',
                controller      : function() {
                    var self = this;

                    this.$onInit = function $onInit() {
                        self.options    = self.options || {};
                        self.class      = self.options.class;
                        self.errorSrc   = self.options.errorTemplate;
                        self.hint       = self.options.hint;
                    };
                },
                terminal        : true,
                compile: function() {
                    var newElementHtml = $templateCache.get('_directive:s_form_wrapper');

                    return {
                        pre: function($scope, $el, $attr, $controller) {
                            var cacheId, html, $newElement;

                            if (!$controller.exchanged) {
                                $el.removeAttr('s-form-wrapper');
                                html = $el[0].outerHTML;
                                $newElement = $(newElementHtml);

                                do {
                                    cacheId = 'wrapped_' + Date.now() + '_' + Math.floor(Math.random() * 10000);
                                } while ($templateCache.get($controller.cacheId));

                                $controller.cacheId = cacheId;
                                $controller.exchanged = true;
                                $el.replaceWith($newElement);
                            }
                            $templateCache.put($controller.cacheId, html);
                            $compile($newElement, null, 2000)($scope);
                            $scope.$on('$destroy', function() {
                                $templateCache.remove(cacheId);
                            });
                        }
                    }
                }
            }
        })
        /**
         * Transforms days(duration) [view] to seconds [model] and back.
         * @ngdoc directive
         * @name sDaysTransformer
         * @description
         *
         * @restrict A
         */
        .directive('sDaysTransformer', function() {
            return {
                restrict : 'A',
                require: 'ngModel',
                link: function($scope, $element, $attr, $ngModelController) {
                    var pos = -1;
                    $ngModelController.$parsers.unshift(function(viewValue) {
                        // Stores the position of the caret, needed to retain position after transformation
                        pos = viewValue.search(Controller.Directive.ContentEditableController.UNICODE_SELECTION_START_PLACEHOLDER);

                        var parsed = parseInt(viewValue);

                        if (isNaN(parsed)) {
                            return viewValue;
                        }

                        return parsed * 86400;
                    });

                    $ngModelController.$formatters.unshift(function(modelValue) {
                        if (!modelValue) {
                            return modelValue;
                        }

                        var parsed = parseInt(modelValue);

                        if (isNaN(parsed)) {
                            return modelValue;
                        }

                        var formattedValue = String(Math.round(parsed / 86400));

                        if (pos > -1) {
                            formattedValue = formattedValue.split('');
                            formattedValue.splice(pos, 0, Controller.Directive.ContentEditableController.UNICODE_SELECTION_START_PLACEHOLDER);
                            formattedValue = formattedValue.join('');
                        }
                        return formattedValue;
                    });
                }
            }
        })
        /**
         * Transforms hours(duration) [view] to seconds [model] and back.
         * @ngdoc directive
         * @name sHoursTransformer
         * @description
         *
         * @restrict A
         */
        .directive('sHoursTransformer', function() {
            return {
                restrict : 'A',
                require: 'ngModel',
                link: function($scope, $element, $attr, $ngModelController) {
                    var pos = -1;
                    $ngModelController.$parsers.unshift(function(viewValue) {
                        // Stores the position of the caret, needed to retain position after transformation
                        pos = viewValue.search(Controller.Directive.ContentEditableController.UNICODE_SELECTION_START_PLACEHOLDER);

                        var parsed = parseInt(viewValue);

                        if (isNaN(parsed)) {
                            return viewValue;
                        }

                        return parsed * 3600;
                    });

                    $ngModelController.$formatters.unshift(function(modelValue) {
                        if (!modelValue) {
                            return modelValue;
                        }

                        var parsed = parseInt(modelValue);

                        if (isNaN(parsed)) {
                            return modelValue;
                        }

                        var formattedValue = String(Math.round(parsed / 3600));

                        if (pos > -1) {
                            formattedValue = formattedValue.split('');
                            formattedValue.splice(pos, 0, Controller.Directive.ContentEditableController.UNICODE_SELECTION_START_PLACEHOLDER);
                            formattedValue = formattedValue.join('');
                        }
                        return formattedValue;
                    });
                }
            }
        })
        /**
         * Transforms hours:minutes [view] to seconds [model] and back.
         * @ngdoc directive
         * @name sTimeTransformer
         * @description
         *
         * @restrict A
         */
        .directive('sTimeTransformer', function() {
            return {
                restrict         : 'A',
                require          : {
                    $ngModelController : 'ngModel'
                },
                controller       : Controller.Directive.sTimeTransformer,
                bindToController : true
            }
        })
        /**
         * @ngdoc directive
         * @name sValidateUrl
         * @description
         *
         * @restrict A
         */
        .directive('sValidateUrl', function (sAPIValidation) {
            return {
                restrict : 'A',
                require  : 'ngModel',
                scope    : {
                    sValidateUrl : '=sValidateUrl'
                },
                link     : function ($scope, $element, $attr, $ngModelCtrl) {
                    if ($scope.sValidateUrl === undefined || $scope.sValidateUrl === true) {
                        $ngModelCtrl.$asyncValidators.url = function url(value) {
                            if (!value) {
                                return $.Deferred().reject();
                            }

                            return sAPIValidation.executeQueuedValidation(value, sAPIValidation.validateUrl.bind(sAPIValidation), 'url-' + $scope.$id, 500);
                        };
                    }
                }
            };
        })
        /**
         * @ngdoc directive
         * @name sValidJson
         * @description
         *
         * @restrict A
         */
        .directive('sValidJson', function() {
            return {
                restrict: 'A',
                require : 'ngModel',
                link: function($scope, $element, $attr, $ngModelCtrl) {
                    $ngModelCtrl.$validators.validJson = function validJson(value) {
                        try {
                            JSON.parse(value);
                        } catch (error) {
                            return false;
                        }
                        return true;
                    };
                }
            }
        })
        /**
         * @ngdoc directive
         * @name sIconWithTooltip
         *
         * @restrict A
         * @element ANY
         */
        .directive('sIconWithTooltip', function ($compile, $templateCache) {
            return {
                restrict         : 'A',
                controller       : function () {
                },
                bindToController : {
                    options : '=sIconWithTooltip'
                },
                controllerAs     : '$sIconWithTooltipCtrl',
                link             : function ($scope, $element) {
                    var contents = $templateCache.get('_directive:s-icon-with-tooltip');

                    if (!contents) {
                        return;
                    }

                    $element.after($compile(angular.element(contents))($scope));
                }
            };
        })
        /**
         * @ngdoc directive
         * @name mdAutocompleterLoadingHelper
         *
         * @restrict A
         * @element md-autocomplete
         * @deprecated Need to Check if this is used at all
         */
        .directive('mdAutocompleterLoadingHelper', function() {
            return {
                restrict:       'A',
                require:        'mdAutocomplete',
                link: function ($scope, $element, $attrs, mdAutocompleteCtrl) {
                    if (!mdAutocompleteCtrl.scope.selectedItem && mdAutocompleteCtrl.scope.searchText) {
                        mdAutocompleteCtrl.loading = true;
                    }
                }
            }
        })
        /**
         * @ngdoc directive
         * @name mdChipsPanelEdit
         *
         * @restrict A
         * @element md-chips
         */
        .directive('mdChipsPanelEdit', function() {
            return {
                restrict: 'A',
                require :   {
                    mdChipsCtrl: 'mdChips',
                    ngModelCtrl: 'ngModel'
                },
                controller: Controller.Directive.mdChipsPanelEdit,
                bindToController: {
                    config: '<mdChipsPanelEdit'
                }
            }
        })
        /**
         * Transforms a JSON string into an object, that is exposed to the current scope as `$json`.
         * It is bi-directional so updates to `$json` will be reflected on the model.
         * @ngdoc directive
         * @name sJsonParser
         *
         * @param {String} sJsonParser The JSON string to parse
         * @restrict A
         */
        .directive('sJsonParser', function() {
            return {
                restrict : 'A',
                controller: function($scope) {
                    var $deRegister = [];

                    this.$onInit = function $onInit() {
                        var self = this;
                        $deRegister.push(
                            $scope.$watch(
                                function() {
                                    return JSON.stringify($scope.$json);
                                },
                                function(val, prevVal) {
                                    if (prevVal === val) {
                                        return;
                                    }
                                    self.json = val;
                                }
                            )
                        );

                        $deRegister.push(
                            $scope.$watch(
                                function() {
                                    return self.json;
                                },
                                function(val) {
                                    if (val === null) {
                                        val = JSON.stringify({});
                                    }
                                    if (val === JSON.stringify($scope.$json)) {
                                        return;
                                    }

                                    $scope.$json = JSON.parse(val);
                                }
                            )
                        );
                    };

                    this.$onDestroy = function $onDestroy() {
                        var $destroyFn;
                        while (($destroyFn = $deRegister.pop())) {
                            $destroyFn();
                        }
                    };

                },
                bindToController: {
                    json: '=sJsonParser'
                }
            }
        })
    ;
})(window);
