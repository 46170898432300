(function(ns) {
    /**
     * @namespace
     * @alias Model.User.Permission
     *
     * @extends Model.RESTAccessByUUID
     */
    var Permission = function (uuid) {
        var feature,
            associatedPermissions       = [],
            associatedPermissionsCache  = [],
            refreshAssociatedPermissionsCache = function refreshAssociatedPermissionsCache() {
                associatedPermissionsCache.splice.apply(
                    associatedPermissionsCache,
                    [0, associatedPermissionsCache.length].concat(associatedPermissions));
            }
        ;

        Object.defineProperties(
            this,
            {
                'feature' : {
                    enumerable: true,
                    get: function() {
                        return feature;
                    },
                    set: function(val) {
                        if (val) {
                            Object.instanceOf(val, Model.Feature);
                        }

                        var oldVal;

                        if (val !== feature) {
                            oldVal = feature;
                            feature = val;
                            if (feature) {
                                feature.addPermission(this);
                            } else {
                                oldVal.removePermission(this);
                            }
                        }
                    }
                },
                'ident' : {
                    enumerable: true,
                    writable: true
                },
                'name' : {
                    enumerable: true,
                    writable: true
                },
                'associatedPermissions': {
                    get: function() {
                        return associatedPermissionsCache;
                    }
                }
            }
        );

        /**
         * @method
         * @name Model.User.Permission#associatePermission
         * @param {Model.User.Permission} permission
         * @param {String} type
         * @returns {Model.User.Permission}
         */
        this.associatePermission = function associatePermission(permission, type) {
            Object.instanceOf(permission, Permission);

            if (!type) {
                throw 'Missing argument (type)!'
            }
            if (!associatedPermissions.filter(function(associatedPermission) {
                    return associatedPermission.type === type && associatedPermission.permission.ident === permission.ident;
                }).length) {
                associatedPermissions.push({
                    type: type,
                    permission: permission
                });
                // counter types are marked with -type, double countering should remove the '-' so '--type' === 'type'
                permission.associatePermission(this, (type.substr(0, 1) === '-' ? type.substr(1) : ('-' + type)));
                refreshAssociatedPermissionsCache();
            }

            return this;
        };

        /**
         * @property {String}
         * @name Model.User.Permission#function
         */
        this.function = '';
        /**
         * @property {String}
         * @name Model.User.Permission#right
         */
        this.right = '';

        /**
         * @property {Object}
         * @name Model.User.Permission#_pProto
         */
        var protecteds = Permission._pProto.constructor.call(this, uuid);
        protecteds.registerRelationHandler(this.endPoint, function(data) {
            if (!data.feature) {
                return;
            }

            var featureEndPoint = new Model.Feature().endPoint;

            var obj = {};
            obj.uuid = featureEndPoint.uuid;
            obj.endPoint = featureEndPoint;
            return [obj];
        });


        /**
         * @property
         * @name Model.User.Permission#associatedPermissions
         * @type {Object}
         */

        /**
         * @property
         * @name Model.User.Permission#ident
         * @type {String}
         */

        /**
         * @property
         * @name Model.User.Permission#name
         * @type {String}
         */
    };

    Object.extendProto(Permission, Model.RESTAccessByUUID);

    /**
     * Creates an permission by data
     * @function Model.User.Permission#createByData
     * @param {object} data
     * @static
     * @returns Model.User.Permission
     */
    Permission.createByData = function createByData(data) {
        var uuid,
            permission;

        if (data && data.hasOwnProperty('uuid')) {
            uuid = data.uuid;
        }

        permission = new Permission(uuid);

        permission.updateByData(data);

        // create a function to resolve dependencies
        if (data.associatedPermissions && data.associatedPermissions.length) {
            permission.resolveAssociatedPermissions = function(permissions) {
                var self                = this,
                    permissionsByUuid   = permissions.reduce(function(carry, permissionInCollection) {
                    carry[permissionInCollection.uuid] = permissionInCollection;
                    return carry;
                }, {});
                data.associatedPermissions.map(function(assocPerm, key) {
                    if (permissionsByUuid[assocPerm.permissionUuid]) {
                        self.associatePermission(permissionsByUuid[assocPerm.permissionUuid], assocPerm.type);
                        data.associatedPermissions.splice(key, 1);
                    }
                });

                // self destruct
                if (!data.associatedPermissions.length) {
                    delete this.resolveAssociatedPermissions;
                }
            }
        }

        return permission;
    };

    /**
     * Updates the permission by data
     * @function Model.User.Permission#updateByData
     * @param {object} data
     */
    Permission.prototype.updateByData = function updateByData(data) {
        if (data.feature) {
            this.feature = Model.Feature.createByData(data.feature);
        }

        Object.updateByData(this, data);

        this.name = this.name || this.ident;

        Permission._pProto.updateByData.apply(this, data);
    };

    Permission.query = function query() {
        return new Model.Collection.RESTAccess(Permission).load().then(function(collection) {
            return collection.items;
        }).then(function(permissions) {
            var features = {}
                ;

            // group it under the same feature instance (createByData returns always a new instance ...)
            permissions.map(function(permission) {
                if (!features[permission.feature.ident]) {
                    features[permission.feature.ident] = permission.feature;
                } else {
                    permission.feature = features[permission.feature.ident];
                }

                if (permission.resolveAssociatedPermissions) {
                    permission.resolveAssociatedPermissions(permissions);
                }
            });

            return permissions
        });
    };

    Permission.CONST_REQUIRES = 'requires';

    ns.Permission = Permission;
})(Object.namespace('Model.User'));