(function (ns) {
    /**
     * @namespace
     * @alias Controller.Directive.MdChipsPanelEdit
     *
     * @constructor
     * @param $element
     * @param $scope
     * @param {Service.sFormPanel} sFormPanel
     */
    var mdChipsPanelEdit = function ($element, $scope, sFormPanel) {
        this.$element       = $element;
        this.$scope         = $scope;
        this.sFormPanel     = sFormPanel;

        this.$deRegister    = [];

        this.isOpen         = null;
    };

    mdChipsPanelEdit.prototype.$postLink = function $postLink() {
        var self = this,
            $lastQuery
        ;

        var $unWatch = this.$scope.$watch(
            function() {
                var $currentQuery = self.$element.find('input');

                // trick angular's watcher to use the previous jQuery object in case the length has not changed,
                // to avoid infinite digest loop
                if ($lastQuery && $lastQuery.length === $currentQuery.length) {
                    return $lastQuery;
                }

                $lastQuery = $currentQuery;
                return $currentQuery
            },
            /**
             * @param {jQuery|Boolean} val
             */
            function(val) {
                if (val === false || !val.length) {
                    return;
                }

                self.setup(val);
                $unWatch();
            }
        );

        this.$scope.$on('$destroy', this.$onDestroy.bind(this));
    };

    /**
     * @param $inputEl
     */
    mdChipsPanelEdit.prototype.setup = function setup($inputEl) {
        var self = this;
        if ($inputEl.attr('type') !== 'button') {
            $inputEl.attr('type', 'button');
            $inputEl.attr('value', 'Add new');
        }

        // prevent the remove button from triggering the panel open
        this.$deRegister = this.$deRegister.concat(this.$element.$on('click', '.md-chip-remove', function($event) {
            $event.stopImmediatePropagation();
            $event.stopPropagation();
        }));
        this.$deRegister = this.$deRegister.concat($inputEl.$on('click', this.handleOpenPanel.bind(this)));
        this.$deRegister = this.$deRegister.concat(this.$element.$on('click', 'md-chip', {existing: true}, this.handleOpenPanel.bind(this)));
        this.$deRegister.push(function() {
            self.tearDown($inputEl);
        });

        if (!this.config.new || !(this.config.new instanceof Function)) {
            throw "Missing config entry 'new', don't know how to create a new model for editing!";
        }

        if (!this.config.rules) {
            throw "Required rules missing, don't know how to create form!";
        }

        if (!this.config.callback) {
            throw "Missing callback to handle submitted values!";
        }
    };

    /**
     * @param $event
     */
    mdChipsPanelEdit.prototype.handleOpenPanel = function handleOpenPanel($event) {
        $event.preventDefault();
        $event.stopPropagation();

        var model;

        if ($event.data && $event.data.existing) {
            var index = $($event.currentTarget).prevAll('md-chip').length;
            model = this.ngModelCtrl.$modelValue[index];
        } else {
            model = this.config.new();
        }

        this.sFormPanel.open($event, model, this.config.rules).then(this.config.callback.bind(this));
    };

    /**
     * @param $inputEl
     */
    mdChipsPanelEdit.prototype.tearDown = function tearDown($inputEl) {
        $inputEl.removeAttr('type').removeAttr('value');
    };

    /**
     * @function
     * @name Controller.Directive.MdChipsPanelEdit#$onDestroy
     */
    mdChipsPanelEdit.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    ns.mdChipsPanelEdit = mdChipsPanelEdit;
})(Object.namespace('Controller.Directive'));
