(function () {
    var settings = angular.module('sSettings', [
            'sFeatureManagementModule',
            'sDomainModule'
        ]);

    settings.config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/settings/platform/facebook-messenger', new sSettings.Route.Platform.FacebookMessenger())
            .when('/settings/platform/instagram-direct', new sSettings.Route.Platform.InstagramDirect())
            .when('/settings/configuration/general', new sSettings.Route.BotConfiguration.General())
            .when('/settings/configuration/default-reactions', new sSettings.Route.DefaultReactions())
            .when('/settings/user_management', new sSettings.Route.UserManagement())
            .when('/settings/role_management', new sSettings.Route.RoleManagement())
            .when('/settings/features', new sSettings.Route.FeatureManagement())
            .when('/settings/plan_management', new sSettings.Route.PlanManagement())
            .when('/settings/domain_management', new sSettings.Route.DomainManagement())
        ;
    }]);

    settings.config(['sTitleProvider', function(sTitleProvider) {
        var title = 'Settings';
        sTitleProvider
            .when(
                '/settings/platform/facebook-messenger',
                title,
                'Facebook Messenger'
            )
            .when(
                '/settings/platform/instagram-direct',
                title,
                'Instagram Direct'
            )
            .when(
                '/settings/configuration/general',
                title,
                'General'
            )
            .when(
                '/settings/configuration/default-reactions',
                title,
                'Default Reactions'
            )
            .when(
                '/settings/role_management',
                title,
                'Role Management'
            )
            .when(
                '/settings/features',
                title,
                'Features'
            )
            .when(
                '/settings/plan_management',
                title,
                'Plan Management'
            )
            .when(
                '/settings/domain_management',
                title,
                'Domain Management'
            )
            .when(
                '/settings/user_management',
                title,
                'User Management'
            );
    }]);

    settings.config(['sNavigationMenuProvider', function(sNavigationMenuProvider) {
        sNavigationMenuProvider.$on(sAPIAccess.Service.sAPIAccess.EVENT_ENDPOINTS_LOADED, ['sAPIAccess', function(event, sAPIAccess) {

            // aggregator menu
            var settingsMenu = new Model.Menu.Menu(),
                subMenu      = settingsMenu
                    .setLabel('Settings')
                    .setOptions({iconClass : 'fa fa-cog'})
                    .sub()
            ;

            var sMenuPlatforms = new Model.Menu.Menu()
                .setLabel('PLATFORMS');

            if (sAPIAccess.isAllowed('facebook.page.all', Const.Method.GET)
                || sAPIAccess.isAllowed('facebook.page.RESTAccess', Const.Method.GET)
            ) {
                sMenuPlatforms.add(
                    Model.Menu.LocationAction.TYPE_IDENT,
                    'Facebook Messenger',
                    '#/settings/platform/facebook-messenger'
                );
            }

            if (sAPIAccess.isAllowed('sender.instagramDirect.all', Const.Method.GET)) {
                sMenuPlatforms.add(
                    Model.Menu.LocationAction.TYPE_IDENT,
                    'Instagram Direct',
                    '#/settings/platform/instagram-direct'
                );
            }

            if (sMenuPlatforms.items.length) {
                subMenu.merge(sMenuPlatforms, true);
            }

            var sMenuBotSettings = new Model.Menu.Menu()
                .setLabel('BOT CONFIGURATION');

            if (sAPIAccess.isAllowed('domain.domainData.RESTAccess', Const.Method.GET)) {
                sMenuBotSettings.add(Model.Menu.LocationAction.TYPE_IDENT, 'General', '#/settings/configuration/general');
            }

            if (sAPIAccess.isAllowed('defaultReaction.byDomain.all', Const.Method.GET)) {
                sMenuBotSettings.add(Model.Menu.LocationAction.TYPE_IDENT, 'Default reactions', '#/settings/configuration/default-reactions');
            }

            if (sMenuBotSettings.items.length) {
                subMenu.merge(sMenuBotSettings, true);
            }

            var sMenuUserManagement = new Model.Menu.Menu()
                .setLabel('ACCESS CONTROL');

            if (sAPIAccess.isAllowed('user.byDomain.list', Const.Method.GET)) {
                sMenuUserManagement.add(Model.Menu.LocationAction.TYPE_IDENT, 'User management', '#/settings/user_management');
            }

            if (sAPIAccess.isAllowed('role.all', Const.Method.GET)) {
                sMenuUserManagement.add(Model.Menu.LocationAction.TYPE_IDENT, 'Role management', '#/settings/role_management');
            }

            if (sAPIAccess.isAllowed('domain.feature.list', Const.Method.GET)) {
                sMenuUserManagement.add(Model.Menu.LocationAction.TYPE_IDENT, 'Features', '#/settings/features');
            }

            if (sMenuUserManagement.items.length) {
                subMenu.merge(sMenuUserManagement, true);
            }

            var sMenuFeatureManagement = new Model.Menu.Menu()
                    .setLabel('APPLICATION CONTROL');

            if (sAPIAccess.isAllowed('plan.all', Const.Method.GET)) {
                sMenuFeatureManagement.add(Model.Menu.LocationAction.TYPE_IDENT, 'Plan management', '#/settings/plan_management');
            }

            if (sAPIAccess.isAllowed('domain.RESTAccess', Const.Method.GET)) {
                sMenuFeatureManagement.add(Model.Menu.LocationAction.TYPE_IDENT, 'Domain management', '#/settings/domain_management');
            }

            if (sMenuFeatureManagement.items.length) {
                subMenu.merge(sMenuFeatureManagement, true);
            }

            // we need to check one level deeper than usual, because items of settings menu are inside another sub-menu
            if (settingsMenu.items.length && settingsMenu.items[0].items.length) {
                sNavigationMenuProvider.bottomMenu.sub(settingsMenu)
            }
        }]);
    }]);

    settings.controller('FacebookMessenger', sSettings.Controller.Platform.FacebookMessenger);

})(window);
