(function () {
    var onHandler = function () {
        var args = Array.prototype.slice.call(arguments),
            deRegs = []
        ;

        this.each(function() {
            var context     = this,
                $element    = $(context)
            ;

            $element.on.apply($element, args);
            deRegs.push(function() {
                $element.off.apply($element, args);
            });
        });

        return deRegs;
    };

    jQuery.fn.extend({
        /**
         * @returns {Array}
         */
        $on: onHandler,
        $prependOnHandler: function () {
            var retVal = onHandler.apply(this, arguments),
                type   = Array.prototype.slice.call(arguments, 0, 1)
            ;

            this.each(function() {
                var events = $._data(this, 'events')[type];
                if (!events.length) {
                    return;
                }
                events.unshift(events.pop());
            });
            return retVal;
        }
    });
})(jQuery);

