(function (ns) {
    /**
     * @namespace
     * @alias sDomain.Provider.sDomain
     *
     * @constructor
     */
    var sDomain = function () {

        this.$get = ['$rootScope', '$location', 'sAppCookies', 'sAPIAccess', function($rootScope, $location, sAppCookies, sAPIAccess) {
            return new Service.sDomain($rootScope, $location, sAppCookies, sAPIAccess);
        }];
    };

    ns.sDomain = sDomain;
})(Object.namespace('sDomain.Provider'));