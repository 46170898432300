(function (ns) {
    /**
     * @namespace
     * @alias sAnalytics.Service.sTrace
     *
     * @constructor
     */
    var sTrace = function () {
        var traceFactories  = {},
            plotTemplates   = {}
            ;

        /**
         * @name sAnalytics.Service.sTrace#registerTraceFactory
         * @param {String} name
         * @param {Function} func
         * @returns {sAnalytics.Service.sTrace}
         */
        this.registerTraceFactory = function addTraceFactory(name, func) {
            traceFactories[name] = func;

            return this;
        };

        /**
         * @name sAnalytics.Service.sTrace#executeTraceFactory
         * @param {String} name
         * @param {Model.Analytics.Entry[]} entries
         * @returns {Array}
         */
        this.executeTraceFactory = function executeTraceFactory(name, entries) {
            if (!traceFactories[name]) {
                throw 'Unknown trace factory: ' + name;
            }

            var retVal = traceFactories[name].apply(this, [entries].concat(Array.prototype.slice.call(arguments, 2)));

            if (!(retVal instanceof Array)) {
                throw 'Invalid return value by trace factory: ' + name;
            }

            return retVal;
        };

        /**
         * @name sAnalytics.Service.sTrace#registerPlotTemplate
         * @param {String} name
         * @param {Object} template
         * @returns {sAnalytics.Service.sTrace}
         */
        this.registerPlotTemplate = function registerPlotTemplate(name, template) {
            plotTemplates[name] = template;
            return this;
        };

        /**
         * @name sAnalytics.Service.sTrace#getPlotTemplate
         * @param {String} templateString
         * @returns {Object}
         */
        this.getPlotTemplate = function getPlotTemplate(templateString) {
            var templates = templateString.split('|');

            return templates.reduce(
                function(carry, element) {
                    if (!plotTemplates[element]) {
                        return carry;
                    }

                    $.extend(true, carry, plotTemplates[element]);

                    return carry;
                },
            {});
        };
    };

    ns.sTrace = sTrace;
})(Object.namespace('sAnalytics.Service'));