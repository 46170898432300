(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sDynamicFormAccordionController
     * @constructor
     */
    var sDynamicFormAccordionController = function sDynamicFormAccordionController() {
    };

    /**
     * @function
     * @name Controller.Component.sDynamicFormAccordionController#$onInit
     */
    sDynamicFormAccordionController.prototype.$onInit = function $onInit() {
        var requiredMethods = [
            'addItem',
            'removeItem',
            'canGainItem',
            'canLoseItem'
        ];

        for (var i = 0; i < requiredMethods.length; i++) {
            if (!(this.dynamicForm.model[requiredMethods[i]] instanceof Function)) {
                throw 'Method "' + requiredMethods[i] + '" is required on the model!';
            }
        }
    };

    /**
     * @function
     * @name Controller.Component.sDynamicFormAccordionController#changeActiveGroup
     * @param activeGroup
     */
    sDynamicFormAccordionController.prototype.changeActiveGroup = function changeActiveGroup(activeGroup) {
        this.dynamicForm.model.activeGroup = activeGroup;
    };

    ns.sDynamicFormAccordionController = sDynamicFormAccordionController;
})(Object.namespace('Controller.Component'));
