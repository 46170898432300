(function(ns) {
    /**
     * @namespace
     * @alias Model.RepairCase.InvalidStructuredMessage
     *
     * @extends Model.RepairCase
     */
    var InvalidStructuredMessage = function() {

            var options = {
                confirm     : 'Got it.',
                decline     : null,
                title       : 'Oh, something is wrong …',
                content     : 'At least one of your messages is invalid. Please review your Conversation and fix the invalid message(s).',
                ariaLabel   : ''
            };

            /**
             * @name Model.RepairCase.InvalidStructuredMessage#faultyMessages
             */

            InvalidStructuredMessage._pProto.constructor.call(
                this,
                this.checkInvalidStructuredMessages,
                null,
                options
            );
        };

    Object.extendProto(InvalidStructuredMessage, Model.RepairCase);

    /**
     * @function
     * @name Model.RepairCase.InvalidStructuredMessage#checkInvalidStructuredMessages
     * @param {Model.Message.Collection} collection
     */
    InvalidStructuredMessage.prototype.checkInvalidStructuredMessages = function checkInvalidStructuredMessages(collection) {
        var issues = [];
        for (var i = 0; i < collection.messages.length; i++) {
            for (var j in collection.messages[i].messageParts) {
                var part = collection.messages[i].messageParts[j];
                if (!part.validate()) {
                    issues.push(collection.messages[i]);
                }
            }
        }

        if (issues.length){
            this.faultyMessages = issues;
            throw new Model.Exception.InvalidMessages(issues);
        }
    };

    ns.InvalidStructuredMessage = InvalidStructuredMessage;

})(Object.namespace('Model.RepairCase'));