(function(ns) {
    /**
     * @namespace
     * @alias sNavigation.Provider.sNavigationMenu
     */
    var sNavigationMenu = function sNavigationMenuProvider() {

        var mainMenu        = new Model.Menu.Menu(),
            bottomMenu      = new Model.Menu.Menu(),
            eventHandlers   = [],
            initFn          ,
            // helper function to resolve dependencies in the form of
            // ['dep1', '...', 'depN', func] => [func, [dep1, ..., depN]]
            // 'dep1' is the name of the dependency while dep1 is the resolved dependency
            convertDIs      = function(diFn, $injector) {
                diFn        = diFn.slice();
                var args    = diFn.splice(0, diFn.length -1);
                diFn        = diFn.pop();

                args = args.map(function(element) {
                    return $injector.get(element);
                });

                return [diFn, args];
            }
            ;

        Object.defineProperties(
            this,
            {
                mainMenu       :
                    {
                        get : function () {
                            return mainMenu;
                        }
                    },
                bottomMenu :
                    {
                        get : function () {
                            return bottomMenu;
                        }
                    }
            }
        );

        this.$on = function $on(event, handler) {
            eventHandlers.push({'event': event, 'handler': handler});

            return this;
        };

        this.$onInit = function $onInit(handler) {
            initFn = handler;

            return this;
        };

        this.$get = function $get($rootScope, $injector) {
            var $deRegister = [],
                args        = [],
                self        = this
            ;

            /**
             * Register events added in the config
             * There is one twist that event handlers might be registered as an array
             * where the last element is the handler function and the preceding elements are the
             * dependencies to be injected
             */
            $deRegister = eventHandlers.reduce(function(carry, element) {
                $deRegister.push($rootScope.$on(
                    element.event,
                    function(event) {
                        // create a closure so we can call the function in an isolated area
                        var args = Array.prototype.slice.call(arguments, 1),
                            handler
                            ;

                        if (element.handler instanceof Array) {
                            // resolve all the dependencies
                            var res = convertDIs(element.handler, $injector);

                            handler         = res.shift();
                            // merge args with the existing arguments
                            args            = [event].concat(res.shift(), args);
                        } else {
                            handler = element.handler;
                        }

                        // call the handler
                        return handler.apply(self.mainMenu, args);
                    }
                ));

                return $deRegister;
            }, $deRegister);

            $rootScope.$on('$destroy', function() {
                var $destroyFn;
                while (($destroyFn = $deRegister.pop())) {
                    $destroyFn();
                }
            });

            if (initFn) {
                // same as for the event subscriber, array type is supported to resolve dependencies
                if (initFn instanceof Array) {
                    var res = convertDIs(initFn, $injector);
                    initFn = res.shift();
                    args = res.shift();
                }

                initFn.apply(this.mainMenu, args);
            }

            return this;
        };

        this.findItemByLabel = function findItemByLabel(label) {
            var item = mainMenu.findItemByLabel(label);

            return (item ? item : bottomMenu.findItemByLabel(label));
        };
    };

    ns.sNavigationMenu = sNavigationMenu;
})(Object.namespace('sNavigation.Provider'));
