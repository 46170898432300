(function(ns) {
    /**
     * @namespace
     * @alias Model.CTA
     *
     * @param uuid
     * @constructor
     * @implements Model.Behavior.Meta
     * @extends Model.UUID
     */
    var CTA = function(uuid) {
        var label       = null,
            actions     = new Model.Action.Collection(),
            parent
        ;

        Model.Behavior.Meta.call(this);

        Object.defineProperties(
            this,
            {
                label: {
                    enumerable: true,
                    configurable: true,
                    set: function (val) {
                        label = val;
                    },
                    get: function () {
                        return label;
                    }
                    /**
                     * @type {String}
                     * @property
                     * @name Model.CTA#label
                     */
                },
                actions: {
                    enumerable: true,
                    configurable: true,
                    get: function() {
                        return actions;
                    },
                    set: function(val) {
                        Object.instanceOf(val, Model.Action.Collection);
                        actions = val;
                    }
                    /**
                     * @type {Model.Action.Collection}
                     * @property
                     * @name Model.CTA#actions
                     */
                },
                parent: {
                    enumerable: false,
                    get: function() {
                        return parent;
                    },
                    set: function(val) {
                        if (parent === val) {
                            return;
                        }

                        // prevent endless loop
                        if (!val && parent && parent.getIndex(this) !== -1) {
                            parent.removeCTA(this);
                        }

                        parent = val;
                        if (parent) {
                            parent.addCTA(this);
                        }
                    }
                    /**
                     * @type {Model.CTA.Collection}
                     * @property
                     * @name Model.CTA#parent
                     */
                },
                meta: {
                    enumerable: true,
                    get: function () {
                        return this.getMetasJson();
                    }
                    /**
                     * @property
                     * @name Model.CTA#meta
                     * @type {Object}
                     */
                }
                
            }
        );

        this.__getCloneArguments = function() {
            return [this.uuid];
        };

        this.__dontCloneProperties = function() {
            return ['parent'];
        };

        CTA._pProto.constructor.call(this, uuid);
    };

    Object.extendProto(CTA, Model.UUID);

    /**
     * @param {Object} data
     */
    CTA.prototype.updateByData = function updateByData(data) {
        var self = this;

        $.each(data, function(keyName, keyValue) {
            if (self.hasOwnProperty(keyName)) {
                if (self[keyName] instanceof Object && self[keyName].updateByData) {
                    self[keyName].updateByData(keyValue);
                } else {
                    self[keyName] = keyValue;
                }
            }
            if (keyName === 'meta') {
                self.updateMetaByData(keyValue);
            }
        });
    };

    /**
     * @name Model.CTA#getAllActions
     * @returns Model.Action[]
     */
    CTA.prototype.getAllActions = function getAllActions() {
        return this.actions.actions;
    };

    /**
     * @name Model.CTA#getPostbackAction
     */
    CTA.prototype.getPostbackAction = function getPostbackAction() {
        return this.actions.getPostbackAction();
    };

    /**
    * @name Model.CTA#getMessageTriggeringAction
     */
    CTA.prototype.getMessageTriggeringAction = function getMessageTriggeringAction() {
        return this.actions.getMessageTriggeringAction();
    };

    CTA.prototype.hasUrl = function hasUrl() {
        return this.actions.length === 1 && this.actions.actions[0].type === 'url';
    };

    /**
     * @name Model.CTA#validate
     * @returns {boolean}
     */
    CTA.prototype.validate = function validate() {
        return Validator.required(this.label) && this.actions.validate();
    };

    ns.CTA = CTA;
})(Object.namespace('Model'));