(function (ns) {
    /**
     * @namespace
     * @alias sEntryPoint.Route.Index
     * @extends sRoute
     * @constructor
     */
    var Index = function Index() {
        this.controller     =  sEntryPoint.Controller.Overview;
        this.controllerAs   = '$overviewCtrl';
        this.templateUrl    = 'sentrypoint:overview';
        this.reloadOnSearch = false;
        this.accessControl  = {'entryPoint.RESTAccess': [Const.Method.GET]};

        Index._pProto.constructor.call(this);
    };

    Object.extendProto(Index, sRoute);

    /**
     * @function
     * @name sEntryPoint.Route.Index#resolveSenders
     * @param {sFacebook.Service.sFacebookPage} sFacebookPage
     * @returns $.Deferred
     */
    Index.prototype.resolveSenders = function resolveSenders(sFacebookPage) {
        return sFacebookPage.loadAll(['isAvailable']);
    };

    ns.Index = Index;
})(Object.namespace('sEntryPoint.Route'));
