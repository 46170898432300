(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sMenuEditForm
     *
     * @param {Boolean} editable
     * @param mdPanelRef
     * @param {Integer} level
     * @param {Integer} maxMenuDepth
     * @param {Function} successCallback
     * @constructor
     */
    var sMenuEditForm = function (
        editable,
        mdPanelRef,
        level,
        maxMenuDepth,
        successCallback) {

        var availableTypes = [[
            {
                value: 'action',
                label: 'Trigger action'
            }]];

        if ((typeof maxMenuDepth === 'undefined') || maxMenuDepth < 0 || maxMenuDepth > level) {
            availableTypes[0].unshift({
                value: 'menu',
                label: 'Show submenu'
            });
        }

        this.rules = {
            label: {
                component: 'div',
                options: {
                    model: 'ng-model',
                    attrs: {
                        'contenteditable'   : true,
                        's-emoji-renderer'  : true,
                        's-emoji-picker'    : true,
                        'autocompleter'     : true,
                        's-form-wrapper'    : '{errorTemplate: \'sbase:text_errors\', class: \'s-text-input \'}',
                        'class'             : 'input',
                        'ng-model-options'  : '{allowInvalid: true}',
                        's-maxlength'       : 30,
                        'required'          : true,
                        's-length-counter'  : '{model: $ctrl.model.label, max: \'sMaxlength\'}'
                    },
                    label: 'Title'
                }
            },
            type: {
                component: 'sRadioList',
                visibility: '$ctrl.model.level !== 2',
                options: {
                    attrs: {
                        options: JSON.stringify(availableTypes)
                    },
                    label: false
                }
            },
            action: {
                component: 'sActionCollection',
                visibility: '$ctrl.model.type === "action"',
                options: {
                    label: 'Actions'
                }
            }
        };

        /**
         * @name Controller.Component.sMenuEditForm#editable
         * @type {Object}
         */
        this.editable = editable;

        /**
         * @name Controller.Component.sMenuEditForm#$mdPanel
         * @type MdPanelRef
         */
        this.$mdPanel = mdPanelRef;

        /**
         * @name Controller.Component.sMenuEditForm#level
         * @type Number
         */
        this.level = level;

        /**
         * @name Controller.Component.sMenuEditForm#successCallback
         * @type Function
         */
        this.successCallback = successCallback
    };

    /**
     * @name Controller.Component.sMenuEditForm#cancel
     */
    sMenuEditForm.prototype.cancel = function cancel() {

        this.$mdPanel.close();
    };

    sMenuEditForm.prototype.onSave = function onSave() {
        var self = this;
        this.$mdPanel.close().then(function () {
            self.successCallback();
        });
    };

    ns.sMenuEditForm = sMenuEditForm;

})(Object.namespace('Controller.Component'));
