(function(ns) {

    /**
     * @namespace
     * @alias Controller.Component.sMemberBlockStatus
     * @constructor
     *
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param $scope
     */
    var sMemberBlockStatus = function sMemberBlockStatus(sAPIAccess, $scope) {
        var self = this,
            urlFunction = function(method) {
                var endPoint = sAPIAccess.endpoint('member.blockStatus');

                switch (method) {
                    case Const.Method.POST:
                        return endPoint.post(self.memberId);
                    case Const.Method.DELETE:
                        return endPoint.delete(self.memberId);
                    default:
                        return endPoint.get(self.memberId);
                }
            }
        ;

        sMemberBlockStatus._pProto.constructor.call(this, urlFunction, $scope);

        this.label = 'Block member';
    };

    Object.extendProto(sMemberBlockStatus, Controller.Component.sAsyncCheckbox);

    ns.sMemberBlockStatus = sMemberBlockStatus;
})(Object.namespace('Controller.Component'));

