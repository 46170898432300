(function (ns) {
    var FORMAT_PLOTLY_TIME = 'YYYY-MM-DD HH:mm:ss.SSSSSS';

    /**
     * @namespace
     * @alias Model.Analytics.Entry
     *
     * @extends Model.Analytics.Value
     * @constructor
     */
    var Entry = function () {
        Entry._pProto.constructor.call(this);

        var categories      = new Model.Collection(Model.Analytics.Category, 'category'),
            layer,
            time
        ;

        Object.defineProperties(
            this,
            {
                time: {
                    get: function() {
                        return time;
                    },
                    set: function(val) {
                        var tmp;
                        if (val) {
                            if (!(tmp = moment(val)).isValid()) {
                                throw 'Argument is not a valid time';
                            }
                        }

                        time = tmp;
                    }
                    /**
                     * @property
                     * @name Model.Analytics.Entry#time
                     * @type {moment}
                     */
                },
                categories: {
                    get: function() {
                        return categories;
                    }
                    /**
                     * @property
                     * @name Model.Analytics.Entry#categories
                     * @type {Model.Collection}
                     */
                },
                layer: {
                    get: function() {
                        return layer;
                    },
                    set: function(val) {
                        if (val) {
                            Object.instanceOf(val, Model.Analytics.Layer);
                        }
                        if (layer === val) {
                            return;
                        }
                        layer = val;
                    }
                    /**
                     * @property
                     * @name Model.Analytics.Entry#layer
                     * @type {?Model.Analytics.Layer}
                     */
                }
            }
        );

        /**
         * @method
         * @name Model.Analytics.Entry.categories#addCategory
         * @param {Model.Analytics.Category} val
         */
    };

    Object.extendProto(Entry, Model.Analytics.Value);

    /**
     * @inheritDoc
     */
    Entry.prototype.updateByData = function updateByData(data) {
        var self = this;
        if (data.layer) {
            this.layer = Model.Analytics.Layer.getByKey(data.layer);
            // layer contains just a reference key, so once the reference was resolved the key should be removed
            delete (data.layer);
        }

        if (data.categories) {
            data.categories.map(function(categoryData) {
                var category = Model.Analytics.Category.getByTypeAndKey(categoryData.key, categoryData.value);
                if (category) {
                    self.categories.addCategory(category);
                }
            });
            // categories are references similar to layer, so remove after resolution
            delete (data.categories);
        }

        Object.updateByData(this, data);

        // map values to the object through the parent object
        Entry._pProto.updateByData.call(this, data.values);
    };

    /**
     * @param {Object} data
     *
     *
     * @returns {Model.Analytics.Entry}
     */
    Entry.createByData = function createByData(data) {
        var entry = new Entry();
        entry.updateByData(data);

        return entry;
    };

    /**
     * @param {Model.Analytics.Entry} entry
     */
    Entry.isoTimeExtractor = function isoTimeExtractor(entry) {
        return entry.time.toISOString();
    };

    /**
     * yyyy-mm-dd HH:MM:SS.ssssss
     * @see: https://help.plot.ly/date-format-and-time-series/#step-1-dates-time-series-and-timestamp-format-in-plotly
     * @param {Model.Analytics.Entry} entry
     */
    Entry.plotlyTimeExtractor = function plotlyTimeExtractor(entry) {
        return entry.time.format(FORMAT_PLOTLY_TIME);
    };

    Entry.FORMAT_PLOTLY_TIME = FORMAT_PLOTLY_TIME;

    ns.Entry = Entry;
})(Object.namespace('Model.Analytics'));