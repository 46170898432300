(function (ns) {
    var KEY_DEFAULT_TYPE = 'default';

    var all             = {},
        registeredTypes = {}
    ;

    /**
     * Immutable object representing a condition definition
     * @namespace
     * @alias Model.Condition.Definition
     *
     * @param {String} category
     * @param {String} label
     * @param {String} name
     * @param {Array} operators
     * @param {Array} values
     * @param {Boolean} internal
     * @constructor
     */
    var Definition = function (category, label, name, operators, values, internal) {
        var config,
            self            = this
            ;

        if (operators && !(operators instanceof Array)) {
            throw 'Must provide an array for the operators!';
        }

        if (!operators) {
            operators = [];
        }

        if (values && !(values instanceof Array)) {
            throw 'Must provide an array for the values!';
        }

        if (!values) {
            values = [];
        }

        values = values.map(function(element) {
            return new Model.Condition.Definition.Value(element['type'], element['requiredByOperators'], element['options'], element['allowAny']);
        });

        operators = operators.map(function(element) {
            return {
                'label' : element ? element.replace(/_/g, ' ') : '-',
                'key'   : element
            };
        });

        Object.defineProperties(
            this,
            {
                category: {
                    get: function() {
                        return category;
                    }
                    /**
                     * @name Model.Condition.Definition#category
                     * @type {string}
                     */
                },
                label: {
                    get: function() {
                        return label;
                    }
                    /**
                     * @name Model.Condition.Definition#label
                     * @type {string}
                     */
                },
                name: {
                    enumerable: true,
                    get: function() {
                        return name;
                    }
                    /**
                     * @name Model.Condition.Definition#name
                     * @type {string}
                     */
                },
                operators: {
                    get: function() {
                        return [].concat(operators);
                    }
                    /**
                     * @name Model.Condition.Definition#operators
                     * @type {Array}
                     */
                },
                values: {
                    get: function() {
                        return [].concat(values);
                    }
                    /**
                     * @name Model.Condition.Definition#values
                     * @type {Array}
                     */
                },
                internal: {
                    enumerable: true,
                    get: function () {
                        return internal;
                    }
                    /**
                     * @property
                     * @name Model.Condition.Definition#internal
                     * @type {Boolean}
                     */
                }
            }
        );


        this.getFormFields = function getFormFields() {
            if (!config) {
                config = {};
                var operatorConfig = {
                        component: 'sSelect',
                        template: '_inline_input',
                        options: {
                            attrs: {
                                'choices'         : '$ctrl.model.definition.operators',
                                'class'           : 'inline',
                                'model'           : '$ctrl.model.operator',
                                'view-field'      : 'label',
                                'value-field'     : 'key',
                                'ng-required'     : true,
                                'no-lazy-loading' : true
                            },
                            label: false
                        }
                    }
                    ;

                self.values.reduce(function(carry, element, index) {
                    var field = {
                            visibility  : '$ctrl.model.isValueVisible(' + index + ')',
                            template    : '_inline_input',
                            options     : {}
                        }
                    ;

                    Definition.executeTypeConfig(element.type, field, element, index);

                    if (!carry.operator && element.requiredByOperators.length || index === self.values.length -1) {
                        carry.operator = operatorConfig;
                    }

                    carry['value[' + index+ ']'] = field;
                    return carry;
                }, config);

            }

            return config;
        };

        all[name] = this;

        this.__getCloneArguments  = function __getCloneArguments() {
            return [category, label, name, operators.map(function(element) {
                return element.key;
            }), values, internal];
        };

        /**
         * @name Model.Condition.Definition#values
         * @type {Model.Condition.Definition.Value[]}
         */

    };

    /**
     * @param {String} name
     * @return {Model.Condition.Definition|null}
     */
    Definition.getByName = function getByName(name) {
        return all[name];
    };

    /**
     * @param {String} type
     * @param {Function} configFn
     * @param {String[]=} aliases
     */
    Definition.registerType = function registerType(type, configFn, aliases) {
        if (registeredTypes[type]) {
            throw new Error('Type "' + type + '" is already registered!');
        }

        Object.instanceOf(configFn, Function);
        registeredTypes[type] = configFn;

        aliases = aliases || [];
        aliases.map(function(alias) {
           Definition.registerType(alias, registeredTypes[type]);
        });
    };

    /**
     * @param {String} type
     * @param {Object} field
     * @param {Object} element
     * @param {Number} index
     */
    Definition.executeTypeConfig = function executeTypeConfig(type, field, element, index) {
        if (registeredTypes[type]) {
            registeredTypes[type](field, element, index);
        } else {
            registeredTypes[KEY_DEFAULT_TYPE](field, element, index);
        }
    };

    Definition.registerType(KEY_DEFAULT_TYPE, function(field) {
        field.component     = 'div';
        $.extend(field.options, {
            model: 'ng-model',
            attrs: {
                'contenteditable'   : true,
                's-form-wrapper'    : '{errorTemplate: \'sbase:text_errors\', class: \'s-text-input \'}',
                'class'             : 'input',
                'ng-model-options'  : '{allowInvalid: true}',
                'ng-required'       : true,
                'allow-multiline'   : false
            }
        });
    });

    Definition.registerType('duration', function(field, element, index) {
        Definition.executeTypeConfig(KEY_DEFAULT_TYPE, field, element, index);
        $.extend(true, field.options, {
            attrs: {
                'ng-pattern'            : new RegExp('^[1-9]([0-9]){0,2}(?:<br>)?$'),
                'placeholder'           : '... day\(s\) ago',
                'data-suffix'           : 'day\(s\) ago',
                's-days-transformer'    : true
            }
        });
        field.options.attrs['s-form-wrapper'] = '{errorTemplate: \'sbase:integer_errors\', class: \'s-text-input \', min: 1, max: 999}';
    });

    Definition.registerType('integer', function(field, element, index) {
        Definition.executeTypeConfig(KEY_DEFAULT_TYPE, field, element, index);
        $.extend(true, field.options, {
            attrs: {
                'ng-pattern'        : new RegExp('^(-)?[1-9]([0-9])*(?:<br>)?$'),
                'placeholder'       : 'Enter a whole number'
            }
        });
        field.options.attrs['s-form-wrapper'] = '{errorTemplate: \'sbase:integer_errors\', class: \'s-text-input \'}';
    });

    Definition.registerType('url', function(field, element, index) {
        Definition.executeTypeConfig(KEY_DEFAULT_TYPE, field, element, index);
        $.extend(true, field.options, {
            attrs: {
                's-validate-url'    : true
            }
        });
        field.options.attrs['s-form-wrapper'] = '{errorTemplate: \'sbase:text_errors\', class: \'s-text-input \'}';
    });

    Definition.registerType('float', function(field, element, index) {
        Definition.executeTypeConfig(KEY_DEFAULT_TYPE, field, element, index);
        $.extend(true, field.options, {
            attrs: {
                'ng-pattern'    : new RegExp('^(-)?(([1-9]+(\\.[0-9]+)?)|(0(\\.[0-9]+)?))(?:<br>)?$'),
                'placeholder'   : 'Enter a number'
            }
        });
        field.options.attrs['s-form-wrapper'] = '{errorTemplate: \'sbase:number_errors\', class: \'s-text-input \'}';
    });

    Definition.registerType('date', function(field, element, index) {
        field.component     = 's-date-picker';
        $.extend(field.options, {
            attrs: {
                'ng-required'   : true,
                'model'         : '$ctrl.model.value[' + index +']'
            }
        });
    });

    Definition.registerType('enum', function(field, element, index) {
        field.component     = 'sSelect';
        $.extend(field.options, {
            attrs           : {
                'ng-required'   : true,
                'model'         : '$ctrl.model.value[' + index +']',
                'choices'       : '$ctrl.model.definition.values[' + index + '].options',
                'view-field'    : 'label',
                'sort-by'       : 'label',
                'value-field'   : 'value'
            }
        });
    });

    Definition.registerType('conversation', function(field, element, index) {
        var any = false;
        if (element.allowAny === true) {
            any = true;
        }
        field.component     = 'sConversationSelect';
        $.extend(field.options, {
            attrs           : {
                'model'         : '$ctrl.model.value[' + index +']',
                'any'           : any
            }
        });
    });

    Definition.registerType('ai_template', function (field, element, index) {
        field.component     = 'sConversationSelect';
        $.extend(field.options, {
            attrs           : {
                'model'         : '$ctrl.model.value[' + index +']',
                'is-ai-template'  : true
            }
        });
    });

    Definition.registerType('user_label', function (field, element, index) {
        field.component     = 'sUserDataLabelSelect';
        $.extend(field.options, {
            attrs: {
                'multi-select'  : false,
                'existing-only' : true
            }
        });
    });

    Definition.registerType('user_attribute', function (field, element, index) {
        field.component     = 'sMemberAttributeSelect';
        $.extend(field.options, {
            attrs: {
                'is-required'   : true,
                's-json-parser' : '$ctrl.model.value[' + index + ']',
                'model'         : '$json'
            }
        });
    });

    Definition.registerType('message_goal', function (field, element, index) {
        field.component = 'sMarkerSelect';
        $.extend(field.options, {
            attrs : {
                'model'        : '$ctrl.model.value[' + index + ']',
                'default-name' : 'Goal'
            }
        });
    });

    ns.Definition = Definition;
})(Object.namespace('Model.Condition'));
