(function(ns) {

    /**
     * @namespace
     * @alias Controller.Component.sCTA
     * @constructor
     *
     * @param $scope
     */
    var sCTA = function sCTA($scope) {
        this.$scope         = $scope;
        this.$deRegister    = [];
    };

    /**
     * @function
     * @name Controller.Component.sCTA#$onInit
     */
    sCTA.prototype.$onInit = function $onInit() {
        var self = this;

        this.$deRegister.push(this.$scope.$watchCollection(
            function() {
                return self.model.actions.actions;
            },
            /**
             * @param {Array} val
             */
            function(val) {
                self.disableTitle = false;
                for (var i = 0; i < val.length; i++) {
                    if (val[i].type === 'fb_share_button') {
                        self.model.label = 'Share';
                        self.disableTitle = true;
                    } else if (val[i].type === Model.Action.TYPE_ONE_TIME_NOTIFICATION) {
                        self.disableTitle = true;
                    }
                }
            }
        ));
    };

    /**
     * @function
     * @name Controller.Component.sCTA#$onDestroy
     */
    sCTA.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    ns.sCTA = sCTA;
})(Object.namespace('Controller.Component'));
