(function (ns) {
    /**
     * @namespace
     * @alias sFeatureManagement.Controller.FeatureList
     * @constructor
     *
     * @param {Model.Domain} domain
     * @param {Model.Feature[]} features
     * @param Notification
     */
    var FeatureList = function (domain, features, Notification) {
        this.features       = features;
        this.domain         = domain;
        this.notification   = Notification;
    };

    /**
     * @function
     * @name sFeatureManagement.Controller.FeatureList#toggleFeature
     * @param {Model.Feature} feature
     */
    FeatureList.prototype.toggleFeature = function toggleFeature(feature) {
        var self = this;

        if(this.domain.isFeatureEnabled(feature)) {
            this.domain.disableFeature(feature);
        } else {
            this.domain.enableFeature(feature);
        }

        this.domain.save().fail(
            function() {
                self.notification.error('<i>' + feature.name + '</i> could not be saved');
            }
        );
    };

    ns.FeatureList = FeatureList;
})(Object.namespace('sFeatureManagement.Controller'));
