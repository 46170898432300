(function(ns) {
    /**
     * @namespace
     * @alias Controller.Directive.sMessageRepository
     *
     * @param sDomainService
     * @param apiEndpoints
     */
    var sMessageRepository = function(sDomainService, apiEndpoints) {
        /**
         * @property
         * @name Controller.Directive.sMessageRepository#repository
         */

        this.sDomainService = sDomainService;
        this.apiEndpoints   = apiEndpoints;
    };

    /**
     * @method
     * @name Controller.Directive.sMessageRepository#getAvailableMessages
     */
    sMessageRepository.prototype.getAvailableMessages = function getAvailableMessages() {
        var $deferred   = $.Deferred(),
            self        = this
            ;

        if (!this.repository.hasOwnProperty('message') || !this.repository.hasOwnProperty('messages')) {
            throw 'The repository has to have the properties "messages" and "message"!';
        }

        $deferred.resolve([{'preview': 'New message', 'uuid': null}]
            .concat(self.repository.message.filterOutAlreadyRelateds(self.repository.messages)
            .filter(function(item) {
                return !self.repository.relateFrom || item.uuid !== self.repository.relateFrom.uuid ;
            })
            .filter(function(item) {
                return !item.isDynamicContentMessage();
            }))
        );

        return $deferred.promise();
    };

    /**
     * @name Controller.Directive.sMessageRepository#getAvailableConversations
     * @param currValue
     * @param {String=} type
     * @param {Boolean=} anyOption
     * @returns {*|Promise}
     */
    sMessageRepository.prototype.getAvailableConversations = function getAvailableConversations(currValue, type, anyOption) {
        var self = this,
            getNewConversations = function() {
                var domainId = self.sDomainService.currentDomainId ? self.sDomainService.currentDomainId : null;

                if (domainId === null) {
                    return $.Deferred().resolve([]);
                }

                var data = {};
                if (type) {
                    data.type = type;
                }

                return $.ajax({
                    url: Model.Message.Anchor.endPoint_getPublishedConversations(domainId),
                    method: Const.Method.GET,
                    data: data,
                    context: self,
                    contentType: false,
                    dataType: 'json',
                    success: function (data) {
                        return data;
                    },
                    error: function () {
                        return [];
                    }
                }).then(function (data) {
                    var messageAnchorList = [];
                    for (var key in data) {
                        // filter current message-anchor so do not link to self
                        if (!self.repository || !self.repository.messageAnchor || (self.repository.messageAnchor && data[key].uuid !== self.repository.messageAnchor.uuid)) {
                            messageAnchorList.push(Model.Message.Anchor.createByData(data[key]));
                        }
                    }

                    if (anyOption === true) {
                        var modelAny = new Model.Message.Anchor();
                        modelAny.messageUuid = 'any';
                        modelAny.title = 'Any';
                        messageAnchorList.push(modelAny);
                    }

                    return messageAnchorList;
                });
            };

        return $.when(getNewConversations()).then(function(msgAnchorList) {

            if (currValue === null) {
                return msgAnchorList;
            }

            // if it's not already in array it seems to be a story_uuid
            for (var key in msgAnchorList) {
                if (msgAnchorList[key].messageUuid === currValue) {
                    return msgAnchorList;
                }
            }

            if (!currValue) {
                return msgAnchorList;
            }

            return $.ajax({
                url         : self.apiEndpoints.messageAnchor.byMessage(currValue),
                method: Const.Method.GET,
                context     : self,
                processData : false,
                contentType : false,
                dataType    : 'json',
                success: function(data) {
                    return data;
                },
                error: function() {
                    return [];
                }
            }).then(function(anchor) {
                var msgAnchor = Model.Message.Anchor.createByData(anchor);
                if (msgAnchor.title) {
                    msgAnchor.title = "Inactive: " + msgAnchor.title;
                    msgAnchorList.push(msgAnchor);
                }
                return msgAnchorList;
            });
        });
    };

    ns.sMessageRepository = sMessageRepository;
})(Object.namespace('Controller.Directive'));