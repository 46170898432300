(function(ns) {
    var TYPE = 'instagram_direct';

    /**
     * @namespace
     * @alias Model.Sender.InstagramDirectAccount
     * @extends Model.Sender.BaseFacebookSender
     * @constructor
     *
     * @param {String=} uuid
     */
    var InstagramDirectAccount = function InstagramDirectAccount(uuid) {
        var self = this;

        /**
         * @property {String|Integer}
         * @name Model.Sender.InstagramDirectAccount#subjectId
         */
        this.subjectId = null;
        /**
         * @property {String}
         * @name Model.Sender.InstagramDirectAccount#subjectName
         */
        this.subjectName = '';
        /**
         * @property {String|Integer}
         * @name Model.Sender.InstagramDirectAccount#pageId
         */
        this.pageId = null;
        /**
         * @property {String|Integer}
         * @name Model.Sender.InstagramDirectAccount#authUserId
         */
        this.authUserId = null;
        /**
         * @property {String}
         * @name Model.Sender.InstagramDirectAccount#authUserName
         */
        this.authUserName = null;

        Object.defineProperties(
            this,
            {
                isBusinessIntegrated: {
                    get: function () {
                        return self.integrationStatus === Model.Sender.INTEGRATION_STATUS_CONNECTED;
                    },
                    set: function () {
                        // noop
                    }
                    /**
                     * @property
                     * @type {Boolean}
                     * @name Model.Sender.InstagramDirectAccount#isBusinessIntegrated
                     */
                },
                alias: {
                    enumerable: true,
                    get: function() {
                        return self.subjectName || self.subjectId;
                    }
                    /**
                     * @property
                     * @name Model.Sender.InstagramDirectAccount#alias
                     * @type String
                     */
                }
            }
        );

        InstagramDirectAccount._pProto.constructor.call(this, InstagramDirectAccount, uuid);
    };

    Object.extendProto(InstagramDirectAccount, Model.Sender.BaseFacebookSender);

    /**
     * Creates a instagram-direct-account by data
     * @function
     * @static
     * @name Model.Sender.InstagramDirectAccount.createByData
     * @param {object} data
     * @returns Model.Sender.InstagramDirectAccount
     */
    InstagramDirectAccount.createByData = function createByData(data) {
        return Model.Sender.createByData(TYPE, data);
    };

    ns.TYPES[TYPE] = InstagramDirectAccount;

    Object.defineProperties(
        InstagramDirectAccount,
        {
            TYPE : {
                value : TYPE
                /**
                 * @property
                 * @constant
                 * @name Model.Sender.InstagramDirectAccount#TYPE
                 * @type {String}
                 */
            }
        }
    );

    ns.InstagramDirectAccount = InstagramDirectAccount;
})(Object.namespace('Model.Sender'));
