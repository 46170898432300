/**
 * Created by Gabor on 15-Nov-16.
 */

(function() {
    var sFollowPubFilter = angular.module('sFollowPubFilters', []);

    sFollowPubFilter
        .filter('escapeHtml', function(){
            return function(input) {
                var $el = $('<div></div>');
                $el.text(input);

                return $el.html();
            };
        })
        .filter('nl2br', ['$sanitize', function($sanitize) {
            var tag = '<br />';
            return function(msg) {
                // ngSanitize's linky filter changes \r and \n to &#10; and &#13; respectively
                msg = (msg + '').replace(/(\r\n|\n\r|\r|\n|&#10;&#13;|&#13;&#10;|&#10;|&#13;)/g, tag + '$1');
                return $sanitize(msg);
            };
        }])
        .filter('arrayOrder', function() {
            return function(input) {
                var res = [];

                for (var i in input) {
                    res[res.length] = input[i];
                }
                return res;
            };
        })
        .filter('trustedResource', ['$sce',
            /**
             * @type $sce
             * @param $sce
             * @returns {Function}
             */
            function ($sce) {
        return function(url) {
            return $sce.trustAsResourceUrl(url);
        };
        }])
        .filter('trustAsHtml', ['$sce',
            /**
             * @type $sce
             * @param $sce
             * @returns {Function}
             */
                function ($sce) {
                return function(value) {
                    return $sce.trustAsHtml(value);
                };
        }])
        .filter('compactNumber', function() {
            return function(input, precision, base, units, joiner) {
                units = units || ['', 'k', 'M', 'B', 'T'];
                base = base || 1000;
                joiner = joiner || ' ';
                if (input === 0) { return [0, units.slice(0,1).pop()].join(joiner); }
                if (isNaN(parseFloat(input)) || !isFinite(input)) return '-';
                if (typeof precision === 'undefined') precision = 1;

                var number = Math.floor(Math.log(input) / Math.log(base)),
                    val = (input / Math.pow(base, Math.floor(number))).toFixed(precision);

                return  (val.match(/\.0*$/) ? val.substr(0, val.indexOf('.')) : val) +  joiner + units[number];
            }
        })
        .filter('bytes', function($filter) {
            return function(bytes, precision) {
                return $filter('compactNumber')(bytes, precision, 1024, ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'], ' ');
            }
        })
        .filter('capitalize', function() {
            return function(s) {
                return (angular.isString(s) && s.length > 0) ? s[0].toUpperCase() + s.substr(1).toLowerCase() : s;
            }
        })
        .filter('removeNBSP', function() {
            return function(input) {
                return input.replace(/&nbsp;/g, Const.Unicode.NON_BREAKING_SPACE);
            }
        })
        .filter('passwordStrength', ['zxcvbn',
            /**
             * @type zxcvbn
             * @param zxcvbn
             * @returns {Function}
             */
            function (zxcvbn) {
                return function(value) {
                    return value ? zxcvbn.score(value) : null;
                };
            }])
        .filter('matchTextTokenize', function() {
            return function(input, groups) {
                var $el = $('<div></div>'),
                    groupIndex = 0;

                $el.html(input);
                input = $el.text();

                groups = groups || [];
                groups.splice(0, groups.length);
                groups.push.apply(groups, Model.AI.MatchText.tokenize(input));


                return groups.reduce(
                    /**
                     *
                     * @param {string} reduced
                     * @param {Model.AI.MatchGroup} group
                     * @returns {string}
                     */
                    function (reduced, group) {
                        reduced += group.toHTML(groupIndex);
                        groupIndex++;
                        return reduced;
                    },
                    ''
                );
            }
        })
        .filter('humanReadableConditions', ['apiEndpoints', function(apiEndpoints) {
            /**
             * @param {Model.Segment} segment
             * @returns {Promise}
             */
            return function(segment) {

                var whitelist = [
                    'received_conversation',
                    'not_received_conversation',
                    'interacted_conversation',
                    'not_interacted_conversation',
                    'not_matched_ai_template',
                    'matched_ai_template'
                ];

                return new Promise(function(resolve, reject){
                    var element = {};
                    var promiseAsync = null;
                    element.strategy = segment.conditions.strategy;
                    element.conditions = [];

                    if (segment.type === Model.Segment.KEY_SUBSCRIPTION_BASED) {
                        var cond = {};
                        cond.label = 'is subscribed to segment';
                        cond.values = [];
                        cond.values.push({'text':segment.name});
                        element.conditions.push(cond);
                    } else {
                        element.conditions = segment.conditions.conditions.reduce(function (carry, item) {
                            var visibleValues = item.getVisibleValues();
                            var cond = {};
                            cond.label = item.definition.label;
                            cond.operator = item.operator;

                            for (var i = 0; i < visibleValues.length; i++) {
                                if (whitelist.indexOf(item.name) !== -1 && i === 0) {
                                    var messageUuid = visibleValues[i];
                                    var keyConversation = i;
                                    var anchor = Model.Message.Anchor.getByMessage(apiEndpoints, visibleValues[i]);
                                    promiseAsync = anchor.then(function(anchor) {
                                        visibleValues[keyConversation] = {
                                            'link':'#/creator/'+ messageUuid,
                                            'title':anchor.title
                                        };
                                    });
                                } else if (typeof visibleValues[i] === 'object' && visibleValues[i].length) {
                                    //time range
                                    visibleValues[i] = {
                                        'from':visibleValues[i][0].model.from.format('ddd, D.MMM YYYY'),
                                        'to':visibleValues[i][0].model.to.format('ddd, D.MMM YYYY')
                                    };
                                } else if (typeof visibleValues[i] === 'object') {
                                    visibleValues[i] = {'text':visibleValues[i].format('ddd, D.MMM YYYY')};
                                } else if (item.name === Model.Segment.Condition.KEY_ATTRIBUTE) {
                                    // Attempt to parse JSON - show the value as is as fallback
                                    try {
                                        visibleValues[i] = {valueJSON : JSON.parse(visibleValues[i])};
                                    } catch (e) {
                                        visibleValues[i] = {'text' : visibleValues[i]};
                                    }
                                } else {
                                    visibleValues[i] = {'text':visibleValues[i]};
                                }
                            }

                            cond.values = visibleValues;
                            carry.push(cond);
                            return carry;
                        }, []);
                    }

                    if (promiseAsync === null) {
                        resolve(element);
                    } else {
                        promiseAsync.then(function(){
                            resolve(element);
                        });
                    }
                });
            }
        }])
        .filter('actionTypeToLabel', [
            'sActionService',
            /**
             * @param {Service.sAction} sActionService
             * @returns {Function}
             */
            function(sActionService) {
                var actionTypeToLabel = function actionTypeToLabel(type) {
                    var label = type;
                    sActionService.getDefinitions().then(function(defCollection) {
                        var definition = defCollection.findByName(type);
                        if (definition) {
                            label = definition.label;
                        }
                    });
                    return label;
                };
                // enable call on each digest cycle
                actionTypeToLabel.$stateful = true;
                return actionTypeToLabel;
            }
        ])
        .filter('ordinalSuffix', function() {
            /**
             * @param {Number} input
             */
            return function(input) {
                return input.toOrdinalSuffixedString().replace(input, '');
            };
        })
        .filter('propertyCount', function() {
            return function(object) {
                return Object.keys(object).length;
            }
        })
        .filter('sprintf', function () {
            /** @see https://www.npmjs.com/package/sprintfjs */
            return function() {
                return sprintf.apply(null, arguments)
            }
        })
        .filter('sExceptionNotificationParser', [function () {
            /**
             * @param {String} input
             * @param {*}      context
             * @param {Object} actionHandlers
             * @returns {Promise}
             */
            return function(input, context, actionHandlers) {
                var regex = /{{'([^']+)':'([^']+)'}}/gm;

                return input.replace(regex, function (match, type, value) {
                    switch (type) {
                        case 'Message':
                            if (!(context instanceof Model.Message)) {
                                break;
                            }

                            var clickHandlerString = '';

                            if (actionHandlers.message) {
                                clickHandlerString = 'actionHandlers.message(\'' + value + '\')'
                            }

                            return '<a ng-click="' + clickHandlerString + '">'
                                + context.getMessageDescription()
                                + '</a>';
                    }

                    return 'hidden';
                });
            }
        }])
    ;
})(window);
