(function (ns) {
    /**
     * @namespace
     * @alias Controller.Directive.sSegmentInfo
     *
     * @param $scope
     * @param $element
     * @param $mdPanel
     * @param $filter
     * @param apiEndpoints
     *
     * @constructor
     */
    var sSegmentInfo = function ($scope, $mdPanel, $element, $filter, apiEndpoints) {
        this.$scope       = $scope;
        this.$mdPanel     = $mdPanel;
        this.$element     = $element;
        this.$filter      = $filter;
        this.apiEndpoints = apiEndpoints;
        this.$deRegister  = [];
    };

    /**
     * @function
     * @name Controller.Directive.sSegmentInfo#$onInit
     */
    sSegmentInfo.prototype.$onInit = function $onInit() {
        var self = this;

        this.$deRegister = this.$deRegister.concat(this.$element.$on('click', function() {
            self.segment = self.$scope.sSegmentInfo;
            self.openPanel();
        }));
    };

    /**
     * @function
     * @name Controller.Directive.sSegmentInfo#$onDestroy
     */
    sSegmentInfo.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name Controller.Directive.sSegmentInfo#openPanel
     */
    sSegmentInfo.prototype.openPanel = function openPanel() {
        var position = this.$mdPanel.newPanelPosition()
                .relativeTo(this.$element)
                .addPanelPosition(
                    this.$mdPanel.xPosition.ALIGN_START,
                    this.$mdPanel.yPosition.BELOW
                ),
            self = this
        ;

        var config = {
            attachTo: angular.element(Const.PanelAnchor),
            resolve: {
                segmentFiltered: function() {
                    return self.$filter('humanReadableConditions')(self.segment).then(function(data) {
                        return self.filteredSegment = data;
                    });
                }
            },
            controller: function() {
                this.segment = self.segment;
                return self;
            },
            controllerAs: '$ctrl',
            templateUrl: '_directive:s-segment-info-description',
            panelClass: 'panel-default md-list panel-segment-info',
            position: position,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: false,
            zIndex: Const.PanelAboveDialogZIndex,
            onCloseSuccess: function(mdPanelRef) {
                // enforce destroy of inner scope, so all the components get freed properly
                if (!mdPanelRef) {
                    return;
                }

                mdPanelRef.destroy();
            }
        };

        return this.$mdPanel.open(config);
    };

    ns.sSegmentInfo = sSegmentInfo;
})(Object.namespace('Controller.Directive'));
