(function (ns) {
    /**
     * @namespace
     * @alias Controller.Component.sMarkerSelect
     *
     * @constructor
     * @param {Service.sMarkerRepository} sMarkerRepository
     */
    var sMarkerSelect = function sMarkerSelect(sMarkerRepository) {
        /**
         * @function
         * @name Controller.Component.sMarkerSelect#getMarkers
         * @returns {Model.Marker[]}
         */
        this.getMarkers = sMarkerRepository.getItems.bind(sMarkerRepository);

        /**
         * @type String
         * @name Controller.Component.sMarkerSelect#placeholder
         */

        /**
         * @type String
         * @name Controller.Component.sMarkerSelect#defaultName
         */
    };

    sMarkerSelect.prototype.$onInit = function $onInit() {
        var defaultName = this.defaultName || Model.Marker.MARKER_DEFAULT_NAME,
            prefixes   = {
                'a'  : [Model.Marker.MARKER_GOAL_NAME, Model.Marker.MARKER_DEFAULT_NAME],
                'an' : []
            },
            prefix;

        for (prefix in prefixes) {
            if (prefixes.hasOwnProperty(prefix)) {
                if (prefixes[prefix].indexOf(defaultName) !== -1) {
                    this.placeholder = 'Choose ' + prefix + ' ' + defaultName;
                }
            }
        }

        if (!this.placeholder) {
            throw 'Please specify a prefix for marker name';
        }
    };

    /**
     * @function
     * @name Controller.Component.sMarkerSelect#createMarkerWithName
     * @param {String=} name
     * @returns {Model.Marker}
     */
    sMarkerSelect.prototype.createMarkerWithName = function createMarkerWithName(name) {
        name = name || this.defaultName;

        return Model.Marker.createMarkerWithName(name);
    };

    ns.sMarkerSelect = sMarkerSelect;
})(Object.namespace('Controller.Component'));
