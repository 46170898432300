(function(ns) {
    /**
     * @namespace
     * @alias sMessageAnchor.Controller.DuplicateController
     * @constructor
     *
     * @param $mdDialog
     * @param $scope
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {sMessageAnchor.Service.MessageAnchor[]} messageAnchors
     * @param {Service.sDomain} sDomainService
     */
    var DuplicateController = function (
        $mdDialog,
        $scope,
        sAPIAccess,
        messageAnchors,
        sDomainService
    ) {
        var self = this;

        this.$mdDialog          = $mdDialog;
        this.messageAnchors     = (messageAnchors instanceof Array ? messageAnchors : [messageAnchors]);
        this.domainIdTo         = this.messageAnchors.slice(0,1).pop().domainId;
        this.$scope             = $scope;
        this.sAPIAccess         = sAPIAccess;
        this.errorMsgs          = [];
        this.availableDomains   = [];
        this.savingInProgress   = false;
        this.activateOnTheFly   = false;
        this.canBeActivated     = this.messageAnchors.slice(0,1).pop().timeLastPublished.isValid();

        sDomainService.getDomains().then(function(domains) {
            self.availableDomains = domains;
            digestIfNeeded($scope);
        })
    };

    /**
     * @function
     * @name sMessageAnchor.Controller.DuplicateController#cancel
     */
    DuplicateController.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    /**
     * @function
     * @name sMessageAnchor.Controller.DuplicateController#duplicate
     * @returns {$.Deferred}
     */
    DuplicateController.prototype.duplicate = function duplicate() {
        var self = this;
        var formdata = new FormData();

        this.savingInProgress = true;
        this.errorMsgs = [];

        if (self.domainIdTo !== self.messageAnchors.slice(0,1).pop().domainId) {
            formdata.append('domainId', this.domainIdTo);
        }

        if (self.activateOnTheFly) {
            formdata.append('activate', this.activateOnTheFly);
        }

        for (var i = 0; i < this.messageAnchors.length; i++) {
            formdata.append('uuid[' + i + "]", this.messageAnchors[i].uuid);
        }

        return $.ajax({
            url         : this.sAPIAccess.endpoint('messageAnchor.duplicateMultiple').post(),
            method      : Const.Method.POST,
            context     : this,
            data        : formdata,
            processData : false,
            contentType : false
        })
        .then(
            function () {
                self.$mdDialog.hide({success: true});
            },
            function (jqXHR) {
                self.$mdDialog.hide({success: false});
                if (jqXHR.status === 409 && jqXHR.responseJSON) {
                    self.showDuplicateException(jqXHR.responseJSON);
                }
            }
        )
        .always(function () {
            self.$scope.$evalAsync(function() {
                self.savingInProgress = false;
            });
        });
    };

    /**
     * @function
     * @name sMessageAnchor.Controller.DuplicateController#showDuplicateException
     * @param {object} problemsByAnchorUuid
     */
    DuplicateController.prototype.showDuplicateException = function showDuplicateException(problemsByAnchorUuid) {
        var self = this;

        this.$mdDialog.show({
            controller          : sMessageAnchor.Controller.DuplicateErrorController,
            controllerAs        : '$ctrl',
            templateUrl         : 'smessageanchor:duplicate-error',
            parent              : angular.element(Const.PanelAnchor),
            clickOutsideToClose : false,
            resolve             : {
                domainName: function domainName(sDomainService) {
                    return sDomainService.getDomainById(self.domainIdTo).then(function(domain) {
                        return domain.name;
                    });
                }
            },
            locals: {
                messageAnchors      : this.messageAnchors,
                problemsByAnchorUuid: problemsByAnchorUuid
            }
        })
    };

    ns.DuplicateController = DuplicateController;
})(Object.namespace('sMessageAnchor.Controller'));
