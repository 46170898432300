(function(ns) {

    /**
     * @namespace
     * @alias Controller.Directive.Accordion.sAccordionBodyController
     * @constructor
     *
     * @param $scope
     * @param $element
     * @param $attrs
     * @param $injector
     */
    var sAccordionBodyController = function sAccordionBodyController($scope, $element, $attrs, $injector) {
        var d           = new Date(),
            ctrlSuffix  = '_' + d.getTime() + '_' + Math.floor(Math.random() * 1000),
            ctrlName    = '$accordionBodyCtrl' + ctrlSuffix
        ;

        sAccordionBodyController._pProto.constructor.call(this);

        this.$scope         = $scope;
        this.$element       = $element;
        this.$attrs         = $attrs;
        this.$injector      = $injector;
        this.$deRegister    = [];

        Object.defineProperties(this, {
            'ctrlName': {
                get: function() {
                    return ctrlName;
                }
                /**
                 * @property
                 * @name Controller.Directive.Accordion.sAccordionBodyController#ctrlName
                 * @type {String}
                 */
            }
        });

        /**
         * @property
         * @name $attrs.sAccordionBody
         * @type Controller.Directive.Accordion.sAccordionBodyController
         */
    };

    Object.extendProto(sAccordionBodyController, ns.sAccordionBehaviorAwareController);

    /**
     * @function
     * @name Controller.Directive.Accordion.sAccordionBodyController#$onInit
     */
    sAccordionBodyController.prototype.$onInit = function $onInit() {
        var self = this;

        this.$deRegister.push(this.$scope.$watch(
            function () {
                return self.$attrs.sAccordionHead;
            },
            function (value) {
                if (!value || value === self.accordionGroupId) {
                    return;
                }
                self.removeFromAccordion();
                self.accordionGroupId = value;
                self.accordionBehaviorController = self.sAccordionBehaviorCtrl;
            }
        ));

        this.$deRegister = this.$deRegister.concat(this.$element.$on('click', function () {
            self.sAccordionBehaviorCtrl.touched = true;
        }));
    };

    /**
     * @function
     * @name Controller.Directive.Accordion.sAccordionBodyController#$onDestroy
     */
    sAccordionBodyController.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }

        this.removeFromAccordion();
    };

    /**
     * @function
     * @name Controller.Directive.Accordion.sAccordionBodyController#$postLink
     */
    sAccordionBodyController.prototype.$postLink = function $postLink() {
        this.accordionGroupId               = this.$attrs.sAccordionBody;
        this.accordionBehaviorController    = this.sAccordionBehaviorCtrl;

        var $scope = this.accordionBehaviorController.$scope;
        $scope[this.ctrlName] = this;

        if (!this.$attrs.ngShow) {
            this.$attrs.$set('ngShow', this.ctrlName + '.isVisible()');
            var directive = this.$injector.get('ngShowDirective')[0];
            directive.compile(this.$element, this.$attrs)($scope, this.$element, this.$attrs);
        }
    };

    ns.sAccordionBodyController = sAccordionBodyController;
})(Object.namespace('Controller.Directive.Accordion'));
