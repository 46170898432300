(function(ns) {
    /**
     * @namespace
     * @alias sAnalytics.Controller.Charts
     * @constructor
     *
     * @param $scope
     * @param {{}} charts
     * @param $location
     * @param $rootScope
     */
    var Charts = function (
        $scope,
        charts,
        $location,
        $rootScope
    ) {
        this.$scope             = $scope;
        this.$location          = $location;
        this.$rootScope         = $rootScope;
        this.$deRegister        = [];

        var defaultDateRange        = new Model.DateRange(),
            self                    = this,
            queue                   = [],
            progress                = $.Deferred().resolve(),
            variant                 = 'absolute'
        ;

        defaultDateRange.from       = moment().subtract(1, 'months').startOf('day');
        defaultDateRange.to         = moment().endOf('day');

        this.filtersConfig = {
            time: {
                component: 'sDateRangePicker',
                template: '_component:s-dynamic-form-row',
                options: {
                    label: 'Date Range',
                    defaultValue: defaultDateRange,
                    asJson: function(key, value) {
                        if (key) {
                            return value;
                        }
                        var dateRange = new Model.DateRange();
                        dateRange.from = moment(value.from);
                        dateRange.to = moment(value.to);
                        return dateRange;
                    }
                }
            },
            interval: {
                component: 'sSelect',
                template: '_component:s-dynamic-form-row',
                options: {
                    label: 'Interval',
                    defaultValue: 'day',
                    attrs: {
                        'choices'       : '[{key: \'hour\', label: \'Hour\', value: 3600}, {key: \'day\', label: \'Day\', value: 86400}]',
                        'class'         : 'interval-select',
                        'view-field'    : 'label',
                        'value-field'   : 'key',
                        'ng-required'   : true,
                        'is-item-disabled'   : '$ctrl.helperControls.$scope.$parent.$ctrl.disableItem(item, $ctrl.model)'
                    }
                }
            }
        };
        
        Object.defineProperties(
            this,
            {
                loading: {
                    get: function () {
                        return Boolean(queue.length);
                    }
                    /**
                     * @property
                     * @name sAnalytics.Controller.Charts#loading
                     * @type {Boolean}
                     */
                },
                variant: {
                    enumerable: true,
                    configurable: true,
                    get: function () {
                        return variant;
                    },
                    set: function (val) {
                        if (val === variant) {
                            return;
                        }
                        variant = val;
                        var $search = self.$location.search();

                        if ($search.variant !== variant) {
                            self.$location.search('variant', variant);
                        }
                    }
                    /**
                     * @property
                     * @name sAnalytics.Controller.Charts#variant
                     * @type {String}
                     */
                }
            });

        var $search = $location.search();

        // If nothing is set, use the first chart
        if (!$search.type || !charts[$search.type]) {
            $location.search('type', Object.getFirstPropertyName(charts) || '');
        }

        this.current = $search.type;

        if ($search.variant) {
            this.variant = $search.variant;
        }

        this.charts = charts;

        $scope.$on('$destroy', this.$onDestroy.bind(this));

        this.$deRegister = this.$deRegister.concat($(document).$on(Model.Series.EVENT_LOAD, function(evt, series) {
            progress = $.Deferred();
            var loading = series.loading;
            series.loading.always(function() {
                // once loaded delete from the queue
                queue.splice(queue.indexOf(loading), 1);
                if (!queue.length) {
                    // if the last one was deleted we're done
                    progress.resolve();
                }
            });

            // if one series is getting loaded > add it to the queue of loaded series
            queue.push(loading);
            if (series !== self.charts[self.current].bag) {
                return;
            }

            // if the main series is getting updated, synchronize its filters with the others
            self.syncFilters();
        }));

        this.$deRegister.push(this.$rootScope.$on('$locationChangeStart', function() {
            var $search = self.$location.search();

            progress.then(function() {
                if ($search.type && $search.type !== self.current) {
                    self.changeToType($search.type);
                }

                if ($search.variant && $search.variant !== self.variant) {
                    self.variant = $search.variant;
                }
            });
        }));
    };

    /**
     * @function
     * @name sAnalytics.Controller.Charts#$onDestroy
     */
    Charts.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * Apply the filters from the main chart to the other charts
     */
    Charts.prototype.syncFilters = function syncFilters() {
        var filters  = this.charts[this.current].bag.filters,
            self    = this
        ;

        $.each(this.charts, function(name, element) {
            if (name === self.current) {
                return;
            }

            element.bag.filters.clear();
            filters.editableFilters.map(
                function(filter) {
                    element.bag.filters.createAndAdd(filter.name, filter.value);
                });
        });
    };

    /**
     * @function
     * @name sAnalytics.Controller.Charts#changeToType
     * @param {string} chartType
     */
    Charts.prototype.changeToType = function changeToType(chartType) {
        if (!this.charts[chartType]) {
            return;
        }

        var self = this;
        this.current = chartType;
        this.$location.search('type', chartType);

        this.charts[this.current].bag.load().then(function(bag) {
            if (!bag.variants.length) {
                return bag;
            }

            if (!bag.variants.filter(function(variant) {
                    return variant.label === self.variant;
                }).length) {
                self.variant = bag.variants.slice(0, 1).pop().value;
            }
        })
    };

    /**
     * Callback to decide if the resolution item is available in the select
     * @name sAnalytics.Controller.Charts#disableItem
     * @param {Object} item
     * @param {Object} filters
     * @returns {boolean}
     */
    Charts.prototype.disableItem = function disableItem(item, filters) {
        var diff = filters.time.to.unix() - filters.time.from.unix();


        /**
         * If the number of points would exceed 24 * 32 = 768, then disable hourly resolution,
         * the cap is set for 31 days as 30 would cut 7/12 of the months
         */
        if (Math.floor(diff / item.value) > 768) {
            item.disabledHint = 'Hourly resolution is available for maximum 32 days';
            return true;
        }

        if (item.disabledHint) {
            item.disabledHint = null;
        }

        return false;
    };

    /**
     * @name sAnalytics.Controller.Charts#getTemplate
     * @param {Object} chart
     * @param {Boolean} isThumb
     * @returns {?String}
     */
    Charts.prototype.getTemplate = function getTemplate(chart, isThumb) {
        if(!chart) {
            return null;
        }

        if (isThumb) {
            return 'default|thumb|stackedBar' + (chart.thumbExtra ? '|' + chart.thumbExtra : '');
        }

        var template = chart.template;

        // special template for hourly interval
        if (template.indexOf('timeRangeXAxis') !== -1 && chart.bag.period === 3600) {
            template += '|timeRangeXAxisHour';
        }

        if (this.variant === 'relative') {
            template += '|percent';
        }

        return template;
    };

    ns.Charts = Charts;
})(Object.namespace('sAnalytics.Controller'));