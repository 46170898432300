(function(ns) {
    var CONST_MAX_ANIMATED_IMAGE_SIZE_IN_PX  = 960,
        CONST_MAX_IMAGE_SIZE_IN_MP            = 32,
        CONST_ANIMATED_IMG_TYPES             = [{'gif': isAnimatedGif}],
        CONST_TYPE_IMAGE                     = 'image'
    ;

    /**
     * @typedef {Object} CropData
     * @property {Number} left
     * @property {Number} top
     * @property {Number} width
     * @property {Number} height
     */

    /**
     * @namespace
     * @alias Model.sImage
     * @extends Model.sFile
     */
    var sImage = function(file, uuid) {
        Model.Behavior.Meta.call(this);
        var reSizedImage        = null,
            imageObj            = new Image(),
            canvas              = document.createElement('canvas'),
            cropCanvas          = document.createElement('canvas'),
            self                = this,
            isLoading
            ;

        cropCanvas.width = 0;
        cropCanvas.height = 0;

        imageObj.setAttribute('crossOrigin', 'anonymous');


        Object.defineProperties(this, {
            imageObj: {
                get: function() {
                    return imageObj;
                }
                /**
                 * @type {Image}
                 * @property
                 * @name Model.sImage#imageObj
                 */
            },
            reSizedImage: {
                get     : function() {
                    return reSizedImage;
                }
                /**
                 * @type {Model.sImage}
                 * @property
                 * @name Model.sImage#reSizedImage
                 */
            },
            related: {
                enumerable  : true,
                get         : function() {
                    return [reSizedImage];
                }
                /**
                 * @type {Model.sImage[]}
                 * @property
                 * @name Model.sImage#related
                 */
            },
            canvas: {
                enumerable: false,
                configurable: false,
                get     : function() {
                    return canvas;
                }
                /**
                 * @type {Canvas}
                 * @property
                 * @name Model.sFile#canvas
                 */
            },
            cropCanvas : {
                enumerable: false,
                configurable: false,
                get : function () {
                    return cropCanvas;
                }
                /**
                 * @type {Canvas}
                 * @property
                 * @name Model.sFile#cropCanvas
                 */
            },
            cropCanvasChanged : {
                enumerable: false,
                writable: true
                /**
                 * @type {Boolean}
                 * @property
                 * @name Model.sFile#cropCanvasChanged
                 */
            },
            options : {
                enumerable: true,
                configurable: false,
                get : function () {
                    return self.getMetasJson();
                }
                /**
                 * @type {Object}
                 * @property
                 * @name Model.sFile#options
                 */
            },
            imagePromise: {
                enumerable: true,
                get: function () {
                    return self.loadContent().then(function() {
                        return sImage.loadImage(imageObj, self.content);
                    });
                }
                /**
                 * @property
                 * @name Model.sImage#imagePromise
                 * @type {PromiseLike}
                 */
            }
        });

        /**
         * @name Model.sImage#setResizedImageContent
         * @method
         * @param {String} content
         * @param {Object} crop
         * @param {String=} uuid
         * @param {String=} url
         * @return {PromiseLike}
         */
        this.setResizedImageContent = function setResizedImageContent(content, crop, uuid, url) {
            if(!this.cropCanvasChanged && reSizedImage) {
                this.cropCanvasChanged = true;
            }

            var blob = dataURItoBlob(content);
            blob.fileName = file.fileName + '-cropped';

            reSizedImage = new sImage(blob, uuid);
            reSizedImage.setMeta('crop', crop);
            reSizedImage.setMeta('keepOriginal', 1);

            if (url) {
                Model.sFile.extendBlobWithUrl(blob, url);
            }

            return reSizedImage.imagePromise;
        };

        sImage._pProto.constructor.call(this, file, uuid);

        var loadContentAncestor = this.loadContent;
        this.loadContent = function loadContent() {
            if (isLoading) {
                return isLoading;
            }

            var $deferred = $.Deferred(),
                self       = this
            ;

            isLoading = $deferred;

            loadContentAncestor.call(this).then(function () {
                sImage.loadImage(self.imageObj, self.content)
                    .then(function (img) {
                        var isAnimated = self.isAnimated();
                        if (isAnimated && (img.width > CONST_MAX_ANIMATED_IMAGE_SIZE_IN_PX || img.height > CONST_MAX_ANIMATED_IMAGE_SIZE_IN_PX)) {
                            var error = new Error('Animated image dimension is bigger then ' + CONST_MAX_ANIMATED_IMAGE_SIZE_IN_PX + 'x' + CONST_MAX_ANIMATED_IMAGE_SIZE_IN_PX + '!');
                            $deferred.notify(error);
                            $deferred.reject(error.message);
                        } else if (!isAnimated && (img.width * img.height / 1024 / 1024) > CONST_MAX_IMAGE_SIZE_IN_MP){
                            var error = new Error('Image dimension is bigger then ' + CONST_MAX_IMAGE_SIZE_IN_MP + ' Megapixels!');
                            $deferred.notify(error);
                            $deferred.reject(error.message);
                        } else {
                            $deferred.resolve(self);
                        }
                    });
            });
            return $deferred.promise();
        };

        this.loadContent().then(function () {
            sImage.loadImage(imageObj, self.content);
        });


        this.resetReSizedImage = function resetReSizedImage() {
            reSizedImage = null;
        };

        /**
         * @property
         * @type HTMLImageElement
         * @name Model.sImage#imageObj
         */
    };

    Object.extendProto(sImage, Model.sFile);

    /**
     * @param {Number|Array} _size
     * @param {CropData=} _crop
     */
    sImage.prototype.resize = function resize(_size, _crop) {
        var self        = this,
            $deferred   = $.Deferred(),
            crop,
            storedCrop
            ;

        if (this.isAnimated()) {
            return $deferred.reject().promise();
        }

        if (_crop && self.reSizedImage && (storedCrop = (self.reSizedImage.getMeta('crop') || {}))) {
            var isNewCrop = false
                ;

            // compare all props
            for (var i in _crop) {
                if (_crop[i] !== storedCrop[i]) {
                    isNewCrop = true;
                    break;
                }
            }

            if (!isNewCrop) {
                return $deferred.resolve(self).promise();
            }
        }

        this.loadContent().then(function() {
            sImage.loadImage(self.imageObj, self.content).then(function(){

                crop        = _crop || {
                        left    : 0,
                        top     : 0,
                        width   : self.imageObj.width,
                        height  : self.imageObj.height
                    }
                ;

                // BUG-45: don't allow crop areas that are outside the actual image boundaries
                crop.left   = Math.max(crop.left, 0);
                crop.top    = Math.max(crop.top, 0);
                crop.width  = Math.min(self.imageObj.width, crop.width);
                crop.height = Math.min(self.imageObj.height, crop.height);


                var ctx = self.cropCanvas.getContext('2d');
                self.cropCanvas.width = crop.width;
                self.cropCanvas.height = crop.height;

                // use crop here to have values, otherwise the function would clear the image
                ctx.drawImage(self.imageObj, crop.left, crop.top, crop.width, crop.height, 0, 0, crop.width, crop.height);
                var size = self.calculateImageSize(_size, crop)
                    ;

                self.canvas.width   = size.width;
                self.canvas.height  = size.height;

                var ratios = [
                        self.canvas.width / self.imageObj.width,
                        self.canvas.height / self.imageObj.height
                    ],
                    maxRatio = Math.max.apply(this, ratios.concat(1)),
                    multiplicator = 1 - maxRatio
                ;

                pica.resizeCanvas(
                    self.cropCanvas,
                    self.canvas,
                    {
                        unsharpAmount   : multiplicator * 80,
                        unsharpRadius   : 0.499 + multiplicator * 0.1,
                        unsharpThreshold: multiplicator * 2,
                        alpha: true
                    },
                    function(){
                        // use _crop here so if it was not cropped, then it will not be set
                        var mimeType = self.file ? self.file.type : '';
                        self.setResizedImageContent(self.canvas.toDataURL(mimeType, 0.9), _crop).then(function() {
                            $deferred.resolve(self);
                        });
                    }
                );
            });
        });
        return $deferred.promise();
    };

    /**
     * @param {HTMLImageElement} image
     * @param {String} content
     */
    sImage.loadImage = function(image, content) {
        var $deferred   = $.Deferred()
            ;

        // for data: remove crossOrigin
        if (content.search(/^data\:/) === 0) {
            image.removeAttribute('crossOrigin');
        } else {
            image.setAttribute('crossOrigin', 'anonymous');
        }

        // sometimes even the uploaded image is not loaded yet
        image.onload = function() {
            $deferred.resolve(image);
        };

        image.onerror = function() {
            $deferred.reject();
        };

        if (image.src !== content) {
            image.src = content;
        } else if (image.complete) {
            $deferred.resolve(image);
        }

        return $deferred.promise();
    };

    /**
     * Calculate new size of image considering size
     *
     * size = [640,480], fit into a rect of 640x480 pixels
     * size = 400, fit into a box of 400 pixels
     *
     * @param {Number|Array} size
     * @param _image
     */
    sImage.prototype.calculateImageSize = function calculateImageSize(size, _image) {
        var image = _image || this.imageObj
            ;

        var ret = {
            width: image.width,
            height: image.height
        };

        if (!size) {
            return ret;
        }

        if (!(size instanceof Array)) {
            size = [size, size];
        }

        var ratios = [
            Math.min((size[0] / image.width), 1),
            Math.min((size[1] / image.height), 1)
        ];

        // no resize needed
        if (ratios[0] === 1 && ratios[1] === 1) {
            return ret;
        }

        var minRatio = Math.min.apply(this, ratios);
        ret.width = image.width * minRatio;
        ret.height = image.height * minRatio;

        return ret;
    };

    /**
     * @return {Boolean}
     */
    sImage.prototype.isAnimated = function isAnimated() {
        if (!this.content) {
            throw new Error('Image content not loaded!');
        }

        var arr = dataURItoUint8Array(this.content);
        return CONST_ANIMATED_IMG_TYPES.reduce(function (isAnimatedFound, element) {
            if (isAnimatedFound) {
                return isAnimatedFound;
            }

            isAnimatedFound = this.file.type.search(new RegExp(Object.getFirstPropertyName(element) + '$')) !== -1 && (Object.getFirstProperty(element)(arr));

            return isAnimatedFound;
        }.bind(this), false);
    };

    Object.defineProperties(
        sImage,
        {
            CONST_TYPE_IMAGE: {
                value: CONST_TYPE_IMAGE
                /**
                 * @property
                 * @constant
                 * @name Model.sImage#CONST_TYPE_IMAGE
                 * @type {String}
                 */
            }
        });

    ns.sImage = sImage;
})(Object.namespace('Model'));
