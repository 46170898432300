(function (ns) {
    /**
     * This class acts more or less as a trait
     *
     * @namespace
     * @alias Model.Behavior.QualityScore
     *
     * @abstract
     * @param {Object} scoreCalculationConfig
     */
    var QualityScore = function (scoreCalculationConfig) {
        if (this.constructor === QualityScore) {
            throw 'Behavior should not be instantiated!';
        }

        var self        = this,
            score       = null,
            cachedModel = null;

        Object.defineProperties(
            this,
            {
                qualityScore: {
                    get: function () {
                        if (cachedModel !== self.toString()) {
                            score = calculateScore.call(self, scoreCalculationConfig);
                            cachedModel = self.toString();
                        }
                        return score;
                    }
                    /**
                     * @property
                     * @name Model.Behavior.QualityScore#qualityScore
                     * @type {Number}
                     */
                },
                qualityScoreHints: {
                    writable: true
                    /**
                     * @property
                     * @name Model.Behavior.QualityScore#qualityScoreHints
                     * @type {Array<String>}
                     */
                }
            }
        );

        this.qualityScoreHints = [];
    };

    /**
     * @function
     * @name Model.Behavior.QualityScore~calculateScore
     * @param {Object} scoreCalculationConfig
     * @return {?Number}
     */
    var calculateScore = function calculateScore(scoreCalculationConfig) {
        var self            = this,
            totalWeight     = 0,
            scoreSum,
            tempScoreHints  = [],
            scoreList       = scoreCalculationConfig.rules.map(function (rule) {
                if (typeof rule.calculationFn !== 'function') {
                    return null;
                }

                var tempScore = rule.calculationFn.call(self, rule.config || {});

                if (tempScore === null) {
                    return null;
                }

                totalWeight += rule.weight;
                return tempScore * rule.weight;
            });

        scoreSum = scoreCalculationConfig.rules.reduce(
            function (currentScore, rule, index) {
                if (scoreList[index] === null) {
                    return currentScore;
                }

                var score = scoreList[index] / totalWeight;

                if (rule.hint) {
                    tempScoreHints.push(buildHintForScore(score, totalWeight, scoreCalculationConfig.maxScore, rule));
                }

                return currentScore + score;
            },
            0
        );

        if (totalWeight > 0) {
            this.qualityScoreHints = [
                buildHintForScore(scoreSum, 1, scoreCalculationConfig.maxScore, {hint: scoreCalculationConfig.hint})
            ].concat(tempScoreHints);
            return scoreSum
        }

        this.qualityScoreHints = [];
        return null;
    };

    /**
     * @function
     * @name Model.Behavior.QualityScore~buildHintForScore
     * @param {Number} score
     * @param {Number} totalWeight
     * @param {Number} maxScore
     * @param {Object} rule
     * @return {String}
     */
    var buildHintForScore = function buildHintForScore(score, totalWeight, maxScore, rule) {
        var tokenList = ['maxScore', 'score'],
            hint = rule.hint
        ;

        if (rule && rule.config) {
            tokenList = tokenList.concat(Object.keys(rule.config));
        }

        // sort tokenlist so bigger words get replaced first
        tokenList.sort(function (a, b) {
            return b.length - a.length
        });

        for (var i = 0; i < tokenList.length; i++) {
            var replacement = '';

            switch (tokenList[i]) {
                case 'score':
                    replacement = (+(score * maxScore).toFixed(2));
                    break;
                case 'maxScore':
                    replacement = (rule.weight)
                        ? (+((rule.weight / totalWeight) * maxScore).toFixed(2))
                        : maxScore;
                    break;
                default:
                    replacement = rule.config[tokenList[i]];
            }

            hint = hint.replace(new RegExp('\:' + tokenList[i], "g"), replacement);
        }

        return hint;
    };

    ns.QualityScore = QualityScore;
})(Object.namespace('Model.Behavior'));
