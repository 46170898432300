(function (ns) {
    /**
     * @namespace
     * @alias Model.Exception.MessagePartsConflict
     *
     * @extends Error
     * @constructor
     * @param {Model.Message}  message
     */
    var MessagePartsConflict = function (message) {
        this.message = 'Message has conflicting messageParts';
        this.payload = message;
    };

    Object.extendProto(MessagePartsConflict, Error);

    ns.MessagePartsConflict = MessagePartsConflict;
})(Object.namespace('Model.Exception'));
