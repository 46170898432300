(function (ns) {
    var EVENT_MESSAGE_ACTION_BOX_ACTION_IN_PROGRESS = 'eventMessageActionBoxActionInProgress',
        EVENT_SAVING_FINISHED                       = 'eventMessageActionBoxSavingFinished',
        EVENT_ACTIVATION_FINISHED                   = 'eventMessageActionBoxActivationFinished'
    ;

    /**
     * @namespace
     * @alias Controller.Component.sMessageCollectionActionBox
     *
     * @param $scope
     * @param {Service.sMessageCollection} sMessageCollection
     * @param Notification
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     */
    var sMessageCollectionActionBox = function ($scope, sMessageCollection, Notification, sAPIAccess) {
        this.isEnterAddingMessage = true;
        this.actionInProgress     = false;
        this.$scope               = $scope;
        this.sMessageCollection   = sMessageCollection;
        this.notification         = Notification;
        this.sAPIAccess           = sAPIAccess;

        /**
         * @property
         * @function
         * @name Controller.Component.sMessageCollectionActionBox#onClickSave
         */

        /**
         * @property
         * @type Boolean
         * @name Controller.Component.sMessageCollectionActionBox#actionInProgress
         */
    };

    /**
     * @function
     * @name Controller.Component.sMessageCollectionActionBox#$onInit
     */
    sMessageCollectionActionBox.prototype.$onInit = function $onInit() {
        this.initActionsMenu();
    };

    sMessageCollectionActionBox.prototype.refresh = function refresh() {
        this.updateActionInProgress(false);
        this.initActionsMenu();
        digestIfNeeded(this.$scope);
    };

    sMessageCollectionActionBox.prototype.initActionsMenu = function initActionsMenu() {
        if(this.actionsMenu && this.actionsMenu.primaryAction.label === 'Activate') {
            return;
        }

        var actions = [];

        // Add save item if allowed
        if (this.sAPIAccess.isAllowed('message.RESTAccess', Const.Method.POST)) {
            actions.push(new Model.Menu.Action('Save', this.save.bind(this)));
        }

        // Add activate action if allowed
        if (this.sAPIAccess.isAllowed('messageAnchor.publish', Const.Method.PUT)) {
            var activateAction = new Model.Menu.Action('Activate', this.publish.bind(this));

            // If conversation is a draft, primary button is Save, otherwise primary button is Activate
            if(this.collection.getAnchor().status === Model.Message.Anchor.STATUS_DRAFT) {
                actions.push(activateAction);
            } else {
                actions.unshift(activateAction);
            }
        }

        // Add send item if allowed
        if (this.sAPIAccess.isAllowed('messageAnchor.send', Const.Method.PUT)
            && this.sAPIAccess.isAllowed('messageAnchor.publish', Const.Method.PUT)
            && !this.collection.getRootMessage().isIncoming
        ) {
            actions.push(new Model.Menu.Action('Send', this.send.bind(this)));
        }

        var primaryAction = actions.shift(),
            actionsMenu;

        if(actions.length) {
            actionsMenu = new Model.Menu.Menu();

            actions.map(function (action) {
                actionsMenu.addItem(action);
            });
        }

        this.actionsMenu = {
            primaryAction : primaryAction,
            actions       : actionsMenu
        };
    };

    sMessageCollectionActionBox.prototype.save = function save() {
        var self = this,
            anchor = this.collection.getAnchor(),
            startTime = (new Date()).getTime()
            trackingData = { status: anchor.status }
        ;

        this.updateActionInProgress(true);
        this.sMessageCollection
            .validateAndSave(this.collection)
            .then(
                function() {
                    trackingData.success = true;
                    
                    if(anchor && anchor.type && anchor.type.alias === Model.Message.TYPE_ALIAS_REACTION) {
                        trackingData.aiTemplateId = anchor.messageUuid;
                    } else {
                        trackingData.conversationId = anchor.messageUuid;
                    }
                },
                function () {
                    trackingData.success = false;
                    
                    if(anchor && anchor.type && anchor.type.alias === Model.Message.TYPE_ALIAS_REACTION) {
                        trackingData.aiTemplateId = anchor.messageUuid;
                    } else {
                        trackingData.conversationId = anchor.messageUuid;
                    }
                }
            ).always(function () {
                self.refresh.call(self);

                self.$scope.$emit(
                    EVENT_SAVING_FINISHED,
                    startTime,
                    anchor && anchor.type ? anchor.type.alias : null,
                    trackingData
                );
            });
    };

    sMessageCollectionActionBox.prototype.publish = function publish() {
        var self = this,
            anchor = this.collection.getAnchor(),
            startTime = (new Date()).getTime(),
            trackingData = {
                tags: anchor.tags,
                title: anchor.title
            }
        ;

        this.updateActionInProgress(true);
        this.sMessageCollection
            .confirmAndSaveAndActivate(this.collection.getRootMessage().messageAnchor)
            .then(
                function() {
                    self.notification.success('Activation complete.');
                    trackingData.success = true;
                },
                function () {
                    trackingData.success = false;
                }
            ).always(function () {
                self.refresh.call(self);

                trackingData.status = anchor.status;
                trackingData.lastUpdated = anchor.timeUpdated.format('DD.MM.YYYY HH:mm');
                if(anchor && anchor.type && anchor.type.alias === Model.Message.TYPE_ALIAS_REACTION) {
                    trackingData.aiTemplateId = anchor.messageUuid;
                } else {
                    trackingData.conversationId = anchor.messageUuid;
                }

                self.$scope.$emit(
                    EVENT_ACTIVATION_FINISHED,
                    startTime,
                    anchor && anchor.type ? anchor.type.alias : null,
                    trackingData
                );
            });
    };

    sMessageCollectionActionBox.prototype.send = function send() {
        this.updateActionInProgress(true);
        this.sMessageCollection
            .setupAndSaveAndSend(this.collection.getRootMessage().messageAnchor, this.$scope)
            .always(this.refresh.bind(this));
    };

    /**
     * @function
     * @name Controller.Component.sMessageCollectionActionBox#updateActionInProgress
     * @param {Boolean} actionInProgress
     */
    sMessageCollectionActionBox.prototype.updateActionInProgress = function updateActionInProgress(actionInProgress) {
        this.actionInProgress = actionInProgress;
        this.$scope.$parent.$broadcast(EVENT_MESSAGE_ACTION_BOX_ACTION_IN_PROGRESS, actionInProgress);
    };

    Object.defineProperties(
        sMessageCollectionActionBox,
        {
            EVENT_MESSAGE_ACTION_BOX_ACTION_IN_PROGRESS : {
                value : EVENT_MESSAGE_ACTION_BOX_ACTION_IN_PROGRESS
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sMessageCollectionActionBox#EVENT_MESSAGE_ACTION_BOX_ACTION_IN_PROGRESS
                 * @type {String}
                 */
            },
            EVENT_SAVING_FINISHED : {
                value : EVENT_SAVING_FINISHED
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sMessageCollectionActionBox#EVENT_SAVING_FINISHED
                 * @type {String}
                 */
            },
            EVENT_ACTIVATION_FINISHED : {
                value : EVENT_ACTIVATION_FINISHED
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sMessageCollectionActionBox#EVENT_ACTIVATION_FINISHED
                 * @type {String}
                 */
            }
        });

    ns.sMessageCollectionActionBox = sMessageCollectionActionBox;
})(Object.namespace('Controller.Component'));
