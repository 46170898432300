(function (ns) {
    var savingInProgress = false;

    /**
     * @namespace
     * @alias sInbox.Controller.AddUnmatchedToAITemplate
     * @constructor
     *
     * @param $mdDialog
     * @param $scope
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {Service.sMessageCollection} sMessageCollection
     * @param {Service.sConversationValidator} sConversationValidator
     */
    var AddUnmatchedToAITemplate = function (
        $mdDialog,
        $scope,
        sAPIAccess,
        sMessageCollection,
        sConversationValidator
    ) {
        this.$mdDialog = $mdDialog;
        this.userInputs = (this.userInputs instanceof Array ? this.userInputs : [this.userInputs]);
        this.$scope = $scope;
        this.sAPIAccess = sAPIAccess;
        this.sConversationValidator = sConversationValidator;
        this.showLoader = false;
        this.AITemplateCanBeSaved = false;
        this.AITemplateCanBeActivated = false;
        this.model = 'Model.AI.Reaction';
        this.sMessageCollection = sMessageCollection;
        this.messageCollection = null;
        this.rules = {
            'priority' : {
                component: 'sPrioritySelect',
                options: {
                    attrs: {
                        model: "$ctrl.helperControls.priority"
                    },
                    label: 'Priority'
                }
            }
        };
        this.rules = $.extend(true, {}, this.rules, Model.Message.Part.getTypes('incoming').AIReaction.fields);
        savingInProgress = false;
        this.newAITemplateName = this.createNewAITemplate ?
            'Unmatched input from ' + moment().format('ddd, D.MMM YYYY (HHmm)') :
            null;
        this.title = this.createNewAITemplate ?
            ('Add the message' + (this.userInputs.length > 1 ? 's' : '') + ' to new AI template') :
            this.addAsFlag !== sInbox.Controller.IncomingMessage.FLAG_NO_ADD ?
            ('Where do you want to add the message' + (this.userInputs.length > 1 ? 's' : '') + '?') :
            'Template review';

        if (this.createNewAITemplate) {
            this.createEmptyAITemplate();
        }

        var self = this;

        this.handleAITemplateChange = function handleAITemplateChange(selection) {
            AddUnmatchedToAITemplate.prototype.handleAITemplateChange.call(self, selection);
        };

        if (this.AITemplateUuid) {
            this.showLoader = true;

            sMessageCollection.loadByMessageUuid(this.AITemplateUuid)
                .then(function (collection) {
                    self.loadAITemplate(collection.getAnchor());
                })
                .always(function () {
                    self.showLoader = false;
                });
        }

        this.$scope.$watch(
            function () {
                return self.addUnmatchedToAITemplateForm.$valid;
            }, function () {
                setTimeout(function () { // Has to be done asynchronously otherwise not working correctly
                    self.updateButtonsState();
                    digestIfNeeded(self.$scope);
                }, 0);
            });

        this.$scope.$watch(
            function () {
                return self.messageCollection ?
                    JSON.stringify(self.messageCollection.getRootMessage().firstPart().content.alternatives) : '';
            }, function (value) {
                if (value !== '') {
                    self.messageCollection.getRootMessage().firstPart().resetCache();
                }
            });

        Object.defineProperties(
            this,
            {
                messages         : {
                    enumerable : true,
                    get        : function () {
                        return self.messageCollection ? self.messageCollection.messages : [];
                    }
                    /**
                     * @property
                     * @name sInbox.Controller.AddUnmatchedToAITemplate#messages
                     * @type {Model.Message[]}
                     */
                },
                message          : {
                    enumerable : true,
                    get        : function () {
                        return self.messageCollection ? self.messageCollection.getRootMessage() : null;
                    }
                    /**
                     * @property
                     * @name sInbox.Controller.AddUnmatchedToAITemplate#message
                     * @type {Model.Message}
                     */
                },
                savingInProgress : {
                    get : function () {
                        return savingInProgress;
                    }
                    /**
                     * @property
                     * @name sInbox.Controller.AddUnmatchedToAITemplate#savingInProgress
                     * @type {Boolean}
                     */
                }
            }
        );

        /**
         * @property
         * @name sInbox.Controller.AddUnmatchedToAITemplate#userInput
         * @type {String|[]}
         */

        /**
         * @property
         * @name sInbox.Controller.AddUnmatchedToAITemplate#domainId
         * @type {Number}
         */

        /**
         * @property
         * @name sInbox.Controller.AddUnmatchedToAITemplate#AITemplateUuid
         * @type {?String}
         */

        /**
         * @property
         * @name sInbox.Controller.AddUnmatchedToAITemplate#createNewAITemplate
         * @type {?Boolean}
         */

        /**
         * @property
         * @name sInbox.Controller.AddUnmatchedToAITemplate#addAsFlag
         * @type {Number}
         */
    };

    /**
     * @function
     * @name sInbox.Controller.AddUnmatchedToAITemplate#addUnmatchedToAITemplate
     * @param {Boolean} activate
     * @returns {void|$.Deferred}
     */
    AddUnmatchedToAITemplate.prototype.addUnmatchedToAITemplate = function addUnmatchedToAITemplate(activate) {
        if (savingInProgress) {
            return;
        }

        savingInProgress = true;

        if (this.createNewAITemplate) {
            this.messageCollection.getAnchor().title = this.newAITemplateName;
        }

        var self = this;

        return this.messageCollection.save().then(function (response) {
            if (!activate) {
                self.hide(response[0].content.uuid, false);
                return $.Deferred().resolve();
            }

            return self.sMessageCollection.validateAndSaveAndActivate(
                self.messageCollection.getAnchor(),
                true
            ).then(function () {
                self.hide(response[0].content.uuid, true);
            }).always(function () {
                digestIfNeeded(self.$scope);
            });
        }).always(function () {
            digestIfNeeded(self.$scope);
        });
    };

    /**
     * Hide this dialog
     *
     * @param {Number} uuid
     * @param {Boolean} activate
     */
    AddUnmatchedToAITemplate.prototype.hide = function hide(uuid, activate) {
        this.$mdDialog.hide({
            messageUuid         : uuid,
            title               : this.messageCollection.getAnchor().title,
            activate            : activate,
            createNewAITemplate : this.createNewAITemplate,
            selection           : this.userInputs
        });
    };

    /**
     * @function
     * @name sInbox.Controller.AddUnmatchedToAITemplate#handleAITemplateChange
     */
    AddUnmatchedToAITemplate.prototype.handleAITemplateChange = function handleAITemplateChange(selection) {
        if (selection) {
            this.loadAITemplate(selection);
        }
    };

    /**
     * @function
     * @name sInbox.Controller.AddUnmatchedToAITemplate#cancel
     */
    AddUnmatchedToAITemplate.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    /**
     * @function
     * @name sInbox.Controller.AddUnmatchedToAITemplate#createEmptyAITemplate
     */
    AddUnmatchedToAITemplate.prototype.createEmptyAITemplate = function createEmptyAITemplate() {
        var collection = Model.Message.Collection.createEmptyAITemplate(this.domainId),
            rootMessage = collection.getRootMessage();

        this.addMatchTextToMessagePart(rootMessage.firstPart());

        rootMessage.messageAnchor.tags = ['Unmatched input'];

        this.messageCollection = collection;
        this.updateButtonsState();
    };

    /**
     * @function
     * @name sInbox.Controller.AddUnmatchedToAITemplate#loadAITemplate
     *
     * @param {Model.Message.Anchor} selection
     */
    AddUnmatchedToAITemplate.prototype.loadAITemplate = function loadAITemplate(selection) {
        var self = this;
        this.showLoader = true;

        this.sMessageCollection
            .loadByAnchor(selection)
            .then(function (collection) {
                if (collection) {
                    if (self.addAsFlag !== sInbox.Controller.IncomingMessage.FLAG_NO_ADD) {
                        self.addMatchTextToMessagePart(collection.getRootMessage().firstPart());
                    }
                    self.messageCollection = collection;
                    setTimeout(function () { // Has to be done asynchronously otherwise not working correctly
                        self.updateButtonsState();
                    }, 0);
                } else {
                    self.messageCollection = null;
                }
            })
            .always(function () {
                self.showLoader = false;
                digestIfNeeded(self.$scope);
            });
    };

    /**
     * @function
     * @name sInbox.Controller.AddUnmatchedToAITemplate#addMatchTextToMessagePart
     *
     * @param {Model.Message.Part} messagePart
     */
    AddUnmatchedToAITemplate.prototype.addMatchTextToMessagePart = function addMatchTextToMessagePart(messagePart) {
        var addedMessages = [],
            part, body, matchText, index;

        for (var i = 0; i < this.userInputs.length; i++) {
            for (var key in this.userInputs[i].messageParts) {

                if (!this.userInputs[i].messageParts.hasOwnProperty(key)) {
                    continue;
                }

                part = this.userInputs[i].messageParts[key];

                for (var j = 0; j < part.messageContents.length; j++) {
                    body = part.messageContents[j].body;

                    if (addedMessages.indexOf(body) !== -1) {
                        continue;
                    }

                    matchText = Model.AI.MatchText.createFromString(
                        body,
                        Model.Rule.TYPES.contains.alias,
                        !this.createNewAITemplate
                    );
                    index = 'matches';

                    if (this.addAsFlag === sInbox.Controller.IncomingMessage.FLAG_NEGATIVE) {
                        index = 'negativeMatches';
                        matchText.type = Model.Rule.TYPES.is_not.alias;
                    }

                    messagePart.content[index].addMatchText(matchText);

                    addedMessages.push(body);
                }
            }
        }
    };

    /**
     * @function
     * @name sInbox.Controller.AddUnmatchedToAITemplate#updateButtonsState
     */
    AddUnmatchedToAITemplate.prototype.updateButtonsState = function updateButtonsState() {
        this.AITemplateCanBeSaved = this.messageCollection && this.sConversationValidator.validate(this.messageCollection);

        this.AITemplateCanBeActivated = this.messageCollection && (this.addUnmatchedToAITemplateForm ?
            this.addUnmatchedToAITemplateForm.$valid && this.sConversationValidator.validate(this.messageCollection, Provider.sConversationValidator.ACTIVATE_LEVEL) :
            this.sConversationValidator.validate(this.messageCollection, Provider.sConversationValidator.ACTIVATE_LEVEL));
    };

    ns.AddUnmatchedToAITemplate = AddUnmatchedToAITemplate;
})(Object.namespace('sInbox.Controller'));
