(function (ns) {
    /**
     * @namespace
     * @alias sInbox.Route.IncomingMessageUnmatched
     * @extends sRoute
     * @constructor
     */
    var IncomingMessageUnmatched = function IncomingMessageUnmatched() {
        IncomingMessageUnmatched._pProto.constructor.call(this);

        this.accessControl = {'messageIncoming.unmatched.all': Const.Method.GET};
    };

    Object.extendProto(IncomingMessageUnmatched, sInbox.Route.IncomingMessageBase);

    /**
     * @function
     * @name sInbox.Route.IncomingMessageUnmatched#resolveListUrl
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @returns PromiseLike
     */
    IncomingMessageUnmatched.prototype.resolveListUrl = function resolveListUrl(sAPIAccess) {
        return sAPIAccess.endpoint('messageIncoming.unmatched.all').get();
    };

    /**
     * @function
     * @name sInbox.Route.IncomingMessageUnmatched#resolveType
     * @returns String
     */
    IncomingMessageUnmatched.prototype.resolveType = function resolveType() {
        return sInbox.Controller.IncomingMessage.TYPE_UNMATCHED;
    };

    ns.IncomingMessageUnmatched = IncomingMessageUnmatched;
})(Object.namespace('sInbox.Route'));
