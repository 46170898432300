(function(ns) {
    var MODE_CREATE = 'create',
        MODE_EDIT   = 'edit'
    ;
    /**
     * @namespace
     * @alias sUserManagement.Controller.Edit
     * @constructor
     *
     * @param $scope
     * @param $rootScope
     * @param {sUserManagement.Service.sUser} sUserService
     * @param $mdDialog
     * @param {Service.sDomain} sDomainService
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {?Model.User} user
     */
    var Edit = function ($scope, $rootScope, sUserService, $mdDialog, sDomainService, sAPIAccess, user) {
        this.mode = user ? MODE_EDIT : MODE_CREATE;

        user = user || new Model.User();

        this.$scope             = $scope;
        this.$rootScope         = $rootScope;
        this.$mdDialog          = $mdDialog;
        this.sDomainService     = sDomainService;
        this.savingInProgress   = null;
        this.errorMsgs          = [];
        this.user               = user;
        this.userRole           = user.getRolesByDomainId(sDomainService.currentDomainId).slice(0,1).pop();
        this.availableRoles     = Model.User.Role.query(sAPIAccess.endpoint('user.role.all').get(), [sDomainService.currentDomainId]);
    };

    /**
     * @function
     * @name sUserManagement.Controller.Edit#save
     * @returns void
     */
    Edit.prototype.save = function save() {
        var self = this;

        if (this.savingInProgress) {
            return;
        }

        this.errorMsgs = [];

        var isNew = this.user.isNew;

        this.savingInProgress = this.user.save().then(
            function () {
                if (isNew) {
                    self.$rootScope.$emit('sInviteSent', self.user)
                }

                self.$mdDialog.hide();
            },
            function (jqXHR) {
                if (jqXHR.responseJSON && jqXHR.responseJSON.messages) {
                    for (var k in jqXHR.responseJSON.messages) {
                        self.errorMsgs.push(jqXHR.responseJSON.messages[k]);
                    }
                }
                digestIfNeeded(self.$scope);
            }
        ).always(function () {
            self.savingInProgress = null;
        });
    };

    /**
     * @function
     * @name sUserManagement.Controller.Edit#cancel
     */
    Edit.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    /**
     * @function
     * @name sUserManagement.Controller.Edit#onChangeRole
     * @param {Model.User.Role} role
     */
    Edit.prototype.onChangeRole = function onChangeRole(role) {
        this.user.setSingleDomainRole(role, this.sDomainService.currentDomainId);
    };

    /**
     * @function
     * @name sUserManagement.Controller.Edit#compareRoles
     * @param {Model.User.Role} roleA
     * @param {Model.User.Role} roleB
     * @returns Boolean
     */
    Edit.prototype.compareRoles = function compareRoles(roleA, roleB) {
        if (roleA === roleB) {
            return true;
        }
        if (!roleA || !roleB) {
            return false;
        }
        return roleA.uuid === roleB.uuid;
    };

    ns.Edit = Edit;
})(Object.namespace('sUserManagement.Controller'));
