(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sPasswordStrengthMeter
     * @constructor
     *
     * @param $filter
     */
    var PasswordStrengthMeter = function PasswordStrengthMeter($filter) {
        this.$filter    = $filter;
        this.strength   = null;
    };

    /**
     * @function
     * @name Controller.Component.sPasswordStrengthMeter#$onChanges
     * @param {object} $changes
     */
    PasswordStrengthMeter.prototype.$onChanges = function $onChanges($changes) {
        if ($changes.model) {
            this.strength = this.$filter('passwordStrength')(this.model);
        }
    };

    ns.sPasswordStrengthMeter = PasswordStrengthMeter;
})(Object.namespace('Controller.Component'));
