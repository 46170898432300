(function($) {
    var $activeAggregator;

    function blockGlobalAjaxEvent(evt, xhr, options) {
        if (!options.captureAjax) {
            return;
        }

        evt.stopImmediatePropagation();
    }

    /**
     * Class used internally to aggregate and execute multiple ajax actions
     * @constructor
     */
    var AjaxAggregator = function() {
        var identifier  = Model.UUID.generateUuidV4(),
            stack       = [],
            $deferred   = $.Deferred(),
            data        = new FormData(),
            isOpen      = true
        ;

        this.push = function(options) {
            stack.push(options);
        };

        this.close = function close() {
            isOpen = false;
            $activeAggregator = undefined;
        };

        this.execute = function(endPoint, dataTransformerFunc) {
            if (!stack.length) {
                $deferred.resolve([]);
                return this.promise;
            }

            var formDataAppendProtoFn   = FormData.prototype.append,
                rawData                 = {}
            ;

            FormData.prototype.append = function(key, value) {
                rawData[key] = value;
                return formDataAppendProtoFn.apply(this, arguments);
            };

            // control-value for api
            data.append('_length', String(stack.length));

            for (var i = 0; i < stack.length; i++) {
                dataTransformerFunc.call(this, stack[i], data, i);
            }

            FormData.prototype.append = formDataAppendProtoFn;

            $.event.trigger($.EVENT_FORM_DATA_PREPARED, rawData);
            this.close();

            $.ajax({
                url              : endPoint,
                data             : data,
                method           : Const.Method.POST,
                processData      : false,
                contentType      : false,
                ignoreAggregation: true,
                context          : this,
                xhr              : function() {
                    var myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                        // For handling the progress of the upload
                        myXhr.upload.addEventListener('progress', function(e) {
                            if (e.lengthComputable) {
                                $deferred.notify(e);
                            }
                        } , false);
                    }
                    return myXhr;
                }
            }).then(
                function () {
                    $deferred.resolve.apply($deferred, arguments);
                },
                function () {
                    $deferred.reject.apply($deferred, arguments);
                }
            );

            return this.promise;
        };

        this.promise = $deferred.promise();

        Object.defineProperties(
            this,
            {
                identifier: {
                    enumerable: true,
                    get: function() {
                        return identifier;
                    }
                    /**
                     * @property
                     * @name AjaxAggregator#identifier
                     * @type {String}
                     */
                },
                counter: {
                    enumerable: true,
                    get: function() {
                        return stack.length;
                    }
                    /**
                     * @property
                     * @name AjaxAggregator#counter
                     * @type {Number}
                     */
                },
                isOpen: {
                    enumerable: true,
                    get: function () {
                        return isOpen;
                    }
                    /**
                     * @property
                     * @name AjaxAggregator#isOpen
                     * @type {Boolean}
                     */
                }
            }
        );
    };

    /**
     * Checks if origin of {url} is same as {origin}
     *
     * @function
     * @name AjaxAggregator#isUrlOfSameOrigin
     * @static
     * @param {String} url
     * @param {String} origin
     * @returns {Boolean}
     */
    AjaxAggregator.isUrlOfSameOrigin = function isUrlOfSameOrigin(url, origin) {
        var urlOfOrigin = new RegExp("^(https*:\/\/|)(www\.|)" + origin + ".*$");

        return urlOfOrigin.test(url);
    };

    Object.defineProperties(
        $,
        {
            EVENT_FORM_DATA_PREPARED: {
                value:'sFormDataAppendEvent'
                /**
                 * @property
                 * @name jQuery#EVENT_FORM_DATA_PREPARED
                 * @type {String}
                 */
            },
            EVENT_AGGREGATION_ENCOUNTERED_ERROR: {
                value: 'sAggregationEncounteredError'
                /**
                 * @property
                 * @constant
                 * @name #EVENT_AGGREGATION_ENCOUNTERED_ERROR
                 * @type {String}
                 */
            }
        });

    /**
     * @see https://api.jquery.com/jQuery.ajaxTransport/
     */
    $.ajaxTransport("+*", function (options, originalOptions) {
        $(document).off('ajaxComplete', blockGlobalAjaxEvent);
        $(document).$prependOnHandler('ajaxComplete', blockGlobalAjaxEvent);

        $(document).off('ajaxSuccess', blockGlobalAjaxEvent);
        $(document).$prependOnHandler('ajaxSuccess', blockGlobalAjaxEvent);

        $(document).off('ajaxError', blockGlobalAjaxEvent);
        $(document).$prependOnHandler('ajaxError', blockGlobalAjaxEvent);

        // Only aggregate requests of the same origin
        if(!AjaxAggregator.isUrlOfSameOrigin(originalOptions.url, window.location.hostname)) {
            return;
        }

        if (originalOptions.aggregatorTransportProcessed) {
            return;
        }
        options.captureAjax = true;

        var sendSingleRequest = function (completeCallback) {
            var optionsForSingleRequest = $.extend(
                    {},
                    options,
                    { error: null }
                );
            delete(optionsForSingleRequest.captureAjax);

            var singleRequest = $.ajax(optionsForSingleRequest);

            singleRequest.done(function (content, statusText, jqXHR) {
                completeCallback(
                    jqXHR.status,
                    (jqXHR.statusText ? jqXHR.statusText : statusText),
                    {
                        data: content,
                        xml : jqXHR.responseXML,
                        text: jqXHR.responseText,
                        json: jqXHR.responseJSON
                    },
                    jqXHR.getAllResponseHeaders()
                );
            });

            singleRequest.fail(function (jqXHR, statusText, errorThrown) {
                notifyAjaxError(jqXHR, this);

                completeCallback(
                    jqXHR.status,
                    (jqXHR.statusText ? jqXHR.statusText : statusText),
                    {
                        data: errorThrown,
                        xml : jqXHR.responseXML,
                        text: jqXHR.responseText,
                        json: jqXHR.responseJSON
                    },
                    jqXHR.getAllResponseHeaders()
                );
            });
        };

        var sendAggregatedRequests = function (completeCallback) {
            var aggregator = options.aggregator;

            options.aggregatorNr   = aggregator.counter;
            originalOptions.url    = originalOptions.url || options.url;
            originalOptions.method = originalOptions.method || Const.Method.GET;
            delete(originalOptions.captureAjax);

            aggregator.push(originalOptions);

            // disassemble the individual responses from the aggregated response we received
            aggregator.promise.always(function () {
                var responseRecords;

                if (aggregator.promise.state() === 'resolved') {
                    responseRecords = arguments[0];
                } else {
                    responseRecords = arguments[0].responseJSON;
                }

                if (!responseRecords || typeof responseRecords[options.aggregatorNr] === 'undefined') {

                    // fail silently, but trigger event with exception
                    $.event.trigger($.EVENT_AGGREGATION_ENCOUNTERED_ERROR, "Could not parse response from aggregated stack");

                    // we need to complete the aggregated requests anyway to unblock promises
                    completeCallback(500, 'Internal Server Error');
                    return;
                }

                /**
                 * This should be ONE response in json returned from the API
                 *
                 * @type Object
                 * @property {Number}        code
                 * @property {String}        statusText
                 * @property {*}             content
                 * @property {Number}        counter
                 * @property {String}        dataName
                 * @property {Array<Object>} headers - (name: String, value: String)
                 */
                var singleResponse        = responseRecords[options.aggregatorNr],
                    headersAsSingleString = '';

                if (singleResponse.headers instanceof Array) {
                    headersAsSingleString = singleResponse.headers.reduce(
                        function (concatenatedString, header, index) {
                            if (index !== 0) {
                                concatenatedString += "\n";
                            }
                            concatenatedString += header.name.toLowerCase() + ': ' + header.value;
                            return concatenatedString;
                        },
                        ''
                    );
                }

                completeCallback(
                    singleResponse.code,
                    singleResponse.statusText,
                    {
                        text: singleResponse.content,
                        json: singleResponse.content,
                        xml : jQuery.isXMLDoc(singleResponse.content) ? singleResponse.content : ''
                    },
                    headersAsSingleString
                );
            });
        };

        return {
            send: function (headers, completeCallback) {
                options.aggregatorTransportProcessed = true;

                // Extra protection to avoid closed aggregators being used
                // this is also to make the behavior independent from the removal of the ajaxSend event
                if (options.aggregator && (!(options.aggregator instanceof AjaxAggregator) || !options.aggregator.isOpen)) {
                    delete options.aggregator;
                }

                // handle all request which are not aggregated together (including the batch request itself)
                if (!options.aggregator) {
                    sendSingleRequest(completeCallback);
                    return;
                }

                // handle aggregated requests which are virtual -> so we need to build the individual response
                sendAggregatedRequests(completeCallback);
            },
            abort: function() {

            }
        }
    });

    /**
     * @param {[]} functionsToAggregate
     * @param {String} endPoint
     * @param {Function=} dataTransformFunc
     * @return {$.Deferred}
     */
    $.aggregateAction = function aggregateAction(functionsToAggregate, endPoint, dataTransformFunc) {
        var $deferred = $.Deferred();

        if (!(functionsToAggregate instanceof Array) || !functionsToAggregate.length) {
            return $deferred.reject('Invalid argument!');
        }

        var self                = this,
            aggregator          = new AjaxAggregator(),
            results             = [],
            /**
             * Set the aggregation on the xhr request, if the aggregator has been executed (closed) then cleanup
             * @param e
             * @param xhr
             * @param {Object} options
             */
            ajaxSendAggregator  = function ajaxSendAggregator(e, xhr, options) {
                if (!aggregator.isOpen) {
                    $(document).off('ajaxSend', ajaxSendAggregator);
                } else if (!options.ignoreAggregation) {
                    options.aggregator = aggregator;

                    xhr.fail(function (jqXHR) {
                        notifyAjaxError(jqXHR, this, aggregator);
                    });
                }
            }
        ;

        function callback() {
            var functionToAggregate;

            $activeAggregator = $deferred;
            $(document).ajaxSend(ajaxSendAggregator);

            try {
                for (var i = 0; i < functionsToAggregate.length; i++) {
                    functionToAggregate = functionsToAggregate[i];

                    if (functionToAggregate instanceof Function) {
                        results.push(functionToAggregate());
                    }
                }
            } catch (err) {
                // fail silently, but trigger event with exception
                $.event.trigger($.EVENT_AGGREGATION_ENCOUNTERED_ERROR, err);
                aggregator.close();
                $(document).off('ajaxSend', ajaxSendAggregator);
                return $deferred.reject().promise();
            }

            dataTransformFunc = dataTransformFunc || function(options, data, counter) {
                var nameObj = {
                    method  : options.method,
                    url     : options.url,
                    counter : counter,
                    params  : options.data
                };

                data.append(JSON.stringify(nameObj).bin2hex(), '');
            };

            if (!aggregator.counter) {
                aggregator.close();
            }

            return aggregator.execute(endPoint, dataTransformFunc)
                .then(
                    function (response) {
                        return $deferred.resolve.call($deferred, results, response);
                    },
                    function () {
                        return $deferred.reject.call($deferred, results);
                    }
                )
                .always(function () {
                    self.actionStack = [];
                    $(document).off('ajaxSend', ajaxSendAggregator);
                })
        }

        if (!$activeAggregator) {
            return callback();
        } else {
            return $activeAggregator.then(callback);
        }
    };

    /**
     * @private
     * @function
     * @name AjaxAggregator~notifyAjaxError
     * @param {Object}            jqXHR
     * @param {*}                 context
     * @param {$.AjaxAggregator=} aggregator
     */
    var notifyAjaxError = function notifyAjaxError(jqXHR, context, aggregator) {
        if (typeof window.bugsnagClient === 'undefined') {
            return;
        }

        var errorObject = new Model.Exception.AjaxError(),
            metaData    = {
                responseHeaders : jqXHR.getAllResponseHeaders(),
                responseStatus  : jqXHR.status,
                response        : jqXHR.responseJSON || jqXHR.responseText,
                requestOptions  : jqXHR.originalRequestOptions,
                requestUrl      : jqXHR.originalRequestOptions ? jqXHR.originalRequestOptions.url : '',
                exceptionId     : jqXHR.getResponseHeader("X-Exception-Id")
        };

        if (!aggregator && (context instanceof $.AjaxAggregator)) {
            aggregator = context;
        }

        if (aggregator) {
            metaData['aggregationIdentifier'] = aggregator.identifier;
            errorObject = new Model.Exception.AggregatedAjaxError(
                (aggregator === context) ? 'MainRequest' : 'SubRequest'
            );
        }

        window.bugsnagClient.notify(
            errorObject,
            {
                metaData: metaData,
                severity: (jqXHR.status >= 500) ? 'error' : 'info'
            }
        );
    };

    $.AjaxAggregator = AjaxAggregator;
})(jQuery);
