(function(ns) {
    var Auth = ns.Auth = function (menu) {
        sSecurity.Route.Auth._pProto.constructor.call(this);

        var resolveGetter = Object.getOwnPropertyDescriptor(this, 'resolve').get;

        this.controllerAs = '$ctrl';
        this.templateUrl = 'ssecurity:auth-wrapper';
        this.reloadOnSearch = false;

        /**
         * @function
         * @name sSecurity.Route.Auth#redirectTo
         * @returns {string|void}
         */
        this.redirectTo = function () {
            var activeAction = menu.getActiveItem();

            if (activeAction) {
                this.controller = activeAction.options.controller;
                this.resolve = $.extend(this.resolve, resolveGetter.call(), activeAction.options.resolve);
            } else {
                return menu.getElementsOfType(Model.Menu.LocationAction).shift().options.path;
            }
        };
    };

    Object.extendProto(sSecurity.Route.Auth, sRoute);

    /**
     * @function
     * @name sSecurity.Route.Auth#resolvePath
     * @param {object} sMenuAuthWrapper
     * @returns {$.Deferred|void}
     */
    Auth.prototype.resolvePath = function resolvePath(sMenuAuthWrapper) {
        var activeAction = sMenuAuthWrapper.getActiveItem();
        if (!activeAction) {
            return;
        }
        return $.when(activeAction.options.templateUrl);
    };

    /**
     * @function
     * @name sSecurity.Route.Auth#resolveTitle
     * @param {object} sMenuAuthWrapper
     * @returns {$.Deferred|void}
     */
    Auth.prototype.resolveTitle = function resolveTitle(sMenuAuthWrapper) {
        var activeAction = sMenuAuthWrapper.getActiveItem();
        if (!activeAction) {
            return;
        }
        return $.when(activeAction.options.title);
    };

    /**
     * @function
     * @name sSecurity.Route.Auth#resolveMenu
     * @param {object} sMenuAuthWrapper
     * @returns {$.Deferred}
     */
    Auth.prototype.resolveMenu = function resolveMenu(sMenuAuthWrapper) {
        return $.when(sMenuAuthWrapper);
    };

})(Object.namespace('sSecurity.Route'));