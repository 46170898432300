(function(angular) {
    /**
     * Adds support for groups and disable elements in md-autocomplete by hijacking the template of the directive
     * @type {angular.Module}
     */
    var mdAutocompleteGrouperAndItemDisabler = angular.module('mdAutocompleteGrouperAndItemDisabler', ['ngMaterial']);

    mdAutocompleteGrouperAndItemDisabler.run(function($injector) {
        var directive = $injector.get('mdAutocompleteDirective');

        var oldTemplate = directive[0].template;

        // overwrite the template to disable click handler in case of disabled or group
        directive[0].template = function() {
            var retVal = oldTemplate.apply(directive[0], arguments);

            var $element = $(retVal);

            $element.find('li').each(function(index, element) {
                var $liElement    = $(element),
                    clickAttr   = $liElement.attr('ng-click'),
                    cssClass = $liElement.attr('class');
                ;

                clickAttr = '!item.isDisabled && !item.isGroup && ' + clickAttr;
                $liElement.attr('ng-click', clickAttr);
                cssClass += ' {{ item.isDisabled ? \'disabled\' : \'\' }}';
                cssClass += ' {{ item.isGroup ? \'opt-group\' : \'\' }}';
                $liElement.attr('class', cssClass);
                $liElement.attr('style', '{{ item.isGroup ? \'pointer-events: none\' : \'\' }}');
            });

            return $element[0].outerHTML;
        };

        // get the controller's invoke for the override
        var ctrl = directive[0].controller,
            material = angular.module('material.components.autocomplete'),
            controllerDef = material._invokeQueue.filter(function(invokeQueueItem) {
                return invokeQueueItem[2][0] === ctrl;
            }).pop();

        // override the controller and define the index property to prevent keyboard selection of group item
        directive[0].controller = function() {
            var index = 0;
            controllerDef[2][1].apply(this, arguments);

            Object.defineProperties(
                this,
                {
                    index: {
                        enumerable: true,
                        configurable: true,
                        get: function () {
                            // this needs to be here because matches might come later then the setting, so the check won't be performed then
                            if (this.matches[index] && (this.matches[index].isGroup || this.matches[index].isDisabled)) {
                                return null;
                            }

                            return index;
                        },
                        set: function (val) {
                            if (this.matches[val] && (this.matches[val].isGroup || this.matches[val].isDisabled)) {
                                if (index < val && val < this.matches.length - 1) {
                                    val += 1;
                                } else if (val < index && val > 0)  {
                                    val -= 1;
                                } else if (index) {
                                    // don't change
                                    return;
                                } else {
                                    val = null;
                                }
                            }

                            if (val === index) {
                                return;
                            }

                            index = val;
                        }
                        /**
                         * @property
                         * @name #index
                         * @type {Number}
                         */
                    }
                });
        };

        directive[0].controller.$inject = controllerDef[2][1].$inject;
    });
})(angular);
