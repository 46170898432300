(function(ns) {
    var KEY_AS_JSON                 = 'asJson',
        KEY_DEFAULT_VALUE           = 'defaultValue',
        KEY_AS_ARRAY                = 'asArray',
        PARAM_SELECTED_FILTER_GROUP = 'selectedFilterGroup'
    ;

    /**
     * @namespace
     * @alias Controller.Component.sFilter
     */
    var Filter = function ($rootScope, $scope, $element, $location) {
        this.$scope         = $scope;
        this.$element       = $element;
        this.refocusFn      = null;
        this.editables      = {};
        this.defaults       = {};
        this.$deRegister    = [];
        this.$location      = $location;
        this.routeChanging  = false;
        this.$rootScope     = $rootScope;

        this.localFilters   = new Model.Filter.Collection();

        /**
         * @typedef {Object} FilterFieldOptions
         * @property {string} defaultValue
         * @property {string} label
         * @property {boolean} asArray
         * @property {Object} attrs
         */

        /**
         * @typedef {Object} FilterField
         * @property {string} component
         * @property {FilterFieldOptions} options
         */

        /**
         * @typedef {Object.<string, FilterField>} FilterFields
         */

        /**
         * @name Controller.Component.sFilter#fields
         * @type FilterFields
         */

        /**
         * @name Controller.Component.sFilter#queryPrefix
         * @type String
         */

        /**
         * @name Controller.Component.sFilter#readonly
         * @type Boolean
         */

        /**
         * @property
         * @name Controller.Component.sFilter#model
         * @type {Model.Filter.Collection}
         */

        /**
         * @property
         * @name Controller.Component.sFilter#label
         * @type {String}
         */

        /**
         * @property
         * @name Controller.Component.sFilter#filterForm
         * @type {form.FormController}
         */
    };

    /**
     * @function
     * @name Controller.Component.sFilter#$onInit
     */
    Filter.prototype.$onInit = function $onInit() {
        var self = this;

        this.label = this.label || 'Filter by';

        this.$deRegister.push(this.$rootScope.$on('$locationChangeSuccess', this.handleLocationChange.bind(this)));
        this.$deRegister.push(this.$rootScope.$on('$routeChangeStart', this.handleRouteChangeStart.bind(this)));

        this.fieldGroups = {};
        this.handleFields();

        this.$deRegister.push(this.$scope.$on(
            Controller.Component.sSelect.EVENT_VALUE_NOT_AVAILABLE,
            function(event, model) {
                var $searchInAsync = self.$location.search();

                $.each(self.editables, function(name) {
                    var fullyQualifiedName = (self.queryPrefix || '') + name;

                    if ($searchInAsync[fullyQualifiedName] === model) {
                        self.$location.search(fullyQualifiedName, null);
                    }
                });

                self.updateFromLocation();
            }
        ));

        this.$deRegister.push(this.$scope.$watch(
            function () {
                return JSON.stringify(self.fields);
            },
            /**
             * @param {String} valNew
             * @param {String} valOld
             */
            function(valNew, valOld) {
                if (valNew !== valOld) {
                    self.handleFields();
                }
                self.handleComponents();
            })
        );

        this.$deRegister.push(this.$scope.$watch(
            function () {
                return JSON.stringify(self.model);
            },
            /**
             * @param {String} newVal
             * @param {String} oldVal
             */
            function(newVal, oldVal) {
                if (oldVal === newVal) {
                    return;
                }

                self.model.editableFilters.map(function(filter) {
                    if (filter.value) {
                        self.localFilters.getOrCreateByName(filter.name).value = filter.value.clone();
                    }
                });

                self.updateLocation();
            })
        );

        this.$deRegister.push(this.$scope.$on(
            Controller.Component.sDynamicFormGroupedRowController.FORM_GROUPED_ROW_GROUP_CHANGED,
            /**
             * @param {Object} event
             * @param {String} groupKey
             */
            function (event, groupKey) {
                self.$location.search(PARAM_SELECTED_FILTER_GROUP, groupKey);
                self.clear();
            })
        );

        // listen to key-down event to catch enter-hitting for submitting filter
        this.$deRegister.push.apply(this.$element.$on('keydown', this.handleKeyDown.bind(this)));
    };

    /**
     * @function
     * @name Controller.Component.sFilter#$onDestroy
     */
    Filter.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name Controller.Component.sFilter#$onChanges
     * @param {object} simpleChange
     */
    Filter.prototype.$onChanges = function $onChanges(simpleChange) {
        // execute refocus function if readonly is changed to falsy value
        if (simpleChange.readonly && !simpleChange.readonly.currentValue && this.refocusFn instanceof Function) {
            this.refocusFn.call(this);
            this.refocusFn = null;
        }
    };

    /**
     * @function
     * @name Controller.Component.sFilter#handleKeyDown
     * @param {jQuery.Event} event
     */
    Filter.prototype.handleKeyDown = function handleKeyDown(event) {
        if (this.readonly || Const.EnterKey !== event.which || !this.filterForm.$valid) {
            return;
        }

        // refocus function for getting the focus back after dom is changed
        var focusedElement = this.$element.find(":focus");
        this.refocusFn = function refocusFn() {
            focusedElement.focus();
        };

        this.apply();
    };

    /**
     * @function
     * @name Controller.Component.sFilter#setFlattenedFields
     */
    Filter.prototype.setFlattenedFields = function setFlattenedFields() {
        this.fieldGroups = {};

        var fields = {},
            name;

        // Transform multiple level fields (name begins with exclamation mark)
        for (name in this.fields) {
            if (!this.fields.hasOwnProperty(name)) {
                continue;
            }

            if (!name.startsWith('!')) {
                fields[name] = this.fields[name];

                continue;
            }

            var componentName;

            for (var i = 0; i < this.fields[name].length; i++) {
                if (!this.fields[name][i].components) {
                    continue;
                }

                var groupNames = [];

                for (componentName in this.fields[name][i].components) {
                    if (!this.fields[name][i].components.hasOwnProperty(componentName)) {
                        continue;
                    }

                    fields[componentName] = this.fields[name][i].components[componentName];

                    groupNames.push(componentName);
                }

                this.fieldGroups[this.fields[name][i].groupKey] = groupNames;
            }
        }

        this.flattenedFields = fields;
    };

    /**
     * @function
     * @name Controller.Component.sFilter#handleFields
     */
    Filter.prototype.handleFields = function handleFields() {
        this.setFlattenedFields();

        var name;

        for (name in this.fields) {
            if (!this.fields.hasOwnProperty(name)) {
                continue;
            }

            // set default-template
            this.fields[name].template = this.fields[name].template || '_component:s-filter-dynamic-form-row';
        }
    };

    /**
     * @function
     * @name Controller.Component.sFilter#handleComponents
     */
    Filter.prototype.handleComponents = function handleComponents() {
        var self = this;

        this.editables = {};

        $.each(this.flattenedFields, function(name, element) {
            if (!self.flattenedFields.hasOwnProperty(name)) {
                return false;
            }

            var defaultValue,
                filter = self.localFilters.getOrCreateByName(name)
            ;

            if (element.options instanceof Object) {
                if (element.options.hasOwnProperty(KEY_DEFAULT_VALUE) && filter.value === undefined) {
                    defaultValue = element.options.defaultValue;

                    filter.value = self.defaults[filter.name] =
                        (defaultValue instanceof Array || defaultValue instanceof Object)
                            ? defaultValue.clone()
                            : defaultValue
                    ;
                }

                if (element.options.hasOwnProperty(KEY_AS_JSON)) {
                    filter.asJson = element.options.asJson;
                }

                if (element.options.hasOwnProperty(KEY_AS_ARRAY)) {
                    filter.asArray = element.options.asArray;
                }
            }

            Object.defineProperty(self.editables, name, {
                enumerable  : true,
                configurable: true,
                get: function () {
                    return filter.value;
                },
                set: function (val) {
                    if (!val) {
                        val = (defaultValue instanceof Array || defaultValue instanceof Object)
                            ? defaultValue.clone()
                            : defaultValue
                        ;
                    }
                    filter.value = val;
                }
            });
        });

        this.updateFromLocation();
    };

    /**
     * @function
     * @name Controller.Component.sFilter#apply
     */
    Filter.prototype.apply = function apply() {
        this.applyChangesToModel();

        this.updateLocation();
        digestIfNeeded(this.$scope);
    };

    Filter.prototype.handleRouteChangeStart = function handleRouteChangeStart() {
        this.routeChanging = true;
    };

    Filter.prototype.handleLocationChange = function handleLocationChange() {
        if (this.routeChanging) {
            return;
        }

        this.updateFromLocation();
    };

    Filter.prototype.updateFromLocation = function updateFromLocation() {
        // for 50ms listen for events coming from child sSelects reporting that a given value is invalid
        var timeOut,
            delayedUnRegister = function delayedUnRegister(delay) {
                delay = delay || 50;
                timeOut = setTimeout(function () {
                    unRegister();
                }, delay);
            },
            unRegister = this.$scope.$on(Controller.Component.sSelect.EVENT_VALUE_DISABLED, function() {
                if (timeOut) {
                    clearTimeout(timeOut);
                }

                delayedUnRegister();

                self.applyChangesToModel();
            })
        ;

        var $search = this.$location.search(),
            self = this;

        $.each(this.editables, function(name) {
            var fullyQualifiedName = (self.queryPrefix || '') + name,
                filter
            ;

            if (!$search[fullyQualifiedName] && self.editables[name]) {
                self.editables[name] = null;
            } else if ($search[fullyQualifiedName]
                && (!self.editables[name] || self.editables[name].valueOf() !== $search[fullyQualifiedName])
                && (filter = self.localFilters.getByName(name))
            ) {
                if (filter.asJson) {
                    self.editables[name] = JSON.parse($search[fullyQualifiedName], filter.asJson);
                } else if (filter.asArray) {
                    self.editables[name] = $search[fullyQualifiedName].toString().split(',');
                } else {
                    self.editables[name] = $search[fullyQualifiedName];
                }
            }
        });

        this.applyChangesToModel();

        delayedUnRegister(500);
    };

    Filter.prototype.updateLocation = function updateLocation() {
        var self = this;

        // If we switch to location without grouped fields, remove param from URL
        if (!Object.keys(this.fieldGroups).length) {
            self.$location.search(PARAM_SELECTED_FILTER_GROUP, null);
        }

        // go through all editable filters, but only take those that are in the model
        this.localFilters.filters.map(function (filter) {
            var setFilter   = self.model.getByName(filter.name),
                queryPrefix = self.queryPrefix || ''
            ;

            if (setFilter && !self.filterValueEqualsDefault(filter)) {
                self.$location.search(queryPrefix + setFilter.name, setFilter.value.valueOf());
            } else {
                self.$location.search(queryPrefix + filter.name, undefined);
            }
        });
    };

    /**
     * @param {Model.Filter} filter
     * @returns {boolean}
     */
    Filter.prototype.filterValueEqualsDefault = function filterValueEqualsDefault(filter) {
        if (!this.defaults.hasOwnProperty(filter.name)) {
            return false;
        }

        var defaultValue = this.defaults[filter.name];

        if (filter.value instanceof Model.DateRange) {
            return filter.value.from.isSame(defaultValue.from, 'second') &&
                filter.value.to.isSame(defaultValue.to, 'second');
        }

        return filter.value === defaultValue;
    };

    /**
     * @function
     * @name Controller.Component.Filter#clear
     */
    Filter.prototype.clear = function clear() {
        var self = this;

        $.each(this.editables, function(name) {
            self.editables[name] = null;
        });

        this.applyChangesToModel();

        this.updateLocation();
    };

    Filter.prototype.applyChangesToModel = function applyChangesToModel() {
        var self = this;

        this.model.clear();
        this.localFilters.editableFilters
            .filter(function (filter) {
                return self.flattenedFields.hasOwnProperty(filter.name) && filter.value && filter.value.toString().length;
            })
            .map(function (filter) {
                self.model.addFilter(filter.clone());
            });
    };

    Object.defineProperties(
        Filter,
        {
            PARAM_SELECTED_FILTER_GROUP : {
                value : PARAM_SELECTED_FILTER_GROUP
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sFilter#PARAM_SELECTED_FILTER_GROUP
                 * @type {String}
                 */
            }
        }
    );

    ns.sFilter = Filter;
})(Object.namespace('Controller.Component'));
