(function (ns) {
    /**
     * @namespace
     * @constructor
     * @alias Controller.Directive.sProductModuleInfoDialog
     * @param $mdDialog
     */
    var sProductModuleInfoDialog = function ($mdDialog) {
        this.$mdDialog = $mdDialog;
    };

    sProductModuleInfoDialog.prototype.$onInit = function $onInit() {
        var productModules = [
            {
                title       : 'Turn incoming messages into revenue',
                subtitle    : 'Talk to your account manager to upgrade your plan',
                description : 'Get access to unlimited AI templates, advanced intent matching capabilities and connect your product feed directly with your intents. Identify pre-purchase queries before they land in your Customer Service team and turn messages into revenue.',
                image       : 'spectrm-product-module-2.png'
            }
        ];

        this.productModule = productModules[0];
    };

    sProductModuleInfoDialog.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    ns.sProductModuleInfoDialog = sProductModuleInfoDialog;
})(Object.namespace('Controller.Directive'));
