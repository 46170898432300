(function(ns) {
    /**
     * @namespace
     * @alias Model.Exception.FileInvalidType
     * @param {Blob} file
     * @param givenType
     * @param allowedTypes
     * @constructor
     * @extends Error
     */
    var FileInvalidType = function(file, givenType, allowedTypes) {

        allowedTypes = (allowedTypes || []);
        this.name           = 'FileInvalidTypeError';
        this.message        = 'File type `' + givenType + '`not allowed. Allowed types: ' + allowedTypes.join(", ");
        this.file           = file;
    };

    ns.FileInvalidType = FileInvalidType;

    Object.extendProto(FileInvalidType, Error);
})(Object.namespace('Model.Exception'));
