(function (ns) {
    /**
     * @namespace
     * @alias Model.Analytics.Trace3d
     *
     * @constructor
     * @extends Model.Analytics.Trace2d
     */
    var Trace3d = function () {
        Trace3d._pProto.constructor.call(this);
        var z = [];

        Object.defineProperties(
            this,
            {
                z: {
                    get: function () {
                        return z;
                    }
                    /**
                     * @property
                     * @name Model.Analytics.Trace3d#z
                     * @type {[]}
                     */
                }
            });

        var pExtendClone = this.__extendClone;

        this.__extendClone = function __extendClone(original) {
            this.z.splice.apply(this.z, [0, this.z.length].concat(original.z));

            pExtendClone.call(this, original);
        }
    };

    Object.extendProto(Trace3d, ns.Trace2d);

    /**
     * @param entries
     * @param extractorX
     * @param extractorY
     * @param extractorZ
     * @return {Model.Analytics.Trace3d}
     */
    Trace3d.createFromEntries = function createFromEntries(entries, extractorX, extractorY, extractorZ) {
        return entries.reduce(
            /**
             * @param {Model.Analytics.Trace3d} trace
             * @param {Model.Analytics.Entry} entry
             */
            function(trace, entry) {
                trace.x.push(extractorX(entry));
                trace.y.push(extractorY(entry));
                trace.z.push(extractorZ(entry));

                return trace;
            },
            new Trace3d()
        );
    };

    ns.Trace3d = Trace3d;
})(Object.namespace('Model.Analytics'));