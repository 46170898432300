(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sChannelSelect
     * @constructor
     *
     * @param $scope
     * @param {sSegment.Service.sSegment} sSegment
     */
    var sChannelSelect = function($scope, sSegment) {
        var self        = this,
            channels    = []
            ;

        this.$scope             = $scope;
        this.sSegmentService    = sSegment;
        this.selectedChannels   = [];
        this.$deRegister        = [];
        this.loading            = null;
        this.refreshEventName   = 's-channel-select_refreshChoices';

        /**
         * @property
         * @name Controller.Component.sChannelSelect#selectedItem
         */

        // function with access to private property
        this.getChannels = function getChannels(forceReload) {
            if (channels.length === 0 || forceReload) {
                return self.loadChannels().then(function(data) {
                    if (self.type) {
                        data = data.filter(function (segment) {
                            return segment.type === self.type;
                        });
                    }

                    channels = data.sort(Array.sortFnByProperty('name'));
                    return channels;
                });
            }
            return $.when(channels);
        };

        /**
         * @property
         * @name Controller.Component.sChannelSelect#multiSelect
         */
    };

    /**
     * @function
     * @name Controller.Component.sChannelSelect#$onInit
     */
    sChannelSelect.prototype.$onInit = function $onInit() {
        var self = this;

        if (this.multiSelect && !this.model) {
            this.model = [];
        }

        this.isRequired = this.isRequired !== false;

        this.$deRegister.push(this.$scope.$watchCollection(
            function () {
                return self.model;
            },
            this.modelChange.bind(this)
        ));
    };

    /**
     * @function
     * @name Controller.Component.sChannelSelect#$onChanges
     * @param {Object.<String, SimpleChange>} $changes
     */
    sChannelSelect.prototype.$onChanges = function $onChanges($changes) {
        var self = this;

        if ($changes.type && !$changes.type.isFirstChange() && $changes.type.currentValue !== $changes.type.previousValue) {
            this.getChannels(true)
                .then(this.modelChange.bind(this))
                .then(function () {
                    self.$scope.$broadcast(self.refreshEventName);
                })
            ;
        }
    };

    /**
     * @function
     * @name Controller.Component.sChannelSelect#modelChange
     * @returns PromiseLike
     */
    sChannelSelect.prototype.modelChange = function modelChange() {
        var self = this;
        return this.getChannels().then(function (channels) {
            self.selectedChannels = [];
            if (self.selectedItem) {
                self.selectedItem = null;
            }
            $.each(channels, function (key, channel) {
                if (self.multiSelect) {
                    if (self.model.indexOf(channel.uuid) !== -1) {
                        self.selectedChannels.push(channel);
                    }
                } else {
                    if (channel.uuid === self.model) {
                        self.selectedItem = channel;
                        return false;
                    }
                }
            });
        });
    };

    /**
     * @function
     * @name Controller.Component.sChannelSelect#selectedItemsChange
     */
    sChannelSelect.prototype.selectedItemsChange = function selectedItemsChange() {
        var model = [];

        $.each(this.selectedChannels, function(key, channel) {
            model.push(channel.uuid);
        });

        this.model = model;
    };

    /**
     * @function
     * @name Controller.Component.sChannelSelect#selectedItemChange
     */
    sChannelSelect.prototype.selectedItemChange = function selectedItemChange(channel) {
        this.model = (channel) ? channel.uuid : null;
    };

    /**
     * @name Controller.Component.sChannelSelect#querySearch
     */
    sChannelSelect.prototype.querySearch = function querySearch(query) {
        var self = this;

        return self.getChannels().then(function(channels) {

            if (query) {
                return channels.filter(self.createFilterFor(query));
            }
            return channels;
        });
    };

    sChannelSelect.prototype.createFilterFor = function createFilterFor(query) {
        var regex = "^" + query,
            regexObj = new RegExp(regex, 'i');

        return function filterFn(channel) {
            return (regexObj.exec(channel.name));
        };
    };

    sChannelSelect.prototype.loadChannels = function loadChannels() {
        var self = this;

        if (this.loading === null) {
            this.loading = this.sSegmentService.loadSegmentsByCurrentDomain()
                .always(function () {
                    self.loading = null;
                });
        }

        return this.loading;
    };

    sChannelSelect.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    ns.sChannelSelect = sChannelSelect;
})(Object.namespace('Controller.Component'));