(function(ns) {
    var PARAM_USER_INPUT      = 'userInput',
        COLUMN_MATCHING       = 'matching',
        COLUMN_MATCHING_SCORE = 'matchingScore'
    ;

    /**
     * @namespace
     * @alias sMessageAnchor.Controller.Overview
     * @constructor
     *
     * @param {Service.sDomain} sDomainService
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param $scope
     * @param $location
     * @param $rootScope
     * @param $mdDialog
     * @param Notification
     * @param {sMessageAnchor.Service.MessageAnchor} sMessageAnchor
     * @param sBugsnag
     * @param {Service.sConfirm} sConfirm
     */
    var Overview = function(
        sDomainService,
        sAPIAccess,
        $scope,
        $location,
        $rootScope,
        $mdDialog,
        Notification,
        sMessageAnchor,
        sBugsnag,
        sConfirm
    ) {
        var self = this;
        this.sDomainService = sDomainService;
        this.sAPIAccess     = sAPIAccess;
        this.notification   = Notification;
        this.sMessageAnchor = sMessageAnchor;
        this.sBugsnag       = sBugsnag;
        this.sConfirm  = sConfirm;
        if (this.sDomainService.currentDomain.isFeatureEnabledByIdent('vStructure')) {
            // no Conversation access
            this.structure = {
                'AI-Templates'  : [
                    'Active',
                    'Draft',
                    'Inactive',
                ]
            };
        } else {
            this.structure = {
                'Conversations' : [
                    'Active',
                    'Draft',
                    'Inactive',
                ],
                'AI-Templates'  : [
                    'Active',
                    'Draft',
                    'Inactive',
                ]
            };
        }


        this.configLists();

        this.currentView       = 'AI-Templates';
        this.currentSubView    = 'Active';
        this.testingUserInput  = false; // Is true if we filter list of AI Templates by user input
        this.hasGroupedFilters = false;

        this.$scope         = $scope;
        this.$location      = $location;
        this.$deRegister    = [];
        this.routeChanging  = false;
        this.$mdDialog      = $mdDialog;

        this.$deRegister.push($rootScope.$on('$locationChangeSuccess', this.updateFieldsFromLocation.bind(this)));
        this.$deRegister.push($rootScope.$on('$routeChangeStart', this.handleRouteChangeStart.bind(this)));
        this.$deRegister.push(this.$scope.$watch(
            function () {
                return self.testingUserInput;
            },
            this.handleTestingUserInputChange.bind(this)
        ));

        this.$deRegister.push($scope.$watchCollection(function() {
                if (!self.currentView || !self.currentSubView) {
                    return null;
                }
                return JSON.stringify(self.views[self.currentView][self.currentSubView].filters)
            },
            function() {
                self.syncListStates();
            }
        ));

        this.$deRegister = this.$deRegister.concat(
            $(document).$on(
                Model.Series.EVENT_LOAD,
                function(event, series, queryParams) {
                    if (!queryParams.metaOnly) {
                        self.syncListStates();
                        self.fetchMetaForLists();
                    }
                }
            )
        );

        $scope.$on('$destroy', this.$onDestroy.bind(this));

        this.updateFieldsFromLocation();
    };

    Overview.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    Overview.prototype.configLists = function configLists() {
        var self = this,
            endPoint = this.sAPIAccess.endpoint('messageAnchor.byDomain.all').get(this.sDomainService.currentDomainId),
            tagsAutocompleteSource = this.sAPIAccess.endpoint('tagging.byDomain.autocomplete.anchor').get(this.sDomainService.currentDomainId, 'anchor')
            ;

        this.views = {};

        for (var i in this.structure) {
            this.views[i] = this.views[i] || {};
            Object.defineProperty(
                this.views[i],
                'count',
                {
                    get: function() {
                        var sum = 0;
                        for (i in this){
                            if (!(this[i] instanceof Model.List)) {
                                continue;
                            }
                            sum += this[i].pager.totalRecords || 0;
                        }

                        return sum;
                    }
                });

            for (var j in this.structure[i]) {
                var index = this.structure[i][j];
                this.views[i][index] = new Model.List(endPoint, 25);
                this.views[i][index].actions = {} || this.views[i][index].actions;

                this.views[i][index].mapFn = function(element) {
                    try {
                        var anchor = Model.Message.AnchorWithContent.createByData(element);
                    } catch(err) {
                        // if not invalid-action-error throw it
                        if (!(err instanceof Model.Exception.InvalidActionError)) {
                            throw err;
                        }

                        // for invalid-action-error report to bugsnag
                        self.sBugsnag.notify(
                            err,
                            {
                                metaData: {
                                    anchorUuid: element.uuid,
                                    domainId:   self.sDomainService.currentDomainId,
                                    domain:     self.sDomainService.currentDomain ? self.sDomainService.currentDomain.name : null
                                }
                            }
                        );
                    }

                    // try to create anchor without content if with content failed
                    if (!anchor) {
                        anchor = Model.Message.Anchor.createByData(element);
                    }

                    return anchor;
                };

                this.views[i][index].defaultOrderBy = {};

                var column = this.views[i][index].columns.createAndAdd('title');
                column.isSortable = true;
                column.label = 'Title';

                column = this.views[i][index].columns.createAndAdd('tags');
                column.label = 'Tags';

                if (i === 'AI-Templates') {
                    column = this.views[i][index].columns.createAndAdd('content');
                    column.label = 'Content';

                    column = this.views[i][index].columns.createAndAdd(COLUMN_MATCHING_SCORE);
                    column.label = 'Similarity';

                    column = this.views[i][index].columns.createAndAdd(COLUMN_MATCHING);
                    column.label = 'Matching';

                    if (index === 'Active') {
                        column = this.views[i][index].columns.createAndAdd('priority');
                        column.label = 'Priority';
                        column.isSortable = true;
                    }

                    this.views[i][index].filters.createAndAdd('types', [Model.Message.TYPE_ALIAS_REACTION], true);
                } else {
                    this.views[i][index].filters.createAndAdd('types', [Model.Message.TYPE_ALIAS_CONVERSATION], true);
                }

                if (index === 'Active') {
                    column = this.views[i][index].columns.createAndAdd('timeLastPublished');
                    column.label = 'Activated';
                    column.isSortable = true;

                    this.views[i][index].filters.createAndAdd('isPublished', true, true);
                } else if (index === 'Draft') {
                    this.views[i][index].filters.createAndAdd('isPublished', false, true);
                }

                if (['Inactive'].indexOf(index) === -1) {
                    column = this.views[i][index].columns.createAndAdd('timeUpdated');
                    column.label = 'Updated';
                    column.isSortable = true;

                    if(this.sAPIAccess.check({'message.RESTAccess': [Const.Method.POST, Const.Method.PUT, Const.Method.DELETE]})) {
                        this.views[i][index].actions['edit'] = new Model.Menu.Action('edit', this.editAnchor.bind(this));
                    }
                    if(this.sAPIAccess.isAllowed('messageAnchor.updateArchivedState', Const.Method.PUT)
                    && index !== 'Draft') {
                        this.views[i][index].actions['deactivate'] = new Model.Menu.Action('deactivate', this.deactivateAnchor.bind(this));
                    }
                    if(this.sAPIAccess.isAllowed('messageAnchor.duplicateMultiple', Const.Method.POST)) {
                        this.views[i][index].actions['duplicate'] = new Model.Menu.Action('duplicate', this.duplicateAnchor.bind(this));
                    }
                    this.views[i][index].filters.createAndAdd('isActive', true, true);
                    this.views[i][index].defaultOrderBy.timeUpdated = 'DESC';
                } else if (index === 'Inactive') {
                    column = this.views[i][index].columns.createAndAdd('timeArchived');
                    column.label = 'Deactivated';
                    column.isSortable = true;

                    this.views[i][index].filters.createAndAdd('isActive', false, true);
                    this.views[i][index].defaultOrderBy.timeArchived = 'DESC';

                    if(this.sAPIAccess.isAllowed('messageAnchor.updateArchivedState', Const.Method.PUT)) {
                        this.views[i][index].actions['activate'] = new Model.Menu.Action('activate', this.activateAnchor.bind(this));
                    }
                } else if (index === 'Draft') {
                    this.views[i][index].filters.createAndAdd('isPublished', false, true);
                }

                if (Object.keys(this.views[i][index].actions).length) {
                    column = this.views[i][index].columns.createAndAdd('options');
                    column.label = 'Options';
                }

                // allow multiple
                this.views[i][index].isMultipleAllowed = ['Inactive'].indexOf(index) === -1
                    && this.sAPIAccess.isAllowed('messageAnchor.duplicateMultiple', Const.Method.POST);


                //action available only for Drafts
                if (this.sAPIAccess.isAllowed('messageAnchor.RESTAccess', Const.Method.DELETE) && ['Draft'].indexOf(index) >= 0) {
                    this.views[i][index].actions['remove'] = new Model.Menu.Action('remove', this.deleteAnchor.bind(this));
                }

                var commonFilterFields = {
                    title : {
                        component : 'sTextInput',
                        options   : {
                            defaultValue : '',
                            attrs        : {
                                'placeholder' : 'Text',
                                'class'       : 'textsearch',
                                'is-disabled' : '$ctrl.helperControls.readonly'
                            },
                            label        : null
                        }
                    },
                    tags  : {
                        component : 'sTags',
                        options   : {
                            defaultValue : [],
                            attrs        : {
                                'autocomplete-source' : '\'' + tagsAutocompleteSource + '\'',
                                'max-length-input'    : 100,
                                'is-disabled'         : '$ctrl.helperControls.readonly',
                                'existing-only'       : true
                            },
                            asArray      : true,
                            label        : null
                        }
                    }
                };

                if (i === 'AI-Templates' && index === 'Active') {
                    this.views[i][index].filterComponents = {
                        '!switchGroup' : [
                            {
                                groupLabel : 'Filter by',
                                groupKey   : 'filter',
                                components : commonFilterFields
                            },
                            {
                                groupLabel : 'AI Tester',
                                groupKey   : 'test',
                                components : {
                                    'userInput' : {
                                        component : 'div',
                                        options   : {
                                            defaultValue : '',
                                            model        : 'ng-model',
                                            attrs        : {
                                                'contenteditable' : true,
                                                'placeholder'     : 'User input',
                                                'ng-class'        : '{"input" : true, "disabled" : $ctrl.helperControls.readonly}'
                                            },
                                            labelClass   : 'ai-tester-label',
                                            label        : 'User Message'
                                        }
                                    },
                                    'hint' : {
                                        component : 'div',
                                        text      : '<span class="hint">Test your bot\'s response to the User Message, adding a sentence or keywords here. Highlighted below is the matching text in our system. Keep in mind that only active AI Templates will be checked.</span>',
                                        options   : {
                                            rowClass  : 'width-100',
                                            attrs : {
                                                'ng-bind-html' : "$ctrl.helperControls.fields['!switchGroup'][1].components.hint.text",
                                                'class'        : 'text-smaller'
                                            },
                                            label : null
                                        }
                                    }
                                }
                            }
                        ]
                    };
                } else {
                    this.views[i][index].filterComponents = commonFilterFields;
                }
            }
        }
    };

    Overview.prototype.fetchMetaForLists = function fetchMetaForLists(force) {
        var actions = [],
            self    = this
        ;

        $.each(this.views, function(i, subView) {
            $.each(subView, function(j, list) {
                if (j === self.currentSubView && i === self.currentView) {
                    return;
                }
                actions.push(function() { return list.load(true, force) });
            });
        });

        return $.aggregateAction(actions, Model.RESTAccessByUUID.endpoint_batch()).then(function() {
            digestIfNeeded(self.$scope);
        });
    };

    Overview.prototype.changeView = function changeView(mainView, subView) {
        if (!mainView || !subView || mainView.toLowerCase() !== 'ai-templates' || subView.toLowerCase() !== 'active') {
            this.$location.search(PARAM_USER_INPUT, null);
            this.hasGroupedFilters = false;
        } else {
            this.hasGroupedFilters = true;
        }

        var changed = false;

        if (this.views[this.currentView][this.currentSubView].loading) {
            return;
        }

        if (mainView.toLowerCase() !== this.currentView.toLowerCase()) {
            changed = true;
            for (var i in this.views) {
                if (i.toLowerCase() === mainView.toLowerCase()) {
                    this.currentView = i;
                }
            }
        }

        subView = subView || Object.getFirstPropertyName(this.views[this.currentView]);

        if (subView.toLowerCase() !== this.currentSubView.toLowerCase()) {
            changed = true;
            for (i in this.views[this.currentView]) {
                if (i.toLowerCase() === subView.toLowerCase()) {
                    this.currentSubView = i;
                }
            }
        }

        if (changed) {
            this.$location.search('type', this.currentView.toLowerCase());
            this.$location.search('state', this.currentSubView.toLowerCase());
        }
    };

    Overview.prototype.updateFieldsFromLocation = function updateFieldsFromLocation() {
        if (this.routeChanging) {
            return false;
        }

        var $search     = this.$location.search(),
            mainView    = $search.type || Object.getFirstPropertyName(this.views),
            subView     = $search.state
        ;

        this.testingUserInput = !!$search[PARAM_USER_INPUT];

        this.changeView(mainView, subView);
    };

    Overview.prototype.syncListStates = function syncListStates() {
        if (!this.currentView || !this.currentSubView) {
            return;
        }
        var filters = this.views[this.currentView][this.currentSubView].filters.filters.filter(function(element) {
            return !element.isHidden;
        });

        for (var i in this.views) {
            for (var j in this.views[i]) {
                if (j === this.currentSubView && i === this.currentView) {
                    continue;
                }

                this.views[i][j].filters.clear();
                for (var k = 0; k < filters.length; k++) {
                    if (filters[k].name === PARAM_USER_INPUT){
                        continue;
                    }

                    this.views[i][j].filters.createAndAdd(filters[k].name, filters[k].value);
                }
            }
        }
    };

    Overview.prototype.handleRouteChangeStart = function handleRouteChangeStart() {
        this.routeChanging = true;
    };

    /**
     * @function
     * @name sMessageAnchor.Controller.Overview#handleTestingUserInputChange
     */
    Overview.prototype.handleTestingUserInputChange = function handleTestingUserInputChange() {
        var self           = this,
            hiddenColumns  = {
                'AI-Templates' : {
                    'Active' : ['tags', 'timeLastPublished']
                }
            },
            matchingColumns = [
                COLUMN_MATCHING,
                COLUMN_MATCHING_SCORE
            ],
            isColumnHidden = function (column, view, subview) {
                // Hide matching column based on whether testing by user input is active
                if (!self.testingUserInput && matchingColumns.includes(column)) {
                    return true;
                }

                return self.testingUserInput && hiddenColumns[view] && hiddenColumns[view][subview] &&
                    hiddenColumns[view][subview].includes(column);
            },
            subView,
            columns;

        // Hide or show particular columns based on current view
        for (var view in this.structure) {
            for (var index in this.structure[view]) {
                subView = this.structure[view][index];
                columns = this.views[view][subView].columns;

                if (columns.length && columns.columns) {
                    for (var i = 0; i < columns.length; i++) {
                        columns.columns[i].isHidden = isColumnHidden(columns.columns[i].name, view, subView);
                    }
                }
            }
        }
    };

    /**
     * @function
     * @name sMessageAnchor.Controller.Overview#editAnchor
     * @param {Model.Message.Anchor} msgAnchor
     */
    Overview.prototype.editAnchor = function editAnchor(msgAnchor) {
        this.$location.url('creator/' + msgAnchor.messageUuid);
    };

    Overview.prototype.deactivateAnchor = function deactivateAnchor(msgAnchor) {
        var self = this;

        return this.sMessageAnchor.deactivate(msgAnchor).then(function() {
            self.reload();
        });
    };

    /**
     * @function
     * @name sMessageAnchor.Controller.Overview#deleteAnchor
     * @param {Model.Message.Anchor} msgAnchor
     * @returns Promise
     */
    Overview.prototype.deleteAnchor = function deleteAnchor(msgAnchor) {
        var self = this;

        var confirmOptions = {
            confirm: 'Remove',
            decline: 'Cancel',
            title: 'Remove draft',
            content: 'Do you really want to remove the draft <b>' + msgAnchor.title + '</b> ?'
        };

        return this.sConfirm.open(confirmOptions).then(
            function () {
                return self.sMessageAnchor.removeAnchor(msgAnchor).then(function() {
                    self.reload();
                });
            });
    };

    Overview.prototype.duplicateAnchor = function duplicateAnchor(msgAnchor) {
        var self = this;

        return this.sMessageAnchor.duplicate(msgAnchor).then(function() {
            self.reload();
        });
    };

    Overview.prototype.activateAnchor = function activateAnchor(msgAnchor) {
        var self = this;

        return this.sMessageAnchor.activate(msgAnchor).then(function() {
            self.reload();
        });
    };

    Overview.prototype.reload = function triggerReload() {
        var self = this;
        this.$scope.$evalAsync(function() {
            self.views[self.currentView][self.currentSubView].load(false, true);
            self.fetchMetaForLists(true);
        });
    };

    /**
     * @function
     * @name sMessageAnchor.Controller.Overview#getListRowClass
     * @param {Model.Message.AnchorWithContent} $row
     * @return {String}
     */
    Overview.prototype.getListRowClass = function getListRowClass($row) {
        var loading = this.views[this.currentView][this.currentSubView].loading;

        return ((!loading && $row.AIMatch && $row.AIMatch.isBotReply) ? 'is-bot-reply' : '');
    };

    ns.Overview = Overview;
})(Object.namespace('sMessageAnchor.Controller'));
