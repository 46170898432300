(function (ns) {
    /**
     * @namespace
     * @alias sSource.Service.sSource
     *
     * @constructor
     * @param {String|Function} sourcesUrl
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     */
    var sSourceService = function (sourcesUrl, sAPIAccess) {

        var self                         = this,
            fetching                     ,
            sourcesCache                 = null,
            placeholdersCache            = [],
            sourceFieldsFetching
        ;

        this.sAPIAccess = sAPIAccess;

        var fetchSources = function fetchSources() {
                if (fetching) {
                    return fetching;
                }

                sourcesCache = [];

                var url = sourcesUrl,
                    endPointsLoading = []
                ;

                if (sourcesUrl instanceof Function) {
                    url = sourcesUrl();
                }
                if (!url) {
                    throw new Error('Sources requires an url in order to work properly!');
                }

                // fetch the source associated to product template and place its placeholders into the behavior
                if (sAPIAccess.isAllowed('source.productTemplate', Const.Method.GET)) {
                    endPointsLoading.push(
                        $.get(sAPIAccess.endpoint('source.productTemplate').get())
                        .then(function(sourceData) {
                            Model.Behavior.ExposePlaceholder.addPlaceholders(Model.Source.createByData(sourceData).placeholders);
                        })
                    );
                }

                endPointsLoading.push(
                    $.get(url)
                        .then(function(data) {
                            var records = data;

                            if (!(records instanceof Array)) {
                                throw new Error('Expected result as array, but got something else!');
                            }

                            records.map(function(sourceData) {
                                sourcesCache.push(Model.Source.createByData(sourceData));
                            });

                            cachePlaceholders();

                            return sourcesCache;
                        })
                );

                fetching = $.when.apply($, endPointsLoading).always(function() {
                    fetching = null;
                });

                return fetching;
            },
            cachePlaceholders = function() {
                placeholdersCache = sourcesCache.reduce(function (placeholders, source) {
                    placeholders.push.apply(placeholders, source.placeholders);
                    return placeholders;
                }, []);
            }
        ;

        /**
         * @name sSource.Service.sSource#getPlaceholders
         * @param {Array=} contextSpecificPlaceholders
         * @param {Array=} allowedTypes
         * @param {Array=} allowedPlaceholders
         * @return {$.Deferred|PromiseLike}
         */
        this.getPlaceholders = function getPlaceholders(contextSpecificPlaceholders, allowedTypes, allowedPlaceholders) {
            if (fetching) {
                return fetching;
            }

            if (!Array.isArray(contextSpecificPlaceholders)) {
                contextSpecificPlaceholders = [];
            }

            allowedTypes = allowedTypes || [];

            return $.when(self.getCachedPlaceholders(contextSpecificPlaceholders)).then(function (placeholders) {
                // If no allowed types are specified, only return placeholders with type text
                if (!Array.isArray(allowedTypes)) {
                    allowedTypes = [allowedTypes];
                }

                if (!allowedTypes.length) {
                    allowedTypes = [
                        Model.Source.Placeholder.PLACEHOLDER_TYPE_DEFAULT,
                        Model.Source.Placeholder.PLACEHOLDER_TYPE_URL,
                        Model.Source.Placeholder.PLACEHOLDER_TYPE_INTEGER
                    ];
                }

                // Filter out allowed placeholders
                if (Array.isArray(allowedPlaceholders) && allowedPlaceholders.length > 0) {
                    placeholders = placeholders.filter(function (placeholder) {
                        return allowedPlaceholders.includes(placeholder.token);
                    });
                }

                // Filter placeholders by type
                return placeholders.filter(function(placeholder) {
                    return allowedTypes.indexOf(placeholder.type) !== -1;
                });
            });
        };

        /**
         * Returns an array of placeholders
         * Three level of placeholders are distinguished
         * - (static global) placeholders - these are generally available wherever placeholders are
         * - (static) specific placeholders - these placeholders are always the same, but depend on a condition
         * - context specific placeholders - these placeholders gets created/defined by the context
         * @name sSource.Service.sSource#getCachedPlaceholders
         * @param {Array} contextSpecificPlaceholders
         * @param {Boolean?} specificPlaceholders Return the specificPlaceholders only, by default is false, so returns only global placeholders
         * @return {$.Deferred|PromiseLike}
         */
        this.getCachedPlaceholders = function getCachedPlaceholders(contextSpecificPlaceholders, specificPlaceholders) {
            function filterFn(placeholder) {
                return (placeholder.source.context === 'global') !== !!specificPlaceholders;
            }

            if (sourcesCache !== null) {
                return $.Deferred().resolve(placeholdersCache.filter(filterFn).concat(contextSpecificPlaceholders));
            }

            return fetchSources().then(function() {
                return placeholdersCache.filter(filterFn).concat(contextSpecificPlaceholders);
            })
        };

        /**
         * @name sSource.Service.sSource#findPlaceholderByToken
         * @param {String} token
         * @param {Array=} contextSpecificPlaceholders
         * @param {Array=} allowedTypes
         * @return {$.Deferred|PromiseLike}
         */
        this.findPlaceholderByToken = function findPlaceholderByToken(token, contextSpecificPlaceholders, allowedTypes) {
            return this.getPlaceholders(contextSpecificPlaceholders, allowedTypes).then(function(placeholders) {
                return placeholders.filter(function(placeholder) {
                    return placeholder.token === token;
                }).shift();
            });
        };

        /**
         * @name sSource.Service.sSource#getMatchingPlaceholders
         * @param {Array=} contextSpecificPlaceholders
         * @param {Array=} allowedTypes
         * @param {Array=} allowedPlaceholders
         * @param {String} word
         */
        this.getMatchingPlaceholders = function getMatchingPlaceholders(word, contextSpecificPlaceholders, allowedTypes, allowedPlaceholders) {
            return this.getPlaceholders(contextSpecificPlaceholders, allowedTypes, allowedPlaceholders).then(function(placeholders) {
                return placeholders.filter(function(placeholder) {
                    return placeholder.fullyQualifiedName.search(new RegExp(word, 'i')) !== -1;
                })
            });
        };

        /**
         * @return {$.Deferred}
         */
        this.getAvailableSourceFieldsAsAttributes = function getAvailableSourceFieldsAsAttributes() {
            if (!sourceFieldsFetching) {
                sourceFieldsFetching = sSourceService.prototype.getAvailableSourceFieldsAsAttributes.call(self);
            }

            return sourceFieldsFetching;
        };

        /**
         * @name sSource.Service.sSource#invalidateCache
         * @method
         */
        this.invalidateCache = function invalidateCache() {
            sourcesCache = null;
        };

        this.invalidateFieldAsAttributeCache = function invalidateFieldAsAttributeCache() {
            sourceFieldsFetching = null;
        };
    };

    /**
     * @function
     * @name sSource.Service.sSource#refreshSource
     * @param {Model.Source} source
     * @returns {$.Deferred}
     */
    sSourceService.prototype.refreshSource = function refreshSource(source) {
        return $.ajax({
            url    : this.sAPIAccess.endpoint('source.refresh').put(source.uuid),
            method : Const.Method.PUT
        });
    };

    /**
     * @function
     * @name sSource.Service.sSource#clearAllEntries
     * @param {Model.Source} source
     * @returns {$.Deferred}
     */
    sSourceService.prototype.clearAllEntries = function clearAllEntries(source) {
        return $.ajax({
            url    : this.sAPIAccess.endpoint('source.clear').put(source.uuid),
            method : Const.Method.PUT
        });
    };

    /**
     * @function
     * @name sSource.Service.sSource#exportSourceContents
     * @param {Model.Source} source
     * @returns {$.Deferred}
     */
    sSourceService.prototype.exportSourceContents = function exportSourceContents(source) {
        return $.ajax({
            url    : this.sAPIAccess.endpoint('source.content.export').get(source.uuid),
            method : Const.Method.GET
        }).then(function (data, status, xhr) {
            var filename = FilenameFromHeaderParser.getFilename(xhr.getResponseHeader('content-disposition')) || 'unknown.csv',
                download = new sDownload(data, filename, 'text/csv')
            ;

            download.downloadFile();
        });
    };

    /**
     * @return {$.Deferred}
     */
    sSourceService.prototype.getAvailableSourceFieldsAsAttributes = function getAvailableSourceFieldsAsAttributes() {
        return $.ajax({
            url: this.sAPIAccess.endpoint('source.fieldsAsAttributes').get()
        }).then(function(data) {
            if (!(data instanceof Array)) {
                return [];
            }

            return data.map(function (elem) {
                return new Model.Source.FieldAsAttribute(elem.name, elem.sourceNames);
            });
        });
    };

    /**
     * @name sSource.Service.sSource#transformer
     * @param {Model.Source.Placeholder} placeholder
     * @returns {String}
     */
    sSourceService.transformer = function transformer(placeholder) {
        return placeholder.token;
    };

    ns.sSource = sSourceService;
})(Object.namespace('sSource.Service'));
