(function() {

    var ns = Object.namespace('Model.ContentFeed'),
        Entry = ns.Entry = function (uuid) {
            var timePublished = moment(null);

            /**
             * @property {String}
             * @name Model.ContentFeed.Entry#channelId
             */
            this.channelId = null;
            /**
             * @property {String}
             * @name Model.ContentFeed.Entry#channelName
             */
            this.channelName = '';
            /**
             * @property {String}
             * @name Model.ContentFeed.Entry#title
             */
            this.title = '';
            /**
             * @property {String}
             * @name Model.ContentFeed.Entry#url
             */
            this.url = '';
            /**
             * @property {String}
             * @name Model.ContentFeed.Entry#description
             */
            this.description = '';

            Object.defineProperties(this, {
                /**
                 * @property {moment|null}
                 * @name Model.ContentFeed.Entry#timePublished
                 */
                timePublished: {
                    enumerable: true,
                    configurable: true,
                    get: function () {
                        return timePublished;
                    },
                    set: function (val) {
                        if (!moment.isMoment(val)) {
                            val = moment(val);
                        }
                        timePublished = val;
                    }
                }
            });

            /**
             * @property {Object}
             * @name Model.ContentFeed.Entry#_pProto
             */
            Model.ContentFeed.Entry._pProto.constructor.call(this, uuid);
        };

    Object.extendProto(Model.ContentFeed.Entry, Model.UUID);

    /**
     * Creates a content-feed-entry by data
     * @function Model.ContentFeed.Entry#createByData
     * @param {object} data
     * @static
     * @returns Model.ContentFeed.Entry
     */
    Model.ContentFeed.Entry.createByData = function createByData(data) {
        var uuid,
            contentFeedEntry;

        if (data && data.hasOwnProperty('uuid')) {
            uuid = data.uuid;
        }

        contentFeedEntry = new Model.ContentFeed.Entry(uuid);
        contentFeedEntry.updateByData(data);

        return contentFeedEntry;
    };

    /**
     * Updates the content-feed-entry by data
     * @function Model.ContentFeed.Entry#updateByData
     * @param {object} data
     * @returns Model.ContentFeed.Entry
     */
    Entry.prototype.updateByData = function updateByData(data) {
        if (data.timePublished) {
            data.timePublished = data.timePublished * 1000;
        }

        Object.updateByData(this, data);
        return this;
    };

    /**
     * @function Model.ContentFeed.Entry#setProcessed
     * @returns jqXHR
     */
    Entry.prototype.setProcessed = function setProcessed() {

        var self        = this,
            endpoint    = Model.ContentFeed.Entry.apiEndPoint_setProcessed;

        return $.ajax({
            url         : endpoint(self.uuid),
            method      : Const.Method.PUT,
            context     : self,
            processData : false,
            contentType : false
        })
    };

    Model.ContentFeed.Entry.apiEndPoint_setProcessed = false;
})();
