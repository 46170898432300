(function(ns) {
    var EVENT_AUTH_USER_UPDATED = 'sEventAuthUserUpdated';

    /**
     * @namespace
     * @alias sSecurity.Service.sAuth
     * @constructor
     *
     * @param $rootScope
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param $mdDialog
     */
    var sAuth = function ($rootScope, sAPIAccess, $mdDialog) {
        var self = this,
            readyDefer = $.Deferred(),
            user = null,
            setUser = function setUser(val) {
                user = val;
                $rootScope.$broadcast(EVENT_AUTH_USER_UPDATED, user);
            }
        ;

        this.$deRegister    = [];
        this.$rootScope     = $rootScope;
        this.$mdDialog      = $mdDialog;
        this.isDialogOpen   = false;

        Object.defineProperties(this, {
            /**
             * @property {promise}
             * @name sSecurity.Service.sAuth#ready
             */
            ready: {
                enumerable: true,
                configurable: false,
                get: function () {
                    return readyDefer.promise;
                }
            },
            /**
             * @property {null|Model.User}
             * @name sSecurity.Service.sAuth#user
             */
            user: {
                enumerable: true,
                configurable: false,
                get: function () {
                    return user;
                }
            }
        });

        /**
         * @function
         * @name sSecurity.Service.sAuth#getUser
         * @returns {Model.User|null}
         *
         * @deprecated - use self.user instead
         */
        this.getUser = function getUser() {
            return self.user;
        };

        /**
         * @function
         * @name sSecurity.Service.sAuth#reloadUser
         * @returns {$.Deferred}
         */
        this.reloadUser = function reloadUser() {
            return $.ajax({
                url         : sAPIAccess.endpoint('security.authUser').get(),
                method      : Const.Method.GET,
                context     : this,
                processData : false,
                contentType : false,
                dataType    : 'json'
            }).then(function (data) {
                setUser(Model.User.createByData(data));
                return user;
            }).always(function (data) {
                readyDefer.resolve(data);
            });
        };

        /**
         * @function
         * @name sSecurity.Service.sAuth#authenticate
         * @param {string} email
         * @param {string} password
         * @returns {$.Deferred}
         */
        this.authenticate = function authenticate(email, password) {
            var self = this,
                data = new FormData()
            ;

            data.append('email', email);
            // bin2hex to make the sniffing harder
            data.append('password', password.bin2hex());

            return $.ajax({
                url         : sAPIAccess.endpoint('security.authenticate').post(),
                method      : Const.Method.POST,
                data        : data,
                processData : false,
                contentType : false
            }).then(function () {
                self.reloadUser();
            });
        };

        /**
         * @function
         * @name sSecurity.Service.sAuth#logout
         * @returns {$.Deferred}
         */
        this.logout = function logout() {
            self.$rootScope.$broadcast('sAuthUserLogout', user.uuid);

            return $.ajax({
                ignoreAggregation   : true,
                url                 : sAPIAccess.endpoint('security.logout').post(),
                method              : Const.Method.POST
            }).then(function () {
                setUser(null);
            });
        };
    };

    sAuth.prototype.init = function init() {
        var self = this;

        this.$deRegister = this.$deRegister.concat($(document).$on('auth-error', function () {
            if (self.isDialogOpen) {
                return;
            }

            self.isDialogOpen = true;

            self.$mdDialog.show({
                controller          : sSecurity.Controller.AuthenticationExpired,
                controllerAs        : '$ctrl',
                templateUrl         : 'ssecurity:authentication-expired',
                parent              : angular.element(Const.PanelAnchor),
                clickOutsideToClose : false,
                bindToController    : true
            }).then(
                function() {
                    self.isDialogOpen = false;
                },
                function() {
                    self.isDialogOpen = false;
                }
            );
        }));
    };

    sAuth.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while(($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    Object.defineProperties(
        sAuth,
        {
            EVENT_AUTH_USER_UPDATED : {
                value : EVENT_AUTH_USER_UPDATED
                /**
                 * @property
                 * @constant
                 * @name sSecurity.Service.sAuth#EVENT_AUTH_USER_UPDATED
                 * @type {String}
                 */
            }
        }
    );

    ns.sAuth = sAuth;
})(Object.namespace('sSecurity.Service'));
