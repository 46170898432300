(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sConnection
     *
     * @param $scope
     * @param $element
     */
    var sConnection = function($scope, $element) {
        /**
         * @property
         * @name Controller.Component.sConnection#$scope
         * @type {$scope}
         */
        this.$scope = $scope;

        /**
         * @property
         * @name Controller.Component.sConnection#appendTo
         * @type {String}
         */

        this.$deRegister = [];

        this.$element = $element;
    };

    /**
     * @function
     * @name Controller.Component.sConnection#$onInit
     */
    sConnection.prototype.$onInit = function() {
        requestAnimationFrame(this.init.bind(this));
    };

    /**
     * @function
     * @name Controller.Component.sConnection#init
     */
    sConnection.prototype.init = function() {
        var self = this;
        if (!this.appendTo) {
            return;
        }

        this.$rectH  = $('<div></div>');
        this.$rectH.css('position', 'absolute');
        this.$rectV  = $('<div></div>');
        this.$rectV.css('position', 'absolute');

        this.$appendTo = $(this.appendTo);
        this.$appendTo.append(this.$rectH).append(this.$rectV);

        this.$rectH.append(this.$element.find('ng-transclude'));

        this.$to    = $(this.to);
        this.$from  = $(this.from);

        this.subscribedResizer = this.reDraw.bind(this);

        this.$deRegister.push(this.$scope.$on('redraw-connection', this.subscribedResizer));

        this.$deRegister = this.$deRegister.concat($(window).$on('resize', this.subscribedResizer));

        this.reDraw();

        this.$deRegister.push(this.$scope.$watch(function() {
                if (!self.$from || !self.$to) {
                    return null;
                }
                var $offsetFrom         = self.$from.offset(),
                    $offsetTo           = self.$to.offset()
                ;

                if (!$offsetFrom || !$offsetTo) {
                    return null;
                }

                return '(' + Math.round($offsetFrom.left + self.$appendTo.scrollLeft()) + ';' + Math.round($offsetFrom.top + self.$appendTo.scrollTop()) + ')' +
                    '(' +  Math.round($offsetTo.left + self.$appendTo.scrollLeft()) + ';' + Math.round($offsetTo.top + self.$appendTo.scrollTop()) + ')';
            },
            function(newVal, oldVal) {
                if (oldVal === newVal) {
                    return;
                }
                self.reDraw();
            }));
    };

    sConnection.prototype.reDraw = function reDraw() {
        if ((!this.from || !this.to)) {
            if (this.$rectV && this.$rectV.length) {
                this.$rectV.css('display', 'none');
            }

            if (this.$rectH && this.$rectH.length) {
                this.$rectH.css('display', 'none');
            }

            this.$scope.$emit('redraw-connection-finished');
            return;
        }

        if (!this.$rectH || !this.$rectH.length || !this.$rectV || !this.$rectV.length || !this.$to.length || !this.$from.length) {
            this.$scope.$emit('redraw-connection-finished');
            return;
        }

        if (!this.$offsetParentFrom) {
            this.$offsetParentFrom = this.$from.scrollParent(true);
        }

        this.$rectV.css('display', 'block');
        this.$rectH.css('display', 'block');

        var classNameFragments  = ['s-connection'],
            offsetC             = this.$appendTo[0].getBoundingClientRect(),
            self                = this,
            y,
            x,
            w,
            h
            ;

        var rectFrom        = this.$from[0].getBoundingClientRect(),
            rectTo          = this.$to[0].getBoundingClientRect()
            ;

        [rectFrom, rectTo].map(function(element, index) {
            if (element.x === element.y && element.x === 0 && element.width === element.x) {
                if (index === 0) {
                    self.$from = $(self.from);
                    self.$offsetParentFrom = self.$from.scrollParent(true);
                    element = self.$from[0].getBoundingClientRect();
                } else {
                    self.$to = $(self.to);
                    if (!self.$to[0]) {
                        self.$scope.$emit('redraw-connection-finished');
                        return;
                    }
                    element = self.$to[0].getBoundingClientRect();
                }
            }
            element.right = element.left + element.width;
            element.bottom = element.top + element.height;

            element.centerX = element.left + element.width / 2;
            element.centerY = element.top + element.height / 2;

            if (index === 0) {
                rectFrom = element;
            } else {
                rectTo = element;
            }
        });

        var rectFromParent  = this.$offsetParentFrom[0].getBoundingClientRect()
        ;

        var usedRectFrom = rectFrom;

        // if there is a scrollParent
        if (this.$offsetParentFrom[0] !== this.$appendTo[0]) {
            usedRectFrom = rectFromParent;
        }

        if (usedRectFrom.right < rectTo.left) {
            classNameFragments.push('right');
            x = Math.round(usedRectFrom.right);
            w = Math.round(rectTo.centerX - usedRectFrom.right);
        } else if (usedRectFrom.left > rectTo.right) {
            classNameFragments.push('left');
            x = Math.round(rectTo.centerX);
            w = Math.round(usedRectFrom.left - rectTo.centerX);
        }

        if (rectFrom.top < rectTo.top) {
            classNameFragments.push('top');
            y = Math.round(rectFrom.centerY);
            h = Math.round(rectTo.top - rectFrom.centerY)
        } else {
            classNameFragments.push('bottom');
            y = Math.round(rectTo.centerY);
            h = Math.round(rectFrom.top - rectTo.centerY)
        }

        var oldDimensions = {},
            newDimensions = {
                'left'  : x - offsetC.left,
                'top'   : y - offsetC.top + this.$appendTo.scrollTop() ,
                'width' : w,
                'height': h
            }
            ;

        var diff = 0;
        for (var i in newDimensions) {
            if (i !== 'width' && i !== 'left') {
                oldDimensions[i] = this.$rectV.css(i);
            } else {
                oldDimensions[i] = this.$rectH.css(i);
            }
            // fix for safari
            if (oldDimensions[i] === 'auto') {
                oldDimensions[i] = 0;
            }
            diff +=  Math.abs(parseInt(oldDimensions[i]) - parseInt(newDimensions[i]));
        }

        if (diff > 4) {
            // If the class contains bottom, then the height should be added to the top value
            var newDH = $.extend({}, newDimensions);
            if (classNameFragments.indexOf('bottom') !== -1) {
                newDH.top = parseInt(newDH.top) + parseInt(newDH.height);
            }
            newDH.height = 0;

            this.$rectH.css(newDH).attr('class', classNameFragments.join(' '));

            // If the class contains right, then the width should be added to the left value
            var newDV = $.extend({}, newDimensions);
            if (classNameFragments.indexOf('right') !== -1) {
                newDV.left = parseInt(newDV.left) + parseInt(newDV.width);
            }
            newDV.width = 0;

            this.$rectV.css(newDV).attr('class', classNameFragments.join(' '));
        }

        this.$appendTo.css('min-height', '0px');

        this.$scope.$emit('redraw-connection-finished');
    };

    /**
     * @name Controller.Component.sConnection#$onChanges
     * @param {Object} changes
     */
    sConnection.prototype.$onChanges = function(changes) {
        if (changes.from) {
            this.from = changes.from.currentValue;
            this.$from = $(this.from);
        }

        if (changes.to) {
            this.to = changes.to.currentValue;
            this.$to = $(this.to);
        }


        requestAnimationFrame(this.reDraw.bind(this));
    };

    /**
     * @function
     * @name Controller.Component.sConnection#$onDestroy
     */
    sConnection.prototype.$onDestroy = function $onDestroy() {
        var $destroyFunc;

        this.$rectH && this.$rectH.remove();
        this.$rectV && this.$rectV.remove();
        while (($destroyFunc = this.$deRegister.pop())) {
            $destroyFunc();
        }
    };

    ns.sConnection = sConnection;

})(Object.namespace('Controller.Component'));