(function(ns) {
    /**
     * @namespace
     * @alias Controller.Component.sPaginator
     * @constructor
     *
     * @param $scope
     * @param $location
     */
    var sPaginator = function sPaginator($scope, $location) {
        this.$scope         = $scope;
        this.$deRegister    = [];
        this.$location      = $location;

        /**
         * @property
         * @name Controller.Component.sPaginator#model
         * @type Model.Paginator
         */

        /**
         * @property
         * @name Controller.Component.sPaginator#readonly
         * @type Boolean
         */
    };

    /**
     * @function
     * @name Controller.Component.sPaginator#$onInit
     */
    sPaginator.prototype.$onInit = function $onInit() {
        var self = this;

        this.$deRegister.push(this.$scope.$watchCollection(
            function() {
                return self.model;
            },
            function(newModel) {
                Object.instanceOf(newModel, Model.Paginator);
            }
        ));
    };

    /**
     * @function
     * @name Controller.Component.sPaginator#$onDestroy
     */
    sPaginator.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name Controller.Component.sPaginator#changeToPage
     * @param {int} newPage
     */
    sPaginator.prototype.changeToPage = function changeToPage(newPage) {
        if (this.readonly) {
            return;
        }

        this.model.currentPage = newPage;
    };

    ns.sPaginator = sPaginator;
})(Object.namespace('Controller.Component'));
