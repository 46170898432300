(function(ns) {
    var DEFAULT_COMPONENT_NAME  = 's-dynamic-form',
        KEY_MODEL               = 'model',
        KEY_TEMPLATE            = 'template',
        KEY_RULES               = 'rules',
        KEY_VISIBILITY          = 'visibility'
    ;

    /**
     * @namespace
     * @alias Controller.Component.sDynamicForm
     * @constructor
     *
     * @param $compile
     * @param $element
     * @param $scope
     */
    var sDynamicForm = function($compile, $element, $scope) {
        var rules   = this.rules, // might be that we already have something
            self    = this
            ;

        /**
         * @property
         * @name Controller.Component.sDynamicForm#batchProperty
         */

        /**
         * @property
         * @name Controller.Component.sDynamicForm#componentName
         */

        /**
         * @property
         * @name Controller.Component.sDynamicForm#formCtrl
         */

        /**
         * @property
         * @type FormController
         * @name Controller.Component.sDynamicForm#$element
         */
        this.$element   = $element;

        /**
         * @function
         * @type $compile
         * @name Controller.Component.sDynamicForm#$compile
         */
        this.$compile   = $compile;

        /**
         * @type Scope
         * @property
         * @name Controller.Component.sDynamicForm#$scope
         */
        this.$scope     = $scope;

        /**
         * Store if the directive has already been linked
         * @type {boolean}
         */
        this.linked = false;

        /**
         * Stores the scope used to link the form, so it can be detached later
         * @name Controller.Component.sDynamicForm#$childScope
         */
        this.$childScope = null;

        Object.defineProperty(
            this,
            /**
             * Object that describes what components to render and which fields to associate to them
             * example:
             * {
             *     // renders a field called 'text' and assigns the model value 'model.text' to it
             *     text: {
             *         component: input
             *         options: {
             *             attrs: {
             *                // html attributes to set on the field defined by component
             *                ng-disabled: true
             *             }
             *         }
             *     },
             *     // renders a pseudo field called 'text' and assigns the model value 'model' to it, because
             *     // of the pseudo prefix the model won't be traversed, the component can do that on it's own
             *     !text: {
             *         component: input
             *         options: {
             *             attrs: {
             *                // html attributes to set on the field defined by component
             *                ng-disabled: true
             *             }
             *         }
             *     }
             * }
             *
             *
             * @property
             * @name Controller.Component.sDynamicForm#rules
             */
            'rules',
            {
                enumerable  : true,
                configurable: true,
                get         : function() {
                    return rules;
                },
                set         : function(val) {
                    rules = val;
                    self.rulesHandler();
                    if (self.linked) {
                        self.$postLink();
                    }
                }
            }
        );
    };

    /**
     * @function
     * @name Controller.Component.sDynamicForm#getComponentName
     * @param {string} component
     * @returns {string}
     */
    sDynamicForm.prototype.getComponentName = function getComponentName(component) {
        return component.replace(/[A-Z]/g, function(match, pos) {
            return (pos ? '-' : '') + match.toLowerCase();
        });
    };

    /**
     * @function
     * @name Controller.Component.sDynamicForm#createFormElementForBatched
     * @returns {*}
     */
    sDynamicForm.prototype.createFormElementForBatched = function createFormElementForBatched() {
        var componentName;

        if (!this.componentName) {
            componentName = DEFAULT_COMPONENT_NAME;
        } else {
            componentName = this.getComponentName(this.componentName);
        }

        var $formElement = angular.element('<' + componentName + '></' + componentName + '>');

        if (componentName === DEFAULT_COMPONENT_NAME) {
            $formElement
                .attr(KEY_MODEL, '$ctrl.model[$ctrl.batchProperty]')
                .attr(KEY_RULES, '$ctrl.rules');
        }

        return $formElement;
    };

    /**
     * @function
     * @name Controller.Component.sDynamicForm#rulesHandler
     */
    sDynamicForm.prototype.rulesHandler = function rulesHandler() {
        if (this.$childScope) {
            this.$childScope.$destroy();
        }
        this.$element.children().remove();

        var key,
            componentName,
            options,
            rowClass,
            label,
            labelClass,
            $rowElement,
            $element,
            $formElementForBatched
        ;

        for (key in this.rules) {
            if (!this.rules.hasOwnProperty(key)) {
                continue;
            }

            // this condition is met for all properties which are batched
            if (this.batchProperty && (!this.rules[key].options || !this.rules[key].options.isMeta)) {

                // if we have a batch-element and we created the batched
                // form already we continue because it will be handled inside the batched form
                if (!!$formElementForBatched) {
                    continue;
                }

                // create form for batch
                $formElementForBatched = this.createFormElementForBatched();
                this.$element.append($formElementForBatched);
                continue;
            }

            if (!this.batchProperty && this.rules[key].options && this.rules[key].options.isMeta) {
                continue;
            }

            // This is a group of fields that should be rendered inside per-group radio button switch
            if (Array.isArray(this.rules[key])) {
                var groups = JSON.stringify(this.rules[key])
                    .replace(/'/g, '\\\'')
                    .replace(/"/g, '\'');

                $rowElement = angular.element(
                    '\<s-dynamic-form-grouped-row  groups="' + groups + '" model="$ctrl.model" helper-controls="$ctrl.helperControls">\</s-dynamic-form-grouped-row>'
                );

                this.$element.append($rowElement);

                continue;
            }

            if (!this.rules[key].component) {
                continue;
            }

            componentName = this.getComponentName(this.rules[key].component);

            options     = this.rules[key].options || {};
            label       = options.hasOwnProperty('label') ? options.label : key;
            labelClass  = options.hasOwnProperty('labelClass') ? options.labelClass : '';
            rowClass    = options.hasOwnProperty('rowClass') ? options.rowClass : '';
            $rowElement = angular.element('\<s-dynamic-form-row  component-name="' + componentName + '" key="' + key + '" helper-controls="$ctrl.helperControls">\</s-dynamic-form-row>');
            $element    = angular.element('<' + componentName + '></' + componentName + '>');

            if (label) {
                $rowElement.attr('label', label);
            }

            if (labelClass) {
                $rowElement.attr('label-class', labelClass);
            }

            if (rowClass) {
                $rowElement.attr('class', rowClass);
            }

            if (this.rules[key].hasOwnProperty(KEY_VISIBILITY)) {
                $rowElement.attr('ng-if', this.rules[key].visibility);
            }

            if (this.rules[key].hasOwnProperty(KEY_TEMPLATE)) {
                $rowElement.attr(KEY_TEMPLATE, this.rules[key].template);
            }

            $element.attr(
                (options[KEY_MODEL] || KEY_MODEL),
                key.match(/^\!.*?$/) ? ("$ctrl.model") :
                    (key.match(/^[a-zA-Z]*\[\d*\]$/) ? ("$ctrl.model." + key) : ("$ctrl.model['" + key + "']"))
            );

            if (this.rules[key].options && this.rules[key].options.attrs) {
                $element.attr(this.rules[key].options.attrs);
            }
            $rowElement.append($element);
            this.$element.append($rowElement);
        }
    };

    /**
     * @function
     * @name Controller.Component.sDynamicForm#$onInit
     */
    sDynamicForm.prototype.$onInit = function $onInit() {
        this.rulesHandler();
    };

    /**
     * @function
     * @name Controller.Component.sDynamicForm#$onDestroy
     */
    sDynamicForm.prototype.$onDestroy = function $onDestroy() {
        if (this.$childScope) {
            this.$childScope.$destroy();
        }
    };

    /**
     * @function
     * @name Controller.Component.sDynamicForm#$postLink
     */
    sDynamicForm.prototype.$postLink = function $postLink() {
        var self = this
            ;

        this.$childScope = this.$scope.$new();

        this.linked = true;

        $.each(this.$element.children(), function(index, element) {
            var linkFn = self.$compile(element);
            linkFn(self.$childScope, null, {});
        });
    };

    /**
     * @function
     * @name Controller.Component.sDynamicForm#$onChanges
     * @param {Object} changes
     */
    sDynamicForm.prototype.$onChanges = function $onChanges(changes) {
        if (changes.componentName && !changes.componentName.isFirstChange()) {
            this.rulesHandler();
            if (this.linked) {
                this.$postLink();
            }
        }
    };

    ns.sDynamicForm = sDynamicForm;
})(Object.namespace('Controller.Component'));
