(function(ns) {
    /**
     * @namespace
     * @alias Model.Analytics.Campaign
     * @constructor
     */
    var Campaign = function () {
        var date,
            conditions  = new Model.Segment.Condition.Collection(),
            self = this
        ;

        Object.defineProperties(
            this,
            {
                date: {
                    enumerable: true,
                    get: function () {
                        return date * 1000;
                    },
                    set: function (val) {
                        if (date !== val) {
                            date = val;
                        }
                    }
                    /**
                     * @property
                     * @type {number}
                     * @name Model.Analytics.Campaign#date
                     */
                },
                userTimezone: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @type {number}
                     * @name Model.Analytics.Campaign#userTimezone
                     */
                },
                messageTag: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @type {string}
                     * @name Model.Analytics.Campaign#messageTag
                     */
                },
                conversationTitle: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @type {string}
                     * @name Model.Analytics.Campaign#conversationTitle
                     */
                },
                conversationUuid: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @type {string}
                     * @name Model.Analytics.Campaign#conversationUuid
                     */
                },
                conditions: {
                    enumerable: true,
                    get: function () {
                        return conditions;
                    }
                    /**
                     * @property
                     * @type {Model.Segment.Condition.Collection}
                     * @name Model.Analytics.Campaign#conditions
                     */
                },
                segment: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @type {Model.Segment}
                     * @name Model.Analytics.Campaign#segment
                     */
                },
                status: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @type {string}
                     * @name Model.Analytics.Campaign#status
                     */
                },
                recipients: {
                    enumerable: true,
                    writable: true
                    /**
                     * @property
                     * @type {number}
                     * @name Model.Analytics.Campaign#recipients
                     */
                }
            }
        );


        /**
         * @name Model.Analytics.Campaign#updateFromJson
         * @param data
         * @returns {Model.Analytics.Campaign}
         */
        this.updateFromJson = function (data) {
            var props = Object.getOwnPropertyNames(this);

            for (var i = 0; i < props.length; i++) {
                if (self[props[i]] instanceof Object && self[props[i]].updateByData) {
                    self[props[i]].updateByData(data[props[i]]);
                    continue;
                }
                if (data[props[i]]) {
                    self[props[i]] = data[props[i]];
                }
            }

            return this;
        }
    };

    /**
     * @name Model.Analytics.Campaign#fromJson
     * @param data
     * @returns {Model.Analytics.Campaign}
     */
    Campaign.fromJson = function (data) {
        var json = typeof(data) === 'string' ? JSON.parse(data) : data,
            campaign = new Campaign();
        return campaign.updateFromJson(json);
    };

    /**
     * @name Model.Analytics.Campaign#mapExistingSegment
     * @param domainSegments
     */
    Campaign.prototype.mapExistingSegment = function mapExistingSegment(domainSegments) {
        var self = this;

        if (this.conditions.length === 1 && typeof this.channel === 'undefined') {
            var singleCondition = this.conditions.conditions[0];
            domainSegments.map(function(channel) {
                if ((singleCondition.name === 'subscription' || singleCondition.name === 'qualify')
                    && (singleCondition.operator === 'is_subscribed'
                        || singleCondition.operator === '='
                        || singleCondition.operator === 'part_of')
                    && singleCondition.value[0] === channel.uuid) {
                    self.segment = channel;
                }
            });
        } else {
            //we create a TMP segment with the current conditions
            var tmpSegment = new Model.Segment();
            tmpSegment.type = Model.Segment.KEY_BEHAVIOR_BASED;
            tmpSegment.name = 'Conditions';
            tmpSegment.conditions.updateByData(this.conditions.toJSON());
            this.segment = tmpSegment;
        }
    };

    ns.Campaign = Campaign;
})(Object.namespace('Model.Analytics'));
