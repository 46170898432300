/**
 * Created by Gabor on 23-Jan-17.
 */


var ns = Object.namespace('Model.Exception'),
    /**
     * @param {Model.Message[]} messages
     * @type {ns.InvalidMessages}
     */
    InvalidMessages = ns.InvalidMessages = function(messages) {

    this.name           = 'InvalidMessages';
    this.message        = 'Messages are invalid for some reason';
    this.messages       = messages

};

Object.extendProto(InvalidMessages, Error);
