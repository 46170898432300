(function (ns) {
    var EVENT_MARKER_POPUP_OPENED = 'sMarkerMarkerPopupOpened';

    /**
     * @namespace
     * @alias Controller.Component.sMarker
     *
     * @constructor
     * @param {Service.sFormPanel} sFormPanel
     * @param {Service.sMarkerRepository} sMarkerRepository
     * @param $scope
     */
    var sMarker = function (sFormPanel, sMarkerRepository, $scope) {
        this.sFormPanel        = sFormPanel;
        this.sMarkerRepository = sMarkerRepository;
        this.$deRegister       = [];
        this.$scope            = $scope;

        /**
         * @property
         * @name Controller.Component.sMarker#isOpen
         * @type {Boolean}
         */

        /**
         * @type String
         * @name Controller.Component.sMarker#defaultName
         */

        /**
         * @property
         * @name Controller.Component.sMarker#label
         * @type {String}
         */

        /**
         * @property
         * @name Controller.Component.sMarker#model
         * @type {String}
         */

        /**
         * @property
         * @name Controller.Component.sMarker#orientation
         * @type {String}
         */
    };

    sMarker.prototype.$onInit = function $onInit() {
        this.defaultName = this.defaultName || Model.Marker.MARKER_DEFAULT_NAME;
        this.label       = this.label || ("New " + this.defaultName);
        this.orientation = this.orientation || "right";

        var self = this;

        this.$deRegister.push(this.$scope.$watch(
            function() {
                return self.model;
            },
            function(newVal, oldVal) {
                self.$scope.$emit(Model.Marker.EVENT_MARKER_MODEL_CHANGE, newVal, oldVal);
            })
        );
    };

    /**
     * @function
     * @name Controller.Component.sMarker#$onDestroy
     */
    sMarker.prototype.$onDestroy = function() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @param event
     */
    sMarker.prototype.handleClick = function handleClick(event) {
        if (this.model) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        }

        delete(event.target);
        this.handleEdit(event);
    };

    /**
     * @param {Event} event
     */
    sMarker.prototype.handleEdit = function handleEdit(event) {
        var self = this,
            marker
        ;

        var rules = {
            '!uuid' : {
                component : 'sMarkerSelect',
                options : {
                    attrs : {
                        'allow-new'        : true,
                        'is-required'      : true,
                        'on-handle-change' : '$ctrl.helperControls.changeSelection(item)',
                        'default-name'     : this.defaultName
                    },
                    label : 'Name'
                }
            }
        };

        var model = this.model;

        if (!model) {
            marker = Model.Marker.createMarkerWithName(this.label);
            model = marker.uuid;
        }

        this.sFormPanel.open(
            event,
            model,
            rules,
            null,
            {
                changeSelection : function(item) {
                    this.selectedMarker = item;
                }
            }
        ).then(function(newVal, oldVal) {
            if (oldVal === newVal) {
                if (!self.model) {
                    self.model = newVal;
                }
                return;
            }
            self.model = newVal;

            // remove the tmp marker if not used
            if (marker && oldVal === marker.uuid) {
                self.sMarkerRepository.removeItem(marker);
            }

            if (this.selectedMarker.isNew) {
                self.sMarkerRepository.addItem(this.selectedMarker);
            }
        }, function() {
            // clean up also on failure
            if (!marker) {
                return;
            }
            self.sMarkerRepository.removeItem(marker);
        });

        this.$scope.$emit(EVENT_MARKER_POPUP_OPENED);
    };

    /**
     * @param {Event} event
     */
    sMarker.prototype.handleRemove = function handleRemove(event) {
        this.model = null;
    };

    Object.defineProperties(
        sMarker,
        {
            EVENT_MARKER_POPUP_OPENED : {
                value : EVENT_MARKER_POPUP_OPENED
                /**
                 * @property
                 * @constant
                 * @name Controller.Component.sMarker#EVENT_MARKER_POPUP_OPENED
                 * @type {String}
                 */
            }
        }
    );

    ns.sMarker = sMarker;
})(Object.namespace('Controller.Component'));
