(function (ns) {
    var TYPE_KEY_FREE_TEXT      = 'freetext',
        TYPE_KEY_KEYWORD        = 'keyword',
        TYPE_KEY_CONTENT_SOURCE = 'content_source',
        LENGTH_UNIT_WORDS       = 'words',
        LENGTH_UNIT_DIGITS      = 'digits',
        KEY_FORM_NAME           = 'entity'
    ;

    /**
     * @typedef {Object} EntityType
     * @property {String} key
     * @property {String} label
     * @property {*} options
     * @property {String} description
     */

    /**
     * @namespace
     * @memberOf Model.AI
     * @constructor
     * @param {String=} uuid
     *
     * @extends Model.RESTAccessByUUID
     */
    var Entity = function Entity(uuid) {
        var name,
            self           = this,
            type           = Entity.getDefaultType(),
            typeOptions    = new (Entity.getTypeByKey(type).options)
        ;

        Object.defineProperties(
            this,
            {
                name        : {
                    enumerable : true,
                    get        : function () {
                        return name;
                    },
                    set        : function (val) {
                        // Ensure that @ and white space characters can not be part of entity name
                        if (val) {
                            val = val.replace(/\s+/g, '_').replace(new RegExp(Model.AI.MatchGroup.ENTITY_PREFIX, 'g'), '');
                        }

                        name = val;
                    }
                    /**
                     * @property
                     * @type String
                     * @name Model.AI.Entity#name
                     */
                },
                type        : {
                    enumerable : true,
                    get        : function () {
                        return type;
                    },
                    set        : function (val) {
                        var types = Entity.getTypes();

                        if (!types.hasOwnProperty(val)) {
                            throw 'Given type key "' + val + '" does not exist, please use valid key.';
                        }

                        if (type === val) {
                            return;
                        }

                        type = val;

                        if (!Entity.getTypeByKey(type).options) {
                            typeOptions = null;
                            return;
                        }

                        typeOptions = new (Entity.getTypeByKey(type).options);
                    }
                    /**
                     * @property
                     * @type String
                     * @name Model.AI.Entity#type
                     */
                },
                typeOptions : {
                    enumerable  : true,
                    get         : function () {
                        return typeOptions;
                    },
                    set         : function (val) {
                        Object.instanceOf(val, Entity.getTypeByKey(type).options);

                        switch (type) {
                            case Model.AI.Entity.TYPE_KEY_FREE_TEXT:
                                typeOptions = val;
                                break;
                            case Model.AI.Entity.TYPE_KEY_KEYWORD:
                                for (var i = 0; i < val.length; i++) {
                                    self.addKeyword(val[i]);
                                }
                                break;
                        }
                    }
                    /**
                     * @property
                     * @type {Object|Array}
                     * @name Model.AI.Entity#typeOptions
                     */
                },
                hasLength   : {
                    enumerable : false,
                    get        : function () {
                        return (this.type === Model.AI.Entity.TYPE_KEY_FREE_TEXT && this.typeOptions.length >= 1)
                    }
                    /**
                     * @property
                     * @type {Boolean}
                     * @name Model.AI.Entity#hasLength
                     */
                }
            }
        );

        Entity._pProto.constructor.call(this, uuid);
    };

    Object.extendProto(Entity, Model.RESTAccessByUUID);

    /**
     * @function
     * @name Model.AI.Entity#updateByData
     * @param {Object} data
     */
    Entity.prototype.updateByData = function updateByData(data) {
        if (data.type) {
            this.type = data.type;
            delete data.type;
        }

        var self = this;

        Object.updateByData(this, data);

        switch (this.type) {
            case Entity.TYPE_KEY_KEYWORD:
                this.typeOptions = data.keywords || [];
                break;
            case Entity.TYPE_KEY_FREE_TEXT:
                this.typeOptions = {};

                // put everything that was also in the object into the typeOptions
                Object.getOwnPropertyNames(self).diff(Object.getOwnPropertyNames(data)).map(function(name) {
                    self.typeOptions[name] = data[name];
                });
                break;
        }

        Entity._pProto.updateByData.call(this);
    };

    /**
     * @param {FormData=} formData
     * @param {string=} name
     * @returns FormData
     */
    Entity.prototype.getFormData = function getFormData(formData, name) {
        var fData = Entity._pProto.getFormData.call(this, formData);

        name = name || KEY_FORM_NAME;

        if (name && name instanceof Object) {
            name['dataName'] = KEY_FORM_NAME;
            name = JSON.stringify(name).bin2hex();
        }

        var data = JSON.parse(JSON.stringify(this));

        delete(data.typeOptions);

        switch (this.type) {
            case Entity.TYPE_KEY_KEYWORD:
                data.keywords = this.typeOptions;
                break;
            case Entity.TYPE_KEY_FREE_TEXT:
                data = $.extend(data, this.typeOptions);
                break;
        }

        fData.append(name, JSON.stringify(data));

        return fData;
    };

    /**
     * @return {String}
     */
    Entity.prototype.toString = function toString() {
        return this.name;
    };

    /**
     * @function
     * @name Model.AI.Entity#getTypeDefinition
     * @return {EntityType}
     */
    Entity.prototype.getTypeDefinition = function getTypeDefinition() {
        return Model.AI.Entity.getTypeByKey(this.type);
    };

    Entity.prototype.toggleHasLength = function toggleHasLength() {
        if (this.type !== TYPE_KEY_FREE_TEXT) {
            return;
        }

        if (this.typeOptions.hasOwnProperty('length') && this.typeOptions.hasOwnProperty('unit')) {
            // If length was removed, reset the type options of the entity
            this.typeOptions = new (this.getTypeDefinition().options);
        } else if (!this.typeOptions.length > 0) {
            // If length was ticked but this.entityTypeOptions is not initialized
            this.typeOptions = {
                length : 1,
                unit   : Model.AI.Entity.LENGTH_UNIT_WORDS
            };
        }
    };

    /**
     * @function
     * @name Model.AI.Entity#addKeyword
     * @param {String=} keyword
     */
    Entity.prototype.addKeyword = function addKeyword(keyword) {
        if (!keyword || typeof keyword.replace !== 'function') {
            return;
        }

        var unicodeChars = Object.keys(Const.Unicode).map(function(key) {
            return Const.Unicode[key];
        });

        keyword = keyword
            .replace(new RegExp(unicodeChars.join('|'), 'g'), '')
            .trim()
        ;

        if (!keyword || !keyword.length) {
            return;
        }

        if (this.type === Model.AI.Entity.TYPE_KEY_KEYWORD) {
            if (this.typeOptions.indexOf(keyword) === -1) {
                this.typeOptions.push(keyword);
            }
        }
    };

    /**
     * @function
     * @name Model.AI.Entity.createByData
     * @param {Object} data
     *
     * @returns {Model.AI.Entity}
     */
    Entity.createByData = function createByData(data) {
        var entity = new Entity(data.uuid ? data.uuid : null);
        entity.updateByData(data);

        return entity;
    };

    /**
     * @function
     * @name Model.AI.Entity.getTypeByKey
     * @param {String} key
     *
     * @return {EntityType}
     * @throws {Error}
     */
    Entity.getTypeByKey = function getTypeByKey(key) {
        var types = Entity.getTypes();

        if (!types.hasOwnProperty(key)) {
            throw 'Given type key "' + key + '" does not exist, please use valid key.';
        }

        return types[key];
    };

    /**
     * @function
     * @name Model.AI.Entity.getDefaultType
     *
     * @return {String}
     */
    Entity.getDefaultType = function getDefaultType() {
        var types = Entity.getTypes();

        return Object.keys(types)[0];
    };

    /**
     * @function
     * @name Model.AI.Entity.getTypes
     *
     * @return {Object<String, EntityType>}
     */
    Entity.getTypes = function getTypes() {
        var types = {};

        types[TYPE_KEY_FREE_TEXT] = {
            key         : TYPE_KEY_FREE_TEXT,
            label       : 'Free text',
            options     : Object,
            description : 'Matches by format rather than content (1 word, 3 digits)'
        };

        types[TYPE_KEY_KEYWORD] = {
            key         : TYPE_KEY_KEYWORD,
            label       : 'Keywords',
            options     : Array,
            description : 'Matches by content (list of keywords)'
        };

        types[TYPE_KEY_CONTENT_SOURCE] = {
            key         : TYPE_KEY_CONTENT_SOURCE,
            label       : 'Content source',
            options     : null,
            description : 'Matches by content source values'
        };

        return types;
    };

    /**
     * @function
     * @name Model.AI.Entity.getTypesAsArray
     *
     * @return {String[]}
     */
    Entity.getTypesAsArray = function getTypesAsArray() {
        var keys        = [],
            entityTypes = Model.AI.Entity.getTypes()
        ;

        for (var i in entityTypes) {
            keys.push(entityTypes[i]);
        }

        return keys;
    };

    Object.defineProperties(
        Entity,
        {
            TYPE_KEY_FREE_TEXT : {
                value : TYPE_KEY_FREE_TEXT
                /**
                 * @property
                 * @name Model.AI.Entity#TYPE_KEY_FREE_TEXT
                 * @type {string}
                 */
            },
            TYPE_KEY_KEYWORD   : {
                value : TYPE_KEY_KEYWORD
                /**
                 * @property
                 * @name Model.AI.Entity#TYPE_KEY_KEYWORD
                 * @type {string}
                 */
            },
            TYPE_KEY_CONTENT_SOURCE: {
                value: TYPE_KEY_CONTENT_SOURCE
                /**
                 * @property
                 * @constant
                 * @name Model.AI.Entity#TYPE_KEY_CONTENT_SOURCE
                 * @type {String}
                 */
            },
            LENGTH_UNIT_WORDS   : {
                value : LENGTH_UNIT_WORDS
                /**
                 * @property
                 * @name Model.AI.Entity#LENGTH_UNIT_WORDS
                 * @type {string}
                 */
            },
            LENGTH_UNIT_DIGITS   : {
                value : LENGTH_UNIT_DIGITS
                /**
                 * @property
                 * @name Model.AI.Entity#LENGTH_UNIT_DIGITS
                 * @type {string}
                 */
            }
        }
    );

    ns.Entity = Entity;
})(Object.namespace('Model.AI'));
