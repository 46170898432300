(function(angular) {

    var module = angular.module('sListModule');

    module
        /**
         * @ngdoc component
         * @name sList
         * @restrict E
         *
         * @param controller
         * @param {Model.List}  model
         * @param {Boolean}     allowSelection If present selection checkboxes will be rendered. Also for allow-selection="false".
         * @param {Function}    rowClass
         * @param {String}      trackBy
         * @param {String=}     queryPrefix
         * @param {String=}     noResultsTemplate
         */
        .component('sList', {
            controller  : sList.Component.Controller.sList,
            templateUrl : function($element, $attrs, $templateCache) {
                var randomized = Date.now() + '-' + Math.floor(Math.random() * 10000);
                $attrs.randomId = randomized;
                $element.children().each(function(index, element) {
                    $templateCache.put(
                        element.nodeName.toLowerCase() + '-' + randomized,
                        element.innerHTML);
                });

                return 'slist/components:s-list'
            },
            bindings : {
                controller        : '=',
                model             : '<',
                allowSelection    : '<',
                rowClass          : '&',
                trackBy           : '@',
                queryPrefix       : '<',
                noResultsTemplate : '@'
            }
        })
        /**
         * Template inclusion placeholder for cell template
         * @ngdoc component
         * @name sListCell
         * @restrict E
         */

        /**
         * Template inclusion placeholder for batch actions
         * @ngdoc component
         * @name sListBatch
         * @restrict E
         */


        /**
         * Template inclusion placeholder for pre-row template
         * @ngdoc component
         * @name sPreRow
         * @restrict E
         */

        /**
         * Template inclusion placeholder for no-results template
         * @ngdoc component
         * @name sNoResults
         * @restrict E
         */

        /**
         * @ngdoc component
         * @name sRecordsPerPageSelector
         * @restrict E
         *
         * @param controller
         * @param {int=}        model
         * @param {Array}       choices
         * @param {Boolean}     readonly
         */
        .component('sRecordsPerPageSelector', {
            templateUrl : 'slist/components:s-records-per-page-selector',
            controller  : sList.Component.Controller.sRecordsPerPageSelector,
            bindings    : {
                model       : '=',
                choices     : '<',
                readonly    : '<'
            }
        })
        ;

})(angular);
