(function (ns) {
    /**
     * @namespace
     * @alias Model.Exception.ActionDefinitionNotFound
     *
     * @constructor
     * @extends Error
     * @param {String} name
     */
    var ActionDefinitionNotFound = function (name) {
        ActionDefinitionNotFound._pProto.constructor.call(this, 'Unable to find action definition by the name: '+ name + '!');
    };

    Object.extendProto(ActionDefinitionNotFound, Error);

    ns.ActionDefinitionNotFound = ActionDefinitionNotFound;
})(Object.namespace('Model.Exception'));