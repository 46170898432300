(function(ns, $) {
    /**
     * @namespace
     * @alias Model.Member.CustomData
     * @constructor
     *
     * @param _id
     * @param _memberId
     */
    var CustomData = function (_id, _memberId) {
        var id              = _id,
            memberId        = _memberId
        ;

        Model.Behavior.KeyValue.call(this);

        Object.defineProperties(
            this,
            {
                id: {
                    get: function () {
                        return id;
                    }
                    /**
                     * @property
                     * @name Model.Member.CustomData#id
                     * @type {Number}
                     */
                },
                memberId: {
                    enumerable: true,
                    get: function () {
                        return memberId;
                    }
                    /**
                     * @property
                     * @name Model.Member.CustomData#memberId
                     * @type {Number}
                     */
                }
            }
        );

        this.save = function () {
            if (!this.memberId) {
                throw "MemberId is required!";
            }
            var method = Const.Method.PUT,
                url = CustomData.endPoint(this.memberId),
                self = this,
                data
            ;

            if (!id) {
                method = Const.Method.POST;
            } else {
                url += '/' + id;
            }

            data = new FormData();
            data.append('customData', JSON.stringify(this));
            return $.ajax({
                url: url,
                data: data,
                method: method,
                processData: false,
                contentType: false
            }).then(function (data) {
                if (!id && data.id) {
                    id = data.id;
                }

                return self;
            });
        };

        this.__dontCloneProperties = function () {
            return ['$$hashKey'];
        };
    };

    CustomData.prototype.delete = function () {
        if (!this.id || !this.memberId) {
            return $.when(null);
        }
        return $.ajax({
            url: CustomData.endPoint(this.memberId) + '/' + this.id,
            method: Const.Method.DELETE
        });
    };

    /**
     * @param id
     * @param memberId
     * @return {PromiseLike}
     */
    CustomData.load = function load(id, memberId) {
        return $.ajax({
            url: CustomData.endPoint(memberId) + '/' + id,
            dataType: 'customData'
        });
    };

    /**
     * @static
     * @param _data
     * @return {Model.Member.CustomData}
     */
    CustomData.fromJson = function (_data) {
        var json = JSON.parse(_data),
            customData = new CustomData(json.id, json.memberId);

        Object.updateByData(customData, json);
        return customData;
    };

    $.ajaxSetup({
        'converters': {
            'text customData': CustomData.fromJson
        }
    });

    ns.CustomData = CustomData;
})(Object.namespace('Model.Member'), $);
