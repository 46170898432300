(function(ns) {
    var ForgotPassword = ns.ForgotPassword = function ($scope, $route, apiEndpoints) {
        var self = this;

        this.$scope             = $scope;
        this.apiEndpoints       = apiEndpoints;
        this.$deRegister        = [];
        this.requestInProgress  = false;
        this.isFormSent         = false;
        this.credentials    = {
            email: ''
        };

        this.$scope.$on('$destroy', this.$onDestroy.bind(this));

        this.$deRegister.push($scope.$watch(
            function() {
                return $route.current.params.email;
            },
            function(newVal) {
                if (newVal) {
                    self.credentials.email = newVal;
                }
            }
        ));
    };

    /**
     * @function
     * @name sSecurity.Controller.ForgotPassword#$onDestroy
     */
    ForgotPassword.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name sSecurity.Controller.ForgotPassword#sendRequest
     */
    ForgotPassword.prototype.sendRequest = function sendRequest() {
        var self = this,
            data = new FormData();

        data.append('email', this.credentials.email);

        this.$scope.$evalAsync(function () {
            self.requestInProgress = true;
        });

        $.ajax({
            url         : this.apiEndpoints.security.forgotPassword(),
            method      : Const.Method.POST,
            data        : data,
            processData : false,
            contentType : false
        }).then(function() {
            self.isFormSent = true;
        }).always(function() {
            self.$scope.$evalAsync(function () {
                self.credentials.email = '';
                self.requestInProgress = false;
            });
        });
    };

})(Object.namespace('sSecurity.Controller'));