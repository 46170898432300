(function (ns) {
    /**
     * @namespace
     * @alias sSegment.Directive.Controller.SegmentRecipients
     *
     * @param $scope
     * @param {sSegment.Service.sSegment} sSegment
     * @constructor
     */
    var SegmentRecipients = function ($scope, sSegment) {
        this.$scope             = $scope;
        this.sSegmentService    = sSegment;
        this.$deRegister        = [];
    };

    /**
     * @function
     * @name sSegment.Directive.Controller.SegmentRecipients#$onInit
     */
    SegmentRecipients.prototype.$onInit = function $onInit() {
        var self = this;

        this.$deRegister.push(this.$scope.$watch(
            function () {
                return Boolean(self.segment) && self.segment.uuid + '-' + JSON.stringify(self.segment.conditions);
            },
            function() {
                setTimeout(function() {
                    if (self.ngFormCtrl.$valid) {
                        self.isLoading = true;
                        digestIfNeeded(self.$scope);
                        self.sSegmentService.fetchRecipientCount(self.segment).always(function() {
                            self.isLoading = false;
                            digestIfNeeded(self.$scope);
                        });
                    }
                }, 0);
            }
        ));
    };

    /**
     * @function
     * @name sSegment.Directive.Controller.SegmentRecipients#$onDestroy
     */
    SegmentRecipients.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    ns.SegmentRecipients = SegmentRecipients;
})(Object.namespace('sSegment.Directive.Controller'));
