(function(ns) {
    var DEFAULT_SECTION_COUNT = 3;

    /**
     * @namespace
     * @alias Controller.Component.sScoreMeter
     * @constructor
     *
     * @param {*} $scope
     */
    var ScoreMeter = function ScoreMeter($scope) {
        this.$scope             = $scope;
        this.$deRegister        = [];

        this.sectionizedScore   = 0;
        this.sectionCount       = DEFAULT_SECTION_COUNT;
        this.tooltipHeading     = null;
        this.tooltipBody        = null;
    };

    /**
     * @function
     * @name Controller.Component.sScoreMeter#$onInit
     */
    ScoreMeter.prototype.$onInit = function $onInit() {
        var self = this
        ;

        this.$deRegister.push(this.$scope.$watch(
            function () {
                return self.model ? self.model.qualityScore : null;
            },
            function () {
                self.recalculateSectionizedScore();
                self.updateTooltipTexts();
            }
        ));
    };

    /**
     * @function
     * @name Controller.Component.sScoreMeter#$onDestroy
     */
    ScoreMeter.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn.call(this);
        }
    };

    /**
     * @function
     * @name Controller.Component.sScoreMeter#$onChanges
     * @param {object} $changes
     */
    ScoreMeter.prototype.$onChanges = function $onChanges($changes) {
        if ($changes.scoreSectionCount) {
            this.sectionCount = (typeof this.scoreSectionCount === "undefined") ? DEFAULT_SECTION_COUNT : this.scoreSectionCount;
            this.recalculateSectionizedScore();
        }
    };

    /**
     * @function
     * @name Controller.Component.sScoreMeter#recalculateSectionizedScore
     */
    ScoreMeter.prototype.recalculateSectionizedScore = function recalculateSectionizedScore() {
        if (!this.model || this.model.qualityScore === null) {
            this.sectionizedScore = 0;
            return;
        }

        this.sectionizedScore = Math.round(this.model.qualityScore * this.sectionCount);
    };

    /**
     * @function
     * @name Controller.Component.sScoreMeter#updateTooltipTexts
     */
    ScoreMeter.prototype.updateTooltipTexts = function updateTooltipTexts() {
        this.tooltipHeading = null;
        this.tooltipBody    = null;

        if (!this.model || !this.model.qualityScoreHints || !(this.model.qualityScoreHints instanceof Array)) {
            return;
        }

        var qualityScoreHints = this.model.qualityScoreHints.slice();
        this.tooltipHeading = qualityScoreHints.shift();
        this.tooltipBody    = qualityScoreHints;
    };

    ns.sScoreMeter = ScoreMeter;
})(Object.namespace('Controller.Component'));
