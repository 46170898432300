(function(ns) {
    /**
     * @namespace
     * @alias Controller.sMessageAdmin.SendController
     *
     * @param $scope
     * @param $mdDialog
     * @constructor
     */
    var SendController = function($scope, $mdDialog) {
        this.isScheduled     = false;
        this.$mdDialog       = $mdDialog;
        this.$scope          = $scope;
        this.$deRegister     = [];

        /**
         * @property
         * @name Controller.sMessageAdmin.SendController#scheduledDate
         * @type {moment.Moment}
         */

        /**
         * @property
         * @name Controller.sMessageAdmin.SendController#anchor
         * @type {Model.Message.Anchor}
         */

        /**
         * @protected
         * @return {{timeScheduled: *, segment: *}}
         */
        var buildSendData = function () {
            return {
                segment       : this.virtualSegment,
                timeScheduled : ((this.isScheduled) ? this.scheduledDate : null)
            };
        }.bind(this);

        /**
         * @name Controller.sMessageAdmin.SendController#onSend
         */
        this.onSend = function onSend() {
            this.$mdDialog.hide(buildSendData());
        };

        // expose protected for inheritance
        if (this.constructor !== SendController && this instanceof SendController) {
            return {
                buildSendData: buildSendData
            }
        }
    };

    /**
     * @param {Boolean=} updateScheduled
     */
    SendController.prototype.initMinDate = function initMinDate(updateScheduled) {
        var date = moment();

        if (this.isScheduled) {
            date.add(15, 'minutes');
        }
        this.minScheduleDate = date
            .clone()
            .seconds(0)
            .milliseconds(0);

        if (updateScheduled) {
            this.scheduledDate = date;
        }
    };

    SendController.prototype.$onInit = function $onInit() {
        this.initMinDate(true);
        this.$deRegister.push(this.$scope.$watch(function() {
                return JSON.stringify(this.segment);
            }.bind(this),
            function (val) {
                if (!val) {
                    return;
                }
                this.createVirtualSegment();
            }.bind(this)));
    };

    SendController.prototype.createVirtualSegment = function createVirtualSegment() {
        this.virtualSegment = new Model.Segment();
        if (!this.segment) {
            return;
        }
        if (this.segment.name) {
            this.virtualSegment = new Model.Segment(this.segment.uuid);
        }

        var segmentJSON = JSON.stringify(this.segment);
        this.virtualSegment.updateByData(JSON.parse(segmentJSON));
    };

    SendController.prototype.$onDestroy = function $onDestroy() {
        var $destroyFn;
        while (($destroyFn = this.$deRegister.pop())) {
            $destroyFn();
        }
    };

    /**
     * @name Controller.sMessageAdmin.SendController#cancel
     */
    SendController.prototype.cancel = function cancel() {
        this.$mdDialog.cancel();
    };

    ns.SendController = SendController;

})(Object.namespace('Controller.sMessageAdmin'));
