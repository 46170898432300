(function (ns) {
    /**
     * @namespace
     * @alias sMessageLog.Route.Member
     * @extends sRoute
     * @constructor
     */
    var Member = function Member() {
        this.controller     = Controller.sMessageLog.Member;
        this.controllerAs   = '$ctrl';
        this.templateUrl    = 'smessagelog:member';
        this.accessControl  = {'messageLog.byMember': Const.Method.GET};

        Member._pProto.constructor.call(this);
    };

    Object.extendProto(Member, sRoute);

    /**
     * Used by the sRoute's magic resolve method
     * @function
     * @name sMessageLog.Route.Member#resolveCollection
     * @param $route
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     * @param {Service.sAction} sActionService
     * @return {PromiseLike}
     */
    Member.prototype.resolveCollection = function resolveCollection($route, sAPIAccess, sActionService) {
        return sActionService.getDefinitions().then(function() {
            var collection = new Model.Message.FetchAbleCollection(
                sAPIAccess.endpoint('messageLog.byMember').get($route.current.params.hMemberId),
                true
            );

            return collection.fetchNext().then(function() {
                return collection;
            });
        });
    };

    /**
     * Used by the sRoute's magic resolve method
     * @function
     * @name sMessageLog.Route.Channel#resolveHMemberId
     * @param $route
     * @return {PromiseLike}
     */
    Member.prototype.resolveHMemberId = function resolveHMemberId($route) {
        return $.when($route.current.params.hMemberId);
    };

    ns.Member = Member;
})(Object.namespace('sMessageLog.Route'));
