(function(angular) {
    var sBugsnagModule = angular.module('sBugsnagModule', []);

    sBugsnagModule
        .provider('sBugsnag', sBugsnag.Provider.sBugsnag)
    ;

    /**
     * Extend $exceptionHandler
     */
    sBugsnagModule.config(['$provide', function ($provide) {
        $provide.decorator("$exceptionHandler", ['$delegate', 'sBugsnag', function ($delegate, sBugsnag) {
            return function (exception, cause) {
                $delegate(exception, cause);
                sBugsnag.notify(exception, {metaData: {cause: cause}});
            };
        }]);
    }]);

    sBugsnagModule.run([
        '$rootScope',
        'sBugsnag',
        function ($rootScope, sBugsnag) {
            $rootScope.$on("$routeChangeStart", function () {
                // resets the limit of bugs reported per pageload
                // see: https://docs.bugsnag.com/platforms/browsers/v3/faq/#how-can-i-adjust-the-number-of-errors-sent-per-page-load
                sBugsnag.refresh();
            });

            $rootScope.$on('$routeChangeError', function (event, current, previous, rejection) {
                // at this point we will have an error as rejection-reason:
                // forward exception to bugsnag

                if (!(rejection instanceof sRouteRejection)) {
                    return;
                }

                if (rejection.reason instanceof Error) {
                    sBugsnag.notify(rejection.reason, {metaData: rejection.meta});
                    return;
                }
                sBugsnag.notify({
                    name: 'Route change error',
                    message: 'Failed to change routes',
                    metaData: {meta: rejection.meta, reason: rejection.reason}
                });
            });

            $(document).on($.EVENT_FORM_DATA_PREPARED, function (event, data) {
                sBugsnag.leaveBreadcrumb('Batched data prepared', data, 'request')
            });

            $(document).on($.EVENT_AGGREGATION_ENCOUNTERED_ERROR, function (event, err) {
                sBugsnag.notify(err);
            });
        }]);
})(angular);