(function (ns) {
    /**
     * @namespace
     * @alias sAnalytics.Route.Campaign
     * @extends sRoute
     * @constructor
     */
    var Campaign = function Campaign() {
        this.controller     = sAnalytics.Controller.Campaign;
        this.controllerAs   = '$ctrl';
        this.templateUrl    = 'sanalytics:campaign';
        this.reloadOnSearch = false;
        this.accessControl  = {'analytics.byDomain.getCampaign': Const.Method.GET};

        Campaign._pProto.constructor.call(this);
    };

    Object.extendProto(Campaign, sRoute);

    /**
     * Used by the sRoute's magic resolve method
     *
     * @function
     * @name sAnalytics.Route.Campaign#resolveDomainSegments
     * @param $route
     * @param {sSegment.Service.sSegment} sSegment
     * @returns Promise
     */
    Campaign.prototype.resolveDomainSegments = function resolveDomainSegments($route, sSegment) {
        return sSegment
            .loadSegmentsByCurrentDomain()
            .then(function(channels) {
                return channels;
            });
    };

    ns.Campaign = Campaign;
})(Object.namespace('sAnalytics.Route'));