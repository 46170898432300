(function(ns) {
    var KEY_USER            = 'user',
        KEY_UUID            = 'uuid',
        KEY_EMAIL           = 'email',
        KEY_ROLE_RELATIONS  = 'roleRelations',
        KEY_META            = 'meta',
        KEY_TIME_CREATED    = 'timeCreated',
        KEY_TIME_UPDATED    = 'timeUpdated'
    ;

    /**
     * @namespace
     * @alias Model.User
     * @constructor
     * @extends Model.RESTAccessByUUID
     *
     * @param uuid
     */
    var User = function(uuid) {
        var timeUpdated         = moment(null),
            timeCreated         = moment(null),
            roleRelations       = [],
            roleRelationsCache  = [],
            refreshRoleRelationsCache = function () {
                roleRelationsCache.splice.apply(
                    roleRelationsCache,
                    [0, roleRelationsCache.length].concat(roleRelations));
            }
        ;

        /**
         * @property
         * @name Model.User#email
         * @type string
         */

        /**
         * @property
         * @name Model.User#meta
         * @type Object
         */

        /**
         * @property
         * @name Model.User#roleRelations
         * @type Model.User.RoleRelation[]
         */

        /**
         * @property
         * @name Model.User#timeCreated
         * @type moment
         */

        /**
         * @property
         * @name Model.User#timeUpdated
         * @type moment
         */

        Object.defineProperties(
            this,
            {
                email                : {
                    writable   : true,
                    enumerable : true
                },
                meta                 : {
                    writable   : true,
                    enumerable : true
                },
                roleRelations        : {
                    configurable : false,
                    enumerable   : true,
                    get          : function () {
                        return roleRelationsCache;
                    }
                },
                timeCreated          : {
                    enumerable   : true,
                    configurable : true,
                    get          : function () {
                        return timeCreated;
                    },
                    set          : function (val) {
                        if (!moment.isMoment(val)) {
                            val = moment(val);
                        }
                        timeCreated = val;
                    }
                },
                timeUpdated          : {
                    enumerable   : true,
                    configurable : true,
                    get          : function () {
                        return timeUpdated;
                    },
                    set          : function (val) {
                        if (!moment.isMoment(val)) {
                            val = moment(val);
                        }
                        timeUpdated = val;
                    }
                }
            }
        );

        this.meta = {};

        /**
         * @function
         * @name Model.User#clearRoleRelations
         */
        this.clearRoleRelations = function clearRoleRelations() {
            roleRelations = [];
            refreshRoleRelationsCache();
        };

        /**
         * @function
         * @name Model.User#addRoleRelation
         * @param {Model.User.RoleRelation} roleRelation
         */
        this.addRoleRelation = function addRoleRelation(roleRelation) {
            Object.instanceOf(roleRelation, Model.User.RoleRelation);
            if (roleRelations.indexOf(roleRelation) === -1) {
                roleRelations.push(roleRelation);
                refreshRoleRelationsCache();
            }
        };

        /**
         * @function
         * @name Model.User#removeRoleRelation
         * @param {Model.User.RoleRelation} roleRelation
         */
        this.removeRoleRelation = function removeRoleRelation(roleRelation) {
            Object.instanceOf(roleRelation, Model.User.RoleRelation);
            var key;

            if ((key = roleRelations.indexOf(roleRelation)) !== -1) {
                roleRelations.splice(key, 1);
                refreshRoleRelationsCache();
            }
        };

        /**
         * @function
         * @name Model.User#addRole
         * @param {Model.User.Role} role
         * @param domainId
         * @param {?string} status
         */
        this.addRole = function addRole(role, domainId, status) {
            Object.instanceOf(role, Model.User.Role);
            domainId = domainId || null;

            if (!this.findRoleRelationByRoleDomainId(role, domainId)) {
                var roleRelation = Model.User.RoleRelation.create(this, role, domainId, status);
                roleRelations.push(roleRelation);
                refreshRoleRelationsCache();
            }
        };

        /**
         * @function
         * @name Model.User#removeRole
         * @param {Model.User.Role} role
         * @param domainId
         */
        this.removeRole = function removeRole(role, domainId) {
            Object.instanceOf(role, Model.User.Role);
            var key,
                roleRelation = this.findRoleRelationByRoleDomainId(role, domainId);

            if (roleRelation && (key = roleRelations.indexOf(roleRelation)) !== -1) {
                roleRelations.splice(key, 1);
                roleRelation.role = null;
                roleRelation.user = null;
                refreshRoleRelationsCache();
            }
        };

        this.__dontCloneProperties = function () {
            return ['$$hashKey', KEY_ROLE_RELATIONS];
        };

        /**
         * @name Model.User#__extendClone
         * @param {Model.User} original
         */
        this.__extendClone = function(original) {
            for (var i = 0; i < original.roleRelations.length; i++) {

                this.addRoleRelation(Model.User.RoleRelation.create(
                    this,
                    original.roleRelations[i].role,
                    original.roleRelations[i].domainId,
                    original.roleRelations[i].status
                ));
            }
        };

        var protecteds = User._pProto.constructor.call(this, uuid);
        protecteds.registerRelationHandler(this.endPoint, function(data) {
            if (!data.roleRelations || !data.roleRelations.length) {
                return;
            }

            var dummy = new Model.User.Role();

            return data.roleRelations.reduce(function (carry, roleRelation) {
                if (!roleRelation.role.uuid) {
                    return carry;
                }

                carry.push({
                    uuid     : roleRelation.role.uuid,
                    endPoint : dummy.endPoint
                });
                return carry;
            }, []);
        });
    };

    Object.extendProto(User, Model.RESTAccessByUUID);

    /**
     * @function
     * @name Model.User#findRoleRelationByRoleDomainId
     * @param {Model.User.Role} role
     * @param domainId
     * @returns {?Model.User.RoleRelation}
     */
    User.prototype.findRoleRelationByRoleDomainId = function findRoleRelationByRoleDomainId(role, domainId) {
        Object.instanceOf(role, Model.User.Role);
        domainId = domainId || null;

        return this.roleRelations.find(function (roleRelation) {
            return roleRelation.role.name === role.name && roleRelation.domainId === domainId;
        }) || null;
    };

    /**
     * @function
     * @name Model.User#findRoleRelationsByDomainId
     * @param domainId
     * @returns Model.User.RoleRelation[]
     */
    User.prototype.findRoleRelationsByDomainId = function findRoleRelationsByDomainId(domainId) {
        domainId = domainId || null;

        return this.roleRelations.filter(function (roleRelation) {
            return roleRelation.domainId === domainId;
        });
    };

    /**
     * @function
     * @name Model.User#getRolesByDomainId
     * @param domainId
     * @returns Model.User.Role[]
     */
    User.prototype.getRolesByDomainId = function getRolesByDomainId(domainId) {
        domainId = domainId || null;

        return this.findRoleRelationsByDomainId(domainId).map(function (roleRelation) {
            return roleRelation.role;
        });
    };

    /**
     * @function
     * @name Model.User#getStatusInDomain
     * @param {Model.Domain} domain
     * @returns {?string}
     */
    User.prototype.getStatusInDomain = function getStatusInDomain(domain) {
        var status = this.findRoleRelationsByDomainId(domain.id).map(function (roleRelation) {
                return roleRelation.status;
            })
        ;

        // return by priority
        if (status.indexOf(Model.User.RoleRelation.STATUS_INVITATION_PENDING) !== -1) {
            return Model.User.RoleRelation.STATUS_INVITATION_PENDING;
        }
        if (status.indexOf(Model.User.RoleRelation.STATUS_INACTIVE) !== -1) {
            return Model.User.RoleRelation.STATUS_INACTIVE;
        }
        if (status.indexOf(Model.User.RoleRelation.STATUS_ACTIVE) !== -1) {
            return Model.User.RoleRelation.STATUS_ACTIVE;
        }
        return status.length ? status.slice(0,1).pop() : null;
    };

    /**
     * @function
     * @name Model.User#setSingleDomainRole
     * @param {Model.User.Role} role
     * @param domainId
     */
    User.prototype.setSingleDomainRole = function setSingleDomainRole(role, domainId) {
        var self = this;
        this.findRoleRelationsByDomainId(domainId).map(function(roleRelation) {
            self.removeRoleRelation(roleRelation);
        });
        this.addRole(role, domainId);
    };

    /**
     * @function
     * @name Model.User#getFormData
     * @param formData
     * @param {string=} name
     * @returns {FormData}
     */
    User.prototype.getFormData = function getFormData(formData, name) {
        var self            = this,
            fData           = User._pProto.getFormData.call(this, formData),
            propertylist    = [KEY_UUID,KEY_EMAIL,KEY_META,KEY_ROLE_RELATIONS],
            userData        = {};

        propertylist.map(function(key) {
            if (!self.hasOwnProperty(key)) {
                return;
            }
            if (key === KEY_ROLE_RELATIONS) {
                userData[key] = self[key].map(function(roleRelation) {
                    return roleRelation.toSimpleObject();
                });
            } else {
                userData[key] = self[key];
            }
        });

        name = name || KEY_USER;
        fData.append(name, JSON.stringify(userData));

        return fData;
    };

    /**
     * @function
     * @name Model.User#updateByData
     * @param {Object} data
     */
    User.prototype.updateByData = function updateByData(data) {
        var self = this;
        for (var key in data) {
            if (key === KEY_ROLE_RELATIONS) {
                if (!(data[key] instanceof Array)) {
                    continue;
                }
                data[key].map(function(arr) {
                    var role;
                    if (arr.role && (role = Model.User.Role.createByData(arr.role))) {
                        self.addRole(role, arr.domainId, arr.status);
                    }
                });
            } else if (Object.prototype.hasOwnProperty.call(self, key)) {
                switch (key) {
                    case KEY_TIME_CREATED:
                    case KEY_TIME_UPDATED:
                        self[key] = data[key] * 1000;
                        break;
                    default:
                        self[key] = data[key];
                }
            }
        }

        User._pProto.updateByData.call(this, data);
    };

    /**
     * @function
     * @name Model.User#hasPendingInvitation
     * @param {string} domainId
     * @returns {boolean}
     */
    User.prototype.hasPendingInvitation = function hasPendingInvitation(domainId) {
        var hasPendingInvitation = false;

        for (var i = 0; i < this.roleRelations.length; i++) {
            if(this.roleRelations[i].domainId === domainId && this.roleRelations[i].status === Model.User.RoleRelation.STATUS_INVITATION_PENDING){
                hasPendingInvitation = true;
                break;
            }
        }

        return hasPendingInvitation;
    };

    /**
     * @function
     * @name Model.User#createByData
     * @static
     * @param {Object} data
     * @returns {Model.User}
     */
    User.createByData = function createByData(data) {
        var uuid,
            user;

        if (data && data.hasOwnProperty(KEY_UUID)) {
            uuid = data.uuid;
        }

        user = new User(uuid);
        user.updateByData(data);

        return user;
    };

    ns.User = User;
})(Object.namespace('Model'));