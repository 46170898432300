(function(ns) {

    var migrateData = function migrateData(data) {
        if (data && data.name === 'label') {
            data.name = 'user_attribute';
            data.value = JSON.stringify({key: data.value, value: null});
        }

        return data;
    };

    /**
     * @namespace
     * @alias Model.Event
     * @constructor
     *
     */
    var Event = function () {
        var self    = this,
            name    ,
            value
        ;

        Object.defineProperties(this, {
            name: {
                enumerable: true,
                configurable: false,
                get: function () {
                    return name;
                },
                set: function(val) {
                    if (name === val) {
                        return;
                    }

                    name = val;
                    self.value = null;
                }
                /**
                 * @property
                 * @type {string}
                 * @name Model.Event#name
                 */
            },
            value: {
                enumerable: true,
                configurable: false,
                get: function () {
                    return value;
                },
                set: function(val) {
                    value = val;
                }
                /**
                 * @property
                 * @type {*}
                 * @name Model.Event#value
                 */
            }
        });

        this.__dontCloneProperties = function () {
            return ['$$hashKey'];
        };
    };

    /**
     * @function
     * @static
     * @name Model.Event#create
     * @returns Model.Event
     * @param {Object} data
     */
    Event.createByData = function createByData(data) {
        var event = new Event();

        event.updateByData(data);

        return event;
    };

    /**
     * @name Model.Event#updateByData
     * @param {Object} data
     * @returns {Model.Event}
     */
    Event.prototype.updateByData = function updateByData(data) {
        data = migrateData(data);
        Object.updateByData(this, data);

        return this;
    };

    ns.Event = Event;
})(Object.namespace('Model'));
