(function() {
    var sBaseModule = angular.module('sBaseModule', []);

    sBaseModule
        .controller('sBaseNoDomainController', Controller.sBaseModule.NoDomainController);

    sBaseModule.config(['$routeProvider',function($routeProvider) {

            $routeProvider.when("/error/nodomain/:redirectUrl", {
                templateUrl : 'sbase:no-domain',
                controller  : 'sBaseNoDomainController',
                controllerAs: '$ctrl',
                resolve: {
                    parameters: ['$route', function($route) {
                        return $route.current.params;
                    }]
                }
            });

    }]);

})(window);