(function(ns) {
    /**
     * @namespace
     * @alias sUserManagement.Controller.List
     * @constructor
     *
     * @param $scope
     * @param {sUserManagement.Service.sUser} sUserService
     * @param $mdDialog
     * @param {Service.sDomain} sDomainService
     * @param Notification
     * @param {string} listEndpoint
     * @param {sAPIAccess.Service.sAPIAccess} sAPIAccess
     */
    var List = function ($scope, sUserService, $mdDialog, sDomainService, Notification, listEndpoint, sAPIAccess) {
        this.$scope                 = $scope;
        this.sAPIAccess             = sAPIAccess;
        this.listEndpoint           = listEndpoint;
        this.sDomainService         = sDomainService;
        this.Notification           = Notification;
        this.sUserService           = sUserService;
        this.allowedAdditionalUsers = 0; // TODO: use some kind of domain-feature-meta
        this.listView               = {};

        this.configList();
        this.reload();
    };

    /**
     * @function
     * @name sUserManagement.Controller.List#configList
     */
    List.prototype.configList = function configList() {
        var column,
            boundConstructor = Model.DomainUser.bind(null, this.sDomainService.currentDomainId)
        ;

        boundConstructor._pProto = Model.DomainUser._pProto;

        this.listView = {};

        Object.defineProperty(
            this.listView,
            'count',
            {
                get: function() {
                    return this.pager.totalRecords || 0;
                }
            }
        );

        this.listView = new Model.List.RESTAccess(boundConstructor, this.listEndpoint, 100);
        this.listView.actions = {};

        column = this.listView.columns.createAndAdd('email');
        column.label = 'Email';

        column = this.listView.columns.createAndAdd('domainRoles');
        column.label = 'Role';

        column = this.listView.columns.createAndAdd('firstname');
        column.label = 'First Name';

        column = this.listView.columns.createAndAdd('lastname');
        column.label = 'Last Name';

        column = this.listView.columns.createAndAdd('timeUpdated');
        column.label = 'Updated at';

        if (this.sAPIAccess.check({'user.RESTAccess' : [Const.Method.POST, Const.Method.PUT]})) {
            this.listView.actions['edit'] = new Model.Menu.Action('edit', this.editUser.bind(this));

            if (this.sAPIAccess.check({'user.resendInvitation' : Const.Method.POST})) {
                this.listView.actions['resendInvitation'] = new Model.Menu.Action('resendInvitation', this.resendInvitation.bind(this));
            }
        }

        if (this.sAPIAccess.isAllowed('user.byDomain.userRole', Const.Method.DELETE)) {
            this.listView.actions['remove'] = new Model.Menu.Action('remove', this.removeUser.bind(this));
        }


        if (Object.keys(this.listView.actions).length) {
            column = this.listView.columns.createAndAdd('options');
            column.label = 'Options';
        }
    };

    /**
     * @function
     * @name sUserManagement.Controller.List#addUser
     */
    List.prototype.addUser = function addUser() {
        this.sUserService.openAddUserDialog().then(this.reload.bind(this));
    };

    /**
     * @function
     * @name sUserManagement.Controller.List#resendInvitation
     * @param {Model.User} user
     */
    List.prototype.resendInvitation = function resendInvitation(user) {
        return this.sUserService.resendInvitation(user).always(this.reload.bind(this));
    };

    /**
     * @function
     * @name sUserManagement.Controller.List#user
     * @param {Model.User} user
     */
    List.prototype.editUser = function editUser(user) {
        this.sUserService.openEditUserDialog(user.clone()).then(this.reload.bind(this));
    };

    /**
     * @function
     * @name sUserManagement.Controller.List#removeUser
     * @param {Model.User} user
     */
    List.prototype.removeUser = function removeUser(user) {
        return this.sUserService.openRemoveUserDialog(user).then(this.reload.bind(this));
    };

    /**
     * @function
     * @name sUserManagement.Controller.List#reload
     */
    List.prototype.reload = function reload() {
        var self = this;
        this.$scope.$evalAsync(function() {
            self.listView.load(false, true);
        });
    };

    ns.List = List;
})(Object.namespace('sUserManagement.Controller'));