(function(ns) {
    /**
     * @namespace
     * @alias Model.Endpoint.Collection
     * @constructor
     *
     * @param {Object.<string, Object.<string, string>>} mapping - The first string is the token we use for mapping, second is the method-string and third is the mapped route-name
     * @param {string=} pathPrefix
     */
    var Collection = function (mapping, pathPrefix) {
        pathPrefix = pathPrefix || '';
        /** @type {Object.<string, Model.Endpoint>} */
        var endpoints = {};

        /**
         * @function
         * @name Model.Endpoint.Collection#update
         * @param {object} keyValueList
         * @returns void
         */
        this.update = function update(keyValueList) {
            for (var key in mapping) {
                if (!mapping.hasOwnProperty(key) || !endpoints.hasOwnProperty(key)) {
                    continue;
                }

                // first reset all paths
                endpoints[key].clear();

                for (var method in mapping[key]) {

                    if (!mapping[key].hasOwnProperty(method)
                        || !keyValueList.hasOwnProperty(mapping[key][method])
                    ) {
                        continue;
                    }

                    endpoints[key].set(method, keyValueList[mapping[key][method]].path);

                    if (keyValueList[mapping[key][method]].hasOwnProperty(Model.Endpoint.KEY_RESOURCE_LIMIT) &&
                        keyValueList[mapping[key][method]].hasOwnProperty(Model.Endpoint.KEY_RESOURCE_USED)) {
                        endpoints[key].resourceLimit = keyValueList[mapping[key][method]][Model.Endpoint.KEY_RESOURCE_LIMIT];
                        endpoints[key].resourceUsed  = keyValueList[mapping[key][method]][Model.Endpoint.KEY_RESOURCE_USED];
                        endpoints[key].limitQuery    = keyValueList[mapping[key][method]][Model.Endpoint.KEY_LIMIT_QUERY];
                    }
                }
            }
        };

        /**
         * @function
         * @name Model.Endpoint.Collection#endpoint
         * @param {string} name
         * @returns {?Model.Endpoint}
         */
        this.endpoint = function endpoint(name) {
            if (endpoints.hasOwnProperty(name)) {
                return endpoints[name];
            }
            console.warn('Endpoint ' + name + ' not found!');
            return null;
        };

        /**
         * @function
         * @name Model.Endpoint.Collection#updateLimitedEndpoints
         * @param {String} limitQuery
         * @param {Number} resourceLimit
         * @param {Number} resourceUsed
         */
        this.updateLimitedEndpoints = function updateLimitedEndpoints(limitQuery, resourceLimit, resourceUsed) {
            for (var key in endpoints) {
                if (!endpoints.hasOwnProperty(key) || endpoints[key].limitQuery !== limitQuery) {
                    continue;
                }

                endpoints[key].resourceLimit = resourceLimit;
                endpoints[key].resourceUsed  = resourceUsed;
            }
        };

        // initiate the defined endpoints
        for (var key in mapping) {
            if (!mapping.hasOwnProperty(key)) {
                continue;
            }
            endpoints[key] = new Model.Endpoint(key, pathPrefix);
        }
    };

    ns.Collection = Collection;
})(Object.namespace('Model.Endpoint'));




