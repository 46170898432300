/**
 * Created by Gabor on 29-May-17.
 */

var ns = Object.namespace('Controller.sBotReaction'),
    Index = ns.Index = function (reactions, sDomainService) {

        // IMPORTANT: also add this to the ng-backend!!!
        var exposedDefaultReactions = [
                'confirmation_unknowable',
                'confirmation_stop',
                'confirmation_addPOI',
                'confirmation_removePOI',
                'confirmation_startUserfeedback',
                'confirmation_sendUserfeedback',
                'welcome_existing_user',
                'conversation_deactivated',
                'bot_error'
            ],
            reactionCollection,
            identMap = Model.AI.Reaction.Collection.identMap;

        this.sDomainService = sDomainService;
        this.sections = [];

        for (var i = 0; i<exposedDefaultReactions.length; i++) {
            reactionCollection = new Model.AI.Reaction.Collection();
            reactionCollection.groupKey = exposedDefaultReactions[i];
            reactionCollection.domainId = this.sDomainService.currentDomainId ? this.sDomainService.currentDomainId : null;
            reactionCollection.findInArray(reactions);
            if (typeof identMap[exposedDefaultReactions[i]] !== 'undefined') {
                for (var prop in identMap[exposedDefaultReactions[i]]) {
                    reactionCollection[prop] = identMap[exposedDefaultReactions[i]][prop];
                }
            }
            this.sections.push(reactionCollection);
        }

        this.activeReaction = null;
    };

Index.prototype.setActiveReaction = function setActiveReaction(reaction) {
    this.activeReaction = reaction;
};