(function (ns) {
    /**
     * @namespace
     * @alias Model.Analytics.Summary
     *
     * @extends Model.Analytics.Value
     * @constructor
     */
    var Summary = function () {
        Model.Behavior.Meta.call(this);
        Object.defineProperties(
            this,
            {
                type: {
                    writable: true
                }
                /**
                 * @property
                 * @name Model.Analytics.Summary#type
                 * @type {String}
                 */
            }
        );
    };

    Object.extendProto(Summary, Model.Analytics.Value);

    /**
     * @param {Object} data
     */
    Summary.prototype.updateByData = function updateByData(data) {
        Object.updateByData(this, data);

        // map values to the object through the parent object
        Summary._pProto.updateByData.call(this, data.values);
    };

    ns.Summary = Summary;
})(Object.namespace('Model.Analytics'));