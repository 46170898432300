(function(ns) {
    /**
     * @namespace
     * @alias sContextNavigation.Provider.sContextNavigationMenu
     */
    var sContextNavigationMenu = function sContextNavigationMenuProvider() {
        var menu = new Model.Menu.Menu(),
            convertDIs      = function(diFn, $injector) {
                diFn        = diFn.slice();
                var args    = diFn.splice(0, diFn.length -1);
                diFn        = diFn.pop();

                args = args.map(function(element) {
                    return $injector.get(element);
                });

                return [diFn, args];
            }
            ;

        /**
         * @name sContextNavigation.Provider.sContextNavigationMenu#addContextAction
         * @param {Model.Menu.ContextAction} action
         * @param {int=} position
         * @returns {sContextNavigation.Provider.sContextNavigationMenu}
         */
        this.addContextAction = function(action, position) {
            Object.instanceOf(action, Model.Menu.ContextAction);
            if (!position) {
                menu.addItem(action);
            } else {
                menu.insertItem(action, position, false);
            }
            return this;
        };

        this.$get = function($injector) {
            menu.items.map(function(entry, position) {
                var wasChanged  = false,
                    action      = entry.action,
                    condition   = entry.condition
                    ;

                if (condition instanceof Array) {
                    var conditionFn = convertDIs(condition, $injector);
                    condition = function() {
                        return conditionFn.slice(0,1).shift().apply(
                            entry,
                            Array.prototype.slice.call(arguments).concat(conditionFn.slice(1,2).shift())
                        );
                    };
                    wasChanged = true;
                }

                if (action instanceof Array) {
                    var actionFn = convertDIs(action, $injector);
                    action = function() {
                        return actionFn.slice(0,1).shift().apply(
                            entry,
                            Array.prototype.slice.call(arguments).concat(actionFn.slice(1,2).shift())
                        );
                    };
                    wasChanged = true;
                }

                if (wasChanged) {
                    menu.insertItem(
                        new Model.Menu.ContextAction(
                            condition,
                            action,
                            entry.options
                        ),
                        position,
                        true
                    );
                }
            });
            return menu;
        };
    };

    ns.sContextNavigationMenu = sContextNavigationMenu;
})(Object.namespace('sContextNavigation.Provider'));