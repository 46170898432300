(function (ns) {
    var categories = {};
    /**
     * @namespace
     * @alias Model.Analytics.Category
     *
     * @param {String} key
     * @param {String} type
     * @param {String} label
     * @constructor
     */
    var Category = function (key, type, label) {
        Object.defineProperties(
            this,
            {
                key: {
                    get: function() {
                        return key;
                    }
                    /**
                     * @property
                     * @name Model.Analytics.Category#key
                     * @type {String}
                     */
                },
                type: {
                    get: function() {
                        return type;
                    }
                    /**
                     * @property
                     * @name Model.Analytics.Category#type
                     * @type {String}
                     */
                },
                label: {
                    get: function() {
                        return label;
                    }
                    /**
                     * @property
                     * @name Model.Analytics.Category#label
                     * @type {String}
                     */
                }
            }
        );

        categories[type + '-' + key] = this;
    };

    /**
     * @param {Object} data
     * @returns {Model.Analytics.Category}
     */
    Category.createByData = function createByData(data) {
        return new Category(data.key, data.type, data.value);
    };

    /**
     * @param {String} type
     * @param {String} key
     * @returns {?Model.Analytics.Category}
     */
    Category.getByTypeAndKey = function getByTypeAndKey(type, key) {
        return categories[type + '-' + key];
    };

    ns.Category = Category;
})(Object.namespace('Model.Analytics'));